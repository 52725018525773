<form [formGroup]="form" class="form-b4a" (submit)="submit()">
  <p-fieldset legend="Detalhes">
    <ng-container formGroupName="grade">
      <span class="p-float-label">
        <input id="title" pInputText formControlName="title" />
        <label for="title">Atributo</label>
      </span>
      <span class="p-float-label">
        <input id="value" pInputText formControlName="value" />
        <label for="value">Valor</label>
      </span>
    </ng-container>
    <span class="p-float-label">
      <p-inputNumber
        id="sku"
        [min]="0"
        [maxFractionDigits]="0"
        [useGrouping]="false"
        formControlName="sku"
      />
      <label for="sku">SKU</label>
    </span>
    <span class="p-float-label" formGroupName="stock">
      <p-inputNumber
        id="quantity"
        inputId="quantity"
        formControlName="quantity"
        [min]="0"
      />
      <label for="quantity">Estoque</label>
    </span>
    <span class="p-float-label">
      <input id="EAN" type="number" pInputText formControlName="EAN" />
      <label for="EAN">EAN</label>
    </span>
    <span class="p-float-label">
      <p-inputNumber
        id="weight"
        inputId="weight"
        formControlName="weight"
        [min]="0"
        [minFractionDigits]="2"
        [maxFractionDigits]="2"
        suffix="g"
      />
      <label for="weight">Peso *</label>
    </span>
    <span class="p-float-label">
      <p-inputNumber
        id="costPrice"
        inputId="costPrice"
        formControlName="costPrice"
        prefix="R$"
        readonly
      />
      <label for="costPrice">Custo</label>
    </span>
  </p-fieldset>
  <p-fieldset legend="Preço" formGroupName="price">
    <span class="p-float-label">
      <p-inputNumber
        id="fullPrice"
        formControlName="fullPrice"
        prefix="R$"
        [maxFractionDigits]="2"
        [minFractionDigits]="2"
        [min]="0.01"
        (onInput)="recalculatePrices()"
        (onBlur)="recalculatePrices()"
        (onKeyDown)="recalculatePrices()"
      />
      <label for="fullPrice">Preço cheio *</label>
    </span>
    <span class="p-float-label">
      <p-inputNumber
        (onInput)="recalculatePrices()"
        (onBlur)="recalculatePrices()"
        (onKeyDown)="recalculatePrices()"
        id="price"
        formControlName="price"
        prefix="R$"
        [maxFractionDigits]="2"
        [minFractionDigits]="2"
        [min]="0.01"
      />
      <label for="price">Preço *</label>
    </span>
    <span class="p-float-label">
      <p-inputNumber
        (onInput)="recalculatePrices()"
        (onBlur)="recalculatePrices()"
        (onKeyDown)="recalculatePrices()"
        id="subscriberDiscount"
        type="number"
        suffix="%"
        formControlName="subscriberDiscountPercentage"
        [maxFractionDigits]="2"
        [minFractionDigits]="2"
        [max]="100"
        [min]="0"
      />
      <label for="subscriberDiscount">Desconto de assinante *</label>
    </span>
    <span class="p-float-label">
      <p-inputNumber
        id="subscriberPrice"
        prefix="R$"
        formControlName="subscriberPrice"
        [maxFractionDigits]="2"
        [minFractionDigits]="2"
      />
      <label for="subscriberPrice">Preço de assinate *</label>
    </span>
    <span class="p-float-label">
      <p-inputNumber
        id="fullPriceDiscountPercentage"
        formControlName="fullPriceDiscountPercentage"
        suffix="%"
        [maxFractionDigits]="2"
        [minFractionDigits]="2"
      />
      <label for="fullPriceDiscountPercentage">Desconto preço cheio *</label>
    </span>
  </p-fieldset>

  <div class="form-footer">
    <div class="info">
      <div>
        <p>
          *Última alteração em:
          {{ variant.updatedAt || variant.createdAt | date : 'short' }}
        </p>
      </div>
    </div>
    <div class="action">
      <p-button
        type="submit"
        pRipple
        label="Salvar"
        severity="primary"
        [disabled]="!form.valid"
      >
      </p-button>
    </div>
  </div>
</form>
<p-toast />

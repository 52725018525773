import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CreditCardPayment } from 'src/app/admin-api';

@Component({
  selector: 'app-payment-details-modal',
  templateUrl: './payment-details-modal.component.html',
  styleUrls: ['./payment-details-modal.component.scss']
})
export class PaymentDetailsModalComponent {
  payment: CreditCardPayment;

  constructor(
    private config: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {
    this.payment = this.config.data;
    if (!this.payment)
      throw 'PaymentDetailsModalComponent: please provide payment';
  }
}

import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { lastValueFrom, map } from 'rxjs';
import {
  CouponControllerService,
  CouponSummary,
  CouponUsageType,
  InfluencerControllerService,
  InfluencerStatus,
  PageableRequest,
  SubscriptionControllerService,
  SubscriptionType
} from 'src/app/admin-api';
import {
  DropdownFilter,
  PageContent,
  TableColumn
} from 'src/app/components/table';
import { getAllSubscriptions, getSubscriptionName } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.scss']
})
export class CouponsComponent implements OnInit {
  cols: Array<TableColumn> = [
    new TableColumn('Id', 'couponId', true, 'number', '', 'couponId'),
    new TableColumn(
      'Nome',
      'name',
      true,
      'text',
      '',
      'couponId',
      true,
      'contains'
    ),
    new TableColumn('Desconto', 'valueString', false, 'text'),
    new TableColumn('Glampoints', 'glampoints', true, 'formattedNumber'),
    new TableColumn(
      'Restrição de plano',
      'subscriptionTypeNames',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn('Tipo partner', 'influencerStatus', true, 'text'),
    new TableColumn('Categoria', 'couponUsageType', true, 'text'),
    new TableColumn('Renovação', 'isRenewal', true, 'boolean'),
    new TableColumn('Produtos', 'products', false, 'number'),
    new TableColumn('Ativo', 'enabled', true, 'inputSwitch'),
    new TableColumn(
      'Expiração',
      'dateExpired',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    ),
    new TableColumn('Utilização', 'quantityUsed', true, 'formattedInteger')
  ];
  couponUsageTypes: Array<CouponUsageType> | undefined;
  influencerStatusList: Array<InfluencerStatus> | undefined;
  dropdownFilters = {
    isRenewal: [
      { label: 'Todos', value: '' },
      { label: 'Sim', value: '1' },
      { label: 'Não', value: '0' }
    ],
    enabled: [
      { label: 'Todos', value: '' },
      { label: 'Sim', value: '1' },
      { label: 'Não', value: '0' }
    ],
    subscriptionTypeNames: []
  };
  subscriptionTypes: SubscriptionType[];

  constructor(
    public couponService: CouponControllerService,
    private influencerService: InfluencerControllerService,
    private title: Title,
    private subscriptionService: SubscriptionControllerService
  ) {}
  async ngOnInit(): Promise<void> {
    this.title.setTitle('Cupons de assinatura');
    this.dropdownFilters['subscriptionName'] = [
      { label: 'Todas', value: '-1' }
    ].concat(
      getAllSubscriptions().map((s) => ({
        label: s.label,
        value: s.value.toString()
      }))
    );
    await Promise.all([
      this.findInfluencerStatusList(),
      this.findCouponUsageTypes(),
      this.findSubscriptionTypes()
    ]);
  }

  async findSubscriptionTypes(): Promise<void> {
    try {
      this.subscriptionTypes = (
        await Promise.all(
          getAllSubscriptions().map((s) =>
            lastValueFrom(
              this.subscriptionService
                .findSubscriptionTypeList(s.value)
                .pipe(map((data) => data.result))
            )
          )
        )
      ).reduce(
        (list: SubscriptionType[], sTypes) => (list = list.concat(sTypes)),
        []
      );
      this.dropdownFilters['subscriptionTypeNames'] = [
        { label: 'Sem restrições', value: '' } as DropdownFilter
      ].concat(
        this.subscriptionTypes.reduce((list: DropdownFilter[], st) => {
          if (list.some((i) => i.value === st.subscriptionId)) {
            list
              .find((i) => i.value === st.subscriptionId)
              .items.push({
                label: this.subscriptionLabel(st.subscriptionTypeId),
                value: this.subscriptionLabel(st.subscriptionTypeId)
              });
          } else {
            list.push({
              label: getSubscriptionName(st.subscriptionId),
              value: st.subscriptionId,
              items: [
                {
                  label: this.subscriptionLabel(st.subscriptionTypeId),
                  value: this.subscriptionLabel(st.subscriptionTypeId)
                }
              ]
            });
          }
          return list;
        }, [])
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findCouponUsageTypes(): Promise<void> {
    try {
      this.couponUsageTypes = await lastValueFrom(
        this.couponService
          .findUsageTypesList()
          .pipe(
            map((data) => data.result?.filter((c) => c.couponUsageTypeId !== 8))
          )
      );
      this.dropdownFilters['couponUsageType'] = [
        { label: 'Todos', value: '-1' }
      ].concat(
        this.couponUsageTypes?.map((t) => ({
          label: t.description as string,
          value: t.couponUsageTypeId?.toString() as string
        })) || []
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findInfluencerStatusList(): Promise<void> {
    try {
      this.influencerStatusList = await lastValueFrom(
        this.influencerService
          .findInfluencerStatusList()
          .pipe(map((data) => data.result))
      );
      this.dropdownFilters['influencerStatus'] = [
        { label: 'Todas', value: '-1' }
      ].concat(
        this.influencerStatusList?.map((i) => ({
          label: i.influencerStatus as string,
          value: i.influencerStatusId?.toString() as string
        })) || []
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findPage(
    request: PageableRequest,
    service: unknown
  ): Promise<PageContent<CouponSummary>> {
    const filterUsage = request.filters?.find(
      (f) => f.field === 'couponUsageType'
    );
    if (filterUsage?.value) {
      if (Number(filterUsage.value) < 0) {
        filterUsage.value = '';
      }
      request.filters?.push({
        condition: 'equals',
        field: 'couponUsageTypeId',
        fieldType: 'number',
        value: filterUsage.value
      });
    }
    const filterInfluencer = request.filters?.find(
      (f) => f.field === 'influencerStatus'
    );
    if (filterInfluencer?.value) {
      if (Number(filterInfluencer.value) < 0) {
        filterInfluencer.value = '';
      }
      request.filters?.push({
        condition: 'equals',
        field: 'influencerStatusTypeIdRestriction',
        fieldType: 'number',
        value: filterInfluencer.value
      });
    }
    return lastValueFrom(
      (service as CouponControllerService)
        .findCouponsTable(request)
        .pipe(map((data) => data.result as PageContent<CouponSummary>))
    );
  }

  async updateEnabled(coupon: CouponSummary): Promise<void> {
    LoaderService.showLoader();
    try {
      await lastValueFrom(
        this.couponService.updateEnabledState({
          couponId: coupon.couponId,
          enabled: coupon.enabled
        })
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  subscriptionLabel(subscriptionTypeId: number) {
    const subType = this.subscriptionTypes?.find(
      (st) => st.subscriptionTypeId === subscriptionTypeId
    );
    return subType
      ? `${getSubscriptionName(subType.subscriptionId)} ${subType.name}`
      : 'Não encontrado';
  }
}

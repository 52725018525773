import { Component, Input, computed } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  BeautyProfileAttribute,
  BeautyProfileControllerService,
  Group,
  Option,
  PersonAttributeLogDetail,
  PersonDetail
} from 'src/app/admin-api';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { BeautyprofileHistoryComponent } from './beautyprofile-history/beautyprofile-history.component';

@Component({
  selector: 'app-person-beautyprofile',
  templateUrl: './person-beautyprofile.component.html',
  styleUrls: ['./person-beautyprofile.component.scss'],
  providers: [DialogService]
})
export class PersonBeautyprofileComponent {
  @Input()
  person: PersonDetail | undefined;

  @Input()
  beautyProfile: Array<Group> | undefined;
  history: Array<PersonAttributeLogDetail> | undefined;

  attributes = computed(() => {
    const list = this.beautyProfile.reduce(
      (list: Array<BeautyProfileAttribute | Group>, g) =>
        (list = list.concat(g.attributes).concat(g.attributeGroups)),
      []
    );
    list.sort((g1, g2) => g1.order - g2.order);
    return list.reduce((attributes: Array<BeautyProfileAttribute>, ag) => {
      if ((ag as BeautyProfileAttribute).options) {
        attributes.push(ag);
      } else {
        attributes = attributes.concat((ag as Group).attributes);
      }
      return attributes;
    }, []);
  });

  constructor(
    private dialogService: DialogService,
    private beautyprofileService: BeautyProfileControllerService
  ) {}

  answeredOptions(attribute: BeautyProfileAttribute): Array<Option> {
    return attribute.options?.filter((o) => o.checked) || [];
  }

  async showHistory(): Promise<void> {
    LoaderService.showLoader();
    try {
      if (!this.history && this.person?.personId) {
        this.history = await lastValueFrom(
          this.beautyprofileService
            .findPersonBeautyProfileHistory(this.person?.personId)
            .pipe(map((data) => data.result))
        );
      }
      if (this.person) {
        this.dialogService.open(BeautyprofileHistoryComponent, {
          closable: true,
          closeOnEscape: false,
          data: {
            history: this.history,
            person: this.person
          },
          maximizable: true,
          header: `${this.person.name} ${this.person.lastName} - Histórico do Perfil de Beleza`
        });
      }
    } catch (error: any) {
      AppDialogService.showErrorDialog(
        error,
        false,
        'Erro ao buscar o histórico.'
      );
    }
    LoaderService.showLoader(false);
  }

  get isAnswered(): boolean {
    return (
      (this.beautyProfile &&
        this.beautyProfile[0]?.lastAnswer &&
        new Date(this.beautyProfile[0].lastAnswer).getTime() > 0) ||
      false
    );
  }

  get dateCreated(): Date {
    return this.beautyProfile[0]?.dateCreated;
  }

  get lastModification(): Date {
    return this.beautyProfile[0]?.lastAnswer;
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxPermissionsService } from 'ngx-permissions';
import { ConfirmationService, MessageService, PrimeIcons } from 'primeng/api';
import { lastValueFrom, map } from 'rxjs';
import {
  CompositionControllerService,
  CompositionProduction,
  CompositionProductionSummary
} from 'src/app/admin-api';
import { TableActionButton, TableColumn } from 'src/app/components/table';
import { Role, roleAsObject } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-composition-production',
  templateUrl: './composition-production.component.html',
  styleUrl: './composition-production.component.scss',
  providers: [MessageService, ConfirmationService]
})
export class CompositionProductionComponent implements OnInit {
  private compositionService: CompositionControllerService = inject(
    CompositionControllerService
  );
  private messageService: MessageService = inject(MessageService);
  private confirmationService: ConfirmationService =
    inject(ConfirmationService);
  private ngxPermissionsService: NgxPermissionsService = inject(
    NgxPermissionsService
  );

  @Input()
  composition?: CompositionProductionSummary;
  @Input()
  username?: string;
  @Output()
  productionChanged = new EventEmitter<CompositionProductionSummary>();

  admin = false;
  permission = false;
  form = new FormGroup({
    compositionId: new FormControl(this.composition?.compositionId, [
      Validators.required
    ]),
    quantity: new FormControl(this.composition?.pending || 1, [
      Validators.required,
      Validators.min(1)
    ])
  });
  cols = [
    new TableColumn('Usuário', 'username', false, 'text'),
    new TableColumn('Quantidade', 'quantity', false, 'formattedInteger'),
    new TableColumn('Data da produção', 'dateCreated', false, 'date')
  ];
  actionButtons = [
    new TableActionButton(
      '',
      '',
      PrimeIcons.TRASH,
      (item: CompositionProduction) =>
        this.admin ||
        (this.permission &&
          (!item.username || item.username === this.username)),
      '',
      'Excluir produção',
      'bottom',
      true,
      true,
      'danger',
      'small'
    )
  ];
  ready = false;

  async ngOnInit(): Promise<void> {
    this.permission = await this.ngxPermissionsService.hasPermission([
      roleAsObject(Role.Logistics).enumValue,
      roleAsObject(Role.Full_Administrator).enumValue
    ]);
    this.admin = await this.ngxPermissionsService.hasPermission(
      roleAsObject(Role.Full_Administrator).enumValue
    );
    if (this.admin || this.permission) {
      this.cols.push(new TableColumn('Ação', '', false, 'button'));
      this.form.patchValue({
        compositionId: this.composition.compositionId,
        quantity: this.composition.pending || 1
      });
    } else {
      this.form.disable();
    }
    this.ready = true;
  }

  async submit(): Promise<void> {
    LoaderService.showLoader();
    try {
      const result = await lastValueFrom(
        this.compositionService
          .createCompositionProduction(this.form.value)
          .pipe(map((data) => data.result))
      );
      this.composition.productions = [...this.composition.productions, result];
      this.composition.pending -= result.quantity;
      this.composition.quantity += result.quantity;
      this.form.reset({
        compositionId: this.composition.compositionId,
        quantity: this.composition.pending || 1
      });
      this.messageService.add({
        severity: 'success',
        detail: 'Produção adicionada com sucesso',
        summary: 'Sucesso'
      });
      this.productionChanged.emit(this.composition);
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  deleteComposition(event: {
    item: CompositionProduction;
    $event: Event;
    action: string;
  }): void {
    this.confirmationService.confirm({
      acceptLabel: 'Sim',
      acceptButtonStyleClass: 'p-button-danger',
      acceptIcon: PrimeIcons.TRASH,
      rejectIcon: PrimeIcons.TIMES,
      rejectLabel: 'Não',
      rejectButtonStyleClass: 'p-button-primary',
      message:
        'Deseja excluir a produção de ' +
        event.item.quantity +
        ' assinante(s)?',
      target: event.$event.target,
      accept: async () => {
        LoaderService.showLoader();
        try {
          const detail = await lastValueFrom(
            this.compositionService
              .deleteCompositionProduction(event.item.compositionProductionId)
              .pipe(map((data) => data.result))
          );
          this.messageService.add({
            severity: 'success',
            summary: 'Sucesso',
            detail
          });
          this.composition.productions =
            this.composition.productions?.filter(
              (p) =>
                p.compositionProductionId !== event.item.compositionProductionId
            ) || [];
          this.composition.pending += event.item.quantity;
          this.composition.quantity -= event.item.quantity;
          this.productionChanged.emit(this.composition);
        } catch (error) {
          AppDialogService.showErrorDialog(error);
        }
        LoaderService.showLoader(false);
      }
    });
  }
}

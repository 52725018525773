<form class="modal-form" [formGroup]="form" (ngSubmit)="onSubmit()">
  <span class="p-float-label">
    <input pInputText id="templateName" formControlName="templateName" />
    <label for="templateName">Nome do Template *</label>
  </span>

  <span class="p-float-label">
    <input pInputText id="templateId" formControlName="templateId" />
    <label for="templateId">ID do Template *</label>
  </span>

  <span class="p-float-label">
    <input pInputText id="subject" formControlName="subject" />
    <label for="subject">Assunto do Email *</label>
  </span>

  <span class="p-float-label">
    <input pInputText id="subtitle" formControlName="subtitle" />
    <label for="subtitle">Subtítulo do Email</label>
  </span>

  <span class="p-float-label">
    <textarea
      pInputTextarea
      id="description"
      formControlName="description"
    ></textarea>
    <label for="description">Descrição do Template</label>
  </span>

  <span class="input-switch">
    <p-inputSwitch formControlName="active" />
    <label>{{
      form.get('active').value ? 'Template Ativo' : 'Template Inativo'
    }}</label>
  </span>

  <div class="buttons">
    <p-button
      label="Cancelar"
      severity="danger"
      styleClass="p-button-outlined"
      (onClick)="ref.close()"
    />
    <p-button
      label="Salvar"
      type="submit"
      [disabled]="!form.valid || !permission"
    />
  </div>
</form>

/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Option } from './option';

export interface BeautyProfileAttribute {
  id?: number;
  name?: string;
  question?: string;
  order?: number;
  type?: number;
  hasError?: boolean;
  errorMessage?: string;
  webImage?: string;
  options?: Array<Option>;
  sensitiveContent?: boolean;
}

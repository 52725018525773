<app-table
  *ngIf="coupons"
  [cols]="cols"
  [addButton]="true"
  addLabel="Cupom"
  [elements]="coupons"
  [lazy]="false"
  modelName="cupons"
  tableTitle="Cupons da Glamparner"
  sortField="movementDate"
  [sortOrder]="1"
  (booleanAction)="updateCouponStatus($event)"
  [emptyMessage]="
    'A Glampartner ' + influencer?.firstName + ' não possui cupons.'
  "
  [addButtonAction]="true"
  (addButtonClick)="addCoupon()"
></app-table>

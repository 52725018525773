/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface InfluencerDetail {
  personId?: number;
  name?: string;
  influencerStatusId?: number;
  enabled?: number;
  dateCreated?: Date;
  boost?: number;
  acceptGlamstar?: Date;
  amount?: number;
  firstName?: string;
  lastName?: string;
  description?: string;
  influencerStatus?: string;
  influencerStatusTypeId?: number;
  pis?: string;
  rg?: string;
  couponName?: string;
}

<div
  *ngIf="product"
  class="product"
  [class.product--even]="index !== undefined && index % 2 === 0"
>
  <div class="product-image">
    <img
      [src]="
        product.defaultImageURL ||
        'https://s3.amazonaws.com/Glambox.Catalog.Settings/ProductVariantDefaultImage_0_SML.png'
      "
    />
  </div>
  <div class="product-info">
    <a
      type="button"
      class="data-link"
      [routerLink]="
        '/products/catalog/product-variant/' + product.productVariantId
      "
      >{{ product.externalID }}</a
    >
    - {{ product.productVariantName | titlecase }}
    <ng-container *ngIf="product.internalEAN"
      >({{ product.internalEAN }})</ng-container
    >
    <span class="info">
      <ng-container *ngIf="product.categoryId || product.subcategoryId"
        ><br />
        <ng-container *ngIf="product.categoryId">
          <strong>Categoria: </strong>{{ product.category }} -
        </ng-container>
        <ng-container *ngIf="product.subcategoryId && product.categoryId">
          <strong>Subcategoria: </strong>{{ product.subcategory }} -
        </ng-container>
        <ng-container *ngIf="product.subcategoryId && !product.categoryId">
          <strong>Categoria: </strong>{{ product.subcategory }} -
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!product.categoryId && !product.subcategoryId">
        <br />
      </ng-container>
      <strong>BrandEquity: </strong>{{ equityLabel(product.brandEquity!) }}
    </span>
  </div>
  <div class="extra-info" *ngIf="product.type !== 'CompositionProduct'">
    <i
      class="pi pi-gift"
      pTooltip="Brinde"
      tooltipPosition="bottom"
      *ngIf="product.type === 'Gift'"
    ></i>
    <i
      class="pi pi-shopping-cart"
      pTooltip="Troca"
      tooltipPosition="bottom"
      *ngIf="product.type === 'Trade'"
    ></i>
    <i
      class="pi pi-undo"
      pTooltip="Reenvio"
      tooltipPosition="bottom"
      *ngIf="product.type === 'Resend'"
    ></i>
    <i
      class="pi pi-money-bill"
      pTooltip="Incentivo para influencer"
      tooltipPosition="bottom"
      *ngIf="product.type === 'IncentiveInfluencer'"
    ></i>
  </div>
</div>

import { Article } from './article';
import { Pageable } from './pageable';
import { Sort } from './sort';

export interface PageArticle {
  totalPages?: number;
  totalElements?: number;
  size?: number;
  content?: Array<Article>;
  number?: number;
  numberOfElements?: number;
  pageable?: Pageable;
  sort?: Sort;
  last?: boolean;
  first?: boolean;
  empty?: boolean;
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AfterViewInit,
  Component,
  Input,
  ViewEncapsulation,
  inject
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Category, Product, Shops } from '@infrab4a/connect';
import { MessageService } from 'primeng/api';
import { TableColumn } from 'src/app/components/table';
import { ShopCategoryService } from 'src/app/connect-api/api/shop/shop-category.service';
import { getShopsArray } from 'src/app/connect-api/enums/ShopMap';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-shop-category-detail',
  templateUrl: './shop-category-detail.component.html',
  styleUrl: './shop-category-detail.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class ShopCategoryDetailComponent implements AfterViewInit {
  @Input() categoryId?: string;
  private title = inject(Title);
  private shoppingCategoryService = inject(ShopCategoryService);
  private messageService = inject(MessageService);
  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);

  category: Partial<Category>;
  categoryProducts: Partial<Product>[] = [];
  productCols = [
    new TableColumn(
      'SKU',
      'sku',
      true,
      'text',
      '/shop-products/catalog/product/',
      'id',
      true,
      'contains'
    ),
    new TableColumn(
      'EAN',
      'EAN',
      true,
      'text',
      '/shop-products/catalog/product/',
      'id'
    ),
    new TableColumn(
      'Nome',
      'name',
      true,
      'text',
      '/shop-products/catalog/product/',
      'id',
      true,
      'contains'
    ),
    new TableColumn(
      'Marca',
      'brand',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    )
  ];
  categoryTreeBreadCrumb = [{ label: 'Não possui árvore de categoria' }];
  mostRelevants = {
    Glamshop: [],
    mensmarket: []
  };
  selectedTab = 0;
  pendingRequest = false;
  running = false;
  shops = getShopsArray();
  accordionIndex = -1;
  productsForSelection: { [shop: string]: Partial<Product>[] } = {};
  copyMostRelevants = new FormControl(false);

  async ngAfterViewInit(): Promise<void> {
    LoaderService.showLoader();
    try {
      if (this.categoryId) {
        this.category = await this.shoppingCategoryService.getCategoryById(
          this.categoryId
        );
        this.findProducts();
        this.title.setTitle(`Loja - ${this.category.name}`);
      } else {
        this.title.setTitle('Loja - Nova categoria');
      }
      this.activatedRoute.queryParams.subscribe((queryParams) => {
        if (queryParams['tab']) {
          this.selectedTab = Number(queryParams['tab']) || 0;
        } else if (!this.category) {
          this.selectedTab = 0;
        }
      });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async updateCategory(
    category: Partial<Category>,
    refresh = false
  ): Promise<void> {
    category = await this.shoppingCategoryService.updateCategory(
      ShopCategoryService.categoryUpdateDTO(category)
    );
    category.updatedAt = new Date();
    if (refresh) {
      this.category = await this.shoppingCategoryService.getCategoryById(
        category.id
      );
    } else {
      this.category = category;
    }
    this.startMostRelevants();
  }

  async saveMostRelevants(shop: Shops): Promise<void> {
    try {
      LoaderService.showLoader();
      const { metadata, ...category } = ShopCategoryService.categoryUpdateDTO(
        this.category
      );
      if (!category.mostRelevants)
        category.mostRelevants = { Glamshop: [], mensmarket: [] };

      this.shops.forEach((s) => {
        if (
          !category?.shops?.includes(s.value) ||
          (!this.copyMostRelevants.value &&
            !this.mostRelevants[s.value]?.length)
        )
          category.mostRelevants[s.value] = null;
        else if (this.copyMostRelevants.value)
          category.mostRelevants[s.value] = (
            this.mostRelevants[shop] as Partial<Product>[]
          )
            .filter(Boolean)
            .map((p) => p?.id);
        else
          category.mostRelevants[s.value] = (
            this.mostRelevants[s.value] as Partial<Product>[]
          ).map((p) => p.id);
      });
      await this.updateCategory(category);
      this.accordionIndex = -1;
      this.messageService.add({
        severity: 'success',
        detail: 'Ordenação dos produtos salva com sucesso.'
      });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  tabChanged($event: number): void {
    this.router.navigate(
      [`/shop-products/categories/category/${this.categoryId}`],
      {
        queryParams: { tab: $event },
        queryParamsHandling: 'merge'
      }
    );
  }

  categoryUpdated(category: Category): void {
    if (
      this.category.brandCategory !== category.brandCategory ||
      this.category.isCollection !== category.isCollection
    )
      this.findProducts();
    this.category = category;
  }

  findProducts(): void {
    delete this.categoryProducts;
    this.runPromise(
      this.shoppingCategoryService.getCategoryProducts(this.category)
    );
  }

  private runPromise(promise: Promise<any>): void {
    if (!this.running) {
      this.running = true;
      setTimeout(async () => {
        try {
          const products = await promise;
          this.running = false;
          if (!this.pendingRequest) {
            this.products = products.products.data.map((product) => {
              return {
                ...product,
                images: product.miniatures?.length
                  ? [product.miniatures[0]]
                  : ['assets/images/no_img_available.jpg']
              };
            });
          } else {
            this.pendingRequest = false;
            this.findProducts();
          }
        } catch (error) {
          this.running = false;
          if (this.pendingRequest) this.findProducts();
          else this.products = [];
        }
      }, 1000);
    } else {
      this.pendingRequest = true;
    }
  }

  private startMostRelevants(): void {
    const glamProducts = this.category?.mostRelevants?.Glamshop || [];
    const mensProducts = this.category?.mostRelevants?.mensmarket || [];

    this.mostRelevants[Shops.GLAMSHOP] = glamProducts
      ? glamProducts.map((p) =>
          this.categoryProducts?.find((cp) => cp.id === p)
        )
      : [];

    this.mostRelevants[Shops.MENSMARKET] = mensProducts
      ? mensProducts.map((p) =>
          this.categoryProducts?.find((cp) => cp.id === p)
        )
      : [];

    this.shops.forEach((shop) => {
      this.productsForSelection[shop.value] = this.categoryProducts?.filter(
        (p) => !this.mostRelevants[shop.value].some((rp) => rp?.id === p?.id)
      );

      this.productsForSelection[shop.value].sort((p1, p2) => {
        if (
          FormUtil.semAcento(p1.name).toUpperCase() <
          FormUtil.semAcento(p2.name).toUpperCase()
        )
          return -1;
        if (
          FormUtil.semAcento(p1.name).toUpperCase() >
          FormUtil.semAcento(p2.name).toUpperCase()
        )
          return 1;
        return Number(p1.id) - Number(p2.id);
      });
    });
    this.copyMostRelevants.setValue(
      this.category.mostRelevants &&
        this.category?.shops?.every(
          (s) =>
            this.category.mostRelevants[s] &&
            this.category.mostRelevants[this.category.shop]?.every(
              (p, i) => this.category.mostRelevants[s][i] === p
            )
        )
    );
    this.copyMostRelevants.markAsPristine();
    this.copyMostRelevants.markAsUntouched();
  }

  private set products(products: Array<Partial<Product>>) {
    this.categoryProducts = products;
    setTimeout(() => this.startMostRelevants(), 250);
  }

  shopsIncludes(shop: string): boolean {
    return this.category.shops.includes(shop);
  }

  getShop(shop: Shops) {
    return this.shops.find((s) => s.value === shop);
  }

  buttonDisabled(shop: Shops): boolean {
    return (
      this.category.mostRelevants &&
      ((!this.mostRelevants[shop]?.length &&
        !this.category?.mostRelevants[shop]) ||
        (!!this.mostRelevants[shop]?.length &&
          this.mostRelevants[shop]?.length ===
            this.category?.mostRelevants[shop]?.length &&
          (this.mostRelevants[shop] as Partial<Product>[])?.every(
            (p, i) => this.category?.mostRelevants[shop][i] === p?.id
          ))) &&
      !this.copyMostRelevants.dirty &&
      !this.copyMostRelevants.touched
    );
  }

  mostRelevant(shop: Shops) {
    return (
      this.category.mostRelevants[shop]?.map((p) =>
        this.categoryProducts?.find((cp) => cp.id === p)
      ) || []
    );
  }

  get mostRelevantShops() {
    return this.shops.filter((s) => this.category?.shops.includes(s.value));
  }
}

import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import { BlacklistControllerService } from 'src/app/admin-api';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-blacklist-domain-form',
  templateUrl: './blacklist-domain-form.component.html'
})
export class BlacklistDomainFormComponent {
  private blacklistService = inject(BlacklistControllerService);
  private ref = inject(DynamicDialogRef);

  form = new FormGroup({
    domainName: new FormControl<string>(null, Validators.required)
  });

  async submit(): Promise<void> {
    LoaderService.showLoader();
    try {
      const result = await lastValueFrom(
        this.blacklistService
          .addDomainToBlacklist(this.form.value)
          .pipe(map((data) => data.result))
      );
      this.ref.close(result);
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }
}

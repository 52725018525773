import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductImagesModule } from 'src/app/components/product-images/product-images.module';
import { AppTableModule } from 'src/app/components/table/app-table.module';
import { CustomizationRoutingModule } from './customization-routing.module';
import { MenuComponent } from './menu/menu.component';

// primeng
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CarouselModule } from 'primeng/carousel';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { MessageModule } from 'primeng/message';
import { OrderListModule } from 'primeng/orderlist';
import { PanelModule } from 'primeng/panel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';

// own-brands
import { GlamBeautyComponent } from './own-brands/glam-beauty/glam-beauty.component';
import { MalmoComponent } from './own-brands/malmo/malmo.component';
import { MensComponent } from './own-brands/mens/mens.component';

// lps
import { LpClubBannersFormComponent } from './glam/lp-club/lp-club-banners/lp-club-banners-form/lp-club-banners-form.component';
import { LpClubBannersComponent } from './glam/lp-club/lp-club-banners/lp-club-banners.component';

// glampoints
import { CallAndWinFormComponent } from './glam/call-friends/call-and-win/call-and-win-form/call-and-win-form.component';
import { CallAndWinComponent } from './glam/call-friends/call-and-win/call-and-win.component';
import { EarnGlampointsFormComponent } from './glam/call-friends/earn-glampoints/earn-glampoints-form/earn-glampoints-form.component';
import { EarnGlampointsComponent } from './glam/call-friends/earn-glampoints/earn-glampoints.component';
import { HomeScreenFormComponent } from './glam/call-friends/home-screen/home-screen-form/home-screen-form.component';
import { HomeScreenComponent } from './glam/call-friends/home-screen/home-screen.component';
import { PostsFormComponent } from './glam/call-friends/posts/posts-form/posts-form.component';
import { PostsComponent } from './glam/call-friends/posts/posts.component';

import { AutoCompleteModule } from 'primeng/autocomplete';
import { BlockUIModule } from 'primeng/blockui';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { FieldsetModule } from 'primeng/fieldset';
import { ImageModule } from 'primeng/image';
import { InputSwitchModule } from 'primeng/inputswitch';
import { PickListModule } from 'primeng/picklist';
import { TooltipModule } from 'primeng/tooltip';
import { GridstackModule } from 'src/app/components/gridstack/gridstack.module';
import { CustomizationComponentBannerFormComponent } from './customization-component/customization-component-banner/customization-component-banner-form/customization-component-banner-form.component';
import { CustomizationComponentBannerComponent } from './customization-component/customization-component-banner/customization-component-banner.component';
import { CustomizationComponentComponent } from './customization-component/customization-component.component';
import { DmsBannerFormComponent } from './dms-section-form/dms-banner-form/dms-banner-form.component';
import { DmsSectionFormComponent } from './dms-section-form/dms-section-form.component';
import { CollectionsLojinhasEditComponent } from './glam/collections-lojinhas/collections-lojinhas-edit/collections-lojinhas-edit.component';
import { CollectionsLojinhasComponent } from './glam/collections-lojinhas/collections-lojinhas.component';
import { EditionComponent } from './glam/editions/edition/edition.component';
import { EditionsComponent } from './glam/editions/editions.component';
import { GlamHomeBannerAlertComponent } from './glam/glam-home/glam-home-banner-alert/glam-home-banner-alert.component';
import { GlamHomeBannerFormComponent } from './glam/glam-home/glam-home-banner-form/glam-home-banner-form.component';
import { GlamHomeCollectionFormComponent } from './glam/glam-home/glam-home-collection-form/glam-home-collection-form.component';
import { GlamHomeSectionComponent } from './glam/glam-home/glam-home-section/glam-home-section.component';
import { GlamHomeComponent } from './glam/glam-home/glam-home.component';
import { LpClubComponent } from './glam/lp-club/lp-club.component';
import { FirstPanelCarouselFormComponent } from './glam/panel/panel-first-carousel/panel-first-carousel-form/panel-first-carousel-form.component';
import { FirstPanelCarouselComponent } from './glam/panel/panel-first-carousel/panel-first-carousel.component';
import { SecondPanelCarouselFormComponent } from './glam/panel/panel-second-carousel/panel-second-carousel-form/panel-second-carousel-form.component';
import { SecondPanelCarouselComponent } from './glam/panel/panel-second-carousel/panel-second-carousel.component';
import { PanelComponent } from './glam/panel/panel.component';
import { VitrineCustomizationComponent } from './glam/vitrine-customization/vitrine-customization.component';
import { WishlistBannersFormComponent } from './glam/wishlist/wishlist-banners/wishlist-banners-form/wishlist-banners-form.component';
import { WishlistBannersComponent } from './glam/wishlist/wishlist-banners/wishlist-banners.component';
import { WishlistThemesFormComponent } from './glam/wishlist/wishlist-themes/wishlist-themes-form/wishlist-themes-form.component';
import { WishlistThemesComponent } from './glam/wishlist/wishlist-themes/wishlist-themes.component';
import { MensHomeComponent } from './mens/mens-home/mens-home.component';
import { GlamBeautyBannerFormComponent } from './own-brands/glam-beauty/glam-beauty-carousel/glam-beauty-banner-form/glam-beauty-banner-form.component';
import { GlamBeautyCarouselComponent } from './own-brands/glam-beauty/glam-beauty-carousel/glam-beauty-carousel.component';
import { MalmoBannerFormComponent } from './own-brands/malmo/malmo-carousel/malmo-banner-form/malmo-banner-form.component';
import { MalmoCarouselComponent } from './own-brands/malmo/malmo-carousel/malmo-carousel.component';
import { MensBannerFormComponent } from './own-brands/mens/mens-carousel/mens-banner-form/mens-banner-form.component';
import { MensCarouselComponent } from './own-brands/mens/mens-carousel/mens-carousel.component';

@NgModule({
  declarations: [
    LpClubComponent,
    LpClubBannersComponent,
    LpClubBannersFormComponent,
    MenuComponent,
    GlamBeautyComponent,
    GlamBeautyCarouselComponent,
    GlamBeautyBannerFormComponent,
    MalmoComponent,
    MalmoCarouselComponent,
    MalmoBannerFormComponent,
    MensComponent,
    MensCarouselComponent,
    MensBannerFormComponent,
    PanelComponent,
    FirstPanelCarouselComponent,
    SecondPanelCarouselComponent,
    FirstPanelCarouselFormComponent,
    SecondPanelCarouselFormComponent,
    VitrineCustomizationComponent,
    EditionsComponent,
    EditionComponent,
    HomeScreenFormComponent,
    HomeScreenComponent,
    CallAndWinFormComponent,
    EarnGlampointsFormComponent,
    PostsFormComponent,
    PostsComponent,
    CallAndWinComponent,
    CallAndWinFormComponent,
    EarnGlampointsComponent,
    WishlistThemesComponent,
    WishlistBannersComponent,
    WishlistThemesFormComponent,
    WishlistBannersFormComponent,
    CustomizationComponentComponent,
    CustomizationComponentBannerComponent,
    CustomizationComponentBannerFormComponent,
    GlamHomeComponent,
    GlamHomeBannerFormComponent,
    GlamHomeCollectionFormComponent,
    GlamHomeSectionComponent,
    MensHomeComponent,
    DmsSectionFormComponent,
    DmsBannerFormComponent,
    GlamHomeBannerAlertComponent,
    CollectionsLojinhasComponent,
    CollectionsLojinhasEditComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextareaModule,
    InputNumberModule,
    InputTextModule,
    ButtonModule,
    CascadeSelectModule,
    CustomizationRoutingModule,
    CalendarModule,
    FileUploadModule,
    CalendarModule,
    ColorPickerModule,
    ToggleButtonModule,
    ListboxModule,
    CalendarModule,
    RadioButtonModule,
    InputTextareaModule,
    ProductImagesModule,
    MessageModule,
    OrderListModule,
    AppTableModule,
    PanelModule,
    AccordionModule,
    DropdownModule,
    TagModule,
    EditorModule,
    ToastModule,
    ConfirmDialogModule,
    GridstackModule,
    CarouselModule,
    FieldsetModule,
    TooltipModule,
    BlockUIModule,
    ImageModule,
    ConfirmPopupModule,
    AutoCompleteModule,
    CardModule,
    CheckboxModule,
    InputSwitchModule,
    PickListModule
  ],
  providers: [ConfirmationService]
})
export class CustomizationModule {}

/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CouponProductChangeDto } from './couponProductChangeDto';

export interface CouponPriceChangeDto {
  couponPriceChangeId?: number;
  couponId?: number;
  value?: number;
  couponType?: number;
  enabled?: boolean;
  dateChange?: Date;
  freebieMessage?: string;
  productVariantSubscriptionTypeIds?: Array<number>;
  subscriptionTypeIds?: Array<number>;
  productVariants?: Array<CouponProductChangeDto>;
}

import { Inject, Injectable } from '@angular/core';
import {
  ShopBanner,
  ShopBrand,
  ShopBrands,
  ShopCarousel,
  ShopCollection,
  ShopSection,
  ShopSettings,
  ShopSettingsRepository
} from '@infrab4a/connect';

import { collection, doc, Firestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ShopSettingsService {
  basePath = 'shopSettings';
  constructor(
    @Inject('ShopSettingsRepository')
    private shopSettingsRepository: ShopSettingsRepository,
    private firestore: Firestore
  ) {}

  async getById(id: string): Promise<Partial<ShopSettings>> {
    return this.shopSettingsRepository.get({ id });
  }

  async update(settings: Partial<ShopSettings>) {
    return this.shopSettingsRepository.update(
      ShopSettings.toInstance(settings)
    );
  }

  static emptyBrand: ShopBrand = {
    brandName: 'Placeholder',
    collectionId: 'placeholder',
    image:
      'https://storage.googleapis.com/b4astage-cb80a.appspot.com/glambox/assets/images/dms/no-img-brand.png',
    slug: 'placeholder'
  };

  createId(): string {
    const collectionRef = collection(this.firestore, this.basePath);
    const { id } = doc(collectionRef);

    return id;
  }
}

export interface CarouselSection extends ShopCarousel, ShopSection {
  type: 'carousel';
}

export function isObject(obj: any) {
  return obj !== null && typeof obj === 'object';
}

export function isCarousel(section: ShopSection): section is CarouselSection {
  return isObject(section) && section.type === 'carousel';
}

export function isBanner(section: ShopSection): section is ShopBanner {
  return isObject(section) && section.type === 'banner';
}

export function isCollection(section: ShopSection): section is ShopCollection {
  return (
    isObject(section) &&
    (section.type === 'collection' || section.type === 'collection premium')
  );
}

export function isBrands(section: ShopSection): section is ShopBrands {
  return isObject(section) && section.type === 'brands';
}

export function isSection(section: ShopSection): section is ShopSection {
  return (
    isObject(section) &&
    (section.type === 'beauty-profile' ||
      section.type === 'benefits' ||
      section.type === 'plans' ||
      section.type === 'newsletter')
  );
}

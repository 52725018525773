/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PersonRelationInfo {
  personId1?: number;
  personId2?: number;
  personRelationTypeId?: number;
  personRelationType?: string;
  dateCreated?: Date;
  firstName?: string;
  lastName?: string;
  name?: string;
  email?: string;
  CPF?: number;
  inviterName?: string;
  inviterEmail?: string;
  inviterCPF?: number;
  cpf?: number;
  subscriptionTypeId?: number;
  subscriptionTypeName?: string;
}

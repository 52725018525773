<div class="card" *ngIf="subscriber">
  <div
    class="card-header"
    [class.card-header--bag]="subscriber.subscriptionId === 5"
    [class.card-header--pass]="subscriber.subscriptionId === 6"
  >
    <a
      type="button"
      [routerLink]="'/users/subscribers/' + subscriber.subscriberId"
    >
      {{ subscriber.boxId }}
    </a>
  </div>
  <div class="card-body">
    <p class="description">
      <strong>Assinatura</strong>:
      {{ subscriber.subscriptionName | titlecase }}
    </p>
    <p class="description">
      <strong>Plano</strong>:
      <ng-container *ngIf="subscriber.subscriptionTypeId !== 70">
        {{ subscriber.subscriptionTypeName }}
      </ng-container>
      <ng-container *ngIf="subscriber.subscriptionTypeId === 70"
        >Anual/mês</ng-container
      >
    </p>
    <p class="description">
      <strong>Edição de início</strong>:
      {{ editionAsDate | date : 'MMMM/yyyy' : '' : 'pt-BR' | titlecase }}
    </p>
    <p class="description">
      <strong>Valor</strong>:
      {{ subscriber.subscriptionPrice | currency : 'BRL' }}
    </p>
    <p class="description">
      <strong>Status</strong>:
      <span
        class="status"
        [class.status--active]="subscriber.subscriberStatus === 0"
      >
        {{ subscriber.status }}
      </span>
    </p>
  </div>
  <div class="card-footer"></div>
</div>

<div class="user">
  <img
    class="avatar"
    [src]="person.urlImageProfile"
    *ngIf="person.urlImageProfile"
  />
  <img
    class="avatar"
    src="assets/images/settings/empty-avatar.png"
    *ngIf="!person.urlImageProfile"
  />
  <div class="info">
    <h5>
      {{ person.name }} {{ person.lastName }}
      <ng-container *ngIf="person.personStatus === 15">(Fraude)</ng-container>
      <i
        class="pi pi-external-link"
        pTooltip="Ver usuário"
        tooltipPosition="bottom"
        (click)="openPersonPage()"
      ></i>
    </h5>
    <h5
      class="influencer"
      *ngIf="influencer"
      [pTooltip]="influencer.description || ''"
      tooltipPosition="bottom"
    >
      {{ influencer.influencerStatus }} ({{
        influencer.enabled ? 'Ativo' : 'Inativo'
      }})
    </h5>
    <h5>
      {{ person.username }}
    </h5>
    <a
      class="instagram"
      *ngIf="person.instagram"
      [attr.href]="'https://instagram.com/' + person.instagram"
      pTooltip="Ver instagram"
      tooltipPosition="right"
      target="_blank"
    >
      <span class="data-link">&#64;{{ person.instagram }}</span>
      <i class="pi pi-instagram"></i>&nbsp;
    </a>
  </div>
</div>

<form class="form-b4a" (submit)="submit()">
  <span
    class="sorting"
    *ngIf="sorting === 'name'"
    (click)="changeSorting('stock')"
  >
    Exibir produtos em estoque no topo
  </span>
  <span
    class="sorting"
    *ngIf="sorting === 'stock'"
    (click)="changeSorting('name')"
  >
    Ordenar por nome do produto
  </span>
  <p-listbox
    [options]="products"
    [formControl]="product"
    [filter]="true"
    optionLabel="productVariantName"
    styleClass="fixed-height"
    filterPlaceHolder="Pesquise pelo Nome, Marca, EAN ou SKU"
    (onFilter)="filter($event)"
    [style]="{ height: '50vh' }"
  >
    <ng-template pTemplate="empty">
      <ng-container *ngIf="loading">
        <i class="pi pi-spinner pi-spin"></i>
      </ng-container>
      <ng-container *ngIf="!loading">
        {{
          searchValid
            ? 'Nenhum item encontrado'
            : 'Digite ao menos 3 caracteres'
        }}
      </ng-container>
    </ng-template>
    <ng-template pTemplate="emptyFilter" let-product>
      <ng-container *ngIf="loading">
        <i class="pi pi-spinner pi-spin"></i>
      </ng-container>
      <ng-container *ngIf="!loading && !products?.length">
        {{
          searchValid
            ? 'Nenhum item encontrado'
            : 'Digite ao menos 3 caracteres'
        }}
      </ng-container>
      <ng-container *ngIf="!loading && products?.length">
        {{ product.productVariantName }}
      </ng-container>
    </ng-template>
  </p-listbox>
  <div class="buttons">
    <p-button
      type="button"
      label="Salvar"
      (onClick)="submit()"
      severity="primary"
      [disabled]="!product.valid"
    />
  </div>
</form>

<app-table
  [cols]="cols"
  [findPage]="findPage"
  [service]="productService"
  modelName="avaliações"
  tableTitle="Avaliações da vitrine"
  [dropdownFilters]="dropdownFilters"
  [actionButtons]="actionButtons"
  [defaultFilterValues]="defaultFilterValues"
  (actionButtonClick)="buttonClick($event)"
  [addButton]="false"
  [changeColumns]="true"
  selectionActionLabel="Validar Avaliações"
  selectionActionTooltip="Aprovar/Reprovar as avaliações selecionadas"
  (emitSelection)="currentReviews = $event"
  [rowSelectable]="showCheckbox"
  [rowBackground]="rowBackground"
>
</app-table>
<p-dialog
  *ngIf="currentReview"
  [(visible)]="currentReview"
  [modal]="true"
  [style]="{ width: '600px' }"
  header="Detalhes da Avaliação"
>
  <div class="reviewDialog">
    <h2 class="title">{{ currentReview.reviewTitle }}</h2>
    <div class="review">{{ currentReview.review }}</div>
    <p-rating
      [(ngModel)]="currentReview.rating"
      [stars]="5"
      [readonly]="true"
      [cancel]="false"
    >
      <ng-template pTemplate="onicon">
        <i class="pi pi-star-fill"></i>
      </ng-template>
      <ng-template pTemplate="officon">
        <i class="pi pi-star"></i>
      </ng-template>
    </p-rating>
    <div class="buttons" *ngIf="currentReview.reviewStatus === 1">
      <p-button
        label="Aprovar"
        styleClass="p-button-success"
        (onClick)="approveReview(currentReview)"
      >
      </p-button>
      <p-button
        label="Reprovar"
        styleClass="p-button-danger"
        (onClick)="denyReview(currentReview)"
      >
      </p-button>
      <p-button
        label="Enviar para IA"
        styleClass="p-button-primary"
        (onClick)="llmProductReview(currentReview)"
      >
      </p-button>
    </div>
  </div>
</p-dialog>
<p-dialog
  *ngIf="currentReviews"
  [(visible)]="currentReviews"
  [modal]="true"
  [style]="{ width: '80%' }"
  header="Avaliações selecionadas"
  ><app-table
    [lazy]="false"
    [elements]="currentReviews"
    [cols]="modalColumns"
    [showHeader]="false"
    modelName="Avaliações Selecionadas"
  ></app-table>
  <div class="buttons">
    <p-button
      label="Aprovar"
      styleClass="p-button-success"
      (onClick)="modifyReviews(true, currentReviews)"
    >
    </p-button>
    <p-button
      label="Reprovar"
      styleClass="p-button-danger"
      (onClick)="modifyReviews(false, currentReviews)"
    >
    </p-button>
    <p-button
      label="Enviar para IA"
      styleClass="p-button-primary"
      (onClick)="llmModifyReviews(true, currentReviews)"
      icon="pi pi-desktop"
      iconPos="left"
    >
    </p-button>
  </div>
</p-dialog>
<p-toast></p-toast>

<div
  class="edition-tracker"
  *ngIf="
    subscriber?.subscriptionId && subscriber?.subscriptionTypeInstallments !== 1
  "
>
  <p-timeline [value]="editionsForTracker" layout="horizontal" align="top">
    <ng-template pTemplate="marker" let-event>
      <span class="custom-marker" [class.custom-marker--green]="event.received">
        <i class="pi pi-check" style="color: white" *ngIf="event.received"></i>
        <i
          class="pi pi-circle"
          style="color: white"
          *ngIf="!event.received"
        ></i>
      </span>
    </ng-template>
    <ng-template pTemplate="content" let-event>
      {{ event.edition | titlecase }}
    </ng-template>
  </p-timeline>
</div>
<div class="show-hide" *ngIf="oldEditions?.length">
  <a type="button" class="data-link" (click)="allExpanded = !allExpanded">
    <i
      class="pi"
      [class.pi-chevron-circle-down]="!allExpanded"
      [class.pi-chevron-circle-up]="allExpanded"
    ></i>
    {{ allExpanded ? 'Ocultar produtos' : 'Exibir produtos' }}
  </a>
</div>
<ng-container *ngFor="let edition of oldEditions">
  <app-composition-details
    [edition]="edition"
    [expanded]="allExpanded"
    [isDesktop]="isDesktop"
    (editionChanged)="editionChanged.emit($event)"
  ></app-composition-details>
</ng-container>
<h5 *ngIf="!compositions?.length" class="empty">
  {{ person?.name }} ainda não possui edições nesta assinatura
</h5>

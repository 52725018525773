import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { ChartModule } from 'primeng/chart';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { AppTableModule } from 'src/app/components/table/app-table.module';
import { AcquisitionAndReactivationReprocessComponent } from './acqusition-and-reactivation-reprocess/acquisition-and-reactivation-reprocess.component';
import { ActiveSubscriptionNewComponent } from './active-subscription-new/active-subscription-new.component';
import { CanceledWithPendingBillingModalComponent } from './canceled-with-pending-billing/canceled-with-pending-billing-modal/canceled-with-pending-billing-modal.component';
import { CanceledWithPendingBillingComponent } from './canceled-with-pending-billing/canceled-with-pending-billing.component';
import { CardDifferenceModalComponent } from './card-difference/card-difference-modal/card-difference-modal.component';
import { CardDifferenceComponent } from './card-difference/card-difference.component';
import { CardOtherSubscriptionTypesComponent } from './card-other-subscription/card-other-subscription.component';
import { DatabaseSessionsComponent } from './database-sessions/database-sessions.component';
import { DontReceiveGiftComponent } from './dont-receive-gift/dont-receive-gift.component';
import { DoubleCommissionComponent } from './double-commission/double-commission.component';
import { DuplicatedRewardComponent } from './duplicated-reward/duplicated-reward.component';
import { FailedPaymentDataComponent } from './failed-payment-data/failed-payment-data.component';
import { IncorrectEndEditionModalComponent } from './incorrect-end-edition/incorrect-end-edition-modal/incorrect-end-edition-modal.component';
import { IncorrectEndEditionComponent } from './incorrect-end-edition/incorrect-end-edition.component';
import { IncorrectPendingBillingModalComponent } from './incorrect-pending-billing/incorrect-pending-billing-modal/incorrect-pending-billing-modal.component';
import { IncorrectPendingBillingComponent } from './incorrect-pending-billing/incorrect-pending-billing.component';
import { IncorrectSubscriberStatusComponent } from './incorrect-subscriber-status/incorrect-subscriber-status.component';
import { InfolessAddressComponent } from './infoless-address/infoless-address.component';
import { ItOpsRoutingModule } from './it-ops-routing.module';
import { ItOpsComponent } from './it-ops.component';
import { LogsComponent } from './logs/logs.component';
import { MuchPendingBillingsComponent } from './much-pending-billings/much-pending-billings.component';
import { NotAttemptedPaymentComponent } from './not-attempted-payment/not-attempted-payment.component';
import { NotPendingRecurrenceComponent } from './not-pending-recurrence/not-pending-recurrence.component';
import { NullAddressComponent } from './null-address/null-address.component';
import { PaidBillingSameMonthComponent } from './paid-billing-same-month/paid-billing-same-month.component';
import { PaymentSameMonthPaymentComponent } from './payment-same-month-payment/payment-same-month-payment.component';
import { PaymentWithoutBillingComponent } from './payment-without-billing/payment-without-billing.component';
import { PendingWhithoutRecurrenceComponent } from './pending-whithout-recurrence/pending-whithout-recurrence.component';
import { RecurrenceIsNullComponent } from './recurrence-is-null/recurrence-is-null.component';
import { RenewalReprocessComponent } from './renewal-reprocess/renewal-reprocess.component';
import { RenewalWrongPriceComponent } from './renewal-wrong-price/renewal-wrong-price.component';
import { TwoSubscriptionsInOneCpfComponent } from './two-subscriptions-in-one-cpf/two-subscriptions-in-one-cpf.component';
import { WrongRecurrenceDateModalComponent } from './wrong-recurrence-date/wrong-recurrence-date-modal/wrong-recurrence-date-modal.component';
import { WrongRecurrenceDateComponent } from './wrong-recurrence-date/wrong-recurrence-date.component';

@NgModule({
  imports: [
    InputTextareaModule,
    CommonModule,
    ItOpsRoutingModule,
    ToastModule,
    AppTableModule,
    RouterModule,
    TooltipModule,
    ButtonModule,
    ConfirmDialogModule,
    InputTextareaModule,
    FormsModule,
    DynamicDialogModule,
    ChartModule,
    DropdownModule
  ],
  declarations: [
    ItOpsComponent,
    PaymentSameMonthPaymentComponent,
    PaidBillingSameMonthComponent,
    NotPendingRecurrenceComponent,
    WrongRecurrenceDateComponent,
    PendingWhithoutRecurrenceComponent,
    MuchPendingBillingsComponent,
    CanceledWithPendingBillingComponent,
    IncorrectPendingBillingComponent,
    FailedPaymentDataComponent,
    ActiveSubscriptionNewComponent,
    IncorrectSubscriberStatusComponent,
    CardOtherSubscriptionTypesComponent,
    DontReceiveGiftComponent,
    NotAttemptedPaymentComponent,
    IncorrectPendingBillingModalComponent,
    WrongRecurrenceDateModalComponent,
    DatabaseSessionsComponent,
    CanceledWithPendingBillingModalComponent,
    RecurrenceIsNullComponent,
    DoubleCommissionComponent,
    DuplicatedRewardComponent,
    CardDifferenceComponent,
    IncorrectEndEditionComponent,
    CardDifferenceModalComponent,
    IncorrectEndEditionModalComponent,
    NullAddressComponent,
    TwoSubscriptionsInOneCpfComponent,
    AcquisitionAndReactivationReprocessComponent,
    RenewalReprocessComponent,
    InfolessAddressComponent,
    PaymentWithoutBillingComponent,
    RenewalReprocessComponent,
    RenewalWrongPriceComponent,
    LogsComponent
  ]
})
export class ItOpsModule {}

<form (submit)="changePassword()" [formGroup]="passwordForm">
  <span class="p-float-label form-control" *ngIf="!modal">
    <input
      type="password"
      pInputText
      formControlName="oldPassword"
      id="oldPassword"
    />
    <label for="oldPassword">Senha atual</label>
  </span>
  <span class="p-float-label form-control">
    <input
      type="password"
      pInputText
      formControlName="newPassword"
      id="password"
    />
    <label for="password">Nova senha</label>
  </span>
  <span class="p-float-label form-control">
    <input
      type="password"
      pInputText
      formControlName="newPasswordConfirmation"
      id="passwordConfirmation"
    />
    <label for="passwordConfirmation">Confirmação de senha</label>
  </span>
  <p-button
    label="Alterar"
    type="submit"
    [disabled]="!passwordForm.valid"
    severity="danger"
  >
  </p-button>
</form>
<p-toast></p-toast>

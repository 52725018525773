import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { lastValueFrom, map } from 'rxjs';
import {
  PageableRequest,
  SubscriberControllerService,
  SubscriberInfo
} from 'src/app/admin-api';
import { PageContent, TableColumn } from 'src/app/components/table';
import { getAllSubscriptions } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-subscribers',
  templateUrl: './subscribers.component.html',
  styleUrls: ['./subscribers.component.scss'],
  providers: [DatePipe]
})
export class SubscribersComponent implements OnInit {
  cols = [
    new TableColumn(
      'SubId',
      'subscriberId',
      true,
      'number',
      '',
      'subscriberId'
    ),
    new TableColumn(
      'GlamId',
      'boxId',
      true,
      'text',
      '',
      'subscriberId',
      true,
      'equals'
    ),
    new TableColumn(
      'Assinatura',
      'subscriptionName',
      true,
      'text',
      undefined,
      undefined,
      true,
      'equals'
    ),
    new TableColumn(
      'Plano',
      'subscriptionTypeName',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Nome',
      'shippingName',
      true,
      'text',
      '/users/person/',
      'personId',
      true,
      'contains'
    ),
    new TableColumn(
      'Cidade',
      'shippingCity',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'UF',
      'shippingState',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Criação',
      'dateCreated',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    ),
    new TableColumn.Builder()
      .setHeader('Status')
      .setField('status')
      .setType('status')
      .setStatusFunction((s: SubscriberInfo) =>
        [10, 11, 12].includes(s.subscriberStatus)
          ? 0
          : s.subscriberStatus
          ? -1
          : 1
      )
      .build(),
    new TableColumn(
      'Status',
      'status',
      true,
      'text',
      undefined,
      undefined,
      true,
      'equals'
    )
  ];
  dropdownFilters:
    | { [key: string]: Array<{ label: string; value: string }> }
    | undefined;
  constructor(
    public subscriberService: SubscriberControllerService,
    private title: Title
  ) {
    this.title.setTitle('Assinaturas');
  }

  async ngOnInit(): Promise<void> {
    await this.findSubscriberStatusList();
  }

  async findPage(
    request: PageableRequest,
    service: unknown
  ): Promise<PageContent<SubscriberInfo>> {
    const filterStatus = request.filters?.find((f) => f.field === 'status');
    if (filterStatus?.value) {
      if (Number(filterStatus.value) < 0) {
        filterStatus.value = '';
      }
      request.filters?.push({
        condition: 'equals',
        field: 'subscriberStatus',
        fieldType: 'number',
        value: filterStatus.value
      });
    }
    const filterSubscription = request.filters?.find(
      (f) => f.field === 'subscriptionName'
    );
    if (filterSubscription?.value) {
      if (Number(filterSubscription.value) < 0) {
        filterSubscription.value = '';
      }
      request.filters?.push({
        condition: 'equals',
        field: 'subscriptionId',
        fieldType: 'number',
        value: filterSubscription.value
      });
    }
    return lastValueFrom(
      (service as SubscriberControllerService)
        .listSubscriberTable(request)
        .pipe(map((data) => data.result as PageContent<SubscriberInfo>))
    );
  }

  async findSubscriberStatusList(): Promise<void> {
    try {
      const statusList = await lastValueFrom(
        this.subscriberService
          .findSubscriberStatusList()
          .pipe(map((data) => data.result))
      );
      if (statusList) {
        this.dropdownFilters = {
          status: [{ label: 'Todos', value: '-1' }].concat(
            statusList.map((s) => ({
              label: s.status as string,
              value: s.subscriberStatusId?.toString() as string
            }))
          ),
          subscriptionName: [
            {
              label: 'Todas',
              value: '-1'
            }
          ].concat(
            getAllSubscriptions().map((s) => ({
              label: s.label,
              value: s.value.toString()
            }))
          )
        };
      }
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
  }
}

import { DatePipe } from '@angular/common';
import {
  Component,
  OnInit,
  ViewChild,
  WritableSignal,
  inject,
  signal
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { NgxPermissionsService } from 'ngx-permissions';
import { ConfirmationService, MessageService, PrimeIcons } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  PageableRequest,
  SubscriberControllerService,
  SubscriberRefundSummary
} from 'src/app/admin-api';
import { FinancialAdminControllerService } from 'src/app/admin-api/api/financialAdminController.service';
import {
  PageContent,
  TableActionButton,
  TableColumn,
  TableComponent
} from 'src/app/components/table';
import { Role, roleAsObject } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';
import { SubscriberRefundDetailsModalComponent } from './subscriber-refund-details-modal/subscriber-refund-details-modal.component';

@Component({
  selector: 'app-subscriber-refunds',
  templateUrl: './subscriber-refunds.component.html',
  styleUrls: ['./subscriber-refunds.component.scss'],
  providers: [ConfirmationService, MessageService, DatePipe, DialogService]
})
export class SubscriberRefundsComponent implements OnInit {
  @ViewChild(TableComponent) table: TableComponent;

  public financialAdminControllerService = inject(
    FinancialAdminControllerService
  );
  public subscriberService = inject(SubscriberControllerService);
  private messageService = inject(MessageService);
  private dialog = inject(DialogService);
  private confirmationService = inject(ConfirmationService);
  private subscriberController = inject(SubscriberControllerService);
  private ngxPermissionsService = inject(NgxPermissionsService);
  private datePipe = inject(DatePipe);
  private title = inject(Title);

  originalCols: Array<TableColumn> = [
    new TableColumn(
      'SubscriberId',
      'subscriberId',
      true,
      'number',
      '/users/subscribers/',
      'subscriberId'
    ),
    new TableColumn(
      'GlamID',
      'boxId',
      true,
      'number',
      '/users/subscribers/',
      'subscriberId'
    ),
    new TableColumn(
      'Primeiro Nome',
      'firstName',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Sobrenome',
      'lastName',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Edição',
      'theme',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Cidade',
      'city',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Valor',
      'amount',
      true,
      'currency',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn.Builder()
      .setHeader('Solicitante')
      .setField('username')
      .build(),
    new TableColumn(
      'Criação',
      'dateCreated',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    )
  ];
  statusList = [
    { label: 'Pendente', value: 0 },
    { label: 'Aprovado', value: 1 }
  ];
  referenceForm = new FormGroup({
    status: new FormControl(0, Validators.required)
  });
  actionButtons = [
    new TableActionButton(
      '',
      'viewDetails',
      PrimeIcons.EYE,
      () => true,
      '',
      'Visualizar obversações',
      'bottom',
      false,
      true,
      'priamry',
      'small'
    ),
    new TableActionButton(
      '',
      'deleteRefund',
      PrimeIcons.TRASH,
      (refund: SubscriberRefundSummary) =>
        !refund.hasBeenApproved && !refund.hasRefunded,
      '',
      'Deletar',
      'bottom',
      false,
      true,
      'danger',
      'small'
    )
  ];
  cols: WritableSignal<Array<TableColumn>> = signal(undefined);
  permission = false;
  startOfMonth: Date;
  defaultFilters = {};

  async ngOnInit(): Promise<void> {
    this.title.setTitle('Estornos');
    this.permission = await this.ngxPermissionsService.hasPermission([
      roleAsObject(Role.Full_Administrator).enumValue,
      roleAsObject(Role.Financial).enumValue
    ]);
    this.startOfMonth = new Date();
    this.startOfMonth.setDate(1);
    this.resetColumns();
  }

  resetColumns(append?: Array<TableColumn>): void {
    const cols = [...this.originalCols].concat(append || []);
    if (this.permission) {
      cols.unshift(new TableColumn('', '', false, 'checkbox'));
      cols.push(new TableColumn('Ação', '', false, 'button'));
    }
    this.cols.set(cols);
  }

  approveAllRefunds(elements: Array<SubscriberRefundSummary>): void {
    this.confirmationService.confirm({
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      rejectButtonStyleClass: 'p-button-danger',
      message: 'Deseja aprovar o(s) estorno(s)?',
      header: 'Estornar',
      accept: async () => {
        LoaderService.showLoader();
        try {
          await lastValueFrom(
            this.financialAdminControllerService.approveAllRefunds(
              elements.map((e) => e.subscriberBillingId)
            )
          );
          await this.table.refresh(true);
          this.messageService.add({
            summary: 'Sucesso',
            detail: elements.length + ' Aprovado(s) com sucesso',
            severity: 'success'
          });
          LoaderService.showLoader(false);
        } catch (error) {
          LoaderService.showLoader(false);
          AppDialogService.showErrorDialog(error);
        }
      }
    });
  }

  callAction(event: {
    item: SubscriberRefundSummary;
    $event: Event;
    action: string;
  }) {
    if (event.action === 'deleteRefund')
      this.confirmationService.confirm({
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        rejectButtonStyleClass: 'p-button-danger',
        message: 'Deseja deletar o estorno?',
        header: 'Deletar',
        accept: async () => {
          LoaderService.showLoader();
          try {
            await lastValueFrom(
              this.subscriberController.removeSubscriberRefund(
                event.item.subscriberBillingId
              )
            );
            await this.table.refresh(true);
            this.messageService.add({
              summary: 'Sucesso',
              detail: 'Estorno excluído com sucesso',
              severity: 'success'
            });
            LoaderService.showLoader(false);
          } catch (error) {
            LoaderService.showLoader(false);
            AppDialogService.showErrorDialog(error);
          }
        }
      });
    else {
      this.dialog.open(SubscriberRefundDetailsModalComponent, {
        header: 'Estorno ' + event.item.boxId + ' - ' + event.item.theme,
        data: {
          refund: event.item
        }
      });
    }
  }

  setFilters() {
    this.referenceForm.controls['status'].markAsUntouched();
    this.referenceForm.controls['status'].markAsPristine();
    if (this.referenceForm.value.status)
      this.defaultFilters = { dateCreated: this.dateDefaultFilter };
    else this.defaultFilters = {};
    this.cols.set(undefined);
    setTimeout(() => {
      if (this.referenceForm.value.status) {
        this.resetColumns([
          new TableColumn(
            'Data do Estorno',
            'dateRefunded',
            true,
            'date',
            undefined,
            undefined,
            true,
            'between'
          )
        ]);
      } else {
        this.resetColumns();
      }
    });
  }

  findPage = async (
    request: PageableRequest
  ): Promise<PageContent<SubscriberRefundSummary>> => {
    request.filters.push({
      condition: 'equals',
      field: 'hasBeenApproved',
      fieldType: 'number',
      value: this.referenceForm.value.status.toString()
    });
    if (
      this.referenceForm.value.status &&
      (this.referenceForm.controls['status'].untouched ||
        this.referenceForm.controls['status'].pristine)
    ) {
      const filter = request.filters.find((f) => f.field === 'dateCreated');
      if (filter) filter.value = this.dateDefaultFilter;
      else
        request.filters.push({
          condition: 'between',
          field: 'dateCreated',
          fieldType: 'date',
          value: this.dateDefaultFilter
        });
    }
    return await lastValueFrom(
      this.subscriberService.findRefundsList(request).pipe(
        map((data) => ({
          ...data.result,
          content: data.result.content.map((r) => ({
            ...r,
            city: r.city + ' - ' + r.state
          }))
        }))
      )
    );
  };

  get dateDefaultFilter(): Array<Date> {
    return [
      FormUtil.utcDate(
        this.datePipe.transform(this.startOfMonth, 'yyyy-MM-dd')
      ),
      FormUtil.utcDate(
        new Date(
          this.datePipe.transform(FormUtil.utcDate(new Date()), 'yyyy-MM-dd')
        )
      )
    ];
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ProductDiffModalComponent } from './product-diff-modal.component';

@NgModule({
  declarations: [ProductDiffModalComponent],
  imports: [
    CommonModule,
    AccordionModule,
    CardModule,
    RadioButtonModule,
    ReactiveFormsModule,
    ButtonModule
  ],
  exports: [ProductDiffModalComponent]
})
export class ProductDiffModalModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { RatingModule } from 'primeng/rating';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { CouponFormModule } from 'src/app/components/coupon-form/coupon-form.module';
import { GridstackModule } from 'src/app/components/gridstack/gridstack.module';
import { ShopCouponFormModule } from 'src/app/components/shop-coupon-form/shop-coupon-form.module';
import { AppTableModule } from 'src/app/components/table/app-table.module';
import { Buy2WinComponent } from './buy-2-win/buy-2-win.component';
import { CampaignDetailsComponent } from './buy-2-win/campaign-details/campaign-details.component';
import { CouponChangeScheduleModalComponent } from './coupons/coupon-detail/coupon-change-schedule-modal/coupon-change-schedule-modal.component';
import { CouponDetailComponent } from './coupons/coupon-detail/coupon-detail.component';
import { CouponProductVariantsComponent } from './coupons/coupon-detail/coupon-product-variants/coupon-product-variants.component';
import { CouponsComponent } from './coupons/coupons.component';
import { EmailRestrictionFormComponent } from './coupons/email-restriction-form/email-restriction-form.component';
import { MarketingRoutingModule } from './marketing-routing.module';
import { ShopCouponDetailComponent } from './shop-coupons/shop-coupon-detail/shop-coupon-detail.component';
import { ShopCouponsComponent } from './shop-coupons/shop-coupons.component';

@NgModule({
  declarations: [
    CouponsComponent,
    CouponDetailComponent,
    EmailRestrictionFormComponent,
    CouponChangeScheduleModalComponent,
    CouponProductVariantsComponent,
    ShopCouponsComponent,
    ShopCouponDetailComponent,
    Buy2WinComponent,
    CampaignDetailsComponent
  ],
  providers: [ConfirmationService],
  imports: [
    CommonModule,
    MarketingRoutingModule,
    CouponFormModule,
    AppTableModule,
    TabViewModule,
    GridstackModule,
    ButtonModule,
    RatingModule,
    FormsModule,
    TooltipModule,
    InputNumberModule,
    CheckboxModule,
    DialogModule,
    ToastModule,
    ConfirmDialogModule,
    FileUploadModule,
    ChipsModule,
    ReactiveFormsModule,
    ConfirmPopupModule,
    DropdownModule,
    CalendarModule,
    DropdownModule,
    InputSwitchModule,
    PanelModule,
    CardModule,
    AutoCompleteModule,
    ConfirmPopupModule,
    FieldsetModule,
    InputTextareaModule,
    InputMaskModule,
    ShopCouponFormModule,
    MultiSelectModule,
    ChipModule
  ]
})
export class MarketingModule {}

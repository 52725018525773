<div class="page-title">Pesquisa - Formulários</div>
<div class="card">
  <p-tabView
    [activeIndex]="selectedTab"
    (activeIndexChange)="tabChanged($event)"
    [scrollable]="true"
  >
    <p-tabPanel header="Formulários de feedback">
      <app-feedback-forms></app-feedback-forms>
    </p-tabPanel>
    <p-tabPanel header="Produtos alocados em edições">
      <ng-template pTemplate="content">
        <app-table
          #productsTable
          *ngIf="productCols"
          [service]="feedbackService"
          [findPage]="findPage"
          [cols]="productCols"
          [dropdownFilters]="dropdownFilters"
          [defaultFilterValues]="defaultFilterValues"
          [addButton]="false"
          modelName="produtos"
          [sortOrder]="1"
          sortField="productId"
          selectionActionLabel="Clonar Formulário"
          (emitSelection)="currentProducts = $event"
        />
      </ng-template>
      <p-dialog
        *ngIf="currentProducts"
        [(visible)]="currentProducts"
        [modal]="true"
        header="Formulário base"
      >
        <form class="form-b4a">
          <span class="p-float-label">
            <p-autoComplete
              [suggestions]="formsAvailable"
              [formControl]="baseForm"
              (completeMethod)="findFeedbackForms($event.query)"
              [inputId]="'formId' + i"
              [name]="'formId' + i"
              placeholder="Pesquise pelo nome"
              filterBy="formTitle"
              optionLabel="formTitle"
              appendTo="body"
              [showClear]="true"
            >
              <ng-template let-form pTemplate="item">
                {{ form.formTitle }}
              </ng-template>
            </p-autoComplete>
            <label [for]="'formId' + i">Formulário</label>
          </span>
          <p-button
            [disabled]="baseForm.invalid"
            label="Clonar formulário"
            styleClass="p-button-success"
            (onClick)="cloneForm()"
          >
          </p-button>
        </form>
        <app-table
          [lazy]="false"
          [elements]="currentProducts"
          [cols]="modalProductsColumns"
          [showHeader]="false"
          modelName="Produtos selecionados"
        ></app-table>
      </p-dialog>
    </p-tabPanel>
    <p-tabPanel header="Formulários ativos"
      ><ng-template pTemplate="content">
        <app-table
          #activeFormsTable
          *ngIf="formsCols"
          [service]="feedbackService"
          pageFunctionName="findAdminActiveForms"
          [cols]="formsCols"
          [addButton]="false"
          modelName="formulários"
          [sortOrder]="1"
          sortField="formId"
          dataKey="formId"
          selectionActionLabel="Desvincular Formulário(s)"
          (emitSelection)="currentForms = $event"
        />
      </ng-template>
      <p-dialog
        *ngIf="currentForms"
        [(visible)]="currentForms"
        [modal]="true"
        [style]="{ width: '80%' }"
        header="Formulários selecionados"
        ><app-table
          [lazy]="false"
          [elements]="currentForms"
          [cols]="modalFormsColumns"
          [showHeader]="false"
          modelName="Formulários selecionados"
        ></app-table>
        <div class="buttons">
          <p-button
            label="Desvincular formulário(s)"
            styleClass="p-button-success"
            (onClick)="disableForms(currentForms)"
          >
          </p-button>
        </div>
      </p-dialog>
    </p-tabPanel>
  </p-tabView>
</div>
<p-toast></p-toast>

<form class="form-b4a" [formGroup]="form" (submit)="submit()">
  <span class="file-upload full-w">
    <span
      class="file-upload logo"
      [formGroupName]="shop"
      *ngFor="let shop of shops"
    >
      <label [for]="'image_' + shop">
        Imagem principal {{ getShop(shop)?.label }}
        <img [src]="form?.value[shop]?.image || defaultImg" />
      </label>
      <span class="buttons">
        <p-fileUpload
          mode="basic"
          chooseLabel="Selecionar"
          [auto]="true"
          [name]="'image_' + shop"
          accept=".jpeg,.jpg,.png,.gif"
          [maxFileSize]="1000000"
          uploadIcon="pi pi-file"
          [customUpload]="true"
          (uploadHandler)="
            onUpload(
              $event,
              shop + '.image',
              getFileUploadElement('image_' + shop)
            )
          "
          [id]="'image_' + shop"
        />
        <p-button
          label="Utilizar em todas"
          severity="help"
          pTooltip="Utilizar esta imagem em todas as lojas"
          *ngIf="form?.value[shop]?.image && imageDiff('image')"
          (onClick)="copyImages('image', shop)"
        />
      </span>
    </span>
  </span>
  <span class="file-upload full-w">
    <span
      class="file-upload logo"
      [formGroupName]="shop"
      *ngFor="let shop of shops"
    >
      <label [for]="'brandBanner_' + shop">
        Banner {{ getShop(shop)?.label }}
        <img [src]="form?.value[shop]?.brandBanner || defaultImg" />
      </label>
      <span class="buttons">
        <p-fileUpload
          mode="basic"
          chooseLabel="Selecionar"
          [auto]="true"
          [name]="'brandBanner_' + shop"
          accept=".jpeg,.jpg,.png,.gif"
          [maxFileSize]="1000000"
          uploadIcon="pi pi-file"
          [customUpload]="true"
          (uploadHandler)="
            onUpload(
              $event,
              shop + '.brandBanner',
              getFileUploadElement('brandBanner_' + shop)
            )
          "
          [id]="'brandBanner_' + shop"
        />
        <p-button
          label="Utilizar em todas"
          severity="help"
          pTooltip="Utilizar esta imagem em todas as lojas"
          *ngIf="form.value[shop]?.brandBanner && imageDiff('brandBanner')"
          (onClick)="copyImages('brandBanner', shop)"
        />
      </span>
    </span>
  </span>
  <span class="file-upload full-w">
    <span
      class="file-upload logo"
      [formGroupName]="shop"
      *ngFor="let shop of shops"
    >
      <label [for]="'brandBannerMobile_' + shop">
        Banner mobile {{ getShop(shop)?.label }}
        <img [src]="form?.value[shop]?.brandBannerMobile || defaultImg" />
      </label>
      <span class="buttons">
        <p-fileUpload
          mode="basic"
          chooseLabel="Selecionar"
          [auto]="true"
          [name]="'brandBannerMobile_' + shop"
          accept=".jpeg,.jpg,.png,.gif"
          [maxFileSize]="1000000"
          uploadIcon="pi pi-file"
          [customUpload]="true"
          (uploadHandler)="
            onUpload(
              $event,
              shop + '.brandBannerMobile',
              getFileUploadElement('brandBannerMobile_' + shop)
            )
          "
          [id]="'brandBannerMobile_' + shop"
        />
        <p-button
          label="Utilizar em todas"
          severity="help"
          pTooltip="Utilizar esta imagem em todas as lojas"
          *ngIf="
            form.value[shop]?.brandBannerMobile &&
            imageDiff('brandBannerMobile')
          "
          (onClick)="copyImages('brandBannerMobile', shop)"
        />
      </span>
    </span>
  </span>

  <div class="form-footer">
    <div class="info">
      <div *ngIf="category">
        <p>
          *Última alteração em:
          {{ category.updatedAt | date : 'short' }}
        </p>
      </div>
    </div>
    <div class="action">
      <p-button
        type="submit"
        pRipple
        label="Salvar"
        severity="primary"
        [disabled]="disableButton"
      />
    </div>
  </div>
</form>

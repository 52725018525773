import {
  ChangeDetectorRef,
  Component,
  inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import BlotFormatter from '@enzedonline/quill-blot-formatter2';
import { Category, Product } from '@infrab4a/connect';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Editor } from 'primeng/editor';
import Quill from 'quill';
import { lastValueFrom, map } from 'rxjs';
import * as beautify from 'simply-beautiful';
import {
  FileControllerService,
  Live,
  LiveContent,
  LiveControllerService
} from 'src/app/admin-api';
import { ShopCategoryService } from 'src/app/connect-api/api/shop/shop-category.service';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-live-content-form',
  templateUrl: './live-content-form.component.html',
  styleUrl: './live-content-form.component.scss',
  providers: [MessageService]
})
export class LiveContentFormComponent implements OnInit {
  @ViewChild(Editor) editor: Editor;

  ref = inject(DynamicDialogRef);

  live: Live = inject(DynamicDialogConfig).data?.live;
  permission: boolean = inject(DynamicDialogConfig).data?.permission || false;
  block: LiveContent = inject(DynamicDialogConfig).data?.block;
  type: 'collection' | 'text' =
    inject(DynamicDialogConfig).data?.type || 'text';
  order: number = inject(DynamicDialogConfig).data?.order || 1;
  categories = (
    inject(DynamicDialogConfig).data?.categories as Partial<Category>[]
  )
    .map((c) => ({
      ...c,
      name: `${c.reference ? c.reference + ' - ' : ''}${c.name}`
    }))
    .sort((c1, c2) => c1.name.localeCompare(c2.name));
  fileService = inject(FileControllerService);
  messageService = inject(MessageService);
  cdRef = inject(ChangeDetectorRef);
  liveService = inject(LiveControllerService);
  shopCategoryService = inject(ShopCategoryService);

  form = new FormGroup({
    html: new FormControl(
      { value: this.block?.html || '', disabled: this.type === 'collection' },
      [Validators.required]
    ),
    HTML: new FormControl(false),
    collectionId: new FormControl(
      {
        value: this.block?.collectionId || null,
        disabled: this.type === 'text'
      },
      [Validators.required, Validators.minLength(1)]
    ),
    order: new FormControl(this.block?.contentOrder || this.order, [
      Validators.required,
      Validators.min(1)
    ])
  });

  toolbarOptions = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: [] }],
    [
      'bold',
      'italic',
      'underline',
      'strike',
      { script: 'sub' },
      { script: 'super' },
      'code-block'
    ], // toggled buttons
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    ['link', 'image'],

    [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }]
  ];
  handlers = {
    image: this.handleImage
  };
  collection: Partial<Category>;
  products: Partial<Product>[];
  totalProducts: number;

  async ngOnInit(): Promise<void> {
    Quill.register('modules/blotFormatter2', BlotFormatter);
    await this.categoryChange();
  }

  format() {
    this.form.controls.html.setValue(
      beautify.html(this.form.value.html, { indent_size: 2 })
    );
  }

  handleImage() {
    document.getElementById('inputFile').click();
  }

  async handleFile($event: Event) {
    try {
      LoaderService.showLoader();
      const link = await lastValueFrom(
        this.fileService
          .uploadFileForm(
            this.filePath.concat('assets/'),
            ($event.target as any).files[0],
            ($event.target as any).files[0].name
          )
          .pipe(map((data) => data.result))
      );
      this.form.controls.html.setValue(
        this.form.value.html + `<p><img src="${link}" /></p>`
      );
      this.editor.writeValue(this.form.value.html);
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso',
        detail: 'Imagem adicionada ao final do texto.'
      });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  addClassToImages() {
    (this.editor?.getQuill() as Quill)?.container
      .querySelectorAll('img')
      .forEach((i) => {
        if (!i.className?.includes('dimensioned'))
          i.classList.add('dimensioned');
      });

    this.form.get('html').setValue(this.form.value.html);
  }

  async submit() {
    if (this.form.valid) this.ref.close(this.form.value);
  }

  async categoryChange() {
    if (this.form.controls.collectionId.valid)
      try {
        LoaderService.showLoader();
        this.collection = await this.shopCategoryService.getCategoryById(
          this.form.value.collectionId
        );
        const response = (
          await this.shopCategoryService.getCategoryProductsPage(
            this.collection,
            4
          )
        ).products;
        this.products = response.data;
        this.totalProducts = response.total;
      } catch (error) {
        delete this.collection;
        delete this.products;
        this.form.controls.collectionId.reset();
        AppDialogService.showErrorDialog(error);
      } finally {
        LoaderService.showLoader(false);
      }
  }

  get filePath() {
    return '/admin/lives/';
  }
}

import { Pageable, Sort } from '../../../admin-api';

export interface PageContent<T> {
  totalPages?: number;
  totalElements?: number;
  size?: number;
  content?: Array<T>;
  number?: number;
  sort?: Sort;
  first?: boolean;
  last?: boolean;
  numberOfElements?: number;
  pageable?: Pageable;
  empty?: boolean;
}

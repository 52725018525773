import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TooltipModule } from 'primeng/tooltip';
import { AppTableModule } from '../table/app-table.module';
import { BeautyprofileHistoryComponent } from './beautyprofile-history/beautyprofile-history.component';
import { PersonBeautyprofileComponent } from './person-beautyprofile.component';

@NgModule({
  declarations: [PersonBeautyprofileComponent, BeautyprofileHistoryComponent],
  imports: [CommonModule, AppTableModule, TooltipModule],
  exports: [PersonBeautyprofileComponent, BeautyprofileHistoryComponent]
})
export class PersonBeautyprofileModule {}

<app-table
  [lazy]="false"
  [elements]="allowedUsers"
  [addButtonAction]="true"
  (addButtonClick)="addNewUser()"
  addLabel="Novo usuário"
  [cols]="cols"
  [exportExcel]="false"
  [exportPdf]="false"
  *ngIf="allowedUsers && actionButtons"
  modelName="usuários"
  sortField="username"
  [sortOrder]="-1"
  [actionButtons]="actionButtons"
  (actionButtonClick)="callAction($event)"
  [dropdownFilters]="dropdownFilters"
  [changeColumns]="true"
  [multiSelectFilter]="true"
  tableTitle="Usuários do sistema"
></app-table>
<p-confirmDialog></p-confirmDialog>
<p-toast></p-toast>

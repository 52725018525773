<p-tabView>
  <p-tabPanel header="Manual">
    <form class="form-b4a" [formGroup]="form" (submit)="submit()">
      <span class="p-float-label">
        <p-chips
          formControlName="emails"
          [separator]="chipRegex"
          [allowDuplicate]="false"
          [addOnTab]="true"
          [showClear]="true"
          [addOnBlur]="true"
          (onAdd)="trimValues()"
          id="emails"
        />
        <label for="emails">E-mails</label>
      </span>
      <p-button label="Incluir" [disabled]="!form.valid" type="submit" />
    </form>
  </p-tabPanel>
  <p-tabPanel header="Planilha">
    <p-fileUpload
      accept=".xlsx"
      [auto]="true"
      [customUpload]="true"
      name="file"
      mode="basic"
      [maxFileSize]="1000000"
      chooseLabel="Importar planilha"
      (uploadHandler)="uploadFile($event)"
    >
    </p-fileUpload>
    <br />
    <table class="xlsx-sample">
      <thead>
        Planilha exemplo
        <tr>
          <th>E-mail</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>abcdef&#64;klmnopq.rst</td>
        </tr>
        <tr>
          <td>abcdefghij&#64;klmnopq.rst</td>
        </tr>
        <tr>
          <td>klmnopq&#64;abcdefghij.rst</td>
        </tr>
        *a primeira linha sempre deve conter o cabeçalho
      </tbody>
    </table>
  </p-tabPanel>
</p-tabView>

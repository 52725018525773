<div class="lp-clube-container">
  <h3>LP Clube</h3>

  <form [formGroup]="form" class="lp-clube-container__campaigns meta-tags">
    <p-panel header="Meta tags">
      <h3>Divulgação e SEO</h3>
      <div class="form-field">
        <label for="titleMeta">Meta Title (obrigatório)</label>
        <input
          type="text"
          id="titleMeta"
          pInputText
          formControlName="titleMeta"
          [ngClass]="{ error: isMetaTitleInvalid() }"
        />
        <p class="error-message" *ngIf="isMetaTitleInvalid()">
          Meta title é um campo obrigatório.
        </p>
      </div>
      <div class="form-field">
        <label for="descriptionMeta"
          >Meta description - máx. 255 caracteres (obrigatório)</label
        >
        <input
          type="text"
          id="descriptionMeta"
          pInputText
          formControlName="descriptionMeta"
          [ngClass]="{ error: isMetaDescriptionInvalid() }"
        />
        <p class="error-message" *ngIf="isMetaDescriptionInvalid()">
          Meta description é um campo obrigatório e deve ter no máximo 255
          caracteres.
        </p>
      </div>
      <div class="form-field">
        <label
          >Meta image - Máx 170kb | WEBP / GIF | Dimensão base: 1200x630
          (obrigatório)</label
        >
        <p-fileUpload
          mode="basic"
          chooseLabel="upload"
          name="file"
          [auto]="true"
          accept="image/webp, image/gif"
          [maxFileSize]="170 * 1024"
          (onSelect)="
            handleFileSelect(
              $event,
              'imageMeta',
              1200,
              630,
              170 * 1024,
              imageMeta
            )
          "
          styleClass="p-button-secondary"
          id="imageMeta"
          #imageMeta
        ></p-fileUpload>
      </div>
      <div class="meta-tags__img">
        <img *ngIf="metaImageUrl" [src]="metaImageUrl" />
      </div>
      <div class="form-field">
        <p-button
          label="Salvar"
          [disabled]="!form.valid"
          (onClick)="save()"
        ></p-button>
      </div>
    </p-panel>
    <p-panel header="Conteúdo">
      <h3>Carrossel de Banners</h3>
      <div class="form-field">
        <p-button
          styleClass="p-button-secondary"
          label="Editar"
          (onClick)="goToCarousels()"
        ></p-button>
      </div>
    </p-panel>
  </form>
</div>

<form class="modal-form" [formGroup]="form" (submit)="submit()">
  <span class="p-float-label">
    <p-dropdown
      appendTo="body"
      [autoDisplayFirst]="false"
      optionLabel="name"
      optionValue="couponId"
      [options]="coupons"
      *ngIf="coupons"
      inputId="couponId"
      [filter]="true"
      filterBy="name"
      formControlName="couponId"
      (onChange)="checkSubscriptionTypeRestrictions(true)"
    ></p-dropdown>
    <label for="couponId">Cupom</label>
    <small
      class="error"
      *ngIf="
        form.controls['couponId'].errors &&
        form.controls['couponId'].errors['incompatibleInfluencer']
      "
    >
      Essa partner não pode usar este cupom
    </small>
    <small
      class="error"
      *ngIf="
        form.controls['couponId'].errors &&
        form.controls['couponId'].errors['incompatibleSubscription']
      "
    >
      Este cupom não pode ser usado nesta assinatura
    </small>
    <small
      class="error"
      *ngIf="
        form.controls['couponId'].errors &&
        form.controls['couponId'].errors['incompatibleSubscriptionType']
      "
    >
      Este cupom não pode ser usado neste plano
    </small>
  </span>
  <span class="p-float-label">
    <p-dropdown
      appendTo="body"
      [autoDisplayFirst]="false"
      optionLabel="subscriptionName"
      optionValue="subscriptionId"
      [options]="subscriptions"
      *ngIf="subscriptions"
      inputId="subscriptionId"
      (onChange)="changeSubscription()"
      formControlName="subscriptionId"
    ></p-dropdown>
    <label for="subscriptionId">Assinatura</label>
  </span>
  <span class="p-float-label">
    <p-dropdown
      appendTo="body"
      [autoDisplayFirst]="false"
      optionLabel="name"
      optionValue="subscriptionTypeId"
      [options]="subscriptionTypes"
      *ngIf="subscriptionTypes"
      inputId="subscriptionTypeId"
      (onChange)="checkSubscriptionTypeRestrictions(true)"
      formControlName="subscriptionTypeId"
    ></p-dropdown>
    <label for="subscriptionTypeId">Plano</label>
  </span>
  <!-- <span class="p-input-checkbox">
    <p-checkbox
      formControlName="personalUse"
      inputId="personalUse"
      label="Uso pessoal?"
      [binary]="true"
      [trueValue]="1"
      [falseValue]="0"
    ></p-checkbox>
  </span> -->
  <div class="buttons">
    <p-button label="Salvar" type="submit" [disabled]="!form.valid"></p-button>
  </div>
</form>

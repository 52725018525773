import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom, map } from 'rxjs';
import {
  CouponControllerService,
  CouponUsageSummary,
  CouponUsageType,
  Edition,
  EditionControllerService
} from 'src/app/admin-api';
import {
  SubscriberGroupEnum,
  getAllSubscriptions,
  getAllSubscriptionsIncludeAll
} from 'src/app/models';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-acquisition-dashboard',
  templateUrl: './acquisition-dashboard.component.html',
  styleUrls: ['./acquisition-dashboard.component.scss']
})
export class AcquisitionDashboardComponent implements OnInit {
  form = new FormGroup({
    subscriptionId: new FormControl<number>(0, [
      Validators.required,
      Validators.min(0)
    ]),
    editionId: new FormControl<number>(
      Number(
        `${new Date().getFullYear()}${(new Date().getMonth() + 1)
          .toString()
          .padStart(2, '0')}`
      ),
      [Validators.required, Validators.min(1)]
    )
  });
  subscriptions: Array<{ label: string; value: number }>;
  editions: Array<Edition> | undefined;
  couponUsages: Array<CouponUsageSummary> | undefined;
  ready = false;
  chartData:
    | {
        labels: Array<string>;
        datasets: Array<{
          type: 'pie' | 'doughtnut' | 'line' | 'bar' | 'radar' | 'polarArea';
          label: string;
          borderWidth?: number;
          backgroundColor?: string;
          borderColor?: string;
          fill?: boolean;
          tension?: number;
          data: Array<number>;
          subscriptionId?: number;
          stack?: string;
          hidden?: boolean;
        }>;
      }
    | undefined;
  chartConfig = {
    options: {
      animation: {
        easing: 'easeInQuad'
      }
    },
    plugins: {
      tooltips: {
        mode: 'index',
        intersect: false
      },
      legend: {
        labels: {
          color: 'black'
        }
      }
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: 'gray'
        },
        grid: {
          color: 'gray',
          drawBorder: false
        }
      },
      y: {
        stacked: true,
        ticks: {
          color: 'gray'
        },
        grid: {
          color: 'gray',
          drawBorder: false
        }
      }
    }
  };
  width: string;
  couponUsageTypes: Array<CouponUsageType>;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.checkScreenSize();
  }

  constructor(
    private couponService: CouponControllerService,
    private editionService: EditionControllerService,
    private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private router: Router,
    private title: Title
  ) {
    this.subscriptions = getAllSubscriptionsIncludeAll();
    this.checkScreenSize();
  }

  checkScreenSize(): void {
    if (window.innerWidth < 768) this.width = '700px';
    else this.width = '100%';
  }

  async ngOnInit(): Promise<void> {
    this.activatedRoute.params.subscribe(async (params) => {
      if (params['editionId']) {
        this.form.patchValue({
          editionId: Number(params['editionId']),
          subscriptionId: Number((params['editionId'] / 1000000).toFixed(0))
        });
        LoaderService.showLoader();
        const fail = document.getElementById('img-fail');
        if (fail?.style?.display === 'none') {
          fail.style.display = 'block';
        }
        await this.findPage();
        await this.findEditions();
        this.title.setTitle('Dashboard Aquisição - ' + this.edition?.theme);
        LoaderService.showLoader(false);
      } else {
        this.navigate();
      }
    });
  }

  async findEditions(): Promise<void> {
    try {
      this.editions = await lastValueFrom(
        this.editionService
          .findEditionsBySubscriptionId(this.form.value.subscriptionId || 1)
          .pipe(map((data) => data.result))
      );
      if (this.form.value.subscriptionId === 0) {
        this.editions?.forEach(
          (e) => (e.editionId = (e.editionId || 0) % 1000000)
        );
      }
    } catch (error: any) {
      this.editions = [];
    }
  }

  async findPage(): Promise<void> {
    this.ready = false;
    await Promise.all([this.findCouponUsages(), this.findCouponUsageTypes()]);
    this.chartData = {
      labels: this.xAxis,
      datasets: getAllSubscriptions()
        .filter(
          (s) =>
            !this.form.value.subscriptionId ||
            s.value === this.form.value.subscriptionId
        )
        .map((s) => ({
          data: this.xAxis.map((x) =>
            this.couponUsages
              .filter((sc) => {
                return (
                  Number((sc.editionId / 1000000).toFixed(0)) === s.value &&
                  this.datePipe.transform(sc.dateCreated, 'dd/MM/yyyy') === x &&
                  sc.subscriberGroupId ===
                    SubscriberGroupEnum.Conquistadas_com_pagamento
                );
              })
              .reduce((sum, sc) => (sum += sc.total || 0), 0)
          ),
          label: s.label + ' ' + 'Aquisição',
          type: 'bar',
          backgroundColor: FormUtil.getColor(s.value, 12, null),
          borderColor: FormUtil.getColor(s.value, 12, null),
          subscriptionId: s.value,
          stack: 'subscriptions'
        }))
    };
    this.chartData.datasets = this.chartData.datasets.concat(
      getAllSubscriptions()
        .filter(
          (s) =>
            !this.form.value.subscriptionId ||
            s.value === this.form.value.subscriptionId
        )
        .map((s) => ({
          data: this.xAxis.map((x) =>
            this.couponUsages
              .filter((sc) => {
                return (
                  Number((sc.editionId / 1000000).toFixed(0)) === s.value &&
                  this.datePipe.transform(sc.dateCreated, 'dd/MM/yyyy') === x &&
                  sc.subscriberGroupId ===
                    SubscriberGroupEnum.Reativacoes_com_pagamento
                );
              })
              .reduce((sum, sc) => (sum += sc.total || 0), 0)
          ),
          label: s.label + ' ' + 'Reativação',
          type: 'bar',
          backgroundColor: FormUtil.getColor(s.value, 6, null),
          borderColor: FormUtil.getColor(s.value, 6, null),
          subscriptionId: s.value,
          stack: 'subscriptions'
        }))
    );
    this.chartData.datasets.sort((d1, d2) => {
      if (d1.subscriptionId > d2.subscriptionId) return 1;
      else if (d1.subscriptionId < d2.subscriptionId) return -1;
      else if (d1.label > d2.label) return 1;
      else if (d1.label < d2.label) return -1;
      return 0;
    });
    this.chartData.datasets.push({
      label: 'Total',
      backgroundColor: FormUtil.getColor(null, null, null),
      borderColor: FormUtil.getColor(null, null, null),
      type: 'bar',
      stack: 'total',
      data: this.xAxis.map((x) =>
        this.couponUsages
          .filter((sc) => {
            return this.datePipe.transform(sc.dateCreated, 'dd/MM/yyyy') === x;
          })
          .reduce((sum, sc) => (sum += sc.total || 0), 0)
      ),
      hidden: true
    });

    this.ready = true;
  }

  async findCouponUsages(): Promise<void> {
    try {
      this.couponUsages = await lastValueFrom(
        this.couponService
          .findCouponUsageReport(this.form.value.editionId as number)
          .pipe(
            map((data) =>
              data.result.map((d) => ({
                ...d,
                dateCreated: FormUtil.utcDate(d.dateCreated)
              }))
            )
          )
      );
    } catch (error: any) {
      this.couponUsages = [];
    }
  }

  async findCouponUsageTypes(): Promise<void> {
    try {
      this.couponUsageTypes = await lastValueFrom(
        this.couponService.findUsageTypesList().pipe(map((data) => data.result))
      );
    } catch (error: any) {
      this.couponUsageTypes = [];
    }
  }

  async changeSubscription(): Promise<void> {
    LoaderService.showLoader();
    const form = { ...this.form.value };
    this.form.controls['editionId'].reset(
      (form.subscriptionId || 0) * 1000000 + ((form.editionId || 0) % 1000000)
    );
    delete this.couponUsages;
    this.ready = false;
    this.navigate();
    LoaderService.showLoader(false);
  }

  async changeEdition(): Promise<void> {
    LoaderService.showLoader();
    delete this.couponUsages;
    this.ready = false;
    this.navigate();
    LoaderService.showLoader(false);
  }

  editionDate(editionId: number): string {
    return FormUtil.editionDate(editionId);
  }

  editionDateName(editionId: number): string {
    return (
      this.datePipe.transform(
        new Date(
          `${((editionId % 1000000) / 100).toFixed(0)}-${editionId % 100}-02`
        ),
        'MMMM/yyyy'
      ) || this.editionDate(editionId)
    );
  }

  navigate(): void {
    this.router.navigate([
      '/dashboard/acquisition/' + this.form.value.editionId
    ]);
  }

  get edition(): Edition | undefined {
    return this.editions?.find(
      (e) => e.editionId === this.form.value.editionId
    );
  }

  get xAxis(): Array<string> {
    const list: Array<Date> =
      this.couponUsages?.reduce((list: Array<Date>, sub) => {
        if (sub.dateCreated) {
          if (
            !list.some(
              (i) =>
                this.datePipe.transform(i, 'dd/MM/yyyy') ===
                this.datePipe.transform(sub.dateCreated, 'dd/MM/yyyy')
            )
          ) {
            list.push(sub.dateCreated);
          }
          list.sort((d1, d2) => d1.getTime() - d2.getTime());
        }
        return list;
      }, []) || [];
    return list.map((d) => this.datePipe.transform(d, 'dd/MM/yyyy'));
  }
}

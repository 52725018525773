<div class="edit-ctn" *ngIf="collection && availableShops">
  <div class="edit-ctn__collection-name">
    <input
      type="text"
      pInputText
      [(ngModel)]="collectionName"
      disabled="true"
      #inputColName
      (blur)="collection.collectionName = inputColName.value"
    />
    <i
      class="pi pi-file-edit"
      [style.font-size]="'25px'"
      [style.margin-right]="'25px'"
      [style.cursor]="'pointer'"
      (click)="inputColName.disabled = !inputColName.disabled"
    ></i>
  </div>
  <span class="p-input-checkbox edit-ctn__checkbox">
    <p-checkbox
      [(ngModel)]="disableCollection"
      [binary]="true"
      inputId="collectionVisibility"
    ></p-checkbox>
    <label for="collectionVisibility">Desativar a collection</label>
  </span>
  <p-panel>
    <p-pickList
      [source]="availableShops"
      [target]="collection?.shops"
      sourceHeader="Lojinhas disponíveis"
      targetHeader="Lojinhas selecionadas"
      [dragdrop]="true"
      [responsive]="true"
      [sourceStyle]="{ height: '30vh' }"
      [targetStyle]="{ height: '30vh' }"
      breakpoint="768px"
      [showSourceControls]="false"
      [showTargetControls]="false"
      filterBy="personName,personId,name"
      sourceFilterPlaceholder="Pesquisar pelo nome ou id do usuário"
      targetFilterPlaceholder="Pesquisar pelo nome ou id do usuário"
    >
      <ng-template pTemplate="item" let-billing>
        <!-- <img
        [src]="billing.imageUrl"
      /> -->
        <div class="edit-ctn__shop-row">
          <img class="edit-ctn__shop-row__photo" [src]="billing.personPhoto" />
          <b>{{ billing.name }}</b>
          <p>- {{ billing.personName }}</p>
        </div>
      </ng-template>
    </p-pickList>
    <div class="edit-ctn__buttons">
      <div class="action">
        <p-button
          type="submit"
          pRipple
          label="Salvar"
          severity="primary"
          (click)="save()"
        >
        </p-button>
      </div>
      <div class="action">
        <p-button
          type="submit"
          pRipple
          label="Voltar"
          severity="secondary"
          routerLink="/configuracoes/collections-lojinhas"
        >
        </p-button>
      </div>
    </div>
  </p-panel>
</div>

/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpResponse
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs';

import { BrandCreateRequest } from '../model/brandCreateRequest';
import { BrandUpdateRequest } from '../model/brandUpdateRequest';
import { PageableRequest } from '../model/pageableRequest';
import { ResponseB4ABrand } from '../model/responseB4ABrand';
import { ResponseB4AListBrand } from '../model/responseB4AListBrand';
import { ResponseB4APageBrandSummaryView } from '../model/responseB4APageBrandSummaryView';

import { Configuration } from '../configuration';
import { BASE_PATH } from '../variables';

@Injectable()
export class BrandControllerService {
  protected basePath = 'http://localhost:5000';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createBrand(
    body?: BrandCreateRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ABrand>;
  public createBrand(
    body?: BrandCreateRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ABrand>>;
  public createBrand(
    body?: BrandCreateRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ABrand>>;
  public createBrand(
    body?: BrandCreateRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4ABrand>(
      'post',
      `${this.basePath}/brand/`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param brandId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findBrandById(
    brandId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ABrand>;
  public findBrandById(
    brandId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ABrand>>;
  public findBrandById(
    brandId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ABrand>>;
  public findBrandById(
    brandId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (brandId === null || brandId === undefined) {
      throw new Error(
        'Required parameter brandId was null or undefined when calling findBrandById.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4ABrand>(
      'get',
      `${this.basePath}/brand/id/${encodeURIComponent(String(brandId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findBrandsList(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListBrand>;
  public findBrandsList(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListBrand>>;
  public findBrandsList(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListBrand>>;
  public findBrandsList(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListBrand>(
      'get',
      `${this.basePath}/brand/`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findBrandsTable(
    body?: PageableRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4APageBrandSummaryView>;
  public findBrandsTable(
    body?: PageableRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4APageBrandSummaryView>>;
  public findBrandsTable(
    body?: PageableRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4APageBrandSummaryView>>;
  public findBrandsTable(
    body?: PageableRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4APageBrandSummaryView>(
      'put',
      `${this.basePath}/brand/list`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateBrand(
    body?: BrandUpdateRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ABrand>;
  public updateBrand(
    body?: BrandUpdateRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ABrand>>;
  public updateBrand(
    body?: BrandUpdateRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ABrand>>;
  public updateBrand(
    body?: BrandUpdateRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4ABrand>(
      'put',
      `${this.basePath}/brand/`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { lastValueFrom, map } from 'rxjs';
import {
  MovementControllerService,
  MovementVariantDTO,
  PageableFilter,
  PageableRequest
} from 'src/app/admin-api';
import { PageContent, TableColumn } from '../table';

@Component({
  selector: 'app-product-movements',
  templateUrl: './product-movements.component.html',
  styleUrls: ['./product-movements.component.scss']
})
export class ProductMovementsComponent implements OnInit {
  @Input()
  productId = 0;

  cols = [
    new TableColumn(
      'Assinante',
      'personName',
      true,
      'text',
      '/users/person/',
      'personId',
      true,
      'contains'
    ),
    new TableColumn(
      'Id da Edição',
      'editionId',
      true,
      'number',
      undefined,
      undefined,
      true,
      'equals'
    ),
    new TableColumn(
      'Edição',
      'theme',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Produto',
      'productVariantName',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Validade',
      'expiration',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Tipo',
      'type',
      true,
      'text',
      undefined,
      undefined,
      true,
      'equals'
    ),
    new TableColumn(
      'Data',
      'movementDate',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    )
  ];
  dropdownFilters = {
    type: [
      { label: 'Todas', value: 'Unkown' },
      { label: 'Troca', value: 'Trade' },
      { label: 'Brinde', value: 'Gift' },
      { label: 'Reenvio', value: 'Resend' },
      { label: 'Incentivo para influencer', value: 'IncentiveInfluencer' }
    ]
  };
  fixedFilters: Array<PageableFilter> | undefined;

  constructor(public movementService: MovementControllerService) {}

  ngOnInit(): void {
    this.fixedFilters = [
      {
        condition: 'equals',
        field: 'productId',
        value: this.productId?.toString(),
        fieldType: 'number'
      }
    ];
  }

  async findPage(
    request: PageableRequest,
    service: unknown
  ): Promise<PageContent<MovementVariantDTO>> {
    const all = request.filters?.find(
      (f) =>
        f.field === 'type' &&
        (f.value === 'Unkown' || !(f.value as string)?.length)
    );
    if (all) {
      all.condition = 'notEquals';
      all.value = 'Unkown';
    }
    return lastValueFrom(
      (service as MovementControllerService)
        .findMovementsTable(request)
        .pipe(map((data) => data.result as PageContent<MovementVariantDTO>))
    );
  }
}

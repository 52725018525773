import { Component, WritableSignal, inject, signal } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  AttributeGroup,
  BeautyProfileAdminControllerService
} from 'src/app/admin-api';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-attribute-group-form',
  templateUrl: './attribute-group-form.component.html',
  styleUrl: './attribute-group-form.component.scss'
})
export class AttributeGroupFormComponent {
  ref: DynamicDialogRef = inject(DynamicDialogRef);
  private config: DynamicDialogConfig = inject(DynamicDialogConfig);
  private beautyProfileAdminService: BeautyProfileAdminControllerService =
    inject(BeautyProfileAdminControllerService);
  private messageService: MessageService = inject(MessageService);

  group: WritableSignal<AttributeGroup | undefined> = signal(
    this.config.data.group
  );
  form = new FormGroup({
    attributeGroupName: new FormControl<string>(
      this.group()?.attributeGroupName,
      [Validators.required, Validators.minLength(1)]
    ),
    active: new FormControl<boolean>(this.group() ? this.group().active : true),
    attributeGroupQuestion: new FormControl<string>(
      {
        value: this.group()?.attributeGroupQuestion,
        disabled: !this.group()?.attributeGroupReferenceId
      },
      [Validators.required, Validators.minLength(1)]
    ),
    attributeGroupId: new FormControl<number>(
      {
        value: this.group()?.attributeGroupId,
        disabled: !this.group()?.attributeGroupId
      },
      [Validators.required, Validators.min(1)]
    )
  });

  async submit(): Promise<void> {
    LoaderService.showLoader();
    try {
      if (this.group()) {
        this.group.set(
          await lastValueFrom(
            this.beautyProfileAdminService
              .updateAttributeGroup(this.form.value)
              .pipe(map((data) => data.result))
          )
        );
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'Grupo de perguntas atualizado com sucesso.'
        });
      } else {
        this.group.set(
          await lastValueFrom(
            this.beautyProfileAdminService
              .createAttributeGroup(this.form.value)
              .pipe(map((data) => data.result))
          )
        );
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'Grupo de perguntas criado com sucesso.'
        });
      }
      this.ref.close(this.group());
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }
}

import {
  Component,
  OnInit,
  ViewChild,
  WritableSignal,
  computed,
  inject,
  signal
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgxPermissionsService } from 'ngx-permissions';
import { lastValueFrom } from 'rxjs';
import { Article, ArticleControllerService } from 'src/app/admin-api';
import {
  DropdownFilter,
  TableColumn,
  TableComponent
} from 'src/app/components/table';
import { Role, roleAsObject } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrl: './articles.component.scss'
})
export class ArticlesComponent implements OnInit {
  articleService: ArticleControllerService = inject(ArticleControllerService);
  private permissionsService: NgxPermissionsService = inject(
    NgxPermissionsService
  );
  private title = inject(Title);

  @ViewChild(TableComponent) table: TableComponent;

  permission: WritableSignal<boolean | undefined> = signal(undefined);
  cols = computed(() => {
    return this.permission() !== undefined
      ? [
          new TableColumn(
            'ArticleId',
            'articleId',
            true,
            'number',
            '/content/article/',
            'articleId'
          ),
          new TableColumn(
            'Titulo',
            'articleTitle',
            true,
            'text',
            '/content/article/',
            'articleId',
            true,
            'contains'
          ),
          new TableColumn(
            'Subtítulo',
            'articleSummary',
            true,
            'text',
            '/content/article/',
            'articleId',
            true,
            'contains'
          ),
          new TableColumn(
            'Data de criação',
            'dateCreated',
            true,
            'date',
            undefined,
            undefined,
            false,
            'between'
          ),
          new TableColumn(
            'Ativo',
            'active',
            true,
            this.permission() ? 'inputSwitch' : 'boolean'
          ),
          new TableColumn(
            'Data de publicação',
            'publishDate',
            true,
            'date',
            undefined,
            undefined,
            true,
            'between'
          )
        ]
      : undefined;
  });

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader();
    this.title.setTitle('Artigos');
    this.permission.set(
      await this.permissionsService.hasPermission([
        roleAsObject(Role.Full_Administrator).enumValue,
        roleAsObject(Role.Content).enumValue
      ])
    );
  }

  dropdownFilters: {
    [field: string]: Array<DropdownFilter>;
  } = {
    active: [
      { label: 'Todos', value: '-1' },
      { label: 'Sim', value: '1' },
      { label: 'Não', value: '0' }
    ]
  };

  defaultFilterValues: { [field: string]: string | Array<string> } = {
    active: '1',
    articleTypeName: '-1'
  };

  async updateActive(article: Article): Promise<void> {
    LoaderService.showLoader();

    try {
      await lastValueFrom(
        this.articleService.updateArticle(article.articleId, article)
      );
      this.table?.refresh();
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }

    LoaderService.showLoader(false);
  }
}

import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChildren
} from '@angular/core';
import {
  PersonDetail,
  SubscriberEditionCompositionSummary,
  SubscriberInfo
} from 'src/app/admin-api';
import { EditionId, PaymentHistory } from 'src/app/models';
import { CompositionDetailsComponent } from './composition-details/composition-details.component';

@Component({
  selector: 'app-subscriber-compositions',
  templateUrl: './subscriber-compositions.component.html',
  styleUrls: ['./subscriber-compositions.component.scss'],
  providers: [DatePipe]
})
export class SubscriberCompositionsComponent {
  @ViewChildren(CompositionDetailsComponent)
  children: Array<CompositionDetailsComponent> | undefined;

  @Input()
  compositions: Array<SubscriberEditionCompositionSummary> | undefined;

  @Input()
  lastPayment: PaymentHistory | undefined;

  @Input()
  person: PersonDetail | undefined;

  @Input()
  isDesktop = false;

  @Input()
  subscriber: SubscriberInfo | undefined;

  @Output()
  editionChanged = new EventEmitter<SubscriberEditionCompositionSummary>();

  allExpanded = false;

  constructor(private datePipe: DatePipe) {}

  get oldEditions(): Array<SubscriberEditionCompositionSummary> {
    return (
      this.compositions?.filter(
        (c) =>
          EditionId.isBeforeOrEqual(
            c.editionId as number,
            EditionId.currentEdition(this.subscriber?.subscriptionId as number)
          ) ||
          (c.compositionId && c.compositionId > 0)
      ) || []
    );
  }

  get newEditionsWithoutComposition(): Array<SubscriberEditionCompositionSummary> {
    return (
      this.compositions?.filter(
        (c) =>
          !c.compositionId &&
          EditionId.isAfter(
            c.editionId as number,
            EditionId.currentEdition(this.subscriber?.subscriptionId as number)
          )
      ) || []
    );
  }

  get editionsForTracker(): Array<{
    edition: string;
    received: boolean;
    editionId: number;
  }> {
    const last =
      this.newEditionsWithoutComposition[0] ||
      this.lastPayment?.billings?.at(this.lastPayment?.billings?.length - 1);
    const editions: Array<{
      edition: string;
      received: boolean;
      editionId: number;
    }> = [];
    if (!last) return [];
    let date = new Date(
      last.editionId?.toString().substring(1, 5) +
        '-' +
        Number(last.editionId?.toString().substring(5, 7)) +
        '-05'
    );
    date = new Date(date.setMonth(date.getMonth() + 1));
    for (
      let index = 0;
      index < (this.subscriber?.subscriptionTypeInstallments || 1);
      index++
    ) {
      date = new Date(date.setMonth(date.getMonth() - 1));
      const editionId = date.getFullYear() * 100 + (date.getMonth() + 1);
      editions.push({
        edition: this.datePipe.transform(
          date,
          'MMM/yy',
          undefined,
          'pt-BR'
        ) as string,
        received:
          this.oldEditions.some(
            (e) => (e.editionId as number) % 1000000 === editionId
          ) ||
          (this.subscriber.subscriptionId === 6 &&
            editionId <= Number(this.datePipe.transform(new Date(), 'yyyyMM'))),
        editionId
      });
    }
    editions.sort((e1, e2) => e1.editionId - e2.editionId);
    return editions;
  }
}

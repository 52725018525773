<app-table
  *ngIf="cols && dropdownFilters"
  [cols]="cols"
  [findPage]="findPage"
  [service]="productService"
  modelName="produtos"
  sortField="productName"
  addLabel="Novo produto"
  tableTitle="Produtos de assinatura"
  [sortOrder]="-1"
  [dropdownFilters]="dropdownFilters"
  addButtonRouterLink="product/new"
  [changeColumns]="true"
  [extraButton]="true"
  extraLabel="Syncronizar"
  (extraButtonClick)="syncERP()"
></app-table>
<p-toast />

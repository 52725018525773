<div class="image-cropper-modal" *ngIf="file()">
  <image-cropper
    [imageFile]="file()"
    (imageCropped)="imageCropped($event)"
    [maintainAspectRatio]="true"
    [aspectRatio]="1"
    [resizeToWidth]="croppedWidth"
    [resizeToHeight]="croppedHeight"
    alignImage="center"
  />
  <img *ngIf="croppedBase64" [src]="croppedBase64" />
  <small *ngIf="croppedWidth && croppedHeight"
    >{{ croppedWidth | number : '1.0-0' }} x
    {{ croppedHeight | number : '1.0-0' }} px</small
  >
  <div class="buttons">
    <p-button label="Voltar" severity="danger" (onClick)="ref.close()" />
    <p-button label="Salvar" (onClick)="dismiss()" severity="primary" />
  </div>
</div>

<img
  [style]="{ 'max-height': maxHeight, 'max-width': maxWidth }"
  [src]="imageURL"
  *ngIf="imageURL"
/>
<img
  [style]="{ 'max-height': maxHeight, 'max-width': maxWidth }"
  [src]="editionImage"
  *ngIf="!imageURL"
  id="img-fail"
  onerror="this.style.display = 'none'"
/>

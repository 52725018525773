import { Component, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import {
  ItOpsControllerService,
  PaymentWithoutBilling
} from 'src/app/admin-api';
import { TableColumn, TableComponent } from 'src/app/components/table';
import { SubscriberProblemGroupEnum } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-payment-without-billing',
  templateUrl: './payment-without-billing.component.html',
  styleUrls: ['./payment-without-billing.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class PaymentWithoutBillingComponent {
  @ViewChild(TableComponent) table: TableComponent;

  columns: Array<TableColumn> = [
    new TableColumn('', '', false, 'checkbox'),
    new TableColumn(
      'SubscriberId',
      'subscriberId',
      true,
      'number',
      '/users/subscribers/',
      'subscriberId'
    ),
    new TableColumn(
      'TID',
      'tid',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Edition',
      'editionId',
      true,
      'number',
      '/users/subscribers/',
      'editionId'
    ),
    new TableColumn(
      'Data de Autorização',
      'dateAuthorized',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    ),
    new TableColumn(
      'Installments',
      'installments',
      true,
      'number',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Shipping Price',
      'shippingPrice',
      true,
      'currency',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Desconto',
      'discount',
      true,
      'currency',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Total',
      'amount',
      true,
      'currency',
      undefined,
      undefined,
      true,
      'gte'
    )
  ];

  constructor(
    public itOpsService: ItOpsControllerService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  markAsResolved(elements: Array<PaymentWithoutBilling>): void {
    this.confirmationService.confirm({
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      rejectButtonStyleClass: 'p-button-danger',
      message: 'Deseja enviar o relatório?',
      header: 'Corrigir',
      accept: async () => {
        LoaderService.showLoader();
        try {
          await lastValueFrom(
            this.itOpsService.markAsResolved(
              SubscriberProblemGroupEnum.PaymentWithoutBilling,
              elements
            )
          );
          await this.table.refresh(true);
          this.messageService.add({
            summary: 'Sucesso',
            detail: elements.length + ' marcado(s) como resolvido(s)',
            severity: 'success'
          });
          LoaderService.showLoader(false);
        } catch (error) {
          LoaderService.showLoader(false);
          AppDialogService.showErrorDialog(error);
        }
      }
    });
  }
}

import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import jsPDF from 'jspdf';
import autoTable, { ColumnInput } from 'jspdf-autotable';
import { TableRowExpandEvent } from 'primeng/table';
import { lastValueFrom, map } from 'rxjs';
import {
  CompositionControllerService,
  CompositionDetails,
  CompositionProductVariantDetail,
  Edition,
  EditionControllerService,
  PageableFilter
} from 'src/app/admin-api';
import { TableColumn } from 'src/app/components/table';
import {
  CompositionProductVariantSummary,
  SubscriptionEnum,
  getAllSubscriptions
} from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-compositions',
  templateUrl: './compositions.component.html',
  styleUrls: ['./compositions.component.scss']
})
export class CompositionsComponent implements OnInit {
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private title: Title = inject(Title);
  private editionService: EditionControllerService = inject(
    EditionControllerService
  );
  private router: Router = inject(Router);
  public compositionService: CompositionControllerService = inject(
    CompositionControllerService
  );

  form = new FormGroup({
    subscriptionId: new FormControl<number>(1, [
      Validators.required,
      Validators.min(0)
    ]),
    editionId: new FormControl<number>(
      Number(
        `1${new Date().getFullYear()}${(new Date().getMonth() + 1)
          .toString()
          .padStart(2, '0')}`
      ),
      [Validators.required, Validators.min(1)]
    )
  });
  subscriptions: Array<{ label: string; value: number }> =
    getAllSubscriptions().filter((s) => s.value !== 6);
  editions: Array<Edition> | undefined;
  compositionCols: Array<TableColumn> = [
    new TableColumn(
      'Id',
      'compositionId',
      true,
      'number',
      '/operations/compositions/id/',
      'compositionId'
    ),
    new TableColumn(
      'Composição',
      'compositionName',
      true,
      'text',
      '/operations/compositions/id/',
      'compositionId',
      true,
      'contains'
    ),
    new TableColumn(
      'Preço custo',
      'costTotal',
      true,
      'currency',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Preço venda',
      'saleTotal',
      true,
      'currency',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Preço nota',
      'invoiceTotal',
      true,
      'currency',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Assinantes',
      'subscriberCount',
      true,
      'formattedInteger',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Produtos',
      'productCount',
      true,
      'formattedInteger',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Peso (g)',
      'weight',
      true,
      'formattedNumber',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Data criação',
      'dateCreated',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    ),
    new TableColumn(
      'Data exportação',
      'dateSyncERP',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    )
  ];

  variantCols: Array<TableColumn> = [
    new TableColumn(
      'EAN',
      'internalEAN',
      true,
      'text',
      '/products/catalog/product-variant/',
      'productVariantId',
      true,
      'contains'
    ),
    new TableColumn(
      'SKU Admin',
      'productVariantId',
      true,
      'number',
      undefined,
      undefined,
      true,
      'equals'
    ),
    new TableColumn(
      'Millennium SKU',
      'externalId',
      true,
      'number',
      undefined,
      undefined,
      true,
      'equals'
    ),
    new TableColumn(
      'Qtd',
      'quantity',
      true,
      'number',
      undefined,
      undefined,
      true,
      'equals'
    ),
    new TableColumn(
      'Marca',
      'brandName',
      true,
      'text',
      '/products/brands/',
      'brandId',
      true,
      'contains'
    ),
    new TableColumn(
      'Produto',
      'productVariantName',
      true,
      'text',
      '/products/catalog/product-variant/',
      'productVariantId',
      true,
      'contains'
    ),
    new TableColumn(
      'Categoria',
      'category',
      true,
      'text',
      '/products/categories/',
      'categoryId',
      true,
      'contains'
    ),
    new TableColumn(
      'Custo',
      'costPrice',
      true,
      'currency',
      undefined,
      undefined,
      true,
      'between'
    ),
    new TableColumn(
      'Valor de mercado',
      'salePrice',
      true,
      'currency',
      undefined,
      undefined,
      true,
      'between'
    ),
    new TableColumn(
      'Sub. Categoria',
      'subcategory',
      true,
      'text',
      '/products/categories/',
      'subcategoryId',
      true,
      'contains'
    ),
    new TableColumn(
      'Validade',
      'expiration',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    )
  ];
  fixedFilters: Array<PageableFilter>;
  loading = true;

  async ngOnInit(): Promise<void> {
    this.activatedRoute.params.subscribe(async (params) => {
      if (params['editionId']) {
        this.form.patchValue({
          editionId: Number(params['editionId']),
          subscriptionId: Number((params['editionId'] / 1000000).toFixed(0))
        });
        LoaderService.showLoader();
        const fail = document.getElementById('img-fail');
        if (fail?.style?.display === 'none') {
          fail.style.display = 'block';
        }
        await this.findEditions();
        this.fixedFilters = [
          {
            condition: 'equals',
            field: 'editionId',
            fieldType: 'number',
            value: this.form.value.editionId.toString()
          }
        ];
        this.title.setTitle('Composições - ' + this.edition?.theme);
        this.variantCols = this.variantCols.filter(
          (c) => c.field !== 'subscriptionName'
        );
        if (this.form.value.subscriptionId === 7) {
          this.variantCols.push(
            new TableColumn('Montagem', 'subscriptionName', true, 'text')
          );
        }
        this.loading = false;
        LoaderService.showLoader(false);
      } else {
        this.navigate();
      }
    });
  }

  async findEditions(): Promise<void> {
    try {
      this.editions = await lastValueFrom(
        this.editionService
          .findEditionsBySubscriptionId(this.form.value.subscriptionId || 1)
          .pipe(map((data) => data.result))
      );
      if (this.form.value.subscriptionId === 0) {
        this.editions?.forEach(
          (e) => (e.editionId = (e.editionId || 0) % 1000000)
        );
      }
    } catch (error: any) {
      this.editions = [];
    }
  }

  navigate(): void {
    this.loading = true;
    this.router.navigate([
      '/operations/compositions/' + this.form.value.editionId
    ]);
  }

  async changeSubscription(): Promise<void> {
    LoaderService.showLoader();
    const form = { ...this.form.value };
    this.form.controls['editionId'].reset(
      (form.subscriptionId || 0) * 1000000 + ((form.editionId || 0) % 1000000)
    );
    this.navigate();
    LoaderService.showLoader(false);
  }

  editionDate(editionId: number): string {
    return FormUtil.editionDate(editionId);
  }

  async onRowExpand(
    $event: TableRowExpandEvent,
    service: unknown
  ): Promise<void> {
    if (service && !($event.data as CompositionDetails).products) {
      LoaderService.showLoader();
      try {
        ($event.data as CompositionDetails).products = await lastValueFrom(
          this.compositionService
            .findCompositionProducts(
              ($event.data as CompositionDetails).compositionId
            )
            .pipe(
              map((data) => {
                data.result.sort((p1, p2) => {
                  if (
                    ($event.data as CompositionDetails).editionId / 1000000 >=
                    7
                  ) {
                    if (p1.subscriptionId < p2.subscriptionId) {
                      return -1;
                    } else if (p1.subscriptionId > p2.subscriptionId) {
                      return 1;
                    }
                  }
                  if (p1.productVariantName < p2.productVariantName) {
                    return -1;
                  } else if (p1.productVariantName > p2.productVariantName) {
                    return 1;
                  }
                  return p1.productVariantId - p2.productVariantId;
                });
                return data.result;
              })
            )
        );
      } catch (error) {
        AppDialogService.showErrorDialog(error);
      }
      LoaderService.showLoader(false);
    }
  }

  rowBackground(
    row: CompositionDetails | CompositionProductVariantDetail
  ): string | undefined {
    if (
      row.editionId / 1000000 >= 7 &&
      (row as CompositionProductVariantDetail).productVariantId
    ) {
      if (
        (row as CompositionProductVariantDetail).subscriptionId ===
        SubscriptionEnum.Glambag
      ) {
        return '#8540f54c';
      }
      return '#fe357b4c';
    }
    return undefined;
  }

  rowColor(
    row: CompositionDetails | CompositionProductVariantDetail
  ): string | undefined {
    if (
      row.editionId / 1000000 >= 7 &&
      (row as CompositionProductVariantDetail).productVariantId
    ) {
      return 'white';
    }
    return undefined;
  }

  changeEdition(): void {
    LoaderService.showLoader();
    this.navigate();
  }

  exportPdf(content: Array<CompositionDetails>): void {
    const doc = new jsPDF({ orientation: 'l', format: 'A4' });
    doc.setFontSize(14);
    doc.setFont('Arial', '', '600');
    content.forEach((c, i) => {
      if (c.products?.length) {
        const items: Array<any> = c.products.map((p) => {
          return {
            'Millennium SKU': p.externalId?.toString() || ' ',
            'SKU Admin': p.productVariantId?.toString() || ' ',
            Validade: p.expiration,
            EAN: p.internalEAN || ' ',
            Marca: p.brandName || ' ',
            Produto: p.productVariantName || ' ',
            Qtd: (p.quantity || 1).toString(),
            Valor: 'R$ ' + (p.invoicePrice || 0).toFixed(2),
            subscriptionId: p.subscriptionId || 0
          } as CompositionProductVariantSummary;
        });
        doc.text(c.compositionId + ' - ' + c.compositionName, 15, 10);
        const headers = Object.keys(items[0])
          .filter((k) => k !== 'subscriptionId')
          .map(
            (h) =>
              ({
                dataKey: h,
                header: h,
                key: h,
                title: h
              } as ColumnInput)
          );
        doc.text('Valor nota: R$ ' + c.invoiceTotal.toFixed(2), 15, 17);
        doc.text('Peso: ' + c.weight.toFixed(2) + 'g', 100, 17);
        doc.text('Assinantes: ' + c.subscriberCount, 200, 17);
        if (c.editionId / 1000000 >= 7) {
          const separated = items.filter(
            (i) =>
              Number((i as CompositionProductVariantDetail).subscriptionId) <=
              SubscriptionEnum.Glambox
          );
          // doc.text('Glambox', 15, 24);
          autoTable(doc, {
            columns: headers,
            body: separated,
            headStyles: {
              fillColor: '#fe357b',
              fontSize: 10
            },
            startY: 24,
            bodyStyles: {
              fontSize: 9
            }
          });
          // doc.text('Glambag', 15, (separated.length + 1) * 12 + 26);
          autoTable(doc, {
            columns: headers,
            body: items.filter(
              (i) =>
                Number(
                  (i as CompositionProductVariantDetail).subscriptionId
                ) === SubscriptionEnum.Glambag
            ),
            headStyles: {
              fillColor: '#8540f5',
              fontSize: 10
            },
            startY: separated.length * 11 + 24,
            bodyStyles: {
              fontSize: 9
            },
            styles: {
              halign: 'left'
            }
          });
        } else {
          autoTable(doc, {
            body: items,
            columns: headers,
            startY: 24,
            headStyles: {
              fontSize: 10,
              fillColor:
                (c.editionId / 1000000).toFixed(0) === '5'
                  ? '#8540f5'
                  : '#fe357b'
            },
            bodyStyles: {
              fontSize: 9
            }
          });
        }
        if (i < content.length - 1) doc.addPage();
      }
    });
    doc.save(`Composições-${new Date().getTime()}.pdf`);
  }

  get edition(): Edition | undefined {
    return this.editions?.find(
      (e) => e.editionId === this.form.value.editionId
    );
  }
}

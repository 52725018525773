import {
  CommonModule,
  CurrencyPipe,
  DatePipe,
  TitleCasePipe
} from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { ChartModule } from 'primeng/chart';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';
import { CouponsRankingChartDialogComponent } from './coupons-ranking-chart/coupons-ranking-chart-dialog/coupons-ranking-chart-dialog.component';
import { CouponsRankingChartComponent } from './coupons-ranking-chart/coupons-ranking-chart.component';
import { CouponsReportComponent } from './coupons-report.component';
import { CouponsUsageSummaryReportComponent } from './coupons-usage-summary-report/coupons-usage-summary-report.component';

@NgModule({
  declarations: [
    CouponsReportComponent,
    CouponsUsageSummaryReportComponent,
    CouponsRankingChartComponent,
    CouponsRankingChartDialogComponent
  ],
  imports: [
    CommonModule,
    TableModule,
    TooltipModule,
    ChartModule,
    DynamicDialogModule,
    ButtonModule,
    TabViewModule
  ],
  exports: [
    CouponsReportComponent,
    CouponsUsageSummaryReportComponent,
    CouponsRankingChartComponent,
    CouponsRankingChartDialogComponent
  ],
  providers: [DatePipe, CurrencyPipe, TitleCasePipe, DialogService]
})
export class CouponsReportModule {}

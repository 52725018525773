<div *ngIf="refund">
  <div>
    <div>
      <strong>Total estorno:</strong> {{ refund.amount | currency : 'BRL' }}
    </div>
    <div><strong>Solicitante:</strong> {{ refund.username }}</div>
  </div>
  <br />
  <div>
    <textarea
      id="reason"
      rows="5"
      cols="80"
      pInputTextarea
      [(ngModel)]="refund.message"
      name="reason"
      readonly
    ></textarea>
  </div>
</div>

<div class="page-title">Vitrine</div>
<form class="main-ctn" [formGroup]="form" (ngSubmit)="submit()" *ngIf="form">
  <ng-container>
    <div class="section">
      <h3>Divulgação e SEO</h3>
      <div class="form-field">
        <label for="metaTitle">Meta Title (obrigatório)</label>
        <input
          type="text"
          pInputText
          id="metaTitle"
          formControlName="titleMeta"
        />
      </div>
      <div class="form-field">
        <label for="descriptionMeta"
          >Meta description - máx. 255 caracteres (obrigatório)</label
        >
        <input
          type="text"
          pInputText
          id="descriptionMeta"
          formControlName="descriptionMeta"
        />
      </div>
      <div class="form-field">
        <label
          >Meta image - Máx 170kb | WEBP / GIF | Dimensão base: 1200x630
          (obrigatório)</label
        >
        <div class="form-field__image-input">
          <div class="form-field__image-input__image-box">
            <img
              class="form-field__image-input__image-box__image"
              [src]="imageMeta"
            />
          </div>
          <p-fileUpload
            mode="basic"
            [chooseLabel]="'upload'"
            name="file"
            [auto]="true"
            [customUpload]="true"
            (uploadHandler)="
              fileSelected($event, fileUpload1, 1200, 630, 'imageMeta')
            "
            accept="image/webp, image/gif"
            [maxFileSize]="174080"
            uploadIcon="pi pi-file"
            id="fileUpload"
            #fileUpload1
          ></p-fileUpload>
        </div>
      </div>
    </div>
    <div class="section">
      <h3>Banner da vitrine</h3>
      <div class="form-field">
        <label for="bannerAlt">Alt text do banner (obrigatório)</label>
        <input
          type="text"
          pInputText
          id="bannerAlt"
          formControlName="altText"
        />
      </div>
      <div class="form-field">
        <label for="bannerLink"
          >Link de redirecionamento do banner (obrigatório)</label
        >
        <input type="text" pInputText id="bannerLink" formControlName="link" />
      </div>
      <div class="form-field">
        <label
          >Banner Desktop - Máx 170kb | WEBP / GIF | Dimensão base: 904x200
          (obrigatório)</label
        >
        <div class="form-field__image-input">
          <div class="form-field__image-input__image-box">
            <img
              class="form-field__image-input__image-box__image"
              [src]="desktopImage"
            />
          </div>
          <p-fileUpload
            mode="basic"
            [chooseLabel]="'upload'"
            name="file"
            [auto]="true"
            [customUpload]="true"
            (uploadHandler)="
              fileSelected($event, fileUpload2, 904, 200, 'desktopImage')
            "
            accept="image/webp, image/gif"
            [maxFileSize]="1024000"
            uploadIcon="pi pi-file"
            id="fileUpload"
            #fileUpload2
          ></p-fileUpload>
        </div>
      </div>
      <div class="form-field">
        <label
          >Banner Mobile - Máx 120kb | WEBP / GIF | Dimensão base: 345x75
          (obrigatório)</label
        >
        <div class="form-field__image-input">
          <div class="form-field__image-input__image-box">
            <img
              class="form-field__image-input__image-box__image"
              [src]="mobileImage"
            />
          </div>
          <p-fileUpload
            mode="basic"
            [chooseLabel]="'upload'"
            name="file"
            [auto]="true"
            [customUpload]="true"
            (uploadHandler)="
              fileSelected($event, fileUpload3, 345, 75, 'mobileImage')
            "
            accept="image/webp, image/gif"
            [maxFileSize]="1024000"
            uploadIcon="pi pi-file"
            id="fileUpload"
            #fileUpload3
          ></p-fileUpload>
        </div>
      </div>
    </div>
    <div class="buttons-field">
      <p-button label="Cancelar" outlined="true" [routerLink]="'..'"></p-button>
      <p-button icon="pi pi-check" label="Salvar" type="submit"></p-button>
    </div>
  </ng-container>
</form>

<form class="form-b4a">
  <div class="form-grid">
    <div class="search-field">
      <span class="p-input-icon-right">
        <i class="pi pi-search"></i>
        <input
          type="text"
          pInputText
          [formControl]="searchControl"
          placeholder="Buscar por template"
        />
      </span>
    </div>
    <div class="button-container">
      <button
        pButton
        type="button"
        icon="pi pi-filter-slash"
        (click)="clearSearch()"
        [disabled]="!hasFilter"
        class="p-button-outlined p-button-secondary"
        pTooltip="Limpar filtros"
      ></button>
    </div>
  </div>
</form>

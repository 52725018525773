<div class="coupon__header" *ngIf="coupon">
  Loja - Detalhes do cupom "{{ coupon?.nickname }}"
</div>
<div class="coupon__header" *ngIf="!coupon">Loja - Novo cupom</div>
<p-tabView *ngIf="coupon">
  <p-tabPanel header="Informações básicas">
    <div class="coupon__tab">
      <app-shop-coupon-form
        [couponId]="couponId"
        [coupon]="coupon"
        [person]="person"
        [influencer]="influencer"
      />
    </div>
  </p-tabPanel>
  <p-tabPanel
    header="Categorias ({{
      productCategories?.length || 0 | number : '1.0-0'
    }})"
    *ngIf="coupon"
  >
    <form class="form-b4a" (submit)="addCategory()">
      <span class="p-float-label">
        <p-autoComplete
          [suggestions]="categoriesAvailable"
          [formControl]="category"
          filterBy="sku"
          optionLabel="name"
          appendTo="body"
          [lazy]="false"
          [multiple]="false"
          (completeMethod)="searchCategories($event)"
          [showClear]="true"
          placeholder="Busque pelo nome ou slug"
        >
          <ng-template pTemplate="item" let-item>
            <ng-container *ngIf="item.reference">{{
              item.reference
            }}</ng-container>
            {{ item.name }}
            <ng-container *ngIf="item.slug"
              >[slug: {{ item.slug }}]</ng-container
            >
            <ng-container *ngIf="item.shops"
              >(Loja: {{ item.shops }})</ng-container
            >
          </ng-template>
          <ng-template pTemplate="selectedItem" let-item>
            <ng-container *ngIf="item.reference">{{
              item.reference
            }}</ng-container>
            {{ item.name }}
            <ng-container *ngIf="item.slug"
              >[slug: {{ item.slug }}]</ng-container
            >
            <ng-container *ngIf="item.shops"
              >(Loja: {{ item.shops }})</ng-container
            >
          </ng-template>
        </p-autoComplete>
        <label for="product">Categoria</label>
      </span>
      <p-button severity="primary" label="Adicionar categoria" type="submit" />
    </form>
    <br />
    <app-table
      *ngIf="productCategories"
      [elements]="productCategories"
      [lazy]="false"
      [cols]="cols"
      [actionButtons]="actionButton"
      [showTitle]="false"
      [showHeader]="false"
      [defaultRows]="10"
      (actionButtonClick)="checkRemoveProductCategory($event)"
      modelName="categorias"
      colsExportMode="visible"
    />
  </p-tabPanel>
  <p-tabPanel header="Pedidos">
    <app-table
      [cols]="orderCols"
      modelName="pedidos"
      [service]="orderService"
      pageFunctionName="getOrdersList"
      colsExportMode="visible"
      [addButton]="false"
      [sortOrder]="1"
      sortField="createdAt"
      colsExportMode="visible"
      [dropdownFilters]="orderFilters"
      [fixedFilters]="[
        {
          condition: 'equals',
          field: 'coupon.id',
          value: couponId
        }
      ]"
      emptyMessage="Não foram realizados pedidos com o cupom"
    />
  </p-tabPanel>
  <p-tabPanel header="Assinaturas"
    ><app-table
      pageFunctionName="getSubscriptionsList"
      [service]="subscriptionService"
      [cols]="subscriptionCols"
      modelName="assinaturas"
      [addButton]="false"
      [dropdownFilters]="subscriptionFilters"
      colsExportMode="visible"
      [sortOrder]="1"
      sortField="createdAt"
      [fixedFilters]="[
        {
          condition: 'equals',
          field: 'coupon.id',
          value: couponId
        }
      ]"
      emptyMessage="Não foram realizadas assinaturas com o cupom"
    />
  </p-tabPanel>
</p-tabView>
<app-shop-coupon-form *ngIf="!coupon" />
<p-confirmPopup />

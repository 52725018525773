import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  FeedbackAdminControllerService,
  FeedbackControllerService,
  FeedbackForm,
  FeedbackFormDetail,
  FeedbackOption,
  FeedbackQuestion,
  FeedbackQuestionGroup
} from 'src/app/admin-api';
import { QuestionTypeEnum } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-feedback-option-clone',
  templateUrl: './feedback-option-clone.component.html',
  styleUrl: './feedback-option-clone.component.scss'
})
export class FeedbackOptionCloneComponent implements OnInit {
  private feedbackService: FeedbackControllerService = inject(
    FeedbackControllerService
  );
  private feedbackAdminService: FeedbackAdminControllerService = inject(
    FeedbackAdminControllerService
  );
  private config: DynamicDialogConfig = inject(DynamicDialogConfig);
  ref: DynamicDialogRef = inject(DynamicDialogRef);

  question: FeedbackQuestion = this.config.data.question;
  feedbackForm: FeedbackForm = this.config.data.form;
  form = new FormGroup({
    optionIds: new FormControl<Array<number>>({ value: [], disabled: true }, [
      Validators.required,
      Validators.minLength(1)
    ]),
    questionId: new FormControl<number>(
      this.question?.questionId,
      Validators.required
    ),
    copyQuestionId: new FormControl<number>(null, [
      Validators.required,
      Validators.min(1)
    ]),
    feedbackFormId: new FormControl<number>(this.feedbackForm?.formId, [
      Validators.required,
      Validators.min(1)
    ]),
    feedbackForm: new FormControl<FeedbackForm>(this.feedbackForm, [
      Validators.required
    ])
  });
  search = new FormControl('');
  forms: Array<FeedbackFormDetail>;
  questionsList: {
    [formId: number]: Array<
      FeedbackQuestion & { options?: Array<FeedbackQuestion> }
    >;
  } = {};
  questions: {
    [formId: number]: Array<FeedbackQuestion | FeedbackQuestionGroup>;
  } = {};
  options: {
    [questionId: number]: Array<FeedbackOption>;
  } = {};

  async ngOnInit(): Promise<void> {
    await this.formChange();
  }

  async submit(): Promise<void> {
    LoaderService.showLoader();
    try {
      const options = await lastValueFrom(
        this.feedbackAdminService
          .cloneFeebackOptions(
            this.form.value.questionId,
            this.form.value.optionIds
          )
          .pipe(map((data) => data.result))
      );
      this.ref.close(options);
    } catch (error) {
      AppDialogService.showErrorDialog(error);
      LoaderService.showLoader(false);
    }
  }

  async findFeedbackForms(search: string): Promise<void> {
    try {
      this.forms = await lastValueFrom(
        this.feedbackService
          .findFeedbackFormsTable({
            page: 0,
            pageSize: 5,
            filters: [
              {
                condition: 'contains',
                field: 'formTitle',
                fieldType: 'text',
                value: search
              }
            ],
            sortBy: 'formTitle',
            sortDirection: 'ASC'
          })
          .pipe(map((data) => data.result.content))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findQuestions(): Promise<void> {
    try {
      this.questions[this.form.value.feedbackForm.formId] = await lastValueFrom(
        this.feedbackService
          .findFormQuestions(this.form.value.feedbackForm.formId)
          .pipe(
            map((data) => {
              return data.result.questions
                .filter(
                  (q) =>
                    q.typeId ===
                      (QuestionTypeEnum['Reposta múltipla'] as any).value ||
                    q.typeId ===
                      (QuestionTypeEnum['Resposta única'] as any).value
                )
                .concat(
                  data.result.groups.map((g) => ({
                    ...g,
                    questionId: g.questions[0].questionId
                  }))
                );
            })
          )
      );
      this.questions[this.form.value.feedbackForm.formId].sort(
        (q1, q2) => q1.questionOrder - q2.questionOrder
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findOptions(): Promise<void> {
    LoaderService.showLoader();
    try {
      this.options[this.form.value.copyQuestionId] = await lastValueFrom(
        this.feedbackService
          .findQuestionOptions(this.form.value.copyQuestionId)
          .pipe(
            map((data) => {
              return data.result;
            })
          )
      );
      this.options[this.form.value.copyQuestionId].sort(
        (o1, o2) => o1.optionOrder - o2.optionOrder
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  async formChange(): Promise<void> {
    this.form.controls['feedbackFormId'].setValue(
      this.form.value.feedbackForm.formId
    );
    if (!this.questions[this.form.value.feedbackForm.formId]?.length) {
      LoaderService.showLoader();
      await this.findQuestions();
      LoaderService.showLoader(false);
    }
    this.form.controls['copyQuestionId'].reset();
    this.form.controls['copyQuestionId'].enable();
  }

  async questionChange(): Promise<void> {
    if (!this.options[this.form.value.copyQuestionId]?.length) {
      LoaderService.showLoader();
      await this.findOptions();
      LoaderService.showLoader(false);
    }
    this.form.controls['optionIds'].reset();
    this.form.controls['optionIds'].enable();
  }

  clearForm(): void {
    this.form.controls['feedbackForm'].reset();
    this.form.controls['optionIds'].reset();
    this.form.controls['optionIds'].disable();
    this.form.controls['copyQuestionId'].reset();
    this.form.controls['copyQuestionId'].disable();
  }

  get currentQuestion(): FeedbackQuestion {
    return this.form.controls['copyQuestionId'].valid
      ? this.questions[this.form.value.feedbackFormId].find(
          (q) =>
            (q as FeedbackQuestion).questionId ===
            this.form.value.copyQuestionId
        )
      : null;
  }

  get optionsLabel(): string {
    return this.form.controls['optionIds'].valid
      ? this.options[this.form.value.copyQuestionId]
          .filter((o) => this.form.value.optionIds.includes(o.optionId))
          .map((o, i) => `${i > 0 ? ' ' : ''}${o.optionOrder}. ${o.optionText}`)
          .toString()
      : 'Selecione uma alternativa';
  }
}

import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { BroadcastUtil } from 'src/app/utils/broadcast.util';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  private authService = inject(AuthService);
  private router = inject(Router);
  private queryParams = inject(ActivatedRoute).queryParams;

  form = new FormGroup({
    username: new FormControl(null, [Validators.required, Validators.email]),
    password: new FormControl(null, [
      Validators.required,
      Validators.minLength(6)
    ])
  });
  errorMessage = '';
  redirectUrl: string;

  ngOnInit() {
    this.queryParams.subscribe((params) => {
      if (params['redirectUrl']) this.redirectUrl = params['redirectUrl'];
    });
  }

  async submit(event: Event) {
    event.preventDefault();
    if (this.form.valid) {
      try {
        LoaderService.showLoader();
        const data = await this.authService.login({
          ...this.form.value
        });
        TokenStorageService.saveResult(data);
        BroadcastUtil.get('userChanged').emit();
        if (this.redirectUrl) window.location.href = this.redirectUrl;
        else this.router.navigate(['/']);
      } catch (error) {
        LoaderService.showLoader(false);
        AppDialogService.showErrorDialog(
          {},
          false,
          'Usuário ou senha inválida.'
        );
      }
    }
  }
}

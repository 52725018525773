<app-table
  [lazy]="true"
  [cols]="columns"
  tableTitle="Falha de Pagamento"
  [addButton]="false"
  [exportPdf]="false"
  [exportExcel]="false"
  [refreshButton]="false"
  [service]="itOpsService"
  pageFunctionName="getFailedPaymentDataTable"
  emptyMessage="Sem mais falhas de pagamento no momento."
  selectionActionLabel="Corrigir"
  selectionActionTooltip="Marcar como resolvido"
  selectionActionIcon="pi pi-check"
  (emitSelection)="markAsResolved($event)"
  modelName="Registros"
  [defaultRows]="15"
></app-table>
<p-confirmDialog></p-confirmDialog>
<p-toast></p-toast>

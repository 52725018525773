<app-table
  [service]="subscripitonController"
  pageFunctionName="findAllSubscriptionTypesPageable"
  [cols]="cols"
  modelName="Planos"
  [changeColumns]="true"
  [defaultRows]="25"
  [exportPdf]="false"
  [exportExcel]="false"
  [addButton]="false"
></app-table>

import { Component, inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { InfluencerDetail, PersonDetail } from 'src/app/admin-api';
import { PWAUtil } from 'src/app/utils/pwa.util';

@Component({
  selector: 'app-person-card-small',
  templateUrl: './person-card-small.component.html',
  styleUrl: './person-card-small.component.scss'
})
export class PersonCardSmallComponent {
  @Input({ required: true }) person: PersonDetail;
  @Input() influencer: InfluencerDetail;

  private router = inject(Router);

  openPersonPage(): void {
    const url = '/users/person/' + this.person?.personId;
    // Se estiver no browser
    if (!PWAUtil.isPWA)
      window.open(
        this.router.serializeUrl(this.router.createUrlTree([url])),
        '_blank'
      );
    else this.router.navigate([url]);
  }
}

<section class="login">
  <h1>Login</h1>
  <form class="login__form" (submit)="submit($event)" [formGroup]="form">
    <div class="login__form_control p-float-label">
      <input
        pInputText
        id="email"
        id="email"
        type="text"
        formControlName="username"
      />
      <label for="email">Email</label>
    </div>
    <div class="login__form_control p-float-label">
      <input
        pInputText
        id="password"
        type="password"
        formControlName="password"
        id="password"
      />
      <label for="password">Senha</label>
    </div>
    <div class="login__form_control">
      <p-button type="submit" label="Entrar"></p-button>
    </div>
  </form>
  <p class="danger" *ngIf="errorMessage"></p>
</section>

<form *ngIf="form" [formGroup]="form" class="form-b4a" (submit)="submit()">
  <span class="p-float-label">
    <input id="recipient" type="text" pInputText formControlName="recipient" />
    <label for="recipient">Destinatário *</label>
    <small
      class="error"
      *ngIf="
        form.controls['recipient'].touched &&
        form.controls['recipient'].errors &&
        form.controls['recipient'].errors['required']
      "
      >Campo obrigatório</small
    >
  </span>
  <span class="p-float-label">
    <p-inputMask
      mask="99999-999"
      formControlName="zip"
      (onComplete)="findAddressByZipcode()"
      (onBlur)="zipCodeBlur()"
      placeholder="99999-999"
    ></p-inputMask>
    <label for="zip">CEP *</label>
    <small
      class="error"
      *ngIf="
        form.controls['zip'].touched &&
        form.controls['zip'].errors &&
        form.controls['zip'].errors['required']
      "
      >Campo obrigatório</small
    >
  </span>
  <span class="p-float-label">
    <input id="street" type="text" pInputText formControlName="street" />
    <label for="street">Logradouro *</label>
    <small
      class="error"
      *ngIf="
        form.controls['street'].touched &&
        form.controls['street'].errors &&
        form.controls['street'].errors['required']
      "
      >Campo obrigatório</small
    >
  </span>
  <span class="p-float-label">
    <input id="number" type="text" pInputText formControlName="number" />
    <label for="number">Número *</label>
    <small
      class="error"
      *ngIf="
        form.controls['number'].touched &&
        form.controls['number'].errors &&
        form.controls['number'].errors['required']
      "
      >Campo obrigatório</small
    >
  </span>
  <span class="p-float-label">
    <input id="extension" type="text" pInputText formControlName="extension" />
    <label for="extension">Complemento</label>
    <small
      class="error"
      *ngIf="
        form.controls['extension'].touched &&
        form.controls['extension'].errors &&
        form.controls['extension'].errors['required']
      "
      >Campo obrigatório</small
    >
  </span>
  <span class="p-float-label">
    <input id="district" type="text" pInputText formControlName="district" />
    <label for="district">Bairro *</label>
    <small
      class="error"
      *ngIf="
        form.controls['district'].touched &&
        form.controls['district'].errors &&
        form.controls['district'].errors['required']
      "
      >Campo obrigatório</small
    >
  </span>
  <span class="p-float-label">
    <input
      id="state"
      type="text"
      pInputText
      formControlName="state"
      *ngIf="states?.length === 0"
    />
    <p-dropdown
      appendTo="body"
      [options]="states"
      *ngIf="states?.length"
      optionLabel="sigla"
      optionValue="sigla"
      [autoDisplayFirst]="false"
      formControlName="state"
      id="state"
    ></p-dropdown>
    <label for="state">Estado *</label>
    <small
      class="error"
      *ngIf="
        form.controls['state'].touched &&
        form.controls['state'].errors &&
        form.controls['state'].errors['required']
      "
      >Campo obrigatório</small
    >
  </span>
  <span class="p-float-label">
    <input
      id="city"
      type="text"
      pInputText
      formControlName="city"
      *ngIf="!cities?.length"
    />
    <p-dropdown
      appendTo="body"
      [options]="cities"
      *ngIf="cities?.length"
      optionLabel="nome"
      optionValue="nome"
      [autoDisplayFirst]="false"
      formControlName="city"
      [virtualScroll]="true"
      [virtualScrollItemSize]="38"
      id="city"
    ></p-dropdown>
    <label for="city">Município *</label>
    <small
      class="error"
      *ngIf="
        form.controls['city'].touched &&
        form.controls['city'].errors &&
        form.controls['city'].errors['required']
      "
      >Campo obrigatório</small
    >
  </span>
  <span class="p-float-label">
    <input
      id="mailBoxNumber"
      type="text"
      pInputText
      formControlName="mailBoxNumber"
    />
    <label for="mailBoxNumber">Caixa Postal</label>
  </span>
  <span class="buttons">
    <p-button
      label="Salvar"
      type="submit"
      severity="primary"
      [disabled]="!form?.valid"
    />
  </span>
</form>
<p-toast />

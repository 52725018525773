import {
  Component,
  inject,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  Category,
  Product,
  ShopCollection,
  Shops,
  ShopSection,
  ShopSettings
} from '@infrab4a/connect';
import { MessageService } from 'primeng/api';
import {
  AutoCompleteCompleteEvent,
  AutoCompleteSelectEvent
} from 'primeng/autocomplete';
import { CarouselResponsiveOptions } from 'primeng/carousel';
import { ImageUploadComponent } from 'src/app/components/image-upload/image-upload.component';
import {
  isCollection,
  ShopSettingsService
} from 'src/app/connect-api/api/shop-settings/shop-settings.service';
import { ShopCategoryService } from 'src/app/connect-api/api/shop/shop-category.service';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { GlamHomeComponent } from '../glam-home.component';

@Component({
  selector: 'app-glam-home-collection-form',
  templateUrl: './glam-home-collection-form.component.html',
  styleUrl: './glam-home-collection-form.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class GlamHomeCollectionFormComponent
  extends ImageUploadComponent<ShopCollection>
  implements OnInit
{
  @Input({ required: true }) id: string;

  private shopSettingsService = inject(ShopSettingsService);
  protected categoryService = inject(ShopCategoryService);
  private messageService = inject(MessageService);
  collection: ShopCollection;
  settings: Partial<ShopSettings> & { sections?: ShopSection[] };
  override form = new FormGroup({
    categoryId: new FormControl<string>(null, Validators.required),
    beautyProfileFilter: new FormControl<boolean>(null),
    collectionImage: new FormControl<string>(null),
    collectionImageAlt: new FormControl<string>(null),
    collectionColor: new FormControl<string>(
      '#fe357b',
      Validators.pattern(/^#[0-9a-f]{3,6}$/i)
    ),
    slug: new FormControl<string>(null, Validators.required),
    id: new FormControl<string>(
      this.shopSettingsService.createId(),
      Validators.required
    ),
    title: new FormControl<string>(null),
    type: new FormControl<string>('collection', Validators.required),
    fixed: new FormControl(false),
    editable: new FormControl(true)
  });
  collectionTypes = [
    { label: 'Regular', value: 'collection' },
    { label: 'Premium', value: 'collection premium' }
  ];
  categories: Partial<Category>[];
  products: Product[];
  slug: string;
  responsiveOptionsSmall: CarouselResponsiveOptions[] = [
    {
      breakpoint: '1199px',
      numVisible: 4,
      numScroll: 4
    },
    {
      breakpoint: '991px',
      numVisible: 3,
      numScroll: 3
    },
    {
      breakpoint: '767px',
      numVisible: 2,
      numScroll: 2
    }
  ];

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader();
    try {
      this.settings = await this.shopSettingsService.getById(
        GlamHomeComponent.documentId
      );
      if (this.id && this.id !== 'new') {
        this.collection = this.settings.sections.find(
          (s) => isCollection(s) && s.id === this.id
        );
        if (!this.collection) throw { message: 'Banner não encontrado' };
        this.slug = this.collection.slug;
        this.form.patchValue(this.collection);
        this.form.controls.id.enable();
        if (this.form.value.categoryId) await this.getProducts();
      }
      this.checkCollectionsLimit();
    } catch (error) {
      AppDialogService.showErrorDialog(error, true);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  typeChanged(): void {
    if (this.form.value.type === 'collection premium') {
      this.form.controls.collectionImage.setValidators(Validators.required);
      this.form.controls.collectionImageAlt.setValidators(Validators.required);
      this.form.controls.collectionColor.setValidators([
        Validators.required,
        Validators.pattern(/^#[0-9a-f]{3,6}$/i)
      ]);
      if (!this.form.value.collectionColor)
        this.form.controls.collectionColor.reset('#fe357b');
    } else {
      this.form.controls.collectionImage.clearValidators();
      this.form.controls.collectionImageAlt.clearValidators();
      this.form.controls.collectionColor.clearValidators();
    }

    this.form.controls.collectionImage.updateValueAndValidity();
    this.form.controls.collectionImageAlt.updateValueAndValidity();
    this.form.controls.collectionColor.updateValueAndValidity();
  }

  checkCollectionsLimit(): void {
    if (this.settings?.sections?.filter((s) => isCollection(s)).length >= 6)
      throw { message: 'Limite de coleções atingido (Máximo:6)' };
  }

  async searchCollections(event: AutoCompleteCompleteEvent): Promise<void> {
    try {
      const result = await Promise.all([
        this.categoryService.getCategoriesList({
          filters: [
            {
              condition: 'contains',
              field: 'slug',
              value: event.query,
              fieldType: 'text'
            },
            {
              condition: 'equals',
              field: 'shop',
              value: this.settings.shop,
              fieldType: 'boolean'
            }
          ],
          page: 0,
          pageSize: 10
        }),
        this.categoryService.getCategoriesList({
          filters: [
            {
              condition: 'contains',
              field: 'name',
              value: event.query,
              fieldType: 'text'
            },
            {
              condition: 'equals',
              field: 'shop',
              value: this.settings.shop,
              fieldType: 'text'
            }
          ],
          page: 0,
          pageSize: 10
        })
      ]);
      const categories = [
        ...(result.reduce(
          (cats, r) => cats.concat(r.content),
          []
        ) as Partial<Category>[])
      ];
      this.categories = [...new Map(categories.map((b) => [b.id, b])).values()];
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  checkCollection(): void {
    if (this.slug !== this.form.value.slug) {
      this.form.controls.slug.setValue(this.slug);
    }
  }

  async collectionChanged($event?: AutoCompleteSelectEvent): Promise<void> {
    LoaderService.showLoader();
    if ($event.value) {
      this.form.controls.categoryId.setValue(
        ($event.value as Partial<Category>).id
      );
    }
    try {
      await this.getProducts();
    } catch (error) {
      this.form.controls.categoryId.reset();
      this.form.controls.slug.reset();
      delete this.products;
      AppDialogService.showErrorDialog(error);
    } finally {
      this.slug = $event.value.slug;
      LoaderService.showLoader(false);
    }
  }

  async getProducts(): Promise<void> {
    const products = await this.categoryService.getCategoryHomeProducts(
      this.form.value.categoryId,
      Shops.GLAMSHOP
    );

    if (!products.length) {
      throw { message: 'Produtos não encontrados na categoria' };
    }

    this.products = products;
  }

  async submit(): Promise<void> {
    if (this.form.valid) {
      try {
        LoaderService.showLoader();
        const collection = this.form.value as ShopCollection;
        const sections = this.settings.sections as ShopSection[];
        if (collection.type === 'collection') {
          collection.collectionColor = null;
        }
        if (this.collection) {
          collection.updatedAt = new Date();
          const ref: ShopCollection = sections.find(
            (s) => isCollection(s) && s.id === this.collection.id
          ) as ShopCollection;
          if (!ref) throw { message: 'Coleção não encontrada' };
          Object.keys(this.form.value).forEach(
            (key) => (ref[key] = collection[key])
          );
        } else {
          collection.createdAt = new Date();
          this.settings.sections = sections
            .filter((s) => s.type !== 'newsletter')
            .concat([{ ...collection }])
            .concat(sections.filter((s) => s.type === 'newsletter'));
        }
        await this.shopSettingsService.update(this.settings);
        await this.afterSubmit();
        this.router.navigate(['/configuracoes/glam/home']);
      } catch (error) {
        AppDialogService.showErrorDialog(error);
      } finally {
        LoaderService.showLoader(false);
      }
    }
  }

  override get filePath(): string {
    return '/glam/home/collection/';
  }
  override get model(): ShopCollection {
    return this.collection;
  }
  override get modelId(): string | number {
    return this.collection?.id;
  }
}

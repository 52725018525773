import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { GlamHttpClient } from 'src/app/utils/glam-http-client';
import { AuthService } from './auth/auth.service';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [{ provide: GlamHttpClient, deps: [HttpClient] }, AuthService]
})
export class ServicesModule {}

/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { EditionCustomizationIconRequest } from './editionCustomizationIconRequest';

export interface EditionCustomizationRequest {
  editionTheme?: string;
  editionDate?: number;
  editionCardDesktop?: string;
  editionCardMobile?: string;
  lastEditionsCarouselCard?: string;
  lastEditionsCarouselCardAlt?: string;
  icons?: Array<EditionCustomizationIconRequest>;
}

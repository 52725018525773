import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { GridStackOptions, GridStackWidget } from 'gridstack';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { lastValueFrom, map } from 'rxjs';
import {
  ProductControllerService,
  ProductVariant,
  ProductVariantSummary
} from 'src/app/admin-api';
import { GridstackComponent } from 'src/app/components/gridstack/gridstack.component';
import { TableColumn, TableComponent } from 'src/app/components/table';
import { getEquityLabel } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-glamclub',
  templateUrl: './glamclub.component.html',
  styleUrls: ['./glamclub.component.scss'],
  providers: [ConfirmationService]
})
export class GlamclubComponent implements OnInit {
  @ViewChild(GridstackComponent) gridComp?: GridstackComponent;
  @ViewChild('no-stock-table') noStockTable: TableComponent | undefined;
  productsList: Array<ProductVariantSummary> | undefined;
  productsInStock: Array<ProductVariantSummary> | undefined;
  products: Array<ProductVariantSummary> | undefined;
  orderedList: Array<ProductVariantSummary> | undefined;
  options: Array<MenuItem> | undefined;
  onlyStock = 1;
  gridOptions: GridStackOptions = {
    margin: 5,
    // float: true,
    minRow: 1,
    cellHeight: '400px',
    removable: false,
    column: 5
  };
  minStock = 5;
  cols: Array<TableColumn> = [
    new TableColumn('', '', false, 'checkbox'),
    new TableColumn(
      'Id',
      'externalId',
      true,
      'number',
      '/products/catalog/product-variant/',
      'productVariantId'
    ),
    new TableColumn(
      'Variante',
      'productVariantName',
      true,
      'text',
      '/products/catalog/product-variant/',
      'productVariantId',
      true,
      'contains'
    ),
    new TableColumn(
      'InternalEAN',
      'internalEAN',
      true,
      'text',
      '/products/catalog/product-variant/',
      'productVariantId',
      true,
      'contains'
    ),
    new TableColumn(
      'Marca',
      'brandName',
      true,
      'text',
      '/produts/brands/',
      'brandId',
      true,
      'contains'
    ),
    new TableColumn(
      'BrandEquity',
      'brandEquityLabel',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Validade',
      'expiration',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn('Estoque', 'stockCount', false, 'formattedInteger'),
    new TableColumn('Nota', 'ratingAverage', false, 'formattedNumber'),
    new TableColumn('Avaliações', 'reviewCount', false, 'formattedInteger')
  ];
  colsInStock: Array<TableColumn> = [
    new TableColumn(
      'Id',
      'externalId',
      true,
      'number',
      '/products/catalog/product-variant/',
      'productVariantId'
    ),
    new TableColumn(
      'Variante',
      'productVariantName',
      true,
      'text',
      '/products/catalog/product-variant/',
      'productVariantId',
      true,
      'contains'
    ),
    new TableColumn(
      'InternalEAN',
      'internalEAN',
      true,
      'text',
      '/products/catalog/product-variant/',
      'productVariantId',
      true,
      'contains'
    ),
    new TableColumn(
      'Marca',
      'brandName',
      true,
      'text',
      '/produts/brands/',
      'brandId',
      true,
      'contains'
    ),
    new TableColumn(
      'BrandEquity',
      'brandEquityLabel',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Validade',
      'expiration',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn('Estoque', 'stockCount', false, 'formattedInteger'),
    new TableColumn('Pontos troca', 'tradePoints', false, 'formattedNumber'),
    new TableColumn(
      'Trocas mês',
      'maxTradesPerPerson',
      false,
      'formattedInteger'
    ),
    new TableColumn('Nota', 'ratingAverage', false, 'formattedNumber'),
    new TableColumn('Avaliações', 'reviewCount', false, 'formattedInteger')
  ];
  constructor(
    private productService: ProductControllerService,
    private confirmationService: ConfirmationService,
    private title: Title
  ) {
    this.title.setTitle('Produtos da vitrine');
  }

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader();
    await Promise.all([this.findProducts(), this.findProductVariantsInStock()]);
    this.options = [
      {
        icon: 'pi pi-save',
        tooltipOptions: { tooltipLabel: 'Salvar alterações' },
        command: async () => {
          await this.saveList();
        }
      },
      {
        icon: 'pi pi-undo',
        tooltipOptions: { tooltipLabel: 'Desfazer alterações' },
        command: async () => {
          LoaderService.showLoader();
          delete this.products;
          await this.findProducts();
          LoaderService.showLoader(false);
        }
      }
    ];
    setTimeout(() => {
      const stacks = document.getElementsByClassName('grid-stack-item');
      if (!stacks.length) window.location.reload();
    }, 1000);
    LoaderService.showLoader(false);
  }

  async findProducts(): Promise<void> {
    try {
      this.productsList = await lastValueFrom(
        this.productService
          .findProductVariantsForGlamclub()
          .pipe(map((data) => data.result))
      );
      this.filterProducts();
    } catch (error: any) {
      AppDialogService.showErrorDialog(error, true);
    }
  }

  async findProductVariantsInStock(): Promise<void> {
    try {
      this.productsInStock = await lastValueFrom(
        this.productService.findProductVariantsInStock().pipe(
          map((data) =>
            data.result?.map((p) => ({
              ...p,
              brandEquityLabel: getEquityLabel(p.brandEquity as number)
            }))
          )
        )
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
  }

  filterProducts(): void {
    this.products = this.productsList
      ?.filter(
        (p) =>
          (this.onlyStock && p.stockCount && p.stockCount > this.minStock) ||
          (!this.onlyStock && (!p.stockCount || p.stockCount <= this.minStock))
      )
      .map((p) => ({
        ...p,
        brandEquityLabel: `${p.brandEquity} ${getEquityLabel(
          p.brandEquity as number
        )}`
      }));
  }

  filterChanged(): void {
    LoaderService.showLoader();
    delete this.products;
    this.onlyStock = this.onlyStock ? 0 : 1;
    this.gridComp?.grid?.destroy();
    setTimeout(() => {
      this.filterProducts();
      setTimeout(() => {
        LoaderService.showLoader(false);
      }, 500);
    }, 500);
  }

  listChanged(): void {
    const grid = this.gridComp?.grid?.save(true) as GridStackWidget[];
    const products: Array<ProductVariantSummary> = [];
    grid.forEach((i) =>
      products.push(
        this.products?.find(
          (p) => p.productVariantId?.toString() === i.id
        ) as ProductVariantSummary
      )
    );
    this.products = products;
  }

  async saveList(): Promise<void> {
    LoaderService.showLoader();
    try {
      const grid = this.gridComp?.grid?.save(true) as GridStackWidget[];
      delete this.products;
      await lastValueFrom(
        this.productService
          .saveGlamclubDisplayOrders(grid.map((i) => Number(i.id)))
          .pipe(map((data) => data.result))
      );
      await this.findProducts();
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  confirmAction(product: ProductVariantSummary, $event: any): void {
    this.confirmationService.confirm({
      target: $event.target,
      message: 'Deseja remover o produto da vitrine?',
      header: 'Atenção',
      icon: 'pi pi-exclamation-triangle danger',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      acceptButtonStyleClass: 'p-button-primary',
      rejectButtonStyleClass: 'p-button-danger',
      accept: async () => {
        LoaderService.showLoader();
        await this.disableTrades([product]);
        LoaderService.showLoader(false);
      }
    });
  }

  async disableTrades(variants: Array<ProductVariantSummary>): Promise<void> {
    LoaderService.showLoader();
    try {
      await lastValueFrom(
        this.productService
          .removeProductVariantFromGlamclub(
            variants.map((v) => v.productVariantId as number)
          )
          .pipe(map((data) => data.result))
      );
      delete this.products;
      await Promise.all([
        this.findProducts(),
        this.findProductVariantsInStock()
      ]);
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  async findProductVariant(
    productVariantId: number
  ): Promise<ProductVariant | undefined> {
    try {
      return await lastValueFrom(
        this.productService
          .findProductVariantById(productVariantId)
          .pipe(map((data) => data.result))
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
      return;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  moveToTop(product: ProductVariantSummary, $event?: any): void {
    this.confirmationService.confirm({
      target: $event.target,
      message: 'Deseja mover o produto para o topo da lista?',
      header: 'Atenção',
      icon: 'pi pi-exclamation-triangle danger',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      acceptButtonStyleClass: 'p-button-primary',
      rejectButtonStyleClass: 'p-button-danger',
      accept: () => {
        LoaderService.showLoader();
        const products = [
          product,
          ...(this.products?.filter(
            (p) => p.productVariantId !== product.productVariantId
          ) || [])
        ];
        delete this.products;
        setTimeout(() => {
          this.products = products;
          setTimeout(() => {
            LoaderService.showLoader(false);
          }, 1000);
        }, 500);
      }
    });
  }
}

/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ProductSummaryItem {
  productId?: number;
  productName?: string;
  brandId?: number;
  brandName?: string;
  productSummary?: string;
  dateCreated?: Date;
  dateUpdated?: Date;
  variantCount?: number;
  ratingAverage?: number;
  reviewCount?: number;
  externalId?: string;
  categoryId?: number;
  category?: string;
  subcategoryId?: number;
  subcategory?: string;
  subcategoryExternalId?: number;
  categoryExternalId?: number;
  internalEAN?: string;
  brandEquity?: number;
  productVariantId?: number;
  stock?: number;
  internalEANGrouped?: string;
  activeFeedbackId?: number;
}

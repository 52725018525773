import { Component, inject } from '@angular/core';
import { MessageService, PrimeIcons } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import {
  CompositionControllerService,
  CompositionDetails
} from 'src/app/admin-api';
import { TableActionButton, TableColumn } from 'src/app/components/table';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { prepareComposition } from '../editions-dashboard.component';

@Component({
  selector: 'app-pending-preparation',
  templateUrl: './pending-preparation.component.html',
  styleUrl: './pending-preparation.component.scss',
  providers: [MessageService]
})
export class PendingPreparationComponent {
  private compositionService = inject(CompositionControllerService);
  private messageService = inject(MessageService);
  private config = inject(DynamicDialogConfig);

  pending: CompositionDetails[] = this.config.data.pending;
  actionButtons: Array<TableActionButton> = [
    new TableActionButton(
      '',
      '',
      PrimeIcons.PLAY,
      // *() => true
      (item: CompositionDetails) => item.pendingPreparation > 0,
      'p-button-success',
      'Preparar composição',
      'bottom'
    )
  ];
  cols = [
    new TableColumn.Builder()
      .setHeader('Id')
      .setField('compositionId')
      .setRouterLink('/operations/compositions/id/')
      .setRouterLinkFieldName('compositionId')
      .setType('number')
      .build(),
    new TableColumn.Builder()
      .setHeader('Composição')
      .setField('compositionName')
      .setRouterLink('/operations/compositions/id/')
      .setRouterLinkFieldName('compositionId')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('Pendente preparo')
      .setField('pendingPreparation')
      .setFilter(false)
      .setType('formattedInteger')
      .build(),
    new TableColumn.Builder().setHeader('Ação').setType('button').build()
  ];

  async prepareComposition(composition: CompositionDetails) {
    try {
      LoaderService.showLoader();
      const result = await prepareComposition(
        composition,
        this.compositionService
      );
      composition.pendingPreparation = result.composition.pending;
      this.messageService.add(result.message);
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }
}

import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  PersonDetail,
  Reward,
  RewardControllerService,
  RewardType
} from 'src/app/admin-api';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-reward-modal',
  templateUrl: './reward-modal.component.html',
  styleUrls: ['./reward-modal.component.scss']
})
export class RewardModalComponent {
  rewardForm: FormGroup;
  person: PersonDetail;
  rewardTypes: Array<RewardType> | undefined;
  constructor(
    private config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private messageService: MessageService,
    private rewardService: RewardControllerService
  ) {
    this.person = this.config.data.person;
    this.rewardTypes = this.config.data.rewardTypes;
    this.rewardForm = new FormGroup({
      personId: new FormControl<number>(
        this.person.personId as number,
        Validators.required
      ),
      rewardType: new FormControl<number | undefined>(
        undefined,
        Validators.required
      ),
      referenceId: new FormControl<number | undefined>(
        undefined,
        Validators.required
      ),
      referenceName: new FormControl<string | undefined>(
        undefined,
        Validators.required
      ),
      points: new FormControl<number>(0, [
        Validators.required,
        Validators.min(1)
      ])
    });
  }

  async submit(): Promise<void> {
    if (this.rewardForm.valid) {
      LoaderService.showLoader(true);
      const reward: Reward = this.rewardForm.value;
      try {
        const personReward = await lastValueFrom(
          this.rewardService
            .addGlampoints(reward)
            .pipe(map((data) => data.result))
        );
        this.messageService.add({
          detail: 'Glampoints creditados com sucesso',
          summary: 'Sucesso',
          severity: 'success'
        });
        this.ref.close(personReward);
      } catch (error: any) {
        AppDialogService.showErrorDialog(
          error,
          false,
          'Erro ao dar glampoints'
        );
      }
      LoaderService.showLoader(false);
    }
  }

  get rewardType(): RewardType | undefined {
    return this.rewardTypes?.find(
      (r) => r.rewardTypeId === this.rewardForm.value.rewardType
    );
  }

  get totalPoints(): number {
    if (this.person.glampointsMultiplier && this.rewardType?.isMultipliable) {
      return (
        (this.person.glampointsMultiplier || 1) * this.rewardForm.value.points
      );
    }
    return this.rewardForm.value.points;
  }

  get buttonLabel(): string {
    return this.totalPoints ? 'Dar ' + this.totalPoints + ' pontos' : 'Salvar';
  }
}

import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  SubscriberControllerService,
  SubscriberRefundRequest
} from 'src/app/admin-api';
import { AvailableBilling, getSubscriptionName } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-edition-chargeback-modal',
  templateUrl: './edition-chargeback-modal.component.html',
  providers: [DatePipe]
})
export class EditionChargebackModalComponent implements OnInit {
  public ref = inject(DynamicDialogRef);
  private config = inject(DynamicDialogConfig);
  private subscriberService = inject(SubscriberControllerService);
  private datePipe = inject(DatePipe);
  private cdRef = inject(ChangeDetectorRef);

  selectedEditions: Array<{
    subscriberBillingId: number;
    editionId: number;
    imageUrl?: string;
  }> = [];
  availableEditions: Array<AvailableBilling> = [];
  editionId: number | undefined;
  message?: string;
  subscriberId: number | undefined;

  ngOnInit() {
    this.cdRef.detach();
    if (this.config.data.subscriberId) {
      this.subscriberId = this.config.data.subscriberId;
    }
    if (this.config.data.billings?.length) {
      this.availableEditions = this.config.data.billings;
      this.availableEditions.sort(
        (b1, b2) => (b1.editionId as number) - (b2.editionId as number)
      );
    }
    this.cdRef.reattach();
  }

  async submit(): Promise<void> {
    LoaderService.showLoader();
    try {
      await lastValueFrom(
        this.subscriberService
          .requestSubscriberEditionRefund({
            message: this.message,
            subscriberBillingIds: this.selectedEditions.map(
              (e) => e.subscriberBillingId
            ),
            subscriberId: this.subscriberId
          } as SubscriberRefundRequest)
          .pipe(map((data) => data.result))
      );
      this.ref.close(true);
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  billingLabel(billing: AvailableBilling): string {
    const dateString = ((billing?.editionId as number) % 1000000).toString();
    return (
      (getSubscriptionName(
        Number(billing.editionId?.toString()[0])
      ) as string) +
      ' ' +
      this.datePipe.transform(
        new Date(
          `${dateString.substring(0, 4)}-${dateString.substring(4)}-01 12:00:00`
        ),
        'MMMM/yyyy',
        undefined,
        'pt-BR'
      ) +
      (billing.delivered ? ' (Entregue)' : '')
    );
  }
}

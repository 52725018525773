import { DatePipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Log, LogControllerService } from 'src/app/audit-api';
import { TableColumn } from 'src/app/components/table';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrl: './logs.component.scss',
  providers: [DatePipe]
})
export class LogsComponent implements OnInit {
  private datePipe = inject(DatePipe);
  logService = inject(LogControllerService);
  private title = inject(Title);
  cols = [
    new TableColumn.Builder().setHeader('Id').setField('id').build(),
    new TableColumn.Builder()
      .setHeader('Aplicação')
      .setField('application')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('Pacote')
      .setField('packageName')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('Entidade')
      .setField('entity')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('Operação')
      .setField('operation')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('Email')
      .setField('email')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('UserId')
      .setField('userId')
      .setCondition('equals')
      .build(),
    new TableColumn.Builder()
      .setHeader('SubscriberId')
      .setField('subscriberId')
      .setCondition('equals')
      .setRouterLink('/users/subscribers/')
      .setRouterLinkFieldName('subscriberId')
      .build(),
    new TableColumn.Builder()
      .setHeader('PersonId')
      .setField('personId')
      .setRouterLink('/users/person/')
      .setRouterLinkFieldName('personId')
      .setCondition('equals')
      .build(),
    new TableColumn.Builder()
      .setHeader('Data')
      .setField('logDate')
      .setType('date')
      .setDisplayFunction((log: Log) =>
        FormUtil.timeDiffLabel(new Date(log.logDate), this.datePipe)
      )
      .build(),
    new TableColumn.Builder()
      .setHeader('Valor')
      .setField('value')
      .setType('text')
      .setCondition('contains')
      .setDisplayFunction((log: Log) => JSON.stringify(log.value))
      .build()
  ];

  ngOnInit(): void {
    this.title.setTitle('Logs dos Sistemas');
  }
}

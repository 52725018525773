<div class="delivery-dashboard">
  <h3>Dashboard Entregas</h3>
  <div class="filters">
    <form class="form-b4a" [formGroup]="form">
      <span class="p-float-label">
        <p-dropdown
          inputId="subscriptionId"
          [options]="subscriptions"
          (onChange)="changeSubscription()"
          formControlName="subscriptionId"
          appendTo="body"
          [autoDisplayFirst]="false"
        >
        </p-dropdown>
        <label for="subscriptionId">Assinatura</label>
      </span>
      <span class="p-float-label">
        <p-dropdown
          inputId="editionId"
          [options]="editions"
          *ngIf="editions"
          optionValue="editionId"
          optionLabel="theme"
          (onChange)="changeEdition()"
          [filter]="true"
          filterBy="theme,title,editionId,description"
          filterMatchMode="contains"
          [autoDisplayFirst]="false"
          formControlName="editionId"
          appendTo="body"
        >
          <ng-template pTemplate="item" let-edition>
            <ng-container *ngIf="form.value.subscriptionId">
              {{ edition.theme }}
              {{ editionDate(edition.editionId) }}
            </ng-container>
            <ng-container *ngIf="!form.value.subscriptionId">
              {{ editionDateName(edition.editionId) | titlecase }}
            </ng-container>
          </ng-template>
          <ng-template pTemplate="selectedItem">
            <ng-container *ngIf="form.value.subscriptionId">
              {{ edition?.theme }}
            </ng-container>
            <ng-container
              *ngIf="edition && edition.editionId && !form.value.subscriptionId"
            >
              {{ editionDateName(edition.editionId) | titlecase }}
            </ng-container>
          </ng-template>
        </p-dropdown>
        <label for="editionId">Edição</label>
      </span>
    </form>
    <div class="picture">
      <app-edition-image
        [imageURL]="edition.imageURL"
        [editionId]="edition.editionId"
        *ngIf="edition"
      ></app-edition-image>
    </div>
  </div>
  <div class="totals" *ngIf="shippingDetails && ready">
    <div class="dashboard-card" (click)="showSubscribers(0)">
      <span class="header header--dark">Assinantes</span>
      <span class="value">{{
        totalSubscribers(0, shippingDetails) | number : '1.0-0'
      }}</span>
    </div>
    <div class="dashboard-card" (click)="showSubscribers(1)">
      <span class="header header--warning">Pagamentos pendentes</span>
      <span class="value">{{
        filterSubscribers(1, shippingDetails)?.length || 0 | number : '1.0-0'
      }}</span>
    </div>
    <div class="dashboard-card" (click)="showSubscribers(2)">
      <span class="header header--primary">Total expedidas</span>
      <span class="value">{{
        totalSubscribers(2, shippingDetails) | number : '1.0-0'
      }}</span>
    </div>
    <div class="dashboard-card" (click)="showSubscribers(3)">
      <span class="header header--primary">Aguardando expedição</span>
      <span class="value">{{
        totalSubscribers(3, shippingDetails) | number : '1.0-0'
      }}</span>
    </div>
    <div class="dashboard-card" (click)="showSubscribers(4)">
      <span class="header header--primary">Aguardando alocação</span>
      <span class="value">{{
        totalSubscribers(4, shippingDetails) | number : '1.0-0'
      }}</span>
    </div>
    <div class="dashboard-card" (click)="showSubscribers(5)">
      <span class="header header--primary">Rastreio pendente</span>
      <span class="value">{{
        totalSubscribers(5, shippingDetails) | number : '1.0-0'
      }}</span>
    </div>
    <div class="dashboard-card" (click)="showSubscribers(6)">
      <span class="header header--success">Entregues</span>
      <span class="value">{{
        totalSubscribers(6, shippingDetails) | number : '1.0-0'
      }}</span>
    </div>
    <div class="dashboard-card" (click)="showSubscribers(7)">
      <span class="header header--warning">Em rota</span>
      <span class="value">{{
        totalSubscribers(7, shippingDetails) | number : '1.0-0'
      }}</span>
    </div>
    <div class="dashboard-card" (click)="showSubscribers(8)">
      <span class="header header--danger">Removidas/Estornadas</span>
      <span class="value">{{
        totalSubscribers(8, shippingDetails) | number : '1.0-0'
      }}</span>
    </div>
    <div class="dashboard-card" (click)="showSubscribers(9)">
      <span class="header header--danger">Pagamento estornado</span>
      <span class="value">{{
        totalSubscribers(9, shippingDetails) | number : '1.0-0'
      }}</span>
    </div>
  </div>
  <div class="dashboard" *ngIf="ready">
    <div *ngFor="let service of shippingServices">
      <div class="service-name">
        {{ service.shippingServiceName }}
      </div>
      <hr />
      <div class="service-total">
        {{ shippingServiceTotal(service) | number : '1.0-0' }}
      </div>
      <table class="xlsx-sample">
        <thead>
          <tr>
            <th>Status</th>
            <th>#</th>
            <th>%</th>
          </tr>
        </thead>
        <tbody>
          <tr
            [ngClass]="{
              danger:
                status.subscriberEditionStatusId < 0 ||
                status.subscriberEditionStatusId > 6,
              success: status.subscriberEditionStatusId === 6
            }"
            *ngFor="let status of service.subscriberEditions"
          >
            <td>{{ status.subscriberEditionStatusName }}</td>
            <td>{{ status.subscribers }}</td>
            <td>
              {{
                (status.subscribers / shippingServiceTotal(service)) * 100
                  | number : '1.2-2'
              }}
              %
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<app-table
  [service]="tokenService"
  pageFunctionName="getTokensList"
  modelName="tokens"
  [cols]="columns"
  tableTitle="Tokens de integração"
  [addButtonAction]="true"
  (addButtonClick)="openModal()"
  [actionButtons]="actionButtons"
  (actionButtonClick)="callAction($event)"
/>
<p-toast />
<p-confirmPopup />

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { lastValueFrom, map } from 'rxjs';
import {
  AdminControllerService,
  UserRoleViewResponse
} from 'src/app/admin-api';
import { RoleDTO, getAllRoles, getRole } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
  providers: [MessageService]
})
export class UserFormComponent implements OnChanges {
  @Input()
  user: UserRoleViewResponse | undefined;

  @Input()
  modal = false;

  @Output()
  closeModal = new EventEmitter<boolean>();

  @Output()
  userCreated = new EventEmitter<UserRoleViewResponse>();

  userForm: FormGroup | undefined;
  dirty = false;
  permissions = getAllRoles();
  userRoles: Array<{
    label: string;
    value: number;
    enumValue: string;
    order: number;
  }> = [];
  roles: Array<RoleDTO> | undefined;
  constructor(
    private adminService: AdminControllerService,
    private messageService: MessageService
  ) {
    this.patchValue();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['user']?.currentValue) {
      this.patchValue();
    }
  }

  patchValue(): void {
    this.userForm = new FormGroup({
      displayName: new FormControl<string>(this.user?.displayName || '', [
        Validators.required,
        Validators.minLength(1)
      ]),
      username: new FormControl<string>(this.user?.username?.trim() || '', [
        Validators.required,
        Validators.email
      ]),
      password: new FormControl<string | null>(
        { value: null, disabled: this.user?.userId !== undefined },
        [Validators.required, Validators.minLength(3), Validators.maxLength(20)]
      ),
      passwordConfirmation: new FormControl<string | null>(
        { value: null, disabled: this.user?.userId !== undefined },
        [Validators.required, Validators.minLength(3), Validators.maxLength(20)]
      )
    });
    this.userRoles =
      this.user?.roles?.split(',').map((r) => getRole(r) as any) || [];
    this.roles = this.permissions.filter(
      (r) =>
        !this.userRoles.some((ur) => ur.enumValue === r.enumValue) &&
        r.value > 0
    );
  }

  async submit(): Promise<void> {
    if (this.userForm) {
      Object.keys(this.userForm.controls).forEach((key) => {
        this.userForm?.controls[key].markAsDirty();
        this.userForm?.controls[key].markAsTouched();
      });

      try {
        if (this.userForm.valid && this.user?.userId) {
          this.userForm.controls['displayName'].setValue(
            this.userForm.value.displayName?.trim()
          );
          this.userForm.controls['username'].setValue(
            this.userForm.value.username?.trim()
          );
          LoaderService.showLoader();
          await lastValueFrom(
            this.adminService
              .updateUser(this.user.userId, this.userForm.value)
              .pipe(map((data) => data.result))
          );
          if (this.modal) await this.saveRoles(true);
          else {
            this.messageService.add({
              severity: 'success',
              summary: 'Sucesso',
              detail: 'Usuário atualizado com sucesso'
            });
          }
          LoaderService.showLoader(false);
        } else if (this.userForm.valid) {
          LoaderService.showLoader();
          this.user = await lastValueFrom(
            this.adminService
              .createUser(this.userForm.value)
              .pipe(map((data) => data.result))
          );
          this.patchValue();
          this.messageService.add({
            severity: 'success',
            summary: 'Sucesso',
            detail: 'Usuário cadastrado com sucesso'
          });
          this.dirty = true;
          this.userCreated.emit(this.user);
          LoaderService.showLoader(false);
        }
      } catch (error: any) {
        LoaderService.showLoader(false);
        AppDialogService.showErrorDialog(error);
      }
    }
  }

  async saveRoles(close = false): Promise<void> {
    LoaderService.showLoader();
    try {
      const view = (await lastValueFrom(
        this.adminService
          .saveUserRoles({
            userId: this.user?.userId as number,
            roles: this.userRoles.map((r) => r.value)
          })
          .pipe(map((data) => data.result))
      )) as UserRoleViewResponse;
      this.user = { ...view };
      if (close) this.closeModal.emit(close);
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }
}

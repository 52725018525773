<form
  class="form-b4a"
  (submit)="submit()"
  [formGroup]="userForm"
  *ngIf="userForm"
>
  <ng-container *ngIf="!dirty">
    <span class="p-float-label form-control">
      <input
        type="text"
        pInputText
        formControlName="displayName"
        name="displayName"
      />
      <label for="displayName">Nome de exibição</label>
    </span>
    <span class="p-float-label form-control">
      <input
        type="text"
        pInputText
        formControlName="username"
        name="username"
      />
      <label for="username">E-mail</label>
    </span>
  </ng-container>
  <ng-container *ngIf="!user">
    <span class="p-float-label form-control">
      <input
        type="password"
        pInputText
        formControlName="password"
        name="password"
      />
      <label for="password">Senha</label>
    </span>
    <span class="p-float-label form-control">
      <input
        type="password"
        pInputText
        formControlName="passwordConfirmation"
        name="passwordConfirmation"
      />
      <label for="passwordConfirmation">Confirmação de senha</label>
    </span>
  </ng-container>
  <ng-container *ngIf="roles && user?.userId && modal">
    <p-pickList
      [source]="roles"
      [target]="userRoles"
      [dragdrop]="true"
      [showSourceControls]="false"
      [showTargetControls]="false"
      sourceHeader="Permissões disponíveis"
      targetHeader="Permissões concedidas"
    >
      <ng-template let-role pTemplate="item">
        {{ role.label }}
      </ng-template>
    </p-pickList>
  </ng-container>

  <div class="buttons">
    <p-button
      label="Voltar"
      type="button"
      (onClick)="closeModal.emit(dirty)"
      severity="danger"
      *ngIf="!dirty && modal"
    ></p-button>
    <p-button
      [disabled]="!userForm.valid"
      label="Salvar"
      type="submit"
      severity="primary"
      *ngIf="!dirty"
    ></p-button>
    <p-button
      type="button"
      severity="primary"
      label="Está ok!"
      *ngIf="dirty && modal"
      [disabled]="!userRoles.length"
      (onClick)="saveRoles(true)"
    >
    </p-button>
  </div>
</form>
<p-toast></p-toast>

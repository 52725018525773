<app-table
  [lazy]="true"
  [cols]="columns"
  tableTitle="Reward Duplicada"
  [addButton]="false"
  [exportPdf]="false"
  [exportExcel]="false"
  [refreshButton]="false"
  [service]="itOpsService"
  pageFunctionName="findDuplicatedRewardsTable"
  emptyMessage="Sem mais rewards duplicadas no momento."
  selectionActionLabel="Excluir"
  selectionActionTooltip="Excluir o(s) reward(s)"
  selectionActionIcon="pi pi-trash"
  (emitSelection)="deleteRewards($event)"
  modelName="Registros"
  [defaultRows]="15"
  [dropdownFilters]="dropdownFilters"
  [selectAll]="false"
  [multiSelectFilter]="true"
  [defaultFilterValues]="defaultFiltervalues"
  *ngIf="dropdownFilters['rewardType']"
  [rowBackground]="rowBackground"
></app-table>
<p-confirmDialog></p-confirmDialog>
<p-toast></p-toast>

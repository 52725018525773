export enum FeedbackOptionTypeEnum {
  'Alternativa' = {
    label: 'Alternativa',
    value: 1,
    description: 'Alternativa para seleção do usuário',
    disabled: false
  } as any,
  'Justificativa' = {
    label: 'Justificativa',
    value: 2,
    description:
      'Ao selecionar esta alternativa, o usuário deve informar uma justificativa (em breve)',
    disabled: true
  } as any
}

export function getAllFeedbackOptionTypes(): Array<{
  label: string;
  value: number;
  description: string;
  disabled: boolean;
}> {
  const objetos: Array<{
    label: string;
    value: number;
    description: string;
    disabled: boolean;
  }> = [];
  for (const [, propertyValue] of Object.entries(FeedbackOptionTypeEnum)) {
    if (propertyValue !== null && typeof propertyValue === 'object') {
      objetos.push(propertyValue);
    }
  }
  return objetos;
}

export function getAllFeedbackOptionTypesIncludeAll(): Array<{
  label: string;
  value: number;
}> {
  const objetos: Array<{ label: string; value: number }> = [];
  for (const [, propertyValue] of Object.entries(FeedbackOptionTypeEnum)) {
    if (propertyValue !== null && typeof propertyValue === 'object') {
      objetos.push(propertyValue);
    }
  }
  return objetos.concat([
    {
      label: 'Todas',
      value: 0
    }
  ]);
}

export function getFeedbackOptionName(id: number): string | undefined {
  return getAllFeedbackOptionTypes().find((e) => e.value === id)?.label;
}

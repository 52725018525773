<form
  class="form-b4a"
  (submit)="submit()"
  [formGroup]="rewardForm"
  *ngIf="rewardTypes"
>
  <span class="p-float-label">
    <p-dropdown
      appendTo="body"
      [options]="rewardTypes"
      optionLabel="rewardTypeName"
      optionValue="rewardTypeId"
      inputId="rewardTypeId"
      formControlName="rewardType"
      styleClass="p-inputwrapper p-inputwrapper-filled"
      name="rewardTypeId"
      [filter]="true"
      filterBy="rewardTypeName"
      [autoDisplayFirst]="false"
      placeholder="Escolha o motivo da pontuação"
    />
    <label for="rewardTypeId">Tipo</label>
  </span>
  <span class="p-float-label">
    <p-inputNumber
      name="referenceId"
      inputId="referenceId"
      styleClass="p-inputwrapper p-inputwrapper-filled"
      formControlName="referenceId"
      placeholder="ID do produto, ID da assinante ou ID da assinatura"
    />
    <label for="referenceId">ReferenceId</label>
  </span>
  <span class="p-float-label">
    <input
      type="text"
      pInputText
      name="referenceName"
      id="referenceName"
      class="p-filled"
      formControlName="referenceName"
      [placeholder]="rewardType ? rewardType.placeholder : ''"
    />
    <label for="referenceName">ReferenceName</label>
  </span>
  <span class="p-float-label">
    <p-inputNumber
      name="points"
      inputId="points"
      styleClass="p-inputwrapper p-inputwrapper-filled"
      formControlName="points"
    />
    <label for="points">Pontos</label>
    <small
      *ngIf="
        rewardForm.value.points >= 1 &&
        person.glampointsMultiplier &&
        person.glampointsMultiplier > 1 &&
        rewardType?.isMultipliable
      "
    >
      *Obs: Joia {{ person.badge }} Pontuação x{{ person.glampointsMultiplier }}
    </small>
  </span>
  <div class="buttons">
    <p-button
      label="Cancelar"
      severity="danger"
      type="button"
      (onClick)="ref.close()"
    />
    <p-button
      [label]="buttonLabel"
      severity="primary"
      type="submit"
      [disabled]="!rewardForm.valid"
    />
  </div>
</form>

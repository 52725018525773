<div class="blacklist">
  <h3>Blacklists</h3>

  <p-tabView>
    <p-tabPanel header="CPF">
      <app-table
        *ngIf="cpfs"
        [lazy]="false"
        [elements]="cpfs"
        [cols]="cpfCols"
        [actionButtons]="actionButtonsCpf"
        (actionButtonClick)="callAction($event)"
        modelName="CPFs"
        sortField="cpf"
        [sortOrder]="-1"
        addLabel="Incluir"
        [addButtonAction]="true"
        (addButtonClick)="addCpf()"
      ></app-table>
    </p-tabPanel>
    <p-tabPanel header="Domínio">
      <app-table
        [lazy]="false"
        *ngIf="domains"
        [elements]="domains"
        [cols]="domainCols"
        [actionButtons]="actionButtonsDomain"
        (actionButtonClick)="callAction($event)"
        modelName="domínios"
        sortField="domainName"
        [sortOrder]="-1"
        addLabel="Incluir"
        [addButtonAction]="true"
        (addButtonClick)="addDomain()"
      ></app-table>
    </p-tabPanel>
  </p-tabView>
</div>
<p-confirmPopup></p-confirmPopup>
<p-toast></p-toast>

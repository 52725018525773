<div class="panel-container">
  <h3>Coleções de lojinhas</h3>

  <p-panel header="Collections">
    <div
      *ngFor="let section of pageDocument?.sections; let i = index"
      class="form-field"
    >
      <label for="carousel-1">{{ section.collectionName }}</label>
      <p-button
        styleClass="p-button-secondary"
        label="Editar"
        [routerLink]="'edit/' + i"
      ></p-button>
    </div>
  </p-panel>

  <p-panel header="Banner">
    <form class="meta-tags" [formGroup]="form">
      <div class="form-field">
        <label
          >Banner Desk - Máx 170kb | WEBP / GIF | Dimensão base: 1216x240
          (obrigatório)</label
        >
        <p-fileUpload
          mode="basic"
          chooseLabel="upload"
          name="file"
          [auto]="true"
          accept="image/webp, image/gif"
          [maxFileSize]="170 * 1024"
          (onSelect)="
            handleFileSelect(
              $event,
              'imageBannerDesk',
              1216,
              240,
              170 * 1024,
              imageBannerDesk
            )
          "
          styleClass="p-button-secondary"
          id="imageBannerDesk"
          #imageBannerDesk
        ></p-fileUpload>
      </div>
      <div class="meta-tags__img">
        <img *ngIf="imageBannerDeskUrl" [src]="imageBannerDeskUrl" />
      </div>
      <div class="form-field">
        <label
          >Banner Mobile - Máx 170kb | WEBP / GIF | Dimensão base: 375x154
          (obrigatório)</label
        >
        <p-fileUpload
          mode="basic"
          chooseLabel="upload"
          name="file"
          [auto]="true"
          accept="image/webp, image/gif"
          [maxFileSize]="170 * 1024"
          (onSelect)="
            handleFileSelect(
              $event,
              'imageBannerMobile',
              375,
              154,
              170 * 1024,
              imageBannerMobile
            )
          "
          styleClass="p-button-secondary"
          id="imageBannerMobile"
          #imageBannerMobile
        ></p-fileUpload>
      </div>
      <div class="meta-tags__img">
        <img *ngIf="imageBannerMobileUrl" [src]="imageBannerMobileUrl" />
      </div>
      <div class="form-field">
        <p-button
          label="Salvar"
          [disabled]="!form.valid"
          (onClick)="save()"
        ></p-button>
      </div>
    </form>
  </p-panel>
</div>

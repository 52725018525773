/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface EmailNotificationDetail {
    id?: number;
    messageId?: string;
    event?: EmailNotificationDetail.EventEnum;
    ip?: string;
    linkClicked?: string;
    reason?: string;
    userAgent?: string;
    receivedAt?: Date;
    recipientName?: string;
    recipientEmail?: string;
    subject?: string;
    sentAt?: Date;
    templateId?: number;
    templateName?: string;
}
export namespace EmailNotificationDetail {
    export type EventEnum = 'PROCESSED' | 'DELIVERED' | 'BLOCKED' | 'BOUNCED' | 'OPENED' | 'CLICKED' | 'SPAM' | 'DROPPED' | 'DEFERRED' | 'UNSUBSCRIBED';
    export const EventEnum = {
        PROCESSED: 'PROCESSED' as EventEnum,
        DELIVERED: 'DELIVERED' as EventEnum,
        BLOCKED: 'BLOCKED' as EventEnum,
        BOUNCED: 'BOUNCED' as EventEnum,
        OPENED: 'OPENED' as EventEnum,
        CLICKED: 'CLICKED' as EventEnum,
        SPAM: 'SPAM' as EventEnum,
        DROPPED: 'DROPPED' as EventEnum,
        DEFERRED: 'DEFERRED' as EventEnum,
        UNSUBSCRIBED: 'UNSUBSCRIBED' as EventEnum
    };
}

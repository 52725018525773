/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpResponse
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs';

import { DisableCreditCardRecurrenceRequest } from '../model/disableCreditCardRecurrenceRequest';
import { PageableRequest } from '../model/pageableRequest';
import { ResponseB4AListCreditCardSummary } from '../model/responseB4AListCreditCardSummary';
import { ResponseB4AListLong } from '../model/responseB4AListLong';
import { ResponseB4AListOrderMovementStatusDescription } from '../model/responseB4AListOrderMovementStatusDescription';
import { ResponseB4AListShippingTrackingStatusDTO } from '../model/responseB4AListShippingTrackingStatusDTO';
import { ResponseB4AListSubscriberAddressChangeTable } from '../model/responseB4AListSubscriberAddressChangeTable';
import { ResponseB4AListSubscriberBillingStatus } from '../model/responseB4AListSubscriberBillingStatus';
import { ResponseB4AListSubscriberCancellationDetail } from '../model/responseB4AListSubscriberCancellationDetail';
import { ResponseB4AListSubscriberCancellationReason } from '../model/responseB4AListSubscriberCancellationReason';
import { ResponseB4AListSubscriberCouponDetail } from '../model/responseB4AListSubscriberCouponDetail';
import { ResponseB4AListSubscriberEditionCompositionSummary } from '../model/responseB4AListSubscriberEditionCompositionSummary';
import { ResponseB4AListSubscriberEditionStatus } from '../model/responseB4AListSubscriberEditionStatus';
import { ResponseB4AListSubscriberInfo } from '../model/responseB4AListSubscriberInfo';
import { ResponseB4AListSubscriberPaymentHistory } from '../model/responseB4AListSubscriberPaymentHistory';
import { ResponseB4AListSubscriberRefund } from '../model/responseB4AListSubscriberRefund';
import { ResponseB4AListSubscriberRenewalInfo } from '../model/responseB4AListSubscriberRenewalInfo';
import { ResponseB4AListSubscriberStatus } from '../model/responseB4AListSubscriberStatus';
import { ResponseB4AListVoucherSubscriberBilling } from '../model/responseB4AListVoucherSubscriberBilling';
import { ResponseB4APageSubscriberCancellationDetail } from '../model/responseB4APageSubscriberCancellationDetail';
import { ResponseB4APageSubscriberInfo } from '../model/responseB4APageSubscriberInfo';
import { ResponseB4APageSubscriberRefundSummary } from '../model/responseB4APageSubscriberRefundSummary';
import { ResponseB4AString } from '../model/responseB4AString';
import { ResponseB4ASubscriberBillingHistoryEdition } from '../model/responseB4ASubscriberBillingHistoryEdition';
import { ResponseB4ASubscriberEditionCompositionSummary } from '../model/responseB4ASubscriberEditionCompositionSummary';
import { ResponseB4ASubscriberInfo } from '../model/responseB4ASubscriberInfo';
import { SubscriberCancellationRequest } from '../model/subscriberCancellationRequest';
import { SubscriberEditionStatusRequest } from '../model/subscriberEditionStatusRequest';
import { SubscriberRefundRequest } from '../model/subscriberRefundRequest';
import { SubscriberStatusChangeRequest } from '../model/subscriberStatusChangeRequest';

import { Configuration } from '../configuration';
import { BASE_PATH } from '../variables';

@Injectable()
export class SubscriberControllerService {
  protected basePath = 'http://localhost:5000';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   *
   * @param personId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cancelFraudAccount(
    personId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public cancelFraudAccount(
    personId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public cancelFraudAccount(
    personId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public cancelFraudAccount(
    personId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (personId === null || personId === undefined) {
      throw new Error(
        'Required parameter personId was null or undefined when calling cancelFraudAccount.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'put',
      `${this.basePath}/subscriber/admin/fraudCancellation/${encodeURIComponent(
        String(personId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cancelSubscriber(
    body?: SubscriberCancellationRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ASubscriberInfo>;
  public cancelSubscriber(
    body?: SubscriberCancellationRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ASubscriberInfo>>;
  public cancelSubscriber(
    body?: SubscriberCancellationRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ASubscriberInfo>>;
  public cancelSubscriber(
    body?: SubscriberCancellationRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4ASubscriberInfo>(
      'post',
      `${this.basePath}/subscriber/admin/cancellation`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteCreditCard(
    body?: DisableCreditCardRecurrenceRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListCreditCardSummary>;
  public deleteCreditCard(
    body?: DisableCreditCardRecurrenceRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListCreditCardSummary>>;
  public deleteCreditCard(
    body?: DisableCreditCardRecurrenceRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListCreditCardSummary>>;
  public deleteCreditCard(
    body?: DisableCreditCardRecurrenceRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AListCreditCardSummary>(
      'put',
      `${this.basePath}/subscriber/credit-card/remove`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public disableSubscriberRecurrence(
    body?: DisableCreditCardRecurrenceRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AString>;
  public disableSubscriberRecurrence(
    body?: DisableCreditCardRecurrenceRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AString>>;
  public disableSubscriberRecurrence(
    body?: DisableCreditCardRecurrenceRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AString>>;
  public disableSubscriberRecurrence(
    body?: DisableCreditCardRecurrenceRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AString>(
      'put',
      `${this.basePath}/subscriber/admin/recurrence/disable`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findAllSubscriberCancellations(
    body?: PageableRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4APageSubscriberCancellationDetail>;
  public findAllSubscriberCancellations(
    body?: PageableRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4APageSubscriberCancellationDetail>>;
  public findAllSubscriberCancellations(
    body?: PageableRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4APageSubscriberCancellationDetail>>;
  public findAllSubscriberCancellations(
    body?: PageableRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4APageSubscriberCancellationDetail>(
      'put',
      `${this.basePath}/subscriber/cancellations`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param personId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findPersonSubscribers(
    personId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListSubscriberInfo>;
  public findPersonSubscribers(
    personId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListSubscriberInfo>>;
  public findPersonSubscribers(
    personId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListSubscriberInfo>>;
  public findPersonSubscribers(
    personId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (personId === null || personId === undefined) {
      throw new Error(
        'Required parameter personId was null or undefined when calling findPersonSubscribers.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListSubscriberInfo>(
      'get',
      `${this.basePath}/subscriber/person/${encodeURIComponent(
        String(personId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findRefundsList(
    body?: PageableRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4APageSubscriberRefundSummary>;
  public findRefundsList(
    body?: PageableRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4APageSubscriberRefundSummary>>;
  public findRefundsList(
    body?: PageableRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4APageSubscriberRefundSummary>>;
  public findRefundsList(
    body?: PageableRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4APageSubscriberRefundSummary>(
      'put',
      `${this.basePath}/subscriber/refund/list`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param subscriberId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findSubscriberAddressChangeHistory(
    subscriberId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListSubscriberAddressChangeTable>;
  public findSubscriberAddressChangeHistory(
    subscriberId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListSubscriberAddressChangeTable>>;
  public findSubscriberAddressChangeHistory(
    subscriberId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListSubscriberAddressChangeTable>>;
  public findSubscriberAddressChangeHistory(
    subscriberId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (subscriberId === null || subscriberId === undefined) {
      throw new Error(
        'Required parameter subscriberId was null or undefined when calling findSubscriberAddressChangeHistory.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListSubscriberAddressChangeTable>(
      'get',
      `${this.basePath}/subscriber/address-change/${encodeURIComponent(
        String(subscriberId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findSubscriberBillingStatusList(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListSubscriberBillingStatus>;
  public findSubscriberBillingStatusList(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListSubscriberBillingStatus>>;
  public findSubscriberBillingStatusList(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListSubscriberBillingStatus>>;
  public findSubscriberBillingStatusList(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListSubscriberBillingStatus>(
      'get',
      `${this.basePath}/subscriber/subscriber-billing-status/list`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param subscriberId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findSubscriberCancellations(
    subscriberId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListSubscriberCancellationDetail>;
  public findSubscriberCancellations(
    subscriberId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListSubscriberCancellationDetail>>;
  public findSubscriberCancellations(
    subscriberId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListSubscriberCancellationDetail>>;
  public findSubscriberCancellations(
    subscriberId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (subscriberId === null || subscriberId === undefined) {
      throw new Error(
        'Required parameter subscriberId was null or undefined when calling findSubscriberCancellations.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListSubscriberCancellationDetail>(
      'get',
      `${this.basePath}/subscriber/cancellations/${encodeURIComponent(
        String(subscriberId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param shippingOrderId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findSubscriberCompositionTrackingHistory(
    shippingOrderId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListShippingTrackingStatusDTO>;
  public findSubscriberCompositionTrackingHistory(
    shippingOrderId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListShippingTrackingStatusDTO>>;
  public findSubscriberCompositionTrackingHistory(
    shippingOrderId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListShippingTrackingStatusDTO>>;
  public findSubscriberCompositionTrackingHistory(
    shippingOrderId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (shippingOrderId === null || shippingOrderId === undefined) {
      throw new Error(
        'Required parameter shippingOrderId was null or undefined when calling findSubscriberCompositionTrackingHistory.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListShippingTrackingStatusDTO>(
      'get',
      `${this.basePath}/subscriber/compositions/tracking/${encodeURIComponent(
        String(shippingOrderId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param subscriberId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findSubscriberCompositions(
    subscriberId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListSubscriberEditionCompositionSummary>;
  public findSubscriberCompositions(
    subscriberId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<
    HttpResponse<ResponseB4AListSubscriberEditionCompositionSummary>
  >;
  public findSubscriberCompositions(
    subscriberId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListSubscriberEditionCompositionSummary>>;
  public findSubscriberCompositions(
    subscriberId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (subscriberId === null || subscriberId === undefined) {
      throw new Error(
        'Required parameter subscriberId was null or undefined when calling findSubscriberCompositions.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListSubscriberEditionCompositionSummary>(
      'get',
      `${this.basePath}/subscriber/compositions/${encodeURIComponent(
        String(subscriberId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param subscriberId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findSubscriberCoupons(
    subscriberId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListSubscriberCouponDetail>;
  public findSubscriberCoupons(
    subscriberId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListSubscriberCouponDetail>>;
  public findSubscriberCoupons(
    subscriberId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListSubscriberCouponDetail>>;
  public findSubscriberCoupons(
    subscriberId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (subscriberId === null || subscriberId === undefined) {
      throw new Error(
        'Required parameter subscriberId was null or undefined when calling findSubscriberCoupons.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListSubscriberCouponDetail>(
      'get',
      `${this.basePath}/subscriber/coupons/${encodeURIComponent(
        String(subscriberId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param subscriberId
   * @param editionId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findSubscriberEditionBilling(
    subscriberId: number,
    editionId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ASubscriberBillingHistoryEdition>;
  public findSubscriberEditionBilling(
    subscriberId: number,
    editionId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ASubscriberBillingHistoryEdition>>;
  public findSubscriberEditionBilling(
    subscriberId: number,
    editionId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ASubscriberBillingHistoryEdition>>;
  public findSubscriberEditionBilling(
    subscriberId: number,
    editionId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (subscriberId === null || subscriberId === undefined) {
      throw new Error(
        'Required parameter subscriberId was null or undefined when calling findSubscriberEditionBilling.'
      );
    }

    if (editionId === null || editionId === undefined) {
      throw new Error(
        'Required parameter editionId was null or undefined when calling findSubscriberEditionBilling.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4ASubscriberBillingHistoryEdition>(
      'get',
      `${this.basePath}/subscriber/payments/billing/${encodeURIComponent(
        String(subscriberId)
      )}/${encodeURIComponent(String(editionId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findSubscriberEditionStatusList(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListSubscriberEditionStatus>;
  public findSubscriberEditionStatusList(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListSubscriberEditionStatus>>;
  public findSubscriberEditionStatusList(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListSubscriberEditionStatus>>;
  public findSubscriberEditionStatusList(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListSubscriberEditionStatus>(
      'get',
      `${this.basePath}/subscriber/edition-status`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findSubscriberEditionStatusListMillenium(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListOrderMovementStatusDescription>;
  public findSubscriberEditionStatusListMillenium(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListOrderMovementStatusDescription>>;
  public findSubscriberEditionStatusListMillenium(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListOrderMovementStatusDescription>>;
  public findSubscriberEditionStatusListMillenium(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListOrderMovementStatusDescription>(
      'get',
      `${this.basePath}/subscriber/erp-status`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param subscriberId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findSubscriberInfoById(
    subscriberId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ASubscriberInfo>;
  public findSubscriberInfoById(
    subscriberId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ASubscriberInfo>>;
  public findSubscriberInfoById(
    subscriberId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ASubscriberInfo>>;
  public findSubscriberInfoById(
    subscriberId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (subscriberId === null || subscriberId === undefined) {
      throw new Error(
        'Required parameter subscriberId was null or undefined when calling findSubscriberInfoById.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4ASubscriberInfo>(
      'get',
      `${this.basePath}/subscriber/id/${encodeURIComponent(
        String(subscriberId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param subscriberId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findSubscriberPayments(
    subscriberId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListSubscriberPaymentHistory>;
  public findSubscriberPayments(
    subscriberId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListSubscriberPaymentHistory>>;
  public findSubscriberPayments(
    subscriberId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListSubscriberPaymentHistory>>;
  public findSubscriberPayments(
    subscriberId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (subscriberId === null || subscriberId === undefined) {
      throw new Error(
        'Required parameter subscriberId was null or undefined when calling findSubscriberPayments.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListSubscriberPaymentHistory>(
      'get',
      `${this.basePath}/subscriber/creditcard/payments/${encodeURIComponent(
        String(subscriberId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param subscriberId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findSubscriberRefunds(
    subscriberId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListSubscriberRefund>;
  public findSubscriberRefunds(
    subscriberId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListSubscriberRefund>>;
  public findSubscriberRefunds(
    subscriberId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListSubscriberRefund>>;
  public findSubscriberRefunds(
    subscriberId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (subscriberId === null || subscriberId === undefined) {
      throw new Error(
        'Required parameter subscriberId was null or undefined when calling findSubscriberRefunds.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListSubscriberRefund>(
      'get',
      `${this.basePath}/subscriber/refunds/${encodeURIComponent(
        String(subscriberId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param subscriberId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findSubscriberRenewals(
    subscriberId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListSubscriberRenewalInfo>;
  public findSubscriberRenewals(
    subscriberId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListSubscriberRenewalInfo>>;
  public findSubscriberRenewals(
    subscriberId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListSubscriberRenewalInfo>>;
  public findSubscriberRenewals(
    subscriberId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (subscriberId === null || subscriberId === undefined) {
      throw new Error(
        'Required parameter subscriberId was null or undefined when calling findSubscriberRenewals.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListSubscriberRenewalInfo>(
      'get',
      `${this.basePath}/subscriber/renewals/${encodeURIComponent(
        String(subscriberId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findSubscriberStatusList(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListSubscriberStatus>;
  public findSubscriberStatusList(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListSubscriberStatus>>;
  public findSubscriberStatusList(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListSubscriberStatus>>;
  public findSubscriberStatusList(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListSubscriberStatus>(
      'get',
      `${this.basePath}/subscriber/status/list`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param subscriberId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findVoucherSubscriberBillings(
    subscriberId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListVoucherSubscriberBilling>;
  public findVoucherSubscriberBillings(
    subscriberId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListVoucherSubscriberBilling>>;
  public findVoucherSubscriberBillings(
    subscriberId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListVoucherSubscriberBilling>>;
  public findVoucherSubscriberBillings(
    subscriberId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (subscriberId === null || subscriberId === undefined) {
      throw new Error(
        'Required parameter subscriberId was null or undefined when calling findVoucherSubscriberBillings.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListVoucherSubscriberBilling>(
      'get',
      `${this.basePath}/subscriber/billings/voucher/${encodeURIComponent(
        String(subscriberId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSubscriberCancellationReasons(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListSubscriberCancellationReason>;
  public getSubscriberCancellationReasons(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListSubscriberCancellationReason>>;
  public getSubscriberCancellationReasons(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListSubscriberCancellationReason>>;
  public getSubscriberCancellationReasons(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListSubscriberCancellationReason>(
      'get',
      `${this.basePath}/subscriber/cancellation/reasons`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listSubscriberTable(
    body?: PageableRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4APageSubscriberInfo>;
  public listSubscriberTable(
    body?: PageableRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4APageSubscriberInfo>>;
  public listSubscriberTable(
    body?: PageableRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4APageSubscriberInfo>>;
  public listSubscriberTable(
    body?: PageableRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4APageSubscriberInfo>(
      'put',
      `${this.basePath}/subscriber`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param subscriberBillingId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public removeSubscriberRefund(
    subscriberBillingId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AString>;
  public removeSubscriberRefund(
    subscriberBillingId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AString>>;
  public removeSubscriberRefund(
    subscriberBillingId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AString>>;
  public removeSubscriberRefund(
    subscriberBillingId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (subscriberBillingId === null || subscriberBillingId === undefined) {
      throw new Error(
        'Required parameter subscriberBillingId was null or undefined when calling removeSubscriberRefund.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AString>(
      'delete',
      `${this.basePath}/subscriber/admin/refund/${encodeURIComponent(
        String(subscriberBillingId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public requestSubscriberEditionRefund(
    body?: SubscriberRefundRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListLong>;
  public requestSubscriberEditionRefund(
    body?: SubscriberRefundRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListLong>>;
  public requestSubscriberEditionRefund(
    body?: SubscriberRefundRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListLong>>;
  public requestSubscriberEditionRefund(
    body?: SubscriberRefundRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AListLong>(
      'post',
      `${this.basePath}/subscriber/admin/edition/refund`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateSubscriberEditionStatusDone(
    body?: SubscriberEditionStatusRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ASubscriberEditionCompositionSummary>;
  public updateSubscriberEditionStatusDone(
    body?: SubscriberEditionStatusRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ASubscriberEditionCompositionSummary>>;
  public updateSubscriberEditionStatusDone(
    body?: SubscriberEditionStatusRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ASubscriberEditionCompositionSummary>>;
  public updateSubscriberEditionStatusDone(
    body?: SubscriberEditionStatusRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4ASubscriberEditionCompositionSummary>(
      'put',
      `${this.basePath}/subscriber/edition/done`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateSubscriberEditionStatusRestored(
    body?: SubscriberEditionStatusRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ASubscriberEditionCompositionSummary>;
  public updateSubscriberEditionStatusRestored(
    body?: SubscriberEditionStatusRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ASubscriberEditionCompositionSummary>>;
  public updateSubscriberEditionStatusRestored(
    body?: SubscriberEditionStatusRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ASubscriberEditionCompositionSummary>>;
  public updateSubscriberEditionStatusRestored(
    body?: SubscriberEditionStatusRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4ASubscriberEditionCompositionSummary>(
      'put',
      `${this.basePath}/subscriber/edition/restore`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateSubscriberStatus(
    body?: SubscriberStatusChangeRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ASubscriberInfo>;
  public updateSubscriberStatus(
    body?: SubscriberStatusChangeRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ASubscriberInfo>>;
  public updateSubscriberStatus(
    body?: SubscriberStatusChangeRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ASubscriberInfo>>;
  public updateSubscriberStatus(
    body?: SubscriberStatusChangeRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4ASubscriberInfo>(
      'put',
      `${this.basePath}/subscriber/status/change`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}

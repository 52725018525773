<div class="coupons-dashboard">
  <h3>Dashboard cupons</h3>
  <div class="filters">
    <form class="form-b4a" [formGroup]="form">
      <span class="p-float-label">
        <p-dropdown
          inputId="subscriptionId"
          [options]="subscriptions"
          (onChange)="changeSubscription()"
          formControlName="subscriptionId"
          appendTo="body"
          [autoDisplayFirst]="false"
        />
        <label for="subscriptionId">Assinatura</label>
      </span>
      <span class="p-float-label">
        <p-dropdown
          inputId="editionId"
          [options]="editions"
          *ngIf="editions"
          optionValue="editionId"
          optionLabel="theme"
          (onChange)="changeEdition()"
          [filter]="true"
          filterBy="theme,title,editionId,description"
          filterMatchMode="contains"
          [autoDisplayFirst]="false"
          formControlName="editionId"
          appendTo="body"
        >
          <ng-template pTemplate="item" let-edition>
            <ng-container *ngIf="form.value.subscriptionId">
              {{ edition.theme }}
              {{ editionDate(edition.editionId) }}
            </ng-container>
            <ng-container *ngIf="!form.value.subscriptionId">
              {{ editionDateName(edition.editionId) | titlecase }}
            </ng-container>
          </ng-template>
          <ng-template pTemplate="selectedItem">
            <ng-container *ngIf="form.value.subscriptionId">
              {{ edition?.theme }}
            </ng-container>
            <ng-container
              *ngIf="edition && edition.editionId && !form.value.subscriptionId"
            >
              {{ editionDateName(edition.editionId) | titlecase }}
            </ng-container>
          </ng-template>
        </p-dropdown>
        <label for="editionId">Edição</label>
      </span>
    </form>
    <div class="picture">
      <app-edition-image
        [imageURL]="edition.imageURL"
        [editionId]="edition.editionId"
        *ngIf="edition"
      />
    </div>
  </div>
  <div class="dashboard" *ngIf="ready">
    <div class="left-info">
      <app-coupons-usage-summary-report
        [coupons]="couponUsages"
        [couponUsageTypes]="couponUsageTypes"
        [editionId]="form.value.editionId"
        *ngIf="couponUsages && couponUsageTypes"
      />
    </div>
    <div class="right-info">
      <app-coupons-report
        [editionId]="form.value.editionId"
        [coupons]="couponUsages"
        [usageTypes]="couponUsageTypes"
        *ngIf="couponUsages && couponUsageTypes && ready"
      />
    </div>
    <div class="right-info charts">
      <h3>Todas</h3>
      <p-chart
        type="bar"
        [data]="chartData"
        [options]="chartConfig"
        id="chart"
        [responsive]="false"
        [width]="width"
        height="500px"
        #chart
        *ngIf="chartData && width && ready"
      />
      <h3>Aquisições</h3>
      <p-chart
        type="bar"
        [data]="chartDataAcquisition"
        [options]="chartConfig"
        id="chart"
        [responsive]="false"
        [width]="width"
        height="500px"
        #chart
        *ngIf="chartDataAcquisition && width && ready"
      />
      <br />
      <h3>Reativações</h3>
      <p-chart
        type="bar"
        [data]="chartDataReactivation"
        [options]="chartConfig"
        id="chart"
        [responsive]="false"
        [width]="width"
        height="500px"
        #chart
        *ngIf="chartDataReactivation && width && ready"
      />
    </div>
  </div>
</div>

import { Component, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import {
  ItOpsControllerService,
  PendingWithoutRecurrence
} from 'src/app/admin-api';
import { TableColumn, TableComponent } from 'src/app/components/table';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-pending-whithout-recurrence',
  templateUrl: './pending-whithout-recurrence.component.html',
  styleUrls: ['./pending-whithout-recurrence.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class PendingWhithoutRecurrenceComponent {
  @ViewChild(TableComponent) table: TableComponent;

  columns: Array<TableColumn> = [
    new TableColumn('', '', false, 'checkbox'),
    new TableColumn(
      'SubscriberId',
      'subscriberId',
      true,
      'number',
      '/users/subscribers/',
      'subscriberId'
    ),
    new TableColumn(
      'PersonId',
      'personId',
      true,
      'number',
      '/users/person/',
      'personId'
    ),
    new TableColumn('Edição', 'editionId', true, 'number'),
    new TableColumn('PaymentId', 'paymentId', true, 'number'),
    new TableColumn('Cartão Correto', 'correctCard', true, 'number'),
    new TableColumn('Ação', '', false, 'button')
  ];

  constructor(
    public itOpsService: ItOpsControllerService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  markAsResolved(elements: Array<PendingWithoutRecurrence>): void {
    this.confirmationService.confirm({
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      rejectButtonStyleClass: 'p-button-danger',
      message: 'Deseja enviar o relatório?',
      header: 'Corrigir',
      accept: async () => {
        LoaderService.showLoader();
        try {
          await lastValueFrom(this.itOpsService.fixRecurrenceIsNull(elements));
          await this.table.refresh(true);
          this.messageService.add({
            summary: 'Sucesso',
            detail: elements.length + ' marcado(s) como resolvido(s)',
            severity: 'success'
          });
          LoaderService.showLoader(false);
        } catch (error) {
          LoaderService.showLoader(false);
          AppDialogService.showErrorDialog(error);
        }
      }
    });
  }
}

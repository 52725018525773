<app-table
  [lazy]="true"
  [cols]="columns"
  tableTitle="Sessões ativas"
  [addButton]="false"
  [exportPdf]="false"
  [exportExcel]="false"
  [refreshButton]="true"
  [service]="itOpsService"
  pageFunctionName="findActiveSessionsTable"
  emptyMessage="Não há sessões ativas"
  selectionActionLabel="Encerrar"
  selectionActionTooltip="Encerrar as sessões"
  selectionActionIcon="pi pi-power-off"
  (emitSelection)="kill($event)"
  [changeColumns]="true"
  modelName="Sessões"
  [defaultRows]="15"
></app-table>
<p-confirmDialog></p-confirmDialog>
<p-toast></p-toast>

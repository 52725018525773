import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
  WritableSignal,
  computed,
  inject,
  signal
} from '@angular/core';
import { TreeNode } from 'primeng/api';
import { TreeNodeCollapseEvent, TreeNodeExpandEvent } from 'primeng/tree';
import { lastValueFrom, map } from 'rxjs';
import {
  Attribute,
  AttributeGroup,
  AttributeOption,
  BeautyProfileControllerService,
  PersonActiveAttributeOptionsGrouped
} from 'src/app/admin-api';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FileUtil } from 'src/app/utils/file.util';
import { FormUtil } from 'src/app/utils/form.util';
import * as xlsx from 'xlsx';

@Component({
  selector: 'app-beauty-profile-answers',
  templateUrl: './beauty-profile-answers.component.html',
  styleUrl: './beauty-profile-answers.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class BeautyProfileAnswersComponent implements OnInit {
  private beautyProfileService: BeautyProfileControllerService = inject(
    BeautyProfileControllerService
  );

  @Input()
  groups: WritableSignal<Array<AttributeGroup>> = signal(undefined);

  @Input()
  subscriberCount: number;

  answers: WritableSignal<Array<PersonActiveAttributeOptionsGrouped>> =
    signal(undefined);

  nodes = computed(() => {
    return this.groups()
      ?.filter(
        (g) =>
          g.active &&
          (g.attributes.some((a) => a.active) ||
            g.groups?.some((ag) => ag.active))
      )
      ?.map((g) =>
        FormUtil.groupToNode(
          g,
          this.expandedGroups,
          this.expandedAttributes,
          true
        )
      );
  });
  expandedGroups: Array<string> = [];
  expandedAttributes: Array<string> = [];

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader();
    const promises = [this.findAnswers()];
    if (!this.groups?.length) promises.push(this.findBeautyProfile());
    await Promise.all(promises);
    LoaderService.showLoader(false);
  }

  async findBeautyProfile(): Promise<void> {
    try {
      this.groups.set(
        await lastValueFrom(
          this.beautyProfileService
            .findBeautyProfileForm()
            .pipe(map((data) => data.result))
        )
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findAnswers(): Promise<void> {
    try {
      this.answers.set(
        await lastValueFrom(
          this.beautyProfileService
            .findBeautyProfilesActiveReport()
            .pipe(map((data) => data.result))
        )
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  onExpandCollapse($event: TreeNodeCollapseEvent | TreeNodeExpandEvent): void {
    if ($event.node.type === 'attribute') {
      if (!$event.node.expanded) {
        const index = this.expandedAttributes.indexOf($event.node.key);
        if (index >= 0) this.expandedAttributes.splice(index, 1);
      } else this.expandedAttributes.push($event.node.key);
    } else {
      if (!$event.node.expanded) {
        const index = this.expandedGroups.indexOf($event.node.key);
        if (index >= 0) this.expandedGroups.splice(index, 1);
      } else this.expandedGroups.push($event.node.key);
    }
  }

  attributeGroupAnswers(
    group: TreeNode<AttributeGroup>
  ): Array<PersonActiveAttributeOptionsGrouped> {
    if (group.data.attributeGroupReferenceId) {
      return this.answers()?.filter((a) =>
        group.children
          .filter((c) => c.type === 'attribute')
          .some((c) => c.key === a.attributeId.toString())
      );
    } else {
      return this.answers()
        ?.filter((a) =>
          group.children
            .filter((c) => c.type === 'attribute')
            .some((c) => c.key === a.attributeId.toString())
        )
        .concat(
          group.children
            .filter((c) => c.type === 'attributeGroup')
            .map((c) => this.attributeGroupAnswers(c))
            .reduce((list, c) => (list = list.concat(c)), [])
        );
    }
  }

  attributeGroupAnswerCount(node: TreeNode<AttributeGroup>): number {
    return this.attributeGroupAnswers(node).reduce(
      (g1, g2) => Math.max(g1, g2.attributeAnswers),
      0
    );
  }

  attributeGroupAnswerPercentage(node: TreeNode<AttributeGroup>): number {
    return this.attributeGroupAnswerCount(node) / (this.subscriberCount || 1);
  }

  attributeAnswers(
    attributeId: number
  ): Array<PersonActiveAttributeOptionsGrouped> {
    return this.answers()?.filter((a) => a.attributeId === attributeId);
  }

  attributeAnswerCount(attributeId: number): number {
    return this.attributeAnswers(attributeId)?.length
      ? this.attributeAnswers(attributeId)[0].attributeAnswers
      : 0;
  }

  attributeAnswerPercentage(node: TreeNode<Attribute>): number {
    return (
      this.attributeAnswerCount(node.data.attributeId) /
      (this.subscriberCount || 1)
    );
  }

  attributeOptionAnswers(
    optionId: number
  ): PersonActiveAttributeOptionsGrouped {
    return this.answers()?.find((a) => a.attributeOptionId === optionId);
  }

  attributeOptionAnswerCount(optionId: number): number {
    return this.attributeOptionAnswers(optionId)?.optionAnswers || 0;
  }

  attributeOptionAnswerPercentage(node: TreeNode<AttributeOption>): number {
    return (
      this.attributeOptionAnswerCount(node.data.attributeOptionId) /
      (this.attributeAnswerCount(node.parent.data.attributeId) || 1)
    );
  }

  exportExcel(): void {
    const worksheet = xlsx.utils.book_new();
    let array = [['Perfil de beleza', 'Respostas', '% das Assinantes'], [], []];
    for (let index = 0; index < this.nodes().length; index++) {
      array = this.addGroupToArray(this.nodes()[index], array);
    }
    xlsx.utils.sheet_add_aoa(worksheet, array);
    Object.keys(worksheet).forEach((key) => {
      if (key[0] === 'B' && Number(key.substring(1)) > 1) {
        worksheet[key].t = 'n';
        worksheet[key].z = '#,##0';
      } else if (key[0] === 'C' && Number(key.substring(1)) > 1) {
        worksheet[key].t = 'n';
        worksheet[key].z = '0.00%';
      }
    });

    const workbook = {
      Sheets: { 'Perfil de beleza': worksheet },
      SheetNames: ['Perfil de beleza']
    };
    const excelBuffer: xlsx.WorkSheet = xlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    });
    FileUtil.saveAsExcelFile(excelBuffer as BlobPart, 'beauty-profile');
  }

  addGroupToArray(
    groupNode: TreeNode<AttributeGroup>,
    array: Array<Array<string>>
  ): Array<Array<string>> {
    array.push([
      `${
        groupNode.data.attributeGroupReferenceId
          ? groupNode.data.order + ') '
          : ''
      }${
        groupNode.data.attributeGroupQuestion ||
        groupNode.data.attributeGroupName
      }`,
      this.attributeGroupAnswerCount(groupNode).toString(),
      this.attributeGroupAnswerPercentage(groupNode).toString()
    ]);
    const questions = groupNode.children;
    for (let index = 0; index < questions.length; index++) {
      const node = questions[index];
      array =
        node.type === 'attributeGroup'
          ? this.addGroupToArray(node, array)
          : this.addAttributeToArray(node, array);
    }
    array.push([]);
    array.push([]);
    return array;
  }

  private addAttributeToArray(
    node: TreeNode<Attribute>,
    array: Array<Array<string>>
  ): Array<Array<string>> {
    const q = node.data as Attribute;
    array.push([
      `${q.order}) ${q.attributeQuestion || q.attributeName}`,
      this.attributeAnswerCount(q.attributeId).toString(),
      this.attributeAnswerPercentage(node).toString()
    ]);
    const options = node.children as Array<TreeNode<AttributeOption>>;
    for (let index = 0; index < options.length; index++) {
      const o = options[index];
      array.push([
        `${o.data.order}. ${o.data.attributeOptionName}`,
        this.attributeOptionAnswerCount(o.data.attributeOptionId).toString(),
        this.attributeOptionAnswerPercentage({ ...o, parent: node }).toString()
      ]);
    }
    array.push([]);
    return array;
  }
}

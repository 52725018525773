<form (submit)="submit()" [formGroup]="form" class="modal-form" *ngIf="form">
  <span class="p-float-label">
    <input
      type="text"
      pInputText
      formControlName="attributeGroupName"
      id="attributeGroupName"
      name="attributeGroupName"
    />
    <label for="attributeGroupName">Nome do grupo *</label>
  </span>
  <span
    class="p-float-label"
    *ngIf="form.controls['attributeGroupQuestion'].enabled"
  >
    <input
      type="text"
      pInputText
      formControlName="attributeGroupQuestion"
      id="attributeGroupQuestion"
      name="attributeGroupQuestion"
    />
    <label for="attributeGroupQuestion">Pergunta do grupo *</label>
  </span>
  <span class="input-switch">
    <label for="active">Inativo</label>
    <p-inputSwitch formControlName="active" inputId="active" />
    <label for="active">Ativo</label>
  </span>
  <span class="buttons">
    <p-button
      type="button"
      severity="danger"
      label="Voltar"
      (onClick)="ref.close()"
    />
    <p-button
      type="submit"
      label="Salvar"
      severity="primary"
      [disabled]="!form.valid"
    />
  </span>
</form>

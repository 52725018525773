import {
  Component,
  OnInit,
  WritableSignal,
  computed,
  inject,
  signal
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LoaderService } from 'src/app/services/loader.service';
import { FileUtil } from 'src/app/utils/file.util';

@Component({
  selector: 'app-image-cropper-modal',
  templateUrl: './image-cropper-modal.component.html',
  styleUrl: './image-cropper-modal.component.scss'
})
export class ImageCropperModalComponent implements OnInit {
  ref: DynamicDialogRef = inject(DynamicDialogRef);
  private config: DynamicDialogConfig = inject(DynamicDialogConfig);
  private sanitizer: DomSanitizer = inject(DomSanitizer);

  file: WritableSignal<File> = signal(undefined);
  base64 = computed(() => {
    return this.file() ? FileUtil.getBase64(this.file()) : undefined;
  });
  croppedImage: WritableSignal<Blob> = signal(undefined);
  croppedBase64: string;

  croppedWidth: number = this.config.data.fixedWidth;
  croppedHeight: number = this.config.data.fixedHeight;

  ngOnInit(): void {
    this.file.set(this.config.data.image || this.config.data.file);
    if (!this.file()) {
      throw 'Please provide data.file or data.image as the orignal file';
    }
    this.ref.onMaximize.subscribe(() => {
      LoaderService.showLoader();
      this.file.set(undefined);
      setTimeout(() => {
        this.file.set(this.config.data.image || this.config.data.file);
        LoaderService.showLoader(false);
      });
    });
  }

  async imageCropped(event: ImageCroppedEvent): Promise<void> {
    this.croppedImage.set(event.blob);
    this.croppedBase64 = await FileUtil.getBase64(
      new File([this.croppedImage()], this.file().name)
    );
    // event.blob can be used to upload the cropped image
  }

  dismiss(): void {
    this.ref.close(new File([this.croppedImage()], this.file().name));
  }
}

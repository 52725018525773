import { Component, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ConfirmationService, MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { ActiveSession, ItOpsControllerService } from 'src/app/admin-api';
import { TableColumn, TableComponent } from 'src/app/components/table';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-database-sessions',
  templateUrl: './database-sessions.component.html',
  styleUrls: ['./database-sessions.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class DatabaseSessionsComponent {
  @ViewChild(TableComponent) table: TableComponent;

  columns: Array<TableColumn> = [
    new TableColumn('', '', false, 'checkbox'),
    new TableColumn('SessionId', 'sessionId', true, 'number'),
    new TableColumn('Database', 'databaseName', true, 'text'),
    new TableColumn('Início', 'transactionBeginTime', true, 'date'),
    new TableColumn('Duração', 'transactionDuration', true, 'number'),
    new TableColumn('Tipo', 'transactionType', true, 'text'),
    new TableColumn('Usuário', 'isUserTransaction', true, 'boolean'),
    new TableColumn('Hostname', 'hostName', true, 'text'),
    new TableColumn('Username', 'loginName', true, 'number'),
    new TableColumn('Comando', 'inputBuffer', true, 'text')
  ];

  constructor(
    public itOpsService: ItOpsControllerService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private title: Title
  ) {
    this.title.setTitle('Sessões ativas');
  }

  kill(elements: Array<ActiveSession>): void {
    this.confirmationService.confirm({
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      rejectButtonStyleClass: 'p-button-danger',
      message: 'Deseja matar o(s) processo(s)?',
      header: 'Encerrar',
      accept: async () => {
        LoaderService.showLoader();
        try {
          for (let index = 0; index < elements.length; index++) {
            await lastValueFrom(
              this.itOpsService.killSession(elements[index].sessionId)
            );
          }
          this.messageService.add({
            summary: 'Sucesso',
            detail: elements.length + ' processo(s) finalizado(s)',
            severity: 'success'
          });
          LoaderService.showLoader(false);
        } catch (error) {
          LoaderService.showLoader(false);
          AppDialogService.showErrorDialog(error);
        }
        await this.table.refresh(true);
      }
    });
  }
}

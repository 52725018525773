import {
  Component,
  OnInit,
  WritableSignal,
  computed,
  inject,
  signal
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { lastValueFrom, map } from 'rxjs';
import {
  PageableRequest,
  ProductCategory,
  ProductControllerService,
  ProductVariantDetail
} from 'src/app/admin-api';
import {
  FilterChangedEvent,
  PageContent,
  TableColumn
} from 'src/app/components/table';
import { getAllBrandEquities, getEquityLabel } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.scss']
})
export class StockComponent implements OnInit {
  productService: ProductControllerService = inject(ProductControllerService);
  private title: Title = inject(Title);

  cols: Array<TableColumn> = [
    new TableColumn(
      'EAN',
      'internalEAN',
      true,
      'text',
      '/products/catalog/product-variant/',
      'productVariantId',
      true,
      'contains'
    ),
    new TableColumn(
      'SKU Admin',
      'productVariantId',
      true,
      'number',
      '/products/catalog/product-variant/',
      'productVariantId',
      true,
      'equals'
    ),
    new TableColumn(
      'Millennium SKU',
      'externalId',
      true,
      'number',
      '/products/catalog/product-variant/',
      'productVariantId',
      true,
      'equals'
    ),
    new TableColumn(
      'Marca',
      'brandName',
      true,
      'text',
      '/products/brands/',
      'brandId',
      true,
      'contains'
    ),
    new TableColumn(
      'Produto',
      'productVariantName',
      true,
      'text',
      '/products/catalog/product-variant/',
      'productVariantId',
      true,
      'contains'
    ),
    new TableColumn(
      'Categoria',
      'category',
      true,
      'text',
      '/products/categories/',
      'categoryId',
      true,
      'in'
    ),
    new TableColumn(
      'Sub. Categoria',
      'subcategory',
      true,
      'text',
      '/products/categories/',
      'subcategoryId',
      true,
      'in'
    ),
    new TableColumn(
      'Custo',
      'costPrice',
      true,
      'currency',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Valor de mercado',
      'salePrice',
      true,
      'currency',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Brand Equity',
      'brandEquityLabel',
      true,
      'text',
      undefined,
      undefined,
      true,
      'in'
    ),
    new TableColumn(
      'Estoque aquisição',
      'inStockAcquisition',
      true,
      'formattedInteger',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Estoque fidelização',
      'inStockFidelization',
      true,
      'formattedInteger',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Estoque Res. Marketing',
      'inStockReserveMarketing',
      true,
      'formattedInteger',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Estoque influencer',
      'inStockInfluencerB4A',
      true,
      'formattedInteger',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Reserva alocação',
      'reservedAllocation',
      true,
      'formattedInteger',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Finalidade',
      'finalities',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Expiração',
      'expiration',
      true,
      'date',
      undefined,
      undefined,
      true,
      'lte'
    ),
    new TableColumn(
      'Dias a vencer',
      'daysToExpire',
      true,
      'formattedInteger',
      undefined,
      undefined,
      true,
      'lte'
    )
  ];

  categories: WritableSignal<Array<ProductCategory>> = signal(undefined);
  parentCategories = computed(() => {
    return this.categories()?.filter((c) => !c.parentId);
  });
  subcategories = computed(() => {
    return this.parentCategories()
      ?.filter(
        (c) =>
          !this.categoryIds()?.length ||
          this.categoryIds()?.includes(c.categoryId)
      )
      ?.map((c) => ({
        ...c,
        categories: this.categories()?.filter(
          (sc) => sc.parentId === c.categoryId
        )
      }));
  });
  dropdownFilters = computed(() => {
    return {
      brandEquityLabel: getAllBrandEquities().map((e) => ({
        label: e.label,
        value: e.value.toString()
      })),
      category:
        this.parentCategories()?.map((c) => ({
          label: `${c.externalId}. ${c.displayName}`,
          value: c.categoryId?.toString()
        })) || [],
      subcategory:
        this.subcategories()?.map((s) => ({
          label: `${s.externalId}. ${s.displayName}`,
          value: s.categoryId?.toString(),
          items: s.categories?.length
            ? s.categories.map((sc) => ({
                label: `${s.externalId}.${sc.externalId} ${sc.displayName}`,
                value: sc.categoryId?.toString()
              }))
            : null
        })) || []
    };
  });
  categoryIds: WritableSignal<Array<number>> = signal([]);

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader();
    await this.findCategories();
  }

  async findCategories(): Promise<void> {
    try {
      this.categories.set(
        await lastValueFrom(
          this.productService
            .findProductCategoriesList()
            .pipe(map((data) => data.result))
        )
      );
    } catch (error) {
      this.categories.set([]);
      AppDialogService.showErrorDialog(error);
    }
  }

  filterChanged($event: FilterChangedEvent): void {
    if ($event.field === 'category') {
      try {
        if ($event.value)
          this.categoryIds.set(
            ($event.value as Array<string>).map((v) => Number(v))
          );
        else this.categoryIds.set([]);
      } catch (error) {
        this.categoryIds.set([]);
      }
    }
  }

  constructor() {
    this.title.setTitle('Estoque de Produtos');
  }

  async findPage(
    request: PageableRequest,
    productService: unknown
  ): Promise<PageContent<ProductVariantDetail>> {
    const filterEquity = request.filters?.find(
      (f) => f.field === 'brandEquityLabel'
    );
    if ((filterEquity?.value as Array<string>)?.length) {
      request.filters?.push({
        condition: 'in',
        field: 'brandEquity',
        fieldType: 'number',
        value: filterEquity.value
      });
    }
    const filterCategory = request.filters?.find((f) => f.field === 'category');
    if ((filterCategory?.value as Array<string>)?.length) {
      request.filters?.push({
        condition: 'in',
        field: 'categoryId',
        fieldType: 'number',
        value: filterCategory.value
      });
      filterCategory;
    }
    const filterSubcategory = request.filters?.find(
      (f) => f.field === 'subcategory'
    );
    if ((filterSubcategory?.value as Array<string>)?.length) {
      request.filters?.push({
        condition: 'in',
        field: 'subcategoryId',
        fieldType: 'number',
        value: filterSubcategory.value
      });
    }
    return lastValueFrom(
      (productService as ProductControllerService)
        .findProductVariantsTable(request)
        .pipe(
          map((data) => {
            if (data.result?.content) {
              data.result.content = data.result.content.map((p) => ({
                ...p,
                brandEquityLabel: `${getEquityLabel(p.brandEquity as number)}`
              }));
            }
            return data.result as PageContent<ProductVariantDetail>;
          })
        )
    );
  }
}

/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BadgeRuleDetailed { 
    badgeRuleId?: number;
    badgeId?: number;
    badgeName?: string;
    quantity?: number;
    personTypeId?: number;
    personTypeDescription?: string;
    description?: string;
    actionType?: number;
    actionDescription?: string;
    periodType?: number;
    periodDescription?: string;
    periodValue?: number;
}
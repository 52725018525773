<form class="modal-form">
  <p-accordion [multiple]="true">
    <p-accordionTab header="Descrição">
      <div [innerHTML]="product.description.description"></div>
    </p-accordionTab>
    <p-accordionTab
      header="Diferenciais"
      *ngIf="product.description.differentials"
    >
      <div [innerHTML]="product.description.differentials"></div>
    </p-accordionTab>
    <p-accordionTab header="Como usar" *ngIf="product.description.howToUse">
      <div [innerHTML]="product.description.howToUse"></div>
    </p-accordionTab>
    <p-accordionTab
      header="Para quem é indicado"
      *ngIf="product.description.whoMustUse"
    >
      <div [innerHTML]="product.description.whoMustUse"></div>
    </p-accordionTab>
    <p-accordionTab header="Sobre a marca" *ngIf="product.description.brand">
      <div [innerHTML]="product.description.brand"></div>
    </p-accordionTab>
    <p-accordionTab
      header="Ingredientes"
      *ngIf="product.description.ingredients"
    >
      <div [innerHTML]="product.description.ingredients"></div>
    </p-accordionTab>
    <p-accordionTab header="Propósito" *ngIf="product.description.purpose">
      <div [innerHTML]="product.description.purpose"></div>
    </p-accordionTab>
    <p-accordionTab header="Vídeo" *ngIf="videoHtml">
      <div [innerHTML]="videoHtml"></div>
      <!-- <iframe width="1236" height="695" [src]="videoUrl"></iframe> -->
    </p-accordionTab>
  </p-accordion>
  <div class="buttons">
    <p-button
      severity="danger"
      label="Voltar"
      type="button"
      (onClick)="ref.close()"
    />
    <p-button
      severity="primary"
      label="Está ok"
      type="button"
      (onClick)="submit()"
    />
  </div>
</form>

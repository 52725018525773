import { Clipboard } from '@angular/cdk/clipboard';
import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  CanceledWithPendingBilling,
  ItOpsControllerService
} from 'src/app/admin-api';
import { SubscriberProblemGroupEnum } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-canceled-with-pending-billing-modal',
  templateUrl: './canceled-with-pending-billing-modal.component.html',
  styleUrls: ['./canceled-with-pending-billing-modal.component.scss']
})
export class CanceledWithPendingBillingModalComponent {
  elements: Array<CanceledWithPendingBilling>;
  canceledUpdate: string;

  constructor(
    private config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private itOpsService: ItOpsControllerService,
    private clipboard: Clipboard,
    private messageService: MessageService
  ) {
    this.elements = this.config.data.elements;
    this.canceledUpdate = this.elements?.reduce((sql, elements) => {
      if (elements.subscriberBillingId)
        sql += `UPDATE [Glambox.Subscription].dbo.SubscriberBilling set SubscriberBillingStatus = 2 where SubscriberBillingId = ${elements.subscriberBillingId};\n`;
      return sql;
    }, '');
  }

  async markAsResolved(): Promise<void> {
    LoaderService.showLoader();
    try {
      const dashboard = await lastValueFrom(
        this.itOpsService
          .markAsResolved(
            SubscriberProblemGroupEnum.CanceledWithPendingBilling,
            this.elements
          )
          .pipe(map((data) => data.result))
      );
      this.ref.close(dashboard);
    } catch (error) {
      LoaderService.showLoader(false);
      AppDialogService.showErrorDialog(error);
    }
  }

  copyToClipboard(text: string): void {
    this.clipboard.copy(text);
    this.messageService.add({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Copiado para área de trasnferência'
    });
  }
}

<app-table
  [cols]="cols"
  [findPage]="findPage"
  [service]="badgeService"
  modelName="Jóias"
  sortField="badgeId"
  addLabel="Nova Jóia"
  [addButton]="permission"
>
</app-table>

export enum SubscriptionEnum {
  'Glambox' = 1,
  'Glambag' = 5,
  'Glampass' = 6,
  'Glamcombo' = 7
}

export function getAllSubscriptions(): Array<{ label: string; value: number }> {
  const objetos: Array<{ label: string; value: number }> = [];
  for (const [p, propertyValue] of Object.entries(SubscriptionEnum)) {
    try {
      if (Number(propertyValue) >= 0) {
        objetos.push({
          label: p,
          value: Number(propertyValue)
        });
      }
    } catch (error: any) {
      objetos.length;
    }
  }
  return objetos;
}

export function getAllSubscriptionsIncludeAll(): Array<{
  label: string;
  value: number;
}> {
  const objetos: Array<{ label: string; value: number }> = [];
  for (const [p, propertyValue] of Object.entries(SubscriptionEnum)) {
    try {
      if (Number(propertyValue) >= 0) {
        objetos.push({
          label: p,
          value: Number(propertyValue)
        });
      }
    } catch (error: any) {
      objetos.length;
    }
  }
  return objetos.concat([
    {
      label: 'Todas',
      value: 0
    }
  ]);
}

export function getSubscriptionName(id: number): string | undefined {
  return (
    getAllSubscriptions().find((e) => e.value === id)?.label || 'Não encontrado'
  );
}

export function getSubscriptionIdsByPriority(): Array<SubscriptionEnum> {
  return [
    SubscriptionEnum.Glamcombo,
    SubscriptionEnum.Glambox,
    SubscriptionEnum.Glambag,
    SubscriptionEnum.Glampass
  ];
}

export function getSubscriptionImage(subscriptionId: number): string {
  const name = getSubscriptionName(subscriptionId)
    .toLowerCase()
    .replace('glam', '');
  return `https://storage.googleapis.com/b4aprodution.appspot.com/glambox/assets/images/box-home/icons/${name}-desk.webp`;
}

<p-chip
  [label]="
    composition?.pending > 0
      ? 'Pendentes (' + (composition.pending | number : '1.0-0') + ')'
      : 'Produção finalizada'
  "
  [icon]="
    composition?.pending > 0 ? 'pi pi-exclamation-triangle' : 'pi pi-check'
  "
  [style]="{
    'background-color': composition?.pending > 0 ? '#f0ad4e' : '#5cb85c',
    color: 'white',
    'margin-bottom': '15px'
  }"
/>
<form
  class="form-b4a"
  [formGroup]="form"
  (submit)="submit()"
  *ngIf="composition?.pending && permission"
>
  <span class="p-float-label">
    <p-inputNumber formControlName="quantity" id="quantity" name="quantity" />
    <label for="quantity">Quantidade *</label>
  </span>
  <p-button
    icon="pi pi-plus-circle"
    severity="success"
    label="Adicionar"
    type="submit"
  />
</form>
<app-table
  *ngIf="composition && ready"
  [lazy]="false"
  [elements]="composition.productions"
  [cols]="cols"
  [showHeader]="false"
  modelName="registros"
  sortField="dateCreated"
  [sortOrder]="1"
  [actionButtons]="actionButtons"
  (actionButtonClick)="deleteComposition($event)"
  emptyMessage="Produção ainda não iniciada"
/>
<p-toast />
<p-confirmPopup />

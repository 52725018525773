/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CreditCardRecurrenceWithCard {
  creditCardId?: number;
  personId?: number;
  subscriberId?: number;
  creditCardFlagId?: number;
  nameOnCard?: string;
  expYear?: number;
  expMonth?: number;
  dateCreated?: Date;
  dateUpdated?: Date;
  cardToken?: string;
  cardIDMercadoPago?: string;
  deviceId?: string;
  cpf?: number;
  pagarmeCardToken?: string;
  customerIDMercadoPago?: string;
  firstDigits?: string;
  lastDigits?: string;
  preferredGatewayId?: number;
  interval?: number;
  amount?: number;
  installments?: number;
  recurrenceStatusId?: number;
  maxAttempts?: number;
  recurrence?: number;
  attempt?: number;
  lastAttemptDate?: Date;
  nextAttemptDate?: Date;
  nextPaymentDate?: Date;
  creditCardRecurrenceStatusName?: string;
  creditCardFlag?: string;
}

<div class="drag-container" cdkDropListGroup>
  <div
    cdkDropList
    (cdkDropListEntered)="enter($event)"
    (cdkDropListDropped)="drop()"
    #placeholder
  ></div>
  <div
    cdkDropList
    *ngFor="let item of list; let i = index"
    (cdkDropListEntered)="enter($event)"
    (cdkDropListDropped)="drop()"
    [ngClass]="itemsClass"
  >
    <div cdkDragBoundary=".drag-container" cdkDrag class="drag-item">
      <ng-container
        [ngTemplateOutlet]="template"
        [ngTemplateOutletContext]="{ data: item }"
      />
    </div>
  </div>
</div>

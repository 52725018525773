import {
  Component,
  computed,
  HostListener,
  inject,
  OnDestroy,
  OnInit,
  signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import { getToken, Messaging, onMessage } from '@angular/fire/messaging';
import { lastValueFrom, map } from 'rxjs';
import {
  OpenControllerService,
  SubscriptionsTotalResponse
} from 'src/app/admin-api';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { AppDialogService } from 'src/app/services/dialog.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit, OnDestroy {
  private messaging = inject(Messaging);
  private openService = inject(OpenControllerService);

  width = computed(() =>
    this.subscriptionId() !== undefined ? 0 : this.clientWidth()
  );
  height = computed(() =>
    this.subscriptionId() !== undefined ? 0 : this.clientHeight()
  );
  clientHeight: WritableSignal<number> = signal(undefined);
  clientWidth: WritableSignal<number> = signal(undefined);
  index = signal(0);
  interval: NodeJS.Timeout;
  subscriptions = this.subscriptionsAvailable;
  subscriptionId = computed(() =>
    this.index() >= 0 ? this.subscriptions[this.index()] : undefined
  );
  signals: { [subscriptionId: number]: WritableSignal<boolean> } = {
    0: signal(true),
    1: signal(false)
  };
  lastUpdate = new Date();
  ready = false;
  subscribersRealtimeTotal: WritableSignal<SubscriptionsTotalResponse> =
    signal(undefined);

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.setSizes();
  }

  setSizes(): void {
    this.clientWidth.set(document.body.clientWidth - 5);
    this.clientHeight.set(document.body.clientHeight - 5);
  }

  ngOnInit(): void {
    Notification.requestPermission().then(async (permissions) => {
      if (permissions === 'granted')
        try {
          const serviceWorkerRegistration =
            await navigator.serviceWorker.register(
              '/assets/firebase-messaging-sw.js',
              {
                type: 'module'
              }
            );
          TokenStorageService.fcmToken = await getToken(this.messaging, {
            vapidKey: environment.firebase.vapidKey,
            serviceWorkerRegistration
          });
          this.subscribeToRefreshTopic();
        } catch (error) {
          AppDialogService.showErrorDialog(error);
        }
    });
    onMessage(this.messaging, (msg) => {
      if (
        msg.data['topic'] &&
        msg.data['topic'].replace('-dev', '') === 'refresh'
      ) {
        window.location.reload();
      }
    });
    this.setSizes();
    this.ready = true;
    this.interval = setInterval(() => {
      if (this.subscribersRealtimeTotal()?.createdAt)
        this.updateSubscriptionId();
    }, 20000);
  }

  async subscribeToRefreshTopic() {
    const topic = 'refresh'.concat(!environment.production ? '-dev' : '');
    try {
      const detail = await lastValueFrom(
        this.openService
          .subscribeToTopic(topic, TokenStorageService.fcmToken)
          .pipe(map((data) => data.result))
      );
      console.log(detail);
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async unsubscribeFromRefreshTopic() {
    const topic = 'refresh'.concat(!environment.production ? '-dev' : '');
    try {
      const detail = await lastValueFrom(
        this.openService
          .unsubscribeFromTopic(topic, TokenStorageService.fcmToken)
          .pipe(map((data) => data.result))
      );
      console.log(detail);
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
      delete this.interval;
    }
    this.unsubscribeFromRefreshTopic();
  }

  updateSubscriptionId(): void {
    if (this.index()) {
      this.index.set(0);
      this.signals[0].set(true);
      this.signals[1].set(false);
    } else {
      this.index.set(1);
      this.signals[0].set(false);
      this.signals[1].set(true);
    }
  }

  get subscriptionsAvailable(): Array<number> {
    return [0];
  }
}

<h1 class="page-title">Vitrine</h1>
<div class="stock-filter">
  <label for="stock"> Ver produtos sem estoque?</label>
  <span class="input-switch">
    Não
    <p-inputSwitch
      name="stock"
      inputId="stock"
      [trueValue]="0"
      [falseValue]="1"
      (onChange)="filterChanged()"
    ></p-inputSwitch>
    Sim
  </span>
</div>
<br />
<ng-container *ngIf="onlyStock">
  <app-gridstack
    (changeCB)="listChanged()"
    [options]="gridOptions"
    *ngIf="products?.length"
  >
    <app-gridstack-item
      *ngFor="let product of products; let i = index"
      [options]="{
        minW: 275,
        noResize: true,
        id: product.productVariantId?.toString()
      }"
      class="gridstack-card"
    >
      <div class="glamclub-product">
        <p-button
          icon="pi pi-ban"
          [text]="true"
          [rounded]="true"
          severity="danger"
          (onClick)="confirmAction(product, $event)"
        ></p-button>
        <p-button
          icon="pi pi-angle-double-up"
          [text]="true"
          [rounded]="true"
          styleClass="bottom"
          severity="primary"
          (onClick)="moveToTop(product, $event)"
          *ngIf="i > 0"
        ></p-button>
        <img class="product-image" [src]="product.defaultImageURL" />
        <p>
          <a
            class="data-link"
            type="button"
            [routerLink]="
              '/products/catalog/product-variant/' + product.productVariantId
            "
          >
            {{ product.productVariantName }}
          </a>
        </p>
        <p><b>Marca</b><br />{{ product.brandName }}</p>
        <p-rating
          [(ngModel)]="product.ratingAverage"
          [stars]="5"
          [readonly]="true"
          [cancel]="false"
        >
          <ng-template pTemplate="onicon">
            <i class="pi pi-star-fill"></i>
          </ng-template>
          <ng-template pTemplate="officon">
            <i class="pi pi-star"></i>
          </ng-template>
        </p-rating>
        <p>{{ product.reviewCount | number : '1.0-0' }} avaliações</p>
        <p>{{ product.tradePoints | number : '1.0-2' }} Glampoints</p>
        <p [class.out]="!product.stockCount || product.stockCount < 0">
          {{ product.stockCount | number : '1.0-0' }} unidades
        </p>
        <p>EAN: {{ product.internalEAN }}</p>
        <p *ngIf="product.expiration">Validade: {{ product.expiration }}</p>
      </div>
    </app-gridstack-item>
  </app-gridstack>
  <p-speedDial
    showIcon="pi pi-ellipsis-v"
    hideIcon="pi pi-times"
    [model]="options"
    *ngIf="options"
    direction="up"
  >
  </p-speedDial>
</ng-container>
<ng-container *ngIf="products && !onlyStock">
  <app-table
    [lazy]="false"
    [elements]="products"
    modelName="variantes"
    [tableTitle]="'Produtos ativos sem estoque mínimo (' + (minStock + 1) + ')'"
    [addButton]="false"
    [cols]="cols"
    sortField="productVariantName"
    [sortOrder]="-1"
    (emitSelection)="disableTrades($event)"
    selectionActionIcon="pi pi-ban"
    selectionActionLabel="Remover da vitrine"
    selectionActionTooltip="Remover produtos da vitrine"
    id="no-stock-table"
    [changeColumns]="true"
  ></app-table>
  <app-table
    [lazy]="false"
    [elements]="productsInStock"
    modelName="variantes"
    [tableTitle]="
      'Produtos inativos com estoque mínimo (' + (minStock + 1) + ')'
    "
    [addButton]="false"
    [cols]="colsInStock"
    sortField="productVariantName"
    [sortOrder]="-1"
    [changeColumns]="true"
  ></app-table>
</ng-container>
<p-confirmPopup> </p-confirmPopup>

<app-table
  *ngIf="orders"
  [lazy]="false"
  [elements]="orders"
  modelName="pedidos não aprovados"
  tableTitle="Pedidos não aprovados"
  [addButton]="false"
  [cols]="cols"
  sortField="date"
  [sortOrder]="1"
  [dropdownFilters]="dropdownFilters"
  [defaultRows]="10"
  [defaultFilterValues]="filterValues"
  (changedFilter)="filterChanged($event)"
/>

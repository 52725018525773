import {
  Component,
  OnInit,
  ViewChild,
  WritableSignal,
  computed,
  inject,
  signal
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgxPermissionsService } from 'ngx-permissions';
import { lastValueFrom } from 'rxjs';
import {
  Article,
  ArticleControllerService,
  PageableFilter
} from 'src/app/admin-api';
import {
  DropdownFilter,
  TableColumn,
  TableComponent
} from 'src/app/components/table';
import { ArticleTypeEnum, Role, roleAsObject } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-glam-tv',
  templateUrl: './glam-tv.component.html',
  styleUrl: './glam-tv.component.scss'
})
export class GlamTvComponent implements OnInit {
  articleService: ArticleControllerService = inject(ArticleControllerService);
  private permissionsService: NgxPermissionsService = inject(
    NgxPermissionsService
  );
  private title = inject(Title);

  @ViewChild(TableComponent) table: TableComponent;

  permission: WritableSignal<boolean | undefined> = signal(undefined);
  cols = computed(() => {
    return this.permission() !== undefined
      ? [
          new TableColumn.Builder()
            .setHeader('ArticleId')
            .setField('articleId')
            .setType('number')
            .setRouterLink('')
            .setRouterLinkFieldName('articleId')
            .build(),
          new TableColumn.Builder()
            .setHeader('Titulo')
            .setField('articleTitle')
            .setRouterLink('')
            .setRouterLinkFieldName('articleId')
            .setCondition('contains')
            .build(),
          new TableColumn.Builder()
            .setHeader('Subtítulo')
            .setField('articleSummary')
            .setRouterLink('')
            .setRouterLinkFieldName('articleId')
            .setCondition('contains')
            .build(),
          new TableColumn.Builder()
            .setHeader('Visualizações')
            .setField('views')
            .setType('formattedNumber')
            .setCondition('gte')
            .build(),
          new TableColumn.Builder()
            .setHeader('Likes')
            .setField('likes')
            .setType('formattedNumber')
            .setCondition('gte')
            .build(),
          new TableColumn.Builder()
            .setHeader('Duração vídeo (min)')
            .setField('minutesTotal')
            .setType('formattedNumber')
            .setCondition('gte')
            .build(),
          new TableColumn.Builder()
            .setHeader('Data de criação')
            .setField('dateCreated')
            .setType('date')
            .setCondition('between')
            .build(),
          new TableColumn.Builder()
            .setHeader('Ativo')
            .setField('active')
            .setType(this.permission() ? 'inputSwitch' : 'boolean')
            .build(),
          new TableColumn.Builder()
            .setHeader('Data de publicação')
            .setField('publishDate')
            .setType('date')
            .setCondition('between')
            .build()
        ]
      : undefined;
  });

  fixedFilters: PageableFilter[] = [
    {
      condition: 'equals',
      field: 'articleType',
      value: ArticleTypeEnum.Vídeos,
      fieldType: 'number'
    }
  ];

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader();
    this.title.setTitle('Vídeos');
    this.permission.set(
      await this.permissionsService.hasPermission([
        roleAsObject(Role.Full_Administrator).enumValue,
        roleAsObject(Role.Content).enumValue
      ])
    );
  }

  dropdownFilters: {
    [field: string]: Array<DropdownFilter>;
  } = {
    active: [
      { label: 'Todos', value: '-1' },
      { label: 'Sim', value: '1' },
      { label: 'Não', value: '0' }
    ]
  };

  defaultFilterValues: { [field: string]: string | Array<string> } = {
    active: '1',
    articleTypeName: '-1'
  };

  async updateActive(article: Article): Promise<void> {
    LoaderService.showLoader();

    try {
      await lastValueFrom(
        this.articleService.updateArticleStatus(
          article.articleId,
          article.active || false
        )
      );
      this.table?.refresh();
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }

    LoaderService.showLoader(false);
  }
}

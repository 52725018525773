import {
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
  inject
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { PrimeIcons } from 'primeng/api';
import { PanelMenu } from 'primeng/panelmenu';
import { Role, roleAsObject } from 'src/app/models';
import { RoleMenuItem } from 'src/app/models/roleMenuItem';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { BroadcastUtil } from 'src/app/utils/broadcast.util';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SideBarComponent implements OnInit {
  @ViewChild(PanelMenu)
  menu: PanelMenu | undefined;

  private permissionsService: NgxPermissionsService = inject(
    NgxPermissionsService
  );
  private router = inject(Router);

  @Output()
  stateChanged = new EventEmitter<boolean>();

  itemsMenu: Array<RoleMenuItem> = [];
  exibitionItems: Array<RoleMenuItem> = [];

  expanded?: boolean;
  isMobile?: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.validateScreenSize();
  }

  async ngOnInit(): Promise<void> {
    await this.configMenu();
    this.validateScreenSize();
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.validateScreenSize();
      }
    });
    BroadcastUtil.get('userChanged').subscribe(async () => {
      await this.configMenu();
    });
  }

  async configMenu(): Promise<void> {
    this.exibitionItems = [];
    this.itemsMenu = [
      {
        label: 'Dashboards',
        icon: PrimeIcons.CHART_LINE,
        styleClass: this.isSelected('dashboard') ? 'menu-highlight' : '',
        items: [
          {
            label: 'Box Rating & Scores',
            icon: PrimeIcons.BOX,
            routerLink: 'dashboard/rating-and-scores'
          },
          {
            label: 'Assinatura',
            icon: PrimeIcons.CHART_BAR,
            routerLink: 'dashboard/subscriptions'
          },
          {
            label: 'Aquisição',
            icon: PrimeIcons.USER_PLUS,
            routerLink: 'dashboard/acquisition'
          },
          {
            label: 'Cupons',
            icon: PrimeIcons.PERCENTAGE,
            routerLink: 'dashboard/coupons'
          },
          {
            label: 'CRM',
            icon: PrimeIcons.ENVELOPE,
            routerLink: 'dashboard/crm'
          },
          {
            label: 'Receita',
            icon: PrimeIcons.DOLLAR,
            routerLink: 'dashboard/income'
          },
          {
            label: 'Upgrade/ Downgrade',
            icon: PrimeIcons.SYNC,
            routerLink: 'dashboard/upgrade-downgrade'
          },
          {
            label: 'Entrega',
            icon: PrimeIcons.TRUCK,
            routerLink: 'dashboard/delivery'
          },
          {
            label: 'Frequências',
            icon: PrimeIcons.CHART_LINE,
            routerLink: 'dashboard/analytics'
          },
          {
            label: 'Consolidado',
            icon: PrimeIcons.SITEMAP,
            routerLink: 'dashboard/consolidated'
          },
          {
            label: 'Lojinhas',
            icon: PrimeIcons.STAR_FILL,
            routerLink: 'dashboard/wishlists'
          },
          {
            label: 'DANFEs',
            icon: PrimeIcons.MONEY_BILL,
            routerLink: 'dashboard/invoices'
          }
        ]
      },
      {
        label: 'Operações',
        icon: PrimeIcons.TRUCK,
        styleClass: this.isSelected('operations') ? 'menu-highlight' : '',
        items: [
          {
            label: 'Edições',
            icon: PrimeIcons.BOOKMARK,
            routerLink: 'operations/editions'
          },
          {
            label: 'Estoque',
            icon: PrimeIcons.DATABASE,
            routerLink: 'operations/stock-movements'
          },
          {
            label: 'Alocações',
            icon: PrimeIcons.BOX,
            routerLink: 'operations/allocation'
          },
          {
            label: 'Composições',
            icon: PrimeIcons.GIFT,
            routerLink: 'operations/compositions'
          },
          {
            label: 'Produção',
            icon: PrimeIcons.STOPWATCH,
            routerLink: 'operations/composition-production'
          },
          {
            label: 'Ocorrências',
            icon: PrimeIcons.MEGAPHONE,
            routerLink: 'operations/incidents'
          }
        ]
      },
      {
        label: 'Pesquisa',
        icon: PrimeIcons.SEARCH,
        styleClass: this.isSelected('research') ? 'menu-highlight' : '',
        items: [
          {
            label: 'Formulários',
            icon: PrimeIcons.FILE,
            routerLink: 'research/feedbacks'
          },
          {
            label: 'Perfil de beleza',
            icon: PrimeIcons.STAR_FILL,
            routerLink: 'research/beauty-profile'
          },
          {
            label: 'Cruzamento',
            icon: PrimeIcons.SHARE_ALT,
            routerLink: 'research/feedback-crossing'
          },
          {
            label: 'Grupos de pesquisa',
            icon: PrimeIcons.USERS,
            routerLink: 'research/feedback-groups'
          },
          {
            label: 'Consultas personalizadas',
            icon: PrimeIcons.DOWNLOAD,
            routerLink: 'research/custom-query'
          }
        ]
      },
      {
        label: 'Conteúdo',
        icon: PrimeIcons.BOOK,
        styleClass: this.isSelected('/content') ? 'menu-highlight' : '',
        items: [
          {
            label: 'MAG',
            icon: PrimeIcons.FILE,
            routerLink: 'content/article'
          },
          {
            label: 'Vídeos',
            icon: PrimeIcons.VIDEO,
            routerLink: 'content/glam-tv'
          },
          {
            label: 'Shorts',
            icon: PrimeIcons.YOUTUBE,
            routerLink: 'content/shorts'
          },
          {
            label: 'Cursos',
            icon: PrimeIcons.GRADUATION_CAP,
            routerLink: 'content/course'
          },
          {
            label: 'Lives',
            icon: PrimeIcons.VIDEO,
            routerLink: 'content/lives'
          }
        ]
      },
      {
        label: 'Usuários',
        icon: PrimeIcons.USERS,
        styleClass: this.isSelected('/users') ? 'menu-highlight' : '',
        items: [
          {
            label: 'Todos os usuários',
            icon: 'pi pi-address-book',
            routerLink: 'users/person'
          },
          {
            label: 'Assinaturas',
            icon: PrimeIcons.ID_CARD,
            routerLink: 'users/subscribers'
          },
          {
            label: 'Cancelamentos',
            icon: PrimeIcons.TIMES_CIRCLE,
            routerLink: 'users/cancellations'
          },
          {
            label: 'Pagamentos',
            icon: PrimeIcons.DOLLAR,
            routerLink: 'users/payments'
          },
          {
            label: 'Blacklist',
            icon: PrimeIcons.BAN,
            routerLink: 'users/blacklists'
          },
          {
            label: 'Estornos',
            icon: PrimeIcons.WALLET,
            routerLink: 'users/refund'
          }
        ]
      },
      {
        label: 'Marketing',
        icon: PrimeIcons.GLOBE,
        styleClass: this.isSelected('/marketing') ? 'menu-highlight' : '',
        items: [
          {
            label: 'Cupons',
            icon: PrimeIcons.PERCENTAGE,
            routerLink: 'marketing/coupons'
          },
          {
            label: 'Cupons Loja',
            icon: PrimeIcons.PERCENTAGE,
            routerLink: 'marketing/shop-coupons'
          },
          {
            label: 'Compre e ganhe',
            icon: PrimeIcons.SYNC,
            routerLink: 'marketing/buy-2-win'
          },
          {
            label: 'Templates de Email',
            icon: PrimeIcons.ENVELOPE,
            routerLink: 'marketing/email-templates'
          }
        ]
      },
      {
        label: 'Config. de Assinatura',
        icon: PrimeIcons.BRIEFCASE,
        styleClass: this.isSelected('/subscription-settings')
          ? 'menu-highlight'
          : '',
        items: [
          {
            label: 'Jóias',
            icon: PrimeIcons.MOON,
            routerLink: 'subscription-settings/badge'
          },
          {
            label: 'Planos',
            icon: PrimeIcons.DOLLAR,
            routerLink: 'subscription-settings/subscription-types'
          },
          {
            label: 'Instituições financeiras',
            icon: PrimeIcons.BUILDING_COLUMNS,
            routerLink: 'subscription-settings/payer-institutions'
          }
        ]
      },
      {
        label: 'Site',
        icon: PrimeIcons.PALETTE,
        styleClass: this.isSelected('/configuracoes') ? 'menu-highlight' : '',
        roles: [Role.Full_Administrator, Role.Customization],
        items: [
          {
            label: 'Customizações',
            icon: PrimeIcons.COG,
            routerLink: 'configuracoes'
          }
        ]
      },
      {
        label: 'Produtos Assinatura',
        icon: PrimeIcons.GIFT,
        styleClass: this.isSelected('/products') ? 'menu-highlight' : '',
        items: [
          {
            label: 'Produtos',
            icon: PrimeIcons.SHOPPING_BAG,
            routerLink: 'products/catalog'
          },
          {
            label: 'Estoque',
            icon: PrimeIcons.DATABASE,
            routerLink: 'products/stock'
          },
          {
            label: 'Marcas',
            icon: PrimeIcons.TAGS,
            routerLink: 'products/brands'
          },
          {
            label: 'Categorias',
            icon: PrimeIcons.BOOK,
            routerLink: 'products/categories'
          },
          {
            label: 'Movimentações',
            icon: PrimeIcons.ARROW_RIGHT_ARROW_LEFT,
            routerLink: 'products/movements'
          },
          {
            label: 'Vitrine',
            icon: PrimeIcons.TH_LARGE,
            routerLink: 'products/glamclub'
          },
          {
            label: 'Batch Update',
            icon: PrimeIcons.UPLOAD,
            routerLink: 'products/batch'
          },
          {
            label: 'Avaliações',
            icon: PrimeIcons.COMMENTS,
            routerLink: 'products/reviews'
          }
        ]
      },
      {
        label: 'Produtos Loja',
        icon: PrimeIcons.SHOPPING_BAG,
        styleClass: this.isSelected('/shop-products') ? 'menu-highlight' : '',
        items: [
          {
            label: 'Produtos',
            icon: PrimeIcons.SHOPPING_BAG,
            routerLink: 'shop-products/catalog'
          },
          {
            label: 'Categorias e Coleções',
            icon: PrimeIcons.BOOK,
            routerLink: 'shop-products/categories'
          },
          {
            label: 'Filtros',
            icon: PrimeIcons.FILTER,
            routerLink: 'shop-products/filters'
          },
          {
            label: 'Batch Update',
            icon: PrimeIcons.UPLOAD,
            routerLink: 'shop-products/batch-update'
          },
          {
            label: 'Avaliações',
            icon: PrimeIcons.COMMENTS,
            routerLink: 'shop-products/reviews'
          },
          {
            label: 'Avise-me',
            icon: PrimeIcons.ENVELOPE,
            routerLink: 'shop-products/avise-me'
          }
        ]
      },
      {
        label: 'Pedidos',
        icon: PrimeIcons.DOLLAR,
        styleClass: this.isSelected('/shop-orders') ? 'menu-highlight' : '',
        items: [
          {
            label: 'Glam',
            routerLink: 'shop-orders/glamshop',
            icon: PrimeIcons.VENUS
          },
          {
            label: "Men's Market",
            routerLink: 'shop-orders/mensmarket',
            icon: PrimeIcons.MARS
          },
          {
            label: 'Vitrine Glam',
            routerLink: 'shop-orders/glampoints',
            icon: PrimeIcons.TROPHY
          },
          {
            label: 'Não aprovados',
            routerLink: 'shop-orders/orders-blocked',
            icon: PrimeIcons.BAN
          }
        ],
        roles: []
      },
      {
        label: 'It Ops',
        icon: PrimeIcons.VERIFIED,
        styleClass: this.isSelected('/it-ops') ? 'menu-highlight' : '',
        roles: [Role.Full_Administrator, Role.ItOps, Role.TI],
        items: [
          {
            label: 'Integridade',
            icon: PrimeIcons.CHART_LINE,
            routerLink: 'it-ops/dashboard'
          },
          {
            label: 'Sessões ativas',
            icon: PrimeIcons.EXCLAMATION_TRIANGLE,
            routerLink: 'it-ops/database-sessions'
          },
          {
            label: 'Logs',
            icon: PrimeIcons.SEARCH,
            routerLink: 'it-ops/logs'
          }
        ]
      },
      {
        label: 'Integrações',
        icon: PrimeIcons.SHARE_ALT,
        styleClass: this.isSelected('/integrations') ? 'menu-highlight' : '',
        roles: [
          Role.Full_Administrator,
          Role.ItOps,
          Role.TI,
          Role.Delivery,
          Role.Integration
        ],
        items: [
          {
            label: 'Exportações',
            icon: PrimeIcons.CLOUD_UPLOAD,
            routerLink: 'integrations/dashboard',
            roles: [Role.Full_Administrator, Role.ItOps, Role.TI]
          },
          {
            label: 'Falhas de Exportação',
            icon: PrimeIcons.EXCLAMATION_TRIANGLE,
            routerLink: 'integrations/fail-export',
            roles: [Role.Full_Administrator, Role.ItOps, Role.TI]
          },
          {
            label: 'Atualização da Tabela de Frete de Assinatura',
            icon: PrimeIcons.SYNC,
            routerLink: 'integrations/update-shipping-company',
            roles: [Role.Full_Administrator, Role.ItOps, Role.TI, Role.Delivery]
          },
          {
            label: 'Atualização da Tabela de Frete de Loja',
            icon: PrimeIcons.SYNC,
            routerLink: 'integrations/update-shipping-company-activation',
            roles: [Role.Full_Administrator, Role.ItOps, Role.TI, Role.Delivery]
          },
          {
            label: 'Tokens',
            icon: PrimeIcons.KEY,
            routerLink: 'integrations/tokens',
            roles: [
              Role.Full_Administrator,
              Role.ItOps,
              Role.Integration,
              Role.TI
            ]
          }
        ]
      },
      {
        label: 'Financeiro',
        icon: PrimeIcons.WALLET,
        styleClass: this.isSelected('/financial') ? 'menu-highlight' : '',
        items: [
          {
            label: 'Valores fiscais',
            icon: PrimeIcons.PERCENTAGE,
            routerLink: 'financial/tax-price'
          }
        ]
      },
      {
        label: 'Configurações',
        icon: PrimeIcons.COG,
        styleClass: this.isSelected('/settings') ? 'menu-highlight' : '',
        items: [
          {
            label: 'Usuários do sistema',
            icon: PrimeIcons.USERS,
            routerLink: 'settings/system-users',
            roles: [Role.Full_Administrator]
          },
          {
            label: 'Minha conta',
            icon: PrimeIcons.USER,
            routerLink: 'settings/account'
          },
          {
            label: 'Acessos',
            icon: PrimeIcons.LOCK,
            routerLink: 'settings/access-looker'
          }
        ]
      },
      {
        label: 'Logout',
        icon: PrimeIcons.SIGN_OUT,
        routerLink: 'logout',
        command: () => {
          this.menu?.collapseAll();
        }
      }
    ];
    for (const i of this.itemsMenu) {
      if (
        await this.permissionsService.hasPermission(
          i.roles?.map((r) => roleAsObject(r).enumValue)
        )
      ) {
        const items = [...(i?.items || [])];
        for (const item of items) {
          if (
            !(await this.permissionsService.hasPermission(
              item.roles?.map((r) => roleAsObject(r).enumValue) || []
            ))
          ) {
            i.items = i.items.filter((it) => it.label !== item.label);
          }
        }
        this.exibitionItems.push(i);
      }
    }
  }

  isSelected(route: string): boolean {
    return window.location.pathname.includes(route);
  }

  changeState(): void {
    this.expanded = !this.expanded;
    this.stateChanged.emit(this.expanded);
  }

  validateScreenSize(): void {
    this.isMobile = window.innerWidth < 768;
    this.expanded = !this.isMobile;
    setTimeout(() => {
      this.stateChanged.emit(this.expanded);
    });
  }

  get items(): Array<RoleMenuItem> {
    return TokenStorageService.userId ? this.exibitionItems : [];
  }
}

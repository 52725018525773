<h3 *ngIf="collection">
  Editar coleção: {{ collection.title || collection.altText }}
</h3>
<h3 *ngIf="!collection">Nova coleção</h3>
<form class="form-b4a" [formGroup]="form" (submit)="submit()">
  <span class="p-float-label">
    <p-dropdown
      [options]="collectionTypes"
      [autoDisplayFirst]="false"
      formControlName="type"
      (onChange)="typeChanged()"
      inputId="type"
    />
    <label for="type">Tipo *</label>
  </span>
  <span class="p-float-label">
    <input
      type="text"
      pInputText
      formControlName="title"
      id="title"
      name="title"
    />
    <label for="title">Nome *</label>
  </span>
  <span class="p-float-label full">
    <p-autoComplete
      [suggestions]="categories"
      formControlName="slug"
      filterBy="name,slug"
      optionLabel="slug"
      optionValue="slug"
      appendTo="body"
      [lazy]="false"
      [multiple]="false"
      (completeMethod)="searchCollections($event)"
      placeholder="Busque pelo nome ou slug"
      name="brand"
      inputId="brand"
      (onSelect)="collectionChanged($event)"
      (onBlur)="checkCollection()"
      (click)="form.controls.slug.reset()"
    >
      <ng-template pTemplate="item" let-item>
        {{ item.name }} [{{ item.slug }}]
      </ng-template>
    </p-autoComplete>
    <label for="brand">Coleção *</label>
  </span>
  <p-card
    subheader="Preview dos produtos"
    *ngIf="form.value.type === 'collection' && products?.length"
  >
    <p-carousel
      [value]="products"
      [numVisible]="4"
      [numScroll]="4"
      [circular]="false"
      [responsiveOptions]="responsiveOptionsSmall"
      [showNavigators]="products.length > 4"
      [showIndicators]="products.length > 4"
      styleClass="carousel__glam"
    >
      <ng-template let-product pTemplate="item">
        <div class="collections-banner">
          <img
            *ngIf="product.images?.length"
            [alt]="product.name"
            [src]="product.images[0]"
            class="collection-banner"
          />
          <img
            *ngIf="!product.images?.length"
            [alt]="product.name"
            src="assets/images/no_img_available.jpg"
            class="collection-banner"
          />
        </div>
      </ng-template>
    </p-carousel>
  </p-card>
  <ng-container *ngIf="form.value.type === 'collection premium'">
    <span class="image-upload full">
      <div class="file-upload">
        <span class="p-float-label">
          <input
            type="text"
            readonly
            pInputText
            formControlName="collectionImage"
          />
          <label for="collectionImage"> Logo da coleção * </label>
        </span>
        <p-fileUpload
          mode="basic"
          chooseLabel="Selecionar"
          name="file"
          [auto]="true"
          accept="image/*"
          [maxFileSize]="1000000"
          uploadIcon="pi pi-file"
          [customUpload]="true"
          (uploadHandler)="onUpload($event, 'collectionImage', fileUpload)"
          id="collectionImage"
          #fileUpload
        />
        <img
          *ngIf="form.value.collectionImage"
          [src]="form.value.collectionImage"
        />
      </div>
    </span>
    <span class="p-float-label full">
      <input
        type="text"
        pInputText
        formControlName="collectionImageAlt"
        id="collectionImageAlt"
        name="collectionImageAlt"
      />
      <label for="collectionImageAlt">Logo Alt Text *</label>
    </span>
    <span class="color-picker p-float-label">
      <input
        type="text"
        pInputText
        formControlName="collectionColor"
        name="collectionColor"
        id="collectionColor"
      />
      <label for="collectionColor">Cor do fundo *</label>
      <p-colorPicker
        formControlName="collectionColor"
        appendTo="body"
        inputId="collectionColor"
      />
    </span>
    <div
      class="collection-preview full"
      *ngIf="
        form.controls.collectionColor.valid &&
        form.controls.collectionImage.valid
      "
    >
      <label for="color-preview">Preview da coleção</label>
      <br />
      <div
        class="color-preview"
        [ngStyle]="{
          background:
            'linear-gradient(180deg, ' +
            form.value.collectionColor +
            ' 33.33%, #f6b6b700 74.24%)'
        }"
      >
        <img
          [src]="form.value.collectionImage"
          [alt]="form.value.collectionImageAlt"
        />
      </div>
    </div>
  </ng-container>
  <span class="buttons">
    <p-button label="Voltar" severity="danger" routerLink="../../" />
    <p-button label="Salvar" type="submit" [disabled]="!form.valid" />
  </span>
</form>

import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriberInfo } from 'src/app/admin-api';

@Component({
  selector: 'app-subscriber-card',
  templateUrl: './subscriber-card.component.html',
  styleUrls: ['./subscriber-card.component.scss']
})
export class SubscriberCardComponent {
  @Input()
  subscriber: SubscriberInfo | undefined;

  constructor(private router: Router) {}

  goToSubscriber(): void {
    this.router.navigate([
      'users/subscribers/' + this.subscriber?.subscriberId
    ]);
  }

  get editionAsDate(): Date {
    return this.subscriber?.startEdition
      ? new Date(
          (
            Number(this.subscriber.startEdition.toString().substring(1)) / 100
          ).toFixed(0) +
            '-' +
            this.subscriber.startEdition.toString().substring(5) +
            '-01 12:00:00'
        )
      : new Date();
  }
}

import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import {
  ProductReviews,
  ReviewWithProductData,
  Shops
} from '@infrab4a/connect';
import { NgxPermissionsService } from 'ngx-permissions';
import { MessageService, PrimeIcons } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { Reward, RewardControllerService } from 'src/app/admin-api';
import {
  TableActionButton,
  TableColumn,
  TableComponent
} from 'src/app/components/table';
import { ShopProductReviewService } from 'src/app/connect-api/api/shop/shop-product-review.service';
import {
  NullValue,
  ReviewWithProduct,
  Role,
  roleAsObject
} from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-shop-reviews',
  templateUrl: './shop-reviews.component.html',
  styleUrl: './shop-reviews.component.scss'
})
export class ShopReviewsComponent implements OnInit {
  @ViewChild(TableComponent) table: TableComponent;

  private title = inject(Title);
  private messageService = inject(MessageService);
  public productReviewService = inject(ShopProductReviewService);
  private permissionService = inject(NgxPermissionsService);
  private rewardService = inject(RewardControllerService);

  reviews: ReviewWithProduct[] = [];
  protected loading = false;
  protected cols = [
    new TableColumn.Builder()
      .setHeader('SKU admin')
      .setField('productId')
      .setRouterLink('/shop-products/catalog/product/')
      .setRouterLinkFieldName('productId')
      .setSortable(false)
      .setCondition('equals')
      .build(),
    new TableColumn.Builder()
      .setHeader('Email')
      .setField('email')
      .setRouterLink('/users/person/')
      .setRouterLinkFieldName('personId')
      .build(),
    new TableColumn.Builder()
      .setHeader('Autor')
      .setField('author')
      .setRouterLink('/users/person/')
      .setRouterLinkFieldName('personId')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('Produto')
      .setField('productName')
      .setRouterLink('/shop-products/catalog/product/')
      .setRouterLinkFieldName('productId')
      .setSortable(false)
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('Localização')
      .setField('location')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('Nota')
      .setField('rate')
      .setType('number')
      .build(),
    new TableColumn.Builder()
      .setHeader('Titulo')
      .setField('title')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('Avaliação')
      .setField('review')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder().setHeader('Loja').setField('shop').build(),
    new TableColumn.Builder()
      .setHeader('Status')
      .setField('status')
      .setDisplayFunction((review: ProductReviews) =>
        ShopProductReviewService.mapReviewStatus(review.status)
      )
      .build(),
    new TableColumn.Builder()
      .setHeader('Criação')
      .setField('createdAt')
      .setType('date')
      .setCondition('between')
      .build(),
    new TableColumn.Builder()
      .setHeader('Últ. atualização')
      .setField('updatedAt')
      .setType('date')
      .setCondition('between')
      .build()
  ];
  actionButtons: Array<TableActionButton> = [
    new TableActionButton(
      '',
      'Detalhes',
      PrimeIcons.EYE,
      undefined,
      '',
      'Detalhes',
      'bottom',
      true,
      true,
      'white',
      'small'
    ),
    new TableActionButton(
      '',
      'Aprovar',
      PrimeIcons.CHECK_CIRCLE,
      (review: ProductReviews) => !review.status,
      '',
      'Aprovar',
      'bottom',
      true,
      true,
      'white',
      'small'
    ),
    new TableActionButton(
      '',
      'Reprovar',
      PrimeIcons.BAN,
      (review: ProductReviews) => review.status !== false,
      '',
      'Reprovar',
      'bottom',
      true,
      true,
      'danger',
      'small'
    )
  ];
  protected showDetailModal = false;
  protected showSelectionModal = false;
  protected currentReview?: ReviewWithProduct;
  protected currentReviews: ReviewWithProduct[] = [];
  status = new FormControl<'pending' | 'approved' | 'rejected'>(
    'pending',
    Validators.required
  );
  protected dropdownFilters = {
    shop: [
      { label: 'Todos', value: '' },
      { label: 'Glamshop', value: Shops.GLAMSHOP },
      { label: 'Men`s Market', value: Shops.MENSMARKET }
    ],
    status: [
      { label: 'Pendentes', value: NullValue },
      { label: 'Aprovados', value: true },
      { label: 'Reprovados', value: false }
    ],
    rate: [
      { label: '1', value: 1 },
      { label: '2', value: 2 },
      { label: '3', value: 3 },
      { label: '4', value: 4 },
      { label: '5', value: 5 },
      {
        label: 'Todas',
        value: undefined
      }
    ]
  };
  permisssion = false;
  ready = false;
  processList?: {
    review: ReviewWithProductData & { productId: number };
    approveError?: any;
    rewardError?: any;
    reward?: Reward;
  }[];
  processing = false;

  async ngOnInit(): Promise<void> {
    this.permisssion = await this.permissionService.hasPermission([
      roleAsObject(Role.Full_Administrator).enumValue,
      roleAsObject(Role.Portfolio).enumValue
    ]);
    this.title.setTitle('Loja - Avaliações de Produtos');
    if (this.permisssion) {
      this.cols.unshift(
        new TableColumn.Builder()
          .setHeader('')
          .setField('')
          .setType('checkbox')
          .build()
      );
      this.cols.push(
        new TableColumn.Builder()
          .setHeader('Ação')
          .setField('')
          .setType('button')
          .build()
      );
    }
    this.ready = true;
  }

  async approveReview(review: ReviewWithProductData): Promise<void> {
    await this.updateReviewApproved(review);
    this.showDetailModal = false;
  }

  async approveMany(
    reviews: Array<ReviewWithProductData & { productId: number }>
  ): Promise<void> {
    LoaderService.showLoader();
    this.processing = true;
    this.processList = reviews.map((review) => ({ review }));
    await this.productReviewService.updateStatus(
      this.processList.map((p) => Number(p.review.id)),
      true
    );
    if (this.processList.some((p) => !p.approveError && p.review.personId))
      try {
        (
          await lastValueFrom(
            this.rewardService.addGlampointsBatch(
              this.processList
                .filter((p) => !p.approveError && p.review.personId)
                .map((p) => this.reviewToReward(p.review))
            )
          )
        ).result.forEach((r) => {
          const process = this.processList.find(
            (p) =>
              !p.rewardError &&
              p.review.productId === Number(r.productId) &&
              p.review.personId === r.personId &&
              p.review.points === r.points
          );
          if (process) {
            process.reward = r;
            process.rewardError = r.rewardId
              ? undefined
              : r.personId
              ? { error: { message: 'Assinatura inativa' } }
              : { error: { message: 'Assinante não identificada.' } };
          }
        });
      } catch (error) {
        AppDialogService.showErrorDialog(error);
        this.processList.forEach((p) => (p.rewardError = true));
      }
    this.processList = this.processList.filter(
      (p) => p.approveError || p.rewardError
    );
    this.messageService.add({
      severity: 'success',
      detail: `${
        reviews.length - this.processList.length
      } avaliações aprovadas com sucesso!`,
      summary: 'Sucesso'
    });
    this.currentReviews = [];
    this.showSelectionModal = false;
    this.table.pageRequest.pageSize = 10;
    await this.table?.refresh(true);
    this.processing = false;
    LoaderService.showLoader(false);
  }

  async updateReviewStatus(review: ReviewWithProductData, status?: boolean) {
    try {
      await this.productReviewService.update({
        id: Number(review.id),
        status: status || review.status
      });
    } catch (error) {
      const process = this.processList?.find((p) => p.review.id === review.id);
      if (process) process.approveError = error;
    }
  }

  protected onSelection($event: ReviewWithProduct[]) {
    this.currentReviews = $event;
    this.showSelectionModal = true;
  }

  protected showCheckbox(value: { data: ProductReviews }) {
    return value.data.status === null;
  }

  async rejectReview(review: ReviewWithProductData): Promise<void> {
    await this.updateReviewRejected(review);
    this.currentReview = undefined;
    this.showDetailModal = false;
  }

  async updateReviewApproved(review: ReviewWithProductData): Promise<void> {
    try {
      LoaderService.showLoader();
      await this.productReviewService.aproveReview(review);
      await lastValueFrom(
        this.rewardService.addGlampoints(this.reviewToReward(review))
      );
      await this.table?.refresh(true);
      this.messageService.add({
        severity: 'success',
        detail: 'Avaliação aprovada com sucesso!',
        summary: 'Sucesso'
      });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async updateReviewRejected(review: ReviewWithProductData): Promise<void> {
    try {
      LoaderService.showLoader();
      await this.productReviewService.disaproveReview(review);
      await this.table?.refresh(true);
      this.messageService.add({
        severity: 'success',
        detail: 'Avaliação rejeitada com sucesso!',
        summary: 'Sucesso'
      });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async buttonClick(event: {
    item: ReviewWithProductData;
    $event: Event;
    action: string;
  }) {
    if (event.action === 'Detalhes') {
      this.currentReview = event.item;
      this.showDetailModal = true;
    } else {
      if (event.action === 'Aprovar') {
        await this.updateReviewApproved(event.item);
      } else if (event.action === 'Reprovar') {
        await this.updateReviewRejected(event.item);
      }
    }
  }

  async rejectMany(reviews: ReviewWithProductData[]): Promise<void> {
    try {
      await this.productReviewService.updateStatus(
        reviews.map((r) => Number(r.id))
      );
      this.messageService.add({
        severity: 'success',
        detail: `${this.currentReviews.length} avaliações rejeitadas com sucesso!`,
        summary: 'Sucesso'
      });
      this.currentReviews = [];
      this.showSelectionModal = false;
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  private reviewToReward(review: ReviewWithProductData): Reward {
    return {
      personId: review.personId,
      rewardType: 37,
      referenceId: 51,
      referenceName: `Review - Produto ${review.productName}`,
      points: review.points,
      productId: review.productId,
      orderId: '',
      subtitle: `Avaliação ${review.productName}`
    } as Reward;
  }

  get modalColumns(): Array<TableColumn> {
    return this.cols.filter(
      (c) =>
        c.type !== 'button' &&
        c.type !== 'checkbox' &&
        c.field !== 'reviewStatus'
    );
  }

  get errorReport(): boolean {
    return !this.processing && this.processList?.length > 0;
  }

  set errorReport(report: any) {
    if (!report) {
      delete this.processList;
    }
  }

  get errorColumns(): Array<TableColumn> {
    return this.modalColumns.concat([
      new TableColumn.Builder()
        .setHeader('Erro')
        .setCondition('contains')
        .setField('error')
        .setDisplayFunction(
          (r: ReviewWithProductData & { error: any }) =>
            r.error?.error?.message ||
            r.error?.message ||
            JSON.stringify(r.error)
        )
        .build()
    ]);
  }

  get approveErrors() {
    return this.processList
      ?.filter((p) => p.approveError)
      .map((p) => ({ ...p.review, error: p.approveError }));
  }

  get rewardErrors() {
    return this.processList
      ?.filter((p) => p.rewardError || !p.reward)
      .map((p) => ({
        ...p.review,
        error: p.rewardError
      }));
  }
}

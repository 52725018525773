<app-table
  *ngIf="cols && products"
  [cols]="cols"
  [elements]="products"
  modelName="Estoque"
  sortField="productVariantName"
  [sortOrder]="-1"
  [lazy]="false"
  [dropdownFilters]="dropdownFilters"
  [showFooter]="true"
  [footerTemplate]="footerTemplate"
  [addButton]="false"
  [showFooter]="true"
  [changeColumns]="true"
  (columnsChanged)="changeColumns($event)"
>
  <ng-template #footerTemplate>
    <tr *ngIf="columnsDiplayed">
      <td [attr.colspan]="columnsDiplayed?.length - 4">Total</td>
      <td class="text-right">{{ totalAcquisiton }}</td>
      <td class="text-right">{{ totalFidelization }}</td>
      <td class="text-right">{{ totalReserveMarketing }}</td>
      <td class="text-right">{{ totalInfluencer }}</td>
    </tr>
  </ng-template>
</app-table>

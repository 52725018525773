/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface EmailTemplate {
    id?: number;
    templateName?: string;
    templateId?: string;
    subject?: string;
    subtitle?: string;
    description?: string;
    active?: boolean;
    createdBy?: string;
    updatedBy?: string;
    createdAt?: Date;
    updatedAt?: Date;
    deactivatedBy?: string;
    deactivatedAt?: Date;
}

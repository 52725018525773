import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { lastValueFrom, map } from 'rxjs';
import {
  ItOpsControllerService,
  SubscriberControllerService,
  TwoSubscriptionsInOneCpf
} from 'src/app/admin-api';
import {
  DropdownFilter,
  TableColumn,
  TableComponent
} from 'src/app/components/table';
import { SubscriberProblemGroupEnum } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-two-subscriptions-in-one-cpf',
  templateUrl: './two-subscriptions-in-one-cpf.component.html',
  styleUrls: ['./two-subscriptions-in-one-cpf.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class TwoSubscriptionsInOneCpfComponent implements OnInit {
  @ViewChild(TableComponent) table: TableComponent;

  columns: Array<TableColumn> = [new TableColumn('CPF', 'cpf', true, 'number')];

  dropdownFilters: { [field: string]: Array<DropdownFilter> };

  constructor(
    public itOpsService: ItOpsControllerService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private subscriberService: SubscriberControllerService
  ) {}

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader(true);
    try {
      this.dropdownFilters = {
        subscriberStatus: await lastValueFrom(
          this.subscriberService.findSubscriberStatusList().pipe(
            map((data) =>
              data.result.map((st) => ({
                label: st.subscriberStatusId + '. ' + st.status,
                value: st.subscriberStatusId.toString()
              }))
            )
          )
        )
      };
    } catch (error) {
      this.dropdownFilters = {};
    }
  }

  markAsResolved(elements: Array<TwoSubscriptionsInOneCpf>): void {
    this.confirmationService.confirm({
      acceptLabel: 'Sim',
      rejectLabel: 'Não',

      rejectButtonStyleClass: 'p-button-danger',
      message: 'Deseja enviar o relatório?',
      header: 'Corrigir',
      accept: async () => {
        LoaderService.showLoader();
        try {
          await lastValueFrom(
            this.itOpsService.markAsResolved(
              SubscriberProblemGroupEnum.TwoSubscriptionsInOneCpf,
              elements
            )
          );
          await this.table.refresh(true);
          this.messageService.add({
            summary: 'Sucesso',
            detail: elements.length + ' marcado(s) como resolvido(s)',
            severity: 'success'
          });
          LoaderService.showLoader(false);
        } catch (error) {
          LoaderService.showLoader(false);
          AppDialogService.showErrorDialog(error);
        }
      }
    });
  }
}

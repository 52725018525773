import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentDetailsModalComponent } from './payment-details-modal.component';



@NgModule({
  declarations: [
    PaymentDetailsModalComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    PaymentDetailsModalComponent
  ]
})
export class PaymentDetailsModalModule { }

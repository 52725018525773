<form class="modal-form" [formGroup]="form" (submit)="submit()">
  <span class="p-float-label">
    <p-autoComplete
      [suggestions]="forms"
      [lazy]="true"
      formControlName="feedbackForm"
      (completeMethod)="findFeedbackForms($event.query)"
      inputId="feedbackForm"
      name="feedbackForm"
      (onSelect)="formChange()"
      placeholder="Pesquise pelo nome"
      filterBy="formTitle"
      optionLabel="formTitle"
      appendTo="body"
      (onClear)="clearForm()"
      [showClear]="true"
    >
      <ng-template let-feedback pTemplate="item">
        {{ feedback.formTitle }}
      </ng-template>
    </p-autoComplete>
    <label for="feedbackForm">Formulário</label>
  </span>
  <span class="p-float-label">
    <p-dropdown
      [options]="[]"
      formControlName="copyQuestionId"
      inputId="copyQuestionId"
      *ngIf="!questions[form.value.feedbackForm?.formId]?.length"
    />
    <p-dropdown
      *ngIf="questions[form.value.feedbackForm?.formId]?.length"
      formControlName="copyQuestionId"
      [options]="questions[form.value.feedbackForm?.formId]"
      name="copyQuestionId"
      inputId="copyQuestionId"
      [filter]="true"
      appendTo="body"
      (onChange)="questionChange()"
      optionValue="questionId"
      optionLabel="title"
    >
      <ng-template pTemplate="item" let-question>
        {{ question.questionOrder }}.
        {{ question.title || question.feedbackGroupName }}
      </ng-template>
    </p-dropdown>
    <label for="copyQuestionId">Pergunta</label>
  </span>
  <span class="p-float-label">
    <p-multiSelect
      [options]="[]"
      formControlName="optionIds"
      name="optionIds"
      inputId="optionIds"
      *ngIf="!form.controls['copyQuestionId'].valid"
    />
    <p-multiSelect
      *ngIf="form.controls['copyQuestionId'].valid"
      [options]="options[form.value.copyQuestionId]"
      appendTo="body"
      formControlName="optionIds"
      optionValue="optionId"
      name="optionIds"
      inputId="optionIds"
    >
      <ng-template pTemplate="item" let-option>
        {{ option.optionOrder }}. {{ option.optionText }}
      </ng-template>
      <ng-template pTemplate="selectedItems">
        {{ optionsLabel }}
      </ng-template>
    </p-multiSelect>
    <label for="optionIds">Alternativas</label>
  </span>
  <div class="buttons">
    <p-button
      label="Cancelar"
      severity="danger"
      type="button"
      (onClick)="ref.close()"
    />
    <p-button
      label="Salvar"
      [disabled]="!form.valid"
      severity="primary"
      type="submit"
    />
  </div>
</form>

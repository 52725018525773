import { DatePipe, TitleCasePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ConfirmationService, MessageService, PrimeIcons } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  PageableFilter,
  SubscriptionType,
  SubscriptionTypePriceControllerService
} from 'src/app/admin-api';
import { SubscriptionTypeControllerService } from 'src/app/admin-api/api/subscriptionTypeController.service';
import { SubscriptionTypePrice } from 'src/app/admin-api/model/subscriptionTypePrice';
import { TableActionButton, TableColumn } from 'src/app/components/table';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';
import { SubscriptionTypePriceFormModalComponent } from './subscription-type-price-form-modal/subscription-type-price-form-modal.component';

@Component({
  selector: 'app-subscription-type-details',
  templateUrl: './subscription-type-details.component.html',
  styleUrl: './subscription-type-details.component.scss',
  providers: [
    DatePipe,
    DialogService,
    ConfirmationService,
    MessageService,
    TitleCasePipe
  ]
})
export class SubscriptionTypeDetailsComponent implements OnInit {
  @Input()
  subscriptionTypeId: number;

  subscriptionType: SubscriptionType | undefined;

  actionButtons: Array<TableActionButton> = [
    new TableActionButton(
      '',
      'remove',
      PrimeIcons.TRASH,
      (price: SubscriptionTypePrice) => !price.changed,
      '',
      'Excluir',
      'bottom',
      true,
      true,
      'danger',
      'small'
    )
  ];

  cols: Array<TableColumn> = [
    new TableColumn.Builder()
      .setHeader('Preço antigo')
      .setField('oldPrice')
      .setFilter(true)
      .setType('currency')
      .build(),
    new TableColumn.Builder()
      .setHeader('Preço novo')
      .setField('newPrice')
      .setFilter(true)
      .setType('currency')
      .build(),
    new TableColumn.Builder()
      .setHeader('Data de alteração (Aquisição/Reativação)')
      .setField('dateChanged')
      .setFilter(true)
      .setType('date')
      .setCondition('between')
      .setDisplayFunction((price: SubscriptionTypePrice) =>
        price?.dateChanged
          ? this.datePipe.transform(
              FormUtil.utcDate(price.dateChanged),
              'dd/MM/yyyy'
            )
          : null
      )
      .setStyleClass('text-right')
      .build(),
    new TableColumn.Builder()
      .setHeader('Data de alteração (Troca de plano)')
      .setField('maxDateToRenew')
      .setFilter(true)
      .setType('date')
      .setCondition('between')
      .setDisplayFunction((price: SubscriptionTypePrice) =>
        price?.dateChanged
          ? this.datePipe.transform(
              FormUtil.utcDate(price.maxDateToRenew),
              'dd/MM/yyyy'
            )
          : null
      )
      .setStyleClass('text-right')
      .build(),
    new TableColumn.Builder()
      .setHeader('Ações')
      .setField('')
      .setType('button')
      .build()
  ];
  fixedFilters: Array<PageableFilter>;
  prices: Array<SubscriptionTypePrice>;

  constructor(
    private title: Title,
    public subscriptionTypeService: SubscriptionTypeControllerService,
    public subscriptionTypePriceController: SubscriptionTypePriceControllerService,
    private datePipe: DatePipe,
    private dialog: DialogService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private titleCasePipe: TitleCasePipe
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.subscriptionTypeId) {
      LoaderService.showLoader();
      await Promise.all([
        this.findSubscriptionType(this.subscriptionTypeId),
        this.findPrices(this.subscriptionTypeId)
      ]);
      LoaderService.showLoader(false);
      this.title.setTitle(
        'Plano - ' + this.titleCasePipe.transform(this.subscriptionType.name)
      );
    } else {
      AppDialogService.showErrorDialog(
        { message: 'Plano não encontrado' },
        true
      );
    }
  }

  openNewPriceModal(): void {
    this.dialog
      .open(SubscriptionTypePriceFormModalComponent, {
        closable: true,
        closeOnEscape: true,
        header: `Agendar troca de preço`,
        data: {
          subscriptionType: this.subscriptionType
        },
        modal: true,
        width: '400px'
      })
      .onClose.subscribe(async (data: SubscriptionTypePrice) => {
        if (data) {
          this.messageService.add({
            severity: 'success',
            detail: 'Agendamento criado com sucesso.',
            summary: 'Sucesso'
          });
          await this.findPrices(this.subscriptionType?.subscriptionTypeId);
          LoaderService.showLoader(false);
        }
      });
  }

  callAction(event: {
    item: SubscriptionTypePrice;
    $event: Event;
    action: 'remove';
  }): void {
    if (event.action === 'remove') {
      const subscriptionTypePriceId = event.item.subscriptionTypePriceId;

      this.confirmationService.confirm({
        header: 'Exclusão',
        message: 'Tem certeza que deseja excluir?',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        accept: async () => {
          await this.deletePrice(subscriptionTypePriceId);
        },
        target: event.$event.target
      });
    }
  }

  async deletePrice(subscriptionTypePriceId: number) {
    LoaderService.showLoader();
    try {
      const detail = await lastValueFrom(
        this.subscriptionTypePriceController
          .deleteSubscriptionTypePrice(subscriptionTypePriceId)
          .pipe(map((data) => data.result))
      );
      this.messageService.add({
        severity: 'success',
        detail,
        summary: 'Sucesso'
      });
      await this.findPrices(this.subscriptionType?.subscriptionTypeId);
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  async findPrices(subscriptionTypeId: number): Promise<void> {
    try {
      this.prices = await lastValueFrom(
        this.subscriptionTypePriceController
          .getAllSubscriptionTypePriceBySubscriptionTypeId(subscriptionTypeId)
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findSubscriptionType(subscriptionTypeId: number): Promise<void> {
    try {
      this.subscriptionType = await lastValueFrom(
        this.subscriptionTypeService
          .findSubscriptionType(subscriptionTypeId)
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  get scheduled(): SubscriptionTypePrice {
    return this.prices?.find(
      (p) =>
        FormUtil.utcDate(p.dateChanged).getTime() >
        FormUtil.utcDate(new Date()).getTime()
    );
  }

  get scheduledDate(): string {
    return this.scheduled
      ? FormUtil.daysDiffLabel(
          new Date(),
          FormUtil.utcDate(this.scheduled.dateChanged)
        )
      : null;
  }

  get lastScheduled(): SubscriptionTypePrice {
    return !this.scheduled && this.prices
      ? this.prices.reduce((last, p) => {
          if (
            FormUtil.utcDate(p.dateChanged).getTime() >
            FormUtil.utcDate(last.dateChanged).getTime()
          ) {
            last = p;
          }
          return last;
        }, this.prices[0])
      : null;
  }

  get lastScheduledDate(): string {
    return this.lastScheduled
      ? FormUtil.daysDiffLabel(new Date(), this.lastScheduled.dateChanged)
      : null;
  }
}

import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Section } from '@infrab4a/connect';
import { PrimeNGConfig } from 'primeng/api';
import { FileUpload, FileUploadHandlerEvent } from 'primeng/fileupload';
import { FileControllerService } from 'src/app/admin-api';
import { ImageService } from 'src/app/services/image.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ImageUploadComponent } from '../image-upload/image-upload.component';

@Component({
  selector: 'app-customization-form',
  template: ''
})
export abstract class CustomizationFormComponent extends ImageUploadComponent<any> {
  documentId: string;
  newCampaignPage: string;
  editCampaignPage: string;
  documentSections: Section<'LandingPageSection'>[];
  activeSection: Section<'LandingPageSection'>;
  activeIndex: number;
  today: Date;
  fileNamePattern: string;
  override form: FormGroup;

  constructor(
    override router: Router,
    protected config: PrimeNGConfig,
    override imageService: ImageService,
    override fileService: FileControllerService
  ) {
    super(fileService, router);
    this.config.setTranslation({
      dateFormat: 'dd/mm/yy'
    });
    this.today = new Date();
  }

  abstract getDocument(): void;

  abstract initializeForm(): void;

  abstract save(): void;

  navigate(path: string) {
    this.router.navigate([path]);
  }

  async upload(
    event: FileUploadHandlerEvent,
    inputName: string,
    expectedWidth: number,
    expectedHeight: number,
    maxWeight: number,
    pInput: FileUpload
  ) {
    LoaderService.showLoader();

    if (
      expectedWidth &&
      expectedHeight &&
      !(await this.imageService.validateImageDimensions(
        event.files[0],
        expectedWidth / expectedHeight,
        maxWeight
      ))
    ) {
      pInput.clear();
      LoaderService.showLoader(false);
      return;
    }

    const fileUrl = await this.onUpload(
      event,
      inputName,
      pInput,
      this.fileNamePattern
    );
    LoaderService.showLoader(false);
    return fileUrl;
  }
}

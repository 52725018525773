export interface ChartDataset {
  type: 'pie' | 'doughtnut' | 'line' | 'bar' | 'radar' | 'polarArea';
  label: string;
  borderWidth?: number;
  backgroundColor?: string;
  borderColor?: string;
  fill?: boolean;
  tension?: number;
  data: Array<number>;
  subscriptionId?: number;
  stack?: string;
  hidden?: boolean;
}

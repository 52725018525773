import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, map } from 'rxjs';
import { ApiResponseModel, Credentials, LoginResponse } from 'src/app/models';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthService {
  constructor(private http: HttpClient) {}

  async login(credentials: Credentials): Promise<LoginResponse> {
    return await lastValueFrom(
      this.http
        .post<ApiResponseModel<LoginResponse>>(
          (environment.apiUrl ? environment.apiUrl : 'http://localhost:5000') +
            '/login',
          credentials
        )
        .pipe(map((data) => data.result))
    );
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BatchFormsAdminComponent } from './batch-forms-admin/batch-forms-admin.component';
import { BeautyProfileComponent } from './beauty-profile/beauty-profile.component';
import { CustomQueryFormComponent } from './custom-query/custom-query-form/custom-query-form.component';
import { CustomQueryComponent } from './custom-query/custom-query.component';
import { HtmlTableComponent } from './custom-query/html-table/html-table.component';
import { FeedbackCrossingComponent } from './feedback-crossing/feedback-crossing.component';
import { FeedbackFormDetailComponent } from './feedback-forms/feedback-form-detail/feedback-form-detail.component';
import { FeedbackGroupDetailsComponent } from './feedback-groups/feedback-group-details/feedback-group-details.component';
import { FeedbackGroupsComponent } from './feedback-groups/feedback-groups.component';

const routes: Routes = [
  { path: 'custom-query', component: CustomQueryComponent },
  { path: 'custom-query/form/:queryId', component: CustomQueryFormComponent },
  { path: 'custom-query/form', component: CustomQueryFormComponent },
  { path: 'custom-query/html/:queryId', component: HtmlTableComponent },
  { path: 'feedback-crossing', component: FeedbackCrossingComponent },
  { path: 'feedbacks', component: BatchFormsAdminComponent },
  { path: 'feedbacks/:formId', component: FeedbackFormDetailComponent },
  { path: 'beauty-profile', component: BeautyProfileComponent },
  { path: 'feedback-groups', component: FeedbackGroupsComponent },
  {
    path: 'feedback-groups/:groupId',
    component: FeedbackGroupDetailsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ResearchRoutingModule {}

<h2 *ngIf="!campaign">Nova campanha de Compre e Ganhe</h2>
<h2 *ngIf="campaign">Campanha - {{ campaign.name }}</h2>
<form class="form-b4a" (submit)="submit()" [formGroup]="form">
  <span class="p-float-label">
    <input
      type="text"
      pInputText
      formControlName="name"
      id="name"
      name="name"
    />
    <label for="name">Nome *</label>
  </span>
  <span class="p-float-label">
    <p-dropdown
      [options]="shops"
      formControlName="shop"
      [autoDisplayFirst]="false"
      inputId="shop"
    />
    <label for="shop">Loja *</label>
  </span>
  <span class="p-float-label">
    <p-calendar
      formControlName="startDate"
      dateFormat="dd/mm/yy"
      inputId="startDate"
    />
    <label for="startDate">Início *</label>
  </span>
  <span class="p-float-label">
    <p-calendar
      formControlName="endDate"
      dateFormat="dd/mm/yy"
      inputId="endDate"
    />
    <label for="endDate">Término</label>
  </span>
  <span class="p-input-checkbox">
    <p-checkbox formControlName="active" [binary]="true" inputId="active" />
    <label for="active">Ativa?</label>
  </span>
  <span class="p-float-label" *ngIf="form.controls.cartValue.enabled">
    <p-inputNumber
      formControlName="cartValue"
      inputId="cartValue"
      [minFractionDigits]="2"
      [maxFractionDigits]="2"
      prefix="R$"
    />
    <label for="cartValue">Valor mínimo do carrinho *</label>
    <small>
      Todos os pedidos com valor superior ao informado, receberão o(s)
      brinde(s).
    </small>
  </span>
  <span class="p-float-label" *ngIf="form.controls.cartValueMin.enabled">
    <p-inputNumber
      formControlName="cartValueMin"
      inputId="cartValueMin"
      [minFractionDigits]="2"
      [maxFractionDigits]="2"
      prefix="R$"
    />
    <label for="cartValueMin"
      >Valor mínimo do carrinho (somenta categorias informadas) *</label
    >
    <small>
      A soma do valor dos produtos que pertencem à(s) categoria(s) deverá ser
      maior que o valor informado para receber o(s) brinde(s).
    </small>
  </span>
  <span class="p-input-checkbox">
    <p-checkbox
      formControlName="activeCategory"
      [binary]="true"
      inputId="activeCategory"
      (onChange)="updateCategory()"
    />
    <label for="activeCategory">Informar categorias</label>
    <small>
      Ao selecionar esta opção, somente a(s) categoria(s) informada(s) na
      campanha serão considerada(s) no valor.
    </small>
  </span>
  <span class="p-float-label" *ngIf="form.controls.categories.enabled">
    <p-autoComplete
      [suggestions]="categoriesAvailable"
      formControlName="categories"
      filterBy="sku"
      optionLabel="name"
      appendTo="body"
      [lazy]="false"
      [multiple]="true"
      (completeMethod)="searchCategories($event)"
      [showClear]="true"
      placeholder="Busque pelo nome ou slug"
    >
      <ng-template pTemplate="item" let-item>
        <ng-container *ngIf="item.reference">{{ item.reference }}</ng-container>
        {{ item.name }}
        <ng-container *ngIf="item.slug">[slug: {{ item.slug }}]</ng-container>
        <ng-container *ngIf="item.shops">(Loja: {{ item.shops }})</ng-container>
      </ng-template>
      <ng-template pTemplate="selectedItem" let-item>
        <ng-container *ngIf="item.reference">{{ item.reference }}</ng-container>
        {{ item.name }}
        <ng-container *ngIf="item.slug">[slug: {{ item.slug }}]</ng-container>
        <ng-container *ngIf="item.shops">(Loja: {{ item.shops }})</ng-container>
      </ng-template>
    </p-autoComplete>
    <label for="product">Categorias *</label>
    <small>
      Somente produtos das categorias informadas serão utilizados no cálculo de
      valor mínimo
    </small>
  </span>

  <div class="table">
    <app-table
      [lazy]="false"
      [elements]="products"
      [cols]="productCols"
      [exportPdf]="false"
      [exportExcel]="false"
      [addButton]="false"
      modelName="brindes"
      [actionButtons]="actionButtons"
      (actionButtonClick)="remove($event)"
      emptyMessage="Sem brindes na campanha"
      *ngIf="products"
      [captionTemplate]="caption"
    >
      <ng-template #caption>
        <div class="input">
          <p-autoComplete
            [suggestions]="productsAvailable"
            [formControl]="product"
            filterBy="sku"
            optionLabel="name"
            appendTo="body"
            [lazy]="false"
            [multiple]="true"
            (completeMethod)="searchProducts($event)"
            [showClear]="true"
            placeholder="Busque pelo nome, SKU ou EAN"
          >
            <ng-template pTemplate="item" let-item>
              {{ item.name }}
              <ng-container *ngIf="item.sku"
                >[SKU: {{ item.sku }}]</ng-container
              >
              <ng-container *ngIf="item.EAN"
                >(EAN: {{ item.EAN }})</ng-container
              >
            </ng-template>
            <ng-template pTemplate="selectedItem" let-item>
              {{ item.name }}
              <ng-container *ngIf="item.sku"
                >[SKU: {{ item.sku }}]</ng-container
              >
              <ng-container *ngIf="item.EAN"
                >(EAN: {{ item.EAN }})</ng-container
              >
            </ng-template>
          </p-autoComplete>
          <p-button
            [disabled]="!productValid"
            type="button"
            (onClick)="addProduct()"
            label="Adicionar"
          />
        </div>
      </ng-template>
    </app-table>
  </div>
  <span class="buttons">
    <p-button
      type="submit"
      severity="primary"
      label="Salvar"
      [disabled]="!form.valid"
    />
  </span>
</form>

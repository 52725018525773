<app-table
  [cols]="cols"
  [findPage]="findPage"
  [service]="productService"
  modelName="categorias"
  sortField="externalIdString"
  [sortOrder]="-1"
  addLabel="Nova categoria"
  [changeColumns]="true"
  [addButton]="permission"
></app-table>

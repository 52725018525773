import {
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  WritableSignal,
  computed,
  inject,
  signal
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService, TreeNode } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { TreeNodeSelectEvent } from 'primeng/tree';
import { lastValueFrom, map } from 'rxjs';
import {
  BadgeControllerService,
  BadgeDTO,
  BeautyProfileAttribute,
  BeautyProfileControllerService,
  FeedbackControllerService,
  FeedbackForm,
  FeedbackFormDetail,
  FeedbackGroup,
  FeedbackGroupControllerService,
  FeedbackGroupOption,
  FeedbackOption,
  FeedbackQuestion,
  FeedbackQuestionGroup,
  PersonFeedbackGroupDetail,
  ProductControllerService,
  ProductVariantSummary
} from 'src/app/admin-api';
import { ProductVariantSelectModalComponent } from 'src/app/components/product-select-modal/product-variant-select-modal/product-variant-select-modal.component';
import {
  DropdownFilter,
  TableColumn,
  TableComponent
} from 'src/app/components/table';
import {
  FeedbackGroupFilterTypeEnum,
  getAllSubscriptionsIncludeAll,
  getPersonTypes
} from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-feedback-group-details',
  templateUrl: './feedback-group-details.component.html',
  styleUrl: './feedback-group-details.component.scss',
  encapsulation: ViewEncapsulation.None,
  providers: [ConfirmationService, MessageService, DialogService]
})
export class FeedbackGroupDetailsComponent implements OnInit {
  @ViewChild(TableComponent) table: TableComponent;

  private feedbackGroupService: FeedbackGroupControllerService = inject(
    FeedbackGroupControllerService
  );
  private feedbackService: FeedbackControllerService = inject(
    FeedbackControllerService
  );
  private beautyProfileService: BeautyProfileControllerService = inject(
    BeautyProfileControllerService
  );
  private badgeService: BadgeControllerService = inject(BadgeControllerService);
  private messageService: MessageService = inject(MessageService);
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private title: Title = inject(Title);
  private router: Router = inject(Router);
  private confirmationService: ConfirmationService =
    inject(ConfirmationService);
  private dialog: DialogService = inject(DialogService);
  private productService: ProductControllerService = inject(
    ProductControllerService
  );

  feedbackGroupId: number;
  feedbackGroup: WritableSignal<FeedbackGroup> = signal(undefined);
  feedbackGroupOptions: WritableSignal<Array<FeedbackGroupOption>> =
    signal(undefined);
  beautyProfile: Array<BeautyProfileAttribute>;
  form = signal(
    new FormGroup({
      feedbackGroupId: new FormControl<number>(
        { value: null, disabled: true },
        Validators.required
      ),
      feedbackGroupName: new FormControl<string>(null, [
        Validators.required,
        Validators.minLength(1)
      ])
    })
  );
  filtersForm = signal(
    new FormGroup({
      filters: new FormArray([
        new FormGroup({
          filterType: new FormControl<number>(null, Validators.required),
          optionIds: new FormControl<Array<number>>(
            { disabled: true, value: [] },
            [Validators.required, Validators.minLength(1)]
          ),
          form: new FormControl<FeedbackForm>(
            { disabled: true, value: null },
            Validators.required
          ),
          question: new FormControl<TreeNode<FeedbackQuestion>>(
            { disabled: true, value: null },
            Validators.required
          ),
          questionId: new FormControl<number>(
            { disabled: true, value: null },
            Validators.required
          ),
          productVariantId: new FormControl<number>(
            { disabled: true, value: null },
            [Validators.required, Validators.min(0)]
          ),
          productType: new FormControl<number>({ disabled: true, value: 0 }, [
            Validators.required
          ]),
          feedbackGroup: new FormControl<FeedbackGroup>(
            { disabled: true, value: null },
            [Validators.required]
          ),
          filterIn: new FormControl<number>({ disabled: true, value: 1 }, [
            Validators.required
          ]),
          productVariant: new FormControl<string>(
            { disabled: true, value: null },
            [Validators.required]
          )
        })
      ])
    })
  );
  persons: WritableSignal<Array<PersonFeedbackGroupDetail>> = signal(undefined);
  states = computed(() => {
    const states = this.persons().reduce(
      (
        list: Array<{
          label: string;
          value: string;
          items: Array<{ label: string; value: string }>;
        }>,
        p
      ) => {
        const exists = list.find(
          (s) => s.value === FormUtil.semAcento(p.shippingState).toUpperCase()
        );
        if (
          exists &&
          !exists.items.some(
            (i) => i.value === FormUtil.semAcento(p.shippingCity).toUpperCase()
          )
        ) {
          exists.items.push({
            label: p.shippingCity,
            value: FormUtil.semAcento(p.shippingCity).toUpperCase()
          });
          exists.items.sort((c1, c2) => {
            if (c1.value > c2.value) return 1;
            if (c1.value < c2.value) return -1;
            return 0;
          });
        } else if (!exists) {
          list.push({
            label: p.shippingState,
            value: FormUtil.semAcento(p.shippingState).toUpperCase(),
            items: [
              {
                label: p.shippingCity,
                value: FormUtil.semAcento(p.shippingCity).toUpperCase()
              }
            ]
          });
          list.sort((s1, s2) => {
            if (s1.value > s2.value) return 1;
            if (s1.value < s2.value) return -1;
            return 0;
          });
        }
        return list;
      },
      []
    );
    states.sort();
    return states;
  });
  dropdownFilters = computed(() => {
    return {
      shippingState: this.states()?.map((s) => ({
        label: s.label,
        value: s.value.toString()
      })),
      shippingCity: this.states(),
      personType: getPersonTypes().map((pt) => ({
        label: pt.label,
        value: pt.label
      })),
      badge: this.badges()?.map((b) => ({
        label: b.title,
        value: b.title
      })),
      subscriptionName: getAllSubscriptionsIncludeAll().map((s) => ({
        ...s,
        value: s.label
      }))
    };
  });
  personCols: Array<TableColumn> = [
    new TableColumn(
      'Id',
      'personId',
      true,
      'number',
      '/users/person/',
      'personId'
    ),
    new TableColumn(
      'Assinante',
      'personName',
      true,
      'text',
      '/users/person/',
      'personId',
      true,
      'contains'
    ),
    new TableColumn(
      'Tipo',
      'personType',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn('Joia', 'badge', true, 'text'),
    new TableColumn(
      'GlamId',
      'boxId',
      true,
      'text',
      '/users/subscribers/',
      'subscriberId',
      true,
      'contains'
    ),
    new TableColumn(
      'Assinatura',
      'subscriptionName',
      true,
      'text',
      undefined,
      undefined,
      true,
      'in'
    ),
    new TableColumn(
      'Estado',
      'shippingState',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Cidade',
      'shippingCity',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn('Mês aniversário', 'birthdayMonth', true, 'text'),
    new TableColumn(
      'Idade',
      'age',
      true,
      'number',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Aniversário',
      'birthday',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    )
  ];
  tabIndex = 0;
  badges: WritableSignal<Array<BadgeDTO>> = signal(undefined);
  questionsList: {
    [formId: number]: Array<
      FeedbackQuestion & { options?: Array<FeedbackQuestion> }
    >;
  } = {};
  questions: {
    [formId: number]: Array<FeedbackQuestion | FeedbackQuestionGroup>;
  } = {};
  questionItems: {
    [formId: number]: Array<TreeNode<FeedbackQuestion | FeedbackQuestionGroup>>;
  } = {};
  forms: Array<FeedbackFormDetail>;
  formsAvailable: Array<FeedbackFormDetail>;
  productTypes: Array<DropdownFilter> = [
    { label: 'Composição', value: 0 },
    { label: 'Movimentação (Troca, Brinde, Incentivo)', value: 1 }
  ];
  filterTypes: Array<DropdownFilter> = [
    { label: 'Perfil de beleza', value: 0 },
    { label: 'Formulário', value: 1 },
    { label: 'Variante', value: 2 },
    { label: 'Grupo', value: 3 }
  ];
  feedbackGroups: Array<FeedbackGroup>;
  selectedGroups: Array<FeedbackGroup>;

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(async (params) => {
      if (params['groupId'] && params['groupId'] !== 'new') {
        try {
          this.feedbackGroupId = Number(params['groupId']);
          await this.init();
          this.title.setTitle(
            'Grupo de pesquisa - ' + this.feedbackGroup().feedbackGroupName
          );
          this.activatedRoute.queryParams.subscribe((queryParams) => {
            if (queryParams['tab']) {
              try {
                this.tabIndex = Number(queryParams['tab']);
              } catch (error) {
                this.tabIndex = 0;
              }
            }
          });
        } catch (error) {
          AppDialogService.showErrorDialog(
            error,
            true,
            'Grupo não encontrado.'
          );
        }
      } else if (params['groupId'] && !this.beautyProfile) {
        LoaderService.showLoader();
        await Promise.all([this.findBeautyProfile(), this.findBadges()]);
        this.title.setTitle('Novo grupo de pesquisa');
        LoaderService.showLoader(false);
      } else {
        AppDialogService.showErrorDialog(null, true, 'Grupo não encontrado.');
      }
    });
  }

  async init(): Promise<void> {
    LoaderService.showLoader();
    await Promise.all([
      this.findFeedbackGroup(),
      this.findBeautyProfile(),
      this.findBadges()
    ]);
    await this.findFeedbackGroupFilters();
    this.form.update((form) => {
      form.patchValue(this.feedbackGroup());
      form.controls['feedbackGroupId'].enable();
      return form;
    });
    // if (this.feedbackGroupOptions()?.length) {
    await this.findPersons();
    // }
    LoaderService.showLoader(false);
  }

  async findFeedbackGroup(): Promise<void> {
    try {
      this.feedbackGroup.set(
        await lastValueFrom(
          this.feedbackGroupService
            .findFeedbackGroupById(this.feedbackGroupId)
            .pipe(map((data) => data.result))
        )
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error, true);
    }
  }

  async findFeedbackForm(formId: number): Promise<FeedbackForm> {
    try {
      return await lastValueFrom(
        this.feedbackService
          .findFeedbackFormById(formId)
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      return null;
    }
  }

  async findFeedbackGroupFilters(): Promise<void> {
    try {
      this.feedbackGroupOptions.set(
        await lastValueFrom(
          this.feedbackGroupService
            .findFeedbackGroupFilters(this.feedbackGroupId)
            .pipe(map((data) => data.result))
        )
      );
      if (this.feedbackGroupOptions()?.length)
        this.filtersForm().controls['filters'].clear();
      if (
        this.feedbackGroupOptions()?.some(
          (f) =>
            f.filterType ===
            FeedbackGroupFilterTypeEnum['Formulário de pesquisa']
        )
      ) {
        const forms = await Promise.all(
          this.feedbackGroupOptions()
            .filter(
              (f) =>
                f.filterType ===
                FeedbackGroupFilterTypeEnum['Formulário de pesquisa']
            )
            .reduce((list: Array<number>, fgo) => {
              if (!list.includes(fgo.formId)) list.push(fgo.formId);
              return list;
            }, [])
            .map((f) => this.findFeedbackForm(f))
        );
        this.forms = forms?.filter((f) => f) || [];
        await Promise.all(this.forms?.map((f) => this.findQuestions(f.formId)));
        await Promise.all(
          Object.keys(this.questions)
            .reduce(
              (
                list: Array<
                  FeedbackQuestion & { options?: Array<FeedbackOption> }
                >,
                f
              ) => {
                list = list.concat(this.questions[f]);
                return list;
              },
              []
            )
            .map((q) => this.findOptions(q))
        );
        this.forms.forEach((form) => {
          const options = this.feedbackGroupOptions()?.filter(
            (fgo) => fgo.formId === form.formId
          );
          const questions = this.questionItems[form.formId].filter((q) =>
            options.map((o) => o.questionId.toString()).includes(q.key)
          );
          questions.forEach((question) => {
            this.addFilter({
              filterType: FeedbackGroupFilterTypeEnum['Formulário de pesquisa'],
              form,
              optionIds: options
                .filter((o) => o.questionId.toString() === question.key)
                .map((fgo) => fgo.value),
              question,
              questionId: (question.data as FeedbackQuestion).questionId,
              filterIn: 1,
              productType: 0,
              productVariant: null,
              productVariantId: null,
              feedbackGroup: null
            });
          });
        });
      }
      if (
        this.feedbackGroupOptions()?.some(
          (f) =>
            f.filterType === FeedbackGroupFilterTypeEnum['Perfil de beleza']
        )
      ) {
        const attributes = this.feedbackGroupOptions()
          ?.filter(
            (f) =>
              f.filterType === FeedbackGroupFilterTypeEnum['Perfil de beleza']
          )
          .reduce((list: Array<number>, fgo) => {
            if (!list.includes(fgo.questionId)) {
              list.push(fgo.questionId);
            }
            return list;
          }, []);
        attributes.forEach((a) => {
          this.addFilter({
            filterType: FeedbackGroupFilterTypeEnum['Perfil de beleza'],
            form: null,
            optionIds: this.feedbackGroupOptions()
              ?.filter(
                (fgo) =>
                  fgo.filterType ===
                    FeedbackGroupFilterTypeEnum['Perfil de beleza'] &&
                  fgo.questionId === a
              )
              .map((fgo) => fgo.value),
            question: this.beautyProfile.find((g) => g.id === a) as any,
            questionId: this.beautyProfile.find((g) => g.id === a)?.id,
            feedbackGroup: null,
            filterIn: 1,
            productType: 0,
            productVariant: null,
            productVariantId: null
          });
        });
      }
      if (
        this.feedbackGroupOptions()?.some(
          (f) => f.filterType === FeedbackGroupFilterTypeEnum.Variante
        )
      ) {
        const products = await Promise.all(
          this.feedbackGroupOptions()
            ?.filter(
              (f) => f.filterType === FeedbackGroupFilterTypeEnum.Variante
            )
            .reduce((list: Array<number>, fgo) => {
              if (!list.includes(fgo.value)) {
                list.push(fgo.value);
              }
              return list;
            }, [])
            .map((fgo) =>
              lastValueFrom(
                this.productService
                  .findProductVariantById(fgo)
                  .pipe(map((data) => data.result))
              )
            )
        );
        products.forEach((p) => {
          this.addFilter({
            filterType: FeedbackGroupFilterTypeEnum.Variante,
            form: null,
            optionIds: null,
            question: null,
            questionId: null,
            feedbackGroup: null,
            filterIn: 1,
            productType:
              this.feedbackGroupOptions().find(
                (o) =>
                  o.filterType === FeedbackGroupFilterTypeEnum.Variante &&
                  o.value === p.productVariantId
              )?.productType || 0,
            productVariant: p.productVariantName,
            productVariantId: p.productVariantId
          });
        });
      }
      if (
        this.feedbackGroupOptions()?.some(
          (f) =>
            f.filterType === FeedbackGroupFilterTypeEnum['Grupo de pesquisa']
        )
      ) {
        const groups = await Promise.all(
          this.feedbackGroupOptions()
            ?.filter(
              (f) =>
                f.filterType ===
                FeedbackGroupFilterTypeEnum['Grupo de pesquisa']
            )
            .reduce((list: Array<number>, fgo) => {
              if (!list.includes(fgo.value)) {
                list.push(fgo.value);
              }
              return list;
            }, [])
            .map((fgo) =>
              lastValueFrom(
                this.feedbackGroupService
                  .findFeedbackGroupById(fgo)
                  .pipe(map((data) => data.result))
              )
            )
        );
        groups.forEach((g) => {
          this.addFilter({
            filterType: FeedbackGroupFilterTypeEnum['Grupo de pesquisa'],
            form: null,
            optionIds: null,
            question: null,
            questionId: null,
            feedbackGroup: g,
            filterIn:
              this.feedbackGroupOptions()?.find(
                (o) =>
                  o.filterType ===
                    FeedbackGroupFilterTypeEnum['Grupo de pesquisa'] &&
                  o.value === g.feedbackGroupId
              )?.filterIn === false
                ? 0
                : 1,
            productType: 0,
            productVariant: null,
            productVariantId: null
          });
        });
      }
      if (!this.filtersForm().value.filters?.length) {
        this.addFilter();
      }
    } catch (error) {
      this.feedbackGroupOptions.set([]);
      AppDialogService.showErrorDialog(error);
    }
  }

  async findBeautyProfile(): Promise<void> {
    try {
      this.beautyProfile = await lastValueFrom(
        this.beautyProfileService
          .findBeautyProfileFormActive()
          .pipe(
            map((data) =>
              data.result.reduce(
                (list: Array<BeautyProfileAttribute>, g) =>
                  (list = list.concat(g.attributes)),
                []
              )
            )
          )
      );
    } catch (error) {
      this.beautyProfile = [];
      AppDialogService.showErrorDialog(error);
    }
  }

  async saveForm(): Promise<void> {
    LoaderService.showLoader();
    try {
      if (this.feedbackGroup()) {
        this.feedbackGroup.set(
          await lastValueFrom(
            this.feedbackGroupService
              .updateFeedbackGroup(this.form().value)
              .pipe(map((data) => data.result))
          )
        );
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'Grupo atualizado com sucesso.'
        });
      } else {
        const group = await lastValueFrom(
          this.feedbackGroupService
            .createFeedbackGroup(this.form().value)
            .pipe(map((data) => data.result))
        );
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'Grupo criado com sucesso.'
        });
        this.router.navigate(
          ['/research/feedback-groups/' + group.feedbackGroupId],
          { queryParams: { tab: 1 } }
        );
      }
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  async findPersons(): Promise<void> {
    try {
      if (this.persons()?.length) {
        this.persons.set(undefined);
      }
      this.persons.set(
        await lastValueFrom(
          this.feedbackGroupService
            .findPersonDetailsInGroup(this.feedbackGroupId)
            .pipe(map((data) => data.result))
        )
      );
    } catch (error) {
      this.persons.set([]);
      AppDialogService.showErrorDialog(error);
    }
  }

  async applyFilters(): Promise<void> {
    if (this.filtersForm().valid) {
      LoaderService.showLoader();
      try {
        this.feedbackGroupOptions.set(
          await lastValueFrom(
            this.feedbackGroupService
              .filterSubscribers(
                this.feedbackGroupId,
                this.filtersForm().value.filters?.reduce(
                  (list: Array<FeedbackGroupOption>, f) =>
                    (list = list.concat(
                      f.optionIds?.map(
                        (o) =>
                          ({
                            feedbackGroupId: this.feedbackGroupId,
                            formId: f.form?.formId || 0,
                            questionId: f.questionId,
                            filterIn: !!f.filterIn,
                            filterType: f.filterType,
                            productType: f.productType,
                            value: o
                          } as FeedbackGroupOption)
                      ) || [
                        {
                          feedbackGroupId: this.feedbackGroupId,
                          formId: f.form?.formId || 0,
                          questionId: f.questionId,
                          filterIn: !!f.filterIn,
                          filterType: f.filterType,
                          productType: f.productType,
                          value:
                            f.productVariantId ||
                            f.feedbackGroup?.feedbackGroupId
                        }
                      ]
                    )),
                  []
                ) || []
              )
              .pipe(map((data) => data.result))
          )
        );
        await this.findPersons();
      } catch (error) {
        AppDialogService.showErrorDialog(error);
      }
      LoaderService.showLoader(false);
    }
  }

  navigate(): void {
    this.router.navigate(
      ['/research/feedback-groups/' + this.feedbackGroupId],
      { queryParams: { tab: this.tabIndex } }
    );
  }

  async findBadges(): Promise<void> {
    try {
      this.badges.set(
        (await lastValueFrom(
          this.badgeService.findActiveBadges().pipe(map((data) => data.result))
        )) || []
      );
    } catch (error: any) {
      console.log(error);
      this.badges.set([]);
    }
  }

  async findFeedbackGroups(search: string): Promise<void> {
    try {
      this.feedbackGroups =
        (await lastValueFrom(
          this.feedbackGroupService
            .findFeedbackGroupsTable({
              page: 0,
              pageSize: 5,
              filters: [
                {
                  condition: 'contains',
                  field: 'feedbackGroupName',
                  fieldType: 'text',
                  value: search
                },
                {
                  condition: 'notEquals',
                  field: 'feedbackGroupId',
                  fieldType: 'number',
                  value: this.feedbackGroupId.toString()
                }
              ],
              sortBy: 'feedbackGroupName',
              sortDirection: 'asc'
            })
            .pipe(map((data) => data.result.content))
        )) || [];
    } catch (error: any) {
      console.log(error);
      this.feedbackGroups = [];
    }
  }

  async removeFilter(i: number): Promise<void> {
    this.filtersForm().controls['filters'].removeAt(i);
  }

  async findFeedbackForms(search: string): Promise<void> {
    try {
      this.forms = await lastValueFrom(
        this.feedbackService
          .findFeedbackFormsTable({
            page: 0,
            pageSize: 5,
            filters: [
              {
                condition: 'contains',
                field: 'formTitle',
                fieldType: 'text',
                value: search
              }
            ],
            sortBy: 'formTitle',
            sortDirection: 'ASC'
          })
          .pipe(map((data) => data.result.content))
      );
      this.formsAvailable = [...this.forms];
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findQuestions(formId: number): Promise<void> {
    try {
      this.questions[formId] = await lastValueFrom(
        this.feedbackService.findFormQuestions(formId).pipe(
          map((data) => {
            return data.result.questions
              .concat(data.result.groups)
              .filter(
                (q: FeedbackQuestion | FeedbackQuestionGroup) =>
                  (q as FeedbackQuestionGroup).feedbackGroupId ||
                  [3, 4].includes((q as FeedbackQuestion).typeId)
              );
          })
        )
      );
      this.questions[formId].sort(
        (q1, q2) => q1.questionOrder - q2.questionOrder
      );
      this.questionsList[formId] = this.questions[formId].reduce((list, q) => {
        if ((q as FeedbackQuestion).questionId) list.push(q);
        else list = list.concat((q as FeedbackQuestionGroup).questions);
        return list;
      }, []);
      this.questionItems[formId] = this.questions[formId].map((q) => {
        (q as FeedbackQuestionGroup)?.questions?.sort(
          (q1, q2) => q1.questionOrder - q2.questionOrder
        );
        return {
          label:
            q.questionOrder +
            '. ' +
            ((q as FeedbackQuestion).title ||
              (q as FeedbackQuestionGroup).feedbackGroupName),
          key:
            (q as FeedbackQuestion).questionId?.toString() ||
            (q as FeedbackQuestionGroup).feedbackGroupId?.toString(),
          children:
            (q as FeedbackQuestionGroup).questions?.map(
              (qq, i) =>
                ({
                  label: i + 1 + '. ' + qq.title,
                  data: qq,
                  leaf: true,
                  key: qq.questionId.toString()
                } as TreeNode<FeedbackQuestion>)
            ) || undefined,
          leaf: !(q as FeedbackQuestionGroup).questions?.length,
          selectable: (q as FeedbackQuestion).questionId > 0,
          data: q
        } as TreeNode<FeedbackQuestion | FeedbackQuestionGroup>;
      });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findOptions(
    question: FeedbackQuestion & { options?: Array<FeedbackOption> }
  ): Promise<void> {
    try {
      question.options = await lastValueFrom(
        this.feedbackService
          .findQuestionOptions(question.questionId)
          .pipe(map((data) => data.result))
      );
      question.options.sort((o1, o2) => o1.optionOrder - o2.optionOrder);
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async questionChange(i: number, $event: TreeNodeSelectEvent): Promise<void> {
    this.filtersForm()
      .controls['filters'].at(i)
      .controls['questionId'].setValue(Number($event.node.key));
    const question = this.questionsList[
      this.filtersForm().value.filters[i].form?.formId
    ].find(
      (q) =>
        (q as FeedbackQuestion).questionId ===
        this.filtersForm().value.filters[i].questionId
    );
    if (!question.options?.length) {
      LoaderService.showLoader();
      await this.findOptions(question);
      LoaderService.showLoader(false);
    }
    this.filtersForm()
      .controls['filters']?.at(i)
      ?.controls['optionIds']?.reset();
    this.filtersForm()
      .controls['filters']?.at(i)
      ?.controls['optionIds']?.enable();
  }

  attributeChange(i: number, attribute: BeautyProfileAttribute): void {
    this.filtersForm()
      .controls['filters'].at(i)
      .controls['questionId'].setValue(attribute.id);
    this.filtersForm().controls['filters'].at(i).controls['optionIds'].reset();
    this.filtersForm().controls['filters'].at(i).controls['optionIds'].enable();
  }

  async formChange(form: FeedbackForm, i: number): Promise<void> {
    if (!form && i > 0) {
      this.filtersForm().controls['filters']?.at(i)?.reset();
      this.filtersForm()
        .controls['filters']?.at(i)
        ?.controls['question']?.disable();
      this.filtersForm()
        .controls['filters']?.at(i)
        ?.controls['questionId']?.disable();
      this.filtersForm()
        .controls['filters']?.at(i)
        ?.controls['optionIds']?.disable();
      return;
    }
    if (form && i >= 0) {
      this.filtersForm()
        .controls['filters'].at(i)
        .controls['filterType'].setValue(form.formId ? 1 : 0);
      if (!this.questionsList[form.formId]?.length) {
        LoaderService.showLoader();
        await this.findQuestions(form.formId);
        LoaderService.showLoader(false);
      }
      this.filtersForm()
        .controls['filters']?.at(i)
        ?.controls['question']?.enable();
      this.filtersForm()
        .controls['filters']?.at(i)
        ?.controls['questionId']?.enable();
      this.filtersForm()
        .controls['filters']?.at(i)
        ?.controls['optionIds']?.disable();
    }
  }

  addFilter(init?: {
    filterType: number;
    form: FeedbackFormDetail;
    optionIds: Array<number>;
    questionId: number;
    question: TreeNode<FeedbackQuestion>;
    productVariantId: number;
    feedbackGroup: FeedbackGroup;
    productVariant: string;
    productType: number;
    filterIn: number;
  }): void {
    this.filtersForm().controls['filters'].push(
      new FormGroup({
        filterType: new FormControl(init?.filterType, Validators.required),
        form: new FormControl(
          {
            value: init?.form,
            disabled:
              !init ||
              init.filterType !==
                FeedbackGroupFilterTypeEnum['Formulário de pesquisa']
          },
          Validators.required
        ),
        optionIds: new FormControl(
          {
            disabled: !init || !init.question,
            value: init?.optionIds || []
          },
          [Validators.required, Validators.minLength(1)]
        ),
        questionId: new FormControl(
          {
            disabled:
              !init ||
              init.filterType === FeedbackGroupFilterTypeEnum['Variante'] ||
              init.filterType ===
                FeedbackGroupFilterTypeEnum['Grupo de pesquisa'] ||
              (init.filterType ===
                FeedbackGroupFilterTypeEnum['Formulário de pesquisa'] &&
                !init.form),
            value: init?.questionId
          },
          Validators.required
        ),
        question: new FormControl(
          {
            disabled:
              !init ||
              init.filterType === FeedbackGroupFilterTypeEnum['Variante'] ||
              init.filterType ===
                FeedbackGroupFilterTypeEnum['Grupo de pesquisa'] ||
              (init.filterType ===
                FeedbackGroupFilterTypeEnum['Formulário de pesquisa'] &&
                !init.form),
            value: init?.question
          },
          Validators.required
        ),
        productType: new FormControl(
          {
            disabled:
              !init || init.filterType !== FeedbackGroupFilterTypeEnum.Variante,
            value: init?.productType || 0
          },
          [Validators.required]
        ),
        productVariantId: new FormControl(
          {
            disabled:
              !init || init.filterType !== FeedbackGroupFilterTypeEnum.Variante,
            value: init?.productVariantId
          },
          [Validators.required, Validators.min(1)]
        ),
        feedbackGroup: new FormControl(
          {
            disabled:
              !init ||
              init.filterType !==
                FeedbackGroupFilterTypeEnum['Grupo de pesquisa'],
            value: init?.feedbackGroup
          },
          [Validators.required]
        ),
        filterIn: new FormControl(
          {
            disabled:
              !init ||
              init.filterType !==
                FeedbackGroupFilterTypeEnum['Grupo de pesquisa'],
            value: init && init.filterIn !== undefined ? init.filterIn : 1
          },
          [Validators.required]
        ),
        productVariant: new FormControl(
          {
            disabled:
              !init || init.filterType !== FeedbackGroupFilterTypeEnum.Variante,
            value: init?.productVariant
          },
          [Validators.required]
        )
      })
    );
  }

  question(
    i: number
  ): (FeedbackQuestion & { options?: Array<FeedbackOption> }) | any {
    return this.filtersForm().value.filters[i].filterType ===
      FeedbackGroupFilterTypeEnum['Formulário de pesquisa']
      ? this.questionsList[
          this.filtersForm().value.filters[i].form?.formId
        ]?.find(
          (q) =>
            (q as FeedbackQuestion).questionId ===
            this.filtersForm().value.filters[i].questionId
        )
      : this.filtersForm().value.filters[i].filterType ===
        FeedbackGroupFilterTypeEnum['Perfil de beleza']
      ? this.beautyProfile.find(
          (a) => a.id === this.filtersForm().value.filters[i].questionId
        )
      : null;
  }

  filterChanged($event: { $event: any; field: string }): void {
    if (
      $event.field === 'shippingState' &&
      this.table?.valueControl($event.field)?.value?.length
    ) {
      this.dropdownFilters()['shippingCity'] = this.states()?.filter((s) =>
        (
          this.table?.valueControl($event.field).value as Array<string>
        ).includes(s.value)
      );
    }
  }

  resetFilters(): void {
    this.confirmationService.confirm({
      acceptLabel: 'Sim',
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: 'Não',
      header: 'Limpar filtros',
      message: 'Deseja limpar os filtros e redefinir as assinantes?',
      accept: async () => {
        LoaderService.showLoader();
        (this.filtersForm().get('filters') as FormArray).clear();
        await this.applyFilters();
        this.addFilter();
        LoaderService.showLoader(false);
      }
    });
  }

  addProduct(i: number) {
    this.dialog
      .open(ProductVariantSelectModalComponent, {
        header: 'Selecionar produto',
        dismissableMask: undefined,
        width: '60%'
      })
      .onClose.subscribe(async (data: ProductVariantSummary) => {
        if (data) {
          this.filtersForm.update((form) => {
            form.controls['filters']
              .at(i)
              .controls['productVariantId'].setValue(data.productVariantId);
            form.controls['filters']
              .at(i)
              .controls['productVariant'].setValue(data.productVariantName);
            return form;
          });
        }
      });
  }

  changeFilterType(i: number): void {
    const type = this.filtersForm().value.filters[i].filterType;
    this.filtersForm()
      .controls['filters'].at(i)
      .controls['feedbackGroup'].disable();
    this.filtersForm()
      .controls['filters'].at(i)
      .controls['feedbackGroup'].reset(null);
    this.filtersForm().controls['filters'].at(i).controls['filterIn'].disable();
    this.filtersForm().controls['filters'].at(i).controls['filterIn'].reset(1);
    this.filtersForm().controls['filters'].at(i).controls['form'].disable();
    this.filtersForm().controls['filters'].at(i).controls['form'].reset(null);
    this.filtersForm()
      .controls['filters'].at(i)
      .controls['optionIds'].disable();
    this.filtersForm()
      .controls['filters'].at(i)
      .controls['optionIds'].reset([]);
    this.filtersForm()
      .controls['filters'].at(i)
      .controls['productType'].disable();
    this.filtersForm()
      .controls['filters'].at(i)
      .controls['productType'].reset(0);
    this.filtersForm()
      .controls['filters'].at(i)
      .controls['productVariantId'].disable();
    this.filtersForm()
      .controls['filters'].at(i)
      .controls['productVariantId'].reset(0);
    this.filtersForm().controls['filters'].at(i).controls['question'].disable();
    this.filtersForm()
      .controls['filters'].at(i)
      .controls['question'].reset(null);
    this.filtersForm()
      .controls['filters'].at(i)
      .controls['questionId'].disable();
    this.filtersForm()
      .controls['filters'].at(i)
      .controls['questionId'].reset(0);
    switch (type) {
      case 1:
        this.filtersForm().controls['filters'].at(i).controls['form'].enable();
        break;
      case 2:
        this.filtersForm()
          .controls['filters'].at(i)
          .controls['productType'].enable();
        this.filtersForm()
          .controls['filters'].at(i)
          .controls['productVariantId'].enable();
        this.filtersForm()
          .controls['filters'].at(i)
          .controls['productVariant'].enable();
        break;
      case 3:
        this.filtersForm()
          .controls['filters'].at(i)
          .controls['feedbackGroup'].enable();
        this.filtersForm()
          .controls['filters'].at(i)
          .controls['filterIn'].enable();
        break;
      default:
        this.filtersForm()
          .controls['filters'].at(i)
          .controls['question'].enable();
        this.filtersForm()
          .controls['filters'].at(i)
          .controls['questionId'].enable();
        break;
    }
  }
}

import { Component, inject } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { SubscriberRefundSummary } from 'src/app/admin-api';

@Component({
  selector: 'app-subscriber-refund-details-modal',
  templateUrl: './subscriber-refund-details-modal.component.html',
  styleUrl: './subscriber-refund-details-modal.component.scss'
})
export class SubscriberRefundDetailsModalComponent {
  private config = inject(DynamicDialogConfig);
  refund = this.config.data.refund as SubscriberRefundSummary;
}

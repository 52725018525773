<div class="coupon-products">
  <form class="form-b4a" [formGroup]="restrictionForm" *ngIf="groups?.length">
    <span class="p-input-checkbox" *ngIf="groups?.length > 1 && !schedule">
      <p-checkbox
        formControlName="restrictAllGroups"
        inputId="validateByGroup"
        label="Restringir todos os grupos ao(s) mesmo(s) plano(s)"
        [binary]="true"
        (onChange)="updateRestrictionSettings($event)"
      />
    </span>
    <span class="full-w" *ngIf="restrictionForm.value.restrictAllGroups">
      <label
        for="subscriptionTypes"
        *ngIf="groups?.length > 1 || groups[0].variants?.length > 1"
      >
        Restrição de assinatura e plano dos brindes
      </label>
      <label
        for="subscriptionTypes"
        *ngIf="groups?.length === 1 && groups[0].variants?.length === 1"
      >
        Restrição de assinatura e plano do brinde
      </label>
      <p-multiSelect
        *ngIf="
          subscriptionTypesTree?.length && couponProductVariantSubscriptionTypes
        "
        formControlName="subscriptionTypeIds"
        inputId="subscriptionTypes"
        [options]="subscriptionTypesTree"
        appendTo="body"
        scrollHeight="250px"
        display="chip"
        (onPanelHide)="dirtyForm() && saveRestrictions()"
        [group]="true"
        placeholder="Sem restrições"
      >
        <ng-template let-group pTemplate="group">
          <span>{{ group.label }}</span>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <span>{{ item.label }}</span>
        </ng-template>
        <ng-template pTemplate="empty">
          <span>Sem restrições</span>
        </ng-template>
        <ng-template let-value pTemplate="selectedItems">
          <span *ngFor="let item of value"
            >{{ subscriptionLabel(item) }},
          </span>
          <span *ngIf="!value?.length">Sem restrições</span>
        </ng-template>
      </p-multiSelect>
      <small *ngIf="restrictionForm.value.subscriptionTypeIds?.length"
        >* Somente assinatuas com os plano(s) informado(s) receberão os brindes
      </small>
    </span>
  </form>
  <p-button
    icon="pi pi-plus"
    label="Adicionar grupo"
    severity="primary"
    size="small"
    (onClick)="addProductVariant()"
  />
  <span *ngIf="outOfStock" class="stock-warning">
    <p>
      <strong>
        * Atenção! Não será possível utilizar o cupom. <br />
        Existe(m) grupo(s) sem estoque do(s) produto(s).
      </strong>
    </p>
  </span>
  <div
    class="product-groups"
    [class.product-groups--dialog]="schedule"
    [formGroup]="subscriptionTypesForm"
  >
    <ng-container formArrayName="subscriptionTypes">
      <div
        *ngFor="let group of groups; let i = index"
        class="product-groups__group card"
      >
        <div class="product-groups__group__header">
          <h4>Grupo {{ i + 1 }} - {{ group.variants.length }} variante(s)</h4>
          <p-button
            [rounded]="true"
            severity="primary"
            size="small"
            styleClass="p-button-xs"
            icon="pi pi-plus"
            pTooltip="Adicionar variante"
            tooltipPosition="bottom"
            (onClick)="addProductVariant(group.groupVariantId)"
          />
        </div>
        <br />
        <div
          class="product-groups__group__product__bottom"
          *ngIf="!restrictionForm.value.restrictAllGroups"
        >
          <p><b>Restrição de assinatura e plano</b></p>
          <p-multiSelect
            *ngIf="subscriptionTypesTree?.length"
            [formControlName]="i"
            inputId="productVariantSubscriptionTypeIds"
            [options]="subscriptionTypesTree"
            appendTo="body"
            scrollHeight="250px"
            display="chip"
            (onPanelHide)="
              groupRestrictionsDirty(i) && updateGroupRestrictions()
            "
            [group]="true"
            placeholder="Sem restrição"
          >
            <ng-template let-group pTemplate="group">
              <span>{{ group.label }}</span>
            </ng-template>
            <ng-template let-item pTemplate="item">
              <span>{{ item.label }}</span>
            </ng-template>
            <ng-template let-item pTemplate="empty">
              <span>Sem restrições</span>
            </ng-template>
            <ng-template let-value pTemplate="selectedItems">
              <span *ngFor="let item of value"
                >{{ subscriptionLabel(item) }},
              </span>
              <span *ngIf="!value?.length">Sem restrições</span>
            </ng-template>
          </p-multiSelect>
        </div>
        <small *ngIf="group.variants.length > 1"
          >*Somente 1 produto do grupo é enviado aleatoriamente</small
        >
        <br />
        <div class="product-groups__group__products">
          <div
            class="product-groups__group__product"
            *ngFor="let productVariant of group.variants; let pIndex = index"
          >
            <div class="product-groups__group__product__top">
              <img
                class="product-groups__group__product__image"
                [src]="productVariant.defaultImageURL"
                *ngIf="productVariant.defaultImageURL"
              />
              <img
                class="product-groups__group__product__image"
                src="assets/images/no_img_available.jpg"
                *ngIf="!productVariant.defaultImageURL"
              />
              <div class="product-groups__group__product__info">
                <p>
                  <a
                    class="data-link"
                    type="button"
                    [routerLink]="
                      '/products/catalog/product-variant/' +
                      productVariant.productVariantId
                    "
                    [class.out-of-stock]="
                      !productVariant.inStockReserveMarketing ||
                      productVariant.inStockReserveMarketing <= 0
                    "
                    [pTooltip]="outOfStock ? 'Sem estoque' : ''"
                    tooltipPosition="bottom"
                  >
                    {{ productVariant.productVariantName }}
                  </a>
                </p>
              </div>
              <div class="product-groups__group__product__actions">
                <p-button
                  icon="pi pi-trash"
                  pTooltip="Remover variante"
                  tooltipPosition="bottom"
                  [text]="true"
                  [rounded]="true"
                  severity="danger"
                  (onClick)="
                    removeProductVariant(
                      $event,
                      productVariant,
                      group.groupVariantId
                    )
                  "
                />
              </div>
            </div>
            <div class="product-groups__group__product__bottom">
              <p><b>Marca</b> {{ productVariant.brandName }}</p>
              <p>
                <b>Nota</b>
                {{ productVariant.ratingAverage || 0 | number : '1.2-2' }}
              </p>
              <p><b>EAN</b> {{ productVariant.internalEAN || '-' }}</p>
              <p><b>SKU Admin</b> {{ productVariant.productVariantId }}</p>
              <p>
                {{ productVariant.reviewCount || 0 | number : '1.0-0' }}
                avaliações
              </p>
              <p>
                {{
                  productVariant.inStockReserveMarketing || 0 | number : '1.0-0'
                }}
                unidades
              </p>
            </div>
            <div class="product-groups__group__product__bottom">
              <p><b>Quantidade</b></p>
              <p-inputNumber
                (ngModelChange)="updateQuantity($event, productVariant)"
                [(ngModel)]="productVariant.quantity"
                [ngModelOptions]="{ standalone: true }"
                [showButtons]="true"
                buttonLayout="form-b4a"
                spinnerMode="form-b4a"
                inputId="quantity"
                [min]="1"
                decrementButtonClass="p-button-secondary"
                incrementButtonClass="p-button-secondary"
                incrementButtonIcon="pi pi-plus"
                decrementButtonIcon="pi pi-minus"
                styleClass="inputnumber-small"
              />
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

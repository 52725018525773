import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgxPermissionsService } from 'ngx-permissions';
import { ConfirmationService, MessageService, PrimeIcons } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import {
  FeedbackGroupControllerService,
  FeedbackGroupDetail
} from 'src/app/admin-api';
import {
  TableActionButton,
  TableColumn,
  TableComponent
} from 'src/app/components/table';
import { Role, roleAsObject } from 'src/app/models';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-feedback-groups',
  templateUrl: './feedback-groups.component.html',
  styleUrl: './feedback-groups.component.scss',
  providers: [ConfirmationService, MessageService]
})
export class FeedbackGroupsComponent implements OnInit {
  @ViewChild(TableComponent) table: TableComponent;

  feedbackGroupService: FeedbackGroupControllerService = inject(
    FeedbackGroupControllerService
  );
  private title: Title = inject(Title);
  private permissionsService: NgxPermissionsService = inject(
    NgxPermissionsService
  );
  private confirmationService: ConfirmationService =
    inject(ConfirmationService);
  private messageService: MessageService = inject(MessageService);

  cols: Array<TableColumn> = [
    new TableColumn(
      'Id do grupo',
      'feedbackGroupId',
      true,
      'number',
      '',
      'feedbackGroupId'
    ),
    new TableColumn(
      'Nome',
      'feedbackGroupName',
      true,
      'text',
      '',
      'feedbackGroupId',
      true,
      'contains'
    ),
    new TableColumn(
      'Filtros',
      'filters',
      true,
      'formattedInteger',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Assinantes',
      'subscribers',
      true,
      'formattedInteger',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Proprietário',
      'username',
      true,
      'text',
      undefined,
      undefined,
      true,
      'startsWith'
    ),
    new TableColumn(
      'Data criação',
      'dateCreated',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    ),
    new TableColumn(
      'Últ. Atualização',
      'dateUpdated',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    ),
    new TableColumn('Ações', '', false, 'button')
  ];
  actionButtons: Array<TableActionButton>;

  async ngOnInit(): Promise<void> {
    this.title.setTitle('Grupos de pesquisa');
    const permission = await this.permissionsService.hasPermission([
      roleAsObject(Role.Full_Administrator).enumValue,
      roleAsObject(Role.Business_Intelligence).enumValue,
      roleAsObject(Role.Customer_Success).enumValue
    ]);
    this.actionButtons = [
      new TableActionButton(
        '',
        'delete',
        PrimeIcons.TRASH,
        (item: FeedbackGroupDetail) =>
          permission ||
          (item.userId && item.userId === TokenStorageService.userId),
        '',
        'Excluir grupo',
        'bottom',
        true,
        true,
        'danger',
        'small'
      )
    ];
  }

  deleteGroup($event: {
    item: FeedbackGroupDetail;
    $event: Event;
    action: string;
  }): void {
    this.confirmationService.confirm({
      acceptLabel: 'Sim',
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: 'Não',
      rejectButtonStyleClass: 'p-button-primary',
      message: 'Deseja excluir o grupo? A segmentação será perdida!',
      header: 'Excluir grupo',
      target: $event.$event.target,
      accept: async () => {
        try {
          LoaderService.showLoader();
          await lastValueFrom(
            this.feedbackGroupService.deleteFeedbackGroup(
              $event.item.feedbackGroupId
            )
          );
          this.messageService.add({
            summary: 'Sucesso',
            detail: 'Grupo excluído com sucesso',
            severity: 'success'
          });
          await this.table.refresh();
        } catch (error) {
          AppDialogService.showErrorDialog(error);
        }
        LoaderService.showLoader(false);
      }
    });
  }
}

import {
  CdkDragEnter,
  CdkDropList,
  moveItemInArray
} from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ProductImage } from 'src/app/admin-api';

@Component({
  selector: 'app-drag-drop-list',
  templateUrl: './drag-drop-list.component.html',
  styleUrls: ['./drag-drop-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DragDropListComponent<T> implements AfterViewInit {
  @Input() template: TemplateRef<any> | null = null;
  @ViewChild(CdkDropList) placeholder: CdkDropList | undefined;
  @Input() list: Array<T> | undefined;
  @Output() listChanged = new EventEmitter<Array<T>>();
  @Input() rowItems = 3;

  protected cdRef = inject(ChangeDetectorRef);

  public target: CdkDropList | undefined;
  public targetIndex: number | undefined;
  public source: CdkDropList | undefined;
  public sourceIndex: number | undefined;
  public dragIndex: number | undefined;

  ngAfterViewInit(): void {
    const phElement = this.placeholder?.element.nativeElement;

    if (phElement) {
      phElement.style.display = 'none';
      phElement.parentNode?.removeChild(phElement);
    }
  }

  indexOf(collection: HTMLCollection, node: any): number {
    return Array.prototype.indexOf.call(collection, node);
  }

  enter(e: CdkDragEnter) {
    const drag = e.item;
    const drop = e.container;

    if (drop === this.placeholder) return;

    const phElement = this.placeholder?.element.nativeElement;
    const dropElement = drop.element.nativeElement;

    const dragIndex =
      typeof this.sourceIndex === 'number'
        ? this.sourceIndex
        : this.indexOf(
            dropElement.parentNode?.children as HTMLCollection,
            drag.dropContainer.element.nativeElement
          );

    const dropIndex = this.indexOf(
      dropElement.parentNode?.children as HTMLCollection,
      dropElement
    );

    if (!this.source && phElement) {
      this.sourceIndex = dragIndex;
      this.source = drag.dropContainer;
      const sourceElement = this.source.element.nativeElement;

      phElement.style.width = 212 + 'px';
      phElement.style.height = sourceElement.clientHeight + 'px';

      sourceElement.parentNode?.removeChild(sourceElement);
    }

    if (phElement) phElement.style.display = '';

    if (this.sourceIndex && this.sourceIndex < dropIndex) {
      if (this.targetIndex && dropIndex < this.targetIndex) {
        dropElement.parentElement?.insertBefore(phElement as Node, dropElement);
      } else {
        dropElement.parentElement?.insertBefore(
          phElement as Node,
          dropElement.nextSibling
        );
      }
    } else {
      if (this.targetIndex && this.targetIndex < dropIndex) {
        dropElement.parentElement?.insertBefore(
          phElement as Node,
          dropElement.nextSibling
        );
      } else {
        dropElement.parentElement?.insertBefore(phElement as Node, dropElement);
      }
    }

    this.targetIndex = dropIndex;
    this.target = drop;

    this.placeholder?._dropListRef.enter(
      drag._dragRef,
      drag.element.nativeElement.offsetLeft,
      drag.element.nativeElement.offsetTop
    );

    this.cdRef.detectChanges();
  }

  drop(): void {
    if (!this.target) return;

    const phElement = this.placeholder?.element.nativeElement;
    const parent = phElement?.parentNode;

    if (phElement) phElement.style.display = 'none';

    parent?.removeChild(phElement as Node);

    parent?.insertBefore(
      this.source?.element.nativeElement as Node,
      parent.children[this.sourceIndex as number]
    );

    if (this.sourceIndex != this.targetIndex) {
      moveItemInArray(
        this.list as Array<ProductImage>,
        this.sourceIndex as number,
        this.targetIndex as number
      );
    }

    this.target = undefined;
    this.source = undefined;
    this.targetIndex = undefined;
    this.sourceIndex = undefined;

    this.cdRef.detectChanges();
    this.listChanged.emit(this.list);
  }

  componentOutlet(item: T): any {
    return { data: item };
  }

  get itemsClass(): string {
    return `items${this.rowItems.toString()}`;
  }
}

<ng-container *ngIf="person && history">
  <app-table
    [elements]="history"
    [addButton]="false"
    [cols]="cols"
    [emptyMessage]="person.name + ' ainda não realizou alterações no perfil'"
    [exportExcel]="false"
    [exportPdf]="false"
    [lazy]="false"
    sortField="dateCreated"
    [sortOrder]="1"
    [changeColumns]="true"
    [showTitle]="false"
    modelName="registros"
  />
</ng-container>

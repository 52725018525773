import { Component, Input, OnInit } from '@angular/core';
import { lastValueFrom, map } from 'rxjs';
import {
  PersonControllerService,
  PersonDetail,
  SocialMedia,
  SocialMediaUserDetail,
  SocialMediaUserLogDetail
} from 'src/app/admin-api';
import { AppDialogService } from 'src/app/services/dialog.service';
import { TableColumn } from '../table';

@Component({
  selector: 'app-person-social-medias',
  templateUrl: './person-social-medias.component.html',
  styleUrls: ['./person-social-medias.component.scss']
})
export class PersonSocialMediasComponent implements OnInit {
  @Input({ required: true })
  currentSocialMedias: Array<SocialMediaUserDetail> | undefined;
  @Input({ required: true })
  socialMediaHistory: Array<SocialMediaUserLogDetail> | undefined;
  @Input({ required: true })
  person: PersonDetail;
  @Input()
  isMobile = false;

  cols: Array<TableColumn> = [
    new TableColumn(
      'Rede social',
      'socialMediaName',
      true,
      'text',
      undefined,
      undefined,
      true,
      'equals'
    ),
    new TableColumn(
      'Usuário',
      'username',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Data vínculo',
      'dateCreated',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    )
  ];
  historyCols: Array<TableColumn> = [
    new TableColumn(
      'Rede social',
      'socialMediaName',
      true,
      'text',
      undefined,
      undefined,
      true,
      'equals'
    ),
    new TableColumn(
      'Usuário antigo',
      'oldUsername',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Novo usuário',
      'newUsername',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Data',
      'dateCreated',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    )
  ];
  socialMedias: Array<SocialMedia> | undefined;

  constructor(private personController: PersonControllerService) {}

  async ngOnInit(): Promise<void> {
    try {
      this.socialMedias = await lastValueFrom(
        this.personController
          .findSocialMedias()
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      this.socialMedias = [];
      AppDialogService.showErrorDialog(error);
    }
  }
}

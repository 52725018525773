import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { TooltipModule } from 'primeng/tooltip';
import { DragDropListModule } from '../drag-drop-list/drag-drop-list.module';
import { ProductImagesComponent } from './product-images.component';

@NgModule({
  declarations: [ProductImagesComponent],
  imports: [
    CommonModule,
    FileUploadModule,
    DragDropListModule,
    DropdownModule,
    FormsModule,
    ConfirmPopupModule,
    TooltipModule
  ],
  exports: [ProductImagesComponent]
})
export class ProductImagesModule {}

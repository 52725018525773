export interface ZipcodeResponse {
  cep: number;
  codigoCepEstado: number;
  codigoCepCidade: number;
  codigoCepBairro: number;
  codigoIbgeCidade: number;
  codigoIbgeEstado: number;
  estado: string;
  siglaEstado: string;
  cidade: string;
  bairro: string;
  logradouro: string;
}

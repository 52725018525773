export enum PersonTypeEnum {
  'Glamgirl' = 1,
  'Glampartner' = 2,
  'Friends and family' = 3
}

export function getPersonTypes(): Array<{ label: string; value: number }> {
  const objetos: Array<{ label: string; value: number }> = [];
  for (const [p, propertyValue] of Object.entries(PersonTypeEnum)) {
    try {
      if (Number(propertyValue) >= 0) {
        objetos.push({
          label: p,
          value: Number(propertyValue)
        });
      }
    } catch (error: any) {
      objetos.length;
    }
  }
  return objetos;
}

export function getPersonTypesIncludeAll(): Array<{
  label: string;
  value: number;
}> {
  const objetos: Array<{ label: string; value: number }> = [];
  for (const [p, propertyValue] of Object.entries(PersonTypeEnum)) {
    try {
      if (Number(propertyValue) >= 0) {
        objetos.push({
          label: p,
          value: Number(propertyValue)
        });
      }
    } catch (error: any) {
      objetos.length;
    }
  }
  return [
    {
      label: 'Todas',
      value: -1
    }
  ].concat(objetos);
}

import { TitleCasePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { lastValueFrom, map } from 'rxjs';
import {
  DoubleCommission,
  InfluencerControllerService,
  InfluencerTransactionType,
  ItOpsControllerService,
  Subscription,
  SubscriptionControllerService,
  SubscriptionType
} from 'src/app/admin-api';
import {
  DropdownFilter,
  TableColumn,
  TableComponent
} from 'src/app/components/table';
import { SubscriberProblemGroupEnum, SubscriptionEnum } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-double-commission',
  templateUrl: './double-commission.component.html',
  styleUrls: ['./double-commission.component.scss'],
  providers: [ConfirmationService, MessageService, TitleCasePipe]
})
export class DoubleCommissionComponent implements OnInit {
  @ViewChild(TableComponent) table: TableComponent;

  columns: Array<TableColumn> = [
    new TableColumn('', '', false, 'checkbox'),
    new TableColumn(
      'SubscriberId',
      'subscriberid',
      true,
      'number',
      '/users/subscribers/',
      'subscriberid'
    ),
    new TableColumn(
      'PersonId',
      'personId',
      true,
      'number',
      '/users/person/',
      'personId'
    ),
    new TableColumn('Order', 'orderId', true, 'number'),
    new TableColumn(
      'SubscriptionId',
      'subscriptionId',
      true,
      'number',
      undefined,
      undefined,
      true,
      'in'
    ),
    new TableColumn(
      'SubscriptionTypeId',
      'subscriptionTypeId',
      true,
      'number',
      undefined,
      undefined,
      true,
      'in'
    ),
    new TableColumn(
      'CouponId',
      'couponId',
      true,
      'number',
      '/marketing/coupons/',
      'couponId'
    ),
    new TableColumn(
      'InfluencerTransactionTypeId',
      'influencerTransactionTypeId',
      true,
      'number',
      undefined,
      undefined,
      true,
      'in'
    ),
    new TableColumn('Valor', 'amount', true, 'currency'),
    new TableColumn('Descrição', 'description', true, 'number'),
    new TableColumn('Quantas Duplicatas', 'total', true, 'number'),
    new TableColumn(
      'Nome do Cupom',
      'couponName',
      true,
      'text',
      '/marketing/coupons/',
      'couponId',
      true,
      'contains'
    )
  ];

  dropdownFilters: { [field: string]: Array<DropdownFilter> };
  subscriptionTypes: Array<SubscriptionType>;
  subscriptions: Array<Subscription>;
  influencerTransactionTypes: Array<InfluencerTransactionType>;

  constructor(
    public itOpsService: ItOpsControllerService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private subscriptionService: SubscriptionControllerService,
    private influencerService: InfluencerControllerService,
    private titleCasePipe: TitleCasePipe
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      this.subscriptions = await lastValueFrom(
        this.subscriptionService
          .findSubscriptionList()
          .pipe(map((data) => data.result))
      );
      this.subscriptionTypes = (
        await Promise.all(
          this.subscriptions.map((s) =>
            lastValueFrom(
              this.subscriptionService
                .findSubscriptionTypeList(s.subscriptionId)
                .pipe(map((data) => data.result))
            )
          )
        )
      ).reduce(
        (types: Array<SubscriptionType>, sts) => (types = types.concat(sts)),
        []
      );
      this.influencerTransactionTypes = await lastValueFrom(
        this.influencerService
          .findInfluencerTransactionTypes()
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    this.dropdownFilters = {};
    if (this.subscriptions) {
      this.dropdownFilters['subscriptionId'] = this.subscriptions.map((s) => ({
        label:
          s.subscriptionId +
          '. ' +
          this.titleCasePipe.transform(s.subscriptionName),
        value: s.subscriptionId.toString()
      }));
    }
    if (this.subscriptionTypes) {
      this.dropdownFilters['subscriptionTypeId'] = this.subscriptionTypes.map(
        (s) => ({
          label:
            s.subscriptionTypeId +
            '. ' +
            this.titleCasePipe.transform(
              SubscriptionEnum[s.subscriptionId] + ' ' + s.name
            ),
          value: s.subscriptionTypeId.toString()
        })
      );
    }
    if (this.influencerTransactionTypes) {
      this.dropdownFilters['influencerTransactionTypeId'] =
        this.influencerTransactionTypes.map((t) => ({
          label:
            t.influencerTransactionTypeId +
            '. ' +
            t.influencerTransactionTypeName,
          value: t.influencerTransactionTypeId.toString()
        }));
    }
  }

  markAsResolved(elements: Array<DoubleCommission>): void {
    this.confirmationService.confirm({
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      rejectButtonStyleClass: 'p-button-danger',
      message: 'Deseja enviar o relatório?',
      header: 'Corrigir',
      accept: async () => {
        LoaderService.showLoader();
        try {
          await lastValueFrom(
            this.itOpsService.markAsResolved(
              SubscriberProblemGroupEnum.DoubleCommission,
              elements
            )
          );
          await this.table.refresh(true);
          this.messageService.add({
            summary: 'Sucesso',
            detail: elements.length + ' marcado(s) como resolvido(s)',
            severity: 'success'
          });
          LoaderService.showLoader(false);
        } catch (error) {
          LoaderService.showLoader(false);
          AppDialogService.showErrorDialog(error);
        }
      }
    });
  }
}

/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Person {
  personId?: number;
  username?: string;
  name?: string;
  lastName?: string;
  badgeId?: number;
  phone?: string;
  rewardBalance?: number;
  birthday?: Date;
  cpf?: number;
  dateCreated?: Date;
  dateUpdated?: Date;
  billingInfoId?: number;
  shippingInfoId?: number;
  personStatus?: number;
  newsletterOptIn?: boolean;
  inviterId?: number;
  postalCode?: string;
  street?: string;
  streetNumber?: string;
  streetExtensions?: string;
  neighborhood?: string;
  city?: string;
  cityCode?: number;
  state?: string;
  stateCode?: number;
  country?: string;
  countryCode?: number;
  facebookId?: number;
  facebookAccessToken?: string;
  facebookAccessTokenExpiration?: number;
  maxipagoCustomerId?: string;
  badgeIdIsManual?: boolean;
  registrationIP?: string;
  registrationHost?: string;
  registrationURL?: string;
  registrationMedium?: string;
  utmSource?: string;
  utmMedium?: string;
  utmContent?: string;
  utmCampaign?: string;
  gender?: string;
  couponName?: string;
  syncGlamShop?: number;
  dateCouponNameEdited?: Date;
  nameImageProfile?: string;
  urlImageProfile?: string;
  role?: string;
}

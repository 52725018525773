import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private static showProgress = new EventEmitter<boolean>(false);

  public static showLoader(show = true): void {
    this.showProgress.emit(show);
  }

  public static get progressSubscriber(): Observable<boolean> {
    return this.showProgress.asObservable();
  }
}

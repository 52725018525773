<div class="feedback-groups">
  <div class="feedback-groups__left">
    <div class="card">
      <div class="card-user">
        <div class="card-body">
          <h3 *ngIf="feedbackGroup()">
            Grupo de pesquisa - {{ feedbackGroup().feedbackGroupName }}
          </h3>
          <h3 *ngIf="!feedbackGroup()">Novo grupo de pesquisa</h3>
          <br />
          <p *ngIf="persons()?.length">
            {{ persons().length | number : '1.0-0' }} assinantes
          </p>
          <p *ngIf="filtersForm()?.value?.filters?.length">
            {{ filtersForm().value.filters.length | number : '1.0-0' }} filtros
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="feedback-groups__right card">
    <p-tabView [(activeIndex)]="tabIndex" (activeIndexChange)="navigate()">
      <p-tabPanel header="Informações">
        <form
          (submit)="saveForm()"
          [formGroup]="form()"
          class="form-b4a"
          [class.new-form]
        >
          <span class="p-float-label">
            <input
              type="text"
              pInputText
              formControlName="feedbackGroupName"
              name="feedbackGroupName"
              id="feedbackGroupName"
            />
            <label for="feedbackGroupName">Nome do grupo *</label>
          </span>
          <span class="buttons">
            <p-button
              type="submit"
              label="Salvar"
              [disabled]="!form().valid"
              severity="primary"
            ></p-button>
          </span>
        </form>
      </p-tabPanel>
      <p-tabPanel header="Assinantes" *ngIf="feedbackGroup()">
        <div class="feedback-groups__filters">
          <form
            class="form-b4a"
            [formGroup]="filtersForm()"
            (submit)="applyFilters()"
          >
            <div formArrayName="filters" class="form-filters">
              <ng-container
                *ngFor="
                  let filter of filtersForm().controls['filters'].controls;
                  let i = index
                "
              >
                <p-button
                  icon="pi pi-minus"
                  severity="danger"
                  [rounded]="true"
                  size="small"
                  (onClick)="removeFilter(i)"
                  type="button"
                  *ngIf="i > 0"
                ></p-button>
                <div [formGroupName]="i" class="form-filter">
                  <span class="p-float-label">
                    <p-dropdown
                      (onChange)="changeFilterType(i)"
                      [options]="filterTypes"
                      formControlName="filterType"
                      appendTo="body"
                      [autoDisplayFirst]="false"
                      inputId="filterType"
                      name="filterType"
                      id="filterType"
                    ></p-dropdown>
                    <label for="filterType">Tipo de filtro</label>
                  </span>
                  <span
                    class="p-float-label"
                    *ngIf="
                      filter.value.filterType && filter.value.filterType === 1
                    "
                  >
                    <p-autoComplete
                      [suggestions]="formsAvailable"
                      [lazy]="true"
                      formControlName="form"
                      (completeMethod)="findFeedbackForms($event.query)"
                      [inputId]="'formId' + i"
                      [name]="'formId' + i"
                      (onSelect)="formChange($event.value, i)"
                      placeholder="Pesquise pelo nome"
                      filterBy="formTitle"
                      optionLabel="formTitle"
                      appendTo="body"
                      (onClear)="formChange(null, i)"
                      [showClear]="true"
                    >
                      <ng-template let-form pTemplate="item">
                        {{ form.formTitle }}
                      </ng-template>
                    </p-autoComplete>
                    <label [for]="'formId' + i">Formulário</label>
                  </span>
                  <ng-container
                    *ngIf="
                      filter.value.filterType !== undefined &&
                      filter.value.filterType <= 1
                    "
                  >
                    <span class="p-float-label">
                      <p-dropdown
                        [options]="[]"
                        formControlName="questionId"
                        [inputId]="'questionId' + i"
                        *ngIf="
                          filter.value.filterType === undefined ||
                          filter.value.filterType === null ||
                          (filter.value.filterType === 1 &&
                            (!filter.value.form?.formId ||
                              !questionItems[filter.value.form?.formId]))
                        "
                      ></p-dropdown>
                      <p-treeSelect
                        formControlName="question"
                        [options]="questionItems[filter.value.form?.formId]"
                        *ngIf="
                          filter.value.filterType === 1 &&
                          questionItems[filter.value.form?.formId]
                        "
                        [name]="'questionId' + i"
                        [inputId]="'questionId' + i"
                        (onNodeSelect)="questionChange(i, $event)"
                        [filter]="true"
                        appendTo="body"
                      >
                      </p-treeSelect>
                      <p-dropdown
                        formControlName="question"
                        [autoDisplayFirst]="false"
                        [options]="beautyProfile"
                        *ngIf="filter.value.filterType === 0"
                        optionLabel="name"
                        [name]="'questionId' + i"
                        [inputId]="'questionId' + i"
                        [filter]="true"
                        [autoDisplayFirst]="false"
                        appendTo="body"
                        (onChange)="attributeChange(i, $event.value)"
                      ></p-dropdown>
                      <label [for]="'questionId' + i">Pergunta</label>
                    </span>
                    <span class="p-float-label">
                      <p-dropdown
                        [options]="[]"
                        [inputId]="'optionIds' + i"
                        [name]="'optionIds' + i"
                        formControlName="optionIds"
                        *ngIf="
                          (filter.value.filterType === 1 &&
                            !filter.value.form) ||
                          !filter.value.questionId
                        "
                      ></p-dropdown>
                      <p-multiSelect
                        [options]="question(i)?.options"
                        *ngIf="
                          filter.value.filterType === 1 &&
                          filter.value.form?.formId > 0 &&
                          filter.value.questionId
                        "
                        formControlName="optionIds"
                        optionLabel="optionText"
                        optionValue="optionId"
                        [filter]="true"
                        filterBy="optionText"
                        [name]="'optionIds' + i"
                        [inputId]="'optionIds' + i"
                        appendTo="body"
                      ></p-multiSelect>
                      <p-multiSelect
                        [options]="question(i)?.options"
                        *ngIf="
                          filter.value.filterType === 0 &&
                          filter.value.questionId
                        "
                        formControlName="optionIds"
                        optionLabel="name"
                        optionValue="id"
                        [filter]="true"
                        filterBy="name"
                        [name]="'optionIds' + i"
                        [inputId]="'optionIds' + i"
                        appendTo="body"
                      ></p-multiSelect>
                      <label [for]="'optionIds' + i">Alternativas</label>
                    </span>
                  </ng-container>
                  <ng-container *ngIf="filter.value.filterType === 2">
                    <span class="p-float-label">
                      <p-dropdown
                        [options]="productTypes"
                        formControlName="productType"
                        inputId="productType"
                        name="productType"
                        id="productType"
                        [autoDisplayFirst]="false"
                        appendTo="body"
                      ></p-dropdown>
                      <label for="productType">Tipo de envio</label>
                    </span>
                    <span class="p-float-label">
                      <input
                        type="text"
                        pInputText
                        readonly
                        (click)="addProduct(i)"
                        formControlName="productVariant"
                        name="productVariant"
                        id="productVariant"
                      />
                      <label for="productVariant">Variante</label>
                    </span>
                  </ng-container>
                  <ng-container *ngIf="filter.value.filterType === 3"
                    ><span class="p-float-label">
                      <p-dropdown
                        [options]="[
                          { label: 'Está presente', value: 1 },
                          { label: 'Não está presente', value: 0 }
                        ]"
                        formControlName="filterIn"
                        inputId="filterIn"
                        name="filterIn"
                        id="filterIn"
                        [autoDisplayFirst]="false"
                        appendTo="body"
                      ></p-dropdown>
                      <label for="filterIn">Condição</label>
                    </span>
                    <span class="p-float-label">
                      <p-autoComplete
                        [suggestions]="feedbackGroups"
                        [lazy]="true"
                        formControlName="feedbackGroup"
                        (completeMethod)="findFeedbackGroups($event.query)"
                        [inputId]="'feedbackGroup' + i"
                        [name]="'feedbackGroup' + i"
                        placeholder="Pesquise pelo nome"
                        filterBy="feedbackGroupName"
                        optionLabel="feedbackGroupName"
                        appendTo="body"
                        [showClear]="true"
                      >
                        <ng-template let-group pTemplate="item">
                          {{ group.feedbackGroupName }}
                        </ng-template>
                      </p-autoComplete>
                      <label [for]="'feedbackGroup' + i"
                        >Grupo de pesquisa</label
                      >
                    </span>
                  </ng-container>
                </div>
              </ng-container>
              <p-button
                icon="pi pi-plus"
                severity="success"
                [rounded]="true"
                size="small"
                *ngIf="filtersForm().controls['filters'].valid"
                (onClick)="addFilter()"
                type="button"
              ></p-button>
            </div>
            <span class="buttons">
              <p-button
                label="Limpar filtros"
                severity="danger"
                type="button"
                (onClick)="resetFilters()"
                [disabled]="!filtersForm().value.filters?.length"
              ></p-button>
              <p-button
                label="Aplicar filtros"
                severity="success"
                type="submit"
                [disabled]="!filtersForm().valid"
              ></p-button>
            </span>
          </form>
          <br />
          <app-table
            *ngIf="persons()?.length"
            [lazy]="false"
            [elements]="persons()"
            [cols]="personCols"
            [addButton]="false"
            modelName="assinantes"
            tableTitle="Assinantes filtradas"
            [multiSelectFilter]="true"
            [dropdownFilters]="dropdownFilters()"
            [changeColumns]="true"
            sortField="personName"
            [sortOrder]="-1"
            (changedFilter)="filterChanged($event)"
          >
          </app-table>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
<p-toast></p-toast>
<p-confirmDialog></p-confirmDialog>

<ng-container *ngIf="ready">
  <h3 *ngIf="shop">
    {{ pageName }}
  </h3>
  <app-gridstack
    [options]="gridOptions"
    *ngIf="sectionTyped?.type !== 'Banner' && form"
  >
    <app-gridstack-item
      *ngFor="let group of form.controls; let i = index"
      [options]="{
        noResize: true,
        id: i,
        minH: sectionTyped?.type === 'Banner[]' ? 2: 1,
        maxH: sectionTyped?.type === 'Banner[]' ? 2: 1,
      }"
      class="gridstack-card"
    >
      <p-fieldset *ngIf="sectionTyped?.type === 'Banner[]'">
        <ng-template pTemplate="header">
          <div class="fieldset-label">
            <span>{{ i + 1 }} - {{ group.value.alt }}</span>
          </div>
        </ng-template>
        <app-dms-banner-form
          [form]="group"
          [shop]="shop"
          [banner]="section[i]"
          [sectionId]="id"
        />
        <div class="actions">
          <p-button
            icon="pi pi-trash"
            [text]="true"
            [rounded]="true"
            severity="danger"
            pTooltip="Excluir"
            tooltipPosition="bottom"
            *ngIf="form.controls.length > 1"
            (onClick)="deleteBannerOrCollection(i)"
          />
          <i
            class="pi pi-arrows-v p-ripple p-element p-button p-button-icon-only p-button-text p-button-primary p-button-rounded"
            pTooltip="Mover"
            tooltipPosition="bottom"
          ></i>
        </div>
      </p-fieldset>
      <p-card *ngIf="sectionTyped?.type === 'string[]'">
        <span class="p-float-label">
          <p-autoComplete
            [suggestions]="availableCategories"
            [formControl]="group"
            filterBy="name,slug"
            optionLabel="name"
            appendTo="body"
            [lazy]="false"
            [multiple]="false"
            (completeMethod)="searchCollections($event)"
            placeholder="Busque pelo nome ou slug"
            [name]="'collection_' + i"
            [inputId]="'collection_' + i"
            (onSelect)="collectionChanged($event, i)"
            (onBlur)="checkCollection(i)"
            (click)="group.reset()"
          >
            <ng-template pTemplate="item" let-item>
              {{ item.name }} [{{ item.slug }}]
            </ng-template>
          </p-autoComplete>
          <label for="brand">Coleção *</label>
        </span>
        <p-carousel
          [value]="products[group.value.id]"
          *ngIf="group.value?.id && products[group.value.id]?.length"
          [numVisible]="4"
          [numScroll]="4"
          [circular]="false"
          [responsiveOptions]="responsiveOptionsSmall"
          [showNavigators]="false"
          [showIndicators]="false"
          styleClass="carousel__glam"
        >
          <ng-template let-product pTemplate="item">
            <div class="collections-banner">
              <img
                *ngIf="product.images?.length"
                [alt]="product.name"
                [src]="product.images[0]"
                class="collection-banner"
              />
              <img
                *ngIf="!product.images?.length"
                [alt]="product.name"
                src="assets/images/no_img_available.jpg"
                class="collection-banner"
              />
            </div>
          </ng-template>
        </p-carousel>
        <div class="actions">
          <p-button
            icon="pi pi-trash"
            [text]="true"
            [rounded]="true"
            severity="danger"
            pTooltip="Excluir"
            tooltipPosition="bottom"
            *ngIf="form.controls.length > 1"
            (onClick)="deleteBannerOrCollection(i)"
          />
          <i
            class="pi pi-arrows-v p-ripple p-element p-button p-button-icon-only p-button-text p-button-primary p-button-rounded"
            pTooltip="Mover"
            tooltipPosition="bottom"
          ></i>
        </div>
      </p-card>
    </app-gridstack-item>
  </app-gridstack>
  <app-dms-banner-form
    [form]="form"
    *ngIf="sectionTyped?.type === 'Banner'"
    [shop]="shop"
    [banner]="section"
    [sectionId]="id"
  />
  <div class="add-button">
    <p-button
      label="Adicionar banner"
      severity="success"
      (onClick)="addBanner()"
      *ngIf="sectionTyped?.type === 'Banner[]'"
    />
    <p-button
      label="Adicionar coleção"
      severity="success"
      (onClick)="addCollection()"
      *ngIf="
        sectionTyped?.type === 'string[]' &&
        (sectionTyped.id !== 'verticalCarousels' || form.controls.length < 3)
      "
    />
  </div>
  <div class="footer">
    <p-button
      label="Voltar"
      severity="danger"
      routerLink="../"
      *ngIf="sectionTyped.id !== 'featuredCampaignBanner'"
    />
    <p-button label="Salvar" [disabled]="!form?.valid" (onClick)="submit()" />
  </div>
</ng-container>
<ng-template #bannersTemp let-banner="banner" let-form="form"> </ng-template>
<p-toast />
<p-confirmPopup />

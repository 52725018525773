import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef
} from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  Edition,
  EditionControllerService,
  IncidentControllerService,
  IncidentType,
  Person,
  PersonControllerService,
  ProductControllerService,
  ProductVariantSummary,
  SubscriberControllerService,
  SubscriberInfo
} from 'src/app/admin-api';
import { IncidentTypeEnum, SubscriptionEnum } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-incident-form',
  templateUrl: './incident-form.component.html',
  styleUrl: './incident-form.component.scss',
  providers: [DialogService, MessageService]
})
export class IncidentFormComponent implements OnInit {
  incidentService = inject(IncidentControllerService);
  editionService = inject(EditionControllerService);
  subscriberService = inject(SubscriberControllerService);
  productService = inject(ProductControllerService);
  personService = inject(PersonControllerService);
  ref = inject(DynamicDialogRef);
  config = inject(DynamicDialogConfig);
  dialog = inject(DialogService);
  messageService = inject(MessageService);

  incidentTypes: Array<IncidentType> = this.config?.data?.incidentTypes;
  editions: Array<Edition> = this.config?.data?.editions;
  incidentTypeId: number = this.config?.data?.incidentTypeId;
  subscriberId: number = this.config?.data?.subscriberId;
  subscriberInfo: SubscriberInfo;
  productVariants: Array<ProductVariantSummary>;
  persons: Array<Person>;
  lastSearch: string;

  form = new FormGroup({
    incidentTypeId: new FormControl<number>(null, [
      Validators.required,
      Validators.min(1)
    ]),
    description: new FormControl<string>(null, [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(400)
    ]),
    productVariantId: new FormControl<number>(null, [
      Validators.required,
      Validators.min(1)
    ]),
    productVariant: new FormControl<ProductVariantSummary>(null, [
      Validators.required
    ]),
    personId: new FormControl<number>({ value: null, disabled: true }, [
      Validators.required,
      Validators.min(1)
    ]),
    person: new FormControl<Person>({ value: null, disabled: true }, [
      Validators.required,
      Validators.min(1)
    ]),
    editionId: new FormControl<number>(null)
  });

  async ngOnInit(): Promise<void> {
    const promises = [];
    if (!this.incidentTypes?.length) promises.push(this.findIncidentTypes());
    if (!this.editions?.length) promises.push();
    LoaderService.showLoader();
    if (this.incidentTypeId)
      this.form.controls['incidentTypeId'].setValue(this.incidentTypeId);
    if (this.subscriberId) {
      this.form.controls['incidentTypeId'].setValue(this.subscriberId);
    }
    if (promises?.length) {
      await Promise.all(promises);
      LoaderService.showLoader(false);
    }
  }

  async findIncidentTypes(): Promise<void> {
    try {
      this.incidentTypes = await lastValueFrom(
        this.incidentService
          .findIncidentTypes()
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      this.incidentTypes = [];
      AppDialogService.showErrorDialog(error);
    }
  }

  async findEditions(): Promise<void> {
    try {
      this.editions = await lastValueFrom(
        this.editionService
          .findEditionsBySubscriptionId(SubscriptionEnum.Glambox)
          .pipe(
            map((data) =>
              data.result.map((e) => ({
                ...e,
                editionId: e.editionId % 1000000
              }))
            )
          )
      );
    } catch (error) {
      this.editions = [];
      AppDialogService.showErrorDialog(error);
    }
  }

  async findSubscriberInfo(): Promise<void> {
    try {
      this.subscriberInfo = await lastValueFrom(
        this.subscriberService
          .findSubscriberInfoById(this.subscriberId)
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      delete this.subscriberInfo;
      AppDialogService.showErrorDialog(error);
    }
  }

  formattedCPF(cpf: number): string {
    return FormUtil.cpfFormatted(cpf);
  }

  async searchProductVariants(search: string): Promise<void> {
    try {
      this.productVariants = await lastValueFrom(
        this.productService
          .searchVariantsByNameOrExternalIdOrBrandOrInternalEAN(search)
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      this.productVariants = [];
      AppDialogService.showErrorDialog(error);
    }
  }

  productChange(product?: ProductVariantSummary): void {
    this.form.controls['productVariantId'].setValue(
      product?.productVariantId || null
    );
  }

  async searchPersons(search: string): Promise<void> {
    try {
      this.lastSearch = search;
      this.persons = await lastValueFrom(
        this.personService
          .searchPersonByNameOrEmailOrCpf(search)
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      this.persons = [];
      AppDialogService.showErrorDialog(error);
    }
  }

  clearPersons($event: KeyboardEvent): void {
    if (($event.target as any).value !== this.lastSearch) delete this.persons;
  }

  personChange(person?: Person): void {
    this.form.controls['personId'].setValue(person?.personId || null);
  }

  enableFields(): void {
    if (this.form.value.incidentTypeId === IncidentTypeEnum.Reenvio) {
      this.form.controls['personId'].enable();
      this.form.controls['person'].enable();
    } else {
      this.form.controls['person'].disable();
      this.form.controls['personId'].disable();
    }
  }

  async submit(): Promise<void> {
    if (this.form.valid) {
      LoaderService.showLoader();
      try {
        const incident = await lastValueFrom(
          this.incidentService
            .createIncident(this.form.value)
            .pipe(map((data) => data.result))
        );
        this.messageService.add({
          severity: 'success',
          detail: 'Ocorrência registrada com sucesso.',
          summary: 'Sucesso'
        });
        this.ref.close(incident);
      } catch (error) {
        LoaderService.showLoader(false);
        AppDialogService.showErrorDialog(error);
      }
    }
  }
}

<h3 *ngIf="collection">
  Editar coleção: {{ collection.title || collection.altText }}
</h3>
<h3 *ngIf="!collection">Nova coleção</h3>
<form class="form-b4a" [formGroup]="form" (submit)="submit()">
  <ng-container formArrayName="infobars">
    <p-card
      *ngFor="let item of infobars.controls; let i = index"
      [formGroupName]="i"
    >
      <div class="form-b4a__card">
        <div class="form-b4a__card__group">
          <span class="p-float-label">
            <input
              type="text"
              pInputText
              formControlName="text"
              id="text"
              name="text"
            />
            <label for="text">Texto* (max 45 caracteres)</label>
          </span>
          <span class="p-float-label">
            <input
              type="text"
              pInputText
              formControlName="link"
              id="link"
              name="link"
            />
            <label for="link">Link</label>
          </span>
        </div>
        <div class="form-b4a__card__group">
          <span class="p-float-label">
            <p-dropdown
              [options]="defaultColors"
              [autoDisplayFirst]="false"
              formControlName="color"
              inputId="color"
            />
            <label for="color">Cor do background *</label>
          </span>
          <p-colorPicker
            *ngIf="showColorPicker(i)"
            formControlName="customColor"
          />
        </div>
        <p-button
          icon="pi pi-trash"
          [text]="true"
          [rounded]="true"
          severity="danger"
          pTooltip="Excluir"
          tooltipPosition="top"
          (onClick)="removeItem(i)"
        />
      </div>
    </p-card>
  </ng-container>
  <p-card
    (click)="addItem(null)"
    [ngStyle]="{
      cursor: 'pointer',
      'pointer-events': infobars.controls.length >= 3 ? 'none' : 'unset'
    }"
  >
    <div class="form-b4a__add-infobar">
      <p>Adicionar infobar</p>
      <i class="pi pi-plus" style="font-size: 36px"></i>
    </div>
  </p-card>
  <span class="input-switch">
    <label for="published">Inativo</label>
    <p-inputSwitch formControlName="published" inputId="published" />
    <label for="published">Ativo</label>
  </span>

  <span class="buttons">
    <p-button label="Voltar" severity="danger" routerLink="../../" />
    <p-button label="Salvar" type="submit" [disabled]="!form.valid" />
  </span>
</form>

<div class="card card-user" *ngIf="person">
  <div
    class="image"
    [class.image--bag]="subscriptionId === 5"
    [class.image--pass]="subscriptionId === 6"
    [class.image--combo]="subscriptionId === 7"
    [class.image--blue]="background === 'blue'"
  ></div>
  <div class="card-body">
    <div class="user">
      <img
        class="avatar"
        [src]="person.urlImageProfile"
        *ngIf="person.urlImageProfile"
      />
      <img
        class="avatar"
        src="assets/images/settings/empty-avatar.png"
        *ngIf="!person.urlImageProfile"
      />
      <h5
        class="influencer"
        *ngIf="influencer"
        [pTooltip]="influencer.description || ''"
      >
        {{ influencer.influencerStatus }} ({{
          influencer.enabled ? 'Ativo' : 'Inativo'
        }})
      </h5>
      <h5 class="title">
        {{ person.name }} {{ person.lastName }}
        <ng-container *ngIf="person.personStatus === 15">(Fraude)</ng-container>
        <i
          class="pi pi-external-link"
          pTooltip="Ver usuário"
          tooltipPosition="bottom"
          *ngIf="showProfileLink"
          (click)="openPersonPage()"
        ></i>
      </h5>
      <a
        class="instagram"
        *ngIf="person.instagram"
        [attr.href]="'https://instagram.com/' + person.instagram"
        pTooltip="Ver instagram"
        tooltipPosition="right"
      >
        <span class="data-link">&#64;{{ person.instagram }}</span>
        <i class="pi pi-instagram"></i>&nbsp;
      </a>
      <p-button
        severity="secondary"
        size="small"
        styleClass="p-button-xs"
        label="Resetar senha"
        *ngIf="showResetPassword"
        (onClick)="resetPassword()"
      ></p-button>
      <ng-container *ngxPermissionsOnly="['ROLE_ADMIN', 'ROLE_FINANCIAL']">
        <p-button
          *ngIf="person.personId && person.personStatus !== 15"
          severity="danger"
          size="small"
          styleClass="p-button-xs"
          label="Apontar Fraude"
          icon="pi pi-ban"
          iconPos="left"
          (onClick)="fraudCancellation()"
        ></p-button>
      </ng-container>
    </div>
    <p class="description" *ngIf="person.username">
      <strong>Email:</strong>
      <span [class.facebook]="person.facebookId">
        {{ person.username
        }}<i
          *ngIf="person.facebookId"
          pTooltip="Acesso por Facebook"
          tooltipPosition="right"
          class="pi pi-facebook p-button-primary"
        ></i>
      </span>
    </p>
    <p class="description" *ngIf="person.birthday">
      <strong>Aniversário:</strong>
      {{ birthdayAsDate | date : 'dd/MM/yyyy' }}
    </p>
    <p class="description" *ngIf="person.cpf">
      <strong>CPF:</strong> {{ person.cpfFormatted }}
    </p>
    <p class="description" *ngIf="person.personId">
      <strong>Jóia:</strong> {{ person.badge || 'Sem joia' }}
    </p>
    <p class="description cupom" *ngIf="person.couponName">
      <span> <strong>Cupom:</strong> {{ person.couponName }} </span>
      <p-button
        severity="danger"
        size="small"
        styleClass="p-button-xs"
        label="Editar cupom"
        icon="pi pi-pencil"
        iconPos="left"
        (onClick)="changeCouponName()"
      ></p-button>
    </p>
    <p *ngIf="influencer?.couponName">
      <span> <strong>Cupom Bflu:</strong> {{ influencer.couponName }} </span>
    </p>
    <p class="description" *ngIf="person.phone">
      <strong>Fone:</strong> {{ person.phone }}
    </p>
    <p class="description" *ngIf="person.dateCreated">
      <strong>Desde:</strong> {{ person.dateCreated | date : 'dd/MM/yyyy' }}
    </p>
    <p
      class="description"
      *ngIf="person.street && person.cityCode && person.stateCode"
    >
      <strong>Endereço:</strong> {{ person.street }} {{ person.streetNumber
      }}<span *ngIf="person.streetExtensions?.length"
        >, {{ person.streetExtensions }}</span
      >
      - {{ person.neighborhood }}, {{ person.city }} - {{ person.state }},
      Brasil, CEP
      {{ person.postalCode }}
    </p>
  </div>
  <!-- <div class="card-footer"></div> -->
</div>
<p-toast></p-toast>
<p-confirmDialog [style]="{ width: '50vw' }"></p-confirmDialog>
<p-dialog
  [visible]="passwordResult !== undefined"
  [style]="{ width: '50vw' }"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  [closable]="false"
  styleClass="password-result"
>
  <ng-template pTemplate="header">
    <span class="p-dialog-title"> Senha alterada </span>
    <p-button
      icon="pi pi-times"
      [rounded]="true"
      styleClass=""
      size="small"
      [text]="true"
      (click)="hideMessage()"
    ></p-button>
  </ng-template>
  <ng-template pTemplate="content">
    <p>
      Nova senha gerada: <strong>{{ passwordResult?.password }}</strong>
    </p>
    <p>{{ passwordResult?.message }}</p>
  </ng-template>
</p-dialog>

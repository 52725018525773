<app-table
  [lazy]="true"
  [cols]="columns"
  tableTitle="Cartões Diferentes"
  [addButton]="false"
  emptyMessage="Sem mais cartões diferentes no momento."
  [exportPdf]="false"
  [exportExcel]="false"
  pageFunctionName="getCardDifferenceTable"
  [service]="itOpsService"
  modelName="Registros"
  sortField="subscriberId"
  [sortOrder]="-1"
  selectionActionLabel="Corrigir"
  selectionActionTooltip="Ações para corrigir os cartões selecionados"
  (emitSelection)="openActionModal($event)"
  *ngIf="ready"
></app-table>
<p-toast></p-toast>

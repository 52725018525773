import { Component, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import {
  IncorrectPendingBilling,
  ItOpsControllerService,
  SubscriberProblemGroupDetail
} from 'src/app/admin-api';
import { TableColumn, TableComponent } from 'src/app/components/table';
import { IncorrectPendingBillingModalComponent } from './incorrect-pending-billing-modal/incorrect-pending-billing-modal.component';

@Component({
  selector: 'app-incorrect-pending-billing',
  templateUrl: './incorrect-pending-billing.component.html',
  styleUrls: ['./incorrect-pending-billing.component.scss'],
  providers: [MessageService, DialogService]
})
export class IncorrectPendingBillingComponent {
  @ViewChild(TableComponent) table: TableComponent;

  columns: Array<TableColumn> = [
    new TableColumn('', '', false, 'checkbox'),
    new TableColumn(
      'SubscriberId',
      'subscriberId',
      true,
      'number',
      '/users/subscribers/',
      'subscriberId'
    ),
    new TableColumn('Edição', 'editionId', true, 'number'),
    new TableColumn('Correct Pending', 'correctPending', true, 'number')
  ];
  ready = true;

  constructor(
    public itOpsService: ItOpsControllerService,
    private dialog: DialogService,
    private messageService: MessageService
  ) {}

  openActionModal(billings: Array<IncorrectPendingBilling>): void {
    this.dialog
      .open(IncorrectPendingBillingModalComponent, {
        height: '65vh',
        header: 'Billings Pendentes Incorretos',
        data: {
          billings
        }
      })
      .onClose.subscribe(async (data: Array<SubscriberProblemGroupDetail>) => {
        if (data) {
          this.ready = false;
          setTimeout(() => {
            this.messageService.add({
              severity: 'success',
              summary: 'Sucesso',
              detail: billings.length + ' marcado(s) como resolvido(s)'
            });
            this.ready = true;
          }, 1000);
        }
      });
  }
}

import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { lastValueFrom, map } from 'rxjs';
import {
  Edition,
  EditionControllerService,
  ItOpsControllerService,
  SubscriberProblemGroupDetail,
  SubscriberProblemGroupResolutionsByDate,
  SubscriberProblemGroupResolutionsByDateAndUsername
} from 'src/app/admin-api';
import { EditionId } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-it-ops',
  templateUrl: './it-ops.component.html',
  styleUrls: ['./it-ops.component.scss'],
  providers: [DatePipe]
})
export class ItOpsComponent implements OnInit {
  kpis: Array<SubscriberProblemGroupDetail> | undefined;
  data: any;
  options: any;
  resolutions: Array<SubscriberProblemGroupResolutionsByDate> | undefined;
  resolutionsByUsename:
    | Array<SubscriberProblemGroupResolutionsByDateAndUsername>
    | undefined;
  totals: Array<{ username: string; total: number }>;
  editions: Array<Edition> | undefined;
  edition: Edition;

  constructor(
    private itOpsService: ItOpsControllerService,
    private title: Title,
    private datePipe: DatePipe,
    private editionService: EditionControllerService
  ) {
    this.title.setTitle('Dashboard IT Ops');
  }

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader();
    await Promise.all([
      this.findKpis(),
      this.findResolutions(),
      this.findResolutionsByUsername(),
      this.findEditions()
    ]);
    this.mountChart();
    LoaderService.showLoader(false);
  }

  mountChart(): void {
    this.totals = this.userResolutions;
    this.data = {
      labels: this.xAxis,
      datasets: this.resolutions
        .reduce(
          (groups: Array<SubscriberProblemGroupResolutionsByDate>, res) => {
            if (
              groups.every(
                (g) =>
                  g.subscriberProblemGroupId !== res.subscriberProblemGroupId
              )
            ) {
              groups.push(res);
            }
            return groups;
          },
          []
        )
        .map((gr) => ({
          type: 'bar',
          label: gr.subscriberProbemGroupName,
          backgroundColor: gr.color,
          stack: 'subscriptions',
          data: this.xAxis.map((data) =>
            this.resolutions
              .filter(
                (r) =>
                  this.datePipe.transform(
                    FormUtil.utcDate(r.dateCreated),
                    'dd/MM/yyyy'
                  ) === data &&
                  r.subscriberProblemGroupId === gr.subscriberProblemGroupId
              )
              .reduce((sum, res) => (sum += res.resolutions), 0)
          )
        }))
        .concat([
          {
            type: 'bar',
            label: 'Total',
            backgroundColor: 'black',
            stack: 'total',
            data: this.xAxis.map((data) =>
              this.resolutions
                .filter(
                  (r) =>
                    this.datePipe.transform(
                      FormUtil.utcDate(r.dateCreated),
                      'dd/MM/yyyy'
                    ) === data
                )
                .reduce((sum, res) => (sum += res.resolutions), 0)
            )
          }
        ])
    };

    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue(
      '--text-color-secondary'
    );
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    this.options = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        tooltips: {
          mode: 'index',
          intersect: false
        },
        legend: {
          labels: {
            color: textColor
          }
        }
      },
      scales: {
        x: {
          stacked: true,
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        },
        y: {
          stacked: true,
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        }
      }
    };
  }

  timeDiffLabel(dateStart: string): string {
    return FormUtil.timeDiffLabel(dateStart, this.datePipe);
  }

  timeLabel(dateStart: string): string | null {
    return this.datePipe.transform(
      FormUtil.utcDate(dateStart),
      'dd/MM/yyyy HH:mm:ss'
    );
  }

  async refresGroup(groupId: number): Promise<void> {
    try {
      LoaderService.showLoader();
      const kpis = await lastValueFrom(
        this.itOpsService.executeProblemKpi(groupId).pipe(
          map((data) =>
            data.result.map((g) => {
              g.viewName =
                g.viewName[0].toLowerCase() + g.viewName.substring(1);
              return g;
            })
          )
        )
      );
      delete this.kpis;
      this.kpis = [...kpis];
      LoaderService.showLoader(false);
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findResolutions(): Promise<void> {
    try {
      const resolutions = await lastValueFrom(
        this.itOpsService
          .getSubscriberResolutionsByMonth(
            this.edition?.editionId % 1000000 || null
          )
          .pipe(map((data) => data.result))
      );
      resolutions.sort(
        (r1, r2) =>
          new Date(r1.dateCreated as any).getTime() -
          new Date(r2.dateCreated as any).getTime()
      );
      this.resolutions = [...resolutions];
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findResolutionsByUsername(): Promise<void> {
    try {
      this.resolutionsByUsename = await lastValueFrom(
        this.itOpsService
          .getSubscriberResolutionsByMonthAndUsername(
            this.edition?.editionId % 1000000 || null
          )
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findKpis(): Promise<void> {
    try {
      this.kpis = await lastValueFrom(
        this.itOpsService.getItOpsDashboard().pipe(
          map((data) =>
            data.result.map((g) => {
              g.viewName =
                g.viewName[0].toLowerCase() + g.viewName.substring(1);
              return g;
            })
          )
        )
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findEditions(): Promise<void> {
    try {
      this.editions = await lastValueFrom(
        this.editionService
          .findEditionsBySubscriptionId(1)
          .pipe(map((data) => data.result))
      );
      this.edition = this.editions.find(
        (e) => EditionId.currentEdition(1) === e.editionId
      );
    } catch (error: any) {
      this.editions = [];
    }
  }

  editionDate(editionId: number): string {
    return FormUtil.editionDate(editionId);
  }

  editionDateName(editionId: number): string {
    return (
      this.datePipe.transform(
        new Date(
          `${((editionId % 1000000) / 100).toFixed(0)}-${editionId % 100}-02`
        ),
        'MMMM/yyyy'
      ) || this.editionDate(editionId)
    );
  }

  async changeEdition(): Promise<void> {
    LoaderService.showLoader();
    delete this.resolutions;
    delete this.resolutionsByUsename;
    delete this.data;
    await Promise.all([
      this.findResolutions(),
      this.findResolutionsByUsername()
    ]);
    this.mountChart();
    LoaderService.showLoader(false);
  }

  get xAxis(): Array<string> {
    return this.resolutions.reduce((days: Array<string>, res) => {
      if (
        !days.includes(
          this.datePipe.transform(
            FormUtil.utcDate(res.dateCreated),
            'dd/MM/yyyy'
          )
        )
      ) {
        days.push(
          this.datePipe.transform(
            FormUtil.utcDate(res.dateCreated),
            'dd/MM/yyyy'
          )
        );
      }
      return days;
    }, []);
  }

  get userResolutions(): Array<{ username: string; total: number }> {
    return (
      this.resolutionsByUsename?.reduce(
        (users: Array<{ username: string; total: number }>, res) => {
          if (users.every((u) => u.username !== res.username)) {
            users.push({
              username: res.username,
              total: res.resolutions
            });
          } else {
            users.find((u) => u.username === res.username).total +=
              res.resolutions;
          }
          return users;
        },
        []
      ) || []
    );
  }
}

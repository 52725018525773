<div class="subscription-type-groups">
  <div class="subscription-type-groups__left">
    <div class="card">
      <div class="card-user">
        <div class="card-body">
          <h3>Informações do plano</h3>
          <br />
          <p><strong>Nome:</strong> {{ subscriptionType?.name | titlecase }}</p>
          <p>
            <strong>Valor assinatura:</strong>
            {{ subscriptionType?.price | currency : 'BRL' }}
          </p>
          <p>
            <strong>Valor mensalidade:</strong>
            {{
              subscriptionType?.price / subscriptionType?.installments
                | currency : 'BRL'
            }}
          </p>
          <p><strong>Edições:</strong> {{ subscriptionType?.editionCount }}</p>
          <p>
            <strong>Parecelas:</strong> {{ subscriptionType?.installments }}
          </p>
          <p *ngIf="scheduled">
            <strong>Troca agendada:</strong>
            {{ scheduled?.newPrice | currency : 'BRL' }} - {{ scheduledDate }}
          </p>
          <p *ngIf="lastScheduled">
            <strong>Última alteração:</strong>
            {{ lastScheduled?.newPrice | currency : 'BRL' }} -
            {{ lastScheduledDate }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="subscription-type-groups__right card">
    <p-tabView>
      <p-tabPanel header="Preço">
        <app-table
          *ngIf="prices && cols"
          [lazy]="false"
          [elements]="prices"
          [cols]="cols"
          modelName="Alterações de preço"
          [defaultRows]="25"
          [addButton]="true"
          addLabel="Agendar troca"
          addButtonIcon="pi pi-calendar-clock"
          [fixedFilters]="fixedFilters"
          [addButtonAction]="true"
          (addButtonClick)="openNewPriceModal()"
          [actionButtons]="actionButtons"
          (actionButtonClick)="callAction($event)"
        ></app-table>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
<p-confirmPopup></p-confirmPopup>
<p-toast></p-toast>

import { TitleCasePipe } from '@angular/common';
import {
  Component,
  inject,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Banner, Category, Home, Product, Shops } from '@infrab4a/connect';
import { GridStackOptions, GridStackWidget } from 'gridstack';
import { MessageService } from 'primeng/api';
import {
  AutoCompleteCompleteEvent,
  AutoCompleteSelectEvent
} from 'primeng/autocomplete';
import { lastValueFrom } from 'rxjs';
import { FileControllerService } from 'src/app/admin-api';
import { GridstackComponent } from 'src/app/components/gridstack/gridstack.component';
import { HomeService } from 'src/app/connect-api/api/shop-settings/home.service';
import { ShopCategoryService } from 'src/app/connect-api/api/shop/shop-category.service';
import { ShopProductService } from 'src/app/connect-api/api/shop/shop-product.service';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { DmsBannerFormComponent } from './dms-banner-form/dms-banner-form.component';

@Component({
  selector: 'app-dms-section-form',
  templateUrl: './dms-section-form.component.html',
  styleUrl: './dms-section-form.component.scss',
  encapsulation: ViewEncapsulation.None,
  providers: [TitleCasePipe]
})
export class DmsSectionFormComponent implements OnInit {
  @ViewChild(GridstackComponent) gridComp?: GridstackComponent;
  @ViewChildren(DmsBannerFormComponent)
  bannerForms?: QueryList<DmsBannerFormComponent>;

  @Input() shop: 'glamshop' | 'mens_market';
  @Input() id: string;

  private homeService = inject(HomeService);
  private categoryService = inject(ShopCategoryService);
  private productService = inject(ShopProductService);
  private messageService = inject(MessageService);
  private router = inject(Router);
  private fileService = inject(FileControllerService);
  private title = inject(Title);
  private titeCasePipe = inject(TitleCasePipe);

  home: Home;
  section: Banner[] | Banner | string[];
  bannerForm = this.bannerGroup();
  bannersForm = new FormArray(
    [this.bannerGroup()],
    [Validators.required, Validators.minLength(1)]
  );
  collectionsForm = new FormArray(
    [new FormControl<Partial<Category>>(null)],
    [Validators.required, Validators.minLength(1)]
  );
  form:
    | typeof this.bannerForm
    | typeof this.bannersForm
    | typeof this.collectionsForm;
  gridOptions: GridStackOptions = {
    margin: 5,
    minRow: 1,
    cellHeight: '200px',
    removable: false,
    column: 1
  };
  responsiveOptions = [
    {
      breakpoint: '1199px',
      numVisible: 1,
      numScroll: 1
    },
    {
      breakpoint: '991px',
      numVisible: 2,
      numScroll: 1
    },
    {
      breakpoint: '767px',
      numVisible: 1,
      numScroll: 1
    }
  ];
  categories: Partial<Category>[];
  availableCategories: Partial<Category>[];
  products: { [id: string]: Product[] } = {};
  ready = false;
  deletedBanners: Banner[] = [];

  async ngOnInit(): Promise<void> {
    try {
      LoaderService.showLoader();
      if (!this.shop || !this.id)
        throw { message: 'Informe a Loja (shop) e o id da seção (id)' };
      if (!this.sectionTyped)
        throw {
          message: `Id inválido. Informe uma das seções (${HomeService.EDITABLE_SECTIONS.map(
            (s) => s.id
          ).toString()})`
        };
      this.title.setTitle(this.pageName);
      this.home = await this.homeService.get(this.shop);
      const section = this.home[this.id];
      // Hero Carousel
      if (section.id && section.id === 'mens_market') {
        let i = 0;
        this.section = [];
        while (section[i]) {
          this.section.push(section[i]);
          i++;
        }
      } else {
        this.section = section;
      }
      if (this.sectionTyped.type === 'Banner') {
        this.form = this.bannerForm;
        this.form.patchValue(this.section as any);
      } else if (this.sectionTyped.type === 'Banner[]') {
        this.bannersForm = new FormArray(
          (this.section as Banner[]).map((b) => this.bannerGroup(b))
        );
        this.form = this.bannersForm;
      } else {
        await this.findCollections();
        await this.findProducts();
        this.collectionsForm = new FormArray(
          (this.section as string[]).map(
            (id) =>
              new FormControl(
                this.collection(id) || { id },
                Validators.required
              )
          )
        );
        this.form = this.collectionsForm;
      }
      this.ready = true;
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async findCollections(): Promise<void> {
    if (this.sectionTyped.type === 'string[]') {
      this.categories = await Promise.all(
        (this.section as string[]).map((id) =>
          this.categoryService.getCategoryById(id)
        )
      );
    }
  }

  async findProducts(): Promise<void> {
    for (const cat of this.categories) {
      if (cat.mostRelevant) {
        this.products[cat.id] = await Promise.all(
          cat.mostRelevant[this.shop]
            .slice(0, 4)
            .map((p) => this.productService.getProductById(p))
        );
      } else if (cat.products?.length) {
        this.products[cat.id] = await Promise.all(
          cat.products
            .slice(0, 4)
            .map((p) => this.productService.getProductById(p))
        );
      } else {
        this.products[cat.id] = [];
      }
    }
  }

  async submit(): Promise<void> {
    if (this.form.valid) {
      try {
        LoaderService.showLoader();
        const home = { ...this.home };
        home[this.id] = this.form.value;
        if (this.gridComp) {
          const items = this.gridComp.grid.save() as GridStackWidget[];
          const newOrder = [];
          items.forEach((item) => {
            if (home[this.id][item.id]) {
              newOrder.push(home[this.id][item.id]);
            }
          });
          home[this.id] = [...newOrder];
        }
        this.persistCollectionProducts(home as Home);
        if (this.id === 'heroCarousel') home.heroCarousel['id'] = this.shop;
        await this.homeService.update(home);
        if (this.bannerForms?.length)
          await Promise.all(this.bannerForms.map((b) => b.afterSubmit()));
        if (this.deletedBanners?.length)
          Promise.all(
            this.deletedBanners
              .reduce((images: string[], b) => {
                if (b.image) images.push(b.image);
                if (b.mobileImage) images.push(b.mobileImage);
                return images;
              }, [])
              .map((i) => lastValueFrom(this.fileService.deleteFile(i)))
          )
            .then((results) => console.log('Arquivos deletados', results))
            .catch((err) => console.error('Erro ao remover arquivo', err));
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: `${this.sectionTyped.label} atualizado(a) com sucesso`
        });
        if (this.sectionTyped.id !== 'featuredCampaignBanner')
          this.router.navigate(['/configuracoes/dms/' + this.shop]);
      } catch (error) {
        AppDialogService.showErrorDialog(error);
      } finally {
        LoaderService.showLoader(false);
      }
    }
  }

  persistCollectionProducts(home: Home): void {
    if (this.sectionTyped.type === 'string[]') {
      const homeCategoryGroups = (home[this.id] as Category[]).map(
        (category) => ({ category, products: this.products[category.id] })
      );
      if (this.sectionTyped.id === 'featuredCategories')
        home['data'].data.featuredProducts = homeCategoryGroups;
      else if (this.sectionTyped.id === 'discoverCategories')
        home['data'].data.discoverProducts = homeCategoryGroups;
      else if (this.sectionTyped.id === 'verticalCarousels')
        home['data'].data.verticalProducts = homeCategoryGroups;
      home[this.id] = (home[this.id] as Category[]).map((c) => c.id);
    }
  }

  addBanner(): void {
    (this.form as FormArray).push(this.bannerGroup());
  }

  addCollection(): void {
    (this.form as FormArray).push(
      new FormControl<Partial<Category>>(null, Validators.required)
    );
  }

  deleteBannerOrCollection(index: number): void {
    LoaderService.showLoader();
    const controls = (
      this.form.controls as FormControl<string>[] | FormGroup[]
    ).filter((c, i) => i !== index);
    delete this.form;
    if (
      this.sectionTyped.type === 'Banner[]' &&
      (this.section[index]?.image || this.section[index]?.imageMobile)
    ) {
      this.deletedBanners.push(this.section[index]);
    }
    setTimeout(() => {
      this.form = new FormArray(controls) as FormArray;
      LoaderService.showLoader(false);
    });
  }

  removeWidget(index: number) {
    const widget = this.gridComp?.gridstackItems?.find(
      (i) => Number(i.options.id) === index
    )?.el;
    if (!widget)
      AppDialogService.showErrorDialog({
        message:
          'Não foi possível remover o item. Atualize a página e tente novamente.'
      });
    else {
      this.gridComp?.grid?.removeWidget(widget);
    }
  }

  collection(collectionId: string): Partial<Category> {
    return this.categories?.find((c) => c.id === collectionId);
  }

  collectionName = (collectionId: string): string => {
    return this.collection(collectionId)?.name;
  };

  bannerGroup(value?: Banner) {
    return new FormGroup({
      image: new FormControl<string>(value?.image || null, Validators.required),
      mobileImage: new FormControl<string>(
        value?.mobileImage || null,
        Validators.required
      ),
      alt: new FormControl<string>(value?.alt || null, Validators.required),
      path: new FormControl<string>(value?.path || null, Validators.required)
    });
  }

  async searchCollections(event: AutoCompleteCompleteEvent): Promise<void> {
    try {
      const result = await Promise.all([
        this.categoryService.getCategoriesList({
          filters: [
            {
              condition: 'contains',
              field: 'slug',
              value: event.query,
              fieldType: 'text'
            },
            {
              condition: 'equals',
              field: 'shop',
              value: this.shop.replace('_', ''),
              fieldType: 'text'
            }
          ],
          page: 0,
          pageSize: 10
        }),
        this.categoryService.getCategoriesList({
          filters: [
            {
              condition: 'contains',
              field: 'name',
              value: event.query,
              fieldType: 'text'
            },
            {
              condition: 'equals',
              field: 'shop',
              value: this.shop.replace('_', ''),
              fieldType: 'boolean'
            }
          ],
          page: 0,
          pageSize: 10
        })
      ]);
      const categories = [
        ...(result.reduce(
          (cats, r) => cats.concat(r.content),
          []
        ) as Partial<Category>[])
      ];
      this.availableCategories = [
        ...new Map(categories.map((b) => [b.id, b])).values()
      ];
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async getProducts(categoryId: string): Promise<void> {
    const products = await this.categoryService.getCategoryHomeProducts(
      categoryId,
      this.shop == 'glamshop' ? Shops.GLAMSHOP : Shops.MENSMARKET
    );

    if (!products.length) {
      throw { message: 'Produtos não encontrados na categoria' };
    }

    this.products[categoryId] = products;
  }

  async collectionChanged(
    $event: AutoCompleteSelectEvent,
    index: number
  ): Promise<void> {
    LoaderService.showLoader();
    if ($event.value) {
      try {
        await this.getProducts($event.value.id);
      } catch (error) {
        (this.form as FormArray).at(index).reset();
        delete this.products[$event.value.id];
        AppDialogService.showErrorDialog(error);
      } finally {
        LoaderService.showLoader(false);
      }
    }
  }

  checkCollection(index: number): void {
    if (!(this.form as FormArray).at(index).value?.id) {
      (this.form as FormArray)
        .at(index)
        .setValue(
          this.collection(this.section[index]) || { id: this.section[index] }
        );
    }
  }

  get pageName(): string {
    return `Customizar Home da ${this.titeCasePipe.transform(
      this.shop.split('_')[0].replace('shop', '')
    )} - ${this.sectionTyped?.label}`;
  }

  get sectionTyped() {
    return HomeService.EDITABLE_SECTIONS.find((s) => s.id === this.id);
  }
}

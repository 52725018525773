<ng-container *ngFor="let parent of parentGroups; let pIndex = index">
  <div
    class="dashboard-card"
    *ngFor="let group of parent.groups; let gIndex = index"
  >
    <div class="groups">
      <div
        *ngFor="let sub of group.groups; let i = index"
        class="dashboard-card"
      >
        <span class="header header--success">
          {{ sub.name }}
        </span>
        <span class="value" *ngIf="sub.subscriberCount >= 0">
          {{ sub.subscriberCount | number : '1.0-0' }}
          <ng-container *ngIf="sub.subscriberCount !== onlyCoupons(sub.id)">
            ({{ onlyCoupons(sub.id) | number : '1.0-0' }} com cupom)
          </ng-container>
          <ng-container *ngIf="sub.subscriberCount && sub.id">
            &nbsp;
            <i
              class="data-link pi pi-download"
              (click)="findSubscribersAndExport(sub)"
              pTooltip="Exportar assinantes"
              tooltipPosition="bottom"
            ></i>
          </ng-container>
        </span>
        <span class="value" *ngIf="sub.conversionRate">
          {{ sub.conversionRate | number : '1.2-2' }} %
        </span>
        <hr />
        <div class="value__group__item__table">
          <table>
            <tr>
              <th>Categoria</th>
              <th>Total</th>
              <th>% Total</th>
              <th *ngIf="sub.conversionRate">Taxa conversão</th>
            </tr>
            <tr
              *ngFor="let usage of sub.groups; let cIndex = index"
              [class.even]="cIndex % 2 === 0"
            >
              <td>
                {{ usage.name }}
                <p-button
                  *ngIf="hasDetails(usage)"
                  (onClick)="showCouponUsageTypeDetails(usage.id, i)"
                  icon="pi pi-chart-line"
                  size="small"
                  [text]="true"
                  [rounded]="true"
                  styleClass="data-link p-button-xs"
                  pTooltip="Ver cupons"
                />
              </td>
              <td class="text-right">
                {{ usage.subscriberCount }}
              </td>
              <td class="text-right">
                {{ usage.totalPercentage | number : '1.2-2' }} %
              </td>
              <td *ngIf="usage.conversionRate >= 0" class="text-right">
                {{ usage.conversionRate | number : '1.2-2' }} %
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-container>

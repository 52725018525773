import { Component, Input } from '@angular/core';
import { ProductVariantStock } from 'src/app/models';
import { TableColumn } from '../table';

@Component({
  selector: 'app-product-stock',
  templateUrl: './product-stock.component.html',
  styleUrls: ['./product-stock.component.scss']
})
export class ProductStockComponent {
  @Input()
  products: Array<ProductVariantStock> | undefined;

  @Input()
  productId: number | undefined;

  cols: Array<TableColumn> = [
    new TableColumn('Id', 'productVariantId'),
    new TableColumn('InternalEAN', 'internalEAN'),
    new TableColumn('Variante', 'productVariantName'),
    new TableColumn(
      'Estoque aquisição',
      'inStockAcquisition',
      false,
      'formattedInteger',
      undefined,
      undefined,
      true,
      'equals',
      'text-right',
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      true
    ),
    new TableColumn(
      'Estoque fidelização',
      'inStockFidelization',
      false,
      'formattedInteger',
      undefined,
      undefined,
      true,
      'equals',
      'text-right',
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      true
    ),
    new TableColumn(
      'Estoque res. markekting',
      'inStockReserveMarketing',
      false,
      'formattedInteger',
      undefined,
      undefined,
      true,
      'equals',
      'text-right',
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      true
    ),
    new TableColumn(
      'Estoque influencer',
      'inStockInfluencerB4A',
      false,
      'formattedInteger',
      undefined,
      undefined,
      true,
      'equals',
      'text-right',
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      true
    )
  ];
  dropdownFilters = {
    type: [
      { label: 'Todas', value: 'all' },
      { label: 'Troca', value: 'Trade' },
      { label: 'Brinde', value: 'Gift' },
      { label: 'Reenvio', value: 'Resend' },
      { label: 'Incentivo para influencer', value: 'IncentiveInfluencer' }
    ]
  };
  columnsDiplayed: Array<TableColumn>;

  constructor() {
    this.columnsDiplayed = [...this.cols];
  }

  changeColumns($event: Array<TableColumn>): void {
    delete this.columnsDiplayed;
    setTimeout(() => {
      this.columnsDiplayed = $event;
    }, 1000);
  }

  get totalAcquisiton(): number {
    return (
      this.products?.reduce((sum, s) => (sum += s.inStockAcquisition), 0) || 0
    );
  }

  get totalFidelization(): number {
    return (
      this.products?.reduce((sum, s) => (sum += s.inStockFidelization), 0) || 0
    );
  }

  get totalReserveMarketing(): number {
    return (
      this.products?.reduce(
        (sum, s) => (sum += s.inStockReserveMarketing),
        0
      ) || 0
    );
  }

  get totalInfluencer(): number {
    return (
      this.products?.reduce((sum, s) => (sum += s.inStockInfluencerB4A), 0) || 0
    );
  }
}

/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SubscriberControlRegretDetail {
  subscriberId?: number;
  editionId?: number;
  subscriberGroupId?: number;
  boxId?: string;
  name?: string;
  phone?: string;
  cpf?: number;
  email?: string;
  subscriptionName?: string;
  subscriptionTypeName?: string;
  badge?: string;
  subscriberStatus?: string;
  theme?: string;
  subscriberEditionName?: string;
  couponId?: number;
  couponName?: string;
  amount?: number;
  compositionId?: number;
  compositionName?: string;
  personType?: string;
  dateRenewed?: Date;
  subscriberEditionStatusName?: string;
  statusMillennium?: string;
  fee?: number;
  refundAmount?: number;
  chargeAmount?: number;
  cancellationReason?: string;
  subscriberCancellationReasonName?: string;
}

import { Component, inject, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  Category,
  NonFunctionPropertyNames,
  RepositoryFindFielters,
  RepositoryFindResult,
  Shops,
  ShopSettingsRepository,
  Wishlist,
  WishlistRepository
} from '@infrab4a/connect';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import { PersonControllerService } from 'src/app/admin-api';
import { UserShopsCollection } from 'src/app/models/user-shops-collection-model';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-collections-lojinhas-edit',
  templateUrl: './collections-lojinhas-edit.component.html',
  styleUrl: './collections-lojinhas-edit.component.scss',
  providers: [DialogService]
})
export class CollectionsLojinhasEditComponent implements OnInit {
  collection: UserShopsCollection;
  pageDocument;
  index: string;
  availableShops: Wishlist[];
  private messageService = inject(MessageService);
  disableCollection: boolean;

  constructor(
    @Inject('ShopSettingsRepository')
    private shopSettingsRepository: ShopSettingsRepository,
    @Inject('WishlistRepository')
    private wishlistRepository: WishlistRepository,
    private dialog: DialogService,
    private route: ActivatedRoute,
    private personService: PersonControllerService
  ) {}

  async ngOnInit(): Promise<void> {
    this.index = this.route.snapshot.paramMap.get('index');
    await this.getDocument();
    await this.findShops();
  }

  async getDocument(): Promise<void> {
    try {
      LoaderService.showLoader();
      this.pageDocument = await this.shopSettingsRepository.get({
        id: 'COLLECTIONS_LOJINHAS'
      });
      this.collection = this.pageDocument.sections[this.index];
      this.disableCollection = this.pageDocument.sections[this.index]?.disabled;
    } catch (e) {
      AppDialogService.showErrorDialog(e);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async save(): Promise<void> {
    try {
      LoaderService.showLoader();
      this.pageDocument.sections[this.index] = this.collection;
      this.pageDocument.sections[this.index].disabled = this.disableCollection;
      await this.shopSettingsRepository.update({
        ...this.pageDocument
      });
      this.messageService.add({
        severity: 'success',
        detail: 'Collection de lojinhas salva com sucesso.'
      });
    } catch (e) {
      AppDialogService.showErrorDialog(e);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async findShops(): Promise<void> {
    try {
      LoaderService.showLoader();
      const results: RepositoryFindResult<Wishlist> =
        await this.wishlistRepository.find({
          fields: [
            'id',
            'image',
            'personName',
            'personId',
            'slug',
            'name'
          ] as NonFunctionPropertyNames<Wishlist>[] as NonFunctionPropertyNames<Category>[],
          filters: {
            personType: 'bflu',
            personIsSubscriber: true,
            shop: Shops.GLAMSHOP
          } as RepositoryFindFielters<Wishlist>,
          orderBy: {
            updatedAt: 'desc'
          }
        });
      const persons = await lastValueFrom(
        this.personService
          .listPersonTable({
            page: 0,
            pageSize: 10000,
            filters: [
              {
                condition: 'in',
                field: 'personId',
                fieldType: 'number',
                value: results.data.map((p) => Number(p.personId))
              }
            ],
            sortBy: 'personId',
            sortDirection: 'asc'
          })
          .pipe(map((data) => data.result.content))
      );
      results.data.forEach(
        (p) =>
          (p.personPhoto = persons.find(
            (pp) => pp.personId.toString() === p.personId
          ).urlImageProfile)
      );
      this.availableShops = results.data;
    } catch (e) {
      AppDialogService.showErrorDialog(e);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  get collectionName(): string {
    return this.collection.collectionName;
  }
}

<h2>Campanhas de Compre e ganhe</h2>
<app-table
  [lazy]="false"
  [elements]="campaigns"
  [cols]="columns"
  modelName="campanhas"
  [dropdownFilters]="dropdownFilters"
  *ngIf="campaigns"
  (booleanAction)="updateActive($event)"
  tableTitle=" "
  addLabel="Nova"
/>
<p-toast />

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  ApiResponseModel,
  CidadeDTO,
  Estado,
  TrackingList,
  TrackingModel,
  ZipcodeResponse
} from '../models';

@Injectable({
  providedIn: 'root'
})
export class OpenService {
  constructor(private http: HttpClient) {}

  findLocationByZipcode(zipcode: string): Promise<ZipcodeResponse> {
    return lastValueFrom(
      this.http
        .get<ApiResponseModel<ZipcodeResponse>>(
          environment.subscriptionApiUrl + 'open/location/cep/' + zipcode
        )
        .pipe(map((data) => data.result))
    );
  }

  listStates(): Promise<Array<Estado>> {
    return lastValueFrom(
      this.http
        .get<ApiResponseModel<Array<Estado>>>(
          environment.subscriptionApiUrl + 'open/location/states'
        )
        .pipe(map((data) => data.result))
    );
  }

  listCities(codigoIBGE: number): Promise<Array<CidadeDTO>> {
    return lastValueFrom(
      this.http
        .get<ApiResponseModel<ApiResponseModel<Array<CidadeDTO>>>>(
          environment.subscriptionApiUrl +
            'open/location/cities?stateId=' +
            codigoIBGE
        )
        .pipe(
          map(
            (data) => (data.result as ApiResponseModel<Array<CidadeDTO>>).result
          )
        )
    );
  }

  getShippingTracking(invoiceNumber: number): Promise<TrackingModel[]> {
    return lastValueFrom(
      this.http
        .get<ApiResponseModel<TrackingList>>(
          environment.subscriptionApiUrl +
            'open/tsimples/tracking/' +
            invoiceNumber
        )
        .pipe(
          map((data) =>
            data.result
              ? (JSON.parse(data.result) as TrackingList).eventos?.map((e) => ({
                  ...e,
                  data_do_evento: new Date(
                    `${e.data_do_evento} ${e.hora_do_evento}`
                  ) as any
                }))
              : []
          )
        )
    );
  }
}

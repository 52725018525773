<app-table
  [cols]="cols"
  modelName="variantes"
  sortField="productVariantId"
  [sortOrder]="1"
  [findPage]="findPage"
  [service]="productService"
  tableTitle="Variantes do sistema"
  [addButton]="false"
  [changeColumns]="true"
  [dropdownFilters]="dropdownFilters()"
  (changedFilter)="filterChanged($event)"
  [multiSelectFilter]="true"
  *ngIf="categories() && dropdownFilters()"
>
</app-table>

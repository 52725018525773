/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { EmailTemplate } from './emailTemplate';
import { PageableObject } from './pageableObject';
import { SortObject } from './sortObject';

export interface PageEmailTemplate {
    totalElements?: number;
    totalPages?: number;
    size?: number;
    content?: Array<EmailTemplate>;
    number?: number;
    sort?: Array<SortObject>;
    first?: boolean;
    last?: boolean;
    pageable?: PageableObject;
    numberOfElements?: number;
    empty?: boolean;
}

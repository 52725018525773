/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Attribute } from './attribute';

export interface AttributeGroup {
  attributeGroupId?: number;
  attributeGroupName?: string;
  dateCreated?: Date;
  dateUpdated?: Date;
  order?: number;
  attributeGroupQuestion?: string;
  attributeGroupReferenceId?: number;
  attributes?: Array<Attribute>;
  groups?: Array<AttributeGroup>;
  active?: boolean;
}

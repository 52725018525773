import { Component, Input } from '@angular/core';
import { CompositionProductVariantDTO } from 'src/app/admin-api';
import { getEquityLabel } from 'src/app/models';

@Component({
  selector: 'app-composition-product-detail',
  templateUrl: './composition-product-detail.component.html',
  styleUrls: ['./composition-product-detail.component.scss']
})
export class CompositionProductDetailComponent {
  @Input()
  product: CompositionProductVariantDTO | undefined;

  @Input()
  index: number | undefined;

  equityLabel(equity: number): string {
    return equity + '.' + getEquityLabel(equity);
  }
}

export interface Address {
  addressId?: number;
  addressType?: Address.AddressTypeEnum;
  city?: string;
  cityCode?: number;
  country?: string;
  countryCode?: number;
  dateCreated?: Date;
  dateUpdated?: Date;
  name?: string;
  neighborhood?: string;
  personId?: number;
  phone?: string;
  postOfficeBox?: string;
  postalCode?: string;
  recipientName?: string;
  reference?: string;
  shippingPrice?: number;
  state?: string;
  stateCode?: number;
  street?: string;
  streetExtension?: string;
  streetNumber?: string;
  subscriberId?: number;
  subscriptionId?: number;
}
export namespace Address {
  export type AddressTypeEnum = 'SHIPPING' | 'BILLING';
  export const AddressTypeEnum = {
    SHIPPING: 'SHIPPING' as AddressTypeEnum,
    BILLING: 'BILLING' as AddressTypeEnum
  };
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ResearchRoutingModule } from './research-routing.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { ChipModule } from 'primeng/chip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeSelectModule } from 'primeng/treeselect';
import { AttributeFormModule } from 'src/app/components/attribute-form/attribute-form.module';
import { BeautyProfileAnswersModule } from 'src/app/components/beauty-profile-answers/beauty-profile-answers.module';
import { FeedbackFormModule } from 'src/app/components/feedback-form/feedback-form.module';
import { ProductSelectModalModule } from 'src/app/components/product-select-modal/product-select-modal.module';
import { AppTableModule } from 'src/app/components/table/app-table.module';
import { BatchFormsAdminComponent } from './batch-forms-admin/batch-forms-admin.component';
import { BeautyProfileComponent } from './beauty-profile/beauty-profile.component';
import { CustomQueryFormComponent } from './custom-query/custom-query-form/custom-query-form.component';
import { CustomQueryComponent } from './custom-query/custom-query.component';
import { HtmlTableComponent } from './custom-query/html-table/html-table.component';
import { FeedbackCrossingComponent } from './feedback-crossing/feedback-crossing.component';
import { FeedbackFormDetailComponent } from './feedback-forms/feedback-form-detail/feedback-form-detail.component';
import { FeedbackFormsComponent } from './feedback-forms/feedback-forms.component';
import { FeedbackGroupDetailsComponent } from './feedback-groups/feedback-group-details/feedback-group-details.component';
import { FeedbackGroupsComponent } from './feedback-groups/feedback-groups.component';

@NgModule({
  declarations: [
    CustomQueryComponent,
    CustomQueryFormComponent,
    HtmlTableComponent,
    FeedbackCrossingComponent,
    FeedbackFormsComponent,
    FeedbackFormDetailComponent,
    BeautyProfileComponent,
    FeedbackGroupsComponent,
    FeedbackGroupDetailsComponent,
    BatchFormsAdminComponent
  ],
  imports: [
    CommonModule,
    ResearchRoutingModule,
    AppTableModule,
    DynamicDialogModule,
    ToastModule,
    ReactiveFormsModule,
    DropdownModule,
    ButtonModule,
    InputTextModule,
    InputTextareaModule,
    TooltipModule,
    AutoCompleteModule,
    MultiSelectModule,
    FieldsetModule,
    TreeModule,
    InputSwitchModule,
    FormsModule,
    OverlayPanelModule,
    TabViewModule,
    FeedbackFormModule,
    ConfirmPopupModule,
    RadioButtonModule,
    TreeSelectModule,
    ProductSelectModalModule,
    AttributeFormModule,
    BeautyProfileAnswersModule,
    FieldsetModule,
    ChipModule,
    ConfirmDialogModule,
    NgxPermissionsModule.forChild(),
    DialogModule
  ]
})
export class ResearchModule {}

export enum SubscriberProblemGroupEnum {
  ActiveSubscriptionNew = 1,
  CanceledWithPendingBilling = 2,
  CardOtherSubscription = 3,
  DontReceiveGift = 4,
  FailedPaymentData = 5,
  RecurrenceIsNull = 6,
  IncorrectPendingBilling = 7,
  IncorrectSubscriberStatus = 8,
  DoubleCommission = 9,
  MuchPendingBillings = 10,
  NotAttemptedPayment = 11,
  NotPendingRecurrence = 12,
  PaidBillingsSameMonth = 13,
  PaymentSameMonth = 14,
  PendingWithoutRecurrence = 15,
  WrongRecurrenceDate = 16,
  DuplicatedReward = 17,
  CardDifference = 18,
  IncorrectEndEdition = 19,
  NullAddress = 20,
  TwoSubscriptionsInOneCpf = 21,
  AcquisitionAndReactivationReprocess = 22,
  RenewalReprocess = 23,
  InfolessAddress = 24,
  PaymentWithoutBilling = 25,
  RenewalWrongPrice = 26
}

<h1>Dashboard de Lojinhas</h1>
<div class="wishlist-dashboard">
  <div class="dashboard-card" *ngFor="let card of cards">
    <span class="header header--primary">
      {{ card.description }}
    </span>
    <span class="value">
      {{ card.value }}
    </span>
  </div>
</div>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CloudfunctionsService {
  constructor(private http: HttpClient) { }
  async getPendingOrders(
  ): Promise<any> {

    const data = await this.http
      .post<any>(
        `https://southamerica-east1-${environment.firebase.projectId}.cloudfunctions.net/exportPendingOrder`,
        {
          data: {
          }
        }
      )
      .toPromise();

    return data.result;
  }
}

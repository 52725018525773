<form class="modal-form" (submit)="submit()" [formGroup]="form">
  <span class="p-float-label">
    <input
      type="text"
      pInputText
      formControlName="feedbackGroupName"
      name="feedbackGroupName"
    />
    <label for="feedbackGroupName">Enunciado *</label>
  </span>
  <div class="checkbox-group">
    <span class="p-input-checkbox">
      <p-checkbox
        formControlName="showRadioButton"
        [binary]="true"
        inputId="showRadioButton"
      />
      <label for="showRadioButton">Exibir radio-button</label>
      <small>* Exibir/Remover o radio-button das alternativas (em breve)</small>
    </span>
    <span class="p-input-checkbox">
      <p-checkbox
        formControlName="isShuffled"
        [binary]="true"
        inputId="isShuffled"
      />
      <label for="isShuffled">Ordem aleatória?</label>
      <small>* Exibir as alternativas em ordem aleatória (em breve)</small>
    </span>
  </div>
  <span class="p-input-checkbox">
    <p-checkbox
      formControlName="lastQuestion"
      [binary]="true"
      inputId="lastQuestion"
    />
    <label for="lastQuestion">Última pergunta?</label>
    <small
      >* Ao responder esta pergunta, encerrar o formulário (em breve)</small
    >
  </span>
  <span class="buttons">
    <p-button
      type="button"
      label="Voltar"
      (onClick)="ref.close()"
      severity="danger"
      size="small"
    />
    <p-button
      type="submit"
      label="Salvar"
      severity="primary"
      [disabled]="!form.valid"
      size="small"
    />
  </span>
</form>

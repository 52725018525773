import { Component, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom } from 'rxjs';
import {
  Product,
  ProductControllerService,
  ProductUpdateRequest
} from 'src/app/admin-api';
import { ShopProductService } from 'src/app/connect-api/api/shop/shop-product.service';
import { ShopProduct } from 'src/app/connect-api/models/ShopProduct';
import { DescriptionField } from 'src/app/models';
import { ProductDetailsComponent } from 'src/app/pages/products/product-details/product-details.component';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-product-diff-modal',
  templateUrl: './product-diff-modal.component.html',
  styleUrl: './product-diff-modal.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class ProductDiffModalComponent implements OnInit {
  private config = inject(DynamicDialogConfig);
  private ref = inject(DynamicDialogRef);
  private productService = inject(ProductControllerService);
  private shopProductService = inject(ShopProductService);

  product: Product = this.config.data.product;
  shopProduct: ShopProduct = this.config.data.shopProduct;
  column: DescriptionField = this.config.data.column;
  form = new FormGroup({
    description: new FormControl<'product' | 'shopProduct'>(
      null,
      Validators.required
    ),
    differentials: new FormControl<'product' | 'shopProduct'>(
      null,
      Validators.required
    ),
    whoMustUse: new FormControl<'product' | 'shopProduct'>(
      null,
      Validators.required
    ),
    howToUse: new FormControl<'product' | 'shopProduct'>(
      null,
      Validators.required
    ),
    brand: new FormControl<'product' | 'shopProduct'>(
      null,
      Validators.required
    ),
    ingredients: new FormControl<'product' | 'shopProduct'>(
      null,
      Validators.required
    )
  });
  index = -1;

  ngOnInit(): void {
    this.toSync?.forEach((c) => {
      if (!this.product[c.value])
        this.form.get(c.value).setValue('shopProduct');
      else if (!this.shopProduct.description[c.value])
        this.form.get(c.value).setValue('product');
    });
    Object.keys(this.form.controls)
      .filter((c) => this.toSync.every((s) => s.value !== c))
      .forEach((c) => this.form.get(c).disable());
    if (this.toSync?.length === 1) {
      this.index = 0;
    }
  }

  async submit() {
    try {
      LoaderService.showLoader();
      this.toSync.forEach((c) => {
        if (this.form.value[c.value] === 'product') {
          this.shopProduct.description[c.value] = this.product[c.controlName];
        } else {
          this.product[c.controlName] = this.shopProduct.description[c.value];
        }
      });
      await Promise.all([
        this.shopProductService.updateProduct({
          description: this.shopProduct.description,
          id: this.shopProduct.id
        }),
        lastValueFrom(
          this.productService.updateProduct(
            this.product as ProductUpdateRequest
          )
        )
      ]);
      this.ref.close(true);
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  get toSync() {
    return this.column
      ? [this.column]
      : ProductDetailsComponent.fieldsToSync(this.shopProduct, this.product);
  }
}

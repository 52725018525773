/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ShippingCompanyRangeDTO {
  shippingCompanyName?: string;
  shippingCompanyRangeId?: number;
  zipStart?: number;
  zipEnd?: number;
  minGrams?: number;
  maxGrams?: number;
  shippingPrice?: number;
  state?: string;
  shippingCompanyId?: number;
  daysToDelivery?: number;
}

<form
  class="modal-form"
  [formGroup]="form"
  *ngIf="form"
  (submit)="submit()"
  id="form"
>
  <span class="p-float-label">
    <input
      type="text"
      pInputText
      formControlName="attributeOptionName"
      id="attributeOptionName"
      name="attributeOptionName"
    />
    <label for="attributeOptionName">Texto *</label>
  </span>
  <span class="p-float-label" *ngIf="form.controls['nextQuestionId'].enabled">
    <p-dropdown
      formControlName="nextQuestionId"
      inputId="nextQuestionId"
      name="nextQuestionId"
      [options]="availableNext()"
      [group]="true"
      [filter]="true"
      appendTo="body"
      [autoDisplayFirst]="false"
      [panelStyle]="{ maxWidth: 'calc(600px - 3rem)' }"
    />
    <label for="nextQuestionId">Próxima pergunta *</label>
  </span>
  <div class="checkbox-group">
    <!-- <span class="p-input-checkbox">
      <p-checkbox
        formControlName="singleOption"
        [binary]="true"
        inputId="singleOption"
      />
      <label for="singleOption">Alternativa única?</label>
    </span> -->
    <span class="input-switch">
      <label for="active">Inativo</label>
      <p-inputSwitch formControlName="active" inputId="active" />
      <label for="active">Ativo</label>
    </span>
  </div>
  <span class="image-upload" *ngIf="!group()?.attributeGroupReferenceId">
    <img
      *ngIf="form.value.attributeOptionImage"
      [src]="form.value.attributeOptionImage"
    />
    <div class="file-upload">
      <span class="p-float-label">
        <input
          type="text"
          [disabled]="true"
          pInputText
          formControlName="attributeOptionImageUrl"
        />
        <label for="attributeOptionImageUrl">Imagem</label>
      </span>
      <p-fileUpload
        mode="basic"
        chooseLabel="Selecionar"
        name="file"
        [auto]="true"
        accept="image/*"
        [maxFileSize]="1000000"
        uploadIcon="pi pi-file"
        [customUpload]="true"
        (uploadHandler)="onUpload($event, 'attributeOptionImage', fileUpload)"
        id="attributeOptionImageUrl"
        #fileUpload
        [disabled]="form.controls.attributeOptionImage.disabled"
      />
    </div>
  </span>
  <span class="buttons">
    <p-button
      type="button"
      severity="danger"
      label="Voltar"
      (onClick)="ref.close()" />
    <p-button
      type="submit"
      label="Salvar"
      severity="primary"
      [disabled]="!form.valid"
  /></span>
</form>

export enum PaymentStatus {
  processing = 'Processando',
  waiting_payment = 'Pendente',
  paid = 'Pago',
  refunded = 'Estornado'
}

export function getAllPaymentStatus(): Array<{ label: string; value: string }> {
  const objetos: Array<{ label: string; value: string }> = [];
  for (const [p, propertyValue] of Object.entries(PaymentStatus)) {
    try {
      objetos.push({
        label: propertyValue,
        value: p
      });
    } catch (error: any) {
      objetos.length;
    }
  }
  return objetos;
}

<table>
  <tr>
    <td>Identificador</td>
    <td>{{ payment.paymentGuid }}</td>
  </tr>
  <tr>
    <td>Data da transação</td>
    <td>{{ payment.dateCreated | date : 'dd/MM/yyyy' }}</td>
  </tr>
  <tr>
    <td>Parcelas</td>
    <td>{{ payment.installments }}</td>
  </tr>
  <tr>
    <td>Valor</td>
    <td>{{ payment.amount | currency : 'BRL' }}</td>
  </tr>
  <tr>
    <td>Mensagem</td>
    <td>{{ payment.authorizationMessage }}</td>
  </tr>
  <tr>
    <td>Código de autorização</td>
    <td>{{ payment.authorizationCode }}</td>
  </tr>
  <tr>
    <td>TID</td>
    <td>{{ payment.tid }}</td>
  </tr>
</table>

export interface ShopCategory {
  id: string;
  name: string;
  slug: string;
  image: string;
  brandCategoryBanner: string;
  brandCategoryBannerMobile: string;
  configuration?: {
    isCollection: boolean;
    isBrand: boolean;
    parentCategory?: {
      id: string;
      name: string;
    };
    reference?: string;
  };
  glamMetadataTitle: string;
  glamMetadataDescription: string;
  mensMetadataTitle: string;
  mensMetadataDescription: string;
  description: string;
  shops: {
    mensMarket: boolean;
    glamShop: boolean;
  };
  conditions?: {
    brand?: string;
    tags?: string[];
  };
  filters: {
    id: number;
    description: string;
  }[];
  products: {
    id: string;
    name: string;
    image: string;
    price: {
      price: number;
      subscriberPrice?: number;
    };
    rate: number;
    createdAt: Date;
    shoppingCount?: number;
  }[];
}

export type ShopCategoryProduct = ShopCategory['products'][0];

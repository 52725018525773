import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  Edition,
  EditionControllerService,
  KpiControllerService,
  SubscriberControlDetail,
  SubscriberEditionShippingDetail,
  SubscriberEditionShippingDetails
} from 'src/app/admin-api';
import { TableColumn } from 'src/app/components/table';
import { TableModalComponent } from 'src/app/components/table-modal/table-modal.component';
import {
  getAllSubscriptions,
  ShippingServiceCounter,
  SubscriberBillingStatusEnum,
  SubscriberEditionStatus,
  SubscriberGroupEnum
} from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-delivery-dashboard',
  templateUrl: './delivery-dashboard.component.html',
  styleUrl: './delivery-dashboard.component.scss'
})
export class DeliveryDashboardComponent implements OnInit {
  form = new FormGroup({
    subscriptionId: new FormControl<number>(0, [
      Validators.required,
      Validators.min(0)
    ]),
    editionId: new FormControl<number>(
      Number(
        `1${new Date().getFullYear()}${(new Date().getMonth() + 1)
          .toString()
          .padStart(2, '0')}`
      ),
      [Validators.required, Validators.min(1)]
    )
  });
  subscriptions: Array<{ label: string; value: number }>;
  editions: Array<Edition> | undefined;
  ready = false;
  shippingDetails: Array<SubscriberEditionShippingDetails> | undefined;
  pendingSubscribers: Array<SubscriberControlDetail> | undefined;
  shippingServices: Array<ShippingServiceCounter> | undefined;
  subscriberShippingDetails: Array<SubscriberEditionShippingDetail> | undefined;

  constructor(
    private kpiService: KpiControllerService,
    private editionService: EditionControllerService,
    private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private router: Router,
    private title: Title,
    private dialog: DialogService
  ) {
    this.subscriptions = getAllSubscriptions().filter((s) => s.value !== 6);
  }

  async ngOnInit(): Promise<void> {
    this.activatedRoute.params.subscribe(async (params) => {
      if (params['editionId']) {
        this.form.patchValue({
          editionId: Number(params['editionId']),
          subscriptionId: Number((params['editionId'] / 1000000).toFixed(0))
        });
        LoaderService.showLoader();
        const fail = document.getElementById('img-fail');
        if (fail?.style?.display === 'none') {
          fail.style.display = 'block';
        }
        await this.findPage();
        await this.findEditions();
        this.title.setTitle('Dashboard entregas - ' + this.edition?.theme);
        LoaderService.showLoader(false);
      } else {
        this.navigate();
      }
    });
  }

  async findEditions(): Promise<void> {
    try {
      this.editions = await lastValueFrom(
        this.editionService
          .findEditionsBySubscriptionId(this.form.value.subscriptionId || 1)
          .pipe(
            map((data) => data.result.filter((e) => e.subscriptionId !== 6))
          )
      );
      if (this.form.value.subscriptionId === 0) {
        this.editions?.forEach(
          (e) => (e.editionId = (e.editionId || 0) % 1000000)
        );
      }
    } catch (error: any) {
      this.editions = [];
    }
  }

  async changeSubscription(): Promise<void> {
    LoaderService.showLoader();
    const form = { ...this.form.value };
    this.form.controls['editionId'].reset(
      (form.subscriptionId || 0) * 1000000 + ((form.editionId || 0) % 1000000)
    );
    delete this.shippingDetails;
    delete this.subscriberShippingDetails;
    delete this.shippingServices;
    this.navigate();
    LoaderService.showLoader(false);
  }

  async changeEdition(): Promise<void> {
    LoaderService.showLoader();
    delete this.shippingDetails;
    delete this.subscriberShippingDetails;
    delete this.shippingServices;
    this.navigate();
    LoaderService.showLoader(false);
  }

  editionDate(editionId: number): string {
    return FormUtil.editionDate(editionId);
  }

  editionDateName(editionId: number): string {
    return (
      this.datePipe.transform(
        new Date(
          `${((editionId % 1000000) / 100).toFixed(0)}-${editionId % 100}-02`
        ),
        'MMMM/yyyy'
      ) || this.editionDate(editionId)
    );
  }

  navigate(): void {
    this.router.navigate(['/dashboard/delivery/' + this.form.value.editionId]);
  }

  async findPending(): Promise<void> {
    try {
      this.pendingSubscribers = await lastValueFrom(
        this.kpiService
          .findSubscriberByEditionIdAndSubscriberGroupId(
            this.form.value.editionId,
            SubscriberGroupEnum.Pendentes
          )
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      this.pendingSubscribers = [];
      AppDialogService.showErrorDialog(error);
    }
  }

  async findPage(): Promise<void> {
    LoaderService.showLoader();
    await Promise.all([this.findPending(), this.findDeliveryDetails()]);
    this.shippingServices = this.shippingDetails.reduce(
      (list: Array<ShippingServiceCounter>, s) => {
        const exists = list.find(
          (ss) => ss.shippingServiceId === s.shippingServiceId
        );
        if (exists) {
          const existsSubscribers = exists.subscriberEditions.find(
            (se) => se.subscriberEditionStatusId === s.subscriberEditionStatusId
          );
          if (existsSubscribers) {
            existsSubscribers.subscribers += s.subscribers;
          } else if (s.subscriberEditionStatusId) {
            exists.subscriberEditions = (
              exists.subscriberEditions || []
            ).concat([
              {
                subscriberEditionStatusId: s.subscriberEditionStatusId,
                subscriberEditionStatusName: s.subscriberEditionStatusName,
                subscribers: s.subscribers
              }
            ]);
          }
          exists.subscriberEditions.sort(
            (se1, se2) =>
              se1.subscriberEditionStatusId - se2.subscriberEditionStatusId
          );
        } else {
          list.push({
            shippingServiceId: s.shippingServiceId,
            shippingServiceName: s.shippingServiceName,
            subscriberEditions: [
              {
                subscriberEditionStatusId: s.subscriberEditionStatusId,
                subscribers: s.subscribers,
                subscriberEditionStatusName: s.subscriberEditionStatusName
              }
            ]
          });
        }
        return list;
      },
      []
    );
    this.shippingServices.sort((s1, s2) => {
      if (s1.shippingServiceName < s2.shippingServiceName) {
        return -1;
      } else if (s1.shippingServiceName > s2.shippingServiceName) {
        return 1;
      }
      return s1.shippingServiceId - s2.shippingServiceId;
    });
    this.ready = true;
    LoaderService.showLoader(false);
  }

  async findDeliveryDetails(): Promise<void> {
    try {
      this.shippingDetails = await lastValueFrom(
        this.kpiService
          .findDeliveryDashboard(this.form.value.editionId)
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      this.shippingDetails = [];
      AppDialogService.showErrorDialog(error);
    }
  }

  shippingServiceTotal(shippingService: ShippingServiceCounter): number {
    return shippingService.subscriberEditions.reduce(
      (sum, s) => (sum += s.subscribers),
      0
    );
  }

  async findSubscriberShippingDetails(): Promise<void> {
    LoaderService.showLoader(true);
    try {
      this.subscriberShippingDetails = await lastValueFrom(
        this.kpiService
          .findDeliveryDashboardSubscribers(this.form.value.editionId)
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      this.subscriberShippingDetails = [];
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  async showSubscribers(id: number): Promise<void> {
    if (!this.subscriberShippingDetails?.length) {
      await this.findSubscriberShippingDetails();
    }
    this.dialog.open(TableModalComponent, {
      data: {
        cols: [
          new TableColumn(
            'SubscriberId',
            'subscriberId',
            true,
            'number',
            '/users/subscribers/',
            'subscriberId'
          ),
          new TableColumn(
            'Assinante',
            'shippingName',
            true,
            'number',
            '/users/person/',
            'personId'
          ),
          new TableColumn(
            'Status edição',
            'subscriberEditionStatusName',
            true,
            'text'
          ),
          new TableColumn(
            'Status cobrança',
            'subscriberBillingStatusName',
            true,
            'text'
          )
        ],
        elements: this.filterSubscribers(
          id,
          id === 1 ? this.pendingSubscribers : this.subscriberShippingDetails
        ),
        modelName: 'assinantes'
      },
      width: '80%',
      height: '90vh',
      header: 'Assinantes',
      maximizable: true
    });
    return;
  }

  totalSubscribers(
    id: number,
    list: Array<SubscriberEditionShippingDetails>
  ): number {
    return (
      this.filterSubscribers(
        id,
        list
      ) as Array<SubscriberEditionShippingDetails>
    ).reduce((sum, s) => (sum += s.subscribers || 0), 0);
  }

  filterSubscribers(
    id: number,
    list: Array<
      | SubscriberEditionShippingDetails
      | SubscriberEditionShippingDetail
      | SubscriberControlDetail
    >
  ): Array<SubscriberEditionShippingDetails | SubscriberEditionShippingDetail> {
    switch (id) {
      case 1:
        return this.pendingSubscribers;
      case 2:
        return list as Array<
          SubscriberEditionShippingDetails | SubscriberEditionShippingDetail
        >;
      case 3:
        return (
          list as Array<
            SubscriberEditionShippingDetails | SubscriberEditionShippingDetail
          >
        ).filter(
          (s) =>
            s.shippingServiceId < 0 &&
            s.subscriberEditionStatusId ===
              SubscriberEditionStatus.COM_COMPOSICAO
        );
      case 4:
        return (
          list as Array<
            SubscriberEditionShippingDetails | SubscriberEditionShippingDetail
          >
        ).filter(
          (s) =>
            !s.subscriberEditionStatusId &&
            s.shippingServiceId < 0 &&
            s.subscriberBillingStatus === SubscriberBillingStatusEnum.Pago
        );
      case 5:
        return (
          list as Array<
            SubscriberEditionShippingDetails | SubscriberEditionShippingDetail
          >
        ).filter((s) => s.shippingServiceId < 0 && s.subscriberEditionStatusId);
      case 6:
        return (
          list as Array<
            SubscriberEditionShippingDetails | SubscriberEditionShippingDetail
          >
        ).filter((s) => s.subscriberEditionStatusId === 6);
      case 7:
        return (
          list as Array<
            SubscriberEditionShippingDetails | SubscriberEditionShippingDetail
          >
        ).filter(
          (s) =>
            s.shippingServiceId >= 0 &&
            s.subscriberEditionStatusId >= 0 &&
            s.subscriberEditionStatusId < 6
        );
      case 8:
        return (
          list as Array<
            SubscriberEditionShippingDetails | SubscriberEditionShippingDetail
          >
        ).filter(
          (s) =>
            s.subscriberBillingStatus === 1 &&
            (s.subscriberEditionStatusId < 0 || s.subscriberEditionStatusId > 6)
        );
      case 9:
        return (
          list as Array<
            SubscriberEditionShippingDetails | SubscriberEditionShippingDetail
          >
        ).filter(
          (s) => s.subscriberBillingStatus > 1 && s.shippingServiceId >= 0
        );
      default:
        return (
          list as Array<
            SubscriberEditionShippingDetails | SubscriberEditionShippingDetail
          >
        ).filter(
          (s) =>
            s.subscriberBillingStatus === 1 || s.subscriberEditionStatusId > 0
        );
    }
  }

  get edition(): Edition | undefined {
    return this.editions?.find(
      (e) => e.editionId === this.form.value.editionId
    );
  }
}

import { Component, Input, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Order } from '@infrab4a/connect';
import { MessageService } from 'primeng/api';
import { ShopOrderService } from 'src/app/connect-api/api/shop/shop-order.service';
import { CidadeDTO, Estado } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { OpenService } from 'src/app/services/open.service';

@Component({
  selector: 'app-shop-order-address-form',
  templateUrl: './shop-order-address-form.component.html',
  styleUrl: './shop-order-address-form.component.scss',
  providers: [MessageService]
})
export class ShopOrderAddressFormComponent implements OnInit {
  private shopOrdersService = inject(ShopOrderService);
  private messageService = inject(MessageService);
  private openService = inject(OpenService);

  @Input()
  order: Order;

  @Input()
  type: 'billing' | 'shipping' = 'billing';

  @Input()
  states: Array<Estado>;

  @Input()
  cities: Array<CidadeDTO>;

  form = new FormGroup({
    city: new FormControl<string>(
      { value: null, disabled: true },
      Validators.required
    ),
    district: new FormControl<string>(null, Validators.required),
    extension: new FormControl<string>(null, Validators.required),
    mailBoxNumber: new FormControl<string>(null),
    number: new FormControl<string>(null, Validators.required),
    recipient: new FormControl<string>(null, Validators.required),
    state: new FormControl<string>(null, Validators.required),
    street: new FormControl<string>(null, Validators.required),
    zip: new FormControl<string>(null, [
      Validators.required,
      Validators.minLength(9)
    ])
  });

  async ngOnInit(): Promise<void> {
    if (!this.order) {
      console.error(
        ShopOrderAddressFormComponent.name,
        'Please provide param {order}'
      );
      return;
    }
    if (this.type === 'billing') {
      this.form.patchValue(this.order.billingAddress);
    } else {
      this.form.patchValue(this.order.shippingAddress);
    }
    if (!this.cities) await this.findCities();
  }

  async findCities(): Promise<void> {
    if (this.form?.controls['state']?.valid && this.selectedState?.codigoIbge) {
      try {
        this.cities = await this.openService.listCities(
          this.selectedState?.codigoIbge
        );
        if (this.form?.controls?.state?.valid)
          this.form?.controls?.city?.enable();
      } catch (error) {
        delete this.cities;
        AppDialogService.showErrorDialog(error);
      }
    }
  }

  async zipCodeBlur(): Promise<void> {
    if (this.form?.controls?.zip?.valid) {
      await this.findAddressByZipcode();
    } else {
      this.form?.controls?.zip?.reset();
    }
  }

  async findAddressByZipcode(): Promise<void> {
    if (
      this.form?.controls?.zip?.valid &&
      this.form?.controls?.zip?.dirty &&
      this.form.value.zip.replaceAll('_', '').trim().length >= 9
    ) {
      LoaderService.showLoader();
      try {
        const result = await this.openService.findLocationByZipcode(
          this.form.value.zip
        );
        if (result?.cidade) {
          this.form.controls['city'].setValue(result.cidade);
        }
        if (result?.siglaEstado) {
          this.form.controls['state'].setValue(result.siglaEstado);
        }
        if (result?.logradouro) {
          this.form.controls['street'].setValue(result.logradouro);
        }
        if (result?.bairro) {
          this.form.controls['district'].setValue(result.bairro);
        }
        this.form.controls.zip.markAsPristine();
      } catch (error) {
        AppDialogService.showErrorDialog(error);
      }
      LoaderService.showLoader(false);
    }
  }

  async submit(): Promise<void> {
    if (this.form.valid) {
      LoaderService.showLoader();
      try {
        this.order[this.type + 'Address'] =
          await this.shopOrdersService.updateOrderAddress(
            {
              ...this.form.value,
              mailbox: this.form.value.mailBoxNumber?.length > 0
            },
            this.type,
            this.order.id
          );
        this.messageService.add({
          severity: 'success',
          detail: 'Endereço atualizado com sucesso',
          summary: 'Sucesso'
        });
      } catch (error) {
        AppDialogService.showErrorDialog(error);
      }
      LoaderService.showLoader(false);
    }
  }

  get selectedState(): Estado {
    return this.states && this.form?.controls?.state?.valid
      ? this.states.find((s) => s.sigla === this.form.value.state)
      : null;
  }
}

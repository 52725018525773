export enum Topic {
  MESSAGE = 'MESSAGE',
  ARTICLE_LIKE = 'ARTICLE_LIKE',
  ARTICLE_VIEW = 'ARTICLE_VIEW',
  COURSE_LIKE = 'COURSE_LIKE',
  ALLOCATION_DRAFT_CHANGE = 'ALLOCATION_DRAFT_CHANGE',
  ALLOCATION_PERMISSION_CHANGE = 'ALLOCATION_PERMISSION_CHANGE',
  ALLOCATION_SEGMENTATION_CHANGE = 'ALLOCATION_SEGMENTATION_CHANGE',
  ALLOCATION_STOCK_CHANGE = 'ALLOCATION_STOCK_CHANGE',
  ALLOCATION_SYNCRONIZATION_CHANGE = 'ALLOCATION_SYNCRONIZATION_CHANGE',
  SUBSCRIBER_UPDATE = 'SUBSCRIBER_UPDATE',
  REFRESH = 'REFRESH'
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { BadgeFormComponent } from './badge-form.component';
import { BadgeRuleFormComponent } from './badge-rule-form/badge-rule-form.component';

@NgModule({
  declarations: [BadgeFormComponent, BadgeRuleFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextModule,
    DropdownModule,
    InputMaskModule,
    ButtonModule,
    CheckboxModule,
    FileUploadModule,
    ToastModule,
    InputTextareaModule,
    EditorModule,
    InputNumberModule,
    FieldsetModule,
    TooltipModule,
    ConfirmDialogModule,
    AutoCompleteModule,
    FileUploadModule,
    ColorPickerModule
  ],
  exports: [BadgeFormComponent, BadgeRuleFormComponent]
})
export class BadgeFormModule {}

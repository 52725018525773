<div>
  <div class="dashboard-card">
    <span class="header header--success"> Pedidos pendentes </span>
    <div *ngIf="pendingResponse && pendingResponse.reason.length > 0">
      <table>
        <thead>
          <tr>
            <th *ngFor="let key of pendingResponse.reason[0].keys">
              {{ key | titlecase }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of pendingResponse.reason">
            <td *ngFor="let key of item.keys">
              {{ item[key] }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  InfluencerDetail,
  PasswordResetResponse,
  PersonControllerService,
  PersonDetail,
  SubscriberControllerService
} from 'src/app/admin-api';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';
import { PWAUtil } from 'src/app/utils/pwa.util';
import { ChangeCouponModalComponent } from '../change-coupon-modal/change-coupon-modal.component';

@Component({
  selector: 'app-person-card',
  templateUrl: './person-card.component.html',
  styleUrls: ['./person-card.component.scss'],
  providers: [DialogService, MessageService, ConfirmationService],
  encapsulation: ViewEncapsulation.None
})
export class PersonCardComponent {
  @Input()
  person: PersonDetail | undefined;

  @Input()
  subscriptionId = 1;

  @Output()
  couponChanged = new EventEmitter<string>();

  @Input()
  isMobile?: boolean;

  @Input()
  influencer: InfluencerDetail | undefined;

  @Input()
  showProfileLink = true;

  @Input()
  showResetPassword = true;

  @Input()
  background: string = '';

  passwordResult: PasswordResetResponse | undefined;

  constructor(
    private dialogService: DialogService,
    private cdRef: ChangeDetectorRef,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private personService: PersonControllerService,
    private router: Router,
    private subscriberService: SubscriberControllerService
  ) {}

  changeCouponName(): void {
    this.dialogService
      .open(ChangeCouponModalComponent, {
        closable: true,
        header: `Você quer alterar o cupom ${this.person?.couponName}?`,
        position: 'center',
        closeOnEscape: false,
        data: {
          person: this.person
        },
        width: this.isMobile ? '100%' : '50%'
      })
      .onClose.subscribe((coupon: string) => {
        if (this.person && coupon) {
          this.person.couponName = coupon;
          this.cdRef.detectChanges();
          this.couponChanged.emit(coupon);
          this.messageService.add({
            detail: 'Cupom alterado com sucesso',
            summary: 'Sucesso',
            severity: 'success'
          });
        }
      });
  }

  resetPassword(): void {
    this.confirmationService.confirm({
      message: 'Deseja resetar a senha da usuária?',
      header: 'Atenção',
      icon: 'pi pi-exclamation-triangle danger',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      acceptButtonStyleClass: 'p-button-primary',
      rejectButtonStyleClass: 'p-button-danger',
      accept: async () => {
        LoaderService.showLoader();
        try {
          this.passwordResult = await lastValueFrom(
            this.personService
              .generateNewPassword({
                personId: this.person?.personId as number
              })
              .pipe(map((data) => data.result))
          );
        } catch (error: any) {
          AppDialogService.showErrorDialog(
            error,
            false,
            'Erro ao resetar a senha'
          );
        }
        LoaderService.showLoader(false);
      }
    });
  }

  fraudCancellation(): void {
    this.confirmationService.confirm({
      message:
        'As assinaturas serão canceladas, o CPF adicionado a Blacklist e o usuário será deslogado de todas as plataformas! \nObs Lembre-se de estornar os pagamentos no Gateway',
      header: 'Atenção, deseja apontar este usuário como fraude?',
      icon: 'pi pi-exclamation-triangle danger',
      acceptLabel: 'Concordo é uma Fraude!',
      rejectLabel: 'Voltar',
      acceptButtonStyleClass: 'p-button-danger',
      rejectButtonStyleClass: 'p-button-secondary',
      accept: async () => {
        LoaderService.showLoader();
        try {
          const message = await lastValueFrom(
            this.subscriberService
              .cancelFraudAccount(this.person.personId)
              .pipe(map((data) => data.result))
          );
          this.messageService.add({
            detail: message,
            summary: 'Sucesso',
            severity: 'success'
          });
          LoaderService.showLoader(false);
          location.reload();
        } catch (error: any) {
          AppDialogService.showErrorDialog(
            error,
            false,
            'Erro ao Apontar como fraude'
          );
          LoaderService.showLoader(false);
        }
      }
    });
  }

  openPersonPage(): void {
    const url = '/users/person/' + this.person?.personId;
    // Se estiver no browser
    if (!PWAUtil.isPWA)
      window.open(
        this.router.serializeUrl(this.router.createUrlTree([url])),
        '_blank'
      );
    else this.router.navigate([url]);
  }

  hideMessage(): void {
    delete this.passwordResult;
  }

  get birthdayAsDate(): Date {
    return FormUtil.dateStringAsDate(this.person?.birthday as any);
  }
}

<div>
  <div *ngIf="!article" class="page-title">Novo {{ modelTitleName }}</div>
  <div *ngIf="article" class="page-title">
    <ng-container *ngIf="permission"> Editar </ng-container>
    <ng-container *ngIf="!permission"> Visualizar </ng-container>
    {{ modelTitleName }} - {{ article.articleTitle }}
  </div>
</div>

<p-tabView [activeIndex]="tab" (activeIndexChange)="tabChanged($event)">
  <p-tabPanel>
    <ng-template pTemplate="header"> Informações </ng-template>
    <form [formGroup]="form" (submit)="submit()" class="form-b4a" *ngIf="form">
      <span class="p-float-label">
        <input
          id="articleTitle"
          type="text"
          pInputText
          formControlName="articleTitle"
        />
        <label for="articleTitle">Título do {{ modelName }} *</label>
        <small
          *ngIf="
            articleTitle.invalid && articleTitle.touched && articleTitle.dirty
          "
          class="error"
        >
          Título do {{ modelName }} é obrigatório
        </small>
        <small class="hint" [class.danger]="!form.controls.articleTitle.valid">
          {{
            form.value.articleTitle?.trim().length || 0 | number : '1.0-0'
          }}/800
        </small>
      </span>

      <span class="input-switch">
        <label for="active">Inativo</label>
        <p-inputSwitch formControlName="active" inputId="active" />
        <label for="active">Ativo</label>
      </span>

      <span class="p-float-label" *ngIf="!isShort">
        <input
          id="articleSummary"
          type="text"
          pInputText
          formControlName="articleSummary"
        />
        <label for="articleSummary">Subtítulo do {{ modelName }} *</label>
        <small
          *ngIf="
            articleSummary.invalid &&
            articleSummary.touched &&
            articleSummary.dirty
          "
          class="error"
        >
          Subtítulo do {{ modelName }} é obrigatório
        </small>
        <small
          class="hint"
          [class.danger]="!form.controls.articleSummary.valid"
        >
          {{
            form.value.articleSummary?.trim().length || 0 | number : '1.0-0'
          }}/4000
        </small>
      </span>

      <span class="p-float-label">
        <input
          id="articleAuthor"
          type="text"
          pInputText
          formControlName="articleAuthor"
        />
        <label for="articleAuthor">Autor do {{ modelName }}</label>
      </span>

      <span class="p-float-label">
        <input
          id="articleDescription"
          type="text"
          pInputText
          formControlName="articleDescription"
        />
        <label for="articleDescription">Meta description</label>
        <small
          class="hint"
          [class.danger]="!form.controls.articleDescription.valid"
        >
          {{
            form.value.articleDescription?.trim().length || 0
              | number : '1.0-0'
          }}/4000
        </small>
      </span>

      <span class="p-float-label">
        <input
          id="articleKeywords"
          type="text"
          pInputText
          formControlName="articleKeywords"
        />
        <label for="articleKeywords">Palavras-chave do {{ modelName }}</label>
      </span>

      <span class="p-float-label">
        <p-calendar
          formControlName="publishDate"
          inputId="publishDate"
          dateFormat="dd/mm/yy"
        />
        <label for="publishDate">Data de publicação</label>
        <small
          class="error"
          *ngIf="
            form.controls['publishDate'].dirty &&
            form.controls['publishDate'].errors &&
            form.controls['publishDate'].errors['invalidDate']
          "
        >
          {{ form.controls['publishDate'].errors['invalidDate'] }}
        </small>
      </span>

      <span class="p-float-label">
        <p-calendar
          formControlName="unpublishDate"
          inputId="unpublishDate"
          dateFormat="dd/mm/yy"
        />
        <label for="unpublishDate">Data de despublicação</label>
        <small
          class="error"
          *ngIf="
            form.controls['unpublishDate'].dirty &&
            form.controls['unpublishDate'].errors &&
            form.controls['unpublishDate'].errors['invalidDate']
          "
        >
          {{ form.controls['unpublishDate'].errors['invalidDate'] }}
        </small>
      </span>

      <span class="p-float-label" *ngIf="isArticle">
        <p-inputNumber
          inputId="minutesTotal"
          formControlName="minutesTotal"
          [min]="1"
        />
        <label for="minutesTotal"> Tempo de leitura (minutos) </label>
      </span>

      <span class="p-float-label full-w">
        <input
          id="articleTags"
          type="text"
          pInputText
          formControlName="articleTags"
        />
        <label for="articleTags">Tags do {{ modelName }}</label>
        <small class="hint" [class.danger]="!form.controls.articleTags.valid">
          {{
            form.value.articleTags?.trim().length || 0 | number : '1.0-0'
          }}/4000
        </small>
      </span>

      <span class="image-upload full-w" *ngIf="isShort">
        <div class="file-upload full-w">
          <span class="p-fixed-label">
            <label for="videoURL">URL do Vídeo (Short)</label>
            <p-inputGroup>
              <input
                type="text"
                pInputText
                formControlName="videoURL"
                id="videoURL"
                name="videoURL"
                placeholder="Cole o link ou faça upload do vídeo"
                (input)="refreshVideo()"
              />
              <p-inputGroupAddon (click)="removeVideo()">
                <i
                  class="pi pi-times"
                  pRipple
                  pTooltip="Remover video"
                  tooltipPosition="bottom"
                ></i>
              </p-inputGroupAddon>
            </p-inputGroup>
          </span>
          <p-fileUpload
            mode="basic"
            chooseLabel="Selecionar"
            name="file"
            [auto]="true"
            accept="video/*"
            uploadIcon="pi pi-file"
            [customUpload]="true"
            (uploadHandler)="updateVideo($event, 'videoURL', iconUpload)"
            id="videoURL"
            #iconUpload
            [disabled]="form.controls.videoURL.disabled"
          />
        </div>
        <small class="error" *ngIf="!form.controls.videoURL.valid">
          * URL inválido
        </small>
        <small>
          * Ao incluir um vídeo, o {{ modelName }} será listado como um Short
        </small>
        <video
          controls
          [height]="
            form.controls.videoURL.value && form.controls.videoURL.valid
              ? 300
              : 0
          "
          width="300"
          id="videoElement"
          #videoElement
          crossorigin="anonymous"
        >
          Não foi possível carregar a prévia
        </video>
        <p-button
          *ngIf="form.controls.videoURL.value && form.controls.videoURL.valid"
          label="Atualizar thumbnail"
          (onClick)="getCoverImage(true, videoElement.currentTime)"
        />
      </span>

      <span class="p-input-editor" *ngIf="!isShort">
        <span class="label-option">
          <label for="articleBody">Corpo do {{ modelName }} </label>
          <span class="p-input-checbox">
            <p-checkbox
              formControlName="html"
              [binary]="true"
              inputId="html"
              name="html"
              (onChange)="format()"
            />
            <label for="html">Ver código fonte</label>
          </span>
        </span>
        <p-editor
          [readonly]="permission === false"
          formControlName="articleBody"
          id="articleBody"
          (keyup)="cdRef.detectChanges()"
          (click)="cdRef.detectChanges()"
          [hidden]="form.value.html"
          [modules]="{
            toolbar: { handlers, container: toolbarOptions },
            blotFormatter2: {
              resize: {
                handleClassName: 'dimensioned'
              }
            }
          }"
        >
          <ng-template pTemplate="header" />
        </p-editor>
        <textarea
          name="articleBody"
          id="articleBody"
          formControlName="articleBody"
          *ngIf="form.value.html"
          rows="50"
        >
        </textarea>
        <small class="hint" [class.danger]="!form.controls.articleBody.valid">
          {{
            form.value.articleBody?.trim().length || 0 | number : '1.0-0'
          }}/50.000
        </small>
      </span>

      <ng-container *ngIf="!images || !images.length">
        <span class="file-upload">
          <label for="articleImage">
            <span *ngIf="!isShort">Imagem padrão</span>
            <span *ngIf="isShort">Thumbnail do video</span>
            <img
              [src]="form.value?.articleImage"
              *ngIf="form.value?.articleImage"
              alt="Imagem padrão"
            />
            <img
              src="assets/images/no_img_available.jpg"
              *ngIf="!form.value?.articleImage"
              alt="Imagem padrão"
            />
          </label>
          <p-fileUpload
            mode="basic"
            chooseLabel="Selecionar"
            name="file"
            [auto]="true"
            accept="image/*"
            [maxFileSize]="1000000"
            uploadIcon="pi pi-file"
            [customUpload]="true"
            (uploadHandler)="cropImageBeforeUpload($event, fileUpload)"
            id="articleImage"
            #fileUpload
            [disabled]="!permission"
          />
        </span>
        <small
          *ngIf="
            isShort &&
            form.controls.videoURL.value &&
            form.controls.videoURL.valid
          "
        >
          * Para atualizar, movimente o video até o tempo desejado e aperte
          "Atualizar thumbnail"
        </small>
      </ng-container>

      <small *ngIf="article?.dateUpdated">
        *Última alteração em:
        {{ article.dateUpdated | date : 'dd/MM/yyyy HH:mm:ss' }}
      </small>

      <div class="buttons" *ngIf="permission">
        <p-button label="Salvar" type="submit" [disabled]="!form.valid" />
      </div>
    </form>
    <input
      type="file"
      [accept]="accept"
      hidden
      id="inputFile"
      (change)="handleFile($event)"
    />
  </p-tabPanel>
  <p-tabPanel *ngIf="article">
    <ng-template pTemplate="header">
      <i class="pi pi-thumbs-up"></i>&nbsp; Likes ({{
        likes?.length || 0 | number : '1.0-0'
      }})
    </ng-template>
    <app-table
      [cols]="likeCols"
      [elements]="likes"
      *ngIf="likes"
      [lazy]="false"
      modelName="likes"
    />
  </p-tabPanel>
  <p-tabPanel *ngIf="article">
    <ng-template pTemplate="header">
      <i class="pi pi-eye"></i>&nbsp; Visualizações ({{
        views?.length || 0 | number : '1.0-0'
      }})
    </ng-template>
    <app-table
      [cols]="viewCols"
      [elements]="views"
      *ngIf="views"
      [lazy]="false"
      modelName="visualizações"
    />
  </p-tabPanel>
</p-tabView>

import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  ShopBanner,
  ShopSettings,
  ShopSettingsRepository
} from '@infrab4a/connect';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CustomizationComponent } from 'src/app/components/customization/customization.component';

@Component({
  selector: 'app-panel-second-carousel',
  templateUrl: './panel-second-carousel.component.html',
  styleUrls: ['./panel-second-carousel.component.scss']
})
export class SecondPanelCarouselComponent
  extends CustomizationComponent
  implements OnInit
{
  pageDocument: ShopSettings;
  pageBanners: ShopBanner[];

  constructor(
    override router: Router,
    @Inject('ShopSettingsRepository')
    private shopSettingsRepository: ShopSettingsRepository,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {
    super(router);

    this.documentId = 'GLAM_PANEL_CAROUSEL-2';
    this.newCampaignPage = 'configuracoes/painel/second-carousel/new';
    this.editCampaignPage = 'configuracoes/painel/second-carousel/edit/';
  }

  async ngOnInit() {
    await this.getDocument();
  }

  override async getDocument(): Promise<void> {
    this.pageDocument = await this.shopSettingsRepository.get({
      id: this.documentId
    });
    this.pageBanners = this.pageDocument.sections;
  }

  override async onReorder(): Promise<void> {
    await this.shopSettingsRepository.update({
      id: this.documentId,
      sections: this.pageBanners
    });
  }

  override async deleteSection(campaign: ShopBanner): Promise<void> {
    this.confirmationService.confirm({
      acceptLabel: 'Sim',
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: 'Não',
      rejectButtonStyleClass: 'p-button-primary',
      message: 'Tem certeza que deseja excluir?',
      header: 'Atenção!',
      accept: async () => {
        this.pageBanners = null;
        const index = this.pageDocument.sections?.findIndex(
          (c: any) => c === campaign
        );

        this.pageDocument.sections?.splice(index, 1);

        await this.shopSettingsRepository.update({
          id: this.documentId,
          sections: this.pageDocument.sections
        });

        this.messageService.add({
          severity: 'success',
          summary: 'Campanha excluída',
          detail: 'A campanha agendada foi excluída com sucesso!'
        });

        this.pageBanners = this.pageDocument.sections;
      },
      reject: () => {
        this.pageBanners = this.pageDocument.sections;
      }
    });
  }

  goToNewScreen() {
    this.navigate(this.newCampaignPage);
  }

  goToEditScreen(banner: ShopBanner) {
    const index = this.pageBanners.indexOf(banner);
    this.navigate(`${this.editCampaignPage}${index}`);
  }

  goToMenu() {
    this.navigate('configuracoes');
  }
}

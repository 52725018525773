import { DatePipe } from '@angular/common';
import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
  WritableSignal
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ChartConfiguration, ChartData } from 'chart.js';
import { UIChart } from 'primeng/chart';
import { lastValueFrom, map } from 'rxjs';
import {
  KpiControllerService,
  SubscriptionsPerHour,
  SubscriptionsPerHourRealtime,
  SubscriptionsTotalResponse
} from 'src/app/admin-api';
import {
  getAllSubscriptionsIncludeAll,
  getSubscriptionName,
  Message,
  SubscriberUpdate,
  Topic
} from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { WebsocketService } from 'src/app/services/websocket.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-analytics-dashboard',
  templateUrl: './analytics-dashboard.component.html',
  styleUrl: './analytics-dashboard.component.scss'
})
export class AnalyticsDashboardComponent implements OnInit, OnDestroy {
  @ViewChild('chart') chart: UIChart;

  @Input()
  defaultSubscriptionId: number;

  @Input()
  show: WritableSignal<boolean>;

  @Input()
  subscribersRealtimeTotal: WritableSignal<SubscriptionsTotalResponse> =
    signal(undefined);

  subscriptionId = new FormControl<number>(0, [
    Validators.required,
    Validators.min(0)
  ]);
  subscriptions: Array<{ label: string; value: number }>;
  width: string;
  chartData: ChartData = {
    labels: this.yAxis,
    datasets: [
      {
        data: this.yAxis.map(() => 0),
        label: 'Média Vendas/hora últimos 7d',
        type: 'line',
        backgroundColor: '#00aaff',
        borderColor: '#00aaff',
        tension: 0.4
      },
      {
        data: this.yAxis.map(() => 0),
        label: 'Vendas/hora dia atual',
        type: 'line',
        backgroundColor: '#606a74',
        borderColor: '#606a74',
        tension: 0.4
      }
    ]
  };
  chartConfig: ChartConfiguration = {
    options: {
      animation: {
        easing: 'easeInQuad'
      }
    }
  } as any;
  subscribers: Array<SubscriptionsPerHour> | undefined;
  subscribersRealtime: Array<SubscriptionsPerHourRealtime> | undefined;
  interval: NodeJS.Timeout;
  lastUpdate = new Date();

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.checkScreenSize();
  }

  constructor(
    private kpiService: KpiControllerService,
    private title: Title,
    private datePipe: DatePipe,
    private websocketService: WebsocketService
  ) {
    this.subscriptions = getAllSubscriptionsIncludeAll();
    this.checkScreenSize();
    if (this.defaultSubscriptionId === undefined)
      this.title.setTitle('Dashboard de frequências');
  }

  checkScreenSize() {
    delete this.width;
    setTimeout(() => {
      this.width = window.innerWidth > 768 ? '100%' : '500px';
    });
  }

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader();
    if (this.defaultSubscriptionId !== undefined) {
      this.subscriptionId.setValue(this.defaultSubscriptionId);
    }
    if (!this.subscribersRealtimeTotal) {
      this.connect();
      this.subscribersRealtimeTotal = signal(undefined);
    }
    await this.findPage(true);
    this.startIntervals();
    LoaderService.showLoader(false);
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
      delete this.interval;
    }
    this.websocketService.disconnect();
  }

  startIntervals() {
    let lastUpdate = new Date();
    this.interval = setInterval(async () => {
      const now = new Date();
      await this.findPage(now.getDate() !== lastUpdate.getDate());
      lastUpdate = now;
    }, 1000 * 60 * 30);
  }

  async changeSubscription(): Promise<void> {
    LoaderService.showLoader();
    delete this.subscribers;
    await this.findPage(true);
    LoaderService.showLoader(false);
  }

  async findPage(findSubscriptions = false): Promise<void> {
    await Promise.all(
      [this.findSubscriptionsRealtime()].concat(
        findSubscriptions ? [this.findSubscriptions()] : []
      )
    );
    this.startChart();
  }

  startChart() {
    this.chartData.datasets[0].data = this.yAxis.map(
      (date) =>
        this.subscribers
          .filter(
            (s) =>
              Number(this.datePipe.transform(s.referenceDate, 'HH')) + 'h' ===
              date
          )
          .reduce((sum, s) => (sum += s.subscriptions), 0) / 7
    );
    this.chartData.datasets[1].data = this.yAxis
      .filter(
        (hour) =>
          Number(this.datePipe.transform(new Date(), 'HH')) >=
          Number(hour.replace('h', ''))
      )
      .map((date) =>
        this.subscribersRealtime
          .filter(
            (s) =>
              Number(this.datePipe.transform(s.referenceDate, 'HH')) + 'h' ===
              date
          )
          .reduce((sum, s) => (sum += s.subscriptions), 0)
      );
    this.chart?.chart?.update();
  }

  async findSubscriptions(): Promise<void> {
    try {
      this.subscribers = await lastValueFrom(
        this.kpiService.findAnalyticsDashboard(this.subscriptionId.value).pipe(
          map((data) =>
            data.result.map((s) => ({
              ...s,
              referenceDate: FormUtil.utcDate(s.referenceDate)
            }))
          )
        )
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findSubscriptionsRealtime(): Promise<void> {
    try {
      this.subscribersRealtime = await lastValueFrom(
        this.kpiService
          .findAnalyticsDashboardRealtime(this.subscriptionId.value)
          .pipe(
            map((data) =>
              data.result.map((s) => ({
                ...s,
                referenceDate: FormUtil.utcDate(s.referenceDate)
              }))
            )
          )
      );
      if (!this.subscribersRealtimeTotal()?.createdAt)
        this.findSubscriptionsRealtimeCount();
    } catch (error) {
      console.error(error);
    }
  }

  async findSubscriptionsRealtimeCount(): Promise<void> {
    try {
      const total = await lastValueFrom(
        this.kpiService
          .findAnalyticsDashboardRealtimeTotal(this.subscriptionId.value)
          .pipe(
            map((data) => ({
              ...data.result,
              createdAt: FormUtil.utcDate(data.result.createdAt)
            }))
          )
      );
      if (
        !this.subscribersRealtimeTotal()?.total ||
        !this.subscribersRealtimeTotal()?.createdAt ||
        total.total > this.subscribersRealtimeTotal().total ||
        total.createdAt.getTime() >
          this.subscribersRealtimeTotal().createdAt.getTime()
      )
        this.subscribersRealtimeTotal.set(total);
    } catch (error) {
      console.error(error);
    }
  }

  hourForecast(hour: number): number {
    if (!hour)
      return (
        this.subscribers
          .filter(
            (s) =>
              Number(this.datePipe.transform(s.referenceDate, 'HH')) === hour
          )
          .reduce((sum, s) => (sum += s.subscriptions), 0) / 7
      );
    return (
      (this.subscribers
        .filter(
          (s) => Number(this.datePipe.transform(s.referenceDate, 'HH')) === hour
        )
        .reduce((sum, s) => (sum += s.subscriptions), 0) /
        7) *
      (this.subscribersRealtime
        .filter(
          (s) =>
            Number(this.datePipe.transform(s.referenceDate, 'HH')) <= hour - 1
        )
        .reduce((sum, s) => (sum += s.subscriptions), 0) /
        (this.subscribers
          .filter(
            (s) =>
              Number(this.datePipe.transform(s.referenceDate, 'HH')) <= hour - 1
          )
          .reduce((sum, s) => (sum += s.subscriptions), 0) /
          7) || 1)
    );
  }

  connect() {
    try {
      this.websocketService.connect(this.onMessage.bind(this));
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  onMessage(message: Message<any>): void {
    console.log(message);
    if (message.topic === Topic.SUBSCRIBER_UPDATE) {
      this.addNewSubscriber({
        ...message.body,
        dateAuthorized: FormUtil.utcDate(message.body.dateAuthorized)
      });
    }
  }

  addNewSubscriber(subscriber: SubscriberUpdate) {
    let counter: SubscriptionsPerHourRealtime;
    if (![0, 2].includes(subscriber.creditCardPaymentTypeId)) return;
    if (subscriber.creditCardPaymentTypeId === 0) {
      counter = this.subscribersRealtime.find(
        (s) =>
          s.acquisitionType === 1 &&
          s.subscriptionId === subscriber.subscriptionId &&
          this.datePipe.transform(s.referenceDate, 'yyyy-MM-dd HH:00:00') ===
            this.datePipe.transform(
              subscriber.dateAuthorized,
              'yyyy-MM-dd HH:00:00'
            )
      );
    } else {
      counter = this.subscribersRealtime.find(
        (s) =>
          s.acquisitionType === 2 &&
          s.subscriptionId === subscriber.subscriptionId &&
          this.datePipe.transform(s.referenceDate, 'yyyy-MM-dd HH:00:00') ===
            this.datePipe.transform(
              subscriber.dateAuthorized,
              'yyyy-MM-dd HH:00:00'
            )
      );
    }
    if (counter) {
      counter.subscriptions++;
    } else {
      counter = {
        acquisitionType: subscriber.creditCardPaymentTypeId || 1,
        referenceDate: new Date(
          this.datePipe.transform(
            subscriber.dateAuthorized,
            'yyyy-MM-dd HH:00:00'
          )
        ),
        subscriptionId: subscriber.subscriptionId,
        subscriptionName: getSubscriptionName(subscriber.subscriptionId),
        subscriptions: 1
      };
      this.subscribersRealtime.push(counter);
    }
    this.subscribersRealtimeTotal.set({
      createdAt: subscriber.dateAuthorized,
      total:
        this.subscribersRealtime?.reduce(
          (sum, s) => (sum += s.subscriptions),
          0
        ) || 0
    });
    const yIndex = this.yAxis.findIndex((l) => {
      const hour =
        Number(this.datePipe.transform(counter.referenceDate, 'HH')) + 'h';
      return l === hour;
    });
    if (yIndex >= 0) {
      this.chartData.datasets[1].data[yIndex] =
        (this.chartData.datasets[1].data[yIndex] as number) + 1;
      this.chart.chart.update();
    }
  }

  get yAxis(): Array<string> {
    const hours: Array<string> = [];
    for (let index = 0; index < 24; index++) {
      hours.push(index + 'h');
    }
    return hours;
  }

  get totalSubscriptionsTillTime(): number {
    return (
      this.subscribers
        .filter(
          (s) =>
            Number(this.datePipe.transform(s.referenceDate, 'HH')) <
            Number(this.datePipe.transform(new Date(), 'HH'))
        )
        .reduce((sum, s) => (sum += s.subscriptions || 0), 0) / 7
    );
  }

  get avgSubscriptions(): number {
    return (
      this.subscribers.reduce((sum, s) => (sum += s.subscriptions || 0), 0) / 7
    );
  }

  get subscriptionsForecast(): number {
    return Math.round(
      this.avgSubscriptions *
        ((this.subscribersRealtimeTotal()?.total || 0) /
          (this.totalSubscriptionsTillTime || 1))
    );
  }

  get subscription(): { label: string; value: number } {
    return this.subscriptions.find(
      (s) => s.value === this.subscriptionId.value
    );
  }
}

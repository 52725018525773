import { Component, Input } from '@angular/core';
import { FormUtil } from '../utils/form.util';

@Component({
  selector: 'app-edition-image',
  templateUrl: './edition-image.component.html',
  styleUrls: ['./edition-image.component.scss']
})
export class EditionImageComponent {
  @Input({ required: true })
  imageURL: string | undefined;

  @Input({ required: true })
  editionId: number | undefined;

  @Input({ required: true })
  maxHeight: string = '100px';

  @Input({ required: true })
  maxWidth: string = '100px';

  constructor() {}

  get editionImage(): string {
    return FormUtil.editionImage(this.editionId || 0);
  }
}

<div *ngIf="person && invites">
  <app-table
    [cols]="colsSent"
    [elements]="invitesSent"
    [lazy]="false"
    [addButton]="false"
    [emptyMessage]="person.name + ' ainda não enviou convites'"
    [exportExcel]="false"
    modelName="convites"
    tableTitle="Convites enviados"
    [exportPdf]="false"
    sortField="dateCreated"
    [sortOrder]="1"
  ></app-table>
  <br />
  <app-table
    [cols]="colsReceived"
    [elements]="invitesReceived"
    [lazy]="false"
    [addButton]="false"
    [emptyMessage]="person.name + ' ainda não recebeu convites'"
    [exportExcel]="false"
    [exportPdf]="false"
    modelName="convites"
    tableTitle="Convites recebidos"
    sortField="dateCreated"
    [sortOrder]="1"
  ></app-table>
</div>

import { Component, ViewChild } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  SubscriberControlGroupByDate,
  SubscriptionType
} from 'src/app/admin-api';
import { SubscriberGroupCounterChartComponent } from '../subscriber-group-counter-chart.component';

@Component({
  selector: 'app-subscriber-group-counter-chart-dialog',
  templateUrl: './subscriber-group-counter-chart-dialog.component.html',
  styleUrls: ['./subscriber-group-counter-chart-dialog.component.scss']
})
export class SubscriberGroupCounterChartDialogComponent {
  @ViewChild(SubscriberGroupCounterChartComponent)
  chart: SubscriberGroupCounterChartComponent | undefined;

  subscriberGroupCounters: Array<SubscriberControlGroupByDate> | undefined;
  graphType: 'daily' | 'monthly' = 'daily';
  subscriptionTypes: Array<SubscriptionType> | undefined;
  responsive = true;
  width: string;
  height: string;

  checkScreenSize() {
    if (window.innerWidth < 842) {
      this.responsive = false;
      this.width = '100%';
      this.height = '500px';
    } else {
      this.responsive = true;
    }
  }

  constructor(private config: DynamicDialogConfig, ref: DynamicDialogRef) {
    this.subscriberGroupCounters = this.config.data.subscriberGroupCounters;
    this.graphType = this.config.data.graphType || 'daily';
    this.subscriptionTypes = this.config.data.subscriptionTypes || 'daily';
    this.checkScreenSize();
    ref.onMaximize.subscribe((event: { maximized: boolean }) => {
      if (this.chart) {
        if (event.maximized && this.responsive) {
          this.responsive = false;
          this.width = (window.innerWidth - 50).toString().concat('px');
          this.height = (window.innerHeight - 100).toString().concat('px');
        } else {
          this.checkScreenSize();
          this.chart.checkScreenSize();
          document.body.classList.add('');
        }
        this.chart.resize();
      }
    });
  }
}

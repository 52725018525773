import { Component, Input, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { lastValueFrom, map } from 'rxjs';
import {
  EditionCustomization,
  FileControllerService,
  SubscriptionControllerService
} from 'src/app/admin-api';
import { CustomizationFormComponent } from 'src/app/components/customization/customization-form.component';
import { getSubscriptionName } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { ImageService } from 'src/app/services/image.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-edition',
  templateUrl: './edition.component.html',
  styleUrls: ['./edition.component.scss'],
  providers: [MessageService]
})
export class EditionComponent
  extends CustomizationFormComponent
  implements OnInit
{
  @Input()
  editionId: string;
  editionDate: number;
  edition: EditionCustomization;
  iconCombo: string;
  iconBox: string;
  iconBag: string;
  iconPass: string;

  override form = new FormGroup({
    editionTheme: new FormControl<string>(null, Validators.required),
    editionDateTime: new FormControl<Date>(null, Validators.required),
    editionDate: new FormControl<number>(null, Validators.required),
    editionCardBackgroundColor: new FormControl<string>('#FFFFFF'),
    editionCardDesktop: new FormControl<string>(null),
    editionCardMobile: new FormControl<string>(null),
    lastEditionsCarouselCard: new FormControl<string>(null),
    lastEditionsCarouselCardAlt: new FormControl<string>(null),
    icons: new FormArray([
      new FormGroup({
        editionId: new FormControl<number>(null, Validators.required),
        icon: new FormControl<string>(null, Validators.required)
      }),
      new FormGroup({
        editionId: new FormControl<number>(null, Validators.required),
        icon: new FormControl<string>(null, Validators.required)
      }),
      new FormGroup({
        editionId: new FormControl<number>(null, Validators.required),
        icon: new FormControl<string>(null, Validators.required)
      }),
      new FormGroup({
        editionId: new FormControl<number>(null, Validators.required),
        icon: new FormControl<string>(null, Validators.required)
      })
    ])
  });

  constructor(
    private fb: FormBuilder,
    private subscriptionControllerService: SubscriptionControllerService,
    override router: Router,
    override config: PrimeNGConfig,
    override imageService: ImageService,
    override fileService: FileControllerService,
    private messageService: MessageService
  ) {
    super(router, config, imageService, fileService);
  }

  async ngOnInit() {
    LoaderService.showLoader();
    await this.getDocument();
    this.initializeForm();
    LoaderService.showLoader(false);
  }

  override async getDocument(): Promise<void> {
    if (this.editionId !== 'new') {
      try {
        const editonId = Number(this.editionId);
        this.edition = await lastValueFrom(
          this.subscriptionControllerService
            .getEditonCustomizationById(editonId)
            .pipe(
              map((data) => ({
                ...data.result,
                editionDateTime: new Date(data.result.editionDateTime)
              }))
            )
        );
      } catch (error) {
        AppDialogService.showErrorDialog(error);
        return;
      }
    }
  }

  override initializeForm(): void {
    this.form.patchValue({
      ...this.edition
    });

    if (this.editionId !== 'new') this.form.get('editionDateTime')?.disable();

    this.form
      .get('editionDateTime')
      ?.valueChanges.subscribe((newValue: Date) => {
        if (newValue instanceof Date) {
          this.convertDateToEditionDate(newValue);

          this.form.get('editionDate')?.setValue(this.editionDate);
          this.form
            .get('icons.0.editionId')
            .setValue(Number(`7${this.editionDate}`));
          this.form
            .get('icons.1.editionId')
            .setValue(Number(`1${this.editionDate}`));
          this.form
            .get('icons.2.editionId')
            .setValue(Number(`5${this.editionDate}`));
          this.form
            .get('icons.3.editionId')
            .setValue(Number(`6${this.editionDate}`));
        }
      });
  }

  override async save() {
    LoaderService.showLoader();
    try {
      if (this.editionId === 'new') {
        const edition = await lastValueFrom(
          this.subscriptionControllerService
            .createEditionCustomization(this.form.value)
            .pipe(map((data) => data.result))
        );
        await this.afterSubmit();
        this.messageService.add({
          severity: 'success',
          detail: 'Edição cadastrada com sucesso.',
          summary: 'Sucesso'
        });
        await this.afterSubmit();
        this.router.navigate([
          '/configuracoes/editions/' + edition.editionDate
        ]);
      } else {
        await lastValueFrom(
          this.subscriptionControllerService.updateEditionCustomization(
            this.form.value
          )
        );

        this.messageService.add({
          severity: 'success',
          detail: 'Edição atualizada com sucesso.',
          summary: 'Sucesso'
        });
      }
      await this.afterSubmit();
    } catch (e) {
      AppDialogService.showErrorDialog(e);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  subscriptionId(index: number): string {
    return index === 0 ? '7' : index === 1 ? '1' : index === 2 ? '5' : '6';
  }

  convertDateToEditionDate(value: Date) {
    this.editionDate = Number(
      JSON.stringify(value.getFullYear()) +
        (value.getMonth() + 1).toString().padStart(2, '0')
    );
  }

  getEditionIcon(index: number) {
    return this.icons?.controls?.at(index);
  }

  getEditionName(index: number) {
    return getSubscriptionName(
      Number(
        (this.getEditionIcon(index).value.editionId as number)
          ?.toString()
          .charAt(0)
      )
    )?.replace('Glam', '');
  }

  get icons() {
    return this.form.get('icons') as FormArray<FormGroup<any>>;
  }

  override get filePath(): string {
    return '/editions/';
  }

  override get model() {
    return this.edition;
  }

  override get modelId() {
    return this.editionId;
  }
}

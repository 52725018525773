<h3 *ngIf="model === null">Cadastro de Regra de Jóia</h3>
<h3 *ngIf="model">Editar Regra de Jóia</h3>
<form
  class="form-b4a product-variant-form"
  *ngIf="form && (model || model === null)"
  [formGroup]="form"
  (submit)="submit()"
>
  <span class="p-float-label small-info">
    <input
      id="description"
      type="text"
      pInputText
      formControlName="description"
    />
    <label for="description">Descrição da Regra*</label>
    <small
      class="error"
      *ngIf="
        form.controls['description'].errors &&
        form.controls['description'].errors['maxlength']
      "
    >
      Máximo de
      {{ form.controls['description'].errors['maxlength']['requiredLength'] }}
      caracteres
    </small>
    <small
      class="error"
      *ngIf="
        form.controls['description'].errors &&
        form.controls['description'].errors['minlength']
      "
    >
      Mínimo de
      {{ form.controls['description'].errors['minlength']['requiredLength'] }}
      caracteres
    </small>
    <small>Ex: Avaliação da Edição</small>
  </span>
  <span class="p-float-label small-info">
    <p-dropdown
      formControlName="personType"
      [options]="personTypes"
      optionLabel="description"
      optionValue="personTypeId"
      appendTo="body"
    />
    <label for="personType">Tipo de Usuário*</label>
  </span>
  <span class="p-float-label small-info">
    <p-dropdown
      formControlName="actionType"
      [options]="actionTypes"
      optionLabel="description"
      optionValue="badgeActionTypeId"
      appendTo="body"
    />
    <label for="actionType">Tipo de Ação*</label>
  </span>
  <span class="p-float-label small-info">
    <p-dropdown
      formControlName="periodType"
      [options]="periodTypes"
      optionLabel="description"
      optionValue="periodTypeId"
      appendTo="body"
      (onChange)="onChange()"
    />
    <label for="periodType">Tipo de Período*</label>
  </span>
  <span class="p-float-label small-info">
    <input
      id="periodValue"
      type="number"
      pInputText
      formControlName="periodValue"
    />
    <label for="periodValue">Valor do Período*</label>
    <small
      class="error"
      *ngIf="
        form.controls['periodValue'].touched &&
        form.controls['periodValue'].dirty &&
        form.controls['periodValue'].errors &&
        form.controls['periodValue'].errors['min']
      "
    >
      Está abaixo do valor mínimo (1)
    </small>
  </span>
  <span class="p-float-label small-info">
    <input id="quantity" type="number" pInputText formControlName="quantity" />
    <label for="quantity">Quantidade*</label>
    <small
      class="error"
      *ngIf="
        form.controls['quantity'].touched &&
        form.controls['quantity'].dirty &&
        form.controls['quantity'].errors &&
        form.controls['quantity'].errors['min']
      "
    >
      Está abaixo do valor mínimo (1)
    </small>
  </span>
  <div class="buttons">
    <p-button
      *ngIf="permission"
      [disabled]="!form.valid"
      severity="primary"
      label="Salvar"
      type="sumbit"
    />
  </div>
</form>

/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Reward {
  rewardId?: number;
  personId?: number;
  rewardType?: number;
  referenceId?: number;
  referenceName?: string;
  points?: number;
  dateCreated?: Date;
  productId?: string;
  orderId?: string;
  token?: string;
  subtitle?: string;
}

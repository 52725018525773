import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { lastValueFrom, map } from 'rxjs';
import {
  FinancialControllerService,
  ItOpsControllerService,
  RecurrenceIsNull
} from 'src/app/admin-api';
import {
  DropdownFilter,
  TableColumn,
  TableComponent
} from 'src/app/components/table';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-recurrence-is-null',
  templateUrl: './recurrence-is-null.component.html',
  styleUrls: ['./recurrence-is-null.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class RecurrenceIsNullComponent implements OnInit {
  @ViewChild(TableComponent) table: TableComponent;

  columns: Array<TableColumn> = [
    new TableColumn('', '', false, 'checkbox'),
    new TableColumn(
      'SubscriberId',
      'subscriberId',
      true,
      'number',
      '/users/subscribers/',
      'subscriberId'
    ),
    new TableColumn('CreditCardId', 'creditCardId', true, 'number'),
    new TableColumn(
      'RecurrenceStatusId',
      'recurrenceStatusId',
      true,
      'number',
      undefined,
      undefined,
      true,
      'in'
    ),
    new TableColumn(
      'Próxima Data de Pagamento',
      'nextPaymentDate',
      true,
      'date'
    ),
    new TableColumn('MaxAttempts', 'maxAttempts', true, 'number'),
    new TableColumn('Attempt', 'attempt', true, 'number'),
    new TableColumn(
      'GatewayId',
      'preferredGatewayId',
      true,
      'number',
      undefined,
      undefined,
      true,
      'in'
    ),
    new TableColumn('Installments', 'installments', true, 'number'),
    new TableColumn('Recurrence', 'recurrence', true, 'number'),
    new TableColumn(
      'Amount',
      'amount',
      true,
      'currency',
      undefined,
      undefined,
      true,
      'gte'
    )
  ];
  dropdownFilters: { [field: string]: Array<DropdownFilter> };

  constructor(
    public itOpsService: ItOpsControllerService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private financialController: FinancialControllerService
  ) {}

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader(true);
    try {
      this.dropdownFilters = {
        preferredGatewayId: await lastValueFrom(
          this.financialController.findGatewaysList().pipe(
            map((data) =>
              data.result.map((g) => ({
                label: g.gatewayId + '. ' + g.gatewayName,
                value: g.gatewayId
              }))
            )
          )
        ),
        recurrenceStatusId: await lastValueFrom(
          this.financialController.findRecurrenceStatusList().pipe(
            map((data) =>
              data.result.map((st) => ({
                label:
                  st.creditCardRecurrenceStatusId +
                  '. ' +
                  st.creditCardRecurrenceStatusName,
                value: st.creditCardRecurrenceStatusId.toString()
              }))
            )
          )
        )
      };
    } catch (error) {
      this.dropdownFilters['preferredGatewayId'] = [];
      AppDialogService.showErrorDialog(error);
    }
  }

  markAsResolved(elements: Array<RecurrenceIsNull>): void {
    this.confirmationService.confirm({
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      rejectButtonStyleClass: 'p-button-danger',
      message: 'Deseja criar as recorrências?',
      header: 'Criar recorrência',
      accept: async () => {
        LoaderService.showLoader();
        try {
          await lastValueFrom(this.itOpsService.fixRecurrenceIsNull(elements));
          await this.table.refresh(true);
          this.messageService.add({
            summary: 'Sucesso',
            detail: elements.length + ' marcado(s) como resolvido(s)',
            severity: 'success'
          });
          LoaderService.showLoader(false);
        } catch (error) {
          LoaderService.showLoader(false);
          AppDialogService.showErrorDialog(error);
        }
      }
    });
  }
}

<app-table
  *ngIf="currentSocialMedias && socialMedias"
  [lazy]="false"
  [elements]="currentSocialMedias"
  modelName="redes sociais"
  [addButton]="false"
  [cols]="cols"
  [emptyMessage]="person.name + ' ainda não vinculou suas redes sociais'"
  [exportExcel]="false"
  tableTitle="Redes sociais vinculadas"
  [exportPdf]="false"
  sortField="dateCreated"
  [sortOrder]="1"
></app-table>
<ng-container *ngIf="socialMediaHistory?.length && socialMedias">
  <br />
  <app-table
    [lazy]="false"
    [elements]="socialMediaHistory"
    modelName="redes sociais"
    [addButton]="false"
    [cols]="historyCols"
    [exportExcel]="false"
    tableTitle="Histórico de alterações"
    [exportPdf]="false"
    sortField="dateCreated"
    [sortOrder]="1"
  ></app-table>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { TimelineModule } from 'primeng/timeline';
import { TooltipModule } from 'primeng/tooltip';
import { EditionImageModule } from 'src/app/edition-image/edition-image.module';
import { ServicesModule } from 'src/app/services/services.module';
import { EditionChargebackModalModule } from '../edition-chargeback-modal/edition-chargeback-modal.module';
import { ShippingTrackingModalModule } from '../shipping-tracking-modal/shipping-tracking-modal.module';
import { CompositionDetailsComponent } from './composition-details/composition-details.component';
import { CompositionProductDetailComponent } from './composition-product-detail/composition-product-detail.component';
import { SubscriberCompositionsComponent } from './subscriber-compositions.component';

@NgModule({
  declarations: [
    SubscriberCompositionsComponent,
    CompositionDetailsComponent,
    CompositionProductDetailComponent
  ],
  imports: [
    CommonModule,
    ButtonModule,
    RouterModule,
    ShippingTrackingModalModule,
    DialogModule,
    TooltipModule,
    ConfirmPopupModule,
    EditionChargebackModalModule,
    TimelineModule,
    ServicesModule,
    EditionImageModule
  ],
  exports: [
    SubscriberCompositionsComponent,
    CompositionDetailsComponent,
    CompositionProductDetailComponent
  ]
})
export class SubscriberCompositionsModule {}

/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface DoubleCommission {
  subscriberid?: number;
  personId?: number;
  orderId?: string;
  subscriptionId?: number;
  subscriptionTypeId?: number;
  couponId?: number;
  influencerTransactionTypeId?: number;
  soldValue?: number;
  amount?: number;
  description?: string;
  total?: number;
  couponName?: string;
}

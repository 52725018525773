export enum FeedbackGroupFilterTypeEnum {
  'Perfil de beleza' = 0,
  'Formulário de pesquisa' = 1,
  'Variante' = 2,
  'Grupo de pesquisa' = 3
}

export function getAllFeedbackGroupFiltersType(): Array<{
  label: string;
  value: number;
}> {
  const objetos: Array<{ label: string; value: number }> = [];
  for (const [p, propertyValue] of Object.entries(
    FeedbackGroupFilterTypeEnum
  )) {
    try {
      if (Number(propertyValue) >= 0) {
        objetos.push({
          label: p,
          value: Number(propertyValue)
        });
      }
    } catch (error: any) {
      objetos.length;
    }
  }
  return objetos;
}

export function getFeedbackGroupFilterTypeName(id: number): string | undefined {
  return getAllFeedbackGroupFiltersType().find((e) => e.value === id)?.label;
}

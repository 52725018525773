/* eslint-disable no-useless-escape */
const regexpPairs = [
  { pattern: /[ãàáäâ]/g, replacement: 'a' },
  { pattern: /[ẽèéëê]/g, replacement: 'e' },
  { pattern: /[ìíïî]/g, replacement: 'i' },
  { pattern: /[õòóöô]/g, replacement: 'o' },
  { pattern: /[ùúüû]/g, replacement: 'u' },
  { pattern: /[ñ]/g, replacement: 'n' },
  { pattern: /[ç]/g, replacement: 'c' },
  { pattern: /[\.·\\\/_,\:;=]/g, replacement: '-' }
];
const mapChar = (letter: string) => {
  return regexpPairs.reduce(
    (acc, { pattern, replacement }) => acc.replace(pattern, replacement),
    letter
  );
};
export function slugify(input: string): string {
  return input
    .split('')
    .map(mapChar)
    .toString()
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/&/g, '-e-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-'); // Replace multiple - with single -
}

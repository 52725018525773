export interface SubscriberSummary {
  BoxId?: string;
  'Tipo de assinante'?: string;
  Nome?: string;
  Telefone?: string;
  CPF?: string;
  'E-mail'?: string;
  Assinatura?: string;
  Plano?: string;
  Joia?: string;
  Status?: string;
  Edição?: string;
  Produto?: string;
  Cupom?: string;
  Composição?: string;
  Total?: string;
  'Data Pagamento'?: string;
}

import { DatePipe, TitleCasePipe } from '@angular/common';
import {
  Component,
  OnInit,
  ViewChild,
  WritableSignal,
  computed,
  inject,
  signal
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { TabView } from 'primeng/tabview';
import { lastValueFrom, map } from 'rxjs';
import {
  Composition,
  CompositionControllerService,
  CompositionProduct,
  CompositionProductVariantDetail,
  CompositionProductionSummary,
  CompositionSubstitutionDetail,
  ProductCategory,
  ProductControllerService,
  ResponseB4AListOrderMovementStatusDescription,
  SubscriberControllerService,
  SubscriberEditionCompositionSummary,
  SubscriberEditionStatus,
  UserControllerService,
  UserRoleViewResponse
} from 'src/app/admin-api';
import {
  DropdownFilter,
  FilterChangedEvent,
  TableColumn
} from 'src/app/components/table';
import {
  Role,
  SubscriberEditionTotal,
  SubscriptionEnum,
  roleAsObject
} from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';
import { CompositionsBeautyProfileComponent } from '../../compositions-beauty-profile/compositions-beauty-profile.component';

@Component({
  selector: 'app-composition-detail',
  templateUrl: './composition-detail.component.html',
  styleUrls: ['./composition-detail.component.scss'],
  providers: [DialogService, ConfirmationService, DatePipe, TitleCasePipe]
})
export class CompositionDetailComponent implements OnInit {
  private router: Router = inject(Router);
  private compositionService: CompositionControllerService = inject(
    CompositionControllerService
  );
  private userService: UserControllerService = inject(UserControllerService);
  private subscriberService: SubscriberControllerService = inject(
    SubscriberControllerService
  );
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private dialog: DialogService = inject(DialogService);
  private confirmationService: ConfirmationService =
    inject(ConfirmationService);
  private messageService: MessageService = inject(MessageService);
  private datePipe: DatePipe = inject(DatePipe);
  private productService: ProductControllerService = inject(
    ProductControllerService
  );
  private title: Title = inject(Title);
  private ngxPermissionsService: NgxPermissionsService = inject(
    NgxPermissionsService
  );
  private titleCasePipe: TitleCasePipe = inject(TitleCasePipe);

  @ViewChild(TabView)
  tabview: TabView | undefined;

  allowedPermissions: Array<string> = [
    roleAsObject(Role.Admin_Allocation).enumValue,
    roleAsObject(Role.User_Allocation).enumValue,
    roleAsObject(Role.Full_Administrator).enumValue
  ];
  compositionId: number;
  composition: Composition;
  compositionProducts: Array<CompositionProductVariantDetail>;
  compositionSubscribers: Array<SubscriberEditionCompositionSummary>;
  subscriberEditionStatusList: Array<SubscriberEditionStatus>;
  subscriberEditionStatusMillenniumnList: Array<ResponseB4AListOrderMovementStatusDescription.ResultEnum>;
  variantCols = computed(() =>
    [
      new TableColumn(
        'EAN',
        'internalEAN',
        true,
        'text',
        '/products/catalog/product-variant/',
        'productVariantId',
        true,
        'contains'
      ),
      new TableColumn(
        'SKU Admin',
        'productVariantId',
        true,
        'number',
        '/products/catalog/product-variant/',
        'productVariantId',
        true,
        'equals'
      ),
      new TableColumn(
        'Millennium SKU',
        'externalId',
        true,
        'number',
        '/products/catalog/product-variant/',
        'productVariantId',
        true,
        'equals'
      ),
      new TableColumn(
        'Qtd',
        'quantity',
        true,
        this.permission() && !this.composition?.dateSyncERP
          ? 'inputNumber'
          : 'number',
        undefined,
        undefined,
        true,
        'equals'
      ),
      new TableColumn(
        'Marca',
        'brandName',
        true,
        'text',
        '/products/brands/',
        'brandId',
        true,
        'contains'
      ),
      new TableColumn(
        'Produto',
        'productVariantName',
        true,
        'text',
        '/products/catalog/product-variant/',
        'productVariantId',
        true,
        'contains'
      ),
      new TableColumn(
        'Categoria',
        'category',
        true,
        'text',
        '/products/categories/',
        'categoryId',
        true,
        'in'
      ),
      new TableColumn(
        'Custo',
        'costPrice',
        true,
        'currency',
        undefined,
        undefined,
        true,
        'between'
      ),
      new TableColumn(
        'Valor de mercado',
        'salePrice',
        true,
        'currency',
        undefined,
        undefined,
        true,
        'between'
      ),
      new TableColumn(
        'Sub. Categoria',
        'subcategory',
        true,
        'text',
        '/products/categories/',
        'subcategoryId',
        true,
        'in'
      ),
      new TableColumn(
        'Estoque aquisição',
        'inStockAcquisition',
        true,
        'formattedInteger',
        undefined,
        undefined,
        true,
        'gte'
      ),
      new TableColumn(
        'Sub. Categoria',
        'subcategory',
        true,
        'text',
        '/products/categories/',
        'subcategoryId',
        true,
        'in'
      ),
      new TableColumn(
        'Validade',
        'expiration',
        true,
        'text',
        undefined,
        undefined,
        true,
        'contains'
      ),
      new TableColumn(
        'Montagem',
        'subscriptionName',
        true,
        !this.permission() || this.composition.dateSyncERP
          ? 'number'
          : 'inputSwitch',
        undefined,
        undefined,
        true,
        'in',
        undefined,
        undefined,
        (product: CompositionProductVariantDetail) => !!product.subscriptionId,
        undefined,
        undefined,
        'Bag',
        'Box'
      )
    ].filter(
      (c) =>
        (this.composition.editionId / 1000000).toFixed(0) === '7' ||
        c.field !== 'subscriptionName'
    )
  );
  subscriberColsOrig: Array<TableColumn> = [
    new TableColumn(
      'BoxId',
      'boxId',
      true,
      'text',
      '/users/subscribers/',
      'subscriberId'
    ),
    new TableColumn(
      'Assinante',
      'personName',
      true,
      'text',
      '/users/person/',
      'personId',
      true,
      'contains'
    ),
    new TableColumn('Nº nota', 'invoiceNumber', true, 'number'),
    new TableColumn('ShippingOrder', 'shippingOrderId', true, 'number'),
    new TableColumn('Cód. Rastreio', 'trackingCode', true, 'text'),
    new TableColumn(
      'Status',
      'subscriberEditionStatusName',
      true,
      'text',
      undefined,
      undefined,
      true,
      'in'
    ),
    new TableColumn(
      'Status Millennium',
      'statusMillenium',
      true,
      'text',
      undefined,
      undefined,
      true,
      'in'
    ),
    new TableColumn(
      'Últ. Atualização',
      'dateUpdatedMillenium',
      true,
      'date',
      undefined,
      undefined,
      true,
      'contains'
    )
  ];
  subscriberCols: Array<TableColumn>;
  selectedTab = 0;
  ready = false;
  edit = false;
  subscriberEditionTotals: Array<SubscriberEditionTotal>;
  subscriberEditionMilleniumTotals: Array<SubscriberEditionTotal>;
  categories: WritableSignal<Array<ProductCategory>> = signal(undefined);
  parentCategories = computed(() => {
    return this.categories()?.filter((c) => !c.parentId);
  });
  subcategories = computed(() => {
    return this.parentCategories()
      ?.filter(
        (c) =>
          !this.categoryIds()?.length ||
          this.categoryIds()?.includes(c.displayName)
      )
      ?.map((c) => ({
        ...c,
        categories: this.categories()?.filter(
          (sc) => sc.parentId === c.categoryId
        )
      }));
  });
  categoryIds: WritableSignal<Array<string>> = signal([]);
  dropdownFilters = computed(() => {
    return {
      category:
        this.parentCategories()?.map((c) => ({
          label: `${c.externalId}. ${c.displayName}`,
          value: c.displayName
        })) || [],
      subcategory:
        this.subcategories()?.map((s) => ({
          label: `${s.externalId}. ${s.displayName}`,
          value: s.displayName,
          items: s.categories?.length
            ? s.categories.map((sc) => ({
                label: `${s.externalId}.${sc.externalId} ${sc.displayName}`,
                value: sc.displayName
              }))
            : null
        })) || [],
      subscriberEditionStatusName: this.compositionSubscribers.reduce(
        (list: Array<DropdownFilter>, cs) => {
          if (!list.some((c) => c.value === cs.subscriberEditionStatusName))
            list.push({
              label: this.titleCasePipe.transform(
                cs.subscriberEditionStatusName
              ),
              value: cs.subscriberEditionStatusName
            });
          list.sort((c1, c2) => {
            if (c1.value > c2.value) return 1;
            if (c1.value < c2.value) return -1;
            return 0;
          });
          return list;
        },
        []
      ),
      statusMillenium: this.compositionSubscribers.reduce(
        (list: Array<DropdownFilter>, cs) => {
          if (!list.some((c) => c.value === cs.statusMillenium))
            list.push({
              label: this.titleCasePipe.transform(cs.statusMillenium),
              value: cs.statusMillenium
            });
          list.sort((c1, c2) => {
            if (c1.value > c2.value) return 1;
            if (c1.value < c2.value) return -1;
            return 0;
          });
          return list;
        },
        []
      ),
      subscriptionName: [
        { label: 'Glambox', value: 'glambox' },
        { label: 'Glambag', value: 'glambag' }
      ]
    };
  });
  permission = signal(false);
  compositionProductionSummary: CompositionProductionSummary;
  user: UserRoleViewResponse;
  substitutionCols = [
    new TableColumn.Builder()
      .setHeader('Assinante')
      .setField('personName')
      .setRouterLink('/users/person/')
      .setRouterLinkFieldName('personId')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('GlamID')
      .setField('glamId')
      .setRouterLink('/users/subscribers/')
      .setRouterLinkFieldName('subscriberId')
      .setCondition('equals')
      .build(),
    new TableColumn.Builder()
      .setHeader('Substituição')
      .setField('productVariantName')
      .setRouterLink('/products/catalog/product-variant/')
      .setRouterLinkFieldName('productVariantId')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('Produto substituído')
      .setField('oldProductVariantName')
      .setRouterLink('/products/catalog/product-variant/')
      .setRouterLinkFieldName('oldProductVariantId')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('Ocorrência')
      .setField('incident')
      .setCondition('in')
      .build(),
    new TableColumn.Builder()
      .setHeader('Descrição')
      .setField('description')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('Data')
      .setField('dateCreated')
      .setCondition('between')
      .setType('date')
      .build()
  ];
  substitutions: Array<CompositionSubstitutionDetail>;

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(async (params) => {
      try {
        this.permission.set(
          await this.ngxPermissionsService.hasPermission(
            this.allowedPermissions
          )
        );
      } catch (error) {
        this.permission.set(false);
        AppDialogService.showErrorDialog(error);
      }
      this.ready = false;
      if (params['compositionId']) {
        LoaderService.showLoader();
        this.compositionId = Number(params['compositionId']);
        await Promise.all([
          this.findComposition(),
          this.findCompositionProducts(),
          this.findCompositionSubscribers(),
          this.findSubscriberEditionStatusList(),
          this.findSubscriberEditionStatusMillenniumList(),
          this.findCategories(),
          this.findCompositionProductions(),
          this.getUserDetails(),
          this.findSubstitutions()
        ]);
        this.title.setTitle('Composição ' + this.composition?.compositionName);
        this.loadSubscribersTable();
        this.activatedRoute.queryParams.subscribe((queryParams) => {
          if (queryParams['tab']) {
            this.selectedTab = Number(queryParams['tab']) || 0;
          } else {
            this.selectedTab = 0;
          }
        });
        this.ready = true;
        LoaderService.showLoader(false);
      } else {
        AppDialogService.showErrorDialog(
          { message: 'Composição não encontrada' },
          true
        );
      }
    });
  }

  async getUserDetails(): Promise<void> {
    try {
      this.user = await lastValueFrom(
        this.userService.getInfo().pipe(map((data) => data.result))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  loadSubscribersTable(): void {
    this.subscriberCols = this.subscriberColsOrig.filter((c) =>
      this.compositionSubscribers?.some(
        (cs) => cs[c.field] !== null && cs[c.field] !== undefined
      )
    );
    this.subscriberEditionTotals = [];
    this.compositionSubscribers
      .filter((cs) => cs.subscriberEditionStatusId <= 6)
      .forEach((cs) => {
        const exists = this.subscriberEditionTotals.find(
          (t) =>
            t.status.toUpperCase() ===
            cs.subscriberEditionStatusName.toUpperCase()
        );
        if (exists) {
          exists.subscribers++;
          const existsMillennium = exists.statusList.find(
            (t) =>
              t.status.toUpperCase() ===
              (cs.statusMillenium || 'C/ Composição').toUpperCase()
          );
          if (existsMillennium) existsMillennium.subscribers++;
          else
            exists.statusList.push({
              status: cs.statusMillenium || 'C/ composição',
              subscribers: 1
            });
        } else {
          this.subscriberEditionTotals.push({
            status: cs.subscriberEditionStatusName,
            subscribers: 1,
            id: cs.subscriberEditionStatusId,
            statusList: [
              {
                status: cs.statusMillenium || 'C/ Composição',
                subscribers: 1
              }
            ]
          });
        }
      });
    const failed = this.compositionSubscribers?.filter(
      (cs) => cs.subscriberEditionStatusId > 6
    );
    if (failed?.length) {
      this.subscriberEditionTotals.push({
        status: 'Falha',
        subscribers: failed.length,
        id: -1,
        statusList: failed.reduce((list: Array<SubscriberEditionTotal>, sc) => {
          const exists = list.find(
            (s) =>
              s.status.toUpperCase() ===
              (sc.statusMillenium || 'C/ composição').toUpperCase()
          );
          if (exists) exists.subscribers++;
          else
            list.push({
              status: sc.statusMillenium || 'C/ composição',
              subscribers: 1
            });
          return list;
        }, [])
      });
    }
    this.subscriberEditionMilleniumTotals = [
      {
        statusList: this.subscriberEditionTotals.reduce(
          (list: Array<SubscriberEditionTotal>, st) => {
            list = list.concat(st.statusList);
            return list;
          },
          []
        ),
        status: '',
        subscribers: 0
      }
    ];
  }

  async findComposition(): Promise<void> {
    try {
      this.composition = await lastValueFrom(
        this.compositionService
          .findCompositionById(this.compositionId)
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error, true);
    }
  }

  async findCompositionProductions(): Promise<void> {
    try {
      const [productions, summary] = await Promise.all([
        lastValueFrom(
          this.compositionService
            .findCompositionProductionsByCompositionId(this.compositionId)
            .pipe(map((data) => data.result))
        ),
        lastValueFrom(
          this.compositionService
            .findCompositionProductionsSummaryByCompositionId(
              this.compositionId
            )
            .pipe(map((data) => data.result))
        )
      ]);
      this.compositionProductionSummary = {
        ...summary,
        productions
      };
    } catch (error) {
      AppDialogService.showErrorDialog(error, true);
    }
  }

  async findCompositionProducts(): Promise<void> {
    try {
      this.compositionProducts = await lastValueFrom(
        this.compositionService
          .findCompositionProducts(this.compositionId)
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
      this.compositionProducts = [];
    }
  }

  async findCompositionSubscribers(): Promise<void> {
    try {
      this.compositionSubscribers = await lastValueFrom(
        this.compositionService
          .findCompositionSubscribers(this.compositionId)
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
      this.compositionSubscribers = [];
    }
  }

  async findSubscriberEditionStatusList(): Promise<void> {
    try {
      this.subscriberEditionStatusList = await lastValueFrom(
        this.subscriberService
          .findSubscriberEditionStatusList()
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      this.subscriberEditionStatusList = [];
      AppDialogService.showErrorDialog(error);
    }
  }

  async findSubscriberEditionStatusMillenniumList(): Promise<void> {
    try {
      this.subscriberEditionStatusMillenniumnList = await lastValueFrom(
        this.subscriberService
          .findSubscriberEditionStatusListMillenium()
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      this.subscriberEditionStatusMillenniumnList = [];
      AppDialogService.showErrorDialog(error);
    }
  }

  async findCategories(): Promise<void> {
    try {
      this.categories.set(
        await lastValueFrom(
          this.productService
            .findProductCategoriesList()
            .pipe(map((data) => data.result))
        )
      );
    } catch (error) {
      this.categories.set([]);
      AppDialogService.showErrorDialog(error);
    }
  }

  async findSubstitutions(): Promise<void> {
    try {
      this.substitutions = await lastValueFrom(
        this.compositionService
          .findCompositionSubstitutions(this.compositionId)
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      this.substitutions = [];
      AppDialogService.showErrorDialog(error);
    }
  }

  filterChanged($event: FilterChangedEvent): void {
    if ($event.field === 'category') {
      try {
        if ($event.value) this.categoryIds.set($event.value as Array<string>);
        else this.categoryIds.set([]);
      } catch (error) {
        this.categoryIds.set([]);
      }
    }
  }

  tabChanged($event: number): void {
    this.router.navigate(
      [`operations/compositions/id/${this.composition.compositionId}`],
      {
        queryParams: { tab: $event },
        queryParamsHandling: 'merge'
      }
    );
  }

  rowBackground(row: CompositionProductVariantDetail): string | undefined {
    if (row.editionId / 1000000 >= 7) {
      if (row.subscriptionId === SubscriptionEnum.Glambag) {
        return '#8540f54c';
      } else if (row.subscriptionId) return '#fe357b4c';
    }
    return undefined;
  }

  editCompositionName(): void {
    this.edit = true;
    setTimeout(() => {
      document.getElementById('compositionName')?.focus();
    }, 1000);
  }

  async changeCompositionName(): Promise<void> {
    LoaderService.showLoader();
    try {
      this.composition = await lastValueFrom(
        this.compositionService
          .updateCompositionName(
            this.composition.compositionId,
            this.composition.compositionName
          )
          .pipe(map((data) => data.result))
      );
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso',
        detail: 'Nome alterado com sucesso.'
      });
      this.edit = false;
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  subscriberStatusColor(subscriberStatus: number): string {
    return FormUtil.subscriberStatusColor(subscriberStatus);
  }

  async changeCompositionProduct(
    compositionProduct: CompositionProductVariantDetail
  ): Promise<void> {
    LoaderService.showLoader();
    try {
      const result = await lastValueFrom(
        this.compositionService
          .updateCompositionProduct({
            ...compositionProduct
          } as CompositionProduct)
          .pipe(map((data) => data.result))
      );
      if (result) {
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail:
            compositionProduct.quantity !== result.quantity
              ? 'Quantidade alterada com sucesso.'
              : 'Montagem alterada com sucesso.'
        });
        compositionProduct.quantity = result.quantity;
        compositionProduct.subscriptionId = result.subscriptionId;
      } else {
        this.ready = false;
        await Promise.all([
          this.findComposition(),
          this.findCompositionProducts()
        ]);
        this.ready = true;
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'Variante removida com sucesso.'
        });
      }
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  falseValue(): number {
    return 1;
  }

  trueValue(): number {
    return 5;
  }

  deleteComposition(): void {
    this.confirmationService.confirm({
      header: 'Remover composição',
      message:
        'Deseja excluir a composição ' + this.composition.compositionName + '?',
      acceptLabel: 'Sim',
      acceptButtonStyleClass: 'p-button-danger',
      acceptIcon: undefined,
      rejectLabel: 'Voltar',
      rejectButtonStyleClass: 'p-button-primary',
      rejectIcon: undefined,
      accept: async () => {
        LoaderService.showLoader();
        try {
          await lastValueFrom(
            this.compositionService.deleteComposition(
              this.composition.compositionId
            )
          );
          this.router.navigate([
            '/operations/compositions/' + this.composition.editionId
          ]);
        } catch (error) {
          AppDialogService.showErrorDialog(error);
        }
        LoaderService.showLoader(false);
      }
    });
  }

  validateSubscribers(): void {
    this.dialog
      .open(CompositionsBeautyProfileComponent, {
        width: '100%',
        height: '90vh',
        header: 'Validar perfis',
        maximizable: true,
        data: {
          compositions: [this.composition]
        }
      })
      .onClose.subscribe(async () => {
        this.ready = false;
        await Promise.all([this.findCompositionSubscribers()]);
        this.loadSubscribersTable();
        this.ready = true;
      });
  }

  get editionDateName(): string {
    return (
      this.datePipe.transform(
        new Date(
          `${((this.composition.editionId % 1000000) / 100).toFixed(0)}-${
            this.composition.editionId % 100
          }-02`
        ),
        'MMMM/yyyy'
      ) || this.editionDate
    );
  }

  get editionImage(): string {
    return this.compositionSubscribers
      ? this.compositionSubscribers[0]?.imageURL
      : null;
  }

  get editionName(): string {
    return this.compositionSubscribers
      ? this.compositionSubscribers[0].theme
      : this.editionDateName;
  }

  get editionDate(): string {
    return FormUtil.editionDate(this.composition.editionId);
  }
}

/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpResponse
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs';

import { Badge } from '../model/badge';
import { BadgeRequest } from '../model/badgeRequest';
import { BadgeRule } from '../model/badgeRule';
import { BadgeRuleRequest } from '../model/badgeRuleRequest';
import { PageableRequest } from '../model/pageableRequest';
import { ResponseB4ABadge } from '../model/responseB4ABadge';
import { ResponseB4ABadgeRule } from '../model/responseB4ABadgeRule';
import { ResponseB4ABadgeRuleDetailed } from '../model/responseB4ABadgeRuleDetailed';
import { ResponseB4AListBadgeActionType } from '../model/responseB4AListBadgeActionType';
import { ResponseB4AListBadgeDTO } from '../model/responseB4AListBadgeDTO';
import { ResponseB4AListBadgeRule } from '../model/responseB4AListBadgeRule';
import { ResponseB4AListBadgeRuleDetailed } from '../model/responseB4AListBadgeRuleDetailed';
import { ResponseB4AListPeriodType } from '../model/responseB4AListPeriodType';
import { ResponseB4AListPersonType } from '../model/responseB4AListPersonType';
import { ResponseB4APageBadge } from '../model/responseB4APageBadge';
import { ResponseB4AString } from '../model/responseB4AString';

import { Configuration } from '../configuration';
import { BASE_PATH } from '../variables';

@Injectable()
export class BadgeControllerService {
  protected basePath = 'http://localhost:5000';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createBadge(
    body?: BadgeRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ABadge>;
  public createBadge(
    body?: BadgeRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ABadge>>;
  public createBadge(
    body?: BadgeRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ABadge>>;
  public createBadge(
    body?: BadgeRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4ABadge>(
      'post',
      `${this.basePath}/badge/admin/create`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createBadgeRule(
    body?: BadgeRuleRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ABadgeRule>;
  public createBadgeRule(
    body?: BadgeRuleRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ABadgeRule>>;
  public createBadgeRule(
    body?: BadgeRuleRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ABadgeRule>>;
  public createBadgeRule(
    body?: BadgeRuleRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4ABadgeRule>(
      'post',
      `${this.basePath}/badge/admin/badgerule/create`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param badgeRuleId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteBadgeRule(
    badgeRuleId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AString>;
  public deleteBadgeRule(
    badgeRuleId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AString>>;
  public deleteBadgeRule(
    badgeRuleId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AString>>;
  public deleteBadgeRule(
    badgeRuleId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (badgeRuleId === null || badgeRuleId === undefined) {
      throw new Error(
        'Required parameter badgeRuleId was null or undefined when calling deleteBadgeRule.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AString>(
      'delete',
      `${this.basePath}/badge/admin/badgerule/delete/${encodeURIComponent(
        String(badgeRuleId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findActionTypeList(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListBadgeActionType>;
  public findActionTypeList(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListBadgeActionType>>;
  public findActionTypeList(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListBadgeActionType>>;
  public findActionTypeList(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListBadgeActionType>(
      'get',
      `${this.basePath}/badge/badgerule/actiontype`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findActiveBadges(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListBadgeDTO>;
  public findActiveBadges(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListBadgeDTO>>;
  public findActiveBadges(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListBadgeDTO>>;
  public findActiveBadges(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListBadgeDTO>(
      'get',
      `${this.basePath}/badge`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param badgeRuleId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findBadgeRule(
    badgeRuleId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ABadgeRule>;
  public findBadgeRule(
    badgeRuleId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ABadgeRule>>;
  public findBadgeRule(
    badgeRuleId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ABadgeRule>>;
  public findBadgeRule(
    badgeRuleId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (badgeRuleId === null || badgeRuleId === undefined) {
      throw new Error(
        'Required parameter badgeRuleId was null or undefined when calling findBadgeRule.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4ABadgeRule>(
      'get',
      `${this.basePath}/badge/badgerule/${encodeURIComponent(
        String(badgeRuleId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param badgeRuleId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findBadgeRuleDetailed(
    badgeRuleId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ABadgeRuleDetailed>;
  public findBadgeRuleDetailed(
    badgeRuleId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ABadgeRuleDetailed>>;
  public findBadgeRuleDetailed(
    badgeRuleId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ABadgeRuleDetailed>>;
  public findBadgeRuleDetailed(
    badgeRuleId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (badgeRuleId === null || badgeRuleId === undefined) {
      throw new Error(
        'Required parameter badgeRuleId was null or undefined when calling findBadgeRuleDetailed.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4ABadgeRuleDetailed>(
      'get',
      `${this.basePath}/badge/badgerule/detail/${encodeURIComponent(
        String(badgeRuleId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param badgeId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findBadgeRuleList(
    badgeId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListBadgeRuleDetailed>;
  public findBadgeRuleList(
    badgeId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListBadgeRuleDetailed>>;
  public findBadgeRuleList(
    badgeId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListBadgeRuleDetailed>>;
  public findBadgeRuleList(
    badgeId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (badgeId === null || badgeId === undefined) {
      throw new Error(
        'Required parameter badgeId was null or undefined when calling findBadgeRuleList.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListBadgeRuleDetailed>(
      'get',
      `${this.basePath}/badge/badgerule/detail/${encodeURIComponent(
        String(badgeId)
      )}/list`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param badgeId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findBadgeRulesListByBadgeId(
    badgeId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListBadgeRule>;
  public findBadgeRulesListByBadgeId(
    badgeId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListBadgeRule>>;
  public findBadgeRulesListByBadgeId(
    badgeId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListBadgeRule>>;
  public findBadgeRulesListByBadgeId(
    badgeId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (badgeId === null || badgeId === undefined) {
      throw new Error(
        'Required parameter badgeId was null or undefined when calling findBadgeRulesListByBadgeId.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListBadgeRule>(
      'get',
      `${this.basePath}/badge/badgerule/${encodeURIComponent(
        String(badgeId)
      )}/list`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findPeriodTypeList(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListPeriodType>;
  public findPeriodTypeList(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListPeriodType>>;
  public findPeriodTypeList(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListPeriodType>>;
  public findPeriodTypeList(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListPeriodType>(
      'get',
      `${this.basePath}/badge/badgerule/periodtype`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findPersonTypeList(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListPersonType>;
  public findPersonTypeList(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListPersonType>>;
  public findPersonTypeList(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListPersonType>>;
  public findPersonTypeList(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListPersonType>(
      'get',
      `${this.basePath}/badge/badgerule/persontype`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param badgeId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getBadgeById(
    badgeId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ABadge>;
  public getBadgeById(
    badgeId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ABadge>>;
  public getBadgeById(
    badgeId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ABadge>>;
  public getBadgeById(
    badgeId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (badgeId === null || badgeId === undefined) {
      throw new Error(
        'Required parameter badgeId was null or undefined when calling getBadgeById.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4ABadge>(
      'get',
      `${this.basePath}/badge/${encodeURIComponent(String(badgeId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listBadge(
    body?: PageableRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4APageBadge>;
  public listBadge(
    body?: PageableRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4APageBadge>>;
  public listBadge(
    body?: PageableRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4APageBadge>>;
  public listBadge(
    body?: PageableRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4APageBadge>(
      'put',
      `${this.basePath}/badge/list`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateBadge(
    body?: Badge,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ABadge>;
  public updateBadge(
    body?: Badge,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ABadge>>;
  public updateBadge(
    body?: Badge,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ABadge>>;
  public updateBadge(
    body?: Badge,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4ABadge>(
      'put',
      `${this.basePath}/badge/admin/update`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateBadgeRule(
    body?: BadgeRule,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ABadgeRule>;
  public updateBadgeRule(
    body?: BadgeRule,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ABadgeRule>>;
  public updateBadgeRule(
    body?: BadgeRule,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ABadgeRule>>;
  public updateBadgeRule(
    body?: BadgeRule,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4ABadgeRule>(
      'put',
      `${this.basePath}/badge/admin/badgerule/update`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}

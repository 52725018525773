import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
  inject
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  Category,
  Filter,
  FilterOption,
  Product,
  ProductGender,
  ProductLabel
} from '@infrab4a/connect';
import { MessageService } from 'primeng/api';
import {
  AutoCompleteCompleteEvent,
  AutoCompleteUnselectEvent
} from 'primeng/autocomplete';
import { ChipsAddEvent } from 'primeng/chips';
import { BaseConnectService } from 'src/app/connect-api/api/shop/base-connect.service';
import { ShopCategoryFilterService } from 'src/app/connect-api/api/shop/shop-category-filter.service';
import { ShopCategoryService } from 'src/app/connect-api/api/shop/shop-category.service';
import { ShopProductService } from 'src/app/connect-api/api/shop/shop-product.service';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';
import { slugify } from 'src/app/utils/slug.util';
import { DropdownFilter } from '../table';

@Component({
  selector: 'app-shop-product-form',
  templateUrl: './shop-product-form.component.html',
  styleUrl: './shop-product-form.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class ShopProductFormComponent implements OnInit {
  @Input() product: Partial<Product>;
  @Input() categories: Array<Category>;

  @Output() productUpdated = new EventEmitter();

  private productService = inject(ShopProductService);
  private categoryService = inject(ShopCategoryService);
  private messageService = inject(MessageService);
  private filterService = inject(ShopCategoryFilterService);

  form = new FormGroup({
    name: new FormControl<string>(null, Validators.required),
    slug: new FormControl<string>(null, Validators.required),
    price: new FormGroup({
      price: new FormControl<number>(null, [
        Validators.required,
        Validators.min(0.01)
      ]),
      fullPrice: new FormControl<number>(null, [
        Validators.required,
        Validators.min(0.01)
      ]),
      subscriberDiscountPercentage: new FormControl<number>(0),
      fullPriceDiscountPercentage: new FormControl<number>({
        value: 0,
        disabled: true
      }),
      subscriberPrice: new FormControl<number>({ value: null, disabled: true }),
      costPrice: new FormControl<number>({ value: 0, disabled: true })
    }),
    hasVariants: new FormControl<boolean>(false),
    NCM: new FormControl<string>(null),
    EAN: new FormControl<string>(null),
    CEST: new FormControl<string>(null),
    weight: new FormControl<number>(0, [
      Validators.required,
      Validators.min(0.01)
    ]),
    costPrice: new FormControl<number>({ value: 0, disabled: true }),
    images: new FormControl<string[]>([]),
    published: new FormControl<boolean>(false),
    brand: new FormControl<string>(null, [
      Validators.required,
      Validators.minLength(1)
    ]),
    type: new FormControl<string>(null),
    category: new FormControl<Category>(null),
    categoryTree: new FormControl<string>({
      value: 'Sem categoria',
      disabled: true
    }),
    video: new FormControl<string>(
      null,
      Validators.pattern(
        /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
      )
    ),
    gender: new FormControl<ProductGender>(null),
    metadata: new FormGroup({
      title: new FormControl<string>(null, [
        Validators.required,
        Validators.minLength(1)
      ]),
      description: new FormControl<string>(null, [
        Validators.required,
        Validators.minLength(1)
      ])
    }),
    tags: new FormControl<string[]>([]),
    filters: new FormControl<FilterOption[]>([]),
    label: new FormControl<ProductLabel>(null),
    group: new FormControl<number>(null),
    validity: new FormControl<boolean>(null)
  });
  genders = [
    { name: 'Masculino', value: 'male' },
    { name: 'Feminino', value: 'female' },
    { name: 'Unissex', value: 'unisex' }
  ];
  labels = [
    { name: 'Nenhum', value: 'null' },
    { name: 'Outlet', value: 'outlet' },
    { name: 'Promoção', value: 'on-sale' },
    { name: 'Últimas unidades', value: 'last-units' },
    { name: 'Glamstar', value: 'glamstar' }
  ];
  groups = [
    { name: 'Grupo 1', value: 1 },
    { name: 'Grupo 2', value: 2 },
    { name: 'Grupo 3', value: 3 },
    { name: 'Grupo 4', value: 4 },
    { name: 'Grupo 5', value: 5 },
    { name: 'Grupo 6', value: 6 },
    { name: 'Grupo 7', value: 7 },
    { name: 'Grupo 8 (manual)', value: 8 }
  ];
  validities = [
    { name: 'Sim', value: true },
    { name: 'Não', value: false }
  ];
  categoriesForDropdown: Array<DropdownFilter>;
  chipRegex: any = /\r|\n|;|,/;
  filterOptions: Filter[] = [];
  filterOptionsAvailable: FilterOption[] = [];

  async ngOnInit(): Promise<void> {
    await this.findFiltersOptions();
    this.categoriesForDropdown = [
      { label: 'Sem categoria', value: BaseConnectService.NULL }
    ].concat(
      this.categories.map((c) => ({
        label: `${c.reference} ${c.name} [${c.shop}]`,
        value: c
      }))
    );
    this.form.patchValue({
      ...this.product,
      price: {
        ...this.product.price,
        costPrice: this.product.costPrice
      },
      category: this.product.category?.id
        ? this.categories?.find(
            (c) => c.id === this.product.category.id.toString()
          )
        : BaseConnectService.NULL,
      filters:
        this.product.filters?.map((f) =>
          this.filterTags?.find((ft) => ft.id === f)
        ) || []
    });
    if (this.product?.category?.id) {
      await this.findCategoryTree(this.product?.category?.id);
    }
    this.recalculatePrices();

    if (!this.product.hasVariants) {
      this.form.get('group').setValidators([Validators.required]);
      this.form.get('validity').setValidators([Validators.required]);
    }
  }

  async findFiltersOptions(): Promise<void> {
    const filterOptions = await this.filterService.getFilterList();
    filterOptions.sort((f1, f2) => {
      if (
        FormUtil.semAcento(f1.description).toUpperCase() <
        FormUtil.semAcento(f2.description).toUpperCase()
      )
        return -1;
      if (
        FormUtil.semAcento(f1.description).toUpperCase() >
        FormUtil.semAcento(f2.description).toUpperCase()
      )
        return 1;
      return 0;
    });
    this.filterOptions = filterOptions;
  }

  async findCategoryTree(categoryId?: string): Promise<void> {
    try {
      let tree = 'Sem categoria';
      if (categoryId) {
        const categories = await this.categoryService.getCategoriesTree(
          categoryId
        );
        tree = categories
          .reverse()
          .map((c) => `${c.reference} ${c.name}`)
          .toString()
          .replaceAll(',', ' > ');
      }
      this.form.controls['categoryTree'].setValue(tree);
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async submit(): Promise<void> {
    if (this.form.valid) {
      try {
        LoaderService.showLoader();
        const product = FormUtil.getTouchedAndDirtyOnly<Partial<Product>>(
          this.form
        );
        if (Object.keys(product).length) {
          await this.productService.updateProduct({
            ...product,
            id: this.product.id,
            filters: this.form.value.filters?.map((f) => f.id) || []
          });
          this.productUpdated.emit();
        }
        this.messageService.add({
          severity: 'success',
          detail: 'Produto atualizado com sucesso.',
          summary: 'Sucesso'
        });
      } catch (error) {
        AppDialogService.showErrorDialog(error);
      } finally {
        LoaderService.showLoader(false);
      }
    } else {
      Object.keys(this.form.controls).forEach((k) => {
        this.form.controls[k].markAsDirty();
        this.form.controls[k].markAsTouched();
        this.form.controls[k].updateValueAndValidity();
      });
    }
  }

  recalculatePrices(): void {
    this.form.controls['price'].controls['subscriberPrice'].setValue(
      (1 - this.form.value.price.subscriberDiscountPercentage / 100) *
        (this.form.value.price.price || 0)
    );
    this.form.controls['price'].controls[
      'fullPriceDiscountPercentage'
    ].setValue(
      (100 *
        ((this.form.value.price.fullPrice || 0) -
          (this.form.getRawValue().price.subscriberPrice || 0))) /
        (this.form.value.price.fullPrice || 1)
    );
  }

  addTag(event?: ChipsAddEvent) {
    if (event?.value) {
      const index = this.form.value.tags.findIndex(
        (t) => t === slugify((event.value as string).toLowerCase())
      );
      if (index >= 0 && index < this.form.value.tags.length - 1) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Atenção',
          detail: `Tag já vinculada '${this.form.value.tags[index]}'`
        });
        this.form.controls['tags'].setValue(
          this.form.value.tags.slice(0, this.form.value.tags.length - 1)
        );
      }
    }
    this.form.controls['tags'].setValue(
      this.form.value.tags.map((t) => slugify(t.toLowerCase()))
    );
  }

  searchFilters(event: AutoCompleteCompleteEvent): void {
    this.filterOptionsAvailable =
      this.filterTags?.filter(
        (f) =>
          !this.form.value.filters?.some((cf) => cf.id === f.id) &&
          f.id.includes(slugify(FormUtil.semAcento(event.query.toLowerCase())))
      ) || [];
  }

  removeFilter(event: AutoCompleteUnselectEvent): void {
    if (event.value) {
      this.form.controls['filters'].setValue(
        this.form.value.filters.filter((f) => f !== event.value)
      );
    }
  }

  get filterTags(): FilterOption[] {
    return this.filterOptions?.reduce(
      (options, f) => (options = options.concat(f.options)),
      []
    );
  }
}

/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CouponProductChangeDetail } from './couponProductChangeDetail';
import { CouponProductVariantSubscriptionTypeRestrictionChange } from './couponProductVariantSubscriptionTypeRestrictionChange';
import { CouponSubscriptionTypeRestrictionChange } from './couponSubscriptionTypeRestrictionChange';

export interface CouponPriceChange {
  couponPriceChangeId?: number;
  couponId: number;
  value: number;
  couponType?: number;
  enabled?: boolean;
  dateChange?: Date;
  dateCreated?: Date;
  userId?: number;
  changed?: boolean;
  freebieMessage?: string;
  productVariants?: Array<CouponProductChangeDetail>;
  productVariantsSubcriptionTypeRestrictions?: Array<CouponProductVariantSubscriptionTypeRestrictionChange>;
  subscriptionTypes?: Array<CouponSubscriptionTypeRestrictionChange>;
}

<ng-container *ngIf="brand">
  <div class="page-title">Detalhes da marca</div>
  <div class="details brand-details">
    <div class="details__left">
      <div class="card card-user">
        <div class="image"></div>
        <div class="card-body">
          <div class="user">
            <img
              class="avatar"
              [src]="brand.logoImageUrl"
              *ngIf="brand.logoImageUrl"
            />
            <img
              class="avatar"
              src="assets/images/no_img_available.jpg"
              *ngIf="!brand.logoImageUrl"
            />
            <h4>{{ brand.brandName }}</h4>
            <p>
              <b>BrandEquity</b>:
              {{ brandEquityLabel }}
            </p>
            <p [innerHTML]="brand.summary"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="details__right">
      <div class="card">
        <p-tabView
          [activeIndex]="selectedTab"
          (activeIndexChange)="tabChanged($event)"
        >
          <p-tabPanel header="Informações gerais">
            <app-brand-form
              [brand]="brand"
              (updated)="updateBrand($event)"
            ></app-brand-form>
          </p-tabPanel>
          <p-tabPanel header="Produtos ({{ products?.length || 0 }})">
            <app-table
              [addButton]="false"
              [cols]="productCols"
              [lazy]="false"
              sortField="productName"
              [sortOrder]="-1"
              modelName="produtos"
              [elements]="products"
              [dropdownFilters]="dropdownFilters"
              *ngIf="products"
              [changeColumns]="true"
            >
            </app-table>
          </p-tabPanel>
          <p-tabPanel header="Variantes ({{ productVariants?.length || 0 }})">
            <app-table
              *ngIf="productVariants && variantCols"
              [elements]="productVariants"
              [cols]="variantCols"
              [addButton]="false"
              [lazy]="false"
              sortField="productVariantName"
              [sortOrder]="-1"
              modelName="variantes"
              [changeColumns]="true"
              [dropdownFilters]="dropdownFilters"
            ></app-table>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>
  </div>
</ng-container>
<div class="brand-form" *ngIf="!brand">
  <div class="page-title">Nova marca</div>
  <app-brand-form> </app-brand-form>
</div>

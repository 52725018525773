<form
  (submit)="updateInfluencerStatus()"
  [formGroup]="formGroup"
  *ngIf="influencer && influencerStatusList && formGroup"
  class="form-b4a"
>
  <span class="p-float-label">
    <p-dropdown
      [options]="influencerStatusList"
      optionValue="influencerStatusId"
      [autoDisplayFirst]="false"
      inputId="influencerStatusId"
      optionLabel="influencerStatus"
      formControlName="influencerStatusId"
      appendTo="body"
    >
      <ng-template pTemplate="item" let-status>
        <span [pTooltip]="status.description">{{
          status.influencerStatus
        }}</span>
      </ng-template>
    </p-dropdown>
    <label for="influencerStatusId">Tipo</label>
  </span>
  <span class="p-float-label">
    <input type="text" pInputText formControlName="pis" id="pis" />
    <label for="pis">PIS</label>
  </span>
  <span class="p-float-label">
    <input type="text" pInputText formControlName="rg" id="rg" />
    <label for="pis">RG</label>
  </span>
  <span class="input-switch">
    <label for="enabled">Inativo</label>
    <p-inputSwitch
      [trueValue]="1"
      [falseValue]="0"
      formControlName="enabled"
      inputId="enabled"
    ></p-inputSwitch>
    <label for="enabled">Ativo</label>
  </span>
  <span class="buttons">
    <p-button
      label="Salvar"
      [disabled]="!formGroup.valid"
      type="submit"
    ></p-button>
  </span>
</form>
<br />
<app-influencer-coupons
  (newCoupon)="newCoupon($event)"
  [coupons]="coupons"
  [influencer]="influencer"
>
</app-influencer-coupons>
<p-toast></p-toast>

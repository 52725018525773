/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Coupon {
  couponId?: number;
  name?: string;
  value?: number;
  couponType?: number;
  maxRedeems?: number;
  enabled?: boolean;
  isRenewal?: number;
  glampoints?: number;
  dateCreated?: Date;
  dateUpdated?: Date;
  dateExpired?: Date;
  freebieCount?: number;
  freebieMessage?: string;
  isMGM?: number;
  isBlogger?: number;
  isGlampartner?: number;
  influencerStatusTypeIdRestriction?: number;
  freeShipping?: number;
  couponUsageTypeId?: number;
}

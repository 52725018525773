<app-table
  [service]="subscriberService"
  [cols]="cols()"
  [addButton]="false"
  tableTitle="Estornos"
  modelName="Estornos"
  [captionTemplate]="captionTemplate"
  selectionActionLabel="Aprovar"
  selectionActionTooltip="Marcar como resolvido"
  selectionActionIcon="pi pi-check"
  (emitSelection)="approveAllRefunds($event)"
  [actionButtons]="actionButtons"
  (actionButtonClick)="callAction($event)"
  sortField="dateCreated"
  [sortOrder]="1"
  *ngIf="cols()"
  [findPage]="findPage"
  [defaultFilterValues]="defaultFilters"
>
  <ng-template #captionTemplate>
    <form class="header" [formGroup]="referenceForm" (submit)="setFilters()">
      <span class="p-float-label">
        <p-dropdown
          id="status"
          [options]="statusList"
          formControlName="status"
          (onChange)="setFilters()"
          class="input"
        >
        </p-dropdown>
        <label for="referenceDate">Status</label>
      </span>
    </form>
  </ng-template>
</app-table>
<p-confirmDialog></p-confirmDialog>
<p-toast></p-toast>

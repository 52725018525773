import { Component, Input } from '@angular/core';
import { CarouselSection } from 'src/app/connect-api/api/shop-settings/shop-settings.service';

@Component({
  selector: 'app-glam-home-banner-alert',
  templateUrl: './glam-home-banner-alert.component.html',
  styleUrl: './glam-home-banner-alert.component.scss'
})
export class GlamHomeBannerAlertComponent {
  @Input({ required: true })
  section: CarouselSection;

  get showWarning(): boolean {
    return this.section?.banners?.every((b) => !b.published || b.deleteDate);
  }

  get endDate(): Date {
    if (!this.showWarning) return null;
    const banners = [...this.section.banners.filter((b) => b.deleteDate)];
    banners.sort((b1, b2) => b1.deleteDate.getTime() - b2.deleteDate.getTime());
    let endDate: Date;
    banners.forEach((d) => {
      if (
        !endDate &&
        !this.section.banners.some(
          (b) =>
            b.id !== d.id &&
            ((b.published &&
              (!b.deleteDate ||
                b.deleteDate.getTime() > d.deleteDate.getTime())) ||
              (!b.published &&
                b.publishDate &&
                b.publishDate.getTime() <= d.deleteDate.getTime()))
          // (b.published ||
          //   (b.publishDate &&
          //     b.publishDate.getTime() <= d.deleteDate.getTime())) &&
          // (!b.deleteDate || b.deleteDate.getTime() > d.deleteDate.getTime())
        )
      ) {
        endDate = d.deleteDate;
      }
    });
    return endDate;
  }
}

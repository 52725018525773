import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TaxPriceComponent } from './tax-price/tax-price.component';

const routes: Routes = [{ path: 'tax-price', component: TaxPriceComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FinancialRoutingModule {}

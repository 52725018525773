import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { PasswordUpdateModule } from 'src/app/components/password-update/password-update.module';
import { AppTableModule } from 'src/app/components/table/app-table.module';
import { UserFormModule } from 'src/app/components/user-form/user-form.module';
import { SettingsRoutingModule } from './settings-routing.module';
import { SettingsComponent } from './settings.component';
import { PasswordUpdateModalComponent } from './system-users/password-update-modal/password-update-modal.component';
import { SystemUsersComponent } from './system-users/system-users.component';
import { UserFormModalComponent } from './system-users/user-form-modal/user-form-modal.component';
import { AccessLookerComponent } from './access-looker/access-looker.component';

@NgModule({
  declarations: [
    SettingsComponent,
    SystemUsersComponent,
    PasswordUpdateModalComponent,
    UserFormModalComponent,
    AccessLookerComponent
  ],
  imports: [
    CommonModule,
    UserFormModule,
    PasswordUpdateModule,
    AppTableModule,
    ConfirmDialogModule,
    ToastModule,
    SettingsRoutingModule
  ]
})
export class SettingsModule {}

/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SubscriberControlGroupCounterOldest {
  editionId?: number;
  subscriberGroupId?: number;
  subscriptionTypeId?: number;
  year?: number;
  month?: number;
  day?: number;
  itemCount?: number;
  itemValue?: number;
  itemTotal?: number;
}

import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  Section,
  ShopSettings,
  ShopSettingsRepository
} from '@infrab4a/connect';
import { FileUpload } from 'primeng/fileupload';
import { lastValueFrom, map } from 'rxjs';
import { FileControllerService } from 'src/app/admin-api';
import { AppDialogService } from 'src/app/services/dialog.service';
import { ImageService } from 'src/app/services/image.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-vitrine-customization',
  templateUrl: './vitrine-customization.component.html',
  styleUrls: ['./vitrine-customization.component.scss']
})
export class VitrineCustomizationComponent implements OnInit {
  form: FormGroup;
  vitrineDocument: ShopSettings;
  glamVitrineSections: Section<'GlampointsVitrineShopSection'>;

  constructor(
    private fb: FormBuilder,
    @Inject('ShopSettingsRepository')
    private shopSettingsRepository: ShopSettingsRepository,
    private imageService: ImageService,
    private fileService: FileControllerService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.initializeDocument();
    await this.initializeForm();
  }

  async initializeDocument(): Promise<void> {
    LoaderService.showLoader();

    try {
      this.vitrineDocument = await this.shopSettingsRepository.get({
        id: 'GLAM_VITRINE'
      });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
      LoaderService.showLoader(false);
      return;
    }

    this.glamVitrineSections = this.vitrineDocument.sections || [];

    LoaderService.showLoader(false);
  }

  initializeForm(): void {
    this.form = this.fb.group({
      titleMeta: [this.vitrineDocument?.titleMeta, Validators.required],
      descriptionMeta: [
        this.vitrineDocument?.descriptionMeta,
        Validators.required
      ],
      imageMeta: [this.vitrineDocument?.imageMeta, Validators.required],
      altText: [
        this.vitrineDocument?.sections[0]?.altText,
        Validators.required
      ],
      link: [this.vitrineDocument?.sections[0]?.link, Validators.required],
      mobileImage: [
        this.vitrineDocument?.sections[0]?.mobileImage,
        Validators.required
      ],
      desktopImage: [
        this.vitrineDocument?.sections[0]?.desktopImage,
        Validators.required
      ],
      autoDelete: [null, Validators.required],
      deleteDate: [null, Validators.required],
      autoPublish: [null, Validators.required],
      publishDate: [null, Validators.required]
    });
  }

  async submit(): Promise<void> {
    LoaderService.showLoader();
    this.glamVitrineSections[0] = this.formSection;

    try {
      await this.shopSettingsRepository.update({
        id: 'GLAM_VITRINE',
        titleMeta: this.form.get('titleMeta').value,
        imageMeta: this.form.get('imageMeta').value,
        descriptionMeta: this.form.get('descriptionMeta').value,
        sections: this.glamVitrineSections
      });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
      LoaderService.showLoader(false);
      return;
    }
    LoaderService.showLoader(false);
    AppDialogService.showInfoDialog({
      message: 'Collection Atualizada',
      header: 'Sucesso'
    });
  }

  get formSection(): any {
    return {
      altText: this.form.get('altText').value,
      link: this.form.get('link').value,
      mobileImage: this.form.get('mobileImage').value,
      desktopImage: this.form.get('desktopImage').value,
      autoDelete: this.form.get('autoDelete').value,
      deleteDate: this.form.get('deleteDate').value,
      autoPublish: this.form.get('autoPublish').value,
      publishDate: this.form.get('publishDate').value
    };
  }

  get imageMeta(): void {
    return this.form.get('imageMeta').value;
  }

  get desktopImage(): void {
    return this.form.get('desktopImage').value;
  }

  get mobileImage(): void {
    return this.form.get('mobileImage').value;
  }

  async fileSelected(
    event: { files: File[] },
    pInput: FileUpload,
    width: number,
    height: number,
    formControlName: string
  ): Promise<void> {
    LoaderService.showLoader();

    const file = event.files[0];
    let compressedFileUrl;
    const imageValid = await this.imageService.validateImageDimensions(
      file,
      width / height
    );

    if (!imageValid) {
      pInput.clear();
      LoaderService.showLoader(false);
      return;
    }

    const renamedFile = new File([file], new Date().getTime().toString(), {
      type: file.type
    });

    try {
      compressedFileUrl = await lastValueFrom(
        this.fileService
          .compressAndUploadFileForm(renamedFile)
          .pipe(map((data) => data.result))
      );
      pInput.clear();
    } catch (error) {
      AppDialogService.showErrorDialog(error);
      LoaderService.showLoader(false);
      pInput.clear();
      return;
    }

    this.form.get(formControlName).setValue(compressedFileUrl);
    LoaderService.showLoader(false);
    AppDialogService.showInfoDialog({
      message: 'imagem selecionada',
      header: 'Sucesso!'
    });
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { DatePipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  PaymentControllerService,
  SubscriberCancellationReason,
  SubscriberControllerService,
  SubscriberEditionCompositionSummary,
  SubscriberInfo,
  SubscriberPaymentHistory,
  SubscriptionControllerService,
  SubscriptionType
} from 'src/app/admin-api';
import {
  EditionId,
  getAllSubscriptions,
  getSubscriptionName,
  PaymentHistory
} from 'src/app/models';
import { AvailableBilling } from 'src/app/models/availableBilling';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-subscriber-cancellation-modal',
  templateUrl: './subscriber-cancellation-modal.component.html',
  styleUrls: ['./subscriber-cancellation-modal.component.scss'],
  providers: [DatePipe]
})
export class SubscriberCancellationModalComponent implements OnInit {
  private static pagarmeUrl =
    'https://beta.dashboard.sandbox.pagar.me/#/transactions/';

  private config = inject(DynamicDialogConfig);
  public ref = inject(DynamicDialogRef);
  private subscriberService = inject(SubscriberControllerService);
  private subscriptionService = inject(SubscriptionControllerService);
  private datePipe = inject(DatePipe);
  private confirmationService = inject(ConfirmationService);
  private paymentService = inject(PaymentControllerService);

  refundStatusList = ['charged_back', 'refunded', 'chargedback'];

  subscriber: SubscriberInfo;
  cancellationReasons: SubscriberCancellationReason[];
  formGroup = new FormGroup({
    subscriberId: new FormControl(
      this.config.data.subscriber?.subscriberId,
      Validators.required
    ),
    cancellationReasonId: new FormControl(undefined, Validators.required),
    cancellationReason: new FormControl(undefined, [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(4000)
    ]),
    automaticSelection: new FormControl(true)
  });
  billings: AvailableBilling[];
  availableEditions: AvailableBilling[];
  selectedEditions: AvailableBilling[] = [];
  paymentHistory: PaymentHistory[] | undefined;
  payment: any;
  compositions: SubscriberEditionCompositionSummary[] | undefined;
  subscriptionTypes: SubscriptionType[];

  async ngOnInit() {
    this.subscriber = this.config.data.subscriber;
    this.cancellationReasons = this.config.data.cancellationReasons;
    this.billings = this.config.data.billings || [];
    if (!this.subscriber)
      throw 'SubscriberCancellationModalComponent: Please provide subscriber';
    if (!this.cancellationReasons || !this.cancellationReasons.length)
      throw 'SubscriberCancellationModalComponent: Please provide cancellationReasons';
    this.paymentHistory = this.config.data.paymentHistory;
    this.compositions = this.config.data.compositions;
    this.billings = [
      ...this.billings.filter((b) =>
        this.lastPayment?.billings?.some(
          (pb) => pb.subscriberBillingId === b.subscriberBillingId
        )
      )
    ];
    this.billings?.sort(
      (b1, b2) => (b1.editionId as number) - (b2.editionId as number)
    );
    await this.findSubscriptionTypes();
    this.autoSelectEditions();
    if (this.lastPayment?.transactionId && !this.lastPayment.payerInstitution)
      this.findTID();
  }

  async findSubscriptionTypes() {
    try {
      this.subscriptionTypes = (
        await Promise.all(
          getAllSubscriptions().map((s) =>
            lastValueFrom(
              this.subscriptionService
                .findSubscriptionTypeList(s.value)
                .pipe(map((data) => data.result))
            )
          )
        )
      ).reduce((list, sTypes) => list.concat(sTypes), []);
    } catch (error) {
      AppDialogService.showErrorDialog(error, true);
    }
  }

  async findTID() {
    if (this.lastPayment?.transactionId)
      try {
        delete this.payment;
        this.payment = await lastValueFrom(
          this.paymentService
            .getTransactionByTID(this.lastPayment.transactionId)
            .pipe(map((data) => data.result))
        );
      } catch (error) {
        this.payment = null;
        console.error(error);
      }
  }

  async submit(noRefund = false, ignoreGatewayStatus = false): Promise<void> {
    // Se não selecionou billing para estornar
    if (!this.selectedEditions?.length && this.billings?.length && !noRefund) {
      this.confirmationService.confirm({
        acceptLabel: 'Sim, somente cancelar',
        rejectLabel: 'Voltar',
        acceptButtonStyleClass: 'p-button-info',
        message:
          'Deseja realizar o cancelamento sem solicitar o estorno das edições?',
        header: 'Cancelamento sem estorno',
        accept: () => this.submit(true)
      });
      return;
    }
    if (
      this.selectedEditions?.length &&
      !this.refundedTransaction &&
      !this.pendingAnnualMonthly &&
      this.lastPayment?.amount &&
      !this.pix &&
      !ignoreGatewayStatus
    ) {
      this.confirmationService.confirm({
        acceptLabel: 'Sim, farei o estorno depois',
        rejectLabel: 'Voltar',
        acceptButtonStyleClass: 'p-button-danger',
        rejectButtonStyleClass: 'p-button-info',
        header: 'Avançar sem estorno',
        message: 'Deseja remover as edições sem realizar o estorno no gateway?',
        accept: () => this.submit(false, true)
      });
      return;
    }
    if (this.formGroup.valid) {
      LoaderService.showLoader();
      try {
        const subscriber = await lastValueFrom(
          this.subscriberService
            .cancelSubscriber({
              ...this.formGroup.value,
              fee: this.fee || 0,
              refundAmount:
                !this.pendingAnnualMonthly || this.refundedTransaction
                  ? this.refundAmount || 0
                  : 0,
              chargeAmount: this.amountToCharge,
              subscriberBillingIds:
                this.selectedEditions?.map((s) => s.subscriberBillingId) || []
            })
            .pipe(map((data) => data.result))
        );
        this.ref.close(subscriber);
      } catch (error: any) {
        LoaderService.showLoader(false);
        AppDialogService.showErrorDialog(
          error,
          false,
          'Não foi possível cancelar a assinatura.'
        );
      }
    }
  }

  billingLabel(billing: AvailableBilling): string {
    const dateString = ((billing?.editionId as number) % 1000000).toString();
    return (
      (getSubscriptionName(
        Number(billing.editionId?.toString()[0])
      ) as string) +
      ' ' +
      this.datePipe.transform(
        new Date(
          `${dateString.substring(0, 4)}-${dateString.substring(4)}-01 12:00:00`
        ),
        'MMMM/yyyy',
        undefined,
        'pt-BR'
      ) +
      (billing.delivered ? ' (Entregue)' : '')
    );
  }

  autoSelectEditions() {
    if (this.lastPayment?.amount && this.formGroup.value.automaticSelection) {
      this.selectedEditions = this.billings.filter(
        (b) =>
          (!this.hasFee && !this.hasRenewalFee) ||
          EditionId.isAfter(
            b.editionId,
            EditionId.currentEdition(Number(b.editionId.toString()[0]))
          ) ||
          (this.billings.length === 1 &&
            EditionId.isAfter(
              b.editionId,
              EditionId.currentEdition(Number(b.editionId.toString()[0]))
            ))
      );
      this.availableEditions = [
        ...this.billings.filter((b) =>
          this.selectedEditions.every(
            (e) => e.subscriberBillingId !== b.subscriberBillingId
          )
        )
      ];
    } else if (!this.availableEditions) {
      this.availableEditions = [...(this.billings || [])];
    }
  }

  diffDays(time: number) {
    return time / (1000 * 60 * 60 * 24);
  }

  get gatewayUrl() {
    if (!this.lastPayment?.transactionId) return null;
    switch (this.lastPayment.gatewayId) {
      case 10:
        return SubscriberCancellationModalComponent.pagarmeUrl.concat(
          this.lastPayment.transactionId
        );
      case 11:
        return (
          environment.production &&
          SubscriberCancellationModalComponent.pagarmeUrl
            .replace('.sandbox', '')
            .concat(this.lastPayment.transactionId)
        );
      case 20:
        return (
          environment.production &&
          'https://dashboard.belvo.com/production/payments/intents/' +
            this.lastPayment.transactionId
        );
    }
    return null;
  }

  get shippingPrice() {
    return this.lastPayment?.billings?.at(0)?.shippingPrice || 0;
  }

  get monthlyAmount() {
    if (!this.lastPayment) return null;
    if (this.lastPayment.payerInstitution)
      return this.lastPayment.amount / this.subscriptionType.recurrenceMonths;
    return this.lastPayment.amount / this.lastPayment.installments;
  }

  get remainingAmount() {
    return this.hasFee || this.hasRenewalFee
      ? this.monthlyAmount * this.selectedEditions?.length
      : 0;
  }

  get fee() {
    return this.remainingAmount
      ? (this.remainingAmount -
          this.selectedEditions.length * this.shippingPrice) *
          0.4
      : 0;
  }

  get refundAmount() {
    return (this.remainingAmount || this.lastPayment?.amount || 0) - this.fee;
  }

  get hasFee() {
    if (
      this.lastPayment &&
      (!this.firstEditionInPayment || !this.restored || !this.restoredNoFee)
    ) {
      return (
        this.diffDays(
          FormUtil.utcDate(new Date()).getTime() -
            new Date(
              this.firstEditionInPayment?.dateCreated ||
                this.lastPayment.dateCreated
            ).getTime()
        ) >= 7
      );
    }
    return false;
  }

  get noFeeLimit() {
    if (this.fee || !this.lastPayment) return null;
    const limit = new Date(this.lastPayment.dateCreated);
    limit.setDate(limit.getDate() + 7);
    return limit;
  }

  get refundedTransaction() {
    return (
      this.payment?.status &&
      (this.payment.status === 'refunded' ||
        this.chargedBack ||
        this.payment.refunded_amount > 0 ||
        this.payment.transactionAmountRefunded > 0)
      // this.refundStatusList.includes()
    );
  }

  get refundedAmount() {
    return (
      (this.refundedTransaction &&
        (this.payment.refunded_amount / 100 ||
          this.payment.transactionAmountRefunded)) ||
      (this.chargedBack &&
        (this.payment.amount / 100 || this.payment.transactionAmount))
    );
  }

  get chargedBack() {
    return (
      this.payment?.status === 'charged_back' ||
      this.payment?.status === 'chargedback'
    );
  }

  get paymentStatus() {
    return this.refundedTransaction && this.payment.status === 'paid'
      ? 'refunded'
      : this.payment.status;
  }

  get restored() {
    return (
      this.firstEditionInPayment?.subscriberEditionStatusId === 11 ||
      this.firstEditionInPayment?.statusMillenium?.toUpperCase() ===
        'DEVOLVIDO' ||
      this.firstEditionInPayment?.statusMillenium?.toUpperCase() ===
        'CANCELADO' ||
      this.firstEditionInPayment?.statusMillenium?.toUpperCase() ===
        'EM DEVOLUÇÃO'
    );
  }

  get restoredNoFee() {
    return this.restored && this.firstEditionInPayment
      ? this.firstEditionInPayment?.statusMillenium?.toUpperCase() ===
          'DEVOLVIDO' ||
          this.diffDays(
            FormUtil.utcDate(
              this.firstEditionInPayment.dateUpdated ||
                this.firstEditionInPayment.dateCreated
            ).getTime() -
              FormUtil.utcDate(
                this.firstEditionInPayment.dateUpdatedMillenium ||
                  this.firstEditionInPayment.dateCreatedMillenium
              ).getTime()
          ) < 7
      : false;
  }

  get lastPayment() {
    return this.paymentHistory
      ?.filter(
        (p) =>
          p.transactionStatusId === 6 &&
          [0, 1, 2].includes(p.creditCardPaymentTypeId)
      )
      ?.reduce((last: PaymentHistory, p: PaymentHistory) => {
        if (
          !last ||
          new Date(last.dateCreated).getTime() <
            new Date(p.dateCreated).getTime()
        ) {
          last = p;
        }
        return last;
      }, null);
  }

  get firstEditionInPayment() {
    if (!this.lastPayment?.subscriptionTypeId) return null;
    const subscriptionType = this.subscriptionTypes?.find(
      (st) => st.subscriptionTypeId === this.lastPayment.subscriptionTypeId
    );
    let first = this.lastPayment.billings?.at(0)?.editionId;
    if (
      subscriptionType?.recurrenceMonths === 1 &&
      subscriptionType?.cycle > 1
    ) {
      const editions: SubscriberPaymentHistory['billings'] = this.paymentHistory
        ?.filter(
          (p) =>
            p.subscriptionTypeId === subscriptionType.subscriptionTypeId &&
            p.transactionStatusId === 6 &&
            [0, 1, 2].includes(p.creditCardPaymentTypeId) &&
            p.billings?.length
        )
        .reduce((billings, p) => (billings = billings.concat(p.billings)), []);
      editions.sort((e1, e2) => e1.editionId - e2.editionId);
      first = editions[0].editionId;
    }
    return this.compositions.find((c) => c.editionId === first);
  }

  get firstRenewal() {
    if (!this.lastPayment || !this.subscriptionTypes) return false;
    const payments = this.paymentHistory?.filter(
      (p) =>
        p.transactionStatusId === 6 &&
        [0, 1, 2].includes(p.creditCardPaymentTypeId) &&
        new Date(p.dateCreated).getTime() <
          new Date(this.lastPayment.dateCreated).getTime()
    );
    payments.sort(
      (p1, p2) =>
        new Date(p1.dateCreated).getTime() - new Date(p2.dateCreated).getTime()
    );
    const subscriptionTypes = payments.reduce(
      (plans: SubscriptionType[], p) => {
        if (
          !plans.some(
            (plan) => plan.subscriptionTypeId === p.subscriptionTypeId
          )
        ) {
          const plan = this.subscriptionTypes?.find(
            (st) => st.subscriptionTypeId === p.subscriptionTypeId
          );
          if (plan) plans.push(plan);
        }
        return plans;
      },
      []
    );
    return (
      payments.length === 1 ||
      (subscriptionTypes.length === 1 &&
        subscriptionTypes[0].recurrenceMonths === 1 &&
        subscriptionTypes[0].cycle > 1 &&
        (payments.length === subscriptionTypes[0].cycle ||
          this.diffDays(
            FormUtil.utcDate(new Date()).getTime() -
              new Date(payments[0].dateCreated).getTime()
          ) < 7))
    );
  }

  get hasRenewalFee() {
    return (
      !this.firstRenewal &&
      this.lastPayment &&
      [1, 2].includes(this.lastPayment.creditCardPaymentTypeId)
    );
  }

  get montlySubscriptionType() {
    return this.lastPayment?.billings?.length
      ? this.subscriptionTypes?.find(
          (st) =>
            st.subscriptionId.toString() ===
              this.lastPayment?.billings?.at(0).editionId.toString()[0] &&
            st.installments === 1 &&
            st.cycle === 1
        )
      : null;
  }

  get subscriptionType() {
    return this.subscriptionTypes?.find(
      (st) => st.subscriptionTypeId === this.subscriber?.subscriptionTypeId
    );
  }

  get pendingAnnualMonthly() {
    return this.subscriptionType?.cycle > 1 &&
      this.subscriptionType?.recurrenceMonths === 1
      ? FormUtil.diff_months(
          this.currentEditionBillings?.length
            ? new Date(
                new Date(new Date().setDate(1)).setMonth(
                  new Date().getMonth() + 1
                )
              )
            : new Date(),
          FormUtil.utcDate(this.subscriber.nextCycleDate)
        )
      : 0;
  }

  get currentEditionBillings() {
    return this.paymentHistory
      ?.filter((h) =>
        h.billings.some(
          (b) =>
            b.subscriberBillingStatus === 1 &&
            b.editionId ===
              EditionId.currentEdition(Number(b.editionId.toString()[0]))
        )
      )
      ?.map((p) =>
        p.billings.filter(
          (b) =>
            b.subscriberBillingStatus === 1 &&
            b.editionId ===
              EditionId.currentEdition(Number(b.editionId.toString()[0]))
        )
      )
      ?.reduce((list, b) => list.concat(b), []);
  }

  get amountToCharge(): number {
    return (
      this.pendingAnnualMonthly *
      ((this.lastPayment?.amount || 0) - (this.shippingPrice || 0)) *
      0.4
    );
  }

  get pix() {
    return !!this.lastPayment?.payerInstitution;
  }
}

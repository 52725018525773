import { CurrencyPipe, DatePipe, TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  Edition,
  EditionControllerService,
  KpiControllerService,
  SubscriberControlGroupBySubscriptionType,
  SubscriberControlGroupDetail,
  SubscriptionControllerService,
  SubscriptionType
} from 'src/app/admin-api';
import { SubscriberGroupCounterChartDialogComponent } from 'src/app/components/subscriber-group-counter-chart/subscriber-group-counter-chart-dialog/subscriber-group-counter-chart-dialog.component';
import {
  EditionId,
  SubscriberGroupEnum,
  SubscriberSummary,
  getAllSubscriptions,
  getAllSubscriptionsIncludeAll,
  getSubscriptionName
} from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FileUtil } from 'src/app/utils/file.util';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-income-dashboard',
  templateUrl: './income-dashboard.component.html',
  styleUrls: ['./income-dashboard.component.scss']
})
export class IncomeDashboardComponent implements OnInit {
  form = new FormGroup({
    subscriptionId: new FormControl<number>(0, [
      Validators.required,
      Validators.min(0)
    ]),
    editionId: new FormControl<number>(
      Number(
        `${new Date().getFullYear()}${(new Date().getMonth() + 1)
          .toString()
          .padStart(2, '0')}`
      ),
      [Validators.required, Validators.min(1)]
    )
  });
  subscriptions: Array<{ label: string; value: number }>;
  editions: Array<Edition> | undefined;
  subscriberGroupCountersByGroup:
    | Array<SubscriberControlGroupDetail>
    | undefined;
  lastSubscriberGroupCountersBygroup:
    | Array<SubscriberControlGroupDetail>
    | undefined;
  subscriberGroupCountersBySubscriptionTypeId:
    | Array<SubscriberControlGroupBySubscriptionType>
    | undefined;
  lastSubscriberGroupCountersBySubscriptionTypeId:
    | Array<SubscriberControlGroupBySubscriptionType>
    | undefined;
  colors: Array<string> = [
    '#5da5da',
    '#faa43a',
    '#60bd68',
    '#f17cb0',
    '#4d4d4d'
  ];
  chartConfig = {
    options: {
      animation: {
        easing: 'easeInQuad'
      }
    }
  };
  totalsList: Array<SubscriberControlGroupDetail> | undefined;
  conquistadasTable: Array<SubscriberControlGroupDetail> | undefined;
  conquistadasSubscriptionTable:
    | Array<SubscriberControlGroupBySubscriptionType>
    | undefined;
  mantidasTable: Array<SubscriberControlGroupDetail> | undefined;
  mantidasSubscriptionTable:
    | Array<SubscriberControlGroupBySubscriptionType>
    | undefined;
  avulsasTable: Array<SubscriberControlGroupDetail> | undefined;
  avulsasSubscriptionTable:
    | Array<SubscriberControlGroupBySubscriptionType>
    | undefined;
  subscriptionTypes: Array<SubscriptionType> | undefined;

  constructor(
    private kpiService: KpiControllerService,
    private editionService: EditionControllerService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private title: Title,
    private dialog: DialogService,
    private datePipe: DatePipe,
    private titlePipe: TitleCasePipe,
    private subscriptionService: SubscriptionControllerService,
    private currencyPipe: CurrencyPipe
  ) {
    this.subscriptions = getAllSubscriptionsIncludeAll();
  }

  async ngOnInit(): Promise<void> {
    this.activatedRoute.params.subscribe(async (params) => {
      if (params['editionId']) {
        this.form.patchValue({
          editionId: Number(params['editionId']),
          subscriptionId: Number((params['editionId'] / 1000000).toFixed(0))
        });
        LoaderService.showLoader();
        await this.findPage();
        await this.findEditions();
        this.title.setTitle('Dashboard Receita - ' + this.edition?.theme);
        LoaderService.showLoader(false);
      } else {
        this.navigate();
      }
    });
  }

  async findPage(): Promise<void> {
    await Promise.all([
      this.findSubscriberGroupCountersByGroup(),
      this.findLastSubscriberGroupCountersByGroup(),
      this.findSubscriberGroupCountersBySubscriptionTypeId(),
      this.findLastSubscriberGroupCountersBySubscriptionTypeId(),
      this.findSubscriptionTypes()
    ]);
    this.conquistadasTable =
      this.subscriberGroupCountersByGroup?.filter(
        (s) => s.parentId === SubscriberGroupEnum.Conquistadas
      ) || [];
    this.mantidasTable =
      this.subscriberGroupCountersByGroup?.filter(
        (s) => s.parentId === SubscriberGroupEnum.Mantidas
      ) || [];
    this.avulsasTable =
      this.subscriberGroupCountersByGroup?.filter(
        (s) => s.subscriberGroupId === SubscriberGroupEnum.Edicao_Avulsa
      ) || [];
    this.conquistadasSubscriptionTable =
      this.subscriberGroupCountersBySubscriptionTypeId
        ?.filter((s) => s.parentId === SubscriberGroupEnum.Conquistadas)
        ?.reduce(
          (list: Array<SubscriberControlGroupBySubscriptionType>, sub) => {
            const exists = list.find(
              (s) => s.subscriptionTypeId === sub.subscriptionTypeId
            );
            if (exists) {
              exists.subscriberCount =
                (exists.subscriberCount || 0) + (sub.subscriberCount || 0);
              exists.installmentTotal =
                (exists.installmentTotal || 0) + (sub.installmentTotal || 0);
              exists.installmentAvg =
                exists.installmentTotal / (exists.subscriberCount || 1);
            } else {
              if (!sub.subscriptionName) {
                sub.subscriptionName = sub.name;
              }
              if (sub.subscriptionTypeName?.includes('Anual.')) {
                sub.subscriptionTypeName = sub.subscriptionTypeName.replace(
                  'Anual.',
                  'Anual/mês'
                );
              }
              sub.subscriptionName = this.titlePipe.transform(
                sub.subscriptionName
              ) as string;
              list.push(sub);
            }
            return list;
          },
          []
        ) || [];
    this.mantidasSubscriptionTable =
      this.subscriberGroupCountersBySubscriptionTypeId
        ?.filter((s) => s.parentId === SubscriberGroupEnum.Mantidas)
        ?.reduce(
          (list: Array<SubscriberControlGroupBySubscriptionType>, sub) => {
            const exists = list.find(
              (s) => s.subscriptionTypeId === sub.subscriptionTypeId
            );
            if (exists) {
              exists.subscriberCount =
                (exists.subscriberCount || 0) + (sub.subscriberCount || 0);
              exists.installmentTotal =
                (exists.installmentTotal || 0) + (sub.installmentTotal || 0);
              exists.installmentAvg =
                exists.installmentTotal / (exists.subscriberCount || 1);
            } else {
              if (!sub.subscriptionName) {
                sub.subscriptionName = sub.name;
              }
              if (sub.subscriptionTypeName?.includes('Anual.')) {
                sub.subscriptionTypeName = sub.subscriptionTypeName.replace(
                  'Anual.',
                  'Anual/mês'
                );
              }
              sub.subscriptionName = this.titlePipe.transform(
                sub.subscriptionName
              ) as string;
              list.push(sub);
            }
            return list;
          },
          []
        ) || [];
    this.avulsasSubscriptionTable =
      this.subscriberGroupCountersBySubscriptionTypeId
        ?.filter(
          (s) => s.subscriberGroupId === SubscriberGroupEnum.Edicao_Avulsa
        )
        ?.reduce(
          (list: Array<SubscriberControlGroupBySubscriptionType>, sub) => {
            const exists = list.find((s) => s.editionId === sub.editionId);
            if (exists) {
              exists.subscriberCount =
                (exists.subscriberCount || 0) + (sub.subscriberCount || 0);
              exists.installmentTotal =
                (exists.installmentTotal || 0) + (sub.installmentTotal || 0);
              exists.installmentAvg =
                exists.installmentTotal / (exists.subscriberCount || 1);
            } else {
              list.push({
                subscriberCount: sub.subscriberCount,
                installmentAvg: sub.installmentAvg,
                installmentTotal: sub.installmentTotal,
                subscriptionName: getSubscriptionName(
                  Number(sub.editionId.toString()[0])
                ),
                subscriberGroupId: SubscriberGroupEnum.Edicao_Avulsa,
                editionId: sub.editionId
              });
            }
            return list;
          },
          []
        ) || [];
    this.conquistadasTable.sort(
      (c1, c2) =>
        (c1.subscriberGroupId as number) - (c2.subscriberGroupId as number)
    );
    this.conquistadasSubscriptionTable.sort(
      (c1, c2) =>
        (c1.subscriptionTypeId as number) - (c2.subscriptionTypeId as number)
    );
    this.mantidasTable.sort(
      (c1, c2) =>
        (c1.subscriberGroupId as number) - (c2.subscriberGroupId as number)
    );
    this.mantidasSubscriptionTable.sort(
      (c1, c2) =>
        (c1.subscriptionTypeId as number) - (c2.subscriptionTypeId as number)
    );
    this.avulsasTable.sort(
      (c1, c2) =>
        (c1.subscriberGroupId as number) - (c2.subscriberGroupId as number)
    );
    this.avulsasSubscriptionTable.sort(
      (c1, c2) =>
        (c1.subscriptionTypeId as number) - (c2.subscriptionTypeId as number)
    );
  }

  async findEditions(): Promise<void> {
    try {
      this.editions = await lastValueFrom(
        this.editionService
          .findEditionsBySubscriptionId(this.form.value.subscriptionId || 1)
          .pipe(map((data) => data.result))
      );
      if (this.form.value.subscriptionId === 0) {
        this.editions?.forEach(
          (e) => (e.editionId = (e.editionId || 0) % 1000000)
        );
      }
    } catch (error: any) {
      this.editions = [];
    }
  }

  async changeSubscription(): Promise<void> {
    LoaderService.showLoader();
    const form = { ...this.form.value };
    this.form.controls['editionId'].reset(
      (form.subscriptionId || 0) * 1000000 + ((form.editionId || 0) % 1000000)
    );
    this.navigate();
    LoaderService.showLoader(false);
  }

  async changeEdition(): Promise<void> {
    LoaderService.showLoader();
    this.navigate();
    LoaderService.showLoader(false);
  }

  editionDate(editionId: number): string {
    return FormUtil.editionDate(editionId);
  }

  editionDateName(editionId: number): string {
    return (
      this.datePipe.transform(
        new Date(
          `${((editionId % 1000000) / 100).toFixed(0)}-${editionId % 100}-02`
        ),
        'MMMM/yyyy'
      ) || this.editionDate(editionId)
    );
  }

  lastMonthDifference(current: number, last: number): number {
    return Number((current - last).toFixed(2));
  }

  lastMonthPercentage(current: number, last: number): number {
    return Number(
      (
        Number(this.lastMonthDifference(current, last) / (last || 1)) * 100
      ).toFixed(1)
    );
  }

  async findSubscriberGroupCountersByGroup(): Promise<void> {
    try {
      this.subscriberGroupCountersByGroup = await lastValueFrom(
        this.kpiService
          .findIncomeDashboardByEditionIdGroupBySubscriberGroupId(
            this.form.value.editionId as number
          )
          .pipe(map((data) => data.result))
      );
    } catch (error: any) {
      this.subscriberGroupCountersByGroup = [];
    }
  }

  async findLastSubscriberGroupCountersByGroup(): Promise<void> {
    try {
      this.lastSubscriberGroupCountersBygroup = await lastValueFrom(
        this.kpiService
          .findIncomeDashboardByEditionIdGroupBySubscriberGroupId(
            EditionId.incrementEdition(this.form.value.editionId as number, -1)
          )
          .pipe(map((data) => data.result))
      );
      this.lastSubscriberGroupCountersBygroup?.sort(
        (s1, s2) =>
          (s1.subscriberGroupId as number) - (s2.subscriberGroupId as number)
      );
    } catch (error: any) {
      this.lastSubscriberGroupCountersBygroup = [];
    }
  }

  async findSubscriberGroupCountersBySubscriptionTypeId(): Promise<void> {
    try {
      this.subscriberGroupCountersBySubscriptionTypeId = await lastValueFrom(
        this.kpiService
          .findIncomeDashboardByEditionIdGroupBySubscriptionType(
            this.form.value.editionId as number
          )
          .pipe(map((data) => data.result))
      );
    } catch (error: any) {
      this.subscriberGroupCountersBySubscriptionTypeId = [];
    }
  }

  async findLastSubscriberGroupCountersBySubscriptionTypeId(): Promise<void> {
    try {
      this.lastSubscriberGroupCountersBySubscriptionTypeId =
        await lastValueFrom(
          this.kpiService
            .findIncomeDashboardByEditionIdGroupBySubscriptionType(
              EditionId.incrementEdition(
                this.form.value.editionId as number,
                -1
              )
            )
            .pipe(map((data) => data.result))
        );
    } catch (error: any) {
      this.lastSubscriberGroupCountersBySubscriptionTypeId = [];
    }
  }

  async findSubscriptionTypes(): Promise<void> {
    try {
      if (this.form.value.subscriptionId)
        this.subscriptionTypes = await lastValueFrom(
          this.subscriptionService
            .findSubscriptionTypeList(this.form.value.subscriptionId)
            .pipe(map((data) => data.result))
        );
      else {
        const subscriptions = await Promise.all(
          getAllSubscriptions().map((s) =>
            lastValueFrom(
              this.subscriptionService
                .findSubscriptionTypeList(s.value)
                .pipe(map((data) => data.result))
            )
          )
        );
        this.subscriptionTypes = subscriptions.reduce(
          (subs: Array<SubscriptionType>, s) => (subs = subs.concat(s || [])),
          []
        );
      }
    } catch (error: any) {
      this.subscriptionTypes = [];
    }
  }

  async showChart(
    group: SubscriberControlGroupDetail,
    graphType: 'daily' | 'monthly' = 'daily'
  ): Promise<void> {
    LoaderService.showLoader();
    try {
      const data = await lastValueFrom(
        this.kpiService
          .findSubscriberDailyReport(
            group.editionId as number,
            group.subscriberGroupId as number
          )
          .pipe(map((data) => data.result))
      );
      LoaderService.showLoader(false);
      if (data) {
        this.dialog.open(SubscriberGroupCounterChartDialogComponent, {
          header: group.name,
          width: '80%',
          maximizable: true,
          height: '90vh',
          data: {
            subscriberGroupCounters: data,
            graphType,
            subscriptionTypes: this.subscriptionTypes
          }
        });
      }
    } catch (error: any) {
      LoaderService.showLoader(false);
      AppDialogService.showErrorDialog(error);
    }
  }

  navigate(): void {
    this.router.navigate(['/dashboard/income/' + this.form.value.editionId]);
  }

  subscriberGroupName(subcriberGroupId: number): string {
    return SubscriberGroupEnum[subcriberGroupId].replaceAll('_', ' ');
  }

  subscriberGroupByGroupLastMonth(
    subscriberGroupId?: number
  ): SubscriberControlGroupDetail | undefined {
    return this.lastSubscriberGroupCountersBygroup?.find(
      (s) => s.subscriberGroupId === subscriberGroupId
    );
  }

  async findSubscribersAndExport(
    group: SubscriberControlGroupDetail
  ): Promise<void> {
    LoaderService.showLoader();
    try {
      const data = await lastValueFrom(
        this.kpiService
          .findSubscriberByEditionIdAndSubscriberGroupId(
            group.editionId as number,
            group.subscriberGroupId as number
          )
          .pipe(
            map((data) =>
              data.result?.map(
                (c) =>
                  ({
                    BoxId: c.boxId,
                    'Tipo de assinante': c.personType,
                    Nome: c.name,
                    Telefone: c.phone,
                    CPF: FormUtil.cpfFormatted(c.cpf as number),
                    'E-mail': c.email,
                    Assinatura: this.titlePipe.transform(c.subscriptionName),
                    Plano: c.subscriptionTypeName,
                    Joia: c.badge,
                    Status: c.subscriberStatus,
                    Edição: c.theme,
                    Produto: this.titlePipe.transform(c.subscriberEditionName),
                    Cupom: c.couponName,
                    Composição: c.compositionName,
                    'Status edição': c.subscriberEditionStatusName,
                    'Status Millennium': c.statusMillennium,
                    Total: this.currencyPipe.transform(c.amount, 'BRL'),
                    'Data Pagamento': this.datePipe.transform(
                      FormUtil.utcDate(c.dateRenewed),
                      'dd/MM/yyyy HH:mm:ss'
                    )
                  } as SubscriberSummary)
              )
            )
          )
      );
      if (data) {
        FileUtil.exportExcel(
          data,
          `subscribers_${group.editionId}_${FormUtil.semAcento(
            group.name as string
          )}`,
          Object.keys(data[0])
        );
        LoaderService.showLoader(false);
      }
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  get edition(): Edition | undefined {
    return this.editions?.find(
      (e) => e.editionId === this.form.value.editionId
    );
  }

  get conquistadas(): SubscriberControlGroupDetail | undefined {
    return this.subscriberGroupCountersByGroup?.reduce(
      (sum: SubscriberControlGroupDetail, s) => {
        if (s.parentId === SubscriberGroupEnum.Conquistadas) {
          sum.subscriberCount =
            (sum.subscriberCount || 0) + (s.subscriberCount || 0);
          sum.installmentTotal =
            (sum.installmentTotal || 0) + (s.installmentTotal || 0);
          sum.installmentAvg =
            sum.installmentTotal / (sum.subscriberCount || 1);
        }
        return sum;
      },
      {
        editionId: this.form.value.editionId as number,
        installmentTotal: 0,
        installmentAvg: 0,
        subscriberCount: 0,
        parentId: 0,
        name: 'Conquistadas',
        subscriberGroupId: SubscriberGroupEnum.Conquistadas
      }
    );
  }

  get conquistadasLastMonth(): SubscriberControlGroupDetail | undefined {
    return this.lastSubscriberGroupCountersBygroup?.reduce(
      (sum: SubscriberControlGroupDetail, s) => {
        if (s.parentId === SubscriberGroupEnum.Conquistadas) {
          sum.subscriberCount =
            (sum.subscriberCount || 0) + (s.subscriberCount || 0);
          sum.installmentTotal =
            (sum.installmentTotal || 0) + (s.installmentTotal || 0);
          sum.installmentAvg =
            sum.installmentTotal / (sum.subscriberCount || 1);
        }
        return sum;
      },
      {
        editionId: this.form.value.editionId as number,
        installmentTotal: 0,
        installmentAvg: 0,
        subscriberCount: 0,
        parentId: 0,
        name: 'Conquistadas',
        subscriberGroupId: SubscriberGroupEnum.Conquistadas
      }
    );
  }

  get mantidas(): SubscriberControlGroupDetail | undefined {
    return this.subscriberGroupCountersByGroup?.reduce(
      (sum: SubscriberControlGroupDetail, s) => {
        if (s.parentId === SubscriberGroupEnum.Mantidas) {
          sum.subscriberCount =
            (sum.subscriberCount || 0) + (s.subscriberCount || 0);
          sum.installmentTotal =
            (sum.installmentTotal || 0) + (s.installmentTotal || 0);
          sum.installmentAvg =
            sum.installmentTotal / (sum.subscriberCount || 1);
        }
        return sum;
      },
      {
        editionId: this.form.value.editionId as number,
        installmentTotal: 0,
        installmentAvg: 0,
        subscriberCount: 0,
        parentId: 0,
        name: 'Mantidas',
        subscriberGroupId: SubscriberGroupEnum.Mantidas
      }
    );
  }

  get mantidasLastMonth(): SubscriberControlGroupDetail | undefined {
    return this.lastSubscriberGroupCountersBygroup?.reduce(
      (sum: SubscriberControlGroupDetail, s) => {
        if (s.parentId === SubscriberGroupEnum.Mantidas) {
          sum.subscriberCount =
            (sum.subscriberCount || 0) + (s.subscriberCount || 0);
          sum.installmentTotal =
            (sum.installmentTotal || 0) + (s.installmentTotal || 0);
          sum.installmentAvg =
            sum.installmentTotal / (sum.subscriberCount || 1);
        }
        return sum;
      },
      {
        editionId: this.form.value.editionId as number,
        installmentTotal: 0,
        installmentAvg: 0,
        subscriberCount: 0,
        parentId: 0,
        name: 'Mantidas',
        subscriberGroupId: SubscriberGroupEnum.Mantidas
      }
    );
  }

  get avulsas(): SubscriberControlGroupDetail | undefined {
    return this.subscriberGroupCountersByGroup?.reduce(
      (sum: SubscriberControlGroupDetail, s) => {
        if (s.subscriberGroupId === SubscriberGroupEnum.Edicao_Avulsa) {
          sum.subscriberCount =
            (sum.subscriberCount || 0) + (s.subscriberCount || 0);
          sum.installmentTotal =
            (sum.installmentTotal || 0) + (s.installmentTotal || 0);
          sum.installmentAvg =
            sum.installmentTotal / (sum.subscriberCount || 1);
        }
        return sum;
      },
      {
        editionId: this.form.value.editionId as number,
        installmentTotal: 0,
        installmentAvg: 0,
        subscriberCount: 0,
        parentId: 0,
        name: 'Mantidas',
        subscriberGroupId: SubscriberGroupEnum.Mantidas
      }
    );
  }

  get avulsasLastMonth(): SubscriberControlGroupDetail | undefined {
    return this.lastSubscriberGroupCountersBygroup?.reduce(
      (sum: SubscriberControlGroupDetail, s) => {
        if (s.subscriberGroupId === SubscriberGroupEnum.Edicao_Avulsa) {
          sum.subscriberCount =
            (sum.subscriberCount || 0) + (s.subscriberCount || 0);
          sum.installmentTotal =
            (sum.installmentTotal || 0) + (s.installmentTotal || 0);
          sum.installmentAvg =
            sum.installmentTotal / (sum.subscriberCount || 1);
        }
        return sum;
      },
      {
        editionId: this.form.value.editionId as number,
        installmentTotal: 0,
        installmentAvg: 0,
        subscriberCount: 0,
        parentId: 0,
        name: 'Mantidas',
        subscriberGroupId: SubscriberGroupEnum.Mantidas
      }
    );
  }

  get pending(): SubscriberControlGroupDetail | undefined {
    return this.subscriberGroupCountersByGroup?.find(
      (g) => g.subscriberGroupId === SubscriberGroupEnum.Pendentes
    );
  }

  get totalIncome(): number {
    return (
      (this.conquistadas?.installmentTotal || 0) +
      (this.mantidas?.installmentTotal || 0) +
      (this.avulsas?.installmentTotal || 0)
    );
  }

  get totalIncomeLastMonth(): number {
    return (
      (this.conquistadasLastMonth?.installmentTotal || 0) +
      (this.mantidasLastMonth?.installmentTotal || 0) +
      (this.avulsasLastMonth?.installmentTotal || 0)
    );
  }

  get totalSubscribers(): number {
    return (
      (this.conquistadas?.subscriberCount || 0) +
      (this.mantidas?.subscriberCount || 0)
    );
  }

  get totalSubscribersLastMonth(): number {
    return (
      (this.conquistadasLastMonth?.subscriberCount || 0) +
      (this.mantidasLastMonth?.subscriberCount || 0)
    );
  }

  get editionImage(): string {
    return FormUtil.editionImage(this.edition?.editionId || 0);
  }
}

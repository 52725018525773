<app-table
  [findPage]="findPage"
  [service]="customQueryService"
  [cols]="cols"
  sortField="customQueryId"
  [sortOrder]="1"
  [defaultRows]="10"
  modelName="queries"
  tableTitle="Consultas personalizadas"
  [actionButtons]="actionButtons"
  (actionButtonClick)="export($event.action, $event.item)"
  addLabel="Nova consulta"
  addButtonRouterLink="form"
  [dropdownFilters]="dropdownFilters"
  (booleanAction)="updateQuery($event)"
  [defaultFilterValues]="defaultFilterValues"
  *ngIf="customQueryGroups"
></app-table>
<p-toast></p-toast>

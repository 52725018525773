<form class="form-b4a" (submit)="submit()" *ngIf="form" [formGroup]="form">
  <span class="p-float-label">
    <input
      type="text"
      pInputText
      name="formTitle"
      formControlName="formTitle"
    />
    <label for="formTitle">Título *</label>
    <small
      class="danger"
      *ngIf="
        form.controls['formTitle'].touched &&
        form.controls['formTitle'].dirty &&
        form.controls['formTitle'].errors?.maxlength
      "
    >
      * Máximo de
      {{ form.controls['formTitle'].errors.maxlength?.requiredLength }}
      caracteres
    </small>
  </span>
  <span class="p-float-label">
    <p-inputNumber formControlName="glampoints" [min]="0"></p-inputNumber>
    <label for="glampoints">Glampoints</label>
    <small
      class="danger"
      *ngIf="
        form.controls['glampoints'].touched &&
        form.controls['glampoints'].dirty &&
        form.controls['glampoints'].errors?.min
      "
    >
      * No mínimo {{ form.controls['glampoints'].errors?.min.min }} glampoint(s)
    </small>
  </span>
  <div class="file-upload" *ngIf="!model">
    <span class="p-float-label">
      <input
        type="text"
        pInputText
        formControlName="fileName"
        [disabled]="true"
      />
      <label for="questions">Importar perguntas</label>
    </span>
    <p-fileUpload
      mode="basic"
      chooseLabel="Selecionar"
      name="file"
      [auto]="true"
      accept=".xlsx"
      [maxFileSize]="1000000"
      uploadIcon="pi pi-file"
      [customUpload]="true"
      (uploadHandler)="setFile($event, fileUpload)"
      id="questions"
      #fileUpload
    ></p-fileUpload>
    <a
      class="p-button p-button-secondary"
      href="assets/documents/FeedbackForm Modelo V2.xlsx"
      download="assets/documents/FeedbackForm Modelo V2.xlsx"
    >
      <i class="pi pi-download"></i>
      Baixar modelo
    </a>
  </div>
  <span class="input-switch">
    <label for="enabled">Inativo</label>
    <p-inputSwitch
      formControlName="enabled"
      inputId="enabled"
      [trueValue]="1"
      [falseValue]="0"
    ></p-inputSwitch>
    <label for="enabled">Ativo</label>
  </span>
  <span class="p-input-checkbox">
    <p-checkbox
      formControlName="isPublic"
      [binary]="true"
      inputId="isPublic"
      [trueValue]="1"
      [falseValue]="0"
    ></p-checkbox>
    <label for="isPublic">Público</label>
  </span>
  <div class="buttons">
    <p-button
      label="Salvar"
      *ngxPermissionsOnly="permissionsAccepted"
      type="submit"
      [disabled]="!form.valid"
    ></p-button>
  </div>
</form>
<p-toast></p-toast>

<form class="modal-form">
  <span class="p-float-label" *ngIf="recurrenceUpdate?.trim().length">
    <textarea
      id="recurrenceUpdate"
      rows="5"
      cols="80"
      pInputTextarea
      [(ngModel)]="recurrenceUpdate"
      (click)="copyToClipboard(recurrenceUpdate)"
      name="recurrenceUpdate"
      readonly
    ></textarea>
    <label for="recurrenceUpdate">Update do recurrence</label>
  </span>
  <div class="buttons">
    <p-button
      type="button"
      label="Sinalizar como resolvido"
      icon="pi pi-check"
      (onClick)="markAsResolved()"
    ></p-button>
  </div>
</form>
<p-toast></p-toast>

<form class="modal-form" [formGroup]="form" (submit)="submit()">
  <span class="p-float-label">
    <p-inputMask
      id="cpf"
      (onBlur)="(form.controls['cpf'].valid)"
      formControlName="cpf"
      mask="999.999.999-99"
      [unmask]="true"
    />
    <label for="cpf">CPF *</label>
  </span>
  <span class="p-float-label">
    <textarea
      id="reason"
      rows="5"
      cols="80"
      pInputTextarea
      formControlName="reason"
      name="reason"
      required
    ></textarea>
    <label for="reason">Motivo *</label>
    <small
      [class.danger]="
        form.controls['reason'].touched &&
        form.controls['reason'].dirty &&
        !form.controls['reason'].valid
      "
    >
      {{ form.value.reason?.length || 0 }}/250 caracteres
    </small>
  </span>
  <p-button
    label="Enviar"
    type="submit"
    [disabled]="!form.valid"
    severity="primary"
    [rounded]="true"
  />
</form>

import { DatePipe } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import {
  ConfirmationService,
  Message,
  MessageService,
  PrimeIcons
} from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  CompositionControllerService,
  CompositionDetails,
  CompositionSubscriberEditionStatusCounter,
  Edition,
  EditionControllerService
} from 'src/app/admin-api';
import {
  DropdownFilter,
  TableActionButton,
  TableColumn
} from 'src/app/components/table';
import { TableModalComponent } from 'src/app/components/table-modal/table-modal.component';
import {
  CompositionDTO,
  CompositionStatusCounter,
  Role,
  getAllSubscriptionsIncludeAll,
  roleAsObject
} from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';
import { PendingPreparationComponent } from './pending-preparation/pending-preparation.component';

@Component({
  selector: 'app-editions-dashboard',
  templateUrl: './editions-dashboard.component.html',
  styleUrl: './editions-dashboard.component.scss',
  providers: [DatePipe, ConfirmationService, MessageService, DialogService]
})
export class EditionsDashboardComponent implements OnInit {
  private compositionService: CompositionControllerService = inject(
    CompositionControllerService
  );
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private router: Router = inject(Router);
  private title: Title = inject(Title);
  private editionService: EditionControllerService = inject(
    EditionControllerService
  );
  private datePipe: DatePipe = inject(DatePipe);
  private confirmationService: ConfirmationService =
    inject(ConfirmationService);
  private messageService: MessageService = inject(MessageService);
  private dialogService: DialogService = inject(DialogService);
  private ngxPermissionService: NgxPermissionsService = inject(
    NgxPermissionsService
  );

  form = new FormGroup({
    subscriptionId: new FormControl<number>(1, [
      Validators.required,
      Validators.min(0)
    ]),
    editionId: new FormControl<number>(
      Number(
        `${new Date().getFullYear()}${(new Date().getMonth() + 1)
          .toString()
          .padStart(2, '0')}`
      ),
      [Validators.required, Validators.min(1)]
    )
  });
  subscriptions = getAllSubscriptionsIncludeAll().filter((s) => s.value !== 6);
  editions: Array<Edition> | undefined;
  compositionCounters:
    | Array<CompositionSubscriberEditionStatusCounter>
    | undefined;
  subscriberEditionStatusList: Array<CompositionStatusCounter> | undefined;
  compositions: Array<CompositionDTO> | undefined;
  cols: Array<TableColumn> | undefined;
  ready = false;
  actionButtons: Array<TableActionButton> = [
    new TableActionButton(
      '',
      '',
      PrimeIcons.PLAY,
      // *() => true
      (item: CompositionDTO) => item.pending > 0,
      'p-button-success',
      'Preparar composição',
      'bottom'
    )
  ];
  permission = false;
  pendingPreparation: CompositionDetails[];

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(async (params) => {
      LoaderService.showLoader();
      this.permission = await this.ngxPermissionService.hasPermission([
        roleAsObject(Role.Full_Administrator).enumValue,
        roleAsObject(Role.Logistics).enumValue
      ]);
      if (params['editionId']) {
        this.ready = false;
        this.form.patchValue({
          editionId: Number(params['editionId']),
          subscriptionId: Number((params['editionId'] / 1000000).toFixed(0))
        });
        const fail = document.getElementById('img-fail');
        if (fail?.style?.display === 'none') {
          fail.style.display = 'block';
        }
        await Promise.all([this.findEditions(), this.findPendingPreparation()]);
        await this.findPage();
        this.title.setTitle('Dashboard de Edições - ' + this.edition?.theme);
        LoaderService.showLoader(false);
      } else {
        this.navigate();
      }
    });
  }

  navigate(): void {
    this.router.navigate(['/operations/editions/' + this.form.value.editionId]);
  }

  async findPendingPreparation(): Promise<void> {
    try {
      this.pendingPreparation = await lastValueFrom(
        this.compositionService
          .findPendingPreparation()
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findPage(): Promise<void> {
    this.compositionCounters = await lastValueFrom(
      this.compositionService
        .findCompositionsDashboard(this.form.value.editionId)
        .pipe(map((data) => data.result))
    );
    this.subscriberEditionStatusList = this.compositionCounters.reduce(
      (list: Array<CompositionStatusCounter>, comp) => {
        if (comp.subscriberEditionStatusId) {
          let exists = list.find(
            (s) => s.id === comp.subscriberEditionStatusId
          );
          if (!exists && comp.subscriberEditionStatusId) {
            exists = {
              id: comp.subscriberEditionStatusId,
              status: comp.subscriberEditionStatusName,
              total: comp.subscribers,
              pending: comp.pending,
              statusList: [
                {
                  status: comp.statusMillennium,
                  total: comp.subscribers
                }
              ]
            };
            list.push(exists);
          } else {
            exists.total += comp.subscribers;
            const existsMillennium = exists.statusList.find(
              (sm) =>
                sm.status.toLowerCase() === comp.statusMillennium.toLowerCase()
            );
            if (existsMillennium) {
              existsMillennium.total += comp.subscribers;
            } else {
              exists.statusList.push({
                status: comp.statusMillennium,
                total: comp.subscribers
              });
            }
          }
          exists.statusList.sort((s1, s2) => {
            if (s1.status < s2.status) {
              return -1;
            } else if (s1.status > s2.status) {
              return 1;
            }
            return 0;
          });
        }
        return list;
      },
      []
    );
    this.compositions = this.compositionCounters.reduce(
      (list: Array<CompositionDTO>, c) => {
        if (
          c.compositionId &&
          !list.some((co) => co.compositionId === c.compositionId)
        ) {
          list.push({
            compositionId: c.compositionId,
            compositionName: c.compositionName,
            subscriptionName: c.subscriptionName,
            pending: c.pending,
            total: this.compositionTotal(c)
          });
        }
        return list;
      },
      []
    );
    this.subscriberEditionStatusList.sort((s1, s2) => {
      if (s1.id > 0 && s2.id < 0) {
        return -1;
      } else if (s1.id < 0 && s2.id > 0) {
        return 1;
      } else {
        return s1.id - s2.id;
      }
    });
    this.resetCols(
      this.subscriberEditionStatusList.reduce(
        (list: Array<TableColumn>, se) => {
          list = list.concat(
            se.statusList.map((s) => {
              const fieldName = s.status + '_' + se.id;
              this.compositions.forEach(
                (c) =>
                  (c[fieldName] = this.subscribersInStatus(c, s.status, se.id))
              );
              return new TableColumn.Builder()
                .setHeader(`${s.status} (${s.total})`)
                .setField(fieldName)
                .setFilter(false)
                .setType('formattedInteger')
                .build();
            })
          );
          return list;
        },
        []
      )
    );
    this.compositions.sort((c1, c2) => c1.compositionId - c2.compositionId);
    this.ready = true;
  }

  async findEditions(): Promise<void> {
    try {
      this.editions = await lastValueFrom(
        this.editionService
          .findEditionsBySubscriptionId(this.form.value.subscriptionId || 1)
          .pipe(map((data) => data.result))
      );
      if (this.form.value.subscriptionId === 0) {
        this.editions?.forEach(
          (e) => (e.editionId = (e.editionId || 0) % 1000000)
        );
      }
    } catch (error: any) {
      this.editions = [];
    }
  }

  async changeSubscription(): Promise<void> {
    LoaderService.showLoader();
    const form = { ...this.form.value };
    this.form.controls['editionId'].reset(
      (form.subscriptionId || 0) * 1000000 + ((form.editionId || 0) % 1000000)
    );
    this.navigate();
    LoaderService.showLoader(false);
  }

  async changeEdition(): Promise<void> {
    LoaderService.showLoader();
    this.navigate();
    LoaderService.showLoader(false);
  }

  editionDate(editionId: number): string {
    return FormUtil.editionDate(editionId);
  }

  editionDateName(editionId: number): string {
    return (
      this.datePipe.transform(
        new Date(
          `${((editionId % 1000000) / 100).toFixed(0)}-${editionId % 100}-02`
        ),
        'MMMM/yyyy'
      ) || this.editionDate(editionId)
    );
  }

  subscriberStatusColor(subscriberStatus: number): string {
    return FormUtil.subscriberStatusColor(subscriberStatus);
  }

  subscribersInStatus(
    composition: CompositionDTO,
    status: string,
    id: number
  ): number {
    return (
      this.compositionCounters.find(
        (c) =>
          c.compositionId === composition.compositionId &&
          c.subscriberEditionStatusId === id &&
          c.statusMillennium.toLowerCase() === status.toLowerCase()
      )?.subscribers || 0
    );
  }

  compositionTotal(composition: CompositionDTO): number {
    return this.compositionCounters
      .filter((c) => c.compositionId === composition.compositionId)
      .reduce((sum, c) => (sum += c.subscribers), 0);
  }

  resetCols(concat?: Array<TableColumn>): void {
    let orig = [
      new TableColumn(
        'Id',
        'compositionId',
        false,
        'number',
        '/operations/compositions/id/',
        'compositionId'
      ),
      new TableColumn(
        'Composição',
        'compositionName',
        false,
        'text',
        '/operations/compositions/id/',
        'compositionId'
      ),
      new TableColumn('Total', 'total', false, 'number'),
      new TableColumn('Pendente preparo', 'pending', false, 'number')
    ];
    if (this.form.value.editionId < 1000000) {
      orig = [
        new TableColumn(
          'Id',
          'compositionId',
          true,
          'number',
          '/operations/compositions/id/',
          'compositionId'
        ),
        new TableColumn('Assinatura', 'subscriptionName', true, 'text'),
        new TableColumn(
          'Composição',
          'compositionName',
          true,
          'text',
          '/operations/compositions/id/',
          'compositionId',
          true,
          'contains'
        ),
        new TableColumn('Total', 'total', false, 'formattedInteger'),
        new TableColumn(
          'Pendente preparo',
          'pending',
          false,
          'formattedInteger'
        )
      ];
    }
    this.cols = orig
      .concat(concat)
      .concat(
        this.permission
          ? [new TableColumn('Ação', '', false, 'button')]
          : [new TableColumn('Ação', '', false, 'button')]
      );
  }

  confirmPrepareComposition($event: {
    item: CompositionDTO;
    $event: Event;
    action: string;
  }): void {
    this.confirmationService.confirm({
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      target: $event.$event.target,
      message: `Deseja preparar a composição '${$event.item.compositionName}'?`,
      header: 'Preparar composição',
      accept: async () => {
        await this.prepareComposition($event.item);
      }
    });
  }

  async prepareComposition(composition: CompositionDTO) {
    try {
      LoaderService.showLoader();
      const result = await prepareComposition(
        composition,
        this.compositionService
      );
      composition = result.composition;
      this.messageService.add(result.message);
      await this.findPendingPreparation();
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async findSubscribers(
    statusId: number,
    status: string = null
  ): Promise<void> {
    LoaderService.showLoader();
    try {
      const subscribers = await lastValueFrom(
        this.compositionService
          .findSubscribersBySubscriberEditionStatusAndEditionId(
            statusId,
            this.form.value.editionId
          )
          .pipe(map((data) => data.result))
      );
      this.dialogService.open(TableModalComponent, {
        width: '80%',
        height: '90vh',
        header: status
          ? 'Assinantes com status ' + status
          : 'Assinantes da edição ' + this.edition?.theme,
        maximizable: true,
        data: {
          cols: this.subscriberColumns(statusId),
          elements: subscribers,
          modelName: 'assinantes',
          dropdownFilters: {
            subscriberEditionStatusName: subscribers.reduce(
              (list: Array<DropdownFilter>, s) => {
                if (
                  !list.some((ss) => ss.value === s.subscriberEditionStatusName)
                ) {
                  list.push({
                    label: s.subscriberEditionStatusName,
                    value: s.subscriberEditionStatusName
                  });
                }
                list.sort((c1, c2) => {
                  if (c1.value > c2.value) return 1;
                  if (c1.value < c2.value) return -1;
                  return 0;
                });
                return list;
              },
              []
            ),
            statusMillenium: subscribers.reduce(
              (list: Array<DropdownFilter>, s) => {
                if (!list.some((ss) => ss.value === s.statusMillenium)) {
                  list.push({
                    label: s.statusMillenium,
                    value: s.statusMillenium
                  });
                }
                list.sort((c1, c2) => {
                  if (c1.value > c2.value) return 1;
                  if (c1.value < c2.value) return -1;
                  return 0;
                });
                return list;
              },
              []
            )
          }
        }
      });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  showPending() {
    this.dialogService
      .open(PendingPreparationComponent, {
        data: {
          pending: this.pendingPreparation
        },
        header: 'Composições aguardando preparo',
        maximizable: true
      })
      .onClose.subscribe(async () => {
        LoaderService.showLoader();
        await this.findPendingPreparation();
        LoaderService.showLoader(false);
      });
  }

  subscriberColumns(statusId: number): Array<TableColumn> {
    let cols: Array<TableColumn>;
    if (this.form.value.editionId < 1000000) {
      cols = [
        new TableColumn(
          'Id',
          'subscriberId',
          true,
          'number',
          '/users/subscribers/',
          'subscriberId'
        ),
        new TableColumn(
          'Assinatura',
          'subscriptionName',
          true,
          'text',
          undefined,
          undefined,
          true,
          'contains'
        ),
        new TableColumn(
          'GlamId',
          'boxId',
          true,
          'text',
          '/users/subscribers/',
          'subscriberId'
        ),
        new TableColumn(
          'Assinante',
          'personName',
          true,
          'text',
          '/users/person/',
          'personId',
          true,
          'contains'
        ),
        new TableColumn(
          'Status ',
          'subscriberEditionStatusName',
          true,
          'text',
          undefined,
          undefined,
          true,
          'contains'
        )
      ];
    } else {
      cols = [
        new TableColumn(
          'Id',
          'subscriberId',
          true,
          'number',
          '/users/subscribers/',
          'subscriberId'
        ),
        new TableColumn(
          'GlamId',
          'boxId',
          true,
          'text',
          '/users/subscribers/',
          'subscriberId'
        ),
        new TableColumn(
          'Assinante',
          'personName',
          true,
          'text',
          '/users/person/',
          'personId',
          true,
          'contains'
        )
      ];
    }
    if (statusId) {
      cols = cols.concat([
        new TableColumn(
          'Status Millennium',
          'statusMillenium',
          true,
          'text',
          undefined,
          undefined,
          true,
          'contains'
        ),
        new TableColumn(
          'Últ atualização',
          'dateUpdatedMillenium',
          true,
          'date',
          undefined,
          undefined,
          true,
          'between'
        )
      ]);
    }
    return cols;
  }

  get pending(): number {
    return this.compositionCounters
      .filter((c) => !c.compositionId && !c.subscriberEditionStatusId)
      .reduce((sum, c) => (sum += c.subscribers || 0), 0);
  }

  get editionImage(): string {
    return FormUtil.editionImage(this.edition?.editionId || 0);
  }

  get edition(): Edition | undefined {
    return this.editions?.find(
      (e) => e.editionId === this.form.value.editionId
    );
  }

  get totalSubscribers(): number {
    return this.compositionCounters.reduce(
      (sum, c) => (sum += c.subscribers || 0),
      0
    );
  }
}

export async function prepareComposition(
  composition: CompositionDTO,
  compositionService: CompositionControllerService
): Promise<{ composition: CompositionDTO; message: Message }> {
  const result = await lastValueFrom(
    compositionService
      .prepareComposition(composition.compositionId)
      .pipe(map((data) => data.result))
  );
  let message: Message;
  if (result.pending && result.pending <= composition.pending) {
    message = {
      severity: 'warn',
      detail: `${result.pending} ${
        result.pending > 1
          ? ' edições não puderem ser preparadas'
          : ' edição não pode ser preparada'
      } .`,
      summary: 'Composição preparada parcialmente'
    };
  } else if (!result.pending) {
    message = {
      severity: 'success',
      detail: 'Composição preparada com sucesso.',
      summary: 'Composição preparada'
    };
  } else {
    message = {
      severity: 'error',
      detail:
        'Não foi possível preparar a composição. Verifique as assinantes.',
      summary: 'Composição não preparada'
    };
  }
  composition.pending = result.pending;
  return { composition, message };
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { AppTableModule } from '../table/app-table.module';
import { ProductStockComponent } from './product-stock.component';

@NgModule({
  declarations: [ProductStockComponent],
  imports: [
    CommonModule,
    AppTableModule,
    DropdownModule,
    ReactiveFormsModule,
    ButtonModule,
    InputNumberModule,
    OverlayPanelModule,
    RouterModule,
    TooltipModule,
    InputTextModule
  ],
  exports: [ProductStockComponent]
})
export class ProductStockModule {}

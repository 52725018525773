/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  CompositionProductVariantDTO,
  SubscriberControllerService,
  SubscriberEditionCompositionSummary
} from 'src/app/admin-api';
import { SubscriptionEnum } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { OpenService } from 'src/app/services/open.service';
import { ShippingTrackingModalComponent } from '../../shipping-tracking-modal/shipping-tracking-modal.component';

@Component({
  selector: 'app-composition-details',
  templateUrl: './composition-details.component.html',
  styleUrls: ['./composition-details.component.scss'],
  providers: [DialogService, ConfirmationService]
})
export class CompositionDetailsComponent implements OnInit, OnChanges {
  @Input()
  edition: SubscriberEditionCompositionSummary | undefined;

  @Input()
  showActions = true;

  @Input()
  expanded = false;

  @Input()
  isDesktop = false;

  @Output()
  editionChanged = new EventEmitter<SubscriberEditionCompositionSummary>();

  editions: Array<{
    subscriptionId: number;
    subscriptionName: string;
    products: Array<CompositionProductVariantDTO>;
    expanded: boolean;
  }> = [
    {
      subscriptionId: SubscriptionEnum.Glambox,
      subscriptionName: SubscriptionEnum[SubscriptionEnum.Glambox],
      products: [],
      expanded: false
    },
    {
      subscriptionId: SubscriptionEnum.Glambag,
      subscriptionName: SubscriptionEnum[SubscriptionEnum.Glambag],
      products: [],
      expanded: false
    }
  ];

  constructor(
    private dialog: DialogService,
    private subscriberService: SubscriberControllerService,
    private confirmationService: ConfirmationService,
    private openService: OpenService
  ) {}

  ngOnInit(): void {
    this.editions.forEach(
      (e) =>
        (e.products = this.edition.products.filter(
          (p) =>
            (this.isCombo && p.subscriptionId === e.subscriptionId) ||
            (!this.isCombo &&
              e.subscriptionId.toString() ===
                this.edition.editionId.toString()[0])
        ))
    );
    this.editions = this.editions.filter((e) => e.products.length);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['expanded']) {
      this.editions.forEach(
        (e) => (e.expanded = changes['expanded'].currentValue)
      );
    }
  }

  async openTrackingModal(): Promise<void> {
    LoaderService.showLoader();
    try {
      const tracking = await this.openService.getShippingTracking(
        this.edition?.invoiceNumber as number
      );
      if (tracking)
        this.dialog.open(ShippingTrackingModalComponent, {
          showHeader: true,
          header: 'Rastreio da nota ' + this.edition?.invoiceNumber,
          position: 'center',
          data: {
            edition: this.edition,
            tracking
          },
          dismissableMask: true,
          width: '50vw'
        });
      else
        AppDialogService.showErrorDialog({
          error: {
            message: 'Sem informações de rastreio para exibir.'
          }
        });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  changeStatusDone(event: MouseEvent): void {
    this.confirmationService.confirm({
      target: event.target || undefined,
      message: 'Desejar marcar a edição como "Entregue"?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      rejectLabel: 'Voltar',
      accept: async () => {
        LoaderService.showLoader();
        try {
          const edition = await lastValueFrom(
            this.subscriberService
              .updateSubscriberEditionStatusDone({
                editionId: this.edition?.editionId,
                subscriberId: this.edition?.subscriberId
              })
              .pipe(map((data) => data.result))
          );
          if (edition) edition.products = this.edition?.products;
          this.edition = { ...edition };
          this.editionChanged.emit(this.edition);
        } catch (error: any) {
          LoaderService.showLoader(false);
          AppDialogService.showErrorDialog(error);
        }
      }
    });
  }

  changeStatusRestored(event: MouseEvent): void {
    this.confirmationService.confirm({
      target: event.target || undefined,
      message: 'Desejar marcar a edição como "Devolvida"?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      rejectLabel: 'Voltar',
      accept: async () => {
        LoaderService.showLoader();
        try {
          const edition = await lastValueFrom(
            this.subscriberService
              .updateSubscriberEditionStatusRestored({
                editionId: this.edition?.editionId,
                subscriberId: this.edition?.subscriberId
              })
              .pipe(map((data) => data.result))
          );
          if (edition) edition.products = this.edition?.products;
          this.edition = { ...edition };
          this.editionChanged.emit(this.edition);
        } catch (error: any) {
          LoaderService.showLoader(false);
          AppDialogService.showErrorDialog(error);
        }
      }
    });
  }

  editionId(subscriptionId: number): number {
    return subscriptionId * 1000000 + (this.edition.editionId % 1000000);
  }

  subscriptionName(subscriptionId: number): string {
    return SubscriptionEnum[subscriptionId];
  }

  get isCombo(): boolean {
    return (this.edition.editionId / 1000000).toFixed(0) === '7';
  }
}

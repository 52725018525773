<h3 class="title">{{ section?.title }}</h3>
<app-glam-home-banner-alert
  [section]="section"
  *ngIf="section?.type === 'carousel'"
/>
<app-gridstack
  [options]="gridOptions"
  (dragStopCB)="listChanged()"
  *ngIf="
    (carouselSection(section) && section?.banners?.length) ||
    sectionBrands?.length
  "
>
  <app-gridstack-item
    *ngFor="let banner of section.banners"
    [options]="{
      noResize: true,
      id: banner?.id
    }"
    class="gridstack-card"
  >
    <ng-container *ngTemplateOutlet="defaultTemp; context: { item: banner }" />
  </app-gridstack-item>
  <app-gridstack-item
    *ngFor="let brand of sectionBrands"
    [options]="{
      noResize: true,
      id: brand?.collectionId
    }"
    class="gridstack-card"
  >
    <ng-container *ngTemplateOutlet="defaultTemp; context: { item: brand }" />
  </app-gridstack-item>
</app-gridstack>
<div class="footer">
  <p-button label="Voltar" severity="danger" routerLink="../" />
  <p-button
    label="Adicionar banner"
    *ngIf="carouselSection(this.section)"
    routerLink="banner/new"
  />
</div>
<ng-template #defaultTemp let-item="item">
  <div>
    <p-card>
      <ng-container *ngIf="section.type === 'carousel'">
        <ng-container *ngTemplateOutlet="bannerTemp; context: { item: item }" />
      </ng-container>
      <ng-container *ngIf="section.type === 'brands'">
        <ng-container *ngTemplateOutlet="brandTemp; context: { item: item }" />
      </ng-container>
      <div class="actions">
        <div class="actions__vertical">
          <p-button
            icon="pi pi-pencil"
            [disabled]="!item?.editable"
            [text]="true"
            [rounded]="true"
            pTooltip="Editar"
            tooltipPosition="bottom"
            [routerLink]="'banner/' + item.id"
            *ngIf="section.type === 'carousel'"
          />
          <p-button
            icon="pi pi-trash"
            [text]="true"
            [rounded]="true"
            severity="danger"
            pTooltip="Excluir"
            tooltipPosition="bottom"
            *ngIf="carouselSection(section) && section.banners?.length > 1"
            (onClick)="deleteBanner($event, item)"
          />
        </div>
        <p-button
          icon="pi pi-thumbtack"
          [disabled]="true"
          *ngIf="item?.fixed"
          [text]="true"
          [rounded]="true"
          pTooltip="Fixo"
          tooltipPosition="bottom"
        />
        <i
          class="pi pi-arrows-v p-ripple p-element p-button p-button-icon-only p-button-text p-button-primary p-button-rounded"
          *ngIf="!item?.fixed"
          pTooltip="Mover"
          tooltipPosition="bottom"
        ></i>
      </div>
    </p-card>
  </div>
</ng-template>
<ng-template #bannerTemp let-item="item">
  <div class="info">
    <p class="info__banner">
      <span>Nome: {{ item.title }}</span>
      <i
        pTooltip="Publicado"
        class="pi pi-check-circle"
        *ngIf="item.published"
      ></i>
      <i
        pTooltip="Não publicado"
        class="pi pi-stop-circle"
        *ngIf="!item.published"
      ></i>
    </p>
    <p *ngIf="item.link?.length > 50">
      Link: {{ item.link | slice : 1 : 50 }}...
    </p>
    <p *ngIf="item.link?.length <= 50">Link: {{ item.link || 'Sem link' }}</p>
    <p *ngIf="item.publishDate">
      Data de publicação:
      {{ item.publishDate | date : 'dd/MM/yyyy HH:mm:ss' }}
    </p>
    <p *ngIf="item.deleteDate">
      Data de exclusão:
      {{ item.deleteDate | date : 'dd/MM/yyyy HH:mm:ss' }}
    </p>
  </div>
  <div class="banner-images">
    <img [src]="item.mobileImage" [alt]="item.altText" class="image-banner" />
    <img [src]="item.desktopImage" [alt]="item.altText" class="image-banner" />
  </div>
</ng-template>
<ng-template #brandTemp let-brand="item">
  <form class="form-b4a form-b4a__vertical">
    <span class="p-float-label">
      <p-autoComplete
        [suggestions]="brands"
        [formControl]="brand.control"
        filterBy="brandName,slug"
        optionLabel="brandName"
        appendTo="body"
        [lazy]="false"
        [multiple]="false"
        (completeMethod)="searchBrands($event, brand)"
        placeholder="Busque pelo nome ou slug"
        name="brand"
        inputId="brand"
        (onBlur)="checkBrand(brand)"
        (onSelect)="brandChange(brand, $event)"
        (click)="brand.control.reset()"
      >
        <ng-template pTemplate="item" let-item>
          {{ item.brandName }} [{{ item.slug }}]
        </ng-template>
      </p-autoComplete>
      <label for="brand">Nome</label>
    </span>
    <p class="brand-info" *ngIf="brand?.slug && brand.slug !== 'placeholder'">
      Slug: {{ brand.slug }}
    </p>
    <p class="brand-info" *ngIf="brandUrl(brand)">
      Link:
      <a target="_blank" [href]="brandUrl(brand)">
        {{ brandUrl(brand) }}
      </a>
    </p>
  </form>
  <div class="brand-image">
    <img [src]="brand?.image" [alt]="brand?.brandName" class="image-brand" />
  </div>
</ng-template>
<p-toast />
<p-confirmPopup />

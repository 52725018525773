/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ActiveSession {
  sessionId?: number;
  databaseName?: string;
  transactionBeginTime?: Date;
  transactionDuration?: number;
  transactionType?: string;
  inputBuffer?: string;
  transactionUOW?: number;
  transactionState?: string;
  transactionName?: string;
  requestStatus?: string;
  isUserTransaction?: number;
  isLocal?: number;
  openTransactionCount?: number;
  hostName?: string;
  programName?: string;
  clientInterfaceName?: string;
  loginName?: string;
  isUserProcess?: number;
}

<div class="page-title">Detalhes do usuário</div>
<div class="details" *ngIf="person">
  <div class="details__left">
    <app-person-card
      [person]="person"
      [isMobile]="isMobile"
      [influencer]="influencer"
      [showProfileLink]="false"
    />
  </div>
  <div class="details__right">
    <div class="card">
      <p-tabView
        [activeIndex]="selectedTab"
        (activeIndexChange)="tabChanged($event)"
        [scrollable]="true"
      >
        <p-tabPanel header="Assinatura">
          <div class="subscribers" *ngIf="subscribers">
            <app-subscriber-card
              *ngFor="let subscriber of subscribers"
              [subscriber]="subscriber"
              (couponChanged)="onCouponChanges($event)"
            />
            <h5 *ngIf="!subscribers.length" class="empty">
              {{ person?.name }} ainda não é assinante
            </h5>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Dados pessoais">
          <app-person-form
            [person]="person"
            *ngIf="person?.dateCreated"
            (updated)="findPerson()"
          />
        </p-tabPanel>
        <p-tabPanel header="Endereços">
          <app-address-info [person]="person" [subscribers]="subscribers" />
        </p-tabPanel>
        <p-tabPanel header="Cartões">
          <app-person-credit-cards
            [personId]="person?.personId"
            [creditCards]="creditCards"
            [isMobile]="isMobile"
            (cardRemoved)="refresh('findPersonCards')"
            *ngIf="creditCards"
          />
        </p-tabPanel>
        <p-tabPanel header="Convites">
          <app-person-invites
            [isMobile]="isMobile"
            [invites]="invites"
            [person]="person"
          />
        </p-tabPanel>
        <p-tabPanel header="Perfil de beleza">
          <app-person-beautyprofile
            [person]="person"
            [beautyProfile]="beautyProfile"
          />
        </p-tabPanel>
        <p-tabPanel header="Pedidos">
          <app-table
            *ngIf="person"
            [cols]="orderCols"
            sortField="createdAt"
            [sortOrder]="1"
            [addButton]="false"
            modelName="pedidos"
            [showTitle]="false"
            [elements]="orders"
            [lazy]="false"
            [dropdownFilters]="dropdownFilters"
            [multiSelectFilter]="true"
            [defaultRows]="15"
          />
        </p-tabPanel>
        <p-tabPanel header="Glampoints">
          <app-person-glampoints
            [isMobile]="isMobile"
            [person]="person"
            [rewards]="rewards"
            [rewardTypes]="rewardTypes"
            *ngIf="rewards && rewardTypes"
            (balanceChanged)="findPerson()"
          />
        </p-tabPanel>
        <p-tabPanel header="Instagram">
          <app-person-instagram-history
            [isMobile]="isMobile"
            [person]="person"
            [history]="instagramHistory"
            *ngIf="instagramHistory"
            (refresh)="refresh('findInstagramHistory')"
          />
        </p-tabPanel>
        <p-tabPanel *ngIf="influencer" header="Glampartner">
          <app-influencer-form
            *ngIf="influencer"
            [influencer]="influencer"
            [influencerStatusList]="influencerStatusList"
            [coupons]="coupons"
            [isMobile]="isMobile"
            (couponAdded)="findAllCoupons(true)"
          />
        </p-tabPanel>
        <p-tabPanel header="Mídias sociais">
          <app-person-social-medias
            *ngIf="socialMedias"
            [currentSocialMedias]="socialMedias"
            [socialMediaHistory]="socialMediaHistory"
            [isMobile]="isMobile"
            [person]="person"
          />
        </p-tabPanel>
        <p-tabPanel header="Log">
          <app-table
            *ngIf="person"
            [service]="personService"
            pageFunctionName="findLogTransactionTable"
            modelName="logs"
            [cols]="logCols"
            [fixedFilters]="filters"
            sortField="id"
            [sortOrder]="-1"
            [exportPdf]="false"
            [exportExcel]="false"
            [addButton]="false"
            [actionButtons]="buttons"
            (actionButtonClick)="showResponseBody($event)"
          />
        </p-tabPanel>
        <p-tabPanel header="Ações">
          <app-table
            *ngIf="personActions?.length"
            [cols]="actionsCols"
            modelName="ações"
            tableTitle="Ações registradas"
            [lazy]="false"
            [elements]="personActions"
            sortField="dateConfirmed"
            [sortOrder]="1"
            [exportPdf]="false"
            [dropdownFilters]="dropdownFilters"
            addLabel="Nova ação"
            [actionButtons]="actionButtons"
            (actionButtonClick)="buttonClick($event)"
            (addButtonClick)="openActionModal()"
            [addButton]="permission"
            [addButtonAction]="true"
            [emptyMessage]="person.name + ' ainda não possui ações registradas'"
          />
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</div>
<p-dialog [visible]="logDetail !== undefined" [closable]="false" [modal]="true">
  <form class="form-b4a">
    <span class="p-float-label">
      <textarea
        id="logDetail"
        rows="5"
        cols="80"
        pInputTextarea
        [(ngModel)]="logDetail"
        name="logDetail"
        (click)="copyToClipboard(logDetail)"
        required
      ></textarea>
    </span>
    <div class="buttons">
      <p-button label="Voltar" (onClick)="closeModal()" />
    </div>
  </form>
</p-dialog>
<p-toast />
<p-confirmPopup />

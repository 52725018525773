import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DragDropListComponent } from './drag-drop-list.component';

import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [DragDropListComponent],
  imports: [CommonModule, DragDropModule],
  exports: [DragDropListComponent]
})
export class DragDropListModule {}

import { Component, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Filter } from '@infrab4a/connect';
import { ConfirmationService, MessageService, PrimeIcons } from 'primeng/api';
import {
  DropdownFilter,
  TableActionButton,
  TableColumn,
  TableComponent
} from 'src/app/components/table';
import { ShopCategoryFilterService } from 'src/app/connect-api/api/shop/shop-category-filter.service';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-category-filters',
  templateUrl: './category-filters.component.html',
  styleUrl: './category-filters.component.scss'
})
export class CategoryFiltersComponent {
  @ViewChild(TableComponent) table: TableComponent;
  cols = [
    new TableColumn(
      'Nome do filtro',
      'description',
      true,
      'text',
      'filter/',
      'id',
      true,
      'contains'
    ),
    new TableColumn(
      'Título',
      'title',
      true,
      'text',
      'filter/',
      'id',
      true,
      'contains'
    ),
    new TableColumn('Ativo', 'enabled', true, 'inputSwitch'),
    new TableColumn(
      'Data criação',
      'createdAt',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    ),
    new TableColumn(
      'Últ. atualização',
      'updatedAt',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    ),
    new TableColumn('Ação', '', false, 'button')
  ];
  protected actionButtons: Array<TableActionButton> = [
    new TableActionButton(
      '',
      'delete',
      PrimeIcons.TRASH,
      undefined,
      '',
      'Excluir',
      'bottom',
      true,
      true,
      'danger'
    )
  ];
  dropdownFilters: {
    [field: string]: Array<DropdownFilter>;
  } = {
    enabled: [
      { label: 'Todos', value: undefined },
      { label: 'Sim', value: true },
      { label: 'Não', value: false }
    ]
  };
  constructor(
    private title: Title,
    protected categoryFilterService: ShopCategoryFilterService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {
    this.title.setTitle('Loja - Filtros de categoria');
  }

  async updateEnabled(filter: Partial<Filter>): Promise<void> {
    LoaderService.showLoader();
    try {
      await this.categoryFilterService.updateFilter({
        id: filter.id,
        enabled: filter.enabled
      });
      this.messageService.add({
        severity: 'success',
        detail: `Filtro ${
          filter.enabled ? 'ativado' : 'desativado'
        } com sucesso!`,
        summary: 'Sucesso'
      });
      await this.table.refresh();
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  delete({ item: { id }, $event }) {
    this.confirmationService.confirm({
      target: $event.target,
      message: 'Deseja excluir o filtro?',
      header: 'Atenção',
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      acceptButtonStyleClass: 'p-button-primary',
      rejectButtonStyleClass: 'p-button-danger',
      accept: async () => {
        LoaderService.showLoader();
        try {
          await this.categoryFilterService.deleteFilter(Number(id));
          this.messageService.add({
            severity: 'success',
            detail: `Filtro excluído!`,
            summary: 'Sucesso'
          });
          await this.table.refresh();
        } catch (error) {
          AppDialogService.showErrorDialog(error);
        }
        LoaderService.showLoader(false);
      }
    });
  }
}

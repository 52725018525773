import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DividerModule } from 'primeng/divider';
import { AddressCardModule } from '../address-card/address-card.module';
import { AddressInfoComponent } from './address-info.component';

@NgModule({
  declarations: [AddressInfoComponent],
  imports: [CommonModule, DividerModule, AddressCardModule],
  exports: [AddressInfoComponent]
})
export class AddressInfoModule {}

<div class="page-title">Perfil de beleza</div>
<div
  class="details beauty-profile"
  *ngIf="beautyProfile && beautyProfile() && subscriberCount() !== undefined"
>
  <div class="details__left">
    <div class="card card-user">
      <div class="image"></div>
      <div class="card-body">
        <div class="user">
          <img class="avatar" src="assets/images/person-check-icon.svg" />
        </div>
        <div>
          <p>{{ subscriberCount() | number : '1.0-0' }} assinantes ativas</p>
          <p>
            <ng-container *ngIf="personCount()">
              {{ personCount().complete | number : '1.0-0' }}
              ({{ percentageComplete() | number : '1.2-2' }}
              %)
            </ng-container>
            <ng-container *ngIf="!personCount()">
              <i class="pi pi-spinner pi-spin"></i>
            </ng-container>
            assinantes com perfil completo
          </p>
          <p>
            <ng-container *ngIf="personCount()">
              {{ personCount().incomplete | number : '1.0-0' }}
              ({{ percentageIncomplete() | number : '1.2-2' }}
              %)
            </ng-container>
            <ng-container *ngIf="!personCount()">
              <i class="pi pi-spinner pi-spin"></i>
            </ng-container>
            assinantes com perfil incompleto
          </p>
          <p>
            <ng-container *ngIf="personCount()">
              {{ empty() | number : '1.0-0' }}
              ({{ percentageEmpty() | number : '1.2-2' }}
              %)
            </ng-container>
            <ng-container *ngIf="!personCount()">
              <i class="pi pi-spinner pi-spin"></i>
            </ng-container>
            assinantes sem perfil
          </p>
          <p>{{ totalGroups() | number : '1.0-0' }} grupos ativos</p>
          <p>{{ totalAttributes() | number : '1.0-0' }} perguntas ativas</p>
          <p>{{ totalOptions() | number : '1.0-0' }} alternativas ativas</p>
        </div>
      </div>
    </div>
  </div>
  <div class="details__right">
    <div class="card">
      <p-tabView
        [(activeIndex)]="tabIndex"
        (activeIndexChange)="navigate($event)"
      >
        <p-tabPanel header="Perguntas" *ngIf="permissions">
          <p-tree
            styleClass="custom-tree"
            class="w-full"
            [value]="nodes()"
            [draggableNodes]="sortable"
            [droppableNodes]="sortable"
            droppableScope="self"
            draggableScope="self"
            (onNodeDrop)="validateChange($event)"
            (onNodeExpand)="onExpandCollapse($event)"
            (onNodeCollapse)="onExpandCollapse($event)"
            [filter]="true"
            [validateDrop]="true"
            filterBy="data.order,data.attributeGroupQuestion,data.attributeGroupName,data.attributeQuestion,data.attributeName,data.attributeOptionName"
          >
            <ng-template pTemplate="header">
              <div class="beauty-profile__header">
                <p-button
                  label="Adicionar grupo"
                  icon="pi pi-plus"
                  severity="success"
                  pTooltip="Adicionar um novo grupo de perguntas"
                  tooltipPosition="left"
                  (onClick)="attributeGroupForm()"
                  class="header-button"
                ></p-button>
                <div class="beauty-profile__attribute">
                  <b class="title">Grupo/Pergunta</b>
                  <b class="beauty-profile__attribute__head">Tipo</b>
                  <b class="beauty-profile__attribute__head">Inativo/Ativo</b>
                  <b class="beauty-profile__attribute__head">Ações</b>
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="attributeGroup" let-node>
              <span class="beauty-profile__attribute-group beauty">
                <span
                  class="beauty-profile__attribute-group__title"
                  [class.beauty-profile__attribute-group__title--disabled]="
                    !node.data.active
                  "
                >
                  {{ node.data.order }}.
                  {{
                    node.data.attributeGroupQuestion ||
                      node.data.attributeGroupName
                  }}
                </span>
                <span class="beauty-profile__attribute-group__type">
                  <ng-container *ngIf="!node.parent"
                    >Grupo de perguntas</ng-container
                  >
                  <ng-container *ngIf="node.parent"
                    >Grupo de frequências</ng-container
                  >
                </span>
                <span class="input-switch">
                  <p-inputSwitch
                    [inputId]="'group' + node.key"
                    [(ngModel)]="node.data.active"
                    (onChange)="changeStatus(node.data)"
                  ></p-inputSwitch>
                </span>
                <span class="beauty-profile__attribute-group__buttons">
                  <p-button
                    icon="pi pi-pencil"
                    pTooltip="Editar grupo"
                    severity="primary"
                    styleClass="p-button-xs"
                    tooltipPosition="left"
                    [rounded]="true"
                    [text]="true"
                    (onClick)="attributeGroupForm(node.data)"
                  ></p-button>
                  <p-button
                    icon="pi pi-plus-circle"
                    pTooltip="Adicionar pergunta"
                    severity="success"
                    styleClass="p-button-xs"
                    tooltipPosition="left"
                    [rounded]="true"
                    [text]="true"
                    (onClick)="attributeForm(node.data)"
                  ></p-button>
                </span>
                <!-- <p-overlayPanel #op>
                  <p-button
                    label="Pergunta"
                    severity="success"
                    size="small"
                    (onClick)="changeQuestion()"
                  ></p-button>
                  <p-button
                    label="Grupo de frequências"
                    severity="success"
                    size="small"
                    (onClick)="addGroup(node.data)"
                  ></p-button>
                </p-overlayPanel> -->
              </span>
            </ng-template>
            <ng-template pTemplate="attribute" let-node>
              <span
                class="beauty-profile__attribute"
                [class.beauty-profile__attribute--no-image]="
                  !isExternalLink(node.data.webImage) &&
                  !isExternalLink(node.data.mobileImage)
                "
              >
                <span
                  class="beauty-profile__attribute__title"
                  [class.beauty-profile__attribute-group__title--disabled]="
                    !node.data.active
                  "
                >
                  <span>
                    {{ node.data.order }}.
                    {{ node.data.attributeQuestion || node.data.attributeName }}
                  </span>
                  <span
                    class="beauty-profile__attribute__title__image"
                    *ngIf="
                      isExternalLink(node.data.webImage) ||
                      isExternalLink(node.data.mobileImage)
                    "
                  >
                    <img
                      *ngIf="isExternalLink(node.data.webImage)"
                      [src]="node.data.webImage"
                    />
                    <img
                      *ngIf="
                        !isExternalLink(node.data.webImage) &&
                        isExternalLink(node.data.mobileImage)
                      "
                      [src]="node.data.mobileImage"
                    />
                  </span>
                </span>
                <span class="beauty-profile__attribute-group__type">
                  Pergunta
                </span>
                <span class="input-switch">
                  <p-inputSwitch
                    [inputId]="'attribute' + node.key"
                    [(ngModel)]="node.data.active"
                    (onChange)="changeStatus(node.data)"
                  ></p-inputSwitch>
                </span>
                <span class="beauty-profile__attribute__buttons">
                  <p-button
                    icon="pi pi-pencil"
                    pTooltip="Alterar pergunta"
                    tooltipPosition="left"
                    (onClick)="attributeForm(node.parent.data, node.data)"
                    severity="warning"
                    styleClass="p-button-xs"
                    [rounded]="true"
                    [text]="true"
                  ></p-button>
                  <p-button
                    icon="pi pi-plus-circle"
                    pTooltip="Adicionar alternativa"
                    tooltipPosition="left"
                    (onClick)="attributeOptionForm(node.parent.data, node.data)"
                    severity="success"
                    styleClass="p-button-xs"
                    [rounded]="true"
                    [text]="true"
                  ></p-button>
                  <p-button
                    icon="pi pi-window-maximize"
                    pTooltip="Converter em grupo de frequências"
                    tooltipPosition="left"
                    (onClick)="convertToGroup(node.data)"
                    severity="primary"
                    styleClass="p-button-xs"
                    [rounded]="true"
                    [text]="true"
                    *ngIf="!node.parent.parent"
                  ></p-button>
                  <p-button
                    icon="pi pi-window-maximize"
                    pTooltip="Remover do grupo"
                    tooltipPosition="left"
                    (onClick)="removeFromGroup(node.data)"
                    severity="primary"
                    styleClass="p-button-xs"
                    [rounded]="true"
                    [text]="true"
                    *ngIf="node.parent.parent"
                  ></p-button>
                </span>
              </span>
            </ng-template>
            <ng-template pTemplate="attributeOption" let-node>
              <span
                class="beauty-profile__attribute-option"
                [class.beauty-profile__attribute-option--no-image]="
                  !isExternalLink(node.data.attributeOptionImage)
                "
              >
                <span
                  class="beauty-profile__attribute-option__title"
                  [class.beauty-profile__attribute-group__title--disabled]="
                    !node.data.active
                  "
                >
                  <span>
                    {{ node.data.order }}.
                    {{ node.data.attributeOptionName }}
                  </span>
                  <span
                    class="beauty-profile__attribute-option__title__image"
                    *ngIf="isExternalLink(node.data.attributeOptionImage)"
                  >
                    <img [src]="node.data.attributeOptionImage" />
                  </span>
                </span>
                <span class="beauty-profile__attribute-group__type">
                  Alternativa
                </span>
                <span class="input-switch">
                  <p-inputSwitch
                    [inputId]="'option' + node.key"
                    [(ngModel)]="node.data.active"
                    (onChange)="changeStatus(node.data)"
                  ></p-inputSwitch>
                </span>
                <span class="beauty-profile__attribute-group__buttons">
                  <p-button
                    icon="pi pi-pencil"
                    pTooltip="Alterar alternativa"
                    tooltipPosition="left"
                    (onClick)="
                      attributeOptionForm(
                        node.parent.parent.data,
                        node.parent.data,
                        node.data
                      )
                    "
                    severity="warning"
                    styleClass="p-button-xs"
                    [rounded]="true"
                    [text]="true"
                  ></p-button>
                </span>
              </span>
            </ng-template>
          </p-tree>
        </p-tabPanel>
        <p-tabPanel header="Respostas">
          <ng-template pTemplate="content">
            <app-beauty-profile-answers
              [groups]="beautyProfile"
              [subscriberCount]="subscriberCount()"
            ></app-beauty-profile-answers>
          </ng-template>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</div>
<p-toast></p-toast>

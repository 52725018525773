import { Component, Input, OnInit, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Category, Coupon, Order } from '@infrab4a/connect';
import { ConfirmationService, PrimeIcons } from 'primeng/api';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { lastValueFrom, map } from 'rxjs';
import {
  InfluencerControllerService,
  InfluencerDetail,
  PersonControllerService,
  PersonDetail
} from 'src/app/admin-api';
import { TableActionButton, TableColumn } from 'src/app/components/table';
import { ShopCategoryService } from 'src/app/connect-api/api/shop/shop-category.service';
import { ShopCouponService } from 'src/app/connect-api/api/shop/shop-coupon.service';
import { ShopOrderService } from 'src/app/connect-api/api/shop/shop-order.service';
import { ShopSubscriptionService } from 'src/app/connect-api/api/shop/shop-subscription.service';
import { ShopMap } from 'src/app/connect-api/enums/ShopMap';
import {
  PaymentMethod,
  getAllPaymentMethod
} from 'src/app/connect-api/models/PaymentMethod';
import {
  PaymentStatus,
  getAllPaymentStatus
} from 'src/app/connect-api/models/PaymentStatus';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-shop-coupon-detail',
  templateUrl: './shop-coupon-detail.component.html',
  styleUrl: './shop-coupon-detail.component.scss',
  providers: [ConfirmationService]
})
export class ShopCouponDetailComponent implements OnInit {
  @Input() couponId: string;

  protected couponService = inject(ShopCouponService);
  protected categoryService = inject(ShopCategoryService);
  protected orderService = inject(ShopOrderService);
  protected subscriptionService = inject(ShopSubscriptionService);
  protected router = inject(Router);
  protected activatedRoute = inject(ActivatedRoute);
  private title = inject(Title);
  private confirmationService = inject(ConfirmationService);
  private personService = inject(PersonControllerService);
  private influencerService = inject(InfluencerControllerService);

  protected coupon: Partial<Coupon>;
  protected categoriesAvailable: Partial<Category>[];
  protected cols = [
    new TableColumn.Builder()
      .setHeader('Id')
      .setField('id')
      .setRouterLink('/shop-products/categories/category/')
      .setRouterLinkFieldName('id')
      .build(),
    new TableColumn.Builder()
      .setHeader('Nome')
      .setField('name')
      .setRouterLink('/shop-products/categories/category/')
      .setRouterLinkFieldName('id')
      .build(),
    new TableColumn('Ação', '', false, 'button')
  ];
  protected actionButton = [
    new TableActionButton(
      '',
      'Excluir',
      PrimeIcons.TRASH,
      undefined,
      '',
      'Excluir',
      'bottom',
      true,
      true,
      'danger',
      'small'
    )
  ];
  productCategories: Partial<Category>[];
  protected category = new FormControl<Partial<Category>>(null);

  planTypes = [
    { value: 'SELECT', displayName: 'SELECT' },
    { value: 'SELECT_MENSAL', displayName: 'SELECT Mensal' },
    { value: 'PRIME', displayName: 'PRIME' },
    { value: 'PRIME_MENSAL', displayName: 'PRIME Mensal' }
  ];

  orderCols = [
    new TableColumn.Builder()
      .setHeader('Id')
      .setField('orderNumber')
      .setRouterLink('/shop-orders/order/')
      .setRouterLinkFieldName('id')
      .setDisplayFunction(
        (order: Partial<Order>) => order.orderNumber || order.id
      )
      .build(),
    new TableColumn.Builder()
      .setHeader('Comprador')
      .setField('user.displayName')
      .setCondition('contains')
      .setDisplayFunction((order: Order) => `${order.user.displayName}`)
      .build(),
    new TableColumn.Builder()
      .setHeader('CPF')
      .setField('user.cpf')
      .setType('number')
      .setDisplayFunction((order: Order) =>
        FormUtil.cpfFormatted(Number(order.user.cpf))
      )
      .build(),
    new TableColumn.Builder()
      .setHeader('Email')
      .setField('user.email')
      .setCondition('contains')
      .setDisplayFunction((order: Order) => order.user.email)
      .build(),
    new TableColumn.Builder()
      .setHeader('Forma Pagamento')
      .setField('payment.paymentMethod')
      .setCondition('in')
      .setDisplayFunction(
        (order: Order) => PaymentMethod[order.payment.paymentMethod]
      )
      .build(),
    new TableColumn.Builder()
      .setHeader('TID')
      .setField('payment.id')
      .setType('number')
      .setDisplayFunction((order: Order) => order.payment.id)
      .build(),
    new TableColumn.Builder()
      .setHeader('Status Pagamento')
      .setField('payment.status')
      .setCondition('in')
      .setDisplayFunction((order: Order) => PaymentStatus[order.payment.status])
      .build(),
    new TableColumn.Builder()
      .setHeader('Produtos')
      .setField('lineItems.length')
      .setType('formattedInteger')
      .setCondition('gte')
      .build(),
    new TableColumn.Builder()
      .setHeader('Loja')
      .setField('shop')
      .setCondition('in')
      .setDisplayFunction((order: Order) => ShopMap[order.shop])
      .build(),
    new TableColumn.Builder()
      .setHeader('Transportadora')
      .setField('shipping.ShippingCompanyName')
      .setCondition('contains')
      .setDisplayFunction((order: Order) => order.shipping?.ShippingCompanyName)
      .build(),
    new TableColumn.Builder()
      .setHeader('Status')
      .setField('status')
      .setCondition('in')
      .build(),
    new TableColumn.Builder()
      .setHeader('Data')
      .setField('createdAt')
      .setType('date')
      .setCondition('gte')
      .setFilter(false)
      .build(),
    new TableColumn.Builder()
      .setHeader('Total')
      .setField('totalPrice')
      .setType('currency')
      .setCondition('gte')
      .build()
  ];

  subscriptionCols = [
    new TableColumn.Builder()
      .setHeader('Nome')
      .setField('user.displayName')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('Email')
      .setField('user.email')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder().setHeader('Status').setField('status').build(),
    new TableColumn.Builder()
      .setHeader('Cidade')
      .setField('shippingAddress.city')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('UF')
      .setField('shippingAddress.state')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('Plano')
      .setField('subscriptionPlan.name')
      .build(),
    new TableColumn.Builder()
      .setHeader('Data Criaçao')
      .setField('createdAt')
      .setType('date')
      .setCondition('between')
      .build()
  ];

  subscriptionFilters = {
    status: [
      { label: 'Ativo', value: 'Ativo' },
      { label: 'Cancelado', value: 'Cancelado' },
      { label: 'Todos', value: null }
    ],
    'subscriptionPlan.name': [
      { label: 'Prime', value: 'PRIME' },
      { label: 'Prime mensal', value: 'PRIME_MENSAL' },
      { label: 'Select', value: 'SELECT' },
      { label: 'Select mensal', value: 'SELECT_MENSAL' },
      { label: 'Todos', value: null }
    ]
  };
  orderFilters = {
    status: [
      { label: 'Aguardando pagamento', value: 'Aguardando pagamento' },
      { label: 'Preparando pedido', value: 'Preparando pedido' },
      { label: 'Enviado', value: 'Enviado' },
      { label: 'Entregue', value: 'Entregue' },
      { label: 'Cancelado', value: 'Cancelado' }
    ],
    shop: [
      { label: 'Glam', value: 'Glamshop' },
      { label: 'Glampoints', value: 'Glampoints' },
      { label: `Men's Market`, value: 'mensmarket' }
    ],
    'payment.paymentMethod': getAllPaymentMethod(),
    'payment.status': getAllPaymentStatus()
  };
  person: PersonDetail;
  influencer: InfluencerDetail;

  async ngOnInit() {
    LoaderService.showLoader();
    if (this.couponId && this.couponId !== 'new') {
      await this.findCoupon();
    }

    this.title.setTitle(
      this.coupon
        ? `Loja - Editar cupom ${this.coupon.nickname}`
        : 'Loja - Novo cupom'
    );
    LoaderService.showLoader(false);
  }

  async findCoupon(): Promise<void> {
    try {
      this.coupon = await this.couponService.getCouponById(this.couponId);
      if (this.coupon.productsCategories?.filter((c) => c)?.length) {
        await this.findCategoriesForProducts();
      }
      if (this.coupon?.personId) {
        await Promise.all([
          this.findPersonDetails(),
          this.findInfluencerDetails()
        ]);
      }
    } catch (error) {
      AppDialogService.showErrorDialog(error, true);
    }
  }

  async findPersonDetails(): Promise<void> {
    try {
      this.person = await lastValueFrom(
        this.personService
          .findPersonInfoById(this.coupon.personId)
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      delete this.person;
      AppDialogService.showErrorDialog(error);
    }
  }

  async findInfluencerDetails(): Promise<void> {
    try {
      this.influencer = await lastValueFrom(
        this.influencerService
          .findInfluencerDetails(this.coupon.personId)
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      delete this.influencer;
    }
  }

  async findCategoriesForProducts(): Promise<void> {
    try {
      this.productCategories = await Promise.all(
        this.coupon.productsCategories
          ?.filter((c) => c)
          .map((p) => this.categoryService.getCategoryById(p))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async searchCategories(event: AutoCompleteCompleteEvent): Promise<void> {
    try {
      const result = await Promise.all([
        this.categoryService.getCategoriesList({
          filters: [
            {
              condition: 'contains',
              field: 'slug',
              value: event.query,
              fieldType: 'text'
            }
          ],
          page: 0,
          pageSize: 10
        }),
        this.categoryService.getCategoriesList({
          filters: [
            {
              condition: 'contains',
              field: 'name',
              value: event.query,
              fieldType: 'text'
            }
          ],
          page: 0,
          pageSize: 10
        })
      ]);
      this.categoriesAvailable = [
        ...new Set(
          result
            .reduce((cats, r) => cats.concat(r.content), [])
            .filter(
              (c) =>
                this.coupon.productsCategories?.every((cat) => cat !== c.id) &&
                (!this.category.valid || this.category.value.id !== c.id)
            )
        )
      ];
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  checkRemoveProductCategory($event: {
    item: Partial<Category>;
    $event: MouseEvent;
  }): void {
    this.confirmationService.confirm({
      acceptLabel: 'Sim',
      acceptButtonStyleClass: 'p-button-danger',
      target: $event.$event.target,
      rejectLabel: 'Não',
      header: 'Remover categoria',
      message: `Deseja remover a categoria "${$event.item.name}"?`,
      accept: async () => {
        await this.removeProductCategory($event);
      }
    });
  }

  async removeProductCategory($event: { item: Partial<Category> }) {
    const request = {
      id: this.couponId,
      productsCategories: (this.coupon.productsCategories || []).filter(
        (pc) => pc && pc !== $event.item.id
      )
    };
    try {
      LoaderService.showLoader(true);
      this.coupon = await this.couponService.updateCoupon(request);
      if (this.coupon.productsCategories?.length)
        await this.findCategoriesForProducts();
      else this.productCategories = [];
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async addCategory() {
    const request = {
      id: this.couponId,
      productsCategories: (this.coupon.productsCategories || [])
        .filter((pc) => pc)
        .concat([this.category.value.id])
    };
    try {
      LoaderService.showLoader(true);
      this.coupon = await this.couponService.updateCoupon(request);
      await this.findCategoriesForProducts();
      this.category.reset();
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }
}

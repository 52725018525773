import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Role, roleAsObject } from 'src/app/models';
import { TokenStorageService } from './token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(private router: Router) {}

  canActivate(): boolean {
    if (TokenStorageService.userLogged) {
      return true;
    }
    this.router.navigate(['login']);
    return false;
  }

  canActivatePermissions(permissions: Array<Role>): boolean {
    if (
      TokenStorageService.userRolesList.some((r) =>
        permissions.map((p) => roleAsObject(p).enumValue).includes(r)
      )
    ) {
      return true;
    }
    this.router.navigate(['/unauthorized']);
    return false;
  }
}

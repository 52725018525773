<app-table
  [lazy]="true"
  [cols]="columns"
  tableTitle="End Edition Incorreta"
  [addButton]="false"
  emptyMessage="Sem mais end editions incorretas no momento."
  [exportPdf]="false"
  [exportExcel]="false"
  pageFunctionName="getIncorrectEndEditionTable"
  [service]="itOpsService"
  modelName="Registros"
  sortField="subscriberId"
  [sortOrder]="-1"
  selectionActionLabel="Corrigir"
  selectionActionTooltip="Ações para corrigir as end editions incorretas"
  (emitSelection)="openActionModal($event)"
  *ngIf="ready"
></app-table>
<p-toast></p-toast>

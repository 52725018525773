<p-tree
  class="w-full"
  [value]="nodes()"
  (onNodeExpand)="onExpandCollapse($event)"
  (onNodeCollapse)="onExpandCollapse($event)"
  [filter]="true"
  *ngIf="answers()?.length"
  filterBy="data.order,data.attributeGroupQuestion,data.attributeGroupName,data.attributeQuestion,data.attributeName,data.attributeOptionName"
>
  <ng-template pTemplate="header">
    <div class="beauty-profile__header">
      <p-button
        label="Exportar XLS"
        icon="pi pi-plus"
        severity="success"
        pTooltip="Exportar repostas em XLS"
        tooltipPosition="left"
        (onClick)="exportExcel()"
        class="header-button"
      />
      <span class="beauty-profile-answers__header">
        <b class="beauty-profile-answers__header__title"
          >Grupo/Pergunta/Alternativa</b
        >
        <b>Respostas</b>
        <b>% Assinantes</b>
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="attribute" let-node>
    <span class="beauty-profile-answers__label">
      <span
        class="beauty-profile-answers__label__title"
        [class.beauty-profile-answers__label__title--disabled]="
          !node.data.active
        "
      >
        {{ node.data.order }}.
        {{ node.data.attributeQuestion || node.data.attributeName }}
      </span>
      <span class="beauty-profile-answers__label__total">
        {{ attributeAnswerCount(node.data.attributeId) | number : '1.0-0' }}
      </span>
      <span class="beauty-profile-answers__label__percentage">
        {{ 100 * attributeAnswerPercentage(node) | number : '1.2-2' }}
        %
      </span>
    </span>
  </ng-template>
  <ng-template pTemplate="attributeGroup" let-node>
    <span class="beauty-profile-answers__label">
      <span
        class="beauty-profile-answers__label__title"
        [class.beauty-profile-answers__label__title--disabled]="
          !node.data.active
        "
      >
        {{ node.data.order }}.
        {{ node.data.attributeGroupQuestion || node.data.attributeGroupName }}
      </span>
      <span class="beauty-profile-answers__label__total">
        {{ attributeGroupAnswerCount(node) | number : '1.0-0' }}
      </span>
      <span class="beauty-profile-answers__label__percentage">
        {{ attributeGroupAnswerPercentage(node) * 100 | number : '1.2-2' }}
        %
      </span>
    </span>
  </ng-template>
  <ng-template pTemplate="attributeOption" let-node>
    <span class="beauty-profile-answers__label">
      <span
        class="beauty-profile-answers__label__title"
        [class.beauty-profile-answers__label__title--disabled]="
          !node.data.active
        "
      >
        {{ node.data.order }}. {{ node.data.attributeOptionName }}
      </span>
      <span class="beauty-profile-answers__label__total">
        {{
          attributeOptionAnswerCount(node.data.attributeOptionId)
            | number : '1.0-0'
        }}
      </span>
      <span class="beauty-profile-answers__label__percentage">
        {{ 100 * attributeOptionAnswerPercentage(node) | number : '1.2-2' }}
        %
      </span>
    </span>
  </ng-template>
</p-tree>

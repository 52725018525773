<ng-container *ngIf="institution">
  <div class="page-title">Detalhes da instituiçao</div>
  <div class="details institution-details">
    <div class="details__left">
      <div class="card card-user">
        <div class="card-body">
          <div>
            <img
              class="avatar"
              [src]="institution.logoUrl || institution.iconUrl"
              *ngIf="institution.logoUrl || institution.iconUrl"
            />
            <h4>{{ institution.institutionName }}</h4>
            <p>
              <strong>Razão social</strong>: {{ institution.legalEntityName }}
            </p>
            <p *ngIf="institution.website">
              <strong>Website</strong>:
              <a
                class="data-link"
                target="_blank"
                [href]="institution.website"
                >{{ institution.website }}</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="details__right">
      <div class="card">
        <p-tabView
          [activeIndex]="selectedTab"
          (activeIndexChange)="tabChanged($event)"
        >
          <p-tabPanel header="Informações gerais">
            <form
              class="form-b4a"
              (submit)="saveInstitution()"
              [formGroup]="form"
            >
              <span class="input-switch">
                <label for="active">Inativo</label>
                <p-inputSwitch formControlName="active" inputId="active" />
                <label for="active">Ativo</label>
              </span>
              <span class="image-upload full-w">
                <img *ngIf="form.value.logoUrl" [src]="form.value.logoUrl" />
                <div class="file-upload">
                  <span class="p-float-label">
                    <input
                      type="text"
                      [disabled]="true"
                      pInputText
                      formControlName="logoUrl"
                    />
                    <label for="logoUrl">Logo *</label>
                  </span>
                  <p-fileUpload
                    mode="basic"
                    chooseLabel="Selecionar"
                    name="file"
                    [auto]="true"
                    accept="image/*"
                    [maxFileSize]="1000000"
                    uploadIcon="pi pi-file"
                    [customUpload]="true"
                    (uploadHandler)="onUpload($event, 'logoUrl', logoUpdload)"
                    id="logoUrl"
                    #logoUpdload
                    [disabled]="form.controls.logoUrl.disabled"
                  />
                </div>
              </span>
              <span class="image-upload full-w">
                <img *ngIf="form.value.iconUrl" [src]="form.value.iconUrl" />
                <div class="file-upload">
                  <span class="p-float-label">
                    <input
                      type="text"
                      [disabled]="true"
                      pInputText
                      formControlName="iconUrl"
                    />
                    <label for="iconUrl">Ícone *</label>
                  </span>
                  <p-fileUpload
                    mode="basic"
                    chooseLabel="Selecionar"
                    name="file"
                    [auto]="true"
                    accept="image/*"
                    [maxFileSize]="1000000"
                    uploadIcon="pi pi-file"
                    [customUpload]="true"
                    (uploadHandler)="onUpload($event, 'iconUrl', iconUpload)"
                    id="iconUrl"
                    #iconUpload
                    [disabled]="form.controls.iconUrl.disabled"
                  />
                </div>
              </span>
              <span class="buttons">
                <p-button
                  label="Salvar"
                  [disabled]="!form.valid"
                  type="submit"
                  styleClass="p-button-primary"
                />
              </span>
            </form>
          </p-tabPanel>
          <p-tabPanel header="Pagamentos">
            <ng-template pTemplate="content">
              <app-table
                [cols]="paymentCols"
                [findPage]="findPage"
                [service]="financialService"
                *ngIf="fixedFilters"
                [fixedFilters]="fixedFilters"
                [dropdownFilters]="dropdownFilters"
                [defaultRows]="10"
                modelName="pagamentos"
                [addButton]="false"
                sortField="dateCreated"
                [sortOrder]="1"
              />
            </ng-template>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>
  </div>
</ng-container>
<p-toast></p-toast>

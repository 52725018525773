import { Component, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import {
  DontReceiveGift,
  ItOpsControllerService,
  MovementControllerService
} from 'src/app/admin-api';
import { TableColumn, TableComponent } from 'src/app/components/table';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-dont-receive-gift',
  templateUrl: './dont-receive-gift.component.html',
  styleUrls: ['./dont-receive-gift.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class DontReceiveGiftComponent {
  @ViewChild(TableComponent) table: TableComponent;

  columns: Array<TableColumn> = [
    new TableColumn('', '', false, 'checkbox'),
    new TableColumn(
      'PersonId',
      'personid',
      true,
      'number',
      '/users/person/',
      'personId'
    ),
    new TableColumn('RewardId', 'rewardId', true, 'number'),
    new TableColumn(
      'Nome',
      'nome',
      true,
      'text',
      '/users/person/',
      'personId',
      true,
      'contains'
    ),
    new TableColumn(
      'E-mail',
      'email',
      true,
      'text',
      '/users/person/',
      'personId',
      true,
      'equals'
    ),
    new TableColumn(
      'Cupom',
      'coupon',
      true,
      'text',
      '/marketing/coupons/',
      'couponId',
      true,
      'contains'
    ),
    new TableColumn('CouponId', 'couponId', true, 'number'),
    new TableColumn('Sku', 'sku', true, 'number'),
    new TableColumn('Produto', 'produto', true, 'text'),
    new TableColumn(
      'Data Criação',
      'DateCreated',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    )
  ];

  constructor(
    public itOpsService: ItOpsControllerService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private movementService: MovementControllerService
  ) {}

  fixGifts(elements: Array<DontReceiveGift>): void {
    this.confirmationService.confirm({
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      rejectButtonStyleClass: 'p-button-danger',
      message: 'Deseja reenviar os brindes não recebidos?',
      header: 'Reenviar brindes',
      accept: async () => {
        LoaderService.showLoader();
        try {
          await lastValueFrom(
            this.movementService.fixDontReceiveGifts(elements)
          );
          await this.table.refresh(true);
          this.messageService.add({
            summary: 'Sucesso',
            detail: elements.length + ' brinde(s) reenviado(s).',
            severity: 'success'
          });
          LoaderService.showLoader(false);
        } catch (error) {
          LoaderService.showLoader(false);
          AppDialogService.showErrorDialog(error);
        }
      }
    });
  }
}

import { Injectable, inject } from '@angular/core';
import { lastValueFrom, map } from 'rxjs';
import {
  CustomQuery,
  CustomQueryControllerService,
  CustomQueryDetail
} from '../admin-api';
import { FileUtil } from '../utils/file.util';
import { PWAUtil } from '../utils/pwa.util';
import { AppDialogService } from './dialog.service';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root'
})
export class CustomQueryService {
  private customQueryService: CustomQueryControllerService = inject(
    CustomQueryControllerService
  );

  async export(
    action: 'exportCSV' | 'exportExcel' | 'exportHTML' | 'exportXML',
    query: CustomQuery | CustomQueryDetail
  ): Promise<void> {
    if (action === 'exportHTML') {
      window.open(
        window.location.origin +
          '/research/custom-query/html/' +
          query.customQueryId,
        PWAUtil.isPWA ? '_self' : '_blank'
      );
    } else {
      LoaderService.showLoader();
      try {
        const content = await lastValueFrom(
          this.customQueryService
            .runQueryCommand(query.customQueryId)
            .pipe(map((data) => data.result))
        );
        if (content.length) FileUtil[action](content, query.queryName);
        else
          AppDialogService.showErrorDialog({
            message: 'A consulta não retornou resultados.'
          });
      } catch (error) {
        AppDialogService.showErrorDialog(error);
      }
      LoaderService.showLoader(false);
    }
  }
}

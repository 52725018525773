import {
  Component,
  HostListener,
  inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  CouponUsageType,
  CouponUsageTypeByCouponName
} from 'src/app/admin-api';
import { SubscriberGroupEnum } from 'src/app/models';
import { CouponsRankingChartComponent } from '../coupons-ranking-chart.component';

@Component({
  selector: 'app-coupons-ranking-chart-dialog',
  templateUrl: './coupons-ranking-chart-dialog.component.html',
  styleUrl: './coupons-ranking-chart-dialog.component.scss'
})
export class CouponsRankingChartDialogComponent implements OnInit {
  @ViewChild(CouponsRankingChartComponent)
  chart: CouponsRankingChartComponent | undefined;

  private config = inject(DynamicDialogConfig);
  ref = inject(DynamicDialogRef);

  couponUsages: Array<CouponUsageTypeByCouponName> | undefined;
  couponUsagesAcquisition: Array<CouponUsageTypeByCouponName> | undefined;
  couponUsagesReactivation: Array<CouponUsageTypeByCouponName> | undefined;
  couponUsageType: CouponUsageType;
  couponUsageTypeAcquisition: CouponUsageType;
  couponUsageTypeReactivation: CouponUsageType;
  totals: { couponName: string; total: number }[];
  totalsAcquisition: { couponName: string; total: number }[];
  totalsReactivation: { couponName: string; total: number }[];
  responsive = true;
  width = '700px';
  height = '500px';
  tabIndex = 0;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.checkScreenSize();
  }

  checkScreenSize() {
    if (window.innerWidth < 768) this.responsive = false;
    else this.responsive = true;
  }

  ngOnInit() {
    this.checkScreenSize();
    this.couponUsages = this.config.data.couponUsages;
    this.tabIndex = this.config.data.tabIndex || 0;
    this.couponUsagesAcquisition =
      this.couponUsages?.filter(
        (c) =>
          c.subscriberGroupId === SubscriberGroupEnum.Conquistadas_com_pagamento
      ) || [];
    this.couponUsagesReactivation =
      this.couponUsages?.filter(
        (c) =>
          c.subscriberGroupId === SubscriberGroupEnum.Reativacoes_com_pagamento
      ) || [];
    this.couponUsageType = this.config.data.couponUsageType;
    this.totals = this.couponUsages.reduce(
      (totals: { couponName: string; total: number }[], c) => {
        const exists = totals.find(
          (t) => t.couponName === c.couponName.toUpperCase()
        );
        if (exists) exists.total += c.total || 0;
        else
          totals.push({
            couponName: c.couponName.toUpperCase(),
            total: c.total
          });
        return totals;
      },
      []
    );
    this.totalsAcquisition = this.couponUsagesAcquisition.reduce(
      (totals: { couponName: string; total: number }[], c) => {
        const exists = totals.find(
          (t) => t.couponName === c.couponName.toUpperCase()
        );
        if (exists) exists.total += c.total || 0;
        else
          totals.push({
            couponName: c.couponName.toUpperCase(),
            total: c.total
          });
        return totals;
      },
      []
    );
    this.totalsReactivation = this.couponUsagesReactivation.reduce(
      (totals: { couponName: string; total: number }[], c) => {
        const exists = totals.find(
          (t) => t.couponName === c.couponName.toUpperCase()
        );
        if (exists) exists.total += c.total || 0;
        else
          totals.push({
            couponName: c.couponName.toUpperCase(),
            total: c.total
          });
        return totals;
      },
      []
    );
    this.totals.sort((c1, c2) => c2.total - c1.total);
    this.totalsAcquisition.sort((c1, c2) => c2.total - c1.total);
    this.totalsReactivation.sort((c1, c2) => c2.total - c1.total);
    this.ref.onMaximize.subscribe((event: { maximized: boolean }) => {
      if (this.chart) {
        if (event.maximized) {
          this.chart.responsive = false;
          this.chart.width = (window.innerWidth - 50).toString().concat('px');
          this.chart.height = (window.innerHeight - 100)
            .toString()
            .concat('px');
        } else if (this.chart.height && this.chart.width) {
          this.chart.responsive = true;
          this.chart.checkScreenSize();
          document.body.classList.add('');
        }
        this.chart.resize();
      }
    });
  }
}

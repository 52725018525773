<div
  class="analytics-dashboard"
  [class.analytics-dashboard--no-layout]="defaultSubscriptionId !== undefined"
  [class.analytics-dashboard--hidden]="
    defaultSubscriptionId !== undefined && show !== undefined && !show()
  "
  [class.analytics-dashboard--fade]="show !== undefined && show()"
>
  <ng-container *ngIf="defaultSubscriptionId === undefined">
    <h3>Dashboard frequências</h3>
    <div class="filters">
      <form class="form-b4a">
        <span class="p-float-label">
          <p-dropdown
            inputId="subscriptionId"
            [options]="subscriptions"
            (onChange)="changeSubscription()"
            [formControl]="subscriptionId"
            appendTo="body"
            [autoDisplayFirst]="false"
          >
          </p-dropdown>
          <label for="subscriptionId">Assinatura</label>
        </span>
      </form>
    </div>
  </ng-container>
  <h3 *ngIf="defaultSubscriptionId !== undefined">
    Previsão de vendas - {{ subscription?.label }}
  </h3>
  <div
    class="totals"
    *ngIf="subscribers?.length && subscribersRealtime?.length"
  >
    <div class="dashboard-card">
      <span
        class="header"
        [class.header--success]="
          subscribersRealtimeTotal()?.total >= totalSubscriptionsTillTime
        "
        [class.header--danger]="
          subscribersRealtimeTotal()?.total < totalSubscriptionsTillTime
        "
        >Acumulado do dia</span
      >
      <span
        >{{
          subscribersRealtimeTotal()?.total | number : '1.0-0'
        }}
        assinaturas</span
      >
    </div>
    <div class="dashboard-card">
      <span class="header header--primary">Acumulado médio (últimos 7d)</span>
      <span
        >{{ totalSubscriptionsTillTime | number : '1.2-2' }} assinaturas</span
      >
    </div>
    <div class="dashboard-card">
      <span
        class="header"
        [class.header--success]="subscriptionsForecast >= avgSubscriptions"
        [class.header--danger]="subscriptionsForecast < avgSubscriptions"
        >Previsão do dia</span
      >
      <span>{{ subscriptionsForecast | number : '1.0-0' }} assinaturas</span>
    </div>
    <div class="dashboard-card">
      <span class="header header--primary">Média diária (últimos 7d)</span>
      <span>{{ avgSubscriptions | number : '1.2-2' }} assinaturas</span>
    </div>
  </div>
  <div
    class="dashboard"
    *ngIf="subscribers?.length && subscribersRealtime?.length"
  >
    <div class="right-info">
      <p-chart
        type="bar"
        [data]="chartData"
        [options]="chartConfig"
        id="chart"
        [responsive]="false"
        [width]="width"
        height="60vh"
        #chart
        *ngIf="chartData && width"
      />
    </div>
  </div>
</div>

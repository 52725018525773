import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PickListModule } from 'primeng/picklist';
import { TooltipModule } from 'primeng/tooltip';
import { SubscriberCancellationModalComponent } from './subscriber-cancellation-modal.component';

@NgModule({
  declarations: [SubscriberCancellationModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DropdownModule,
    InputTextareaModule,
    ButtonModule,
    DialogModule,
    PickListModule,
    ConfirmDialogModule,
    CheckboxModule,
    TooltipModule
  ],
  exports: [SubscriberCancellationModalComponent]
})
export class SubscriberCancellationModalModule {}

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  Brand,
  FeedbackForm,
  FileControllerService,
  Product,
  ProductCategory,
  ProductControllerService,
  ProductCreateRequest,
  ProductFinality,
  ProductFinalityItem,
  ProductImage,
  ProductImageRequest,
  ProductUpdateRequest,
  ProductVariant
} from 'src/app/admin-api';
import { ShopProduct } from 'src/app/connect-api/models/ShopProduct';
import {
  DescriptionField,
  getDescriptionFieldFromControl
} from 'src/app/models';
import { ProductDetailsComponent } from 'src/app/pages/products/product-details/product-details.component';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';
import { ImageUploadComponent } from '../image-upload/image-upload.component';
import { ProductDiffModalComponent } from '../product-diff-modal/product-diff-modal.component';
@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss'],
  providers: [MessageService],
  encapsulation: ViewEncapsulation.None
})
export class ProductFormComponent
  extends ImageUploadComponent<Product>
  implements OnInit, OnChanges
{
  @Input()
  product: Product | undefined;

  @Input()
  category: ProductCategory | undefined;

  @Input()
  brands: Array<Brand> | undefined;

  @Input()
  categories?: Array<ProductCategory & { parentExternalId?: number }>;

  @Input()
  feedbackForms: Array<FeedbackForm> | undefined;

  @Input()
  productVariants: Array<ProductVariant> | undefined;

  @Output()
  updated = new EventEmitter<Product>();

  @Input()
  isMobile?: boolean;

  @Input()
  finalities: Array<ProductFinality> | undefined;

  @Input()
  productFinalities: Array<ProductFinalityItem> | undefined;

  @Input()
  images: Array<ProductImage> | undefined;

  @Input()
  shopProduct: ShopProduct;

  productTypes: Array<{ label: string; value: number }> = [
    { label: 'RegularProduct', value: 1 },
    { label: 'EditionProduct', value: 2 }
  ];
  parentCategories: Array<{
    label: string;
    value: number;
    items: Array<{ label: string; value: number }>;
  }> = [];
  filteredFinalities: Array<ProductFinality> = [];
  override form = new FormGroup({
    productId: new FormControl<number>(
      { value: null, disabled: true },
      Validators.required
    ),
    productName: new FormControl<string>(null, Validators.required),
    defaultImageURL: new FormControl<string>(null),
    brandId: new FormControl<number>(null, Validators.required),
    defaultProductVariantId: new FormControl<number>(null),
    productTypeId: new FormControl<number>(null, Validators.required),
    showOnWebsite: new FormControl(0, Validators.required),
    productSummary: new FormControl<string>(null, [
      Validators.required,
      Validators.maxLength(4000)
    ]),
    categoryId: new FormControl<number>(null),
    activeFeedbackId: new FormControl<number>(null),
    differentials: new FormControl<string>(null, Validators.maxLength(4000)),
    whoMustUse: new FormControl<string>(null, Validators.maxLength(4000)),
    howToUse: new FormControl<string>(null, Validators.maxLength(4000)),
    aboutTheBrand: new FormControl<string>(null, Validators.maxLength(4000)),
    ingredients: new FormControl<string>(null, Validators.maxLength(4000)),
    finalities: new FormControl<ProductFinality[]>([])
  });

  constructor(
    private productService: ProductControllerService,
    override router: Router,
    override fileService: FileControllerService,
    private cdRef: ChangeDetectorRef,
    private messageService: MessageService,
    private dialog: DialogService
  ) {
    super(fileService, router);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['product']) {
      this.startForm();
    }
  }

  ngOnInit() {
    this.startForm();
  }

  startForm() {
    this.form.patchValue({
      ...this.product,
      finalities:
        this.productFinalities?.map(
          (pf) =>
            this.finalities?.find(
              (f) => f.productFinalityId === pf.productFinalityId
            ) as ProductFinality
        ) || []
    });
    if (this.product) this.form.controls.productId.enable();
    this.categories?.forEach((c) => {
      let parent;
      if (c.parentId) {
        parent = this.categories?.find(
          (parent) => parent.categoryId === c.parentId
        );
      }
      c.displayName = `${
        parent
          ? parent.externalId + '.' + c.externalId + ' '
          : c.externalId + ' '
      } ${c.displayName}`;
      c.parentExternalId = parent?.externalId || c.externalId;
    });
    this.categories?.sort((c1, c2) => {
      if ((c1.parentExternalId as number) < (c2.parentExternalId as number)) {
        return -1;
      } else if (
        (c1.parentExternalId as number) > (c2.parentExternalId as number)
      ) {
        return 1;
      } else if ((c1.parentId as number) < (c2.parentId as number)) {
        return -1;
      } else if ((c1.parentId as number) > (c2.parentId as number)) {
        return 1;
      } else {
        return (c1.externalId as number) - (c2.externalId as number);
      }
    });
    this.filteredFinalities = this.finalities || [];
  }

  async saveProduct(): Promise<void> {
    if (this.form?.valid) {
      LoaderService.showLoader();
      try {
        if (this.product?.productId) {
          const request: ProductUpdateRequest = {
            ...this.form.value
          } as ProductUpdateRequest;
          this.product = await lastValueFrom(
            this.productService
              .updateProduct(request)
              .pipe(map((data) => data.result))
          );
          await this.saveProductImages();
          await this.saveProductFinalities();
          this.updated.emit(this.product);
          this.messageService.add({
            severity: 'success',
            detail: 'Produto atualizado com sucesso',
            summary: 'Sucesso'
          });
        } else {
          const request: ProductCreateRequest = {
            ...this.form.value
          } as ProductCreateRequest;
          this.product = await lastValueFrom(
            this.productService
              .createProduct(request)
              .pipe(map((data) => data.result))
          );
          await this.saveProductImages();
          await this.saveProductFinalities();
          this.messageService.add({
            severity: 'success',
            detail: 'Produto cadastrado com sucesso',
            summary: 'Sucesso'
          });
          this.router.navigate([
            'products/catalog/product/' + this.product?.productId
          ]);
        }
      } catch (error: any) {
        AppDialogService.showErrorDialog(error);
      }
      LoaderService.showLoader(false);
    }
  }

  async saveProductImages(): Promise<void> {
    if (this.filesUploaded.length) {
      const images: Array<ProductImageRequest> = [];
      for (let index = 0; index < this.filesUploaded.length; index++) {
        images.push({
          imageUrl: this.filesUploaded[index],
          position: index,
          productId: this.product?.productId
        });
      }
      try {
        await Promise.all(
          images.map((i) =>
            lastValueFrom(this.productService.addProductImage(i))
          )
        );
        await this.afterSubmit();
      } catch (err: any) {
        AppDialogService.showErrorDialog(err);
      }
    }
  }

  async saveProductFinalities(): Promise<void> {
    try {
      this.productFinalities = await lastValueFrom(
        this.productService
          .updateProductFinalities(
            this.product?.productId as number,
            this.form?.value.finalities?.map(
              (f: ProductFinality) => f.productFinalityId
            )
          )
          .pipe(map((data) => data.result))
      );
    } catch (err: any) {
      AppDialogService.showErrorDialog(err);
    }
  }

  changeCategory(categoryId: number) {
    this.form?.controls['categoryId'].setValue(categoryId);
    this.cdRef.detectChanges();
  }

  imageVariant(productVariantId?: number): string {
    return (
      this.productVariants?.find((p) => p.productVariantId === productVariantId)
        ?.productVariantName || 'Sem variante'
    );
  }

  filterFinalities($event: { originalEvent: Event; query: string }): void {
    const exists = this.filteredFinalities.find(
      (f) => f.slug === FormUtil.getSlug($event.query)
    );
    this.filteredFinalities = [];
    if ($event.query.trim().length && !exists)
      this.filteredFinalities.push({
        name: $event.query,
        slug: FormUtil.getSlug($event.query)
      });
    this.filteredFinalities = this.filteredFinalities.concat(
      this.finalities?.filter((f) =>
        f.slug?.includes(FormUtil.getSlug($event.query))
      ) || []
    );
  }

  async onFinalitySelect(finality: ProductFinality): Promise<void> {
    if (!finality.productFinalityId) {
      finality =
        (await lastValueFrom(
          this.productService
            .addProductFinality(finality.name)
            .pipe(map((data) => data.result))
        )) || finality;
      if (finality.productFinalityId) {
        this.form?.controls['finalities'].setValue(
          (this.form.value.finalities as Array<ProductFinality>)
            .filter((f) => f.productFinalityId)
            .concat([finality])
        );
        this.finalities?.push(finality);
        this.finalities?.sort((f1, f2) => {
          if (f1.slug && f2.slug && f1.slug < f2.slug) return -1;
          else if (f1.slug && f2.slug && f1.slug > f2.slug) return 1;
          return 0;
        });
      }
    }
  }

  async syncInfo(column: DescriptionField): Promise<void> {
    this.dialog
      .open(ProductDiffModalComponent, {
        data: {
          product: this.product,
          shopProduct: this.shopProduct,
          column
        },
        width: '60%',
        height: '90vh',
        header: 'Sincronizar ' + this.product.productName,
        maximizable: true
      })
      .onClose.subscribe(async (success) => {
        if (success) {
          LoaderService.showLoader();
          this.updated.emit();
          this.messageService.add({
            summary: 'Sucesso',
            severity: 'success',
            detail: `Campo ${column.label} sincronizado com sucesso.`
          });
          LoaderService.showLoader(false);
        }
      });
  }

  hasSyncInfo(controlName: string) {
    return ProductDetailsComponent.hasSyncInfo(
      this.descriptionField(controlName),
      this.form.value,
      this.shopProduct
    );
  }

  descriptionField(controlName: string) {
    const field = getDescriptionFieldFromControl(controlName);
    return field;
  }

  get filePath(): string {
    return '/admin/products/';
  }

  override get model() {
    return this.product;
  }

  override get modelId() {
    return this.product?.productId | 0;
  }
}

import { Inject, Injectable } from '@angular/core';
import { Home, HomeRepository } from '@infrab4a/connect';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  static EDITABLE_SECTIONS: {
    id: string;
    label: string;
    type: 'Banner[]' | 'Banner' | 'string[]';
    hidden?: boolean;
  }[] = [
    { id: 'heroCarousel', label: 'Carrossel', type: 'Banner[]' },
    { id: 'featuredCategories', label: 'Destaques', type: 'string[]' },
    { id: 'buyToWinBanner', label: 'Banner (Compre e ganhe)', type: 'Banner' },
    { id: 'discoverCategories', label: 'Descubra', type: 'string[]' },
    { id: 'blockBanners', label: 'Block Banners', type: 'Banner[]' },
    { id: 'verticalCarousels', label: 'Marcas verticais', type: 'string[]' },
    { id: 'blogBanner', label: 'Banner (Blog)', type: 'Banner' },
    {
      id: 'featuredCampaignBanner',
      label: 'Banner campanha (página de produto)',
      type: 'Banner',
      hidden: true
    }
  ];
  constructor(
    @Inject('HomeRepository')
    private homeRepository: HomeRepository
  ) {}

  async get(id: 'glamshop' | 'mens_market') {
    return await this.homeRepository.get({ id });
  }

  async update(home: Partial<Home>) {
    return await this.homeRepository.update(home);
  }
}

<ng-container *ngIf="product">
  <div class="product-detail__header">Loja - Detalhes do Produto</div>
  <div class="product-detail__content">
    <div class="product-detail__content__card">
      <div class="card card-user">
        <div class="image"></div>
        <div class="card-body">
          <div class="user">
            <img
              class="avatar"
              *ngIf="product.images?.length"
              [src]="product.images[0]"
            />
            <img
              class="avatar"
              src="assets/images/no_img_available.jpg"
              *ngIf="!product.images?.length"
            />
            <h4>{{ product.name }}</h4>
            <span class="rating">
              <p-rating
                [formControl]="rating"
                [stars]="5"
                [readonly]="true"
                [cancel]="false"
              >
                <ng-template pTemplate="onicon">
                  <i class="pi pi-star-fill"></i>
                </ng-template>
                <ng-template pTemplate="officon">
                  <i class="pi pi-star"></i>
                </ng-template>
              </p-rating>
              &nbsp;
              {{ rating.value }}
            </span>
            <span *ngIf="reviewsCount && reviewsCount > 1">
              {{ reviewsCount | number : '1.0-0' }} avaliações
            </span>
            <span *ngIf="reviewsCount && reviewsCount === 1">
              1 avaliação
            </span>
            <span *ngIf="!reviewsCount">Sem avaliações</span>
            <p *ngIf="product.brand"><b>Marca</b>: {{ product.brand }}</p>
            <p *ngIf="product.EAN"><b>EAN</b>: {{ product.EAN }}</p>
            <p *ngIf="product.sku"><b>SKU</b>: {{ product.sku }}</p>
            <p *ngIf="product.costPrice">
              <b>Preço custo</b>: {{ product.costPrice | currency : 'BRL' }}
            </p>
            <p *ngIf="productVariants?.length">
              <b>Preço custo médio (variantes)</b>:
              {{ avgVariantsCostPrice | currency : 'BRL' }}
            </p>
            <p>
              <b>Estoque</b>:
              {{ product.stock?.quantity || 0 | number : '1.0-0' }}
            </p>
            <p>
              <b>Categoria</b>: <span [innerHTML]="categoryTreeLabel"></span>
            </p>
            <p *ngIf="subscriptionProduct?.productId" class="with-button">
              <span>
                <b>Disponível Assinatura</b>:
                <a
                  [routerLink]="
                    '/products/catalog/product/' + subscriptionProduct.productId
                  "
                  class="data-link"
                >
                  {{ subscriptionProduct.productName | titlecase }}
                </a>
              </span>
              <ng-container *ngIf="toSync?.length">
                <p-button
                  icon="pi pi-sync"
                  styleClass="p-button-primary p-button-xs"
                  [text]="true"
                  [rounded]="true"
                  [pTooltip]="'Sincronizar campos divergentes: ' + toSyncString"
                  (onClick)="syncInfo()"
                />
              </ng-container>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="product-detail__content__tabs">
      <div class="card">
        <p-tabView
          [activeIndex]="selectedTab"
          (activeIndexChange)="tabChanged($event)"
        >
          <p-tabPanel header="Informações">
            <app-shop-product-form
              [product]="product"
              [categories]="categories"
              *ngIf="categories"
              (productUpdated)="findProduct()"
            />
          </p-tabPanel>
          <p-tabPanel header="Descrições">
            <app-shop-product-description-form
              [product]="product"
              (productUpdated)="findProduct()"
              [subscriptionProduct]="subscriptionProduct"
            />
          </p-tabPanel>
          <p-tabPanel
            [header]="
              'Variantes (' +
              (productVariants?.length || 0 | number : '1.0-0') +
              ')'
            "
            *ngIf="product.hasVariants"
          >
            <app-table
              *ngIf="variantCols && productVariants && !productVariant"
              [cols]="variantCols"
              [lazy]="false"
              [elements]="productVariants"
              modelName="variantes"
              [showTitle]="false"
              sortField="grade.value"
              [addButton]="false"
              [sortOrder]="-1"
              [changeColumns]="false"
              [defaultRows]="15"
              linkTarget="_action"
              [actionFields]="['grade.title', 'grade.value', 'EAN', 'sku']"
              (linkAction)="productVariant = $event.value"
            />
            <ng-container *ngIf="productVariant">
              <p-button
                [text]="true"
                [rounded]="true"
                pRipple
                label="Voltar"
                icon="pi pi-arrow-left"
                (onClick)="backFromVariantForm()"
              ></p-button>
              <app-shop-product-variant-form [variant]="productVariant" />
            </ng-container>
          </p-tabPanel>
          <p-tabPanel header="Imagens">
            <div class="product-detail__content__tabs__images__upload">
              <p-fileUpload
                mode="basic"
                chooseLabel="Adicionar imagem"
                name="file"
                [auto]="true"
                [customUpload]="true"
                (uploadHandler)="onFileSelected($event, fileUpload)"
                accept="image/*"
                [maxFileSize]="10 * 1024 * 1024"
                uploadIcon="pi pi-file"
                id="fileUpload"
                [multiple]="true"
                #fileUpload
              ></p-fileUpload>
              <small *ngIf="!fileValidationMessage">
                Extensões: JPG, JPEG, PNG ou GIF | Tamanho: até 10mb
              </small>
            </div>
            <app-drag-drop-list
              [list]="product.images"
              [template]="imageTemplate"
              (listChanged)="saveImageSort($event)"
            >
              <ng-template #imageTemplate let-image="data">
                <p-button
                  [rounded]="true"
                  severity="danger"
                  [text]="true"
                  icon="pi pi-trash"
                  pTooltip="Excluir imagem"
                  tooltipPosition="bottom"
                  type="button"
                  (onClick)="confirmDeleteImage(image, $event)"
                ></p-button>
                <img style="width: 100%" [src]="image" alt="" />
              </ng-template>
            </app-drag-drop-list>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>
  </div>
</ng-container>
<p-confirmPopup styleClass="danger"></p-confirmPopup>
<p-toast />

import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import { BlacklistControllerService } from 'src/app/admin-api';
import { AppDialogService } from 'src/app/services/dialog.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-blacklist-cpf-form',
  templateUrl: './blacklist-cpf-form.component.html',
  styleUrls: ['./blacklist-cpf-form.component.scss']
})
export class BlacklistCpfFormComponent {
  private blacklistService = inject(BlacklistControllerService);
  private ref = inject(DynamicDialogRef);

  form = new FormGroup({
    cpf: new FormControl<number>(
      null,
      Validators.required,
      FormUtil.cpfValidator()
    ),
    reason: new FormControl<string>(null, [
      Validators.required,
      Validators.maxLength(250)
    ])
  });

  async submit(): Promise<void> {
    try {
      const blocked = await lastValueFrom(
        this.blacklistService
          .addCpfToBlacklist(this.form.value)
          .pipe(map((data) => data.result))
      );
      this.ref.close(blocked);
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { ShopOrderAddressFormComponent } from './shop-order-address-form.component';

@NgModule({
  declarations: [ShopOrderAddressFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextModule,
    CalendarModule,
    ToastModule,
    InputMaskModule,
    DropdownModule
  ],
  exports: [ShopOrderAddressFormComponent]
})
export class ShopOrderAddressFormModule {}

import {
  ChangeDetectorRef,
  Component,
  QueryList,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { Dialog } from 'primeng/dialog';
import { DialogService } from 'primeng/dynamicdialog';
import { DialogOptions } from './models';
import { TokenStorageService } from './services/auth/token-storage.service';
import { LoaderService } from './services/loader.service';
import { BroadcastUtil } from './utils/broadcast.util';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [DialogService],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  @ViewChildren(Dialog)
  dialogRefs: QueryList<Dialog>;

  loader = false;
  dialog: DialogOptions | undefined;
  goBack = false;
  menuExpanded = true;
  permissionDenied = false;
  back = false;
  sessionExpired = false;

  constructor(
    private cdRef: ChangeDetectorRef,
    private permissionsService: NgxPermissionsService,
    private dialogService: DialogService
  ) {
    this.permissionsService.loadPermissions(
      TokenStorageService.userRolesList || []
    );
    LoaderService.progressSubscriber.subscribe((show: boolean) => {
      this.loader = show;
      this.cdRef.detectChanges();
    });
    BroadcastUtil.get('userChanged').subscribe(async () => {
      this.permissionsService.loadPermissions(
        TokenStorageService.userRolesList || []
      );
    });
    BroadcastUtil.get('dialog').subscribe(
      (result: { options: DialogOptions; goBack: boolean }) => {
        this.dialog = result.options;
        this.goBack = result.goBack;
        this.cdRef.detectChanges();
      }
    );
    BroadcastUtil.get('permissionDenied').subscribe(() => {
      if (!this.sessionExpired) {
        if (!this.permissionDenied) {
          if (this.dialogService.dialogComponentRefMap.size)
            this.dialogService.dialogComponentRefMap.forEach((r) =>
              r.destroy()
            );
          if (document.getElementsByTagName('p-dynamicdialog')?.length) {
            const dialogs = document.getElementsByTagName('p-dynamicdialog');
            for (let index = 0; index < dialogs.length; index++) {
              dialogs[index].remove();
            }
          }
        }
        this.permissionDenied = true;
      }
    });
    BroadcastUtil.get('tokenExpired').subscribe(() => {
      if (!this.sessionExpired)
        if (this.dialogService.dialogComponentRefMap.size)
          this.dialogService.dialogComponentRefMap.forEach((r) => r.destroy());
      this.sessionExpired = true;
    });
  }

  goBackHistory(): void {
    if (this.dialog?.visible) this.dialog.visible = false;
    if (window.history.length && window.history.length > 1)
      window.history.back();
    else window.close();
  }

  backFromPermission(): void {
    if (this.permissionDenied) this.permissionDenied = false;
  }

  menuStateChanged($event: boolean): void {
    this.menuExpanded = $event;
    this.cdRef.detectChanges();
  }

  login(): void {
    if (this.sessionExpired) this.sessionExpired = false;
    window.location.reload();
  }

  get userId(): number {
    return TokenStorageService.userId;
  }

  get noLayout(): boolean {
    return window.location.pathname.split('/')[1].includes('home');
  }
}

import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Role } from 'src/app/models';
import { AuthGuard } from 'src/app/services/auth/auth-guard';
import { AccessLookerComponent } from './access-looker/access-looker.component';
import { SettingsComponent } from './settings.component';
import { SystemUsersComponent } from './system-users/system-users.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'account'
  },
  {
    path: 'account',
    component: SettingsComponent
  },
  {
    path: 'system-users',
    component: SystemUsersComponent,
    canActivate: [
      () => inject(AuthGuard).canActivatePermissions([Role.Full_Administrator])
    ]
  },
  {
    path: 'access-looker',
    component: AccessLookerComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule {}

/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ProductVariantMovementDetail {
  movementId?: number;
  productVariantId?: number;
  personId?: number;
  movementDate?: Date;
  type?: string;
  productVariantName?: string;
  internalEAN?: string;
  productId?: number;
  personName?: string;
  externalId?: number;
  brandName?: string;
  brandEquity?: number;
  brandId?: number;
  quantity?: number;
  referenceName?: string;
  expiration?: string;
}

import {
  Component,
  computed,
  HostListener,
  inject,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import { SubscriptionsTotalResponse } from 'src/app/admin-api';
import { Message, Topic } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { WebsocketService } from 'src/app/services/websocket.service';
import { FormUtil } from 'src/app/utils/form.util';
import { AnalyticsDashboardComponent } from '../dashboards/analytics-dashboard/analytics-dashboard.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild(AnalyticsDashboardComponent)
  analytics: AnalyticsDashboardComponent;

  private websocketService = inject(WebsocketService);

  width = computed(() =>
    this.subscriptionId() !== undefined ? 0 : this.clientWidth()
  );
  height = computed(() =>
    this.subscriptionId() !== undefined ? 0 : this.clientHeight()
  );
  clientHeight: WritableSignal<number> = signal(undefined);
  clientWidth: WritableSignal<number> = signal(undefined);
  index = signal(0);
  interval: NodeJS.Timeout;
  subscriptions = this.subscriptionsAvailable;
  subscriptionId = computed(() =>
    this.index() >= 0 ? this.subscriptions[this.index()] : undefined
  );
  signals: { [subscriptionId: number]: WritableSignal<boolean> } = {
    0: signal(true),
    1: signal(false)
  };
  lastUpdate = new Date();
  ready = false;
  subscribersRealtimeTotal: WritableSignal<SubscriptionsTotalResponse> =
    signal(undefined);

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.setSizes();
  }

  setSizes(): void {
    this.clientWidth.set(document.body.clientWidth - 5);
    this.clientHeight.set(document.body.clientHeight - 5);
  }

  ngOnInit(): void {
    this.connect();
    this.setSizes();
    this.ready = true;
    this.interval = setInterval(() => {
      if (this.subscribersRealtimeTotal()?.createdAt)
        this.updateSubscriptionId();
    }, 20000);
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
      delete this.interval;
    }
    this.websocketService.disconnect();
  }

  updateSubscriptionId(): void {
    if (this.index()) {
      this.index.set(0);
      this.signals[0].set(true);
      this.signals[1].set(false);
    } else {
      this.index.set(1);
      this.signals[0].set(false);
      this.signals[1].set(true);
    }
  }

  connect() {
    try {
      this.websocketService.connect(this.onMessage.bind(this));
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  onMessage(message: Message<any>): void {
    console.log(message);
    if (message.topic === Topic.SUBSCRIBER_UPDATE) {
      message.body = {
        ...message.body,
        dateAuthorized: FormUtil.utcDate(message.body.dateAuthorized)
      };
      this.analytics.addNewSubscriber(message.body);
    } else if (message.topic === Topic.REFRESH) {
      window.location.reload();
    }
  }

  get subscriptionsAvailable(): Array<number> {
    return [0];
  }
}

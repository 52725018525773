import { ReviewImportData } from '../types/review-import-data.type';

export class ProductReviewImportError extends Error {
  constructor(
    message: string,
    readonly reviews: ReviewImportData[],
    readonly sku: string
  ) {
    super(message);
  }
}

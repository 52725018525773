<app-table
  modelName="Avaliações"
  [service]="shopCouponService"
  pageFunctionName="getCouponsList"
  [cols]="cols"
  sortField="nickname"
  [sortOrder]="-1"
  [defaultRows]="25"
  [actionButtons]="actionButtons"
  [defaultFilterValues]="{ reviewStatus: 'pending' }"
  (actionButtonClick)="buttonClick($event)"
  (emitSelection)="handleSelection($event)"
  (booleanAction)="updateActive($event)"
  [dropdownFilters]="dropdownFilters"
  [addButton]="true"
  [changeColumns]="true"
  selectionActionLabel="Excluir cupons selecionados'"
  selectionActionTooltip="Excluir cupons selecionados"
  modelName="cupons"
  tableTitle="Loja - Cupons"
/>

<p-dialog
  *ngIf="selection.length"
  [(visible)]="showSelectionModal"
  [modal]="true"
  [style]="{ width: '80%' }"
  header="Excluir cupons selecionados"
>
  <app-table
    [lazy]="false"
    [elements]="selection"
    [cols]="modalColumns"
    [showHeader]="false"
    [dropdownFilters]="dropdownFilters"
    modelName="Avaliações Selecionadas"
  ></app-table>
  <div class="buttons">
    <p-button
      label="Excluir"
      styleClass="p-button-success"
      (onClick)="deleteMany(selection)"
    >
    </p-button>
  </div>
</p-dialog>

<p-confirmPopup></p-confirmPopup>

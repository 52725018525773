import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { BeautyProfileAnswersComponent } from './beauty-profile-answers.component';

@NgModule({
  declarations: [BeautyProfileAnswersComponent],
  imports: [CommonModule, TreeModule, ButtonModule, TooltipModule],
  exports: [BeautyProfileAnswersComponent]
})
export class BeautyProfileAnswersModule {}

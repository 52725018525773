import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import {
  PasswordUpdateRequest,
  UserControllerService,
  UserRoleViewResponse
} from 'src/app/admin-api';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-password-update',
  templateUrl: './password-update.component.html',
  styleUrls: ['./password-update.component.scss'],
  providers: [MessageService]
})
export class PasswordUpdateComponent implements OnChanges {
  @Input()
  user: UserRoleViewResponse | undefined;

  @Input()
  modal = false;

  @Output()
  closeModal = new EventEmitter<boolean>();

  passwordForm = new FormGroup({
    oldPassword: new FormControl<string | null>(null, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(20)
    ]),
    newPassword: new FormControl<string | null>(null, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(20)
    ]),
    newPasswordConfirmation: new FormControl<string | null>(null, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(20)
    ]),
    username: new FormControl<string | null>(null)
  });

  constructor(
    private userService: UserControllerService,
    private messageService: MessageService
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges) {
    if (this.modal) this.passwordForm.controls['oldPassword'].disable();
    else this.passwordForm.controls['oldPassword'].enable();
    this.passwordForm.controls['username'].setValue(
      this.user?.username as string
    );
  }

  async changePassword(): Promise<void> {
    if (this.passwordForm.valid) {
      LoaderService.showLoader();
      try {
        await lastValueFrom(
          this.userService.updateUserPassword(
            this.user?.userId as number,
            this.passwordForm.value as PasswordUpdateRequest
          )
        );
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'Senha atualizada com sucesso'
        });
        this.passwordForm.reset();
        this.closeModal.emit(true);
      } catch (error: any) {
        AppDialogService.showErrorDialog(error);
      }
      LoaderService.showLoader(false);
    }
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { lastValueFrom, map } from 'rxjs';
import {
  IncorrectSubscriberStatus,
  ItOpsControllerService,
  SubscriberControllerService
} from 'src/app/admin-api';
import {
  DropdownFilter,
  TableColumn,
  TableComponent
} from 'src/app/components/table';
import {
  SubscriberProblemGroupEnum,
  getAllSubscriberBillingStatus
} from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-incorrect-subscriber-status',
  templateUrl: './incorrect-subscriber-status.component.html',
  styleUrls: ['./incorrect-subscriber-status.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class IncorrectSubscriberStatusComponent implements OnInit {
  @ViewChild(TableComponent) table: TableComponent;

  columns: Array<TableColumn> = [
    new TableColumn('', '', false, 'checkbox'),
    new TableColumn(
      'SubscriberId',
      'subscriberId',
      true,
      'number',
      '/users/subscribers/',
      'subscriberId'
    ),
    new TableColumn(
      'BoxId',
      'boxid',
      true,
      'text',
      '/users/subscribers/',
      'subscriberId'
    ),
    new TableColumn('Edição', 'editionId', true, 'number'),
    new TableColumn(
      'Subscriber Status',
      'subscriberStatus',
      true,
      'number',
      undefined,
      undefined,
      true,
      'in'
    ),
    new TableColumn(
      'Subscriber Billing Status',
      'subscriberBillingStatus',
      true,
      'number',
      undefined,
      undefined,
      true,
      'in'
    )
  ];
  dropdownFilters: { [field: string]: Array<DropdownFilter> };

  constructor(
    public itOpsService: ItOpsControllerService,
    private subscriberService: SubscriberControllerService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {}

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader(true);
    try {
      this.dropdownFilters = {
        subscriberStatus: await lastValueFrom(
          this.subscriberService.findSubscriberStatusList().pipe(
            map((data) =>
              data.result.map((st) => ({
                label: st.subscriberStatusId + '. ' + st.status,
                value: st.subscriberStatusId.toString()
              }))
            )
          )
        ),
        subscriberBillingStatus: getAllSubscriberBillingStatus().map((sb) => ({
          label: sb.value + '. ' + sb.label,
          value: sb.value.toString()
        }))
      };
    } catch (error) {
      this.dropdownFilters = {};
    }
  }

  updateStatus(elements: Array<IncorrectSubscriberStatus>): void {
    this.confirmationService.confirm({
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      rejectButtonStyleClass: 'p-button-danger',
      message: 'Deseja ativar as assinaturas selecionadas?',
      header: 'Ativar Assinaturas',
      accept: async () => {
        LoaderService.showLoader();
        try {
          // await lastValueFrom(this.subscriberService.updateSubscriberStatus());
          await Promise.all(
            elements.map((e) =>
              lastValueFrom(
                this.subscriberService.updateSubscriberStatus({
                  subscriberId: e.subscriberId,
                  subscriberStatusId: 0
                })
              )
            )
          );
          await lastValueFrom(
            this.itOpsService.markAsResolved(
              SubscriberProblemGroupEnum.IncorrectSubscriberStatus,
              elements
            )
          );
          await this.table.refresh(true);
          this.messageService.add({
            summary: 'Sucesso',
            detail: elements.length + ' marcado(s) como resolvido(s)',
            severity: 'success'
          });
          LoaderService.showLoader(false);
        } catch (error) {
          LoaderService.showLoader(false);
          AppDialogService.showErrorDialog(error);
        }
      }
    });
  }
}

import { Where } from '@infrab4a/connect';

export enum OperatorMapper {
  equals = Where.EQUALS,
  notEquals = Where.NOTEQUALS,
  contains = Where.LIKE,
  startsWith = Where.LIKE,
  gt = Where.GT,
  lt = Where.LT,
  gte = Where.GTE,
  lte = Where.LTE,
  in = Where.IN
}

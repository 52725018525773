/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CreditCardSummary {
  creditCardId?: number;
  personId?: number;
  subscriberId?: number;
  creditCardFlagId?: number;
  nameOnCard?: string;
  expYear?: number;
  expMonth?: number;
  dateCreated?: Date;
  firstDigits?: string;
  lastDigits?: string;
  creditCardFlagName?: string;
  glamId?: string;
  recurrence?: string;
  recurrenceStatusId?: number;
}

import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom, map } from 'rxjs';
import {
  Edition,
  EditionBadgeScoreDetails,
  EditionControllerService,
  EditionScoreDetails,
  EditionScoreLogAcc,
  KpiControllerService
} from 'src/app/admin-api';
import { TableColumn } from 'src/app/components/table';
import { getAllSubscriptionsIncludeAll } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-edition-rating-and-scores-dashboard',
  templateUrl: './edition-rating-and-scores-dashboard.component.html',
  styleUrl: './edition-rating-and-scores-dashboard.component.scss',
  providers: [DatePipe]
})
export class EditionRatingAndScoresDashboardComponent implements OnInit {
  form = new FormGroup({
    subscriptionId: new FormControl<number>(0, [
      Validators.required,
      Validators.min(0)
    ]),
    editionId: new FormControl<number>(
      Number(
        `1${new Date().getFullYear()}${(new Date().getMonth() + 1)
          .toString()
          .padStart(2, '0')}`
      ),
      [Validators.required, Validators.min(1)]
    )
  });
  subscriptions: Array<{ label: string; value: number }>;
  editions: Array<Edition> | undefined;
  ready = false;
  chartData:
    | {
        labels: Array<string>;
        datasets: Array<{
          type: 'pie' | 'doughtnut' | 'line' | 'bar' | 'radar' | 'polarArea';
          label: string;
          borderWidth?: number;
          backgroundColor?: string;
          borderColor?: string;
          fill?: boolean;
          tension?: number;
          data: Array<number>;
          subscriptionId?: number;
          stack?: string;
          hidden?: boolean;
        }>;
      }
    | undefined;
  chartConfig = {
    options: {
      animation: {
        easing: 'easeInQuad'
      }
    }
  };
  width: string;
  editionScoreDetails: EditionScoreDetails | undefined;
  editionScoreLogs: EditionScoreLogAcc[] | undefined;
  editionBadgeScores: EditionBadgeScoreDetails[] | undefined;
  cols: Array<TableColumn> = [
    new TableColumn.Builder()
      .setHeader('Ícone')
      .setField('iconUrl')
      .setFilter(false)
      .setRouterLink('/subscription-settings/badge/')
      .setRouterLinkFieldName('badgeId')
      .setLinkActive((item: EditionBadgeScoreDetails) => item.badgeId > 0)
      .setStyleClass('iconCol')
      .setType('image')
      .build(),
    new TableColumn.Builder()
      .setHeader('Id joia')
      .setField('badgeId')
      .setType('number')
      .setRouterLink('/subscription-settings/badge/')
      .setRouterLinkFieldName('badgeId')
      .setLinkActive((item: EditionBadgeScoreDetails) => item.badgeId > 0)
      .build(),
    new TableColumn.Builder()
      .setHeader('Joia')
      .setField('badge')
      .setCondition('contains')
      .setRouterLink('/subscription-settings/badge/')
      .setRouterLinkFieldName('badgeId')
      .setLinkActive((item: EditionBadgeScoreDetails) => item.badgeId > 0)
      .build(),
    new TableColumn('Avg Box Rating', 'avgBoxRating', false, 'formattedNumber'),
    new TableColumn('Avg Delivery', 'avgDelivery', false, 'formattedNumber'),
    new TableColumn('Avg Products', 'avgProducts', false, 'formattedNumber'),
    new TableColumn('Avg Match', 'avgFitting', false, 'formattedNumber'),
    new TableColumn(
      'Avaliações',
      'recomendationsTotal',
      false,
      'formattedInteger'
    )
  ];

  constructor(
    private kpiService: KpiControllerService,
    private editionService: EditionControllerService,
    private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private router: Router,
    private title: Title
  ) {
    this.subscriptions = getAllSubscriptionsIncludeAll().filter(
      (s) => s.value !== 6
    );
    this.width = (document.getElementById('main-container').clientWidth - 60)
      .toString()
      .concat('px');
  }

  async ngOnInit(): Promise<void> {
    this.activatedRoute.params.subscribe(async (params) => {
      this.ready = false;
      if (params['editionId']) {
        this.form.patchValue({
          editionId: Number(params['editionId']),
          subscriptionId: Number((params['editionId'] / 1000000).toFixed(0))
        });
        LoaderService.showLoader();
        const fail = document.getElementById('img-fail');
        if (fail?.style?.display === 'none') {
          fail.style.display = 'block';
        }
        await this.findPage();
        await this.findEditions();
        this.title.setTitle(
          'Dashboard Rating and Scores - ' + this.edition?.theme
        );
        LoaderService.showLoader(false);
      } else {
        this.navigate();
      }
    });
  }

  async findEditions(): Promise<void> {
    try {
      this.editions = await lastValueFrom(
        this.editionService
          .findEditionsBySubscriptionId(this.form.value.subscriptionId || 1)
          .pipe(map((data) => data.result))
      );
      if (this.form.value.subscriptionId === 0) {
        this.editions?.forEach(
          (e) => (e.editionId = (e.editionId || 0) % 1000000)
        );
      }
    } catch (error: any) {
      this.editions = [];
    }
  }

  async changeSubscription(): Promise<void> {
    LoaderService.showLoader();
    const form = { ...this.form.value };
    this.form.controls['editionId'].reset(
      (form.subscriptionId || 0) * 1000000 + ((form.editionId || 0) % 1000000)
    );
    delete this.editionScoreDetails;
    delete this.editionScoreLogs;
    this.navigate();
    LoaderService.showLoader(false);
  }

  async changeEdition(): Promise<void> {
    LoaderService.showLoader();
    delete this.editionScoreDetails;
    delete this.editionScoreLogs;
    this.navigate();
    LoaderService.showLoader(false);
  }

  editionDate(editionId: number): string {
    return FormUtil.editionDate(editionId);
  }

  editionDateName(editionId: number): string {
    return (
      this.datePipe.transform(
        new Date(
          `${((editionId % 1000000) / 100).toFixed(0)}-${editionId % 100}-02`
        ),
        'MMMM/yyyy'
      ) || this.editionDate(editionId)
    );
  }

  navigate(): void {
    this.router.navigate([
      '/dashboard/rating-and-scores/' + this.form.value.editionId
    ]);
  }

  async findRatingAndScore(): Promise<void> {
    try {
      this.editionScoreDetails = await lastValueFrom(
        this.kpiService
          .findEditionScoresAndRatings(this.form.value.editionId)
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findRatingAndScoreLogs(): Promise<void> {
    try {
      this.editionScoreLogs = await lastValueFrom(
        this.kpiService
          .findEditionScoresAndRatingsLogs(this.form.value.editionId)
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findRatingAndScoreByBadges(): Promise<void> {
    try {
      this.editionBadgeScores = await lastValueFrom(
        this.kpiService
          .findScoresAndRatingsDashboardByBadge(this.form.value.editionId)
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findPage(): Promise<void> {
    LoaderService.showLoader();
    await Promise.all([
      this.findRatingAndScore(),
      this.findRatingAndScoreLogs(),
      this.findRatingAndScoreByBadges()
    ]);
    this.chartData = {
      labels: this.xAxis,
      datasets: [
        {
          data: this.xAxis.map(
            (x) =>
              this.editionScoreLogs
                .filter(
                  (log) =>
                    this.datePipe.transform(
                      FormUtil.utcDate(log.referenceDate),
                      'dd/MM/yyyy'
                    ) === x
                )
                .reduce((sum, sc) => (sum += sc.avgBoxRating || 0), 0) /
              this.editionScoreLogs.filter(
                (log) =>
                  this.datePipe.transform(
                    FormUtil.utcDate(log.referenceDate),
                    'dd/MM/yyyy'
                  ) === x
              ).length
          ),
          label: 'Box Rating',
          type: 'line',
          tension: 0.4,
          backgroundColor: '#fe357b',
          borderColor: '#fe357b'
        },
        {
          data: this.xAxis.map(
            (x) =>
              this.editionScoreLogs
                .filter(
                  (log) =>
                    this.datePipe.transform(
                      FormUtil.utcDate(log.referenceDate),
                      'dd/MM/yyyy'
                    ) === x
                )
                .reduce((sum, sc) => (sum += sc.avgProducts || 0), 0) /
              this.editionScoreLogs.filter(
                (log) =>
                  this.datePipe.transform(
                    FormUtil.utcDate(log.referenceDate),
                    'dd/MM/yyyy'
                  ) === x
              ).length
          ),
          label: 'Products Rating',
          type: 'line',
          tension: 0.4,
          backgroundColor: '#00aaff',
          borderColor: '#00aaff'
        },
        {
          data: this.xAxis.map(
            (x) =>
              this.editionScoreLogs
                .filter(
                  (log) =>
                    this.datePipe.transform(
                      FormUtil.utcDate(log.referenceDate),
                      'dd/MM/yyyy'
                    ) === x
                )
                .reduce((sum, sc) => (sum += sc.avgFitting || 0), 0) /
              this.editionScoreLogs.filter(
                (log) =>
                  this.datePipe.transform(
                    FormUtil.utcDate(log.referenceDate),
                    'dd/MM/yyyy'
                  ) === x
              ).length
          ),
          label: 'Match Rating',
          type: 'line',
          tension: 0.4,
          backgroundColor: '#8540f5',
          borderColor: '#8540f5'
        },
        {
          data: this.xAxis.map(
            (x) =>
              this.editionScoreLogs
                .filter(
                  (log) =>
                    this.datePipe.transform(
                      FormUtil.utcDate(log.referenceDate),
                      'dd/MM/yyyy'
                    ) === x
                )
                .reduce((sum, sc) => (sum += sc.avgDelivery || 0), 0) /
              this.editionScoreLogs.filter(
                (log) =>
                  this.datePipe.transform(
                    FormUtil.utcDate(log.referenceDate),
                    'dd/MM/yyyy'
                  ) === x
              ).length
          ),
          label: 'Delivery Rating',
          type: 'line',
          tension: 0.4,
          backgroundColor: '#faa43a',
          borderColor: '#faa43a'
        }
      ]
    };
    this.ready = true;
    LoaderService.showLoader(false);
  }

  get edition(): Edition | undefined {
    return this.editions?.find(
      (e) => e.editionId === this.form.value.editionId
    );
  }

  get xAxis(): Array<string> {
    return (
      this.editionScoreLogs?.reduce((list: Array<string>, log) => {
        if (
          log.referenceDate &&
          !list.some(
            (i) =>
              i ===
              this.datePipe.transform(
                FormUtil.utcDate(log.referenceDate),
                'dd/MM/yyyy'
              )
          )
        ) {
          list.push(
            this.datePipe.transform(
              FormUtil.utcDate(log.referenceDate),
              'dd/MM/yyyy'
            ) as string
          );
        }
        return list;
      }, []) || []
    );
  }

  get totalRecomendations() {
    return (
      this.editionBadgeScores?.reduce(
        (sum, i) => (sum += i.recomendationsTotal),
        0
      ) || 0
    );
  }
}

import { Component, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { ItOpsControllerService, PaymentSameMonth } from 'src/app/admin-api';
import { TableColumn, TableComponent } from 'src/app/components/table';
import { SubscriberProblemGroupEnum } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-payment-same-month-payment',
  templateUrl: './payment-same-month-payment.component.html',
  styleUrls: ['./payment-same-month-payment.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class PaymentSameMonthPaymentComponent {
  @ViewChild(TableComponent) table: TableComponent;

  columns: Array<TableColumn> = [
    new TableColumn('', '', false, 'checkbox'),
    new TableColumn(
      'SubscriberId',
      'subscriberId',
      true,
      'number',
      '/users/subscribers/',
      'subscriberId'
    ),
    new TableColumn(
      'PersonId',
      'personId',
      true,
      'number',
      '/users/person/',
      'personId'
    ),
    new TableColumn('Ano', 'ano', true, 'number'),
    new TableColumn('Mês', 'mes', true, 'number')
  ];

  constructor(
    public itOpsService: ItOpsControllerService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  markAsResolved(elements: Array<PaymentSameMonth>): void {
    this.confirmationService.confirm({
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      rejectButtonStyleClass: 'p-button-danger',
      message: 'Deseja enviar o relatório?',
      header: 'Corrigir',
      accept: async () => {
        LoaderService.showLoader();
        try {
          await lastValueFrom(
            this.itOpsService.markAsResolved(
              SubscriberProblemGroupEnum.PaymentSameMonth,
              elements
            )
          );
          await this.table.refresh(true);
          this.table.selectedItems = [];
          this.messageService.add({
            summary: 'Sucesso',
            detail: elements.length + ' marcado(s) como resolvido(s)',
            severity: 'success'
          });
          LoaderService.showLoader(false);
        } catch (error) {
          AppDialogService.showErrorDialog(error);
        }
        LoaderService.showLoader(false);
      }
    });
  }
}

<div class="page-title">Estoques e saídas</div>
<app-table
  [captionTemplate]="captionTemplate"
  [lazy]="false"
  [cols]="cols"
  [elements]="rows"
  [addButton]="false"
  modelName="produtos"
  (emitSelection)="showReport($event)"
  selectionActionLabel="Ver relatório"
  selectionActionTooltip="Ver relatório de saídas dos produtos selecionados"
  selectionActionIcon="pi pi-"
  *ngIf="rows && cols"
>
  <ng-template #captionTemplate>
    <form class="header" [formGroup]="referenceForm">
      <span class="p-float-label">
        <p-calendar
          id="period"
          dateFormat="dd/mm/yy"
          formControlName="period"
          [maxDate]="today"
          selectionMode="range"
          [readonlyInput]="true"
          (onSelect)="periodChange()"
        >
        </p-calendar>
        <label for="period">Período</label>
      </span>
      <span class="p-float-label">
        <p-multiSelect
          emptyMessage="Nenhuma"
          id="columns"
          [options]="colsOriginal"
          formControlName="columns"
          (onPanelHide)="reloadColumns()"
          class="input"
          optionLabel="header"
          [maxSelectedLabels]="0"
          (onChange)="updateTotalCols()"
          [selectedItemsLabel]="columnsLabel"
        >
        </p-multiSelect>
        <label for="columns">Colunas para exibição</label>
      </span>
    </form>
  </ng-template>
</app-table>

import { DatePipe } from '@angular/common';
import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
  WritableSignal
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ChartConfiguration, ChartData } from 'chart.js';
import { UIChart } from 'primeng/chart';
import { lastValueFrom, map } from 'rxjs';
import {
  KpiControllerService,
  SubscriptionsPerHour,
  SubscriptionsPerHourRealtime,
  SubscriptionsTotalResponse
} from 'src/app/admin-api';
import { getAllSubscriptionsIncludeAll } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-analytics-dashboard',
  templateUrl: './analytics-dashboard.component.html',
  styleUrl: './analytics-dashboard.component.scss'
})
export class AnalyticsDashboardComponent implements OnInit, OnDestroy {
  @ViewChild('chart') chart: UIChart;

  @Input()
  defaultSubscriptionId: number;

  @Input()
  show: WritableSignal<boolean>;

  @Input()
  subscribersRealtimeTotal: WritableSignal<SubscriptionsTotalResponse> =
    signal(undefined);

  subscriptionId = new FormControl<number>(0, [
    Validators.required,
    Validators.min(0)
  ]);
  subscriptions: Array<{ label: string; value: number }>;
  width: string;
  chartData: ChartData;
  chartConfig: ChartConfiguration = {
    options: {
      animation: {
        easing: 'easeInQuad'
      }
    }
  } as any;
  ready = false;
  subscribers: Array<SubscriptionsPerHour> | undefined;
  subscribersRealtime: Array<SubscriptionsPerHourRealtime> | undefined;
  interval: NodeJS.Timeout;
  intervalCount: NodeJS.Timeout;
  lastUpdate = new Date();

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.checkScreenSize();
  }

  constructor(
    private kpiService: KpiControllerService,
    private title: Title,
    private datePipe: DatePipe
  ) {
    this.subscriptions = getAllSubscriptionsIncludeAll();
    this.checkScreenSize();
    if (this.defaultSubscriptionId === undefined)
      this.title.setTitle('Dashboard de frequências');
  }

  checkScreenSize() {
    delete this.width;
    setTimeout(() => {
      this.width = window.innerWidth > 768 ? '100%' : '500px';
    });
  }

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader();
    if (this.defaultSubscriptionId !== undefined) {
      this.subscriptionId.setValue(this.defaultSubscriptionId);
    }
    if (!this.subscribersRealtimeTotal)
      this.subscribersRealtimeTotal = signal(undefined);
    await this.findPage();
    this.startIntervals();
    LoaderService.showLoader(false);
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
      delete this.interval;
    }
    if (this.intervalCount) {
      clearInterval(this.intervalCount);
      delete this.intervalCount;
    }
  }

  startIntervals() {
    this.interval = setInterval(async () => {
      this.lastUpdate = new Date();
      const total =
        this.subscribersRealtime?.reduce(
          (sum, s) => (sum += s.subscriptions),
          0
        ) || 0;
      if (
        !this.subscribersRealtimeTotal()?.total ||
        total < this.subscribersRealtimeTotal()?.total
      ) {
        LoaderService.showLoader();
        await this.findPage();
        LoaderService.showLoader(false);
      }
    }, 300000);
    this.intervalCount = setInterval(() => {
      this.lastUpdate = new Date();
      this.findSubscriptionsRealtimeCount();
    }, 60000);
  }

  async changeSubscription(): Promise<void> {
    LoaderService.showLoader();
    delete this.subscribers;
    this.ready = false;
    await this.findPage();
    LoaderService.showLoader(false);
  }

  async findPage(): Promise<void> {
    this.ready = false;
    await Promise.all([
      this.findSubscriptions(),
      this.findSubscriptionsRealtime()
    ]);
    this.findSubscriptionsRealtimeCount();
    this.chartData = {
      labels: this.yAxis,
      datasets: [
        {
          data: this.yAxis.map(
            (date) =>
              this.subscribers
                .filter(
                  (s) =>
                    Number(this.datePipe.transform(s.referenceDate, 'HH')) +
                      'h' ===
                    date
                )
                .reduce((sum, s) => (sum += s.subscriptions), 0) / 7
          ),
          label: 'Média Vendas/hora últimos 7d',
          type: 'line',
          backgroundColor: '#00aaff',
          borderColor: '#00aaff',
          tension: 0.4
        },
        {
          data: this.yAxis
            .filter(
              (hour) =>
                Number(this.datePipe.transform(new Date(), 'HH')) >=
                Number(hour.replace('h', ''))
            )
            .map((date) =>
              this.subscribersRealtime
                .filter(
                  (s) =>
                    Number(this.datePipe.transform(s.referenceDate, 'HH')) +
                      'h' ===
                    date
                )
                .reduce((sum, s) => (sum += s.subscriptions), 0)
            ),
          label: 'Vendas/hora dia atual',
          type: 'line',
          backgroundColor: '#606a74',
          borderColor: '#606a74',
          tension: 0.4
        }
      ]
    };
    this.ready = true;
  }

  async findSubscriptions(): Promise<void> {
    try {
      this.subscribers = await lastValueFrom(
        this.kpiService.findAnalyticsDashboard(this.subscriptionId.value).pipe(
          map((data) =>
            data.result.map((s) => ({
              ...s,
              referenceDate: FormUtil.utcDate(s.referenceDate)
            }))
          )
        )
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findSubscriptionsRealtime(): Promise<void> {
    try {
      this.subscribersRealtime = await lastValueFrom(
        this.kpiService
          .findAnalyticsDashboardRealtime(this.subscriptionId.value)
          .pipe(
            map((data) =>
              data.result.map((s) => ({
                ...s,
                referenceDate: FormUtil.utcDate(s.referenceDate)
              }))
            )
          )
      );
      const total =
        this.subscribersRealtime?.reduce(
          (sum, s) => (sum += s.subscriptions),
          0
        ) || 0;
      if (
        !this.subscribersRealtimeTotal()?.total ||
        total > this.subscribersRealtimeTotal().total
      )
        this.subscribersRealtimeTotal.update((t) => ({ ...t, total }));
    } catch (error) {
      console.error(error);
    }
  }

  async findSubscriptionsRealtimeCount(): Promise<void> {
    try {
      const total = await lastValueFrom(
        this.kpiService
          .findAnalyticsDashboardRealtimeTotal(this.subscriptionId.value)
          .pipe(
            map((data) => ({
              ...data.result,
              createdAt: FormUtil.utcDate(data.result.createdAt)
            }))
          )
      );
      if (
        !this.subscribersRealtimeTotal()?.total ||
        !this.subscribersRealtimeTotal()?.createdAt ||
        total.total > this.subscribersRealtimeTotal().total ||
        total.createdAt.getTime() >
          this.subscribersRealtimeTotal().createdAt.getTime()
      )
        this.subscribersRealtimeTotal.set(total);
    } catch (error) {
      console.error(error);
    }
  }

  hourForecast(hour: number): number {
    if (!hour)
      return (
        this.subscribers
          .filter(
            (s) =>
              Number(this.datePipe.transform(s.referenceDate, 'HH')) === hour
          )
          .reduce((sum, s) => (sum += s.subscriptions), 0) / 7
      );
    return (
      (this.subscribers
        .filter(
          (s) => Number(this.datePipe.transform(s.referenceDate, 'HH')) === hour
        )
        .reduce((sum, s) => (sum += s.subscriptions), 0) /
        7) *
      (this.subscribersRealtime
        .filter(
          (s) =>
            Number(this.datePipe.transform(s.referenceDate, 'HH')) <= hour - 1
        )
        .reduce((sum, s) => (sum += s.subscriptions), 0) /
        (this.subscribers
          .filter(
            (s) =>
              Number(this.datePipe.transform(s.referenceDate, 'HH')) <= hour - 1
          )
          .reduce((sum, s) => (sum += s.subscriptions), 0) /
          7) || 1)
    );
  }

  get yAxis(): Array<string> {
    const hours: Array<string> = [];
    for (let index = 0; index < 24; index++) {
      hours.push(index + 'h');
    }
    return hours;
  }

  get totalSubscriptionsTillTime(): number {
    return (
      this.subscribers
        .filter(
          (s) =>
            Number(this.datePipe.transform(s.referenceDate, 'HH')) <
            Number(this.datePipe.transform(new Date(), 'HH'))
        )
        .reduce((sum, s) => (sum += s.subscriptions || 0), 0) / 7
    );
  }

  get avgSubscriptions(): number {
    return (
      this.subscribers.reduce((sum, s) => (sum += s.subscriptions || 0), 0) / 7
    );
  }

  get subscriptionsForecast(): number {
    return Math.round(
      this.avgSubscriptions *
        (this.subscribersRealtimeTotal().total /
          (this.totalSubscriptionsTillTime || 1))
    );
  }

  get subscription(): { label: string; value: number } {
    return this.subscriptions.find(
      (s) => s.value === this.subscriptionId.value
    );
  }
}

import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { lastValueFrom, map } from 'rxjs';
import {
  BadgeControllerService,
  BadgeDTO,
  PersonControllerService,
  PersonUpdateRequest
} from 'src/app/admin-api';
import { Person } from 'src/app/admin-api/model/person';
import { CidadeDTO, Estado } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-person-form',
  templateUrl: './person-form.component.html',
  styleUrls: ['./person-form.component.scss'],
  providers: [DatePipe]
})
export class PersonFormComponent implements OnInit {
  @Input()
  person: Person | undefined;

  @Output()
  updated = new EventEmitter<Person>();

  @Input()
  isMobile?: boolean;

  personForm: FormGroup | undefined;
  states: Array<Estado> | undefined;
  cities: Array<CidadeDTO> | undefined;
  badges: Array<BadgeDTO> | undefined;

  constructor(
    private personService: PersonControllerService,
    private datePipe: DatePipe,
    private messageService: MessageService,
    private badgeService: BadgeControllerService
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.person) {
      await this.findBadges();
      this.personForm = new FormGroup({
        name: new FormControl<string>(this.person.name as string, [
          Validators.required,
          Validators.pattern('[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]*')
        ]),
        lastName: new FormControl<string>(this.person.lastName as string, [
          Validators.required,
          Validators.pattern('[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]*')
        ]),
        username: new FormControl<string>(this.person.username as string, [
          Validators.required,
          Validators.pattern(
            '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'
          )
        ]),
        cpf: new FormControl<string>(
          this.person.cpf ? this.cpfFormatted : '',
          Validators.required,
          FormUtil.cpfValidator()
        ),
        phone: new FormControl<string>(this.person.phone as string),
        birthday: new FormControl<string>(
          this.person.birthday
            ? (this.datePipe.transform(
                FormUtil.dateStringAsDate(this.person.birthday as any),
                'dd/MM/yyyy'
              ) as string)
            : '',
          [Validators.required, FormUtil.birthdayYear(), FormUtil.date()]
        ),
        badgeId: new FormControl<number>(this.person.badgeId as number),
        newsletterOptIn: new FormControl<boolean>(
          this.person.newsletterOptIn || false
        )
        // postalCode: new FormControl<string>(this.person.postalCode as string, [
        //   Validators.required,
        //   Validators.minLength(9)
        // ]),
        // stateCode: new FormControl<number>(
        //   this.person.stateCode as number,
        //   Validators.required
        // ),
        // state: new FormControl<string>(this.person.state as string, [
        //   Validators.required,
        //   Validators.maxLength(255)
        // ]),
        // cityCode: new FormControl<number>(
        //   this.person.cityCode as number,
        //   Validators.required
        // ),
        // city: new FormControl<string>(this.person.city as string, [
        //   Validators.required,
        //   Validators.maxLength(255)
        // ]),
        // neighborhood: new FormControl<string | undefined>(
        //   this.person.neighborhood,
        //   [Validators.required, Validators.maxLength(255)]
        // ),
        // street: new FormControl<string>(this.person.street as string, [
        //   Validators.required,
        //   Validators.maxLength(255)
        // ]),
        // streetNumber: new FormControl<string>(
        //   this.person.streetNumber as string,
        //   [Validators.required, Validators.maxLength(30)]
        // ),
        // streetExtensions: new FormControl<string | undefined>(
        //   this.person.streetExtensions,
        //   Validators.maxLength(200)
        // )
      });
    }
  }

  async findBadges(): Promise<void> {
    try {
      this.badges =
        (await lastValueFrom(
          this.badgeService.findActiveBadges().pipe(map((data) => data.result))
        )) || [];
      this.badges = [
        {
          badgeId: 0,
          consecutiveEditionsToAchieve: 0,
          glampointsMultiplier: 0,
          title: 'Sem joia'
        } as BadgeDTO
      ].concat(this.badges);
    } catch (error: any) {
      console.log(error);
      this.badges = [];
    }
  }

  async validateEmail(): Promise<void> {
    LoaderService.showLoader();
    if (
      !(await lastValueFrom(
        this.personService
          .validateEmail(
            this.person?.personId as number,
            this.personForm?.value.username
          )
          .pipe(map((data) => data.result))
      ))
    ) {
      this.personForm?.get('username')?.setErrors({
        ...this.personForm.get('username')?.errors,
        invalid: true
      });
    } else {
      const errors = this.personForm?.get('username')?.errors || {};
      delete errors['invalid'];
      this.personForm?.get('username')?.setErrors(errors);
      this.personForm?.get('username')?.markAsPristine();
      this.personForm?.get('username')?.updateValueAndValidity();
    }
    LoaderService.showLoader(false);
  }

  async validateCpf(): Promise<void> {
    LoaderService.showLoader();
    if (
      !(await lastValueFrom(
        this.personService
          .validateCPF(
            this.person?.personId as number,
            FormUtil.onlyNumbers(this.personForm?.value.cpf as string)
          )
          .pipe(map((data) => data.result))
      ))
    ) {
      this.personForm?.get('cpf')?.setErrors({
        ...this.personForm.get('cpf')?.errors,
        exists: true
      });
    } else {
      const errors = this.personForm?.get('cpf')?.errors || {};
      delete errors['exists'];
      this.personForm?.get('cpf')?.setErrors(errors);
      this.personForm?.get('cpf')?.markAsPristine();
      this.personForm?.get('cpf')?.updateValueAndValidity();
    }
    LoaderService.showLoader(false);
  }

  async savePerson(): Promise<void> {
    if (this.personForm?.valid) {
      LoaderService.showLoader();
      try {
        const data: PersonUpdateRequest = { ...this.personForm.value };
        data.cpf = data.cpf ? FormUtil.onlyNumbers(data.cpf.toString()) : 0;
        if (data.birthday)
          data.birthday = FormUtil.stringAsDate(data.birthday as any);
        this.person = await lastValueFrom(
          this.personService
            .updatePerson(this.person?.personId as number, data)
            .pipe(map((data) => data.result))
        );
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'Dados atualizados com sucesso'
        });
        this.updated.emit(this.person);
      } catch (error: any) {
        AppDialogService.showErrorDialog(error);
      }
      LoaderService.showLoader(false);
    }
  }

  get cpfFormatted(): string {
    let v = this.person?.cpf?.toString()?.padStart(11, '0') as string;
    v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
    v = v.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
    //de novo (para o segundo bloco de números)
    v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    return v;
  }
}

/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ProductVariantSummary {
  brandId?: number;
  brandName?: string;
  brandEquity?: number;
  brandSummary?: string;
  brandLogoImage?: string;
  productName?: string;
  activeFeedbackId?: number;
  showOnWebSite?: boolean;
  defaultProductVariantId?: number;
  productSummary?: string;
  descriptionArticleId?: number;
  productVariantId?: number;
  productId?: number;
  productVariantName?: string;
  variantSummary?: string;
  weight?: number;
  salePrice?: number;
  saleStock?: number;
  tradePoints?: number;
  tradeStock?: number;
  maxTradesPerPerson?: number;
  tradeActive?: boolean;
  defaultImageURL?: string;
  externalStoreUrl?: string;
  orderCount?: number;
  dateCreated?: Date;
  dateUpdated?: Date;
  tradeCount?: number;
  ratingAverage?: number;
  reviewCount?: number;
  stockCount?: number;
  displayOrder?: number;
  costPrice?: number;
  internalEAN?: string;
  externalId?: number;
  ncm?: string;
  sellable?: boolean;
  tradeable?: boolean;
  expiration?: string;
  inStockReserveMarketing?: number;
}

<form [formGroup]="form" class="form-container">
  <h3>Chama as Amigas - Postagens</h3>

  <p-panel header="Período">
    <div class="form-field">
      <label>Data de início (obrigatório)</label>
      <p-calendar
        [showTime]="true"
        formControlName="beginDate"
        [ngClass]="{ error: isBeginDateInvalid() }"
        [minDate]="today"
        [showButtonBar]="true"
      ></p-calendar>
      <p class="error-message" *ngIf="isBeginDateInvalid()">
        Data é um campo obrigatório.
      </p>
    </div>

    <div class="form-field">
      <label>Data de término</label>
      <p-calendar
        [showTime]="true"
        formControlName="endDate"
        [ngClass]="{ error: isEndDateInvalid() }"
        [minDate]="today"
        [showButtonBar]="true"
      ></p-calendar>
      <p class="error-message" *ngIf="isEndDateInvalid()">Data inválida.</p>
    </div>
  </p-panel>

  <p-panel header="Conteúdo">
    <div class="form-field">
      <label>Title (obrigatório)</label>
      <input
        type="text"
        pInputText
        formControlName="title"
        [ngClass]="{ error: isTitleInvalid() }"
      />
      <p class="error-message" *ngIf="isTitleInvalid()">
        Title é um campo obrigatório.
      </p>
    </div>
    <div class="form-field">
      <label>Título da imagem (obrigatório)</label>
      <input
        type="text"
        pInputText
        formControlName="imageTitle"
        [ngClass]="{ error: isTitleInvalid() }"
      />
      <p class="error-message" *ngIf="isTitleInvalid()">
        Título da imagem é um campo obrigatório.
      </p>
    </div>
    <br />
    <div class="form-field">
      <label>Tipo de post - stories ou feed (obrigatório)</label>
      <p-dropdown
        [options]="['stories', 'feed']"
        formControlName="tag"
        [ngClass]="{ error: isPostTypeInvalid() }"
      ></p-dropdown>

      <p class="error-message" *ngIf="isPostTypeInvalid()">
        Tipo de post é um campo obrigatório.
      </p>
    </div>
    <br />
    <div class="form-field">
      <label>Sugestão de post - máx. 255 caracteres (obrigatório)</label>
      <p-editor formControlName="postSuggestion" [style]="{ height: '100px' }">
        <ng-template pTemplate="header">
          <span class="ql-formats">
            <button type="button" class="ql-bold" aria-label="Bold"></button>
            <button
              type="button"
              class="ql-italic"
              aria-label="Italic"
            ></button>
            <button
              type="button"
              class="ql-underline"
              aria-label="Underline"
            ></button>
          </span>
          <span class="ql-formats">
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
          </span> </ng-template
      ></p-editor>
      <p class="error-message" *ngIf="isPostSuggestionInvalid()">
        Sugestão de post é um campo obrigatório e deve ter no máximo 255
        caracteres.
      </p>
    </div>
    <br />
    <div class="form-field">
      <label> Imagem para download | WEBP / GIF (obrigatório) </label>
      <p-fileUpload
        mode="basic"
        chooseLabel="upload"
        name="demo[]"
        [auto]="true"
        accept="image/webp, image/gif"
        (onSelect)="handleFileSelect($event, 'image', 1080, 1350, 0, image)"
        [showUploadButton]="false"
        [showCancelButton]="false"
        [disabled]="!form.get('tag').valid"
        #image
        styleClass="p-button-raised p-button-secondary"
      ></p-fileUpload>
    </div>
    <div class="form-container__img">
      <img *ngIf="imageDownloadUrl" [src]="imageDownloadUrl" />
    </div>
    <br />

    <br />
    <div class="form-field">
      <p-button
        styleClass="p-button-outlined cancel-button"
        label="Cancelar"
        (onClick)="goToMenu()"
      ></p-button>
    </div>
    <div class="form-field">
      <p-button
        styleClass="p-button"
        label="Salvar"
        (onClick)="save()"
        [disabled]="!form.valid"
      ></p-button>
    </div>
  </p-panel>
</form>

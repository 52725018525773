import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Banner } from '@infrab4a/connect';
import { ImageUploadComponent } from 'src/app/components/image-upload/image-upload.component';
import { HomeService } from 'src/app/connect-api/api/shop-settings/home.service';

@Component({
  selector: 'app-dms-banner-form',
  templateUrl: './dms-banner-form.component.html',
  styleUrl: './dms-banner-form.component.scss'
})
export class DmsBannerFormComponent
  extends ImageUploadComponent<Banner>
  implements OnInit
{
  @Input()
  override form = new FormGroup({
    image: new FormControl<string>(null, Validators.required),
    mobileImage: new FormControl<string>(null, Validators.required),
    alt: new FormControl<string>(null, Validators.required),
    path: new FormControl<string>(null, Validators.required)
  });

  @Input()
  banner: Banner;

  @Input({ required: true })
  shop: 'glamshop' | 'mens_market';

  @Input({ required: true })
  sectionId: string;

  @Output()
  formChanged = new EventEmitter<Banner>();

  ngOnInit(): void {
    this.form?.valueChanges.subscribe((value) =>
      this.formChanged.emit(value as Banner)
    );
  }

  public override get filePath(): string {
    return `/dms/${this.shop}/${this.sectionId}/`;
  }
  public override get model(): Banner {
    return this.banner;
  }
  public override get modelId(): string | number {
    return this.banner ? 1 : 0;
  }

  get isRouterLink(): boolean {
    return (
      this.form.value.path &&
      !this.form.value.path.startsWith('http') &&
      !this.form.value.path.startsWith('/')
    );
  }

  get sectionType(): 'Banner[]' | 'Banner' | 'string[]' {
    return HomeService.EDITABLE_SECTIONS.find((s) => s.id === this.sectionId)
      .type;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PickListModule } from 'primeng/picklist';
import { EditionChargebackModalComponent } from './edition-chargeback-modal.component';

@NgModule({
  declarations: [EditionChargebackModalComponent],
  imports: [
    CommonModule,
    ButtonModule,
    InputTextareaModule,
    PickListModule,
    FormsModule
  ],
  exports: [EditionChargebackModalComponent]
})
export class EditionChargebackModalModule {}

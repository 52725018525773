<form [formGroup]="form" class="form-container">
  <h3>Painel - Carrossel de Banners 2</h3>

  <p-panel header="Período">
    <div class="form-field">
      <label>Data de início (obrigatório)</label>
      <p-calendar
        [showTime]="true"
        formControlName="beginDate"
        [ngClass]="{ error: isBeginDateInvalid() }"
        [minDate]="today"
        [showButtonBar]="true"
      ></p-calendar>
      <p class="error-message" *ngIf="isBeginDateInvalid()">
        Data é um campo obrigatório.
      </p>
    </div>

    <div class="form-field">
      <label>Data de término</label>
      <p-calendar
        [showTime]="true"
        formControlName="endDate"
        [ngClass]="{ error: isEndDateInvalid() }"
        [minDate]="today"
        [showButtonBar]="true"
      ></p-calendar>
      <p class="error-message" *ngIf="isEndDateInvalid()">Data inválida.</p>
    </div>
  </p-panel>

  <p-panel header="Conteúdo">
    <div class="form-field">
      <label>Title (obrigatório)</label>
      <input
        type="text"
        pInputText
        formControlName="title"
        [ngClass]="{ error: isTitleInvalid() }"
      />
      <p class="error-message" *ngIf="isTitleInvalid()">
        Title é um campo obrigatório.
      </p>
    </div>
    <div class="form-field">
      <label>Alt text - mín. 50 e máx. 125 caracteres (obrigatório)</label>
      <input
        type="text"
        pInputText
        formControlName="altText"
        [ngClass]="{ error: isAltTextInvalid() }"
      />
      <p class="error-message" *ngIf="isAltTextInvalid()">
        Alt text é um campo obrigatório e deve ter entre 50 e 125 caracteres.
      </p>
    </div>
    <div class="form-field">
      <label>Link do banner</label>
      <input
        type="text"
        pInputText
        formControlName="link"
        placeholder="Exemplo: /clube"
      />
    </div>
    <div class="form-field">
      <label>
        Banner Desktop - Máx 170kb | WEBP / GIF | Dimensão base: 1216x160
        (obrigatório)
      </label>
      <p-fileUpload
        mode="basic"
        chooseLabel="upload"
        name="demo[]"
        [auto]="true"
        accept="image/webp, image/gif"
        [maxFileSize]="170 * 1024"
        (onSelect)="
          handleFileSelect(
            $event,
            'desktopImage',
            1216,
            160,
            170 * 1024,
            desktopImage
          )
        "
        [showUploadButton]="false"
        [showCancelButton]="false"
        #desktopImage
        styleClass="p-button-raised p-button-secondary"
      ></p-fileUpload>
    </div>
    <div class="form-container__img">
      <img *ngIf="desktopBannerUrl" [src]="desktopBannerUrl" />
    </div>
    <div class="form-field">
      <label>
        Banner Mobile - Máx 120kb | WEBP / GIF | Dimensão base: 517x234
        (obrigatório)
      </label>
      <p-fileUpload
        mode="basic"
        chooseLabel="upload"
        name="demo[]"
        [auto]="true"
        accept="image/webp, image/gif"
        [maxFileSize]="120 * 1024"
        (onSelect)="
          handleFileSelect(
            $event,
            'mobileImage',
            517,
            234,
            120 * 1024,
            mobileImage
          )
        "
        [showUploadButton]="false"
        [showCancelButton]="false"
        #mobileImage
        styleClass="p-button-raised p-button-secondary"
      ></p-fileUpload>
    </div>
    <div class="form-container__img">
      <img *ngIf="mobileBannerUrl" [src]="mobileBannerUrl" />
    </div>
    <div class="form-field">
      <p-button
        [outlined]="true"
        styleClass="cancel-button"
        label="Cancelar"
        (onClick)="goToMenu()"
      ></p-button>
    </div>
    <div class="form-field">
      <p-button
        styleClass="p-button"
        label="Salvar"
        (onClick)="save()"
        [disabled]="!form.valid"
      ></p-button>
    </div>
  </p-panel>
</form>

<app-table
  [lazy]="true"
  [cols]="columns"
  tableTitle="Subscriber Status Incorreto"
  [addButton]="false"
  [exportPdf]="false"
  [exportExcel]="false"
  [refreshButton]="false"
  [service]="itOpsService"
  pageFunctionName="getIncorrectSubscriberStatusTable"
  emptyMessage="Sem mais assinaturas com o 'STATUS' incorreto no momento."
  selectionActionLabel="Corrigir"
  selectionActionTooltip="Marcar como resolvido"
  selectionActionIcon="pi pi-check"
  (emitSelection)="updateStatus($event)"
  modelName="Registros"
  [defaultRows]="15"
  *ngIf="dropdownFilters"
  [dropdownFilters]="dropdownFilters"
  [multiSelectFilter]="true"
></app-table>
<p-confirmDialog></p-confirmDialog>
<p-toast></p-toast>

<form [formGroup]="form" class="form-container">
  <h3>Chama as Amigas - Ganhe glampoints</h3>

  <p-panel header="Período">
    <div class="form-field">
      <label>Data de início (obrigatório)</label>
      <p-calendar
        [showTime]="true"
        formControlName="beginDate"
        [ngClass]="{ error: isBeginDateInvalid() }"
        [minDate]="today"
        [showButtonBar]="true"
      ></p-calendar>
      <p class="error-message" *ngIf="isBeginDateInvalid()">
        Data é um campo obrigatório.
      </p>
    </div>
  </p-panel>

  <p-panel header="Conteúdo">
    <div class="form-field">
      <label>Nome da campanha (obrigatório)</label>
      <input
        type="text"
        pInputText
        formControlName="campaignName"
        [ngClass]="{ error: isCampaignNameInvalid() }"
      />
      <p class="error-message" *ngIf="isCampaignNameInvalid()">
        Nome é um campo obrigatório.
      </p>
    </div>
    <div class="form-field">
      <label>Nome Brinde (obrigatório)</label>
      <input
        type="text"
        pInputText
        formControlName="nameGift"
        [ngClass]="{ error: isTitleInvalid() }"
      />
      <p class="error-message" *ngIf="isTitleInvalid()">
        Nome é um campo obrigatório.
      </p>
    </div>
    <br />
    <div class="form-field">
      <label>
        Imagem Brinde - Máx 190kb | WEBP / GIF | Dimensão base: 350x350
        (obrigatório)
      </label>
      <p-fileUpload
        mode="basic"
        chooseLabel="upload"
        name="demo[]"
        [auto]="true"
        accept="image/webp, image/gif"
        [maxFileSize]="190 * 1024"
        (onSelect)="
          handleFileSelect($event, 'imageGift', 350, 350, 190 * 1024, imageGift)
        "
        [showUploadButton]="false"
        [showCancelButton]="false"
        #imageGift
        styleClass="p-button-raised p-button-secondary"
      ></p-fileUpload>
    </div>
    <div class="form-container__img">
      <img *ngIf="imageGiftUrl" [src]="imageGiftUrl" />
    </div>
    <br />
    <div class="form-field">
      <label>Alt text (obrigatório)</label>
      <input
        type="text"
        pInputText
        formControlName="altText"
        [ngClass]="{ error: isAltTextInvalid() }"
      />
      <p class="error-message" *ngIf="isAltTextInvalid()">
        Alt text é um campo obrigatório.
      </p>
    </div>
    <br />
    <br />
    <div class="form-field">
      <p-button
        [outlined]="true"
        styleClass="cancel-button"
        label="Cancelar"
        (onClick)="goToMenu()"
      ></p-button>
    </div>
    <div class="form-field">
      <p-button
        styleClass="p-button"
        label="Salvar"
        (onClick)="save()"
        [disabled]="!form.valid"
      ></p-button>
    </div>
  </p-panel>
</form>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BadgeModule } from 'primeng/badge';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { RatingModule } from 'primeng/rating';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { FileControllerService } from 'src/app/admin-api';
import { DragDropListModule } from 'src/app/components/drag-drop-list/drag-drop-list.module';
import { ShopProductFormModule } from 'src/app/components/shop-product-form/shop-product-form.module';
import { AppTableModule } from 'src/app/components/table/app-table.module';
import {
  BATCH_CATEGORY_MOST_RELEVANT_SERVICE,
  BATCH_CATEGORY_SERVICE,
  BATCH_PRODUCT_SERVICE,
  BATCH_REVIEW_SERVICE
} from 'src/app/connect-api/api/shop/batch/batch.service';
import { CategoriesBatchService } from 'src/app/connect-api/api/shop/batch/categories-batch.service';
import { CategoryMostRelevantBatchService } from 'src/app/connect-api/api/shop/batch/categories-most-relevant-batch.service';
import { ProductsBatchService } from 'src/app/connect-api/api/shop/batch/products-batch.service';
import { ReviewsBatchService } from 'src/app/connect-api/api/shop/batch/reviews-batch.service';
import { BatchUpdateComponent } from './batch-update/batch-update.component';
import { CategoryFilterDetailComponent } from './category-filters/category-filter-detail/category-filter-detail.component';
import { CategoryFiltersComponent } from './category-filters/category-filters.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { ProductStockNotificationsComponent } from './product-stock-notifications/product-stock-notifications.component';
import { ShopCategoriesComponent } from './shop-categories/shop-categories.component';
import { ShopCategoryDetailComponent } from './shop-categories/shop-category-detail/shop-category-detail.component';
import { ShopCategoryFormComponent } from './shop-categories/shop-category-form/shop-category-form.component';
import { ShopCategoryImagesComponent } from './shop-categories/shop-category-images/shop-category-images.component';
import { ProductsRoutingModule } from './shop-products-routing.module';
import { ShopProductsComponent } from './shop-products.component';
import { ShopReviewsComponent } from './shop-reviews/shop-reviews.component';

@NgModule({
  declarations: [
    ShopProductsComponent,
    ProductDetailComponent,
    ShopCategoriesComponent,
    ShopCategoryDetailComponent,
    CategoryFiltersComponent,
    ShopReviewsComponent,
    CategoryFilterDetailComponent,
    BatchUpdateComponent,
    ShopCategoryFormComponent,
    ShopCategoryImagesComponent,
    ProductStockNotificationsComponent
  ],
  providers: [
    ConfirmationService,
    FileControllerService,
    {
      provide: BATCH_CATEGORY_SERVICE,
      useClass: CategoriesBatchService
    },
    {
      provide: BATCH_CATEGORY_MOST_RELEVANT_SERVICE,
      useClass: CategoryMostRelevantBatchService
    },
    {
      provide: BATCH_PRODUCT_SERVICE,
      useClass: ProductsBatchService
    },
    {
      provide: BATCH_REVIEW_SERVICE,
      useClass: ReviewsBatchService
    }
  ],
  imports: [
    CommonModule,
    AppTableModule,
    ProductsRoutingModule,
    RatingModule,
    ReactiveFormsModule,
    FormsModule,
    TabViewModule,
    ShopProductFormModule,
    DragDropListModule,
    FileUploadModule,
    ConfirmPopupModule,
    OverlayPanelModule,
    AutoCompleteModule,
    FieldsetModule,
    InputTextModule,
    DropdownModule,
    PanelModule,
    ToastModule,
    CheckboxModule,
    ProgressBarModule,
    DialogModule,
    InputGroupModule,
    TooltipModule,
    EditorModule,
    PickListModule,
    BreadcrumbModule,
    ButtonModule,
    ChipsModule,
    TabViewModule,
    SelectButtonModule,
    AccordionModule,
    BadgeModule
  ]
})
export class ShopProductsModule {}

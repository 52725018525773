import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { lastValueFrom, map } from 'rxjs';
import {
  Brand,
  BrandControllerService,
  BrandCreateRequest,
  BrandUpdateRequest,
  FileControllerService
} from 'src/app/admin-api';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ImageUploadComponent } from '../image-upload/image-upload.component';

@Component({
  selector: 'app-brand-form',
  templateUrl: './brand-form.component.html',
  styleUrls: ['./brand-form.component.scss'],
  providers: [MessageService]
})
export class BrandFormComponent
  extends ImageUploadComponent<Brand>
  implements OnInit, OnDestroy
{
  @Input()
  brand: Brand | undefined;

  @Output()
  updated = new EventEmitter<Brand>();

  constructor(
    private brandService: BrandControllerService,
    private messageService: MessageService,
    override router: Router,
    override fileService: FileControllerService
  ) {
    super(fileService, router);
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      brandId: new FormControl(this.brand?.brandId),
      brandName: new FormControl(this.brand?.brandName, Validators.required),
      summary: new FormControl(this.brand?.summary, [
        Validators.required,
        Validators.maxLength(4000)
      ]),
      brandEquity: new FormControl(this.brand?.brandEquity, [
        Validators.min(0),
        Validators.max(5),
        Validators.required
      ]),
      carouselImageDisplay: new FormControl(
        this.brand?.carouselImageDisplay === 1,
        Validators.required
      ),
      coverImageDisplay: new FormControl(
        this.brand?.coverImageDisplay === 1,
        Validators.required
      ),
      logoImageUrl: new FormControl(this.brand?.logoImageUrl),
      coverImageUrl: new FormControl(this.brand?.coverImageUrl),
      carouselImageUrl: new FormControl(this.brand?.carouselImageUrl)
    });
  }

  async saveBrand(): Promise<void> {
    if (this.form?.valid) {
      LoaderService.showLoader();
      try {
        if (this.brand?.brandId) {
          const request: BrandUpdateRequest = { ...this.form.value };
          request.carouselImageDisplay = Number(request.carouselImageDisplay);
          request.coverImageDisplay = Number(request.coverImageDisplay);
          this.brand = await lastValueFrom(
            this.brandService
              .updateBrand(request)
              .pipe(map((data) => data.result))
          );
          this.updated.emit(this.brand);
          this.messageService.add({
            severity: 'success',
            detail: 'Marca atualizada com sucesso',
            summary: 'Sucesso'
          });
          await this.afterSubmit();
        } else {
          const request: BrandCreateRequest = { ...this.form.value };
          request.carouselImageDisplay = Number(request.carouselImageDisplay);
          request.coverImageDisplay = Number(request.coverImageDisplay);
          this.brand = await lastValueFrom(
            this.brandService
              .createBrand(request)
              .pipe(map((data) => data.result))
          );
          this.messageService.add({
            severity: 'success',
            detail: 'Marca cadastrada com sucesso',
            summary: 'Sucesso'
          });
          this.router.navigate(['products/brands/' + this.brand?.brandId]);
        }
      } catch (error: any) {
        AppDialogService.showErrorDialog(error);
      }
      LoaderService.showLoader(false);
    }
  }

  get filePath(): string {
    return '/admin/brands/';
  }

  override get model() {
    return this.brand;
  }

  override get modelId() {
    return this.brand?.brandId || 0;
  }
}

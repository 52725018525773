<form class="form-b4a" [formGroup]="form" (submit)="submit()">
  <span class="p-float-label full-w">
    <input
      type="text"
      pInputText
      formControlName="title"
      name="title"
      id="title"
    />
    <label for="title">Título *</label>
  </span>
  <span class="p-float-label">
    <input
      type="text"
      pInputText
      formControlName="subtitle"
      name="subtitle"
      id="subtitle"
    />
    <label for="subtitle">Subtítulo</label>
  </span>
  <span class="p-float-label">
    <p-inputNumber
      [min]="1"
      formControlName="length"
      name="length"
      inputId="length"
    />
    <label for="length">Duração vídeo (segundos) *</label>
  </span>
  <span class="full-w input-switch">
    <label for="active">Inativo</label>
    <p-inputSwitch formControlName="active" inputId="active" />
    <label for="active">Ativo</label>
  </span>
  <span class="image-upload full-w">
    <div class="file-upload full-w">
      <span class="p-fixed-label">
        <label for="videoURL">URL do Vídeo *</label>
        <p-inputGroup>
          <input
            type="text"
            pInputText
            formControlName="videoURL"
            id="videoURL"
            name="videoURL"
            (input)="refreshVideo()"
            placeholder="Cole o link ou faça upload do vídeo"
          />
          <p-inputGroupAddon (click)="removeVideo()">
            <i
              class="pi pi-times"
              pRipple
              pTooltip="Remover video"
              tooltipPosition="bottom"
            ></i>
          </p-inputGroupAddon>
        </p-inputGroup>
      </span>
      <p-fileUpload
        mode="basic"
        chooseLabel="Selecionar"
        name="file"
        [auto]="true"
        accept="video/*"
        uploadIcon="pi pi-file"
        [customUpload]="true"
        (uploadHandler)="updateVideo($event, 'videoURL', videoUpload)"
        id="videoURL"
        #videoUpload
        [disabled]="form.controls.videoURL.disabled"
      />
    </div>
    <small
      class="error"
      *ngIf="form.value.videoURL && !form.controls.videoURL.valid"
    >
      URL inválida
    </small>
    <video
      controls
      [height]="form.controls.videoURL.valid ? 300 : 0"
      width="300"
      id="videoElement"
      #videoElement
      crossorigin="anonymous"
    >
      Não foi possível carregar a prévia
    </video>
    <p-button
      *ngIf="form.controls.videoURL.valid"
      label="Atualizar thumbnail"
      (onClick)="getCoverImage(true, videoElement.currentTime)"
    />
  </span>
  <span class="thumbnail" *ngIf="form.value.image">
    <h3>Thumbnail do video</h3>
    <img [src]="form.value.image" />
    <small>
      * Para atualizar, movimente o vídeo até o tempo desejado e aperte
      "Atualizar thumbnail"
    </small>
  </span>

  <span class="p-input-editor">
    <span class="label-option">
      <label for="description">Descrição </label>
      <span class="p-input-checbox">
        <p-checkbox
          formControlName="html"
          [binary]="true"
          inputId="html"
          name="html"
          (onChange)="format()"
        />
        <label for="html">Ver código fonte</label>
      </span>
    </span>
    <p-editor
      formControlName="description"
      id="description"
      (keyup)="cdRef.detectChanges()"
      (click)="cdRef.detectChanges()"
      [hidden]="form.value.html"
      [modules]="{
        toolbar: { handlers, container: toolbarOptions },
        blotFormatter2: {
          resize: {
            handleClassName: 'dimensioned'
          }
        }
      }"
    >
      <ng-template pTemplate="header" />
    </p-editor>
    <textarea
      name="description"
      id="description"
      formControlName="description"
      *ngIf="form.value.html"
      rows="50"
    >
    </textarea>
    <small class="hint" [class.danger]="!form.controls.description.valid">
      {{ form.value.description?.trim().length || 0 | number : '1.0-0' }}/4.000
    </small>
  </span>
  <span class="buttons">
    <p-button label="Salvar" [disabled]="!form.valid" type="submit" />
  </span>
</form>
<input type="file" hidden id="inputFile" (change)="handleFile($event)" />

import { DatePipe, DecimalPipe } from '@angular/common';
import {
  Component,
  HostListener,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { ChartData } from 'chart.js';
import { UIChart } from 'primeng/chart';
import {
  CouponUsageType,
  CouponUsageTypeByCouponName
} from 'src/app/admin-api';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-coupons-ranking-chart',
  templateUrl: './coupons-ranking-chart.component.html',
  providers: [DatePipe, DecimalPipe]
})
export class CouponsRankingChartComponent implements OnInit {
  @ViewChild('chart')
  chart: UIChart | undefined;

  @Input()
  couponUsages: Array<CouponUsageTypeByCouponName> | undefined;

  @Input()
  width: string = null;

  @Input()
  height: string = null;

  @Input()
  responsive = true;

  @Input()
  couponUsageType: CouponUsageType;

  chartData: ChartData;
  chartConfig = {
    options: {
      animation: {
        easing: 'easeInQuad'
      }
    },
    plugins: {
      tooltips: {
        mode: 'index',
        intersect: false
      },
      legend: {
        labels: {
          color: 'black'
        }
      }
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: 'gray'
        },
        grid: {
          color: 'gray',
          drawBorder: false
        }
      },
      y: {
        stacked: true,
        ticks: {
          color: 'gray'
        },
        grid: {
          color: 'gray',
          drawBorder: false
        }
      }
    }
  };
  largeScreen?: boolean;
  showChart = false;
  ranking: { [couponId: number]: Array<CouponUsageTypeByCouponName> };
  rankingColors = [
    '#9e0142',
    '#d53e4f',
    '#f46d43',
    '#fdae61',
    '#fee08b',
    '#e6f598',
    '#abdda4',
    '#66c2a5',
    '#1a9850',
    '#3288bd',
    '#5e4fa2'
    // '#000000'
  ];

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.checkScreenSize();
  }

  constructor(private datePipe: DatePipe, private decimalPipe: DecimalPipe) {
    this.checkScreenSize();
  }

  checkScreenSize(): void {
    this.largeScreen = window.innerWidth > 1600;
    if (this.largeScreen && this.responsive) {
      this.height = '600px';
      this.width = '800px';
    } else if (this.responsive) {
      delete this.width;
      delete this.height;
    }
    this.resize();
  }

  ngOnInit(): void {
    if (!this.couponUsages || !this.couponUsageType) {
      AppDialogService.showErrorDialog(
        {},
        false,
        'Please provide couponUsages and the couponUsageType to render'
      );
      return;
    }
    let totals = this.couponUsages.reduce(
      (totals: { couponName: string; total: number }[], c) => {
        const exists = totals.find(
          (t) => t.couponName === c.couponName.toUpperCase()
        );
        if (exists) exists.total += c.total || 0;
        else
          totals.push({
            couponName: c.couponName.toUpperCase(),
            total: c.total
          });
        return totals;
      },
      []
    );
    totals.sort((c1, c2) => c2.total - c1.total);
    this.ranking = {};
    const rest = totals.slice(10);
    totals = [...totals.slice(0, 10)];
    totals.forEach((t) => {
      this.ranking[t.couponName] = this.couponUsages.filter(
        (c) => c.couponName.toUpperCase() === t.couponName
      );
    });
    if (rest.length) {
      const coupons = Object.keys(this.ranking);
      this.ranking['OUTROS'] = this.couponUsages.filter(
        (c) => !coupons.includes(c.couponName.toUpperCase())
      );
    }
    this.chartData = {
      labels: this.xAxis,
      datasets: []
    };
    totals.forEach((t, i) => {
      this.chartData.datasets.push({
        type: 'bar',
        label: `${t.couponName} (${this.decimalPipe.transform(
          t.total,
          '1.0-0'
        )})`,
        backgroundColor: this.rankingColors[i],
        borderColor: this.rankingColors[i],
        stack: 'coupon',
        data: this.xAxis.map((d) =>
          this.ranking[t.couponName]
            .filter(
              (c) => this.datePipe.transform(c.dateCreated, 'dd/MM/yyyy') === d
            )
            .reduce((sum, c) => (sum += c.total || 0), 0)
        )
      });
    });
    if (this.ranking['OUTROS'])
      this.chartData.datasets.push({
        type: 'bar',
        label: 'Outros',
        backgroundColor: this.rankingColors[this.rankingColors.length - 1],
        borderColor: this.rankingColors[this.rankingColors.length - 1],
        stack: 'coupon',
        data: this.xAxis.map((d) =>
          this.ranking['OUTROS']
            .filter(
              (c) => this.datePipe.transform(c.dateCreated, 'dd/MM/yyyy') === d
            )
            .reduce((sum, c) => (sum += c.total || 0), 0)
        )
      });
    this.chartData.datasets.push({
      label: 'Total',
      type: 'bar',
      stack: 'total',
      data: this.xAxis.map((x) =>
        this.couponUsages
          .filter(
            (sc) => this.datePipe.transform(sc.dateCreated, 'dd/MM/yyyy') === x
          )
          .reduce((sum, sc) => (sum += sc.total || 0), 0)
      ),
      backgroundColor: FormUtil.getColor(null, null, null),
      borderColor: FormUtil.getColor(null, null, null),
      hidden: true
    });
    LoaderService.showLoader(false);
    this.showChart = true;
  }

  resize(): void {
    this.showChart = false;
    setTimeout(() => {
      this.showChart = true;
    });
  }

  get xAxis(): Array<string> {
    const dates: Array<Date> = [];
    Object.keys(this.ranking).forEach((couponId) => {
      this.ranking[couponId]?.forEach((c) => {
        if (
          !dates.some(
            (d) =>
              this.datePipe.transform(d, 'dd/MM/yyyy') ===
              this.datePipe.transform(c.dateCreated, 'dd/MM/yyyy')
          )
        )
          dates.push(c.dateCreated);
      });
    });
    dates.sort((d1, d2) => d1.getTime() - d2.getTime());
    return dates.map((d) => this.datePipe.transform(d, 'dd/MM/yyyy'));
  }
}

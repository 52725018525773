import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  PersonDetail,
  PersonReward,
  RewardControllerService,
  RewardType
} from 'src/app/admin-api';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';
import { PWAUtil } from 'src/app/utils/pwa.util';
import { RewardModalComponent } from '../reward-modal/reward-modal.component';
import { TableActionButton, TableColumn } from '../table';

@Component({
  selector: 'app-person-glampoints',
  templateUrl: './person-glampoints.component.html',
  styleUrls: ['./person-glampoints.component.scss'],
  providers: [DialogService, ConfirmationService, MessageService]
})
export class PersonGlampointsComponent implements OnInit {
  @Input()
  person: PersonDetail | undefined;

  @Input()
  rewards: Array<PersonReward> | undefined;

  @Input()
  rewardTypes: Array<RewardType> | undefined;

  @Input()
  isMobile?: boolean;

  @Output()
  balanceChanged = new EventEmitter();

  cols: Array<TableColumn> = [
    new TableColumn(
      'Descrição',
      'referenceName',
      true,
      'text',
      '',
      'referenceName',
      true,
      'contains',
      undefined,
      undefined,
      undefined,
      undefined,
      this.referenceExists
    ),
    new TableColumn('Tipo', 'rewardTypeName', true, 'text'),
    new TableColumn('Data', 'dateCreated', true, 'date'),
    new TableColumn('Pontos', 'points', false, 'formattedNumber'),
    new TableColumn('Ação', '', false, 'button')
  ];
  rewardType = new FormControl<number>(Number.NEGATIVE_INFINITY);
  dropdownFilters:
    | { [key: string]: Array<{ label: string; value: string }> }
    | undefined;
  actionButtons: Array<TableActionButton> = [
    new TableActionButton(
      '',
      'askCancelTrade',
      'pi pi-trash',
      this.chargeBackAvailable,
      '',
      'Estonar',
      'bottom',
      true,
      true,
      'danger'
    )
  ];

  constructor(
    private dialog: DialogService,
    private cdRef: ChangeDetectorRef,
    private confirmationService: ConfirmationService,
    private rewardService: RewardControllerService,
    private messageService: MessageService
  ) {}

  async ngOnInit(): Promise<void> {
    this.dropdownFilters = {
      rewardTypeName: this.rewardTypes.map((rt) => ({
        label: rt.rewardTypeName,
        value: rt.rewardTypeName.toString()
      }))
    };
  }

  openRewardModal(): void {
    this.dialog
      .open(RewardModalComponent, {
        closable: false,
        closeOnEscape: false,
        header: `Inserir pontos para ${this.person?.name} ${this.person?.lastName}`,
        data: {
          person: this.person,
          rewardTypes: this.rewardTypesActive
        },
        modal: true,
        width: this.isMobile ? '100%' : '60%'
      })
      .onClose.subscribe((result?: PersonReward) => {
        if (result) {
          this.rewards = [result].concat(this.rewards || []);
          this.cdRef.detectChanges();
          this.balanceChanged.emit();
        }
      });
  }

  goToReference(reward: PersonReward): void {
    window.open(
      FormUtil.linkReference(reward),
      PWAUtil.isPWA ? '_self' : '_blank'
    );
  }

  chargeBackAvailable(reward: PersonReward): boolean {
    return reward.rewardType === -1;
  }

  referenceExists(reward: PersonReward): boolean {
    return FormUtil.linkReference(reward) !== null;
  }

  askCancelTrade($event: Event, reward: PersonReward, status?: string): void {
    this.confirmationService.confirm({
      target: $event.target,
      message: !status
        ? 'Deseja estornar esta troca?'
        : `O status encontra-se como ${status} na Millennium e não será alterado. Estornar pontos mesmo assim?`,
      header: 'Atenção',
      icon: 'pi pi-exclamation-triangle danger',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      acceptButtonStyleClass: 'p-button-primary',
      rejectButtonStyleClass: 'p-button-danger',
      accept: async () => {
        await this.cancelTrade(reward, status, $event);
      }
    });
  }

  async cancelTrade(
    reward: PersonReward,
    status?: string,
    $event?: Event
  ): Promise<void> {
    LoaderService.showLoader();
    try {
      let cancel: PersonReward;
      if (status)
        cancel = await lastValueFrom(
          this.rewardService
            .forceCancelTradeReward(reward.rewardId as number)
            .pipe(map((data) => data.result))
        );
      else
        cancel = await lastValueFrom(
          this.rewardService
            .cancelTradeReward(reward.rewardId as number)
            .pipe(map((data) => data.result))
        );
      if (cancel) {
        let rewards = [...this.rewards];
        delete this.rewards;
        rewards = [cancel].concat(rewards || []);
        this.cdRef.detectChanges();
        this.messageService.add({
          summary: 'Sucesso',
          detail: 'Estorno realizado com sucesso.',
          severity: 'success'
        });
        this.rewards = rewards;
      }
      this.balanceChanged.emit();
    } catch (error) {
      if (error.status === 409) {
        setTimeout(() => {
          this.askCancelTrade(
            $event,
            reward,
            error.error?.message || 'ALTERADO'
          );
        }, 1000);
      } else {
        AppDialogService.showErrorDialog(error);
      }
    }
    LoaderService.showLoader(false);
  }

  async recomputeBalance(): Promise<void> {
    LoaderService.showLoader();
    try {
      await lastValueFrom(
        this.rewardService
          .recomputePersonRewardBalance(this.person.personId)
          .pipe(map((data) => data.result))
      );
      this.balanceChanged.emit();
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  get rewardTypesActive(): Array<RewardType> {
    return this.rewardTypes?.filter((r) => r.active) || [];
  }

  get totalCredit(): number {
    return (
      this.rewards
        ?.filter((r) => r.points && r.points > 0)
        .reduce((sum, r) => (sum += r.points || 0), 0) || 0
    );
  }

  get totalSpent(): number {
    return (
      this.rewards
        ?.filter((r) => r.rewardType === -1 && r.points && r.points < 0)
        .reduce((sum, r) => (sum += r.points || 0), 0) || 0
    );
  }

  get totalDebit(): number {
    return (
      this.rewards
        ?.filter((r) => r.rewardType !== -1 && r.points && r.points < 0)
        .reduce((sum, r) => (sum += r.points || 0), 0) || 0
    );
  }

  get rewardsList(): Array<PersonReward> {
    return this.rewardType.value === Number.NEGATIVE_INFINITY
      ? this.rewards || []
      : this.rewards?.filter((r) => r.rewardType === this.rewardType.value) ||
          [];
  }

  get filterOn(): boolean {
    return this.rewardType.value !== Number.NEGATIVE_INFINITY;
  }

  get rewardBalance(): number {
    return this.rewards?.reduce((sum, r) => (sum += r.points || 0), 0) || 0;
  }

  get balanceDiff(): boolean {
    return this.person.rewardBalance !== this.rewardBalance;
  }
}

/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Dados para criação/atualização de template de email
 */
export interface EmailTemplateRequest {
  templateName: string;
  /**
   * ID do template no provedor
   */
  templateId: string;
  /**
   * Assunto padrão do email
   */
  subject: string;
  /**
   * Subtítulo do template
   */
  subTitle: string;
  /**
   * Descrição do template
   */
  description?: string;
  /**
   * Indica se o template está ativo
   */
  active: boolean;
}

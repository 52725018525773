import { Inject, Injectable } from '@angular/core';
import {
  Status,
  Subscription,
  SubscriptionRepository
} from '@infrab4a/connect';
import { PageableRequest } from 'src/app/admin-api';
import { BaseConnectService } from './base-connect.service';

@Injectable({
  providedIn: 'root'
})
export class ShopSubscriptionService extends BaseConnectService<Subscription> {
  constructor(
    @Inject('SubscriptionRepository')
    private subscriptionRepository: SubscriptionRepository
  ) {
    super(subscriptionRepository);
  }

  async getSubscriptionsByEmail(
    email: string
  ): Promise<Partial<Subscription>[]> {
    return (
      await this.subscriptionRepository.find({
        filters: {
          user: {
            email
          }
        }
      })
    ).data;
  }

  async getSubscriptionsList(request: Partial<PageableRequest>) {
    const result = await this.paginatedSearch(request);
    const content = result.data.map(
      (subscription) => ({ ...subscription } as Subscription)
    );
    const totalPages = request.pageSize
      ? Math.floor(result.count / request.pageSize)
      : undefined;
    const totalElements = result.count;

    return {
      totalPages,
      totalElements,
      content,
      empty: result.count === 0
    };
  }

  async paidToday() {
    const date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    const subscriptions = await this.getSubscriptionsList({
      filters: [
        {
          condition: 'gte',
          field: 'createdAt',
          fieldType: 'date',
          value: date
        }
      ],
      sortBy: 'createdAt',
      sortDirection: 'desc',
      page: 0,
      pageSize: 9999
    });
    return subscriptions.content.filter((s) => s.status === Status.ACTIVE);
  }
}

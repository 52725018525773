<div class="income-dashboard">
  <h3>Dashboard receita</h3>
  <div class="filters">
    <form class="form-b4a" [formGroup]="form">
      <span class="p-float-label">
        <p-dropdown
          inputId="subscriptionId"
          [options]="subscriptions"
          (onChange)="changeSubscription()"
          formControlName="subscriptionId"
          [autoDisplayFirst]="false"
          appendTo="body"
        >
        </p-dropdown>
        <label for="subscriptionId">Assinatura</label>
      </span>
      <span class="p-float-label">
        <p-dropdown
          inputId="editionId"
          [options]="editions"
          *ngIf="editions"
          optionValue="editionId"
          optionLabel="theme"
          (onChange)="changeEdition()"
          [filter]="true"
          filterBy="theme,title,editionId,description"
          filterMatchMode="contains"
          [autoDisplayFirst]="false"
          formControlName="editionId"
          appendTo="body"
        >
          <ng-template pTemplate="item" let-edition>
            <ng-container *ngIf="form.value.subscriptionId">
              {{ edition.theme }}
              {{ editionDate(edition.editionId) }}
            </ng-container>
            <ng-container *ngIf="!form.value.subscriptionId">
              {{ editionDateName(edition.editionId) | titlecase }}
            </ng-container>
          </ng-template>
          <ng-template pTemplate="selectedItem">
            <ng-container *ngIf="form.value.subscriptionId">
              {{ edition?.theme }}
            </ng-container>
            <ng-container
              *ngIf="edition && edition.editionId && !form.value.subscriptionId"
            >
              {{ editionDateName(edition.editionId) | titlecase }}
            </ng-container>
          </ng-template>
        </p-dropdown>
        <label for="editionId">Edição</label>
      </span>
    </form>
    <div class="picture">
      <img
        [src]="edition.imageURL"
        *ngIf="edition && edition.imageURL"
        [alt]="edition.description"
      />
      <img
        [src]="editionImage"
        *ngIf="edition && !edition.imageURL"
        [alt]="edition.description"
        id="img-fail"
        onerror="this.style.display = 'none'"
      />
    </div>
  </div>
  <div class="edition-info">
    <div class="totals">
      <div class="dashboard-card" *ngIf="pending?.installmentTotal">
        <span class="header header--warning">Pendente</span>
        <span class="value">{{
          pending?.installmentTotal || 0 | currency : 'BRL'
        }}</span>
        <span class="value"
          >{{
            pending?.subscriberCount || 0 | number : '1.0-0'
          }}
          assinantes</span
        >
      </div>
      <div class="dashboard-card">
        <span class="header header--success">Receita</span>
        <span class="value">{{ totalIncome | currency : 'BRL' }}</span>
        <span class="value">
          <span
            class="last-month"
            *ngIf="lastMonthDifference(totalIncome, totalIncomeLastMonth)"
            [class.last-month--negative]="
              lastMonthDifference(totalIncome, totalIncomeLastMonth) < 0
            "
            [class.last-month--positive]="
              lastMonthDifference(totalIncome, totalIncomeLastMonth) > 0
            "
            [pTooltip]="
              'Mês anterior ' + (totalIncomeLastMonth | currency : 'BRL')
            "
            tooltipPosition="right"
          >
            <i
              class="pi pi-arrow-down"
              *ngIf="lastMonthDifference(totalIncome, totalIncomeLastMonth) < 0"
            ></i>
            <i
              class="pi pi-arrow-up"
              *ngIf="lastMonthDifference(totalIncome, totalIncomeLastMonth) > 0"
            ></i>
            {{
              lastMonthDifference(totalIncome, totalIncomeLastMonth)
                | currency : 'BRL'
            }}
            [{{ lastMonthPercentage(totalIncome, totalIncomeLastMonth) }} %]
          </span>
        </span>
      </div>
      <div class="dashboard-card">
        <span class="header header--primary">Assinantes</span>
        <span class="value">{{ totalSubscribers | number : '1.0-0' }}</span>
        <span class="value">
          <span
            class="last-month"
            *ngIf="
              lastMonthDifference(totalSubscribers, totalSubscribersLastMonth)
            "
            [class.last-month--negative]="
              lastMonthDifference(totalSubscribers, totalSubscribersLastMonth) <
              0
            "
            [class.last-month--positive]="
              lastMonthDifference(totalSubscribers, totalSubscribersLastMonth) >
              0
            "
            [pTooltip]="
              'Mês anterior ' + (totalSubscribersLastMonth | number : '1.2-2')
            "
            tooltipPosition="right"
          >
            <i
              class="pi pi-arrow-down"
              *ngIf="
                lastMonthDifference(
                  totalSubscribers,
                  totalSubscribersLastMonth
                ) < 0
              "
            ></i>
            <i
              class="pi pi-arrow-up"
              *ngIf="
                lastMonthDifference(
                  totalSubscribers,
                  totalSubscribersLastMonth
                ) > 0
              "
            ></i>
            {{
              lastMonthDifference(totalSubscribers, totalSubscribersLastMonth)
                | number : '1.0-0'
            }}
            [{{
              lastMonthPercentage(totalSubscribers, totalSubscribersLastMonth)
            }}
            %]
          </span>
        </span>
      </div>
    </div>
    <div class="info-data">
      <div class="conquistadas" *ngIf="conquistadas && conquistadasLastMonth">
        <div class="dashboard-card">
          <span class="header">Conquistadas</span>
          <span class="value">
            <b>
              {{ conquistadas.installmentTotal | currency : 'BRL' }}
            </b>
          </span>
          <span class="value">
            <span
              class="last-month"
              [class.last-month--negative]="
                lastMonthDifference(
                  conquistadas.installmentTotal || 0,
                  conquistadasLastMonth.installmentTotal || 0
                ) < 0
              "
              [class.last-month--positive]="
                lastMonthDifference(
                  conquistadas.installmentTotal || 0,
                  conquistadasLastMonth.installmentTotal || 0
                ) > 0
              "
              [pTooltip]="
                'Mês anterior ' +
                (conquistadasLastMonth.installmentTotal | currency : 'BRL')
              "
              tooltipPosition="right"
            >
              <i
                class="pi pi-arrow-down"
                *ngIf="
                  lastMonthDifference(
                    conquistadas.installmentTotal || 0,
                    conquistadasLastMonth.installmentTotal || 0
                  ) < 0
                "
              ></i>
              <i
                class="pi pi-arrow-up"
                *ngIf="
                  lastMonthDifference(
                    conquistadas.installmentTotal || 0,
                    conquistadasLastMonth.installmentTotal || 0
                  ) > 0
                "
              ></i>
              {{
                lastMonthDifference(
                  conquistadas.installmentTotal || 0,
                  conquistadasLastMonth.installmentTotal || 0
                ) | currency : 'BRL'
              }}
              [{{
                lastMonthPercentage(
                  conquistadas.installmentTotal || 0,
                  conquistadasLastMonth.installmentTotal || 0
                )
              }}
              %]
            </span>
          </span>
          <span class="value value--left">
            Ticket médio:
            <b> {{ conquistadas.installmentAvg | currency : 'BRL' }}</b> de
            <b> {{ conquistadas.subscriberCount | number : '1.0-0' }}</b>
            assinantes
          </span>
          <hr style="width: 100%" />
          <div class="value value--left">
            <table>
              <tbody>
                <tr *ngFor="let counter of conquistadasTable">
                  <td>
                    <a
                      class="data-link"
                      type="button"
                      (click)="showChart(counter)"
                    >
                      <b>
                        {{ counter.name }}
                      </b>
                    </a>
                    &nbsp;
                    <i
                      class="data-link pi pi-download"
                      (click)="findSubscribersAndExport(counter)"
                      pTooltip="Exportar assinantes"
                      *ngIf="counter.subscriberCount"
                      tooltipPosition="bottom"
                    ></i>
                  </td>
                  <td class="right">
                    <span>
                      {{ counter.installmentTotal | currency : 'BRL' }}
                    </span>
                    &nbsp;
                    <span
                      class="last-month"
                      [class.last-month--negative]="
                        lastMonthDifference(
                          counter.installmentTotal || 0,
                          subscriberGroupByGroupLastMonth(
                            counter.subscriberGroupId
                          )?.installmentTotal || 0
                        ) < 0
                      "
                      [class.last-month--positive]="
                        lastMonthDifference(
                          counter.installmentTotal || 0,
                          subscriberGroupByGroupLastMonth(
                            counter.subscriberGroupId
                          )?.installmentTotal || 0
                        ) > 0
                      "
                      [pTooltip]="
                        subscriberGroupByGroupLastMonth(
                          counter.subscriberGroupId
                        )?.installmentTotal
                          ? 'Mês anterior ' +
                            (subscriberGroupByGroupLastMonth(
                              counter.subscriberGroupId
                            )?.installmentTotal | currency : 'BRL')
                          : ''
                      "
                      tooltipPosition="right"
                    >
                      <i
                        class="pi pi-arrow-down"
                        *ngIf="
                          lastMonthDifference(
                            counter.installmentTotal || 0,
                            subscriberGroupByGroupLastMonth(
                              counter.subscriberGroupId
                            )?.installmentTotal || 0
                          ) < 0
                        "
                      ></i>
                      <i
                        class="pi pi-arrow-up"
                        *ngIf="
                          lastMonthDifference(
                            counter.installmentTotal || 0,
                            subscriberGroupByGroupLastMonth(
                              counter.subscriberGroupId
                            )?.installmentTotal || 0
                          ) > 0
                        "
                      ></i>
                      <ng-container
                        *ngIf="
                          lastMonthDifference(
                            counter.installmentTotal || 0,
                            subscriberGroupByGroupLastMonth(
                              counter.subscriberGroupId
                            )?.installmentTotal || 0
                          )
                        "
                      >
                        {{
                          lastMonthDifference(
                            counter.installmentTotal || 0,
                            subscriberGroupByGroupLastMonth(
                              counter.subscriberGroupId
                            )?.installmentTotal || 0
                          ) | currency : 'BRL'
                        }}
                        [{{
                          lastMonthPercentage(
                            counter.installmentTotal || 0,
                            subscriberGroupByGroupLastMonth(
                              counter.subscriberGroupId
                            )?.installmentTotal || 0
                          )
                        }}
                        %]
                      </ng-container>
                      <ng-container
                        *ngIf="
                          !lastMonthDifference(
                            counter.installmentTotal || 0,
                            subscriberGroupByGroupLastMonth(
                              counter.subscriberGroupId
                            )?.installmentTotal || 0
                          )
                        "
                      >
                        -
                      </ng-container>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr style="width: 100%" />
          <div class="value table value--left">
            <table>
              <thead>
                <tr>
                  <th>Assinatura</th>
                  <th class="right">Assinantes</th>
                  <th class="right">Receita total</th>
                  <th class="right">Ticket médio</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let counter of conquistadasSubscriptionTable">
                  <td>
                    {{ counter.subscriptionName }}
                    {{ counter.subscriptionTypeName }}
                  </td>
                  <td class="right">
                    {{ counter.subscriberCount | number : '1.0-0' }}
                  </td>
                  <td class="right">
                    {{ counter.installmentTotal | currency : 'BRL' }}
                  </td>
                  <td class="right">
                    {{ counter.installmentAvg | currency : 'BRL' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="mantidas" *ngIf="mantidas && mantidasLastMonth">
        <div class="dashboard-card">
          <span class="header">Mantidas</span>
          <span class="value">
            <b>
              {{ mantidas.installmentTotal | currency : 'BRL' }}
            </b>
          </span>
          <span class="value">
            <span
              class="last-month"
              [class.last-month--negative]="
                lastMonthDifference(
                  mantidas.installmentTotal || 0,
                  mantidasLastMonth.installmentTotal || 0
                ) < 0
              "
              [class.last-month--positive]="
                lastMonthDifference(
                  mantidas.installmentTotal || 0,
                  mantidasLastMonth.installmentTotal || 0
                ) > 0
              "
              [pTooltip]="
                'Mês anterior ' +
                (mantidasLastMonth.installmentTotal | currency : 'BRL')
              "
              tooltipPosition="right"
            >
              <i
                class="pi pi-arrow-down"
                *ngIf="
                  lastMonthDifference(
                    mantidas.installmentTotal || 0,
                    mantidasLastMonth.installmentTotal || 0
                  ) < 0
                "
              ></i>
              <i
                class="pi pi-arrow-up"
                *ngIf="
                  lastMonthDifference(
                    mantidas.installmentTotal || 0,
                    mantidasLastMonth.installmentTotal || 0
                  ) > 0
                "
              ></i>
              {{
                lastMonthDifference(
                  mantidas.installmentTotal || 0,
                  mantidasLastMonth.installmentTotal || 0
                ) | currency : 'BRL'
              }}
              [{{
                lastMonthPercentage(
                  mantidas.installmentTotal || 0,
                  mantidasLastMonth.installmentTotal || 0
                )
              }}
              %]
            </span>
          </span>
          <span class="value value--left">
            Ticket médio:
            <b>
              {{ mantidas.installmentAvg | currency : 'BRL' }}
            </b>
            de
            <b>{{ mantidas.subscriberCount | number : '1.0-0' }}</b> assinantes
          </span>
          <hr style="width: 100%" />
          <div class="value value--left">
            <table>
              <tbody>
                <tr *ngFor="let counter of mantidasTable">
                  <td>
                    <a
                      class="data-link"
                      type="button"
                      (click)="
                        showChart(
                          counter,
                          counter.subscriberGroupId !== 1100
                            ? 'daily'
                            : 'monthly'
                        )
                      "
                    >
                      <b>
                        {{ counter.name }}
                      </b>
                    </a>
                    &nbsp;
                    <i
                      class="data-link pi pi-download"
                      (click)="findSubscribersAndExport(counter)"
                      pTooltip="Exportar assinantes"
                      tooltipPosition="bottom"
                      *ngIf="counter.subscriberCount"
                    ></i>
                  </td>
                  <td class="right">
                    {{ counter.installmentTotal | currency : 'BRL' }}
                    &nbsp;
                    <span
                      class="last-month"
                      [class.last-month--negative]="
                        lastMonthDifference(
                          counter.installmentTotal || 0,
                          subscriberGroupByGroupLastMonth(
                            counter.subscriberGroupId
                          )?.installmentTotal || 0
                        ) < 0
                      "
                      [class.last-month--positive]="
                        lastMonthDifference(
                          counter.installmentTotal || 0,
                          subscriberGroupByGroupLastMonth(
                            counter.subscriberGroupId
                          )?.installmentTotal || 0
                        ) > 0
                      "
                      [pTooltip]="
                        subscriberGroupByGroupLastMonth(
                          counter.subscriberGroupId
                        )?.installmentTotal
                          ? 'Mês anterior ' +
                            (subscriberGroupByGroupLastMonth(
                              counter.subscriberGroupId
                            )?.installmentTotal | currency : 'BRL')
                          : ''
                      "
                      tooltipPosition="right"
                    >
                      <i
                        class="pi pi-arrow-down"
                        *ngIf="
                          lastMonthDifference(
                            counter.installmentTotal || 0,
                            subscriberGroupByGroupLastMonth(
                              counter.subscriberGroupId
                            )?.installmentTotal || 0
                          ) < 0
                        "
                      ></i>
                      <i
                        class="pi pi-arrow-up"
                        *ngIf="
                          lastMonthDifference(
                            counter.installmentTotal || 0,
                            subscriberGroupByGroupLastMonth(
                              counter.subscriberGroupId
                            )?.installmentTotal || 0
                          ) > 0
                        "
                      ></i>
                      <ng-container
                        *ngIf="
                          lastMonthDifference(
                            counter.installmentTotal || 0,
                            subscriberGroupByGroupLastMonth(
                              counter.subscriberGroupId
                            )?.installmentTotal || 0
                          )
                        "
                      >
                        {{
                          lastMonthDifference(
                            counter.installmentTotal || 0,
                            subscriberGroupByGroupLastMonth(
                              counter.subscriberGroupId
                            )?.installmentTotal || 0
                          ) | currency : 'BRL'
                        }}
                        [{{
                          lastMonthPercentage(
                            counter.installmentTotal || 0,
                            subscriberGroupByGroupLastMonth(
                              counter.subscriberGroupId
                            )?.installmentTotal || 0
                          )
                        }}
                        %]
                      </ng-container>
                      <ng-container
                        *ngIf="
                          !lastMonthDifference(
                            counter.installmentTotal || 0,
                            subscriberGroupByGroupLastMonth(
                              counter.subscriberGroupId
                            )?.installmentTotal || 0
                          )
                        "
                      >
                        -
                      </ng-container>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr style="width: 100%" />
          <div class="value table value--left">
            <table>
              <thead>
                <tr>
                  <th>Assinatura</th>
                  <th class="right">Assinantes</th>
                  <th class="right">Receita total</th>
                  <th class="right">Ticket médio</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let counter of mantidasSubscriptionTable">
                  <td>
                    {{ counter.subscriptionName }}
                    {{ counter.subscriptionTypeName }}
                  </td>
                  <td class="right">
                    {{ counter.subscriberCount | number : '1.0-0' }}
                  </td>
                  <td class="right">
                    {{ counter.installmentTotal | currency : 'BRL' }}
                  </td>
                  <td class="right">
                    {{ counter.installmentAvg | currency : 'BRL' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="mantidas" *ngIf="avulsas && avulsasLastMonth">
        <div class="dashboard-card">
          <span class="header">Edições avulsas</span>
          <span class="value">
            <b>
              {{ avulsas.installmentTotal | currency : 'BRL' }}
            </b>
          </span>
          <span class="value">
            <span
              class="last-month"
              [class.last-month--negative]="
                lastMonthDifference(
                  avulsas.installmentTotal || 0,
                  avulsasLastMonth.installmentTotal || 0
                ) < 0
              "
              [class.last-month--positive]="
                lastMonthDifference(
                  avulsas.installmentTotal || 0,
                  avulsasLastMonth.installmentTotal || 0
                ) > 0
              "
              [pTooltip]="
                'Mês anterior ' +
                (avulsasLastMonth.installmentTotal | currency : 'BRL')
              "
              tooltipPosition="right"
            >
              <i
                class="pi pi-arrow-down"
                *ngIf="
                  lastMonthDifference(
                    avulsas.installmentTotal || 0,
                    avulsasLastMonth.installmentTotal || 0
                  ) < 0
                "
              ></i>
              <i
                class="pi pi-arrow-up"
                *ngIf="
                  lastMonthDifference(
                    avulsas.installmentTotal || 0,
                    avulsasLastMonth.installmentTotal || 0
                  ) > 0
                "
              ></i>
              {{
                lastMonthDifference(
                  avulsas.installmentTotal || 0,
                  avulsasLastMonth.installmentTotal || 0
                ) | currency : 'BRL'
              }}
              [{{
                lastMonthPercentage(
                  avulsas.installmentTotal || 0,
                  avulsasLastMonth.installmentTotal || 0
                )
              }}
              %]
            </span>
          </span>
          <span class="value value--left">
            Ticket médio:
            <b>
              {{ avulsas.installmentAvg | currency : 'BRL' }}
            </b>
            de
            <b>{{ avulsas.subscriberCount | number : '1.0-0' }}</b> assinantes
          </span>
          <hr style="width: 100%" />
          <div class="value value--left">
            <table>
              <tbody>
                <tr *ngFor="let counter of avulsasTable">
                  <td>
                    <b>
                      {{ counter.name }}
                    </b>
                    &nbsp;
                    <i
                      class="data-link pi pi-download"
                      (click)="findSubscribersAndExport(counter)"
                      pTooltip="Exportar assinantes"
                      tooltipPosition="bottom"
                      *ngIf="counter.subscriberCount"
                    ></i>
                  </td>
                  <td class="right">
                    {{ counter.installmentTotal | currency : 'BRL' }}
                    &nbsp;
                    <span
                      class="last-month"
                      [class.last-month--negative]="
                        lastMonthDifference(
                          counter.installmentTotal || 0,
                          subscriberGroupByGroupLastMonth(
                            counter.subscriberGroupId
                          )?.installmentTotal || 0
                        ) < 0
                      "
                      [class.last-month--positive]="
                        lastMonthDifference(
                          counter.installmentTotal || 0,
                          subscriberGroupByGroupLastMonth(
                            counter.subscriberGroupId
                          )?.installmentTotal || 0
                        ) > 0
                      "
                      [pTooltip]="
                        subscriberGroupByGroupLastMonth(
                          counter.subscriberGroupId
                        )?.installmentTotal
                          ? 'Mês anterior ' +
                            (subscriberGroupByGroupLastMonth(
                              counter.subscriberGroupId
                            )?.installmentTotal | currency : 'BRL')
                          : ''
                      "
                      tooltipPosition="right"
                    >
                      <i
                        class="pi pi-arrow-down"
                        *ngIf="
                          lastMonthDifference(
                            counter.installmentTotal || 0,
                            subscriberGroupByGroupLastMonth(
                              counter.subscriberGroupId
                            )?.installmentTotal || 0
                          ) < 0
                        "
                      ></i>
                      <i
                        class="pi pi-arrow-up"
                        *ngIf="
                          lastMonthDifference(
                            counter.installmentTotal || 0,
                            subscriberGroupByGroupLastMonth(
                              counter.subscriberGroupId
                            )?.installmentTotal || 0
                          ) > 0
                        "
                      ></i>
                      <ng-container
                        *ngIf="
                          lastMonthDifference(
                            counter.installmentTotal || 0,
                            subscriberGroupByGroupLastMonth(
                              counter.subscriberGroupId
                            )?.installmentTotal || 0
                          )
                        "
                      >
                        {{
                          lastMonthDifference(
                            counter.installmentTotal || 0,
                            subscriberGroupByGroupLastMonth(
                              counter.subscriberGroupId
                            )?.installmentTotal || 0
                          ) | currency : 'BRL'
                        }}
                        [{{
                          lastMonthPercentage(
                            counter.installmentTotal || 0,
                            subscriberGroupByGroupLastMonth(
                              counter.subscriberGroupId
                            )?.installmentTotal || 0
                          )
                        }}
                        %]
                      </ng-container>
                      <ng-container
                        *ngIf="
                          !lastMonthDifference(
                            counter.installmentTotal || 0,
                            subscriberGroupByGroupLastMonth(
                              counter.subscriberGroupId
                            )?.installmentTotal || 0
                          )
                        "
                      >
                        -
                      </ng-container>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr style="width: 100%" />
          <div class="value table value--left">
            <table>
              <thead>
                <tr>
                  <th>Assinatura</th>
                  <th class="right">Assinantes</th>
                  <th class="right">Receita total</th>
                  <th class="right">Ticket médio</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let counter of avulsasSubscriptionTable">
                  <td>
                    {{ counter.subscriptionName }}
                  </td>
                  <td class="right">
                    {{ counter.subscriberCount | number : '1.0-0' }}
                  </td>
                  <td class="right">
                    {{ counter.installmentTotal | currency : 'BRL' }}
                  </td>
                  <td class="right">
                    {{ counter.installmentAvg | currency : 'BRL' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

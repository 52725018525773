<div class="product-images">
  <form class="form-b4a">
    <span class="p-float-label">
      <p-dropdown
        inputId="productVariant"
        [options]="productVariantsFilter"
        *ngIf="productVariantsFilter"
        [(ngModel)]="productVariantId"
        [filter]="true"
        filterBy="productVariantName,internalEAN,cean"
        (onChange)="filterImages()"
        name="productVariantId"
        [virtualScroll]="true"
        [virtualScrollItemSize]="45"
        appendTo="body"
      >
      </p-dropdown>
      <label for="productVariant">Filtro de variante</label>
    </span>
    <p-fileUpload
      mode="basic"
      chooseLabel="Adicionar imagem"
      name="file"
      [auto]="true"
      [customUpload]="true"
      (uploadHandler)="onUpload($event, fileUpload)"
      accept="image/*"
      [maxFileSize]="1000000"
      uploadIcon="pi pi-file"
      id="fileUpload"
      #fileUpload
      [multiple]="true"
    ></p-fileUpload>
  </form>

  <app-drag-drop-list
    (listChanged)="listReordered($event)"
    [list]="filteredImages"
    [template]="imageTemplate"
  >
    <ng-template #imageTemplate let-image="data">
      <p-button
        [rounded]="true"
        severity="danger"
        [text]="true"
        icon="pi pi-trash"
        pTooltip="Excluir imagem"
        tooltipPosition="bottom"
        type="button"
        (onClick)="confirmDeleteImage(image, $event)"
      ></p-button>
      <img style="height: 25vh; width: auto" [src]="image.imageUrl" alt="" />
      <span class="p-float-label" *ngIf="productVariants">
        <p-dropdown
          [options]="productVariants"
          optionLabel="productVariantName"
          optionValue="productVariantId"
          (onChange)="updateProductVariant($event, image.productImageId)"
          appendTo="body"
          [(ngModel)]="image.productVariantId"
          [inputId]="'productVariantId' + image.productImageId"
          [virtualScroll]="true"
          [virtualScrollItemSize]="45"
        ></p-dropdown>
        <label [for]="'productVariantId' + image.productImageId"
          >Variante</label
        >
      </span>
    </ng-template>
  </app-drag-drop-list>
</div>
<p-confirmPopup styleClass="danger"> </p-confirmPopup>

<p-sidebar
  [visible]="true"
  [style]="{ background: '#1c1a1b' }"
  styleClass="sidebar"
  [showCloseIcon]="true"
  [closeOnEscape]="false"
  [dismissible]="true"
  [modal]="false"
>
  <ng-container>
    <div class="logo">
      <img *ngIf="expanded" src="assets/images/Logo_B4A_White.png" />
      <p-button
        icon="pi {{
          expanded ? 'pi-angle-double-left' : 'pi-angle-double-right'
        }}"
        [rounded]="true"
        [pTooltip]="expanded ? 'Esconder' : 'Expandir'"
        [text]="true"
        tooltipPosition="bottom"
        (onClick)="changeState()"
      ></p-button>
    </div>
    <p-panelMenu
      [model]="items"
      *ngIf="expanded"
      [multiple]="false"
    ></p-panelMenu>
  </ng-container>
</p-sidebar>

<form
  [formGroup]="categoryForm"
  class="form-b4a"
  (submit)="submit()"
  *ngIf="ready"
>
  <p-fieldset legend="Informações básicas">
    <span class="p-float-label">
      <input
        id="name"
        type="text"
        [ngClass]="{
          'ng-invalid ng-dirty': invalidControl('name')
        }"
        pInputText
        formControlName="name"
        (input)="slugify()"
        (blur)="!categoryForm.value.isCollection && setCategoryTreeLabel()"
      />
      <label for="name">Nome *</label>
      <ng-container *ngIf="invalidControl('name')">
        <small
          class="error"
          *ngIf="categoryForm.controls['name'].error?.required"
          class="invalid-feedback"
        >
          Obrigatório
        </small>
      </ng-container>
    </span>

    <span class="p-float-label">
      <input id="slug" type="text" pInputText formControlName="slug" readonly />
      <label for="slug">Slug *</label>
      <small
        *ngIf="
          categoryForm.controls['slug'].errors &&
          categoryForm.controls['slug'].errors['required']
        "
      >
        Preencha o campo de nome para criar o slug</small
      >
      <small
        *ngIf="
          categoryForm.controls['slug'].errors &&
          categoryForm.controls['slug'].errors['exists']
        "
      >
        Já existe categoria com slug especificado!</small
      >
    </span>

    <span class="p-input-editor">
      <label for="description">Descrição</label>
      <p-editor
        #description
        formControlName="description"
        id="description"
        [ngClass]="{
          'ng-invalid ng-dirty': invalidControl('description')
        }"
      />
    </span>
  </p-fieldset>

  <p-fieldset legend="Configurações">
    <span class="full-w">
      <label for="type">Tipo</label>
      <p-selectButton
        [options]="[
          { value: 'collection', label: 'Coleção' },
          { value: 'brandCategory', label: 'Marca' },
          { value: 'levelOneCategory', label: 'Categoria nível 1' },
          { value: 'category', label: 'Categoria' }
        ]"
        formControlName="type"
        inputId="type"
        (onChange)="collectionChange($event)"
      />
    </span>

    <span class="p-input-checkbox">
      <p-checkbox
        formControlName="published"
        [binary]="true"
        inputId="published"
      />
      <label for="published">Publicada/Ativa</label>
      <small>Categoria ativa e visível na loja</small>
    </span>

    <span class="p-input-checkbox">
      <p-checkbox formControlName="levelOneCategory"
        [binary]="true"
        (onChange)="categoryLevelOndeChange($event)"
        inputId="levelOneCategory" />
      <label for="levelOneCategory">Categoria nível 1</label>
      <small>Categoria de menu de loja sem catetoria superior</small>
    </span>

    <ng-container *ngIf="!categoryForm.controls['isCollection'].getRawValue()">
      <span
        class="p-float-label"
        *ngIf="categoryForm.controls['parent'].enabled"
      >
        <p-dropdown
          formControlName="parent"
          [options]="categories"
          filterBy="name,reference,shop"
          appendTo="body"
          (onChange)="changeParentCategory($event.value.id)"
          id="parent"
          [filter]="true"
          [virtualScroll]="true"
          [virtualScrollItemSize]="38"
        >
          <ng-template let-category pTemplate="selectedItem">
            <span
              >{{ category.name }} ({{ category.reference }}) [{{
                category.shop
              }}]</span
            >
          </ng-template>
          <ng-template let-category pTemplate="item">
            <span
              >{{ category.name }} ({{ category.reference }}) [{{
                category.shop
              }}]</span
            >
          </ng-template>
        </p-dropdown>

        <label for="parent"
          >Categoria superior
          <ng-container *ngIf="isRequired(categoryForm.controls['parent'])"
            >*</ng-container
          ></label
        >
        <small>Categoria a qual pertence essa categoria</small>
        <ng-container *ngIf="invalidControl('parent')">
          <small
            class="error"
            *ngIf="categoryForm.controls['parent']?.error?.required"
            class="invalid-feedback"
          >
            Obrigatório
          </small>
        </ng-container>
      </span>

      <span class="p-float-label">
        <input
          id="reference"
          type="text"
          pInputText
          autocomplete="off"
          [ngClass]="{
            'ng-invalid ng-dirty': categoryForm.controls['reference'].errors
          }"
          formControlName="reference"
          (input)="validateReference() && setCategoryTreeLabel()"
          (blur)="validateReference() && setCategoryTreeLabel()"
        />
        <label for="reference">Referência * </label>
        <small *ngIf="categoryForm.controls['reference'].valid"
          >Código da referência da categoria</small
        >
        <small
          *ngIf="
            categoryForm.controls['reference'].errors &&
            categoryForm.controls['reference'].errors['format']
          "
        >
          {{ categoryForm.controls['reference'].errors['format'] }}
        </small>
      </span>

      <span
        class="p-float-label"
        *ngIf="
          !categoryForm.value.isCollection &&
          !categoryForm.value.levelOneCategory
        "
      >
        <input type="text" pInputText formControlName="parentTree" />
      </span>
    </ng-container>

    <span class="p-float-label full-w" formGroupName="conditions">
      <p-chips
        formControlName="tags"
        [separator]="chipRegex"
        inputId="tags"
        name="tags"
        [addOnTab]="true"
        [addOnBlur]="true"
        [showClear]="true"
        (onAdd)="addTag($event)"
        (onBlur)="addTag($event)"
        (onClear)="
          categoryForm.controls['conditions'].controls['tags'].setValue([])
        "
      />
      <label for="tags">Tags</label>
      <small>Utilize "," ou "Enter" para adicionar</small>
    </span>

    <span class="p-float-label">
      <p-autoComplete
        [suggestions]="filterOptionsAvailable"
        formControlName="filters"
        filterBy="description"
        optionLabel="slug"
        appendTo="body"
        [lazy]="false"
        [multiple]="true"
        (completeMethod)="searchFilters($event)"
        [showClear]="true"
        (onClear)="categoryForm.controls['filters'].setValue([])"
      />
      <label for="filters">Filtros</label>
    </span>
  </p-fieldset>

  <p-fieldset legend="Exibição em loja *">
    <span class="p-input-checkbox" *ngFor="let shop of shops">
      <p-checkbox
        formControlName="shops"
        name="shops"
        [inputId]="shop.value"
        [value]="shop.value"
        (onChange)="changeShopAvailability()"
      />
      <label [for]="shop.value">{{ shop.label | titlecase }}</label>
    </span>

    <small
      *ngIf="
        categoryForm.controls['shops'].errors &&
        categoryForm.controls['shops'].errors['required']
      "
    >
      * Selecione pelo menos uma opção
    </small>
  </p-fieldset>

  <p-fieldset
    legend="Metadados"
    class="full"
    *ngIf="categoryForm.controls.shops.valid"
  >
    <span
      class="p-input-checkbox full-w"
      *ngIf="categoryForm.value.shops?.length > 1"
    >
      <p-checkbox
        formControlName="copyMetadata"
        inputId="copyMetadata"
        [binary]="true"
        (onChange)="copyMetadata()"
      />
      <label for="copyMetadata">Iguais em todas as lojas</label>
      <small>Título e descrição serão os mesmos em todas as lojas</small>
    </span>

    <ng-container
      formArrayName="metadatas"
      *ngIf="categoryForm.controls.metadatas.controls.length"
    >
      <ng-container
        *ngIf="
          categoryForm.value.copyMetadata ||
          categoryForm.controls.metadatas.controls.length === 1
        "
        formGroupName="0"
      >
        <span class="p-float-label">
          <input
            id="metadataTitle"
            type="text"
            pInputText
            (input)="copyValue($event, 'title')"
            formControlName="title"
          />
          <label for="metadataTitle">Título *</label>
        </span>

        <span class="p-float-label">
          <input
            id="metadataDescription"
            type="text"
            pInputText
            (input)="copyValue($event, 'description')"
            formControlName="description"
          />
          <label for="metadataDescription">Descrição *</label>
        </span>
      </ng-container>
      <ng-container
        *ngIf="
          !categoryForm.value.copyMetadata &&
          categoryForm.controls.metadatas.controls.length > 1
        "
      >
        <ng-container
          *ngFor="
            let metadata of categoryForm.controls.metadatas.controls;
            let i = index
          "
          [formGroupName]="i"
        >
          <span class="p-float-label">
            <input
              [id]="'metadataTitle_' + i"
              type="text"
              pInputText
              (input)="categoryForm.updateValueAndValidity()"
              formControlName="title"
            />
            <label [for]="'metadataTitle_' + i"
              >Título {{ shop(metadata.value.shop)?.label }} *</label
            >
          </span>

          <span class="p-float-label">
            <input
              [id]="'metadataDescription_' + i"
              type="text"
              pInputText
              (input)="categoryForm.updateValueAndValidity()"
              formControlName="description"
            />
            <label [for]="'metadataDescription_' + i"
              >Descrição {{ shop(metadata.value.shop)?.label }} *</label
            >
          </span>
        </ng-container>
      </ng-container>
    </ng-container>
  </p-fieldset>

  <div class="form-footer">
    <div class="info">
      <div *ngIf="category">
        <p>*Última alteração em: {{ category.updatedAt | date : 'short' }}</p>
      </div>
    </div>
    <div class="action">
      <p-button
        type="submit"
        pRipple
        label="Salvar"
        severity="primary"
        [disabled]="!categoryForm.valid || categoryForm.invalid"
      />
    </div>
  </div>
</form>

export enum QuestionTypeEnum {
  // 'Dropdown' = 1,
  'Texto simples' = {
    label: 'Texto simples',
    value: 2,
    description: 'O usuário deve informar um texto curto'
  } as any,
  'Resposta única' = {
    label: 'Resposta única',
    value: 3,
    description: 'O usuário deve selecionar apenas 1 alternativa'
  } as any,
  'Reposta múltipla' = {
    label: 'Reposta múltipla',
    value: 4,
    description: 'O usuário pode selecionar várias alternativas'
  } as any,
  'Texto grande' = {
    label: 'Texto grande',
    value: 5,
    description: 'O usuário deve informar um texto longo'
  } as any,
  'Matriz de opções' = {
    label: 'Matriz de opções',
    value: 6,
    disabled: true,
    description: 'O usuário deve selecionar as opções em uma matriz'
  } as any
  // 'Seleção de ícone' = 7
}

export function getAllQuestionTypesActive(): Array<{
  label: string;
  value: number;
  description: string;
}> {
  const objetos: Array<{ label: string; value: number; description: string }> =
    [];
  for (const [, propertyValue] of Object.entries(QuestionTypeEnum)) {
    if (
      propertyValue !== null &&
      typeof propertyValue === 'object' &&
      !(propertyValue as any)['disabled']
    ) {
      objetos.push(propertyValue);
    }
  }
  return objetos;
}

export function getAllQuestionTypes(): Array<{
  label: string;
  value: number;
  description: string;
  disabled?: boolean;
}> {
  const objetos: Array<{
    label: string;
    value: number;
    description: string;
    disabled?: boolean;
  }> = [];
  for (const [, propertyValue] of Object.entries(QuestionTypeEnum)) {
    if (propertyValue !== null && typeof propertyValue === 'object') {
      objetos.push(propertyValue);
    }
  }
  return objetos;
}

export function getAllQuestionTypesIncludeAll(): Array<{
  label: string;
  value: number;
}> {
  const objetos: Array<{ label: string; value: number }> = [];
  for (const [, propertyValue] of Object.entries(QuestionTypeEnum)) {
    if (propertyValue !== null && typeof propertyValue === 'object') {
      objetos.push(propertyValue);
    }
  }
  return objetos.concat([
    {
      label: 'Todas',
      value: 0
    }
  ]);
}

export function getQuestionTypeName(id: number): string | undefined {
  return getAllQuestionTypes().find((e) => e.value === id)?.label;
}

export function getQuestionTypeDescription(id: number): string | undefined {
  return getAllQuestionTypes().find((e) => e.value === id)?.description;
}

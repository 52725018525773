import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { ConfirmationService, MessageService, PrimeIcons } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CreditCardSummary } from 'src/app/admin-api';
import { DisableRecurrenceModalComponent } from '../disable-recurrence-modal/disable-recurrence-modal.component';
import { TableActionButton, TableColumn } from '../table';

@Component({
  selector: 'app-person-credit-cards',
  templateUrl: './person-credit-cards.component.html',
  styleUrls: ['./person-credit-cards.component.scss'],
  providers: [ConfirmationService]
})
export class PersonCreditCardsComponent {
  @Input()
  creditCards: Array<CreditCardSummary & { flag: string }> | undefined;

  @Input()
  personId: number | undefined;

  @Input()
  isMobile?: boolean;

  @Output()
  cardRemoved = new EventEmitter<void>();

  private dialog = inject(DialogService);
  private messageService = inject(MessageService);

  cardCols: Array<TableColumn> = [
    new TableColumn('Id', 'creditCardId', false, 'number'),
    new TableColumn('Bandeira', 'flag', false, 'image'),
    new TableColumn('Final', 'lastDigits', false, 'text'),
    new TableColumn('Nome', 'nameOnCard', false, 'text'),
    new TableColumn('Assinatura', 'glamId', false, 'text'),
    new TableColumn('Recorrência', 'recurrence', false, 'text'),
    new TableColumn('Data cadastro', 'dateCreated', false, 'date'),
    new TableColumn('Ação', '', false, 'button')
  ];
  actionButtons: Array<TableActionButton> = [
    new TableActionButton(
      '',
      (card: CreditCardSummary) => this.confirmDeleteCard(card),
      PrimeIcons.BAN,
      this.isDeleteCard,
      '',
      'Remover cartão',
      'bottom',
      true,
      true,
      'danger'
    ),
    new TableActionButton(
      '',
      (card: CreditCardSummary) => this.confirmDeleteCard(card),
      PrimeIcons.BAN,
      this.isRecurrenceCard,
      '',
      'Desabilitar recorrência',
      'bottom',
      true,
      true,
      'danger'
    )
  ];

  confirmDeleteCard(creditCard: CreditCardSummary): void {
    let width = '100%';
    if (!this.isMobile) {
      width = '500px';
    }
    this.dialog
      .open(DisableRecurrenceModalComponent, {
        closable: true,
        header: this.isDeleteCard(creditCard)
          ? 'Remover cartão da assinatura'
          : 'Desabilitar recorrência',
        data: {
          subscriberId: creditCard?.subscriberId,
          removeCard: this.isDeleteCard(creditCard)
        },
        width
      })
      .onClose.subscribe(
        async (
          detail?: string | Array<CreditCardSummary & { flag: string }>
        ) => {
          if (detail && typeof detail === 'string') {
            this.messageService.add({
              severity: 'success',
              summary: 'Sucesso',
              detail
            });
            if (this.isRecurrenceCard(creditCard)) {
              creditCard.recurrenceStatusId = 2;
              creditCard.recurrence = 'Desabilitado';
            } else if (this.isDeleteCard(creditCard)) {
              delete creditCard.subscriberId;
              delete creditCard.glamId;
            }
          } else if (detail && Array.isArray(detail)) {
            this.creditCards = detail;
          }
        }
      );
  }

  isDeleteCard(card: CreditCardSummary): boolean {
    return card.subscriberId && card.recurrenceStatusId !== 0;
  }

  isRecurrenceCard(card: CreditCardSummary): boolean {
    return card.subscriberId && card.recurrenceStatusId === 0;
  }
}

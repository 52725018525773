import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { lastValueFrom, map } from 'rxjs';
import {
  FileControllerService,
  ProductControllerService,
  ProductImage,
  ProductVariant
} from 'src/app/admin-api';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-product-images',
  templateUrl: './product-images.component.html',
  styleUrls: ['./product-images.component.scss'],
  providers: [ConfirmationService]
})
export class ProductImagesComponent implements OnInit, OnChanges {
  @Input()
  images: Array<ProductImage> | undefined;

  @Input()
  productVariants: Array<ProductVariant> | undefined;

  @Input()
  productId: number | undefined;

  @Output()
  imagesChanged = new EventEmitter<Array<ProductImage>>();

  productVariantId = -1;
  filteredImages: Array<ProductImage> | undefined;
  productVariantsFilter: Array<{ label: string; value: number }> | undefined;

  constructor(
    private productService: ProductControllerService,
    private cdRef: ChangeDetectorRef,
    private fileService: FileControllerService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['images']?.currentValue?.length !==
      changes['images']?.previousValue?.length
    ) {
      this.filterImages();
    } else if (
      changes['productVariants']?.currentValue?.length !==
      changes['productVariants']?.previousValue?.length
    ) {
      this.syncProductVariants();
    }
  }

  syncProductVariants(): void {
    this.productVariants = [
      { productVariantId: 0, productVariantName: 'Sem variante' },
      ...(this.productVariants || [])
    ];
    this.productVariantsFilter = [
      { label: 'Sem filtro', value: -1 },
      ...(this.productVariants.map((p) => ({
        label: p.productVariantName as string,
        value: p.productVariantId as number
      })) || [])
    ];
  }

  ngOnInit(): void {
    this.syncProductVariants();
    this.filterImages();
  }

  filterImages(): void {
    this.filteredImages = [
      ...(this.images?.filter((i) => {
        if (!this.productVariantId) {
          return !i.productVariantId;
        } else if (this.productVariantId > 0) {
          return i.productVariantId === this.productVariantId;
        }
        return true;
      }) || [])
    ];
  }

  async updateProductVariant(
    $event: {
      originalEvent?: Event;
      value: number;
    },
    imageId?: number
  ): Promise<void> {
    LoaderService.showLoader();
    try {
      await lastValueFrom(
        this.productService.updateImageProductVariantId({
          productImageId: imageId,
          productVariantId: $event.value
        })
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  async listReordered($event: Array<ProductImage>): Promise<void> {
    LoaderService.showLoader();
    try {
      this.images = await lastValueFrom(
        this.productService
          .saveImagePositions(
            this.productId as number,
            $event?.map((i) => i.productImageId as number)
          )
          .pipe(map((data) => data.result))
      );
      this.filterImages();
      this.imagesChanged.emit(this.images);
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  async onUpload(
    $event: { files: File[] },
    fileUpload: FileUpload
  ): Promise<void> {
    LoaderService.showLoader();
    try {
      const results = await Promise.all(
        $event.files.map((file, i) =>
          lastValueFrom(
            this.fileService
              .uploadFileForm(
                this.filePath,
                file,
                this.productId?.toString() +
                  '_' +
                  new Date().getTime().toString() +
                  '_' +
                  i +
                  '.' +
                  file.type.split('/')[1]
              )
              .pipe(map((data) => data.result))
          )
        )
      );
      if (results?.length) {
        const images = await Promise.all(
          results.map((result, i) =>
            lastValueFrom(
              this.productService
                .addProductImage({
                  imageUrl: result,
                  position: this.images?.length + i,
                  productId: this.productId
                })
                .pipe(map((data) => data.result))
            )
          )
        );
        if (images) {
          this.images = (this.images || []).concat(images);
          this.images.sort(
            (i1, i2) => (i1.position as number) - (i2.position as number)
          );
          this.filterImages();
          this.imagesChanged.emit(this.images);
          this.cdRef.detectChanges();
        }
      }
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
    fileUpload.clear();
    LoaderService.showLoader(false);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async confirmDeleteImage(image: ProductImage, $event: any): Promise<void> {
    this.confirmationService.confirm({
      target: $event.target,
      message: 'Deseja excluir a imagem?',
      header: 'Atenção',
      icon: 'pi pi-exclamation-triangle danger',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      acceptButtonStyleClass: 'p-button-primary',
      rejectButtonStyleClass: 'p-button-danger',
      accept: async () => {
        LoaderService.showLoader();
        await this.deleteImage(image.productImageId as number);
        this.images = [
          ...(this.images?.filter(
            (i) =>
              (i.productImageId as number) !== (image.productImageId as number)
          ) || [])
        ];
        this.cdRef.detectChanges();
        LoaderService.showLoader(false);
      }
    });
  }

  async deleteImage(imageId: number): Promise<void> {
    try {
      await lastValueFrom(this.productService.removeProductImage(imageId));
      this.images = [
        ...(this.images?.filter((i) => i.productImageId !== imageId) || [])
      ];
      this.images.sort(
        (i1, i2) => (i1.position as number) - (i2.position as number)
      );
      this.filterImages();
      this.imagesChanged.emit(this.images);
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
  }

  get filePath(): string {
    return '/admin/product';
  }
}

import { Component, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { ItOpsControllerService, RenewalReprocess } from 'src/app/admin-api';
import { TableColumn, TableComponent } from 'src/app/components/table';
import { SubscriberProblemGroupEnum } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-renewal-reprocess',
  templateUrl: './renewal-reprocess.component.html',
  styleUrls: ['./renewal-reprocess.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class RenewalReprocessComponent {
  @ViewChild(TableComponent) table: TableComponent;

  columns: Array<TableColumn> = [
    new TableColumn('', '', false, 'checkbox'),
    new TableColumn(
      'Nome',
      'nome',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'E-mail',
      'email',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Phone',
      'phone',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'PersonId',
      'personId',
      true,
      'number',
      '/users/person/',
      'personId'
    ),
    new TableColumn(
      'SubscriberId',
      'subscriberid',
      true,
      'number',
      '/users/subscribers/',
      'subscriberid'
    ),
    new TableColumn(
      'Name Type',
      'nameType',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Data de Criação',
      'dateCreated',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    ),
    new TableColumn(
      'TID',
      'tid',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Total',
      'amount',
      true,
      'number',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Mensagem de Autorização',
      'authorizationMessage',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Status da Transação',
      'transactionStatusId',
      true,
      'number'
    ),
    new TableColumn(
      'Tipo da Assinatura',
      'subscriptionName',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Recorrência da Assinatura',
      'plano',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn('Id do Pagamento', 'creditCardPaymentId', true, 'number')
  ];

  constructor(
    public itOpsService: ItOpsControllerService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  markAsResolved(elements: Array<RenewalReprocess>): void {
    this.confirmationService.confirm({
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      rejectButtonStyleClass: 'p-button-danger',
      message: 'Deseja enviar o relatório?',
      header: 'Corrigir',
      accept: async () => {
        LoaderService.showLoader();
        try {
          await lastValueFrom(
            this.itOpsService.markAsResolved(
              SubscriberProblemGroupEnum.RenewalReprocess,
              elements
            )
          );
          await this.table.refresh(true);
          this.messageService.add({
            summary: 'Sucesso',
            detail: elements.length + ' marcado(s) como resolvido(s)',
            severity: 'success'
          });
          LoaderService.showLoader(false);
        } catch (error) {
          LoaderService.showLoader(false);
          AppDialogService.showErrorDialog(error);
        }
      }
    });
  }
}

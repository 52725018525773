import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MessageService, PrimeIcons } from 'primeng/api';
import { lastValueFrom, map } from 'rxjs';
import {
  BelvoOpenFinanceInstitution,
  CreditCardPaymentSummary,
  FinancialControllerService,
  Gateway,
  PageableRequest,
  TransactionStatus
} from 'src/app/admin-api';
import {
  DropdownFilter,
  PageContent,
  TableActionButton,
  TableColumn
} from 'src/app/components/table';
import { TransactionStatusEnum } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PaymentsComponent implements OnInit {
  cols: Array<TableColumn> = [
    new TableColumn(
      'Id assinatura',
      'subscriberId',
      true,
      'number',
      '/users/subscribers/',
      'subscriberId'
    ),
    new TableColumn(
      'Assinante',
      'personName',
      true,
      'text',
      '/users/person/',
      'personId',
      true,
      'contains'
    ),
    new TableColumn('TID', 'tid', true, 'text', undefined, undefined, true),
    new TableColumn(
      'Gateway',
      'gatewayName',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),

    new TableColumn.Builder()
      .setHeader('Instituição de pagamento')
      .setField('payerInstitution')
      .setStyleClass('institution')
      .setInnerHTML((payment: CreditCardPaymentSummary) =>
        this.payerInstitutionImg(payment)
      )
      .setRouterLink(
        (payment: CreditCardPaymentSummary) =>
          this.payerInstitution(payment)?.website ||
          FormUtil.gatewayWebsite(payment.gatewayId)
      )
      .setSortable(false)
      .build(),
    new TableColumn('Valor', 'amount', false, 'currency'),
    new TableColumn('Parcelas', 'installments', false, 'text'),
    new TableColumn(
      'Data',
      'dateCreated',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    ),
    new TableColumn('Status', 'transactionStatusName', true, 'number'),
    new TableColumn('Detalhe', 'authorizationMessage', true, 'text'),
    new TableColumn('Tipo', 'creditCardPaymentType', true, 'text'),
    new TableColumn.Builder()
      .setHeader('Ação')
      .setField(' ')
      .setFilter(false)
      .setType('button')
      .build()
  ];
  dropdownFilters: {
    [field: string]: Array<DropdownFilter>;
  } = {
    transactionStatusName: []
  };
  transactionStatusList: Array<TransactionStatus> | undefined;
  gateways: Array<Gateway> | undefined;
  institutions: BelvoOpenFinanceInstitution[];
  actionButtons = [
    new TableActionButton.Builder()
      .setIcon(PrimeIcons.COPY)
      .setAction((payment: CreditCardPaymentSummary) =>
        this.copyToClipboard(payment.nextStep)
      )
      .setSeverity('primary')
      .setSize('small')
      .setStyleClass('p-button-xs')
      .setText(true)
      .setTooltip('Copiar link de pagamento')
      .setTooltipPosition('bottom')
      .setDisplay(
        (payment: CreditCardPaymentSummary) =>
          !!payment.nextStep?.trim() &&
          [
            TransactionStatusEnum.EM_ANDAMENTO,
            TransactionStatusEnum.CRIADA
          ].includes(payment.transactionStatusId)
      )
      .build()
  ];
  constructor(
    public financialService: FinancialControllerService,
    private title: Title,
    private clipboard: Clipboard,
    private messageService: MessageService
  ) {
    this.title.setTitle('Pagamentos');
  }

  async ngOnInit(): Promise<void> {
    await Promise.all([
      this.findTransactionStatusList(),
      this.findGatewayList(),
      this.findOpenFinanceInstitutions()
    ]);
  }

  async findTransactionStatusList(): Promise<void> {
    try {
      this.transactionStatusList = await lastValueFrom(
        this.financialService
          .findTransactionStatusList()
          .pipe(map((data) => data.result))
      );
      this.dropdownFilters['transactionStatusName'] = [
        { label: 'Todos', value: '-1' }
      ].concat(
        this.transactionStatusList?.map(
          (t) =>
            ({
              label: t.transactionStatusName,
              value: t.transactionStatusId?.toString()
            } as { label: string; value: string })
        ) || []
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findGatewayList(): Promise<void> {
    try {
      this.gateways = await lastValueFrom(
        this.financialService
          .findGatewaysList()
          .pipe(map((data) => data.result))
      );
      this.dropdownFilters['gatewayName'] = [
        { label: 'Todos', value: '-1' }
      ].concat(
        this.gateways?.map(
          (g) =>
            ({
              label: g.gatewayName,
              value: g.gatewayId?.toString()
            } as { label: string; value: string })
        ) || []
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findPage(
    request: PageableRequest,
    service: unknown
  ): Promise<PageContent<CreditCardPaymentSummary>> {
    const filterTransaction = request.filters?.find(
      (f) => f.field === 'transactionStatusName'
    );
    if (filterTransaction?.value) {
      if (Number(filterTransaction.value) < 0) {
        filterTransaction.value = '';
      }
      request.filters?.push({
        condition: 'equals',
        field: 'transactionStatusId',
        fieldType: 'number',
        value: filterTransaction.value
      });
    }
    const filterGateway = request.filters?.find(
      (f) => f.field === 'gatewayName'
    );
    if (filterGateway?.value) {
      if (Number(filterGateway.value) < 0) {
        filterGateway.value = '';
      }
      request.filters?.push({
        condition: 'equals',
        field: 'gatewayId',
        fieldType: 'number',
        value: filterGateway.value
      });
    }
    return lastValueFrom(
      (service as FinancialControllerService)
        .findPaymentTable(request)
        .pipe(
          map((data) => data.result as PageContent<CreditCardPaymentSummary>)
        )
    );
  }

  payerInstitution(payment: CreditCardPaymentSummary) {
    return this.institutions?.find((i) => i.id === payment.payerInstitution);
  }

  async findOpenFinanceInstitutions(): Promise<void> {
    try {
      this.institutions = await lastValueFrom(
        this.financialService
          .findOpenFinanceInstitutions()
          .pipe(map((data) => data.result))
      );
      this.institutions.sort((i1, i2) =>
        i1.display_name.localeCompare(i2.display_name)
      );
      this.dropdownFilters['payerInstitution'] = this.institutions.map((i) => ({
        label: i.display_name,
        value: i.id
      }));
    } catch (error: any) {
      this.institutions = [];
    }
  }

  payerInstitutionImg(payment: CreditCardPaymentSummary) {
    return this.payerInstitution(payment)?.logo ||
      this.payerInstitution(payment)?.text_logo ||
      this.payerInstitution(payment)?.icon_logo
      ? `<img src="${
          this.payerInstitution(payment)?.logo ||
          this.payerInstitution(payment)?.text_logo ||
          this.payerInstitution(payment)?.icon_logo
        }" alt="${this.payerInstitution(payment).legalEntity_name}">`
      : this.payerInstitution(payment)?.display_name ||
          FormUtil.gatewayImage(payment.gatewayId) ||
          payment.gatewayName;
  }

  copyToClipboard(text: string): void {
    this.clipboard.copy(text);
    this.messageService.add({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Copiado para área de trasnferência'
    });
  }
}

<app-table
  [lazy]="false"
  [elements]="content"
  [cols]="cols"
  [sortField]="cols[0].field"
  [sortOrder]="-1"
  [addButton]="false"
  [tableTitle]="query.queryName"
  modelName="registros"
  [defaultRows]="15"
  *ngIf="query && content && cols"
></app-table>

import { Clipboard } from '@angular/cdk/clipboard';
import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  IncorrectPendingBilling,
  ItOpsControllerService
} from 'src/app/admin-api';
import { SubscriberProblemGroupEnum } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-incorrect-pending-billing-modal',
  templateUrl: './incorrect-pending-billing-modal.component.html',
  styleUrls: ['./incorrect-pending-billing-modal.component.scss'],
  providers: [MessageService]
})
export class IncorrectPendingBillingModalComponent {
  billings: Array<IncorrectPendingBilling>;
  billingUpdate: string;
  recurrenceUpdate: string;

  constructor(
    private config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private itOpsService: ItOpsControllerService,
    private clipboard: Clipboard,
    private messageService: MessageService
  ) {
    this.billings = this.config.data.billings;
    this.billingUpdate = this.billings?.reduce((sql, billing) => {
      sql += `UPDATE [Glambox.Subscription].dbo.SubscriberBilling set EditionId = ${billing.correctPending} where SubscriberId = ${billing.subscriberId} and SubscriberBillingStatus = 0;\n`;
      return sql;
    }, '');
    this.recurrenceUpdate = this.billings?.reduce((sql, billing) => {
      if (billing.correctPending && billing.subscriberId)
        sql += `UPDATE [Glambox.Subscription].dbo.Subscriber set NextAttemptDate = NULL, Attempt = 0, NextPaymentDate = '${`${Number(
          ((billing.correctPending % 1000000) / 100).toFixed(0)
        )}-${(billing.correctPending % 100)
          .toString()
          .padStart(2, '0')}-02 08:00:00.000'`} where SubscriberId = ${
          billing.subscriberId
        };\n`;
      return sql;
    }, '');
  }

  async markAsResolved(): Promise<void> {
    LoaderService.showLoader();
    try {
      const dashboard = await lastValueFrom(
        this.itOpsService
          .markAsResolved(
            SubscriberProblemGroupEnum.IncorrectPendingBilling,
            this.billings
          )
          .pipe(map((data) => data.result))
      );
      this.ref.close(dashboard);
    } catch (error) {
      LoaderService.showLoader(false);
      AppDialogService.showErrorDialog(error);
    }
  }

  copyToClipboard(text: string): void {
    this.clipboard.copy(text);
    this.messageService.add({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Copiado para área de trasnferência'
    });
  }
}

export enum ProductReviewStatus {
  // 'New' = 0,
  'Pending' = 1,
  'Denied' = 2,
  'Accepted' = 3
}

export function getAllProductReviewStatus(): Array<{
  label: string;
  value: number;
}> {
  const objetos: Array<{ label: string; value: number }> = [];
  for (const [p, propertyValue] of Object.entries(ProductReviewStatus)) {
    try {
      if (Number(propertyValue) >= 0) {
        objetos.push({
          label: p,
          value: Number(propertyValue)
        });
      }
    } catch (error: any) {
      objetos.length;
    }
  }
  return objetos;
}

export function getAllProductReviewStatusIncludeAll(): Array<{
  label: string;
  value: string;
}> {
  const objetos: Array<{ label: string; value: string }> = [];
  for (const [p, propertyValue] of Object.entries(ProductReviewStatus)) {
    try {
      if (Number(propertyValue) >= 0) {
        objetos.push({
          label: p,
          value: propertyValue as string
        });
      }
    } catch (error: any) {
      objetos.length;
    }
  }
  return objetos.concat([
    {
      label: 'Todas',
      value: '-1'
    }
  ]);
}

export function getProductReviewStatusName(id: number): string | undefined {
  return getAllProductReviewStatus().find((e) => e.value === id)?.label;
}

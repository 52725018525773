<form
  class="form-b4a"
  [formGroup]="form"
  (submit)="changeCoupon()"
  *ngIf="person"
>
  <span class="p-float-label">
    <input
      type="text"
      pInputText
      formControlName="couponName"
      name="couponName"
      class="p-filled"
      (input)="toUpper()"
    />
    <label for="couponName">Cupom</label>
    <small [class.danger]="form.touched && form.dirty && !form.valid">
      *O cupom não pode conter espaços ou caracteres especiais.
    </small>
  </span>
  <div class="buttons">
    <p-button [disabled]="!form.valid" label="Alterar cupom" type="submit" />
    <p-button
      label="Cancelear"
      type="button"
      severity="danger"
      (onClick)="ref.close()"
    />
  </div>
</form>

/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Product {
  productId?: number;
  brandId?: number;
  productName?: string;
  productSummary?: string;
  descriptionArticleId?: number;
  defaultImageURL?: string;
  subscriptionId?: number;
  externalStoreUrl?: string;
  dateCreated?: Date;
  dateUpdated?: Date;
  defaultProductVariantId?: number;
  activeFeedbackId?: number;
  showOnWebsite?: number;
  ratingAverage?: number;
  reviewCount?: number;
  productTypeId?: number;
  externalId?: number;
  differentials?: string;
  whoMustUse?: string;
  howToUse?: string;
  aboutTheBrand?: string;
  ingredients?: string;
}

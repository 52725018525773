import { Component, Input } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DropdownFilter, TableColumn } from '../table';

@Component({
  selector: 'app-table-modal',
  templateUrl: './table-modal.component.html',
  styleUrl: './table-modal.component.scss'
})
export class TableModalComponent {
  @Input()
  cols: Array<TableColumn>;

  @Input()
  elements: Array<any>;

  @Input()
  modelName = 'registros';

  @Input()
  dropdownFilters: { [field: string]: Array<DropdownFilter> };

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) {
    this.cols = this.config.data.cols;
    this.elements = this.config.data.elements;
    this.modelName = this.config.data.modelName;
    this.dropdownFilters = this.config.data.dropdownFilters;
    if (!this.cols || !this.elements) {
      throw 'TableModalComponent: please provide the array of columns and the array of elements to show';
    }
  }
}

export interface AllSubscriptionTypes {
  subscriptionTypeId?: number;
  subscriptionId?: number;
  subscriptionName?: string;
  subscriptionTypeName?: string;
  installments?: number;
  editionCount?: number;
  price?: number;
  dateCreated?: Date;
  active?: boolean;
}

<div class="rating-and-scores-dashboard">
  <h3>Dashboard Rating and Scores</h3>
  <div class="filters">
    <form class="form-b4a" [formGroup]="form">
      <span class="p-float-label">
        <p-dropdown
          inputId="subscriptionId"
          [options]="subscriptions"
          (onChange)="changeSubscription()"
          formControlName="subscriptionId"
          appendTo="body"
          [autoDisplayFirst]="false"
        />
        <label for="subscriptionId">Assinatura</label>
      </span>
      <span class="p-float-label">
        <p-dropdown
          inputId="startEditionId"
          [options]="editions"
          *ngIf="editions"
          optionValue="editionId"
          optionLabel="theme"
          (onChange)="changeEdition()"
          [filter]="true"
          filterBy="theme,title,editionId,description"
          filterMatchMode="contains"
          [autoDisplayFirst]="false"
          formControlName="startEditionId"
          appendTo="body"
        >
          <ng-template pTemplate="item" let-edition>
            <ng-container *ngIf="form.value.subscriptionId">
              {{ edition.theme }}
              {{ editionDate(edition.editionId) }}
            </ng-container>
            <ng-container *ngIf="!form.value.subscriptionId">
              {{ editionDateName(edition.editionId) | titlecase }}
            </ng-container>
          </ng-template>
          <ng-template pTemplate="selectedItem">
            <ng-container *ngIf="form.value.subscriptionId">
              {{ startEdition?.theme }}
            </ng-container>
            <ng-container
              *ngIf="
                startEdition &&
                startEdition.editionId &&
                !form.value.subscriptionId
              "
            >
              {{ editionDateName(startEdition.editionId) | titlecase }}
            </ng-container>
          </ng-template>
        </p-dropdown>
        <label for="editionId">Edição início</label>
      </span>
      <span class="p-float-label">
        <p-dropdown
          inputId="endEditionId"
          [options]="editions"
          *ngIf="editions"
          optionValue="editionId"
          optionLabel="theme"
          (onChange)="changeEdition()"
          [filter]="true"
          filterBy="theme,title,editionId,description"
          filterMatchMode="contains"
          [autoDisplayFirst]="false"
          formControlName="endEditionId"
          appendTo="body"
        >
          <ng-template pTemplate="item" let-edition>
            <ng-container *ngIf="form.value.subscriptionId">
              {{ edition.theme }}
              {{ editionDate(edition.editionId) }}
            </ng-container>
            <ng-container *ngIf="!form.value.subscriptionId">
              {{ editionDateName(edition.editionId) | titlecase }}
            </ng-container>
          </ng-template>
          <ng-template pTemplate="selectedItem">
            <ng-container *ngIf="form.value.subscriptionId">
              {{ endEdition?.theme }}
            </ng-container>
            <ng-container
              *ngIf="
                endEdition && endEdition.editionId && !form.value.subscriptionId
              "
            >
              {{ editionDateName(endEdition.editionId) | titlecase }}
            </ng-container>
          </ng-template>
        </p-dropdown>
        <label for="editionId">Edição Fim</label>
      </span>
    </form>
  </div>
  <div class="totals" *ngIf="editionScoreDetails?.length">
    <div class="dashboard-card">
      <span class="header header--box">Box Rating</span>
      <span class="value">{{ avgBoxRating | number : '1.2-2' }}</span>
    </div>
    <div class="dashboard-card">
      <span class="header header--primary">Products Rating</span>
      <span class="value">{{ avgProducts | number : '1.2-2' }}</span>
    </div>
    <div class="dashboard-card">
      <span class="header header--bag">Match Rating</span>
      <span class="value">{{ avgFitting | number : '1.2-2' }}</span>
    </div>
    <div class="dashboard-card">
      <span class="header header--delivery">Delivery Rating</span>
      <span class="value">{{ avgDelivery | number : '1.2-2' }}</span>
    </div>
    <div class="dashboard-card">
      <span class="header header--dark">Avaliações</span>
      <span class="value">{{ recomendationsTotal | number : '1.0-0' }}</span>
    </div>
  </div>
  <div class="dashboard" *ngIf="ready">
    <p-chart
      [data]="chartData"
      [options]="chartConfig"
      id="chart"
      [responsive]="false"
      width="100%"
      height="400px"
      #chart
      *ngIf="chartData && width"
    />
    <br />
    <app-table
      [lazy]="false"
      [cols]="cols"
      [elements]="editionScoreDetails"
      sortField=" "
      [showHeader]="false"
      modelName="edições"
      *ngIf="editionScoreDetails"
    />
  </div>
</div>

/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ResponseB4AListOrderMovementStatusDescription {
  result?: Array<ResponseB4AListOrderMovementStatusDescription.ResultEnum>;
}
export namespace ResponseB4AListOrderMovementStatusDescription {
  export type ResultEnum =
    | 'EMPTY'
    | 'AG_RESSUPRIMENTO'
    | 'AG_ASSINATURA'
    | 'AG_CONFIRMACAO_DE_ENTREGA'
    | 'AG_EMBARQUE'
    | 'AG_FATURAMENTO'
    | 'AVERIGUAR_FALHA_NA_ENTREGA'
    | 'CANCELADO'
    | 'DESPACHADO'
    | 'EM_TRANSITO'
    | 'ENTREGUE'
    | 'FALHA_NA_ENTREGA'
    | 'SUBSTITUIDO';
  export const ResultEnum = {
    EMPTY: 'EMPTY' as ResultEnum,
    AGRESSUPRIMENTO: 'AG_RESSUPRIMENTO' as ResultEnum,
    AGASSINATURA: 'AG_ASSINATURA' as ResultEnum,
    AGCONFIRMACAODEENTREGA: 'AG_CONFIRMACAO_DE_ENTREGA' as ResultEnum,
    AGEMBARQUE: 'AG_EMBARQUE' as ResultEnum,
    AGFATURAMENTO: 'AG_FATURAMENTO' as ResultEnum,
    AVERIGUARFALHANAENTREGA: 'AVERIGUAR_FALHA_NA_ENTREGA' as ResultEnum,
    CANCELADO: 'CANCELADO' as ResultEnum,
    DESPACHADO: 'DESPACHADO' as ResultEnum,
    EMTRANSITO: 'EM_TRANSITO' as ResultEnum,
    ENTREGUE: 'ENTREGUE' as ResultEnum,
    FALHANAENTREGA: 'FALHA_NA_ENTREGA' as ResultEnum,
    SUBSTITUIDO: 'SUBSTITUIDO' as ResultEnum
  };
}

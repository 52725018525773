import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxPermissionsService } from 'ngx-permissions';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom } from 'rxjs';
import { EmailTemplateControllerService } from 'src/app/admin-api/api/emailTemplateController.service';
import { EmailTemplateRequest } from 'src/app/admin-api/model/emailTemplateRequest';
import { EmailTemplateResponse } from 'src/app/admin-api/model/emailTemplateResponse';
import { EmailTemplatesComponent } from 'src/app/pages/marketing/email-templates/email-templates.component';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-email-template-form',
  templateUrl: './email-template-form.component.html',
  styleUrls: ['./email-template-form.component.scss'],
  providers: [MessageService]
})
export class EmailTemplateFormComponent implements OnInit {
  private emailTemplateService = inject(EmailTemplateControllerService);
  private config = inject(DynamicDialogConfig);
  ref = inject(DynamicDialogRef);
  private permissionService = inject(NgxPermissionsService);

  template: EmailTemplateResponse = this.config.data.template;
  permission: boolean = this.config.data.permission;

  form: FormGroup = new FormGroup({
    templateName: new FormControl(
      { value: this.template?.templateName, disabled: !this.permission },
      [Validators.required]
    ),
    templateId: new FormControl(
      { value: this.template?.templateId, disabled: !this.permission },
      [Validators.required]
    ),
    subject: new FormControl(
      { value: this.template?.subject, disabled: !this.permission },
      [Validators.required]
    ),
    subtitle: new FormControl({
      value: this.template?.subtitle,
      disabled: !this.permission
    }),
    description: new FormControl({
      value: this.template?.description,
      disabled: !this.permission
    }),
    active: new FormControl(
      {
        value: this.template ? this.template.active : true,
        disabled: !this.permission
      },
      [Validators.required]
    )
  });

  async ngOnInit() {
    if (!this.permission)
      this.permission = await this.permissionService.hasPermission(
        EmailTemplatesComponent.roles
      );
  }

  async onSubmit() {
    if (this.form.invalid) return;

    if (this.template?.id) {
      await this.updateExistingTemplate(this.form.value);
    } else {
      await this.createNewTemplate(this.form.value);
    }
  }

  private async updateExistingTemplate(request: EmailTemplateRequest) {
    try {
      LoaderService.showLoader();
      await lastValueFrom(
        this.emailTemplateService.updateTemplate(request, this.template.id)
      );
      this.ref.close(true);
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  private async createNewTemplate(request: EmailTemplateRequest) {
    try {
      LoaderService.showLoader();
      await lastValueFrom(this.emailTemplateService.createTemplate(request));
      this.ref.close(true);
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }
}

<div class="person-credit-cards">
  <app-table
    [lazy]="false"
    [elements]="creditCards"
    *ngIf="creditCards"
    modelName="cartões"
    [cols]="cardCols"
    sortField="dateCreated"
    [sortOrder]="1"
    [actionButtons]="actionButtons"
    [defaultRows]="10"
    [showTitle]="false"
    [exportPdf]="false"
    [exportExcel]="false"
    [addButton]="false"
    [changeColumns]="true"
  />
</div>
<p-confirmPopup styleClass="danger" />
<p-toast />

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { MessageService } from 'primeng/api';
import { lastValueFrom, map } from 'rxjs';
import { ProductCategory, ProductControllerService } from 'src/app/admin-api';
import { Role, roleAsObject } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-product-category-form',
  templateUrl: './product-category-form.component.html',
  styleUrls: ['./product-category-form.component.scss']
})
export class ProductCategoryFormComponent implements OnInit {
  private productService: ProductControllerService = inject(
    ProductControllerService
  );
  private router: Router = inject(Router);
  private messageService: MessageService = inject(MessageService);
  private ngxPermissionsService: NgxPermissionsService = inject(
    NgxPermissionsService
  );

  @Input()
  model: ProductCategory | undefined;

  @Output()
  updated = new EventEmitter<ProductCategory>();
  form: FormGroup | undefined;

  @Input()
  children = false;

  @Input()
  categories: Array<ProductCategory> | undefined;

  parentCategories: Array<ProductCategory> | undefined;
  permission = false;
  acceptedPermissions = [
    roleAsObject(Role.Full_Administrator).enumValue,
    roleAsObject(Role.Admin_Allocation).enumValue,
    roleAsObject(Role.User_Allocation).enumValue
  ];

  async ngOnInit(): Promise<void> {
    this.permission = await this.ngxPermissionsService.hasPermission(
      this.acceptedPermissions
    );
    this.form = new FormGroup({
      categoryId: new FormControl(this.model?.categoryId),
      displayName: new FormControl(
        { value: this.model?.displayName, disabled: !this.permission },
        Validators.required
      ),
      externalId: new FormControl(
        { value: this.model?.externalId, disabled: !this.permission },
        [Validators.required, Validators.min(1)]
      ),
      parentId: new FormControl({
        value: this.model?.parentId || 0,
        disabled: this.children || !this.permission
      })
    });
    this.parentCategories = [
      {
        categoryId: 0,
        displayName: 'Sem categoria macro',
        externalId: 0,
        parentId: 0
      } as ProductCategory
    ].concat(this.categories?.filter((c) => !c.parentId) || []);
    if (this.last?.externalId && !this.model) {
      this.form.controls['externalId'].setValue(this.last.externalId + 1);
    }
  }

  async save(): Promise<void> {
    if (this.form?.valid) {
      LoaderService.showLoader();
      try {
        if (this.model?.categoryId) {
          this.model = await lastValueFrom(
            this.productService
              .updateProductCategory(this.form.getRawValue())
              .pipe(map((data) => data.result))
          );
          this.updated.emit(this.model);
          this.messageService.add({
            severity: 'success',
            detail: 'Categoria atualizada com sucesso',
            summary: 'Sucesso'
          });
        } else {
          this.model = await lastValueFrom(
            this.productService
              .createProductCategory(this.form.value)
              .pipe(map((data) => data.result))
          );
          this.messageService.add({
            severity: 'success',
            detail: 'Categoria cadastrada com sucesso',
            summary: 'Sucesso'
          });
          this.router.navigate([
            'products/categories/' + this.model?.categoryId
          ]);
        }
      } catch (error: any) {
        AppDialogService.showErrorDialog(error);
      }
      LoaderService.showLoader(false);
    }
  }

  get parentCategory(): ProductCategory {
    return this.parentCategories?.find(
      (c) => c.categoryId === this.form?.getRawValue().parentId
    ) as ProductCategory;
  }

  get last(): ProductCategory {
    const cats = [
      ...(this.categories?.filter(
        (c) => c.parentId === this.form?.value.parentId
      ) || [])
    ];
    cats.sort(
      (c1, c2) => (c2.externalId as number) - (c1.externalId as number)
    );
    return cats[0] as ProductCategory;
  }
}

<app-table
  addLabel="Novo cupom"
  [service]="couponService"
  [findPage]="findPage"
  [cols]="cols"
  tableTitle="Cupons"
  sortField="name"
  [sortOrder]="-1"
  (booleanAction)="updateEnabled($event)"
  [dropdownFilters]="dropdownFilters"
  [changeColumns]="true"
/>

<div class="email-list">
  <app-table
    #emailTable
    [lazy]="true"
    [cols]="columns"
    [service]="notificationService"
    [findPage]="findPage"
    modelName="eventos"
    emptyMessage="Nenhum evento encontrado"
    tableTitle="Eventos"
    [addButton]="false"
    [actionButtons]="[]"
    [dropdownFilters]="dropdownFilters"
    dataKey="id"
    (rowExpand)="onRowExpand($event)"
    [expandPaginator]="true"
    [fixedFilters]="fixedFilters"
    [expansionCols]="expansionColumns"
    expandField="detailsData"
    tableTitle=" "
  >
  </app-table>
</div>

<div class="upgrade-downgrade-dashboard">
  <h3>Dashboard Upgrade/downgrade</h3>
  <div class="filters">
    <form class="form-b4a" [formGroup]="form">
      <span class="p-float-label">
        <p-dropdown
          inputId="subscriptionId"
          [options]="subscriptions"
          (onChange)="changeSubscription()"
          formControlName="subscriptionId"
          [autoDisplayFirst]="false"
          appendTo="body"
        >
        </p-dropdown>
        <label for="subscriptionId">Assinatura</label>
      </span>
      <span class="p-float-label">
        <p-dropdown
          inputId="editionId"
          [options]="editions"
          *ngIf="editions"
          optionValue="editionId"
          optionLabel="theme"
          (onChange)="changeEdition()"
          [filter]="true"
          filterBy="theme,title,editionId,description"
          filterMatchMode="contains"
          [autoDisplayFirst]="false"
          formControlName="editionId"
          appendTo="body"
        >
          <ng-template pTemplate="item" let-edition>
            <ng-container *ngIf="form.value.subscriptionId">
              {{ edition.theme }}
              {{ editionDate(edition.editionId) }}
            </ng-container>
            <ng-container *ngIf="!form.value.subscriptionId">
              {{ editionDateName(edition.editionId) | titlecase }}
            </ng-container>
          </ng-template>
          <ng-template pTemplate="selectedItem">
            <ng-container *ngIf="form.value.subscriptionId">
              {{ edition?.theme }}
            </ng-container>
            <ng-container
              *ngIf="edition && edition.editionId && !form.value.subscriptionId"
            >
              {{ editionDateName(edition.editionId) | titlecase }}
            </ng-container>
          </ng-template>
        </p-dropdown>
        <label for="editionId">Ediçao</label>
      </span>
    </form>
    <div class="picture">
      <app-edition-image
        [imageURL]="edition.imageURL"
        [editionId]="edition.editionId"
        *ngIf="edition"
      ></app-edition-image>
    </div>
  </div>
  <div class="totals" *ngIf="ready">
    <div class="dashboard-card">
      <div class="header header--primary">Renovações</div>
      <div class="value">{{ subscribers?.length | number : '1.0-0' }}</div>
    </div>
    <div class="dashboard-card">
      <div class="header header--warning">Mesmo plano</div>
      <div class="value">{{ samePlanCount | number : '1.0-0' }}</div>
    </div>
    <div class="dashboard-card">
      <div class="header header--success">Upgrades</div>
      <div class="value">{{ upgradeCount | number : '1.0-0' }}</div>
    </div>
    <div class="dashboard-card">
      <div class="header header--danger">Downgrades</div>
      <div class="value">{{ downgradeCount | number : '1.0-0' }}</div>
    </div>
  </div>
  <div class="dashboard">
    <div class="right-info">
      <app-table
        *ngIf="cols && ready"
        [cols]="cols"
        [findPage]="findPage"
        [sortOrder]="-1"
        sortField="oldSubscriptionTypeId"
        [paginator]="false"
        [showTitle]="false"
        [exportExcel]="false"
        [exportPdf]="false"
        [addButton]="false"
        [dropdownFilters]="dropdownFilters"
        [multiSelectFilter]="true"
        [defaultFilterValues]="defaultFilters"
        [service]="kpiService"
        [changeColumns]="true"
      ></app-table>
    </div>
  </div>
</div>

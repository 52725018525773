import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgxPermissionsService } from 'ngx-permissions';
import { lastValueFrom, map } from 'rxjs';
import {
  FeedbackAdminControllerService,
  FeedbackControllerService,
  FeedbackFormDetail
} from 'src/app/admin-api';
import {
  DropdownFilter,
  TableColumn,
  TableComponent
} from 'src/app/components/table';
import { Role, roleAsObject } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-feedback-forms',
  templateUrl: './feedback-forms.component.html',
  styleUrl: './feedback-forms.component.scss'
})
export class FeedbackFormsComponent implements OnInit {
  feedbackService: FeedbackControllerService = inject(
    FeedbackControllerService
  );
  feedbackAdminService: FeedbackAdminControllerService = inject(
    FeedbackAdminControllerService
  );
  private title: Title = inject(Title);
  private permissionsService: NgxPermissionsService = inject(
    NgxPermissionsService
  );

  @ViewChild(TableComponent)
  table: TableComponent | undefined;

  cols: Array<TableColumn>;
  dropdownFilters: {
    [field: string]: Array<DropdownFilter>;
  } = {
    enabled: [
      { label: 'Sim', value: '1' },
      { label: 'Não', value: '0' },
      { label: 'Todos', value: null }
    ],
    isPublic: [
      { label: 'Sim', value: '1' },
      { label: 'Não', value: '0' },
      { label: 'Todos', value: null }
    ]
  };
  permissions = false;

  async ngOnInit(): Promise<void> {
    this.permissions = await this.permissionsService.hasPermission([
      roleAsObject(Role.Full_Administrator).enumValue,
      roleAsObject(Role.Business_Intelligence).enumValue,
      roleAsObject(Role.Customer_Success).enumValue
    ]);
    this.title.setTitle('Formulários de feedback');
    this.cols = [
      new TableColumn('Id', 'formId', true, 'number', '', 'formId'),
      new TableColumn(
        'Título',
        'formTitle',
        true,
        'text',
        '',
        'formId',
        true,
        'contains'
      ),
      new TableColumn(
        'Edição',
        'theme',
        true,
        'text',
        undefined,
        undefined,
        true,
        'contains'
      ),
      new TableColumn(
        'Produtos',
        'products',
        true,
        'formattedInteger',
        undefined,
        undefined,
        true,
        'gte'
      ),
      new TableColumn(
        'Perguntas',
        'questions',
        true,
        'formattedInteger',
        undefined,
        undefined,
        true,
        'gte'
      ),
      new TableColumn(
        'Respostas',
        'answerCount',
        true,
        'formattedInteger',
        undefined,
        undefined,
        true,
        'gte'
      ),
      new TableColumn(
        'Glampoints',
        'glampoints',
        true,
        'formattedNumber',
        undefined,
        undefined,
        true,
        'gte'
      ),
      new TableColumn(
        'Ativo',
        'enabled',
        true,
        this.permissions ? 'inputSwitch' : 'boolean',
        undefined,
        undefined,
        true,
        'equals'
      ),
      new TableColumn(
        'Data criação',
        'dateCreated',
        true,
        'date',
        undefined,
        undefined,
        true,
        'between'
      )
    ];
  }

  async updateForm(form: FeedbackFormDetail): Promise<void> {
    LoaderService.showLoader();
    try {
      await lastValueFrom(
        this.feedbackAdminService
          .updateFeedbackForm(form)
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }
}

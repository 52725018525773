import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { AppTableModule } from '../table/app-table.module';
import { InfluencerCouponFormComponent } from './influencer-coupon-form/influencer-coupon-form.component';
import { InfluencerCouponsComponent } from './influencer-coupons/influencer-coupons.component';
import { InfluencerFormComponent } from './influencer-form.component';

@NgModule({
  declarations: [
    InfluencerFormComponent,
    InfluencerCouponsComponent,
    InfluencerCouponFormComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DropdownModule,
    ButtonModule,
    TooltipModule,
    InputSwitchModule,
    InputTextModule,
    AppTableModule,
    InputSwitchModule,
    CheckboxModule,
    ToastModule
  ],
  exports: [InfluencerFormComponent, InfluencerCouponsComponent]
})
export class InfluencerFormModule {}

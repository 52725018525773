import { Component, Input } from '@angular/core';
import { PaymentCard } from '@infrab4a/connect';
import { CreditCardRecurrenceWithCard } from 'src/app/admin-api';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-credit-card-view',
  templateUrl: './credit-card-view.component.html',
  styleUrl: './credit-card-view.component.scss'
})
export class CreditCardViewComponent {
  @Input({ required: true })
  creditCard: CreditCardRecurrenceWithCard | PaymentCard;

  get cardFlag(): string {
    return FormUtil.cardFlagImg(this.creditCard);
  }

  get cardHolder(): string {
    return (
      (this.creditCard as CreditCardRecurrenceWithCard)?.nameOnCard ||
      (this.creditCard as PaymentCard)?.holder_name
    );
  }

  get cardExpiration(): string {
    return (
      (this.creditCard as PaymentCard).expiration_date ||
      `${(this.creditCard as CreditCardRecurrenceWithCard)?.expMonth
        .toString()
        .padStart(2, '0')}/${
        (this.creditCard as CreditCardRecurrenceWithCard)?.expYear
      }`
    );
  }
}

/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ResponseB4AEditionScoreDetails } from '../model/responseB4AEditionScoreDetails';
import { ResponseB4AInteger } from '../model/responseB4AInteger';
import { ResponseB4AListCouponAcquisitionSummary } from '../model/responseB4AListCouponAcquisitionSummary';
import { ResponseB4AListEditionBadgeScoreDetails } from '../model/responseB4AListEditionBadgeScoreDetails';
import { ResponseB4AListEditionScoreDetails } from '../model/responseB4AListEditionScoreDetails';
import { ResponseB4AListEditionScoreLogAcc } from '../model/responseB4AListEditionScoreLogAcc';
import { ResponseB4AListSubscriberAcquisition } from '../model/responseB4AListSubscriberAcquisition';
import { ResponseB4AListSubscriberControlDetail } from '../model/responseB4AListSubscriberControlDetail';
import { ResponseB4AListSubscriberControlGroupByDate } from '../model/responseB4AListSubscriberControlGroupByDate';
import { ResponseB4AListSubscriberControlGroupBySubscriptionType } from '../model/responseB4AListSubscriberControlGroupBySubscriptionType';
import { ResponseB4AListSubscriberControlGroupCounterByGroup } from '../model/responseB4AListSubscriberControlGroupCounterByGroup';
import { ResponseB4AListSubscriberControlGroupCounterOldest } from '../model/responseB4AListSubscriberControlGroupCounterOldest';
import { ResponseB4AListSubscriberControlGroupDetail } from '../model/responseB4AListSubscriberControlGroupDetail';
import { ResponseB4AListSubscriberEditionShippingDetail } from '../model/responseB4AListSubscriberEditionShippingDetail';
import { ResponseB4AListSubscriberEditionShippingDetails } from '../model/responseB4AListSubscriberEditionShippingDetails';
import { ResponseB4AListSubscriberReactivation } from '../model/responseB4AListSubscriberReactivation';
import { ResponseB4AListSubscriberUpgradeDowngrade } from '../model/responseB4AListSubscriberUpgradeDowngrade';
import { ResponseB4AListSubscriptionType } from '../model/responseB4AListSubscriptionType';
import { ResponseB4AListSubscriptionsPerHour } from '../model/responseB4AListSubscriptionsPerHour';
import { ResponseB4AListSubscriptionsPerHourRealtime } from '../model/responseB4AListSubscriptionsPerHourRealtime';
import { ResponseB4AShopContributionResponse } from '../model/responseB4AShopContributionResponse';
import { ResponseB4AShopIncomeResponse } from '../model/responseB4AShopIncomeResponse';
import { ResponseB4ASubscriptionsTotalResponse } from '../model/responseB4ASubscriptionsTotalResponse';
import { UpgradeDowngradeDashboardRequest } from '../model/upgradeDowngradeDashboardRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class KpiControllerService {

    protected basePath = 'https://admin-api-stage.b4a.com.br';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param editionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAcquisitionsByEditionId(editionId: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseB4AListSubscriberAcquisition>;
    public findAcquisitionsByEditionId(editionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseB4AListSubscriberAcquisition>>;
    public findAcquisitionsByEditionId(editionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseB4AListSubscriberAcquisition>>;
    public findAcquisitionsByEditionId(editionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (editionId === null || editionId === undefined) {
            throw new Error('Required parameter editionId was null or undefined when calling findAcquisitionsByEditionId.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-key) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseB4AListSubscriberAcquisition>('get',`${this.basePath}/kpi/acquisitions/${encodeURIComponent(String(editionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param subscriptionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAnalyticsDashboard(subscriptionId?: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseB4AListSubscriptionsPerHour>;
    public findAnalyticsDashboard(subscriptionId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseB4AListSubscriptionsPerHour>>;
    public findAnalyticsDashboard(subscriptionId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseB4AListSubscriptionsPerHour>>;
    public findAnalyticsDashboard(subscriptionId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (subscriptionId !== undefined && subscriptionId !== null) {
            queryParameters = queryParameters.set('subscriptionId', <any>subscriptionId);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-key) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseB4AListSubscriptionsPerHour>('get',`${this.basePath}/kpi/dashboard/analytics`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param subscriptionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAnalyticsDashboardRealtime(subscriptionId?: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseB4AListSubscriptionsPerHourRealtime>;
    public findAnalyticsDashboardRealtime(subscriptionId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseB4AListSubscriptionsPerHourRealtime>>;
    public findAnalyticsDashboardRealtime(subscriptionId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseB4AListSubscriptionsPerHourRealtime>>;
    public findAnalyticsDashboardRealtime(subscriptionId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (subscriptionId !== undefined && subscriptionId !== null) {
            queryParameters = queryParameters.set('subscriptionId', <any>subscriptionId);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-key) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseB4AListSubscriptionsPerHourRealtime>('get',`${this.basePath}/kpi/dashboard/analytics/real-time`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param subscriptionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAnalyticsDashboardRealtimeTotal(subscriptionId?: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseB4ASubscriptionsTotalResponse>;
    public findAnalyticsDashboardRealtimeTotal(subscriptionId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseB4ASubscriptionsTotalResponse>>;
    public findAnalyticsDashboardRealtimeTotal(subscriptionId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseB4ASubscriptionsTotalResponse>>;
    public findAnalyticsDashboardRealtimeTotal(subscriptionId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (subscriptionId !== undefined && subscriptionId !== null) {
            queryParameters = queryParameters.set('subscriptionId', <any>subscriptionId);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-key) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseB4ASubscriptionsTotalResponse>('get',`${this.basePath}/kpi/dashboard/analytics/real-time/total`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findContributionMargin(observe?: 'body', reportProgress?: boolean): Observable<ResponseB4AShopContributionResponse>;
    public findContributionMargin(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseB4AShopContributionResponse>>;
    public findContributionMargin(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseB4AShopContributionResponse>>;
    public findContributionMargin(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-key) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseB4AShopContributionResponse>('get',`${this.basePath}/kpi/dashboard/consolidated/contribution`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param editionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findCouponUsageSummaryByEditionId(editionId: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseB4AListCouponAcquisitionSummary>;
    public findCouponUsageSummaryByEditionId(editionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseB4AListCouponAcquisitionSummary>>;
    public findCouponUsageSummaryByEditionId(editionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseB4AListCouponAcquisitionSummary>>;
    public findCouponUsageSummaryByEditionId(editionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (editionId === null || editionId === undefined) {
            throw new Error('Required parameter editionId was null or undefined when calling findCouponUsageSummaryByEditionId.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-key) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseB4AListCouponAcquisitionSummary>('get',`${this.basePath}/kpi/acquisition/coupon/${encodeURIComponent(String(editionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param editionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findDeliveryDashboard(editionId: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseB4AListSubscriberEditionShippingDetails>;
    public findDeliveryDashboard(editionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseB4AListSubscriberEditionShippingDetails>>;
    public findDeliveryDashboard(editionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseB4AListSubscriberEditionShippingDetails>>;
    public findDeliveryDashboard(editionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (editionId === null || editionId === undefined) {
            throw new Error('Required parameter editionId was null or undefined when calling findDeliveryDashboard.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-key) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseB4AListSubscriberEditionShippingDetails>('get',`${this.basePath}/kpi/dashboard/delivery/${encodeURIComponent(String(editionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param editionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findDeliveryDashboardSubscribers(editionId: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseB4AListSubscriberEditionShippingDetail>;
    public findDeliveryDashboardSubscribers(editionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseB4AListSubscriberEditionShippingDetail>>;
    public findDeliveryDashboardSubscribers(editionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseB4AListSubscriberEditionShippingDetail>>;
    public findDeliveryDashboardSubscribers(editionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (editionId === null || editionId === undefined) {
            throw new Error('Required parameter editionId was null or undefined when calling findDeliveryDashboardSubscribers.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-key) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseB4AListSubscriberEditionShippingDetail>('get',`${this.basePath}/kpi/dashboard/delivery/subscribers/${encodeURIComponent(String(editionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param editionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findEditionScoresAndRatings(editionId: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseB4AEditionScoreDetails>;
    public findEditionScoresAndRatings(editionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseB4AEditionScoreDetails>>;
    public findEditionScoresAndRatings(editionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseB4AEditionScoreDetails>>;
    public findEditionScoresAndRatings(editionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (editionId === null || editionId === undefined) {
            throw new Error('Required parameter editionId was null or undefined when calling findEditionScoresAndRatings.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-key) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseB4AEditionScoreDetails>('get',`${this.basePath}/kpi/dashboard/rating/${encodeURIComponent(String(editionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param editionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findEditionScoresAndRatingsLogs(editionId: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseB4AListEditionScoreLogAcc>;
    public findEditionScoresAndRatingsLogs(editionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseB4AListEditionScoreLogAcc>>;
    public findEditionScoresAndRatingsLogs(editionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseB4AListEditionScoreLogAcc>>;
    public findEditionScoresAndRatingsLogs(editionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (editionId === null || editionId === undefined) {
            throw new Error('Required parameter editionId was null or undefined when calling findEditionScoresAndRatingsLogs.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-key) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseB4AListEditionScoreLogAcc>('get',`${this.basePath}/kpi/dashboard/rating/log/${encodeURIComponent(String(editionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param editionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findIncomeDashboardByEditionIdGroupBySubscriberGroupId(editionId: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseB4AListSubscriberControlGroupDetail>;
    public findIncomeDashboardByEditionIdGroupBySubscriberGroupId(editionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseB4AListSubscriberControlGroupDetail>>;
    public findIncomeDashboardByEditionIdGroupBySubscriberGroupId(editionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseB4AListSubscriberControlGroupDetail>>;
    public findIncomeDashboardByEditionIdGroupBySubscriberGroupId(editionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (editionId === null || editionId === undefined) {
            throw new Error('Required parameter editionId was null or undefined when calling findIncomeDashboardByEditionIdGroupBySubscriberGroupId.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-key) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseB4AListSubscriberControlGroupDetail>('get',`${this.basePath}/kpi/income/group/${encodeURIComponent(String(editionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param editionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findIncomeDashboardByEditionIdGroupBySubscriptionType(editionId: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseB4AListSubscriberControlGroupBySubscriptionType>;
    public findIncomeDashboardByEditionIdGroupBySubscriptionType(editionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseB4AListSubscriberControlGroupBySubscriptionType>>;
    public findIncomeDashboardByEditionIdGroupBySubscriptionType(editionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseB4AListSubscriberControlGroupBySubscriptionType>>;
    public findIncomeDashboardByEditionIdGroupBySubscriptionType(editionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (editionId === null || editionId === undefined) {
            throw new Error('Required parameter editionId was null or undefined when calling findIncomeDashboardByEditionIdGroupBySubscriptionType.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-key) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseB4AListSubscriberControlGroupBySubscriptionType>('get',`${this.basePath}/kpi/income/subscriptionType/${encodeURIComponent(String(editionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findMensSubscriptions(observe?: 'body', reportProgress?: boolean): Observable<ResponseB4AInteger>;
    public findMensSubscriptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseB4AInteger>>;
    public findMensSubscriptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseB4AInteger>>;
    public findMensSubscriptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-key) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseB4AInteger>('get',`${this.basePath}/kpi/dashboard/consolidated/mens-subscriptions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findOwnBrandsIncome(observe?: 'body', reportProgress?: boolean): Observable<ResponseB4AShopIncomeResponse>;
    public findOwnBrandsIncome(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseB4AShopIncomeResponse>>;
    public findOwnBrandsIncome(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseB4AShopIncomeResponse>>;
    public findOwnBrandsIncome(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-key) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseB4AShopIncomeResponse>('get',`${this.basePath}/kpi/dashboard/consolidated/own-brands`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param editionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findReactivationsByEditionId(editionId: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseB4AListSubscriberReactivation>;
    public findReactivationsByEditionId(editionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseB4AListSubscriberReactivation>>;
    public findReactivationsByEditionId(editionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseB4AListSubscriberReactivation>>;
    public findReactivationsByEditionId(editionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (editionId === null || editionId === undefined) {
            throw new Error('Required parameter editionId was null or undefined when calling findReactivationsByEditionId.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-key) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseB4AListSubscriberReactivation>('get',`${this.basePath}/kpi/reactivations/${encodeURIComponent(String(editionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param startEdition 
     * @param endEdition 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findScoresAndRatingsDashboard(startEdition: number, endEdition: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseB4AListEditionScoreDetails>;
    public findScoresAndRatingsDashboard(startEdition: number, endEdition: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseB4AListEditionScoreDetails>>;
    public findScoresAndRatingsDashboard(startEdition: number, endEdition: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseB4AListEditionScoreDetails>>;
    public findScoresAndRatingsDashboard(startEdition: number, endEdition: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (startEdition === null || startEdition === undefined) {
            throw new Error('Required parameter startEdition was null or undefined when calling findScoresAndRatingsDashboard.');
        }

        if (endEdition === null || endEdition === undefined) {
            throw new Error('Required parameter endEdition was null or undefined when calling findScoresAndRatingsDashboard.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-key) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseB4AListEditionScoreDetails>('get',`${this.basePath}/kpi/dashboard/rating/${encodeURIComponent(String(startEdition))}/${encodeURIComponent(String(endEdition))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param editionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findScoresAndRatingsDashboardByBadge(editionId: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseB4AListEditionBadgeScoreDetails>;
    public findScoresAndRatingsDashboardByBadge(editionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseB4AListEditionBadgeScoreDetails>>;
    public findScoresAndRatingsDashboardByBadge(editionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseB4AListEditionBadgeScoreDetails>>;
    public findScoresAndRatingsDashboardByBadge(editionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (editionId === null || editionId === undefined) {
            throw new Error('Required parameter editionId was null or undefined when calling findScoresAndRatingsDashboardByBadge.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-key) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseB4AListEditionBadgeScoreDetails>('get',`${this.basePath}/kpi/dashboard/rating/badge/${encodeURIComponent(String(editionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findShopIncome(observe?: 'body', reportProgress?: boolean): Observable<ResponseB4AShopIncomeResponse>;
    public findShopIncome(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseB4AShopIncomeResponse>>;
    public findShopIncome(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseB4AShopIncomeResponse>>;
    public findShopIncome(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-key) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseB4AShopIncomeResponse>('get',`${this.basePath}/kpi/dashboard/consolidated/shop`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param editionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findSubscriberAcquisitionTotalsByEditionId(editionId: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseB4AListSubscriberControlGroupCounterByGroup>;
    public findSubscriberAcquisitionTotalsByEditionId(editionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseB4AListSubscriberControlGroupCounterByGroup>>;
    public findSubscriberAcquisitionTotalsByEditionId(editionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseB4AListSubscriberControlGroupCounterByGroup>>;
    public findSubscriberAcquisitionTotalsByEditionId(editionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (editionId === null || editionId === undefined) {
            throw new Error('Required parameter editionId was null or undefined when calling findSubscriberAcquisitionTotalsByEditionId.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-key) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseB4AListSubscriberControlGroupCounterByGroup>('get',`${this.basePath}/kpi/acquisition/totals/${encodeURIComponent(String(editionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param editionId 
     * @param subscriberGroupId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findSubscriberByEditionIdAndSubscriberGroupId(editionId: number, subscriberGroupId: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseB4AListSubscriberControlDetail>;
    public findSubscriberByEditionIdAndSubscriberGroupId(editionId: number, subscriberGroupId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseB4AListSubscriberControlDetail>>;
    public findSubscriberByEditionIdAndSubscriberGroupId(editionId: number, subscriberGroupId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseB4AListSubscriberControlDetail>>;
    public findSubscriberByEditionIdAndSubscriberGroupId(editionId: number, subscriberGroupId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (editionId === null || editionId === undefined) {
            throw new Error('Required parameter editionId was null or undefined when calling findSubscriberByEditionIdAndSubscriberGroupId.');
        }

        if (subscriberGroupId === null || subscriberGroupId === undefined) {
            throw new Error('Required parameter subscriberGroupId was null or undefined when calling findSubscriberByEditionIdAndSubscriberGroupId.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-key) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseB4AListSubscriberControlDetail>('get',`${this.basePath}/kpi/subscribers/${encodeURIComponent(String(editionId))}/${encodeURIComponent(String(subscriberGroupId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param editionId 
     * @param subscriberGroupId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findSubscriberDailyReport(editionId: number, subscriberGroupId: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseB4AListSubscriberControlGroupByDate>;
    public findSubscriberDailyReport(editionId: number, subscriberGroupId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseB4AListSubscriberControlGroupByDate>>;
    public findSubscriberDailyReport(editionId: number, subscriberGroupId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseB4AListSubscriberControlGroupByDate>>;
    public findSubscriberDailyReport(editionId: number, subscriberGroupId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (editionId === null || editionId === undefined) {
            throw new Error('Required parameter editionId was null or undefined when calling findSubscriberDailyReport.');
        }

        if (subscriberGroupId === null || subscriberGroupId === undefined) {
            throw new Error('Required parameter subscriberGroupId was null or undefined when calling findSubscriberDailyReport.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-key) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseB4AListSubscriberControlGroupByDate>('get',`${this.basePath}/kpi/daily/${encodeURIComponent(String(editionId))}/${encodeURIComponent(String(subscriberGroupId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param editionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findSubscriptionDashboardByEditionId(editionId: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseB4AListSubscriberControlGroupBySubscriptionType>;
    public findSubscriptionDashboardByEditionId(editionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseB4AListSubscriberControlGroupBySubscriptionType>>;
    public findSubscriptionDashboardByEditionId(editionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseB4AListSubscriberControlGroupBySubscriptionType>>;
    public findSubscriptionDashboardByEditionId(editionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (editionId === null || editionId === undefined) {
            throw new Error('Required parameter editionId was null or undefined when calling findSubscriptionDashboardByEditionId.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-key) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseB4AListSubscriberControlGroupBySubscriptionType>('get',`${this.basePath}/kpi/subscription/group/${encodeURIComponent(String(editionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param editionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findSubscriptionDashboardOlderCounters(editionId: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseB4AListSubscriberControlGroupCounterOldest>;
    public findSubscriptionDashboardOlderCounters(editionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseB4AListSubscriberControlGroupCounterOldest>>;
    public findSubscriptionDashboardOlderCounters(editionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseB4AListSubscriberControlGroupCounterOldest>>;
    public findSubscriptionDashboardOlderCounters(editionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (editionId === null || editionId === undefined) {
            throw new Error('Required parameter editionId was null or undefined when calling findSubscriptionDashboardOlderCounters.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-key) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseB4AListSubscriberControlGroupCounterOldest>('get',`${this.basePath}/kpi/subscription/oldest/${encodeURIComponent(String(editionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param editionId 
     * @param old 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findSubscriptionTypesWithUpgradeOrDowngrade(editionId: number, old?: boolean, observe?: 'body', reportProgress?: boolean): Observable<ResponseB4AListSubscriptionType>;
    public findSubscriptionTypesWithUpgradeOrDowngrade(editionId: number, old?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseB4AListSubscriptionType>>;
    public findSubscriptionTypesWithUpgradeOrDowngrade(editionId: number, old?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseB4AListSubscriptionType>>;
    public findSubscriptionTypesWithUpgradeOrDowngrade(editionId: number, old?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (editionId === null || editionId === undefined) {
            throw new Error('Required parameter editionId was null or undefined when calling findSubscriptionTypesWithUpgradeOrDowngrade.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (old !== undefined && old !== null) {
            queryParameters = queryParameters.set('old', <any>old);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-key) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseB4AListSubscriptionType>('get',`${this.basePath}/kpi/dashboard/upgrade-downgrade/subscription-types/${encodeURIComponent(String(editionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findUpgradeDowngradeDashboard(body?: UpgradeDowngradeDashboardRequest, observe?: 'body', reportProgress?: boolean): Observable<ResponseB4AListSubscriberUpgradeDowngrade>;
    public findUpgradeDowngradeDashboard(body?: UpgradeDowngradeDashboardRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseB4AListSubscriberUpgradeDowngrade>>;
    public findUpgradeDowngradeDashboard(body?: UpgradeDowngradeDashboardRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseB4AListSubscriberUpgradeDowngrade>>;
    public findUpgradeDowngradeDashboard(body?: UpgradeDowngradeDashboardRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (bearer-key) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ResponseB4AListSubscriberUpgradeDowngrade>('put',`${this.basePath}/kpi/dashboard/upgrade-downgrade`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

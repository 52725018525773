import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { CourseFormModule } from 'src/app/components/course-form/course-form.module';
import { ImageCropperModalModule } from 'src/app/components/image-cropper-modal/image-cropper-modal.module';
import { AppTableModule } from 'src/app/components/table/app-table.module';
import { ArticleDetailsComponent } from './article-details/article-details.component';
import { ArticlesComponent } from './articles/articles.component';
import { ContentRoutingModule } from './content-routing.module';
import { CourseDetailComponent } from './courses/course-detail/course-detail.component';
import { CoursesComponent } from './courses/courses.component';
import { GlamTvComponent } from './glam-tv/glam-tv.component';
import { LiveDetailComponent } from './lives/live-detail/live-detail.component';
import { LiveContentFormComponent } from './lives/live-form/live-content-form/live-content-form.component';
import { LiveFormComponent } from './lives/live-form/live-form.component';
import { LivesComponent } from './lives/lives.component';
import { ShortsComponent } from './shorts/shorts.component';

@NgModule({
  declarations: [
    ArticlesComponent,
    ArticleDetailsComponent,
    LivesComponent,
    LiveDetailComponent,
    LiveFormComponent,
    LiveContentFormComponent,
    CoursesComponent,
    CourseDetailComponent,
    GlamTvComponent,
    ShortsComponent,
    CoursesComponent,
    CourseDetailComponent
  ],
  imports: [
    CommonModule,
    ContentRoutingModule,
    AppTableModule,
    ReactiveFormsModule,
    ButtonModule,
    EditorModule,
    FileUploadModule,
    InputTextModule,
    CalendarModule,
    InputSwitchModule,
    DropdownModule,
    ImageCropperModalModule,
    CheckboxModule,
    InputNumberModule,
    TabViewModule,
    InputGroupModule,
    InputGroupAddonModule,
    TooltipModule,
    CourseFormModule,
    TreeModule,
    FormsModule,
    NgxPermissionsModule.forChild(),
    ToastModule,
    ConfirmPopupModule,
    ConfirmDialogModule,
    InputGroupModule,
    InputGroupAddonModule
  ]
})
export class ContentModule {}

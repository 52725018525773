import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { ConfirmationService, MessageService, PrimeIcons } from 'primeng/api';
import { TabView } from 'primeng/tabview';
import { lastValueFrom, map } from 'rxjs';
import {
  Badge,
  BadgeActionType,
  BadgeControllerService,
  BadgeRule,
  BadgeRuleDetailed,
  PeriodType,
  PersonType
} from 'src/app/admin-api';
import {
  TableActionButton,
  TableColumn,
  TableComponent
} from 'src/app/components/table';
import { Role, roleAsObject } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-badge-details',
  templateUrl: './badge-details.component.html',
  styleUrl: './badge-details.component.scss',
  providers: [ConfirmationService]
})
export class BadgeDetailsComponent implements OnInit {
  @ViewChild(TabView)
  tabview: TabView | undefined;
  @ViewChild(TableComponent)
  table: TableComponent | undefined;

  badgeId: number | undefined;
  badgeRuleId: number | undefined;
  badge: Badge | undefined;
  badgeRule: BadgeRule | undefined;
  selectedBadgeRule: BadgeRule | null | undefined;
  currentBadgeRule: BadgeRuleDetailed | undefined;
  badgeRules: Array<BadgeRuleDetailed> | undefined;
  isMobile: boolean;
  changed = false;
  selectedTab = 0;
  badgeActionTypes: Array<BadgeActionType> | undefined;
  personTypes: Array<PersonType> | undefined;
  periodTypes: Array<PeriodType> | undefined;
  permission = false;

  badgeRulesCols: Array<TableColumn> = [
    new TableColumn(
      'Descrição',
      'description',
      true,
      'text',
      '',
      'badgeRuleId'
    ),
    new TableColumn('Quantidade', 'quantity', true, 'number'),
    new TableColumn('Tipo Usuário', 'personTypeDescription', true, 'number'),
    new TableColumn('Tipo de Regra', 'actionDescription', true, 'number'),
    new TableColumn('Tipo de Período', 'periodDescription', true, 'number'),
    new TableColumn('Período', 'periodValue', true, 'number')
  ];
  actionButtons: Array<TableActionButton> = [
    new TableActionButton(
      '',
      'Excluir',
      PrimeIcons.TRASH,
      undefined,
      '',
      'Excluir',
      'bottom',
      true,
      true,
      'danger',
      'small'
    )
  ];

  dropdownFilters:
    | { [key: string]: Array<{ label: string; value: string }> }
    | undefined;

  constructor(
    private badgeService: BadgeControllerService,
    private activatedRoute: ActivatedRoute,
    private title: Title,
    private router: Router,
    private messageService: MessageService,
    private permissionsService: NgxPermissionsService,
    private confirmationService: ConfirmationService
  ) {}

  async ngOnInit(): Promise<void> {
    this.checkScreenSize();
    this.permission = await this.permissionsService.hasPermission([
      roleAsObject(Role.Full_Administrator).enumValue
    ]);
    this.activatedRoute.params.subscribe(async (params: Params) => {
      if (
        params['badgeId'] &&
        params['badgeId'] !== 'new' &&
        Number(params['badgeId']) !== this.badgeId
      ) {
        this.badgeId = Number(params['badgeId']);
        if (this.permission) {
          this.badgeRulesCols.push(
            new TableColumn('Ação', '', false, 'button')
          );
        }
        if (this.badgeId) {
          LoaderService.showLoader();
          await Promise.all([
            this.findBadge(this.badgeId),
            this.findBadgeRules()
          ]);
        }
        this.title.setTitle('Jóia - ' + this.badge?.title);
      } else if (!this.permission) {
        this.router.navigate(['/unauthorized']);
      } else if (this.permission && params['badgeId'] == 'new') {
        this.title.setTitle('Nova jóia');
        this.activatedRoute.queryParams.subscribe((queryParams) => {
          if (queryParams['tab']) {
            this.selectedTab = Number(queryParams['tab']) || 0;
          } else if (!this.badgeRule) {
            this.selectedTab = 0;
          }
        });
      } else {
        AppDialogService.showErrorDialog(
          { message: 'Jóia não encontrada' },
          true
        );
      }
      LoaderService.showLoader(false);
    });
  }
  async findBadge(badgeId: number): Promise<void> {
    try {
      this.badge = await lastValueFrom(
        this.badgeService.getBadgeById(badgeId).pipe(map((data) => data.result))
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error, true);
    }
  }
  checkScreenSize(): void {
    this.isMobile = window.innerWidth < 768;
  }
  get badgeImage(): string {
    return `https://glam.com.br/assets/icons/glampoints/gems/${FormUtil.semAcento(
      this.badge?.title
    )
      .toLowerCase()
      .replace(' ', '-')}.svg`;
  }
  tabChanged($event: number): void {
    this.router.navigate([`subscription-settings/badge/${this.badgeId}`], {
      queryParams: { tab: $event },
      queryParamsHandling: 'merge'
    });
  }
  async findBadgeRule(badgeRuleId: number): Promise<void> {
    try {
      this.badgeRule = await lastValueFrom(
        this.badgeService
          .findBadgeRule(badgeRuleId)
          .pipe(map((data) => data.result))
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error, true);
    }
  }
  async findBadgeRules(): Promise<void> {
    try {
      this.badgeRules = await lastValueFrom(
        this.badgeService
          .findBadgeRuleList(this.badgeId as number)
          .pipe(map((data) => data.result))
      );
      const [actionType, personType, periodType] = await Promise.all([
        this.getAllActionTypes(),
        this.getAllPersonTypes(),
        this.getAllPeriodTypes()
      ]);
      if (actionType && personType && periodType) {
        this.dropdownFilters = {
          actionDescription: actionType,
          personTypeDescription: personType,
          periodDescription: periodType
        };
      }
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
  }
  async badgeRuleAction($event: BadgeRuleDetailed | null): Promise<void> {
    if ($event) {
      await Promise.all([this.findBadgeRules()]);
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso',
        detail: `Regra ${
          this.selectedBadgeRule ? 'alterada' : 'cadastrada'
        } com sucesso`
      });
    }
    delete this.selectedBadgeRule;
    LoaderService.showLoader(false);
  }
  async deleteBadgeRule(badgeRuleDetailed: BadgeRuleDetailed) {
    LoaderService.showLoader();
    try {
      const result = await lastValueFrom(
        this.badgeService
          .deleteBadgeRule(badgeRuleDetailed.badgeRuleId)
          .pipe(map((data) => data.result))
      );
      this.messageService.add({
        severity: 'success',
        detail: result,
        summary: 'Sucesso'
      });
      delete this.badgeRules;
      await this.findBadgeRules();
      this.table?.refresh();
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }
  async buttonClick(event: {
    item: BadgeRuleDetailed;
    $event: Event;
    action: string;
  }) {
    this.currentBadgeRule = event.item;
    this.confirmationService.confirm({
      header: 'Reprovação',
      message: 'Tem certeza que deseja excluir?',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      accept: async () => {
        await this.deleteBadgeRule(this.currentBadgeRule);
      },
      target: event.$event.target
    });
  }

  async getAllActionTypes(): Promise<
    Array<{
      label: string;
      value: string;
    }>
  > {
    const objetos: Array<{ label: string; value: string }> = [];
    this.badgeActionTypes = await lastValueFrom(
      this.badgeService.findActionTypeList().pipe(map((data) => data.result))
    );
    for (let i = 0; i < this.badgeActionTypes.length; i++) {
      objetos.push({
        label: this.badgeActionTypes[i].description,
        value: this.badgeActionTypes[i].description
      });
    }
    return objetos.concat([
      {
        label: 'Todas',
        value: null
      }
    ]);
  }

  async getAllPersonTypes(): Promise<
    Array<{
      label: string;
      value: string;
    }>
  > {
    const objetos: Array<{ label: string; value: string }> = [];
    this.personTypes = await lastValueFrom(
      this.badgeService.findPersonTypeList().pipe(map((data) => data.result))
    );
    for (let i = 0; i < this.personTypes.length; i++) {
      objetos.push({
        label: this.personTypes[i].description,
        value: this.personTypes[i].description
      });
    }
    return objetos.concat([
      {
        label: 'Todas Opções',
        value: null
      }
    ]);
  }

  async getAllPeriodTypes(): Promise<
    Array<{
      label: string;
      value: string;
    }>
  > {
    const objetos: Array<{ label: string; value: string }> = [];
    this.periodTypes = await lastValueFrom(
      this.badgeService.findPeriodTypeList().pipe(map((data) => data.result))
    );
    for (let i = 0; i < this.periodTypes.length; i++) {
      objetos.push({
        label: this.periodTypes[i].description,
        value: this.periodTypes[i].description
      });
    }
    return objetos.concat([
      {
        label: 'Todas',
        value: null
      }
    ]);
  }

  goToBadge($event: Badge): void {
    this.router.navigate(['subscription-settings/badge/' + $event.badgeId]);
  }
}

export interface ReviewWithProduct {
  id?: string;
  personId?: number;
  productId?: string;
  productSku?: string;
  productName?: string;
  author?: string;
  email?: string;
  rate?: number;
  review?: string;
  reviewStatus?: 'pending' | 'approved' | 'rejected';
  title?: string;
  createdAt?: Date;
}

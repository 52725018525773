<form
  class="form-b4a"
  [formGroup]="personForm"
  *ngIf="personForm"
  (submit)="savePerson()"
>
  <span class="p-float-label">
    <input id="name" type="text" pInputText formControlName="name" />
    <label for="name">Nome *</label>
  </span>
  <span class="p-float-label">
    <input id="lastName" type="text" pInputText formControlName="lastName" />
    <label for="lastName">Sobrenome *</label>
  </span>
  <span class="p-float-label">
    <input
      id="username"
      type="text"
      pInputText
      formControlName="username"
      (blur)="personForm.controls['username'].valid && validateEmail()"
    />
    <label for="username">E-mail *</label>
    <small
      class="error"
      *ngIf="
        personForm.controls['username'].errors &&
        personForm.controls['username'].errors['pattern']
      "
    >
      Formato de e-mail inválido
    </small>
    <small
      *ngIf="
        personForm.controls['username'].errors &&
        personForm.controls['username'].errors['invalid']
      "
      class="p-error block"
      >E-mail já utilizado</small
    >
  </span>
  <span class="p-float-label">
    <p-inputMask
      id="cpf"
      (onBlur)="personForm.controls['cpf'].valid && validateCpf()"
      formControlName="cpf"
      mask="999.999.999-99"
    ></p-inputMask>
    <label for="cpf">CPF *</label>
    <small
      *ngIf="
        personForm.controls['cpf'].errors &&
        personForm.controls['cpf'].errors['exists']
      "
      class="p-error block"
      >CPF já utilizado</small
    >
    <small
      *ngIf="
        personForm.controls['cpf'].errors &&
        personForm.controls['cpf'].errors['invalid']
      "
      class="p-error block"
      >CPF inválido</small
    >
  </span>
  <span class="p-float-label">
    <p-inputMask
      id="phone"
      formControlName="phone"
      mask="(99) 99999-9999"
    ></p-inputMask>
    <label for="phone">Telefone</label>
  </span>
  <span class="p-float-label">
    <p-inputMask
      id="birthday"
      formControlName="birthday"
      mask="99/99/9999"
    ></p-inputMask>
    <label for="birthday">Aniversário *</label>
    <small
      class="error"
      *ngIf="
        personForm.controls['birthday'].errors &&
        personForm.controls['birthday'].errors['invalidDate']
      "
      >{{ personForm.controls['birthday'].errors['invalidDate'] }}</small
    >
  </span>
  <span class="p-float-label" *ngIf="badges">
    <p-dropdown
      [options]="badges"
      optionLabel="title"
      optionValue="badgeId"
      id="badgeId"
      formControlName="badgeId"
      appendTo="body"
      [autoDisplayFirst]="false"
    ></p-dropdown>
    <label for="badgeId">Joia</label>
  </span>
  <span class="p-input-checkbox">
    <p-checkbox
      formControlName="newsletterOptIn"
      [binary]="true"
      inputId="newsletterOptIn"
    ></p-checkbox>
    <label for="newsletterOptIn">Receber e-mails</label>
  </span>
  <small *ngIf="person?.dateUpdated"
    >*Última alteração em:
    {{ person.dateUpdated | date : 'dd/MM/yyyy HH:mm:ss' }}</small
  >
  <div class="buttons">
    <p-button
      label="Salvar"
      type="submit"
      [disabled]="
        !personForm.valid ||
        personForm.controls['username'].dirty ||
        personForm.controls['cpf'].dirty
      "
    ></p-button>
  </div>
</form>

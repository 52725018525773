import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  FeedbackAdminControllerService,
  FeedbackOption,
  FeedbackQuestion
} from 'src/app/admin-api';
import {
  FeedbackOptionTypeEnum,
  getAllFeedbackOptionTypes
} from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';
import { ImageUploadComponent } from '../../image-upload/image-upload.component';

@Component({
  selector: 'app-feedback-option-form',
  templateUrl: './feedback-option-form.component.html',
  styleUrl: './feedback-option-form.component.scss'
})
export class FeedbackOptionFormComponent
  extends ImageUploadComponent<FeedbackOption>
  implements OnInit
{
  private feedbackService: FeedbackAdminControllerService = inject(
    FeedbackAdminControllerService
  );
  private config: DynamicDialogConfig = inject(DynamicDialogConfig);
  private messageService: MessageService = inject(MessageService);
  ref: DynamicDialogRef = inject(DynamicDialogRef);

  option: FeedbackOption | undefined = this.config.data.option;
  question: FeedbackQuestion = this.config.data.question;
  optionTypes: Array<{ label: string; value: number }> =
    getAllFeedbackOptionTypes();
  questions: Array<FeedbackQuestion>;
  override form = new FormGroup({
    optionId: new FormControl<number>(
      { disabled: !this.option, value: this.option?.optionId },
      [Validators.required, Validators.min(1)]
    ),
    optionText: new FormControl<string>(
      this.option?.optionText,
      Validators.required
    ),
    enabled: new FormControl<boolean>(
      this.option ? this.option?.enabled : true
    ),
    nextQuestionId: new FormControl<number>({
      value: this.option?.nextQuestionId || 0,
      disabled: this.question?.groupId > 0
    }),
    mediaUrl: new FormControl<string>(
      { value: this.option?.mediaUrl, disabled: !this.option?.mediaUrl },
      Validators.required,
      FormUtil.imageUrlValidator()
    ),
    iconOutlinedUrl: new FormControl<string>(
      {
        value: this.option?.iconOutlinedUrl,
        disabled: !this.option?.iconOutlinedUrl && !this.option?.iconFilledUrl
      },
      Validators.required,
      FormUtil.imageUrlValidator()
    ),
    iconFilledUrl: new FormControl<string>(
      {
        value: this.option?.iconFilledUrl,
        disabled: !this.option?.iconOutlinedUrl && !this.option?.iconFilledUrl
      },
      Validators.required,
      FormUtil.imageUrlValidator()
    ),
    optionType: new FormControl<number>(
      this.option?.optionType ||
        (FeedbackOptionTypeEnum.Alternativa as any).value,
      Validators.required
    ),
    questionId: new FormControl<number>(
      { disabled: !!this.option, value: this.question?.questionId },
      [Validators.required, Validators.min(1)]
    ),
    displayType: new FormControl<number>(
      this.option?.mediaUrl
        ? 1
        : this.option?.iconFilledUrl || this.option?.iconOutlinedUrl
        ? 2
        : 0
    )
  });
  displayTypes: Array<{ label: string; value: number; disabled?: boolean }> = [
    { label: 'Texto', value: 0 },
    { label: 'Imagem', value: 1, disabled: true },
    { label: 'Ícone', value: 2, disabled: true }
  ];

  ngOnInit(): void {
    this.questions = this.config.data.questions.filter(
      (q: FeedbackQuestion) =>
        q.questionId !== this.question?.questionId &&
        q.questionOrder > this.question?.questionOrder
    );
    if (
      this.questions.length ||
      (this.config.data.questions as Array<FeedbackQuestion>).some(
        (q) => q.questionOrder > this.question?.questionOrder
      )
    ) {
      this.questions.unshift({
        title: 'Encerrar formulário',
        questionId: -1
      });
      this.questions.unshift({
        title: 'Nenhuma',
        questionId: 0
      });
    }
    this.form.controls['nextQuestionId'].markAsTouched();
    this.form.controls['nextQuestionId'].markAsDirty();
  }

  async submit(): Promise<void> {
    if (!this.form.valid) {
      Object.keys(this.form.controls).forEach((key) => {
        this.form.controls[key].markAsDirty();
        this.form.controls[key].markAsTouched();
        this.form.controls[key].updateValueAndValidity();
        return;
      });
    }
    LoaderService.showLoader();
    try {
      if (this.option) {
        this.option = await lastValueFrom(
          this.feedbackService
            .updateFeedbackOption(this.form.value)
            .pipe(map((data) => data.result))
        );
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'Alternativa atualizada com sucesso'
        });
      } else {
        this.option = await lastValueFrom(
          this.feedbackService
            .createFeedbackOption(this.form.value)
            .pipe(map((data) => data.result))
        );
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'Alternativa criada com sucesso'
        });
      }
      await this.afterSubmit();
      this.ref.close(this.option);
    } catch (error) {
      LoaderService.showLoader(false);
      AppDialogService.showErrorDialog(error);
    }
  }

  changeDisplayType(): void {
    this.form.controls['iconOutlinedUrl'].disable();
    this.form.controls['iconFilledUrl'].disable();
    this.form.controls['mediaUrl'].disable();
    switch (this.form.value.displayType as number) {
      case 2:
        this.form.controls['iconOutlinedUrl'].enable();
        this.form.controls['iconFilledUrl'].enable();
        break;
      case 1:
        this.form.controls['mediaUrl'].enable();
        break;
    }
  }

  get nextQuestion(): FeedbackQuestion {
    return this.form?.value.nextQuestionId && this.questions.length
      ? this.questions.find(
          (q) => q.questionId === this.form.value.nextQuestionId
        )
      : null;
  }

  get filePath(): string {
    return '/admin/feedback/options/';
  }

  override get model() {
    return this.option;
  }

  override get modelId() {
    return this.option?.optionId;
  }
}

import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  FeedbackAdminControllerService,
  FeedbackQuestionGroup
} from 'src/app/admin-api';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-feedback-group-form',
  templateUrl: './feedback-group-form.component.html',
  styleUrl: './feedback-group-form.component.scss'
})
export class FeedbackGroupFormComponent {
  private feedbackService: FeedbackAdminControllerService = inject(
    FeedbackAdminControllerService
  );
  private config: DynamicDialogConfig = inject(DynamicDialogConfig);
  ref: DynamicDialogRef = inject(DynamicDialogRef);

  group: FeedbackQuestionGroup = this.config.data.group;
  form = new FormGroup({
    feedbackGroupName: new FormControl<string>(
      this.group?.feedbackGroupName,
      Validators.required
    ),
    feedbackGroupId: new FormControl<number>(this.group.feedbackGroupId, [
      Validators.required,
      Validators.min(1)
    ]),
    showRadioButton: new FormControl<boolean>({
      disabled: true,
      value: this.group ? this.group.showRadioButton : true
    }),
    lastQuestion: new FormControl<boolean>({
      value: this.group?.lastQuestion,
      disabled: true
    }),
    isShuffled: new FormControl<boolean>({
      value: this.group?.isShuffled || false,
      disabled: true
    })
  });

  async submit(): Promise<void> {
    LoaderService.showLoader();
    try {
      this.group = await lastValueFrom(
        this.feedbackService
          .updateFeedbackQuestionGroup(this.form.value)
          .pipe(map((data) => data.result))
      );
      this.ref.close(this.group);
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }
}

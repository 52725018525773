import { DatePipe, TitleCasePipe } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { lastValueFrom, map } from 'rxjs';
import {
  DailyOrderCountByMonth,
  ExportedOrderTotal,
  PendingExportOrder
} from 'src/app/admin-api';
import { IntegrationControllerService } from 'src/app/admin-api/api/integrationController.service';
import { KeyExportDescriptionEnum, getDescriptionByKey } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
@Component({
  selector: 'app-integration',
  templateUrl: './integration.component.html',
  styleUrls: ['./integration.component.scss'],
  providers: [DatePipe, TitleCasePipe]
})
export class IntegrationComponent implements OnInit {
  private integrationService: IntegrationControllerService = inject(
    IntegrationControllerService
  );
  private datePipe: DatePipe = inject(DatePipe);
  private title = inject(Title);
  private titlecasePipe: TitleCasePipe = inject(TitleCasePipe);

  dateInit = new Date();
  selectedMonth = new FormControl<string>(
    this.datePipe.transform(new Date(), 'yyyy-MM'),
    Validators.required
  );
  pendingExportOrder: PendingExportOrder;
  exportedOrderTotal: ExportedOrderTotal;
  months: Array<{ label: string; value: string }>;
  dailyOrderCountByMonth: DailyOrderCountByMonth[];
  chartData: any;
  chartConfig: any;
  keyDescription = KeyExportDescriptionEnum;

  async ngOnInit(): Promise<void> {
    this.title.setTitle('Integrações - Dashboard de Exportações');
    this.initializeMonths();
    this.loadData();
  }

  initializeMonths() {
    this.months = [];

    let date = new Date(new Date().setDate(5));
    while (date.getFullYear() > 2019) {
      this.months.push({
        label: this.titlecasePipe.transform(
          this.datePipe.transform(date, 'MMMM yyyy', undefined, 'pt-BR')
        ) as string,
        value: this.datePipe.transform(date, 'yyyy-MM') as string
      });
      date = new Date(date.setMonth(date.getMonth() - 1));
    }
  }

  async loadData(): Promise<void> {
    try {
      LoaderService.showLoader();
      await Promise.all([
        this.fetchPendingExportedOrder(),
        this.fetchExportedOrderTotal()
      ]);
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async onMonthChange(): Promise<void> {
    LoaderService.showLoader();
    await this.fetchExportedOrderTotal();
    LoaderService.showLoader(false);
  }

  private async fetchPendingExportedOrder(): Promise<void> {
    try {
      this.pendingExportOrder = await lastValueFrom(
        this.integrationService
          .findTotalPendingExport()
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  private async fetchExportedOrderTotal(): Promise<void> {
    try {
      if (!this.selectedMonth.valid) {
        return;
      }

      this.exportedOrderTotal = await lastValueFrom(
        this.integrationService
          .findExportedOrderTotal(this.selectedMonth.value)
          .pipe(map((data) => data.result))
      );
      this.dailyOrderCountByMonth = await lastValueFrom(
        this.integrationService
          .findDailyOrderCountByMonth(this.selectedMonth.value)
          .pipe(map((data) => data.result))
      );
      this.createChart();
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  private async createChart(): Promise<void> {
    const xAxis = {
      ticks: {
        color: 'gray'
      },
      grid: {
        color: 'gray',
        drawBorder: false
      }
    };

    const yAxis = {
      ticks: {
        color: 'gray'
      },
      grid: {
        color: 'gray',
        drawBorder: false
      }
    };

    const plugins = {
      tooltips: {
        mode: 'index',
        intersect: false
      },
      legend: {
        labels: {
          color: 'black'
        }
      }
    };

    let datasets = [];
    let labels = [];

    if (this.dailyOrderCountByMonth && this.dailyOrderCountByMonth.length > 0) {
      const selectedMonthParts = this.selectedMonth.value.split('-');
      const year = selectedMonthParts[0];
      const month = selectedMonthParts[1];

      labels = this.dailyOrderCountByMonth.map((data) => {
        const formattedDate = `${data.dayOfMonth}/${month}/${year}`;
        return formattedDate;
      });

      const keys = this.getKeys(this.dailyOrderCountByMonth[0]);
      const index = keys.indexOf('dayOfMonth');
      if (index !== -1) {
        keys.splice(index, 1);
      }

      const defaultColors = [
        '#FF5733',
        '#33FF57',
        '#5733FF',
        '#33FFFF',
        '#FF33FF',
        '#FFFF33',
        '#8CDA33'
      ];
      let colorIndex = 0;

      datasets = keys.map((key) => {
        const color = defaultColors[colorIndex];
        colorIndex = (colorIndex + 1) % defaultColors.length;

        return {
          label: getDescriptionByKey(key),
          data: this.dailyOrderCountByMonth.map((item) => item[key]),
          stack: key === 'total' ? 'separate' : 'daily',
          backgroundColor: color
        };
      });

      const totalDay = this.dailyOrderCountByMonth.map((item) => {
        const {
          totalEdition,
          totalGift,
          totalTrade,
          totalResend,
          totalInfluencer,
          totalIncentive,
          totalBfluenceAward
        } = item;
        return (
          totalEdition +
          totalGift +
          totalTrade +
          totalResend +
          totalInfluencer +
          totalIncentive +
          totalBfluenceAward
        );
      });

      datasets.push({
        label: 'Pedidos Exportados no Dia',
        data: totalDay,
        stack: 'separate',
        backgroundColor: '#4d4d4d'
      });
    }

    this.chartData = {
      labels: labels,
      datasets: datasets,
      options: {
        animation: {
          easing: 'easeInQuad'
        },
        plugins: plugins,
        scales: {
          x: xAxis,
          y: yAxis
        }
      }
    };
  }

  getKeys(object: any): string[] {
    if (object === null || typeof object !== 'object') {
      return [];
    }

    return Object.keys(object);
  }

  get selectedMonthName(): string {
    return (
      this.months.find((m) => m.value === this.selectedMonth.value)?.label ||
      null
    );
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  ShopBanner,
  ShopSettings,
  ShopSettingsRepository
} from '@infrab4a/connect';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';

import { FileControllerService } from 'src/app/admin-api';
import { CustomizationFormComponent } from 'src/app/components/customization/customization-form.component';
import { ImageService } from 'src/app/services/image.service';
@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent
  extends CustomizationFormComponent
  implements OnInit
{
  pageDocument: ShopSettings;
  pageBanners: ShopBanner[];

  constructor(
    @Inject('ShopSettingsRepository')
    private shopSettingsRepository: ShopSettingsRepository,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    override router: Router,
    override imageService: ImageService,
    override config: PrimeNGConfig,
    override fileService: FileControllerService
  ) {
    super(router, config, imageService, fileService);

    this.documentId = 'GLAM_PANEL';

    this.form = this.formBuilder.group({
      descriptionMeta: ['', [Validators.required, Validators.maxLength(255)]],
      titleMeta: ['', Validators.required],
      imageMeta: ['']
    });
  }

  async ngOnInit() {
    await this.getDocument();
    this.initializeForm();
  }

  override async getDocument(): Promise<void> {
    this.pageDocument = await this.shopSettingsRepository.get({
      id: this.documentId
    });
    this.pageBanners = this.pageDocument.sections;
  }

  override initializeForm() {
    this.form.patchValue(this.pageDocument);
  }

  override async save() {
    if (this.form.valid) {
      await this.shopSettingsRepository.update({
        id: this.documentId,
        ...this.form.value
      });

      this.messageService.add({
        severity: 'success',
        summary: 'Salvo com sucesso',
        detail: 'As informações foram salvas.'
      });

      await this.afterSubmit();
    } else {
      console.error('Formulário inválido. Não é possível salvar os dados.');
    }
  }

  async handleFileSelect(
    event: { files: File[] },
    inputName: string,
    expectedWidth: number,
    expectedHeight: number,
    maxWeight: number,
    pInput: FileUpload
  ) {
    this.fileNamePattern = `glampoints-panel-meta-${new Date().getTime()}.webp`;

    await this.upload(
      event,
      inputName,
      expectedWidth,
      expectedHeight,
      maxWeight,
      pInput
    );
  }

  isMetaTitleInvalid() {
    const metaTitleControl = this.form.get('titleMeta');
    return (
      metaTitleControl?.invalid &&
      (metaTitleControl?.touched || metaTitleControl?.dirty)
    );
  }

  isMetaDescriptionInvalid() {
    const metaDescriptionControl = this.form.get('descriptionMeta');
    return (
      metaDescriptionControl?.invalid &&
      (metaDescriptionControl?.touched || metaDescriptionControl?.dirty)
    );
  }

  goToFirstCarousel() {
    this.navigate('configuracoes/painel/first-carousel');
  }

  goToSecondCarousel() {
    this.navigate('configuracoes/painel/second-carousel');
  }

  goToMenu() {
    this.navigate('configuracoes');
  }
  get metaImageUrl(): string {
    return this.form.get('imageMeta')?.value;
  }

  override get filePath(): string {
    return '/pages/glam-panel/';
  }

  override get model() {
    return this.pageDocument;
  }

  override get modelId() {
    return this.pageDocument?.id;
  }
}

export interface SubscriptionType {
  subscriptionTypeId?: number;
  subscriptionId?: number;
  name?: string;
  imageURL?: string;
  summary?: string;
  price?: number;
  installments?: number;
  editionCount?: number;
  recurrenceMonths?: number;
  rewardPoints?: number;
  dateCreated?: Date;
  dateUpdated?: Date;
  restrictedSubscriptionId?: number;
  badgeId?: number;
  isActive?: boolean;
  isVisible?: boolean;
  liveloPoints?: number;
  cycle?: number;
}

<p-tabView [activeIndex]="tabIndex">
  <p-tabPanel header="Todas">
    <div class="coupons-ranking-chart">
      <div class="coupons-ranking-chart__ranking">
        <div
          class="coupons-ranking-chart__ranking__total"
          *ngFor="let total of totals; let i = index"
        >
          <span class="coupons-ranking-chart__ranking__total__label"
            >{{ i + 1 }}.

            {{ total.couponName | uppercase }}
            <i
              *ngIf="i < 3"
              class="pi pi-trophy"
              [class.coupons-ranking-chart__ranking__total--gold]="i === 0"
              [class.coupons-ranking-chart__ranking__total--silver]="i === 1"
              [class.coupons-ranking-chart__ranking__total--bronze]="i === 2"
            >
            </i>
          </span>
          <span class="coupons-ranking-chart__ranking__total__value">
            {{ total.total || 0 | number : '1.0-0' }}
          </span>
        </div>
      </div>
      <div class="coupons-ranking-chart__chart">
        <app-coupons-ranking-chart
          [couponUsages]="couponUsages"
          [couponUsageType]="couponUsageType"
          [responsive]="responsive"
          [width]="responsive ? undefined : width"
          [height]="responsive ? undefined : height"
        />
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Aquisição">
    <div class="coupons-ranking-chart">
      <div class="coupons-ranking-chart__ranking">
        <div
          class="coupons-ranking-chart__ranking__total"
          *ngFor="let total of totalsAcquisition; let i = index"
        >
          <span class="coupons-ranking-chart__ranking__total__label"
            >{{ i + 1 }}.

            {{ total.couponName | uppercase }}
            <i
              *ngIf="i < 3"
              class="pi pi-trophy"
              [class.coupons-ranking-chart__ranking__total--gold]="i === 0"
              [class.coupons-ranking-chart__ranking__total--silver]="i === 1"
              [class.coupons-ranking-chart__ranking__total--bronze]="i === 2"
            >
            </i>
          </span>
          <span class="coupons-ranking-chart__ranking__total__value">
            {{ total.total || 0 | number : '1.0-0' }}
          </span>
        </div>
      </div>
      <div class="coupons-ranking-chart__chart">
        <app-coupons-ranking-chart
          [couponUsages]="couponUsagesAcquisition"
          [couponUsageType]="couponUsageType"
          [responsive]="responsive"
          [width]="responsive ? undefined : width"
          [height]="responsive ? undefined : height"
        />
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Reativação">
    <div class="coupons-ranking-chart">
      <div class="coupons-ranking-chart__ranking">
        <div
          class="coupons-ranking-chart__ranking__total"
          *ngFor="let total of totalsReactivation; let i = index"
        >
          <span class="coupons-ranking-chart__ranking__total__label"
            >{{ i + 1 }}.

            {{ total.couponName | uppercase }}
            <i
              *ngIf="i < 3"
              class="pi pi-trophy"
              [class.coupons-ranking-chart__ranking__total--gold]="i === 0"
              [class.coupons-ranking-chart__ranking__total--silver]="i === 1"
              [class.coupons-ranking-chart__ranking__total--bronze]="i === 2"
            >
            </i>
          </span>
          <span class="coupons-ranking-chart__ranking__total__value">
            {{ total.total || 0 | number : '1.0-0' }}
          </span>
        </div>
      </div>
      <div class="coupons-ranking-chart__chart">
        <app-coupons-ranking-chart
          [couponUsages]="couponUsagesReactivation"
          [couponUsageType]="couponUsageType"
          [responsive]="responsive"
          [width]="responsive ? undefined : width"
          [height]="responsive ? undefined : height"
        />
      </div>
    </div>
  </p-tabPanel>
</p-tabView>

import {
  ChangeDetectorRef,
  Component,
  inject,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Editor } from 'primeng/editor';
import { FileUpload, FileUploadHandlerEvent } from 'primeng/fileupload';
import Quill from 'quill';
import { lastValueFrom, map } from 'rxjs';
import * as beautify from 'simply-beautiful';
import {
  Course,
  CourseControllerService,
  CourseModuleContent
} from 'src/app/admin-api';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';
import { ImageUploadComponent } from '../../image-upload/image-upload.component';

@Component({
  selector: 'app-content-form',
  templateUrl: './content-form.component.html',
  styleUrl: './content-form.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class ContentFormComponent
  extends ImageUploadComponent<Course>
  implements OnInit
{
  @ViewChild(Editor) editor: Editor;

  private courseService = inject(CourseControllerService);
  private messageService = inject(MessageService);
  private ref = inject(DynamicDialogRef);
  cdRef = inject(ChangeDetectorRef);

  content: CourseModuleContent = inject(DynamicDialogConfig).data.content;
  moduleId: number = inject(DynamicDialogConfig).data.moduleId;
  override form = new FormGroup({
    title: new FormControl<string>(null, [
      Validators.required,
      Validators.maxLength(200)
    ]),
    subtitle: new FormControl<string>(null, [Validators.maxLength(200)]),
    description: new FormControl<string>(null, [Validators.maxLength(4000)]),
    active: new FormControl(false),
    image: new FormControl<string>(null),
    videoURL: new FormControl<string>(null, [
      Validators.required,
      FormUtil.linkValidator
    ]),
    html: new FormControl(false),
    order: new FormControl<number>(null),
    length: new FormControl<number>(null, [
      Validators.required,
      Validators.min(1)
    ])
  });
  toolbarOptions = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: [] }],
    [
      'bold',
      'italic',
      'underline',
      'strike',
      { script: 'sub' },
      { script: 'super' },
      'code-block'
    ], // toggled buttons
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    ['link', 'image'],

    [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }]
  ];
  handlers = {
    image: this.handleImage
  };

  ngOnInit(): void {
    this.form.patchValue(this.content);
    if (this.content?.videoURL) this.loadVideo();
    if (this.content?.videoURL && !this.content?.image) this.getCoverImage();
    if (this.content?.videoURL && !this.content?.length)
      this.getVideoDuration();
  }

  format() {
    this.form
      .get('description')
      .setValue(beautify.html(this.form.value.description, { indent_size: 2 }));
  }

  handleImage() {
    document.getElementById('inputFile').click();
  }

  async handleFile($event: Event) {
    try {
      LoaderService.showLoader();
      const link = await lastValueFrom(
        this.fileService
          .uploadFileForm(
            this.filePath.concat('assets/'),
            ($event.target as any).files[0],
            ($event.target as any).files[0].name
          )
          .pipe(map((data) => data.result))
      );
      this.form.controls.description.setValue(
        this.form.value.description + `<p><img src="${link}" /></p>`
      );
      this.editor.writeValue(this.form.value.description);
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso',
        detail: 'Imagem adicionada ao final do texto.'
      });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  addClassToImages() {
    (this.editor?.getQuill() as Quill)?.container
      .querySelectorAll('img')
      .forEach((i) => {
        if (!i.className?.includes('dimensioned'))
          i.classList.add('dimensioned');
      });
    this.form
      .get('description')
      .setValue(
        (this.editor?.getQuill() as Quill)?.getSemanticHTML() ||
          this.form.value.description
      );
  }

  async submit() {
    if (this.form.valid) {
      try {
        LoaderService.showLoader();
        if (this.content) {
          this.content = await lastValueFrom(
            this.courseService
              .updateCourseModuleContent(this.form.value, this.modelId)
              .pipe(map((data) => data.result))
          );
        } else {
          this.content = await lastValueFrom(
            this.courseService
              .createCourseModuleContent(this.form.value, this.moduleId)
              .pipe(map((data) => data.result))
          );
        }
        await this.afterSubmit();
        this.ref.close(this.content);
      } catch (error) {
        LoaderService.showLoader(false);
        AppDialogService.showErrorDialog(error);
      }
    }
  }

  async updateVideo(
    $event: FileUploadHandlerEvent,
    field: string,
    fileUpload: FileUpload
  ) {
    LoaderService.showLoader();
    await this.onUpload($event, field, fileUpload);
    await this.getCoverImage();
    this.getVideoDuration();
  }

  async refreshVideo() {
    if (
      this.form.controls.videoURL.value &&
      FormUtil.validURL(this.form.value.videoURL)
    ) {
      LoaderService.showLoader();
      await this.getCoverImage();
      this.getVideoDuration();
    } else {
      this.form.controls.videoURL.setErrors({ invalid: true });
      this.loadVideo();
    }
    if (
      this.content?.videoURL &&
      this.form.value.videoURL !== this.content.videoURL &&
      !this.imagesReplaced.includes(this.content.videoURL)
    ) {
      this.imagesReplaced.push(this.content.videoURL);
    }
  }

  async getCoverImage(loader = false, seekTo = 0.1) {
    try {
      if (loader) LoaderService.showLoader();
      const cover = await this.getVideoCover(seekTo);
      await this.onUpload(
        {
          files: [
            new File(
              [cover],
              this.content?.contentId?.toString() ||
                new Date().getTime().toString()
            )
          ]
        },
        'image'
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      if (loader) LoaderService.showLoader(false);
    }
  }

  getVideoDuration() {
    const video = document.getElementsByTagName('video')[0];
    if (video?.duration) this.form.controls.length.setValue(video.duration);
  }

  loadVideo() {
    const video = document.getElementsByTagName('video')[0];
    video.setAttribute('src', this.form.value.videoURL);
    video.load();
    return video;
  }

  getVideoCover(seekTo = 0.0): Promise<Blob> {
    return new Promise((resolve, reject) => {
      // load the file to a video player
      const video = this.loadVideo();
      video.addEventListener('error', () => {
        reject('error when loading video file');
      });
      // load metadata of the video to get video duration and dimensions
      video.addEventListener('loadedmetadata', () => {
        // seek to user defined timestamp (in seconds) if possible
        if (video.duration < seekTo) {
          reject('video is too short.');
          return;
        }
        // delay seeking or else 'seeked' event won't fire on Safari
        setTimeout(() => {
          video.currentTime = seekTo;
        }, 200);
        // extract video thumbnail once seeking is complete
        video.addEventListener('seeked', () => {
          // define a canvas to have the same dimension as the video
          const canvas = document.createElement('canvas');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          // draw the video frame to canvas
          const ctx = canvas.getContext('2d');
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          // return the canvas image as a blob
          ctx.canvas.toBlob(
            (blob) => {
              resolve(blob);
            },
            'image/jpeg',
            0.75 /* quality */
          );
        });
      });
    });
  }

  removeVideo() {
    this.imagesReplaced.push(this.form.value.videoURL);
    this.imagesReplaced.push(this.form.value.image);
    this.form.controls.videoURL.reset();
    this.form.controls.image.reset();
    this.form.controls.length.reset();
    this.loadVideo();
  }

  get modelId() {
    return this.content?.contentId;
  }

  get filePath(): string {
    return '/courses/modules/content';
  }

  get model(): CourseModuleContent {
    return this.content;
  }
}

/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Composition {
  compositionId?: number;
  editionId?: number;
  compositionName?: string;
  maxSubscriberCount?: number;
  productVariantCount?: number;
  subscriberCount?: number;
  invoiceTotal?: number;
  saleTotal?: number;
  costTotal?: number;
  minInvoiceNumber?: number;
  maxInvoiceNumber?: number;
  glamclubTradeCount?: number;
  lastboxCount?: number;
  dateCreated?: Date;
  dateUpdated?: Date;
  weightGrams?: number;
  productVariantId?: number;
  displayTitle?: string;
  description?: string;
  productionGoal?: number;
  dateSyncERP?: Date;
}

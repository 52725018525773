<h3>Menu Customização</h3>
<section>
  <div class="card flex justify-content-center">
    <p-cascadeSelect
      [(ngModel)]="selectedSite"
      (ngModelChange)="onChange($event)"
      [options]="sites"
      optionLabel="cname"
      optionGroupLabel="name"
      [optionGroupChildren]="['pages', 'subPages']"
      [style]="{ minWidth: '14rem' }"
      placeholder="Escolha o site"
    />
  </div>
</section>
<!-- <router-outlet></router-outlet> -->

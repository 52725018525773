import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { lastValueFrom, map } from 'rxjs';
import {
  PageableRequest,
  PersonControllerService,
  PersonDetail
} from 'src/app/admin-api';
import { PageContent, TableColumn } from 'src/app/components/table';
import { getPersonTypesIncludeAll } from 'src/app/models';

@Component({
  selector: 'app-person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.scss'],
  providers: [DatePipe]
})
export class PersonComponent {
  cols = [
    new TableColumn('Id', 'personId', true, 'number', '', 'personId'),
    new TableColumn(
      'Nome',
      'name',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Sobrenome',
      'lastName',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Email',
      'username',
      true,
      'text',
      undefined,
      undefined,
      true,
      'startsWith'
    ),
    new TableColumn(
      'CPF',
      'cpfFormatted',
      true,
      'text',
      undefined,
      undefined,
      true,
      'equals'
    ),
    new TableColumn(
      'Telefone',
      'phone',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Criação',
      'dateCreated',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    ),
    new TableColumn(
      'Últ. Atualização',
      'dateUpdated',
      false,
      'date',
      undefined,
      undefined,
      true,
      'between'
    ),
    new TableColumn(
      'Cupom',
      'couponName',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Tipo',
      'personType',
      true,
      'text',
      undefined,
      undefined,
      true,
      'equals'
    )
  ];
  dropdownFilters:
    | { [key: string]: Array<{ label: string; value: string }> }
    | undefined;

  constructor(
    public personService: PersonControllerService,
    private title: Title
  ) {
    this.title.setTitle('Usuários');
    this.dropdownFilters = {
      personType: getPersonTypesIncludeAll().map((pt) => ({
        label: pt.label,
        value: pt.value.toString()
      }))
    };
  }

  async findPage(
    request?: PageableRequest,
    service?: unknown
  ): Promise<PageContent<PersonDetail>> {
    const filterPersonType = request.filters?.find(
      (f) => f.field === 'personType'
    );
    if (filterPersonType?.value) {
      if (Number(filterPersonType.value) < 0) {
        filterPersonType.value = '';
      }
      request.filters?.push({
        condition: 'equals',
        field: 'personTypeId',
        fieldType: 'number',
        value: filterPersonType.value
      });
    }
    return lastValueFrom(
      (service as PersonControllerService)
        .listPersonTable(request)
        .pipe(map((data) => data.result as PageContent<PersonDetail>))
    );
  }
}

import {
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  WritableSignal,
  computed,
  inject,
  signal
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import {
  ConfirmationService,
  MessageService,
  TreeDragDropService,
  TreeNode
} from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import {
  Tree,
  TreeNodeCollapseEvent,
  TreeNodeDropEvent,
  TreeNodeExpandEvent
} from 'primeng/tree';
import { lastValueFrom, map } from 'rxjs';
import {
  Attribute,
  AttributeGroup,
  AttributeOption,
  BeautyProfileAdminControllerService,
  BeautyProfileControllerService,
  PersonActiveAttributeOptionsGrouped,
  PersonAttributesResponse,
  SubscriberControllerService
} from 'src/app/admin-api';
import { AttributeFormComponent } from 'src/app/components/attribute-form/attribute-form.component';
import { AttributeGroupFormComponent } from 'src/app/components/attribute-form/attribute-group-form/attribute-group-form.component';
import { AttributeOptionFormComponent } from 'src/app/components/attribute-form/attribute-option-form/attribute-option-form.component';
import { Role, roleAsObject } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-beauty-profile',
  templateUrl: './beauty-profile.component.html',
  styleUrl: './beauty-profile.component.scss',
  encapsulation: ViewEncapsulation.None,
  providers: [
    MessageService,
    TreeDragDropService,
    DialogService,
    ConfirmationService
  ]
})
export class BeautyProfileComponent implements OnInit {
  @ViewChild(Tree) tree: Tree;

  private beautyProfileService: BeautyProfileControllerService = inject(
    BeautyProfileControllerService
  );
  private beautyProfileAdminService: BeautyProfileAdminControllerService =
    inject(BeautyProfileAdminControllerService);
  private subscriberService: SubscriberControllerService = inject(
    SubscriberControllerService
  );
  private permissionsService: NgxPermissionsService = inject(
    NgxPermissionsService
  );
  private title: Title = inject(Title);
  private dialog: DialogService = inject(DialogService);
  private router: Router = inject(Router);
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  nodes = computed(() => {
    return this.beautyProfile().map((g) =>
      FormUtil.groupToNode(g, this.expandedGroups, this.expandedAttributes)
    );
  });
  totalGroups = computed(() => {
    return this.beautyProfile()?.filter((g) => g.active)?.length || 0;
  });
  totalAttributes = computed(() => {
    return this.beautyProfile()
      ?.filter((g) => g.active)
      .reduce(
        (total, g) =>
          (total +=
            (g.attributes.filter((a) => a.active).length || 0) +
              g.groups
                ?.filter((gg) => gg.active)
                ?.reduce(
                  (sum, gg) =>
                    (sum +=
                      gg.attributes?.filter((a) => a.active)?.length || 0),
                  0
                ) || 0),
        0
      );
  });
  totalOptions = computed(() => {
    return this.beautyProfile()
      ?.filter((g) => g.active)
      ?.reduce(
        (total, g) =>
          (total +=
            g.attributes
              ?.filter((a) => a.active)
              ?.reduce(
                (sum, a) =>
                  (sum += a.options?.filter((o) => o.active)?.length || 0),
                0
              ) +
            g.groups
              ?.filter((a) => a.active)
              ?.reduce(
                (sum, a) => (
                  (sum += a.attributes
                    ?.filter((a) => a.active)
                    .reduce(
                      (sum2, a) =>
                        (sum2 +=
                          a.options?.filter((o) => o.active)?.length || 0),
                      0
                    )),
                  0
                ),
                0
              )),
        0
      );
  });
  sortable = computed(() => {
    return !this.tree?.filterViewChild?.nativeElement?.value;
  });
  empty = computed(() => {
    return (
      (this.subscriberCount() || 0) -
      ((this.personCount()?.complete || 0) + this.personCount()?.incomplete)
    );
  });
  percentageEmpty = computed(() => {
    return 100 * ((this.empty() || 0) / (this.subscriberCount() || 1));
  });
  percentageComplete = computed(() => {
    return (
      100 *
      ((this.personCount()?.complete || 0) / (this.subscriberCount() || 1))
    );
  });
  percentageIncomplete = computed(() => {
    return (
      100 *
      ((this.personCount()?.incomplete || 0) / (this.subscriberCount() || 1))
    );
  });
  expandedGroups: Array<string> = [];
  expandedAttributes: Array<string> = [];
  beautyProfile: WritableSignal<Array<AttributeGroup>> = signal(undefined);
  personCount: WritableSignal<PersonAttributesResponse> = signal(undefined);
  subscriberCount: WritableSignal<number | undefined> = signal(undefined);
  answers: Array<PersonActiveAttributeOptionsGrouped>;
  tabIndex = 0;
  permissions = false;

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader();
    this.title.setTitle('Perfil de beleza');
    this.permissions = await this.permissionsService.hasPermission(
      this.permissionsAccepted
    );
    await Promise.all([this.findBeautyProfile(), this.findSubscriberCount()]);
    this.findPersonCount();
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['tab']) {
        try {
          this.tabIndex = Number(params['tab']);
        } catch (error) {
          this.tabIndex = 0;
        }
      }
    });
    LoaderService.showLoader(false);
  }

  async findBeautyProfile(): Promise<void> {
    try {
      this.beautyProfile.set(
        await lastValueFrom(
          this.beautyProfileService
            .findBeautyProfileForm()
            .pipe(map((data) => data.result))
        )
      );
    } catch (error) {
      this.beautyProfile.set([]);
      AppDialogService.showErrorDialog(error);
    }
  }

  async findPersonCount(): Promise<void> {
    try {
      this.personCount.set(undefined);
      this.personCount.set(
        await lastValueFrom(
          this.beautyProfileService
            .countPersonAttributes()
            .pipe(map((data) => data.result))
        )
      );
    } catch (error) {
      this.personCount.set({
        complete: 0,
        incomplete: 0
      });
      AppDialogService.showErrorDialog(error);
    }
  }

  async findSubscriberCount(): Promise<void> {
    try {
      this.subscriberCount.set(
        await lastValueFrom(
          this.subscriberService
            .listSubscriberTable({
              page: 0,
              pageSize: 1,
              filters: [
                {
                  condition: 'in',
                  field: 'subscriberStatus',
                  fieldType: 'number',
                  value: '0,10'
                }
              ],
              sortBy: 'subscriberId',
              sortDirection: 'asc'
            })
            .pipe(map((data) => data.result.totalElements))
        )
      );
    } catch (error) {
      this.subscriberCount.set(0);
      AppDialogService.showErrorDialog(error);
    }
  }

  isExternalLink(link?: string): boolean {
    return link && (link.includes('http://') || link.includes('https://'));
  }

  async validateChange($event: TreeNodeDropEvent): Promise<void> {
    if (
      ($event.dragNode.type === $event.dropNode.type || // Mesmo tipo
        ($event.dragNode.type === 'attribute' &&
          $event.dropNode.type === 'attributeGroup' &&
          $event.dropNode.parent) || // Pergunta e grupo
        ($event.dragNode.type === 'attributeGroup' &&
          $event.dragNode.parent &&
          $event.dropNode.type === 'attribute')) && // Grupo e pergunta
      $event.dragNode.parent?.key === $event.dropNode.parent?.key
    ) {
      $event.accept();
      LoaderService.showLoader();
      try {
        await this.updateOrder($event.dragNode);
      } catch (error) {
        AppDialogService.showErrorDialog(error);
      }
      LoaderService.showLoader(false);
    }
  }

  attributeGroupForm(group?: AttributeGroup): void {
    this.dialog
      .open(AttributeGroupFormComponent, {
        closable: false,
        data: {
          group
        },
        width: '600px',
        header: group ? 'Editar grupo de perguntas' : 'Novo grupo de perguntas'
      })
      .onClose.subscribe(async (group: AttributeGroup) => {
        if (group) {
          LoaderService.showLoader();
          await this.findBeautyProfile();
          this.findPersonCount();
          LoaderService.showLoader(false);
        }
      });
  }

  attributeForm(group: AttributeGroup, attribute?: Attribute): void {
    this.dialog
      .open(AttributeFormComponent, {
        closable: false,
        data: {
          attribute,
          group
        },
        width: group.attributeGroupReferenceId ? '800px' : '600px',
        header: attribute ? 'Editar pergunta' : 'Nova pergunta'
      })
      .onClose.subscribe(async (attribute: Attribute) => {
        if (attribute) {
          LoaderService.showLoader();
          await this.findBeautyProfile();
          this.findPersonCount();
          LoaderService.showLoader(false);
        }
      });
  }

  attributeOptionForm(
    group: AttributeGroup,
    attribute: Attribute,
    attributeOption?: AttributeOption
  ): void {
    this.dialog
      .open(AttributeOptionFormComponent, {
        closable: false,
        data: {
          attributeOption,
          attribute,
          group,
          beautyProfile: this.beautyProfile()
        },
        width: '600px',
        header: attributeOption ? 'Editar alternativa' : 'Nova alternativa'
      })
      .onClose.subscribe(async (option: AttributeOption) => {
        if (option) {
          LoaderService.showLoader();
          await this.findBeautyProfile();
          this.findPersonCount();
          LoaderService.showLoader(false);
        }
      });
  }

  async convertToGroup(attribute: Attribute): Promise<void> {
    LoaderService.showLoader();
    try {
      await lastValueFrom(
        this.beautyProfileAdminService.convertAttributeIntoGroup(
          attribute.attributeId
        )
      );
      await this.findBeautyProfile();
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  async removeFromGroup(attribute: Attribute): Promise<void> {
    LoaderService.showLoader();
    try {
      await lastValueFrom(
        this.beautyProfileAdminService.removeAttributeFromGroup(
          attribute.attributeId
        )
      );
      await this.findBeautyProfile();
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  async updateOrder(
    node: TreeNode<AttributeGroup | Attribute | AttributeOption>
  ): Promise<void> {
    if (!node.parent)
      await lastValueFrom(
        this.beautyProfileAdminService.updateAttributeGroupsOrder(
          this.nodes().map((g) => Number(g.key))
        )
      );
    else if (node.type === 'attribute' || node.type === 'attributeGroup')
      await this.updateAttributes(node);
    else
      await lastValueFrom(
        this.beautyProfileAdminService.updateAttributeOptionsOrder(
          Number(node.parent.key),
          node.parent.children.map((n) => Number(n.key))
        )
      );
    await this.findBeautyProfile();
  }

  async updateAttributes(
    node: TreeNode<Attribute | AttributeGroup>
  ): Promise<void> {
    await lastValueFrom(
      this.beautyProfileAdminService.updateAttributesOrder(
        Number(node.parent.key),
        node.parent.children.map(
          (a) => `${a.type === 'attribute' ? 'a' : 'g'}${a.key}`
        )
      )
    );
  }

  indexOf(
    node: TreeNode<AttributeGroup | Attribute | AttributeOption>
  ): number {
    if (node.parent) {
      return node.parent.children.findIndex((n) => n.key === node.key) + 1;
    }
    return this.nodes().findIndex((n) => n.key === node.key);
  }

  onExpandCollapse($event: TreeNodeCollapseEvent | TreeNodeExpandEvent): void {
    if ($event.node.type === 'attribute') {
      if (!$event.node.expanded) {
        const index = this.expandedAttributes.indexOf($event.node.key);
        if (index >= 0) this.expandedAttributes.splice(index, 1);
      } else this.expandedAttributes.push($event.node.key);
    } else {
      if (!$event.node.expanded) {
        const index = this.expandedGroups.indexOf($event.node.key);
        if (index >= 0) this.expandedGroups.splice(index, 1);
      } else this.expandedGroups.push($event.node.key);
    }
  }

  async changeStatus(
    obj: Attribute | AttributeGroup | AttributeOption
  ): Promise<void> {
    LoaderService.showLoader();
    try {
      if (
        Object.prototype.hasOwnProperty.call(obj, 'attributeGroupName') &&
        Object.prototype.hasOwnProperty.call(obj, 'attributeGroupId')
      ) {
        obj = await lastValueFrom(
          this.beautyProfileAdminService
            .updateAttributeGroup(obj)
            .pipe(map((data) => data.result))
        );
      } else if (
        Object.prototype.hasOwnProperty.call(obj, 'attributeId') &&
        Object.prototype.hasOwnProperty.call(obj, 'attributeName')
      ) {
        obj = await lastValueFrom(
          this.beautyProfileAdminService
            .updateAttribute(obj)
            .pipe(map((data) => data.result))
        );
      } else if (
        Object.prototype.hasOwnProperty.call(obj, 'attributeOptionId')
      ) {
        obj = await lastValueFrom(
          this.beautyProfileAdminService
            .updateAttributeOption(obj)
            .pipe(map((data) => data.result))
        );
      }
      await this.findBeautyProfile();
      this.findPersonCount();
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  navigate(tab: number): void {
    this.router.navigate(['research/beauty-profile'], {
      queryParams: { tab }
    });
  }

  get permissionsAccepted(): Array<string> {
    return [
      roleAsObject(Role.Full_Administrator)?.enumValue,
      roleAsObject(Role.Business_Intelligence)?.enumValue,
      roleAsObject(Role.Customer_Success)?.enumValue
    ];
  }
}

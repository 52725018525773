export enum SubscriberBillingStatusEnum {
  'Testing' = -1,
  'Pendente' = 0,
  'Pago' = 1,
  'Falha' = 2,
  'Expirado' = 3,
  'Cancelado' = 4,
  'Estornado' = 5,
  'Estorno parcial' = 6
}

export function getAllSubscriberBillingStatusIncludeAll(): Array<{
  label: string;
  value: number;
}> {
  const objetos: Array<{ label: string; value: number }> = [];
  for (const [p, propertyValue] of Object.entries(
    SubscriberBillingStatusEnum
  )) {
    try {
      if (Number(propertyValue) >= 0) {
        objetos.push({
          label: p,
          value: Number(propertyValue)
        });
      }
    } catch (error: any) {
      objetos.length;
    }
  }
  return objetos.concat([
    {
      label: 'Todas',
      value: 0
    }
  ]);
}

export function getAllSubscriberBillingStatus(): Array<{
  label: string;
  value: number;
}> {
  const objetos: Array<{ label: string; value: number }> = [];
  for (const [p, propertyValue] of Object.entries(
    SubscriberBillingStatusEnum
  )) {
    try {
      if (Number(propertyValue) >= 0) {
        objetos.push({
          label: p,
          value: Number(propertyValue)
        });
      }
    } catch (error: any) {
      objetos.length;
    }
  }
  return objetos;
}

<ng-container *ngIf="category">
  <div class="page-title">
    Detalhes da categoria -
    {{ parentCategory ? parentCategory.externalId + '.' : '' }}
    {{ category.externalId }} {{ category.displayName }}
  </div>
  <div class="details category-details">
    <div class="details__right">
      <div class="card">
        <p-tabView
          [activeIndex]="selectedTab"
          (activeIndexChange)="tabChanged($event)"
        >
          <p-tabPanel header="Informações gerais">
            <div class="basic-details">
              <app-product-category-form
                *ngIf="categories && childrenCategories"
                [model]="category"
                (updated)="updateCategory($event)"
                [categories]="categories"
                [children]="childrenCategories.length > 0"
              ></app-product-category-form>
              <app-table
                *ngIf="!parentCategory && fixedFilters"
                [service]="productService"
                [findPage]="findPage"
                [exportExcel]="false"
                [exportPdf]="false"
                [addButton]="false"
                [cols]="categoryCols"
                sortField="externalIdString"
                [fixedFilters]="fixedFilters"
                [sortOrder]="-1"
                modelName="subcategorias"
              ></app-table>
            </div>
          </p-tabPanel>
          <p-tabPanel header="Produtos ({{ products?.length || 0 }})">
            <app-table
              *ngIf="products && productCols"
              [elements]="products"
              [cols]="productCols"
              [addButton]="false"
              [lazy]="false"
              sortField="productName"
              [sortOrder]="-1"
              modelName="produtos"
              [dropdownFilters]="dropdownFilters()"
              [changeColumns]="true"
            ></app-table>
          </p-tabPanel>
          <p-tabPanel header="Variantes ({{ productVariants?.length || 0 }})">
            <app-table
              *ngIf="productVariants && variantCols"
              [elements]="productVariants"
              [cols]="variantCols"
              [addButton]="false"
              [lazy]="false"
              sortField="productVariantName"
              [sortOrder]="-1"
              modelName="variantes"
              [changeColumns]="true"
            ></app-table>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>
  </div>
</ng-container>

<div class="category-form" *ngIf="!category">
  <div class="page-title">Nova categoria</div>
  <app-product-category-form [categories]="categories" *ngIf="categories">
  </app-product-category-form>
</div>

<form
  [formGroup]="form"
  *ngIf="form && categories"
  (submit)="save()"
  class="form-b4a category-form"
  [class.editable]="model"
>
  <span class="p-float-label">
    <p-inputNumber inputId="externalId" formControlName="externalId">
    </p-inputNumber>
    <label for="externalId">ID Externo *</label>
    <small
      *ngIf="
        (form.dirty || !model) && last && last.categoryId !== model?.categoryId
      "
      >*Obs: Última referência
      <b>{{ last.externalId }} {{ last.displayName }}</b></small
    >
    <small
      class="error"
      *ngIf="
        form.controls['externalId'].errors &&
        form.controls['externalId'].errors['min']
      "
    >
      Mínimo {{ form.controls['externalId'].errors['min']['min'] }}
    </small>
  </span>
  <span class="p-float-label">
    <input
      type="text"
      pInputText
      formControlName="displayName"
      name="displayName"
      id="displayName"
    />
    <label for="displayName">Nome *</label>
  </span>
  <span class="p-float-label" *ngIf="parentCategories">
    <p-dropdown
      formControlName="parentId"
      appendTo="body"
      [autoDisplayFirst]="false"
      [options]="parentCategories"
      optionValue="categoryId"
      (onChange)="
        !model &&
          !form.controls['externalId'].valid &&
          last.externalId &&
          form.controls['externalId'].setValue(last.externalId + 1)
      "
    >
      <ng-template pTemplate="item" let-category>
        {{ category.externalId }} {{ category.displayName }}
      </ng-template>
      <ng-template pTemplate="selectedItem">
        {{ parentCategory.externalId }} {{ parentCategory.displayName }}
      </ng-template>
    </p-dropdown>
    <label for="parentId">Categoria Macro</label>
    <small *ngIf="children">*Existem categorias vinculadas a esta</small>
  </span>
  <div class="buttons" *ngIf="permission">
    <p-button label="Salvar" type="submit" [disabled]="!form.valid"></p-button>
  </div>
</form>

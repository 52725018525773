import {
  CrudRepository,
  ModelBaseStructure,
  RepositoryFindFielters,
  RepositoryOrderBy,
  Where
} from '@infrab4a/connect';
import { PageableFilter, PageableRequest } from 'src/app/admin-api';
import { NullValue } from 'src/app/models';
import { OperatorMapper } from '../../enums/OperatorMapper';

export class BaseConnectService<T extends ModelBaseStructure> {
  static NULL = { action: 'null' } as any;
  static REMOVE = { action: 'remove' } as any;

  constructor(private repository: CrudRepository<T>) {}

  async paginatedSearch(request: Partial<PageableRequest>) {
    const {
      filters: pageableFilter,
      page,
      pageSize,
      sortBy,
      sortDirection
    } = request;
    const filters = this.getFiltersQuery(pageableFilter);

    const result = await this.repository.find({
      ...(filters && { filters }),
      ...(pageSize && {
        limits: {
          limit: pageSize,
          offset: page * pageSize
        }
      }),
      ...(sortBy && {
        orderBy: { [sortBy]: sortDirection } as RepositoryOrderBy<T>
      }),
      options: {
        enableCount: true
      }
    });
    // let count = result.count;
    // if (result.count === Infinity)
    //   count =
    //     (
    //       await this.repository.find({
    //         ...(filters && { filters }),
    //         fields: [],
    //         limits: {
    //           limit: 10000
    //         }
    //       })
    //     ).data?.length || Infinity;
    return result;
  }

  getFiltersQuery(filters: Array<PageableFilter>) {
    if (!filters || !filters.length) return undefined;
    return filters
      .filter(
        (f) =>
          f.value &&
          f.value !== '' &&
          (!Array.isArray(f.value) || f.value.some((c) => c))
      )
      .reduce((acc, filter) => {
        if (filter.condition === 'between' && this.scheme === 'firebase')
          return acc;
        else if (filter.condition === 'between') {
          acc[filter.field] = [
            {
              value: (filter.value[0] as Date).toISOString(),
              operator: OperatorMapper.gte
            },
            {
              value: (filter.value[1] as Date).toISOString(),
              operator: OperatorMapper.lte
            }
          ];
        } else if (
          filter.value === undefined ||
          filter.value === null ||
          filter.value === '' ||
          (Array.isArray(filter.value) && filter.value[0] == '')
        )
          return acc;
        else if (filter.value === NullValue) {
          acc[filter.field] = {
            value: null,
            operator:
              filter.condition === 'notEquals' ? Where.ISNOTNULL : Where.ISNULL
          };
        } else if (filter.condition === 'contains') {
          acc[filter.field] = {
            value: `${filter.value}`,
            operator: OperatorMapper[filter.condition],
            ignoreCase: true,
            ignoreAccent: true
          };
        } else {
          acc[filter.field] = {
            value:
              filter.fieldType === 'date' &&
              filter.value instanceof Date &&
              this.scheme === 'firebase'
                ? filter.value.getTime()
                : filter.value,
            operator: OperatorMapper[filter.condition]
          };
        }
        return acc;
      }, {} as RepositoryFindFielters<T>);
  }

  get scheme(): 'firebase' | 'hasura' {
    return 'firebase';
  }
}

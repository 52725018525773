<form [formGroup]="form" class="form-b4a" (submit)="save()">
  <span class="p-float-label">
    <p-dropdown
      formControlName="checkoutType"
      inputId="checkoutType"
      [options]="checkoutTypes"
      optionValue="value"
      optionLabel="displayName"
      appendTo="body"
      [autoDisplayFirst]="false"
      (onChange)="checkExclusivities()"
    />
    <label for="checkoutType">Tipo de checkout *</label>
  </span>
  <span class="p-float-label">
    <p-dropdown
      formControlName="shopAvailability"
      inputId="shopAvailability"
      [options]="stores"
      optionValue="value"
      optionLabel="displayName"
      appendTo="body"
      [autoDisplayFirst]="false"
    />
    <label for="shopAvailability">Universo *</label>
  </span>
  <span class="p-float-label">
    <input id="nickname" type="text" pInputText formControlName="nickname" />
    <label for="nickname">Nickname *</label>
  </span>
  <span class="p-float-label">
    <input
      id="minSubTotalValue"
      type="number"
      pInputText
      formControlName="minSubTotalValue"
    />
    <label for="minSubTotalValue">Valor mínimo de subtotal *</label>
  </span>
  <ng-container formGroupName="discount">
    <ng-container
      *ngIf="form.get('discount.subscriber').enabled"
      formGroupName="subscriber"
    >
      <span class="p-float-label">
        <p-dropdown
          formControlName="type"
          inputId="type"
          [options]="discountTypes"
          optionValue="value"
          optionLabel="displayName"
          appendTo="body"
          [autoDisplayFirst]="false"
        />
        <label for="type">Tipo de desconto (Loja - Assinante) *</label>
      </span>
      <span class="p-float-label">
        <p-inputNumber
          *ngIf="form.value.discount.subscriber.type === 1"
          [minFractionDigits]="2"
          [maxFractionDigits]="2"
          prefix="R$"
          [min]="0"
          inputId="value"
          formControlName="value"
        ></p-inputNumber>
        <p-inputNumber
          *ngIf="form.value.discount.subscriber.type === 2"
          [min]="0"
          [max]="100"
          inputId="value"
          formControlName="value"
          [minFractionDigits]="2"
          [maxFractionDigits]="3"
        ></p-inputNumber>
        <p-inputNumber
          *ngIf="form.value.discount.subscriber.type === 3"
          [min]="0"
          [max]="100"
          suffix="%"
          inputId="value"
          formControlName="value"
          [minFractionDigits]="2"
          [maxFractionDigits]="3"
        ></p-inputNumber>
        <label for="value">Valor do desconto (Loja - Assinante) *</label>
      </span>
    </ng-container>
    <ng-container
      formGroupName="non_subscriber"
      *ngIf="form.get('discount.non_subscriber').enabled"
    >
      <span class="p-float-label">
        <p-dropdown
          formControlName="type"
          inputId="type"
          [options]="discountTypes"
          optionValue="value"
          optionLabel="displayName"
          appendTo="body"
          [autoDisplayFirst]="false"
        />
        <label for="type">Tipo de desconto (Loja - Não assinante) *</label>
      </span>
      <span class="p-float-label">
        <p-inputNumber
          *ngIf="form.value.discount.non_subscriber.type === 1"
          [minFractionDigits]="2"
          [maxFractionDigits]="2"
          prefix="R$"
          [min]="0"
          inputId="value"
          formControlName="value"
        ></p-inputNumber>
        <p-inputNumber
          *ngIf="form.value.discount.non_subscriber.type === 2"
          [min]="0"
          [max]="100"
          inputId="value"
          formControlName="value"
          [minFractionDigits]="2"
          [maxFractionDigits]="3"
        ></p-inputNumber>
        <p-inputNumber
          *ngIf="form.value.discount.non_subscriber.type === 3"
          [min]="0"
          [max]="100"
          suffix="%"
          inputId="value"
          formControlName="value"
          [minFractionDigits]="2"
          [maxFractionDigits]="3"
        ></p-inputNumber>
        <label for="value">Valor do desconto (Loja - Não assinante) *</label>
      </span>
    </ng-container>
    <ng-container
      formGroupName="subscription"
      *ngIf="form.get('discount.subscription').enabled"
    >
      <span class="p-float-label">
        <p-dropdown
          formControlName="type"
          inputId="type"
          [options]="discountTypesSubscription"
          optionValue="value"
          optionLabel="displayName"
          appendTo="body"
          [autoDisplayFirst]="false"
        />
        <label for="type">Tipo de desconto (Assinatura) *</label>
      </span>
      <span class="p-float-label">
        <p-inputNumber
          *ngIf="form.value.discount.subscription.type === 1"
          [minFractionDigits]="2"
          [maxFractionDigits]="2"
          prefix="R$"
          [min]="0"
          inputId="value"
          formControlName="value"
        ></p-inputNumber>
        <p-inputNumber
          *ngIf="form.value.discount.subscription.type === 2"
          [min]="0"
          [max]="100"
          inputId="value"
          formControlName="value"
          [minFractionDigits]="2"
          [maxFractionDigits]="3"
        ></p-inputNumber>
        <label for="value">Valor do desconto (Assinatura) *</label>
      </span>
    </ng-container>
  </ng-container>

  <span class="p-float-label">
    <p-dropdown
      formControlName="category"
      inputId="category"
      [options]="couponCategories"
      optionValue="value"
      optionLabel="displayName"
      appendTo="body"
      [autoDisplayFirst]="false"
    />
    <label for="category">Categoria do cupom *</label>
  </span>
  <span class="p-float-label" *ngIf="form.controls.plan.enabled">
    <p-dropdown
      formControlName="plan"
      inputId="plan"
      [options]="plans"
      appendTo="body"
      [autoDisplayFirst]="false"
    />
    <label for="plan">Plano *</label>
  </span>
  <ng-container *ngIf="person">
    <span class="p-float-label">
      <input
        id="personId"
        name="personId"
        type="text"
        pInputText
        formControlName="personId"
      />
      <label for="personId">PersonId</label>
    </span>
    <app-person-card-small
      *ngIf="person"
      [person]="person"
      [influencer]="influencer"
    />
  </ng-container>
  <span class="p-input-checkbox full-w">
    <p-checkbox
      formControlName="active"
      [binary]="true"
      inputId="active"
    ></p-checkbox>
    <label for="active">Ativo</label>
  </span>
  <p-fieldset legend="Disponibilidade">
    <span class="p-float-label">
      <p-calendar
        formControlName="beginAt"
        inputId="beginAt"
        dateFormat="dd/mm/yy"
      ></p-calendar>
      <label for="beginAt">Válido a partir de *</label>
      <small
        class="error"
        *ngIf="
          form.controls['beginAt'].dirty &&
          form.controls['beginAt'].errors &&
          form.controls['beginAt'].errors['invalidDate']
        "
        >{{ form.controls['beginAt'].errors['invalidDate'] }}</small
      >
    </span>
    <span class="p-float-label">
      <p-calendar
        formControlName="expiresIn"
        inputId="expiresIn"
        dateFormat="dd/mm/yy"
      ></p-calendar>
      <label for="expiresIn">Válido até</label>
      <small
        class="error"
        *ngIf="
          form.controls['expiresIn'].dirty &&
          form.controls['expiresIn'].errors &&
          form.controls['expiresIn'].errors['invalidDate']
        "
        >{{ form.controls['expiresIn'].errors['invalidDate'] }}</small
      >
    </span>
    <div class="limit">
      <h3>Exclusividade e tipos de usuário</h3>
      <div class="exclusivities">
        <div *ngFor="let exclusivity of excluisivities" class="radio-button">
          <p-checkbox
            [inputId]="exclusivity.value"
            [value]="exclusivity.value"
            formControlName="exclusivityType"
            name="exclusivityType"
            (onChange)="checkRestrictions()"
          />
          <label [for]="exclusivity.value" class="ml-2">
            {{ exclusivity.label }}
          </label>
        </div>
      </div>
    </div>
    <span
      class="p-float-label"
      *ngIf="form.controls['userExclusiveEmail'].enabled"
    >
      <p-chips
        inputId="userExclusiveEmail"
        formControlName="userExclusiveEmail"
        name="userExclusiveEmail"
        [separator]="chipRegex"
        [addOnTab]="true"
        [addOnBlur]="true"
        [showClear]="true"
        (onAdd)="addEmail($event)"
        (onBlur)="addEmail($event)"
        (onClear)="form.controls['userExclusiveEmail'].setValue([])"
      ></p-chips>
      <label for="userExclusiveEmail">E-mail(s) do(s) usuário(s) *</label>
      <small>Utilize "," ou "Enter" para adicionar</small>
    </span>
    <ng-container formGroupName="useLimits">
      <div class="limit" *ngIf="useLimit" formGroupName="non_subscriber">
        <h3>
          Limites de uso
          {{ nonSubscribersOrAllUsers ? ' (Não assinante)' : '' }}
        </h3>
        <span class="p-input-checkbox">
          <p-checkbox
            formControlName="limitedPerUser"
            [binary]="true"
            inputId="limitedPerUser"
          />
          <label for="limitedPerUser">1 cupom por usuário</label>
        </span>
        <span class="p-input-checkbox">
          <p-checkbox
            formControlName="firstOrder"
            [binary]="true"
            inputId="firstOrder"
          />
          <label for="firstOrder">Primeira compra</label>
        </span>
        <span class="p-input-checkbox">
          <p-checkbox
            formControlName="unlimited"
            [binary]="true"
            inputId="unlimited"
            (onChange)="updateQuantity('non_subscriber', $event)"
          />
          <label for="unlimited">Ilimitado</label>
        </span>
        <span
          class="p-float-label"
          *ngIf="form.get('useLimits.non_subscriber.total').enabled"
        >
          <p-inputNumber id="total" formControlName="total" [min]="0" />
          <label for="total"> Quantidade total disponível * </label>
        </span>
      </div>
      <div class="limit" *ngIf="useLimitSubscribers" formGroupName="subscriber">
        <h3>Limites de uso (Assinante)</h3>
        <span class="p-input-checkbox">
          <p-checkbox
            formControlName="limitedPerUser"
            [binary]="true"
            inputId="limitedPerUser"
          />
          <label for="limitedPerUser">1 cupom por usuário</label>
        </span>
        <span class="p-input-checkbox">
          <p-checkbox
            formControlName="firstOrder"
            [binary]="true"
            inputId="firstOrder"
          />
          <label for="firstOrder">Primeira compra</label>
        </span>
        <span class="p-input-checkbox">
          <p-checkbox
            formControlName="unlimited"
            [binary]="true"
            inputId="unlimited"
            (onChange)="updateQuantity('subscriber', $event)"
          ></p-checkbox>
          <label for="unlimited">Ilimitado</label>
        </span>
        <span
          class="p-float-label"
          *ngIf="form.get('useLimits.subscriber.total').enabled"
        >
          <p-inputNumber id="total" formControlName="total" [min]="0" />
          <label for="total"> Quantidade total disponível * </label>
        </span>
      </div>
      <div
        class="limit"
        *ngIf="useLimitSubscription"
        formGroupName="subscription"
      >
        <h3>Limites de uso (Assinatura)</h3>
        <span class="p-input-checkbox">
          <p-checkbox
            formControlName="limitedPerUser"
            [binary]="true"
            inputId="limitedPerUser"
          />
          <label for="limitedPerUser">1 cupom por usuário</label>
        </span>
        <span class="p-input-checkbox">
          <p-checkbox
            formControlName="firstOrder"
            [binary]="true"
            inputId="firstOrder"
          />
          <label for="firstOrder">Primeira compra</label>
        </span>
        <span class="p-input-checkbox">
          <p-checkbox
            formControlName="unlimited"
            [binary]="true"
            inputId="unlimited"
            (onChange)="updateQuantity('subscription', $event)"
          ></p-checkbox>
          <label for="unlimited">Ilimitado</label>
        </span>
        <span
          class="p-float-label"
          *ngIf="form.get('useLimits.subscription.total').enabled"
        >
          <p-inputNumber id="total" formControlName="total" [min]="0" />
          <label for="total"> Quantidade total disponível * </label>
        </span>
      </div>
    </ng-container>
  </p-fieldset>
  <div class="buttons">
    <p-button [disabled]="!form.valid" type="submit" label="Salvar"></p-button>
  </div>
</form>
<p-toast />

import { Component, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { lastValueFrom, map } from 'rxjs';
import {
  SubscriptionTypeControllerService,
  SubscriptionTypeTaxControllerService,
  SubscriptionTypeTaxDto
} from 'src/app/admin-api';
import { SubscriptionAndSubscriptionTypeDto } from 'src/app/admin-api/model/subscriptionAndSubscriptionTypeDto';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-tax-price',
  templateUrl: './tax-price.component.html',
  styleUrl: './tax-price.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class TaxPriceComponent implements OnInit {
  async ngOnInit() {
    await this.findAllTaxesAndSubscriptionTypePrices();
    this.tableData = this.extractSubscriptionTypesTaxes();
    this.subscriptionTypesTableData = this.extractSubscriptionTypePrices();

    this.tableData.sort(
      (c1, c2) =>
        FormUtil.subscriptionTypeOrder[c1.label.toLowerCase()] -
        FormUtil.subscriptionTypeOrder[c2.label.toLowerCase()]
    );

    this.subscriptionTypesTableData.sort(
      (c1, c2) =>
        FormUtil.subscriptionTypeOrder[c1.label.toLowerCase()] -
        FormUtil.subscriptionTypeOrder[c2.label.toLowerCase()]
    );

    this.subscriptions.sort(
      (c1, c2) =>
        FormUtil.subscriptionOrder[c1.subscriptionName.toLowerCase()] -
        FormUtil.subscriptionOrder[c2.subscriptionName.toLowerCase()]
    );

    this.subscriptionAndSubscriptionTypes.sort(
      (c1, c2) =>
        FormUtil.subscriptionOrder[c1.subscriptionName.toLowerCase()] -
        FormUtil.subscriptionOrder[c2.subscriptionName.toLowerCase()]
    );
  }

  constructor(private title: Title) {
    this.title.setTitle('Valores fiscais');
  }

  subscriptions: SubscriptionTypeTaxDto[];

  // @ViewChild('tableData')
  tableData: { label: string; type: string }[] = [];

  subscriptionTypesTableData: { label: string; type: string }[] = [];

  subscriptionAndSubscriptionTypes: SubscriptionAndSubscriptionTypeDto[];

  previousPrice: number | null = null;

  taxControllerService = inject(SubscriptionTypeTaxControllerService);

  subscriptionTypeControllerService = inject(SubscriptionTypeControllerService);

  private extractSubscriptionTypesTaxes(): { label: string; type: string }[] {
    const uniqueTypes = new Set<string>();

    this.subscriptions.forEach((subscription) => {
      // Adiciona todos os tipos de ebookPrices
      subscription.ebookPrices?.forEach((price) => {
        if (price.subscriptionTypeName) {
          uniqueTypes.add(price.subscriptionTypeName);
        }
      });

      // Adiciona todos os tipos de servicePrices
      subscription.servicePrices?.forEach((price) => {
        if (price.subscriptionTypeName) {
          uniqueTypes.add(price.subscriptionTypeName);
        }
      });
    });

    return Array.from(uniqueTypes).map((type) => ({
      label: type,
      type: type
    }));
  }

  private extractSubscriptionTypePrices(): { label: string; type: string }[] {
    const uniqueTypes = new Set<string>();

    this.subscriptionAndSubscriptionTypes.forEach((subscription) => {
      subscription.subscriptionTypes?.forEach((subType) => {
        if (subType.subscriptionTypeName) {
          uniqueTypes.add(subType.subscriptionTypeName);
        }
      });
    });

    return Array.from(uniqueTypes).map((type) => ({
      label: type,
      type: type
    }));
  }

  getSubscriptionTypePrice(
    subscription: SubscriptionAndSubscriptionTypeDto,
    subscriptionType: string
  ): string {
    const subType = subscription.subscriptionTypes.find(
      (p) => p.subscriptionTypeName === subscriptionType
    );

    return subType?.monthlyPrice !== undefined && subType?.monthlyPrice !== null
      ? `${subType.monthlyPrice.toFixed(2).replace('.', ',')}`
      : '-';
  }

  getPercentageValue(subscriptionTax: any): number {
    const matchedSubscriptionType = this.subscriptionAndSubscriptionTypes
      .flatMap((subscription) => subscription.subscriptionTypes ?? [])
      .find(
        (subType) =>
          subType.subscriptionTypeId === subscriptionTax.subscriptionTypeId
      );

    if (!subscriptionTax.price) {
      return;
    }

    const percentageValue =
      (subscriptionTax.price / matchedSubscriptionType.monthlyPrice) * 100;

    return percentageValue;
  }

  async onPriceChange(
    subscriptionTax: any,
    subscriptionId: number,
    priceType: 'ebook' | 'service'
  ) {
    if (this.previousPrice === subscriptionTax?.price) {
      return;
    }

    LoaderService.showLoader();

    try {
      if (this.isValidTaxIdButZeroPrice(subscriptionTax, priceType)) {
        priceType === 'ebook'
          ? await lastValueFrom(
              this.taxControllerService.deleteEbook(
                subscriptionTax.subscriptionTypeEbookId
              )
            )
          : await lastValueFrom(
              this.taxControllerService.deleteService(
                subscriptionTax.subscriptionTypeServiceId
              )
            );
      } else {
        const taxPriceDto = {
          subscriptionTypeId: subscriptionTax.subscriptionTypeId,
          taxPrice: subscriptionTax.price,
          taxType: priceType,
          subscriptionId: subscriptionId,
          taxSubscriptionTypeEbookId:
            subscriptionTax.subscriptionTypeEbookId !== null
              ? subscriptionTax.subscriptionTypeEbookId
              : null,
          taxSubscriptionTypeServiceId:
            subscriptionTax.subscriptionTypeServiceId !== null
              ? subscriptionTax.subscriptionTypeServiceId
              : null,
          priceType: priceType
        };

        await lastValueFrom(
          this.taxControllerService.updateTaxPrice(taxPriceDto)
        );
      }

      // await this.tableData?.refresh(true);
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async findAllTaxesAndSubscriptionTypePrices() {
    try {
      LoaderService.showLoader();
      this.subscriptions = await lastValueFrom(
        this.taxControllerService.listAllTaxes().pipe(
          map((data) =>
            data.result.map((res) => ({
              ...res,
              ebookPrices: res.ebookPrices.map((eb) => ({
                ...eb,
                subscriptionTypeName: eb.subscriptionTypeName.replace(
                  'Anual.',
                  'Anual/Mês'
                )
              })),
              servicePrices: res.servicePrices.map((sp) => ({
                ...sp,
                subscriptionTypeName: sp.subscriptionTypeName.replace(
                  'Anual.',
                  'Anual/Mês'
                )
              }))
            }))
          )
        )
      );

      this.subscriptionAndSubscriptionTypes = await lastValueFrom(
        this.subscriptionTypeControllerService
          .findAllSubscriptionByTypes()
          .pipe(
            map((data) =>
              data.result.map((subscription) => ({
                ...subscription,
                subscriptionTypes: subscription.subscriptionTypes?.map(
                  (subType) => ({
                    ...subType,
                    subscriptionTypeName: subType.subscriptionTypeName
                      ? subType.subscriptionTypeName.replace(
                          'Anual.',
                          'Anual/Mês'
                        )
                      : subType.subscriptionTypeName
                  })
                )
              }))
            )
          )
      );

      this.tableData.forEach((subs) => {
        this.subscriptions.forEach((s) => {
          if (
            !s.servicePrices.some(
              (sp) => sp.subscriptionTypeName === subs.label
            )
          ) {
            s.servicePrices.push({ subscriptionTypeName: subs.label });
          }

          if (
            !s.ebookPrices.some((sp) => sp.subscriptionTypeName === subs.label)
          ) {
            s.ebookPrices.push({ subscriptionTypeName: subs.label });
          }
        });
      });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  private isValidTaxIdButZeroPrice(
    subscriptionTax: any,
    priceType: string
  ): boolean {
    if (
      priceType === 'ebook' &&
      subscriptionTax.subscriptionTypeEbookId &&
      subscriptionTax.price === 0
    ) {
      return true;
    }

    if (
      priceType === 'service' &&
      subscriptionTax.subscriptionTypeServiceId &&
      subscriptionTax.price === 0
    ) {
      return true;
    }
  }

  async preventKeys(
    e: KeyboardEvent,
    subscriptionTax: any,
    subscriptionId: number,
    priceType: 'ebook' | 'service'
  ) {
    if (
      e.key === 'ArrowDown' ||
      e.key == 'Down' ||
      e.key === 'ArrowUp' ||
      e.key == 'Up' ||
      e.key === 'ArrowLeft' ||
      e.key == 'Left' ||
      e.key === 'ArrowRight' ||
      e.key == 'Right'
    ) {
      e.stopPropagation();
    } else if (e.key === 'Escape') {
      subscriptionTax.price = this.previousPrice;
    } else if (e.key === 'Enter') {
      await this.onPriceChange(subscriptionTax, subscriptionId, priceType);
    }
  }
}

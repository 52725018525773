import { Component, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import {
  AcquisitionAndReactivationReprocess,
  ItOpsControllerService
} from 'src/app/admin-api';
import { TableColumn, TableComponent } from 'src/app/components/table';
import { SubscriberProblemGroupEnum } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-acquisition-and-reactivation-reprocess',
  templateUrl: './acquisition-and-reactivation-reprocess.component.html',
  styleUrls: ['./acquisition-and-reactivation-reprocess.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class AcquisitionAndReactivationReprocessComponent {
  @ViewChild(TableComponent) table: TableComponent;

  columns: Array<TableColumn> = [
    new TableColumn('', '', false, 'checkbox'),
    new TableColumn(
      'Nome',
      'nome',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Email',
      'email',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Phone',
      'phone',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'PersonId',
      'personId',
      true,
      'number',
      '/users/person/',
      'personId'
    ),
    new TableColumn(
      'SubscriberId',
      'subscriberId',
      true,
      'number',
      '/users/subscribers/',
      'subscriberId'
    ),
    new TableColumn(
      'Name Type',
      'nameType',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Data de Criação',
      'dateCreated',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    ),
    new TableColumn(
      'TID',
      'tid',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Total',
      'amount',
      true,
      'currency',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Mensagem de Autorização',
      'authorizationMessage',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Status da Transação',
      'transactionStatusId',
      true,
      'number',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Nome da Assinatura',
      'subscriptionName',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Plano',
      'plano',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'CreditCardPaymentId',
      'creditCardPaymentId',
      true,
      'number',
      undefined,
      undefined,
      true,
      'gte'
    )
  ];

  constructor(
    public itOpsService: ItOpsControllerService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  markAsResolved(elements: Array<AcquisitionAndReactivationReprocess>): void {
    this.confirmationService.confirm({
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      rejectButtonStyleClass: 'p-button-danger',
      message: 'Deseja enviar o relatório?',
      header: 'Corrigir',
      accept: async () => {
        LoaderService.showLoader();
        try {
          await lastValueFrom(
            this.itOpsService.markAsResolved(
              SubscriberProblemGroupEnum.AcquisitionAndReactivationReprocess,
              elements
            )
          );
          await this.table.refresh(true);
          this.messageService.add({
            summary: 'Sucesso',
            detail: elements.length + ' marcado(s) como resolvido(s)',
            severity: 'success'
          });
          LoaderService.showLoader(false);
        } catch (error) {
          LoaderService.showLoader(false);
          AppDialogService.showErrorDialog(error);
        }
      }
    });
  }
}

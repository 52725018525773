<form class="form-b4a" [formGroup]="form" (submit)="submit()">
  <span class="p-float-label">
    <input
      type="text"
      pInputText
      formControlName="name"
      name="name"
      id="name"
    />
    <label for="name">Nome *</label>
  </span>
  <span class="p-float-label">
    <input
      type="text"
      pInputText
      formControlName="link"
      name="link"
      id="link"
    />
    <label for="link">Link</label>
  </span>
  <span class="full-w input-switch">
    <label for="active">Inativo</label>
    <p-inputSwitch formControlName="active" inputId="active" />
    <label for="active">Ativo</label>
  </span>
  <span class="p-input-editor">
    <span class="label-option">
      <label for="description">Descrição </label>
      <span class="p-input-checbox">
        <p-checkbox
          formControlName="html"
          [binary]="true"
          inputId="html"
          name="html"
          (onChange)="format()"
        />
        <label for="html">Ver código fonte</label>
      </span>
    </span>
    <p-editor
      formControlName="description"
      id="description"
      (keyup)="cdRef.detectChanges()"
      (click)="cdRef.detectChanges()"
      [hidden]="form.value.html"
      [modules]="{
        toolbar: { handlers, container: toolbarOptions },
        blotFormatter2: {
          resize: {
            handleClassName: 'dimensioned'
          }
        }
      }"
    >
      <ng-template pTemplate="header" />
    </p-editor>
    <textarea
      name="description"
      id="description"
      formControlName="description"
      *ngIf="form.value.html"
      rows="50"
    >
    </textarea>
    <small class="hint" [class.danger]="!form.controls.description.valid">
      {{ form.value.description?.trim().length || 0 | number : '1.0-0' }}/4.000
    </small>
  </span>
  <span class="buttons">
    <p-button label="Salvar" [disabled]="!form.valid" type="submit" />
  </span>
</form>
<input type="file" hidden id="inputFile" (change)="handleFile($event)" />

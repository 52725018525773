export const reviewsColumnImport = {
  createdAt: '',
  shop: '',
  sku: '',
  author: '',
  email: '',
  location: '',
  rate: '',
  review: '',
  title: '',
  status: ''
} as const;

export type ReviewImportData = {
  [key in keyof typeof reviewsColumnImport]: string;
};

import { Component, inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  HomeSection,
  ShopBanner,
  ShopSection,
  ShopSettings
} from '@infrab4a/connect';
import { MessageService } from 'primeng/api';
import { ImageUploadComponent } from 'src/app/components/image-upload/image-upload.component';
import {
  CarouselSection,
  isBanner,
  isCarousel,
  ShopSettingsService
} from 'src/app/connect-api/api/shop-settings/shop-settings.service';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { GlamHomeComponent } from '../glam-home.component';

@Component({
  selector: 'app-glam-home-banner-form',
  templateUrl: './glam-home-banner-form.component.html',
  styleUrl: './glam-home-banner-form.component.scss'
})
export class GlamHomeBannerFormComponent
  extends ImageUploadComponent<ShopBanner>
  implements OnInit
{
  @Input() type: 'carousel' | undefined;
  @Input({ required: true }) id: string;
  private shopSettingsService = inject(ShopSettingsService);
  private messageService = inject(MessageService);

  banner: ShopBanner;
  settings: Partial<ShopSettings> & { sections?: ShopSection[] };
  carousel: CarouselSection;
  override form = new FormGroup({
    altText: new FormControl<string>(null, Validators.required),
    countdown: new FormControl<string>(null),
    autoDelete: new FormControl<boolean>(false),
    autoPublish: new FormControl<boolean>(false),
    deleteDate: new FormControl<Date>({ value: null, disabled: true }, [
      Validators.required
    ]),
    desktopImage: new FormControl<string>(null, Validators.required),
    exhibitionRule: new FormControl<'actives' | 'notActives' | 'all'>('all', [
      Validators.required
    ]),
    link: new FormControl<string>(null),
    mobileImage: new FormControl<string>(null, Validators.required),
    publishDate: new FormControl<Date>({ value: null, disabled: true }, [
      Validators.required
    ]),
    published: new FormControl<boolean>(false),
    id: new FormControl<string>(
      this.shopSettingsService.createId(),
      Validators.required
    ),
    type: new FormControl<'banner'>(
      { value: 'banner', disabled: true },
      Validators.required
    ),
    title: new FormControl<string>(null, Validators.required),
    fixed: new FormControl(false),
    editable: new FormControl(true)
  });
  exhibitionRules = [
    { label: 'Assinantes já comprou', value: 'actives' },
    { label: 'Não assinantes', value: 'notActives' },
    { label: 'Assinante nunca comprou', value: 'noOrders' },
    { label: 'Todos', value: 'all' }
  ];

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader();
    try {
      this.settings = await this.shopSettingsService.getById(
        GlamHomeComponent.documentId
      );
      if (this.type)
        this.carousel = this.settings.sections.find((s) =>
          isCarousel(s)
        ) as CarouselSection;
      else this.form.controls.type.enable();
      if (this.id && this.id !== 'new') {
        this.banner =
          this.carousel?.banners?.find((b) => b.id === this.id) ||
          this.settings.sections.find((s) => isBanner(s) && s.id === this.id);
        if (!this.banner) throw { message: 'Banner não encontrado' };
        this.form.patchValue({
          ...this.banner,
          deleteDate: this.banner.deleteDate
            ? new Date(this.banner.deleteDate)
            : null,
          publishDate: this.banner.publishDate
            ? new Date(this.banner.publishDate)
            : null,
          editable: true,
          type: 'banner'
        });
        this.form.controls.id.enable();
        this.checkAutomaticPublish();
      }
      this.checkBannerLimit();
    } catch (error) {
      AppDialogService.showErrorDialog(error, true);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  checkBannerLimit(): void {
    if (
      !this.carousel &&
      this.settings?.sections?.filter((s) => isBanner(s)).length >= 5
    )
      throw { message: 'Limite de banners atingido (Máximo:5)' };
  }

  checkAutomaticPublish(): void {
    if (this.form.value.autoDelete) this.form.controls.deleteDate.enable();
    else this.form.controls.deleteDate.disable();
    if (this.form.value.autoPublish) this.form.controls.publishDate.enable();
    else this.form.controls.publishDate.disable();
    if (this.form.value.autoDelete || this.form.value.autoPublish)
      this.form.controls.published.disable();
    else this.form.controls.published.enable();
  }

  async submit(): Promise<void> {
    if (this.form.valid) {
      LoaderService.showLoader();
      try {
        const banner = this.form.value;
        if (!banner.autoDelete) banner.deleteDate = null;
        if (!banner.autoPublish) banner.publishDate = null;
        if (banner.autoDelete || banner.autoPublish) banner.published = false;
        if (banner.publishDate) {
          banner.publishDate.setSeconds(0);
          banner.publishDate.setMilliseconds(0);
          banner.published =
            banner.publishDate.getTime() <= new Date().getTime();
        }
        if (banner.deleteDate) {
          banner.deleteDate.setSeconds(0);
          banner.deleteDate.setMilliseconds(0);
          banner.published =
            (banner.published || !banner.autoPublish) &&
            banner.deleteDate.getTime() > new Date().getTime();
        }
        this.updateSettings(banner as ShopBanner);
        this.checkActiveBanner();
        await this.shopSettingsService.update(this.settings);
        const detail = `Banner ${
          this.banner ? 'atualizado' : 'criado'
        } com sucesso`;
        this.messageService.add({
          summary: 'Sucesso',
          severity: 'success',
          detail
        });
        await this.afterSubmit();
        if (this.carousel)
          this.router.navigate(['/configuracoes/glam/home/carousel']);
        else this.router.navigate(['/configuracoes/glam/home']);
      } catch (error) {
        AppDialogService.showErrorDialog(error);
      } finally {
        LoaderService.showLoader(false);
      }
    }
  }

  updateSettings(banner: ShopBanner): void {
    let ref: ShopBanner[];
    if (this.carousel) {
      ref = (
        this.settings.sections.find((s) => isCarousel(s)) as CarouselSection
      ).banners;
    } else {
      ref = this.settings.sections;
    }
    let bannerRef: ShopBanner;
    if (this.banner) {
      banner.updatedAt = new Date();
      bannerRef = ref.find((s) => s.id === banner.id);
      Object.keys(this.form.controls).forEach((key) => {
        bannerRef[key] = this.form.value[key];
      });
    } else {
      banner.createdAt = new Date();
      this.checkBannerLimit();
      let newsletter: any;
      if (!this.carousel) {
        newsletter = ref.pop();
      }
      ref.push({
        ...banner
      });
      if (newsletter) ref.push(newsletter);
    }
  }

  checkActiveBanner(): void {
    if (this.carousel) {
      const carousel = (this.settings.sections as HomeSection).find((s) =>
        isCarousel(s)
      );
      if (carousel.banners?.every((b) => !b.published))
        throw { message: 'Pelo menos 1 banner deve permanecer ativo' };
    }
  }

  override get filePath(): string {
    return '/glam/home/banner/';
  }
  override get model(): ShopBanner {
    return this.banner;
  }
  override get modelId(): string | number {
    return this.banner?.id;
  }

  get isRouterLink(): boolean {
    return (
      this.form.value.link &&
      !this.form.value.link.startsWith('http') &&
      !this.form.value.link.startsWith('/')
    );
  }

  get desktopHeight(): number {
    return this.carousel ? 820 : 480;
  }

  get desktopWidth(): number {
    return 2432;
  }

  get mobileHeight(): number {
    return this.carousel ? 900 : 308;
  }

  get mobileWidth(): number {
    return 750;
  }
}

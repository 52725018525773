<div class="feedback-crossing">
  <h3>Cruzamento de formulários</h3>
  <form (submit)="submit()" [formGroup]="form" class="form-b4a">
    <p-fieldset legend="Filtros">
      <div formArrayName="filters" class="form-filters">
        <ng-container
          *ngFor="
            let filter of form.controls['filters'].controls;
            let i = index
          "
        >
          <p-button
            icon="pi pi-minus"
            severity="danger"
            [rounded]="true"
            size="small"
            (onClick)="removeFilter(i)"
            type="button"
            *ngIf="i > 0"
          ></p-button>
          <div [formGroupName]="i" class="form-filter">
            <span class="p-float-label">
              <p-autoComplete
                [suggestions]="formsAvailable"
                [lazy]="true"
                formControlName="form"
                (completeMethod)="findFeedbackForms($event.query)"
                [inputId]="'formId' + i"
                [name]="'formId' + i"
                (onSelect)="formChange($event.value, i)"
                placeholder="Pesquise pelo nome"
                filterBy="formTitle"
                optionLabel="formTitle"
                appendTo="body"
                (onClear)="formChange(null, i)"
                [showClear]="true"
              >
                <ng-template let-form pTemplate="item">
                  {{ form.formTitle }}
                </ng-template>
              </p-autoComplete>
              <label [for]="'formId' + i">Formulário</label>
            </span>
            <span class="p-float-label">
              <p-dropdown
                [options]="[]"
                formControlName="questionId"
                [inputId]="'questionId' + i"
                *ngIf="!filter.value.form"
              ></p-dropdown>
              <p-treeSelect
                formControlName="question"
                [options]="questionItems[filter.value.form?.formId]"
                *ngIf="filter.value.form?.formId > 0"
                [name]="'questionId' + i"
                [inputId]="'questionId' + i"
                (onNodeSelect)="questionChange(i, $event)"
                [filter]="true"
                appendTo="body"
              >
              </p-treeSelect>
              <p-dropdown
                formControlName="question"
                [autoDisplayFirst]="false"
                [options]="beautyProfile"
                *ngIf="filter.value.form?.formId === 0"
                optionLabel="name"
                [name]="'questionId' + i"
                [inputId]="'questionId' + i"
                [filter]="true"
                [autoDisplayFirst]="false"
                appendTo="body"
                (onChange)="attributeChange(i, $event.value)"
              ></p-dropdown>
              <label [for]="'questionId' + i">Pergunta</label>
            </span>
            <span class="p-float-label">
              <p-dropdown
                [options]="[]"
                [inputId]="'optionIds' + i"
                [name]="'optionIds' + i"
                formControlName="optionIds"
                *ngIf="!filter.value.form || !filter.value.questionId"
              ></p-dropdown>
              <p-multiSelect
                [options]="question(i)?.options"
                *ngIf="filter.value.form?.formId > 0 && filter.value.questionId"
                formControlName="optionIds"
                optionLabel="optionText"
                optionValue="optionId"
                [filter]="true"
                filterBy="optionText"
                [name]="'optionIds' + i"
                [inputId]="'optionIds' + i"
                appendTo="body"
              ></p-multiSelect>
              <p-multiSelect
                [options]="question(i)?.options"
                *ngIf="
                  filter.value.form?.formId === 0 && filter.value.questionId
                "
                formControlName="optionIds"
                optionLabel="name"
                optionValue="id"
                [filter]="true"
                filterBy="name"
                [name]="'optionIds' + i"
                [inputId]="'optionIds' + i"
                appendTo="body"
              ></p-multiSelect>
              <label [for]="'optionIds' + i">Alternativas</label>
            </span>
          </div>
        </ng-container>
        <p-button
          icon="pi pi-plus"
          severity="success"
          [rounded]="true"
          size="small"
          *ngIf="form.controls['filters'].valid"
          (onClick)="addFilter()"
          type="button"
        ></p-button>
      </div>
    </p-fieldset>
    <p-fieldset legend="Perguntas">
      <div class="form-crossing">
        <div class="form-question">
          <span class="p-float-label">
            <p-autoComplete
              [suggestions]="forms"
              [lazy]="true"
              formControlName="form1"
              (completeMethod)="findFeedbackForms($event.query)"
              inputId="form1"
              name="form1"
              (onSelect)="formChange($event.value)"
              placeholder="Pesquise pelo nome"
              filterBy="formTitle"
              optionLabel="formTitle"
              appendTo="body"
              (onClear)="clearForm1()"
              [showClear]="true"
            >
              <ng-template let-form pTemplate="item">
                {{ form.formTitle }}
              </ng-template>
            </p-autoComplete>
            <label for="form1">Formulário</label>
          </span>
          <span class="p-float-label" *ngIf="form.value.form1?.formId > 0">
            <p-treeSelect
              formControlName="question1"
              [options]="questionItems[form.value.form1?.formId]"
              name="question1"
              inputId="question1"
              (onNodeSelect)="
                crossingQuestionChange(
                  form.value.form1.formId,
                  $event,
                  'question1'
                )
              "
              [filter]="true"
              appendTo="body"
            >
            </p-treeSelect>
            <label for="question1">Pergunta</label>
          </span>
        </div>
        <b>X</b>
        <div class="form-question">
          <span class="p-float-label">
            <p-autoComplete
              [suggestions]="forms"
              [lazy]="true"
              formControlName="form2"
              (completeMethod)="findFeedbackForms($event.query)"
              inputId="form2"
              name="form2"
              (onSelect)="formChange($event.value)"
              placeholder="Pesquise pelo nome"
              filterBy="formTitle"
              optionLabel="formTitle"
              appendTo="body"
              (onClear)="clearForm2()"
              [showClear]="true"
            >
              <ng-template let-form pTemplate="item">
                {{ form.formTitle }}
              </ng-template>
            </p-autoComplete>
            <label for="form2">Formulário</label>
          </span>
          <span class="p-float-label" *ngIf="form.value.form2?.formId > 0">
            <p-treeSelect
              formControlName="question2"
              [options]="questionItems[form.value.form2?.formId]"
              name="question2"
              inputId="question2"
              (onNodeSelect)="
                crossingQuestionChange(
                  form.value.form2.formId,
                  $event,
                  'question2'
                )
              "
              [filter]="true"
              appendTo="body"
            >
            </p-treeSelect>
            <label for="question2">Pergunta</label>
            <small
              class="error"
              *ngIf="form.controls['questionId2'].errors?.sameQuestion"
              >Não é possível cruzar a mesma pergunta</small
            >
          </span>
        </div>
      </div>
      <div class="buttons">
        <p-button label="Enviar" type="submit" [disabled]="!valid"></p-button>
      </div>
    </p-fieldset>
  </form>
  <br />
  <app-table
    [lazy]="false"
    [paginator]="false"
    [addButton]="false"
    [tableTitle]="crossingName"
    [cols]="cols"
    [elements]="rows"
    *ngIf="ready"
    sortField="optionId"
    [sortOrder]="-1"
    [preHeaderTemplate]="preHeaderTemplate"
    colsExportMode="visible"
  >
    <ng-template #preHeaderTemplate>
      <tr>
        <th colspan="3">{{ question1.title }}</th>
        <th [attr.colspan]="question2.options.length * 2">
          X {{ question2.title }}
        </th>
      </tr>
    </ng-template>
  </app-table>
</div>

import { Clipboard } from '@angular/cdk/clipboard';
import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService, PrimeIcons } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  IntegrationTokenControllerService,
  IntegrationTokenDomainView,
  IntegrationTokenView
} from 'src/app/admin-api';
import {
  TableActionButton,
  TableActionEvent,
  TableColumn
} from 'src/app/components/table';
import { getAllRoles, getRole, Role, roleAsObject } from 'src/app/models';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-integration-token-form',
  templateUrl: './integration-token-form.component.html',
  styleUrl: './integration-token-form.component.scss',
  providers: [MessageService]
})
export class IntegrationTokenFormComponent implements OnInit {
  private tokenService = inject(IntegrationTokenControllerService);
  private config = inject(DynamicDialogConfig);
  private messageService = inject(MessageService);
  private ref = inject(DynamicDialogRef);
  private clipboard = inject(Clipboard);

  token: IntegrationTokenView = this.config.data.token;
  expression =
    /(\b[.\w-]+:\/{2,3})(?!\.)(?!.*\.{2})(?!-)(?!.*-\.)(?!.*@\.)([\w\-@^=%&:;~+.]+(?<!-))(\/+[\w\-@^=%&:;/~+.]+(?<!\.))?\??([\w=\-&@$!|~+_]+)*#?([\w=\-&@$!|~+_]+)*/gim;
  // /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{3,}(\.[a-zA-Z0-9]{3,})(\.[a-zA-Z0-9]{3,})?/gi;
  form = new FormGroup({
    tokenId: new FormControl<number>(
      {
        value: this.token?.tokenId,
        disabled: !this.token
      },
      Validators.required
    ),
    name: new FormControl<string>(this.token?.name, Validators.required),
    token: new FormControl<string>(this.token?.token)
  });
  tokenDomains: IntegrationTokenDomainView[];

  domain = new FormControl<string>(null, [
    Validators.required,
    Validators.pattern(this.expression)
  ]);

  roles = new FormControl<number[]>(
    this.token?.roleEnums?.split(',')?.map((r) => getRole(r).value) || [
      roleAsObject(Role.Integration).value
    ],
    [Validators.required, Validators.minLength(1)]
  );

  roleOptions = getAllRoles()
    .filter((r) => r.value)
    .filter(
      (r) =>
        TokenStorageService.userRolesList.includes(
          roleAsObject(Role.Full_Administrator).enumValue
        ) || TokenStorageService.userRolesList.includes(r.enumValue)
    );

  cols = [
    new TableColumn.Builder()
      .setHeader('Domínio')
      .setField('url')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('Data criação')
      .setField('dateCreated')
      .setCondition('between')
      .setType('date')
      .build(),
    new TableColumn.Builder()
      .setHeader('Usuário')
      .setField('username')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('Requisições')
      .setField('requests')
      .setCondition('gte')
      .build(),
    new TableColumn.Builder()
      .setHeader('Ação')
      .setField(' ')
      .setType('button')
      .build()
  ];

  actionButtons = [
    new TableActionButton.Builder()
      .setAction('delete')
      .setIcon(PrimeIcons.TRASH)
      .setSeverity('danger')
      .setRounded(true)
      .setText(true)
      .build()
  ];

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader();
    await this.findDomains();
    LoaderService.showLoader(false);
  }

  async deleteDomain(event: TableActionEvent): Promise<void> {
    try {
      LoaderService.showLoader();
      const detail = await lastValueFrom(
        this.tokenService
          .deleteTokenDomain(
            (event.item as IntegrationTokenDomainView).tokenDomainId
          )
          .pipe(map((data) => data.result))
      );
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso',
        detail
      });
      await this.findDomains();
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async addDomain() {
    if (this.domain.valid) {
      try {
        LoaderService.showLoader();
        await lastValueFrom(
          this.tokenService
            .addTokenDomain(this.token.tokenId, this.domain.value)
            .pipe(map((data) => data.result))
        );
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'Domínio adicionado com sucesso'
        });
        await this.findDomains();
        this.domain.reset();
      } catch (error) {
        AppDialogService.showErrorDialog(error);
      } finally {
        LoaderService.showLoader(false);
      }
    }
  }

  async findDomains() {
    try {
      if (this.token)
        this.tokenDomains =
          (await lastValueFrom(
            this.tokenService
              .getTokenDomains(this.token.tokenId)
              .pipe(map((data) => data.result))
          )) || [];
    } catch (error) {
      this.tokenDomains = [];
      AppDialogService.showErrorDialog(error);
    }
  }

  async submit() {
    if (this.form.valid) {
      try {
        LoaderService.showLoader();
        if (this.token) {
          await Promise.all([
            lastValueFrom(
              this.tokenService.updateTokenName(
                this.form.value.tokenId,
                this.form.value.name
              )
            ),
            lastValueFrom(
              this.tokenService.updateTokenRoles(
                this.form.value.tokenId,
                this.roles.value
              )
            )
          ]);
          this.messageService.add({
            severity: 'success',
            summary: 'Sucesso',
            detail: 'Token atualizado com sucesso.'
          });
          this.ref.close();
        } else {
          this.token = await lastValueFrom(
            this.tokenService
              .createToken(this.form.value.name)
              .pipe(map((data) => data.result))
          );
          this.form.patchValue(this.token);
          this.form.controls.tokenId.enable();
          this.tokenDomains = [];
          this.messageService.add({
            severity: 'success',
            summary: 'Sucesso',
            detail: 'Token adicionado com sucesso.'
          });
        }
      } catch (error) {
        AppDialogService.showErrorDialog(error);
      } finally {
        LoaderService.showLoader(false);
      }
    }
  }

  copyToClipboard(text: string): void {
    this.clipboard.copy(text);
    this.messageService.add({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Token copiado para área de trasnferência'
    });
  }
}

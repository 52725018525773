/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SubscriberRefundSummary {
  subscriberBillingId?: number;
  removeEdition?: number;
  subscriberId?: number;
  dateCreated?: Date;
  message?: string;
  firstName?: string;
  lastName?: string;
  amount?: number;
  editionId?: number;
  hasRefunded?: number;
  lastSubscriberBillingStatus?: number;
  hasBeenApproved?: number;
  dateRefunded?: Date;
  partialRefund?: number;
  subscriberBillingStatus?: string;
  theme?: string;
  boxId?: string;
  personId?: number;
  postalCode?: string;
  street?: string;
  streetNumber?: string;
  streetExtension?: string;
  neighborhood?: string;
  city?: string;
  state?: string;
  username?: string;
}

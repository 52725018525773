import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { ListboxModule } from 'primeng/listbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { ToastModule } from 'primeng/toast';
import { CouponFormComponent } from './coupon-form.component';
import { CouponProductVariantFormComponent } from './coupon-product-variant-form/coupon-product-variant-form.component';

@NgModule({
  declarations: [CouponFormComponent, CouponProductVariantFormComponent],
  imports: [
    CommonModule,
    ToastModule,
    InputTextModule,
    InputNumberModule,
    DropdownModule,
    CheckboxModule,
    InputSwitchModule,
    ReactiveFormsModule,
    ButtonModule,
    ConfirmDialogModule,
    CalendarModule,
    ListboxModule,
    AutoCompleteModule,
    MultiSelectModule
  ],
  exports: [CouponFormComponent, CouponProductVariantFormComponent]
})
export class CouponFormModule {}

/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Pageable } from './pageable';
import { RenewalReprocess } from './renewalReprocess';
import { Sort } from './sort';

export interface PageRenewalReprocess { 
    totalElements?: number;
    totalPages?: number;
    size?: number;
    content?: Array<RenewalReprocess>;
    number?: number;
    sort?: Sort;
    numberOfElements?: number;
    first?: boolean;
    last?: boolean;
    pageable?: Pageable;
    empty?: boolean;
}
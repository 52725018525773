import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ShopBanner,
  ShopSettings,
  ShopSettingsRepository
} from '@infrab4a/connect';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CustomizationComponent } from 'src/app/components/customization/customization.component';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-customization-component-banner',
  templateUrl: './customization-component-banner.component.html',
  styleUrl: './customization-component-banner.component.scss'
})
export class CustomizationComponentBannerComponent
  extends CustomizationComponent
  implements OnInit
{
  pageDocument: ShopSettings;
  pageBanners: ShopBanner[];
  landingPageRoute: string;

  constructor(
    override router: Router,
    @Inject('ShopSettingsRepository')
    private shopSettingsRepository: ShopSettingsRepository,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute
  ) {
    super(router);
  }

  async ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    this.documentId = id.toUpperCase();
    this.landingPageRoute = id.replaceAll('_', '-');
    this.newCampaignPage = `configuracoes/${this.landingPageRoute}-banner-new/${id}`;
    this.editCampaignPage = `configuracoes/${this.landingPageRoute}-banner-edit/${id}`;
    await this.getDocument();
  }

  override async getDocument(): Promise<void> {
    try {
      LoaderService.showLoader();
      this.pageDocument = await this.shopSettingsRepository.get({
        id: this.documentId
      });
    } catch (err) {
      AppDialogService.showErrorDialog(err);
    } finally {
      LoaderService.showLoader(false);
    }
    this.pageBanners = this.pageDocument.sections;
  }

  override async deleteSection(campaign: ShopBanner): Promise<void> {
    this.confirmationService.confirm({
      acceptLabel: 'Sim',
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: 'Não',
      rejectButtonStyleClass: 'p-button-primary',
      message: 'Tem certeza que deseja excluir?',
      header: 'Atenção!',
      accept: async () => {
        this.pageBanners = null;
        const index = this.pageDocument.sections?.findIndex(
          (c: any) => c === campaign
        );

        this.pageDocument.sections?.splice(index, 1);

        try {
          LoaderService.showLoader();
          await this.shopSettingsRepository.update({
            id: this.documentId,
            sections: this.pageDocument.sections
          });
          this.messageService.add({
            severity: 'success',
            summary: 'Campanha excluída',
            detail: 'A campanha agendada foi excluída com sucesso!'
          });

          this.pageBanners = this.pageDocument.sections;
        } catch (err) {
          AppDialogService.showErrorDialog(err);
        } finally {
          LoaderService.showLoader(false);
        }
      },
      reject: () => {
        this.pageBanners = this.pageDocument.sections;
      }
    });
  }

  goToNewScreen() {
    this.navigate(this.newCampaignPage);
  }

  goToEditScreen(banner: ShopBanner) {
    const index = this.pageBanners.indexOf(banner);
    this.navigate(`${this.editCampaignPage}/${index}`);
  }

  goToMenu() {
    this.navigate('configuracoes');
  }
}

<app-table
  [lazy]="true"
  [cols]="columns"
  tableTitle="Assinaturas Ativas Status New"
  [addButton]="false"
  [exportPdf]="false"
  [exportExcel]="false"
  [refreshButton]="false"
  [service]="itOpsService"
  pageFunctionName="getActiveSubscriptionNewTable"
  emptyMessage="Sem mais assinaturas ativas com status 'NEW' no momento."
  selectionActionLabel="Corrigir"
  selectionActionTooltip="Marcar como resolvido"
  selectionActionIcon="pi pi-check"
  (emitSelection)="markAsResolved($event)"
  modelName="Registros"
  [defaultRows]="15"
  [dropdownFilters]="dropdownFilters"
  [multiSelectFilter]="true"
  *ngIf="dropdownFilters"
></app-table>
<p-confirmDialog></p-confirmDialog>
<p-toast></p-toast>

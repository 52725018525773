/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SubscriberCouponDetail {
  subscriberId?: number;
  couponId?: number;
  dateCreated?: Date;
  subscriberCouponId?: number;
  couponName?: string;
  inviterPersonid?: number;
  totalPrice?: number;
  paymentMethod?: number;
  paymentId?: number;
  paymentLogId?: number;
  subscriptionTypeId?: number;
  inviter?: string;
  freeShipping?: number;
  value?: string;
}

import { DatePipe } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
  inject
} from '@angular/core';
import { CouponUsageSummary, CouponUsageType } from 'src/app/admin-api';
import {
  CouponDailyUsage,
  CouponUsageTypeEnum,
  EditionId
} from 'src/app/models';
import { TableColumn } from '../table';

@Component({
  selector: 'app-coupons-report',
  templateUrl: './coupons-report.component.html',
  styleUrls: ['./coupons-report.component.scss'],
  providers: [DatePipe],
  encapsulation: ViewEncapsulation.None
})
export class CouponsReportComponent implements OnInit {
  @Input({ required: true })
  editionId: number;

  @Input({ required: true })
  usageTypes: Array<CouponUsageType> | undefined;

  @Input({ required: true })
  coupons: Array<CouponUsageSummary> | undefined;

  private datePipe = inject(DatePipe);

  couponsTable: Array<CouponDailyUsage> | undefined;
  cols: Array<TableColumn> = [
    new TableColumn('Categoria', 'usageType', false, 'text'),
    new TableColumn('WoW', 'wow', false, 'text'),
    new TableColumn('1D-7', 'lastWeek', false, 'text')
  ];
  columnIndex = -1;
  selectedIndex = 0;
  ready = false;

  ngOnInit(): void {
    this.findPage();
  }

  findPage(): void {
    this.ready = false;
    if (
      this.reportDates?.at(0)?.getMonth() !== new Date().getMonth() ||
      this.reportDates?.length === 1
    ) {
      this.selectedIndex = this.reportDates.length - 1;
    } else {
      this.selectedIndex = this.reportDates.length - 2;
    }
    this.couponsTable = this.usageTypes
      ?.concat([
        {
          couponUsageType: 'Sem cupom',
          couponUsageTypeId: -1,
          description: 'Sem uso de cupom'
        }
      ])
      .map((ut) => {
        return {
          usageType: ut.couponUsageType,
          usageTypeId: ut.couponUsageTypeId,
          usages: this.reportDates.map((date) => {
            const usage = this.coupons?.filter(
              (c) =>
                this.datePipe.transform(c.dateCreated, 'dd/MM/yyyy') ===
                  this.datePipe.transform(date, 'dd/MM/yyyy') &&
                c.couponUsageTypeId === ut.couponUsageTypeId
            ) || [
              {
                couponUsageType: ut.description,
                couponUsageTypeId: ut.couponUsageTypeId,
                dateCreated: date,
                total: 0
              }
            ];
            return {
              usage: usage.reduce((sum, c) => (sum += c.total || 0), 0),
              day: date
            };
          }),
          wow: this.getWow(ut.couponUsageTypeId as number),
          wowValue: this.getWoWValue(ut.couponUsageTypeId as number),
          lastWeek: this.getLastWeekDiff(ut.couponUsageTypeId as number),
          lastWeekValue: this.getLastWeekDiffValue(
            ut.couponUsageTypeId as number
          )
        } as CouponDailyUsage;
      });
    this.couponsTable?.push({
      usages: this.reportDates?.map((rd) => ({
        day: rd,
        usage:
          this.coupons
            ?.filter(
              (c) =>
                this.datePipe.transform(c.dateCreated, 'dd/MM/yyyy') ===
                this.datePipe.transform(rd, 'dd/MM/yyyy')
            )
            .reduce((sum, c) => (sum += c.total || 0), 0) || 0,
        usageCoupon:
          this.coupons
            .filter(
              (c) =>
                this.datePipe.transform(c.dateCreated, 'dd/MM/yyyy') ===
                  this.datePipe.transform(rd, 'dd/MM/yyyy') &&
                c.couponUsageTypeId >= 0
            )
            .reduce((sum, c) => (sum += c.total), 0) || 0
      })),
      usageType: 'Total',
      usageTypeId: -1,
      wow: this.getWow(-1),
      wowValue: this.getWoWValue(-1),
      lastWeek: this.getLastWeekDiff(-1),
      lastWeekValue: this.getLastWeekDiffValue(-1)
    });
    setTimeout(() => {
      const tb = document.getElementsByClassName('p-datatable-wrapper');
      if (tb.length) tb[0].scrollLeft = 10000;
      this.ready = true;
    });
  }

  changeSelectedIndex(index: number): void {
    this.selectedIndex = index;
    this.couponsTable?.forEach((c) => {
      c.wow = this.getWow(c.usageTypeId);
      c.wowValue = this.getWoWValue(c.usageTypeId);
      c.lastWeek = this.getLastWeekDiff(c.usageTypeId);
      c.lastWeekValue = this.getLastWeekDiffValue(c.usageTypeId);
    });
  }

  usageTotal(usage: unknown): number {
    return (usage as any).usage;
  }

  getWow(usageTypeId: number): string {
    return Math.round(this.getWoWValue(usageTypeId)) + ' %';
  }

  getWoWValue(usageTypeId: number): number {
    const selectedDay = this.reportDates[this.selectedIndex] || new Date();
    const lastWeekEnd = new Date(selectedDay.getTime());
    lastWeekEnd.setHours(0);
    lastWeekEnd.setMinutes(0);
    lastWeekEnd.setSeconds(0);
    lastWeekEnd.setMilliseconds(0);
    lastWeekEnd.setDate(selectedDay.getDate() - 7);
    const lastWeekStart = new Date(lastWeekEnd.getTime());
    lastWeekStart.setDate(lastWeekEnd.getDate() - 6);
    const thisWeek = this.coupons?.filter(
      (u) =>
        u.dateCreated.getTime() > lastWeekEnd.getTime() &&
        (usageTypeId === -1 || u.couponUsageTypeId === usageTypeId)
    );
    const lastWeek = this.coupons?.filter(
      (u) =>
        u.dateCreated.getTime() <= lastWeekEnd.getTime() &&
        u.dateCreated.getTime() >= lastWeekStart.getTime() &&
        (usageTypeId === -1 || u.couponUsageTypeId === usageTypeId)
    );
    // const lastWeek = usages.slice(usages.length - 16, usages.length - 9);
    const thisWeekTotal = thisWeek?.reduce(
      (sum, d) => (sum += d.total || 0),
      0
    );
    const lastWeekTotal = lastWeek?.reduce(
      (sum, d) => (sum += d.total || 0),
      0
    );
    return (
      100 *
      (((thisWeekTotal || 0) - (lastWeekTotal || 0)) / (lastWeekTotal || 1))
    );
  }

  getLastWeekDiff(usageTypeId: number): string {
    return Math.round(this.getLastWeekDiffValue(usageTypeId)) + ' %';
  }

  getLastWeekDiffValue(usageTypeId: number): number {
    const selectedDay = this.reportDates[this.selectedIndex] || new Date();
    const lastWeekDay = new Date(selectedDay.getTime());
    lastWeekDay.setHours(0);
    lastWeekDay.setMinutes(0);
    lastWeekDay.setSeconds(0);
    lastWeekDay.setMilliseconds(0);
    lastWeekDay.setDate(selectedDay.getDate() - 7);
    if (this.coupons) {
      const lastWeekTotal = this.coupons
        .filter(
          (u) =>
            this.datePipe.transform(u.dateCreated, 'dd/MM/yyyy') ===
              this.datePipe.transform(lastWeekDay, 'dd/MM/yyyy') &&
            (usageTypeId === -1 || u.couponUsageTypeId === usageTypeId)
        )
        .reduce((sum, u) => (sum += u.total || 0), 0);
      const lastDayUsages = this.coupons.filter(
        (u) =>
          this.datePipe.transform(u.dateCreated, 'dd/MM/yyyy') ===
            this.datePipe.transform(selectedDay, 'dd/MM/yyyy') &&
          (usageTypeId === -1 || u.couponUsageTypeId === usageTypeId)
      );
      const todayTotal = lastDayUsages.reduce(
        (sum, u) => (sum += u.total || 0),
        0
      );
      return 100 * ((todayTotal - lastWeekTotal) / (lastWeekTotal || 1));
    }
    return 0;
  }

  get oldEdition(): boolean {
    return EditionId.isBefore(
      this.editionId as number,
      EditionId.currentEdition(Number((this.editionId / 1000000).toFixed(0)))
    );
  }

  get reportDates(): Array<Date> {
    const today = new Date();
    today.setDate(1);
    if (this.oldEdition) today.setMonth(((this.editionId as number) % 100) - 1);
    today.setFullYear(
      Number((((this.editionId as number) % 1000000) / 100).toFixed(0))
    );
    const endOfMonth = new Date();
    endOfMonth.setMonth(today.getMonth() + 1);
    endOfMonth.setDate(0);
    const tomorrow = new Date();
    tomorrow.setMilliseconds(0);
    tomorrow.setSeconds(0);
    tomorrow.setMinutes(0);
    tomorrow.setHours(0);
    if (this.oldEdition) {
      tomorrow.setMonth(endOfMonth.getMonth());
      tomorrow.setFullYear(endOfMonth.getFullYear());
      tomorrow.setDate(endOfMonth.getDate() + 1);
    } else tomorrow.setDate(tomorrow.getDate() + 1);
    const dates: Array<Date> = [];
    while (
      today.getTime() < tomorrow.getTime() &&
      today.getTime() <= endOfMonth.getTime()
    ) {
      dates.push(new Date(today.getTime()));
      today.setDate(today.getDate() + 1);
    }
    return dates;
  }

  get paidMTD(): number {
    return (
      this.coupons
        ?.filter(
          (c) =>
            c.couponUsageTypeId === CouponUsageTypeEnum.Paid &&
            this.datePipe.transform(c.dateCreated, 'MM/yyyy') ===
              this.datePipe.transform(
                this.reportDates[this.selectedIndex],
                'MM/yyyy'
              ) &&
            c.dateCreated.getTime() <=
              this.reportDates[this.selectedIndex].getTime()
        )
        .reduce((sum, c) => (sum += c.total || 0), 0) || 0
    );
  }

  get paidYesterday(): number {
    const yesterday = new Date(this.reportDates[this.selectedIndex].getTime());
    yesterday.setDate(yesterday.getDate() - 1);
    return (
      this.coupons
        ?.filter(
          (c) =>
            this.datePipe.transform(c.dateCreated, 'dd/MM/yyyy') ===
              this.datePipe.transform(yesterday, 'dd/MM/yyyy') &&
            c.couponUsageTypeId === CouponUsageTypeEnum.Paid
        )
        .reduce((sum, c) => (sum += c.total || 0), 0) || 0
    );
  }
}

import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TableColumn } from 'src/app/components/table';
import { ShopProductService } from 'src/app/connect-api/api/shop/shop-product.service';

@Component({
  selector: 'app-product-stock-notifications',
  templateUrl: './product-stock-notifications.component.html',
  styleUrl: './product-stock-notifications.component.scss'
})
export class ProductStockNotificationsComponent {
  cols = [
    new TableColumn.Builder()
      .setHeader('Nome')
      .setField('name')
      .setCondition('contains')
      .setRouterLink('/shop-products/catalog/product/')
      .setRouterLinkFieldName('product_id')
      .build(),
    new TableColumn.Builder().setHeader('EAN').setField('ean').build(),
    new TableColumn.Builder().setHeader('SKU').setField('sku').build(),
    new TableColumn.Builder()
      .setHeader('Categoria')
      .setField('category')
      .setCondition('contains')
      .setRouterLink('/shop-products/categories/category/')
      .setRouterLinkFieldName('category_id')
      .build(),
    new TableColumn.Builder()
      .setHeader('Referência')
      .setField('reference')
      .build(),
    new TableColumn('Emails cadastrados', 'emails_registered', true, 'number')
  ];

  constructor(
    private title: Title,
    protected shoppingProductService: ShopProductService
  ) {
    this.title.setTitle('Loja - Avise-me');
  }
}

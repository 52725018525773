import { DatePipe } from '@angular/common';
import { Component, HostListener } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { PersonAttributeLogDetail, PersonDetail } from 'src/app/admin-api';
import { FormUtil } from 'src/app/utils/form.util';
import { TableColumn } from '../../table';

@Component({
  selector: 'app-beautyprofile-history',
  templateUrl: './beautyprofile-history.component.html',
  styleUrls: ['./beautyprofile-history.component.scss'],
  providers: [DatePipe]
})
export class BeautyprofileHistoryComponent {
  person: PersonDetail | undefined;
  history: Array<PersonAttributeLogDetail> = [];
  cols: Array<TableColumn> = [
    new TableColumn(
      'Questão',
      'attributeQuestion',
      false,
      'text',
      undefined,
      undefined,
      false
    ),
    new TableColumn('Opção antiga', 'oldOption', false),
    new TableColumn('Opção nova', 'newOption', false),
    new TableColumn('Data da atualização', 'dateCreated', false, 'date')
  ];
  isMobile?: boolean;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.checkScreenSize();
  }

  constructor(private config: DynamicDialogConfig, private datePipe: DatePipe) {
    this.person = this.config.data.person;
    this.history = this.config.data.history;
    this.checkScreenSize();
  }

  checkScreenSize(): void {
    this.isMobile = window.innerWidth < 768;
  }

  timeDiffLabel(dateStart: string): string {
    return FormUtil.timeDiffLabel(dateStart, this.datePipe);
  }
}

<app-table
  [service]="feedbackGroupService"
  pageFunctionName="findFeedbackGroupsTable"
  [cols]="cols"
  tableTitle="Grupos de pesquisa"
  addLabel="Novo grupo"
  modelName="grupos"
  sortField="feedbackGroupId"
  [sortOrder]="1"
  colsExportMode="visible"
  *ngIf="actionButtons"
  [actionButtons]="actionButtons"
  (actionButtonClick)="deleteGroup($event)"
></app-table>
<p-confirmPopup></p-confirmPopup>
<p-toast></p-toast>

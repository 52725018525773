<div class="coupons-table" *ngIf="couponsTable">
  <h3>Uso de cupons</h3>
  <p-table
    [columns]="cols"
    [value]="couponsTable"
    *ngIf="couponsTable"
    [scrollable]="true"
    scrollDirection="horizontal"
    styleClass="p-datatable-striped"
    [autoLayout]="true"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" pFrozenColumn>
          {{ col.header }}
        </th>
        <th
          *ngFor="let usage of couponsTable[0].usages; let i = index"
          (mouseenter)="columnIndex = i"
          [class.mouse-over]="i === columnIndex"
          [class.selected]="i === selectedIndex"
          (click)="changeSelectedIndex(i)"
        >
          {{ usage.day | date : 'EEE' : '' : 'pt-BR' }}
          <br />
          {{ usage.day | date : 'dd/MMM' }}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-day let-columns="columns">
      <tr>
        <td
          *ngFor="let col of columns"
          pFrozenColumn
          [class.danger]="
            (col.field === 'wow' && day.wowValue < 0) ||
            (col.field === 'lastWeek' && day.lastWeekValue < 0)
          "
          [class.success]="
            (col.field === 'wow' && day.wowValue > 0) ||
            (col.field === 'lastWeek' && day.lastWeekValue > 0)
          "
        >
          {{ day[col.field] }}
        </td>
        <td
          [class.mouse-over]="i === columnIndex"
          [class.selected]="i === selectedIndex"
          (mouseenter)="columnIndex = i"
          (click)="changeSelectedIndex(i)"
          *ngFor="let usage of day.usages; let i = index"
        >
          {{ usageTotal(usage) | number : '1.0-0' }}
          <span
            [pTooltip]="usage.usageCoupon + ' com cupom'"
            tooltipPosition="bottom"
            *ngIf="usageTotal(usage) > usage.usageCoupon"
            >({{ usage.usageCoupon | number : '1.0-0' }})</span
          >
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      <div><b>Paid MTD</b>: {{ paidMTD }}</div>
      <div><b>Paid D-1</b>: {{ paidYesterday }}</div>
    </ng-template>
  </p-table>
</div>

import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { PrimeIcons } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  CompositionControllerService,
  CompositionProductionSummary,
  Edition,
  EditionControllerService,
  PageableFilter,
  UserControllerService,
  UserRoleViewResponse
} from 'src/app/admin-api';
import {
  TableActionButton,
  TableColumn,
  TableComponent
} from 'src/app/components/table';
import {
  EditionId,
  Role,
  getAllSubscriptions,
  roleAsObject
} from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';
import { CompositionProductionModalComponent } from './composition-production-modal/composition-production-modal.component';

@Component({
  selector: 'app-composition-productions',
  templateUrl: './composition-productions.component.html',
  styleUrl: './composition-productions.component.scss',
  providers: [DialogService]
})
export class CompositionProductionsComponent implements OnInit {
  @ViewChild(TableComponent)
  table: TableComponent;

  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private title: Title = inject(Title);
  private editionService: EditionControllerService = inject(
    EditionControllerService
  );
  private router: Router = inject(Router);
  public compositionService: CompositionControllerService = inject(
    CompositionControllerService
  );
  private dialog: DialogService = inject(DialogService);
  public userService: UserControllerService = inject(UserControllerService);
  public ngxPermissionService: NgxPermissionsService = inject(
    NgxPermissionsService
  );

  form = new FormGroup({
    subscriptionId: new FormControl<number>(1, [
      Validators.required,
      Validators.min(0)
    ]),
    editionId: new FormControl<number>(EditionId.currentEdition(1), [
      Validators.required,
      Validators.min(1)
    ])
  });
  subscriptions = getAllSubscriptions().filter((s) => s.value !== 6);
  editions: Array<Edition> | undefined;

  fixedFilters: Array<PageableFilter>;
  loading = true;
  compositionCols = [
    new TableColumn(
      'Id',
      'compositionId',
      true,
      'number',
      '/operations/compositions/id/',
      'compositionId'
    ),
    new TableColumn(
      'Composição',
      'compositionName',
      true,
      'text',
      '/operations/compositions/id/',
      'compositionId',
      true,
      'contains'
    ),
    new TableColumn(
      'Assinantes',
      'subscriberCount',
      true,
      'formattedInteger',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Produzidas',
      'quantity',
      true,
      'formattedInteger',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'A produzir',
      'pending',
      true,
      'formattedInteger',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn('Ação', '', false, 'button')
  ];
  productionCols = [
    new TableColumn('Quantidade', 'quantity', false, 'number'),
    new TableColumn('Data', 'dateCreated', false, 'date')
  ];
  actionButtons = [
    new TableActionButton(
      '',
      'viewProduction',
      PrimeIcons.EYE,
      (c: CompositionProductionSummary) => c.quantity > 0,
      '',
      'Ver produção',
      'bottom',
      false,
      true,
      'primary',
      'small'
    )
  ];
  user: UserRoleViewResponse;
  permission = false;

  async ngOnInit(): Promise<void> {
    this.permission = await this.ngxPermissionService.hasPermission([
      roleAsObject(Role.Full_Administrator).enumValue,
      roleAsObject(Role.Logistics).enumValue
    ]);
    if (this.permission)
      this.actionButtons.push(
        new TableActionButton(
          '',
          'addProduction',
          PrimeIcons.PLUS_CIRCLE,
          (c: CompositionProductionSummary) => c.pending > 0,
          '',
          'Adicionar produção',
          'left',
          false,
          true,
          'success',
          'small'
        )
      );
    this.activatedRoute.params.subscribe(async (params) => {
      if (params['editionId']) {
        this.form.patchValue({
          editionId: Number(params['editionId']),
          subscriptionId: Number((params['editionId'] / 1000000).toFixed(0))
        });
        LoaderService.showLoader();
        const fail = document.getElementById('img-fail');
        if (fail?.style?.display === 'none') {
          fail.style.display = 'block';
        }
        await Promise.all([this.findEditions(), this.getUserDetails()]);
        this.fixedFilters = [
          {
            condition: 'equals',
            field: 'editionId',
            fieldType: 'number',
            value: this.form.value.editionId.toString()
          }
        ];
        this.title.setTitle('Controle de produção - ' + this.edition?.theme);
        this.loading = false;
        LoaderService.showLoader(false);
      } else {
        this.navigate();
      }
    });
  }

  async findEditions(): Promise<void> {
    try {
      this.editions = await lastValueFrom(
        this.editionService
          .findEditionsBySubscriptionId(this.form.value.subscriptionId || 1)
          .pipe(map((data) => data.result))
      );
      if (this.form.value.subscriptionId === 0) {
        this.editions?.forEach(
          (e) => (e.editionId = (e.editionId || 0) % 1000000)
        );
      }
    } catch (error: any) {
      this.editions = [];
    }
  }

  navigate(): void {
    this.loading = true;
    this.router.navigate([
      '/operations/composition-production/' + this.form.value.editionId
    ]);
  }

  async changeSubscription(): Promise<void> {
    LoaderService.showLoader();
    const form = { ...this.form.value };
    this.form.controls['editionId'].reset(
      (form.subscriptionId || 0) * 1000000 + ((form.editionId || 0) % 1000000)
    );
    this.navigate();
    LoaderService.showLoader(false);
  }

  editionDate(editionId: number): string {
    return FormUtil.editionDate(editionId);
  }

  changeEdition(): void {
    LoaderService.showLoader();
    this.navigate();
  }

  async tableAction(event: {
    item: CompositionProductionSummary;
    $event: Event;
    action: string;
  }): Promise<void> {
    if (!event.item.productions?.length) {
      event.item.productions = await lastValueFrom(
        this.compositionService
          .findCompositionProductionsByCompositionId(event.item.compositionId)
          .pipe(map((data) => data.result))
      );
    }
    this.dialog
      .open(CompositionProductionModalComponent, {
        data: {
          composition: event.item,
          username: this.user.username
        },
        height: '75vh',
        header: event.item.compositionName,
        closeOnEscape: false
      })
      .onClose.subscribe(async () => {
        await this.table.refresh();
      });
  }

  async getUserDetails(): Promise<void> {
    try {
      this.user = await lastValueFrom(
        this.userService.getInfo().pipe(map((data) => data.result))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  get edition(): Edition | undefined {
    return this.editions?.find(
      (e) => e.editionId === this.form.value.editionId
    );
  }
}

import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ChartConfiguration, ChartData } from 'chart.js';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  EmailTemplateControllerService,
  NotificationControllerService,
  NotificationTrend,
  PageableRequest,
  PageEmailNotificationDetail
} from 'src/app/admin-api';
import { EmailNotificationBasicDetail } from 'src/app/admin-api/model/emailNotificationBasicDetail';
import { EmailTemplate } from 'src/app/admin-api/model/emailTemplate';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { EmailListComponent } from './email-list/email-list.component';

@Component({
  selector: 'app-crm-dashboard',
  templateUrl: './crm-dashboard.component.html',
  styleUrls: ['./crm-dashboard.component.scss'],
  providers: [MessageService, DatePipe]
})
export class CrmDashboardComponent implements OnInit {
  @Input() visible: boolean = false;
  @Input() template: EmailNotificationBasicDetail;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() modalClose = new EventEmitter<void>();

  public notificationService = inject(NotificationControllerService);
  private emailTemplateService = inject(EmailTemplateControllerService);
  private title = inject(Title);
  private datePipe = inject(DatePipe);
  private dialog = inject(DialogService);

  dateFilterForm = new FormGroup({
    startDate: new FormControl<Date>(null, Validators.required),
    endDate: new FormControl<Date>(new Date()),
    templates: new FormControl(
      [],
      [Validators.required, Validators.minLength(1)]
    )
  });
  analyticsData: NotificationTrend[] = [];
  chartData: ChartData;
  today = new Date();
  width: string;
  isLoading = false;
  chartType = 'bar';

  events: {
    [eventName: string]: { color: string; label: string };
  } = {
    sent: { color: '#4CAF50', label: 'Total Enviados' },
    delivered: { color: '#2196F3', label: 'Entregues' },
    processed: { color: '#8BC34A', label: 'Processados' },
    opened: { color: '#FFC107', label: 'Abertos' },
    clicked: { color: '#9C27B0', label: 'Clicados' },
    bounced: { color: '#F44336', label: 'Rejeitados' },
    spam: { color: '#795548', label: 'Spam' },
    unsubscribed: { color: '#607D8B', label: 'Descadastrados' },
    blocked: { color: '#FF5722', label: 'Bloqueados' },
    dropped: { color: '#FF9800', label: 'Descartados' },
    deferred: { color: '#9E9E9E', label: 'Adiados' }
  };

  chartOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: '#495057',
          usePointStyle: true,
          padding: 20
        }
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          label: function (context) {
            const value = context.raw as number;
            return `${context.dataset.label}: ${value}`;
          }
        }
      }
    },
    scales: {
      x: {
        ticks: {
          color: '#495057',
          maxRotation: 45,
          minRotation: 45
        },
        grid: { color: '#ebedef' }
      },
      y: {
        beginAtZero: true,
        ticks: { color: '#495057' },
        grid: { color: '#ebedef' }
      }
    }
  };
  templates: EmailTemplate[];

  constructor() {
    this.checkScreenSize();
    this.title.setTitle('Dashboard de CRM');
  }

  async ngOnInit() {
    LoaderService.showLoader();
    await this.findTemplates();
    await this.loadInitialAnalytics();
    LoaderService.showLoader(false);
  }

  checkScreenSize(): void {
    this.width = window.innerWidth > 768 ? '100%' : '500px';
  }

  onHide(): void {
    this.visible = false;
    this.visibleChange.emit(false);
    this.modalClose.emit();
  }

  private async loadInitialAnalytics(): Promise<void> {
    const lastMonth = new Date();
    lastMonth.setDate(lastMonth.getDate() - 30);

    this.dateFilterForm.controls.startDate.setValue(lastMonth);

    await this.loadAnalyticsFromAPI(false);
  }

  public async loadAnalyticsFromAPI(loader = true): Promise<void> {
    try {
      if (!this.dateFilterForm.valid) return;
      if (loader) LoaderService.showLoader();
      delete this.analyticsData;
      LoaderService.showLoader(true);
      this.analyticsData = await lastValueFrom(
        this.notificationService
          .getTemplateAnalytics(this.dateFilterForm.value)
          .pipe(map((data) => data.result))
      );
      this.setupSimpleChartData();
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      if (loader) LoaderService.showLoader(false);
      LoaderService.showLoader(false);
    }
  }

  async findTemplates() {
    try {
      this.templates = await lastValueFrom(
        this.emailTemplateService
          .listTemplates()
          .pipe(map((data) => data.result))
      );
      this.dateFilterForm.controls.templates.setValue(
        this.templates.map((t) => t.id)
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  private setupSimpleChartData(): void {
    if (!this.analyticsData || this.analyticsData.length === 0) return;

    const days = this.analyticsData.reduce((list: Date[], e) => {
      const ref = this.datePipe.transform(e.referenceDate, 'dd/MM');
      if (!list.some((l) => this.datePipe.transform(l, 'dd/MM') === ref))
        list.push(new Date(e.referenceDate));
      return list;
    }, []);
    days.sort((l1, l2) => l1.getTime() - l2.getTime());
    const labels = days.map((d) => this.datePipe.transform(d, 'dd/MM'));

    this.chartData = {
      labels,
      datasets: this.eventsArray.map((event) => ({
        type: 'line',
        tension: 0.4,
        label: event.label,
        data: labels.map((ref) =>
          this.analyticsData
            .filter(
              (e) => this.datePipe.transform(e.referenceDate, 'dd/MM') === ref
            )
            .reduce((sum, e) => (sum += e[event.eventName]), 0)
        ),
        backgroundColor: event.color,
        borderColor: event.color
      }))
    };

    this.chartOptions = {
      ...this.chartOptions,
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'top',
          labels: {
            color: '#495057',
            usePointStyle: true,
            padding: 15,
            font: {
              size: 12
            }
          }
        },
        tooltip: {
          mode: 'index',
          intersect: false
        }
      },
      scales: {
        x: {
          ticks: {
            color: '#495057',
            maxRotation: 45,
            minRotation: 45,
            font: {
              size: 11
            }
          },
          grid: { color: '#ebedef' },
          title: {
            display: true,
            text: 'Data',
            color: '#495057',
            font: {
              weight: 'bold',
              size: 12
            }
          }
        },
        y: {
          beginAtZero: true,
          ticks: { color: '#495057' },
          grid: { color: '#ebedef' },
          title: {
            display: true,
            text: 'Quantidade',
            color: '#495057',
            font: {
              weight: 'bold',
              size: 12
            }
          }
        }
      }
    };
  }

  async showEvents(event: {
    color: '#4CAF50';
    label: 'Total Enviados';
    eventName: string;
  }) {
    this.dialog.open(EmailListComponent, {
      modal: true,
      maximizable: true,
      width: '95vw',
      height: '90vh',
      data: {
        event,
        filters: this.dateFilterForm.value
      },
      header: 'Eventos' + (event.eventName === 'sent' ? '' : ' ' + event.label)
    });
    // this.event = event;
    // this.showEvent = true;
  }

  public async findPage(
    request: PageableRequest,
    service?: NotificationControllerService
  ): Promise<PageEmailNotificationDetail> {
    return await lastValueFrom(
      service.getEventDetails(request).pipe(
        map((data) => ({
          ...data.result,
          content: data.result.content.map((c) => ({
            ...c,
            detailsData: [
              {
                ip: c.ip,
                linkClicked: c.linkClicked,
                reason: c.reason,
                userAgent: c.userAgent
              }
            ]
          }))
        }))
      )
    );
  }

  totalEvent(eventName: string) {
    return this.analyticsData?.reduce(
      (sum: number, e) => (sum += e[eventName] || 0),
      0
    );
  }

  get eventsArray() {
    return Object.keys(this.events).map((eventName) => ({
      ...this.events[eventName],
      eventName
    }));
  }
}

<div class="mens-home" *ngIf="home && ready">
  <h3 class="title">Customizar Home da Mens</h3>
  <p-fieldset [legend]="section.label" *ngFor="let section of sections">
    <p-carousel
      *ngIf="
        (section.type === 'Banner[]' || section.type === 'string[]') &&
        home[section.id]?.length
      "
      [value]="home[section.id]"
      [numVisible]="
        section.id === 'heroCarousel' || section.type === 'string[]' ? 1 : 2
      "
      [numScroll]="
        section.id === 'heroCarousel' || section.type === 'string[]' ? 1 : 2
      "
      [circular]="section.id === 'heroCarousel'"
      [responsiveOptions]="responsiveOptionsBig"
      [autoplayInterval]="section.id === 'heroCarousel' ? 5000 : null"
      [showNavigators]="home[section.id]?.length > 1"
      [showIndicators]="home[section.id]?.length > 1"
      styleClass="carousel__mens"
    >
      <ng-template
        let-banner
        pTemplate="item"
        *ngIf="section.type === 'Banner[]'"
      >
        <div class="carousel-banner">
          <img [alt]="banner.alt" [src]="banner.image" class="border-round" />
        </div>
      </ng-template>
      <ng-template
        let-collectionId
        pTemplate="item"
        *ngIf="section.type === 'string[]'"
      >
        <span class="collection-name">
          {{ collection(collectionId)?.name }}
        </span>
        <span *ngIf="!products[collectionId]?.length">
          Sem produtos para exibir
        </span>
        <div class="collection-products" *ngIf="products[collectionId]?.length">
          <a
            [routerLink]="'/shop-products/catalog/product/' + product.id"
            target="href"
            [pTooltip]="product.name"
            tooltipPosition="bottom"
            class="collection-products__product"
            *ngFor="let product of products[collectionId]"
          >
            <img
              *ngIf="product.images?.length"
              [alt]="product.name"
              [src]="product.images[0]"
              class="product-image"
            />
            <img
              *ngIf="!product.images?.length"
              [alt]="product.name"
              src="assets/images/no_img_available.jpg"
              class="product-image"
            />
          </a>
        </div>
      </ng-template>
    </p-carousel>
    <div class="home-banner" *ngIf="section.type === 'Banner'">
      <img
        [src]="home[section.id].image || home[section.id].imageMobile"
        [alt]="home[section.id].alt"
      />
    </div>
    <div class="actions">
      <p-button
        icon="pi pi-pencil"
        [text]="true"
        [rounded]="true"
        pTooltip="Editar"
        tooltipPosition="bottom"
        [routerLink]="'' + section.id"
      />
    </div>
  </p-fieldset>
</div>

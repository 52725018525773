<components-loader *ngIf="loader"></components-loader>
<div class="container" [class.expanded]="menuExpanded" *ngIf="!noLayout">
  <app-side-bar (stateChanged)="menuStateChanged($event)" />
  <div id="main-container" class="content">
    <router-outlet />
  </div>
</div>
<div class="container" *ngIf="noLayout">
  <router-outlet />
</div>
<p-dialog
  *ngIf="dialog"
  [header]="dialog.header || ''"
  [modal]="true"
  [position]="dialog.position || 'center'"
  [closeOnEscape]="dialog.closeOnEscape || true"
  [closable]="true"
  [styleClass]="dialog.styleClass || ''"
  [showHeader]="true"
  [closeIcon]="' '"
  [visible]="dialog.visible"
>
  <div style="text-align: center">
    <span [innerHTML]="dialog.message"></span>
    <br />
    <br />
    <button
      label="Voltar"
      pButton
      pRipple
      class="mr-2"
      severity="danger"
      (click)="dialog.visible = false"
      *ngIf="!goBack"
    ></button>
    <button
      label="Voltar"
      pButton
      pRipple
      class="mr-2"
      severity="danger"
      (click)="goBackHistory()"
      *ngIf="goBack"
    ></button>
  </div>
</p-dialog>
<p-dialog
  [visible]="permissionDenied"
  [modal]="true"
  [position]="'center'"
  [closeOnEscape]="true"
  [closable]="false"
  [showHeader]="false"
  [closeIcon]="' '"
>
  <app-unauthorized
    [modal]="true"
    (navigate)="backFromPermission()"
    buttonLabel="Voltar"
  />
</p-dialog>
<p-dialog
  [visible]="sessionExpired"
  [modal]="true"
  [position]="'center'"
  [closeOnEscape]="true"
  [closable]="false"
  [showHeader]="false"
  [closeIcon]="' '"
  [style]="{ 'text-align': 'center' }"
>
  <p>Sessão expirada. Faça login novamente.</p>
  <br />
  <p-button (onClick)="login()" label="Ir para login" />
</p-dialog>
<p-toast />

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService, PrimeIcons } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  AdminControllerService,
  UserRoleViewResponse
} from 'src/app/admin-api';
import {
  DropdownFilter,
  TableActionButton,
  TableColumn
} from 'src/app/components/table';
import { getAllRoles, getRole } from 'src/app/models';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';
import { PasswordUpdateModalComponent } from './password-update-modal/password-update-modal.component';
import { UserFormModalComponent } from './user-form-modal/user-form-modal.component';

@Component({
  selector: 'app-system-users',
  templateUrl: './system-users.component.html',
  styleUrls: ['./system-users.component.scss'],
  providers: [ConfirmationService, DialogService, MessageService]
})
export class SystemUsersComponent implements OnInit {
  private adminService = inject(AdminControllerService);
  private confirmationService = inject(ConfirmationService);
  private messageService = inject(MessageService);
  private dialog = inject(DialogService);
  private title = inject(Title);
  private router: Router = inject(Router);

  allowedUsers: Array<UserRoleViewResponse & { role: string }> | undefined;
  permissions = [
    { label: 'Usuário', value: 'ROLE_ALLOC_USER' },
    { label: 'Admin', value: 'ROLE_ALLOC_ADMIN' }
  ];
  allPermissions: Array<{ label: string; value: string }>;
  cols: Array<TableColumn> = [
    new TableColumn('Id', 'userId', true, 'number'),
    new TableColumn(
      'Nome',
      'displayName',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Email',
      'username',
      true,
      'text',
      undefined,
      undefined,
      true,
      'startsWith'
    ),
    new TableColumn(
      'Permissão',
      'role',
      true,
      'text',
      undefined,
      undefined,
      true,
      'in'
    ),
    new TableColumn(
      'Usuário Glam',
      'personId',
      true,
      'number',
      '/users/person/',
      'personId',
      true,
      'equals'
    ),
    new TableColumn('Ação', '', false, 'button')
  ];
  actionButtons: Array<TableActionButton>;
  dropdownFilters: { [key: string]: Array<DropdownFilter> } = {
    role: [{ label: 'Todas', value: '' }].concat(
      getAllRoles().map((r) => ({
        label: r.label,
        value: r.label
      }))
    )
  };

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader();
    this.title.setTitle('Usuários do sistema');
    this.actionButtons = [
      new TableActionButton(
        '',
        'changePassword',
        PrimeIcons.KEY,
        () => true,
        '',
        'Alterar senha',
        'bottom',
        true,
        true,
        'warning'
      ),
      new TableActionButton(
        '',
        'editUser',
        PrimeIcons.PENCIL,
        () => true,
        '',
        'Editar usuário',
        'bottom',
        true,
        true,
        'primary'
      ),
      new TableActionButton(
        '',
        'excludeUser',
        PrimeIcons.TRASH,
        (user: UserRoleViewResponse) =>
          TokenStorageService.userId !== user.userId,
        '',
        'Excluir usuário',
        'bottom',
        true,
        true,
        'danger'
      )
    ];
    await this.findUsers();
    LoaderService.showLoader(false);
  }

  async findUsers(): Promise<void> {
    try {
      this.allowedUsers = (
        (await lastValueFrom(
          this.adminService.getAllowedUsers().pipe(map((data) => data.result))
        )) || []
      ).map((u) => ({
        ...u,
        role: getRole(FormUtil.getRolePrincipal(u.roles as string))
          ?.label as string
      }));
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
      this.allowedUsers = [];
    }
  }

  callAction($event: {
    item: UserRoleViewResponse & { role: string };
    $event: Event;
    action: 'changePassword' | 'editUser' | 'excludeUser' | string;
  }): void {
    if (
      $event.action === 'changePassword' ||
      $event.action === 'editUser' ||
      $event.action === 'excludeUser'
    ) {
      this[$event.action]($event.item, $event.$event);
    }
  }

  addNewUser(): void {
    LoaderService.showLoader(false);
    this.dialog
      .open(UserFormModalComponent, {
        showHeader: true,
        header: 'Novo usuário',
        dismissableMask: true,
        closable: false
      })
      .onClose.subscribe(async (success: any) => {
        if (success) {
          LoaderService.showLoader();
          delete this.allowedUsers;
          await this.findUsers();
          LoaderService.showLoader(false);
        }
      });
  }

  editUser(user: UserRoleViewResponse & { role: string }): void {
    this.dialog
      .open(UserFormModalComponent, {
        showHeader: true,
        closable: false,
        header: 'Editar usuário',
        dismissableMask: false,
        data: {
          user
        }
      })
      .onClose.subscribe(async (success: any) => {
        if (success) {
          LoaderService.showLoader();
          delete this.allowedUsers;
          await this.findUsers();
          this.messageService.add({
            severity: 'success',
            summary: 'Sucesso',
            detail: 'Usuário atualizado com sucesso'
          });

          LoaderService.showLoader(false);
        }
      });
  }

  excludeUser(user: UserRoleViewResponse, event: Event) {
    this.confirmationService.confirm({
      target: event.target as HTMLButtonElement,
      message: `Deseja remover o acesso do usuário ${user.username}?`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      acceptButtonStyleClass: 'p-button-danger',
      accept: async () => {
        LoaderService.showLoader();
        try {
          const response = await lastValueFrom(
            this.adminService
              .deleteUser(user.userId as number)
              .pipe(map((data) => data.result))
          );
          delete this.allowedUsers;
          await this.findUsers();
          this.messageService.add({
            severity: 'info',
            summary: 'Sucesso',
            detail: response as string
          });
        } catch (error: any) {
          AppDialogService.showErrorDialog(error);
        }
        LoaderService.showLoader(false);
      }
    });
  }

  getRoleLabel(role: string): string {
    return getRole(role)?.label || 'NONE';
  }

  changePassword(user: UserRoleViewResponse): void {
    if (user.userId === TokenStorageService.userId) {
      this.router.navigate(['/settings/account']);
      return;
    }
    this.dialog
      .open(PasswordUpdateModalComponent, {
        showHeader: true,
        header: 'Alterar senha',
        dismissableMask: false,
        width: '500px',
        data: {
          user
        }
      })
      .onClose.subscribe(async (success: any) => {
        if (success) {
          LoaderService.showLoader();
          this.messageService.add({
            severity: 'success',
            summary: 'Sucesso',
            detail: 'Senha atualizada com sucesso'
          });
          LoaderService.showLoader(false);
        }
      });
  }
}

<form
  class="modal-form"
  (submit)="submit()"
  [formGroup]="actionForm"
  *ngIf="actionTypes"
>
  <span class="p-float-label">
    <p-dropdown
      appendTo="body"
      [options]="actionTypes"
      optionLabel="description"
      optionValue="badgeActionTypeId"
      inputId="badgeActionTypeId"
      formControlName="actionType"
      styleClass="p-inputwrapper p-inputwrapper-filled"
      name="badgeActionTypeId"
      [filter]="true"
      filterBy="description"
      [autoDisplayFirst]="false"
    />
    <label for="badgeActionTypeId">Tipo</label>
  </span>
  <span class="p-float-label">
    <p-inputNumber
      name="referenceId"
      id="referenceId"
      formControlName="referenceId"
      placeholder="ID de referência"
    />
    <label for="referenceId">ReferenceId</label>
  </span>
  <div class="buttons">
    <p-button
      label="Cancelar"
      severity="danger"
      type="button"
      (onClick)="ref.close()"
    />
    <p-button
      label="Salvar"
      severity="primary"
      type="submit"
      [disabled]="!actionForm.valid"
    />
  </div>
</form>

import {
  Component,
  OnInit,
  WritableSignal,
  inject,
  signal
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  Attribute,
  AttributeGroup,
  BeautyProfileAdminControllerService
} from 'src/app/admin-api';
import { AttributeTypeEnum, getAllAttributeTypes } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ImageUploadComponent } from '../image-upload/image-upload.component';

@Component({
  selector: 'app-attribute-form',
  templateUrl: './attribute-form.component.html',
  styleUrl: './attribute-form.component.scss'
})
export class AttributeFormComponent
  extends ImageUploadComponent<Attribute>
  implements OnInit
{
  ref: DynamicDialogRef = inject(DynamicDialogRef);
  private config: DynamicDialogConfig = inject(DynamicDialogConfig);
  private beautyProfileAdminService: BeautyProfileAdminControllerService =
    inject(BeautyProfileAdminControllerService);
  private messageService: MessageService = inject(MessageService);

  attribute: WritableSignal<Attribute> = signal(this.config.data.attribute);
  group: WritableSignal<AttributeGroup> = signal(this.config.data.group);
  override form = new FormGroup({
    attributeId: new FormControl<number>(
      { value: this.attribute()?.attributeId, disabled: !this.attribute() },
      [Validators.required, Validators.min(1)]
    ),
    attributeGroupId: new FormControl<number>(
      { value: this.group()?.attributeGroupId, disabled: !!this.attribute() },
      [Validators.required, Validators.min(1)]
    ),
    attributeName: new FormControl<string>(this.attribute()?.attributeName, [
      Validators.required,
      Validators.minLength(1)
    ]),
    attributeQuestion: new FormControl<string>(
      this.attribute()?.attributeQuestion,
      [Validators.required, Validators.minLength(1)]
    ),
    syncToCustomerIO: new FormControl<boolean>(
      this.attribute()?.syncToCustomerIO
    ),
    syncronizable: new FormControl<boolean>(this.attribute()?.syncronizable),
    attributeTypeId: new FormControl<number>(
      this.attribute()?.attributeTypeId || AttributeTypeEnum['Resposta única'],
      [Validators.required, Validators.min(1), Validators.max(2)]
    ),
    active: new FormControl<boolean>(
      this.attribute() ? this.attribute()?.active : true
    ),
    webImage: new FormControl<string>(this.attribute()?.webImage),
    webImageUrl: new FormControl<string>({
      value: this.attribute()?.webImage,
      disabled: true
    }),
    mobileImage: new FormControl<string>(this.attribute()?.mobileImage),
    mobileImageUrl: new FormControl<string>({
      value: this.attribute()?.mobileImage,
      disabled: true
    }),
    sensitiveContent: new FormControl(
      this.attribute()?.sensitiveContent || false
    )
  });
  attributeTypes = getAllAttributeTypes();

  ngOnInit(): void {
    this.form.controls['mobileImage'].valueChanges.subscribe((value) =>
      this.form.controls['mobileImageUrl'].setValue(value)
    );
    this.form.controls['webImage'].valueChanges.subscribe((value) =>
      this.form.controls['webImageUrl'].setValue(value)
    );
  }

  async submit(): Promise<void> {
    LoaderService.showLoader();
    try {
      if (this.attribute()) {
        this.attribute.set(
          await lastValueFrom(
            this.beautyProfileAdminService
              .updateAttribute(this.form.value)
              .pipe(map((data) => data.result))
          )
        );
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'Pergunta atualizada com sucesso.'
        });
      } else {
        this.attribute.set(
          await lastValueFrom(
            this.beautyProfileAdminService
              .createAttribute(this.form.value)
              .pipe(map((data) => data.result))
          )
        );
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'Pergunta criada com sucesso.'
        });
      }
      await this.afterSubmit();
      this.ref.close(this.attribute());
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  public override get filePath(): string {
    return '/admin/beauty-profile/';
  }

  override get model(): Attribute {
    return this.attribute();
  }
  override get modelId(): number {
    return this.attribute()?.attributeId;
  }
}

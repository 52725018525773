<div class="panel-container">
  <h3 *ngIf="documentId && pageDocument">{{pageDocument.adminPageTitle ? pageDocument.adminPageTitle : documentId}}</h3>

  <p-panel header="Conteúdo">
    <div class="form-field">
      <label for="carousel-1">Banner Principal</label>
      <p-button
        id="carousel-1"
        styleClass="p-button-secondary"
        label="Editar"
        (onClick)="goToEdit()"
      ></p-button>
    </div>
  </p-panel>

  <p-panel header="Divulgação e SEO">
    <form class="meta-tags" [formGroup]="form">
      <div class="form-field">
        <label for="titleMeta">Meta Title (obrigatório)</label>
        <input
          type="text"
          id="titleMeta"
          pInputText
          formControlName="titleMeta"
          [ngClass]="{ error: isMetaTitleInvalid() }"
        />
        <p class="error-message" *ngIf="isMetaTitleInvalid()">
          Meta title é um campo obrigatório.
        </p>
      </div>
      <div class="form-field">
        <label for="descriptionMeta"
          >Meta description - máx. 255 caracteres (obrigatório)</label
        >
        <input
          type="text"
          id="descriptionMeta"
          pInputText
          formControlName="descriptionMeta"
          [ngClass]="{ error: isMetaDescriptionInvalid() }"
        />
        <p class="error-message" *ngIf="isMetaDescriptionInvalid()">
          Meta description é um campo obrigatório e deve ter no máximo 255
          caracteres.
        </p>
      </div>
      <div class="form-field" *ngIf="isGrowthLp">
        <label for="pagePath">Segmento final da url da página (obrigatório)</label>
        <input
          type="text"
          id="pagePath"
          pInputText
          formControlName="pagePath"
          [ngClass]="{ error: isPagePathInvalid() }"
        />
        <p class="error-message" *ngIf="isPagePathInvalid() && !form.controls['pagePath'].errors?.['invalidChar'] && !form.controls['pagePath'].errors?.['invalidPath']">
          Campo obrigatório.
        </p>
        <p class="error-message" *ngIf="form.controls['pagePath'].errors?.['invalidChar']">
          Caracter inválido
        </p>
        <p class="error-message" *ngIf="form.controls['pagePath'].errors?.['invalidPath']">
          Escolha outro nome
        </p>
      </div>
      <div class="form-field">
        <label
          >Meta image - Máx 170kb | WEBP / GIF | Dimensão base: 1200x630
          (obrigatório)</label
        >
        <p-fileUpload
          mode="basic"
          chooseLabel="upload"
          name="file"
          [auto]="true"
          accept="image/webp, image/gif"
          [maxFileSize]="170 * 1024"
          (onSelect)="
            handleFileSelect(
              $event,
              'imageMeta',
              1200,
              630,
              170 * 1024,
              imageMeta
            )
          "
          styleClass="p-button-secondary"
          id="imageMeta"
          #imageMeta
        ></p-fileUpload>
      </div>
      <div class="meta-tags__img">
        <img *ngIf="metaImageUrl" [src]="metaImageUrl" />
      </div>
      <div class="form-field">
        <p-button
          label="Salvar"
          [disabled]="!form.valid"
          (onClick)="save()"
        ></p-button>
      </div>
    </form>
  </p-panel>
</div>




import { Component, OnInit, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { lastValueFrom, map } from 'rxjs';
import { UserControllerService, UserRoleViewResponse } from 'src/app/admin-api';
import { AppDialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  private userController = inject(UserControllerService);
  private title = inject(Title);

  user: UserRoleViewResponse | undefined;

  async ngOnInit(): Promise<void> {
    this.title.setTitle('Minha conta');
    try {
      this.user = await lastValueFrom(
        this.userController.getInfo().pipe(map((data) => data.result))
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
  }
}

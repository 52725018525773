/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Category, CategoryImages, Shops } from '@infrab4a/connect';
import { MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { FileControllerService } from 'src/app/admin-api';
import { ImageUploadComponent } from 'src/app/components/image-upload/image-upload.component';
import { ShopCategoryService } from 'src/app/connect-api/api/shop/shop-category.service';
import { getShopsArray } from 'src/app/connect-api/enums/ShopMap';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-shop-category-images',
  templateUrl: './shop-category-images.component.html',
  styleUrl: './shop-category-images.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class ShopCategoryImagesComponent
  extends ImageUploadComponent<CategoryImages>
  implements OnInit, OnChanges
{
  @ViewChildren(FileUpload) fileUploadElements: QueryList<FileUpload>;
  @Input({ required: true }) category: Category;

  override form = new FormGroup({
    Glamshop: this.imageForm,
    mensmarket: this.imageForm
  });

  maxFileSize = 10 * 1024 * 1024;

  constructor(
    private shoppingCategoryService: ShopCategoryService,
    override router: Router,
    override fileService: FileControllerService,
    private messageService: MessageService
  ) {
    super(fileService, router);
  }

  ngOnInit(): void {
    this.startPage();
  }

  startPage() {
    this.form.patchValue(this.category.images);
    this.availableShops?.forEach((s) => {
      if (this.category.shops.includes(s)) this.form.get(s).enable();
      else this.form.get(s).disable();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['category']) {
      this.startPage();
    }
  }

  async submit(): Promise<void> {
    try {
      LoaderService.showLoader();
      this.category.images = this.form.getRawValue() as CategoryImages;
      const { metadata, ...category } = this.category;
      await this.shoppingCategoryService.updateCategory(
        ShopCategoryService.categoryUpdateDTO(category)
      );

      await this.afterSubmit();
      this.messageService.add({
        severity: 'success',
        detail: `Categoria atualizada com sucesso!`,
        summary: 'Sucesso'
      });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  getShop(shop: Shops) {
    return getShopsArray().find((s) => s.value === shop);
  }

  getOtherShop(shop: Shops) {
    return getShopsArray().find((s) => s.value !== shop);
  }

  getFileUploadElement(id: string) {
    return this.fileUploadElements.find((f) => f.name === id);
  }

  copyImages(field: string, shop: Shops) {
    const image = this.form.get(shop).get(field).value;
    this.shops.forEach((s) => {
      if (
        this.form.get(s).get(field).value &&
        this.form.get(s).get(field).value !== image
      ) {
        this.imagesReplaced.push(this.form.get(s).get(field).value);
      }
      if (this.form.get(s).get(field).enabled)
        this.form.get(s).get(field).setValue(image);
    });
  }

  imageDiff(field: string) {
    return (
      this.shops.some(
        (s) =>
          this.category.shops.includes(s) && !this.form.get(s).get(field).value
      ) ||
      this.shops.reduce((list, s) => {
        if (!list.includes(this.form.get(s).get(field).value))
          list.push(this.form.get(s).get(field).value);
        return list;
      }, []).length !== 1
    );
  }

  public override get filePath(): string {
    return '/admin/shop/products/categories/';
  }

  get defaultImg(): string {
    return 'assets/images/no_img_available.jpg';
  }

  get disableButton(): boolean {
    return (
      this.category &&
      this.form &&
      this.category?.images &&
      Object.keys(this.category.images).every(
        (s) =>
          this.category.images[s]?.image ===
            this.form.getRawValue()[s]?.image &&
          this.category.images[s]?.brandBanner ===
            this.form.getRawValue()[s]?.brandCategoryBanner &&
          this.category.images[s]?.brandBannerMobile ===
            this.form.getRawValue()[s]?.brandCategoryBannerMobile &&
          this.category.images[s]?.image ===
            this.form.getRawValue()[s]?.image &&
          this.category.images[s]?.brandBanner ===
            this.form.getRawValue()[s]?.brandCategoryBanner &&
          this.category.images[s]?.brandBannerMobile ===
            this.form.getRawValue()[s]?.brandCategoryBannerMobile
      )
    );
  }

  override get model() {
    return this.category.images;
  }

  override get modelId() {
    return Number(this.category.id);
  }

  get imageForm() {
    return new FormGroup({
      image: new FormControl<string>(null),
      brandBanner: new FormControl<string>(null),
      brandBannerMobile: new FormControl<string>(null),
      sameImage: new FormControl(false),
      sameBrandBanner: new FormControl(false),
      sameBrandBannerMobile: new FormControl(false)
    });
  }

  get availableShops() {
    return Object.keys(this.form.controls);
  }

  get shops() {
    return this.availableShops.filter((s) => this.form.get(s).enabled);
  }
}

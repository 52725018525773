<app-table
  *ngIf="person"
  [lazy]="false"
  [elements]="history"
  [cols]="cols"
  sortField="dateCreated"
  [sortOrder]="1"
  [actionButtons]="actionButtons"
  (actionButtonClick)="handleEvent($event)"
  [showHeader]="false"
  [emptyMessage]="person.name + ' ainda não vinculou seu Instagram'"
></app-table>
<p-confirmPopup></p-confirmPopup>
<p-toast></p-toast>

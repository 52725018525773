<ng-container *ngIf="product">
  <div class="page-title">Detalhes do produto</div>
  <div class="details product-details">
    <div class="details__left">
      <div class="card card-user">
        <div class="image"></div>
        <div class="card-body">
          <div class="user">
            <img
              class="avatar"
              [src]="product.defaultImageURL"
              *ngIf="product.defaultImageURL"
            />
            <img
              class="avatar"
              src="assets/images/no_img_available.jpg"
              *ngIf="!product.defaultImageURL"
            />
            <h4>{{ product.productName }}</h4>
            <span class="rating">
              <p-rating
                [formControl]="rating"
                [stars]="5"
                [readonly]="true"
                [cancel]="false"
              >
                <ng-template pTemplate="onicon">
                  <i class="pi pi-star-fill"></i>
                </ng-template>
                <ng-template pTemplate="officon">
                  <i class="pi pi-star"></i>
                </ng-template>
              </p-rating>
              &nbsp;
              {{ product.ratingAverage }}
            </span>
            <span>
              {{ product.reviewCount | number : '1.0-0' }} avaliações
            </span>
            <p *ngIf="brand">
              <b>Marca</b>:
              <a
                class="data-link"
                [routerLink]="'/products/brands/' + brand.brandId"
                >{{ brand.brandName }}</a
              >
            </p>
            <ng-container *ngIf="category && subcategory">
              <p>
                <b>Categoria</b>:
                <a
                  class="data-link"
                  [routerLink]="'/products/categories/' + category.categoryId"
                  >{{ category.displayName }}</a
                >
              </p>
              <p>
                <b>Subcategoria</b>:
                <a
                  [routerLink]="
                    '/products/categories/' + subcategory.categoryId
                  "
                  class="data-link"
                  >{{ category.externalId }}.{{ subcategory.externalId }}
                  {{ subcategory.displayName }}</a
                >
              </p>
            </ng-container>
            <p *ngIf="subcategory && !category">
              <b>Categoria</b>: {{ subcategory.externalId }}
              {{ subcategory.displayName }}
            </p>
            <p>
              <b>BrandEquity</b>: {{ brand?.brandEquity }}
              {{ brandEquityLabel }}
            </p>
            <ng-container *ngIf="defaultProductVariant">
              <p
                *ngIf="
                  defaultProductVariant.cean &&
                  (!defaultProductVariant.internalEAN ||
                    defaultProductVariant.internalEAN !==
                      defaultProductVariant.cean)
                "
              >
                <b>CEAN</b>: {{ defaultProductVariant.cean }}
              </p>
              <p *ngIf="defaultProductVariant.internalEAN">
                <b>EAN</b>: {{ defaultProductVariant.internalEAN }}
              </p>
              <p *ngIf="defaultProductVariant.externalId">
                <b>External ID</b>: {{ defaultProductVariant.externalId }}
              </p>
            </ng-container>
            <p *ngIf="feedbackForm">
              <b>Feeback Form</b>:
              <a
                [routerLink]="'/research/feedbacks/' + feedbackForm.formId"
                class="data-link"
              >
                {{ feedbackForm.formTitle }}
              </a>
            </p>
            <p>
              <b>Estoque aquisição</b>:
              {{ stockAcquisition | number : '1.0-0' }}
            </p>
            <p>
              <b>Estoque fidelização</b>:
              {{ stockFidelization | number : '1.0-0' }}
            </p>
            <p>
              <b>Estoque res. marketing</b>:
              {{ stockReserveMarketing | number : '1.0-0' }}
            </p>
            <p>
              <b>Estoque influencer</b>:
              {{ stockInfluencer | number : '1.0-0' }}
            </p>
            <p *ngIf="shopProduct" class="with-button">
              <span>
                <b> Disponível Loja </b>:
                <a
                  [routerLink]="
                    '/shop-products/catalog/product/' + shopProduct.id
                  "
                  class="data-link"
                >
                  {{ shopProduct.name | titlecase }}
                </a>
              </span>
              <ng-container *ngIf="toSync?.length">
                <p-button
                  icon="pi pi-sync"
                  styleClass="p-button-primary p-button-xs"
                  [text]="true"
                  [rounded]="true"
                  [pTooltip]="'Sincronizar campos divergentes: ' + toSyncString"
                  (onClick)="syncInfo()"
                />
              </ng-container>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="details__right">
      <div class="card">
        <p-tabView
          [activeIndex]="selectedTab"
          (activeIndexChange)="tabChanged($event)"
        >
          <p-tabPanel header="Informações gerais">
            <app-product-form
              [product]="product"
              [category]="subcategory"
              [brands]="brands"
              [productVariants]="productVariants"
              [feedbackForms]="feedbackForms"
              [categories]="categories"
              [images]="images"
              *ngIf="brands && productVariants && feedbackForms && categories"
              (updated)="updateProduct($event)"
              [finalities]="finalities"
              [productFinalities]="productFinalities"
              [shopProduct]="shopProduct"
            />
          </p-tabPanel>
          <p-tabPanel
            header="Variantes ({{
              productVariants?.length || 0 | number : '1.0-0'
            }})"
          >
            <app-table
              *ngIf="
                variantCols &&
                productVariants &&
                selectedProductVariant === undefined
              "
              [cols]="variantCols"
              [elements]="productVariants"
              modelName="variantes"
              sortField="externalId"
              [sortOrder]="1"
              [lazy]="false"
              linkTarget="_action"
              [actionFields]="[
                'productVariantName',
                'productVariantId',
                'externalId'
              ]"
              [showTitle]="false"
              addLabel="Nova variante"
              (linkAction)="selectedProductVariant = $event.value"
              selectionActionIcon="pi pi-ban"
              selectionActionLabel="Inativar trocas"
              selectionActionTooltip="Inativar a troca das variantes selecionadas"
              (emitSelection)="disableTrades($event)"
              [addButtonAction]="true"
              (addButtonClick)="selectedProductVariant = null"
              [changeColumns]="true"
            ></app-table>

            <ng-container
              *ngIf="selectedProductVariant || selectedProductVariant === null"
            >
              <p-button
                [text]="true"
                [rounded]="true"
                pRipple
                label="Voltar"
                icon="pi pi-arrow-left"
                (onClick)="selectedProductVariant = undefined"
              ></p-button>
              <app-product-variant-form
                [product]="selectedProductVariant"
                [productId]="productId"
                [images]="images"
                [productVariants]="productVariants"
                (buttonAction)="variantAction($event)"
              >
              </app-product-variant-form>
            </ng-container>
          </p-tabPanel>
          <p-tabPanel header="Estoque">
            <app-product-stock
              [products]="stockTable"
              [productId]="productId"
              *ngIf="stockTable?.length"
            ></app-product-stock>
          </p-tabPanel>
          <p-tabPanel
            header="Envios em edições ({{
              compositions?.length | number : '1.0-0'
            }})"
          >
            <app-table
              *ngIf="compositionCols && compositions"
              [cols]="compositionCols"
              [elements]="compositions"
              modelName="composições"
              sortField="compositionId"
              [sortOrder]="1"
              [lazy]="false"
              tableTitle="Envios em edições"
              [addButton]="false"
              [changeColumns]="true"
            ></app-table>
          </p-tabPanel>
          <p-tabPanel header="Movimentações" *ngIf="productId">
            <ng-template pTemplate="content">
              <app-product-movements
                [productId]="productId"
                *ngIf="productId"
              ></app-product-movements>
            </ng-template>
          </p-tabPanel>
          <p-tabPanel
            header="Imagens ({{ images?.length | number : '1.0-0' }})"
          >
            <app-product-images
              [images]="images"
              [productId]="productId"
              *ngIf="images && productVariants"
              [productVariants]="productVariants"
              (imagesChanged)="images = $event"
            ></app-product-images>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>
  </div>
</ng-container>
<div class="product-form" *ngIf="!product">
  <div class="page-title">Novo produto</div>
  <app-product-form
    [brands]="brands"
    [categories]="categories"
    [feedbackForms]="feedbackForms"
    [finalities]="finalities"
    *ngIf="brands && feedbackForms && categories"
  >
  </app-product-form>
</div>

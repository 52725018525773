import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-customization',
  template: ''
})
export abstract class CustomizationComponent {
  documentId: string;
  newCampaignPage: string;
  editCampaignPage: string;

  constructor(protected router: Router) {}

  abstract getDocument(): void;

  deleteSection?(section: any): void;

  onReorder?(): void;

  navigate(path: string) {
    this.router.navigate([path]);
  }
}

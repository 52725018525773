import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxPermissionsService } from 'ngx-permissions';
import { ConfirmationService, MessageService } from 'primeng/api';
import { lastValueFrom, map } from 'rxjs';
import {
  BadgeActionType,
  BadgeControllerService,
  BadgeRule,
  BadgeRuleDetailed,
  PeriodType,
  PersonType
} from 'src/app/admin-api';
import { Role, roleAsObject } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-badge-rule-form',
  templateUrl: './badge-rule-form.component.html',
  providers: [MessageService, ConfirmationService]
})
export class BadgeRuleFormComponent implements OnInit {
  @Input()
  model: BadgeRuleDetailed | null | undefined;

  @Input()
  badgeId: number | undefined;

  @Output()
  buttonAction = new EventEmitter<BadgeRule | null>();

  private badgeService = inject(BadgeControllerService);
  private messageService = inject(MessageService);
  private permissionsService = inject(NgxPermissionsService);

  form: FormGroup;
  personTypes: Array<PersonType>;
  actionTypes: Array<BadgeActionType>;
  periodTypes: Array<PeriodType>;
  permission = false;

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader();
    this.permission = await this.permissionsService.hasPermission([
      roleAsObject(Role.Full_Administrator).enumValue
    ]);
    await Promise.all([
      this.getPersonTypes(),
      this.getActionTypes(),
      this.getPeriodTypes()
    ]);
    this.form = new FormGroup({
      badgeRuleId: new FormControl<number | undefined>(
        {
          disabled: true,
          value: this.model?.badgeRuleId
        },
        Validators.required
      ),
      badgeId: new FormControl<number | undefined>(
        {
          value: this.model?.badgeId || this.badgeId,
          disabled: !this.permission
        },
        Validators.required
      ),
      personType: new FormControl<number | undefined>(
        { value: this.model?.personTypeId, disabled: !this.permission },
        Validators.required
      ),
      description: new FormControl<string | undefined>(
        { value: this.model?.description, disabled: !this.permission },
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(255)
        ]
      ),
      actionType: new FormControl<number | undefined>(
        { value: this.model?.actionType, disabled: !this.permission },
        Validators.required
      ),
      periodType: new FormControl<number | undefined>(
        { value: this.model?.periodType, disabled: !this.permission },
        Validators.required
      ),
      periodValue: new FormControl<number | undefined>(
        {
          value: this.model?.periodValue || 0,
          disabled:
            this.model && this.permission && this.model.periodType !== 5
              ? false
              : true
        },
        [Validators.required, Validators.min(1)]
      ),
      quantity: new FormControl<number | undefined>(
        { value: this.model?.quantity || 0, disabled: !this.permission },
        [Validators.required, Validators.min(1)]
      )
    });
    if (this.model?.badgeRuleId) {
      this.form.controls['badgeRuleId'].enable();
    }
    LoaderService.showLoader(false);
  }

  async submit(): Promise<void> {
    if (this.form?.valid) {
      LoaderService.showLoader();
      try {
        if (this.model?.badgeRuleId) {
          this.model = await lastValueFrom(
            this.badgeService
              .updateBadgeRule(this.form.getRawValue())
              .pipe(map((data) => data.result))
          );
          this.messageService.add({
            severity: 'success',
            detail: 'Regra atualizada com sucesso.',
            summary: 'Sucesso'
          });
        } else {
          this.model = await lastValueFrom(
            this.badgeService
              .createBadgeRule(this.form.getRawValue())
              .pipe(map((data) => data.result))
          );
          this.messageService.add({
            severity: 'success',
            detail: 'Regra cadastrada com sucesso.',
            summary: 'Sucesso'
          });
        }
        this.buttonAction.emit(this.model);
      } catch (error: any) {
        LoaderService.showLoader(false);
        AppDialogService.showErrorDialog(error);
      }
    }
  }

  async getPersonTypes(): Promise<void> {
    try {
      this.personTypes = await lastValueFrom(
        this.badgeService.findPersonTypeList().pipe(map((data) => data.result))
      );
    } catch (error: any) {
      LoaderService.showLoader(false);
    }
  }

  async getActionTypes(): Promise<void> {
    try {
      this.actionTypes = await lastValueFrom(
        this.badgeService.findActionTypeList().pipe(map((data) => data.result))
      );
    } catch (error: any) {
      LoaderService.showLoader(false);
    }
  }

  async getPeriodTypes(): Promise<void> {
    try {
      this.periodTypes = await lastValueFrom(
        this.badgeService.findPeriodTypeList().pipe(map((data) => data.result))
      );
    } catch (error: any) {
      LoaderService.showLoader(false);
    }
  }

  onChange() {
    if (this.form.value.periodType !== 5) {
      this.form.get('periodValue').enable();
      this.form
        .get('periodValue')
        .setValidators([Validators.required, Validators.min(1)]);
    } else {
      this.form.get('periodValue').disable();
      this.form
        .get('periodValue')
        .setValidators([Validators.required, Validators.min(0)]);
      this.form.get('periodValue').setValue(0);
    }
    this.form.get('periodValue').markAsDirty();
    this.form.get('periodValue').markAsTouched();
    this.form.get('periodValue').updateValueAndValidity();
  }
}

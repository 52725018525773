import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  CouponDTO,
  InfluencerControllerService,
  InfluencerDetail,
  Subscription,
  SubscriptionControllerService,
  SubscriptionType
} from 'src/app/admin-api';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-influencer-coupon-form',
  templateUrl: './influencer-coupon-form.component.html',
  styleUrls: ['./influencer-coupon-form.component.scss']
})
export class InfluencerCouponFormComponent implements OnInit {
  influencer: InfluencerDetail | undefined;
  coupons: Array<CouponDTO>;
  subscriptionTypes: Array<SubscriptionType>;
  subscriptions: Array<Subscription>;

  form = new FormGroup({
    personId: new FormControl<number>(undefined, Validators.required),
    couponId: new FormControl<number>(undefined, Validators.required),
    subscriptionId: new FormControl<number>(1, Validators.required),
    subscriptionTypeId: new FormControl<number>(undefined, Validators.required)
  });

  constructor(
    private config: DynamicDialogConfig,
    private subscriptionService: SubscriptionControllerService,
    private influencerService: InfluencerControllerService,
    private ref: DynamicDialogRef
  ) {}

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader();
    this.influencer = this.config.data.influencer;
    this.form.controls['personId'].setValue(this.influencer.personId);
    await Promise.all([
      this.findSubscriptions(),
      this.findSubscriptionTypes(),
      this.findAvailableCoupons()
    ]);
    LoaderService.showLoader(false);
  }

  async findSubscriptions(): Promise<void> {
    try {
      this.subscriptions = await lastValueFrom(
        this.subscriptionService
          .findSubscriptionList()
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      this.subscriptions = [];
      AppDialogService.showErrorDialog(error);
    }
  }

  async findSubscriptionTypes(): Promise<void> {
    try {
      this.subscriptionTypes = await lastValueFrom(
        this.subscriptionService
          .findSubscriptionTypeList(this.form.value.subscriptionId)
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      this.subscriptionTypes = [];
      AppDialogService.showErrorDialog(error);
    }
  }

  async findAvailableCoupons(): Promise<void> {
    try {
      this.coupons = await lastValueFrom(
        this.influencerService
          .findInfluencerAvailableCoupons(this.influencer.personId)
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      this.coupons = [];
      AppDialogService.showErrorDialog(error);
    }
  }

  async changeSubscription(): Promise<void> {
    LoaderService.showLoader();
    delete this.subscriptionTypes;
    await this.findSubscriptionTypes();
    this.checkSubscriptionTypeRestrictions(true);
    LoaderService.showLoader(false);
  }

  async submit(): Promise<void> {
    if (this.form.valid) {
      LoaderService.showLoader();
      try {
        const coupon = await lastValueFrom(
          this.influencerService.addInfluencerCoupon(this.form.value)
        );
        this.ref.close(coupon);
      } catch (error) {
        LoaderService.showLoader(false);
        AppDialogService.showErrorDialog(error);
      }
    }
  }

  checkSubscriptionTypeRestrictions(change = false): void {
    if (change && !this.form.valid) this.clearErrors();
    if (
      this.selectedCoupon?.influencerStatusTypeIdRestriction &&
      this.selectedCoupon?.influencerStatusTypeIdRestriction !==
        this.influencer.influencerStatusId
    ) {
      this.form.controls['couponId'].setErrors({
        incompatibleInfluencer: 1
      });
    } else if (
      this.selectedCoupon?.subscriptionTypeIds?.length &&
      this.form.value.subscriptionTypeId &&
      !this.selectedCoupon?.subscriptionTypeIds.includes(
        this.form.value.subscriptionTypeId
      )
    ) {
      this.form.controls['couponId'].setErrors({
        incompatibleSubscriptionType: 1
      });
    } else {
      this.clearErrors();
    }
  }

  clearErrors(): void {
    this.form.controls['couponId'].setErrors(null);
  }

  get selectedCoupon() {
    return this.coupons.find((c) => c.couponId === this.form.value.couponId);
  }
}

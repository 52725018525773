import { CurrencyPipe, DatePipe } from '@angular/common';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { PaymentCard } from '@infrab4a/connect';
import { TreeNode } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { map, switchMap } from 'rxjs/operators';
import {
  Attribute,
  AttributeGroup,
  AttributeOption,
  CreditCardRecurrenceWithCard,
  PersonReward
} from '../admin-api';
import { RoleDTO, getRole } from '../models';

export class FormUtil {
  static validarCPF(cpf: string): Observable<boolean> {
    let soma;
    let resto;
    soma = 0;

    cpf = cpf.replace(/[^\d]+/g, '');

    if (cpf === '00000000000') {
      return of(false);
    }

    for (let i = 1; i <= 9; i++) {
      soma = soma + parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
    }
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
      resto = 0;
    }
    if (resto !== parseInt(cpf.substring(9, 10), 10)) {
      return of(false);
    }

    soma = 0;
    for (let i = 1; i <= 10; i++) {
      soma = soma + parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
    }
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
      resto = 0;
    }
    if (resto !== parseInt(cpf.substring(10, 11), 10)) {
      return of(false);
    }
    return of(true);
  }

  static cpfValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.validarCPF(control.value).pipe(
        map((res) => {
          // if res is true, username exists, return true
          return !res ? { invalid: true } : null;
          // NB: Return null if there is no error
        })
      );
    };
  }

  static cardExpirationValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.validateExpiration(control.value).pipe(
        map((res) => {
          // if res is true, username exists, return true
          return !res ? { invalid: true } : null;
          // NB: Return null if there is no error
        })
      );
    };
  }

  static validateExpiration(expiration: string): Observable<boolean> {
    const exp = [expiration.substring(0, 2), expiration.substring(2, 4)];
    if (Number(exp[0]) > 12) {
      return of(false);
    }
    if (Number(exp[1]) + 2000 < new Date().getFullYear()) {
      return of(false);
    }
    const date = new Date(Number(exp[1]) + 2000 + '-' + exp[0] + '-01');
    if (date.getTime() < new Date().getTime()) {
      return of(false);
    }
    return of(true);
  }

  static cardFlagImg(card: CreditCardRecurrenceWithCard | PaymentCard): string {
    if (card) {
      if (
        (!(card as CreditCardRecurrenceWithCard).creditCardFlag ||
          (card as CreditCardRecurrenceWithCard).creditCardFlag === '') &&
        (!(card as PaymentCard).brand || (card as PaymentCard).brand === '')
      ) {
        (card as CreditCardRecurrenceWithCard).creditCardFlag = 'credit-card';
      }
      return (
        'assets/icons/card-flags/' +
        (
          (card as CreditCardRecurrenceWithCard).creditCardFlag ||
          (card as PaymentCard).brand
        )
          .toLowerCase()
          .replace(' ', '') +
        '.svg'
      );
    }
    return 'assets/icons/card-flags/credit-card.svg';
  }

  static getCardFlagId(flagName: string): number {
    switch (flagName.toLowerCase()) {
      case 'visa':
        return 1;
      case 'master':
      case 'mastercard':
      case 'master card':
        return 2;
      case 'amex':
        return 3;
      case 'diners':
        return 4;
      case 'hipercard':
        return 5;
      case 'elo':
        return 6;
      case 'discover':
        return 7;
      case 'aura':
        return 8;
      case 'jcb':
        return 9;
      default:
        return 0;
    }
  }

  static getFlagById(id: number): string {
    switch (id) {
      case 1:
        return 'visa';
      case 2:
        return 'master';
      case 3:
        return 'amex';
      case 4:
        return 'diners';
      case 5:
        return 'hipercard';
      case 6:
        return 'elo';
      case 7:
        return 'discover';
      case 8:
        return 'aura';
      case 9:
        return 'jcb';
      default:
        return 'credit-card';
    }
  }

  static prediction(editionId: number): Date {
    let date = new Date();
    date = new Date(
      date.setMonth(
        Number(
          editionId
            .toString()
            .substring(
              editionId.toString().length - 2,
              editionId.toString().length
            )
        )
      )
    );
    date = new Date(
      date.setFullYear(Number(editionId.toString().substring(1, 5)))
    );
    date = new Date(date.setDate(15));
    return date;
  }

  static getRandomInt(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  static date(): ValidatorFn {
    return (formControl: AbstractControl) => {
      if (!formControl.value) {
        return null;
      }

      const [day, month, year]: string = formControl.value.split('/');
      const dayDate = +day;
      const monthDate = +month;
      const yearDate = +year;

      if (dayDate > 31) {
        return { invalidDate: 'Data inválida' };
      }

      if (monthDate > 12) {
        return { invalidDate: 'Data inválida' };
      }

      if (yearDate < 1000) {
        return { invalidDate: 'Data inválida' };
      }

      return null;
    };
  }

  static beforeToday(): ValidatorFn {
    return (formControl: AbstractControl) => {
      if (!formControl.value) {
        return null;
      }

      const [day, month, year]: string = formControl.value.split('/');

      if (
        new Date(Number(year), Number(month), Number(day)).getTime() >
        new Date().getTime()
      ) {
        return { invalidDate: 'Maior que a data atual' };
      }
      return null;
    };
  }

  static afterToday(): ValidatorFn {
    return (formControl: AbstractControl<Date>) => {
      if (!formControl.value) {
        return null;
      }

      if (formControl.value.getTime() < new Date().getTime()) {
        return { invalidDate: 'Menor que a data atual' };
      }
      return null;
    };
  }

  static afterTodayAsync(): AsyncValidatorFn {
    const subject = new BehaviorSubject<Date>(null);
    const output = subject.asObservable().pipe(
      switchMap((value) => {
        if (!value) {
          return null;
        }

        if (value.getTime() < new Date().getTime()) {
          return of({ invalidDate: 'Menor que a data atual' });
        }
        return of(null);
      })
    );

    return (
      formControl: AbstractControl<Date>
    ): Observable<ValidationErrors | null> => {
      if (!formControl.value) {
        return null;
      }

      subject.next(formControl.value);
      return output;
    };
  }

  static birthdayYear(): ValidatorFn {
    const validator = (formControl: FormControl) => {
      if (!formControl.value) {
        return null;
      }

      const { 2: year }: string = formControl.value.split('/');
      const birthdayYear = +year;

      const currentYear = new Date().getFullYear();
      const numberYearsSubtract = 300;
      const minYearsOld = 18;
      const maxYearsOld = 120;
      const minValidYear = currentYear - numberYearsSubtract;

      if (currentYear - birthdayYear < minYearsOld) {
        return { invalidDate: 'Menor de 18 anos' };
      }

      if (currentYear - birthdayYear > maxYearsOld) {
        return { invalidDate: 'Idade inválida' };
      }

      if (birthdayYear < minValidYear) {
        return { invalidDate: 'Ano inválido' };
      }

      return null;
    };

    return validator as ValidatorFn;
  }

  static monthDiff(d1: Date, d2: Date) {
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  static onlyNumbers(text: string): number {
    return Number(text.replace(/\D/g, ''));
  }

  static stringAsDate(text: string): Date {
    const split = text.split('/');
    return new Date(split[2] + '-' + split[1] + '-' + split[0]);
  }

  static dateStringAsDate(text: string): Date {
    return new Date(text.split('T')[0] + ' 12:00:00');
  }

  static timeDiffLabel(dateStart: string | Date, datePipe: DatePipe): string {
    const today = new Date();
    let startDate: Date;
    if (dateStart instanceof Date) {
      startDate = new Date(dateStart.getTime());
    } else {
      startDate = new Date(dateStart);
    }
    const diffMs = Math.abs(today.getTime() - startDate.getTime()); // milliseconds between now & startDate
    const diffDays = Math.floor(diffMs / 86400000); // days
    const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
    if (diffDays > 0) {
      if (startDate.getHours() === 0 && startDate.getMinutes() === 0) {
        return datePipe.transform(startDate, 'dd/MM/yyyy') || '';
      }
      return datePipe.transform(startDate, 'dd/MM/yyyy HH:mm:ss') || '';
    } else if (diffMins > 0 && diffHrs < 1) {
      return `Há ${diffMins} minuto(s)`;
    } else if (diffHrs >= 1 && diffHrs < 2) {
      return `Há 1 hora`;
    } else if (diffHrs >= 2 && diffHrs < 13) {
      return `Há ${diffHrs} horas`;
    } else if (startDate.getHours() === 0 && startDate.getMinutes() === 0) {
      return datePipe.transform(startDate, 'dd/MM/yyyy') || '';
    } else if (diffMins <= 0 && diffMs > 0) {
      return 'Agora';
    } else {
      return datePipe.transform(startDate, 'dd/MM/yyyy HH:mm:ss') || '';
    }
  }

  static couponDiscountValue(
    value: number,
    couponType: number,
    currencyPipe: CurrencyPipe
  ): string {
    return couponType === 0
      ? `${value * 100} %`
      : (currencyPipe.transform(value, 'BRL') as string);
  }

  static isDateBefore(date: Date, dateMax: Date): boolean {
    return date.getTime() <= dateMax.getTime();
  }

  public static getRolePrincipal(roles?: string): string {
    if (!roles) return 'NONE';

    const split = roles.split(',');
    let principal: RoleDTO = {};
    for (const role of split) {
      const value = getRole(role);
      if (
        value &&
        (!principal.value ||
          (value.order as number) < (principal.order as number))
      ) {
        principal = value;
      }
    }
    return principal.enumValue || 'NONE';
  }

  public static semAcento(string: string): string {
    return string
      .trim()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }

  public static getSlug(string: string): string {
    return this.semAcento(string).replace(' ', '_').toLowerCase();
  }

  public static cpfFormatted(cpf: number): string {
    return cpf
      .toString()
      .padStart(11, '0')
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  }

  public static phoneFormatted(phone: number): string {
    return phone
      .toString()
      .padStart(11, '0')
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2');
  }

  public static editionDate(editionId: number): string {
    return `${((editionId % 1000000) % 100).toString().padStart(2, '0')}/${(
      (editionId % 1000000) /
      100
    ).toFixed(0)}`;
  }

  public static editionImage(editionId: number): string {
    return (
      'https://s3.amazonaws.com/Glambox.Content.MediaObject/Edition/' +
      (editionId / 1000000 >= 1 ? editionId : '1' + editionId) +
      '_boxEdition.png'
    );
  }

  public static getColor(
    subscriptionId: number | null | undefined,
    installments: number | undefined,
    subscriptionTypeId: number | undefined
  ): string | undefined {
    return subscriptionId !== undefined && subscriptionId !== null
      ? this.colors[subscriptionId].find(
          (c) =>
            c.installments === installments &&
            (c.subscriptionTypeId === undefined ||
              c.subscriptionTypeId === subscriptionTypeId)
        )?.color
      : this.colors[0][this.colors[0].length - 1].color;
  }

  public static get colors(): {
    [key: number]: Array<{
      installments?: number;
      subscriptionTypeId?: number;
      color: string;
    }>;
  } {
    return {
      1: [
        { color: '#FE357B', installments: 12 },
        { color: '#c09cea', installments: 1, subscriptionTypeId: 70 },
        { color: '#ffc0de', installments: 1, subscriptionTypeId: 1 },
        { color: '#FE86B0', installments: 6 },
        { color: '#4d4d4d' }
      ],
      0: [
        { color: '#FE357B', installments: 12 },
        { color: '#c09cea', installments: 1, subscriptionTypeId: 70 },
        { color: '#ffc0de', installments: 1, subscriptionTypeId: 1 },
        { color: '#FE86B0', installments: 6 },
        { color: '#4d4d4d' }
      ],
      5: [
        { color: '#8540F5', installments: 12 },
        { color: '#dbbcff', installments: 1 },
        { color: '#BB95F8', installments: 6 },
        { color: '#4d4d4d' }
      ],
      6: [
        { color: '#FF7100', installments: 12 },
        { color: '#ffc89d', installments: 1 },
        { color: '#FFA965', installments: 6 },
        { color: '#4d4d4d' }
      ],
      7: [
        { color: '#008733', installments: 12 },
        { color: '#bafdd2', installments: 1 },
        { color: '#ACD8BD', installments: 6 },
        { color: '#4d4d4d' }
      ]
    };
  }

  public static get productReferencesOnRewardTypes(): Array<number> {
    return [-6, -5, -4, -3, -1, 51];
  }

  public static get personReferencesOnRewardTypes(): Array<number> {
    return [2, 22, 23, 24, 38, 39, 40, 41, 43, 42];
  }

  public static get subscriberReferencesOnRewardTypes(): Array<number> {
    return [10, 12, 14];
  }

  public static linkReference(reward: PersonReward): string | null {
    if (
      this.personReferencesOnRewardTypes.includes(reward.rewardType as number)
    ) {
      return '/users/person/' + reward.referenceId;
    } else if (
      this.productReferencesOnRewardTypes.includes(reward.rewardType as number)
    ) {
      return '/products/catalog/product-variant/' + reward.referenceId;
    } else if (
      this.subscriberReferencesOnRewardTypes.includes(
        reward.rewardType as number
      )
    ) {
      return '/users/subscribers/' + reward.referenceId;
    }
    return null;
  }

  public static subscriberStatusColor(subscriberStatus: number): string {
    switch (subscriberStatus) {
      case 1:
        return '#3f68aa';
      case 2:
        return '#015104';
      case 3:
        return '#87380a';
      case 4:
        return '#6ebdbf';
      case 5:
      case 6:
        return '#657af2';
      default:
        return '#ef4444';
    }
  }

  static validateImageUrl(url: string): Observable<boolean> {
    return of(url.match(/\.(jpeg|jpg|gif|png|webp)/) != null);
  }

  static imageUrlValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.validateImageUrl(control.value).pipe(
        map((res) => {
          // if res is true, username exists, return true
          return !res ? { invalid: true } : null;
          // NB: Return null if there is no error
        })
      );
    };
  }

  public static utcDate(dateWithTimezone: Date | string): Date {
    let date: Date;
    if (dateWithTimezone instanceof Date) {
      date = new Date(dateWithTimezone.getTime());
    } else {
      date = new Date(dateWithTimezone);
    }
    const dateAux = new Date(date.getTime());
    date.setMilliseconds(dateAux.getUTCMilliseconds());
    date.setSeconds(dateAux.getUTCSeconds());
    date.setMinutes(dateAux.getUTCMinutes());
    date.setHours(dateAux.getUTCHours());
    date.setDate(dateAux.getUTCDate());
    date.setMonth(dateAux.getUTCMonth());
    date.setFullYear(dateAux.getUTCFullYear());
    return date;
  }

  static groupToNode(
    g: AttributeGroup,
    expandedGroups?: Array<string>,
    expandedAttributes?: Array<string>,
    onlyActive = false
  ): TreeNode<AttributeGroup> {
    const group = {
      data: g,
      droppable: false,
      key: g.attributeGroupId.toString(),
      type: 'attributeGroup',
      expanded: expandedGroups?.includes(g.attributeGroupId.toString()),
      children: g.attributes
        .filter(
          (a) => !onlyActive || (a.active && a.options?.some((o) => o.active))
        )
        .map((a) => this.attributeToNode(a, expandedAttributes, onlyActive))
        .concat(
          g.groups
            .filter(
              (g) =>
                !onlyActive || (g.active && g.attributes?.some((a) => a.active))
            )
            .map((gg) =>
              this.groupToNode(gg, expandedGroups, expandedAttributes)
            )
        )
    } as TreeNode<AttributeGroup>;
    group.children.sort((c1, c2) => c1.data.order - c2.data.order);
    return group;
  }

  static attributeToNode(
    a: Attribute,
    expandedAttributes?: Array<string>,
    onlyActive = false
  ): TreeNode<Attribute> {
    return {
      data: a,
      droppable: false,
      key: a.attributeId.toString(),
      type: 'attribute',
      expanded: expandedAttributes?.includes(a.attributeId.toString()),
      children: a.options
        .filter((o) => !onlyActive || o.active)
        .map((o) => this.optionToNode(o))
    };
  }

  static optionToNode(o: AttributeOption): TreeNode<AttributeOption> {
    return {
      data: o,
      droppable: false,
      key: o.attributeOptionId.toString(),
      type: 'attributeOption'
    };
  }

  static daysDiffLabel(
    startDate: Date | string,
    endDate: Date | string
  ): string {
    const start = this.utcDate(startDate);
    const end = this.utcDate(endDate);
    const diffHours = Number(
      Math.abs(end.getTime() - start.getTime()) / (1000 * 60 * 60)
    );
    const diffDays = Number((diffHours / 24).toFixed(0));
    const diffMonth = Number((diffDays / 30).toFixed(0));
    const diffYear = Number((diffMonth / 12).toFixed(0));
    let message = 'Há ';
    if (start < end) message = 'Em ';
    if (diffDays < 1) {
      message += Math.ceil(diffHours) + ' hora(s)';
    } else if (diffDays < 30) {
      message += diffDays + ' dia(s)';
    } else if (diffMonth < 12) {
      message += diffMonth + ' mes(es)';
    } else {
      message += diffYear + ' ano(s)';
    }
    return message;
  }

  public static discountLabel(
    value: number,
    couponType: number,
    currencyPipe: CurrencyPipe
  ): string {
    if (couponType) {
      return currencyPipe.transform(value, 'BRL');
    }
    return `${(value * 100).toFixed(2)} %`;
  }

  static getTouchedAndDirtyOnly<T>(form: FormGroup): Partial<T> {
    const obj: Partial<T> = {} as any;
    Object.keys(form.controls)
      .filter((key) => form.controls[key]?.touched && form.controls[key]?.dirty)
      .forEach((name) => {
        obj[name] = form.getRawValue()[name];
      });
    return obj;
  }

  static deepGet(obj, keys: Array<string>) {
    return keys.reduce((xs, x) => xs?.[x] ?? null, obj);
  }

  static deepSet(obj: any, key: string): any {
    const keys = key.split('.');
    let level = { ...obj };
    while (keys.length) {
      const k = keys.shift();
      if (Object.keys(level[k])) level = level[k];
    }
  }

  static objectKeysAsArray(obj: any): Array<string> {
    return Object.keys(obj).reduce((list, key) => {
      if (
        obj[key] &&
        !(obj[key] instanceof Date) &&
        !Array.isArray(obj[key]) &&
        obj[key] instanceof Object
      ) {
        list = list.concat(
          this.objectKeysAsArray(obj[key]).map((k) => `${key}.${k}`)
        );
      } else {
        list.push(key);
      }
      return list;
    }, []);
  }

  static pagePathValidator: ValidatorFn = (pagePathCtrl) => {
    const regExp: RegExp = /^[A-Za-z0-9_-]+$/;
    const unavailablePaths = [
      'social',
      'glampartners',
      'partner',
      'bflu',
      'assinaturas',
      'glambox',
      'glambag',
      'glampass',
      'glamcombo'
    ];
    if (pagePathCtrl.value && !regExp.test(pagePathCtrl.value))
      return { invalidChar: true };
    else if (
      pagePathCtrl.value &&
      unavailablePaths.includes(pagePathCtrl.value)
    )
      return { invalidPath: true };
    return null;
  };

  static isOnlyNumbers(value: string): boolean {
    return /^\d+$/.test(value);
  }

  static onlyUnique(value: any, index: number, array: any[], idField?: string) {
    return (
      array.findIndex((v) =>
        idField ? v[idField] === value[idField] : v === value
      ) === index
    );
  }

  static onlyUniques(array: any[], idField?: string) {
    return array.filter((v, i) => FormUtil.onlyUnique(v, i, array, idField));
  }

  static getImageLinksInObject(obj: object) {
    return Object.keys(obj)
      .map((key) => {
        if (
          obj[key] &&
          typeof obj[key] === 'string' &&
          obj[key].includes('https://')
        )
          return obj[key];
        if (obj[key] && typeof obj[key] === 'object') {
          return this.getImageLinksInObject(obj[key]);
        }
        return null;
      })
      .filter((obj: string | string[]) => obj)
      .reduce((images: string[], obj: string | string[]) => {
        if (Array.isArray(obj) && obj.length) images = images.concat(obj);
        else if (!Array.isArray(obj)) images.push(obj);
        return images;
      }, []);
  }

  static trimAllStrings(form: FormGroup): void {
    Object.keys(form.controls).forEach((c) => {
      if (
        form.get(c) instanceof AbstractControl &&
        typeof form.value[c] === 'string'
      ) {
        form.get(c).setValue(form.value[c]?.trim());
      } else if (form.get(c) instanceof FormGroup) {
        this.trimAllStrings(form.get(c) as FormGroup);
      }
    });
  }

  static diff_months(startDate: Date, endDate: Date) {
    return Math.max(
      0,
      (endDate.getFullYear() - startDate.getFullYear()) * 12 -
        startDate.getMonth() +
        endDate.getMonth()
    );
  }

  static gatewayImage(gatewayId: number): string {
    switch (gatewayId) {
      case 10:
      case 11:
      case 15:
      case 16:
        return '<img src="https://www.pagar.me/static/logo_pagarme-68c8fd6201a5902cf1e143270fa22ddf.svg" alt="Pagar.Me">';
      case 12:
      case 13:
      case 14:
        return '<img src="https://statics.belvo.io/institutions/text_logos/mercadopago_text.svg" alt="Mercado Pago">';
      case 17:
      case 18:
        return '<img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a2/Adyen_Corporate_Logo.svg/1920px-Adyen_Corporate_Logo.svg.png" alt="Adyen">';
      case 19:
      case 20:
        return '<img src="https://belvo.com/wp-content/themes/belvo/assets/img/belvo.svg" alt="Belvo">';
      default:
        return null;
    }
  }

  static gatewayWebsite(gatewayId: number): string {
    switch (gatewayId) {
      case 10:
      case 11:
      case 15:
      case 16:
        return 'https://www.pagar.me';
      case 12:
      case 13:
      case 14:
        return 'https://www.mercadopago.com.br/';
      case 17:
      case 18:
        return 'https://www.adyen.com/pt_BR/';
      case 19:
      case 20:
        return 'https://belvo.com/pt-br/';
      default:
        return null;
    }
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TagModule } from 'primeng/tag';
import { AddressCardComponent } from './address-card.component';

@NgModule({
  declarations: [AddressCardComponent],
  imports: [CommonModule, TagModule],
  exports: [AddressCardComponent]
})
export class AddressCardModule {}

import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  BeautyProfileAttribute,
  BeautyProfileControllerService,
  CompositionBeautyProfileCheck,
  CompositionControllerService,
  CompositionDetails,
  Group
} from 'src/app/admin-api';
import { DropdownFilter, TableColumn } from 'src/app/components/table';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-compositions-beauty-profile',
  templateUrl: './compositions-beauty-profile.component.html',
  styleUrls: ['./compositions-beauty-profile.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class CompositionsBeautyProfileComponent implements OnInit {
  compositions: Array<CompositionDetails> | undefined;
  beautyProfile: Array<Group>;
  beautyProfileOptions: Array<{
    label: string;
    items: Array<{ label: string; value: number; parent: string }>;
  }>;
  attributeOptions: Array<number> = [];
  products: Array<
    | (CompositionBeautyProfileCheck & {
        attribute?: string;
        option?: string;
      })
    | undefined
  >;
  productsCols: Array<TableColumn> = [
    new TableColumn('', '', false, 'checkbox'),
    new TableColumn(
      'SKU Admin',
      'productVariantId',
      true,
      'number',
      '/products/catalog/product-variant/',
      'productVariantId',
      true,
      'in'
    ),
    new TableColumn(
      'InternalEAN',
      'internalEAN',
      true,
      'text',
      '/products/catalog/product-variant/',
      'productVariantId'
    ),
    new TableColumn(
      'MillenniumSKU',
      'externalId',
      true,
      'number',
      '/products/catalog/product-variant/',
      'productVariantId'
    ),
    new TableColumn(
      'Variante',
      'productVariantName',
      true,
      'text',
      '/products/catalog/product-variant/',
      'productVariantId',
      true,
      'in'
    ),
    new TableColumn(
      'Categoria',
      'category',
      true,
      'text',
      '/products/categories/',
      'categoryId',
      true,
      'in'
    ),
    new TableColumn(
      'Atributo',
      'attribute',
      true,
      'text',
      undefined,
      undefined,
      true,
      'in'
    ),
    new TableColumn(
      'Alternativa',
      'option',
      true,
      'text',
      undefined,
      undefined,
      true,
      'in'
    ),
    new TableColumn('Assinantes', 'subscriberCount', false, 'number')
  ];
  dropdownFilters: { [field: string]: Array<DropdownFilter> };

  constructor(
    private config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private beautyProfileService: BeautyProfileControllerService,
    private compositionService: CompositionControllerService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {
    this.compositions = this.config.data.compositions;
    if (!this.compositions?.length) {
      throw 'Por favor informe as composições';
    }
  }

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader();
    try {
      this.beautyProfile = await lastValueFrom(
        this.beautyProfileService
          .findBeautyProfileFormActive()
          .pipe(map((data) => data.result))
      );
      this.beautyProfileOptions = [];
      this.beautyProfile?.forEach((g) => {
        const list: Array<BeautyProfileAttribute | Group> = g.attributes.concat(
          g.attributeGroups
        );
        list.sort((a1, a2) => a1.order - a2.order);
        this.beautyProfileOptions = this.beautyProfileOptions.concat(
          list.reduce(
            (
              attributes: Array<{
                label: string;
                items: Array<{ label: string; value: number; parent: string }>;
                value?: string;
              }>,
              a
            ) => {
              if ((a as BeautyProfileAttribute).options?.length)
                attributes.push({
                  label: ((a as BeautyProfileAttribute).question ||
                    a.name) as string,
                  items:
                    (a as BeautyProfileAttribute).options?.map((o) => ({
                      label: o.name as string,
                      value: o.id as number,
                      parent: ((a as BeautyProfileAttribute).question ||
                        a.name) as string
                    })) || [],
                  value: this.firstAttributeInGroup({ value: a.id })?.name
                });
              else
                attributes = attributes.concat(
                  (a as Group).attributes.map((a) => ({
                    label: ((a as BeautyProfileAttribute).question ||
                      a.name) as string,
                    items:
                      (a as BeautyProfileAttribute).options?.map((o) => ({
                        label: o.name as string,
                        value: o.id as number,
                        parent: ((a as BeautyProfileAttribute).question ||
                          a.name) as string
                      })) || []
                  }))
                );
              return attributes;
            },
            []
          )
        );
      });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  async submit(): Promise<void> {
    LoaderService.showLoader();
    try {
      delete this.products;
      this.products = await lastValueFrom(
        this.compositionService
          .findBeautyProfileCheck({
            attributeOptionIds: this.attributeOptions,
            compositionIds: this.compositions.map((c) => c.compositionId)
          })
          .pipe(
            map((data) => {
              (
                data.result as Array<
                  CompositionBeautyProfileCheck & {
                    attribute?: string;
                    option?: string;
                  }
                >
              ).forEach((i) => {
                i.option = this.attribute(i.attributeOptionId)?.label;
                i.attribute = this.attribute(i.attributeOptionId)?.parent;
              });
              return data.result;
            })
          )
      );
      this.dropdownFilters = {
        productVariantName:
          this.products?.reduce((list: Array<DropdownFilter>, p) => {
            if (!list.some((v) => v.value === p.productVariantName)) {
              list.push({
                label: p.productVariantName,
                value: p.productVariantName
              });
            }
            list.sort((v1, v2) => {
              if (FormUtil.semAcento(v1.label) < FormUtil.semAcento(v2.label))
                return -1;
              if (FormUtil.semAcento(v1.label) > FormUtil.semAcento(v2.label))
                return 1;
              return 0;
            });
            return list;
          }, []) || [],
        category:
          this.products
            ?.filter((p) => p.category)
            ?.reduce((list: Array<DropdownFilter>, p) => {
              if (!list.some((v) => v.value === p.category)) {
                list.push({
                  label: p.category,
                  value: p.category
                });
              }
              list.sort((v1, v2) => {
                if (FormUtil.semAcento(v1.label) < FormUtil.semAcento(v2.label))
                  return -1;
                if (FormUtil.semAcento(v1.label) > FormUtil.semAcento(v2.label))
                  return 1;
                return 0;
              });
              return list;
            }, []) || [],
        attribute:
          this.products?.reduce((list: Array<DropdownFilter>, p) => {
            if (!list.some((v) => v.value === p.attribute)) {
              list.push({
                label: p.attribute,
                value: p.attribute
              });
            }
            list.sort((v1, v2) => {
              if (FormUtil.semAcento(v1.label) < FormUtil.semAcento(v2.label))
                return -1;
              if (FormUtil.semAcento(v1.label) > FormUtil.semAcento(v2.label))
                return 1;
              return 0;
            });
            return list;
          }, []) || [],
        option:
          this.products?.reduce((list: Array<DropdownFilter>, p) => {
            const exists = list.find((v) => v.value === p.attribute);
            if (!exists) {
              list.push({
                label: p.attribute,
                value: p.attribute,
                items: [{ label: p.option, value: p.option }]
              });
            } else if (!exists.items.some((i) => i.value === p.option)) {
              list
                .find((v) => v.value === p.attribute)
                .items.push({
                  label: p.option,
                  value: p.option
                });
            }
            list.sort((v1, v2) => {
              if (FormUtil.semAcento(v1.label) < FormUtil.semAcento(v2.label))
                return -1;
              if (FormUtil.semAcento(v1.label) > FormUtil.semAcento(v2.label))
                return 1;
              return 0;
            });
            return list;
          }, []) || []
      };
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  attribute(attributeOptionId: number): DropdownFilter {
    return this.beautyProfileOptions
      .find((o) => o.items.some((i) => i.value === attributeOptionId))
      .items?.find((i) => i.value === attributeOptionId);
  }

  confirmDelete($event: Array<CompositionBeautyProfileCheck>): void {
    this.confirmationService.confirm({
      acceptLabel: 'Sim',
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: 'Não',
      rejectButtonStyleClass: 'p-button-primary',
      message:
        'Deseja remover as assinantes que receberam os produtos?<br><b>Esta ação não pode ser desfeita!</b>',
      header: 'Remover assinantes',
      accept: async () => {
        await this.removeSubscribers($event);
      }
    });
  }

  async removeSubscribers(
    $event: Array<CompositionBeautyProfileCheck>
  ): Promise<void> {
    LoaderService.showLoader();
    try {
      const detail = await lastValueFrom(
        this.compositionService
          .removeCompositionChecks({
            compositionChecks: $event,
            compositionIds: this.compositions.map((c) => c.compositionId)
          })
          .pipe(map((data) => data.result))
      );
      await this.submit();
      this.messageService.add({
        summary: 'Sucesso',
        detail,
        severity: 'success'
      });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  firstAttributeInGroup(att: { value: any }): Group | undefined {
    const group = this.beautyProfile?.find((g) =>
      g.attributes?.some((a) => a.id === att.value)
    );
    if (group?.attributes && group?.attributes[0].id === att.value) {
      return group;
    }
    return undefined;
  }
}

/**
 * Audit MicroService
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Log {
  id?: string;
  userId?: number;
  email?: string;
  application?: string;
  logDate?: number;
  value?: any;
  operation?: string;
  entity?: string;
  packageName?: string;
  personId?: number;
  subscriberId?: number;
}

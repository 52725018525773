import { Component, OnInit, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { lastValueFrom, map } from 'rxjs';
import { SendFailure } from 'src/app/admin-api';
import { IntegrationControllerService } from 'src/app/admin-api/api/integrationController.service';
import { TableColumn } from 'src/app/components/table';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-fail-export',
  templateUrl: './fail-export.component.html',
  styleUrl: './fail-export.component.scss'
})
export class FailExportComponent implements OnInit {
  private integrationService: IntegrationControllerService = inject(
    IntegrationControllerService
  );
  private title = inject(Title);

  failExport: SendFailure[];

  cols: Array<TableColumn> = [
    new TableColumn('Robô', 'descriptionBot', true, 'text'),
    new TableColumn('SubscriberId', 'subscriberId', true, 'number'),
    new TableColumn('EditionId', 'editionId', true, 'number'),
    new TableColumn('ShippingOrderId', 'shippingOrderId', true, 'number'),
    new TableColumn('MovementId', 'movementId', true, 'number'),
    new TableColumn('Motivo', 'payloadError', true, 'text'),
    new TableColumn('Etapa', 'step', true, 'text'),
    new TableColumn('Data da tentativa', 'dateCreated', true, 'date')
  ];

  ngOnInit(): void {
    this.title.setTitle('Integrações - Falhas de exportação');
    this.fetchFailExport();
  }

  async fetchFailExport(): Promise<void> {
    try {
      LoaderService.showLoader();
      this.failExport = await lastValueFrom(
        this.integrationService
          .findAllFailExport()
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }
}

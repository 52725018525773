import { CurrencyPipe, DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  inject
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, PrimeIcons, SelectItemGroup } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MultiSelect } from 'primeng/multiselect';
import { lastValueFrom, map } from 'rxjs';
import {
  Coupon,
  CouponPriceChange,
  CouponPriceChangeControllerService,
  CouponProductChangeDetail,
  CouponProductChangeDto,
  CouponProductVariantSubscriptionTypeRestriction,
  CouponProductVariantSubscriptionTypeRestrictionChange,
  CouponProductVariantSummary,
  Subscription,
  SubscriptionType
} from 'src/app/admin-api';
import { getSubscriptionName } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';
import { CouponProductVariantsComponent } from '../coupon-product-variants/coupon-product-variants.component';

@Component({
  selector: 'app-coupon-change-schedule-modal',
  templateUrl: './coupon-change-schedule-modal.component.html',
  styleUrl: './coupon-change-schedule-modal.component.scss',
  providers: [DatePipe, CurrencyPipe],
  encapsulation: ViewEncapsulation.None
})
export class CouponChangeScheduleModalComponent implements OnInit {
  @ViewChild(CouponProductVariantsComponent)
  productVariantsComponent: CouponProductVariantsComponent;
  @ViewChild(MultiSelect) multiSelect: MultiSelect;

  private config = inject(DynamicDialogConfig);
  private datePipe = inject(DatePipe);
  private currencyPipe = inject(CurrencyPipe);
  private couponPriceChangeService = inject(CouponPriceChangeControllerService);
  private ref = inject(DynamicDialogRef);
  private cdRef = inject(ChangeDetectorRef);
  private confirmationService = inject(ConfirmationService);

  coupon: Coupon & { subscriptionTypeIds?: number[] } = this.config.data.coupon;
  subscriptions: Array<Subscription> = this.config.data.subscriptions;
  couponPriceChange: CouponPriceChange = this.config.data.schedule;
  scheduledProducts: Array<
    CouponProductChangeDetail & { restrictions?: number[] }
  > = this.couponPriceChange?.productVariants || [];
  productVariants: Array<CouponProductVariantSummary> =
    this.config.data.productVariants;
  restrictions: Array<CouponProductVariantSubscriptionTypeRestriction> =
    this.config.data.restrictions;
  tomorrow = FormUtil.utcDate(
    this.datePipe.transform(
      new Date(new Date().setDate(new Date().getDate() + 1)),
      'yyyy-MM-dd'
    )
  );
  form = new FormGroup({
    couponId: new FormControl(this.coupon?.couponId, Validators.required),
    value: new FormControl<number>(
      this.couponPriceChange?.value || this.coupon?.value || 0,
      [Validators.required, Validators.min(0.01)]
    ),
    couponType: new FormControl<number>(
      this.couponPriceChange?.couponType || this.coupon.couponType,
      Validators.required
    ),
    subscriptionTypeIds: new FormControl<number[]>(
      this.couponPriceChange
        ? this.couponPriceChange.subscriptionTypes?.map(
            (s) => s.subscriptionTypeId
          )
        : this.coupon.subscriptionTypeIds
    ),
    enabled: new FormControl<boolean>(
      this.couponPriceChange
        ? this.couponPriceChange.enabled
        : this.coupon.enabled
    ),
    dateChange: new FormControl<Date>(
      this.couponPriceChange?.dateChange || this.tomorrow,
      [Validators.required]
    ),
    couponPriceChangeId: new FormControl<number>(
      {
        value: this.couponPriceChange?.couponPriceChangeId,
        disabled: !this.couponPriceChange?.couponPriceChangeId
      },
      Validators.required
    ),
    freebieMessage: new FormControl<string>(
      this.couponPriceChange?.freebieMessage || this.coupon.freebieMessage,
      [Validators.required, Validators.maxLength(550)]
    )
  });
  subscriptionTypes: Array<SubscriptionType> =
    this.config.data.subscriptionTypes;
  subscriptionTypesForSelection: Array<SubscriptionType>;
  couponSubscriptionTypes: Array<SubscriptionType> | undefined;
  couponTypes: Array<{ label: string; value: number }> = [
    { label: 'Porcentagem', value: 0 },
    { label: 'Valor Absoluto', value: 1 }
  ];
  confirmation = false;
  changeProducts = false;
  subscriptionTypesTree: SelectItemGroup[];
  productSubscriptionTypesTree: SelectItemGroup[];

  async ngOnInit(): Promise<void> {
    this.configureTree();
    this.configSubscriptionsTree();
    const restrictions =
      this.couponPriceChange?.productVariantsSubcriptionTypeRestrictions ||
      this.restrictions;
    if (restrictions?.length) {
      this.form.controls['restrictedSubscriptionTypes'].setValue(
        restrictions.map(
          (
            r:
              | CouponProductVariantSubscriptionTypeRestriction
              | CouponProductVariantSubscriptionTypeRestrictionChange
          ) =>
            this.subscriptionTypes.find(
              (st) => st.subscriptionTypeId === r.restrictedSubscriptionTypeId
            )
        )
      );
    }
  }

  onCouponTypeChange(): void {
    if (this.form?.value.couponType === 0) {
      this.form.controls['value'].setValidators([
        Validators.required,
        Validators.min(0),
        Validators.max(1)
      ]);
    } else if (this.form?.value.couponType) {
      this.form.controls['value'].setValidators([
        Validators.required,
        Validators.min(0)
      ]);
    }
    this.form?.controls['value'].updateValueAndValidity();
  }

  async submit(): Promise<void> {
    if (!this.form.valid) {
      Object.keys(this.form.controls).forEach((key) => {
        this.form.get(key).markAsTouched();
        this.form.get(key).markAsDirty();
        this.form.get(key).updateValueAndValidity();
      });
      return;
    }

    if (!this.confirmation) {
      LoaderService.showLoader();
      this.scheduledProducts = (
        this.productVariantsComponent?.productVariants || []
      ).map((p) => ({
        ...p,
        restrictions:
          this.productVariantsComponent.subscriptionTypesForm.value.subscriptionTypes?.at(
            p.groupVariant - 1
          ) || []
      }));
      this.scheduledProducts.forEach((p) =>
        p.restrictions.sort(
          (r1, r2) =>
            (this.subscriptionTypes.find((st) => st.subscriptionTypeId === r1)
              ?.subscriptionId || 1) -
            (this.subscriptionTypes.find((st) => st.subscriptionTypeId === r2)
              ?.subscriptionId || 1)
        )
      );
      this.restrictions = (
        this.productVariantsComponent.restrictionForm?.value
          ?.subscriptionTypeIds || []
      ).map((st) => ({
        couponId: this.coupon?.couponId || this.couponPriceChange?.couponId,
        restrictedSubscriptionTypeId: st
      }));
      this.restrictions.sort(
        (r1, r2) =>
          (this.subscriptionTypes.find(
            (st) => st.subscriptionTypeId === r1.restrictedSubscriptionTypeId
          )?.subscriptionId || 1) -
          (this.subscriptionTypes.find(
            (st) => st.subscriptionTypeId === r2.restrictedSubscriptionTypeId
          )?.subscriptionId || 1)
      );
      this.confirmation = true;
      setTimeout(() => {
        LoaderService.showLoader(false);
      }, 1000);
      return;
    }

    LoaderService.showLoader();
    try {
      if (this.couponPriceChange) {
        this.schedule = await lastValueFrom(
          this.couponPriceChangeService
            .updateCouponPriceChange({
              ...this.form.value,
              productVariants: this.productsToDTO(this.scheduledProducts)
            })
            .pipe(map((data) => data.result))
        );
      } else {
        this.schedule = await lastValueFrom(
          this.couponPriceChangeService
            .createPriceChange({
              ...this.form.value,
              productVariants: this.productsToDTO(this.scheduledProducts)
            })
            .pipe(map((data) => data.result))
        );
        this.form.controls.couponPriceChangeId.setValue(
          this.couponPriceChange.couponPriceChangeId
        );
        this.form.controls.couponPriceChangeId.enable();
      }
      this.ref.close(this.couponPriceChange);
    } catch (error) {
      this.back();
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  discountLabel(value: number, couponType: number): string {
    return FormUtil.discountLabel(value, couponType, this.currencyPipe);
  }

  removeProductsConfirmation(): Promise<void> {
    return new Promise((resolve, reject) => {
      LoaderService.showLoader(false);
      this.confirmationService.confirm({
        acceptLabel: 'Sim',
        acceptIcon: PrimeIcons.TRASH,
        acceptButtonStyleClass: 'p-button-danger',
        rejectLabel: 'Voltar',
        rejectButtonStyleClass: 'p-button-primary',
        message: 'Deseja agendar a remoção de todos os brindes do cupom?',
        header: 'Remover brindes',
        accept: async () => {
          LoaderService.showLoader();
          // await this.removeProducts();
          resolve();
        },
        reject: () => {
          reject();
        }
      });
    });
  }

  productsToDTO(
    products: CouponProductChangeDetail[]
  ): CouponProductChangeDto[] {
    return products.map(
      (p) =>
        ({
          couponPriceChangeId: this.couponPriceChange?.couponPriceChangeId || 0,
          couponProductVariantType: p.couponProductVariantType,
          groupVariant: p.groupVariant,
          productVariantId: p.productVariantId,
          quantity: p.quantity,
          couponId: this.coupon.couponId
        } as CouponProductChangeDto)
    );
  }

  back(): void {
    this.confirmation = false;
  }

  subscriptionName(subscriptionId: number): string {
    return getSubscriptionName(subscriptionId);
  }

  configSubscriptionsTree(): void {
    this.productSubscriptionTypesTree = this.subscriptionTypesTree
      .filter((s) =>
        s.items.some(
          (i) =>
            !this.form.value.subscriptionTypeIds.length ||
            this.form.value.subscriptionTypeIds.includes(i.value)
        )
      )
      .map((s) => ({
        ...s,
        items: s.items.filter(
          (i) =>
            !this.form.value.subscriptionTypeIds.length ||
            this.form.value.subscriptionTypeIds.includes(i.value)
        )
      }));
    setTimeout(() => {
      this.multiSelect.el.nativeElement.classList =
        'p-element p-inputwrapper ng-pristine ng-valid ng-star-inserted p-inputwrapper-focus ng-touched';
    });
  }

  configureTree() {
    if (this.subscriptionTypes?.length) {
      this.subscriptionTypesTree = this.subscriptionTypes
        .filter(
          (st) =>
            !this.coupon?.subscriptionTypeIds?.length ||
            this.coupon.subscriptionTypeIds.includes(st.subscriptionTypeId)
        )
        .reduce((list: SelectItemGroup[], st) => {
          const exits = list.find((n) => n.value === st.subscriptionId);
          if (!exits) {
            list.push({
              value: st.subscriptionId,
              label: getSubscriptionName(st.subscriptionId),
              items: [
                {
                  value: st.subscriptionTypeId,
                  label: st.name,
                  title: `${getSubscriptionName(st.subscriptionId)} ${st.name}`
                }
              ]
            });
          } else {
            exits.items.push({
              value: st.subscriptionTypeId,
              label: st.name,
              title: `${getSubscriptionName(st.subscriptionId)} ${st.name}`
            });
          }
          return list;
        }, []);
    }
  }

  subscriptionLabel(subscriptionTypeId: number) {
    const subType = this.subscriptionTypes?.find(
      (st) => st.subscriptionTypeId === subscriptionTypeId
    );
    return subType
      ? `${getSubscriptionName(subType.subscriptionId)} ${subType.name}`
      : 'Não encontrado';
  }

  subscriptionTypeNames(
    subscriptionTypes: number[]
  ): { label: string; value: number }[] {
    return subscriptionTypes.map((r) => ({
      label: this.subscriptionLabel(r),
      value:
        this.subscriptionTypes.find((st) => st.subscriptionTypeId === r)
          ?.subscriptionId || 1
    }));
  }

  backgroundColor(subscriptionId: number) {
    return subscriptionId
      ? FormUtil.colors[subscriptionId][0].color
      : FormUtil.getColor(null, 0, 0);
  }

  get restrictionsNames() {
    return this.subscriptionTypeNames(
      this.restrictions.map((r) => r.restrictedSubscriptionTypeId)
    );
  }

  get subscriptionTypeName() {
    if (!this.form.value.subscriptionTypeIds?.length)
      return [{ label: 'Nenhum' }];
    return this.subscriptionTypeNames(this.form.value.subscriptionTypeIds);
  }

  get couponSubscriptionTypeNames() {
    if (this.couponPriceChange?.subscriptionTypes?.length)
      return this.subscriptionTypeNames(
        this.couponPriceChange.subscriptionTypes.map(
          (s) => s.subscriptionTypeId
        )
      );
    else if (this.couponPriceChange || !this.coupon.subscriptionTypeIds?.length)
      return [{ label: 'Nenhum' }];
    return this.subscriptionTypeNames(this.coupon.subscriptionTypeIds);
  }

  get percentageSuffix(): string {
    return (this.form?.value.value * 100).toFixed(1) + '% de desconto';
  }

  set schedule(couponPriceChange: CouponPriceChange) {
    this.couponPriceChange = couponPriceChange;
  }

  get restrictSubscriptionTypeNamesOld(): string {
    const restrictions =
      this.couponPriceChange?.productVariantsSubcriptionTypeRestrictions ||
      this.restrictions;
    return restrictions?.length
      ? restrictions
          ?.map((r) => {
            const st = this.subscriptionTypes.find(
              (st) => st.subscriptionTypeId === r.restrictedSubscriptionTypeId
            );
            return `${this.subscriptionName(st.subscriptionId)} ${st.name}`;
          })
          .toString()
          .replaceAll(',', ', ')
      : 'Nenhuma';
  }
}

<ng-container *ngIf="coupon">
  <div class="page-title">
    Detalhes do cupom -
    {{ coupon.name }}
  </div>
  <div class="details coupon-details">
    <div class="details__right">
      <p-card>
        <p-tabView
          [activeIndex]="selectedTab"
          (activeIndexChange)="tabChanged($event)"
        >
          <p-tabPanel header="Informações gerais">
            <app-coupon-form
              [model]="coupon"
              [subscriptions]="subscriptions"
              [couponUsageTypes]="couponUsageTypes"
              [couponTypes]="couponTypes"
              [influencerStatusList]="influencerStatusList"
              [subscriptionTypes]="subscriptionTypes"
              (updated)="updateCoupon($event)"
              *ngIf="
                coupon?.subscriptionTypeIds &&
                subscriptions &&
                couponUsageTypes &&
                influencerStatusList &&
                couponTypes &&
                subscriptionTypes
              "
            />
          </p-tabPanel>
          <p-tabPanel header="Produtos">
            <app-coupon-product-variants
              [coupon]="coupon"
              [couponProductVariantSubscriptionTypes]="
                couponProductVariantSubscriptionTypeIdsRestrictions
              "
              *ngIf="
                coupon?.subscriptionTypeIds &&
                subscriptionTypes &&
                couponProductVariantSubscriptionTypeIdsRestrictions
              "
              [subscriptionTypes]="subscriptionTypes"
            />
          </p-tabPanel>
          <p-tabPanel header="Restrições">
            <app-table
              *ngIf="restrictions"
              [lazy]="false"
              [elements]="restrictions"
              [cols]="restrictionsCols"
              tableTitle="E-mails restritos"
              modelName="emails"
              emptyMessage="{{ coupon.name }} não possui restrições de e-mail"
              addLabel="E-mail"
              [addButtonAction]="true"
              selectionActionLabel="Remover"
              selectionActionTooltip="Remove os e-mails do cupom"
              selectionActionClass="p-button-danger"
              selectionActionIcon="pi pi-trash"
              [defaultRows]="10"
              (addButtonClick)="addEmailRestriction()"
              (emitSelection)="removeEmailRestrictions($event)"
              (rowItemChanged)="updateMaxRedeems($event)"
            ></app-table>
          </p-tabPanel>
          <p-tabPanel header="Utilização">
            <app-table
              *ngIf="colsSubscribers && fixedFilters"
              [cols]="colsSubscribers"
              tableTitle="Utilização do cupom"
              sortField="dateCreated"
              [sortOrder]="1"
              [service]="couponService"
              [findPage]="findPage"
              [addButton]="false"
              [fixedFilters]="fixedFilters"
              modelName="utilizações"
              [changeColumns]="true"
              emptyMessage="O cupom ainda não foi utilizado"
            ></app-table>
          </p-tabPanel>
          <p-tabPanel header="Alterações agendadas">
            <app-table
              #changesTable
              [cols]="scheduleCols"
              [service]="couponPriceChangeService"
              pageFunctionName="listAllPageableCouponPriceChange"
              [fixedFilters]="fixedFilters"
              sortField="dateChange"
              [sortOrder]="1"
              addLabel="Agendar alteração"
              addButtonIcon="pi pi-calendar-clock"
              [addButtonAction]="true"
              (addButtonClick)="openNewPriceModal()"
              [actionButtons]="actionButtons"
              (actionButtonClick)="callAction($event)"
              modelName="alterações agendadas"
              *ngIf="fixedFilters"
            ></app-table>
          </p-tabPanel>
        </p-tabView>
      </p-card>
    </div>
  </div>
</ng-container>

<div class="coupon-form" *ngIf="!coupon">
  <div class="page-title">Novo cupom</div>
  <app-coupon-form
    [model]="coupon"
    [subscriptions]="subscriptions"
    [couponUsageTypes]="couponUsageTypes"
    [couponTypes]="couponTypes"
    [influencerStatusList]="influencerStatusList"
    [subscriptionTypes]="subscriptionTypes"
    (updated)="updateCoupon($event)"
    *ngIf="
      subscriptions &&
      couponUsageTypes &&
      couponTypes &&
      influencerStatusList &&
      subscriptionTypes
    "
  />
</div>

<p-toast />
<p-confirmDialog />

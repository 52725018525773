import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { BrandFormComponent } from './brand-form.component';

@NgModule({
  declarations: [BrandFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextModule,
    EditorModule,
    ButtonModule,
    InputNumberModule,
    CheckboxModule,
    FileUploadModule,
    ToastModule
  ],
  exports: [BrandFormComponent]
})
export class BrandFormModule {}

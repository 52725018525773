<app-table
  [lazy]="true"
  [cols]="columns"
  tableTitle="Recorrência sem Billing Pendente"
  [addButton]="false"
  [exportPdf]="false"
  [exportExcel]="false"
  [refreshButton]="false"
  [service]="itOpsService"
  pageFunctionName="getNotPendingRecurrenceTable"
  emptyMessage="Sem mais recorrências sem billings pendentes no momento."
  selectionActionLabel="Criar billing"
  selectionActionTooltip="Criar as billings pendentes que faltam"
  selectionActionIcon="pi pi-plus"
  (emitSelection)="createSelectedBillings($event)"
  modelName="Registros"
  [defaultRows]="15"
  [dropdownFilters]="dropdownFilters"
  [multiSelectFilter]="true"
  *ngIf="dropdownFilters"
></app-table>
<p-confirmDialog></p-confirmDialog>
<p-toast></p-toast>

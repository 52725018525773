import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { ChangeCouponModalModule } from '../change-coupon-modal/change-coupon-modal.module';
import { PersonCardSmallComponent } from './person-card-small/person-card-small.component';
import { PersonCardComponent } from './person-card.component';

@NgModule({
  declarations: [PersonCardComponent, PersonCardSmallComponent],
  imports: [
    CommonModule,
    ButtonModule,
    DynamicDialogModule,
    ChangeCouponModalModule,
    ToastModule,
    TooltipModule,
    ConfirmDialogModule,
    DialogModule,
    NgxPermissionsModule.forChild()
  ],
  exports: [PersonCardComponent, PersonCardSmallComponent]
})
export class PersonCardModule {}

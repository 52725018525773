export interface CompositionProductVariantSummary {
  EAN: string;
  'SKU Admin': string;
  'Millennium SKU': string;
  Qtd: string;
  Marca: string;
  Produto: string;
  Validade: string;
  Valor: string;
  subscriptionId?: number;
}

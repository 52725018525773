<h2>Falhas de exportação</h2>
<div>
  <ng-container *ngIf="failExport && cols">
    <app-table
      [elements]="failExport"
      [addButton]="false"
      [cols]="cols"
      emptyMessage="Nenhuma falha de exportação encontrada."
      [exportExcel]="false"
      [exportPdf]="false"
      [lazy]="false"
      sortField="dateCreated"
      [sortOrder]="1"
      [changeColumns]="true"
      [showTitle]="false"
      modelName="registros"
    ></app-table>
  </ng-container>
</div>

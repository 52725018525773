import { LoginResponse } from 'src/app/models';

const TOKEN_KEY = 'auth-token';
const USER_ID = 'auth-user';
const ROLE_KEY = 'auth-role';

export class TokenStorageService {
  static signOut(): void {
    localStorage.clear();
    sessionStorage.clear();
  }

  static set token(token: string) {
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.setItem(TOKEN_KEY, token);
  }

  static get token(): string {
    const token = localStorage.getItem(TOKEN_KEY);
    return token?.replace('Bearer ', '') || '';
  }

  static set user(userId: string) {
    window.localStorage.removeItem(USER_ID);
    window.localStorage.setItem(USER_ID, userId);
  }

  static set roles(role: string) {
    window.localStorage.removeItem(ROLE_KEY);
    window.localStorage.setItem(ROLE_KEY, role);
  }

  static get userId(): number {
    return Number(localStorage.getItem(USER_ID));
  }

  static get userRoles(): string | null {
    return localStorage.getItem(ROLE_KEY);
  }

  static get userRolesList(): Array<string> | undefined {
    return this.userRoles?.split(',');
  }

  static saveResult(result: LoginResponse): void {
    this.user = result.userId.toString();
    this.token = result.Authorization;
    this.roles = result.roles;
  }

  static get userLogged(): boolean {
    return !!this.userId;
  }

  static set fcmToken(token: string) {
    localStorage.setItem('fcmToken', token);
  }

  static get fcmToken() {
    return localStorage.getItem('fcmToken');
  }
}

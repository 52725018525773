<form [formGroup]="form" class="form-container" (submit)="save()">
  <h3>Chama as Amigas - Tela inicial</h3>

  <p-panel header="Período">
    <div class="form-field">
      <label>Data de início (obrigatório)</label>
      <p-calendar
        [showTime]="true"
        formControlName="beginDate"
        [ngClass]="{ error: isBeginDateInvalid() }"
        [minDate]="today"
        [showButtonBar]="true"
      ></p-calendar>
      <p class="error-message" *ngIf="isBeginDateInvalid()">
        Data é um campo obrigatório.
      </p>
    </div>

    <div class="form-field">
      <label>Data de término</label>
      <p-calendar
        [showTime]="true"
        formControlName="endDate"
        [ngClass]="{ error: isEndDateInvalid() }"
        [minDate]="today"
        [showButtonBar]="true"
      ></p-calendar>
      <p class="error-message" *ngIf="isEndDateInvalid()">Data inválida.</p>
    </div>
  </p-panel>

  <p-panel header="Conteúdo">
    <div class="form-field">
      <label>Title (obrigatório)</label>
      <input
        type="text"
        pInputText
        formControlName="title"
        [ngClass]="{ error: isTitleInvalid() }"
      />
      <p class="error-message" *ngIf="isTitleInvalid()">
        Title é um campo obrigatório.
      </p>
    </div>
    <div class="form-field">
      <label>Alt text - mín. 50 e máx. 125 caracteres (obrigatório)</label>
      <input
        type="text"
        pInputText
        formControlName="altText"
        [ngClass]="{ error: isAltTextInvalid() }"
      />
      <p class="error-message" *ngIf="isAltTextInvalid()">
        Alt text é um campo obrigatório e deve ter entre 50 e 125 caracteres.
      </p>
    </div>
    <div class="form-field">
      <label>Link do banner</label>
      <input
        type="text"
        pInputText
        formControlName="link"
        placeholder="Exemplo: /clube"
      />
    </div>
    <br />
    <div class="form-field">
      <label>
        Banner - Máx 170kb | WEBP / GIF | Dimensão base: 592x150 (obrigatório)
      </label>
      <p-fileUpload
        mode="basic"
        chooseLabel="upload"
        name="demo[]"
        [auto]="true"
        accept="image/webp, image/gif"
        [maxFileSize]="170 * 1024"
        (onSelect)="
          handleFileSelect($event, 'image', 592, 150, 170 * 1024, desktopImage)
        "
        [showUploadButton]="false"
        [showCancelButton]="false"
        #desktopImage
        styleClass="p-button-raised p-button-secondary"
      ></p-fileUpload>
    </div>
    <div class="form-container__img">
      <img *ngIf="bannerUrl" [src]="bannerUrl" />
    </div>
    <br />
    <div class="form-field">
      <p-button
        styleClass="p-button-outlined cancel-button"
        label="Cancelar"
        (onClick)="goToMenu()"
      ></p-button>
    </div>
    <div class="form-field">
      <p-button
        type="submit"
        styleClass="p-button"
        label="Salvar"
        [disabled]="!form.valid"
      ></p-button>
    </div>
  </p-panel>
</form>

<div class="card composition" *ngIf="edition && edition.editionId">
  <span
    class="name"
    [class.name--bag]="edition.editionId.toString()[0] === '5'"
    [class.name--combo]="edition.editionId.toString()[0] === '7'"
  >
    <div class="edition-image" *ngIf="isDesktop && edition.imageURL">
      <app-edition-image
        [imageURL]="edition.imageURL"
        [editionId]="edition.editionId"
        maxWidth="75px"
        maxHeight="75px"
      ></app-edition-image>
    </div>
    <div class="info">
      <strong> {{ edition.editionId }} {{ edition.theme }} </strong>
      <div class="info-block">
        <span class="edition-info">
          <span *ngIf="edition.compositionId">
            <strong>Composição:</strong> {{ edition.compositionName }}
          </span>
          <span *ngIf="edition.invoiceNumber">
            <strong>Nº da nota:</strong> {{ edition.invoiceNumber }}
          </span>
        </span>
        <span class="edition-info">
          <span *ngIf="edition.nfse">
            <strong>Nº da NFS:</strong> {{ edition.nfse }}
          </span>
          <span *ngIf="edition.rps">
            <strong>Nº do RPS:</strong> {{ edition.rps }}
          </span>
        </span>
      </div>
    </div>
    <div
      class="edition-status"
      [class.edition-status--no-action]="!edition.subscriberEditionStatusId"
    >
      <span class="tracking">
        <p-button
          icon="pi pi-truck"
          type="button"
          *ngIf="
            edition.subscriberEditionStatusId &&
            edition.subscriberEditionStatusId > 2
          "
          [rounded]="true"
          size="small"
          (onClick)="openTrackingModal()"
          pTooltip="Ver rastreio"
          tooltipPosition="bottom"
        ></p-button>
        <ng-container *ngIf="showActions">
          <p-button
            icon="pi pi-check-circle"
            type="button"
            *ngIf="
              edition.subscriberEditionStatusId &&
              edition.subscriberEditionStatusId > 0 &&
              edition.subscriberEditionStatusId !== 6
            "
            [rounded]="true"
            severity="success"
            size="small"
            (onClick)="changeStatusDone($event)"
            pTooltip="Marcar como recebida"
            tooltipPosition="bottom"
          ></p-button>
          <p-button
            icon="pi pi-undo"
            type="button"
            *ngIf="edition.subscriberEditionStatusId === 6"
            [rounded]="true"
            severity="danger"
            size="small"
            (onClick)="changeStatusRestored($event)"
            pTooltip="Marcar como devolvida"
            tooltipPosition="bottom"
          ></p-button>
        </ng-container>
        <span
          class="status"
          [class.status--active]="edition.subscriberEditionStatusId === 6"
          [class.status--progress]="
            edition.subscriberEditionStatusId &&
            edition.subscriberEditionStatusId < 6
          "
        >
          {{ edition.subscriberEditionStatusName }}
        </span>
      </span>
      <span *ngIf="edition.dateUpdatedMillenium">
        <strong>Última atualização:</strong>
        {{ edition.dateUpdatedMillenium | date : 'dd/MM/yyyy HH:mm' : 'UTC' }}
      </span>
      <span *ngIf="edition.statusMillenium">
        <strong>Status Millenium:</strong>
        {{ edition.statusMillenium | titlecase }}
      </span>
      <span *ngIf="edition.finalDeliveryDate">
        <strong>Data entrega:</strong>
        {{ edition.finalDeliveryDate | date : 'dd/MM/yyyy HH:mm' : 'UTC' }}
      </span>
    </div>
  </span>
  <div class="products" *ngFor="let ed of editions">
    <div
      class="combo-division"
      *ngIf="editions.length > 1"
      [class.combo-division--bag]="ed.subscriptionId === 5"
    >
      <app-edition-image
        maxWidth="75px"
        maxHeight="75px"
        [editionId]="editionId(ed.subscriptionId)"
      ></app-edition-image>
      &nbsp;{{ ed.subscriptionName }}
    </div>
    <ng-container *ngIf="ed.expanded">
      <div *ngFor="let product of ed.products; let i = index">
        <app-composition-product-detail
          [product]="product"
          [index]="i"
        ></app-composition-product-detail>
      </div>
    </ng-container>
    <ng-container *ngIf="ed.products?.length">
      <div class="show-hide" [class.show-hide--bag]="ed.subscriptionId === 5">
        <a type="button" class="data-link" (click)="ed.expanded = !ed.expanded">
          <i
            class="pi"
            [class.pi-chevron-circle-down]="!ed.expanded"
            [class.pi-chevron-circle-up]="ed.expanded"
          ></i>
          <ng-container *ngIf="!ed.expanded">
            <strong> {{ ed.products?.length }} produtos </strong>
          </ng-container>
          <ng-container *ngIf="ed.expanded"> Ocultar produtos </ng-container>
        </a>
      </div>
    </ng-container>
  </div>
</div>
<p-confirmPopup></p-confirmPopup>

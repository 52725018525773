<div class="card-info" *ngIf="creditCard">
  <img
    loading="lazy"
    *ngIf="cardFlag"
    [src]="cardFlag"
    class="card-info__flag-card"
  />
  <div class="card-info__data">
    <p>
      <b>{{ cardHolder | titlecase }}</b>
    </p>
    <p>
      xxxx xxxx xxxx
      {{ creditCard.lastDigits }}
    </p>
    <p>Válido até: {{ cardExpiration }}</p>
  </div>
</div>

import { Directive, ElementRef, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appValueChange]'
})
export class ValueChangeDirective implements OnChanges {
  constructor(private element: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['innerHTML']?.currentValue !== changes['innerHTML']?.previousValue
    ) {
      (this.element.nativeElement as HTMLElement)?.classList.add(
        'value-change'
      );
      setTimeout(
        () =>
          (this.element.nativeElement as HTMLElement)?.classList.remove(
            'value-changes'
          ),
        2000
      );
    }
  }
}

/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SubscriberRefund {
  subscriberBillingId?: number;
  removeEdition?: number;
  subscriberId?: number;
  paymentMethodId?: number;
  paymentId?: number;
  paymentLogId?: number;
  dateCreated?: Date;
  message?: string;
  amount?: number;
  cancellationProtocol?: string;
  authorizationCode?: string;
  editionId?: number;
  hasRefunded?: number;
  lastSubscriberBillingStatus?: number;
  hasBeenApproved?: number;
  authCode?: string;
  dateRefunded?: Date;
  partialRefund?: number;
}

import { Component, Input } from '@angular/core';
import { CompositionProductVariant } from 'src/app/admin-api/model/compositionProductVariant';
import { TableColumn } from '../table';

@Component({
  selector: 'app-product-variant-compositions',
  templateUrl: './product-variant-compositions.component.html',
  styleUrls: ['./product-variant-compositions.component.scss']
})
export class ProductVariantCompositionsComponent {
  @Input()
  compositions: Array<CompositionProductVariant> | undefined;

  @Input()
  productId: number | undefined;

  cols: Array<TableColumn> = [
    new TableColumn(
      'Id',
      'compositionId',
      true,
      'link',
      '/products/compositions/'
    ),
    new TableColumn('Composição', 'compositionName'),
    new TableColumn('Edição', 'editionName'),
    new TableColumn(
      'Assinantes',
      'subscriberCount',
      false,
      'text',
      undefined,
      undefined,
      true,
      'equals',
      'text-right'
    ),
    new TableColumn('Variante', 'productVariantName'),
    new TableColumn('InternalEAN', 'internalEAN'),
    new TableColumn('Data criação', 'dateCreated', false, 'date')
  ];
  types = [
    { label: 'Todas', value: 'all' },
    { label: 'Troca', value: 'Trade' },
    { label: 'Brinde', value: 'Gift' },
    { label: 'Reenvio', value: 'Resend' },
    { label: 'Incentivo para influencer', value: 'IncentiveInfluencer' }
  ];
}

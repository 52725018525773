import { InjectionToken } from '@angular/core';

export interface BatchService {
  itemsForUpload: any[];
  errors: Error[];
  parse(input: File): Promise<any[]>;
  update(): Promise<Array<any>>;
  get importProgress(): number;
}

export const BATCH_CATEGORY_SERVICE = new InjectionToken<BatchService>(
  'CategoriesBatchService'
);

export const BATCH_PRODUCT_SERVICE = new InjectionToken<BatchService>(
  'ProductsBatchService'
);

export const BATCH_REVIEW_SERVICE = new InjectionToken<BatchService>(
  'ReviewsBatchService'
);

export const BATCH_CATEGORY_MOST_RELEVANT_SERVICE =
  new InjectionToken<BatchService>('CategoryMostRelevantBatchService');

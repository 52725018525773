<app-table
  *ngIf="cols"
  [cols]="cols"
  [service]="feedbackService"
  pageFunctionName="findFeedbackFormsTable"
  sortField="formId"
  [sortOrder]="1"
  modelName="formulários"
  [dropdownFilters]="dropdownFilters"
  addLabel="Novo formulário"
  [addButton]="permissions"
  (booleanAction)="updateForm($event)"
  [changeColumns]="true"
></app-table>

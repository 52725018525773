import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ShopSettings, ShopSettingsRepository } from '@infrab4a/connect';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { FileControllerService } from 'src/app/admin-api';
import { CustomizationFormComponent } from 'src/app/components/customization/customization-form.component';
import { ImageService } from 'src/app/services/image.service';

@Component({
  selector: 'app-lp-club-banners-form',
  templateUrl: './lp-club-banners-form.component.html',
  styleUrls: ['./lp-club-banners-form.component.scss']
})
export class LpClubBannersFormComponent
  extends CustomizationFormComponent
  implements OnInit
{
  pageDocument: ShopSettings;

  constructor(
    @Inject('ShopSettingsRepository')
    private shopSettingsRepository: ShopSettingsRepository,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    override router: Router,
    override imageService: ImageService,
    override config: PrimeNGConfig,
    override fileService: FileControllerService
  ) {
    super(router, config, imageService, fileService);

    this.documentId = 'LP_CLUB';

    this.form = this.formBuilder.group({
      desktopImage: ['', [Validators.required]],
      mobileImage: ['', [Validators.required]],
      title: ['', [Validators.required]],
      altText: [
        '',
        [
          Validators.required,
          Validators.minLength(50),
          Validators.maxLength(125)
        ]
      ],
      link: [''],
      beginDate: [null, [Validators.required]],
      endDate: [null],
      hasMultiples: true
    });
  }

  async ngOnInit() {
    await this.getDocument();
  }

  override async getDocument(): Promise<void> {
    try {
      this.pageDocument = await this.shopSettingsRepository.get({
        id: this.documentId
      });
      this.documentSections = this.pageDocument.sections || [];
      if (this.router.url.includes('edit')) {
        this.activatedRoute.params.subscribe((params) => {
          this.activeIndex = +params['index'];
          this.activeSection = this.documentSections[this.activeIndex];
          this.initializeForm();
        });
      }
    } catch (error) {
      this.form.patchValue(this.activeSection);
    }
  }

  override initializeForm(): void {
    this.form.patchValue(this.activeSection);
  }

  override async save() {
    if (this.form.valid) {
      const doc = this.form.value;

      if (
        doc.beginDate <= this.today &&
        (!doc.endDate || this.today <= new Date(doc.endDate))
      ) {
        doc.active = true;
      } else {
        doc.active = false;
      }

      if (this.activeSection) this.documentSections[this.activeIndex] = doc;
      else this.documentSections.push(doc);

      await this.shopSettingsRepository.update({
        id: this.documentId,
        sections: this.documentSections
      });

      this.messageService.add({
        severity: 'success',
        summary: 'Salvo com sucesso',
        detail: 'As informações foram salvas.'
      });
      await this.afterSubmit();
      this.navigate('/configuracoes/lp-club/banners');
    } else {
      console.error('Formulário inválido. Não é possível salvar os dados.');
    }
  }

  isBeginDateInvalid() {
    const beginDateControl = this.form.get('beginDate');
    return (
      beginDateControl?.invalid &&
      (beginDateControl?.touched || beginDateControl?.dirty)
    );
  }

  isEndDateInvalid() {
    const beginDateControl = this.form.get('beginDate');
    const endDateControl = this.form.get('endDate');

    if (!endDateControl.value) return false;

    if (!beginDateControl.value) return true;

    if (
      new Date(endDateControl.value).setSeconds(0) <=
      new Date(beginDateControl.value).setSeconds(0)
    )
      return true;

    return (
      endDateControl?.invalid &&
      (endDateControl?.touched || endDateControl?.dirty)
    );
  }

  isAltTextInvalid() {
    const altTextControl = this.form.get('altText');
    return (
      altTextControl?.invalid &&
      (altTextControl?.touched || altTextControl?.dirty)
    );
  }

  isTitleInvalid() {
    const titleControl = this.form.get('title');
    return (
      titleControl?.invalid && (titleControl?.touched || titleControl?.dirty)
    );
  }

  async handleFileSelect(
    event: { files: File[] },
    inputName: string,
    expectedWidth: number,
    expectedHeight: number,
    maxWeight: number,
    pInput: FileUpload
  ) {
    this.fileNamePattern = `lp-club-banner-${new Date().getTime()}.webp`;

    await this.upload(
      event,
      inputName,
      expectedWidth,
      expectedHeight,
      maxWeight,
      pInput
    );
  }

  goToMenu() {
    this.navigate('configuracoes/lp-club/banners');
  }

  get desktopBannerUrl(): string {
    return this.form.get('desktopImage')?.value;
  }

  get mobileBannerUrl(): string {
    return this.form.get('mobileImage')?.value;
  }

  override get filePath(): string {
    return '/pages/lp-clube/';
  }

  override get model() {
    return this.pageDocument;
  }

  override get modelId() {
    return this.pageDocument?.id;
  }
}

/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CouponSummary {
  couponId?: number;
  name?: string;
  value?: number;
  valueString?: string;
  couponType?: number;
  products?: number;
  subscriptionTypeIds?: string;
  subscriptionTypeNames?: string;
  maxRedeems?: number;
  enabled?: boolean;
  isRenewal?: number;
  glampoints?: number;
  dateCreated?: Date;
  dateUpdated?: Date;
  dateExpired?: Date;
  freebieCount?: number;
  freebieMessage?: string;
  influencerStatusTypeIdRestriction?: number;
  freeShipping?: number;
  couponUsageTypeId?: number;
  couponUsageType?: string;
  quantityUsed?: number;
  influencerStatus?: string;
}

import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ShopBanner,
  ShopSettings,
  ShopSettingsRepository
} from '@infrab4a/connect';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { FileControllerService } from 'src/app/admin-api';
import { CustomizationFormComponent } from 'src/app/components/customization/customization-form.component';
import { AppDialogService } from 'src/app/services/dialog.service';
import { ImageService } from 'src/app/services/image.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-customization-component',
  templateUrl: './customization-component.component.html',
  styleUrl: './customization-component.component.scss'
})
export class CustomizationComponentComponent
  extends CustomizationFormComponent
  implements OnInit
{
  landingPageId: string;
  landingPageName: string;
  landingPageRoute: string;

  pageDocument: ShopSettings;
  pageBanners: ShopBanner[];

  constructor(
    @Inject('ShopSettingsRepository')
    private shopSettingsRepository: ShopSettingsRepository,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private route: ActivatedRoute,
    override router: Router,
    override imageService: ImageService,
    override config: PrimeNGConfig,
    override fileService: FileControllerService
  ) {
    super(router, config, imageService, fileService);

    this.form = this.formBuilder.group({
      descriptionMeta: ['', [Validators.required, Validators.maxLength(255)]],
      titleMeta: ['', Validators.required],
      imageMeta: ['', Validators.required],
      pagePath: ['', [Validators.required, FormUtil.pagePathValidator]]
    });
  }

  async ngOnInit(): Promise<void> {
    const id = this.route.snapshot.paramMap.get('id');
    this.documentId = id.toUpperCase();
    this.landingPageRoute = id.replaceAll('_', '-');
    await this.getDocument();
    this.initializeForm();
  }

  override async getDocument(): Promise<void> {
    try {
      LoaderService.showLoader();
      this.pageDocument = await this.shopSettingsRepository.get({
        id: this.documentId
      });
      this.pageBanners = this.pageDocument.sections;
    } catch (err) {
      AppDialogService.showErrorDialog(err);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  override initializeForm() {
    if (!this.isGrowthLp) {
      this.form.get('pagePath')?.clearValidators();
      this.form.get('pagePath')?.updateValueAndValidity();
    }
    this.form.patchValue(this.pageDocument);
  }

  override async save() {
    if (this.form.valid) {
      const formValues = { ...this.form.value };
      if (!this.isGrowthLp) {
        delete formValues.pagePath;
      }
      try {
        LoaderService.showLoader();
        await this.shopSettingsRepository.update({
          id: this.documentId,
          ...this.form.value
        });
        this.messageService.add({
          severity: 'success',
          summary: 'Salvo com sucesso',
          detail: 'As informações foram salvas.'
        });

        await this.afterSubmit();
      } catch (err) {
        AppDialogService.showErrorDialog(err);
      } finally {
        LoaderService.showLoader(false);
      }
    } else {
      console.error('Formulário inválido. Não é possível salvar os dados.');
    }
  }

  async handleFileSelect(
    event: { files: File[] },
    inputName: string,
    expectedWidth: number,
    expectedHeight: number,
    maxWeight: number,
    pInput: FileUpload
  ) {
    this.fileNamePattern = `customization-${
      this.documentId
    }-${new Date().getTime()}`;

    await this.upload(
      event,
      inputName,
      expectedWidth,
      expectedHeight,
      maxWeight,
      pInput
    );
  }

  isMetaTitleInvalid() {
    const metaTitleControl = this.form.get('titleMeta');
    return (
      metaTitleControl?.invalid &&
      (metaTitleControl?.touched || metaTitleControl?.dirty)
    );
  }

  isPagePathInvalid() {
    const pagePathControl = this.form.get('pagePath');
    return (
      pagePathControl?.invalid &&
      (pagePathControl?.touched || pagePathControl?.dirty)
    );
  }

  isMetaDescriptionInvalid() {
    const metaDescriptionControl = this.form.get('descriptionMeta');
    return (
      metaDescriptionControl?.invalid &&
      (metaDescriptionControl?.touched || metaDescriptionControl?.dirty)
    );
  }

  goToEdit() {
    this.navigate(
      `configuracoes/${
        this.landingPageRoute
      }-banner/${this.route.snapshot.paramMap.get('id')}`
    );
  }

  get metaImageUrl(): string {
    return this.form.get('imageMeta')?.value;
  }

  get filePath(): string {
    return '/pages/lp-assinaturas/';
  }

  get isGrowthLp(): boolean {
    return this.documentId.includes('LP_GROWTH');
  }

  override get model() {
    return this.pageDocument;
  }

  override get modelId() {
    return this.pageDocument?.id;
  }
}

<form class="form-b4a" [formGroup]="form" (submit)="submit()">
  <span class="p-float-label">
    <input
      type="text"
      pInputText
      formControlName="name"
      name="name"
      id="name"
    />
    <label for="name">Nome *</label>
  </span>
  <span class="p-float-label">
    <input
      type="text"
      pInputText
      formControlName="subtitle"
      name="subtitle"
      id="subtitle"
    />
    <label for="subtitle">Subtítulo *</label>
  </span>
  <span class="p-float-label">
    <p-dropdown
      [autoDisplayFirst]="false"
      [options]="businessUnits"
      formControlName="businessUnitId"
      name="businessUnitId"
      inputId="businessUnitId"
    />
    <label for="businessUnitId">Negócio *</label>
  </span>
  <span class="p-float-label">
    <input
      type="text"
      pInputText
      formControlName="author"
      name="author"
      id="author"
    />
    <label for="author">Autor *</label>
  </span>
  <span class="p-float-label">
    <input
      type="text"
      pInputText
      formControlName="link"
      name="link"
      id="link"
    />
    <label for="link">Link</label>
  </span>
  <span class="full-w input-switch">
    <label for="active">Inativo</label>
    <p-inputSwitch formControlName="active" inputId="active" />
    <label for="active">Ativo</label>
  </span>
  <span class="image-upload full-w">
    <div class="file-upload">
      <span class="p-float-label">
        <input
          type="text"
          [disabled]="true"
          pInputText
          formControlName="image"
        />
        <label for="image">Imagem</label>
      </span>
      <p-fileUpload
        mode="basic"
        chooseLabel="Selecionar"
        name="file"
        [auto]="true"
        accept="image/*"
        [maxFileSize]="1000000"
        uploadIcon="pi pi-file"
        [customUpload]="true"
        (uploadHandler)="onUpload($event, 'image', fileUpload)"
        id="image"
        #fileUpload
        [disabled]="form.controls.image.disabled"
      />
    </div>
    <span class="image">
      <img *ngIf="form.value.image" [src]="form.value.image" />
      <a
        type="button"
        class="delete-icon"
        (click)="form.controls.image.reset()"
      >
        <i
          class="pi pi-times-circle"
          pTooltip="Remover imagem"
          *ngIf="form.value.image"
        ></i>
      </a>
    </span>
  </span>
  <span class="p-input-editor">
    <span class="label-option">
      <label for="description">Descrição </label>
      <span class="p-input-checbox">
        <p-checkbox
          formControlName="html"
          [binary]="true"
          inputId="html"
          name="html"
          (onChange)="format()"
        />
        <label for="html">Ver código fonte</label>
      </span>
    </span>
    <p-editor
      [readonly]="!permission"
      formControlName="description"
      id="description"
      (keyup)="cdRef.detectChanges()"
      (click)="cdRef.detectChanges()"
      [hidden]="form.value.html"
      [modules]="{
        toolbar: { handlers, container: toolbarOptions },
        blotFormatter2: {
          resize: {
            handleClassName: 'dimensioned'
          }
        }
      }"
    >
      <ng-template pTemplate="header" />
    </p-editor>
    <textarea
      name="description"
      id="description"
      formControlName="description"
      *ngIf="form.value.html"
      rows="50"
    >
    </textarea>
    <small class="hint" [class.danger]="!form.controls.description.valid">
      {{ form.value.description?.trim().length || 0 | number : '1.0-0' }}/4.000
    </small>
  </span>
  <span class="buttons">
    <p-button label="Salvar" [disabled]="!form.valid" type="submit" />
  </span>
</form>
<input type="file" hidden id="inputFile" (change)="handleFile($event)" />

import { DecimalPipe } from '@angular/common';
import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Category, Product } from '@infrab4a/connect';
import { MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { ErpControllerService } from 'src/app/admin-api';
import {
  DropdownFilter,
  TableColumn,
  TableComponent
} from 'src/app/components/table';
import { ShopCategoryService } from 'src/app/connect-api/api/shop/shop-category.service';
import { ShopProductService } from 'src/app/connect-api/api/shop/shop-product.service';
import { NullValue } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FileUtil } from 'src/app/utils/file.util';

@Component({
  selector: 'app-shop-products',
  templateUrl: './shop-products.component.html',
  styleUrl: './shop-products.component.scss',
  providers: [DecimalPipe]
})
export class ShopProductsComponent implements OnInit {
  @ViewChild(TableComponent) table: TableComponent;
  private title = inject(Title);
  protected productService = inject(ShopProductService);
  protected categoryService = inject(ShopCategoryService);
  private erpService = inject(ErpControllerService);
  private messageService = inject(MessageService);

  categoriesForProducts: Category[];

  cols = [
    new TableColumn.Builder()
      .setHeader('ID')
      .setField('id')
      .setRouterLink('product/')
      .setRouterLinkFieldName('id')
      .setCondition('equals')
      .build(),
    new TableColumn.Builder()
      .setHeader('Nome')
      .setField('name')
      .setRouterLink('product/')
      .setRouterLinkFieldName('id')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('EAN')
      .setField('EAN')
      .setRouterLink('product/')
      .setRouterLinkFieldName('id')
      .build(),
    new TableColumn.Builder()
      .setHeader('SKU')
      .setField('sku')
      .setRouterLink('product/')
      .setRouterLinkFieldName('id')
      .build(),
    new TableColumn.Builder()
      .setHeader('Tipo')
      .setField('type')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('Marca')
      .setField('brand')
      .setCondition('contains')
      .setRouterLink('/shop-products/categories/category/')
      .setRouterLinkFieldName('category_id')
      .build(),
    new TableColumn.Builder()
      .setHeader('Possui variantes?')
      .setField('hasVariants')
      .setType('boolean')
      .build(),
    new TableColumn.Builder()
      .setHeader('Quantidade')
      .setField('stock')
      .setDisplayFunction(
        (product: Partial<Product>) => product.stock?.quantity || 0
      )
      .setType('formattedInteger')
      .setCondition('gte')
      .build(),
    new TableColumn.Builder()
      .setHeader('Ativo')
      .setField('published')
      .setType('inputSwitch')
      .build(),
    new TableColumn.Builder()
      .setHeader('Label')
      .setField('label')
      .setType('text')
      .setSortable(false)
      .setCondition('equals')
      .setDisplayFunction((product: Partial<Product>) =>
        ShopProductService.getProductLabel(product.label)
      )
      .build(),
    new TableColumn.Builder()
      .setHeader('Categoria')
      .setField('categoryId')
      .setType('text')
      .setSortable(false)
      .setRouterLink('/shop-products/categories/category/')
      .setRouterLinkFieldName('categoryId')
      .setDisplayFunction((product: Partial<Product>) => {
        const category = this.categoriesForProducts?.find(
          (cat) => cat.id.toString() === product.category?.id.toString()
        );
        return category?.reference
          ? `${category.reference} ${category.name}`
          : '';
      })
      .build(),
    new TableColumn.Builder()
      .setHeader('Grupo')
      .setField('group')
      .setType('text')
      .setSortable(false)
      .setCondition('equals')
      .setDisplayFunction(
        (product: Partial<Product>) => product.group ?? 'Sem grupo'
      )
      .build(),
    new TableColumn.Builder()
      .setHeader('Vigente')
      .setField('validity')
      .setType('boolean')
      .setSortable(false)
      .setCondition('equals')
      .build(),
    new TableColumn.Builder()
      .setHeader('Tags')
      .setField('tags')
      .setType('text')
      .setSortable(false)
      .setCondition('in')
      .build(),
    new TableColumn.Builder()
      .setHeader('Filtros')
      .setField('filters')
      .setType('text')
      .setSortable(false)
      .setCondition('in')
      .build(),
    new TableColumn.Builder()
      .setHeader('Data criação')
      .setField('createdAt')
      .setType('date')
      .setCondition('between')
      .build(),
    new TableColumn.Builder()
      .setHeader('Últ. atualização')
      .setField('updatedAt')
      .setType('date')
      .setCondition('between')
      .build()
  ];
  filterValues: { [field: string]: any | Array<any> } = {};
  dropdownFilters: {
    [field: string]: Array<DropdownFilter>;
  } = {
    published: [
      { label: 'Todos', value: NullValue },
      { label: 'Sim', value: true },
      { label: 'Não', value: false }
    ],
    hasVariants: [
      { label: 'Todos', value: NullValue },
      { label: 'Sim', value: true },
      { label: 'Não', value: false }
    ],
    label: [
      { label: 'Todos', value: '' },
      { label: 'Sem label', value: NullValue },
      { label: 'Outlet', value: 'outlet' },
      { label: 'Promoção', value: 'on-sale' },
      { label: 'Últimas unidades', value: 'last-units' },
      { label: 'Glamstar', value: 'glamstar' }
    ],
    categoryId: [{ label: 'Todos', value: '' }],
    validity: [
      { label: 'Todos', value: NullValue },
      { label: 'Sim', value: true },
      { label: 'Não', value: false }
    ],
    group: [
      { label: 'Todos', value: NullValue },
      { label: 'Grupo 1', value: 1 },
      { label: 'Grupo 2', value: 2 },
      { label: 'Grupo 3', value: 3 },
      { label: 'Grupo 4', value: 4 },
      { label: 'Grupo 5', value: 5 },
      { label: 'Grupo 6', value: 6 },
      { label: 'Grupo 7', value: 7 },
      { label: 'Grupo 8', value: 8 }
    ]
  };

  async ngOnInit() {
    this.title.setTitle('Loja - Produtos');
    this.categoriesForProducts =
      await this.categoryService.getCategoriesForProduct();
    this.dropdownFilters['categoryId'] = [{ label: 'Todos', value: '' }].concat(
      this.categoriesForProducts.map((cat) => {
        return {
          label: cat.reference,
          value: cat.id
        };
      })
    );
  }

  async updatePublished(product: Product) {
    LoaderService.showLoader();
    try {
      await this.productService.updateProduct({
        id: product.id,
        published: product.published
      });
      await this.table.refresh();
      this.messageService.add({
        severity: 'success',
        detail: 'Produto atualizado com sucesso.'
      });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async syncMillennium(): Promise<void> {
    try {
      LoaderService.showLoader();
      await lastValueFrom(this.erpService.executeSyncShopProduct());
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso',
        detail: 'Sincronização realizada'
      });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async productVariantFullReport() {
    LoaderService.showLoader(true);
    const result = await this.productService.productVariantReport();
    const cols = [
      'id',
      'name',
      'description',
      'slug',
      'sku',
      'ean',
      'cest',
      'ncm',
      'has_variants',
      'is_variant',
      'grade',
      'type',
      'is_kit',
      'tags',
      'published',
      'stock',
      'brand',
      'brand_description',
      'images',
      'miniatures',
      'video',
      'weight',
      'cost_price',
      'full_price',
      'price',
      'subscriber_discount_percentage',
      'subscriber_price',
      'how_to_use',
      'who_must_use',
      'differentials',
      'ingredients',
      'purpose',
      'filters',
      'category_id',
      'outlet',
      'label',
      'group',
      'validity',
      'gender',
      'shopping_count',
      'rating',
      'reviews_total',
      'created_at',
      'updated_at'
    ];
    FileUtil.exportExcel(result, 'Produtos e variantes', cols);
    setTimeout(() => {
      LoaderService.showLoader(false);
    }, 500);
  }
}

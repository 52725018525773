/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PersonAttributeLogDetail {
  id?: number;
  personId?: number;
  attributeId?: number;
  oldAttributeOptionId?: number;
  dateCreated?: Date;
  customerIODate?: Date;
  dateUpdated?: Date;
  newAttributeOptionId?: number;
  attributeQuestion?: string;
  oldOption?: string;
  newOption?: string;
}

import { DatePipe } from '@angular/common';
import {
  Component,
  HostListener,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { UIChart } from 'primeng/chart';
import {
  SubscriberControlGroupByDate,
  SubscriptionType
} from 'src/app/admin-api';
import { FormUtil } from 'src/app/utils/form.util';
@Component({
  selector: 'app-subscriber-group-counter-chart',
  templateUrl: './subscriber-group-counter-chart.component.html',
  styleUrls: ['./subscriber-group-counter-chart.component.scss'],
  providers: [DatePipe]
})
export class SubscriberGroupCounterChartComponent implements OnInit {
  @ViewChild('chart')
  chart: UIChart | undefined;

  @Input()
  subscriberGroupCounters: Array<SubscriberControlGroupByDate> | undefined;

  @Input()
  graphType: 'daily' | 'monthly' = 'daily';

  @Input()
  width: string = null;

  @Input()
  height: string = null;

  @Input()
  responsive = true;

  @Input()
  subscriptionTypes: Array<SubscriptionType> | undefined;

  chartData:
    | {
        labels: Array<string>;
        datasets: Array<{
          type: 'pie' | 'doughtnut' | 'line' | 'bar' | 'radar' | 'polarArea';
          label: string;
          borderWidth?: number;
          backgroundColor?: string;
          borderColor?: string;
          fill?: boolean;
          tension?: number;
          data: Array<number>;
          subscriptionId?: number;
        }>;
      }
    | undefined;
  chartConfig = {
    options: {
      animation: {
        easing: 'easeInQuad'
      }
    }
  };
  largeScreen?: boolean;
  showChart = false;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.checkScreenSize();
  }

  constructor(private datePipe: DatePipe) {
    this.checkScreenSize();
  }

  checkScreenSize(): void {
    this.largeScreen = window.innerWidth > 1600;
    if (this.largeScreen && this.responsive) {
      this.height = '600px';
      this.width = '800px';
    } else if (this.responsive) {
      delete this.width;
      delete this.height;
    }
    this.resize();
  }

  ngOnInit(): void {
    this.chartData = {
      labels: this.xAxis,
      datasets: []
    };
    const subscriptionTypes: Array<{
      subscriptionId: number;
      name: string;
      installments: number;
      subscriptionTypeId: number;
    }> =
      this.subscriberGroupCounters?.reduce(
        (
          list: Array<{
            subscriptionId: number;
            name: string;
            installments: number;
            subscriptionTypeId: number;
          }>,
          sub
        ) => {
          if (sub.name?.includes('Anual.')) {
            sub.name = sub.name.replace('Anual.', 'Anual/Mês');
          }
          if (sub.name && !list.some((i) => i.name === sub.name)) {
            list.push({
              name: sub.name,
              subscriptionId: Number(
                ((sub.editionId || 0) / 1000000).toFixed(0)
              ),
              installments:
                this.subscriptionTypes?.find(
                  (st) => st.subscriptionTypeId === sub.subscriptionTypeId
                )?.installments || 0,
              subscriptionTypeId: sub.subscriptionTypeId as number
            });
          }
          return list;
        },
        []
      ) || [];
    subscriptionTypes.sort((s1, s2) => {
      if (s1.subscriptionId < s2.subscriptionId) {
        return -1;
      } else if (s1.subscriptionId > s2.subscriptionId) {
        return 1;
      } else if (s1.name < s2.name) {
        return -1;
      } else if (s1.name > s2.name) {
        return 1;
      }
      return 0;
    });
    subscriptionTypes.forEach((s) => {
      this.chartData?.datasets.push({
        type: 'line',
        label: s.name,
        borderColor: FormUtil.getColor(
          s.subscriptionId,
          s.installments,
          s.subscriptionTypeId
        ),
        backgroundColor: FormUtil.getColor(
          s.subscriptionId,
          s.installments,
          s.subscriptionTypeId
        ),
        borderWidth: 2,
        subscriptionId: s.subscriptionId,
        data:
          this.graphType === 'daily'
            ? this.xAxis.map(
                (day) =>
                  this.subscriberGroupCounters
                    ?.filter(
                      (sub) =>
                        this.datePipe.transform(
                          FormUtil.utcDate(sub.dateCreated),
                          'dd/MM/yyyy'
                        ) === day && sub.name === s.name
                    )
                    .reduce((sum, s) => (sum += s.subscriberCount || 0), 0) || 0
              )
            : this.xAxis.map(
                (month) =>
                  this.subscriberGroupCounters
                    ?.filter(
                      (sub) =>
                        this.datePipe.transform(
                          FormUtil.utcDate(sub.dateCreated),
                          'MM/yyyy'
                        ) === month && sub.name === s.name
                    )
                    .reduce((sum, s) => (sum += s.subscriberCount || 0), 0) || 0
              ),
        fill: false
      });
    });
    this.chartData.datasets.push({
      type: 'bar',
      label: 'Total',
      backgroundColor: FormUtil.getColor(undefined, undefined, undefined),
      data:
        this.graphType === 'daily'
          ? this.xAxis.map(
              (day) =>
                this.subscriberGroupCounters
                  ?.filter(
                    (sub) =>
                      this.datePipe.transform(
                        FormUtil.utcDate(sub.dateCreated),
                        'dd/MM/yyyy'
                      ) === day
                  )
                  .reduce((sum, s) => (sum += s.subscriberCount || 0), 0) || 0
            )
          : this.xAxis.map(
              (month) =>
                this.subscriberGroupCounters
                  ?.filter(
                    (sub) =>
                      this.datePipe.transform(
                        FormUtil.utcDate(sub.dateCreated),
                        'MM/yyyy'
                      ) === month
                  )
                  .reduce((sum, s) => (sum += s.subscriberCount || 0), 0) || 0
            )
    });
    this.showChart = true;
  }

  resize(): void {
    this.showChart = false;
    setTimeout(() => {
      this.showChart = true;
    });
  }

  get xAxis(): Array<string> {
    if (this.graphType === 'daily')
      return (
        this.subscriberGroupCounters?.reduce((list: Array<string>, sub) => {
          if (
            sub.dateCreated &&
            !list.some(
              (i) =>
                i ===
                this.datePipe.transform(
                  FormUtil.utcDate(sub.dateCreated),
                  'dd/MM/yyyy'
                )
            )
          ) {
            list.push(
              this.datePipe.transform(
                FormUtil.utcDate(sub.dateCreated),
                'dd/MM/yyyy'
              ) as string
            );
          }
          return list;
        }, []) || []
      );
    const first = this.subscriberGroupCounters
      ? new Date(this.subscriberGroupCounters[0].dateCreated as any)
      : null;
    const last =
      this.subscriberGroupCounters?.length &&
      this.subscriberGroupCounters?.length > 1
        ? new Date(
            this.subscriberGroupCounters[
              this.subscriberGroupCounters.length - 1
            ].dateCreated as any
          )
        : new Date();
    if (first && last) {
      const months: Array<string> = [];
      for (let date = first; date < last; date.setMonth(date.getMonth() + 1)) {
        months.push(this.datePipe.transform(date, 'MM/yyyy') as string);
      }
      return months;
    }

    return [];
  }
}

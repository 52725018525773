import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MenuItem } from 'src/app/models';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
  selectedSite: any = '';
  name = '';

  sites: MenuItem[] = [
    {
      name: 'glam',
      code: 'AU',
      pages: [
        {
          cname: 'Painel',
          code: 'configuracoes/painel'
        },
        {
          name: 'Chama as Amigas',
          code: 'AU',
          subPages: [
            {
              cname: 'Tela Inicial',
              code: 'configuracoes/call-friends/home-screen'
            },
            {
              cname: 'Chame e Ganhe',
              code: 'configuracoes/call-friends/call-and-win'
            },
            {
              cname: 'Ganhe glampoints',
              code: 'configuracoes/call-friends/earn-glampoints'
            },
            {
              cname: 'Postagens',
              code: 'configuracoes/call-friends/posts'
            }
          ]
        },
        {
          cname: 'LP Clube',
          code: 'configuracoes/lp-club'
        },
        // {
        //   cname: 'Vitrine',
        //   code: 'configuracoes/vitrine'
        // },
        {
          cname: 'Edições',
          code: 'configuracoes/editions'
        },
        {
          name: 'Favoritos',
          code: 'AU',
          subPages: [
            {
              cname: 'Temas',
              code: 'configuracoes/wishlist/themes'
            },
            {
              cname: 'Banners',
              code: 'configuracoes/wishlist/banners'
            }
          ]
        },
        {
          cname: 'Home',
          code: 'configuracoes/glam/home'
        },
        {
          cname: 'Banner campanha',
          code: 'configuracoes/dms/glamshop/featuredCampaignBanner'
        },
        {
          cname: 'Collections de lojinhas',
          code: 'configuracoes/collections-lojinhas'
        }
      ]
    },
    {
      name: 'Mensmarket',
      code: 'AU',
      pages: [
        {
          cname: 'Clube',
          code: 'configuracoes/mensmarket-clube/mensmarket_clube'
        },
        {
          cname: 'Prime',
          code: 'configuracoes/mensmarket-prime/mensmarket_prime'
        },
        {
          cname: 'Select',
          code: 'configuracoes/mensmarket-select/mensmarket_select'
        },
        {
          cname: 'Promocoes',
          code: 'configuracoes/mensmarket-promocoes/mensmarket_promocoes'
        },
        {
          cname: 'Home',
          code: 'configuracoes/dms/mens_market'
        },
        {
          cname: 'Banner campanha',
          code: 'configuracoes/dms/mens_market/featuredCampaignBanner'
        }
      ]
    },
    {
      name: 'LPs Promocionais',
      code: 'AU',
      pages: [
        {
          cname: 'LP Assinaturas',
          code: 'configuracoes/lp-signatures/lp_signatures'
        },
        {
          cname: 'LP glambox',
          code: 'configuracoes/lp-glambox/lp_glambox'
        },
        {
          cname: 'LP glambag',
          code: 'configuracoes/lp-glambag/lp_glambag'
        },
        {
          cname: 'LP glampass',
          code: 'configuracoes/lp-glampass/lp_glampass'
        },
        {
          cname: 'LP glamcombo',
          code: 'configuracoes/lp-glamcombo/lp_glamcombo'
        },
        {
          cname: 'LP convite',
          code: 'configuracoes/lp-glamgirl/lp_glamgirl'
        },
        {
          cname: 'LP partners',
          code: 'configuracoes/lp-partners/lp_partners'
        },
        {
          cname: 'LP ambassador',
          code: 'configuracoes/lp-ambassadors/lp_ambassadors'
        },
        {
          cname: 'LP royal',
          code: 'configuracoes/lp-royals/lp_royals'
        },
        {
          cname: 'LP Edição',
          code: 'configuracoes/lp-edition/lp_edition'
        },
        {
          cname: 'LP Edição glambox',
          code: 'configuracoes/lp-edition-glambox/lp_edition_glambox'
        },
        {
          name: 'LPs Growth',
          code: 'AU',
          subPages: [
            {
              cname: 'LP Growth 1',
              code: 'configuracoes/lp-growth-1/lp_growth_1'
            },
            {
              cname: 'LP Growth 2',
              code: 'configuracoes/lp-growth-2/lp_growth_2'
            },
            {
              cname: 'LP Growth 3',
              code: 'configuracoes/lp-growth-3/lp_growth_3'
            },
            {
              cname: 'LP Growth 4',
              code: 'configuracoes/lp-growth-4/lp_growth_4'
            },
            {
              cname: 'LP Growth 5',
              code: 'configuracoes/lp-growth-5/lp_growth_5'
            }
          ]
        }
      ]
    },
    {
      name: 'Marcas Próprias',
      code: 'AU',
      pages: [
        {
          cname: 'Glam Beauty',
          code: 'configuracoes/glam-beauty'
        },
        {
          cname: 'Malmo',
          code: 'configuracoes/malmo'
        },
        {
          cname: 'Mens',
          code: 'configuracoes/mens'
        }
      ]
    }
  ];

  constructor(private router: Router, private title: Title) {
    this.title.setTitle('Customização do site');
  }

  onChange(event: any) {
    if (!event.code) return;
    this.router.navigate([event.code]);
  }
}

export enum ZipRange {
  ZERO_START = 1000000,
  ZERO_END = 9999999,
  ONE_START = 10000000,
  ONE_END = 19999999,
  TWO_START = 20000000,
  TWO_END = 29999999,
  THREE_START = 30000000,
  THREE_END = 39999999,
  FOUR_START = 40000000,
  FOUR_END = 49999999,
  FIVE_START = 50000000,
  FIVE_END = 59999999,
  SIX_START = 60000000,
  SIX_END = 69999999,
  SEVEN_START = 70000000,
  SEVEN_END = 79999999,
  EIGHT_START = 80000000,
  EIGHT_END = 89999999,
  NINE_START = 90000000,
  NINE_END = 99999999
}

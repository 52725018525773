import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AcquisitionAndReactivationReprocessComponent } from './acqusition-and-reactivation-reprocess/acquisition-and-reactivation-reprocess.component';
import { ActiveSubscriptionNewComponent } from './active-subscription-new/active-subscription-new.component';
import { CanceledWithPendingBillingComponent } from './canceled-with-pending-billing/canceled-with-pending-billing.component';
import { CardDifferenceComponent } from './card-difference/card-difference.component';
import { CardOtherSubscriptionTypesComponent } from './card-other-subscription/card-other-subscription.component';
import { DatabaseSessionsComponent } from './database-sessions/database-sessions.component';
import { DontReceiveGiftComponent } from './dont-receive-gift/dont-receive-gift.component';
import { DoubleCommissionComponent } from './double-commission/double-commission.component';
import { DuplicatedRewardComponent } from './duplicated-reward/duplicated-reward.component';
import { FailedPaymentDataComponent } from './failed-payment-data/failed-payment-data.component';
import { IncorrectEndEditionComponent } from './incorrect-end-edition/incorrect-end-edition.component';
import { IncorrectPendingBillingComponent } from './incorrect-pending-billing/incorrect-pending-billing.component';
import { IncorrectSubscriberStatusComponent } from './incorrect-subscriber-status/incorrect-subscriber-status.component';
import { InfolessAddressComponent } from './infoless-address/infoless-address.component';
import { ItOpsComponent } from './it-ops.component';
import { LogsComponent } from './logs/logs.component';
import { MuchPendingBillingsComponent } from './much-pending-billings/much-pending-billings.component';
import { NotAttemptedPaymentComponent } from './not-attempted-payment/not-attempted-payment.component';
import { NotPendingRecurrenceComponent } from './not-pending-recurrence/not-pending-recurrence.component';
import { NullAddressComponent } from './null-address/null-address.component';
import { PaidBillingSameMonthComponent } from './paid-billing-same-month/paid-billing-same-month.component';
import { PaymentSameMonthPaymentComponent } from './payment-same-month-payment/payment-same-month-payment.component';
import { PaymentWithoutBillingComponent } from './payment-without-billing/payment-without-billing.component';
import { PendingWhithoutRecurrenceComponent } from './pending-whithout-recurrence/pending-whithout-recurrence.component';
import { RecurrenceIsNullComponent } from './recurrence-is-null/recurrence-is-null.component';
import { RenewalReprocessComponent } from './renewal-reprocess/renewal-reprocess.component';
import { RenewalWrongPriceComponent } from './renewal-wrong-price/renewal-wrong-price.component';
import { TwoSubscriptionsInOneCpfComponent } from './two-subscriptions-in-one-cpf/two-subscriptions-in-one-cpf.component';
import { WrongRecurrenceDateComponent } from './wrong-recurrence-date/wrong-recurrence-date.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard'
  },
  {
    path: 'dashboard',
    component: ItOpsComponent
  },
  {
    path: 'incorrectEndEdition',
    component: IncorrectEndEditionComponent
  },
  {
    path: 'cardDifference',
    component: CardDifferenceComponent
  },
  {
    path: 'doubleCommission',
    component: DoubleCommissionComponent
  },
  {
    path: 'paymentSameMonth',
    component: PaymentSameMonthPaymentComponent
  },
  {
    path: 'paidBillingsSameMonth',
    component: PaidBillingSameMonthComponent
  },
  {
    path: 'notPendingRecurrence',
    component: NotPendingRecurrenceComponent
  },
  {
    path: 'wrongRecurrenceDate',
    component: WrongRecurrenceDateComponent
  },
  {
    path: 'pendingWithoutRecurrence',
    component: PendingWhithoutRecurrenceComponent
  },
  {
    path: 'muchPendingBillings',
    component: MuchPendingBillingsComponent
  },
  {
    path: 'canceledWithPendingBilling',
    component: CanceledWithPendingBillingComponent
  },
  {
    path: 'incorrectPendingBilling',
    component: IncorrectPendingBillingComponent
  },
  {
    path: 'failedPaymentData',
    component: FailedPaymentDataComponent
  },
  {
    path: 'activeSubscriptionNew',
    component: ActiveSubscriptionNewComponent
  },
  {
    path: 'incorrectSubscriberStatus',
    component: IncorrectSubscriberStatusComponent
  },
  {
    path: 'cardOtherSubscription',
    component: CardOtherSubscriptionTypesComponent
  },
  {
    path: 'dontReceiveGift',
    component: DontReceiveGiftComponent
  },
  {
    path: 'notAttemptedPayment',
    component: NotAttemptedPaymentComponent
  },
  {
    path: 'recurrenceIsNull',
    component: RecurrenceIsNullComponent
  },
  {
    path: 'database-sessions',
    component: DatabaseSessionsComponent
  },
  {
    path: 'duplicatedReward',
    component: DuplicatedRewardComponent
  },
  {
    path: 'nullAddress',
    component: NullAddressComponent
  },
  {
    path: 'twoSubscriptionsInOneCpf',
    component: TwoSubscriptionsInOneCpfComponent
  },
  {
    path: 'acquisitionAndReactivationReprocess',
    component: AcquisitionAndReactivationReprocessComponent
  },
  {
    path: 'renewalReprocess',
    component: RenewalReprocessComponent
  },
  {
    path: 'infolessAddress',
    component: InfolessAddressComponent
  },
  {
    path: 'paymentWithoutBilling',
    component: PaymentWithoutBillingComponent
  },
  {
    path: 'renewalReprocess',
    component: RenewalReprocessComponent
  },
  {
    path: 'renewalWrongPrice',
    component: RenewalWrongPriceComponent
  },
  {
    path: 'logs',
    component: LogsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ItOpsRoutingModule {}

<form class="modal-form" (submit)="submit()">
  <p-pickList
    [source]="availableEditions"
    [target]="selectedEditions"
    sourceHeader="Edições disponíveis"
    targetHeader="Edições a estornar"
    [dragdrop]="true"
    [responsive]="true"
    [sourceStyle]="{ height: '30vh' }"
    [targetStyle]="{ height: '30vh' }"
    breakpoint="768px"
    [showSourceControls]="false"
    [showTargetControls]="false"
  >
    <ng-template pTemplate="item" let-billing>
      {{ billingLabel(billing) }}
    </ng-template>
  </p-pickList>
  <span class="p-float-label">
    <textarea
      id="message"
      rows="5"
      cols="30"
      pInputTextarea
      [(ngModel)]="message"
      name="message"
      required
    ></textarea>
    <label for="message">Escreva uma descrição sobre o motivo do estorno</label>
    <small [class.danger]="message?.length > 255">
      {{ message?.length || 0 }}/255 caracteres
    </small>
  </span>
  <span class="buttons">
    <p-button
      label="Voltar"
      size="small"
      (onClick)="ref.close()"
      type="button"
    />
    <p-button
      label="Estornar"
      severity="danger"
      size="small"
      type="submit"
      [disabled]="
        !message?.length || !selectedEditions.length || message?.length > 255
      "
    />
  </span>
</form>

import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { lastValueFrom, map } from 'rxjs';
import {
  ItOpsControllerService,
  RewardControllerService,
  RewardType
} from 'src/app/admin-api';
import { DuplicatedReward } from 'src/app/admin-api/model/duplicatedReward';
import {
  DropdownFilter,
  TableColumn,
  TableComponent
} from 'src/app/components/table';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-duplicated-reward',
  templateUrl: './duplicated-reward.component.html',
  styleUrls: ['./duplicated-reward.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class DuplicatedRewardComponent implements OnInit {
  @ViewChild(TableComponent) table: TableComponent;

  columns: Array<TableColumn> = [
    new TableColumn('', '', false, 'checkbox'),
    new TableColumn(
      'PersonId',
      'personId',
      true,
      'number',
      '/users/person/',
      'personId'
    ),
    new TableColumn('RewardId', 'rewardId', true, 'number'),
    new TableColumn('RewardType', 'rewardTypeName', false, 'text'),
    new TableColumn(
      'RewardTypeId',
      'rewardType',
      true,
      'number',
      undefined,
      undefined,
      true,
      'in'
    ),
    new TableColumn('ReferenceId', 'referenceId', true, 'number'),
    new TableColumn(
      'ReferenceName',
      'referenceName',
      true,
      'text',
      '/products/catalog/product-variant/',
      'referenceId',
      true,
      'contains'
    ),
    new TableColumn(
      'Pontos',
      'points',
      true,
      'number',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn('Saldo assinante', 'rewardBalance', true, 'number'),
    new TableColumn(
      'Data de Criação',
      'dateCreated',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    ),
    new TableColumn('ProductId', 'productId', true, 'text'),
    new TableColumn('OrderId', 'orderid', true, 'text')
  ];
  dropdownFilters: {
    [field: string]: Array<DropdownFilter>;
  } = {};
  defaultFiltervalues: {
    [field: string]: any | Array<any>;
  } = { rewardType: [] };
  rewardTypes: Array<RewardType> | undefined;
  problems: Array<DuplicatedReward> | undefined;

  constructor(
    public itOpsService: ItOpsControllerService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private rewardService: RewardControllerService
  ) {}

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader();
    await this.findRewardTypes();
  }

  deleteRewards(elements: Array<DuplicatedReward>): void {
    this.confirmationService.confirm({
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      rejectButtonStyleClass: 'p-button-danger',
      message: 'Deseja excluir a(s) reward(s) selecionada(s)?',
      header: 'Excluir',
      accept: async () => {
        LoaderService.showLoader();
        try {
          this.problems = await lastValueFrom(
            this.rewardService
              .deleteDuplicatedRewards(elements)
              .pipe(map((data) => data.result))
          );
          if (this.problems < elements)
            this.messageService.add({
              summary: 'Sucesso',
              detail:
                elements.length -
                (this.problems?.length || 0) +
                ' reward(s) excluído(s)',
              severity: 'success'
            });
          if (this.problems?.length) {
            this.messageService.add({
              summary: 'Erro',
              detail: this.problems.length + ' reward(s) não excluído(s).',
              severity: 'error'
            });
          }
          await this.table.refresh(true);
          LoaderService.showLoader(false);
        } catch (error) {
          LoaderService.showLoader(false);
          AppDialogService.showErrorDialog(error);
        }
      }
    });
  }

  async findRewardTypes(): Promise<void> {
    try {
      this.rewardTypes = await lastValueFrom(
        this.rewardService
          .findRewardTypes()
          .pipe(map((data) => data.result.filter((rt) => rt.rewardTypeId < 0)))
      );
      this.defaultFiltervalues['rewardType'] = this.rewardTypes.map((rt) =>
        rt.rewardTypeId.toString()
      );
      this.dropdownFilters['rewardType'] = this.rewardTypes.map((rt) => ({
        label: rt.rewardTypeName,
        value: rt.rewardTypeId.toString()
      }));
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  rowBackground = (reward: DuplicatedReward) => {
    return this.problems?.some((r) => r.rewardId === reward.rewardId)
      ? '#ed9e9e'
      : 'auto';
  };
}

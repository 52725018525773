import { Shops } from '@infrab4a/connect';

export enum ShopMap {
  Glamshop = 'Glam',
  mensmarket = `Men's Market`,
  Glampoints = 'Vitrine Glam'
}

export function getShop(name: string) {
  for (const [p] of Object.entries(ShopMap)) {
    if (p.toLowerCase() === name.toLowerCase()) {
      return p;
    }
  }
}

export function getShopsArray() {
  return [
    { label: 'Glamshop', value: Shops.GLAMSHOP },
    { label: "Men's Market", value: Shops.MENSMARKET }
  ];
}

import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { lastValueFrom, map } from 'rxjs';
import {
  ErpControllerService,
  PageableRequest,
  ProductControllerService,
  ProductSummaryItem
} from 'src/app/admin-api';
import { PageContent, TableColumn } from 'src/app/components/table';
import { getAllBrandEquities, getEquityLabel } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  providers: [MessageService]
})
export class ProductsComponent implements OnInit {
  cols = [
    new TableColumn(
      'Nome',
      'productName',
      true,
      'text',
      'product/',
      'productId',
      true,
      'contains'
    ),
    new TableColumn(
      'SKU Millennium',
      'externalId',
      true,
      'number',
      'product/',
      'productId'
    ),
    new TableColumn(
      'SKU Admin',
      'productId',
      true,
      'number',
      'product/',
      'productId'
    ),
    new TableColumn(
      'InternalEAN',
      'internalEAN',
      true,
      'text',
      'product/',
      'productId',
      true,
      'contains'
    ),
    new TableColumn(
      'Marca',
      'brandName',
      true,
      'text',
      '/products/brands/',
      'brandId',
      true,
      'contains'
    ),
    new TableColumn(
      'BrandEquity',
      'brandEquityLabel',
      true,
      'number',
      undefined,
      undefined,
      true,
      'equals'
    ),
    new TableColumn(
      'Categoria',
      'category',
      true,
      'text',
      '/products/categories/',
      'categoryId',
      true,
      'contains'
    ),
    new TableColumn(
      'Subcategoria',
      'subcategory',
      true,
      'text',
      '/products/categories/',
      'subcategoryId',
      true,
      'contains'
    ),
    new TableColumn('Nota', 'ratingAverage', false, 'formattedNumber'),
    new TableColumn('Avaliações', 'reviewCount', false, 'formattedInteger'),
    new TableColumn('Variantes', 'variantCount', false, 'formattedInteger'),
    new TableColumn('Estoque', 'stock', false, 'formattedInteger'),
    new TableColumn(
      'Criação',
      'dateCreated',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    )
  ];
  dropdownFilters:
    | { [key: string]: Array<{ label: string; value: string }> }
    | undefined;
  constructor(
    public productService: ProductControllerService,
    private title: Title,
    private erpService: ErpControllerService,
    private messageService: MessageService
  ) {
    this.title.setTitle('Produtos de assinatura');
  }

  async ngOnInit(): Promise<void> {
    this.dropdownFilters = {
      brandEquityLabel: [{ label: 'Todas', value: '-1' }].concat(
        getAllBrandEquities().map((e) => ({
          label: e.label,
          value: e.value.toString()
        }))
      )
    };
  }

  async findPage(
    request: PageableRequest,
    productService: unknown
  ): Promise<PageContent<ProductSummaryItem>> {
    const filterEquity = request.filters?.find(
      (f) => f.field === 'brandEquityLabel'
    );
    if (filterEquity?.value) {
      if (Number(filterEquity.value) < 0) {
        filterEquity.value = '';
      }
      request.filters?.push({
        condition: 'equals',
        field: 'brandEquity',
        fieldType: 'number',
        value: filterEquity.value
      });
    }
    return lastValueFrom(
      (productService as ProductControllerService)
        .findProductsTable(request)
        .pipe(
          map((data) => {
            if (data.result?.content) {
              data.result.content = data.result.content.map((p) => ({
                ...p,
                brandEquityLabel: `${getEquityLabel(p.brandEquity as number)}`
              }));
            }
            return data.result as PageContent<ProductSummaryItem>;
          })
        )
    );
  }

  async syncERP(): Promise<void> {
    try {
      LoaderService.showLoader();
      const response = await lastValueFrom(
        this.erpService.executeSyncProduct().pipe(map((data) => data.result))
      );
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso',
        detail: response
      });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  SubscriptionType,
  SubscriptionTypePriceControllerService
} from 'src/app/admin-api';
import { SubscriptionTypePrice } from 'src/app/admin-api/model/subscriptionTypePrice';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-subscription-type-price-form-modal',
  templateUrl: './subscription-type-price-form-modal.component.html',
  styleUrl: './subscription-type-price-form-modal.component.scss'
})
export class SubscriptionTypePriceFormModalComponent implements OnInit {
  subscriptionTypePrice: SubscriptionTypePrice | undefined;

  form: FormGroup | undefined;

  subscriptionType: SubscriptionType;
  tomorrow: Date;

  constructor(
    private subscriptionTypePriceController: SubscriptionTypePriceControllerService,
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) {}

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader();
    const today = new Date(
      `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${new Date().getDate().toString().padStart(2, '0')}`
    );
    today.setDate(today.getDate() + 1);
    this.tomorrow = FormUtil.utcDate(today);
    this.subscriptionType = this.config?.data?.subscriptionType;

    const dateTomorrow = new Date();
    dateTomorrow.setDate(dateTomorrow.getDate() + 1);

    this.form = new FormGroup({
      subscriptionTypeId: new FormControl(
        this.subscriptionType?.subscriptionTypeId
      ),
      newPrice: new FormControl<number>(0, [
        Validators.required,
        Validators.min(1)
      ]),
      dateChanged: new FormControl(dateTomorrow, [
        FormUtil.date,
        Validators.required
      ]),
      maxDateToRenew: new FormControl(dateTomorrow, [
        FormUtil.date,
        Validators.required
      ])
    });

    LoaderService.showLoader(false);
  }

  async savePrice(): Promise<void> {
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach((key) => {
        this.form.controls[key]?.markAsTouched();
        this.form.controls[key]?.markAsDirty();
        this.form.controls[key]?.updateValueAndValidity();
      });

      return;
    }

    LoaderService.showLoader();
    try {
      if (this.subscriptionType?.subscriptionTypeId) {
        this.subscriptionTypePrice = await lastValueFrom(
          this.subscriptionTypePriceController
            .createSubscriptionTypePrice({
              ...this.form.value,
              newPrice:
                this.form.value.newPrice * this.subscriptionType.installments
            })
            .pipe(map((data) => data.result))
        );

        this.ref.close(this.subscriptionTypePrice);
      }
    } catch (error: any) {
      LoaderService.showLoader(false);
      AppDialogService.showErrorDialog(error);
    }
  }

  validateMaxDateToRenew(): void {
    this.form?.controls['maxDateToRenew']?.updateValueAndValidity();
    if (
      this.form?.controls['dateChanged']?.valid &&
      this.form?.controls['maxDateToRenew']?.value <
        this.form?.controls['dateChanged']?.value
    )
      this.form?.controls['maxDateToRenew']?.setErrors({
        ...(this.form?.controls['maxDateToRenew'].errors || {}),
        before: true
      });
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ShopSettingsRepository, WishlistRepository } from '@infrab4a/connect';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { FileControllerService } from 'src/app/admin-api';
import { CustomizationFormComponent } from 'src/app/components/customization/customization-form.component';
import { AppDialogService } from 'src/app/services/dialog.service';
import { ImageService } from 'src/app/services/image.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-collections-lojinhas',
  templateUrl: './collections-lojinhas.component.html',
  styleUrl: './collections-lojinhas.component.scss'
})
export class CollectionsLojinhasComponent
  extends CustomizationFormComponent
  implements OnInit
{
  pageDocument;
  edit: boolean;

  constructor(
    @Inject('ShopSettingsRepository')
    private shopSettingsRepository: ShopSettingsRepository,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    @Inject('WishlistRepository')
    private wishlistRepository: WishlistRepository,
    override router: Router,
    override imageService: ImageService,
    override config: PrimeNGConfig,
    override fileService: FileControllerService
  ) {
    super(router, config, imageService, fileService);

    this.documentId = 'COLLECTIONS_LOJINHAS';

    this.form = this.formBuilder.group({
      imageBannerDesk: [''],
      imageBannerMobile: ['']
    });
  }

  async ngOnInit() {
    await this.getDocument();
    this.initializeForm();
  }

  override async getDocument(): Promise<void> {
    try {
      LoaderService.showLoader();
      this.pageDocument = await this.shopSettingsRepository.get({
        id: this.documentId
      });
    } catch (e) {
      AppDialogService.showErrorDialog(e);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  override initializeForm() {
    this.form.patchValue(this.pageDocument);
  }

  override async save() {
    if (this.form.valid) {
      try {
        LoaderService.showLoader();
        await this.shopSettingsRepository.update({
          id: this.documentId,
          ...this.form.value
        });

        this.messageService.add({
          severity: 'success',
          summary: 'Salvo com sucesso',
          detail: 'As informações foram salvas.'
        });

        await this.afterSubmit();
      } catch (e) {
        AppDialogService.showErrorDialog(e);
      } finally {
        LoaderService.showLoader(false);
      }
    } else {
      console.error('Formulário inválido. Não é possível salvar os dados.');
    }
  }

  async handleFileSelect(
    event: { files: File[] },
    inputName: string,
    expectedWidth: number,
    expectedHeight: number,
    maxWeight: number,
    pInput: FileUpload
  ) {
    this.fileNamePattern = `collections-lojinhas-banner-${new Date().getTime()}`;

    await this.upload(
      event,
      inputName,
      expectedWidth,
      expectedHeight,
      maxWeight,
      pInput
    );
  }

  get imageBannerDeskUrl(): string {
    return this.form.get('imageBannerDesk')?.value;
  }

  get imageBannerMobileUrl(): string {
    return this.form.get('imageBannerMobile')?.value;
  }

  override get filePath(): string {
    return '/pages/collections-lojinhas/';
  }

  override get model() {
    return this.pageDocument;
  }

  override get modelId() {
    return this.pageDocument?.id;
  }
}

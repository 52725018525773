import { Component, inject } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CompositionProductionSummary } from 'src/app/admin-api';

@Component({
  selector: 'app-composition-production-modal',
  templateUrl: './composition-production-modal.component.html',
  styleUrl: './composition-production-modal.component.scss'
})
export class CompositionProductionModalComponent {
  private config: DynamicDialogConfig = inject(DynamicDialogConfig);
  composition: CompositionProductionSummary = this.config.data.composition;
  username: string = this.config.data.username;
}

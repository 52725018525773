import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TooltipModule } from 'primeng/tooltip';
import { AppTableModule } from '../table/app-table.module';
import { ShippingTrackingModalComponent } from './shipping-tracking-modal.component';

@NgModule({
  declarations: [ShippingTrackingModalComponent],
  imports: [CommonModule, TooltipModule, AppTableModule],
  exports: [ShippingTrackingModalComponent]
})
export class ShippingTrackingModalModule {}

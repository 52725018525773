import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FileUpload } from 'primeng/fileupload';
import { lastValueFrom, map } from 'rxjs';
import {
  ProductControllerService,
  ProductVariantCategoryDetail,
  ProductVariantSummary
} from 'src/app/admin-api';
import { TableColumn } from 'src/app/components/table';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-batch-update',
  templateUrl: './batch-update.component.html',
  styleUrls: ['./batch-update.component.scss']
})
export class BatchUpdateComponent {
  variantsInStock: Array<ProductVariantSummary> | undefined;
  variantsNoStock: Array<ProductVariantSummary> | undefined;
  productsFailed: Array<ProductVariantSummary> | undefined;
  cols: Array<TableColumn> = [
    new TableColumn(
      'Id',
      'productVariantId',
      true,
      'number',
      '/products/catalog/product-variant/',
      'productVariantId'
    ),
    new TableColumn(
      'External ID',
      'externalId',
      true,
      'number',
      '/products/catalog/product-variant/',
      'productVariantId'
    ),
    new TableColumn(
      'Nome',
      'productVariantName',
      true,
      'text',
      '/products/catalog/product-variant/',
      'productVariantId',
      true,
      'contains'
    ),
    new TableColumn(
      'InternalEAN',
      'internalEAN',
      true,
      'text',
      '/products/catalog/product-variant/',
      'productVariantId',
      true,
      'contains'
    ),
    new TableColumn(
      'Validade',
      'expiration',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn('Peso (g)', 'weight', false, 'formattedNumber'),
    new TableColumn('Estoque', 'stockCount', false, 'formattedInteger'),
    new TableColumn('Pontos troca', 'tradePoints', false, 'formattedNumber'),
    new TableColumn(
      'Máximo trocas',
      'maxTradesPerPerson',
      false,
      'formattedInteger'
    )
  ];
  colsFailed: Array<TableColumn> = [
    new TableColumn('InternalEAN', 'internalEAN', false, 'text'),
    new TableColumn('Pontos troca', 'tradePoints', false, 'formattedNumber'),
    new TableColumn(
      'Máximo trocas',
      'maxTradesPerPerson',
      false,
      'formattedInteger'
    ),
    new TableColumn('Validade', 'expiration', false, 'text')
  ];
  colsCategory: Array<TableColumn> = [
    new TableColumn.Builder()
      .setHeader('EAN')
      .setField('internalEAN')
      .setRouterLink('/products/catalog/product-variant/')
      .setRouterLinkFieldName('productVariantId')
      .build(),
    new TableColumn.Builder()
      .setHeader('Produto')
      .setField('productVariantName')
      .setRouterLink('/products/catalog/product-variant/')
      .setRouterLinkFieldName('productVariantId')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('Categoria')
      .setField('categoryName')
      .setRouterLink('/products/category/')
      .setRouterLinkFieldName('categoryId')
      .setCondition('contains')
      .setDisplayFunction(
        (p: ProductVariantCategoryDetail) =>
          `${p.categoryExternalId} ${p.categoryName}`
      )
      .build()
  ];
  colsProductNotFound: Array<TableColumn> = [
    new TableColumn.Builder()
      .setHeader('EAN')
      .setField('internalEAN')
      .setRouterLink('/products/catalog/product-variant/')
      .setRouterLinkFieldName('productVariantId')
      .build(),
    new TableColumn.Builder()
      .setHeader('Categoria')
      .setField('categoryName')
      .setRouterLink('/products/category/')
      .setRouterLinkFieldName('categoryId')
      .setCondition('contains')
      .setDisplayFunction(
        (p: ProductVariantCategoryDetail) =>
          `${p.categoryExternalId} ${p.categoryName}`
      )
      .build()
  ];
  colsCategoryNotFound: Array<TableColumn> = [
    new TableColumn.Builder()
      .setHeader('Categoria')
      .setField('categoryName')
      .setRouterLink('/products/category/')
      .setRouterLinkFieldName('categoryId')
      .setCondition('contains')
      .setDisplayFunction(
        (p: ProductVariantCategoryDetail) =>
          `${p.categoryExternalId} ${p.categoryName || ''}`
      )
      .build(),
    new TableColumn.Builder()
      .setHeader('EAN')
      .setField('internalEAN')
      .setRouterLink('/products/catalog/product-variant/')
      .setRouterLinkFieldName('productVariantId')
      .build(),
    new TableColumn.Builder()
      .setHeader('Produto')
      .setField('productVariantName')
      .setRouterLink('/products/catalog/product-variant/')
      .setRouterLinkFieldName('productVariantId')
      .build()
  ];
  minStock = 5;
  productsNotFound: Array<ProductVariantCategoryDetail>;
  categoriesNotFound: Array<ProductVariantCategoryDetail>;
  productCategoryItens: Array<ProductVariantCategoryDetail>;

  constructor(
    private productsService: ProductControllerService,
    private title: Title
  ) {
    this.title.setTitle('Batch update de produtos de assinatura');
  }

  async uploadFile(
    $event: { files: File[] },
    fileUpload: FileUpload
  ): Promise<void> {
    try {
      LoaderService.showLoader();
      const products = await lastValueFrom(
        this.productsService
          .updateGlamclubXlsxFileForm($event.files[0])
          .pipe(map((data) => data.result))
      );
      this.variantsInStock = products?.filter(
        (p) =>
          p.productVariantId && p.stockCount && p.stockCount > this.minStock
      );
      this.variantsNoStock = products?.filter(
        (p) =>
          p.productVariantId && (!p.stockCount || p.stockCount <= this.minStock)
      );
      this.productsFailed = products?.filter((p) => !p.productVariantId);
      fileUpload.clear();
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async uploadCategoryFile($event: { files: File[] }, fileUpload: FileUpload) {
    try {
      LoaderService.showLoader();
      const products = await lastValueFrom(
        this.productsService
          .updateBatchProductCategory($event.files[0])
          .pipe(map((data) => data.result))
      );
      this.productCategoryItens = products.filter(
        (p) => p.productVariantId && p.categoryId
      );
      this.categoriesNotFound = products.filter((p) => !p.categoryId);
      this.productsNotFound = products.filter((p) => !p.productVariantId);
      fileUpload.clear();
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }
}

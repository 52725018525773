import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { PersonActionModalComponent } from './person-action-modal.component';

@NgModule({
  declarations: [PersonActionModalComponent],
  imports: [
    CommonModule,
    ButtonModule,
    InputNumberModule,
    InputTextModule,
    DropdownModule,
    ReactiveFormsModule
  ],
  exports: [PersonActionModalComponent]
})
export class PersonActionModalModule {}

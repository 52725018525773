<div class="no-permission" [class.no-permission--modal]="modal">
  <div class="unauthorized">
    <i class="pi pi-lock"></i>
    <h4 *ngIf="!modal">
      Você não possui permissão para visualizar essa página.
    </h4>
    <h4 *ngIf="modal">Você não possui essa permissão.</h4>
    <h4>Caso isso seja um engano, entre em contato com seu gestor.</h4>
    <p-button
      [label]="buttonLabel || 'Voltar ao início'"
      (onClick)="goHome()"
    ></p-button>
  </div>
</div>

<app-table
  *ngIf="cols"
  [cols]="cols"
  [findPage]="findPage"
  [service]="personService"
  modelName="usuários"
  sortField="personId"
  [sortOrder]="1"
  [addButton]="false"
  [dropdownFilters]="dropdownFilters"
  [changeColumns]="true"
></app-table>

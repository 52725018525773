<app-table
  [service]="articleService"
  pageFunctionName="listAllPageable"
  [cols]="cols()"
  modelName="Videos"
  [dropdownFilters]="dropdownFilters"
  [defaultFilterValues]="defaultFilterValues"
  sortField="publishDate"
  [sortOrder]="1"
  (booleanAction)="updateActive($event)"
  [changeColumns]="true"
  addButtonRouterLink="new"
  [addButton]="permission()"
  [defaultRows]="25"
  tableTitle="Vídeos"
  *ngIf="cols()"
  [fixedFilters]="fixedFilters"
/>

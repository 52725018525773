import { Inject, Injectable } from '@angular/core';
import {
  Product,
  ProductLabel,
  ProductRepository,
  ProductStockNotificationRepository,
  ProductVariantReport,
  Variant,
  VariantRepository,
  Where
} from '@infrab4a/connect';
import { PageContent } from 'src/app/components/table';
import { ConnectRequest } from 'src/app/models';
import { BaseConnectService } from './base-connect.service';

@Injectable({
  providedIn: 'root'
})
export class ShopProductService extends BaseConnectService<Product> {
  constructor(
    @Inject('ProductRepository')
    private productRepository: ProductRepository,
    @Inject('VariantRepository')
    private variantRepository: VariantRepository,
    @Inject('ProductStockNotificationRepository')
    private productStockNotificationRepository: ProductStockNotificationRepository
  ) {
    super(productRepository);
  }

  async getProductById(id: string): Promise<Product> {
    return this.productRepository.get({ id });
  }

  async getProductBySKU(sku: string): Promise<Product> {
    return await this.productRepository
      .find({
        filters: {
          sku: {
            operator: Where.EQUALS,
            value: sku
          }
        }
      })
      .then((res) => res.data.shift());
  }

  async getProductByEan(ean: string): Promise<Product> {
    return await this.productRepository
      .find({
        filters: {
          EAN: {
            operator: Where.EQUALS,
            value: ean
          }
        }
      })
      .then((res) => res.data.shift());
  }

  async getProductsList(
    request: Partial<ConnectRequest>
  ): Promise<PageContent<Partial<Product>>> {
    const result = await this.paginatedSearch(request);
    const content = result.data.map((category) => ({ ...category }));
    const totalPages = request.pageSize
      ? Math.floor(result.count / request.pageSize)
      : undefined;
    const totalElements = result.count;

    return {
      totalPages,
      totalElements,
      content,
      empty: result.count === 0
    };
  }

  async updateProduct(product: Partial<Product>): Promise<Product> {
    if (product.description)
      Object.keys(product.description).forEach((f) => {
        if (!product.description[f]?.length) {
          if (f === 'description')
            product.description[f] = BaseConnectService.NULL;
          else if (f === 'brand') {
            product.description[f] = null;
            product['brandDescription'] = BaseConnectService.NULL;
          } else {
            product.description[f] = null;
            product[f] = BaseConnectService.NULL;
          }
        }
      });
    product.label =
      (product.label as string) === 'null'
        ? BaseConnectService.NULL
        : product.label;

    return this.productRepository.update(Product.toInstance(product));
  }

  async filterByBrand(brandName: string) {
    return await this.productRepository.find({
      filters: {
        brand: { operator: Where.EQUALS, value: brandName }
      }
    });
  }

  async findProductVariants(productId: string): Promise<Variant[]> {
    const result = await this.variantRepository.find({
      filters: {
        productId: {
          operator: Where.EQUALS,
          value: productId
        }
      },
      limits: {
        limit: 9999,
        offset: 0
      }
    });
    return result.data;
  }

  async updateVariant(
    variant: Partial<Variant>,
    variantId: string
  ): Promise<Partial<Variant>> {
    return await this.variantRepository.update({ ...variant, id: variantId });
  }

  async getProductStockNotificationReport(request: Partial<ConnectRequest>) {
    console.log(request);
    const query = this.getFiltersQuery(request.filters) as any;

    const notifications =
      await this.productStockNotificationRepository.getNotificationsReport(
        {
          ean: query?.ean?.value,
          name: query?.name?.value,
          sku: query?.sku?.value,
          reference: query?.reference?.value,
          category: query?.category?.value,
          emailsCount: query?.emails_registered?.value
        },
        {
          field: request.sortBy,
          direction: request.sortDirection
        },
        {
          limit: request.pageSize,
          offset: request.pageSize * request.page
        }
      );

    const totalPages = request.pageSize
      ? Math.floor(notifications.count / request.pageSize)
      : undefined;

    return {
      totalPages,
      totalElements: notifications.count,
      content: notifications.data,
      empty: notifications.count === 0
    };
  }

  override get scheme(): 'firebase' | 'hasura' {
    return 'hasura';
  }

  public static getProductLabel(label?: ProductLabel): string {
    const labels = {
      glamstar: 'Glamstar',
      outlet: 'Outlet',
      'last-units': 'Últimas unidades',
      'on-sale': 'Promoção'
    };

    if (!label) return 'Sem label';

    return labels[label];
  }

  async productVariantReport(): Promise<ProductVariantReport[]> {
    return this.productRepository.productVariantFullReport().then();
  }
}

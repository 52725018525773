export * from './addressController.service';
export * from './adminController.service';
export * from './articleController.service';
export * from './badgeController.service';
export * from './beautyProfileAdminController.service';
export * from './beautyProfileController.service';
export * from './blacklistController.service';
export * from './brandController.service';
export * from './compositionController.service';
export * from './couponController.service';
export * from './couponPriceChangeController.service';
export * from './customQueryController.service';
export * from './editionController.service';
export * from './erpController.service';
export * from './feedbackAdminController.service';
export * from './feedbackController.service';
export * from './feedbackGroupController.service';
export * from './fileController.service';
export * from './financialAdminController.service';
export * from './financialController.service';
export * from './incidentController.service';
export * from './influencerController.service';
export * from './integrationController.service';
export * from './integrationTokenController.service';
export * from './itOpsController.service';
export * from './kpiController.service';
export * from './movementController.service';
export * from './openController.service';
export * from './paymentController.service';
export * from './personController.service';
export * from './productController.service';
export * from './rewardController.service';
export * from './subscriberController.service';
export * from './subscriptionController.service';
export * from './subscriptionTypeController.service';
export * from './subscriptionTypePriceController.service';
export * from './userController.service';
import { AddressControllerService } from './addressController.service';
import { AdminControllerService } from './adminController.service';
import { ArticleControllerService } from './articleController.service';
import { BadgeControllerService } from './badgeController.service';
import { BeautyProfileAdminControllerService } from './beautyProfileAdminController.service';
import { BeautyProfileControllerService } from './beautyProfileController.service';
import { BlacklistControllerService } from './blacklistController.service';
import { BrandControllerService } from './brandController.service';
import { CompositionControllerService } from './compositionController.service';
import { CouponControllerService } from './couponController.service';
import { CouponPriceChangeControllerService } from './couponPriceChangeController.service';
import { CustomQueryControllerService } from './customQueryController.service';
import { EditionControllerService } from './editionController.service';
import { ErpControllerService } from './erpController.service';
import { FeedbackAdminControllerService } from './feedbackAdminController.service';
import { FeedbackControllerService } from './feedbackController.service';
import { FeedbackGroupControllerService } from './feedbackGroupController.service';
import { FileControllerService } from './fileController.service';
import { FinancialAdminControllerService } from './financialAdminController.service';
import { FinancialControllerService } from './financialController.service';
import { IncidentControllerService } from './incidentController.service';
import { InfluencerControllerService } from './influencerController.service';
import { IntegrationControllerService } from './integrationController.service';
import { IntegrationTokenControllerService } from './integrationTokenController.service';
import { ItOpsControllerService } from './itOpsController.service';
import { KpiControllerService } from './kpiController.service';
import { MovementControllerService } from './movementController.service';
import { OpenControllerService } from './openController.service';
import { PaymentControllerService } from './paymentController.service';
import { PersonControllerService } from './personController.service';
import { ProductControllerService } from './productController.service';
import { RewardControllerService } from './rewardController.service';
import { SubscriberControllerService } from './subscriberController.service';
import { SubscriptionControllerService } from './subscriptionController.service';
import { SubscriptionTypeControllerService } from './subscriptionTypeController.service';
import { SubscriptionTypePriceControllerService } from './subscriptionTypePriceController.service';
import { UserControllerService } from './userController.service';
export const APIS = [
  AddressControllerService,
  AdminControllerService,
  ArticleControllerService,
  BadgeControllerService,
  BeautyProfileAdminControllerService,
  BeautyProfileControllerService,
  BlacklistControllerService,
  BrandControllerService,
  CompositionControllerService,
  CouponControllerService,
  CouponPriceChangeControllerService,
  CustomQueryControllerService,
  EditionControllerService,
  ErpControllerService,
  FeedbackAdminControllerService,
  FeedbackControllerService,
  FeedbackGroupControllerService,
  FileControllerService,
  FinancialAdminControllerService,
  FinancialControllerService,
  IncidentControllerService,
  InfluencerControllerService,
  IntegrationControllerService,
  IntegrationTokenControllerService,
  ItOpsControllerService,
  KpiControllerService,
  MovementControllerService,
  OpenControllerService,
  PaymentControllerService,
  PersonControllerService,
  ProductControllerService,
  RewardControllerService,
  SubscriberControllerService,
  SubscriptionControllerService,
  SubscriptionTypeControllerService,
  SubscriptionTypePriceControllerService,
  UserControllerService
];

import { Clipboard } from '@angular/cdk/clipboard';
import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ConfirmationService, MessageService, PrimeIcons } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  IntegrationTokenControllerService,
  IntegrationTokenView
} from 'src/app/admin-api';
import {
  TableActionButton,
  TableActionEvent,
  TableColumn,
  TableComponent
} from 'src/app/components/table';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { IntegrationTokenFormComponent } from './integration-token-form/integration-token-form.component';

@Component({
  selector: 'app-integration-tokens',
  templateUrl: './integration-tokens.component.html',
  styleUrl: './integration-tokens.component.scss',
  providers: [DialogService, MessageService, ConfirmationService]
})
export class IntegrationTokensComponent implements OnInit {
  @ViewChild(TableComponent) table: TableComponent;

  tokenService = inject(IntegrationTokenControllerService);
  private dialogService = inject(DialogService);
  private clipboard = inject(Clipboard);
  private messageService = inject(MessageService);
  private confirmationService = inject(ConfirmationService);
  private title = inject(Title);

  columns = [
    new TableColumn.Builder()
      .setHeader('TokenId')
      .setField('tokenId')
      .setType('number')
      .build(),
    new TableColumn.Builder()
      .setHeader('Nome')
      .setField('name')
      .setCondition('equals')
      .build(),
    new TableColumn.Builder()
      .setHeader('Usuário')
      .setField('email')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('Email')
      .setField('username')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('Data criação')
      .setField('dateCreated')
      .setType('date')
      .setCondition('between')
      .build(),
    new TableColumn.Builder()
      .setHeader('Permissões')
      .setField('roles')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('Domínios')
      .setField('domains')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('Ação')
      .setType('button')
      .setField(' ')
      .build()
  ];

  actionButtons = [
    new TableActionButton.Builder()
      .setAction('edit')
      .setIcon(PrimeIcons.PENCIL)
      .setRounded(true)
      .setText(true)
      .setTooltip('Editar token')
      .setTooltipPosition('bottom')
      .setSeverity('warning')
      .build(),
    new TableActionButton.Builder()
      .setAction('delete')
      .setIcon(PrimeIcons.TRASH)
      .setSeverity('danger')
      .setTooltip('Excluir token')
      .setTooltipPosition('bottom')
      .setRounded(true)
      .setText(true)
      .build(),
    new TableActionButton.Builder()
      .setAction('copy')
      .setIcon(PrimeIcons.COPY)
      .setSeverity('info')
      .setTooltip('Copiar para área de transferência')
      .setTooltipPosition('bottom')
      .setRounded(true)
      .setText(true)
      .build()
  ];

  ngOnInit(): void {
    this.title.setTitle('Tokens de integração');
  }

  async openModal(token?: IntegrationTokenView) {
    try {
      LoaderService.showLoader();
      this.dialogService
        .open(IntegrationTokenFormComponent, {
          data: {
            token
          },
          header: token
            ? 'Editar token ' + token.tokenId
            : 'Novo token de integração',
          height: '80vh'
        })
        .onClose.subscribe(async () => await this.table?.refresh());
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  callAction(event: TableActionEvent) {
    if (event.action === 'edit') this.openModal(event.item);
    else if (event.action === 'copy')
      this.copyToClipboard((event.item as IntegrationTokenView).token);
    else if (event.action === 'delete') this.deleteToken(event);
  }

  deleteToken(event: TableActionEvent) {
    this.confirmationService.confirm({
      acceptButtonStyleClass: 'p-button-danger',
      acceptLabel: 'Excluir',
      rejectLabel: 'Voltar',
      target: event.$event.target,
      message:
        'Tem certeza que deseja excluir o token ' +
        event.item.name +
        ' e impedir sua utilização?',
      accept: async () => {
        LoaderService.showLoader();
        try {
          const detail = await lastValueFrom(
            this.tokenService
              .deleteToken((event.item as IntegrationTokenView).tokenId)
              .pipe(map((data) => data.result))
          );
          await this.table.refresh();
          this.messageService.add({
            severity: 'success',
            summary: 'Sucesso',
            detail
          });
        } catch (error) {
          AppDialogService.showErrorDialog(error);
        } finally {
          LoaderService.showLoader(false);
        }
      }
    });
  }

  copyToClipboard(text: string): void {
    this.clipboard.copy(text);
    this.messageService.add({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Token copiado para área de trasnferência'
    });
  }
}

import { Component, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import {
  ItOpsControllerService,
  SubscriberProblemGroupDetail,
  WrongRecurrenceDate
} from 'src/app/admin-api';
import { TableColumn, TableComponent } from 'src/app/components/table';
import { WrongRecurrenceDateModalComponent } from './wrong-recurrence-date-modal/wrong-recurrence-date-modal.component';

@Component({
  selector: 'app-wrong-recurrence-date',
  templateUrl: './wrong-recurrence-date.component.html',
  styleUrls: ['./wrong-recurrence-date.component.scss'],
  providers: [
    MessageService,
    DialogService,
    ConfirmationService,
    ItOpsControllerService
  ]
})
export class WrongRecurrenceDateComponent {
  @ViewChild(TableComponent) table: TableComponent;

  columns: Array<TableColumn> = [
    new TableColumn('', '', false, 'checkbox'),
    new TableColumn(
      'SubscriberId',
      'subscriberid',
      true,
      'number',
      '/users/subscribers/',
      'subscriberid'
    ),
    new TableColumn('Edição', 'personId', true, 'number'),
    new TableColumn('Edição Pendente', 'editionPending', true, 'number'),
    new TableColumn(
      'Próximo Pagamento',
      'nextPaymentDate',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    ),
    new TableColumn(
      'Attempt',
      'nextAttemptDate',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    ),
    new TableColumn('CreditCardId', 'creditCardId', true, 'number')
  ];
  ready = true;

  constructor(
    public itOpsService: ItOpsControllerService,
    private confirmationService: ConfirmationService,
    private dialog: DialogService,
    private messageService: MessageService
  ) {}

  openActionModal(recurrences: Array<WrongRecurrenceDate>): void {
    this.dialog
      .open(WrongRecurrenceDateModalComponent, {
        height: '65vh',
        header: 'Data da Recorrência Incorreta',
        data: {
          recurrences
        }
      })
      .onClose.subscribe(async (data: Array<SubscriberProblemGroupDetail>) => {
        if (data) {
          this.ready = false;
          setTimeout(() => {
            this.messageService.add({
              severity: 'success',
              summary: 'Sucesso',
              detail: recurrences.length + ' marcado(s) como resolvido(s)'
            });
            this.ready = true;
          }, 1000);
        }
      });
  }
}

<form class="form-b4a" (submit)="submit()">
  <span class="p-float-label">
    <p-multiSelect
      [options]="beautyProfileOptions"
      [group]="true"
      [filter]="true"
      filterBy="label,parent"
      [maxSelectedLabels]="0"
      selectedItemsLabel="{0} itens"
      defaultLabel="Selecione"
      appendTo="body"
      [(ngModel)]="attributeOptions"
      name="attributeOptions"
      inputId="attributeOptions"
      [panelStyle]="{ 'max-width': '500px' }"
      [showToggleAll]="true"
    >
      <ng-template let-item pTemplate="group">
        <div class="attribute-group" *ngIf="item.value">
          {{ item.value }}
        </div>
        <div [class.attribute]="item.value">
          {{ item.label }}
        </div>
      </ng-template>
    </p-multiSelect>
    <label for="attributeOptions">Respostas do perfil</label>
  </span>
  <p-button
    type="submit"
    label="Buscar"
    severity="primary"
    [disabled]="!attributeOptions.length"
  />
</form>
<div *ngIf="!products">
  <h4>Composições</h4>
  <div *ngFor="let comp of compositions">
    {{ comp.compositionName }}
  </div>
</div>
<app-table
  *ngIf="products && dropdownFilters"
  [lazy]="false"
  [elements]="products"
  [cols]="productsCols"
  [addButton]="false"
  modelName="produtos"
  sortField="productVariantName"
  [sortOrder]="-1"
  selectionActionIcon="pi pi-trash"
  selectionActionLabel="Remover assinantes"
  selectionActionTooltip="Remover as assinantes desse produtos"
  (emitSelection)="confirmDelete($event)"
  [defaultRows]="10"
  emptyMessage="Nenhuma assinante recebeu produto com os atributos selecionados"
  [dropdownFilters]="dropdownFilters"
  [multiSelectFilter]="true"
/>
<p-toast />
<p-confirmDialog />

<form class="modal-form">
  <span class="p-float-label" *ngIf="canceledUpdate?.trim().length">
    <textarea
      id="canceledUpdate"
      rows="5"
      cols="80"
      pInputTextarea
      [(ngModel)]="canceledUpdate"
      (click)="copyToClipboard(canceledUpdate)"
      name="canceledUpdate"
      readonly
    ></textarea>
    <label for="canceledUpdate"
      >Update das canceladas com billing pendente</label
    >
  </span>
  <div class="buttons">
    <p-button
      type="button"
      label="Sinalizar como resolvido"
      icon="pi pi-check"
      (onClick)="markAsResolved()"
    />
  </div>
</form>

import {
  ChangeDetectorRef,
  Component,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import BlotFormatter from '@enzedonline/quill-blot-formatter2';
import { NgxPermissionsService } from 'ngx-permissions';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Editor } from 'primeng/editor';
import { FileUpload, FileUploadHandlerEvent } from 'primeng/fileupload';
import Quill from 'quill';
import { firstValueFrom, lastValueFrom, map } from 'rxjs';
import * as beautify from 'simply-beautiful';
import {
  Article,
  ArticleControllerService,
  FileControllerService
} from 'src/app/admin-api';
import { ArticleType } from 'src/app/admin-api/model/articleType';
import { ImageCropperModalComponent } from 'src/app/components/image-cropper-modal/image-cropper-modal.component';
import { ImageUploadComponent } from 'src/app/components/image-upload/image-upload.component';
import { ArticleTypeEnum, Role, roleAsObject } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-article-details',
  templateUrl: './article-details.component.html',
  styleUrl: './article-details.component.scss',
  providers: [DialogService],
  encapsulation: ViewEncapsulation.None
})
export class ArticleDetailsComponent
  extends ImageUploadComponent<Article>
  implements OnInit
{
  @ViewChild(Editor) editor: Editor;
  article: Article | undefined;

  allArticleType: Array<ArticleType> | undefined;
  permission = false;
  showAsHtml = false;

  toolbarOptions = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: [] }],
    [
      'bold',
      'italic',
      'underline',
      'strike',
      { script: 'sub' },
      { script: 'super' },
      'code-block'
    ], // toggled buttons
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    ['link', 'image'],

    [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }]
  ];
  handlers = {
    image: this.handleImage
  };

  constructor(
    override router: Router,
    override fileService: FileControllerService,
    public articleService: ArticleControllerService,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private permissionsService: NgxPermissionsService,
    private title: Title,
    private dialog: DialogService,
    protected cdRef: ChangeDetectorRef
  ) {
    super(fileService, router);
  }

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader();
    Quill.register('modules/blotFormatter2', BlotFormatter);
    this.permission = await this.permissionsService.hasPermission([
      roleAsObject(Role.Full_Administrator).enumValue,
      roleAsObject(Role.Content).enumValue
    ]);
    await this.getAllArticleTypes();
    const params = await firstValueFrom(this.activatedRoute.params);
    if (params['articleId'] && Number(params['articleId'])) {
      try {
        this.article = await lastValueFrom(
          this.articleService
            .findById(Number(params['articleId']))
            .pipe(map((data) => data.result))
        );
        this.title.setTitle('Artigo - ' + this.article.articleTitle);
      } catch (error) {
        AppDialogService.showErrorDialog(error, true);
      }
    } else if (!this.permission) {
      this.router.navigate(['/unauthorized']);
    } else {
      this.title.setTitle('Novo artigo');
    }

    this.form = new FormGroup({
      articleId: new FormControl({
        value: this.article?.articleId || '',
        disabled: !this.permission
      }),
      articleTitle: new FormControl(
        {
          value: this.article?.articleTitle || '',
          disabled: !this.permission
        },
        [Validators.required, Validators.maxLength(255)]
      ),
      articleType: new FormControl(
        {
          value:
            this.article?.articleType !== null &&
            this.article?.articleType !== undefined
              ? this.article.articleType
              : ArticleTypeEnum.Artigo,
          disabled: !this.permission
        },
        Validators.required
      ),
      articleTags: new FormControl(
        {
          value: this.article?.articleTags || '',
          disabled: !this.permission
        },
        Validators.maxLength(4000)
      ),
      articleSummary: new FormControl(
        {
          value: this.article?.articleSummary || '',
          disabled: !this.permission
        },
        [Validators.required, Validators.maxLength(4000)]
      ),
      articleKeywords: new FormControl(
        {
          value: this.article?.articleKeywords || '',
          disabled: !this.permission
        },
        Validators.maxLength(800)
      ),
      articleImage: new FormControl({
        value: this.article?.articleImage || '',
        disabled: !this.permission
      }),
      articleDescription: new FormControl(
        {
          value: this.article?.articleDescription || '',
          disabled: !this.permission
        },
        Validators.maxLength(4000)
      ),
      articleBody: new FormControl(
        {
          value: this.article?.articleBody || '',
          disabled: !this.permission
        },
        Validators.maxLength(50000)
      ),
      html: new FormControl(false),
      articleAuthor: new FormControl(
        {
          value: this.article?.articleAuthor || '',
          disabled: !this.permission
        },
        Validators.maxLength(255)
      ),
      active: new FormControl(
        { value: this.article?.active || false, disabled: !this.permission },
        Validators.required
      ),
      articleTypeName: new FormControl({
        value: this.article?.articleTypeName || '',
        disabled: !this.permission
      }),
      publishDate: new FormControl(
        {
          value: this.article?.publishDate
            ? FormUtil.utcDate(this.article?.publishDate)
            : FormUtil.utcDate(new Date()),
          disabled: !this.permission
        },
        [FormUtil.date]
      ),
      unpublishDate: new FormControl(
        {
          value: this.article?.unpublishDate
            ? FormUtil.utcDate(this.article.unpublishDate)
            : null,
          disabled: !this.permission
        },
        [FormUtil.date]
      )
    });
    this.format();
    LoaderService.showLoader(false);
  }

  async submit(): Promise<void> {
    if (this.form.invalid) {
      this.messageService.add({
        severity: 'error',
        detail: 'Não foi possivel salvar. Campos obrigatórios não preenchidos',
        summary: 'Erro'
      });

      return;
    }

    LoaderService.showLoader();
    try {
      this.addClassToImages();
      let detail;

      if (this.article?.articleId) {
        this.article = await lastValueFrom(
          this.articleService
            .updateArticle(this.modelId, this.form.value)
            .pipe(map((data) => data.result))
        );
        detail = 'Artigo atualizado com sucesso.';
      } else {
        this.article = await lastValueFrom(
          this.articleService
            .createArticle(this.form.value)
            .pipe(map((data) => data.result))
        );
        detail = 'Artigo cadastrado com sucesso';
      }
      this.form.get('html').setValue(false);

      await this.afterSubmit();
      this.messageService.add({
        severity: 'success',
        detail,
        summary: 'Sucesso'
      });
      LoaderService.showLoader(false);
      if (!this.modelId) {
        this.form.get('articleId').setValue(this.article.articleId);
        this.form.get('articleId').enable();
        this.router.navigate(['/content/article/' + this.article.articleId]);
      }
    } catch (error: any) {
      LoaderService.showLoader(false);
      AppDialogService.showErrorDialog(error);
    }
  }

  async getAllArticleTypes() {
    try {
      this.allArticleType = await lastValueFrom(
        this.articleService
          .getAllArticleTypes()
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async cropImageBeforeUpload(
    $event: FileUploadHandlerEvent,
    fileUpload: FileUpload
  ): Promise<void> {
    this.dialog
      .open(ImageCropperModalComponent, {
        width: '600px',
        height: '80vh',
        data: {
          image: $event.files[0],
          fixedWidth: 245,
          fixedHeight: 245
        },
        showHeader: true,
        header: 'Ajustar imagem',
        maximizable: true,
        modal: true
      })
      .onClose.subscribe(async (cropped: File) => {
        if (cropped) {
          $event.files = [cropped];
          await this.onUpload($event, 'articleImage', fileUpload);
        } else {
          fileUpload.clear();
        }
      });
  }

  get filePath(): string {
    return '/content/article/';
  }

  get articleTitle() {
    return this.form.get('articleTitle');
  }

  get articleDescription() {
    return this.form.get('articleDescription');
  }

  get articleSummary() {
    return this.form.get('articleSummary');
  }

  get publishDate() {
    return this.form.get('publishDate');
  }

  override get model() {
    return this.article;
  }

  override get modelId() {
    return this.article?.articleId || 0;
  }

  format() {
    this.form
      .get('articleBody')
      .setValue(beautify.html(this.form.value.articleBody, { indent_size: 2 }));
  }

  handleImage() {
    document.getElementById('inputFile').click();
  }

  async handleFile($event: Event) {
    try {
      LoaderService.showLoader();
      const link = await lastValueFrom(
        this.fileService
          .uploadFileForm(
            this.filePath.concat('assets/'),
            ($event.target as any).files[0],
            ($event.target as any).files[0].name
          )
          .pipe(map((data) => data.result))
      );
      this.form
        .get('articleBody')
        .setValue(this.form.value.articleBody + `<p><img src="${link}" /></p>`);
      this.editor.writeValue(this.form.value.articleBody);
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso',
        detail: 'Imagem adicionada ao final do texto.'
      });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  addClassToImages() {
    (this.editor?.getQuill() as Quill)?.container
      .querySelectorAll('img')
      .forEach((i) => {
        if (!i.className?.includes('dimensioned'))
          i.classList.add('dimensioned');
      });
    this.form
      .get('articleBody')
      .setValue(
        (this.editor?.getQuill() as Quill)?.getSemanticHTML() ||
          this.form.value.articleBody
      );
  }
}

import { DatePipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { OrderBlocked } from '@infrab4a/connect';
import { PageableFilter } from 'src/app/admin-api';
import { FilterChangedEvent, TableColumn } from 'src/app/components/table';
import { OrderBlockedService } from 'src/app/connect-api/api/shop/order-blocked.service';
import { getShop, ShopMap } from 'src/app/connect-api/enums/ShopMap';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-orders-blocked',
  templateUrl: './orders-blocked.component.html',
  styleUrl: './orders-blocked.component.scss',
  providers: [DatePipe]
})
export class OrdersBlockedComponent implements OnInit {
  orderBlockedService = inject(OrderBlockedService);
  datePipe = inject(DatePipe);

  cols = [
    new TableColumn.Builder().setHeader('Id do pedido').setField('id').build(),
    new TableColumn.Builder()
      .setHeader('Cliente')
      .setField('customer.name')
      .setCondition('contains')
      .setRouterLink('/users/person/')
      .setRouterLinkFieldName('customer.id')
      .setLinkActive(
        (o: OrderBlocked) =>
          o.customer.id && !Number.isNaN(Number(o.customer.id))
      )
      .build(),
    new TableColumn.Builder()
      .setHeader('Email')
      .setField('customer.email')
      .setRouterLink('/users/person/')
      .setRouterLinkFieldName('customer.id')
      .setLinkActive(
        (o: OrderBlocked) =>
          o.customer.id && !Number.isNaN(Number(o.customer.id))
      )
      .build(),
    new TableColumn.Builder()
      .setHeader('CPF')
      .setField('customer.cpf')
      .setRouterLink('/users/person/')
      .setRouterLinkFieldName('customer.id')
      .setLinkActive(
        (o: OrderBlocked) =>
          o.customer.id && !Number.isNaN(Number(o.customer.id))
      )
      .setDisplayFunction((o: OrderBlocked) =>
        o.customer?.cpf
          ? FormUtil.cpfFormatted(FormUtil.onlyNumbers(o.customer.cpf))
          : null
      )
      .setType('number')
      .build(),
    new TableColumn.Builder().setHeader('Motivo').setField('blockType').build(),
    new TableColumn.Builder().setHeader('Tipo').setField('type').build(),
    new TableColumn.Builder()
      .setHeader('Loja')
      .setField('checkout.shop')
      .setDisplayFunction((order: Partial<OrderBlocked>) =>
        getShop(order.checkout.shop)
      )
      .build(),
    new TableColumn.Builder()
      .setHeader('Data')
      .setField('date')
      .setType('date')
      .setCondition('gte')
      .setRequired(true)
      .build(),
    new TableColumn.Builder()
      .setHeader('Valor')
      .setField('checkout.total')
      .setType('currency')
      .setCondition('gte')
      .build()
  ];
  orders: OrderBlocked[];
  dropdownFilters = {
    'checkout.shop': [
      { label: ShopMap.Glamshop, value: 'Glamshop' },
      { label: ShopMap.Glampoints, value: 'Glampoints' },
      { label: ShopMap.mensmarket, value: 'mensmarket' },
      { label: 'Todas', value: undefined }
    ]
  };
  filterValues: { [field: string]: any | Array<any> } = {
    date: this.today
  };

  async ngOnInit() {
    await this.findOrdersBlocked();
  }

  async findOrdersBlocked() {
    try {
      LoaderService.showLoader();
      const filters = Object.keys(this.filterValues)
        .filter((c) => this.filterValues[c])
        .map(
          (c) =>
            ({
              condition: this.column(c)?.condition,
              field: c,
              fieldType: this.column(c)?.type,
              value: this.filterValues[c]
            } as PageableFilter)
        );
      const filtersAfter = filters.filter(
        (c) => !['date', 'customer.email'].includes(c.field)
      );
      this.orders = FormUtil.filterList(
        await this.orderBlockedService.getOrders(
          filters.filter((c) => ['date', 'customer.email'].includes(c.field))
        ),
        filtersAfter
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async filterChanged(event: FilterChangedEvent) {
    const oldValue = {
      email: this.filterValues['customer.email'],
      date: this.filterValues['date']
    };
    if (event.field) this.filterValues[event.field] = event.value || null;
    if (event.field === 'date' && !this.filterValues[event.field])
      this.filterValues[event.field] = this.today;
    if (
      oldValue?.date !== this.filterValues['date'] ||
      oldValue?.email !== this.filterValues['customer.email']
    )
      await this.findOrdersBlocked();
  }

  private column(field: string) {
    return this.cols.find((c) => c.field === field);
  }

  private get today() {
    return new Date(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
  }
}

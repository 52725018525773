import { Inject, Injectable } from '@angular/core';
import {
  Category,
  CategoryMetadata,
  CategoryRepository,
  FindRepositoryParams,
  Shops,
  Where
} from '@infrab4a/connect';
import { Papa, ParseResult } from 'ngx-papaparse';
import { MessageService } from 'primeng/api';
import { ShopCategoryFilterService } from 'src/app/connect-api/api/shop/shop-category-filter.service';
import { ShopProductService } from 'src/app/connect-api/api/shop/shop-product.service';
import { CategoryBatchUpdateJSON } from 'src/app/connect-api/models/batch/CategoryBatchUpdateJSON';
import { BatchService } from './batch.service';

@Injectable({
  providedIn: 'root'
})
export class CategoriesBatchService implements BatchService {
  itemsForUpload: Partial<Category>[];
  chunkSize = 100;
  completeCount = 0;
  errors = [];

  constructor(
    private papa: Papa,
    @Inject('CategoryRepository') private repository: CategoryRepository,
    private productService: ShopProductService,
    private categoryFilterService: ShopCategoryFilterService,
    private messageService: MessageService
  ) {}

  async parse(input: File): Promise<Partial<Category>[]> {
    return new Promise<Partial<Category>[]>((resolve, reject) => {
      this.completeCount = 0;
      if (!input) return resolve((this.itemsForUpload = []));

      this.papa.parse(input, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: async (
          results: ParseResult<Array<CategoryBatchUpdateJSON>>
        ) => {
          try {
            if (results.errors.length > 0) {
              return reject(results.errors.shift());
            }

            this.itemsForUpload = [];
            if (!results.data?.length)
              return reject({
                message: 'Nenhuma categoria identificada no arquivo.'
              });
            const data = results.data.filter((c) =>
              Object.keys(c).some((k) => c[k])
            );
            if (data.some((c) => !c.shops?.trim().length))
              return reject({ message: 'A coluna "shops" é obrigatória' });
            if (data.some((c) => !c.slug?.trim().length))
              return reject({ message: 'A coluna slug é obrigatória' });
            for (let index = 0; index < data.length; index++) {
              const category = data[index];
              this.itemsForUpload.push(
                await this.convertToCategory(category, index)
              );
            }

            resolve(this.itemsForUpload);
          } catch (error: any) {
            reject(new Error(`Arquivo inválido: ${error.message}`));
          }
        }
      });
    });
  }

  async update(): Promise<Array<Partial<Category>>> {
    if (this.itemsForUpload?.length <= 0) {
      return [];
    }

    this.errors = [];

    const chunks = this.getChunkedCategoryList();
    return await this.startImportation(chunks);
  }

  get importProgress(): number {
    return +((this.completeCount * 100) / this.itemsForUpload.length).toFixed(
      0
    );
  }

  private async convertToCategory(
    category: CategoryBatchUpdateJSON,
    index: number
  ): Promise<Partial<Category>> {
    const shops = category.shops.split(',');

    const missingGlamMetadata =
      (!category.glamMetadataTitle && category.glamMetadataDescription) ||
      (category.glamMetadataTitle && !category.glamMetadataDescription);

    const missingMensMetadata =
      (!category.mensMetadataTitle && category.mensMetadataDescription) ||
      (category.mensMetadataTitle && !category.mensMetadataDescription);

    if (shops.includes(Shops.GLAMSHOP) && missingGlamMetadata) {
      throw new Error(
        'Para atualizar metadados, todos os campos de todos os shops são obrigatórios'
      );
    }

    if (shops.includes(Shops.MENSMARKET) && missingMensMetadata) {
      throw new Error(
        'Para atualizar metadados, todos os campos de todos os shops são obrigatórios'
      );
    }

    const metadata: CategoryMetadata[] = [];

    if (
      shops.includes(Shops.GLAMSHOP) &&
      category.glamMetadataTitle &&
      category.glamMetadataDescription
    ) {
      metadata.push({
        shop: Shops.GLAMSHOP,
        title: category.glamMetadataTitle,
        description: category.glamMetadataDescription
      });
    }

    if (
      shops.includes(Shops.MENSMARKET) &&
      category.mensMetadataTitle &&
      category.mensMetadataDescription
    ) {
      metadata.push({
        shop: Shops.MENSMARKET,
        title: category.mensMetadataTitle,
        description: category.mensMetadataDescription
      });
    }

    const parentId = await this.searchCategoryIdByReference(
      category.categoryReference,
      index
    );

    return Object.entries({
      slug: category.slug,
      ...(category.name && { name: category.name }),
      ...(category.description && { description: category.description }),
      ...(category.isBrand && { brandCategory: !!category.isBrand }),
      ...(category.isCollection && { isCollection: !!category.isCollection }),
      ...(category.published && { published: !!category.published }),
      ...(category.tags && {
        conditions: {
          tags: this.getTags(category.tags)
        }
      }),
      ...(category.shops && { shops: category.shops.split(',') }),
      ...(metadata.length && {
        metadatas: metadata
      }),
      ...(await this.loadProductsIfIsBrand(category)),
      ...(category.categoryReference && { parentId: parentId }),
      ...(category.reference && {
        reference: category.reference
          ? category.reference.toString().trim()
          : { action: null }
      }),
      ...(category.reference && { isCollection: false }),
      ...(category.filters && {
        filters: await this.getFilters(category.filters)
      })
    } as Partial<Category>).reduce(
      (iCateogry, [key, value]) =>
        ![undefined, null].includes(value)
          ? { ...iCateogry, [key]: value }
          : iCateogry,
      {}
    );
  }

  private async searchCategoryIdByReference(reference, index) {
    if (!reference) return null;

    const category = await this.repository
      .find({
        filters: {
          reference: {
            operator: Where.EQUALS,
            value: reference.toString().trim()
          }
        }
      })
      .then((res) => res.data);

    if (!category.length)
      throw new Error(
        `Categoria pai do produto não encontrado na linha ${index + 2}!`
      );

    return Number(category[0].id);
  }

  async loadProductsIfIsBrand(category: CategoryBatchUpdateJSON) {
    let products = [];

    if (category.isBrand) {
      products = (
        await this.productService.filterByBrand(category.name)
      ).data.map((p) => p.id);
      return {
        conditions: {
          brand: category.name
        },
        products
      };
    }
  }

  private async startImportation(
    chunks: Partial<Category>[][]
  ): Promise<Array<Partial<Category>>> {
    let successImportCount = 0;
    this.completeCount = 0;
    let updated: Array<Partial<Category>> = [];

    for (const categories of chunks) {
      for (const category of categories) {
        try {
          const body: FindRepositoryParams<Category> = {
            filters: {
              slug: { operator: Where.EQUALS, value: category.slug },
              shops: { operator: Where.IN, value: category.shops }
            }
          };
          if (category.shops?.length === 1) {
            body.filters.shop = category.shops[0] as any;
          }
          const result = await this.repository.find(body);
          if (result.data.length > 0) {
            const ups = await Promise.all(
              result.data.map((cat) =>
                this.repository.update(
                  Category.toInstance({
                    ...category,
                    id: cat.id,
                    shops:
                      category.shops?.length > 1 ? cat.shops : category.shops
                  })
                )
              )
            );
            updated = updated.concat(
              (ups || []).map(
                (c) => ({ ...c, shop: c.shops[0] } as Partial<Category>)
              )
            );
          } else {
            this.errors.push({
              message: `Categoria não encontrada ${category.slug} [${category.shop}]`
            });
          }
          successImportCount++;
        } catch (error) {
          this.errors.push(error);
        } finally {
          this.completeCount++;
        }
      }
    }

    if (this.errors.length === this.completeCount) {
      this.messageService.add({
        severity: 'warn',
        detail: `Não foi possível realizar a atualização`,
        summary: 'Erro'
      });
    } else {
      this.messageService.add({
        severity: 'success',
        detail: ` Foram atualizadas ${successImportCount} categorias`,
        summary: 'Atualização concluída!'
      });
    }
    return updated?.length
      ? (
          await this.repository.find({
            filters: {
              id: { operator: Where.IN, value: updated.map((c) => c.id) }
            }
          })
        ).data.map((c) => ({ ...c, shop: c.shops[0] } as Partial<Category>))
      : [];
  }

  private getChunkedCategoryList(): Partial<Category>[][] {
    const itemsForUpload = [...this.itemsForUpload];

    return [...Array(Math.ceil(itemsForUpload.length / this.chunkSize))].map(
      () => itemsForUpload.splice(0, this.chunkSize)
    );
  }

  private getTags(inputTags: string) {
    const tags = inputTags.split(';');
    return tags.map((tag) => tag.toLocaleLowerCase().trim());
  }

  private async getMostRelevantProducts(inputEan: string) {
    const eans = inputEan.split(';');
    const products = [];
    if (eans.length) {
      for (const ean of eans) {
        const product = await this.productService.getProductByEan(
          ean.toLocaleLowerCase().trim()
        );
        products.push(product.id);
      }
    }

    return products;
  }

  private async getFilters(inputFilter: string) {
    const filtersInput = inputFilter.split(';');
    const filters = [];

    if (filtersInput.length) {
      for (const slug of filtersInput) {
        const filter = await this.categoryFilterService.getFilterBySlug(
          slug.toLocaleLowerCase().trim()
        );
        filters.push(filter);
      }
    }

    return filters;
  }
}

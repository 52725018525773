import { Component, inject, Input, OnInit } from '@angular/core';
import { lastValueFrom, map } from 'rxjs';
import { PersonDetail, SubscriberInfo } from 'src/app/admin-api';
import { AddressControllerService } from 'src/app/admin-api/api/addressController.service';
import { Address } from 'src/app/admin-api/model/address';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-address-info',
  templateUrl: './address-info.component.html',
  styleUrl: './address-info.component.scss'
})
export class AddressInfoComponent implements OnInit {
  @Input()
  person: PersonDetail | undefined;

  @Input()
  subscribers: Array<SubscriberInfo> = [];

  public addressControllerService = inject(AddressControllerService);

  addresses: Address[] = [];

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader();

    try {
      this.addresses = await lastValueFrom(
        this.addressControllerService
          .getAddress(this.person.personId)
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }

    LoaderService.showLoader(false);
  }

  get billingAddresses(): Array<Address> {
    const billingAddresses = this.addresses.filter(
      (address) => address.addressType === Address.AddressTypeEnum.BILLING
    );

    billingAddresses?.sort((a, b) => {
      return (
        FormUtil.utcDate(b.dateUpdated).getTime() -
        FormUtil.utcDate(a.dateUpdated).getTime()
      );
    });

    return billingAddresses;
  }

  get shippingAddresses(): Array<Address> {
    const shippingAddresses = this.addresses.filter(
      (address) => address.addressType === Address.AddressTypeEnum.SHIPPING
    );

    shippingAddresses?.sort((a, b) => {
      return (
        FormUtil.utcDate(b.dateUpdated).getTime() -
        FormUtil.utcDate(a.dateUpdated).getTime()
      );
    });

    return shippingAddresses;
  }
}

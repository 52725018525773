<form class="modal-form" [formGroup]="form" (submit)="submit()">
  <span class="p-float-label">
    <input type="text" pInputText formControlName="domainName" />
    <label for="domainName">Domínio *</label>
  </span>
  <p-button
    label="Enviar"
    type="submit"
    [disabled]="!form.valid"
    [rounded]="true"
    severity="primary"
  />
</form>

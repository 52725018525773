export enum AttributeTypeEnum {
  'Resposta única' = 1,
  'Resposta múltipla' = 2
}

export function getAllAttributeTypes(): Array<{
  label: string;
  value: number;
}> {
  const objetos: Array<{ label: string; value: number }> = [];
  for (const [label, value] of Object.entries(AttributeTypeEnum)) {
    if (Number(value) >= 0) {
      objetos.push({
        label,
        value: Number(value)
      });
    }
  }
  return objetos;
}

import { TitleCasePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  AllSubscriptionTypes,
  SubscriptionControllerService
} from 'src/app/admin-api';
import { TableColumn } from 'src/app/components/table';
import { TableComponent } from '../../../components/table';

@Component({
  selector: 'app-subscription-types',
  templateUrl: './subscription-types.component.html',
  styleUrl: './subscription-types.component.scss',
  providers: [TitleCasePipe]
})
export class SubscriptionTypesComponent {
  @ViewChild(TableComponent) table: TableComponent;

  constructor(
    private title: Title,
    public subscripitonController: SubscriptionControllerService,
    private titleCasePipe: TitleCasePipe
  ) {
    this.title.setTitle('Planos');
  }

  cols: Array<TableColumn> = [
    new TableColumn.Builder()
      .setHeader('Id')
      .setField('subscriptionTypeId')
      .setRouterLink('/subscription-settings/subscription-types/')
      .setRouterLinkFieldName('subscriptionTypeId')
      .setType('number')
      .build(),
    new TableColumn.Builder()
      .setHeader('Assinatura')
      .setField('subscriptionName')
      .setRouterLink('/subscription-settings/subscription-types/')
      .setRouterLinkFieldName('subscriptionTypeId')
      .setCondition('contains')
      .setDisplayFunction((st: AllSubscriptionTypes) =>
        this.titleCasePipe.transform(st.subscriptionName)
      )
      .build(),
    new TableColumn.Builder()
      .setHeader('Plano')
      .setField('subscriptionTypeName')
      .setRouterLink('/subscription-settings/subscription-types/')
      .setRouterLinkFieldName('subscriptionTypeId')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('Parcelas')
      .setField('installments')
      .setType('number')
      .build(),
    new TableColumn.Builder()
      .setHeader('Edições')
      .setField('editionCount')
      .setType('number')
      .build(),
    new TableColumn.Builder()
      .setHeader('Ativo')
      .setField('active')
      .setType('boolean')
      .build(),
    new TableColumn.Builder()
      .setHeader('Visível')
      .setField('visible')
      .setType('boolean')
      .build(),
    new TableColumn.Builder()
      .setHeader('Valor da assinatura')
      .setField('subscriptionTotalPrice')
      .setType('currency')
      .setCondition('gte')
      .build(),
    new TableColumn.Builder()
      .setHeader('Valor da mensalidade')
      .setField('monthlyPrice')
      .setType('currency')
      .setCondition('gte')
      .build(),
    new TableColumn.Builder()
      .setHeader('Última alteração')
      .setField('dateCreated')
      .setType('date')
      .setCondition('between')
      .build()
  ];
}

<form
  class="form-b4a badge-form"
  [class.editable]="badge"
  [formGroup]="form"
  *ngIf="form"
  (submit)="saveBadge()"
>
  <span class="p-float-label">
    <input id="title" type="text" pInputText formControlName="title" /><label
      for="title"
    >
      Nome da Jóia*
    </label>
    <small
      class="error"
      *ngIf="
        form.controls['title'].errors &&
        form.controls['title'].errors['maxlength']
      "
    >
      Máximo de
      {{ form.controls['title'].errors['maxlength']['requiredLength'] }}
      caracteres
    </small>
    <small
      class="error"
      *ngIf="
        form.controls['title'].errors &&
        form.controls['title'].errors['minlength']
      "
    >
      Mínimo de
      {{ form.controls['title'].errors['minlength']['requiredLength'] }}
      caracteres
    </small>
  </span>
  <span class="p-float-label">
    <input
      id="glampointsMultiplier"
      type="number"
      pInputText
      formControlName="glampointsMultiplier"
    />
    <label for="glampointsMultiplier">Multiplicador de pontos*</label>
    <small
      class="error"
      *ngIf="
        form.controls['glampointsMultiplier'].touched &&
        form.controls['glampointsMultiplier'].dirty &&
        form.controls['glampointsMultiplier'].errors &&
        form.controls['glampointsMultiplier'].errors['min']
      "
    >
      Está abaixo do valor mínimo (1)
    </small>
  </span>
  <span class="p-float-label">
    <input id="level" type="number" pInputText formControlName="level" />
    <label for="level"> Nível* </label>
    <small
      class="error"
      *ngIf="
        form.controls['level'].touched &&
        form.controls['level'].dirty &&
        form.controls['level'].errors &&
        form.controls['level'].errors['min']
      "
    >
      Está abaixo do valor mínimo (1)
    </small>
  </span>
  <span class="p-float-label">
    <input
      id="consecutiveEditionsToAchieve"
      type="number"
      pInputText
      formControlName="consecutiveEditionsToAchieve"
    />
    <label for="consecutiveEditionsToAchieve">Edições Consecutivas*</label>
    <small
      class="error"
      *ngIf="
        form.controls['consecutiveEditionsToAchieve'].touched &&
        form.controls['consecutiveEditionsToAchieve'].dirty &&
        form.controls['consecutiveEditionsToAchieve'].errors &&
        form.controls['consecutiveEditionsToAchieve'].errors['min']
      "
    >
      Está abaixo do valor mínimo (1)
    </small>
  </span>
  <span class="file-upload" *ngIf="!images || !images.length">
    <label for="iconUrl">
      <span>Imagem padrão*</span>
      <img
        [src]="form.value?.iconUrl"
        *ngIf="form.value?.iconUrl"
        alt="Imagem padrão"
      />
      <img
        src="assets/images/no_img_available.jpg"
        *ngIf="!form.value?.iconUrl"
        alt="Imagem padrão"
      />
    </label>
    <p-fileUpload
      *ngIf="permission"
      mode="basic"
      chooseLabel="Selecionar"
      name="file"
      [auto]="true"
      accept="image/*"
      [maxFileSize]="1000000"
      uploadIcon="pi pi-file"
      [customUpload]="true"
      (uploadHandler)="onUpload($event, 'iconUrl', fileUpload)"
      id="iconUrl"
      #fileUpload
    ></p-fileUpload>
  </span>
  <span class="color-picker">
    <label for="hexColorCode">Seletor de cor*</label>
    <p-colorPicker
      formControlName="hexColorCode"
      appendTo="body"
      inputId="hexColorCode"
    />
  </span>
  <div class="buttons">
    <p-button
      *ngIf="permission"
      label="Salvar"
      type="submit"
      [disabled]="!form.valid"
    />
  </div>
</form>
<p-toast />

<app-table
  *ngIf="visibleCols"
  [cols]="visibleCols"
  pageFunctionName="findCompositionToExportPage"
  [service]="compositionService"
  modelName="composições"
  sortField="dateCreated"
  [sortOrder]="1"
  [addButton]="false"
  tableTitle="Composições não exportadas"
  selectionActionIcon="pi pi-verified"
  selectionActionLabel="Validar assinantes"
  selectionActionTooltip="Validar perfil de beleza com os produtos da composição"
  (emitSelection)="checkCompositions($event)"
  [defaultRows]="10"
  [actionButtons]="actionButtons"
  (actionButtonClick)="removeComposition($event.item, $event.$event)"
  [changeColumns]="true"
></app-table>
<p-confirmPopup styleClass="danger"></p-confirmPopup>
<p-toast></p-toast>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreditCardViewComponent } from './credit-card-view.component';



@NgModule({
  declarations: [
    CreditCardViewComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CreditCardViewComponent
  ]
})
export class CreditCardViewModule { }

<div class="address-shipping">
  <p-tag
    *ngIf="isAddressVinculatedInSubscriber()"
    value="Endereço da assinatura"
    [rounded]="true"
  />
  <p-tag
    *ngIf="isAddressVinculatedInPerson()"
    value="Endereço da cobrança"
    [rounded]="true"
  />
  <p class="description">{{ address.name }}</p>
  <p class="description">
    {{ address.street }} {{ address.streetNumber }}
    <span *ngIf="address.streetExtension">, {{ address.streetExtension }}</span>
  </p>
  <p class="description">CEP {{ address.postalCode }}</p>
  <p class="description">
    {{ address.neighborhood }}, {{ address.city }} - {{ address.state }}
  </p>
  <p class="description" *ngIf="address.postOfficeBox">
    Caixa postal: {{ address.postOfficeBox }}
  </p>
  <p class="description">{{ address.reference }}</p>
  <p class="description">{{ address.phone }}</p>
</div>

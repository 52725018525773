<p>Selecione a planilha com os produtos a serem incluídos na vitrine</p>
<div class="upload">
  <p-fileUpload
    accept=".xlsx"
    [auto]="true"
    [customUpload]="true"
    name="file"
    mode="basic"
    [maxFileSize]="1000000"
    chooseLabel="Selecionar"
    #upload
    (uploadHandler)="uploadFile($event, upload)"
  >
  </p-fileUpload>
  <table class="xlsx-sample">
    <thead>
      Planilha exemplo
      <tr>
        <th>InternalEAN</th>
        <th>Pontos troca</th>
        <th>Máximo trocas(/mês)</th>
        <th>Validade</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>XXXXXXXXXXXX</td>
        <td>150</td>
        <td>1</td>
        <td>10/05/2025</td>
      </tr>
      <tr>
        <td>XXXXXXXXXXXX</td>
        <td>180</td>
        <td>1</td>
        <td>01/01/2030</td>
      </tr>
      <tr>
        <td>XXXXXXXXXXXX</td>
        <td>160</td>
        <td>2</td>
        <td>01/08/2024</td>
      </tr>
      *a primeira linha sempre deve conter o cabeçalho
    </tbody>
  </table>
</div>
<br />
<p-tabView *ngIf="variantsInStock || variantsNoStock || productsFailed">
  <p-tabPanel
    [header]="'Variantes habilitadas (' + variantsInStock.length + ')'"
    *ngIf="variantsInStock && variantsInStock.length"
  >
    <app-table
      [lazy]="false"
      [elements]="variantsInStock"
      [cols]="cols"
      [addButton]="false"
      [exportExcel]="false"
      [exportPdf]="false"
      modelName="variantes"
      sortField="productVariantName"
      [sortOrder]="-1"
      [showTitle]="false"
      [changeColumns]="true"
    ></app-table>
  </p-tabPanel>
  <p-tabPanel
    [header]="'Variantes sem estoque mínimo (' + variantsNoStock.length + ')'"
    *ngIf="variantsNoStock && variantsNoStock.length"
  >
    <app-table
      [lazy]="false"
      [elements]="variantsNoStock"
      [cols]="cols"
      [addButton]="false"
      [exportExcel]="false"
      [exportPdf]="false"
      modelName="variantes"
      sortField="productVariantName"
      [showTitle]="false"
      [changeColumns]="true"
      [sortOrder]="-1"
    ></app-table>
  </p-tabPanel>
  <p-tabPanel
    [header]="'Variantes não encontradas (' + productsFailed.length + ')'"
    *ngIf="productsFailed && productsFailed.length"
  >
    <app-table
      [lazy]="false"
      [elements]="productsFailed"
      [cols]="colsFailed"
      [addButton]="false"
      [exportExcel]="false"
      [exportPdf]="false"
      [showTitle]="false"
      modelName="variantes"
      [changeColumns]="true"
      sortField="internalEAN"
      [sortOrder]="-1"
    ></app-table>
  </p-tabPanel>
</p-tabView>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ArticleDetailsComponent } from './article-details/article-details.component';
import { ArticlesComponent } from './articles/articles.component';
import { CourseDetailComponent } from './courses/course-detail/course-detail.component';
import { CoursesComponent } from './courses/courses.component';
import { GlamTvComponent } from './glam-tv/glam-tv.component';
import { LiveDetailComponent } from './lives/live-detail/live-detail.component';
import { LivesComponent } from './lives/lives.component';
import { ShortsComponent } from './shorts/shorts.component';

const routes: Routes = [
  { path: 'article', component: ArticlesComponent },
  { path: 'article/:articleId', component: ArticleDetailsComponent },
  { path: 'glam-tv', component: GlamTvComponent },
  { path: 'glam-tv/:articleId', component: ArticleDetailsComponent },
  { path: 'shorts', component: ShortsComponent },
  { path: 'shorts/:articleId', component: ArticleDetailsComponent },
  { path: 'course', component: CoursesComponent },
  { path: 'course/:courseId', component: CourseDetailComponent },
  { path: 'lives', component: LivesComponent },
  { path: 'lives/:liveId', component: LiveDetailComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContentRoutingModule {}

import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
  ViewChild
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TabView } from 'primeng/tabview';
import { lastValueFrom, map } from 'rxjs';
import {
  Brand,
  BrandControllerService,
  ProductControllerService,
  ProductSummaryItem,
  ProductVariantBrand
} from 'src/app/admin-api';
import { TableColumn } from 'src/app/components/table';
import { getAllBrandEquities, getEquityLabel } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-brand-detail',
  templateUrl: './brand-detail.component.html',
  styleUrls: ['./brand-detail.component.scss']
})
export class BrandDetailComponent implements OnInit {
  @ViewChild(TabView)
  tabview: TabView | undefined;

  brand: Brand | undefined;
  brandId: number | undefined;
  products: Array<ProductSummaryItem> | undefined;
  productVariants: Array<ProductVariantBrand> | undefined;
  selectedTab = 0;
  isMobile?: boolean;
  dropdownFilters = {
    brandEquityLabel: [{ label: 'Todas', value: '-1' }].concat(
      getAllBrandEquities().map((e) => ({
        label: e.label,
        value: e.value.toString()
      }))
    )
  };

  productCols = [
    new TableColumn(
      'SKU Millennium',
      'externalId',
      true,
      'number',
      '/products/catalog/product/',
      'productId'
    ),
    new TableColumn(
      'InternalEAN',
      'internalEAN',
      true,
      'text',
      '/products/catalog/product/',
      'productId',
      true,
      'contains'
    ),
    new TableColumn(
      'Nome',
      'productName',
      true,
      'text',
      '/products/catalog/product/',
      'productId',
      true,
      'contains'
    ),
    new TableColumn(
      'Categoria',
      'category',
      true,
      'text',
      '/products/categories/',
      'categoryId',
      true,
      'contains'
    ),
    new TableColumn(
      'Subcategoria',
      'subcategory',
      true,
      'text',
      '/products/categories/',
      'subcategoryId',
      true,
      'contains'
    ),
    new TableColumn('Nota', 'ratingAverage', false, 'formattedNumber'),
    new TableColumn('Avaliações', 'reviewCount', false, 'formattedInteger'),
    new TableColumn('Variantes', 'variantCount', false, 'formattedInteger'),
    new TableColumn(
      'Criação',
      'dateCreated',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    )
  ];

  variantCols: Array<TableColumn> = [
    new TableColumn(
      'Id',
      'externalId',
      true,
      'number',
      '/products/catalog/product-variant/',
      'productVariantId'
    ),
    new TableColumn(
      'Nome',
      'productVariantName',
      true,
      'text',
      '/products/catalog/product-variant/',
      'productVariantId',
      true,
      'contains'
    ),
    new TableColumn(
      'InternalEAN',
      'internalEAN',
      true,
      'text',
      '/products/catalog/product-variant/',
      'productVariantId',
      true,
      'contains'
    ),
    new TableColumn('Peso (g)', 'weight', false, 'formattedNumber'),
    new TableColumn(
      'Validade',
      'expiration',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Data cadastro',
      'dateCreated',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    ),
    new TableColumn(
      'Data atualização',
      'dateUpdated',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    )
  ];

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.checkScreenSize();
  }
  constructor(
    private activatedRoute: ActivatedRoute,
    private brandService: BrandControllerService,
    private productService: ProductControllerService,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private title: Title
  ) {}

  checkScreenSize(): void {
    this.isMobile = window.innerWidth < 768;
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(async (params: Params) => {
      LoaderService.showLoader();
      if (params['brandId'] && params['brandId'] !== 'new') {
        this.brandId = Number(params['brandId']);
        await Promise.all([
          this.findBrand(),
          this.findProducts(),
          this.findProductVariants()
        ]);
        this.title.setTitle('Marca ' + this.brand?.brandName);
      } else {
        this.title.setTitle('Nova marca');
      }
      this.activatedRoute.queryParams.subscribe((queryParams) => {
        if (queryParams['tab']) {
          this.selectedTab = Number(queryParams['tab']) || 0;
        }
      });
      LoaderService.showLoader(false);
    });
  }

  async findBrand(): Promise<void> {
    try {
      this.brand = await lastValueFrom(
        this.brandService
          .findBrandById(this.brandId as number)
          .pipe(map((data) => data.result))
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error, true);
    }
  }

  async findProducts(): Promise<void> {
    try {
      this.products = await lastValueFrom(
        this.productService
          .findBrandProducts(this.brandId as number)
          .pipe(map((data) => data.result))
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findProductVariants(): Promise<void> {
    try {
      this.productVariants = await lastValueFrom(
        this.productService
          .findProductVariantsListByBrandId(this.brandId as number)
          .pipe(map((data) => data.result))
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
  }

  tabChanged($event: number): void {
    this.router.navigate([`products/brands/${this.brandId}`], {
      queryParams: { tab: $event },
      queryParamsHandling: 'merge'
    });
  }

  async updateBrand(brand: Brand): Promise<void> {
    this.brand = brand;
    this.cdRef.detectChanges();
    LoaderService.showLoader(false);
  }

  get brandEquityLabel(): string | undefined {
    return getEquityLabel(this.brand?.brandEquity as number);
  }
}

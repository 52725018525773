<h3 *ngIf="model === null">Cadastro de variante</h3>
<h3 *ngIf="model">Editar variante</h3>
<form
  class="form-b4a product-variant-form"
  *ngIf="form && (model || model === null)"
  [formGroup]="form"
  (submit)="submit()"
>
  <p-fieldset legend="Informações básicas">
    <span class="p-float-label small-info">
      <input
        id="productVariantName"
        type="text"
        pInputText
        formControlName="productVariantName"
      />
      <label for="productVariantName">Nome da variante *</label>
      <small
        class="error"
        *ngIf="
          form.controls['productVariantName'].errors &&
          form.controls['productVariantName'].errors['maxlength']
        "
        >Máximo de
        {{
          form.controls['productVariantName'].errors['maxlength'][
            'requiredLength'
          ]
        }}
        caracteres</small
      >
      <small
        class="error"
        *ngIf="
          form.controls['productVariantName'].errors &&
          form.controls['productVariantName'].errors['minlength']
        "
        >Mínimo de
        {{
          form.controls['productVariantName'].errors['minlength'][
            'requiredLength'
          ]
        }}
        caracteres</small
      >
      <small>Ex: Esmalte Bela Unha Vermelho 5 ml</small>
    </span>
    <span class="p-float-label small-info">
      <p-inputMask
        id="expirationAttribute"
        formControlName="expirationAttribute"
        mask="99/99/9999"
        placeholder="99/99/9999"
      ></p-inputMask>
      <label for="expirationAttribute">Validade</label>
      <small>data (dd/mm/yyyy)</small>
    </span>
    <span class="file-upload" *ngIf="!images || !images.length">
      <label for="defaultImageURL">
        <span>Imagem padrão</span>
        <img
          [src]="form.value?.defaultImageURL"
          *ngIf="form.value?.defaultImageURL"
        />
        <img
          src="assets/images/no_img_available.jpg"
          *ngIf="!form.value?.defaultImageURL"
        />
      </label>
      <p-fileUpload
        mode="basic"
        chooseLabel="Selecionar"
        name="file"
        [auto]="true"
        accept="image/*"
        [maxFileSize]="1000000"
        uploadIcon="pi pi-file"
        [customUpload]="true"
        (uploadHandler)="onUpload($event, 'defaultImageURL', fileUpload)"
        id="defaultImageURL"
        #fileUpload
      ></p-fileUpload>
    </span>
    <span class="p-float-label small-info" *ngIf="images && images.length">
      <p-dropdown
        [options]="images"
        optionValue="imageUrl"
        formControlName="defaultImageURL"
        styleClass="image-dropdown"
        [virtualScroll]="true"
        [virtualScrollItemSize]="69"
      >
        <ng-template pTemplate="item" let-image>
          <img [src]="image.imageUrl" style="height: 45px; width: auto" />
          <span
            [pTooltip]="imageVariant(image.productVariantId)"
            *ngIf="imageVariant(image.productVariantId)"
            >{{ imageVariant(image.productVariantId) }}</span
          >
        </ng-template>
        <ng-template pTemplate="selectedItem" let-image>
          <img [src]="image.imageUrl" style="height: 45px; width: auto" />
          <span *ngIf="imageVariant(image.productVariantId)">{{
            imageVariant(image.productVariantId)
          }}</span>
        </ng-template>
      </p-dropdown>
      <label for="defaultImageURL">Imagem padrão</label>
    </span>
  </p-fieldset>
  <p-fieldset legend="Quantidades pesos e valores">
    <span class="p-float-label small-info">
      <p-inputNumber
        formControlName="unitMeasure"
        inputId="unitMeasure"
      ></p-inputNumber>
      <label for="unitMeasure">Unidade de medida do produto *</label>
      <small
        class="error"
        *ngIf="
          form.controls['unitMeasure'].errors &&
          form.controls['unitMeasure'].errors['min'] !== undefined
        "
        >Mínimo
        {{ form.controls['unitMeasure'].errors['min']['min'] }}
      </small>
      <small
        >Normalmente o número 1. Combinado com o nome da unidade tem-se: 1 un
        (exemplo)</small
      >
    </span>
    <span class="p-float-label small-info">
      <input id="unitName" type="text" pInputText formControlName="unitName" />
      <label for="unitName">Nome da unidade de medida do produto *</label>
      <small
        class="error"
        *ngIf="
          form.controls['unitName'].errors &&
          form.controls['unitName'].errors['maxlength']
        "
        >Máximo de
        {{ form.controls['unitName'].errors['maxlength']['requiredLength'] }}
        caracteres</small
      >
      <small
        >Normalmente Un. Pode ser Cx (caixa) ou nome da unidade se vendido a
        granel</small
      >
    </span>
    <span class="p-float-label small-info">
      <p-inputNumber
        formControlName="weight"
        inputId="weight"
        mode="decimal"
      ></p-inputNumber>
      <label for="weight">Peso do produto, em gramas (g) *</label>
      <small>Ex: 982.2</small>
    </span>
    <span class="p-float-label small-info">
      <p-inputNumber
        formControlName="costPrice"
        inputId="costPrice"
        [minFractionDigits]="2"
        [maxFractionDigits]="2"
        prefix="R$"
      ></p-inputNumber>
      <label for="costPrice">Valor de compra do produto (R$)</label>
      <small
        >Preencha este campo se o produto tiver sido comprado, caso tenha sido
        bonificado, deixe zero ou em branco</small
      >
    </span>
    <span class="p-float-label small-info">
      <input
        id="internalEAN"
        type="text"
        pInputText
        formControlName="internalEAN"
      />
      <label for="internalEAN">Codigo de Barras INTERNO (EAN)</label>
      <small
        class="error"
        *ngIf="
          form.controls['internalEAN'].errors &&
          form.controls['internalEAN'].errors['maxlength']
        "
        >Máximo de
        {{ form.controls['internalEAN'].errors['maxlength']['requiredLength'] }}
        caracteres</small
      >
      <small>Preencher com cod Barras SEMPRE</small>
    </span>
  </p-fieldset>
  <p-fieldset legend="Dados de nota fiscal">
    <span class="p-float-label small-info">
      <input
        id="invoiceName"
        type="text"
        pInputText
        formControlName="invoiceName"
      />
      <label for="invoiceName">Nome para nota fiscal *</label>
      <small
        class="error"
        *ngIf="
          form.controls['invoiceName'].errors &&
          form.controls['invoiceName'].errors['maxlength']
        "
        >Máximo de
        {{ form.controls['invoiceName'].errors['maxlength']['requiredLength'] }}
        caracteres</small
      >
      <small
        >Preencha com o nome do produto abreviado tal como deve aparecer na nota
        fiscal. Normalmente é o mesmo da nota de entrada</small
      >
    </span>
    <span class="p-float-label small-info">
      <p-inputNumber
        formControlName="invoicePrice"
        inputId="invoicePrice"
        [minFractionDigits]="2"
        [maxFractionDigits]="2"
        prefix="R$"
      ></p-inputNumber>
      <label for="invoicePrice">Valor de nota fiscal do produto (R$)</label>
      <small
        >Preencha com o valor que deve aparecer no valor da nota fiscal glambox.
        Normalmente é o mesmo que na nota de entrada do produto. Não pode ser
        zero.</small
      >
    </span>
    <span class="p-float-label small-info">
      <p-inputMask id="ncm" formControlName="ncm" mask="99999999"></p-inputMask>
      <label for="ncm">NCM</label>
      <small
        class="error"
        *ngIf="
          form.controls['ncm'].errors &&
          form.controls['ncm'].errors['maxlength']
        "
        >Máximo de
        {{ form.controls['ncm'].errors['maxlength']['requiredLength'] }}
        caracteres</small
      >
      <small>8 digitos: Nomenclatura comum do MERCOSUL</small>
    </span>
    <span class="p-float-label small-info">
      <p-inputMask id="cfop" formControlName="cfop" mask="9999"></p-inputMask>
      <label for="cfop">CFOP</label>
      <small
        class="error"
        *ngIf="
          form.controls['cfop'].errors &&
          form.controls['cfop'].errors['maxlength']
        "
        >Máximo de
        {{ form.controls['cfop'].errors['maxlength']['requiredLength'] }}
        caracteres</small
      >
      <small
        >4 digitos: Código fiscal da operação. Ex: Isento ICMS mesmo estado =
        5911, Isento ICMS outros estados 6911</small
      >
    </span>
    <span class="p-float-label small-info">
      <p-inputMask id="cst" formControlName="cst" mask="999"></p-inputMask>
      <label for="cst">CST</label>
      <small
        class="error"
        *ngIf="
          form.controls['cst'].errors &&
          form.controls['cst'].errors['maxlength']
        "
        >Máximo de
        {{ form.controls['cst'].errors['maxlength']['requiredLength'] }}
        caracteres</small
      >
      <small>3 digitos: Código da Situação Tributária</small>
    </span>
    <span class="p-float-label small-info">
      <input id="cean" type="text" pInputText formControlName="cean" />
      <label for="cean">Codigo de Barras (EAN)</label>
      <small
        class="error"
        *ngIf="
          form.controls['cean'].errors &&
          form.controls['cean'].errors['maxlength']
        "
        >Máximo de
        {{ form.controls['cean'].errors['maxlength']['requiredLength'] }}
        caracteres</small
      >
      <small>preencher com cod Barras ou SEM GTIN</small>
    </span>
  </p-fieldset>
  <p-fieldset legend="Informações de Glamclub">
    <span class="p-input-checkbox">
      <p-checkbox
        formControlName="tradeActive"
        [binary]="true"
        inputId="tradeActive"
        (onChange)="onTradeActiveChange()"
      ></p-checkbox>
      <label for="tradeActive">Trocas Ativas?</label>
      <small>Marque para deixar a troca do produto ativa no glamclub</small>
    </span>
    <span></span>
    <ng-container *ngIf="form.value.tradeActive">
      <span class="p-float-label small-info">
        <p-inputNumber
          formControlName="maxTradesPerPerson"
          inputId="maxTradesPerPerson"
        ></p-inputNumber>
        <label for="maxTradesPerPerson">Número máximo de trocas</label>
        <small
          >Preecha o número máximo de trocas deste produto que uma pessoa pode
          fazer no mês.</small
        >
      </span>
      <span class="p-float-label small-info">
        <p-inputNumber
          formControlName="tradePoints"
          inputId="tradePoints"
        ></p-inputNumber>
        <label for="tradePoints">Glampoints para trocar</label>
        <small
          >Preencha a quantidade de pontos (glampoints) para trocar pelo
          produto</small
        >
      </span>
    </ng-container>
  </p-fieldset>
  <small *ngIf="model?.dateUpdated"
    >*Última alteração em:
    {{ model.dateUpdated | date : 'dd/MM/yyyy HH:mm:ss' }}</small
  >
  <div class="buttons">
    <p-button
      severity="danger"
      label="Voltar"
      type="button"
      (onClick)="buttonAction.emit()"
    ></p-button>
    <p-button
      [disabled]="!form.valid"
      severity="primary"
      label="Salvar"
      type="sumbit"
    ></p-button>
  </div>
</form>
<p-toast></p-toast>
<p-confirmDialog [closable]="false"></p-confirmDialog>

export enum CreditCardRecurrenceStatus {
  'Ativo' = 0,
  'Desabilitado por máximo de tentativas' = 1,
  'Desabilitado' = 2,
  'Desabilitado por erro do cartão' = 3
}

export function getAllCreditCardRecurrenceStatus(): Array<{
  label: string;
  value: number;
}> {
  const objetos: Array<{ label: string; value: number }> = [];
  for (const [p, propertyValue] of Object.entries(CreditCardRecurrenceStatus)) {
    try {
      if (Number(propertyValue) >= 0) {
        objetos.push({
          label: p,
          value: Number(propertyValue)
        });
      }
    } catch (error: any) {
      objetos.length;
    }
  }
  return objetos;
}

<ng-container *ngIf="isAnswered">
  <div class="summary">
    <p>
      <strong>Criado em: </strong>&nbsp;{{
        dateCreated | date : 'dd/MM/yyyy HH:mm:ss'
      }}
    </p>
    <p>
      <strong>Última alteração:</strong>&nbsp;{{
        lastModification | date : 'dd/MM/yyyy HH:mm:ss'
      }}
    </p>
  </div>
  <h5><a class="data-link" (click)="showHistory()">Ver histórico</a></h5>
  <table *ngFor="let attribute of attributes()">
    <tr>
      <th style="width: 30px"></th>
      <th>
        {{ attribute.question }}
      </th>
    </tr>
    <tr *ngFor="let opt of attribute.options">
      <td>
        <ng-container *ngIf="opt.checked">X</ng-container>
      </td>
      <td>{{ opt.name }}</td>
    </tr>
  </table>
</ng-container>
<ng-container *ngIf="!isAnswered">
  {{ person?.name }} ainda não respondeu o perfil de beleza
</ng-container>

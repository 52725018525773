import { Component, OnInit, inject } from '@angular/core';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { CloudfunctionsService } from '../../../services/cloudfunctions.service';

@Component({
  selector: 'app-activation-orders',
  templateUrl: './activation-orders.component.html',
  styleUrl: './activation-orders.component.scss'
})
export class ActivationOrdersComponent implements OnInit {
  private cloudfunctionsService: CloudfunctionsService = inject(
    CloudfunctionsService
  );
  pendingResponse: any;

  ngOnInit(): void {
    this.getPendingOrders();
  }
  async getPendingOrders() {
    try {
      LoaderService.showLoader();
      const response = await this.cloudfunctionsService.getPendingOrders();
      this.pendingResponse = {
        ...response,
        reason: response.reason.map((item) => ({
          ...item,
          keys: Object.keys(item)
        }))
      };
      //console.log(this.pendingResponse);
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
  inject
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxPermissionsService } from 'ngx-permissions';
import { MessageService } from 'primeng/api';
import { FileUpload, FileUploadHandlerEvent } from 'primeng/fileupload';
import { lastValueFrom, map } from 'rxjs';
import {
  FeedbackAdminControllerService,
  FeedbackForm
} from 'src/app/admin-api';
import { Role, roleAsObject } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrl: './feedback-form.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class FeedbackFormComponent implements OnInit {
  private feedbackService: FeedbackAdminControllerService = inject(
    FeedbackAdminControllerService
  );
  private messageService: MessageService = inject(MessageService);
  private permissionsService: NgxPermissionsService = inject(
    NgxPermissionsService
  );

  @Input({ required: true })
  model: FeedbackForm;

  @Input()
  permissions = false;

  @Output()
  updated = new EventEmitter<FeedbackForm>();

  form: FormGroup;
  permissionsAccepted = [
    roleAsObject(Role.Full_Administrator).enumValue,
    roleAsObject(Role.Business_Intelligence as any).enumValue,
    roleAsObject(Role.Customer_Success as any).enumValue
  ];

  async ngOnInit(): Promise<void> {
    if (!this.permissions) {
      this.permissions = await this.permissionsService.hasPermission(
        this.permissionsAccepted
      );
    }
    this.form = new FormGroup({
      formTitle: new FormControl<string>(
        { value: this.model?.formTitle, disabled: !this.permissions },
        [Validators.required, Validators.maxLength(255)]
      ),
      glampoints: new FormControl<number>(
        { value: this.model?.glampoints, disabled: !this.permissions },
        [Validators.required, Validators.min(1)]
      ),
      isPublic: new FormControl<number>({
        value: this.model?.isPublic,
        disabled: !this.permissions
      }),
      enabled: new FormControl<number>({
        value: this.model ? this.model.enabled || 0 : 1,
        disabled: !this.permissions
      }),
      formId: new FormControl<number>(
        {
          disabled: !this.model || !this.permissions,
          value: this.model?.formId
        },
        [Validators.required, Validators.min(1)]
      ),
      questions: new FormControl<File>(null),
      fileName: new FormControl<string>({
        value: 'Arquivos .xlsx',
        disabled: true
      })
    });
  }

  async submit(): Promise<void> {
    if (!this.form.valid) {
      Object.keys(this.form.controls).forEach((key) => {
        this.form.controls[key].markAsDirty();
        this.form.controls[key].markAsTouched();
        this.form.controls[key].updateValueAndValidity();
      });
      return;
    }
    LoaderService.showLoader();
    try {
      if (this.model) {
        this.model = await this.updateForm();
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'Formulário atualizado com sucesso'
        });
      } else if (this.form.value.questions) {
        this.model = await this.importForm();
        this.form.controls['formId'].enable();
        this.form.controls['formId'].setValue(this.model.formId);
        this.model = await this.updateForm();
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'Formulário criado com sucesso'
        });
      } else {
        this.model = await lastValueFrom(
          this.feedbackService
            .createFeedbackForm(this.form.value)
            .pipe(map((data) => data.result))
        );
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'Formulário criado com sucesso'
        });
      }
      this.updated.emit(this.model);
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  async importForm(): Promise<FeedbackForm> {
    return lastValueFrom(
      this.feedbackService
        .importFeedbackForm(this.form.value.questions, this.model?.formId || 0)
        .pipe(map((data) => data.result))
    );
  }

  async updateForm(): Promise<FeedbackForm> {
    return lastValueFrom(
      this.feedbackService
        .updateFeedbackForm(this.form.value)
        .pipe(map((data) => data.result))
    );
  }

  setFile($event: FileUploadHandlerEvent, fileUpload: FileUpload): void {
    this.form.controls['questions'].setValue($event.files[0]);
    this.form.controls['fileName'].setValue(this.form.value.questions.name);
    fileUpload.clear();
  }
}

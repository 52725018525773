/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpResponse
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs';

import { NotificationTrendRequest } from '../model/notificationTrendRequest';
import { PageableRequest } from '../model/pageableRequest';
import { ResponseB4AListNotificationTrend } from '../model/responseB4AListNotificationTrend';
import { ResponseB4APageEmailNotificationBasicDetail } from '../model/responseB4APageEmailNotificationBasicDetail';
import { ResponseB4APageEmailNotificationDetail } from '../model/responseB4APageEmailNotificationDetail';

import { Configuration } from '../configuration';
import { BASE_PATH } from '../variables';


@Injectable()
export class NotificationControllerService {

    protected basePath = 'https://admin-api-stage.b4a.com.br';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Listar eventos detalhados por template
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEventDetails(body: PageableRequest, observe?: 'body', reportProgress?: boolean): Observable<ResponseB4APageEmailNotificationDetail>;
    public getEventDetails(body: PageableRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseB4APageEmailNotificationDetail>>;
    public getEventDetails(body: PageableRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseB4APageEmailNotificationDetail>>;
    public getEventDetails(body: PageableRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling getEventDetails.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-key) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ResponseB4APageEmailNotificationDetail>('put',`${this.basePath}/notifications/events/details`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Análise detalhada dos templates
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTemplateAnalytics(body: NotificationTrendRequest, observe?: 'body', reportProgress?: boolean): Observable<ResponseB4AListNotificationTrend>;
    public getTemplateAnalytics(body: NotificationTrendRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseB4AListNotificationTrend>>;
    public getTemplateAnalytics(body: NotificationTrendRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseB4AListNotificationTrend>>;
    public getTemplateAnalytics(body: NotificationTrendRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling getTemplateAnalytics.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-key) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ResponseB4AListNotificationTrend>('put',`${this.basePath}/notifications/analytics`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Listar todas as notificações
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listNotifications(body: PageableRequest, observe?: 'body', reportProgress?: boolean): Observable<ResponseB4APageEmailNotificationBasicDetail>;
    public listNotifications(body: PageableRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseB4APageEmailNotificationBasicDetail>>;
    public listNotifications(body: PageableRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseB4APageEmailNotificationBasicDetail>>;
    public listNotifications(body: PageableRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling listNotifications.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-key) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ResponseB4APageEmailNotificationBasicDetail>('put',`${this.basePath}/notifications`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

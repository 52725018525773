import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, HostListener, inject, OnDestroy } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { FileUpload, FileUploadHandlerEvent } from 'primeng/fileupload';
import { filter, lastValueFrom, map, Subscription } from 'rxjs';
import { FileControllerService } from 'src/app/admin-api';
import { AppDialogService } from 'src/app/services/dialog.service';
import { ImageService } from 'src/app/services/image.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-image-upload',
  template: ''
})
export abstract class ImageUploadComponent<T> implements OnDestroy {
  protected imageService = inject(ImageService);
  private httpClient = inject(HttpClient);

  filesUploaded: Array<string> = [];
  form: FormGroup | FormArray | undefined;
  subscription: Subscription;
  imagesReplaced: Array<string> = [];
  deleting = false;

  constructor(
    protected fileService: FileControllerService,
    protected router: Router
  ) {
    this.subscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event) => {
        if (
          !(event as NavigationStart).url.includes(this.currentUrl) &&
          this.filesUploaded.length
        ) {
          this.deleteUploadedInList(this.filesUploaded).then(() => {
            console.log('Files deleted');
            this.filesUploaded = [];
          });
        }
      });
  }

  @HostListener('window:beforeunload')
  beforeUnload() {
    // Deleta os arquivos que foram enviados para o storage e não foram atualizados
    if (this.deleteOnUnload && this.filesUploaded.length)
      this.deleteUploadedInList(this.filesUploaded).then(() => {
        console.log('Files deleted');
        this.filesUploaded = [];
      });
  }

  ngOnDestroy(): void {
    // Deleta os arquivos que foram enviados para o storage e não foram atualizados
    if (this.filesUploaded.length)
      this.deleteUploadedInList(this.filesUploaded).then(() => {
        console.log('Files deleted');
        this.filesUploaded = [];
      });
  }

  async deleteUploadedInList(list: Array<string>): Promise<void> {
    if (!this.deleting)
      try {
        this.deleting = true;
        const results = await Promise.all(
          list.map((f) =>
            lastValueFrom(
              this.fileService.deleteFile(f).pipe(map((data) => data.result))
            )
          )
        );
        results.forEach((r) => console.log(r));
      } catch (error: any) {
        console.error(error);
      } finally {
        this.deleting = false;
      }
  }

  async onUpload(
    $event: FileUploadHandlerEvent,
    field: string,
    fileUpload: FileUpload = null,
    fileName: string = undefined,
    compress = false,
    expectedWidth?: number,
    expectedHeight?: number
  ): Promise<string> {
    LoaderService.showLoader();
    const file = $event.files[0];
    if (
      expectedWidth &&
      expectedHeight &&
      !(await this.imageService.validateImageDimensions(
        file,
        expectedWidth / expectedHeight
      ))
    ) {
      fileUpload?.clear();
      LoaderService.showLoader(false);
      return;
    }
    if (!fileName && this.modelId) {
      fileName = this.modelId.toString();
    } else if (!fileName && this.model) {
      fileName = typeof this.model;
    } else if (!fileName) {
      fileName = new Date().getTime().toString();
    }
    fileName +=
      '_' +
      field +
      '_' +
      new Date().getTime().toString() +
      '.' +
      file.name.split('.')[1];
    let result: string;
    try {
      if (file.size >= 32 * 1024 * 1000) {
        result = await this.uploadToBucket(this.filePath, file, fileName);
      } else if (compress) {
        result = (await lastValueFrom(
          this.fileService
            .compressAndUploadFileForm(file, this.filePath)
            .pipe(map((data) => data.result))
        )) as string;
      } else {
        // Se o arquivo tem menos de 32MB
        result = (await lastValueFrom(
          this.fileService
            .uploadFileForm(this.filePath, file, fileName)
            .pipe(map((data) => data.result))
        )) as string;
      }
      this.filesUploaded.push(result);
      if (this.model && FormUtil.deepGet(this.model, field.split('.')))
        this.imagesReplaced.push(
          FormUtil.deepGet(this.model, field.split('.'))
        );
      this.form?.get(field)?.setValue(result);
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
    fileUpload?.clear();
    LoaderService.showLoader(false);
    return result;
  }

  async uploadToBucket(filePath: string, file: File, fileName: string) {
    const uploadLink = await lastValueFrom(
      this.fileService
        .generateSignedUploadURL(filePath.concat(fileName))
        .pipe(map((data) => data.result))
    );
    const headers = new HttpHeaders().append(
      'Content-Type',
      'application/octet-stream'
    );
    await lastValueFrom(
      this.httpClient.put<string>(uploadLink, await file.arrayBuffer(), {
        headers
      })
    );
    return (
      await lastValueFrom(
        this.fileService
          .generateSignedDownloadURL(filePath.concat(fileName))
          .pipe(map((data) => data.result))
      )
    )
      .split('?')
      .at(0);
  }

  async afterSubmit(): Promise<void> {
    if (this.imagesReplaced?.length) {
      await this.deleteUploadedInList(this.imagesReplaced);
      this.imagesReplaced = [];
    }
    const images = FormUtil.getImageLinksInObject(this.form.value);
    this.filesUploaded = this.filesUploaded.filter((f) => !images.includes(f));
    if (this.filesUploaded?.length) {
      await this.deleteUploadedInList(this.filesUploaded);
      this.filesUploaded = [];
    }
  }

  get deleteOnUnload(): boolean {
    return true;
  }

  get currentUrl(): string {
    return location.pathname;
  }

  public abstract get filePath(): string;
  public abstract get model(): T | undefined;
  public abstract get modelId(): string | number | undefined;
}

export interface PersonDetail {
  personId?: number;
  username?: string;
  name?: string;
  lastName?: string;
  phone?: string;
  phoneFormatted?: string;
  rewardBalance?: number;
  birthday?: Date;
  cpf?: number;
  dateCreated?: Date;
  dateUpdated?: Date;
  couponName?: string;
  badge?: string;
  badgeId?: number;
  personStatus?: number;
  postalCode?: string;
  street?: string;
  streetNumber?: string;
  streetExtensions?: string;
  neighborhood?: string;
  city?: string;
  cityCode?: number;
  state?: string;
  stateCode?: number;
  urlImageProfile?: string;
  newsletterOptIn?: boolean;
  glampointsMultiplier?: number;
  instagram?: string;
  facebookId?: number;
  facebookAccessToken?: string;
  cpfFormatted?: string;
  cpfString?: string;
  addressId?: number;
}

import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom, map } from 'rxjs';
import {
  CouponControllerService,
  CouponUsageSummary,
  CouponUsageType,
  Edition,
  EditionControllerService
} from 'src/app/admin-api';
import {
  EditionId,
  getAllSubscriptionsIncludeAll,
  SubscriberGroupEnum
} from 'src/app/models';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-coupons-dashboard',
  templateUrl: './coupons-dashboard.component.html',
  styleUrls: ['./coupons-dashboard.component.scss']
})
export class CouponsDashboardComponent implements OnInit {
  form = new FormGroup({
    subscriptionId: new FormControl<number>(0, [
      Validators.required,
      Validators.min(0)
    ]),
    editionId: new FormControl<number>(
      Number(
        `${new Date().getFullYear()}${(new Date().getMonth() + 1)
          .toString()
          .padStart(2, '0')}`
      ),
      [Validators.required, Validators.min(1)]
    )
  });
  subscriptions: Array<{ label: string; value: number }>;
  editions: Array<Edition> | undefined;
  couponUsages: Array<CouponUsageSummary> | undefined;
  ready = false;
  chartDataAcquisition:
    | {
        labels: Array<string>;
        datasets: Array<{
          type: 'pie' | 'doughtnut' | 'line' | 'bar' | 'radar' | 'polarArea';
          label: string;
          borderWidth?: number;
          backgroundColor?: string;
          borderColor?: string;
          fill?: boolean;
          tension?: number;
          data: Array<number>;
          subscriptionId?: number;
          stack?: string;
          hidden?: boolean;
        }>;
      }
    | undefined;
  chartDataReactivation:
    | {
        labels: Array<string>;
        datasets: Array<{
          type: 'pie' | 'doughtnut' | 'line' | 'bar' | 'radar' | 'polarArea';
          label: string;
          borderWidth?: number;
          backgroundColor?: string;
          borderColor?: string;
          fill?: boolean;
          tension?: number;
          data: Array<number>;
          subscriptionId?: number;
          stack?: string;
          hidden?: boolean;
        }>;
      }
    | undefined;
  chartData:
    | {
        labels: Array<string>;
        datasets: Array<{
          type: 'pie' | 'doughtnut' | 'line' | 'bar' | 'radar' | 'polarArea';
          label: string;
          borderWidth?: number;
          backgroundColor?: string;
          borderColor?: string;
          fill?: boolean;
          tension?: number;
          data: Array<number>;
          subscriptionId?: number;
          stack?: string;
          hidden?: boolean;
        }>;
      }
    | undefined;
  chartConfig = {
    options: {
      animation: {
        easing: 'easeInQuad'
      }
    },
    plugins: {
      tooltips: {
        mode: 'index',
        intersect: false
      },
      legend: {
        labels: {
          color: 'black'
        }
      }
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: 'gray'
        },
        grid: {
          color: 'gray',
          drawBorder: false
        }
      },
      y: {
        stacked: true,
        ticks: {
          color: 'gray'
        },
        grid: {
          color: 'gray',
          drawBorder: false
        }
      }
    }
  };
  width: string;
  couponUsageTypes: Array<CouponUsageType>;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.checkScreenSize();
  }

  constructor(
    private couponService: CouponControllerService,
    private editionService: EditionControllerService,
    private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private router: Router,
    private title: Title
  ) {
    this.subscriptions = getAllSubscriptionsIncludeAll();
    this.checkScreenSize();
  }

  checkScreenSize(): void {
    if (window.innerWidth < 768) this.width = '700px';
    else this.width = '100%';
  }

  async ngOnInit(): Promise<void> {
    this.activatedRoute.params.subscribe(async (params) => {
      if (params['editionId']) {
        this.form.patchValue({
          editionId: Number(params['editionId']),
          subscriptionId: Number((params['editionId'] / 1000000).toFixed(0))
        });
        LoaderService.showLoader();
        const fail = document.getElementById('img-fail');
        if (fail?.style?.display === 'none') {
          fail.style.display = 'block';
        }
        await this.findPage();
        await this.findEditions();
        this.title.setTitle('Dashboard de Cupons - ' + this.edition?.theme);
        LoaderService.showLoader(false);
      } else {
        this.navigate();
      }
    });
  }

  async findEditions(): Promise<void> {
    try {
      this.editions = await lastValueFrom(
        this.editionService
          .findEditionsBySubscriptionId(this.form.value.subscriptionId || 1)
          .pipe(map((data) => data.result))
      );
      if (this.form.value.subscriptionId === 0) {
        this.editions?.forEach(
          (e) => (e.editionId = (e.editionId || 0) % 1000000)
        );
      }
    } catch (error: any) {
      this.editions = [];
    }
  }

  async findPage(): Promise<void> {
    this.ready = false;
    await Promise.all([this.findCouponUsages(), this.findCouponUsageTypes()]);
    this.chartDataAcquisition = {
      labels: this.xAxis,
      datasets: this.couponUsageTypes
        .concat([
          {
            couponUsageType: 'Sem cupom',
            couponUsageTypeId: -1,
            description: 'Sem uso de cupom',
            color: 'gray'
          }
        ])
        .map((s) => ({
          data: this.xAxis.map((x) =>
            this.editionUsages
              .filter(
                (sc) =>
                  sc.couponUsageTypeId === s.couponUsageTypeId &&
                  this.datePipe.transform(sc.dateCreated, 'dd/MM/yyyy') === x &&
                  sc.subscriberGroupId ===
                    SubscriberGroupEnum.Conquistadas_com_pagamento
              )
              .reduce((sum, sc) => (sum += sc.total || 0), 0)
          ),
          label: s.couponUsageType,
          type: 'bar',
          backgroundColor: s.color,
          borderColor: s.color,
          couponUsageTypeId: s.couponUsageTypeId,
          stack: 'couponUsageTypes'
        }))
    };
    this.chartDataReactivation = {
      labels: this.xAxis,
      datasets: this.couponUsageTypes
        .concat([
          {
            couponUsageType: 'Sem cupom',
            couponUsageTypeId: -1,
            description: 'Sem uso de cupom',
            color: 'gray'
          }
        ])
        .map((s) => ({
          data: this.xAxis.map((x) =>
            this.editionUsages
              .filter(
                (sc) =>
                  sc.couponUsageTypeId === s.couponUsageTypeId &&
                  this.datePipe.transform(sc.dateCreated, 'dd/MM/yyyy') === x &&
                  sc.subscriberGroupId ===
                    SubscriberGroupEnum.Reativacoes_com_pagamento
              )
              .reduce((sum, sc) => (sum += sc.total || 0), 0)
          ),
          label: s.couponUsageType,
          type: 'bar',
          backgroundColor: s.color,
          borderColor: s.color,
          couponUsageTypeId: s.couponUsageTypeId,
          stack: 'couponUsageTypes'
        }))
    };
    this.chartData = {
      labels: this.xAxis,
      datasets: this.couponUsageTypes
        .concat([
          {
            couponUsageType: 'Sem cupom',
            couponUsageTypeId: -1,
            description: 'Sem uso de cupom',
            color: 'gray'
          }
        ])
        .map((s) => ({
          data: this.xAxis.map((x) =>
            this.editionUsages
              .filter(
                (sc) =>
                  sc.couponUsageTypeId === s.couponUsageTypeId &&
                  this.datePipe.transform(sc.dateCreated, 'dd/MM/yyyy') === x
              )
              .reduce((sum, sc) => (sum += sc.total || 0), 0)
          ),
          label: s.couponUsageType,
          type: 'bar',
          backgroundColor: s.color,
          borderColor: s.color,
          couponUsageTypeId: s.couponUsageTypeId,
          stack: 'couponUsageTypes'
        }))
    };
    this.chartDataAcquisition.datasets.push({
      label: 'Total',
      backgroundColor: FormUtil.getColor(null, null, null),
      borderColor: FormUtil.getColor(null, null, null),
      type: 'bar',
      stack: 'total',
      data: this.xAxis.map((x) =>
        this.editionUsages
          .filter(
            (sc) =>
              this.datePipe.transform(sc.dateCreated, 'dd/MM/yyyy') === x &&
              sc.subscriberGroupId ===
                SubscriberGroupEnum.Conquistadas_com_pagamento
          )
          .reduce((sum, sc) => (sum += sc.total || 0), 0)
      ),
      hidden: true
    });
    this.chartDataReactivation.datasets.push({
      label: 'Total',
      backgroundColor: FormUtil.getColor(null, null, null),
      borderColor: FormUtil.getColor(null, null, null),
      type: 'bar',
      stack: 'total',
      data: this.xAxis.map((x) =>
        this.editionUsages
          .filter(
            (sc) =>
              this.datePipe.transform(sc.dateCreated, 'dd/MM/yyyy') === x &&
              sc.subscriberGroupId ===
                SubscriberGroupEnum.Reativacoes_com_pagamento
          )
          .reduce((sum, sc) => (sum += sc.total || 0), 0)
      ),
      hidden: true
    });
    this.chartData.datasets.push({
      label: 'Total',
      backgroundColor: FormUtil.getColor(null, null, null),
      borderColor: FormUtil.getColor(null, null, null),
      type: 'bar',
      stack: 'total',
      data: this.xAxis.map((x) =>
        this.editionUsages
          .filter(
            (sc) => this.datePipe.transform(sc.dateCreated, 'dd/MM/yyyy') === x
          )
          .reduce((sum, sc) => (sum += sc.total || 0), 0)
      ),
      hidden: true
    });
    this.ready = true;
  }

  async findCouponUsages(): Promise<void> {
    try {
      const [old, current] = await Promise.all([
        await lastValueFrom(
          this.couponService
            .findCouponUsageReport(this.form.value.editionId)
            .pipe(
              map((data) =>
                data.result.map((d) => ({
                  ...d,
                  dateCreated: FormUtil.utcDate(d.dateCreated)
                }))
              )
            )
        ),
        await lastValueFrom(
          this.couponService
            .findCouponUsageReport(
              EditionId.incrementEdition(this.form.value.editionId, -1)
            )
            .pipe(
              map((data) =>
                data.result.map((d) => ({
                  ...d,
                  dateCreated: FormUtil.utcDate(d.dateCreated)
                }))
              )
            )
        )
      ]);
      this.couponUsages = old.concat(current);
    } catch (error: any) {
      this.couponUsages = [];
    }
  }

  async findCouponUsageTypes(): Promise<void> {
    try {
      this.couponUsageTypes = await lastValueFrom(
        this.couponService.findUsageTypesList().pipe(
          map((data) => {
            data.result.sort((c1, c2) => {
              if (c1.couponUsageType > c2.couponUsageType) return 1;
              if (c2.couponUsageType > c1.couponUsageType) return -1;
              return c2.couponUsageTypeId - c1.couponUsageTypeId;
            });
            return data.result;
          })
        )
      );
    } catch (error: any) {
      this.couponUsageTypes = [];
    }
  }

  async changeSubscription(): Promise<void> {
    LoaderService.showLoader();
    const form = { ...this.form.value };
    this.form.controls['editionId'].reset(
      (form.subscriptionId || 0) * 1000000 + ((form.editionId || 0) % 1000000)
    );
    delete this.couponUsages;
    this.ready = false;
    this.navigate();
    LoaderService.showLoader(false);
  }

  async changeEdition(): Promise<void> {
    LoaderService.showLoader();
    delete this.couponUsages;
    this.ready = false;
    this.navigate();
    LoaderService.showLoader(false);
  }

  editionDate(editionId: number): string {
    return FormUtil.editionDate(editionId);
  }

  editionDateName(editionId: number): string {
    return (
      this.datePipe.transform(
        new Date(
          `${((editionId % 1000000) / 100).toFixed(0)}-${editionId % 100}-02`
        ),
        'MMMM/yyyy'
      ) || this.editionDate(editionId)
    );
  }

  navigate(): void {
    this.router.navigate(['/dashboard/coupons/' + this.form.value.editionId]);
  }

  get editionUsages(): Array<CouponUsageSummary> {
    this.couponUsages.sort(
      (s1, s2) => s1.dateCreated.getTime() - s2.dateCreated.getTime()
    );
    return this.couponUsages?.filter(
      (c) => c.editionId % 1000000 === this.form.value.editionId % 1000000
    );
  }

  get edition(): Edition | undefined {
    return this.editions?.find(
      (e) => e.editionId === this.form.value.editionId
    );
  }

  get xAxis(): Array<string> {
    return (
      this.editionUsages?.reduce((list: Array<string>, sub) => {
        const date = this.datePipe.transform(sub.dateCreated, 'dd/MM/yyyy');
        if (!list.some((i) => i === date)) {
          list.push(date);
        }
        return list;
      }, []) || []
    );
  }

  get currentEdition(): boolean {
    return (
      this.form.value.editionId ===
      EditionId.currentEdition(this.form.value.subscriptionId)
    );
  }
}

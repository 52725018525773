import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-email-marketing-form',
  templateUrl: './email-marketing-form.component.html',
  styleUrls: ['./email-marketing-form.component.scss']
})
export class EmailMarketingFormComponent {
  @Input() loading = false;
  @Output() search = new EventEmitter<string>();
  @Output() clear = new EventEmitter<void>();

  searchControl = new FormControl('');

  constructor() {
    this.searchControl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((value) => this.search.emit(value));
  }

  clearSearch(): void {
    this.searchControl.setValue('');
    this.clear.emit();
  }

  get hasFilter(): boolean {
    return !!this.searchControl.value?.trim();
  }
}

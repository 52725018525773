<form
  class="form-b4a product-form"
  [class.editable]="product"
  [formGroup]="form"
  *ngIf="form"
  (submit)="saveProduct()"
>
  <span class="p-float-label">
    <input
      id="productName"
      type="text"
      pInputText
      formControlName="productName"
    />
    <label for="productName">Nome do produto *</label>
  </span>
  <span class="p-float-label" *ngIf="brands">
    <p-dropdown
      [options]="brands"
      optionLabel="brandName"
      optionValue="brandId"
      id="brandId"
      formControlName="brandId"
      [autoDisplayFirst]="false"
      [filter]="true"
      filterBy="brandName"
      appendTo="body"
    ></p-dropdown>
    <label for="brandId">Marca *</label>
  </span>
  <span class="p-float-label" *ngIf="productVariants">
    <p-dropdown
      [options]="productVariants"
      optionLabel="productVariantName"
      optionValue="productVariantId"
      id="defaultProductVariantId"
      formControlName="defaultProductVariantId"
      [autoDisplayFirst]="false"
      [filter]="true"
      appendTo="body"
      filterBy="productVariantName,internalEAN"
    ></p-dropdown>
    <label for="defaultProductVariantId">Variante padrão do produto</label>
  </span>
  <span class="p-float-label" *ngIf="productTypes">
    <p-dropdown
      [options]="productTypes"
      id="productTypeId"
      [autoDisplayFirst]="false"
      formControlName="productTypeId"
      appendTo="body"
    ></p-dropdown>
    <label for="productTypeId">Tipo de produto *</label>
  </span>
  <span class="p-float-label" *ngIf="categories">
    <p-dropdown
      [options]="categories"
      id="categoryId"
      optionValue="categoryId"
      optionLabel="displayName"
      [autoDisplayFirst]="false"
      formControlName="categoryId"
      [filter]="true"
      appendTo="body"
      filterBy="displayName"
    >
    </p-dropdown>
    <label for="categoryId">Categoria do produto</label>
  </span>
  <span class="p-float-label" *ngIf="feedbackForms">
    <p-dropdown
      [options]="feedbackForms"
      optionValue="formId"
      optionLabel="formTitle"
      id="activeFeedbackId"
      [autoDisplayFirst]="false"
      formControlName="activeFeedbackId"
      [filter]="true"
      appendTo="body"
      filterBy="formTitle"
      [virtualScroll]="true"
      [virtualScrollItemSize]="45"
    ></p-dropdown>
    <label for="activeFeedbackId">Formulário de feedback</label>
  </span>
  <span class="p-float-label">
    <p-autoComplete
      formControlName="finalities"
      [suggestions]="filteredFinalities"
      (completeMethod)="filterFinalities($event)"
      (onSelect)="onFinalitySelect($event)"
      field="name"
      [multiple]="true"
      [minLength]="1"
      [maxlength]="255"
      appendTo="body"
      [autoHighlight]="true"
      [dropdown]="true"
    >
    </p-autoComplete>
    <label for="finalities">Finalidades</label>
  </span>
  <span class="file-upload" *ngIf="!images || !images.length">
    <label for="defaultImageURL">
      <span>Imagem padrão</span>
      <img
        [src]="form.value?.defaultImageURL"
        *ngIf="form.value?.defaultImageURL"
      />
      <img
        src="assets/images/no_img_available.jpg"
        *ngIf="!form.value?.defaultImageURL"
      />
    </label>
    <p-fileUpload
      mode="basic"
      chooseLabel="Selecionar"
      name="file"
      [auto]="true"
      accept="image/*"
      [maxFileSize]="1000000"
      uploadIcon="pi pi-file"
      [customUpload]="true"
      (uploadHandler)="onUpload($event, 'defaultImageURL', fileUpload)"
      id="defaultImageURL"
      #fileUpload
    ></p-fileUpload>
  </span>
  <span class="p-float-label" *ngIf="images && images.length">
    <p-dropdown
      [options]="images"
      optionValue="imageUrl"
      formControlName="defaultImageURL"
      styleClass="image-dropdown"
      [virtualScroll]="true"
      [virtualScrollItemSize]="69"
      [autoDisplayFirst]="false"
    >
      <ng-template pTemplate="item" let-image>
        <img [src]="image.imageUrl" style="height: 45px; width: auto" />
        <span
          [pTooltip]="imageVariant(image.productVariantId)"
          *ngIf="imageVariant(image.productVariantId)"
          >{{ imageVariant(image.productVariantId) }}</span
        >
      </ng-template>
      <ng-template pTemplate="selectedItem" let-image>
        <img [src]="image.imageUrl" style="height: 45px; width: auto" />
        <span *ngIf="imageVariant(image.productVariantId)">{{
          imageVariant(image.productVariantId)
        }}</span>
      </ng-template>
    </p-dropdown>
    <label for="defaultImageURL">Imagem padrão</label>
  </span>
  <span
    class="p-input-editor"
    [class.invalid]="
      form.controls['productSummary'].dirty &&
      form.controls['productSummary'].errors
    "
  >
    <label for="productSummary">
      {{ descriptionField('productSummary')?.label }} *
    </label>
    <small
      *ngIf="hasSyncInfo('productSummary')"
      class="warning"
      (click)="syncInfo(descriptionField('productSummary'))"
    >
      * {{ descriptionField('productSummary')?.label }} está diferente na loja
    </small>
    <p-editor formControlName="productSummary" id="productSummary"></p-editor>
    <small
      [class.error]="
        form.controls['productSummary'].invalid &&
        form.controls['productSummary'].dirty
      "
      >{{ form.value.productSummary?.length || 0 }}/4000</small
    >
  </span>
  <span
    class="p-input-editor"
    [class.invalid]="
      form.controls['differentials'].dirty &&
      form.controls['differentials'].errors
    "
  >
    <label for="differentials">
      {{ descriptionField('differentials')?.label }}
    </label>
    <small
      *ngIf="hasSyncInfo('differentials')"
      class="warning"
      (click)="syncInfo(descriptionField('differentials'))"
    >
      * {{ descriptionField('differentials')?.label }} está diferente na loja
    </small>
    <p-editor formControlName="differentials" id="differentials"></p-editor>
    <small
      [class.error]="
        form.controls['differentials'].invalid &&
        form.controls['differentials'].dirty
      "
      >{{ form.value.differentials?.trim().length || 0 }}/4000</small
    >
  </span>
  <span
    class="p-input-editor"
    [class.invalid]="
      form.controls['whoMustUse'].dirty && form.controls['whoMustUse'].errors
    "
  >
    <label for="whoMustUse">
      {{ descriptionField('whoMustUse')?.label }}
    </label>
    <small
      *ngIf="hasSyncInfo('whoMustUse')"
      class="warning"
      (click)="syncInfo(descriptionField('whoMustUse'))"
    >
      * {{ descriptionField('whoMustUse')?.label }} está diferente na loja
    </small>
    <p-editor formControlName="whoMustUse" id="whoMustUse"></p-editor>
    <small
      [class.error]="
        form.controls['whoMustUse'].invalid && form.controls['whoMustUse'].dirty
      "
      >{{ form.value.whoMustUse?.trim().length || 0 }}/4000</small
    >
  </span>
  <span
    class="p-input-editor"
    [class.invalid]="
      form.controls['howToUse'].dirty && form.controls['howToUse'].errors
    "
  >
    <label for="howToUse">
      {{ descriptionField('howToUse')?.label }}
    </label>
    <small
      *ngIf="hasSyncInfo('howToUse')"
      class="warning"
      (click)="syncInfo(descriptionField('howToUse'))"
    >
      * {{ descriptionField('howToUse')?.label }} está diferente na loja
    </small>
    <p-editor formControlName="howToUse" id="howToUse"></p-editor>
    <small
      [class.error]="
        form.controls['howToUse'].invalid && form.controls['howToUse'].dirty
      "
      >{{ form.value.howToUse?.trim().length || 0 }}/4000</small
    >
  </span>
  <span
    class="p-input-editor"
    [class.invalid]="
      form.controls['aboutTheBrand'].dirty &&
      form.controls['aboutTheBrand'].errors
    "
  >
    <label for="aboutTheBrand">
      {{ descriptionField('aboutTheBrand')?.label }}
    </label>
    <small
      *ngIf="hasSyncInfo('aboutTheBrand')"
      class="warning"
      (click)="syncInfo(descriptionField('aboutTheBrand'))"
    >
      * {{ descriptionField('aboutTheBrand')?.label }} está diferente na loja
    </small>
    <p-editor formControlName="aboutTheBrand" id="aboutTheBrand"></p-editor>
    <small
      [class.error]="
        form.controls['aboutTheBrand'].invalid &&
        form.controls['aboutTheBrand'].dirty
      "
      >{{ form.value.aboutTheBrand?.trim().length || 0 }}/4000</small
    >
  </span>
  <span
    class="p-input-editor"
    [class.invalid]="
      form.controls['ingredients'].dirty && form.controls['ingredients'].errors
    "
  >
    <label for="ingredients">
      {{ descriptionField('ingredients')?.label }}
    </label>
    <small
      *ngIf="hasSyncInfo('ingredients')"
      class="warning"
      (click)="syncInfo(descriptionField('ingredients'))"
    >
      * {{ descriptionField('ingredients')?.label }} está diferente na loja
    </small>
    <p-editor formControlName="ingredients" id="ingredients"></p-editor>
    <small
      [class.error]="
        form.controls['ingredients'].invalid &&
        form.controls['ingredients'].dirty
      "
      >{{ form.value.ingredients?.trim().length || 0 }}/4000</small
    >
  </span>
  <small *ngIf="product?.dateUpdated"
    >*Última alteração em:
    {{ product.dateUpdated | date : 'dd/MM/yyyy HH:mm:ss' }}</small
  >
  <div class="buttons">
    <p-button label="Salvar" type="submit" [disabled]="!form.valid"></p-button>
  </div>
</form>
<p-toast></p-toast>

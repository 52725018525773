import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent {
  interval: any;
  seconds = 3;
  constructor(
    private router: Router,
    private permissionsService: NgxPermissionsService
  ) {
    this.permissionsService.flushPermissions();
    TokenStorageService.signOut();
    this.interval = setInterval(() => {
      this.seconds--;
      if (!this.seconds) {
        clearInterval(this.interval);
        this.router.navigate(['/']);
      }
    }, 1000);
  }
}

import { Component, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-access-looker',
  templateUrl: './access-looker.component.html',
  styleUrl: './access-looker.component.scss'
})
export class AccessLookerComponent {
  constructor() {
    inject(Title).setTitle('Lista de acesssos unificados');
  }
}

/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SubscriberBillingHistoryEdition {
  subscriberBillingId?: number;
  subscriberId?: number;
  editionId?: number;
  amount?: number;
  installment?: number;
  subscriberBillingStatus?: number;
  paymentId?: number;
  paymentMethod?: number;
  paymentLogId?: number;
  paymentIdentificationCode?: string;
  paymentIdentificationMessage?: string;
  recurrence?: number;
  attempt?: number;
  dateCreated?: Date;
  dateUpdated?: Date;
  dateExpired?: Date;
  refundValue?: number;
  notifiedCIO?: boolean;
  subscriberControlId?: number;
  previousBillingId?: number;
  boletoUrl?: string;
  boletoCode?: string;
  boletoExpirateDate?: Date;
  boletoStatus?: string;
  shippingPrice?: number;
  discount?: number;
  theme?: string;
  title?: string;
  subscriptionId?: number;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditionImageComponent } from './edition-image.component';



@NgModule({
  declarations: [
    EditionImageComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    EditionImageComponent
  ]
})
export class EditionImageModule { }

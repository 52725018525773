<div class="operations-compositions">
  <div class="filters">
    <form class="form-b4a" [formGroup]="form">
      <span class="p-float-label">
        <p-dropdown
          inputId="subscriptionId"
          [options]="subscriptions"
          (onChange)="changeSubscription()"
          formControlName="subscriptionId"
          [autoDisplayFirst]="false"
          appendTo="body"
        >
        </p-dropdown>
        <label for="subscriptionId">Assinatura</label>
      </span>
      <span class="p-float-label">
        <p-dropdown
          inputId="editionId"
          [options]="editions"
          *ngIf="editions"
          optionValue="editionId"
          optionLabel="theme"
          (onChange)="changeEdition()"
          [filter]="true"
          filterBy="theme,title,editionId,description"
          filterMatchMode="contains"
          [autoDisplayFirst]="false"
          formControlName="editionId"
          appendTo="body"
        >
          <ng-template pTemplate="item" let-edition>
            {{ edition.theme }}
            {{ editionDate(edition.editionId) }}
          </ng-template>
          <ng-template pTemplate="selectedItem">
            {{ edition?.theme }}
          </ng-template>
        </p-dropdown>
        <label for="editionId">Ediçao</label>
      </span>
    </form>
    <div class="picture">
      <app-edition-image
        [imageURL]="edition.imageURL"
        [editionId]="edition.editionId"
        *ngIf="edition"
      ></app-edition-image>
    </div>
  </div>
  <div>
    <app-table
      [service]="compositionService"
      sortField="compositionId"
      [sortOrder]="1"
      modelName="composições"
      [cols]="compositionCols"
      [addButton]="false"
      [fixedFilters]="fixedFilters"
      *ngIf="edition && form.valid && !loading"
      pageFunctionName="findCompositionProductions"
      tableTitle="Controle de produção"
      [changeColumns]="true"
      [actionButtons]="actionButtons"
      (actionButtonClick)="tableAction($event)"
    ></app-table>
  </div>
</div>

export interface ShopCategoryFilter {
  id: number;
  description: string;
  slug: string;
  enabled: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  options: Array<{
    id: string;
    description: string;
  }>;
}

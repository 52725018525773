<div class="coupon-change-schedule-modal">
  <p-panel header="Informações gerais" class="form">
    <form
      class="form-b4a"
      [class.form-b4a--confirmation]="confirmation"
      [formGroup]="form"
    >
      <ng-container *ngIf="!confirmation">
        <span class="p-float-label">
          <p-calendar
            formControlName="dateChange"
            id="dateChange"
            name="dateChange"
            dateFormat="dd/mm/yy"
            appendTo="body"
            [minDate]="tomorrow"
          />
          <label for="dateChange">Data da alteração *</label>
        </span>
        <span class="p-float-label">
          <p-dropdown
            formControlName="couponType"
            inputId="couponType"
            [options]="couponTypes"
            appendTo="body"
            [autoDisplayFirst]="false"
            (onChange)="onCouponTypeChange()"
          />
          <label for="couponType">Tipo do cupom *</label>
        </span>
        <span class="p-float-label">
          <p-inputNumber
            *ngIf="form.value.couponType === 0"
            [min]="0"
            [max]="1"
            inputId="value"
            formControlName="value"
            [minFractionDigits]="2"
            [maxFractionDigits]="3"
          />
          <p-inputNumber
            *ngIf="form.value.couponType === 1"
            [min]="0"
            prefix="R$"
            [minFractionDigits]="2"
            [maxFractionDigits]="2"
            inputId="value"
            formControlName="value"
          />
          <label for="value">Valor *</label>
          <small
            class="error"
            *ngIf="
              form.controls['value'].dirty &&
              form.controls['value'].errors &&
              form.controls['value'].errors['max']
            "
          >
            Valor máximo {{ form.controls['value'].errors['max']['max'] }}
          </small>
          <small
            class="error"
            *ngIf="
              form.controls['value'].dirty &&
              form.controls['value'].errors &&
              form.controls['value'].errors['min']
            "
          >
            Valor mínimo {{ form.controls['value'].errors['min']['min'] }}
          </small>
          <small *ngIf="form.value.couponType === 0">{{
            percentageSuffix
          }}</small>
        </span>
        <span class="p-float-label">
          <p-multiSelect
            *ngIf="subscriptionTypesTree?.length"
            formControlName="subscriptionTypeIds"
            inputId="subscriptionTypes"
            [options]="subscriptionTypesTree"
            appendTo="body"
            scrollHeight="250px"
            display="chip"
            (onPanelHide)="configSubscriptionsTree()"
            [group]="true"
            placeholder=" "
          >
            <ng-template let-group pTemplate="group">
              <span>{{ group.label }}</span>
            </ng-template>
            <ng-template let-item pTemplate="item">
              <span>{{ item.label }}</span>
            </ng-template>
            <ng-template let-value pTemplate="selectedItems">
              <span *ngFor="let item of value"
                >{{ subscriptionLabel(item) }},
              </span>
              <span *ngIf="!value?.length">Sem restrições</span>
            </ng-template>
          </p-multiSelect>
          <label for="subscriptionTypes">Restrição de assinatura e plano</label>
          <small *ngIf="!form.value.subscriptionTypeIds?.length"
            >* O cupom será aceito em todas as Assinaturas e Planos</small
          >
        </span>
        <span class="p-float-label full-w">
          <input type="text" pInputText formControlName="freebieMessage" />
          <small
            class="error"
            *ngIf="
              form.controls['freebieMessage'].dirty &&
              form.controls['freebieMessage'].errors &&
              form.controls['freebieMessage'].errors['maxlength']
            "
          >
            Máximo de
            {{
              form.controls['freebieMessage'].errors['maxlength'][
                'requiredLength'
              ]
            }}
            caracteres
          </small>
          <label for="freebieMessage">Mensagem do cupom *</label>
        </span>
        <span class="input-switch">
          <label for="enabled">Inativo</label>
          <p-inputSwitch formControlName="enabled" inputId="enabled" />
          <label for="enabled">Ativo</label>
        </span>
      </ng-container>
      <ng-container *ngIf="confirmation">
        <div class="">
          <h4>Confirme as alterações</h4>
          <p>
            <strong>Data:</strong>
            {{ form.value.dateChange | date : 'dd/MM/yyyy' }}
          </p>
          <p
            *ngIf="
              (!couponPriceChange &&
                (coupon.value !== form.value.value ||
                  coupon.couponType !== form.value.couponType)) ||
              (couponPriceChange &&
                (couponPriceChange?.value !== form.value.value ||
                  couponPriceChange?.couponType !== form.value.couponType))
            "
          >
            <strong>Desconto:</strong>
            <span>
              <span>{{ discountLabel(coupon.value, coupon.couponType) }}</span>
              <span>&nbsp;&#8594;&nbsp;</span>
              <span>{{
                discountLabel(form.value.value, form.value.couponType)
              }}</span>
            </span>
          </p>
          <p
            *ngIf="
              (!couponPriceChange &&
                coupon.freebieMessage !== form.value.freebieMessage) ||
              (couponPriceChange &&
                couponPriceChange?.freebieMessage !== form.value.freebieMessage)
            "
          >
            <strong>Mensagem do cupom:</strong>
            <span>
              <span>{{ coupon.freebieMessage }}</span>
              <span>&nbsp;&#8594;&nbsp;</span>
              <span>{{ form.value.freebieMessage }}</span>
            </span>
          </p>
          <ng-container
            *ngIf="
              subscriptionTypeName?.toString() !==
              couponSubscriptionTypeNames?.toString()
            "
          >
            <p>
              <strong>Restrição de plano:</strong>
            </p>
            <p>
              <span class="restrictions">
                <p-chip
                  [label]="restriction.label"
                  [style]="{
                    'background-color': backgroundColor(restriction.value),
                    color: 'white',
                    'font-weight': 600
                  }"
                  *ngFor="let restriction of couponSubscriptionTypeNames"
                />
              </span>
              <!-- <span>{{ couponSubscriptionTypeName }}</span> -->
              <span>&nbsp;&#8594;&nbsp;</span>
              <span class="restrictions">
                <p-chip
                  [label]="restriction.label"
                  [style]="{
                    'background-color': backgroundColor(restriction.value),
                    color: 'white',
                    'font-weight': 600
                  }"
                  *ngFor="let restriction of subscriptionTypeName"
                />
              </span>
              <!-- <span>{{ subscriptionTypeName }}</span> -->
            </p>
          </ng-container>
        </div>
      </ng-container>
    </form>
  </p-panel>
  <p-panel
    header="Produtos"
    [class.products]="
      productVariantsComponent?.productVariants?.length > 0 || confirmation
    "
  >
    <app-coupon-product-variants
      [couponPriceChange]="couponPriceChange"
      [coupon]="coupon"
      [subscriptionTypes]="subscriptionTypes"
      [schedule]="true"
      [productVariants]="couponPriceChange ? scheduledProducts : undefined"
      [couponProductVariantSubscriptionTypes]="restrictions"
      [subscriptionTypesTree]="productSubscriptionTypesTree"
      *ngIf="
        !confirmation &&
        scheduledProducts &&
        coupon &&
        restrictions &&
        productSubscriptionTypesTree
      "
    />
    <ng-container *ngIf="confirmation">
      <ng-container *ngIf="restrictions?.length">
        <span class="restrictions">
          <strong>Restrição de plano dos brindes:</strong>
          <p-chip
            [label]="restriction.label"
            [style]="{
              'background-color': backgroundColor(restriction.value),
              color: 'white',
              'font-weight': 600
            }"
            *ngFor="let restriction of restrictionsNames"
          />
        </span>
        <hr />
      </ng-container>
      <div class="product" *ngFor="let product of scheduledProducts">
        <span>- {{ product.productVariantName }}</span>
        <ng-container *ngIf="product.restrictions?.length">
          <span class="restrictions">
            <strong>Restrito aos planos:</strong>
            <p-chip
              [label]="restriction.label"
              [style]="{
                'background-color': backgroundColor(restriction.value),
                color: 'white',
                'font-weight': 600
              }"
              *ngFor="
                let restriction of subscriptionTypeNames(product.restrictions)
              "
            />
          </span>
          <hr />
        </ng-container>
      </div>
      <span *ngIf="!scheduledProducts?.length">Sem brindes no cupom</span>
    </ng-container>
  </p-panel>
</div>
<span class="buttons">
  <p-button
    label="Voltar"
    severity="danger"
    (onClick)="back()"
    *ngIf="confirmation"
  />
  <p-button
    label="Salvar"
    severity="primary"
    type="button"
    [disabled]="!form.valid"
    (onClick)="submit()"
  />
</span>

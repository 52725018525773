<div class="subscriber-group-counter-chart">
  <p-chart
    type="bar"
    [data]="chartData"
    [options]="chartConfig"
    id="chart"
    [responsive]="false"
    [height]="height"
    [width]="width"
    #chart
    *ngIf="!responsive && showChart"
  />
  <p-chart
    type="bar"
    [data]="chartData"
    [options]="chartConfig"
    id="chart"
    *ngIf="responsive && showChart"
    #chart
  />
</div>

/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CompositionSubstitutionDetail {
  compositionSubstitutionId?: number;
  compositionId?: number;
  compositionName?: string;
  dateCreated?: Date;
  subscriberId?: number;
  personId?: number;
  productVariantId?: number;
  oldProductVariantId?: number;
  productVariantName?: string;
  oldProductVariantName?: string;
  personName?: string;
  glamId?: string;
  description?: string;
  incident?: string;
  incidentId?: number;
  incidentTypeId?: number;
}

<ng-container *ngIf="feedbackForm && formId">
  <div class="page-title">
    Formulário de feedback - {{ feedbackForm.formTitle }}
  </div>
  <div class="details feedback-form-details">
    <div class="details__left">
      <div class="card card-user">
        <div class="card-body">
          <div>
            <h4>{{ feedbackForm.formTitle }}</h4>
            <p>{{ feedbackForm.answerCount | number : '1.0-0' }} feedbacks</p>
            <p *ngIf="questions">{{ questions.length || 0 }} perguntas</p>
            <p *ngIf="options">{{ options.length || 0 }} alternativas</p>
          </div>
        </div>
      </div>
    </div>
    <div class="details__right">
      <div class="card">
        <p-tabView
          [activeIndex]="selectedTab"
          (activeIndexChange)="tabChanged($event)"
        >
          <p-tabPanel header="Informações gerais">
            <app-feedback-form
              [permissions]="permissions"
              [model]="feedbackForm"
              (updated)="feedbackForm = $event"
            ></app-feedback-form>
          </p-tabPanel>
          <p-tabPanel header="Perguntas">
            <div class="feedback-questions">
              <div
                class="feedback-questions__buttons"
                *ngxPermissionsOnly="permissionsAccepted"
              >
                <p-button
                  severity="success"
                  label="Baixar XLS"
                  icon="pi pi-file-excel"
                  (onClick)="exportExcel()"
                  type="button"
                ></p-button>
                <p-button
                  severity="warning"
                  label="Importar perguntas"
                  icon="pi pi-clone"
                  (onClick)="cloneQuestions()"
                  type="button"
                ></p-button>
                <p-button
                  severity="primary"
                  label="Adicionar pergunta"
                  icon="pi pi-plus"
                  (onClick)="changeQuestion()"
                  type="button"
                ></p-button>
              </div>
              <p-tree
                [value]="questionNodes"
                class="w-full md:w-30rem"
                [draggableNodes]="permissions"
                [droppableNodes]="permissions"
                draggableScope="self"
                droppableScope="self"
                (onNodeDrop)="changeOrder($event)"
                *ngIf="questionNodes"
              >
                <ng-template let-node pTemplate="question" let-index>
                  <span
                    class="feedback-questions__feedback-question"
                    [class.feedback-questions__feedback-question--danger]="
                      (node.data.typeId === 3 || node.data.typeId === 4) &&
                      !node?.children?.length
                    "
                    [class.feedback-questions__feedback-question--warning]="
                      (node.data.typeId === 2 || node.data.typeId === 5) &&
                      node?.children?.length
                    "
                    [class.feedback-questions__feedback-question--no-action]="
                      !permissions
                    "
                  >
                    <b
                      class="label"
                      [class.label--disabled]="!node.data.status"
                    >
                      {{ node.data.questionOrder }}. {{ node.data.title }}
                      <small
                        *ngIf="
                          isQuestionTypeOptions(node.data) &&
                          !node?.children?.length
                        "
                      >
                        (* Sem alternativas)
                      </small>
                      <small
                        *ngIf="
                          !isQuestionTypeOptions(node.data) &&
                          node?.children?.length
                        "
                      >
                        (* Existem alternativas)
                      </small>
                    </b>
                    <span
                      class="feedback-questions__feedback-question__answers"
                    >
                      {{ node.data.answerCount || 0 | number : '1.0-0' }}
                    </span>
                    <span
                      class="feedback-questions__feedback-question__type"
                      [pTooltip]="questionTypeDescription(node.data.typeId)"
                      tooltipPosition="bottom"
                    >
                      {{ questionTypeName(node.data.typeId) }}
                    </span>
                    <span class="input-switch">
                      <p-inputSwitch
                        [inputId]="'questionStatus' + index"
                        [trueValue]="1"
                        [falseValue]="0"
                        [(ngModel)]="node.data.status"
                        (onChange)="changeQuestionStatus(node.data)"
                        [disabled]="!permissions"
                      ></p-inputSwitch>
                    </span>
                    <span
                      class="buttons"
                      *ngxPermissionsOnly="permissionsAccepted"
                    >
                      <p-button
                        icon="pi pi-pencil"
                        styleClass="p-button-xs"
                        severity="primary"
                        [rounded]="true"
                        [text]="true"
                        pTooltip="Editar pergunta"
                        tooltipPosition="left"
                        (onClick)="changeQuestion(node.data)"
                      ></p-button>
                      <p-button
                        icon="pi pi-plus-circle"
                        styleClass="p-button-xs"
                        severity="success"
                        [rounded]="true"
                        [text]="true"
                        pTooltip="Adicionar alternativa"
                        tooltipPosition="left"
                        (onClick)="changeOption(node.data)"
                        *ngIf="
                          node.data.status &&
                          (node.data.typeId === 3 || node.data.typeId === 4)
                        "
                      ></p-button>
                      <p-button
                        icon="pi pi-copy"
                        styleClass="p-button-xs"
                        severity="warning"
                        [rounded]="true"
                        [text]="true"
                        pTooltip="Importar alternativas"
                        tooltipPosition="left"
                        (onClick)="cloneOptions(node.data)"
                        *ngIf="
                          (node.data.status && node.data.typeId === 3) ||
                          node.data.typeId === 4
                        "
                      ></p-button>
                      <!-- <p-button
                        icon="pi pi-window-minimize"
                        styleClass="p-button-xs"
                        severity="primary"
                        [rounded]="true"
                        [text]="true"
                        pTooltip="Converter em matriz"
                        tooltipPosition="left"
                        (onClick)="convertToGroup(node.data)"
                        *ngIf="
                          node.data.status &&
                          (node.data.typeId === 3 || node.data.typeId === 4) &&
                          node.children?.length
                        "
                      ></p-button> -->
                      <p-button
                        icon="pi pi-trash"
                        styleClass="p-button-xs"
                        severity="danger"
                        [rounded]="true"
                        [text]="true"
                        pTooltip="Excluir pergunta"
                        tooltipPosition="left"
                        (onClick)="deleteQuestion(node.data, $event)"
                        *ngIf="
                          !node.data.status && !isAnsweredQuestion(node.data)
                        "
                      ></p-button>
                    </span>
                  </span>
                </ng-template>
                <ng-template let-node pTemplate="option">
                  <span
                    class="feedback-questions__feedback-option"
                    [class.feedback-questions__feedback-option--no-action]="
                      !permissions
                    "
                  >
                    <span
                      [class.label--disabled]="!node.data.enabled"
                      class="label"
                      *ngIf="
                        !node.data.mediaUrl &&
                        !node.data.iconFilledUrl &&
                        !node.data.iconOutlinedUrl
                      "
                      [class.feedback-questions__feedback-option--no-action]="
                        !permissions
                      "
                    >
                      {{ node.data.optionOrder }}.
                      {{ node.data.optionText }}
                    </span>
                    <span
                      [class.label--disabled]="!node.data.enabled"
                      class="label"
                      *ngIf="
                        node.data.mediaUrl ||
                        node.data.iconFilledUrl ||
                        node.data.iconOutlinedUrl
                      "
                    >
                      {{ node.data.optionOrder }}.
                      <img
                        [src]="node.data.mediaUrl"
                        *ngIf="node.data.mediaUrl"
                      />
                      <img
                        [src]="
                          node.data.iconOutlinedUrl || node.data.iconFilledUrl
                        "
                        *ngIf="!node.data.mediaUrl"
                      />
                    </span>
                    <span class="feedback-questions__feedback-option__answers">
                      <span>{{
                        node.data.absFrequency || 0 | number : '1.0-0'
                      }}</span>
                      <span>
                        ({{
                          (node.data.relFrequency || 0) * 100
                            | number : '1.2-2'
                        }}) %
                      </span>
                    </span>
                    <p-dropdown
                      *ngxPermissionsOnly="permissionsAccepted"
                      [options]="optionTypes"
                      [(ngModel)]="node.data.optionType"
                      (onChange)="updateOption(node.data)"
                      appendTo="body"
                    >
                      <ng-template pTemplate="item" let-type>
                        <span
                          class="tooltip-item"
                          [pTooltip]="type.description"
                          tooltipPosition="right"
                          >{{ type.label }}</span
                        >
                      </ng-template>
                    </p-dropdown>
                    <span
                      class="feedback-questions__feedback-question__type"
                      *ngxPermissionsExcept="permissionsAccepted"
                    >
                      Alternativa
                    </span>
                    <span class="input-switch">
                      <p-inputSwitch
                        [inputId]="'optionStatus' + index"
                        [(ngModel)]="node.data.enabled"
                        (onChange)="updateOption(node.data)"
                        [disabled]="!permissions"
                      ></p-inputSwitch>
                    </span>
                    <span
                      class="buttons"
                      *ngxPermissionsOnly="permissionsAccepted"
                    >
                      <p-button
                        icon="pi pi-pencil"
                        styleClass="p-button-xs"
                        severity="warning"
                        [rounded]="true"
                        [text]="true"
                        pTooltip="Editar alternativa"
                        tooltipPosition="left"
                        (onClick)="changeOption(node.parent.data, node.data)"
                      ></p-button>
                      <p-button
                        icon="pi pi-trash"
                        styleClass="p-button-xs"
                        severity="danger"
                        [rounded]="true"
                        [text]="true"
                        pTooltip="Excluir alternativa"
                        tooltipPosition="left"
                        *ngIf="
                          node.parent.type === 'question' &&
                          !node.data.enabled &&
                          !isAnsweredOption(node.data)
                        "
                        (onClick)="deleteOption(node.data, $event)"
                      ></p-button>
                      <p-button
                        icon="pi pi-trash"
                        pTooltip="Excluir alternativa"
                        tooltipPosition="bottom"
                        [rounded]="true"
                        [text]="true"
                        styleClass="p-button-xs"
                        severity="danger"
                        *ngIf="
                          node.parent.type === 'groupedQuestion' &&
                          !node.data.enabled &&
                          !isAnsweredGroupOption(
                            node.parent.parent.data,
                            node.data.optionOrder
                          )
                        "
                        (onClick)="
                          deleteOption(
                            node.data,
                            $event,
                            node.parent.parent.data
                          )
                        "
                      ></p-button>
                    </span>
                  </span>
                </ng-template>
                <ng-template let-node pTemplate="group">
                  <span
                    class="feedback-questions__feedback-question"
                    [class.feedback-questions__group--no-action]="!permissions"
                  >
                    <b
                      class="label"
                      [class.feedback-questions__feedback-question--disabled]="
                        !node.data.status
                      "
                    >
                      <ng-container *ngIf="node.data.questions.length === 1">
                        {{ node.data.questions[0].questionOrder }}.
                      </ng-container>
                      <ng-container *ngIf="node.data.questions.length > 1">
                        {{ node.data.questions[0].questionOrder }}-{{
                          node.data.questions[node.data.questions.length - 1]
                            .questionOrder
                        }}.
                      </ng-container>
                      {{ node.data.feedbackGroupName }}
                    </b>
                    <span
                      class="feedback-questions__feedback-question__answers"
                    >
                      {{
                        node.data.questions[0].answerCount || 0
                          | number : '1.0-0'
                      }}
                    </span>
                    <span
                      class="feedback-questions__feedback-question__type"
                      [pTooltip]="questionTypeDescription(node.data.typeId)"
                      tooltipPosition="bottom"
                    >
                      {{ questionTypeName(node.data.typeId) }}
                    </span>
                    <span class="input-switch">
                      <p-inputSwitch
                        [inputId]="'groupStatus' + index"
                        [trueValue]="1"
                        [falseValue]="0"
                        [(ngModel)]="node.data.status"
                        (click)="changeGroupStatus(node.data, $event)"
                        [disabled]="!permissions"
                      ></p-inputSwitch>
                    </span>
                    <span class="buttons">
                      <p-button
                        icon="pi pi-pencil"
                        pTooltip="Editar grupo"
                        tooltipPosition="left"
                        severity="primary"
                        styleClass="p-button-xs"
                        [text]="true"
                        [rounded]="true"
                        type="button"
                        (onClick)="changeGroupName(node.data)"
                      ></p-button>
                      <p-button
                        icon="pi pi-plus-circle"
                        pTooltip="Adicionar pergunta"
                        tooltipPosition="left"
                        severity="success"
                        styleClass="p-button-xs"
                        [text]="true"
                        [rounded]="true"
                        type="button"
                        *ngIf="node.data.status"
                        (onClick)="changeQuestion(node.data)"
                      ></p-button>
                      <p-button
                        icon="pi pi-clone"
                        styleClass="p-button-xs"
                        severity="warning"
                        [rounded]="true"
                        [text]="true"
                        pTooltip="Importar perguntas"
                        tooltipPosition="left"
                        (onClick)="cloneQuestions(node.data)"
                        *ngIf="node.data.status"
                      ></p-button>
                      <p-button
                        icon="pi pi-trash"
                        styleClass="p-button-xs"
                        severity="danger"
                        [rounded]="true"
                        [text]="true"
                        pTooltip="Excluir pergunta"
                        tooltipPosition="left"
                        (onClick)="deleteGroup(node.data, $event)"
                        *ngIf="!node.data.status && !isAnsweredGroup(node.data)"
                      ></p-button>
                    </span>
                  </span>
                </ng-template>
                <ng-template pTemplate="groupedQuestion" let-node>
                  <span
                    class="feedback-questions__feedback-question"
                    [class.feedback-questions__feedback-question--no-action]="
                      !permissions
                    "
                  >
                    <span
                      class="label"
                      [class.label--disabled]="!node.data.status"
                    >
                      <ng-container *ngIf="node.parent?.children?.length > 1">
                        {{ node.data.questionOrder }}. </ng-container
                      >{{ node.data.title }}
                    </span>
                    <span
                      class="feedback-questions__feedback-question__answers"
                    >
                      {{ node.data.answerCount || 0 | number : '1.0-0' }}
                    </span>
                    <span class="feedback-questions__feedback-question__type">
                      -
                    </span>
                    <span class="input-switch">
                      <p-inputSwitch
                        [inputId]="'questionStatus' + index"
                        [trueValue]="1"
                        [falseValue]="0"
                        [(ngModel)]="node.data.status"
                        (onChange)="changeQuestionStatus(node.data)"
                        [disabled]="!permissions"
                      ></p-inputSwitch>
                    </span>
                    <span class="buttons">
                      <p-button
                        icon="pi pi-pencil"
                        styleClass="p-button-xs"
                        severity="primary"
                        [rounded]="true"
                        [text]="true"
                        pTooltip="Editar pergunta"
                        tooltipPosition="left"
                        (onClick)="changeQuestion(node.data)"
                      ></p-button>
                      <p-button
                        icon="pi pi-plus-circle"
                        styleClass="p-button-xs"
                        severity="success"
                        [rounded]="true"
                        [text]="true"
                        pTooltip="Adicionar alternativa"
                        tooltipPosition="left"
                        (onClick)="changeOption(node.data)"
                      ></p-button>
                      <p-button
                        icon="pi pi-copy"
                        styleClass="p-button-xs"
                        severity="warning"
                        [rounded]="true"
                        [text]="true"
                        pTooltip="Importar alternativas"
                        tooltipPosition="left"
                        (onClick)="cloneOptions(node.data)"
                      ></p-button>
                      <p-button
                        icon="pi pi-history"
                        styleClass="p-button-xs"
                        severity="danger"
                        [rounded]="true"
                        [text]="true"
                        pTooltip="Converter em pergunta"
                        tooltipPosition="left"
                        (onClick)="
                          removeFromGroup(node.parent.data, node.data, $event)
                        "
                      ></p-button>
                      <p-button
                        icon="pi pi-trash"
                        pTooltip="Excluir pergunta"
                        tooltipPosition="left"
                        severity="danger"
                        styleClass="p-button-xs"
                        [text]="true"
                        [rounded]="true"
                        type="button"
                        *ngIf="
                          !node.data.status &&
                          !isAnsweredQuestion(node.data) &&
                          node.parent.data.questions?.length > 1
                        "
                        (onClick)="deleteQuestion(node.data, $event)"
                      ></p-button>
                    </span>
                  </span>
                </ng-template>
                <ng-template pTemplate="groupedOptions" let-node>
                  <span
                    class="feedback-questions__feedback-grouped-option"
                    [class.feedback-questions__feedback-grouped-question--no-action]="
                      !permissions
                    "
                  >
                    <b class="label">Pergunta</b>
                    <span
                      class="feedback-questions__feedback-grouped-option__option"
                      *ngFor="let option of node.data; let i = index"
                    >
                      <b
                        [class.feedback-questions__feedback-grouped-option__option--disabled]="
                          !option.enabled
                        "
                        class="label"
                      >
                        {{ option.optionText }}

                        <p-radioButton
                          [(ngModel)]="option.checked"
                          [checked]="false"
                          [name]="'option_' + option.optionId"
                          [inputId]="'option_' + option.optionId"
                          [value]="option.optionId"
                          [disabled]="true"
                          [disabled]="!permissions"
                        ></p-radioButton>
                      </b>
                      <span
                        class="feedback-questions__feedback-grouped-option__option__buttons"
                        *ngxPermissionsOnly="permissionsAccepted"
                      >
                        <p-button
                          icon="pi pi-pencil"
                          pTooltip="Editar alternativa"
                          tooltipPosition="bottom"
                          [rounded]="true"
                          [text]="true"
                          styleClass="p-button-xs"
                          (onClick)="
                            changeOption(node.parent.data.questions[0], option)
                          "
                        ></p-button>
                        <p-button
                          icon="pi pi-trash"
                          pTooltip="Excluir alternativa"
                          tooltipPosition="bottom"
                          [rounded]="true"
                          [text]="true"
                          styleClass="p-button-xs"
                          severity="danger"
                          *ngIf="
                            !option.enabled &&
                            !isAnsweredGroupOption(
                              node.parent.data,
                              option.optionOrder
                            )
                          "
                          (onClick)="
                            deleteOption(option, $event, node.parent.data)
                          "
                        ></p-button>
                        <p-button
                          icon="pi pi-arrow-right-arrow-left"
                          pTooltip="Trocar posição"
                          tooltipPosition="bottom"
                          [rounded]="true"
                          [text]="true"
                          styleClass="p-button-xs"
                          severity="secondary"
                          *ngIf="i < node.data.length - 1"
                          (onClick)="
                            switchOptionOrder(
                              option,
                              node.data[i + 1],
                              node.parent.data.questions[0]
                            )
                          "
                        ></p-button>
                        <p-button
                          icon="pi pi-plus-circle"
                          pTooltip="Adicionar alternativa"
                          tooltipPosition="bottom"
                          [rounded]="true"
                          [text]="true"
                          styleClass="p-button-xs"
                          severity="success"
                          (onClick)="
                            changeOption(node.parent.data.questions[0])
                          "
                          *ngIf="i === node.data.length - 1"
                        ></p-button>
                      </span>
                    </span>
                    <b
                      class="feedback-questions__feedback-grouped-option__header"
                    >
                    </b>
                    <b
                      class="feedback-questions__feedback-grouped-option__header"
                    >
                    </b>
                  </span>
                </ng-template>
                <ng-template pTemplate="questionHeader" let-node>
                  <ng-template
                    *ngTemplateOutlet="headerTemp; context: { $implicit: node }"
                  ></ng-template>
                </ng-template>
                <ng-template pTemplate="optionHeader" let-node>
                  <ng-template
                    *ngTemplateOutlet="headerTemp; context: { $implicit: node }"
                  ></ng-template>
                </ng-template>
                <ng-template pTemplate="groupedQuestionHeader" let-node>
                  <ng-template
                    *ngTemplateOutlet="headerTemp; context: { $implicit: node }"
                  ></ng-template>
                </ng-template>
              </p-tree>
            </div>
          </p-tabPanel>
          <p-tabPanel
            header="Produtos ({{ products?.length || 0 | number : '1.0-0' }})"
          >
            <app-table
              #productsTable
              *ngIf="productCols && pagesLoaded.includes(2) && products"
              [cols]="productCols"
              modelName="Produtos"
              sortField="productId"
              [sortOrder]="1"
              [showTitle]="false"
              addLabel="Incluir produto"
              [addButtonAction]="true"
              (addButtonClick)="addProduct()"
              [changeColumns]="true"
              [elements]="products"
              [lazy]="false"
              [addButton]="permissions"
              [actionButtons]="actionButtons"
              (actionButtonClick)="removeProduct($event.item)"
            ></app-table>
          </p-tabPanel>
          <p-tabPanel
            header="Respostas ({{ totalAnswers || 0 | number : '1.0-0' }})"
          >
            <app-table
              #answersTable
              *ngIf="answersCols && fixedFilters"
              [cols]="answersCols"
              modelName="Respostas"
              sortField="dateCreated"
              [service]="feedbackService"
              [findPage]="findPage"
              [sortOrder]="1"
              [showTitle]="false"
              [addButton]="false"
              [changeColumns]="true"
              [dropdownFilters]="dropdownFilters"
              [fixedFilters]="fixedFilters"
              [multiSelectFilter]="true"
              (tableLoaded)="setTotalAnswers()"
            ></app-table>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!feedbackForm && !formId && permissions">
  <div class="feedback-form">
    <h3>Novo formulário de feedback</h3>
    <app-feedback-form (updated)="goToForm($event)"></app-feedback-form>
  </div>
</ng-container>
<p-confirmPopup></p-confirmPopup>
<p-toast></p-toast>
<ng-template #headerTemp let-node>
  <span
    class="feedback-questions__header"
    [class.feedback-questions__header--no-type]="
      node.type === 'groupedQuestionHeader'
    "
    [class.feedback-questions__header--no-action]="!permissions"
  >
    <b
      class="label"
      *ngIf="
        node?.type === 'questionHeader' ||
        node?.type === 'groupedQuestionHeader'
      "
      >Pergunta</b
    >
    <b class="label--option" *ngIf="node?.type === 'optionHeader'"
      >Alternativa</b
    >
    <b
      class="feedback-questions__header__answers"
      *ngIf="node?.type === 'questionHeader'"
      >Respostas</b
    >
    <b
      class="feedback-questions__header__answers"
      *ngIf="node?.type === 'optionHeader'"
      >% Respostas</b
    >
    <b
      class="feedback-questions__header__type"
      *ngIf="node?.type !== 'groupedQuestionHeader'"
      >Tipo</b
    >
    <b class="feedback-questions__header__status">Inativo/Ativo</b>
    <b
      class="feedback-questions__header__buttons"
      *ngxPermissionsOnly="permissionsAccepted"
      >Ações</b
    >
  </span>
</ng-template>

<ng-container *ngIf="badge">
  <div class="page-title">Detalhes da jóia</div>
  <div class="details badge-details">
    <div class="details__left">
      <div class="card card-user">
        <div class="image"></div>
        <div class="card-body">
          <div class="user">
            <img class="avatar" [src]="badge.iconUrl" *ngIf="badge.iconUrl" />
            <img class="avatar" [src]="badgeImage" *ngIf="!badge.iconUrl" />
            <h4>{{ badge.title }}</h4>
            <p *ngIf="badge.consecutiveEditionsToAchieve">
              <b> Edições Consecutivas </b>:
              {{ badge.consecutiveEditionsToAchieve }}
            </p>
            <p *ngIf="badge.glampointsMultiplier">
              <b>Multiplicador de pontos</b>:
              {{ badge.glampointsMultiplier }}
            </p>
            <p *ngIf="badge.level">
              <b>Nível</b>:
              {{ badge.level }}
            </p>
            <p *ngIf="badge.glampointsRewardAnnual">
              <b>Glampoints para Anual</b>:
              {{ badge.glampointsRewardAnnual }}
            </p>
            <p *ngIf="badge.glampointsRewardSemestral">
              <b>Glampoints para Semestral</b>:
              {{ badge.glampointsRewardSemestral }}
            </p>
            <p *ngIf="badge.glampointsRewardMonthly">
              <b>Glampoints para Mensal</b>:
              {{ badge.glampointsRewardMonthly }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="details__right">
      <div class="card">
        <p-tabView
          [activeIndex]="selectedTab"
          (activeIndexChange)="tabChanged($event)"
        >
          <p-tabPanel header="Informações gerais">
            <app-badge-form [badge]="badge" (updated)="badge = $event">
            </app-badge-form
          ></p-tabPanel>
          <p-tabPanel header="Regras ({{ badgeRules?.length || 0 }})">
            <app-table
              *ngIf="
                badgeRulesCols && badgeRules && selectedBadgeRule === undefined
              "
              [cols]="badgeRulesCols"
              [elements]="badgeRules"
              modelName="Regras da Jóia"
              sortField="badgeRuleId"
              [sortOrder]="1"
              [lazy]="false"
              linkTarget="_action"
              [showTitle]="false"
              addLabel="Nova regra"
              [changeColumns]="true"
              [actionButtons]="actionButtons"
              [dropdownFilters]="dropdownFilters"
              (actionButtonClick)="buttonClick($event)"
              (addButtonClick)="selectedBadgeRule = null"
              [addButtonAction]="true"
              [addButton]="permission"
              [changeColumns]="true"
              linkTarget="_action"
              (linkAction)="selectedBadgeRule = $event.value"
              [actionFields]="['description']"
            ></app-table>
            <ng-container
              *ngIf="selectedBadgeRule || selectedBadgeRule === null"
            >
              <p-button
                [text]="true"
                [rounded]="true"
                pRipple
                label="Voltar"
                icon="pi pi-arrow-left"
                (onClick)="selectedBadgeRule = undefined"
              ></p-button>
              <app-badge-rule-form
                [model]="selectedBadgeRule"
                [badgeId]="badgeId"
                [badgeRules]="badgeRules"
                (buttonAction)="badgeRuleAction($event)"
              >
              </app-badge-rule-form>
            </ng-container>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>
  </div>
</ng-container>
<div class="badge-form" *ngIf="!badge">
  <div class="page-title">Nova jóia</div>
  <app-badge-form (updated)="goToBadge($event)"> </app-badge-form>
</div>
<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>

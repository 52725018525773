import { DatePipe } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgxPermissionsService } from 'ngx-permissions';
import { lastValueFrom, map } from 'rxjs';
import {
  PageableRequest,
  ProductCategorySummaryDTO,
  ProductControllerService
} from 'src/app/admin-api';
import { PageContent, TableColumn } from 'src/app/components/table';
import { Role, roleAsObject } from 'src/app/models';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
  providers: [DatePipe]
})
export class CategoriesComponent implements OnInit {
  private title: Title = inject(Title);
  public productService: ProductControllerService = inject(
    ProductControllerService
  );
  private ngxPermissionsService: NgxPermissionsService = inject(
    NgxPermissionsService
  );

  cols: Array<TableColumn> = [
    new TableColumn('Id', 'categoryId', true, 'number', '', 'categoryId'),
    new TableColumn(
      'Id Externo',
      'externalIdString',
      true,
      'text',
      '',
      'categoryId',
      true,
      'contains'
    ),
    new TableColumn(
      'Categoria Macro',
      'macroCategory',
      true,
      'text',
      '',
      'parentId',
      true,
      'contains'
    ),
    new TableColumn(
      'Categoria',
      'category',
      true,
      'text',
      '',
      'categoryId',
      true,
      'contains'
    ),
    new TableColumn(
      'Subcategorias',
      'subcategories',
      false,
      'formattedInteger'
    ),
    new TableColumn('Produtos', 'products', false, 'formattedInteger')
  ];
  permission = false;

  async ngOnInit(): Promise<void> {
    this.title.setTitle('Produtos de assinatura - Categorias');
    this.permission = await this.ngxPermissionsService.hasPermission([
      roleAsObject(Role.Full_Administrator).enumValue,
      roleAsObject(Role.Admin_Allocation).enumValue,
      roleAsObject(Role.User_Allocation).enumValue
    ]);
  }

  async findPage(
    request: PageableRequest,
    productService: unknown
  ): Promise<PageContent<ProductCategorySummaryDTO>> {
    return lastValueFrom(
      (productService as ProductControllerService)
        .findProductCategoriesTable(request)
        .pipe(
          map((data) => data.result as PageContent<ProductCategorySummaryDTO>)
        )
    );
  }

  get modelName(): string {
    return 'categories';
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { ListboxModule } from 'primeng/listbox';
import { ProductSelectModalComponent } from './product-select-modal.component';
import { ProductVariantSelectModalComponent } from './product-variant-select-modal/product-variant-select-modal.component';

@NgModule({
  declarations: [ProductSelectModalComponent, ProductVariantSelectModalComponent],
  imports: [CommonModule, ListboxModule, ButtonModule, ReactiveFormsModule],
  exports: [ProductSelectModalComponent, ProductVariantSelectModalComponent]
})
export class ProductSelectModalModule {}

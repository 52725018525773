<app-table
  *ngIf="cols"
  [cols]="cols"
  [findPage]="findPage"
  [service]="subscriberService"
  [fixedFilters]="fixedFilters"
  modelName="cancelamentos"
  sortField="dateCancelled"
  [sortOrder]="1"
  [addButton]="false"
  [captionTemplate]="captionTemplate"
  [dropdownFilters]="dropdownFilters"
>
  <ng-template #captionTemplate>
    <form
      class="header"
      [formGroup]="referenceForm"
      (submit)="setDateFilters()"
    >
      <span class="p-float-label">
        <p-dropdown
          id="referenceDate"
          [options]="referenceDates"
          formControlName="referenceDate"
          (onChange)="setDateFilters()"
          class="input"
        >
        </p-dropdown>
        <label for="referenceDate">Mês referência</label>
      </span>
      <span class="p-float-label">
        <p-multiSelect
          emptyMessage="Nenhuma"
          id="columns"
          [options]="colsOriginal"
          formControlName="columns"
          (onPanelHide)="reloadColumns()"
          class="input"
          optionLabel="header"
          [maxSelectedLabels]="0"
          (onChange)="updateTotalCols()"
          [selectedItemsLabel]="columnsLabel"
        >
        </p-multiSelect>
        <label for="columns">Colunas para exibição</label>
      </span>
    </form>
  </ng-template>
</app-table>

export enum Role {
  None = { label: 'Nenhum', enumValue: 'NONE', value: 0, order: -1 } as any,
  TI = { label: 'TI', enumValue: 'ROLE_TI', value: 1, order: 2 } as any,
  Customer = {
    label: 'Glambox User',
    enumValue: 'ROLE_CUSTOMER',
    value: 2,
    order: Number.MAX_SAFE_INTEGER
  } as any,
  User_Allocation = {
    label: 'Allocation User',
    enumValue: 'ROLE_ALLOC_USER',
    value: 3,
    order: 5
  } as any,
  Full_Administrator = {
    label: 'Full Administrator',
    enumValue: 'ROLE_ADMIN',
    value: 4,
    order: 0
  } as any,
  Admin_Allocation = {
    label: 'Allocation Administrator',
    enumValue: 'ROLE_ALLOC_ADMIN',
    value: 6,
    order: 3
  } as any,
  SAC = { label: 'SAC', enumValue: 'ROLE_SAC', value: 7, order: 7 } as any,
  ItOps = {
    label: 'ItOps',
    enumValue: 'ROLE_IT_OPS',
    value: 8,
    order: 1
  } as any,
  Business_Intelligence = {
    label: 'Business Intelligence',
    enumValue: 'ROLE_USER_BI',
    value: 9,
    order: 4
  } as any,
  Customer_Success = {
    label: 'Customer Success',
    enumValue: 'ROLE_USER_CS',
    value: 10,
    order: 4
  } as any,
  Content = {
    label: 'Content',
    enumValue: 'ROLE_CONTENT',
    value: 11,
    order: 6
  } as any,
  Logistics = {
    label: 'Logística',
    enumValue: 'ROLE_LOGISTICS',
    value: 12,
    order: 6
  } as any,
  Financial = {
    label: 'Financeiro',
    enumValue: 'ROLE_FINANCIAL',
    value: 13,
    order: 2
  } as any,
  Customization = {
    label: 'Customização',
    enumValue: 'ROLE_CUSTOMIZATION',
    value: 14,
    order: 8
  } as any,
  Portfolio = {
    label: 'Sourcing and Portfolio',
    enumValue: 'ROLE_PORTFOLIO',
    value: 15,
    order: 9
  } as any,
  Delivery = {
    label: 'Delivery',
    enumValue: 'ROLE_DELIVERY',
    value: 16,
    order: 6
  } as any,
  Integration = {
    label: 'Integrações',
    enumValue: 'ROLE_INTEGRATION',
    value: 17,
    order: 3
  } as any
}

export function getAllRoles(): Array<RoleDTO> {
  const objetos: Array<RoleDTO> = [];
  for (const entry of Object.entries(Role)) {
    if (typeof entry[1] === 'object') objetos.push(entry[1]);
  }
  return objetos;
}

export function getRole(enumValue: string): RoleDTO | undefined {
  return getAllRoles().find((d) => d.enumValue === enumValue);
}

export interface RoleDTO {
  label?: string;
  value?: number;
  enumValue?: string;
  order?: number;
}

export function roleAsObject(role: Role): RoleDTO {
  return {
    enumValue: (role as any).enumValue,
    label: (role as any).label,
    order: (role as any).order,
    value: (role as any).value
  };
}

import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ConfirmationService, MessageService, PrimeIcons } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import { BlacklistedCpf, BlacklistedDomain } from 'src/app/admin-api';
import { BlacklistControllerService } from 'src/app/admin-api/api/blacklistController.service';
import { BlacklistCpfFormComponent } from 'src/app/components/blacklist-form/blacklist-cpf-form/blacklist-cpf-form.component';
import { BlacklistDomainFormComponent } from 'src/app/components/blacklist-form/blacklist-domain-form/blacklist-domain-form.component';
import { TableActionButton, TableColumn } from 'src/app/components/table';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-blacklist',
  templateUrl: './blacklist.component.html',
  styleUrls: ['./blacklist.component.scss'],
  providers: [ConfirmationService, MessageService, DialogService]
})
export class BlacklistComponent implements OnInit {
  cpfs: Array<BlacklistedCpf> | undefined;
  domains: Array<BlacklistedDomain> | undefined;
  cpfCols: Array<TableColumn> = [
    new TableColumn(
      'CPF',
      'cpf',
      true,
      'number',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Motivo',
      'reason',
      true,
      'text',
      undefined,
      undefined,
      false,
      'contains'
    ),
    new TableColumn(
      'Data bloqueio',
      'startOfBlock',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    ),
    new TableColumn('Ação', '', false, 'button')
  ];
  actionButtonsCpf: Array<TableActionButton> = [
    new TableActionButton(
      '',
      'removeCpf',
      PrimeIcons.TRASH,
      () => true,
      '',
      'Remover da blacklist',
      'bottom',
      true,
      true,
      'danger'
    )
  ];
  domainCols: Array<TableColumn> = [
    new TableColumn(
      'Domínio',
      'domainName',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Data bloqueio',
      'dateCreated',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    ),
    new TableColumn(
      'Tentativas',
      'attempts',
      true,
      'formattedInteger',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn('Ação', '', false, 'button')
  ];
  actionButtonsDomain: Array<TableActionButton> = [
    new TableActionButton(
      '',
      'removeDomain',
      PrimeIcons.TRASH,
      () => true,
      '',
      'Remover da blacklist',
      'bottom',
      true,
      true,
      'danger'
    )
  ];

  constructor(
    private blacklistService: BlacklistControllerService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private dialog: DialogService,
    private title: Title
  ) {
    this.title.setTitle('Blacklists');
  }

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader();
    await Promise.all([this.findCpfs(), this.findDomains()]);
    LoaderService.showLoader(false);
  }

  async findCpfs(): Promise<void> {
    try {
      delete this.cpfs;
      this.cpfs = await lastValueFrom(
        this.blacklistService
          .findBlacklistedCpf()
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findDomains(): Promise<void> {
    try {
      delete this.domains;
      this.domains = await lastValueFrom(
        this.blacklistService
          .findBlacklistedDomains()
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  callAction($event: {
    item: BlacklistedCpf | BlacklistedDomain;
    $event: Event;
    action: 'removeCpf' | 'removeDomain' | string;
  }): void {
    if ($event.action === 'removeCpf' || $event.action === 'removeDomain') {
      this[$event.action]($event.item, $event.$event);
    }
  }

  removeCpf(cpf: BlacklistedCpf, $event: Event): void {
    this.confirmationService.confirm({
      acceptLabel: 'Sim',
      acceptButtonStyleClass: 'p-button-primary',
      rejectLabel: 'Não',
      rejectButtonStyleClass: 'p-button-danger',
      message: `Deseja remover o CPF ${cpf.cpf} da blacklist?`,
      target: $event.target,
      accept: async () => {
        LoaderService.showLoader();
        try {
          const detail = await lastValueFrom(
            this.blacklistService
              .removeCpfFromBlacklist(cpf.cpf)
              .pipe(map((data) => data.result))
          );
          await this.findCpfs();
          this.messageService.add({
            severity: 'success',
            summary: 'Sucesso',
            detail
          });
        } catch (error) {
          AppDialogService.showErrorDialog(error);
        }
        LoaderService.showLoader(false);
      }
    });
  }

  removeDomain(domain: BlacklistedDomain, $event: Event): void {
    this.confirmationService.confirm({
      acceptLabel: 'Sim',
      acceptButtonStyleClass: 'p-button-primary',
      rejectLabel: 'Não',
      rejectButtonStyleClass: 'p-button-danger',
      message: `Deseja remover o domínio ${domain.domainName} da blacklist?`,
      target: $event.target,
      accept: async () => {
        LoaderService.showLoader();
        try {
          const detail = await lastValueFrom(
            this.blacklistService
              .removeDomainFromBlacklist(domain.domainName)
              .pipe(map((data) => data.result))
          );
          await this.findDomains();
          this.messageService.add({
            severity: 'success',
            summary: 'Sucesso',
            detail
          });
        } catch (error) {
          AppDialogService.showErrorDialog(error);
        }
        LoaderService.showLoader(false);
      }
    });
  }

  addCpf(): void {
    this.dialog
      .open(BlacklistCpfFormComponent, {
        header: 'Incluir CPF',
        width: '450px'
      })
      .onClose.subscribe(async (data: BlacklistedCpf) => {
        if (data) {
          LoaderService.showLoader();
          await this.findCpfs();
          this.messageService.add({
            summary: 'Sucesso',
            detail: 'CPF incluído com sucesso',
            severity: 'success'
          });
          LoaderService.showLoader(false);
        }
      });
  }

  addDomain(): void {
    this.dialog
      .open(BlacklistDomainFormComponent, {
        header: 'Incluir Domínio',
        width: '450px'
      })
      .onClose.subscribe(async (data: BlacklistedDomain) => {
        if (data) {
          LoaderService.showLoader();
          await this.findDomains();
          this.messageService.add({
            summary: 'Sucesso',
            detail: 'Domínio incluído com sucesso',
            severity: 'success'
          });
          LoaderService.showLoader(false);
        }
      });
  }
}

export enum ShopAvailability {
  'Glamshop' = 'Glam',
  'mensmarket' = "Men's Market",
  'ALL' = "Glam + Men's"
}

export function getAllShopAvailabilities(): Array<{
  label: string;
  value: string;
}> {
  const objetos: Array<{ label: string; value: string }> = [];
  for (const [value, label] of Object.entries(ShopAvailability)) {
    objetos.push({
      label,
      value
    });
  }
  return objetos;
}

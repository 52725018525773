import { Component, inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Buy2Win } from '@infrab4a/connect';
import { MessageService } from 'primeng/api';
import { TableColumn } from 'src/app/components/table';
import { ShopBuyToWinService } from 'src/app/connect-api/api/shop/shop-buy-to-win.service';
import { ShopAvailability } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-buy-2-win',
  templateUrl: './buy-2-win.component.html',
  styleUrl: './buy-2-win.component.scss',
  providers: [MessageService]
})
export class Buy2WinComponent implements OnInit {
  private shoppingCampaignService = inject(ShopBuyToWinService);
  private messageService = inject(MessageService);
  private title = inject(Title);

  campaigns: Partial<Buy2Win>[];
  columns = [
    new TableColumn.Builder()
      .setHeader('Id')
      .setField('id')
      .setRouterLink('/marketing/buy-2-win/')
      .setRouterLinkFieldName('id')
      .build(),
    new TableColumn.Builder()
      .setHeader('Nome')
      .setField('name')
      .setCondition('contains')
      .setRouterLink('/marketing/buy-2-win/')
      .setRouterLinkFieldName('id')
      .setType('text')
      .build(),
    new TableColumn.Builder()
      .setHeader('Ativa')
      .setField('active')
      .setType('inputSwitch')
      .build(),
    new TableColumn.Builder()
      .setHeader('Início')
      .setField('startDate')
      .setType('date')
      .setCondition('between')
      .build(),
    new TableColumn.Builder()
      .setHeader('Término')
      .setField('endDate')
      .setType('date')
      .setCondition('between')
      .build(),
    new TableColumn.Builder()
      .setHeader('Loja')
      .setField('shop')
      .setDisplayFunction(
        (campaign: Buy2Win) => ShopAvailability[campaign.shop]
      )
      .build()
  ];

  dropdownFilters = {
    active: [
      { label: 'Sim', value: true },
      { label: 'Não', value: false },
      { label: 'Todos', value: undefined }
    ],
    shop: [
      { label: ShopAvailability.ALL, value: ShopAvailability.ALL },
      { label: ShopAvailability.Glamshop, value: ShopAvailability.Glamshop },
      {
        label: ShopAvailability.mensmarket,
        value: ShopAvailability.mensmarket
      },
      { label: 'Todas', value: undefined }
    ]
  };

  async ngOnInit(): Promise<void> {
    this.title.setTitle('Marketing - Compre e ganhe');
    await this.findCampaigns();
  }

  async findCampaigns(): Promise<void> {
    try {
      LoaderService.showLoader();
      this.campaigns = await this.shoppingCampaignService.findAll();
    } catch (error) {
      AppDialogService.showErrorDialog(error, true);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async updateActive(campaign: Partial<Buy2Win>) {
    try {
      LoaderService.showLoader();
      await this.shoppingCampaignService.updatecampaign(campaign);
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso',
        detail: 'Campanha atualizada com sucesso.'
      });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }
}

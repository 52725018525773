<h1>IT Ops Dashboard</h1>
<div class="it-ops-dashboard" *ngIf="kpis?.length">
  <div class="it-ops-dashboard__group" *ngFor="let group of kpis">
    <div class="dashboard-card" [title]="group.description">
      <span
        class="header"
        [class.header--success]="!group.itemCount"
        [class.header--danger]="group.itemCount"
      >
        <div class="header__title">
          {{ group.name }}
          <p-button
            (onClick)="refresGroup(group.id)"
            icon="pi pi-refresh"
            [rounded]="true"
            [text]="true"
            size="medium"
            styleClass="white-button"
          ></p-button>
        </div>
        <small
          tooltipPosition="bottom"
          [pTooltip]="
            timeDiffLabel(group.dateUpdated).includes('/')
              ? ''
              : timeLabel(group.dateUpdated) || ''
          "
        >
          última. atualização:
          {{ timeDiffLabel(group.dateUpdated) }}
        </small>
      </span>
      <span class="value" [routerLink]="'/it-ops/' + group.viewName">{{
        group.itemCount | number : '1.0-0'
      }}</span>
    </div>
  </div>
</div>
<br />
<div>
  <p-dropdown
    inputId="editionId"
    [options]="editions"
    *ngIf="editions"
    optionLabel="theme"
    (onChange)="changeEdition()"
    [filter]="true"
    filterBy="theme,title,editionId,description"
    filterMatchMode="contains"
    [autoDisplayFirst]="false"
    appendTo="body"
    [(ngModel)]="edition"
  >
    <ng-template pTemplate="item" let-edition>
      {{ editionDateName(edition.editionId) }}
    </ng-template>
    <ng-template pTemplate="selectedItem">
      <ng-container *ngIf="edition && edition.editionId">
        {{ editionDateName(edition.editionId) | titlecase }}
      </ng-container>
    </ng-template>
  </p-dropdown>
  <p-chart type="bar" [data]="data" [options]="options" />
</div>
<div>
  <b>Total</b>

  <div *ngFor="let total of totals">
    <b>{{ total.username }}</b> | {{ total.total | number : '1.0-0' }}
  </div>
</div>

import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import { PersonControllerService, PersonDetail } from 'src/app/admin-api';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-change-coupon-modal',
  templateUrl: './change-coupon-modal.component.html',
  styleUrls: ['./change-coupon-modal.component.scss']
})
export class ChangeCouponModalComponent {
  form = new FormGroup({
    couponName: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(/^[A-Z0-9]*$/),
      Validators.maxLength(100)
    ])
  });
  person: PersonDetail;

  constructor(
    private config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private personService: PersonControllerService
  ) {
    this.person = this.config.data.person;
  }

  async changeCoupon(): Promise<void> {
    if (this.form.valid && this.person.personId) {
      LoaderService.showLoader();
      try {
        const coupon = await lastValueFrom(
          this.personService
            .updateCouponName(
              this.person.personId,
              this.form.value.couponName as string
            )
            .pipe(map((data) => data.result))
        );
        this.ref.close(coupon);
      } catch (error: any) {
        AppDialogService.showErrorDialog(
          error,
          false,
          'Não foi possível alterar o cupom.'
        );
        console.error(error);
      }
      LoaderService.showLoader(false);
    }
  }

  toUpper(): void {
    this.form
      .get('couponName')
      ?.setValue(
        this.form.value?.couponName?.toUpperCase().replaceAll(' ', '') || ''
      );
  }
}

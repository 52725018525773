import {
  SubscriberBillingHistoryEdition,
  SubscriberPaymentHistory
} from '../admin-api';

export type PaymentHistory = SubscriberPaymentHistory & {
  billings?: Array<
    SubscriberBillingHistoryEdition & {
      subscriberBillingStatusName?: string;
      editionName?: string;
    }
  >;
};

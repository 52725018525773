import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { PersonDetail, PersonRelationInfo } from 'src/app/admin-api';
import { TableColumn } from '../table';

@Component({
  selector: 'app-person-invites',
  templateUrl: './person-invites.component.html',
  styleUrls: ['./person-invites.component.scss'],
  providers: [DatePipe]
})
export class PersonInvitesComponent {
  @Input()
  person: PersonDetail | undefined;

  @Input()
  invites: Array<PersonRelationInfo> | undefined;

  @Input()
  isMobile?: boolean;

  colsReceived: Array<TableColumn> = [
    new TableColumn(
      'De quem',
      'inviterName',
      false,
      'text',
      '/users/person/',
      'personId1'
    ),
    new TableColumn('Relação', 'personRelationType', false),
    new TableColumn('Plano', 'subscriptionTypeName', false),
    new TableColumn('Criação', 'dateCreated', false, 'date')
  ];

  colsSent: Array<TableColumn> = [
    new TableColumn(
      'Para quem',
      'name',
      false,
      'text',
      '/users/person/',
      'personId2'
    ),
    new TableColumn('Relação', 'personRelationType', false),
    new TableColumn('Plano', 'subscriptionTypeName', false),
    new TableColumn('Criação', 'dateCreated', false, 'date')
  ];

  get invitesSent(): Array<PersonRelationInfo> {
    return (
      this.invites?.filter((i) => i.personId1 === this.person?.personId) || []
    );
  }

  get invitesReceived(): Array<PersonRelationInfo> {
    return (
      this.invites?.filter((i) => i.personId2 === this.person?.personId) || []
    );
  }
}

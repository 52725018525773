<app-table
  [cols]="cols"
  [lazy]="false"
  [elements]="pending"
  modelName="Composições"
  [showHeader]="false"
  [actionButtons]="actionButtons"
  (actionButtonClick)="prepareComposition($event.item)"
/>
<p-toast />

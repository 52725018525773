import { Component } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import {
  CanceledWithPendingBilling,
  ItOpsControllerService,
  SubscriberProblemGroupDetail
} from 'src/app/admin-api';
import { DropdownFilter, TableColumn } from 'src/app/components/table';
import { getAllSubscriberBillingStatus } from 'src/app/models';
import { CanceledWithPendingBillingModalComponent } from './canceled-with-pending-billing-modal/canceled-with-pending-billing-modal.component';

@Component({
  selector: 'app-canceled-with-pending-billing',
  templateUrl: './canceled-with-pending-billing.component.html',
  styleUrls: ['./canceled-with-pending-billing.component.scss'],
  providers: [
    MessageService,
    DialogService,
    ConfirmationService,
    ItOpsControllerService
  ]
})
export class CanceledWithPendingBillingComponent {
  columns: Array<TableColumn> = [
    new TableColumn('', '', false, 'checkbox'),
    new TableColumn(
      'SubscriberId',
      'subscriberId',
      true,
      'number',
      '/users/subscribers/',
      'subscriberId'
    ),
    new TableColumn('Edição', 'editionId', true, 'number'),
    new TableColumn(
      'Subscriber Billing Id',
      'subscriberBillingId',
      true,
      'number'
    ),
    new TableColumn(
      'Subscriber Billing Status',
      'subscriberBillingStatus',
      true,
      'number',
      undefined,
      undefined,
      true,
      'in'
    )
  ];
  ready = true;
  dropdownFilters: {
    [field: string]: Array<DropdownFilter>;
  } = {
    subscriberBillingStatus: getAllSubscriberBillingStatus().map((sb) => ({
      label: sb.value + '. ' + sb.label,
      value: sb.value
    }))
  };

  constructor(
    public itOpsService: ItOpsControllerService,
    private messageService: MessageService,
    private dialog: DialogService
  ) {}

  openActionModal(elements: Array<CanceledWithPendingBilling>): void {
    this.dialog
      .open(CanceledWithPendingBillingModalComponent, {
        height: '65vh',
        header: 'Cancelada com Billing Pendente',
        data: {
          elements
        }
      })
      .onClose.subscribe(async (data: Array<SubscriberProblemGroupDetail>) => {
        if (data) {
          this.ready = false;
          setTimeout(() => {
            this.messageService.add({
              severity: 'success',
              summary: 'Sucesso',
              detail: elements.length + ' marcado(s) como resolvido(s)'
            });
            this.ready = true;
          }, 1000);
        }
      });
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { NgxPermissionsModule } from 'ngx-permissions';
import { InputNumberModule } from 'primeng/inputnumber';
import { TableModule } from 'primeng/table';
import { FinancialRoutingModule } from './financial-routing.module';
import { TaxPriceComponent } from './tax-price/tax-price.component';

@NgModule({
  declarations: [TaxPriceComponent],
  imports: [
    CommonModule,
    FinancialRoutingModule,
    InputNumberModule,
    TableModule,
    FormsModule,
    NgxPermissionsModule.forChild()
  ]
})
export class FinancialModule {}

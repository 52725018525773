import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ShopSettings, ShopSettingsRepository } from '@infrab4a/connect';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { FileControllerService } from 'src/app/admin-api';
import { CustomizationFormComponent } from 'src/app/components/customization/customization-form.component';
import { ImageService } from 'src/app/services/image.service';

@Component({
  selector: 'app-earn-glampoints-form',
  templateUrl: './earn-glampoints-form.component.html',
  styleUrls: ['./earn-glampoints-form.component.scss']
})
export class EarnGlampointsFormComponent
  extends CustomizationFormComponent
  implements OnInit
{
  pageDocument: ShopSettings;

  constructor(
    @Inject('ShopSettingsRepository')
    private shopSettingsRepository: ShopSettingsRepository,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    override router: Router,
    override imageService: ImageService,
    override config: PrimeNGConfig,
    override fileService: FileControllerService
  ) {
    super(router, config, imageService, fileService);

    this.documentId = 'EARN_GLAMPOINTS';

    this.form = this.formBuilder.group({
      campaignName: ['', [Validators.required]],
      imageGift: ['', [Validators.required]],
      nameGift: ['', [Validators.required]],
      altText: ['', [Validators.required]],
      beginDate: [null, [Validators.required]]
    });
  }

  async ngOnInit() {
    await this.getDocument();
  }

  override async getDocument(): Promise<void> {
    try {
      this.pageDocument = await this.shopSettingsRepository.get({
        id: this.documentId
      });
      this.documentSections = this.pageDocument.sections || [];
      if (this.router.url.includes('edit')) {
        this.activatedRoute.params.subscribe((params) => {
          this.activeIndex = +params['index'];
          this.activeSection = this.documentSections[this.activeIndex];
          this.initializeForm();
        });
      }
    } catch (error) {
      this.form.patchValue(this.activeSection);
    }
  }

  override initializeForm(): void {
    this.form.patchValue(this.activeSection);
  }

  override async save() {
    if (this.form.valid) {
      if (this.activeSection)
        this.documentSections[this.activeIndex] = this.form.value;
      else this.documentSections.push(this.form.value);

      await this.shopSettingsRepository.update({
        id: this.documentId,
        sections: this.documentSections
      });

      this.messageService.add({
        severity: 'success',
        summary: 'Salvo com sucesso',
        detail: 'As informações foram salvas.'
      });
      await this.afterSubmit();

      this.navigate('/configuracoes/call-friends/earn-glampoints');
    } else {
      console.error('Formulário inválido. Não é possível salvar os dados.');
    }
  }

  isBeginDateInvalid() {
    const beginDateControl = this.form.get('beginDate');
    return (
      beginDateControl?.invalid &&
      (beginDateControl?.touched || beginDateControl?.dirty)
    );
  }

  isCampaignNameInvalid() {
    const campaignNameControl = this.form.get('campaignName');
    return (
      campaignNameControl?.invalid &&
      (campaignNameControl?.touched || campaignNameControl?.dirty)
    );
  }

  isTitleInvalid() {
    const titleControl = this.form.get('nameGift');
    return (
      titleControl?.invalid && (titleControl?.touched || titleControl?.dirty)
    );
  }

  isAltTextInvalid() {
    const altTextControl = this.form.get('altText');
    return (
      altTextControl?.invalid &&
      (altTextControl?.touched || altTextControl?.dirty)
    );
  }

  isDiscountInvalid() {
    const discountControl = this.form.get('discount');
    return (
      discountControl?.invalid &&
      (discountControl?.touched || discountControl?.dirty)
    );
  }

  async handleFileSelect(
    event: { files: File[] },
    inputName: string,
    expectedWidth: number,
    expectedHeight: number,
    maxWeight: number,
    pInput: FileUpload
  ) {
    this.fileNamePattern = `glampoints-earn-glampoints-${new Date().getTime()}.webp`;

    await this.upload(
      event,
      inputName,
      expectedWidth,
      expectedHeight,
      maxWeight,
      pInput
    );
  }

  goToMenu() {
    this.navigate('configuracoes/call-friends/earn-glampoints');
  }

  get imageGiftUrl(): string {
    return this.form.get('imageGift')?.value;
  }

  override get filePath(): string {
    return '/pages/earn-glampoints/';
  }

  override get model() {
    return this.pageDocument;
  }

  override get modelId() {
    return this.pageDocument?.id;
  }
}

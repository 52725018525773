/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AttributeOption {
  attributeOptionId?: number;
  attributeId?: number;
  attributeOptionName?: string;
  attributeOptionImage?: string;
  dateCreated?: Date;
  dateUpdated?: Date;
  absFreq?: number;
  relFreq?: number;
  feeedbackOptionIdOld?: number;
  feeedbackOptionIdNew?: number;
  order?: number;
  nextQuestionId?: number;
  active?: boolean;
  singleOption?: boolean;
}

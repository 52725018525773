<app-table
  [cols]="cols"
  [findPage]="findPage"
  modelName="marcas"
  [service]="brandService"
  sortField="brandName"
  [sortOrder]="-1"
  addLabel="Nova marca"
  [dropdownFilters]="dropdownFilters"
  [changeColumns]="true"
></app-table>

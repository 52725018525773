/* eslint-disable @typescript-eslint/no-explicit-any */
import { CurrencyPipe, DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  inject
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService, PrimeIcons } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  Coupon,
  CouponControllerService,
  CouponEmailRestrictionDetail,
  CouponPriceChangeControllerService,
  CouponPriceChangeDetail,
  CouponProductVariantSubscriptionTypeRestriction,
  CouponProductVariantSummary,
  CouponUsageType,
  InfluencerControllerService,
  InfluencerStatus,
  PageableFilter,
  PageableRequest,
  SubscriberCouponSummary,
  Subscription,
  SubscriptionControllerService,
  SubscriptionType
} from 'src/app/admin-api';
import { GridstackComponent } from 'src/app/components/gridstack/gridstack.component';
import {
  PageContent,
  TableActionButton,
  TableColumn,
  TableComponent
} from 'src/app/components/table';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';
import { EmailRestrictionFormComponent } from '../email-restriction-form/email-restriction-form.component';
import { CouponChangeScheduleModalComponent } from './coupon-change-schedule-modal/coupon-change-schedule-modal.component';

@Component({
  selector: 'app-coupon-detail',
  templateUrl: './coupon-detail.component.html',
  styleUrls: ['./coupon-detail.component.scss'],
  providers: [
    DialogService,
    ConfirmationService,
    MessageService,
    DatePipe,
    CurrencyPipe
  ],
  encapsulation: ViewEncapsulation.None
})
export class CouponDetailComponent implements OnInit {
  @ViewChild(GridstackComponent) gridComp?: GridstackComponent;
  @ViewChild('changesTable') changesTable: TableComponent;

  public couponService = inject(CouponControllerService);
  public couponPriceChangeService = inject(CouponPriceChangeControllerService);
  private subscriptionService = inject(SubscriptionControllerService);
  private activatedRoute = inject(ActivatedRoute);
  private title = inject(Title);
  private router = inject(Router);
  private cdRef = inject(ChangeDetectorRef);
  private dialog = inject(DialogService);
  private messageService = inject(MessageService);
  private confirmationService = inject(ConfirmationService);
  private influencerService = inject(InfluencerControllerService);
  private datePipe = inject(DatePipe);
  private currencyPipe = inject(CurrencyPipe);

  coupon: (Coupon & { subscriptionTypeIds?: number[] }) | undefined;
  couponUsageTypes: Array<CouponUsageType> | undefined;
  couponTypes: Array<{ label: string; value: number }> = [
    { label: 'Porcentagem', value: 0 },
    { label: 'Valor Absoluto', value: 1 }
  ];
  subscriptions: Array<Subscription> | undefined;
  subscriptionTypes: Array<SubscriptionType> | undefined;
  influencerStatusList: Array<InfluencerStatus> | undefined;
  couponProductVariantSubscriptionTypeIdsRestrictions:
    | Array<CouponProductVariantSubscriptionTypeRestriction>
    | undefined;
  selectedTab = 0;
  variantCols: Array<TableColumn> = [
    new TableColumn('Id', 'externalId', true, 'number'),
    new TableColumn(
      'Nome',
      'productVariantName',
      true,
      'text',
      '/products/catalog/product-variant/',
      'productVariantId',
      true,
      'contains'
    ),
    new TableColumn(
      'Marca',
      'brandName',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'InternalEAN',
      'internalEAN',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn('Peso (g)', 'weight', false, 'text'),
    new TableColumn('Tipo de produto', 'typeName', true, 'text'),
    new TableColumn('Ação', 'action', false, 'button')
  ];
  groups: Array<{
    groupVariantId: number;
    variants: Array<CouponProductVariantSummary>;
  }> = [];
  fixedFilters: Array<PageableFilter> | undefined;
  colsSubscribers: Array<TableColumn> = [
    new TableColumn(
      'Plano',
      'subscriptionTypeName',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Usuário',
      'personName',
      true,
      'text',
      '/users/person/',
      'personId',
      true,
      'contains'
    ),
    new TableColumn(
      'Desconto',
      'value',
      false,
      'number',
      undefined,
      undefined,
      true,
      'equals'
    ),
    new TableColumn(
      'Convite',
      'inviter',
      true,
      'text',
      '/users/person/',
      'inviterPersonid',
      true,
      'contains'
    ),
    new TableColumn('Frete grátis', 'freeShipping', false, 'boolean'),
    new TableColumn(
      'Data de utilização',
      'dateCreated',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    )
  ];
  restrictions: Array<CouponEmailRestrictionDetail> | undefined;
  restrictionsCols: Array<TableColumn> = [
    new TableColumn('', '', false, 'checkbox'),
    new TableColumn(
      'Email',
      'email',
      true,
      'text',
      '/users/person/',
      'personId',
      true,
      'startsWith',
      undefined,
      undefined,
      undefined,
      undefined,
      (value: CouponEmailRestrictionDetail) => !!value.personId
    ),
    new TableColumn(
      'Utilizações',
      'redeems',
      true,
      'number',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Máximo usos',
      'maxRedeems',
      true,
      'inputNumber',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn('Data', 'dateCreated', false, 'date')
  ];

  actionButtons: Array<TableActionButton> = [
    new TableActionButton(
      '',
      'edit',
      PrimeIcons.PENCIL,
      (price) => !price.changed,
      '',
      'Editar',
      'bottom',
      true,
      true,
      'primary',
      'small'
    ),
    new TableActionButton(
      '',
      'remove',
      PrimeIcons.TRASH,
      (price) => !price.changed,
      '',
      'Excluir',
      'bottom',
      true,
      true,
      'danger',
      'small'
    )
  ];
  scheduleCols: Array<TableColumn> = [
    new TableColumn.Builder()
      .setHeader('Data da mudança')
      .setField('dateChange')
      .setFilter(true)
      .setType('date')
      .setCondition('between')
      .setDisplayFunction((price: CouponPriceChangeDetail) =>
        this.dateChangeLabel(price.dateChange)
      )
      .build(),
    new TableColumn.Builder()
      .setHeader('Usuário')
      .setField('userName')
      .setFilter(true)
      .build(),
    new TableColumn.Builder()
      .setHeader('Desconto')
      .setField('value')
      .setType('number')
      .setCondition('gte')
      .setFilter(false)
      .setDisplayFunction((price: CouponPriceChangeDetail) =>
        FormUtil.discountLabel(price.value, price.couponType, this.currencyPipe)
      )
      .build(),
    new TableColumn.Builder()
      .setHeader('Restrição de plano')
      .setField('subscriptionTypeName')
      .setDisplayFunction(
        (price: CouponPriceChangeDetail) =>
          price.subscriptionTypeNames || 'Sem restrição'
      )
      .build(),
    new TableColumn.Builder()
      .setHeader('Ativo')
      .setField('enabled')
      .setType('boolean')
      .build(),
    new TableColumn.Builder()
      .setHeader('Restrição brindes')
      .setField('restrictSubscriptionTypes')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('Brindes')
      .setField('products')
      .setCondition('gte')
      .setType('formattedInteger')
      .build(),
    new TableColumn.Builder()
      .setHeader('Data criação')
      .setField('dateCreated')
      .setFilter(true)
      .setType('date')
      .setCondition('between')
      .build(),
    new TableColumn.Builder()
      .setHeader('Ações')
      .setField('')
      .setType('button')
      .setFilter(false)
      .build()
  ];

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(async (params) => {
      LoaderService.showLoader();
      if (params['couponId'] && params['couponId'] !== 'new') {
        await Promise.all([
          this.findCoupon(Number(params['couponId'])),
          this.findCouponUsageTypes(),
          this.findSubscriptions(),
          this.findInfluencerStatusList()
        ]);
        await Promise.all([
          this.findCouponSubscriptionTypeRestrictions(),
          this.findEmailRestrictions(),
          this.findCouponProductVariantSubscriptionTypeRestrictions()
        ]);
        this.fixedFilters = [
          {
            condition: 'equals',
            field: 'couponId',
            value: this.coupon?.couponId?.toString(),
            fieldType: 'number'
          }
        ];
        this.title.setTitle('Cupom ' + this.coupon?.name);
      } else {
        await Promise.all([
          this.findCouponUsageTypes(),
          this.findSubscriptions(),
          this.findInfluencerStatusList()
        ]);
        this.title.setTitle('Novo cupom');
      }
      this.activatedRoute.queryParams.subscribe((qParams) => {
        if (qParams['tab'] && this.coupon?.couponId) {
          this.selectedTab = Number(qParams['tab']);
        }
        LoaderService.showLoader(false);
      });
    });
  }

  async findCoupon(couponId: number): Promise<void> {
    try {
      this.coupon = await lastValueFrom(
        this.couponService
          .findCouponById(couponId)
          .pipe(map((data) => data.result))
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error, true);
    }
  }

  async findCouponUsageTypes(): Promise<void> {
    delete this.couponUsageTypes;
    try {
      this.couponUsageTypes = await lastValueFrom(
        this.couponService
          .findUsageTypesList()
          .pipe(
            map((data) => data.result?.filter((c) => c.couponUsageTypeId !== 8))
          )
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findSubscriptions(): Promise<void> {
    delete this.subscriptions;
    try {
      this.subscriptions = await lastValueFrom(
        this.subscriptionService
          .findSubscriptionList()
          .pipe(
            map((data) =>
              [
                { subscriptionId: 0, subscriptionName: 'Todas' } as Subscription
              ].concat(data.result)
            )
          )
      );
      if (this.subscriptions.length) {
        this.subscriptionTypes = (
          await Promise.all(
            this.subscriptions.map((s) =>
              lastValueFrom(
                this.subscriptionService
                  .findSubscriptionTypeList(s.subscriptionId)
                  .pipe(
                    map((data) =>
                      data.result.map((st) => ({
                        ...st,
                        name: st.name.replace('.', '/Mês')
                      }))
                    )
                  )
              )
            )
          )
        ).reduce((list, sts) => (list = list.concat(sts)), []);
      }
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findInfluencerStatusList(): Promise<void> {
    try {
      this.influencerStatusList = await lastValueFrom(
        this.influencerService.findInfluencerStatusList().pipe(
          map((data) =>
            [
              {
                influencerStatus: 'Nenhuma',
                influencerStatusId: 0
              } as InfluencerStatus
            ].concat(data.result)
          )
        )
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findEmailRestrictions(): Promise<void> {
    delete this.restrictions;
    try {
      this.restrictions = await lastValueFrom(
        this.couponService
          .findCouponEmailRestrictions(this.coupon.couponId)
          .pipe(map((data) => data.result))
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findCouponSubscriptionTypeRestrictions(): Promise<void> {
    try {
      if (this.coupon)
        this.coupon.subscriptionTypeIds = await lastValueFrom(
          this.couponService
            .findSubscriptionTypeRestrictionByCouponId(this.coupon.couponId)
            .pipe(map((data) => data.result.map((s) => s.subscriptionTypeId)))
        );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findCouponProductVariantSubscriptionTypeRestrictions(): Promise<void> {
    delete this.couponProductVariantSubscriptionTypeIdsRestrictions;
    try {
      this.couponProductVariantSubscriptionTypeIdsRestrictions =
        await lastValueFrom(
          this.couponService
            .findCouponProductVariantSubscriptionTypeRestrictionByCouponId(
              this.coupon.couponId
            )
            .pipe(map((data) => data.result))
        );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
  }

  tabChanged($event: number): void {
    this.router.navigate([`/marketing/coupons/${this.coupon?.couponId}`], {
      queryParams: { tab: $event },
      queryParamsHandling: 'merge'
    });
  }

  async updateCoupon(coupon: Coupon): Promise<void> {
    this.coupon = coupon;
    await this.findCouponSubscriptionTypeRestrictions();
    this.cdRef.detectChanges();
    LoaderService.showLoader(false);
  }

  async findPage(
    request: PageableRequest,
    service: unknown
  ): Promise<PageContent<SubscriberCouponSummary>> {
    const all = request.filters?.find(
      (f) =>
        f.field === 'type' &&
        (f.value === 'Unkown' || !(f.value as string)?.length)
    );
    if (all) {
      all.condition = 'notEquals';
      all.value = 'Unkown';
    }
    return lastValueFrom(
      (service as CouponControllerService)
        .findSubscriberCouponsTable(request)
        .pipe(
          map((data) => data.result as PageContent<SubscriberCouponSummary>)
        )
    );
  }

  addEmailRestriction(): void {
    this.dialog
      .open(EmailRestrictionFormComponent, {
        header: 'Incluir e-mails',
        data: {
          coupon: this.coupon
        },
        width: '420px',
        height: '50vh'
      })
      .onClose.subscribe(async (data: Array<CouponEmailRestrictionDetail>) => {
        if (data) {
          this.messageService.add({
            severity: 'success',
            summary: 'Sucesso',
            detail: `${data.length} e-mail(s) inseridos`
          });
          await this.findEmailRestrictions();
          LoaderService.showLoader(false);
        }
      });
  }

  removeEmailRestrictions($event: Array<CouponEmailRestrictionDetail>): void {
    this.confirmationService.confirm({
      acceptIcon: PrimeIcons.TRASH,
      acceptButtonStyleClass: 'p-button-danger',
      acceptLabel: 'Remover',
      rejectLabel: 'Voltar',
      rejectButtonStyleClass: 'p-button-primary',
      rejectIcon: PrimeIcons.TIMES,
      message: 'Deseja remover ' + $event.length + ' e-mail(s) do cupom?',
      header: 'Remover e-mails',
      accept: async () => {
        const restrictions = this.restrictions.length;
        delete this.restrictions;
        LoaderService.showLoader();
        this.restrictions = await lastValueFrom(
          this.couponService
            .removeCouponEmailRestrictions({
              couponId: this.coupon.couponId,
              emails: $event.map((e) => e.email)
            })
            .pipe(map((data) => data.result))
        );
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: `${
            restrictions - this.restrictions.length
          } e-mail(s) removidos`
        });
        LoaderService.showLoader(false);
      }
    });
  }

  async updateMaxRedeems(
    restriction: CouponEmailRestrictionDetail
  ): Promise<void> {
    LoaderService.showLoader();
    try {
      await lastValueFrom(
        this.couponService.updateRestrictionMaxRedeems(restriction)
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  async openNewPriceModal(schedule?: CouponPriceChangeDetail): Promise<void> {
    try {
      const promises: Promise<any>[] = [
        lastValueFrom(
          this.couponService
            .findCouponProductVariants(this.coupon.couponId)
            .pipe(map((data) => data.result))
        )
      ];
      if (schedule)
        promises.push(
          lastValueFrom(
            this.couponPriceChangeService
              .getCouponPriceByCouponPriceChangeId(schedule.couponPriceChangeId)
              .pipe(map((data) => data.result))
          )
        );
      const [productVariants, priceChange] = await Promise.all(promises);
      this.dialog
        .open(CouponChangeScheduleModalComponent, {
          closable: true,
          closeOnEscape: true,
          header: `Agendar alterações`,
          data: {
            coupon: this.coupon,
            subscriptions: this.subscriptions,
            subscriptionTypes: this.subscriptionTypes,
            schedule: priceChange,
            productVariants: productVariants || [],
            restrictions:
              this.couponProductVariantSubscriptionTypeIdsRestrictions
          },
          modal: true,
          focusOnShow: false
        })
        .onClose.subscribe(async (data) => {
          if (data) {
            this.messageService.add({
              severity: 'success',
              detail: 'Alterações agendadas com sucesso.',
              summary: 'Sucesso'
            });
            await this.changesTable?.refresh();
            LoaderService.showLoader(false);
          }
        });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async callAction(event: {
    item: CouponPriceChangeDetail;
    $event: Event;
    action: 'remove' | 'edit';
  }): Promise<void> {
    if (event.action === 'remove') {
      this.confirmationService.confirm({
        header: 'Exclusão',
        message: `Tem certeza que deseja excluir o agendamento do dia ${this.dateChangeLabel(
          event.item.dateChange
        )}?`,
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        acceptButtonStyleClass: 'p-button-danger',
        accept: async () => {
          await this.deletePrice(event.item.couponPriceChangeId);
        },
        target: event.$event.target
      });
    } else if (event.action === 'edit') {
      await this.openNewPriceModal(event.item);
    }
  }

  async deletePrice(couponPriceChangeId: number) {
    LoaderService.showLoader();
    try {
      const detail = await lastValueFrom(
        this.couponPriceChangeService
          .deletePriceChange(couponPriceChangeId)
          .pipe(map((data) => data.result))
      );
      this.messageService.add({
        severity: 'success',
        detail,
        summary: 'Sucesso'
      });
      await this.changesTable?.refresh();
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  dateChangeLabel(dateChange: Date | string): string {
    return dateChange
      ? this.datePipe.transform(FormUtil.utcDate(dateChange), 'dd/MM/yyyy')
      : null;
  }
}

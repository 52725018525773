<app-table
  *ngIf="cols"
  [cols]="cols"
  pageFunctionName="getCategoriesList"
  [service]="shoppingCategoryService"
  modelName="categorias"
  sortField="name"
  [sortOrder]="-1"
  [defaultRows]="10"
  [changeColumns]="true"
  [addButton]="true"
  [addLabel]="'Nova categoria'"
  addButtonRouterLink="category/new"
  [dropdownFilters]="dropdownFilters"
  [defaultFilterValues]="defaultFilterValues"
/>

<form class="form-b4a" [formGroup]="form" (submit)="submit()">
  <p-accordion *ngIf="product && shopProduct" [activeIndex]="index">
    <p-accordionTab *ngFor="let field of toSync" [header]="field.label">
      <p>Escolha qual das opções deseja utilizar:</p>
      <span class="sync-options">
        <p-card>
          <ng-template pTemplate="header">
            <p
              class="p-input-radio"
              (click)="form.get(field.value).setValue('product')"
            >
              <p-radioButton
                [name]="field.value"
                [inputId]="field.value + '_product'"
                value="product"
                [formControlName]="field.value"
              />
              <label [for]="field.value + '_product'">Assinatura</label>
              <small>
                Atualizado em
                {{
                  product.dateUpdated || product.dateCreated
                    | date : 'dd/MM/yyyy'
                }}
                às
                {{
                  product.dateUpdated || product.dateCreated | date : 'HH:mm:ss'
                }}
              </small>
            </p>
          </ng-template>
          <span
            [innerHTML]="product[field.controlName]"
            *ngIf="product[field.controlName]"
          ></span>
          <span *ngIf="!product[field.controlName]">- Sem informações</span>
        </p-card>
        <p-card>
          <ng-template pTemplate="header">
            <p
              class="p-input-radio"
              (click)="form.get(field.value).setValue('shopProduct')"
            >
              <p-radioButton
                [name]="field.value"
                [inputId]="field.value + '_shopProduct'"
                value="shopProduct"
                [formControlName]="field.value"
              />
              <label [for]="field.value + '_shopProduct'">Loja</label>
              <small>
                * Atualizado em
                {{
                  shopProduct.updatedAt || shopProduct.createdAt
                    | date : 'dd/MM/yyyy'
                }}
                às
                {{
                  shopProduct.updatedAt || shopProduct.createdAt
                    | date : 'HH:mm:ss'
                }}
              </small>
            </p>
          </ng-template>
          <span
            [innerHTML]="shopProduct.description[field.value]"
            *ngIf="shopProduct.description[field.value]"
          ></span>
          <span *ngIf="!shopProduct.description[field.value]">
            <p>- Sem informações</p>
          </span>
        </p-card>
      </span>
    </p-accordionTab>
  </p-accordion>
  <span class="buttons">
    <p-button label="Salvar" type="submit" [disabled]="!form.valid" />
  </span>
</form>

<app-table
  [cols]="cols"
  [actionButtons]="actionButtons"
  pageFunctionName="getPaginetedFilterList"
  [service]="categoryFilterService"
  modelName="filtros"
  sortField="description"
  [sortOrder]="-1"
  [defaultRows]="10"
  [addButton]="true"
  [dropdownFilters]="dropdownFilters"
  (booleanAction)="updateEnabled($event)"
  (actionButtonClick)="delete($event)"
/>
<p-confirmPopup />
<p-toast />

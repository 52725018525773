import { Injectable } from '@angular/core';
import SockJS from 'sockjs-client';
import { environment } from 'src/environments/environment';
import Stomp from 'stompjs';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  private stompClient;
  private topic = '/statusProcessor';
  private onMessage: (message: any) => void = this.logMessage;
  private retrys = 0;
  private maxFails = 10;

  private logMessage(message: any) {
    console.log('[Websocket new message]:', message);
  }

  public connect(onMessage?: (message: any) => void) {
    console.log('Starting a WebSocket connection');
    this.onMessage = onMessage || this.logMessage;
    const ws = new SockJS(environment.websocketUrl);
    this.stompClient = Stomp.over(ws);
    this.stompClient.connect(
      {},
      () => {
        this.retrys = 0;
        this.stompClient.subscribe(this.topic, (event: { body: string }) => {
          this.onMessage(JSON.parse(event.body));
        });
      },
      this.onError.bind(this)
    );
  }

  public disconnect() {
    if (this.stompClient)
      this.stompClient.disconnect(() =>
        console.log('disconnected from ' + environment.websocketUrl)
      );
  }

  private onError(error) {
    console.log('Error while connect: ' + error);
    if (this.retrys < this.maxFails)
      setTimeout(() => {
        this.retrys++;
        console.log('Retry: ', this.retrys);
        console.log('Trying to connect again...');
        this.connect(this.onError);
      }, 3000);
    else {
      console.log('Max fails reached. Disconnecting...');
      this.disconnect();
      window.location.reload();
    }
  }
}

import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { BroadcastUtil } from 'src/app/utils/broadcast.util';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  form = new FormGroup({
    username: new FormControl(null, [Validators.required, Validators.email]),
    password: new FormControl(null, [
      Validators.required,
      Validators.minLength(6)
    ])
  });
  errorMessage = '';

  constructor(private authService: AuthService, private router: Router) {}

  async submit(event: Event) {
    event.preventDefault();
    if (this.form.valid) {
      try {
        LoaderService.showLoader();
        const data = await this.authService.login({
          ...this.form.value
        });
        TokenStorageService.saveResult(data);
        LoaderService.showLoader(false);
        BroadcastUtil.get('userChanged').emit();
        this.router.navigate(['/']);
      } catch (error) {
        LoaderService.showLoader(false);
        AppDialogService.showErrorDialog(
          {},
          false,
          'Usuário ou senha inválida.'
        );
      }
    }
  }
}

<div class="card">
  <app-table
    [cols]="columns"
    *ngIf="columns"
    [service]="emailTemplateService"
    pageFunctionName="findTemplatesPage"
    tableTitle="Templates de Email"
    modelName="Templates"
    emptyMessage="Nenhum template encontrado"
    [actionButtons]="actionButtons"
    (actionButtonClick)="actionButtonEvent($event.item)"
    [addButtonAction]="true"
    (addButtonClick)="openNew()"
    [addButton]="permission"
  />
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { TreeSelectModule } from 'primeng/treeselect';
import { AttributeFormComponent } from './attribute-form.component';
import { AttributeGroupFormComponent } from './attribute-group-form/attribute-group-form.component';
import { AttributeOptionFormComponent } from './attribute-option-form/attribute-option-form.component';

@NgModule({
  declarations: [
    AttributeGroupFormComponent,
    AttributeOptionFormComponent,
    AttributeFormComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputSwitchModule,
    InputTextModule,
    ButtonModule,
    DropdownModule,
    CheckboxModule,
    FileUploadModule,
    TreeSelectModule
  ],
  exports: [
    AttributeGroupFormComponent,
    AttributeOptionFormComponent,
    AttributeFormComponent
  ],
  providers: [MessageService]
})
export class AttributeFormModule {}

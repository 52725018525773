<form class="form-b4a" [formGroup]="form" (submit)="showReview()">
  <ng-container formGroupName="description">
    <span class="p-input-editor">
      <label for="productSummary">
        {{ descriptionField('description')?.label }} *
      </label>
      <small
        *ngIf="hasSyncInfo('description')"
        class="warning"
        (click)="syncInfo(descriptionField('description'))"
      >
        * {{ descriptionField('description')?.label }} está diferente na
        assinatura
      </small>
      <p-editor
        [style]="{ height: '320px' }"
        formControlName="description"
        id="description"
        #description
      />
    </span>
    <span class="p-input-editor">
      <label for="differentials">
        {{ descriptionField('differentials')?.label }}
      </label>
      <small
        *ngIf="hasSyncInfo('differentials')"
        class="warning"
        (click)="syncInfo(descriptionField('differentials'))"
      >
        * {{ descriptionField('differentials')?.label }} está diferente na
        assinatura
      </small>
      <p-editor
        [style]="{ height: '320px' }"
        formControlName="differentials"
        id="differentials"
        #differentials
      />
    </span>
    <span class="p-input-editor">
      <label for="whoMustUse">
        {{ descriptionField('whoMustUse')?.label }}
      </label>
      <small
        *ngIf="hasSyncInfo('whoMustUse')"
        class="warning"
        (click)="syncInfo(descriptionField('whoMustUse'))"
      >
        * {{ descriptionField('whoMustUse')?.label }} está diferente na
        assinatura
      </small>
      <p-editor
        [style]="{ height: '320px' }"
        formControlName="whoMustUse"
        id="whoMustUse"
        #whoMustUse
      />
    </span>
    <span class="p-input-editor">
      <label for="howToUse">
        {{ descriptionField('howToUse')?.label }}
      </label>
      <small
        *ngIf="hasSyncInfo('howToUse')"
        class="warning"
        (click)="syncInfo(descriptionField('howToUse'))"
      >
        * {{ descriptionField('howToUse')?.label }} está diferente na assinatura
      </small>
      <p-editor
        [style]="{ height: '320px' }"
        formControlName="howToUse"
        id="howToUse"
        #howToUse
      />
    </span>
    <span class="p-input-editor">
      <label for="brand">
        {{ descriptionField('brand')?.label }}
      </label>
      <small
        *ngIf="hasSyncInfo('brand')"
        class="warning"
        (click)="syncInfo(descriptionField('brand'))"
      >
        * {{ descriptionField('brand')?.label }} está diferente na assinatura
      </small>
      <p-editor
        [style]="{ height: '320px' }"
        formControlName="brand"
        id="brand"
        #brand
      />
    </span>
    <span class="p-input-editor">
      <label for="ingredients">
        {{ descriptionField('ingredients')?.label }}
      </label>
      <small
        *ngIf="hasSyncInfo('ingredients')"
        class="warning"
        (click)="syncInfo(descriptionField('ingredients'))"
      >
        * {{ descriptionField('ingredients')?.label }} está diferente na
        assinatura
      </small>
      <p-editor
        [style]="{ height: '320px' }"
        formControlName="ingredients"
        id="ingredients"
        #ingredients
      />
    </span>
    <span class="p-input-editor">
      <label for="purpose">Propósito</label>
      <p-editor
        [style]="{ height: '320px' }"
        formControlName="purpose"
        id="purpose"
        #purpose
      />
    </span>
  </ng-container>
  <span class="p-float-label">
    <input id="video" type="text" pInputText formControlName="video" />
    <label for="video">Vídeo (HTML incorporado)</label>
  </span>
  <div class="form-footer">
    <div class="info">
      <div *ngIf="product">
        <p>*Última alteração em: {{ product.updatedAt | date : 'short' }}</p>
      </div>
    </div>
    <div class="action">
      <p-button
        type="submit"
        pRipple
        label="Salvar"
        severity="primary"
        [disabled]="!form.valid"
      >
      </p-button>
    </div>
  </div>
</form>
<p-toast />

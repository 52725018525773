<div class="rating-and-scores-dashboard">
  <h3>Dashboard Rating and Scores</h3>
  <div class="filters">
    <form class="form-b4a" [formGroup]="form">
      <span class="p-float-label">
        <p-dropdown
          inputId="subscriptionId"
          [options]="subscriptions"
          (onChange)="changeSubscription()"
          formControlName="subscriptionId"
          appendTo="body"
          [autoDisplayFirst]="false"
        />
        <label for="subscriptionId">Assinatura</label>
      </span>
      <span class="p-float-label">
        <p-dropdown
          inputId="editionId"
          [options]="editions"
          *ngIf="editions"
          optionValue="editionId"
          optionLabel="theme"
          (onChange)="changeEdition()"
          [filter]="true"
          filterBy="theme,title,editionId,description"
          filterMatchMode="contains"
          [autoDisplayFirst]="false"
          formControlName="editionId"
          appendTo="body"
        >
          <ng-template pTemplate="item" let-edition>
            <ng-container *ngIf="form.value.subscriptionId">
              {{ edition.theme }}
              {{ editionDate(edition.editionId) }}
            </ng-container>
            <ng-container *ngIf="!form.value.subscriptionId">
              {{ editionDateName(edition.editionId) | titlecase }}
            </ng-container>
          </ng-template>
          <ng-template pTemplate="selectedItem">
            <ng-container *ngIf="form.value.subscriptionId">
              {{ edition?.theme }}
            </ng-container>
            <ng-container
              *ngIf="edition && edition.editionId && !form.value.subscriptionId"
            >
              {{ editionDateName(edition.editionId) | titlecase }}
            </ng-container>
          </ng-template>
        </p-dropdown>
        <label for="editionId">Edição</label>
      </span>
    </form>
    <div class="picture">
      <app-edition-image
        [imageURL]="edition.imageURL"
        [editionId]="edition.editionId"
        *ngIf="edition"
      />
    </div>
  </div>
  <div class="totals" *ngIf="editionScoreDetails">
    <div class="dashboard-card">
      <span class="header header--box">Box Rating</span>
      <span class="value">{{
        editionScoreDetails.avgBoxRating | number : '1.2-2'
      }}</span>
    </div>
    <div class="dashboard-card">
      <span class="header header--primary">Products Rating</span>
      <span class="value">{{
        editionScoreDetails.avgProducts | number : '1.2-2'
      }}</span>
    </div>
    <div class="dashboard-card">
      <span class="header header--bag">Match Rating</span>
      <span class="value">{{
        editionScoreDetails.avgFitting | number : '1.2-2'
      }}</span>
    </div>
    <div class="dashboard-card">
      <span class="header header--delivery">Delivery Rating</span>
      <span class="value">{{
        editionScoreDetails.avgDelivery | number : '1.2-2'
      }}</span>
    </div>
    <div class="dashboard-card">
      <span class="header header--dark">Avaliações</span>
      <span class="value">{{
        editionScoreDetails.recomendationsTotal | number : '1.0-0'
      }}</span>
    </div>
  </div>
  <div class="dashboard" *ngIf="ready">
    <p-chart
      [data]="chartData"
      [options]="chartConfig"
      id="chart"
      [responsive]="false"
      width="100%"
      height="400px"
      #chart
      *ngIf="chartData && width"
    />
  </div>
  <div>
    <app-table
      [lazy]="false"
      [elements]="editionBadgeScores"
      [cols]="cols"
      *ngIf="editionBadgeScores?.length"
      [addButton]="false"
      modelName="registros"
      tableTitle="Joias"
      [footerTemplate]="footerTemplate"
      [showFooter]="true"
      [paginator]="false"
      sortField="badgeId"
    >
      <ng-template #footerTemplate>
        <tr>
          <td colspan="3">Total</td>
          <td>
            {{ editionScoreDetails?.avgBoxRating || 0 | number : '1.2-2' }}
          </td>
          <td>
            {{ editionScoreDetails?.avgDelivery || 0 | number : '1.2-2' }}
          </td>
          <td>
            {{ editionScoreDetails?.avgProducts || 0 | number : '1.2-2' }}
          </td>
          <td>{{ editionScoreDetails?.avgFitting || 0 | number : '1.2-2' }}</td>
          <td>{{ totalRecomendations || 0 | number : '1.0-0' }}</td>
        </tr>
      </ng-template>
    </app-table>
  </div>
</div>

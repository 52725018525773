import { Component, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TreeNode } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileUpload, FileUploadHandlerEvent } from 'primeng/fileupload';
import { lastValueFrom, map } from 'rxjs';
import {
  FeedbackAdminControllerService,
  FeedbackControllerService,
  FeedbackForm,
  FeedbackFormDetail,
  FeedbackQuestion,
  FeedbackQuestionGroup
} from 'src/app/admin-api';
import { QuestionTypeEnum, getQuestionTypeName } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-feedback-question-clone',
  templateUrl: './feedback-question-clone.component.html',
  styleUrl: './feedback-question-clone.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class FeedbackQuestionCloneComponent implements OnInit {
  private feedbackService: FeedbackControllerService = inject(
    FeedbackControllerService
  );
  private feedbackAdminService: FeedbackAdminControllerService = inject(
    FeedbackAdminControllerService
  );
  config: DynamicDialogConfig = inject(DynamicDialogConfig);
  ref: DynamicDialogRef = inject(DynamicDialogRef);

  group: FeedbackQuestionGroup = this.config.data.group;
  feedbackForm: FeedbackForm = this.config.data.form;
  form = new FormGroup({
    questionIds: new FormControl<Array<number>>(
      [],
      [Validators.required, Validators.minLength(1)]
    ),
    questions: new FormControl<
      Array<TreeNode<FeedbackQuestion | FeedbackQuestionGroup>>
    >([], [Validators.required, Validators.minLength(1)]),
    groupId: new FormControl<number>(this.group?.feedbackGroupId),
    formId: new FormControl<number>(this.feedbackForm?.formId, [
      Validators.required,
      Validators.min(1)
    ]),
    feedbackFormId: new FormControl<number>(this.feedbackForm?.formId, [
      Validators.required,
      Validators.min(1)
    ]),
    feedbackForm: new FormControl<FeedbackForm>(this.feedbackForm, [
      Validators.required
    ]),
    copyOptions: new FormControl<boolean>(true),
    importType: new FormControl<number>(0, Validators.required),
    file: new FormControl<File>(
      { value: null, disabled: true },
      Validators.required
    ),
    fileName: new FormControl<string>({ value: null, disabled: true })
  });
  search = new FormControl('');
  forms: Array<FeedbackFormDetail>;
  formsAvailable: Array<FeedbackFormDetail>;
  questionsList: {
    [formId: number]: Array<
      FeedbackQuestion & { options?: Array<FeedbackQuestion> }
    >;
  } = {};
  questions: {
    [formId: number]: Array<FeedbackQuestion | FeedbackQuestionGroup>;
  } = {};
  questionItems: {
    [formId: number]: Array<TreeNode<FeedbackQuestion | FeedbackQuestionGroup>>;
  } = {};

  async ngOnInit(): Promise<void> {
    await this.formChange();
  }

  async submit(): Promise<void> {
    LoaderService.showLoader();
    try {
      if (!this.form.value.importType) {
        const questions = await lastValueFrom(
          this.feedbackAdminService
            .cloneFeebackQuestions(this.form.value)
            .pipe(map((data) => data.result))
        );
        this.ref.close(questions);
      } else {
        const form = await lastValueFrom(
          this.feedbackAdminService
            .importFeedbackForm(this.form.value.file, this.form.value.formId)
            .pipe(map((data) => data.result))
        );
        this.ref.close(form);
      }
    } catch (error) {
      AppDialogService.showErrorDialog(error);
      LoaderService.showLoader(false);
    }
  }

  async findFeedbackForms(search: string): Promise<void> {
    try {
      this.forms = await lastValueFrom(
        this.feedbackService
          .findFeedbackFormsTable({
            page: 0,
            pageSize: 5,
            filters: [
              {
                condition: 'contains',
                field: 'formTitle',
                fieldType: 'text',
                value: search
              }
            ],
            sortBy: 'formTitle',
            sortDirection: 'ASC'
          })
          .pipe(map((data) => data.result.content))
      );
      this.formsAvailable = [...this.forms];
      this.formsAvailable.unshift({
        formTitle: 'Perfil de beleza',
        formId: 0
      });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findQuestions(): Promise<void> {
    try {
      this.questions[this.form.value.feedbackForm.formId] = await lastValueFrom(
        this.feedbackService
          .findFormQuestions(this.form.value.feedbackForm.formId)
          .pipe(
            map((data) => {
              return data.result.questions.concat(data.result.groups);
            })
          )
      );
      this.questions[this.form.value.feedbackForm.formId].sort(
        (q1, q2) => q1.questionOrder - q2.questionOrder
      );
      this.questionsList[this.form.value.feedbackForm.formId] = this.questions[
        this.form.value.feedbackForm.formId
      ].reduce((list, q) => {
        if ((q as FeedbackQuestion).questionId) list.push(q);
        else list = list.concat((q as FeedbackQuestionGroup).questions);
        return list;
      }, []);
      this.questionItems[this.form.value.feedbackForm.formId] = this.questions[
        this.form.value.feedbackForm.formId
      ].map((q) => {
        (q as FeedbackQuestionGroup)?.questions?.sort(
          (q1, q2) => q1.questionOrder - q2.questionOrder
        );
        return {
          label:
            q.questionOrder +
            '. ' +
            ((q as FeedbackQuestion).title ||
              (q as FeedbackQuestionGroup).feedbackGroupName) +
            ` (${getQuestionTypeName(
              (q as FeedbackQuestion).typeId ||
                (QuestionTypeEnum['Matriz de opções'] as any).value
            )})`,
          key:
            (q as FeedbackQuestion).questionId?.toString() ||
            (q as FeedbackQuestionGroup).feedbackGroupId?.toString(),
          children:
            (q as FeedbackQuestionGroup).questions?.map(
              (qq, i) =>
                ({
                  label: i + 1 + '. ' + qq.title,
                  data: qq,
                  leaf: true,
                  key: qq.questionId.toString()
                } as TreeNode<FeedbackQuestion>)
            ) || undefined,
          leaf: !(q as FeedbackQuestionGroup).questions?.length,
          data: q
        } as TreeNode<FeedbackQuestion | FeedbackQuestionGroup>;
      });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async formChange(): Promise<void> {
    this.form.controls['feedbackFormId'].setValue(
      this.form.value.feedbackForm.formId
    );
    if (!this.questions[this.form.value.feedbackForm.formId]?.length) {
      LoaderService.showLoader();
      await this.findQuestions();
      LoaderService.showLoader(false);
    }
    if (!this.questionItems[this.form.value.feedbackForm.formId]?.length) {
      this.form.controls['feedbackForm'].setErrors({
        empty: true
      });
      this.form.controls['feedbackForm'].markAsDirty();
      this.form.controls['feedbackForm'].markAsTouched();
      this.form.updateValueAndValidity();
      return;
    }
    this.form.controls['questions'].reset([]);
    this.form.controls['questions'].enable();
    this.form.controls['questionIds'].reset([]);
    this.form.controls['questionIds'].enable();
  }

  clearForm(): void {
    this.form.controls['feedbackForm'].reset();
    this.form.controls['questions'].reset([]);
    this.form.controls['questions'].disable();
    this.form.controls['questionIds'].reset([]);
    this.form.controls['questionIds'].disable();
  }

  async questionChange(): Promise<void> {
    this.form.controls['questionIds'].setValue(
      this.form.value.questions?.reduce((list: Array<number>, q) => {
        if ((q.data as FeedbackQuestion).questionId) {
          list.push((q.data as FeedbackQuestion).questionId);
        } else if (
          (q.data as FeedbackQuestionGroup).feedbackGroupId &&
          (q.data as FeedbackQuestionGroup).questions
        ) {
          list = list.concat(
            (q.data as FeedbackQuestionGroup).questions.map(
              (gq) => gq.questionId
            )
          );
        }
        return list;
      }, [])
    );
  }

  importTypeChange(): void {
    if (!this.form.value.importType) {
      this.form.controls['feedbackForm'].enable();
      this.form.controls['feedbackFormId'].enable();
      this.form.controls['questionIds'].enable();
      this.form.controls['questions'].enable();
      this.form.controls['copyOptions'].enable();
      this.form.controls['file'].disable();
    } else {
      this.form.controls['feedbackForm'].disable();
      this.form.controls['feedbackFormId'].disable();
      this.form.controls['questionIds'].disable();
      this.form.controls['questions'].disable();
      this.form.controls['copyOptions'].disable();
      this.form.controls['file'].enable();
    }
  }

  setFile($event: FileUploadHandlerEvent, fileUpload: FileUpload): void {
    this.form.controls['file'].setValue($event.files[0]);
    this.form.controls['fileName'].setValue(this.form.value.file?.name);
    fileUpload.clear();
  }
}

<div class="subscriptions-dashboard">
  <h3>Dashboard assinatura</h3>
  <div class="filters">
    <form class="form-b4a" [formGroup]="form()">
      <span class="p-float-label">
        <p-dropdown
          inputId="subscriptionId"
          [options]="subscriptions()"
          (onChange)="changeSubscription()"
          formControlName="subscriptionId"
          [autoDisplayFirst]="false"
          appendTo="body"
        >
        </p-dropdown>
        <label for="subscriptionId">Assinatura</label>
      </span>
      <span class="p-float-label">
        <p-dropdown
          inputId="editionId"
          [options]="editions()"
          *ngIf="editions()?.length"
          optionValue="editionId"
          optionLabel="theme"
          (onChange)="changeEdition()"
          [filter]="true"
          filterBy="theme,title,editionId,description"
          filterMatchMode="contains"
          [autoDisplayFirst]="false"
          formControlName="editionId"
          appendTo="body"
        >
          <ng-template pTemplate="item" let-edition>
            <ng-container *ngIf="form().value.subscriptionId">
              {{ edition.theme }}
              {{ editionDate(edition.editionId) }}
            </ng-container>
            <ng-container *ngIf="!form().value.subscriptionId">
              {{ editionDateName(edition.editionId) | titlecase }}
            </ng-container>
          </ng-template>
          <ng-template pTemplate="selectedItem">
            <ng-container *ngIf="form().value.subscriptionId">
              {{ edition?.theme }}
            </ng-container>
            <ng-container
              *ngIf="
                edition && edition.editionId && !form().value.subscriptionId
              "
            >
              {{ editionDateName(edition.editionId) | titlecase }}
            </ng-container>
          </ng-template>
        </p-dropdown>
        <label for="editionId">Ediçao</label>
      </span>
    </form>
    <div class="picture">
      <app-edition-image
        [imageURL]="edition.imageURL"
        [editionId]="edition.editionId"
        *ngIf="edition"
      ></app-edition-image>
    </div>
  </div>
  <div class="dashboard">
    <div class="right-info" *ngIf="parentGroups()?.length">
      <p-tabView>
        <p-tabPanel *ngFor="let pg of parentGroups(); let pgIndex = index">
          <ng-template pTemplate="header">
            <span>{{
              pg.name + ' ' + (pg.subscriberCount || 0 | number : '1.0-0')
            }}</span>
          </ng-template>

          <div class="groups">
            <div
              class="dashboard-card"
              *ngFor="let parent of pg.groups; let pIndex = index"
            >
              <span
                class="header"
                [class.header--success]="parent.subscriberGroupId === 2000"
                [class.header--danger]="
                  parent.subscriberGroupId === 2 ||
                  parent.subscriberGroupId === 8
                "
                [class.header--primary]="parent.subscriberGroupId === 1000"
                [class.header--warning]="
                  parent.subscriberGroupId === 1 ||
                  parent.subscriberGroupId === 12
                "
                [class.header--bag]="parent.subscriberGroupId === 4000"
                >{{ parent.name }}</span
              >
              <span class="value">{{
                parent.subscriberCount | number : '1.0-0'
              }}</span>
              <hr />
              <span class="value__group">
                <span
                  class="value__group__item"
                  [class.value__group__item--flex2]="
                    !group.subscriptions || (pIndex === 0 && gIndex === 0)
                  "
                  [class.value__group__item--flex3]="
                    !group.subscriptions ||
                    (pgIndex === 1 &&
                      pIndex === 0 &&
                      gIndex === 0 &&
                      group.groups?.length === 3)
                  "
                  *ngFor="let group of parent.groups; let gIndex = index"
                >
                  <span
                    class="value__group__item__header"
                    [class.value__group__item__header--small]="
                      group.groups?.length
                    "
                    [class.value__group__item__header--subgroup]="
                      !group.subscriptions
                    "
                  >
                    <a
                      [class.data-link]="hasChart(group)"
                      type="button"
                      (click)="hasChart(group) && showChart(group)"
                      *ngIf="group.subscriptions"
                    >
                      {{
                        subscriberGroupName(group.subscriberGroupId) ||
                          group.name
                      }}
                    </a>
                    <span class="subgroup" *ngIf="!group.subscriptions">
                      {{ group.name }}
                    </span>
                    <span class="value__group__item__value">
                      <ng-container
                        *ngIf="
                          [1, 8, 12].includes(group.subscriberGroupId) &&
                          compareToStartOfMonth(group) &&
                          group.subscriberGroupId &&
                          !futureEdition
                        "
                      >
                        <span
                          [class.diff-negative]="
                            compareToStartOfMonth(group) > 0
                          "
                          [class.diff-positive]="
                            compareToStartOfMonth(group) < 0
                          "
                          pTooltip="Início do mês {{
                            startOfMonthCount(group) | number : '1.0-0'
                          }}"
                          tooltipPosition="bottom"
                        >
                          ({{
                            compareToStartOfMonth(group) | number : '1.0-0'
                          }})
                        </span>
                      </ng-container>
                      {{ group.subscriberCount | number : '1.0-0' }}
                      <ng-container *ngIf="group.subscriberCount">
                        &nbsp;
                        <i
                          class="data-link pi pi-download"
                          (click)="findSubscribersAndExport(group)"
                          pTooltip="Exportar assinantes"
                          tooltipPosition="bottom"
                          *ngIf="group.subscriberGroupId"
                        ></i>
                      </ng-container>
                    </span>
                  </span>
                  <hr />
                  <span class="value__group" *ngIf="group.groups">
                    <span
                      class="value__group__item value__group__item--fit"
                      *ngFor="let subgroup of group.groups; let sIndex = index"
                    >
                      <span
                        class="value__group__item__header value__group__item__header--small"
                      >
                        <a
                          type="button"
                          [class.data-link]="hasChart(subgroup)"
                          (click)="hasChart(subgroup) && showChart(subgroup)"
                        >
                          {{
                            subscriberGroupName(subgroup.subscriberGroupId) ||
                              subgroup.name
                          }}
                        </a>
                        <span class="value__group__item__value">
                          {{ subgroup.subscriberCount | number : '1.0-0' }}
                          <ng-container *ngIf="subgroup.subscriberCount">
                            &nbsp;
                            <i
                              class="data-link pi pi-download"
                              (click)="findSubscribersAndExport(subgroup)"
                              pTooltip="Exportar assinantes"
                              tooltipPosition="bottom"
                            ></i>
                          </ng-container>
                        </span>
                      </span>
                      <hr />
                      <span class="value__group__item__table">
                        <table>
                          <tr
                            *ngFor="
                              let sub of subgroup.subscriptions;
                              let i = index
                            "
                            [class.even]="i % 2 === 0"
                          >
                            <td
                              *ngIf="isMobile || (gIndex === 0 && sIndex === 0)"
                            >
                              <span
                                [class.box]="
                                  sub?.subscriptionName?.toUpperCase() ===
                                  'GLAMBOX'
                                "
                                [class.bag]="
                                  sub?.subscriptionName?.toUpperCase() ===
                                  'GLAMBAG'
                                "
                                [class.pass]="
                                  sub?.subscriptionName?.toUpperCase() ===
                                  'GLAMPASS'
                                "
                                [class.primary]="
                                  sub?.subscriptionName?.toUpperCase() ===
                                  'GLAMCOMBO'
                                "
                              >
                                {{ subscriptionFullName(sub) }}
                              </span>
                            </td>
                            <td class="right">
                              {{ sub.subscriberCount | number : '1.0-0' }}
                            </td>
                          </tr>
                        </table>
                      </span>
                    </span>
                  </span>
                  <span
                    class="value__group"
                    *ngIf="
                      !group.groups?.length &&
                      group.subscriptions?.length &&
                      pgIndex === 0
                    "
                  >
                    <span class="value__group__item">
                      <span
                        class="value__group__item__header value__group__item__header--small"
                      ></span>
                      <hr />
                    </span>
                  </span>
                  <ng-container *ngIf="group.subscriptions">
                    <span class="value__group__item__table">
                      <table>
                        <tr
                          *ngFor="let sub of group.subscriptions; let i = index"
                          [class.even]="i % 2 === 0"
                        >
                          <td
                            *ngIf="
                              isMobile ||
                              (isTablet && gIndex === 0) ||
                              (pIndex === 0 && gIndex === 0)
                            "
                          >
                            <span
                              [class.box]="
                                sub?.subscriptionName?.toUpperCase() ===
                                'GLAMBOX'
                              "
                              [class.bag]="
                                sub?.subscriptionName?.toUpperCase() ===
                                'GLAMBAG'
                              "
                              [class.pass]="
                                sub?.subscriptionName?.toUpperCase() ===
                                'GLAMPASS'
                              "
                              [class.primary]="
                                sub?.subscriptionName?.toUpperCase() ===
                                'GLAMCOMBO'
                              "
                            >
                              {{ subscriptionFullName(sub) }}
                            </span>
                          </td>
                          <td class="right">
                            <ng-container
                              *ngIf="
                                [8, 12].includes(parent.subscriberGroupId) &&
                                sub.subscriberGroupId &&
                                compareToStartOfMonth(sub) &&
                                !futureEdition
                              "
                            >
                              <span
                                [class.diff-negative]="
                                  compareToStartOfMonth(sub) > 0
                                "
                                [class.diff-positive]="
                                  compareToStartOfMonth(sub) < 0
                                "
                                pTooltip="Início do mês {{
                                  startOfMonthCount(sub) | number : '1.0-0'
                                }}"
                                tooltipPosition="bottom"
                              >
                                ({{
                                  compareToStartOfMonth(sub) | number : '1.0-0'
                                }})
                              </span>
                            </ng-container>
                            {{ sub.subscriberCount | number : '1.0-0' }}
                          </td>
                        </tr>
                      </table>
                    </span>
                  </ng-container>
                </span>
              </span>
            </div>
          </div>
        </p-tabPanel>
      </p-tabView>
      <!-- </div> -->
    </div>
  </div>
</div>

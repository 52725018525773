import { DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CheckoutTypes, Coupon } from '@infrab4a/connect';
import { ConfirmationService, PrimeIcons } from 'primeng/api';
import {
  TableActionButton,
  TableColumn,
  TableComponent
} from 'src/app/components/table';
import { ShopCouponService } from 'src/app/connect-api/api/shop/shop-coupon.service';
import { ShopAvailability, getAllShopAvailabilities } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-shop-coupons',
  templateUrl: './shop-coupons.component.html',
  styleUrl: './shop-coupons.component.scss',
  providers: [DatePipe]
})
export class ShopCouponsComponent {
  @ViewChild(TableComponent) table: TableComponent;
  protected cols = [
    new TableColumn('', '', false, 'checkbox'),
    new TableColumn(
      'Nome',
      'nickname',
      true,
      'text',
      '',
      'id',
      true,
      'contains'
    ),
    new TableColumn.Builder()
      .setHeader('Expira em')
      .setField('expiresIn')
      .setType('date')
      .setCondition('gte')
      .setDisplayFunction((coupon: Partial<Coupon>) =>
        coupon?.expiresIn &&
        coupon.expiresIn instanceof Date &&
        !isNaN(coupon.expiresIn as any)
          ? this.datePipe.transform(coupon.expiresIn, 'dd/MM/yyyy')
          : null
      )
      .build(),
    new TableColumn.Builder()
      .setHeader('Universo')
      .setField('shopAvailability')
      .setDisplayFunction(
        (coupon: Partial<Coupon>) => ShopAvailability[coupon.shopAvailability]
      )
      .build(),
    new TableColumn.Builder()
      .setHeader('Checkout')
      .setField('checkoutType')
      .setDisplayFunction((coupon: Partial<Coupon>) =>
        this.checkoutTypeName(coupon.checkoutType)
      )
      .build(),
    new TableColumn.Builder()
      .setHeader('Categoria')
      .setField('category')
      .build(),
    new TableColumn(
      'Ativo',
      'active',
      true,
      'inputSwitch',
      undefined,
      undefined,
      true,
      'equals'
    ),
    new TableColumn('Ação', '', false, 'button')
  ];
  protected actionButtons = [
    new TableActionButton(
      '',
      'Excluir',
      PrimeIcons.TRASH,
      undefined,
      '',
      'Excluir',
      'bottom',
      true,
      true,
      'danger',
      'small'
    )
  ];
  modalColumns = [
    new TableColumn(
      'Nome',
      'nickname',
      true,
      'text',
      '',
      'id',
      true,
      'contains'
    ),
    new TableColumn(
      'Expira em',
      'expiresIn',
      true,
      'date',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Disponibilidade',
      'shopAvailability',
      true,
      'text',
      undefined,
      undefined,
      true,
      'equals'
    ),
    new TableColumn(
      'Ativo',
      'active',
      true,
      'boolean',
      undefined,
      undefined,
      true,
      'equals'
    )
  ];
  dropdownFilters = {
    shopAvailability: getAllShopAvailabilities().concat({
      value: undefined,
      label: `Tudo`
    }),
    checkoutType: [
      { label: 'Todos', value: undefined },
      { label: 'Loja + Clube', value: CheckoutTypes.ALL },
      { label: 'Clube da Mens', value: CheckoutTypes.SUBSCRIPTION },
      { label: 'Lojas', value: CheckoutTypes.ECOMMERCE }
    ],
    active: [
      { label: 'Todos', value: undefined },
      { label: 'Ativo', value: true },
      { label: 'Inativo', value: false }
    ],
    category: [
      { value: 'Reembolso', label: `Reembolso` },
      { value: 'Desculpas', label: `Desculpas` },
      { value: 'Glampartner', label: `Glampartner` },
      { value: 'Glamqueens', label: `Glamqueens` },
      { value: 'BFlu', label: `BFlu` },
      { value: 'Royal', label: `Royal` },
      { value: 'Glamgirls', label: `Glamgirls` },
      { value: 'Glamdiva', label: `Glamdiva` },
      { value: 'Impulsionamento', label: `Impulsionamento` },
      { value: 'Paid Media', label: `Paid Media` },
      { value: 'Organic', label: `Organic` },
      { value: 'Direct', label: `Direct` },
      { value: 'CRM', label: `CRM` },
      { value: 'LOJAUNICO', label: `Loja único` },
      { value: 'Outros', label: `Outros` },
      { value: undefined, label: 'Todas' }
    ]
  };
  selection: { id: string }[] = [];
  showSelectionModal = false;
  constructor(
    protected shopCouponService: ShopCouponService,
    title: Title,
    private confirmationService: ConfirmationService,
    private datePipe: DatePipe
  ) {
    title.setTitle('Loja - Cupons');
  }

  async buttonClick(event: { item: { id: string }; $event: Event }) {
    this.confirmationService.confirm({
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      message: 'Tem certeza que deseja excluir o cupom?',
      target: event.$event?.target,
      acceptButtonStyleClass: 'p-button-danger',
      accept: async () => {
        LoaderService.showLoader(true);
        try {
          await this.shopCouponService.deleteCoupon(event.item.id);
        } catch (error) {
          AppDialogService.showErrorDialog(error);
        } finally {
          await this.table.refresh();
          LoaderService.showLoader(false);
        }
      }
    });
  }

  handleSelection(coupons: { id: string }[]) {
    this.selection = coupons;
    this.showSelectionModal = true;
  }

  async updateActive(event: Partial<Coupon>) {
    LoaderService.showLoader(true);
    try {
      await this.shopCouponService.updateCoupon(event);
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      await this.table.refresh();
      LoaderService.showLoader(false);
    }
  }

  async deleteMany(coupons: { id: string }[]) {
    LoaderService.showLoader(true);
    try {
      await Promise.all(
        coupons.map((coupon) => this.shopCouponService.deleteCoupon(coupon.id))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      this.selection = [];
      this.showSelectionModal = false;
      await this.table.refresh();
      LoaderService.showLoader(false);
    }
  }

  checkoutTypeName(id: CheckoutTypes): string {
    switch (id) {
      case CheckoutTypes.ECOMMERCE:
        return 'Lojas';
      case CheckoutTypes.SUBSCRIPTION:
        return 'Clube da Mens';
      default:
        return 'Loja + Clube';
    }
  }
}

/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AcquisitionAndReactivationReprocess {
  nome?: string;
  email?: string;
  phone?: string;
  personId?: number;
  subscriberId?: number;
  nameType?: string;
  dateCreated?: Date;
  tid?: string;
  amount?: number;
  authorizationMessage?: string;
  transactionStatusId?: number;
  subscriptionName?: string;
  plano?: string;
  creditCardPaymentId?: number;
}

import { Clipboard } from '@angular/cdk/clipboard';
import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import { ItOpsControllerService, WrongRecurrenceDate } from 'src/app/admin-api';
import { SubscriberProblemGroupEnum } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-wrong-recurrence-date-modal',
  templateUrl: './wrong-recurrence-date-modal.component.html',
  styleUrls: ['./wrong-recurrence-date-modal.component.scss'],
  providers: [MessageService]
})
export class WrongRecurrenceDateModalComponent {
  recurrences: Array<WrongRecurrenceDate>;
  wrongUpdate: string;
  recurrenceUpdate: string;
  inputTextarea: string;

  constructor(
    private config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private itOpsService: ItOpsControllerService,
    private clipboard: Clipboard,
    private messageService: MessageService
  ) {
    this.recurrences = this.config.data.recurrences;
    this.recurrenceUpdate = this.recurrences?.reduce((sql, recurrences) => {
      if (recurrences.editionPending && recurrences.creditCardId)
        sql += `UPDATE [Glambox.Subscription].dbo.Subscriber set NextAttemptDate = NULL, MaxAttempts = 5, Attempt = 0, NextPaymentDate = '${`${Number(
          ((recurrences.editionPending % 1000000) / 100).toFixed(0)
        )}-${(recurrences.editionPending % 100)
          .toString()
          .padStart(2, '0')}-02 12:00:00.000'`} where CreditCardId = ${
          recurrences.creditCardId
        };\n`;
      return sql;
    }, '');
  }

  async markAsResolved(): Promise<void> {
    LoaderService.showLoader();
    try {
      const dashboard = await lastValueFrom(
        this.itOpsService
          .markAsResolved(
            SubscriberProblemGroupEnum.WrongRecurrenceDate,
            this.recurrences
          )
          .pipe(map((data) => data.result))
      );
      this.ref.close(dashboard);
    } catch (error) {
      LoaderService.showLoader(false);
      AppDialogService.showErrorDialog(error);
    }
  }

  copyToClipboard(text: string): void {
    this.clipboard.copy(text);
    this.messageService.add({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Copiado para área de transferência'
    });
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { lastValueFrom, map } from 'rxjs';
import {
  CustomQueryControllerService,
  CustomQueryDetail,
  CustomQueryGroup,
  PageableRequest
} from 'src/app/admin-api';
import {
  DropdownFilter,
  PageContent,
  TableActionButton,
  TableColumn,
  TableComponent
} from 'src/app/components/table';
import { CustomQueryService } from 'src/app/services/custom-query.service';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-custom-query',
  templateUrl: './custom-query.component.html',
  styleUrls: ['./custom-query.component.scss'],
  providers: [MessageService]
})
export class CustomQueryComponent implements OnInit {
  @ViewChild(TableComponent)
  table: TableComponent | undefined;

  cols: Array<TableColumn> = [
    new TableColumn(
      'Id',
      'customQueryId',
      true,
      'number',
      '/research/custom-query/form/',
      'customQueryId'
    ),
    new TableColumn(
      'Nome',
      'queryName',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Última execução',
      'dateLastExecution',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    ),
    new TableColumn(
      'Execução (ms)',
      'lastExecutionMilliseconds',
      true,
      'formattedInteger',
      undefined,
      undefined,
      true,
      'lte'
    ),
    new TableColumn(
      'Grupo',
      'groupName',
      true,
      'text',
      undefined,
      undefined,
      true,
      'equals'
    ),
    new TableColumn('Ativo', 'isActive', true, 'inputSwitch'),
    new TableColumn('Ações', '', false, 'button')
  ];
  actionButtons: Array<TableActionButton> = [
    new TableActionButton(
      'csv',
      'exportCSV',
      undefined,
      () => true,
      '',
      'Exportar CSV',
      'bottom',
      true,
      false,
      'primary',
      'small'
    ),
    new TableActionButton(
      'xls',
      'exportExcel',
      undefined,
      () => true,
      '',
      'Exportar XLS',
      'bottom',
      true,
      false,
      'success',
      'small'
    ),
    new TableActionButton(
      'html',
      'exportHTML',
      undefined,
      () => true,
      '',
      'Abrir em uma página',
      'bottom',
      true,
      false,
      'danger',
      'small'
    ),
    new TableActionButton(
      'xml',
      'exportXML',
      undefined,
      () => true,
      '',
      'Exportar XML',
      'bottom',
      true,
      false,
      'secondary',
      'small'
    )
  ];
  dropdownFilters: { [key: string]: Array<DropdownFilter> } = {
    isActive: [
      { label: 'Todos', value: '' },
      { label: 'Sim', value: '1' },
      { label: 'Não', value: '0' }
    ]
  };
  defaultFilterValues: { [field: string]: any | Array<any> } = {
    isActive: '1'
  };
  customQueryGroups: Array<CustomQueryGroup> | undefined;
  constructor(
    public customQueryService: CustomQueryControllerService,
    private title: Title,
    private messageService: MessageService,
    private customQueryExecuteService: CustomQueryService
  ) {
    this.title.setTitle('Consultas personalizadas');
  }

  async ngOnInit(): Promise<void> {
    this.customQueryGroups = await lastValueFrom(
      this.customQueryService
        .findCustomQueryGroups()
        .pipe(map((data) => data.result))
    );
    this.dropdownFilters['groupName'] = [{ label: 'Todos', value: '0' }].concat(
      this.customQueryGroups.map((g) => ({
        label: g.groupName,
        value: g.customQueryGroupId.toString()
      }))
    );
  }

  async findPage(
    request: PageableRequest,
    service: unknown
  ): Promise<PageContent<CustomQueryDetail>> {
    const groupFilter = request.filters?.find((f) => f.field === 'groupName');
    if (groupFilter?.value) {
      if (Number(groupFilter.value) <= 0) {
        groupFilter.value = '';
      }
      request.filters?.push({
        condition: 'equals',
        field: 'customQueryGroupId',
        fieldType: 'number',
        value: groupFilter.value
      });
    }

    return lastValueFrom(
      (service as CustomQueryControllerService)
        .findCustomQueryList(request)
        .pipe(map((data) => data.result))
    );
  }

  async export(
    action: 'exportCSV' | 'exportExcel' | 'exportHTML' | 'exportXML',
    query: CustomQueryDetail
  ): Promise<void> {
    await this.customQueryExecuteService.export(action, query);
  }

  async updateQuery(query: CustomQueryDetail): Promise<void> {
    LoaderService.showLoader();
    try {
      await lastValueFrom(
        this.customQueryService.updateCustomQuery(query.customQueryId, query)
      );
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso',
        detail: query.isActive ? 'Query ativa' : 'Query inativa'
      });
      await this.table.pageTable();
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }
}

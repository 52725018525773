<form
  class="form-b4a coupon-form"
  [class.editable]="model"
  [formGroup]="form"
  *ngIf="form"
  (submit)="submit()"
>
  <span class="p-float-label">
    <input
      type="text"
      (keyup)="upperCase(form.controls['name'])"
      pInputText
      formControlName="name"
      id="name"
    />
    <label for="name">Nome do cupom *</label>
    <small
      class="error"
      *ngIf="
        form.controls['name'].dirty &&
        form.controls['name'].errors &&
        form.controls['name'].errors['maxlength']
      "
    >
      Máximo de
      {{ form.controls['name'].errors['maxlength']['requiredLength'] }}
      caracteres
    </small>
  </span>
  <span class="input-switch">
    <label for="enabled">Inativo</label>
    <p-inputSwitch formControlName="enabled" inputId="enabled" />
    <label for="enabled">Ativo</label>
  </span>
  <span class="p-float-label">
    <p-dropdown
      formControlName="couponType"
      inputId="couponType"
      [options]="couponTypes"
      appendTo="body"
      [autoDisplayFirst]="false"
      (onChange)="onCouponTypeChange()"
    />
    <label for="couponType">Tipo do cupom *</label>
  </span>
  <span class="p-float-label">
    <p-inputNumber
      *ngIf="form.value.couponType === 0"
      [min]="0"
      [max]="1"
      inputId="value"
      formControlName="value"
      [minFractionDigits]="2"
      [maxFractionDigits]="3"
    />
    <p-inputNumber
      *ngIf="form.value.couponType === 1"
      [minFractionDigits]="2"
      [maxFractionDigits]="2"
      prefix="R$"
      [min]="0"
      inputId="value"
      formControlName="value"
    />
    <label for="value">Valor *</label>
    <small
      class="error"
      *ngIf="
        form.controls['value'].dirty &&
        form.controls['value'].errors &&
        form.controls['value'].errors['max']
      "
    >
      Valor máximo {{ form.controls['value'].errors['max']['max'] }}
    </small>
    <small
      class="error"
      *ngIf="
        form.controls['value'].dirty &&
        form.controls['value'].errors &&
        form.controls['value'].errors['min']
      "
    >
      Valor mínimo {{ form.controls['value'].errors['min']['min'] }}
    </small>
    <small *ngIf="form.value.couponType === 0">{{ percentageSuffix }}</small>
  </span>
  <span class="p-float-label">
    <p-inputNumber
      [min]="0"
      formControlName="glampoints"
      inputId="glampoints"
    />
    <label for="glampoints">Glampoints extras *</label>
  </span>
  <span class="p-float-label">
    <p-inputNumber
      [min]="0"
      formControlName="maxRedeems"
      inputId="maxRedeems"
    />
    <label for="maxRedeems">Máximo de uso no total *</label>
    <small>(0 para ilimitado)</small>
  </span>
  <span class="p-float-label">
    <p-calendar
      formControlName="dateExpired"
      (onFocus)="clearDateExpired()"
      inputId="dateExpired"
      dateFormat="dd/mm/yy"
      appendTo="body"
    />
    <label for="dateExpired">Data de expiração *</label>
    <small
      class="error"
      *ngIf="
        form.controls['dateExpired'].errors &&
        form.controls['dateExpired'].errors['invalidDate']
      "
    >
      {{ form.controls['dateExpired'].errors['invalidDate'] }}
    </small>
    <small>
      Lembre-se sempre de programar mais 3 dias após o término da campanha
    </small>
  </span>
  <span class="p-float-label" *ngIf="couponUsageTypes">
    <p-dropdown
      formControlName="couponUsageTypeId"
      inputId="couponUsageTypeId"
      [options]="couponUsageTypes"
      appendTo="body"
      optionLabel="description"
      optionValue="couponUsageTypeId"
      appendTo="body"
      [autoDisplayFirst]="false"
    />
    <label for="couponUsageTypeId">Categoria</label>
  </span>

  <span class="p-float-label" *ngIf="influencerStatusList">
    <p-dropdown
      formControlName="influencerStatusTypeIdRestriction"
      inputId="influencerStatusTypeIdRestriction"
      [options]="influencerStatusList"
      appendTo="body"
      optionLabel="influencerStatus"
      optionValue="influencerStatusId"
      [autoDisplayFirst]="false"
      appendTo="body"
    />
    <label for="influencerStatusTypeIdRestriction">Restrição Glampartner</label>
  </span>
  <span class="p-float-label">
    <p-multiSelect
      *ngIf="subscriptionTypesTree?.length"
      formControlName="subscriptionTypeIds"
      inputId="subscriptionTypes"
      [options]="subscriptionTypesTree"
      appendTo="body"
      scrollHeight="250px"
      display="chip"
      (onPanelHide)="validateRestrictions()"
      placeholder=" "
      [group]="true"
    >
      <ng-template let-group pTemplate="group">
        <span>{{ group.label }}</span>
      </ng-template>
      <ng-template let-item pTemplate="item">
        <span>{{ item.label }}</span>
      </ng-template>
      <ng-template let-value pTemplate="selectedItems">
        <span></span>
        <span *ngFor="let item of value">{{ subscriptionLabel(item) }}, </span>
        <span *ngIf="!value?.length">Sem restrições</span>
      </ng-template>
    </p-multiSelect>
    <label for="subscriptionTypes">Restrição de assinatura e plano</label>
    <small *ngIf="!form.value.subscriptionTypeIds?.length"
      >* O cupom será aceito em todas as Assinaturas e Planos</small
    >
  </span>
  <span class="p-float-label full-w">
    <input type="text" pInputText formControlName="freebieMessage" />
    <small
      class="error"
      *ngIf="
        form.controls['freebieMessage'].dirty &&
        form.controls['freebieMessage'].errors &&
        form.controls['freebieMessage'].errors['maxlength']
      "
    >
      Máximo de
      {{
        form.controls['freebieMessage'].errors['maxlength']['requiredLength']
      }}
      caracteres
    </small>
    <label for="freebieMessage">Mensagem do cupom *</label>
  </span>
  <span class="form-group">
    <span class="p-input-checkbox">
      <p-checkbox
        formControlName="isRenewal"
        inputId="isRenewal"
        label="Cupom de renovação?"
        [binary]="true"
        [trueValue]="1"
        [falseValue]="0"
      />
    </span>
    <span class="p-input-checkbox">
      <p-checkbox
        formControlName="freeShipping"
        inputId="freeShipping"
        label="Frete grátis?"
        [binary]="true"
        [trueValue]="1"
        [falseValue]="0"
      />
    </span>
  </span>
  <small *ngIf="model?.dateUpdated">
    *Última alteração em:
    {{ model.dateUpdated | date : 'dd/MM/yyyy HH:mm:ss' }}
  </small>
  <div class="buttons">
    <p-button label="Salvar" type="submit" [disabled]="!form.valid" />
  </div>
</form>
<p-toast />
<p-confirmDialog [closable]="false" />

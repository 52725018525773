import { CurrencyPipe, DatePipe, TitleCasePipe } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { lastValueFrom, map, Observable } from 'rxjs';
import {
  CouponControllerService,
  CouponUsageSummary,
  CouponUsageType,
  CouponUsageTypeByCouponName,
  KpiControllerService
} from 'src/app/admin-api';
import {
  EditionId,
  SubscriberGroup,
  SubscriberGroupEnum,
  SubscriberSummary
} from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FileUtil } from 'src/app/utils/file.util';
import { FormUtil } from 'src/app/utils/form.util';
import { CouponsRankingChartDialogComponent } from '../coupons-ranking-chart/coupons-ranking-chart-dialog/coupons-ranking-chart-dialog.component';

@Component({
  selector: 'app-coupons-usage-summary-report',
  templateUrl: './coupons-usage-summary-report.component.html',
  styleUrl: './coupons-usage-summary-report.component.scss'
})
export class CouponsUsageSummaryReportComponent implements OnInit {
  @Input()
  coupons: Array<CouponUsageSummary>;

  @Input()
  couponUsageTypes: Array<CouponUsageType>;

  @Input()
  editionId = EditionId.currentEdition(0);

  private kpiService = inject(KpiControllerService);
  private datePipe = inject(DatePipe);
  private currencyPipe = inject(CurrencyPipe);
  private titlePipe = inject(TitleCasePipe);
  private couponService = inject(CouponControllerService);
  private dialog = inject(DialogService);

  parentGroups: Array<SubscriberGroup>;
  ready = false;

  usages: { [couponUsageTypeId: number]: Array<CouponUsageTypeByCouponName> } =
    {};

  ngOnInit(): void {
    if (!this.coupons || !this.couponUsageTypes)
      AppDialogService.showErrorDialog(
        {},
        false,
        'Prease provide "coupons" and "couponUsageTypes"'
      );
    this.couponUsageTypes = [...this.couponUsageTypes].concat([
      {
        couponUsageTypeId: -1,
        couponUsageType: 'Sem cupom'
      }
    ]);
    this.coupons = this.coupons.filter((c) => this.sameEdition(c.editionId));
    this.parentGroups = [
      {
        name: 'Canal',
        groups: [
          {
            name: 'Novas',
            id: SubscriberGroupEnum.Conquistadas,
            groups: [
              {
                name: 'Todas',
                id: SubscriberGroupEnum.Conquistadas,
                groups: this.couponUsageTypes.map((ct) => {
                  return {
                    name: ct.couponUsageType,
                    subscriberCount: this.coupons
                      ?.filter(
                        (a) => a.couponUsageTypeId === ct.couponUsageTypeId
                      )
                      .reduce((sum, c) => (sum += c.total || 0), 0),
                    id: ct.couponUsageTypeId
                  };
                })
              },
              {
                name: 'Aquisição',
                id: SubscriberGroupEnum.Conquistadas_com_pagamento,
                groups: this.couponUsageTypes.map((ct) => {
                  return {
                    name: ct.couponUsageType,
                    subscriberCount: this.coupons
                      ?.filter(
                        (a) =>
                          a.couponUsageTypeId === ct.couponUsageTypeId &&
                          a.subscriberGroupId ===
                            SubscriberGroupEnum.Conquistadas_com_pagamento
                      )
                      .reduce((sum, c) => (sum += c.total || 0), 0),
                    id: ct.couponUsageTypeId
                  };
                })
              },
              {
                name: 'Reativação',
                id: SubscriberGroupEnum.Reativacoes_com_pagamento,
                groups: this.couponUsageTypes.map((ct) => {
                  return {
                    name: ct.couponUsageType,
                    subscriberCount: this.coupons
                      ?.filter(
                        (a) =>
                          a.couponUsageTypeId === ct.couponUsageTypeId &&
                          a.subscriberGroupId ===
                            SubscriberGroupEnum.Reativacoes_com_pagamento
                      )
                      .reduce((sum, c) => (sum += c.total || 0), 0),
                    id: ct.couponUsageTypeId
                  };
                })
              }
            ]
          }
        ]
      }
    ];
    this.parentGroups.forEach((pg) => {
      pg.groups.forEach((g) => {
        g.groups.forEach((sg) => {
          sg.subscriberCount = this.totalCount(sg.id);
          sg.groups.forEach((cg) => {
            cg.totalPercentage = this.totalPercentage(cg, sg);
          });
          sg.groups.sort((c1, c2) => {
            if (c1.id === -1) return 1;
            if (c2.id === -1) return -1;
            if (c1.name > c2.name) {
              return 1;
            } else if (c1.name < c2.name) {
              return -1;
            }
            return c1.id - c2.id;
          });
        });
        g.subscriberCount = this.groupTotal(g);
      });
      pg.subscriberCount = this.groupTotal(pg);
    });
    this.ready = true;
  }

  totalPercentage(group: SubscriberGroup, parent: SubscriberGroup): number {
    return 100 * (group.subscriberCount / (this.groupTotal(parent) || 1));
  }

  groupTotal(group: SubscriberGroup): number {
    return (
      group.groups
        ?.filter((g) => g.id !== SubscriberGroupEnum.Conquistadas)
        .reduce((sum, g) => (sum += g.subscriberCount || 0), 0) ||
      group.subscriberCount ||
      0
    );
  }

  totalCount(subscriberGroupId: number): number {
    return (
      this.coupons
        .filter(
          (a) =>
            subscriberGroupId === SubscriberGroupEnum.Conquistadas ||
            a.subscriberGroupId === subscriberGroupId
        )
        .reduce((sum, a) => (sum += a.total || 0), 0) || 0
    );
  }

  onlyCoupons(subscriberGroupId?: number): number {
    return this.coupons
      .filter(
        (c) =>
          c.couponUsageTypeId >= 0 &&
          (subscriberGroupId === SubscriberGroupEnum.Conquistadas ||
            c.subscriberGroupId === subscriberGroupId)
      )
      .reduce((sum, c) => (sum += c.total || 0), 0);
  }

  sameEdition(editionId: number): boolean {
    if (this.editionId > 1000000) {
      return this.editionId === editionId;
    }
    return this.editionId === Number(editionId.toString().substring(1));
  }

  async showCouponUsageTypeDetails(
    couponUsageTypeId: number,
    tabIndex = 0
  ): Promise<void> {
    LoaderService.showLoader();
    if (!this.usages[couponUsageTypeId])
      this.usages[couponUsageTypeId] = await lastValueFrom(
        this.couponService
          .findCouponUsageDetailsByEdition(couponUsageTypeId, this.editionId)
          .pipe(
            map((data) =>
              data.result.map((c) => ({
                ...c,
                dateCreated: FormUtil.utcDate(c.dateCreated)
              }))
            )
          )
      );
    const couponUsageType = this.couponUsageTypes?.find(
      (c) => c.couponUsageTypeId === couponUsageTypeId
    );
    this.dialog.open(CouponsRankingChartDialogComponent, {
      header:
        (couponUsageType?.couponUsageType || couponUsageTypeId) +
        ' - Ranking dos cupons',
      data: {
        couponUsages: this.usages[couponUsageTypeId],
        couponUsageType,
        tabIndex
      },
      width: '90%',
      height: '90vh',
      maximizable: true,
      closable: true
    });
  }

  get currentEdition(): boolean {
    return EditionId.isAfterOrEqual(
      this.editionId,
      EditionId.currentEdition(Number((this.editionId / 1000000).toFixed(0)))
    );
  }

  async findSubscribersAndExport(group: SubscriberGroup): Promise<void> {
    LoaderService.showLoader();
    try {
      let data: Array<SubscriberSummary> = [];
      let observables: Observable<any[]>[] = [];
      if (
        this.currentEdition &&
        [
          SubscriberGroupEnum.Conquistadas_com_pagamento,
          SubscriberGroupEnum.Conquistadas
        ].includes(group.id)
      )
        observables.push(
          this.kpiService.findAcquisitionsByEditionId(this.editionId).pipe(
            map((data) =>
              data.result?.map((c) => {
                return {
                  BoxId: c.boxId,
                  Nome: c.name,
                  Telefone: c.phone,
                  CPF: FormUtil.cpfFormatted(c.cpf as number),
                  'E-mail': c.email,
                  Assinatura: this.titlePipe.transform(c.subscriptionName),
                  Plano: c.subscriptionTypeName,
                  Joia: c.badge,
                  Status: c.subscriberStatus,
                  Edição: c.theme,
                  Produto: this.titlePipe.transform(c.subscriberEditionName),
                  Cupom: c.couponName,
                  Composição: c.compositionName,
                  'Status edição': c.subscriberEditionStatusName,
                  'Status Millennium': c.statusMillennium,
                  Total: this.currencyPipe.transform(c.amount, 'BRL'),
                  'Data Pagamento': this.datePipe.transform(
                    FormUtil.utcDate(c.dateRenewed),
                    'dd/MM/yyyy HH:mm:ss'
                  ),
                  'Link Admin':
                    window.location.origin +
                    '/users/subscribers/' +
                    c.subscriberId
                } as SubscriberSummary;
              })
            )
          )
        );
      if (
        this.currentEdition &&
        [
          SubscriberGroupEnum.Reativacoes_com_pagamento,
          SubscriberGroupEnum.Conquistadas
        ].includes(group.id)
      )
        observables.push(
          this.kpiService.findReactivationsByEditionId(this.editionId).pipe(
            map((data) =>
              data.result?.map((c) => {
                return {
                  BoxId: c.boxId,
                  Nome: c.name,
                  Telefone: c.phone,
                  CPF: FormUtil.cpfFormatted(c.cpf as number),
                  'E-mail': c.email,
                  Assinatura: this.titlePipe.transform(c.subscriptionName),
                  Plano: c.subscriptionTypeName,
                  Joia: c.badge,
                  Status: c.subscriberStatus,
                  Edição: c.theme,
                  Produto: this.titlePipe.transform(c.subscriberEditionName),
                  Cupom: c.couponName,
                  Composição: c.compositionName,
                  'Status edição': c.subscriberEditionStatusName,
                  'Status Millennium': c.statusMillennium,
                  Total: this.currencyPipe.transform(c.amount, 'BRL'),
                  'Data Pagamento': this.datePipe.transform(
                    FormUtil.utcDate(c.dateRenewed),
                    'dd/MM/yyyy HH:mm:ss'
                  ),
                  'Link Admin':
                    window.location.origin +
                    '/users/subscribers/' +
                    c.subscriberId
                } as SubscriberSummary;
              })
            )
          )
        );
      if (!this.currentEdition) {
        let groups = [];
        if (group.id === SubscriberGroupEnum.Conquistadas)
          groups = [
            SubscriberGroupEnum.Conquistadas_com_pagamento,
            SubscriberGroupEnum.Reativacoes_com_pagamento
          ];
        else groups = [group.id];
        observables = observables.concat(
          groups.map((g) =>
            this.kpiService
              .findSubscriberByEditionIdAndSubscriberGroupId(this.editionId, g)
              .pipe(
                map((data) =>
                  data.result?.map((c) => {
                    return {
                      BoxId: c.boxId,
                      Nome: c.name,
                      Telefone: c.phone,
                      CPF: FormUtil.cpfFormatted(c.cpf as number),
                      'E-mail': c.email,
                      Assinatura: this.titlePipe.transform(c.subscriptionName),
                      Plano: c.subscriptionTypeName,
                      Joia: c.badge,
                      Status: c.subscriberStatus,
                      Edição: c.theme,
                      Produto: this.titlePipe.transform(
                        c.subscriberEditionName
                      ),
                      Cupom: c.couponName,
                      Composição: c.compositionName,
                      'Status edição': c.subscriberEditionStatusName,
                      'Status Millennium': c.statusMillennium,
                      Total: this.currencyPipe.transform(c.amount, 'BRL'),
                      'Data Pagamento': this.datePipe.transform(
                        FormUtil.utcDate(c.dateRenewed),
                        'dd/MM/yyyy HH:mm:ss'
                      ),
                      'Link Admin':
                        window.location.origin +
                        '/users/subscribers/' +
                        c.subscriberId
                    } as SubscriberSummary;
                  })
                )
              )
          )
        );
      }
      if (observables?.length)
        data =
          (await Promise.all(observables.map((o) => lastValueFrom(o))))?.reduce(
            (list, r) => (list = list.concat(r)),
            []
          ) || [];
      if (data?.length) {
        FileUtil.exportExcel(
          data,
          `subscribers_${this.editionId}_${FormUtil.semAcento(group.name)}`,
          Object.keys(data[0])
        );
        LoaderService.showLoader(false);
      } else {
        AppDialogService.showErrorDialog({
          message: 'Sem dados para exportar.'
        });
      }
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  hasDetails(group: SubscriberGroup): boolean {
    return group.id >= 0 && group.subscriberCount > 0;
  }
}

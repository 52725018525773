import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BatchUpdateComponent } from './batch-update/batch-update.component';
import { BrandDetailComponent } from './brands/brand-detail/brand-detail.component';
import { BrandsComponent } from './brands/brands.component';
import { CategoriesComponent } from './categories/categories.component';
import { CategoryDetailComponent } from './categories/category-detail/category-detail.component';
import { GlamclubComponent } from './glamclub/glamclub.component';
import { MovementsComponent } from './movements/movements.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { ProductsComponent } from './products.component';
import { ReviewsComponent } from './reviews/reviews.component';
import { StockComponent } from './stock/stock.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'catalog'
  },
  {
    path: 'catalog',
    component: ProductsComponent
  },
  {
    path: 'catalog/product',
    component: ProductDetailsComponent
  },
  {
    path: 'catalog/product/:productId',
    component: ProductDetailsComponent
  },
  {
    path: 'catalog/product-variant/:productVariantId',
    component: ProductDetailsComponent
  },
  {
    path: 'brands',
    component: BrandsComponent
  },
  {
    path: 'brands/:brandId',
    component: BrandDetailComponent
  },
  {
    path: 'glamclub',
    component: GlamclubComponent
  },
  {
    path: 'categories',
    component: CategoriesComponent
  },
  {
    path: 'categories/:categoryId',
    component: CategoryDetailComponent
  },
  {
    path: 'batch',
    component: BatchUpdateComponent
  },
  {
    path: 'stock',
    component: StockComponent
  },
  {
    path: 'movements',
    component: MovementsComponent
  },
  {
    path: 'reviews',
    component: ReviewsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductsRoutingModule {}

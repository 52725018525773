import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { ProductSelectModalModule } from '../product-select-modal/product-select-modal.module';
import { IncidentFormComponent } from './incident-form.component';

@NgModule({
  declarations: [IncidentFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DropdownModule,
    InputTextModule,
    ToastModule,
    ButtonModule,
    ProductSelectModalModule,
    AutoCompleteModule,
    InputTextareaModule,
    TooltipModule
  ],
  exports: [IncidentFormComponent]
})
export class IncidentFormModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ChartModule } from 'primeng/chart';
import { SubscriberGroupCounterChartDialogComponent } from './subscriber-group-counter-chart-dialog/subscriber-group-counter-chart-dialog.component';
import { SubscriberGroupCounterChartComponent } from './subscriber-group-counter-chart.component';

@NgModule({
  declarations: [
    SubscriberGroupCounterChartComponent,
    SubscriberGroupCounterChartDialogComponent
  ],
  imports: [CommonModule, ChartModule],
  exports: [
    SubscriberGroupCounterChartComponent,
    SubscriberGroupCounterChartDialogComponent
  ]
})
export class SubscriberGroupCounterChartModule {}

/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Movement } from './movement';

export interface SubscriberEditionIncident {
  incidentId?: number;
  incidentTypeId?: number;
  description?: string;
  productVariantId?: number;
  personId?: number;
  editionId?: number;
  dateCreated?: Date;
  dateUpdated?: Date;
  userId?: number;
  movementId?: number;
  movement?: Movement;
}

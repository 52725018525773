import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { BroadcastUtil } from 'src/app/utils/broadcast.util';
import { TokenStorageService } from './token-storage.service';

export class HttpClientInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(req)
      .pipe(catchError((err: HttpErrorResponse) => this.handleAuthError(err)));
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (TokenStorageService.userLogged && err.status === 403) {
      BroadcastUtil.get('permissionDenied').emit();
    }
    if (TokenStorageService.userLogged && err.status === 401) {
      TokenStorageService.signOut();
      BroadcastUtil.get('tokenExpired').emit();
    }
    throw err;
  }
}

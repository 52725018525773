import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';

import { EmailMarketingFormComponent } from './email-marketing-form.component';

@NgModule({
  declarations: [EmailMarketingFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    InputTextModule,
    TooltipModule
  ],
  exports: [EmailMarketingFormComponent]
})
export class EmailMarketingFormModule {}

/**
 * Audit MicroService
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Log } from './log';
import { PageableObject } from './pageableObject';
import { SortObject } from './sortObject';

export interface PageLog {
  totalPages?: number;
  totalElements?: number;
  first?: boolean;
  last?: boolean;
  size?: number;
  content?: Array<Log>;
  number?: number;
  sort?: Array<SortObject>;
  numberOfElements?: number;
  pageable?: PageableObject;
  empty?: boolean;
}

import {
  ChangeDetectorRef,
  Component,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import BlotFormatter from '@enzedonline/quill-blot-formatter2';
import { NgxPermissionsService } from 'ngx-permissions';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Editor } from 'primeng/editor';
import { FileUpload, FileUploadHandlerEvent } from 'primeng/fileupload';
import Quill from 'quill';
import { firstValueFrom, lastValueFrom, map } from 'rxjs';
import * as beautify from 'simply-beautiful';
import {
  Article,
  ArticleAccessDetail,
  ArticleControllerService,
  ArticleLikeDetail,
  FileControllerService
} from 'src/app/admin-api';
import { ImageCropperModalComponent } from 'src/app/components/image-cropper-modal/image-cropper-modal.component';
import { ImageUploadComponent } from 'src/app/components/image-upload/image-upload.component';
import { TableColumn } from 'src/app/components/table';
import { ArticleTypeEnum, Role, roleAsObject } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-article-details',
  templateUrl: './article-details.component.html',
  styleUrl: './article-details.component.scss',
  providers: [DialogService],
  encapsulation: ViewEncapsulation.None
})
export class ArticleDetailsComponent
  extends ImageUploadComponent<Article>
  implements OnInit
{
  @ViewChild(Editor) editor: Editor;
  article: Article | undefined;

  permission: boolean;
  showAsHtml = false;

  toolbarOptions = [
    [{ header: [2, 3, 4, false] }],
    [
      'bold',
      'italic',
      'strike',
      { script: 'sub' },
      { script: 'super' },
      'code-block'
    ], // toggled buttons
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    ['link', 'image'],

    [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }]
  ];
  handlers = {
    image: this.handleImage
  };

  override form = new FormGroup({
    articleId: new FormControl<number>(null),
    articleTitle: new FormControl<string>(null, [
      Validators.required,
      Validators.maxLength(255)
    ]),
    articleType: new FormControl(this.articleType, Validators.required),
    articleTags: new FormControl<string>(null, Validators.maxLength(4000)),
    articleSummary: new FormControl<string>(
      null,
      [Validators.maxLength(4000)].concat(
        this.isShort ? [] : [Validators.required]
      )
    ),
    articleKeywords: new FormControl<string>(null, Validators.maxLength(800)),
    articleImage: new FormControl<string>(null),
    articleDescription: new FormControl<string>(
      null,
      Validators.maxLength(4000)
    ),
    articleBody: new FormControl<string>(null, Validators.maxLength(50000)),
    html: new FormControl(false),
    articleAuthor: new FormControl<string>(null, Validators.maxLength(255)),
    active: new FormControl(false),
    articleTypeName: new FormControl<string>(null),
    publishDate: new FormControl<Date>(FormUtil.utcDate(new Date()), [
      FormUtil.date
    ]),
    unpublishDate: new FormControl<Date>(null, [FormUtil.date]),
    minutesTotal: new FormControl<number>(null, [
      Validators.min(this.isShort ? 0 : 1)
    ]),
    videoURL: new FormControl<string>(null, [FormUtil.linkValidator])
  });
  likeCols = [
    new TableColumn.Builder()
      .setHeader('Últ. atualização')
      .setField('dateUpdated')
      .setType('date')
      .build(),
    new TableColumn.Builder()
      .setHeader('Assinante')
      .setField('personName')
      .setCondition('contains')
      .setRouterLink('/users/person/')
      .setRouterLinkFieldName('personId')
      .build(),
    new TableColumn.Builder()
      .setHeader('Deu like?')
      .setField('like')
      .setType('boolean')
      .setFilter(true)
      .build()
  ];
  viewCols = [
    new TableColumn.Builder()
      .setHeader('Última visualização')
      .setField('dateUpdated')
      .setType('date')
      .build(),
    new TableColumn.Builder()
      .setHeader('Primeira visualização')
      .setField('dateCreated')
      .setType('date')
      .build(),
    new TableColumn.Builder()
      .setHeader('Assinante')
      .setField('personName')
      .setCondition('contains')
      .setRouterLink('/users/person/')
      .setRouterLinkFieldName('personId')
      .setLinkActive((like: ArticleAccessDetail) => like.personId > 0)
      .build(),
    new TableColumn.Builder()
      .setHeader('Visualizações')
      .setField('views')
      .setCondition('gte')
      .build()
  ];
  likes: ArticleLikeDetail[];
  views: ArticleAccessDetail[];
  tab = 0;
  acceptTypes = [{ value: 'image/*', label: 'image' }];
  accept = this.acceptTypes.map((t) => t.value).toString();

  constructor(
    override router: Router,
    override fileService: FileControllerService,
    public articleService: ArticleControllerService,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private permissionsService: NgxPermissionsService,
    private title: Title,
    private dialog: DialogService,
    protected cdRef: ChangeDetectorRef
  ) {
    super(fileService, router);
  }

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader();
    this.permission = await this.permissionsService.hasPermission([
      roleAsObject(Role.Full_Administrator).enumValue,
      roleAsObject(Role.Content).enumValue
    ]);
    Quill.register('modules/blotFormatter2', BlotFormatter);
    const params = await firstValueFrom(this.activatedRoute.params);
    if (params['articleId'] && Number(params['articleId'])) {
      try {
        this.article = await lastValueFrom(
          this.articleService
            .findById(Number(params['articleId']))
            .pipe(map((data) => data.result))
        );
        if (this.articleType !== this.article.articleType) {
          throw { message: `${this.modelName} não encontrado` };
        }
        await Promise.all([this.findArticleLikes(), this.findArticleViews()]);
        this.title.setTitle('Artigo - ' + this.article.articleTitle);
        this.form.patchValue({
          ...this.article,
          publishDate: this.article?.publishDate
            ? FormUtil.utcDate(this.article?.publishDate)
            : FormUtil.utcDate(new Date()),
          unpublishDate: this.article?.unpublishDate
            ? FormUtil.utcDate(this.article.unpublishDate)
            : null
        });
        if (this.article.videoURL) this.loadVideo();
        if (this.article.videoURL && this.article?.minutesTotal)
          this.getVideoDuration();
        this.activatedRoute.queryParams.subscribe((queryParams) => {
          try {
            if (queryParams && queryParams['tab'] && Number(queryParams['tab']))
              this.tab = Number(queryParams['tab']);
          } catch (error) {
            this.tab = 0;
          }
        });
      } catch (error) {
        AppDialogService.showErrorDialog(error, true);
      }
    } else if (!this.permission) {
      this.router.navigate(['/unauthorized']);
    } else {
      this.title.setTitle('Novo ' + this.modelTitleName);
    }
    this.format();
    LoaderService.showLoader(false);
  }

  async findArticleLikes() {
    try {
      this.likes = await lastValueFrom(
        this.articleService
          .getArticleLikes(this.article.articleId)
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      this.likes = [];
      AppDialogService.showErrorDialog(error);
    }
  }

  async findArticleViews() {
    try {
      this.views = await lastValueFrom(
        this.articleService
          .getArticleViews(this.article.articleId)
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      this.views = [];
      AppDialogService.showErrorDialog(error);
    }
  }

  tabChanged(index: number) {
    this.tab = index;
    this.navigate();
  }

  navigate() {
    this.router.navigate(
      ['/content/' + this.url + '/' + this.article.articleId],
      {
        queryParams: { tab: this.tab },
        queryParamsHandling: 'merge'
      }
    );
  }

  async submit(): Promise<void> {
    if (this.form.invalid) {
      this.messageService.add({
        severity: 'error',
        detail: 'Não foi possivel salvar. Campos obrigatórios não preenchidos',
        summary: 'Erro'
      });

      return;
    }

    LoaderService.showLoader();
    try {
      this.addClassToImages();
      let detail;

      const isNew = !this.modelId;
      if (!isNew) {
        this.article = await lastValueFrom(
          this.articleService
            .updateArticle(this.modelId, this.form.value)
            .pipe(map((data) => data.result))
        );
        detail = 'Artigo atualizado com sucesso.';
        this.form.get('articleBody').setValue(this.article.articleBody);
      } else {
        this.article = await lastValueFrom(
          this.articleService
            .createArticle(this.form.value)
            .pipe(map((data) => data.result))
        );
        detail = 'Artigo cadastrado com sucesso';
      }
      this.form.get('html').setValue(false);

      await this.afterSubmit();
      this.messageService.add({
        severity: 'success',
        detail,
        summary: 'Sucesso'
      });
      LoaderService.showLoader(false);
      if (isNew) {
        this.form.get('articleId').setValue(this.modelId);
        this.form.get('articleId').enable();
        this.router.navigate(['/content/' + this.url + '/' + this.modelId]);
      }
    } catch (error: any) {
      LoaderService.showLoader(false);
      AppDialogService.showErrorDialog(error);
    }
  }

  async cropImageBeforeUpload(
    $event: FileUploadHandlerEvent,
    fileUpload: FileUpload
  ): Promise<void> {
    this.dialog
      .open(ImageCropperModalComponent, {
        width: '600px',
        height: '80vh',
        data: {
          image: $event.files[0],
          fixedWidth: 245,
          fixedHeight: 245
        },
        showHeader: true,
        header: 'Ajustar imagem',
        maximizable: true,
        modal: true
      })
      .onClose.subscribe(async (cropped: File) => {
        if (cropped) {
          $event.files = [cropped];
          await this.onUpload($event, 'articleImage', fileUpload);
        } else {
          fileUpload.clear();
        }
      });
  }

  get filePath(): string {
    return '/content/' + this.url;
  }

  get articleTitle() {
    return this.form.get('articleTitle');
  }

  get articleDescription() {
    return this.form.get('articleDescription');
  }

  get articleSummary() {
    return this.form.get('articleSummary');
  }

  get publishDate() {
    return this.form.get('publishDate');
  }

  override get model() {
    return this.article;
  }

  override get modelId() {
    return this.article?.articleId || 0;
  }

  format() {
    this.form
      .get('articleBody')
      .setValue(beautify.html(this.form.value.articleBody, { indent_size: 2 }));
  }

  handleImage() {
    document.getElementById('inputFile').click();
  }

  async updateVideo(
    $event: FileUploadHandlerEvent,
    field: string,
    fileUpload: FileUpload
  ) {
    LoaderService.showLoader();
    await this.onUpload($event, field, fileUpload);
    await this.getCoverImage();
    this.getVideoDuration();
  }

  async refreshVideo() {
    if (
      this.form.controls.videoURL.value &&
      FormUtil.validURL(this.form.value.videoURL)
    ) {
      LoaderService.showLoader();
      await this.getCoverImage();
      this.getVideoDuration();
    } else {
      this.form.controls.videoURL.setErrors({ invalid: true });
      this.loadVideo();
    }
  }

  async handleFile($event: Event) {
    try {
      if (
        !this.acceptTypes?.some((t) =>
          (($event.target as any).files[0] as File).type.includes(t.label)
        )
      ) {
        throw { error: { message: 'Arquivo não suportado.' } };
      }
      LoaderService.showLoader();
      const link = await lastValueFrom(
        this.fileService
          .uploadFileForm(
            this.filePath.concat('assets/'),
            ($event.target as any).files[0],
            ($event.target as any).files[0].name
          )
          .pipe(map((data) => data.result))
      );
      this.form
        .get('articleBody')
        .setValue(this.form.value.articleBody + `<p><img src="${link}" /></p>`);
      this.editor.writeValue(this.form.value.articleBody);
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso',
        detail: 'Imagem adicionada ao final do texto.'
      });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  addClassToImages() {
    const imgs = (this.editor?.getQuill() as Quill)?.container.querySelectorAll(
      'img'
    );
    if (imgs?.length) {
      imgs.forEach((i) => {
        if (!i.className?.includes('dimensioned'))
          i.classList.add('dimensioned');
      });

      this.form
        .get('articleBody')
        .setValue(
          (this.editor?.getQuill() as Quill)?.getSemanticHTML() ||
            this.form.value.articleBody
        );
    }
  }

  async getCoverImage(loader = false, seekTo = 0.1) {
    try {
      if (loader) LoaderService.showLoader();
      const cover = await this.getVideoCover(seekTo);
      await this.onUpload(
        {
          files: [
            new File(
              [cover],
              this.article?.articleId?.toString() ||
                new Date().getTime().toString()
            )
          ]
        },
        'articleImage'
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      if (loader) LoaderService.showLoader(false);
    }
  }

  getVideoDuration() {
    const video = document.getElementsByTagName('video')[0];
    if (video?.duration)
      this.form.controls.minutesTotal.setValue(video.duration / 60);
  }

  loadVideo() {
    const video = document.getElementsByTagName('video')[0];
    video.setAttribute('src', this.form.value.videoURL);
    video.load();
    return video;
  }

  getVideoCover(seekTo = 0.0): Promise<Blob> {
    return new Promise((resolve, reject) => {
      // load the file to a video player
      const video = this.loadVideo();
      video.addEventListener('error', () => {
        reject('error when loading video file');
      });
      // load metadata of the video to get video duration and dimensions
      video.addEventListener('loadedmetadata', () => {
        // seek to user defined timestamp (in seconds) if possible
        if (video.duration < seekTo) {
          reject('video is too short.');
          return;
        }
        // delay seeking or else 'seeked' event won't fire on Safari
        setTimeout(() => {
          video.currentTime = seekTo;
        }, 200);
        // extract video thumbnail once seeking is complete
        video.addEventListener('seeked', () => {
          // define a canvas to have the same dimension as the video
          const canvas = document.createElement('canvas');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          // draw the video frame to canvas
          const ctx = canvas.getContext('2d');
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          // return the canvas image as a blob
          ctx.canvas.toBlob(
            (blob) => {
              resolve(blob);
            },
            'image/jpeg',
            0.75 /* quality */
          );
        });
      });
    });
  }

  removeVideo() {
    this.imagesReplaced.push(this.form.value.videoURL);
    this.imagesReplaced.push(this.form.value.articleImage);
    this.form.controls.videoURL.reset();
    this.form.controls.articleImage.reset();
    this.form.controls.minutesTotal.reset();
    this.loadVideo();
  }

  get articleType() {
    if (this.isGlamTV) return ArticleTypeEnum.Vídeos;
    if (this.isShort) return ArticleTypeEnum.Shorts;
    return ArticleTypeEnum.MAG;
  }

  get isArticle() {
    return this.router.url.includes('/article/');
  }

  get isGlamTV() {
    return this.router.url.includes('/glam-tv/');
  }

  get isShort() {
    return this.router.url.includes('/shorts/');
  }

  get modelTitleName() {
    switch (this.articleType) {
      case ArticleTypeEnum.Vídeos:
        return 'vídeo';
      case ArticleTypeEnum.Shorts:
        return 'short';
      default:
        return 'artigo';
    }
  }

  get modelName() {
    if (
      this.articleType === ArticleTypeEnum.Vídeos ||
      this.articleType === ArticleTypeEnum.Shorts
    )
      return 'video';
    return 'artigo';
  }

  get url() {
    return this.isGlamTV ? 'glam-tv' : this.isShort ? 'shorts' : 'article';
  }
}

<div class="panel-container">
  <h3>Mens</h3>

  <div class="panel-container__buttons">
    <p-button label="Incluir banner" (onClick)="goToNewScreen()"></p-button>
  </div>

  <div class="panel-container__campaigns">
    <p-panel header="Campanha atual">
      <p-orderList
        *ngIf="pageBanners"
        [value]="pageBanners"
        [dragdrop]="true"
        [stripedRows]="true"
        (onReorder)="onReorder()"
      >
        <ng-template let-banner pTemplate="item">
          <div>
            <div class="panel-container__campaigns__campaign">
              <div class="panel-container__campaigns__campaign__item">
                <h4>Título: {{ banner.title }}</h4>
                <p-tag
                  [severity]="banner.active ? 'success' : 'info'"
                  [value]="banner.active ? 'Ativo' : 'Agendado'"
                ></p-tag>
              </div>
              <img
                class="panel-container__campaigns__campaign__item"
                [src]="banner.desktopImage"
                height="150"
                width="444.88"
              />
              <div class="panel-container__campaigns__campaign__item buttons">
                <p-button
                  label="Editar"
                  [outlined]="true"
                  (onClick)="goToEditScreen(banner)"
                ></p-button>
                <p-button
                  label="Excluir"
                  [outlined]="true"
                  severity="danger"
                  (onClick)="deleteSection(banner)"
                  *ngIf="pageBanners.length > 1"
                ></p-button>
              </div>
            </div>
          </div>
        </ng-template>
      </p-orderList>
      <p-confirmDialog></p-confirmDialog>
    </p-panel>
  </div>
</div>

/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { EditionCustomization } from './editionCustomization';
import { Pageable } from './pageable';
import { Sort } from './sort';

export interface PageEditionCustomization {
  totalPages?: number;
  totalElements?: number;
  size?: number;
  content?: Array<EditionCustomization>;
  number?: number;
  sort?: Sort;
  numberOfElements?: number;
  pageable?: Pageable;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RatingModule } from 'primeng/rating';
import { SpeedDialModule } from 'primeng/speeddial';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { BrandFormModule } from 'src/app/components/brand-form/brand-form.module';
import { DragDropListModule } from 'src/app/components/drag-drop-list/drag-drop-list.module';
import { GridstackModule } from 'src/app/components/gridstack/gridstack.module';
import { ProductCategoryFormModule } from 'src/app/components/product-category-form/product-category-form.module';
import { ProductDiffModalModule } from 'src/app/components/product-diff-modal/product-diff-modal.module';
import { ProductFormModule } from 'src/app/components/product-form/product-form.module';
import { ProductImagesModule } from 'src/app/components/product-images/product-images.module';
import { ProductMovementsModule } from 'src/app/components/product-movements/product-movements.module';
import { ProductStockModule } from 'src/app/components/product-stock/product-stock.module';
import { ProductVariantCompositionsModule } from 'src/app/components/product-variant-compositions/product-variant-compositions.module';
import { AppTableModule } from 'src/app/components/table/app-table.module';
import { BatchUpdateComponent } from './batch-update/batch-update.component';
import { BrandDetailComponent } from './brands/brand-detail/brand-detail.component';
import { BrandsComponent } from './brands/brands.component';
import { CategoriesComponent } from './categories/categories.component';
import { CategoryDetailComponent } from './categories/category-detail/category-detail.component';
import { GlamclubComponent } from './glamclub/glamclub.component';
import { MovementsComponent } from './movements/movements.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { ProductsRoutingModule } from './products-routing.module';
import { ProductsComponent } from './products.component';
import { ReviewsComponent } from './reviews/reviews.component';
import { StockComponent } from './stock/stock.component';
@NgModule({
  declarations: [
    ProductDetailsComponent,
    ProductsComponent,
    BrandsComponent,
    GlamclubComponent,
    BrandDetailComponent,
    CategoriesComponent,
    CategoryDetailComponent,
    BatchUpdateComponent,
    StockComponent,
    MovementsComponent,
    ReviewsComponent
  ],
  imports: [
    CommonModule,
    ProductsRoutingModule,
    DropdownModule,
    ReactiveFormsModule,
    InputNumberModule,
    ButtonModule,
    OverlayPanelModule,
    TooltipModule,
    InputTextModule,
    TabViewModule,
    ProductFormModule,
    RatingModule,
    ProductVariantCompositionsModule,
    ProductStockModule,
    ProductMovementsModule,
    BrandFormModule,
    ProductImagesModule,
    DragDropListModule,
    FormsModule,
    SpeedDialModule,
    AppTableModule,
    ConfirmPopupModule,
    ProductCategoryFormModule,
    FileUploadModule,
    InputSwitchModule,
    GridstackModule,
    MultiSelectModule,
    DialogModule,
    ToastModule,
    ProductDiffModalModule
  ]
})
export class ProductsModule {}

<form class="form-b4a" [formGroup]="form" *ngIf="form">
  <span class="p-float-label">
    <input type="text" pInputText formControlName="alt" id="alt" name="alt" />
    <label for="alt">Alt text *</label>
  </span>
  <span class="p-float-label">
    <input
      type="text"
      pInputText
      formControlName="path"
      id="path"
      name="path"
    />
    <label for="path">Link *</label>
    <small *ngIf="isRouterLink">
      * Recomenda-se iniciar o link com '/' para referências dentro da Glam
    </small>
  </span>
  <span
    class="image-upload"
    [class.image-upload__full-w]="sectionType === 'Banner'"
  >
    <div class="file-upload">
      <span class="p-float-label">
        <input type="text" readonly pInputText formControlName="image" />
        <label for="image">Imagem Desktop * </label>
      </span>
      <p-fileUpload
        mode="basic"
        chooseLabel="Selecionar"
        name="file"
        [auto]="true"
        accept=".webp"
        [maxFileSize]="250 * 1024"
        uploadIcon="pi pi-file"
        [customUpload]="true"
        (uploadHandler)="onUpload($event, 'image', desktopUpload)"
        id="image"
        #desktopUpload
      />
    </div>
    <img *ngIf="form.value.image" [src]="form.value.image" />
  </span>
  <span
    class="image-upload"
    [class.image-upload__full-w]="sectionType === 'Banner'"
  >
    <div class="file-upload">
      <span class="p-float-label">
        <input type="text" readonly pInputText formControlName="mobileImage" />
        <label for="mobileImage">Imagem mobile * </label>
      </span>
      <p-fileUpload
        mode="basic"
        chooseLabel="Selecionar"
        name="file"
        [auto]="true"
        accept=".webp"
        [maxFileSize]="250 * 1024"
        uploadIcon="pi pi-file"
        [customUpload]="true"
        (uploadHandler)="onUpload($event, 'mobileImage', mobileUpload)"
        id="mobileImage"
        #mobileUpload
      />
    </div>
    <img *ngIf="form.value.mobileImage" [src]="form.value.mobileImage" />
  </span>
</form>

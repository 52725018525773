<form
  [class.modal-form]="!group()?.attributeGroupReferenceId"
  [class.form-b4a]="group()?.attributeGroupReferenceId > 0"
  [formGroup]="form"
  *ngIf="form"
  (submit)="submit()"
>
  <span class="p-float-label">
    <input
      type="text"
      pInputText
      formControlName="attributeName"
      id="attributeName"
      name="attributeName"
    />
    <label for="attributeName">Nome do atributo *</label>
  </span>
  <span class="p-float-label">
    <input
      type="text"
      pInputText
      formControlName="attributeQuestion"
      id="attributeQuestion"
      name="attributeQuestion"
    />
    <label for="attributeQuestion">Enunciado *</label>
  </span>
  <span class="p-float-label">
    <p-dropdown
      [options]="attributeTypes"
      formControlName="attributeTypeId"
      inputId="attributeTypeId"
      appendTo="body"
      [autoDiplayFirst]="false"
      [panelStyle]="{
        maxWidth:
          group()?.attributeGroupReferenceId > 0
            ? 'calc(800px - 3rem)'
            : 'calc(600px - 3rem)'
      }"
    />
    <label for="attributeTypeId">Tipo de pergunta *</label>
  </span>
  <span class="p-input-checkbox">
    <p-checkbox
      formControlName="sensitiveContent"
      [binary]="true"
      inputId="sensitiveContent"
    />
    <label for="sensitiveContent">Conteúdo sensível</label>
    <small
      >* Marque essa opção caso a pergunta ou as alternativas apresentem
      conteúdo sensível no texto.</small
    >
  </span>
  <span class="input-switch">
    <label for="active">Inativo</label>
    <p-inputSwitch formControlName="active" inputId="active" />
    <label for="active">Ativo</label>
  </span>
  <span class="image-upload" *ngIf="group()?.attributeGroupReferenceId">
    <img *ngIf="form.value.webImage" [src]="form.value.webImage" />
    <div class="file-upload">
      <span class="p-float-label">
        <input
          type="text"
          [disabled]="true"
          pInputText
          formControlName="webImageUrl"
        />
        <label for="webImageUrl">Imagem Web *</label>
      </span>
      <p-fileUpload
        mode="basic"
        chooseLabel="Selecionar"
        name="file"
        [auto]="true"
        accept="image/*"
        [maxFileSize]="1000000"
        uploadIcon="pi pi-file"
        [customUpload]="true"
        (uploadHandler)="onUpload($event, 'webImage', fileUpload)"
        id="webImageUrl"
        #fileUpload
        [disabled]="form.controls.webImage.disabled"
      />
    </div>
  </span>
  <span class="image-upload" *ngIf="group()?.attributeGroupReferenceId">
    <img *ngIf="form.value.mobileImage" [src]="form.value.mobileImage" />
    <div class="file-upload">
      <span class="p-float-label">
        <input
          type="text"
          [disabled]="true"
          pInputText
          formControlName="mobileImageUrl"
        />
        <label for="mobileImageUrl">Imagem Mobile *</label>
      </span>
      <p-fileUpload
        mode="basic"
        chooseLabel="Selecionar"
        name="file"
        [auto]="true"
        accept="image/*"
        [maxFileSize]="1000000"
        uploadIcon="pi pi-file"
        [customUpload]="true"
        (uploadHandler)="onUpload($event, 'mobileImage', fileUpload)"
        id="mobileImageUrl"
        #fileUpload
        [disabled]="form.controls.mobileImage.disabled"
      />
    </div>
  </span>
  <span class="buttons">
    <p-button
      type="button"
      severity="danger"
      label="Voltar"
      (onClick)="ref.close()"
    />
    <p-button
      type="submit"
      label="Salvar"
      severity="primary"
      [disabled]="!form.valid"
    />
  </span>
</form>

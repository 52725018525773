import { Injectable } from '@angular/core';
import { lastValueFrom, map } from 'rxjs';
import { AppDialogService } from 'src/app/services/dialog.service';
import { FileControllerService } from '../admin-api';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  constructor(private fileService: FileControllerService) {}

  async validateImageDimensions(
    file: File,
    expectedRatio: number,
    maxWeight?: number
  ): Promise<boolean> {
    const img = new Image();
    img.src = URL.createObjectURL(file);

    let error: string = '';

    if (!['image/webp', 'image/gif'].includes(file.type))
      error += 'Formato de imagem inválido <br />';

    const actualWeight = file.size;

    if (maxWeight && actualWeight > maxWeight)
      error += `Tamanho máximo excedido em ${
        (actualWeight - maxWeight) / 1024
      }kb. <br />`;

    await new Promise<boolean>((resolve) => {
      img.onload = () => {
        const width = img.width;
        const height = img.height;

        const actualRatio = width / height;
        const isRatioValid = Math.abs(actualRatio - expectedRatio) < 0.01;

        if (!isRatioValid) {
          error += 'A imagem selecionada não possui as dimensões solicitadas.';
        }

        resolve(false);
      };
    });
    if (error !== '') {
      AppDialogService.showErrorDialog({
        message: error
      });
      return false;
    }
    return true;
  }

  async uploadImage(
    file: File,
    fileName: string,
    filePath: string
  ): Promise<string> {
    LoaderService.showLoader();

    const blob = file.slice(0, file.size, file.type);
    const uploadFile = new File([blob], fileName, { type: file.type });

    try {
      const result = (await lastValueFrom(
        this.fileService
          .uploadFileForm(filePath, uploadFile, uploadFile.name)
          .pipe(map((data) => data.result))
      )) as string;
      return result;
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }
}

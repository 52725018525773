import { Component, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgxPermissionsService } from 'ngx-permissions';
import { MessageService, PrimeIcons } from 'primeng/api';
import { lastValueFrom, map } from 'rxjs';
import {
  PageableRequest,
  ProductControllerService,
  ProductReviewDetailed,
  ProductReviewId
} from 'src/app/admin-api';
import {
  PageContent,
  TableActionButton,
  TableColumn,
  TableComponent
} from 'src/app/components/table';
import {
  ProductReviewStatus,
  Role,
  getAllProductReviewStatusIncludeAll,
  roleAsObject
} from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrl: './reviews.component.scss',
  providers: [MessageService]
})
export class ReviewsComponent {
  @ViewChild(TableComponent)
  table: TableComponent;
  cols = [
    new TableColumn(
      'SKU admin',
      'productVariantId',
      true,
      'number',
      '/products/catalog/product-variant/',
      'productVariantId',
      true,
      'contains'
    ),
    new TableColumn(
      'Autor',
      'firstName',
      true,
      'text',
      '/users/person/',
      'personId',
      true,
      'contains'
    ),
    new TableColumn(
      'Email',
      'email',
      true,
      'text',
      '/users/person/',
      'personId',
      true,
      'equals'
    ),
    new TableColumn(
      'Produto',
      'productVariantName',
      true,
      'text',
      '/products/catalog/product-variant/',
      'productVariantId',
      true,
      'contains'
    ),
    new TableColumn('Nota', 'rating', true, 'number'),
    new TableColumn(
      'Titulo',
      'reviewTitle',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Avaliação',
      'review',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Status',
      'productReviewStatus',
      true,
      'text',
      undefined,
      undefined,
      true,
      'equals'
    ),
    new TableColumn(
      'Criação',
      'dateCreated',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    )
  ];
  dropdownFilters:
    | { [key: string]: Array<{ label: string; value: string }> }
    | undefined;
  actionButtons: Array<TableActionButton> = [
    new TableActionButton(
      '',
      'Detalhes',
      PrimeIcons.EYE,
      undefined,
      '',
      'Detalhes',
      'bottom',
      true,
      true,
      'white',
      'small'
    ),
    new TableActionButton(
      '',
      'Aprovar',
      PrimeIcons.CHECK_CIRCLE,
      (review: ProductReviewDetailed) =>
        review.reviewStatus !== ProductReviewStatus.Accepted,
      '',
      'Aprovar',
      'bottom',
      true,
      true,
      'white',
      'small'
    ),
    new TableActionButton(
      '',
      'Reprovar',
      PrimeIcons.BAN,
      (review: ProductReviewDetailed) =>
        review.reviewStatus !== ProductReviewStatus.Denied,
      '',
      'Reprovar',
      'bottom',
      true,
      true,
      'danger',
      'small'
    ),
    new TableActionButton(
      '',
      'Enviar para IA',
      PrimeIcons.MICROCHIP_AI,
      (review: ProductReviewDetailed) =>
        review.reviewStatus === ProductReviewStatus.Pending,
      '',
      'Enviar para IA',
      'bottom',
      true,
      true,
      'primary',
      'small'
    )
  ];
  defaultFilterValues = {
    productReviewStatus: ProductReviewStatus.Pending.toString()
  };
  currentReview: ProductReviewDetailed | undefined;
  currentReviews: Array<ProductReviewDetailed> | undefined;
  errorReviews: Array<ProductReviewId> | undefined;
  permission = false;

  constructor(
    public productService: ProductControllerService,
    private title: Title,
    private messageService: MessageService,
    private permissionsService: NgxPermissionsService
  ) {
    this.title.setTitle('Avaliações da vitrine');
    this.getProductReviewDetailed();
  }

  async getProductReviewDetailed(): Promise<void> {
    this.permission = await this.permissionsService.hasPermission([
      roleAsObject(Role.Full_Administrator).enumValue,
      roleAsObject(Role.Portfolio).enumValue
    ]);
    try {
      const statusList = getAllProductReviewStatusIncludeAll();
      const ratingList = this.getAllReviewsRating();
      if (statusList && ratingList) {
        this.dropdownFilters = {
          productReviewStatus: statusList,
          rating: ratingList
        };
      }
      if (this.permission) {
        this.cols.unshift(new TableColumn('', '', false, 'checkbox'));
        this.cols.push(new TableColumn('Ação', '', false, 'button'));
      }
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findPage(
    request: PageableRequest,
    service: unknown
  ): Promise<PageContent<ProductReviewDetailed>> {
    const filterProductReviewStatus = request.filters?.find(
      (f) => f.field === 'productReviewStatus'
    );
    if (filterProductReviewStatus?.value) {
      if (Number(filterProductReviewStatus.value) < 0) {
        filterProductReviewStatus.value = '';
      }
      request.filters?.push({
        condition: 'equals',
        field: 'reviewStatus',
        fieldType: 'number',
        value: filterProductReviewStatus.value
      });
    }
    return lastValueFrom(
      (service as ProductControllerService)
        .getProductReviewDetailed(request)
        .pipe(map((data) => data.result as PageContent<ProductReviewDetailed>))
    );
  }

  getAllReviewsRating(): Array<{
    label: string;
    value: string;
  }> {
    const objetos: Array<{ label: string; value: string }> = [];
    for (let i = 1; i < 6; i++) {
      objetos.push({
        label: i.toString(),
        value: i.toString()
      });
    }
    return objetos.concat([
      {
        label: 'Todas',
        value: '-1'
      }
    ]);
  }

  //Eventos
  async buttonClick(event: {
    item: ProductReviewDetailed;
    $event: Event;
    action: string;
  }) {
    if (event.action === 'Detalhes') {
      this.currentReview = event.item;
    } else if (event.action === 'Aprovar') {
      await this.approveReview(event.item);
    } else if (event.action === 'Reprovar') {
      await this.denyReview(event.item);
    } else if (event.action === 'Enviar para IA') {
      await this.llmProductReview(event.item);
    }
  }

  async approveReview(review: ProductReviewDetailed) {
    LoaderService.showLoader();
    try {
      const result = await lastValueFrom(
        this.productService
          .acceptProductReview(review.personId, review.productId)
          .pipe(map((data) => data.result))
      );
      this.messageService.add({
        severity: 'success',
        detail: result,
        summary: 'Sucesso'
      });
      await this.table?.refresh();
      delete this.currentReview;
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  async denyReview(review: ProductReviewDetailed) {
    LoaderService.showLoader();
    try {
      const result = await lastValueFrom(
        this.productService
          .denyProductReview(review.personId, review.productId)
          .pipe(map((data) => data.result))
      );
      this.messageService.add({
        severity: 'success',
        detail: result,
        summary: 'Sucesso'
      });
      await this.table?.refresh();
      delete this.currentReview;
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  async llmProductReview(review: ProductReviewDetailed) {
    LoaderService.showLoader();
    try {
      const result = await lastValueFrom(
        this.productService
          .llmReviews({
            reviewList: [
              { personId: review.personId, productId: review.productId }
            ]
          })
          .pipe(map((data) => data.result))
      );
      if (result?.at(0).approved)
        this.messageService.add({
          severity: 'success',
          detail: 'Avaliação aprovada.',
          summary: 'Sucesso'
        });
      else if (result?.at(0).approved === false)
        this.messageService.add({
          severity: 'success',
          detail: 'Avaliação rejeitada.',
          summary: 'Sucesso'
        });
      else
        this.messageService.add({
          severity: 'error',
          detail: 'Não foi possível analisar esta avaliação.',
          summary: 'Erro'
        });
      await this.table?.refresh();
      delete this.currentReview;
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  async llmModifyReviews(
    accept: boolean,
    reviewsList: Array<ProductReviewDetailed>
  ) {
    LoaderService.showLoader();
    try {
      const result = await lastValueFrom(
        this.productService
          .llmReviews({
            accept,
            reviewList: reviewsList.map((r) => ({
              personId: r.personId,
              productId: r.productId
            }))
          })
          .pipe(map((data) => data.result))
      );
      const approved = result.filter((r) => r.approved);
      const rejected = result.filter((r) => r.approved === false);
      const failed = result.filter(
        (r) => r.approved === null || r.approved === undefined
      );
      if (approved?.length)
        this.messageService.add({
          severity: 'success',
          detail: approved.length + ' avaliações aprovadas',
          summary: 'Sucesso'
        });
      if (rejected?.length)
        this.messageService.add({
          severity: 'success',
          detail: rejected.length + ' rejeitadas aprovadas',
          summary: 'Sucesso'
        });
      if (failed?.length) {
        this.errorReviews = failed;
        this.messageService.add({
          severity: 'error',
          detail: this.errorReviews + ' avaliações não puderam ser analisadas',
          summary: 'Erro'
        });
      }
      delete this.currentReviews;
      await this.table?.refresh(true);
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  async modifyReviews(
    accept: boolean,
    reviewsList: Array<ProductReviewDetailed>
  ) {
    LoaderService.showLoader();
    try {
      const result = await lastValueFrom(
        this.productService
          .modifyReviews({
            accept,
            reviewList: reviewsList.map((r) => ({
              personId: r.personId,
              productId: r.productId
            }))
          })
          .pipe(map((data) => data.result))
      );
      if (result.length === 0)
        this.messageService.add({
          severity: 'success',
          detail:
            reviewsList.length +
            ' avaliações ' +
            (accept ? 'aprovadas' : 'reprovadas') +
            ' com sucesso!',
          summary: 'Sucesso'
        });
      else {
        this.errorReviews = result;
        this.messageService.add({
          severity: 'error',
          detail:
            result.length +
            ' avaliações não puderam ser ' +
            (accept ? 'aprovadas.' : 'reprovadas.'),
          summary: 'Erro'
        });
      }
      delete this.currentReviews;
      await this.table?.refresh(true);
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }
  showCheckbox(review: { data: ProductReviewDetailed }): boolean {
    return review.data.reviewStatus === ProductReviewStatus.Pending;
  }
  rowBackground = (review: ProductReviewDetailed) => {
    return this.errorReviews?.some(
      (r) => r.personId === review.personId && r.productId === review.productId
    )
      ? '#ed9e9e'
      : 'auto';
  };

  get modalColumns(): Array<TableColumn> {
    return this.cols.filter(
      (c) => c.type !== 'button' && c.type !== 'checkbox'
    );
  }
}

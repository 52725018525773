import { Clipboard } from '@angular/cdk/clipboard';
import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import { IncorrectEndEdition, ItOpsControllerService } from 'src/app/admin-api';
import { SubscriberProblemGroupEnum } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-incorrect-end-edition-modal',
  templateUrl: './incorrect-end-edition-modal.component.html',
  styleUrls: ['./incorrect-end-edition-modal.component.scss'],
  providers: [MessageService]
})
export class IncorrectEndEditionModalComponent {
  recurrences: Array<IncorrectEndEdition>;
  endEditionUpdate: string;

  constructor(
    private config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private itOpsService: ItOpsControllerService,
    private clipboard: Clipboard,
    private messageService: MessageService
  ) {
    this.recurrences = this.config.data.recurrences;
    this.endEditionUpdate = this.recurrences?.reduce((sql, recurrence) => {
      let year = Math.floor(recurrence.editionId / 100);
      let month: number = recurrence.editionId % 100;

      if (month === 1) {
        year -= 1;
        month = 12;
      } else {
        month -= 1;
      }

      const formattedMonth = month < 10 ? `0${month}` : `${month}`;
      const newEndEdition = `${year}${formattedMonth}`;

      return sql + `UPDATE [Glambox.Subscription].dbo.Subscriber SET EndEdition = ${newEndEdition}
      WHERE SubscriberId = ${recurrence.subscriberId}
      AND SubscriberId IN (SELECT SubscriberId FROM [Glambox.Subscription].dbo.SubscriberBilling WHERE SubscriberBillingStatus = 0);
      \n`;
    }, '');
  }

  async markAsResolved(): Promise<void> {
    LoaderService.showLoader();
    try {
      const dashboard = await lastValueFrom(
        this.itOpsService
          .markAsResolved(
            SubscriberProblemGroupEnum.IncorrectEndEdition,
            this.recurrences
          )
          .pipe(map((data) => data.result))
      );
      this.ref.close(dashboard);
    } catch (error) {
      LoaderService.showLoader(false);
      AppDialogService.showErrorDialog(error);
    }
  }

  copyToClipboard(text: string): void {
    this.clipboard.copy(text);
    this.messageService.add({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Copiado para área de transferência'
    });
  }
}

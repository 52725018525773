import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DropdownFilter, TableColumn } from 'src/app/components/table';
import { ShopCategoryService } from 'src/app/connect-api/api/shop/shop-category.service';

@Component({
  selector: 'app-shop-categories',
  templateUrl: './shop-categories.component.html',
  styleUrl: './shop-categories.component.scss'
})
export class ShopCategoriesComponent {
  cols = [
    new TableColumn(
      'Id',
      'id',
      true,
      'text',
      'category/',
      'id',
      true,
      'equals'
    ),
    new TableColumn(
      'Nome',
      'name',
      true,
      'text',
      'category/',
      'id',
      true,
      'contains'
    ),
    new TableColumn(
      'Loja',
      'shops',
      true,
      'text',
      undefined,
      undefined,
      undefined,
      'in'
    ),
    new TableColumn(
      'É uma marca',
      'brandCategory',
      true,
      'boolean',
      undefined,
      undefined
    ),
    new TableColumn(
      'É uma coleção',
      'isCollection',
      true,
      'boolean',
      undefined,
      undefined
    ),
    new TableColumn(
      'É uma Wishlist',
      'isWishlist',
      true,
      'boolean',
      undefined,
      undefined
    ),
    new TableColumn(
      'Referência',
      'reference',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Ativo',
      'published',
      true,
      'boolean',
      undefined,
      undefined,
      true
    )
  ];

  dropdownFilters: {
    [field: string]: Array<DropdownFilter>;
  } = {
    shops: [
      { label: 'Todos', value: '' },
      { label: 'Glamshop', value: 'Glamshop' },
      { label: 'Men`s Market', value: 'mensmarket' }
    ],
    brandCategory: [
      { label: 'Todos', value: undefined },
      { label: 'Sim', value: true },
      { label: 'Não', value: false }
    ],
    isCollection: [
      { label: 'Todos', value: undefined },
      { label: 'Sim', value: true },
      { label: 'Não', value: false }
    ],
    isWishlist: [
      { label: 'Todos', value: undefined },
      { label: 'Sim', value: true },
      { label: 'Não', value: false }
    ],
    published: [
      { label: 'Todos', value: undefined },
      { label: 'Sim', value: true },
      { label: 'Não', value: false }
    ]
  };

  defaultFilterValues = { isWishlist: false };

  constructor(
    private title: Title,
    protected shoppingCategoryService: ShopCategoryService
  ) {
    this.title.setTitle('Loja - Categorias');
  }
}

/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ProductVariantCreateRequest {
  productId: number;
  productVariantName: string;
  costPrice: number;
  invoicePrice: number;
  invoiceName: string;
  unitName: string;
  unitMeasure: number;
  weight: number;
  tradePoints?: number;
  maxTradesPerPerson?: number;
  tradeActive: boolean;
  isTradeable: boolean;
  defaultImageURL?: string;
  externalStoreUrl?: string;
  longExternalStoreUrl?: string;
  isSellableUrl?: boolean;
  wmsId?: number;
  displayOrder?: number;
  internalEAN?: string;
  externalId?: number;
  expirationAttribute?: string;
  genero?: string;
  cfop?: string;
  cst?: string;
  extipi?: string;
  ncm?: string;
  cean?: string;
}

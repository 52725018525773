<form
  class="form-b4a brand-form"
  [class.editable]="model"
  [formGroup]="form"
  *ngIf="form"
  (submit)="saveBrand()"
>
  <span class="p-float-label">
    <input type="text" pInputText formControlName="brandName" id="brandName" />
    <label for="brandName">Nome da marca *</label>
  </span>
  <span class="p-float-label">
    <p-inputNumber
      inputId="brandEquity"
      formControlName="brandEquity"
      [max]="5"
      [min]="0"
    />
    <label for="brandEquity">BrandEquity *</label>
    <small
      class="error"
      *ngIf="
        form.controls['brandEquity'].errors &&
        form.controls['brandEquity'].errors['max']
      "
    >
      Máximo {{ form.controls['brandEquity'].errors['max']['max'] }}
    </small>
  </span>
  <span class="file-upload logo">
    <label for="logoImageUrl">
      Logo da marca
      <img
        [src]="form.get('logoImageUrl')?.value"
        *ngIf="form.get('logoImageUrl')?.value"
      />
    </label>
    <p-fileUpload
      mode="basic"
      chooseLabel="Selecionar"
      [auto]="true"
      name="file"
      accept="image/*"
      [maxFileSize]="1000000"
      uploadIcon="pi pi-file"
      [customUpload]="true"
      (uploadHandler)="onUpload($event, 'logoImageUrl', logoUpload)"
      id="logoImageUrl"
      #logoUpload
    />
  </span>
  <span class="file-upload">
    <label for="carouselImageUrl">
      Imagem do carousel
      <img
        [src]="form.get('carouselImageUrl')?.value"
        *ngIf="form.get('carouselImageUrl')?.value"
      />
    </label>
    <p-fileUpload
      mode="basic"
      chooseLabel="Selecionar"
      name="file"
      [auto]="true"
      [customUpload]="true"
      (uploadHandler)="onUpload($event, 'carouselImageUrl', carouselUpload)"
      accept="image/*"
      [maxFileSize]="1000000"
      uploadIcon="pi pi-file"
      id="carouselImageUrl"
      #carouselUpload
    />
  </span>
  <span class="file-upload">
    <label for="coverImageUrl">
      Imagem de capa
      <img
        [src]="form.get('coverImageUrl')?.value"
        *ngIf="form.get('coverImageUrl')?.value"
      />
    </label>
    <p-fileUpload
      mode="basic"
      chooseLabel="Selecionar"
      name="file"
      accept="image/*"
      [maxFileSize]="1000000"
      [auto]="true"
      uploadIcon="pi pi-file"
      id="coverImageUrl"
      [customUpload]="true"
      (uploadHandler)="onUpload($event, 'coverImageUrl', coverUpload)"
      #coverUpload
    />
  </span>
  <span class="p-input-checkbox">
    <p-checkbox
      formControlName="carouselImageDisplay"
      [binary]="true"
      inputId="carouselImageDisplay"
    />
    <label for="carouselImageDisplay">Exibir imagem no carousel</label>
  </span>
  <span class="p-input-checkbox">
    <p-checkbox
      formControlName="coverImageDisplay"
      [binary]="true"
      inputId="coverImageDisplay"
    />
    <label for="coverImageDisplay">Exibir imagem de capa</label>
  </span>
  <span
    class="p-input-editor"
    [class.invalid]="
      form.controls['summary'].dirty && form.controls['summary'].invalid
    "
  >
    <label for="summary">Descrição da marca *</label>
    <p-editor
      [style]="{ height: '200px' }"
      formControlName="summary"
      id="summary"
    />
    <small
      [class.error]="
        form.controls['summary'].dirty && form.controls['summary'].invalid
      "
    >
      {{ form.value.summary?.trim().length || 0 }}/4000
    </small>
  </span>
  <small *ngIf="model?.dateUpdated">
    *Última alteração em:
    {{ model.dateUpdated | date : 'dd/MM/yyyy HH:mm:ss' }}
  </small>
  <div class="buttons">
    <p-button label="Salvar" type="submit" [disabled]="!form.valid" />
  </div>
</form>
<p-toast />

import { DatePipe, TitleCasePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom, map } from 'rxjs';
import {
  Edition,
  EditionControllerService,
  KpiControllerService,
  PageableRequest,
  SubscriberUpgradeDowngrade,
  SubscriptionType
} from 'src/app/admin-api';
import {
  DropdownFilter,
  TableColumn,
  TableComponent
} from 'src/app/components/table';
import {
  SubscriptionEnum,
  SubscriptionTypeEvent,
  getAllSubscriptionsIncludeAll,
  getSubscriptionIdsByPriority
} from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-upgrade-downgrade-dashboard',
  templateUrl: './upgrade-downgrade-dashboard.component.html',
  styleUrl: './upgrade-downgrade-dashboard.component.scss',
  providers: [DatePipe, TitleCasePipe]
})
export class UpgradeDowngradeDashboardComponent implements OnInit {
  @ViewChild(TableComponent)
  table: TableComponent | undefined;

  form = new FormGroup({
    subscriptionId: new FormControl<number>(1, [
      Validators.required,
      Validators.min(0)
    ]),
    editionId: new FormControl<number>(
      Number(
        `${new Date().getFullYear()}${(new Date().getMonth() + 1)
          .toString()
          .padStart(2, '0')}`
      ),
      [Validators.required, Validators.min(1)]
    )
  });
  subscriptions: Array<{ label: string; value: number }>;
  editions: Array<Edition> | undefined;
  subscriptionTypes: Array<SubscriptionType> | undefined;
  oldSubscriptionTypes: Array<SubscriptionType> | undefined;
  subscribers:
    | Array<
        SubscriberUpgradeDowngrade & { upgrade: boolean; changePlan: boolean }
      >
    | undefined;
  cols: Array<TableColumn> | undefined;
  elements: Array<SubscriptionTypeEvent> | undefined;
  ready = false;
  dropdownFilters: {
    [field: string]: Array<DropdownFilter>;
  };
  defaultFilters: {
    [field: string]: any | Array<any>;
  };

  constructor(
    public kpiService: KpiControllerService,
    private editionService: EditionControllerService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private title: Title,
    private datePipe: DatePipe,
    private titleCasePipe: TitleCasePipe
  ) {
    this.subscriptions = getAllSubscriptionsIncludeAll();
  }

  async ngOnInit(): Promise<void> {
    this.activatedRoute.params.subscribe(async (params) => {
      if (params['editionId']) {
        this.form.patchValue({
          editionId: Number(params['editionId']),
          subscriptionId: Number((params['editionId'] / 1000000).toFixed(0))
        });
        LoaderService.showLoader();
        const fail = document.getElementById('img-fail');
        if (fail?.style?.display === 'none') {
          fail.style.display = 'block';
        }
        this.ready = false;
        await Promise.all([
          this.findEditions(),
          this.findSubscriptionTypes(),
          this.findOldSubscriptionTypes()
        ]);
        this.cols = [
          new TableColumn(
            'Assinatura antiga',
            'oldSubscriptionTypeName',
            true,
            'text',
            undefined,
            undefined,
            true,
            'equals',
            '',
            () => 1,
            () => false,
            undefined,
            () => false,
            undefined,
            undefined,
            true
          )
        ].concat(
          this.subscriptionTypes.map(
            (st) =>
              new TableColumn(
                SubscriptionEnum[st.subscriptionId] +
                  ' ' +
                  st.name.replace('Anual.', 'Anual/mês'),
                st.subscriptionTypeId.toString(),
                false,
                'number',
                undefined,
                undefined,
                false,
                'equals',
                '',
                () => -1,
                () => false,
                undefined,
                () => false,
                undefined,
                undefined,
                false,
                {
                  'background-color': FormUtil.getColor(
                    st.subscriptionId,
                    st.installments,
                    st.subscriptionTypeId
                  ),
                  color: 'white'
                }
              )
          )
        );
        this.dropdownFilters = {
          oldSubscriptionTypeName: this.oldSubscriptionTypes.map((st) => ({
            label:
              SubscriptionEnum[st.subscriptionId] +
              ' ' +
              st.name.replace('Anual.', 'Anual/mês'),
            value: st.subscriptionTypeId.toString()
          }))
        };
        this.defaultFilters = {
          oldSubscriptionTypeName: this.oldSubscriptionTypes.map((e) =>
            e.subscriptionTypeId.toString()
          )
        };
        this.title.setTitle(
          'Dashboard de Upgrade/Downgrade - ' + this.edition?.theme
        );
        this.ready = true;
        LoaderService.showLoader(false);
      } else {
        this.navigate();
      }
    });
  }

  async findEditions(): Promise<void> {
    try {
      this.editions = await lastValueFrom(
        this.editionService
          .findEditionsBySubscriptionId(this.form.value.subscriptionId || 1)
          .pipe(map((data) => data.result))
      );
      if (this.form.value.subscriptionId === 0) {
        this.editions?.forEach(
          (e) => (e.editionId = (e.editionId || 0) % 1000000)
        );
      }
    } catch (error: any) {
      this.editions = [];
    }
  }

  async findSubscriptionTypes(): Promise<void> {
    try {
      this.subscriptionTypes = await lastValueFrom(
        this.kpiService
          .findSubscriptionTypesWithUpgradeOrDowngrade(
            this.form.value.editionId
          )
          .pipe(map((data) => data.result))
      );
    } catch (error: any) {
      this.subscriptionTypes = [];
    }
  }

  async findOldSubscriptionTypes(): Promise<void> {
    try {
      this.oldSubscriptionTypes = await lastValueFrom(
        this.kpiService
          .findSubscriptionTypesWithUpgradeOrDowngrade(
            this.form.value.editionId,
            true
          )
          .pipe(map((data) => data.result))
      );
    } catch (error: any) {
      this.oldSubscriptionTypes = [];
    }
  }

  async changeSubscription(): Promise<void> {
    LoaderService.showLoader();
    const form = { ...this.form.value };
    this.form.controls['editionId'].reset(
      (form.subscriptionId || 0) * 1000000 + ((form.editionId || 0) % 1000000)
    );
    this.navigate();
    LoaderService.showLoader(false);
  }

  async changeEdition(): Promise<void> {
    LoaderService.showLoader();
    this.navigate();
    LoaderService.showLoader(false);
  }

  editionDate(editionId: number): string {
    return FormUtil.editionDate(editionId);
  }

  editionDateName(editionId: number): string {
    return (
      this.datePipe.transform(
        new Date(
          `${((editionId % 1000000) / 100).toFixed(0)}-${editionId % 100}-02`
        ),
        'MMMM/yyyy'
      ) || this.editionDate(editionId)
    );
  }

  navigate(): void {
    this.router.navigate([
      '/dashboard/upgrade-downgrade/' + this.form.value.editionId
    ]);
  }

  findPage = async (request: PageableRequest) => {
    try {
      this.subscribers = await lastValueFrom(
        this.kpiService
          .findUpgradeDowngradeDashboard({
            editionId: this.form.value.editionId,
            oldSubscriptionTypeIds: (
              (request.filters?.find(
                (f) => f.field === 'oldSubscriptionTypeName'
              )?.value as Array<string>) || []
            ).map((s) => Number(s))
          })
          .pipe(
            map((data) => {
              return data.result.map((s) => {
                const subType = this.subscriptionTypes.find(
                  (st) => st.subscriptionTypeId === s.subscriptionTypeId
                );
                const oldSubType = this.oldSubscriptionTypes.find(
                  (st) => st.subscriptionTypeId === s.oldSubscriptionTypeId
                );
                return {
                  ...s,
                  upgrade:
                    getSubscriptionIdsByPriority().indexOf(
                      subType.subscriptionId
                    ) <
                      getSubscriptionIdsByPriority().indexOf(
                        oldSubType.subscriptionId
                      ) && subType.installments >= oldSubType.installments,
                  changePlan:
                    subType.subscriptionTypeId !== oldSubType.subscriptionTypeId
                };
              });
            })
          )
      );
      const elements: Array<SubscriptionTypeEvent> =
        this.oldSubscriptionTypes.map((st) => ({
          installments: st.installments,
          oldSubscriptionTypeId: st.subscriptionTypeId,
          oldSubscriptionTypeName: this.titleCasePipe.transform(
            SubscriptionEnum[st.subscriptionId] +
              ' ' +
              st.name.replace('Anual.', 'Anual/mês')
          )
        }));
      this.subscribers.forEach((s) => {
        const el = elements.find(
          (e) => e.oldSubscriptionTypeId === s.oldSubscriptionTypeId
        );
        el[s.subscriptionTypeId] = (el[s.subscriptionTypeId] || 0) + 1;
      });
      const total = {
        oldSubscriptionTypeId: Number.MAX_VALUE,
        oldSubscriptionTypeName: 'Total'
      };
      this.subscriptionTypes.forEach((st) => {
        total[st.subscriptionTypeId] = this.subscribers.filter(
          (s) => s.subscriptionTypeId === st.subscriptionTypeId
        ).length;
      });
      elements.push(total);
      return new Promise((resolve) =>
        resolve({
          content: elements
        })
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  };

  get edition(): Edition | undefined {
    return this.editions?.find(
      (e) => e.editionId === this.form.value.editionId
    );
  }

  get upgradeCount(): number {
    return (
      this.subscribers?.filter((s) => s.changePlan && s.upgrade)?.length || 0
    );
  }

  get downgradeCount(): number {
    return (
      this.subscribers?.filter((s) => s.changePlan && !s.upgrade)?.length || 0
    );
  }

  get samePlanCount(): number {
    return this.subscribers?.filter((s) => !s.changePlan)?.length || 0;
  }
}

<div class="card-header" *ngIf="billingAddresses?.length">
  <p>Endereço de cobrança</p>
</div>
<div class="card">
  <div class="card-body" *ngFor="let address of billingAddresses">
    <app-address-card
      [address]="address"
      [subscribers]="subscribers"
      [person]="person"
    />
    <p-divider />
  </div>
</div>

<div class="card-header" *ngIf="shippingAddresses?.length">
  <p>Endereço de entrega</p>
</div>
<div class="card">
  <div class="card-body" *ngFor="let address of shippingAddresses">
    <app-address-card
      [address]="address"
      [subscribers]="subscribers"
      [person]="person"
    />
    <p-divider />
  </div>
</div>

<h5 *ngIf="!addresses?.length">
  {{ person?.name }} ainda não cadastrou seu endereço
</h5>

<form [formGroup]="form" *ngIf="form" class="modal-form" (submit)="submit()">
  <span class="p-float-label" *ngIf="!incidentTypeId">
    <p-dropdown
      *ngIf="incidentTypes"
      [options]="incidentTypes"
      optionLabel="incident"
      optionValue="incidentTypeId"
      formControlName="incidentTypeId"
      inputId="incidentTypeId"
      name="incidentTypeId"
      appendTo="body"
      (onChange)="enableFields()"
    >
      <ng-template pTemplate="item" let-type>
        <span [pTooltip]="type.description">
          {{ type.incident }} ({{ type.areaOfInterest }})
        </span>
      </ng-template>
    </p-dropdown>
    <label for="incidentTypeId">Ocorrência</label>
  </span>
  <ng-container *ngIf="form.controls['incidentTypeId']?.valid">
    <span class="p-float-label">
      <p-autoComplete
        [suggestions]="productVariants"
        [lazy]="true"
        formControlName="productVariant"
        (completeMethod)="searchProductVariants($event.query)"
        inputId="productVariant"
        name="productVariant"
        (onSelect)="productChange($event.value)"
        placeholder="Pesquise pelo Nome, Marca ou SKU"
        filterBy="productVariantName"
        optionLabel="productVariantName"
        appendTo="body"
        (onClear)="productChange(null)"
        [showClear]="true"
      >
        <ng-template let-product pTemplate="item">
          {{ product.productVariantName }}
          <ng-container *ngIf="product.externalId"
            >(SKU: {{ product.externalId }})</ng-container
          >
          {{ product.brandName }}
        </ng-template>
      </p-autoComplete>
      <label for="productVariant">Variante</label>
    </span>
    <span class="p-float-label">
      <p-autoComplete
        [suggestions]="persons"
        [lazy]="true"
        formControlName="person"
        (completeMethod)="searchPersons($event.query)"
        inputId="person"
        name="person"
        (onSelect)="personChange($event.value)"
        placeholder="Pesquise pelo Nome, Email ou CPF"
        filterBy="name,lastName,cpf,email"
        optionLabel="username"
        appendTo="body"
        (onClear)="personChange(null)"
        [showClear]="true"
        (onKeyUp)="clearPersons($event)"
      >
        <ng-template let-person pTemplate="item">
          <ng-container *ngIf="person.cpf">
            {{ formattedCPF(person.cpf) }} -
          </ng-container>
          {{ person.name }} {{ person.lastName }} ({{ person.username }})
        </ng-template>
      </p-autoComplete>
      <label for="person">Assinante</label>
    </span>
    <span class="p-float-label">
      <textarea
        name="description"
        id="description"
        cols="30"
        rows="10"
        pInputTextarea
        formControlName="description"
        placeholder="Descreva o que houve"
      ></textarea>
      <label for="description">Descrição</label>
      <small
        [class.danger]="
          form.controls['description'].touched &&
          form.controls['description'].dirty &&
          !form.controls['description'].valid
        "
        >{{ form.value.description?.length || 0 }}/400 caracteres</small
      >
    </span>
  </ng-container>
  <span class="buttons">
    <p-button
      type="button"
      (onClick)="ref.close()"
      label="Voltar"
      severity="danger"
    />
    <p-button type="submit" [disabled]="!form.valid" label="Salvar" />
  </span>
</form>
<p-toast></p-toast>

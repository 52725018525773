<form *ngIf="form" [formGroup]="form" class="form-b4a" (submit)="submit()">
  <p-fieldset legend="Informações básicas">
    <span class="p-float-label">
      <input id="name" type="text" pInputText formControlName="name" />
      <label for="name">Nome do produto *</label>
    </span>
    <span class="p-float-label">
      <input id="slug" type="text" pInputText formControlName="slug" />
      <label for="slug">Slug *</label>
    </span>
    <span class="p-float-label">
      <p-dropdown
        formControlName="gender"
        inputId="gender"
        [options]="genders"
        optionValue="value"
        optionLabel="name"
        appendTo="body"
        [autoDisplayFirst]="false"
      />
      <label for="gender">Gênero *</label>
    </span>
    <span class="p-float-label">
      <input id="brand" type="text" pInputText formControlName="brand" />
      <label for="brand">Marca *</label>
    </span>
    <span class="p-float-label">
      <input id="weight" type="number" pInputText formControlName="weight" />
      <label for="weight">Peso *</label>
    </span>
    <span class="p-float-label">
      <input id="type" type="text" pInputText formControlName="type" />
      <label for="type">Tipo do produto</label>
    </span>
    <span class="p-float-label" *ngIf="categories?.length">
      <p-dropdown
        [virtualScroll]="true"
        [virtualScrollItemSize]="38"
        formControlName="category"
        [options]="categoriesForDropdown"
        appendTo="body"
        id="category"
        [filter]="true"
        (onChange)="findCategoryTree(form.value.category?.id)"
      />
      <label for="category">Categoria</label>
    </span>
    <span class="p-float-label">
      <input type="text" pInputText formControlName="categoryTree" />
      <label for="categoryTree">Árvore de categoria</label>
    </span>
    <span class="p-float-label">
      <p-dropdown
        formControlName="label"
        inputId="label"
        [options]="labels"
        optionValue="value"
        optionLabel="name"
        appendTo="body"
        [autoDisplayFirst]="true"
      />
      <label for="label">Label</label>
    </span>
    <span class="p-input-checkbox">
      <p-checkbox
        formControlName="published"
        [binary]="true"
        inputId="published"
      ></p-checkbox>
      <label for="published">Publicado?</label>
    </span>
  </p-fieldset>
  <p-fieldset legend="Preço" formGroupName="price">
    <span class="p-float-label">
      <p-inputNumber
        id="fullPrice"
        formControlName="fullPrice"
        prefix="R$"
        [maxFractionDigits]="2"
        [minFractionDigits]="2"
        [min]="0.01"
        (onInput)="recalculatePrices()"
        (onBlur)="recalculatePrices()"
        (onKeyDown)="recalculatePrices()"
      />
      <label for="fullPrice">Preço cheio *</label>
    </span>
    <span class="p-float-label">
      <p-inputNumber
        (onInput)="recalculatePrices()"
        (onBlur)="recalculatePrices()"
        (onKeyDown)="recalculatePrices()"
        id="price"
        formControlName="price"
        prefix="R$"
        [maxFractionDigits]="2"
        [minFractionDigits]="2"
        [min]="0.01"
      />
      <label for="price">Preço *</label>
    </span>
    <span class="p-float-label">
      <p-inputNumber
        (onInput)="recalculatePrices()"
        (onBlur)="recalculatePrices()"
        (onKeyDown)="recalculatePrices()"
        id="subscriberDiscount"
        type="number"
        suffix="%"
        formControlName="subscriberDiscountPercentage"
        [maxFractionDigits]="2"
        [minFractionDigits]="2"
        [max]="100"
        [min]="0"
      />
      <label for="subscriberDiscount">Desconto de assinante *</label>
    </span>
    <span class="p-float-label">
      <p-inputNumber
        id="subscriberPrice"
        prefix="R$"
        formControlName="subscriberPrice"
        [maxFractionDigits]="2"
        [minFractionDigits]="2"
      />
      <label for="subscriberPrice">Preço de assinate *</label>
    </span>
    <span class="p-float-label">
      <p-inputNumber
        id="fullPriceDiscountPercentage"
        formControlName="fullPriceDiscountPercentage"
        suffix="%"
        [maxFractionDigits]="2"
        [minFractionDigits]="2"
      />
      <label for="fullPriceDiscountPercentage">Desconto preço cheio *</label>
    </span>
    <span class="p-float-label">
      <p-inputNumber
        id="costPrice"
        formControlName="costPrice"
        prefix="R$"
        [maxFractionDigits]="2"
        [minFractionDigits]="2"
      />
      <label for="fullPriceDiscountPercentage">Preço de custo</label>
    </span>
  </p-fieldset>

  <p-fieldset legend="Fiscal">
    <span class="p-float-label">
      <input id="EAN" type="text" pInputText formControlName="EAN" />
      <label for="EAN">EAN/GTIN</label>
    </span>
    <span class="p-float-label">
      <input id="NCM" type="text" pInputText formControlName="NCM" />
      <label for="NCM">NCM</label>
    </span>
    <span class="p-float-label">
      <input id="CEST" type="text" pInputText formControlName="CEST" />
      <label for="CEST">CEST</label>
    </span>
  </p-fieldset>

  <p-fieldset legend="Metadata" formGroupName="metadata">
    <span class="p-float-label">
      <input id="title" type="text" pInputText formControlName="title" />
      <label for="title">Título *</label>
    </span>
    <span class="p-float-label">
      <input
        id="description"
        type="text"
        pInputText
        formControlName="description"
      />
      <label for="description">Descrição *</label>
    </span>
  </p-fieldset>

  <p-fieldset legend="Tags">
    <span class="p-float-label full-w">
      <p-chips
        formControlName="tags"
        [separator]="chipRegex"
        inputId="tags"
        name="tags"
        [addOnTab]="true"
        [addOnBlur]="true"
        [showClear]="true"
        (onAdd)="addTag($event)"
        (onBlur)="addTag($event)"
        (onClear)="form.controls['tags'].setValue([])"
      />
      <label for="tags">Tags</label>
      <small>Utilize "," ou "Enter" para adicionar</small>
    </span>
    <span class="p-float-label">
      <p-autoComplete
        [suggestions]="filterOptionsAvailable"
        formControlName="filters"
        filterBy="description"
        optionLabel="id"
        appendTo="body"
        [lazy]="false"
        [multiple]="true"
        (completeMethod)="searchFilters($event)"
        [showClear]="true"
        (onClear)="categoryForm.controls['filters'].setValue([])"
      />
      <label for="filters">Fitros</label>
    </span>
  </p-fieldset>

  <div class="form-footer">
    <div class="info">
      <div *ngIf="product">
        <p>*Última alteração em: {{ product.updatedAt | date : 'short' }}</p>
      </div>
    </div>
    <div class="action">
      <p-button
        type="submit"
        pRipple
        label="Salvar"
        severity="primary"
        [disabled]="!form.valid"
      >
      </p-button>
    </div>
  </div>
</form>
<p-toast />

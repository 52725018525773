<app-table
  *ngIf="cols && productId && fixedFilters"
  [cols]="cols"
  modelName="movimentações"
  sortField="movementDate"
  [sortOrder]="1"
  [service]="movementService"
  [findPage]="findPage"
  [addButton]="false"
  [dropdownFilters]="dropdownFilters"
  [fixedFilters]="fixedFilters"
  [changeColumns]="true"
></app-table>

<form
  [formGroup]="form"
  (submit)="savePrice()"
  class="form-b4a form-b4a__vertical"
  *ngIf="form"
>
  <span class="p-float-label">
    <p-inputNumber
      id="newPrice"
      prefix="R$"
      inputId="currency-brazil"
      [minFractionDigits]="2"
      [maxFractionDigits]="2"
      formControlName="newPrice"
      pAutoFocus
      [autofocus]="true"
    />
    <label for="newPrice">Valor da mensalidade (R$) *</label>
    <small *ngIf="subscriptionType?.installments > 1 && form.value.newPrice"
      >{{
        form.value.newPrice * subscriptionType.installments | currency : 'BRL'
      }}
      em {{ subscriptionType.installments }}x</small
    >
    <small
      *ngIf="
        form.controls['newPrice'].invalid && form.controls['newPrice'].dirty
      "
      class="error"
    >
      * O valor da mensalidade é obrigatório
    </small>
  </span>

  <span class="p-float-label">
    <p-calendar
      formControlName="dateChanged"
      inputId="dateChanged"
      dateFormat="dd/mm/yy"
      (onSelect)="validateMaxDateToRenew()"
      [readonlyInput]="true"
      appendTo="body"
      [minDate]="tomorrow"
    />
    <label for="dateChanged"
      >Data da alteração (Aquisição e Reativação) *</label
    >
    <small
      class="error"
      *ngIf="
        form.controls['dateChanged'].invalid &&
        form.controls['dateChanged'].dirty &&
        form.controls['dateChanged'].touched
      "
      >* A data da alteração é obrigatória</small
    >
  </span>

  <span class="p-float-label">
    <p-calendar
      formControlName="maxDateToRenew"
      inputId="maxDateToRenew"
      dateFormat="dd/mm/yy"
      appendTo="body"
      (onSelect)="validateMaxDateToRenew()"
      [readonlyInput]="true"
    />
    <label for="maxDateToRenew">Data da alteração (Troca de plano) *</label>
    <small
      class="error"
      *ngIf="
        form.controls['maxDateToRenew']?.errors?.required &&
        form.controls['maxDateToRenew']?.dirty &&
        form.controls['maxDateToRenew']?.touched
      "
      >* A data de alteração é obrigatória</small
    >
    <small class="error" *ngIf="form.controls['maxDateToRenew']?.errors?.before"
      >* Data da alterção (troca de plano) não pode ser inferior a data para
      aquisição/reativação</small
    >
  </span>

  <div class="buttons">
    <p-button label="Inserir" type="submit" [disabled]="form.invalid" />
  </div>
</form>

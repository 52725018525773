import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Category } from '@infrab4a/connect';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { FileUpload } from 'primeng/fileupload';
import { lastValueFrom, map } from 'rxjs';
import {
  FileControllerService,
  Product,
  ProductControllerService
} from 'src/app/admin-api';
import { ProductDiffModalComponent } from 'src/app/components/product-diff-modal/product-diff-modal.component';
import { TableColumn } from 'src/app/components/table';
import { ShopCategoryService } from 'src/app/connect-api/api/shop/shop-category.service';
import { ShopProductService } from 'src/app/connect-api/api/shop/shop-product.service';
import { ShopProduct } from 'src/app/connect-api/models/ShopProduct';
import { VariantDTO } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';
import { ProductDetailsComponent } from '../../products/product-details/product-details.component';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrl: './product-detail.component.scss',
  providers: [DatePipe]
})
export class ProductDetailComponent implements OnInit {
  @Input() productId: string;

  private shopProductService = inject(ShopProductService);
  private productService = inject(ProductControllerService);
  private categoryService = inject(ShopCategoryService);
  private confirmationService = inject(ConfirmationService);
  private messageService = inject(MessageService);
  private fileService = inject(FileControllerService);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private title = inject(Title);
  private dialog = inject(DialogService);

  categories: Category[];
  selectedTab = 0;
  product: Partial<ShopProduct>;
  productVariants: Array<VariantDTO>;

  rating = new FormControl(0);
  variantCols = [
    new TableColumn.Builder()
      .setHeader('Atributo')
      .setField('grade.title')
      .setCondition('contains')
      .setRouterLink('product/')
      .setRouterLinkFieldName('id')
      .build(),
    new TableColumn.Builder()
      .setHeader('Valor')
      .setField('grade.value')
      .setCondition('contains')
      .setRouterLink('product/')
      .setRouterLinkFieldName('id')
      .build(),
    new TableColumn('EAN', 'EAN', true, 'text', 'product/', 'id'),
    new TableColumn('SKU', 'sku', true, 'text', 'product/', 'id'),
    new TableColumn(
      'Peso (g)',
      'weight',
      true,
      'formattedNumber',
      undefined,
      undefined,
      true,
      'equals'
    ),
    new TableColumn.Builder()
      .setHeader('Preço custo')
      .setField('costPrice')
      .setCondition('gte')
      .setType('currency')
      .build(),
    new TableColumn.Builder()
      .setHeader('Estoque')
      .setField('stock.quantity')
      .setCondition('gte')
      .setType('formattedInteger')
      .build(),
    new TableColumn.Builder()
      .setHeader('Última atualização')
      .setField('updatedAt')
      .setCondition('between')
      .setType('date')
      .build()
  ];
  productVariant: VariantDTO;
  categoryTree: Array<Category>;
  categoryTreeLabel = 'Sem categoria';
  subscriptionProduct: Product;

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader();
    await this.findProduct();
    this.title.setTitle(
      this.product?.metadata?.title ||
        this.product?.metadata?.description ||
        this.product.name
    );
    await Promise.all([this.findProductVariants(), this.findCategories()]);
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['tab'] && !Number.isNaN(params['tab'])) {
        this.selectedTab = Number(params['tab']);
      }
    });
    LoaderService.showLoader(false);
  }

  async findProduct(): Promise<void> {
    try {
      this.shopProduct = await this.shopProductService.getProductById(
        this.productId
      );
      await this.findSubscriptionProduct();
      if (this.product?.category?.id) await this.findCategoryTree();
    } catch (error) {
      AppDialogService.showErrorDialog(error, true, 'Produto não encontrado');
    }
  }

  async findProductVariants(): Promise<void> {
    try {
      if (this.product?.hasVariants)
        this.productVariants = (
          await this.shopProductService.findProductVariants(
            this.productId.toString()
          )
        ).reduce((variants, v) => {
          variants = variants.concat(
            v.grade.map((g) => ({ ...v, grade: g } as VariantDTO))
          );
          return variants;
        }, []);
      else this.productVariants = [];
    } catch (error) {
      this.productVariants = [];
      AppDialogService.showErrorDialog(error);
    }
  }

  async findCategories(): Promise<void> {
    try {
      this.categories = await this.categoryService.getCategoriesForProduct();
      this.categories.sort((c1, c2) => {
        if (
          FormUtil.semAcento(c1.name).toUpperCase() <
          FormUtil.semAcento(c2.name).toUpperCase()
        )
          return -1;
        if (
          FormUtil.semAcento(c1.name).toUpperCase() >
          FormUtil.semAcento(c2.name).toUpperCase()
        )
          return 1;
        return Number(c1.id) - Number(c2.id);
      });
    } catch (error) {
      this.categories = [];
      AppDialogService.showErrorDialog(error);
    }
  }

  confirmDeleteImage(image: string, $event: any) {
    this.confirmationService.confirm({
      target: $event.target,
      message: 'Deseja excluir a imagem?',
      header: 'Atenção',
      icon: 'pi pi-exclamation-triangle danger',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      acceptButtonStyleClass: 'p-button-primary',
      rejectButtonStyleClass: 'p-button-danger',
      accept: async () => {
        LoaderService.showLoader();
        try {
          await this.fileService.deleteFile(image);
          await this.shopProductService.updateProduct({
            id: this.product.id,
            images: this.product.images.filter((i) => i !== image)
          });
          await this.findProduct();
          this.messageService.add({
            severity: 'success',
            summary: 'Sucesso',
            detail: 'Imagem removida com sucesso'
          });
        } catch (error) {
          AppDialogService.showErrorDialog(error);
        } finally {
          LoaderService.showLoader(false);
        }
      }
    });
  }

  tabChanged(index: number) {
    this.router.navigate([`/shop-products/catalog/product/${this.productId}`], {
      queryParams: { tab: index },
      queryParamsHandling: 'merge'
    });
  }

  async findCategoryTree(): Promise<void> {
    try {
      if (this.product?.category?.id) {
        this.categoryTree = await this.categoryService.getCategoriesTree(
          this.product?.category?.id
        );
        this.categoryTreeLabel =
          this.categoryTree
            ?.reverse()
            .map(
              (c) =>
                `<a href="${window.location.origin}/shop-products/categories/category/${c.id}" class="data-link">${c.reference} ${c.name}</a>`
            )
            .toString()
            .replaceAll(',', ' > ') || 'Sem categoria';
      }
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async onFileSelected(
    $event: { files: File[] },
    fileUpload: FileUpload
  ): Promise<void> {
    try {
      LoaderService.showLoader();
      const results = await Promise.all(
        $event.files.map((file, i) =>
          lastValueFrom(
            this.fileService
              .uploadFileForm(
                '/admin/shop/products-images/' +
                  this.product.id +
                  '_' +
                  new Date().getTime() +
                  '_' +
                  i +
                  '.' +
                  file.name.split('.'[1]),
                file
              )
              .pipe(map((data) => data.result))
          )
        )
      );
      const images = (this.product.images || []).concat(results);
      await this.shopProductService.updateProduct({
        id: this.product.id,
        images
      });
      await this.findProduct();
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso',
        detail: 'Imagem adiciona com sucesso'
      });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      fileUpload.clear();
      LoaderService.showLoader(false);
    }
  }

  async saveImageSort(images: Array<string>): Promise<void> {
    try {
      LoaderService.showLoader();
      await this.shopProductService.updateProduct({
        id: this.product.id,
        images
      });
      await this.findProduct();
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso',
        detail: 'Ordem das imagens alterada com sucesso.'
      });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async findSubscriptionProduct() {
    if (this.product?.EAN)
      try {
        const product = await lastValueFrom(
          this.productService
            .findProductsTable({
              page: 0,
              pageSize: 1,
              sortBy: 'productId',
              sortDirection: 'desc',
              filters: [
                {
                  condition: 'contains',
                  field: 'internalEAN',
                  value: this.product.EAN,
                  fieldType: 'text'
                }
              ]
            })
            .pipe(map((data) => data.result?.content[0]))
        );
        if (product)
          this.subscriptionProduct = await lastValueFrom(
            this.productService
              .findProductById(product.productId)
              .pipe(map((data) => data.result))
          );
      } catch (error) {
        AppDialogService.showErrorDialog(error);
      }
  }

  async syncInfo(): Promise<void> {
    this.dialog
      .open(ProductDiffModalComponent, {
        data: {
          product: this.subscriptionProduct,
          shopProduct: this.product
        },
        width: '60%',
        height: '90vh',
        header: 'Sincronizar ' + this.product.name,
        maximizable: true
      })
      .onClose.subscribe(async (success) => {
        if (success) {
          LoaderService.showLoader();
          await Promise.all([this.findProduct()]);
          this.messageService.add({
            summary: 'Sucesso',
            severity: 'success',
            detail: 'Produto sincronizado com sucesso.'
          });
          LoaderService.showLoader(false);
        }
      });
  }

  private set shopProduct(product: Partial<ShopProduct>) {
    this.product = product;
    this.rating.setValue(product.rate || 0);
  }

  get toSync() {
    return ProductDetailsComponent.fieldsToSync(
      this.product,
      this.subscriptionProduct
    );
  }

  get toSyncString() {
    return (
      '\n' +
      this.toSync
        ?.map((f) => `- ${f.label}`)
        .toString()
        .replaceAll(',', '\n')
    );
  }

  get avgVariantsCostPrice() {
    return (
      (this.productVariants?.reduce(
        (sum, pv) => (sum += pv?.costPrice || 0),
        0
      ) || 0) / (this.productVariants?.length || 1)
    );
  }
}

<ng-container *ngIf="edition">
  <p>
    <strong>ShippingOrder:</strong>
    {{ edition.shippingOrderId }}
  </p>
  <p>
    <strong>Chave NFE:</strong>
    {{ edition.chaveNFE }}
  </p>
</ng-container>
<ng-container *ngIf="order">
  <p>
    <strong>Nº Pedido:</strong>
    {{ order.orderNumber }}
  </p>
  <p *ngIf="order.pedidov">
    <strong>Pedido V:</strong>
    {{ order.pedidov }}
  </p>
</ng-container>
<hr />
<ng-container *ngFor="let tracking of trackingHistory; let i = index">
  <h4>
    {{ tracking.shippingServiceName }}
    <span class="status status--active" *ngIf="i === 0"
      ><i class="pi pi-truck"></i> Ativo</span
    >
  </h4>
  <app-table
    [lazy]="false"
    [elements]="tracking.history"
    [cols]="cols"
    [showHeader]="false"
    modelName="registros"
    sortField="data_do_evento"
    [sortOrder]="1"
    [paginator]="false"
  />
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { ImageCropperModalModule } from 'src/app/components/image-cropper-modal/image-cropper-modal.module';
import { AppTableModule } from 'src/app/components/table/app-table.module';
import { ArticleDetailsComponent } from './article-details/article-details.component';
import { ArticlesComponent } from './articles/articles.component';
import { ContentRoutingModule } from './content-routing.module';

@NgModule({
  declarations: [ArticlesComponent, ArticleDetailsComponent],
  imports: [
    CommonModule,
    ContentRoutingModule,
    AppTableModule,
    ReactiveFormsModule,
    ButtonModule,
    EditorModule,
    FileUploadModule,
    InputTextModule,
    CalendarModule,
    InputSwitchModule,
    DropdownModule,
    ImageCropperModalModule,
    CheckboxModule
  ]
})
export class ContentModule {}

/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Dados do template de email
 */
export interface EmailTemplateResponse {
  /**
   * ID do template
   */
  id?: number;
  /**
   * Nome único do template
   */
  templateName?: string;
  /**
   * ID do template no provedor
   */
  templateId?: string;
  /**
   * Assunto padrão do email
   */
  subject?: string;
  /**
   * Subtítulo do template
   */
  subtitle?: string;
  /**
   * Descrição do template
   */
  description?: string;
  /**
   * Indica se o template está ativo
   */
  active?: boolean;
  /**
   * Usuário que criou o template
   */
  createdBy?: string;
  /**
   * Data de criação
   */
  createdAt?: Date;
  /**
   * Usuário que atualizou o template
   */
  updatedBy?: string;
  /**
   * Data da última atualização
   */
  updatedAt?: Date;
  /**
   * Usuário que desativou o template
   */
  deactivatedBy?: string;
  /**
   * Data/hora da desativação
   */
  deactivatedAt?: Date;
}

<form class="modal-form" (submit)="submit()" [formGroup]="form" *ngIf="form">
  <span class="p-float-label">
    <input
      type="text"
      pInputText
      pAutoFocus
      [autofocus]="true"
      formControlName="title"
      name="title"
    />
    <label for="title">Enunciado *</label>
  </span>
  <span class="p-float-label">
    <p-dropdown
      inputId="typeId"
      formControlName="typeId"
      [autoDiplayFirst]="false"
      (onChange)="changeType() && validateMinAndMax()"
      [options]="questionTypes"
      appendTo="body"
    >
      <ng-template pTemplate="item" let-type>
        <span
          class="tooltip-item"
          [pTooltip]="type.description"
          tooltipPosition="right"
          >{{ type.label }}</span
        >
      </ng-template>
    </p-dropdown>
    <label for="typeId">Tipo de pergunta *</label>
    <small *ngIf="form.value.typeId">* {{ questionTypeDescription }}</small>
  </span>
  <span class="p-float-label" *ngIf="form.value.typeId === 4">
    <p-inputNumber
      (onInput)="validateMinAndMax()"
      [min]="0"
      formControlName="minAnswers"
      name="minAnswers"
      inputId="minAnswers"
      [max]="options?.length ? options.length - 1 : undefined"
    />
    <label for="minAnswers">Mín. Respostas *</label>
    <small
      class="danger"
      *ngIf="
        form.controls['minAnswers'].touched &&
        form.controls['minAnswers'].dirty &&
        form.controls['minAnswers'].errors?.min
      "
    >
      * Mínimo {{ form.controls['minAnswers'].errors?.min.min }}
    </small>
  </span>
  <span class="p-float-label" *ngIf="form.value.typeId === 4">
    <p-inputNumber
      (onInput)="validateMinAndMax()"
      [min]="1"
      formControlName="maxAnswers"
      name="maxAnswers"
      inputId="maxAnswers"
    />
    <label for="maxAnswers">Máx. Respostas *</label>
    <ng-container
      *ngIf="
        form.controls['maxAnswers'].touched &&
        form.controls['maxAnswers'].dirty &&
        form.controls['maxAnswers'].errors?.min
      "
    >
      <small class="danger">
        * Mínimo {{ form.controls['maxAnswers'].errors?.min.min }}
      </small>
      <br />
    </ng-container>
  </span>
  <!-- <span
    class="p-float-label"
    *ngIf="form.value.typeId === 2 || form.value.typeId === 5"
  >
    <p-inputNumber
      (onInput)="validateMinAndMax()"
      [min]="1"
      formControlName="minCount"
      name="minCount"
      inputId="minCount"
      [max]="4000"
    />
    <label for="minCount">Mín. Caracteres *</label>
    <small
      class="danger"
      *ngIf="
        form.controls['minCount'].touched &&
        form.controls['minCount'].dirty &&
        form.controls['minCount'].errors?.min
      "
    >
      * Mínimo {{ form.controls['minCount'].errors?.min.min }}
    </small>
  </span> -->
  <span
    class="p-float-label"
    *ngIf="form.value.typeId === 2 || form.value.typeId === 5"
  >
    <p-inputNumber
      (onInput)="validateMinAndMax()"
      [min]="form.value.minCount"
      formControlName="maxCount"
      name="maxCount"
      inputId="maxCount"
      [max]="4000"
    />
    <label for="maxCount">Máx. Caracteres *</label>
    <small
      class="danger"
      *ngIf="
        form.controls['maxCount'].touched &&
        form.controls['maxCount'].dirty &&
        form.controls['maxCount'].errors?.min
      "
    >
      * Mínimo {{ form.controls['maxCount'].errors?.min.min }}
    </small>
  </span>
  <span class="input-switch">
    <label for="enabled">Inativo</label>
    <p-inputSwitch
      formControlName="status"
      inputId="status"
      [trueValue]="1"
      [falseValue]="0"
    />
    <label for="status">Ativo</label>
  </span>
  <span class="question-media" *ngIf="form.value.mediaUrl">
    <iframe
      [src]="safeMediaUrl"
      *ngIf="mediaType === 'video'"
      height="200px"
    ></iframe>
    <img [src]="form.value.mediaUrl" *ngIf="mediaType === 'img'" />
  </span>
  <div class="file-upload">
    <span class="p-float-label">
      <input type="text" pInputText formControlName="mediaUrl" />
      <label for="mediaUrl">Mídia</label>
      <p-fileUpload
        mode="basic"
        chooseLabel="Selecionar"
        name="file"
        [auto]="true"
        accept="image/*"
        [maxFileSize]="1000000"
        uploadIcon="pi pi-file"
        [customUpload]="true"
        (uploadHandler)="onUpload($event, 'mediaUrl')"
        id="mediaUrl"
        #fileUpload
        [disabled]="form.controls.mediaUrl.disabled"
      />
    </span>
    <small>* Informe um link ou faça upload de uma imagem (em breve)</small>
  </div>
  <div class="checkbox-group">
    <span
      class="p-input-checkbox"
      *ngIf="!question?.groupId && !form.value.groupId"
    >
      <p-checkbox
        formControlName="horizontalOptions"
        [binary]="true"
        inputId="horizontalOptions"
      />
      <label for="horizontalOptions">Exibir na horizontal</label>
      <small>* Exibir alternativas na horizontal (em breve)</small>
    </span>
    <span class="p-input-checkbox">
      <p-checkbox
        formControlName="showRadioButton"
        [binary]="true"
        inputId="showRadioButton"
      />
      <label for="showRadioButton">Exibir radio-button</label>
      <small>* Exibir/Remover o radio-button das alternativas (em breve)</small>
    </span>
  </div>
  <div class="checkbox-group" *ngIf="!question?.groupId && !form.value.groupId">
    <span class="p-input-checkbox">
      <p-checkbox
        formControlName="isShuffled"
        [binary]="true"
        inputId="isShuffled"
      />
      <label for="isShuffled">Ordem aleatória?</label>
      <small>* Exibir as alternativas em ordem aleatória (em breve)</small>
    </span>
    <span class="p-input-checkbox">
      <p-checkbox
        formControlName="lastQuestion"
        [binary]="true"
        inputId="lastQuestion"
      />
      <label for="lastQuestion">Última pergunta?</label>
      <small
        >* Ao responder esta pergunta, encerrar o formulário (em breve)</small
      >
    </span>
  </div>
  <div class="buttons">
    <p-button
      label="Cancelar"
      severity="danger"
      type="button"
      (onClick)="ref.close()"
    />
    <p-button
      label="Salvar"
      [disabled]="!form.valid"
      severity="primary"
      type="submit"
    />
  </div>
</form>
<p-toast />
<p-confirmDialog />

/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface VoucherSubscriberBilling {
  subscriberBillingId?: number;
  voucherId?: number;
  number?: string;
  secondaryId?: string;
  subscriberBillingStatusId?: number;
  subscriberBillingStatus?: string;
  voucherProviderId?: number;
  voucherProviderName?: string;
  paymentMethod?: number;
  subscriberId?: number;
  editionId?: number;
  amount?: number;
  dateCreated?: Date;
  dateUpdated?: Date;
  subscriptionTypeName?: string;
}

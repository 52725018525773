export class EditionId {
  public static currentEdition(subscriptionId: number): number {
    return (
      subscriptionId * 1000000 +
      new Date().getFullYear() * 100 +
      (new Date().getMonth() + 1)
    );
  }

  public static incrementEdition(editionId: number, increment: number): number {
    const editionDate = new Date(
      `${((editionId % 1000000) / 100).toFixed(0)}-${editionId % 100}-05`
    );
    const incrementedDate = new Date(
      editionDate.setMonth(editionDate.getMonth() + increment)
    );
    return (
      Number((editionId / 1000000).toFixed(0)) * 1000000 +
      incrementedDate.getFullYear() * 100 +
      (incrementedDate.getMonth() + 1)
    );
  }

  public static isBefore(
    editionBefore: number,
    currentEdition: number
  ): boolean {
    return editionBefore % 1000000 < currentEdition % 1000000;
  }

  public static isBeforeOrEqual(
    editionBefore: number,
    currentEdition: number
  ): boolean {
    return editionBefore % 1000000 <= currentEdition % 1000000;
  }

  public static isAfter(editionAfter: number, currentEdition: number): boolean {
    return editionAfter % 1000000 > currentEdition % 1000000;
  }

  public static isAfterOrEqual(
    editionAfter: number,
    currentEdition: number
  ): boolean {
    return editionAfter % 1000000 >= currentEdition % 1000000;
  }

  public static yearMonthReference(editionId: number) {
    return editionId % 1000000;
  }

  public static subscriptionId(editionId: number) {
    return Number((editionId / 1000000).toFixed(0));
  }
}

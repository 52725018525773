import { TitleCasePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { lastValueFrom, map } from 'rxjs';
import { CustomQuery, CustomQueryControllerService } from 'src/app/admin-api';
import { TableColumn } from 'src/app/components/table';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-html-table',
  templateUrl: './html-table.component.html',
  styleUrls: ['./html-table.component.scss'],
  providers: [TitleCasePipe]
})
export class HtmlTableComponent implements OnInit {
  @Input() queryId: string | undefined;

  content: Array<unknown> | undefined;
  cols: Array<TableColumn> | undefined;
  query: CustomQuery | undefined;

  constructor(
    private customQueryService: CustomQueryControllerService,
    private titleCase: TitleCasePipe
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.queryId) {
      LoaderService.showLoader();
      await Promise.all([this.findContent(), this.findQuery()]);
      LoaderService.showLoader(false);
    } else {
      AppDialogService.showErrorDialog(
        { message: 'Query não encontrada' },
        true
      );
    }
  }

  async findQuery(): Promise<void> {
    try {
      this.query = await lastValueFrom(
        this.customQueryService
          .findCustomQueryById(Number(this.queryId))
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findContent(): Promise<void> {
    try {
      this.content = await lastValueFrom(
        this.customQueryService
          .runQueryCommand(Number(this.queryId))
          .pipe(map((data) => data.result))
      );
      if (this.content?.length) {
        this.cols = Object.keys(this.content[0]).map(
          (field) =>
            new TableColumn(
              this.titleCase.transform(field),
              field,
              true,
              'text',
              undefined,
              undefined,
              true,
              'contains'
            )
        );
      }
    } catch (error) {
      AppDialogService.showErrorDialog(error, true);
    }
  }
}

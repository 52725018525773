import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { MessageService } from 'primeng/api';
import { lastValueFrom, map } from 'rxjs';
import {
  ActiveFormAdmin,
  Edition,
  EditionControllerService,
  FeedbackAdminControllerService,
  FeedbackControllerService,
  FeedbackForm,
  FeedbackFormDetail,
  PageableRequest,
  ProductFeedbackAdmin
} from 'src/app/admin-api';
import {
  DropdownFilter,
  PageContent,
  TableColumn,
  TableComponent
} from 'src/app/components/table';
import { EditionId, Role, roleAsObject } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-batch-forms-admin',
  templateUrl: './batch-forms-admin.component.html',
  styleUrl: './batch-forms-admin.component.scss'
})
export class BatchFormsAdminComponent implements OnInit {
  private permissionService = inject(NgxPermissionsService);
  public feedbackService = inject(FeedbackControllerService);
  public feedbackAdminService = inject(FeedbackAdminControllerService);
  private messageService = inject(MessageService);
  private router = inject(Router);
  private editionService = inject(EditionControllerService);

  @ViewChild('productsTable')
  productsTable: TableComponent;

  @ViewChild('activeFormsTable')
  activeFormsTable: TableComponent;

  permissions = false;
  productCols: Array<TableColumn>;
  formsCols: Array<TableColumn>;
  selectedTab = 0;
  currentProducts: Array<ProductFeedbackAdmin> | undefined;
  currentForms: Array<ActiveFormAdmin> | undefined;
  baseForm = new FormControl<FeedbackForm>(null, Validators.required);
  forms: Array<FeedbackFormDetail>;
  formsAvailable: Array<FeedbackFormDetail>;
  dropdownFilters: { [key: string]: Array<DropdownFilter> };
  dateNow = new Date();
  defaultFilterValues = {
    theme:
      this.dateNow.getDate() < 26
        ? (
            EditionId.incrementEdition(EditionId.currentEdition(1), -1) %
            1000000
          ).toString()
        : EditionId.currentEdition(1).toString()
  };
  editions: Array<Edition>;

  async ngOnInit(): Promise<void> {
    this.permissions = await this.permissionService.hasPermission([
      roleAsObject(Role.Full_Administrator).enumValue,
      roleAsObject(Role.Business_Intelligence).enumValue,
      roleAsObject(Role.Customer_Success).enumValue
    ]);
    this.productCols = [
      new TableColumn.Builder()
        .setHeader('Id')
        .setField('productId')
        .setRouterLink('/products/catalog/product/')
        .setRouterLinkFieldName('productId')
        .setType('number')
        .build(),
      new TableColumn.Builder()
        .setHeader('Produto')
        .setField('productName')
        .setCondition('contains')
        .build(),
      new TableColumn.Builder().setHeader('Edição').setField('theme').build(),
      new TableColumn.Builder()
        .setHeader('Id Formulário')
        .setField('activeFeedbackId')
        .setType('number')
        .build(),
      new TableColumn.Builder()
        .setHeader('Formulário ativo')
        .setField('enabled')
        .setType('boolean')
        .build()
    ];
    this.formsCols = [
      new TableColumn.Builder()
        .setHeader('Id formulário')
        .setField('formId')
        .setRouterLink('')
        .setRouterLinkFieldName('formId')
        .setType('number')
        .build(),
      new TableColumn.Builder()
        .setHeader('Id produto')
        .setField('productId')
        .setRouterLink('/products/catalog/product/')
        .setRouterLinkFieldName('productId')
        .setType('number')
        .build(),
      new TableColumn.Builder()
        .setHeader('Título do Formulário')
        .setField('formTitle')
        .setType('text')
        .setCondition('contains')
        .build(),
      new TableColumn.Builder()
        .setHeader('Nome do Produto')
        .setField('productName')
        .setType('text')
        .setCondition('contains')
        .build(),
      new TableColumn.Builder()
        .setHeader('Data de Criação')
        .setField('dateCreated')
        .setType('text')
        .setCondition('contains')
        .build(),
      new TableColumn.Builder()
        .setHeader('Edição')
        .setField('theme')
        .setType('text')
        .setCondition('contains')
        .build()
    ];
    if (this.permissions) {
      this.productCols.unshift(
        new TableColumn.Builder().setType('checkbox').setField(' ').build()
      );
      this.formsCols.unshift(
        new TableColumn.Builder().setType('checkbox').setField(' ').build()
      );
    }
    await this.getProductsEditions();
    this.dropdownFilters = {
      theme: this.editions.map((e) => ({
        label: e.theme,
        value: e.editionId.toString()
      }))
    };
  }

  async cloneForm() {
    LoaderService.showLoader();
    try {
      const result = await lastValueFrom(
        this.feedbackAdminService
          .cloneForms({
            baseFormId: this.baseForm.value.formId,
            products: this.currentProducts
          })
          .pipe(map((data) => data.result))
      );
      this.messageService.add({
        severity: 'success',
        detail: result,
        summary: 'Sucesso'
      });
      delete this.currentProducts;
      this.baseForm.reset();
      await this.productsTable?.refresh(true);
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  async disableForms(forms: Array<ActiveFormAdmin>) {
    LoaderService.showLoader();
    const productIds = forms.map((form) => form.productId);
    try {
      const result = await lastValueFrom(
        this.feedbackAdminService
          .disableForms(productIds)
          .pipe(map((data) => data.result))
      );
      this.messageService.add({
        severity: 'success',
        detail: result,
        summary: 'Sucesso'
      });
      delete this.currentForms;
      await this.activeFormsTable?.refresh(true);
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  get modalFormsColumns(): Array<TableColumn> {
    return this.formsCols.filter(
      (c) => c.type !== 'button' && c.type !== 'checkbox'
    );
  }

  get modalProductsColumns(): Array<TableColumn> {
    return this.productCols.filter(
      (c) => c.type !== 'button' && c.type !== 'checkbox'
    );
  }

  async findFeedbackForms(search: string): Promise<void> {
    try {
      this.forms = await lastValueFrom(
        this.feedbackService
          .findFeedbackFormsTable({
            page: 0,
            pageSize: 5,
            filters: [
              {
                condition: 'contains',
                field: 'formTitle',
                fieldType: 'text',
                value: search
              }
            ],
            sortBy: 'formTitle',
            sortDirection: 'ASC'
          })
          .pipe(map((data) => data.result.content))
      );
      this.formsAvailable = [...this.forms];
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  tabChanged($event: number): void {
    this.router.navigate([`research/feedbacks/`], {
      queryParams: { tab: $event },
      queryParamsHandling: 'merge'
    });
  }

  async findPage(
    request: PageableRequest,
    service: unknown
  ): Promise<PageContent<ProductFeedbackAdmin>> {
    const filterEditionId = request.filters?.find((f) => f.field === 'theme');
    if (filterEditionId?.value) {
      request.filters?.push({
        condition: 'equals',
        field: 'edition',
        fieldType: 'number',
        value: filterEditionId.value
      });
      filterEditionId.value = '';
    }
    const result = lastValueFrom(
      (service as FeedbackControllerService)
        .findProductFeedbackAdmin(request)
        .pipe(map((data) => data.result as PageContent<ProductFeedbackAdmin>))
    );
    return result;
  }

  async getProductsEditions(): Promise<void> {
    try {
      this.editions = await lastValueFrom(
        this.editionService.findEditionsBySubscriptionId(1).pipe(
          map((data) =>
            data.result.map((e) => ({
              ...e,
              editionId: e.editionId % 1000000
            }))
          )
        )
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }
}

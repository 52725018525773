<div class="glampoints" *ngIf="person">
  <div class="summary">
    <p>
      <strong>Glampoints ganhos: </strong>&nbsp;{{
        totalCredit | number : '1.2-2'
      }}
    </p>
    <p>
      <strong>Trocas na vitrine:</strong>&nbsp;{{
        totalSpent | number : '1.2-2'
      }}
    </p>
    <p>
      <strong>Glampoints expirados:</strong>&nbsp;
      {{ totalDebit | number : '1.2-2' }}
    </p>
    <p>
      <strong>Saldo atual:</strong>&nbsp;
      <span
        [class.balance-diff]="balanceDiff"
        [pTooltip]="balanceDiff ? 'Saldo incorreto' : ''"
        tooltipPosition="bottom"
      >
        {{ person.rewardBalance | number : '1.2-2' }}
      </span>
      <p-button
        *ngIf="balanceDiff"
        [rounded]="true"
        size="small"
        [text]="true"
        icon="pi pi-refresh"
        tooltipPosition="bottom"
        pTooltip="Recalcular"
        (onClick)="recomputeBalance()"
      ></p-button>
    </p>
  </div>
  <ng-container *ngIf="rewards">
    <app-table
      [cols]="cols"
      [elements]="rewards"
      [lazy]="false"
      modelName="registros"
      sortField="dateCreated"
      [sortOrder]="1"
      [dropdownFilters]="dropdownFilters"
      addLabel="Dar glampoints"
      [addButtonAction]="true"
      (addButtonClick)="openRewardModal()"
      linkTarget="_action"
      (linkAction)="goToReference($event.value)"
      [actionFields]="['referenceName']"
      [actionButtons]="actionButtons"
      (actionButtonClick)="askCancelTrade($event.$event, $event.item)"
      [emptyMessage]="person.name + ' ainda não recebeu Glampoints'"
      [showFooter]="true"
      [footerTemplate]="footerTemplate"
      [changeColumns]="true"
      tableTitle="Extrato de pontos"
    >
      <ng-template #footerTemplate>
        <tr *ngIf="rewards.length > 1">
          <td colspan="3">Total</td>
          <td>{{ rewardBalance }}</td>
          <td></td>
        </tr>
      </ng-template>
    </app-table>
  </ng-container>
</div>
<p-confirmDialog styleClass="danger"> </p-confirmDialog>
<p-toast></p-toast>

export enum BrandEquity {
  'Não informado' = 0,
  'Detractor' = 1,
  'Unknown' = 2,
  'Low' = 3,
  'Mid' = 4,
  'Strong' = 5
}

export function getAllBrandEquities(): Array<{ label: string; value: number }> {
  const objetos: Array<{ label: string; value: number }> = [];
  for (const [p, propertyValue] of Object.entries(BrandEquity)) {
    try {
      if (Number(propertyValue) >= 0) {
        objetos.push({
          label: `${propertyValue} ${p}`,
          value: Number(propertyValue)
        });
      }
    } catch (error: any) {
      objetos.length;
    }
  }
  return objetos;
}

export function getEquityLabel(id: number): string {
  return (
    getAllBrandEquities().find((e) => e.value === id)?.label ||
    (BrandEquity['Não informado'] as any).label
  );
}

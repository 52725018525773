import {
  Component,
  OnInit,
  WritableSignal,
  computed,
  inject,
  signal
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService, TreeNode } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  Attribute,
  AttributeGroup,
  AttributeOption,
  BeautyProfileAdminControllerService
} from 'src/app/admin-api';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ImageUploadComponent } from '../../image-upload/image-upload.component';

@Component({
  selector: 'app-attribute-option-form',
  templateUrl: './attribute-option-form.component.html',
  styleUrl: './attribute-option-form.component.scss'
})
export class AttributeOptionFormComponent
  extends ImageUploadComponent<AttributeOption>
  implements OnInit
{
  ref: DynamicDialogRef = inject(DynamicDialogRef);
  private config: DynamicDialogConfig = inject(DynamicDialogConfig);
  private beautyProfileAdminService: BeautyProfileAdminControllerService =
    inject(BeautyProfileAdminControllerService);
  private messageService: MessageService = inject(MessageService);

  group: WritableSignal<AttributeGroup> = signal(this.config.data.group);
  attribute: WritableSignal<Attribute> = signal(this.config.data.attribute);
  attributeOption: WritableSignal<AttributeOption> = signal(
    this.config.data.attributeOption
  );
  beautyProfile: WritableSignal<Array<AttributeGroup>> = signal(
    this.config.data.beautyProfile
  );
  availableNext = computed(() => {
    return [
      {
        label: '',
        items: [
          { label: 'Nenhuma', value: 0 },
          {
            label: 'Encerrar formulário',
            value: -1
          }
        ]
      } as {
        label: string;
        value?: number;
        items?: Array<{ label: string; value: number }>;
      }
    ].concat(
      this.beautyProfile()
        ?.filter(
          (g) =>
            g.order >= this.group()?.order &&
            (g.attributes.some(
              (a) => a.order > (this.attribute()?.order || 0) + 1
            ) ||
              g.groups.some((gg) => gg.order > (this.group()?.order || 0) + 1))
        )
        .map((g) => {
          const attributes = g.attributes.filter(
            (a) => a.order > this.attribute()?.order + 1
          );
          return {
            label: `${g.order}. ${g.attributeGroupName}`,
            items: attributes
              .map((a) => ({
                label: `${a.order}. ${a.attributeQuestion || a.attributeName}`,
                value: a.attributeId
              }))
              .concat(
                g.groups
                  .filter((gg) => gg.order > this.group()?.order)
                  .map((gg) => ({
                    label: `${gg.order}. ${
                      gg.attributeGroupQuestion || gg.attributeGroupName
                    }`,
                    value: gg.attributes?.length
                      ? gg.attributes[0].attributeId
                      : gg.attributeGroupId
                  }))
              )
          };
        })
    );
  });
  override form = new FormGroup({
    attributeOptionId: new FormControl<number>(
      {
        value: this.attributeOption()?.attributeOptionId,
        disabled: !this.attributeOption()
      },
      [Validators.required, Validators.min(1)]
    ),
    attributeId: new FormControl<number>(
      {
        value: this.attribute()?.attributeId,
        disabled: !!this.attributeOption()
      },
      [Validators.required, Validators.min(1)]
    ),
    attributeOptionName: new FormControl<string>(
      this.attributeOption()?.attributeOptionName,
      [Validators.required, Validators.minLength(1)]
    ),
    attributeOptionImage: new FormControl<string>(
      this.attributeOption()?.attributeOptionImage
    ),
    attributeOptionImageUrl: new FormControl<string>(
      this.attributeOption()?.attributeOptionImage
    ),
    singleOption: new FormControl<boolean>(
      this.attributeOption() ? this.attributeOption().singleOption : true
    ),
    active: new FormControl<boolean>(
      this.attributeOption() ? this.attributeOption().active : true
    ),
    nextQuestionId: new FormControl<number>({
      value: this.attributeOption()?.nextQuestionId || 0,
      disabled: this.group().attributeGroupReferenceId > 0
    })
  });

  ngOnInit(): void {
    this.form.controls['attributeOptionImage'].valueChanges.subscribe((value) =>
      this.form.controls['attributeOptionImageUrl'].setValue(value)
    );
  }

  async submit(): Promise<void> {
    LoaderService.showLoader();
    try {
      if (this.attributeOption()) {
        this.attributeOption.set(
          await lastValueFrom(
            this.beautyProfileAdminService
              .updateAttributeOption(this.form.value)
              .pipe(map((data) => data.result))
          )
        );
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'Alternativa atualizada com sucesso.'
        });
      } else {
        this.attributeOption.set(
          await lastValueFrom(
            this.beautyProfileAdminService
              .createAttributeOption(this.form.value)
              .pipe(map((data) => data.result))
          )
        );
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'Alternativa criada com sucesso.'
        });
      }
      await this.afterSubmit();
      this.ref.close(this.attributeOption);
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  public override get filePath(): string {
    return '/admin/beauty-profile/';
  }

  private toGroupNode(
    group: AttributeGroup,
    index: number
  ): TreeNode<AttributeGroup> {
    const attributes = group.attributes.filter(
      (a) => a.order > this.attribute()?.order + 1
    );
    return {
      data: group,
      key: 'g' + group.attributeGroupId,
      label: `${index + 1}. ${
        group.attributeGroupQuestion || group.attributeGroupName
      }`,
      children: attributes
        .map((a, index) => this.toAttributeNode(a, index))
        .concat(
          group.groups?.map((g, index) =>
            this.toGroupNode(g, attributes.length + index)
          )
        )
    };
  }

  private toAttributeNode(
    attribute: Attribute,
    index: number
  ): TreeNode<Attribute> {
    return {
      data: attribute,
      key: attribute.attributeId.toString(),
      label: `${index + 1}. ${
        attribute.attributeQuestion || attribute.attributeName
      }`
    };
  }

  override get model(): AttributeOption {
    return this.attributeOption();
  }
  override get modelId(): number {
    return this.attributeOption()?.attributeOptionId;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { ChangeCouponModalComponent } from './change-coupon-modal.component';

@NgModule({
  declarations: [ChangeCouponModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
    DynamicDialogModule
  ],
  exports: [ChangeCouponModalComponent]
})
export class ChangeCouponModalModule {}

/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BelvoOpenFinanceInstitution {
  id?: string;
  active?: boolean;
  name?: string;
  display_name?: string;
  legalEntity_name?: string;
  website?: string;
  logo?: string;
  icon_logo?: string;
  text_logo?: string;
  primary_color?: string;
  country?: string;
  sort_priority?: number;
  description?: string;
}

import { Component, OnInit, ViewChild, inject, signal } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgxPermissionsService } from 'ngx-permissions';
import { ConfirmationService, MessageService, PrimeIcons } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  CompositionControllerService,
  CompositionDetails
} from 'src/app/admin-api';
import {
  TableActionButton,
  TableColumn,
  TableComponent
} from 'src/app/components/table';
import { Role, roleAsObject } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { CompositionsBeautyProfileComponent } from '../compositions-beauty-profile/compositions-beauty-profile.component';

@Component({
  selector: 'app-allocation',
  templateUrl: './allocation.component.html',
  styleUrls: ['./allocation.component.scss'],
  providers: [DialogService, ConfirmationService, MessageService]
})
export class AllocationComponent implements OnInit {
  public compositionService: CompositionControllerService = inject(
    CompositionControllerService
  );
  private title: Title = inject(Title);
  private dialog: DialogService = inject(DialogService);
  private confirmationService: ConfirmationService =
    inject(ConfirmationService);
  private messageService: MessageService = inject(MessageService);
  private ngxPermissionsService: NgxPermissionsService = inject(
    NgxPermissionsService
  );

  @ViewChild(TableComponent)
  table: TableComponent | undefined;

  cols = [
    new TableColumn(
      'Id',
      'compositionId',
      true,
      'number',
      '/operations/compositions/id/',
      'compositionId'
    ),
    new TableColumn(
      'Composição',
      'compositionName',
      true,
      'text',
      '/operations/compositions/id/',
      'compositionId',
      true,
      'contains'
    ),
    new TableColumn(
      'Edição',
      'editionId',
      true,
      'number',
      undefined,
      undefined,
      true,
      'equals'
    ),
    new TableColumn(
      'Preço custo',
      'costTotal',
      true,
      'currency',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Preço venda',
      'saleTotal',
      true,
      'currency',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Preço nota',
      'invoiceTotal',
      true,
      'currency',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Assinantes',
      'subscriberCount',
      true,
      'formattedInteger',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Produtos',
      'productCount',
      true,
      'formattedInteger',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Peso (g)',
      'weight',
      true,
      'formattedNumber',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Data criação',
      'dateCreated',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    )
  ];
  permission = signal(false);

  actionButtons: Array<TableActionButton> = [
    new TableActionButton(
      '',
      'removeComposition',
      PrimeIcons.TRASH,
      undefined,
      '',
      'Excluir composição',
      'bottom',
      true,
      true,
      'danger',
      'small'
    )
  ];
  visibleCols: Array<TableColumn>;

  async ngOnInit(): Promise<void> {
    this.title.setTitle('Alocações - Composições não exportadas');
    this.permission.set(
      await this.ngxPermissionsService.hasPermission([
        roleAsObject(Role.Full_Administrator).enumValue,
        roleAsObject(Role.Admin_Allocation).enumValue,
        roleAsObject(Role.User_Allocation).enumValue
      ])
    );
    if (this.permission()) {
      this.visibleCols = [
        new TableColumn(
          '',
          '',
          false,
          'checkbox',
          undefined,
          undefined,
          true,
          'equals',
          undefined,
          undefined,
          undefined
        )
      ]
        .concat(this.cols)
        .concat([new TableColumn('Ação', '', false, 'button')]);
    } else {
      this.visibleCols = [...this.cols];
    }
  }

  canSelect(composition: CompositionDetails): boolean {
    return !composition.dateSyncERP;
  }

  checkCompositions($event: Array<CompositionDetails>): void {
    this.dialog
      .open(CompositionsBeautyProfileComponent, {
        width: '100%',
        height: '90vh',
        header: 'Validar perfis',
        maximizable: true,
        data: {
          compositions: $event
        }
      })
      .onClose.subscribe(async () => {
        await this.table.pageTable();
      });
  }

  removeComposition(item: CompositionDetails, $event: Event): void {
    this.confirmationService.confirm({
      acceptButtonStyleClass: 'p-button-danger',
      acceptIcon: PrimeIcons.TRASH,
      acceptLabel: 'Excluir',
      rejectButtonStyleClass: 'p-button-primary',
      rejectIcon: PrimeIcons.ARROW_LEFT,
      rejectLabel: 'Voltar',
      target: $event.target,
      message:
        'Tem certeza que deseja remover a composição ' +
        item.compositionName +
        '?',
      accept: async () => {
        LoaderService.showLoader();
        try {
          const detail = await lastValueFrom(
            this.compositionService
              .deleteComposition(item.compositionId)
              .pipe(map((data) => data.result))
          );
          await this.table.pageTable();
          this.messageService.add({
            detail,
            summary: 'Sucesso',
            severity: 'success'
          });
        } catch (error) {
          AppDialogService.showErrorDialog(error);
        }
        LoaderService.showLoader(false);
      }
    });
  }
}

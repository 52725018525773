import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SubscriptionTypesComponent } from 'src/app/pages/subscription-settings/subscription-types/subscription-types.component';
import { BadgeDetailsComponent } from './badge-details/badge-details.component';
import { BadgeComponent } from './badge/badge.component';
import { SubscriptionTypeDetailsComponent } from './subscription-types/subscription-type-details/subscription-type-details.component';

const routes: Routes = [
  {
    path: 'badge',
    component: BadgeComponent
  },
  {
    path: 'badge/:badgeId',
    component: BadgeDetailsComponent
  },
  {
    path: 'subscription-types',
    component: SubscriptionTypesComponent
  },
  {
    path: 'subscription-types/:subscriptionTypeId',
    component: SubscriptionTypeDetailsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SubscriptionSettingsRoutingModule {}

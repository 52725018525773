/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Movement {
  movementId?: number;
  chaveNFE?: string;
  origWarehouseId?: number;
  origName?: string;
  destWarehouseId?: number;
  destPersonId?: number;
  destSubscriberId?: number;
  destEditionId?: number;
  dateCreated?: Date;
  isConfirmed?: number;
  hasAdjustment?: number;
  hasUpdatedStock?: number;
  additionalInfo?: string;
  user?: string;
  isRejected?: number;
  dateUpdated?: Date;
  shippingOrderId?: number;
  orderId?: string;
  dateSyncERP?: Date;
}

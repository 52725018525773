import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxPermissionsService } from 'ngx-permissions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  BadgeActionType,
  Person,
  PersonActionCreateRequest,
  PersonControllerService
} from 'src/app/admin-api';
import { Role, roleAsObject } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-person-action-modal',
  templateUrl: './person-action-modal.component.html',
  styleUrl: './person-action-modal.component.scss'
})
export class PersonActionModalComponent implements OnInit {
  actionForm: FormGroup;
  person: Person;
  actionTypes: Array<BadgeActionType> | undefined;
  permission = false;
  constructor(
    private config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private personService: PersonControllerService,
    private permissionsService: NgxPermissionsService
  ) {
    this.person = this.config.data.person;
    this.actionTypes = this.config.data.actionTypes;
    this.actionForm = new FormGroup({
      personId: new FormControl<number>(
        this.person.personId as number,
        Validators.required
      ),
      referenceId: new FormControl<number | undefined>(
        undefined,
        Validators.required
      ),
      actionType: new FormControl<number | undefined>(
        undefined,
        Validators.required
      ),
      manual: new FormControl<boolean>(true, Validators.required)
    });
  }

  async ngOnInit(): Promise<void> {
    this.permission = await this.permissionsService.hasPermission([
      roleAsObject(Role.Full_Administrator).enumValue
    ]);
  }

  async submit(): Promise<void> {
    if (this.actionForm.valid && this.permission) {
      LoaderService.showLoader(true);
      const action: PersonActionCreateRequest = this.actionForm.value;
      try {
        const personAction = await lastValueFrom(
          this.personService
            .createPersonAction(action)
            .pipe(map((data) => data.result))
        );
        this.ref.close(personAction);
      } catch (error: any) {
        AppDialogService.showErrorDialog(
          error,
          false,
          'Erro ao registrar nova ação'
        );
        LoaderService.showLoader(false);
      }
    }
  }

  get actionType(): BadgeActionType | undefined {
    return this.actionTypes?.find(
      (r) => r.badgeActionTypeId === this.actionForm.value.badgeActionTypeType
    );
  }
}

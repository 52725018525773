<div class="reviews">
  <div>
    <app-table
      *ngIf="cols && ready"
      [cols]="cols"
      pageFunctionName="getReviewsList"
      [service]="productReviewService"
      modelName="Avaliações"
      sortField="createdAt"
      [sortOrder]="1"
      [defaultRows]="10"
      [actionButtons]="actionButtons"
      [dropdownFilters]="dropdownFilters"
      [defaultFilterValues]="{ status: 'NULL' }"
      (actionButtonClick)="buttonClick($event)"
      (emitSelection)="onSelection($event)"
      [addButton]="false"
      [changeColumns]="true"
      [rowSelectable]="showCheckbox"
      selectionActionLabel="Validar Avaliações"
      selectionActionTooltip="Aprovar/Reprovar as avaliações selecionadas"
      tableTitle="Loja - Avaliações dos Produtos"
    />
  </div>
</div>

<p-dialog
  *ngIf="currentReview"
  [(visible)]="showDetailModal"
  [modal]="true"
  [style]="{ width: '600px' }"
  header="Detalhes da Avaliação"
>
  <div class="reviewDialog">
    <h2 class="title">{{ currentReview.title }}</h2>
    <div class="review">{{ currentReview.review }}</div>
    <p-rating
      [(ngModel)]="currentReview.rate"
      [stars]="5"
      [readonly]="true"
      [cancel]="false"
    >
      <ng-template pTemplate="onicon">
        <i class="pi pi-star-fill"></i>
      </ng-template>
      <ng-template pTemplate="officon">
        <i class="pi pi-star"></i>
      </ng-template>
    </p-rating>
    <div class="buttons">
      <p-button
        *ngIf="currentReview.status === null"
        label="Aprovar"
        styleClass="p-button-success"
        (onClick)="approveReview(currentReview)"
      />
      <p-button
        *ngIf="currentReview.status === null"
        label="Reprovar"
        styleClass="p-button-danger"
        (onClick)="rejectReview(currentReview)"
      />
    </div>
  </div>
</p-dialog>

<p-dialog
  *ngIf="currentReviews?.length"
  [(visible)]="showSelectionModal"
  [modal]="true"
  [style]="{ width: '80%' }"
  header="Avaliações selecionadas"
>
  <app-table
    [lazy]="false"
    [elements]="currentReviews"
    [cols]="modalColumns"
    [showHeader]="false"
    modelName="Avaliações Selecionadas"
  />
  <div class="buttons">
    <p-button
      label="Aprovar"
      styleClass="p-button-success"
      (onClick)="approveMany(currentReviews)"
    />
    <p-button
      label="Reprovar"
      styleClass="p-button-danger"
      (onClick)="rejectMany(currentReviews)"
    />
  </div>
</p-dialog>
<p-dialog
  [(visible)]="errorReport"
  [modal]="true"
  [style]="{ width: '80%' }"
  header="Erros na aprovação"
>
  <p-tabView>
    <p-tabPanel header="Avaliações não aprovadas" *ngIf="approveErrors?.length">
      <app-table
        [lazy]="false"
        [elements]="approveErrors"
        [cols]="errorColumns"
        tableTitle=" "
        modelName="avaliações"
        [addButton]="false"
      />
    </p-tabPanel>
    <p-tabPanel header="Glampoints não creditados" *ngIf="rewardErrors?.length">
      <app-table
        [lazy]="false"
        [elements]="rewardErrors"
        [cols]="errorColumns"
        tableTitle=" "
        modelName="avaliações"
        [addButton]="false"
      />
    </p-tabPanel>
  </p-tabView>
</p-dialog>
<p-toast />

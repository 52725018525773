import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  ConfirmationService,
  MessageService,
  SelectItemGroup
} from 'primeng/api';
import { MultiSelect } from 'primeng/multiselect';
import { lastValueFrom, map } from 'rxjs';
import {
  Coupon,
  CouponControllerService,
  CouponCreateRequest,
  CouponUpdateRequest,
  CouponUsageType,
  InfluencerStatus,
  Subscription,
  SubscriptionControllerService,
  SubscriptionType
} from 'src/app/admin-api';
import { CouponUsageTypeEnum, getSubscriptionName } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-coupon-form',
  templateUrl: './coupon-form.component.html',
  styleUrls: ['./coupon-form.component.scss'],
  providers: [MessageService, ConfirmationService],
  encapsulation: ViewEncapsulation.None
})
export class CouponFormComponent implements OnInit {
  @ViewChild(MultiSelect) multiSelect: MultiSelect;

  @Input()
  couponUsageTypes: Array<CouponUsageType> | undefined;

  @Input()
  couponTypes: Array<{ label: string; value: number }> = [
    { label: 'Porcentagem', value: 0 },
    { label: 'Valor Absoluto', value: 1 }
  ];

  @Input()
  subscriptions: Array<Subscription> | undefined;

  @Input()
  subscriptionTypes: Array<SubscriptionType> | undefined;

  @Input()
  influencerStatusList: Array<InfluencerStatus> | undefined;

  @Input({ required: true })
  model: Coupon & { subscriptionTypeIds?: number[] } = {};

  @Output()
  updated = new EventEmitter<Coupon>();

  private subscriptionService = inject(SubscriptionControllerService);
  private couponService = inject(CouponControllerService);
  private messageService = inject(MessageService);
  private router = inject(Router);
  private confirmationService = inject(ConfirmationService);

  today = new Date();
  form = new FormGroup({
    name: new FormControl<string>(null, [
      Validators.required,
      Validators.maxLength(100)
    ]),
    value: new FormControl<number>(null, [
      Validators.required,
      Validators.min(0)
    ]),
    couponType: new FormControl(0, Validators.required),
    subscriptionTypeIds: new FormControl<number[]>([]),
    maxRedeems: new FormControl(0, [Validators.required, Validators.min(0)]),
    enabled: new FormControl(false, Validators.required),
    isRenewal: new FormControl(0, Validators.required),
    glampoints: new FormControl(0, [Validators.required, Validators.min(0)]),
    dateExpired: new FormControl<Date>(null),
    freebieCount: new FormControl(0),
    freebieMessage: new FormControl<string>(null, [
      Validators.required,
      Validators.maxLength(550)
    ]),
    influencerStatusTypeIdRestriction: new FormControl(0),
    freeShipping: new FormControl(0, Validators.required),
    couponUsageTypeId: new FormControl(
      CouponUsageTypeEnum.CRM,
      Validators.required
    ),
    couponId: new FormControl({ value: null, disabled: true }, [
      Validators.required,
      Validators.min(1)
    ])
  });

  subscriptionTypesTree: SelectItemGroup[];

  items: SubscriptionType[] | undefined;

  ngOnInit(): void {
    this.form.controls.dateExpired.valueChanges.subscribe((value) => {
      if (value && value.getTime() < this.today.getTime()) {
        setTimeout(() => {
          this.form.controls.dateExpired.setErrors({
            invalidDate: 'Menor que a data atual'
          });
          this.form.controls.dateExpired.markAsTouched();
          this.form.controls.dateExpired.markAsDirty();
        }, 500);
      }
    });
    if (this.model) {
      this.form.controls['couponId'].enable();
      this.form.patchValue({
        ...this.model,
        dateExpired: new Date(this.model.dateExpired),
        name: this.model.name.toUpperCase(),
        influencerStatusTypeIdRestriction:
          this.model.influencerStatusTypeIdRestriction || 0
      });
    }
    if (!this.subscriptionTypes?.length) this.findSubscriptionTypes();
    this.configureTree();
  }

  async findSubscriptionTypes() {
    try {
      if (this.subscriptions.length)
        this.subscriptionTypes = (
          await Promise.all(
            this.subscriptions.map((s) =>
              this.findSubscriptionTypesBySubscriptionId(s.subscriptionId)
            )
          )
        ).reduce(
          (list: SubscriptionType[], sts) => (list = list.concat(sts)),
          []
        );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  configureTree() {
    if (this.subscriptionTypes?.length) {
      this.subscriptionTypesTree = this.subscriptionTypes.reduce(
        (list: SelectItemGroup[], st) => {
          const exits = list.find((n) => n.value === st.subscriptionId);
          if (!exits) {
            list.push({
              value: st.subscriptionId,
              label: getSubscriptionName(st.subscriptionId),
              items: [
                {
                  value: st.subscriptionTypeId,
                  label: st.name,
                  title: `${getSubscriptionName(st.subscriptionId)} ${st.name}`
                }
              ]
            });
          } else {
            exits.items.push({
              value: st.subscriptionTypeId,
              label: st.name,
              title: `${getSubscriptionName(st.subscriptionId)} ${st.name}`
            });
          }
          return list;
        },
        []
      );
      this.validateRestrictions();
    }
  }

  onCouponTypeChange(): void {
    if (this.form?.value.couponType === 0) {
      this.form.controls['value'].setValidators([
        Validators.required,
        Validators.min(0),
        Validators.max(1)
      ]);
    } else if (this.form?.value.couponType) {
      this.form.controls['value'].setValidators([
        Validators.required,
        Validators.min(0)
      ]);
    }
    this.form?.controls['value'].updateValueAndValidity();
  }

  validateRestrictions(): void {
    if (
      this.model?.couponId &&
      this.form.controls.subscriptionTypeIds.touched &&
      this.form.controls.subscriptionTypeIds.dirty &&
      this.model?.subscriptionTypeIds?.sort() !=
        this.form.value.subscriptionTypeIds.sort() &&
      this.form.value.subscriptionTypeIds?.some(
        (st) => !this.model?.subscriptionTypeIds?.includes(st)
      )
    )
      this.messageService.add({
        summary: 'Alerta',
        detail:
          'Lembre-se de verificar a restrição de plano dos brindes após a alteração.',
        severity: 'warn'
      });
    setTimeout(() => {
      this.multiSelect.el.nativeElement.classList =
        'p-element p-inputwrapper ng-pristine ng-valid ng-star-inserted p-inputwrapper-focus ng-touched';
    });
    // console.log(this.multiSelect?.el.nativeElement.children[0].class=);
  }

  async findSubscriptionTypesBySubscriptionId(
    subscriptionId: number
  ): Promise<SubscriptionType[]> {
    try {
      return await lastValueFrom(
        this.subscriptionService
          .findSubscriptionTypeList(subscriptionId)
          .pipe(
            map((data) =>
              [
                { subscriptionTypeId: 0, name: 'Nenhum' } as SubscriptionType
              ].concat(data.result)
            )
          )
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async submit(): Promise<void> {
    this.form.controls['influencerStatusTypeIdRestriction'].setValue(
      this.form.value.influencerStatusTypeIdRestriction || null
    );
    if (this.form?.valid && this.valueRangeValid && this.dateExpiredValid) {
      await this.saveCoupon(this.form.value);
    } else if (this.form?.valid && this.valueRangeValid) {
      this.confirmationService.confirm({
        acceptLabel: 'Sim',
        acceptButtonStyleClass: 'p-button-danger',
        rejectLabel: 'Não',
        rejectButtonStyleClass: 'p-button-primary',
        message: 'O cupom já está expirado, deseja continuar?',
        accept: async () => {
          await this.saveCoupon(this.form?.value);
        },
        reject: () => {
          this.form?.controls['dateExpired'].markAsDirty();
          this.form?.controls['dateExpired'].updateValueAndValidity();
        }
      });
    }
  }

  async saveCoupon(
    request: CouponUpdateRequest | CouponCreateRequest
  ): Promise<void> {
    LoaderService.showLoader();
    try {
      if (this.model) {
        this.model = await lastValueFrom(
          this.couponService
            .updateCoupon(request)
            .pipe(map((data) => data.result))
        );
        this.updated.emit(this.model);
        this.messageService.add({
          severity: 'success',
          detail: 'Cupom atualizado com sucesso',
          summary: 'Sucesso'
        });
      } else {
        this.model = await lastValueFrom(
          this.couponService
            .createCoupon(request)
            .pipe(map((data) => data.result))
        );
        this.messageService.add({
          severity: 'success',
          detail: 'Cupom cadastrado com sucesso',
          summary: 'Sucesso'
        });
        this.router.navigate(['/marketing/coupons/' + this.model?.couponId]);
      }
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  clearDateExpired(): void {
    if (this.form?.controls['dateExpired'].errors) {
      this.form?.controls['dateExpired'].clearValidators();
      this.form?.controls['dateExpired'].reset(this.today);
    }
  }

  upperCase(control: AbstractControl): void {
    control.setValue(control.value?.toUpperCase());
  }

  subscriptionName(subscriptionId: number): string {
    return getSubscriptionName(subscriptionId);
  }

  subscriptionLabel(subscriptionTypeId: number) {
    const subType = this.subscriptionTypes?.find(
      (st) => st.subscriptionTypeId === subscriptionTypeId
    );
    return subType
      ? `${getSubscriptionName(subType.subscriptionId)} ${subType.name}`
      : 'Não encontrado';
  }

  get dateExpiredValid(): boolean {
    if (!this.form?.controls['dateExpired']) {
      return true;
    }

    return !FormUtil.isDateBefore(
      new Date(this.form.value.dateExpired),
      new Date()
    );
  }

  get valueRangeValid(): boolean {
    if (this.form?.value.couponType === 0) {
      return this.form.value.value >= 0 && this.form.value.value <= 1;
    }
    return this.form?.value.value >= 0;
  }

  get percentageSuffix(): string {
    return (this.form?.value.value * 100).toFixed(1) + '% de desconto';
  }
}

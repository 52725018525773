import { Inject, Injectable } from '@angular/core';
import {
  ProductRepository,
  Where,
  WishlistRepository
} from '@infrab4a/connect';

@Injectable({
  providedIn: 'root'
})
export class ShopDashboardService {
  constructor(
    @Inject('WishlistRepository')
    private wishlistRepository: WishlistRepository,
    @Inject('ProductRepository') private productRepository: ProductRepository
  ) {}

  async getWishlists() {
    return this.wishlistRepository.find({ fields: ['id', 'products'] });
  }

  async getProducts(productsIds: string[]) {
    return this.productRepository.find({
      fields: ['id', 'sku', 'name', 'type'],
      filters: {
        id: {
          operator: Where.IN,
          value: productsIds
        }
      }
    });
  }

  async getWishlistDashboardData() {
    const wishlists = await this.getWishlists();
    const wishlistProductsIds = wishlists.data.reduce(
      (acc, curr) => acc.concat(curr.products),
      []
    );
    const products = await this.getProducts(wishlistProductsIds);
    const productsRecord = products.data.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {});
    const ownBrands = wishlists.data.reduce(
      (acc, curr) =>
        acc +
        curr.products.filter(
          (productId) => productsRecord[productId]?.type === 'Own Brands'
        ).length,
      0
    );
    const allBrands = wishlists.data.reduce(
      (acc, curr) => acc.concat(curr.products),
      []
    ).length;

    return {
      totalShops: wishlists.data.filter((wishlist) => wishlist.products.length)
        .length,
      ownBrands,
      allBrands
    };
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { AppTableModule } from '../table/app-table.module';
import { PersonGlampointsComponent } from './person-glampoints.component';

@NgModule({
  declarations: [PersonGlampointsComponent],
  imports: [
    CommonModule,
    TooltipModule,
    RouterModule,
    ButtonModule,
    ReactiveFormsModule,
    DropdownModule,
    ConfirmDialogModule,
    ToastModule,
    AppTableModule
  ],
  exports: [PersonGlampointsComponent]
})
export class PersonGlampointsModule {}

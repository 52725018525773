import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrl: './unauthorized.component.scss'
})
export class UnauthorizedComponent {
  @Output()
  navigate = new EventEmitter();

  @Input()
  modal = false;

  @Input()
  buttonLabel;

  private router: Router = inject(Router);

  goHome(): void {
    if (this.modal) this.navigate.emit();
    this.router.navigate(['/']);
  }
}

import { DecimalPipe } from '@angular/common';
import {
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  inject
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import {
  ConfirmationService,
  MessageService,
  PrimeIcons,
  TreeDragDropService,
  TreeNode
} from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { TreeNodeDropEvent } from 'primeng/tree';
import { lastValueFrom, map } from 'rxjs';
import {
  FeedbackAdminControllerService,
  FeedbackAnswerDetail,
  FeedbackControllerService,
  FeedbackForm,
  FeedbackOption,
  FeedbackQuestion,
  FeedbackQuestionGroup,
  PageableFilter,
  PageableRequest,
  ProductControllerService,
  ProductSummaryItem,
  ProductUpdateRequest
} from 'src/app/admin-api';
import { FeedbackGroupFormComponent } from 'src/app/components/feedback-form/feedback-group-form/feedback-group-form.component';
import { FeedbackOptionCloneComponent } from 'src/app/components/feedback-form/feedback-option-clone/feedback-option-clone.component';
import { FeedbackOptionFormComponent } from 'src/app/components/feedback-form/feedback-option-form/feedback-option-form.component';
import { FeedbackQuestionCloneComponent } from 'src/app/components/feedback-form/feedback-question-clone/feedback-question-clone.component';
import { FeedbackQuestionFormComponent } from 'src/app/components/feedback-form/feedback-question-form/feedback-question-form.component';
import { ProductSelectModalComponent } from 'src/app/components/product-select-modal/product-select-modal.component';
import {
  DropdownFilter,
  PageContent,
  TableActionButton,
  TableColumn,
  TableComponent
} from 'src/app/components/table';
import {
  QuestionTypeEnum,
  Role,
  getAllFeedbackOptionTypes,
  getAllQuestionTypes,
  getQuestionTypeDescription,
  getQuestionTypeName,
  roleAsObject
} from 'src/app/models';
import { FeedbackGroupDTO } from 'src/app/models/feedbackGroupDTO';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FileUtil } from 'src/app/utils/file.util';
import * as xlsx from 'xlsx';

@Component({
  selector: 'app-feedback-form-detail',
  templateUrl: './feedback-form-detail.component.html',
  styleUrl: './feedback-form-detail.component.scss',
  encapsulation: ViewEncapsulation.None,
  providers: [
    MessageService,
    TreeDragDropService,
    DialogService,
    ConfirmationService,
    DecimalPipe
  ]
})
export class FeedbackFormDetailComponent implements OnInit {
  @ViewChild('answersTable') answersTable: TableComponent;
  @ViewChild('productsTable') productsTable: TableComponent;

  feedbackService: FeedbackControllerService = inject(
    FeedbackControllerService
  );
  private feedbackAdminService: FeedbackAdminControllerService = inject(
    FeedbackAdminControllerService
  );
  productService: ProductControllerService = inject(ProductControllerService);
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private router: Router = inject(Router);
  private title: Title = inject(Title);
  private dialog: DialogService = inject(DialogService);
  private messageService: MessageService = inject(MessageService);
  private confirmationService: ConfirmationService =
    inject(ConfirmationService);
  private permissionsService: NgxPermissionsService = inject(
    NgxPermissionsService
  );
  private decimalPipe = inject(DecimalPipe);
  formId: number;
  feedbackForm: FeedbackForm;
  questions: Array<FeedbackQuestion>;
  options: Array<FeedbackOption>;
  productCols: Array<TableColumn> = [
    new TableColumn(
      'Nome',
      'productName',
      true,
      'text',
      '/products/catalog/product/',
      'productId',
      true,
      'contains'
    ),
    new TableColumn(
      'SKU Millennium',
      'externalId',
      true,
      'number',
      '/products/catalog/product/',
      'productId'
    ),
    new TableColumn(
      'SKU Admin',
      'productId',
      true,
      'number',
      '/products/catalog/product/',
      'productId'
    ),
    new TableColumn(
      'InternalEAN',
      'internalEAN',
      true,
      'text',
      '/products/catalog/product/',
      'productId',
      true,
      'contains'
    ),
    new TableColumn(
      'Marca',
      'brandName',
      true,
      'text',
      '/products/brands/',
      'brandId',
      true,
      'contains'
    ),
    new TableColumn(
      'Categoria',
      'category',
      true,
      'text',
      '/products/categories/',
      'categoryId',
      true,
      'contains'
    ),
    new TableColumn(
      'Subcategoria',
      'subcategory',
      true,
      'text',
      '/products/categories/',
      'subcategoryId',
      true,
      'contains'
    ),
    new TableColumn('Variantes', 'variantCount', false),
    new TableColumn('Estoque', 'stock', false),
    new TableColumn(
      'Criação',
      'dateCreated',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    ),
    new TableColumn('Ação', '', false, 'button')
  ];
  answersCols: Array<TableColumn> = [
    new TableColumn(
      'PersonId',
      'personId',
      true,
      'number',
      '/users/person/',
      'personId'
    ),
    new TableColumn(
      'Assinante',
      'personName',
      true,
      'text',
      '/users/person/',
      'personId',
      true,
      'contains'
    ),
    new TableColumn(
      'Pergunta',
      'title',
      true,
      'text',
      undefined,
      undefined,
      true,
      'in'
    ),
    new TableColumn(
      'Tipo de pergunta',
      'typeIdName',
      true,
      'text',
      undefined,
      undefined,
      true,
      'in'
    ),
    new TableColumn(
      'Resposta',
      'optionText',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Data',
      'dateCreated',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    )
  ];
  actionButtons: Array<TableActionButton> = [
    new TableActionButton(
      '',
      'Remover',
      PrimeIcons.BAN,
      undefined,
      '',
      'Remover',
      'bottom',
      true,
      true,
      'danger',
      'small'
    )
  ];
  defaultProductsFilters: { [field: string]: any | Array<any> };
  selectedTab = 0;
  questionNodes: Array<TreeNode<FeedbackQuestion | FeedbackGroupDTO>>;
  optionTypes: Array<{ label: string; value: number }> =
    getAllFeedbackOptionTypes();
  pagesLoaded = [0];
  groups: Array<FeedbackGroupDTO>;
  groupIds: Array<number>;
  dropdownFilters: {
    [field: string]: Array<DropdownFilter>;
  } = {
    typeIdName: getAllQuestionTypes()
  };
  products: Array<ProductSummaryItem>;
  permissions = false;
  permissionsAccepted = [
    roleAsObject(Role.Full_Administrator)?.enumValue,
    roleAsObject(Role.Business_Intelligence)?.enumValue,
    roleAsObject(Role.Customer_Success)?.enumValue
  ];
  fixedFilters: Array<PageableFilter>;
  totalAnswers: number;

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(async (params: Params) => {
      this.permissions = await this.permissionsService.hasPermission(
        this.permissionsAccepted
      );
      if (params['formId'] && params['formId'] !== 'new') {
        this.formId = Number(params['formId']);
        LoaderService.showLoader();
        this.defaultProductsFilters = {};
        await Promise.all([
          this.findFeedbackForm(),
          this.findOptions(),
          this.findQuestions(),
          this.findProducts()
        ]);
        this.fixedFilters = [
          {
            condition: 'in',
            field: 'questionId',
            fieldType: 'number',
            value: this.questions?.map((q) => q.questionId)
          }
        ];
        this.title.setTitle(
          'Formulário de Feedback - ' + this.feedbackForm.formTitle
        );
        this.init();
        this.activatedRoute.queryParams.subscribe((qParams) => {
          if (qParams['tab']) {
            try {
              this.selectedTab = Number(qParams['tab']);
            } catch (error) {
              console.error(error);
              this.selectedTab = 0;
            }
            this.pagesLoaded.push(this.selectedTab);
          }
          LoaderService.showLoader(false);
        });
      } else if (this.permissions) {
        this.title.setTitle('Novo Formulário de Feedback');
      } else {
        AppDialogService.showErrorDialog({}, true, 'Sem permissão de acesso.');
      }
    });
  }

  init(): void {
    this.questions.sort((q1, q2) => q1.questionOrder - q2.questionOrder);
    this.options.sort((o1, o2) =>
      o1.questionId === o2.questionId
        ? o1.optionOrder - o2.optionOrder
        : o1.questionId - o2.questionId
    );
    const expanded =
      this.questionNodes
        ?.filter((q) => q.expanded)
        .reduce((list: Array<number>, q) => {
          list = list
            .concat([Number(q.key)])
            .concat(
              (q as TreeNode<FeedbackQuestionGroup>).children
                ?.filter((c) => c.type === 'groupedQuestion' && c.expanded)
                ?.map((qq) => Number(qq.key)) || []
            );
          return list;
        }, []) || [];
    delete this.questionNodes;
    if (this.questions?.length || this.groups?.length) {
      this.questionNodes = [
        {
          type: 'questionHeader',
          key: 'header',
          leaf: true,
          draggable: false,
          droppable: false,
          selectable: false
        } as TreeNode<FeedbackQuestion | FeedbackGroupDTO>
      ].concat(
        this.questions.map((q) => ({
          children: this.getQuestionOptions(q.questionId).map(
            (o) =>
              ({
                data: o,
                key: o.optionId.toString(),
                label: o.optionText,
                type: 'option',
                droppable: false,
                parent: q
              } as TreeNode<FeedbackOption>)
          ),
          droppable: false,
          key: q.questionId.toString(),
          label: q.title,
          type: 'question',
          data: q,
          expanded: expanded?.some((e) => e === q.questionId),
          leaf: !this.options.some((o) => o.questionId === q.questionId)
        }))
      );
      this.questionNodes = this.questionNodes.concat(
        this.groups?.map(
          (g) =>
            ({
              data: {
                ...g,
                typeId: (QuestionTypeEnum['Matriz de opções'] as any).value
              },
              key: g.feedbackGroupId.toString(),
              label: g.feedbackGroupName,
              type: 'group',
              leaf: false,
              droppable: false,
              expanded: expanded?.some((e) => e === g.feedbackGroupId),
              children: g.questions.map(
                (q) =>
                  ({
                    data: {
                      ...q,
                      options: this.getQuestionOptions(q.questionId)
                    },
                    parent: g,
                    label: q.title,
                    key: q.questionId.toString(),
                    droppable: false,
                    type: 'groupedQuestion',
                    children: this.getQuestionOptions(q.questionId).map(
                      (o) =>
                        ({
                          data: o,
                          key: o.optionId.toString(),
                          parent: q,
                          type: 'option',
                          droppable: false
                        } as TreeNode<FeedbackOption>)
                    ),
                    expanded: expanded.some((e) => e === q.questionId)
                  } as TreeNode<FeedbackQuestion>)
              )
            } as TreeNode<FeedbackGroupDTO>)
        ) || []
      );
      this.questionNodes.sort((q1, q2) => {
        if (q1.type === 'questionHeader') return -1;
        if (q2.type === 'questionHeader') return 1;
        if (q1.type === 'question' && q2.type === 'question')
          return (
            (q1.data as FeedbackQuestion).questionOrder -
            (q2.data as FeedbackQuestion).questionOrder
          );
        else if (q1.type === 'question' && q2.type === 'group')
          return (
            (q1.data as FeedbackQuestion).questionOrder -
            (q2.data as FeedbackGroupDTO).questions[0].questionOrder
          );
        else if (q1.type === 'group' && q2.type === 'question')
          return (
            (q1.data as FeedbackGroupDTO).questions[0].questionOrder -
            (q2.data as FeedbackQuestion).questionOrder
          );
        else
          return (
            (q1.data as FeedbackGroupDTO).questions[0].questionOrder -
            (q2.data as FeedbackGroupDTO).questions[0].questionOrder
          );
      });
    }
  }

  async findFeedbackForm(): Promise<void> {
    try {
      this.feedbackForm = await lastValueFrom(
        this.feedbackService
          .findFeedbackFormById(this.formId)
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error, true);
    }
  }

  async findQuestions(): Promise<void> {
    try {
      const result = await lastValueFrom(
        this.feedbackService
          .findFormQuestions(this.formId)
          .pipe(map((data) => data.result))
      );
      this.questions = result.questions;
      this.groups = result.groups.map((g) => ({
        ...g,
        status: g.questions?.some((q) => q.status) ? 1 : 0
      }));
      const total: Array<FeedbackQuestion> = this.questions.concat(
        this.groups.reduce(
          (list: Array<FeedbackQuestion>, g) =>
            (list = list.concat(g.questions)),
          []
        )
      );
      total.sort((q1, q2) => q1.questionOrder - q2.questionOrder);
      this.dropdownFilters['title'] = total.map((q) => ({
        label: `${q.questionOrder}. ${q.title}`,
        value: q.questionId
      }));
    } catch (error) {
      this.questions = [];
      AppDialogService.showErrorDialog(error);
    }
  }

  async findOptions(): Promise<void> {
    try {
      this.options = await lastValueFrom(
        this.feedbackService
          .findFormOptions(this.formId)
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      this.options = [];
      AppDialogService.showErrorDialog(error);
    }
  }

  async findProducts(): Promise<void> {
    try {
      delete this.products;
      this.products = await lastValueFrom(
        this.productService
          .findProductsByActiveFormId(this.formId)
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      this.products = [];
      AppDialogService.showErrorDialog(error);
    }
  }

  tabChanged($event: number) {
    if (!this.pagesLoaded.includes($event)) this.pagesLoaded.push($event);
    this.router.navigate(['/research/feedbacks/' + this.formId], {
      queryParams: { tab: $event }
    });
  }

  async changeQuestionStatus(question: FeedbackQuestion): Promise<void> {
    LoaderService.showLoader();
    try {
      await this.updateQuestion(question);
      await this.findQuestions();
      this.init();
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso',
        detail: 'Pergunta atualizada com sucesso'
      });
    } catch (error) {
      question.status = question.status ? 0 : 1;
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  async updateQuestion(question: FeedbackQuestion): Promise<void> {
    await lastValueFrom(
      this.feedbackAdminService
        .updateFeedbackQuestion(question)
        .pipe(map((data) => data.result))
    );
  }

  async changeGroupStatus(
    group: FeedbackGroupDTO,
    $event: MouseEvent
  ): Promise<void> {
    if (!group.status) {
      group.status = 1;
      this.confirmationService.confirm({
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        target: $event.target,
        rejectButtonStyleClass: 'p-button-danger',
        message:
          'Ao inativar o grupo, as perguntas também serão inativadas. Deseja continuar?',
        header: 'Inativar grupo',
        accept: async () => {
          LoaderService.showLoader();
          try {
            await Promise.all(
              group.questions
                .filter((q) => q.status)
                .map((q) => {
                  q.status = 0;
                  return lastValueFrom(
                    this.feedbackAdminService.updateFeedbackQuestion(q)
                  );
                })
            );
            await this.findQuestions();
            this.init();
            this.messageService.add({
              severity: 'success',
              summary: 'Sucesso',
              detail: 'Grupo atualizado com sucesso'
            });
          } catch (error) {
            group.status = 1;
            AppDialogService.showErrorDialog(error);
          }
          LoaderService.showLoader(false);
        },
        dismissableMask: false,
        blockScroll: true
      });
      return;
    } else {
      LoaderService.showLoader();
      try {
        group.questions[0].status = 1;
        await this.updateQuestion(group.questions[0]);
        await Promise.all([this.findQuestions(), this.findOptions()]);
        this.init();
      } catch (error) {
        group.questions[0].status = 0;
        AppDialogService.showErrorDialog(error);
      }
      LoaderService.showLoader(false);
    }
  }

  async updateOption(option: FeedbackOption): Promise<void> {
    LoaderService.showLoader();
    try {
      await lastValueFrom(
        this.feedbackAdminService
          .updateFeedbackOption(option)
          .pipe(map((data) => data.result))
      );
      await Promise.all([this.findQuestions(), this.findOptions()]);
      this.init();
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso',
        detail: 'Alternativa atualizada com sucesso'
      });
    } catch (error) {
      option.enabled = !option.enabled;
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  changeQuestion(question?: FeedbackQuestion | FeedbackGroupDTO): void {
    this.dialog
      .open(FeedbackQuestionFormComponent, {
        data: {
          question,
          options:
            question && (question as FeedbackQuestion)?.questionId
              ? this.getQuestionOptions(
                  (question as FeedbackQuestion).questionId
                )
              : undefined,
          formId: this.formId
        },
        width: '600px',
        header:
          question && (question as FeedbackQuestion)?.questionId
            ? 'Editar pergunta ' + (question as FeedbackQuestion).questionOrder
            : 'Nova pergunta',
        closable: false,
        modal: true
      })
      .onClose.subscribe(async (data: FeedbackQuestion) => {
        if (data) {
          await Promise.all([this.findQuestions(), this.findOptions()]);
          this.init();
          LoaderService.showLoader(false);
        }
      });
  }

  changeOption(question: FeedbackQuestion, option?: FeedbackOption): void {
    this.dialog
      .open(FeedbackOptionFormComponent, {
        data: {
          option: option,
          OptionTypes: this.optionTypes,
          question,
          questions: this.questions
        },
        width: '600px',
        header: option
          ? 'Editar alternativa ' + option.optionOrder
          : 'Nova alternativa',
        closable: false,
        modal: true
      })
      .onClose.subscribe(async (data: FeedbackOption) => {
        if (data) {
          await Promise.all([this.findQuestions(), this.findOptions()]);
          this.init();
          LoaderService.showLoader(false);
        }
      });
  }

  async changeOrder($event: TreeNodeDropEvent): Promise<void> {
    const allowedDiffTypes = ['group', 'question'];
    if (
      $event.dragNode.parent?.key !== $event.dropNode.parent?.key ||
      ($event.dragNode.type !== $event.dropNode.type &&
        (!allowedDiffTypes.includes($event.dropNode.type) ||
          !allowedDiffTypes.includes($event.dragNode.type)))
    ) {
      AppDialogService.showErrorDialog(
        {},
        false,
        'Não é possível realizar esta ação'
      );
      this.init();
      return;
    }
    LoaderService.showLoader();
    try {
      if (
        $event.dragNode.type === 'question' ||
        $event.dragNode.type === 'groupedQuestion' ||
        $event.dragNode.type === 'group'
      ) {
        await lastValueFrom(
          this.feedbackAdminService
            .updateQuestionsOrder(
              this.questionNodes.reduce((ids: Array<number>, q) => {
                if (q.type === 'group') {
                  ids = ids.concat(
                    q.children
                      .filter((q) => q.type === 'groupedQuestion')
                      .map((q) => Number(q.key))
                  );
                } else if (q.type === 'question') {
                  ids.push(Number(q.key));
                }
                return ids;
              }, [])
            )
            .pipe(map((data) => data.result))
        );
        await this.findQuestions();
        this.init();
      } else if (
        $event.dragNode.type === 'option' &&
        $event.dragNode.parent.type === 'question'
      ) {
        this.options = await lastValueFrom(
          this.feedbackAdminService
            .updateOptionsOrder(
              $event.dragNode.parent.children.map((q) => Number(q.key))
            )
            .pipe(map((data) => data.result))
        );
        await Promise.all([this.findQuestions(), this.findOptions()]);
        this.init();
      } else if (
        $event.dragNode.type === 'option' &&
        $event.dragNode.parent.type === 'groupedQuestion'
      ) {
        this.options = await lastValueFrom(
          this.feedbackAdminService
            .updateGroupOptionsSort(
              $event.dragNode.parent.data.groupId,
              $event.dragNode.parent.children.map((q) => Number(q.key))
            )
            .pipe(map((data) => data.result))
        );
        await Promise.all([this.findQuestions(), this.findOptions()]);
        this.init();
      }
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  async convertToGroup(question: FeedbackQuestion): Promise<void> {
    LoaderService.showLoader();
    try {
      const group = await lastValueFrom(
        this.feedbackAdminService
          .convertQuestionIntoQuestionGroup(question.questionId)
          .pipe(map((data) => data.result))
      );
      await this.findQuestions();
      this.init();
      this.changeGroupName(
        {
          ...this.groups.find(
            (g) => g.feedbackGroupId === group.feedbackGroupId
          ),
          feedbackGroupName: ''
        },
        true
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  removeFromGroup(
    group: FeedbackGroupDTO,
    question: FeedbackQuestion,
    $event: MouseEvent
  ): void {
    this.confirmationService.confirm({
      target: $event.target,
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      message: 'Deseja mover a pergunta para fora do grupo?',
      header: 'Converter em pergunta',
      accept: async () => {
        LoaderService.showLoader();
        try {
          await lastValueFrom(
            this.feedbackAdminService
              .moveQuestionFromGroupToForm(question.questionId)
              .pipe(map((data) => data.result))
          );
          await this.findQuestions();
          this.init();
        } catch (error) {
          AppDialogService.showErrorDialog(error);
        }
        LoaderService.showLoader(false);
      }
    });
  }

  changeGroupName(group: FeedbackGroupDTO, newGroup = false): void {
    this.dialog
      .open(FeedbackGroupFormComponent, {
        data: {
          group,
          newGroup
        },
        width: '600px',
        header: newGroup ? 'Novo grupo' : 'Editar grupo',
        closable: false,
        modal: true
      })
      .onClose.subscribe(async (data: FeedbackGroupDTO) => {
        if (data) {
          await this.findQuestions();
          this.init();
        }
      });
  }

  addProduct() {
    this.dialog
      .open(ProductSelectModalComponent, {
        header: 'Selecionar produto',
        dismissableMask: undefined,
        width: '60%'
      })
      .onClose.subscribe(async (data: ProductSummaryItem) => {
        if (data) {
          LoaderService.showLoader();
          try {
            const product = await lastValueFrom(
              this.productService
                .findProductById(data.productId)
                .pipe(map((data) => data.result))
            );
            product.activeFeedbackId = this.formId;
            await lastValueFrom(
              this.productService
                .updateProduct(product as ProductUpdateRequest)
                .pipe(map((data) => data.result))
            );
            await this.findProducts();
            this.messageService.add({
              severity: 'success',
              detail: 'Produto incluído com sucesso.',
              summary: 'Sucesso'
            });
          } catch (error) {
            AppDialogService.showErrorDialog(error);
          }
          LoaderService.showLoader(false);
        }
      });
  }

  questionTypeName(typeId: number): string {
    return getQuestionTypeName(typeId);
  }

  questionTypeDescription(typeId: number): string {
    return getQuestionTypeDescription(typeId);
  }

  async switchOptionOrder(
    option1: FeedbackOption,
    option2: FeedbackOption,
    question: FeedbackQuestion
  ): Promise<void> {
    LoaderService.showLoader();
    const options = this.getQuestionOptions(question.questionId);
    const auxOrder = Number(option1.optionOrder);
    option1.optionOrder = Number(option2.optionOrder);
    option2.optionOrder = auxOrder;
    options.sort((o1, o2) => o1.optionOrder - o2.optionOrder);
    try {
      await lastValueFrom(
        this.feedbackAdminService.updateGroupOptionsSort(
          question.groupId,
          options.map((o) => o.optionId)
        )
      );
      await this.findOptions();
      this.init();
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  findPage = async (
    request: PageableRequest
  ): Promise<PageContent<FeedbackAnswerDetail>> => {
    const typeIdNameFilter = request.filters.find(
      (f) => f.field === 'typeIdName'
    );
    if (typeIdNameFilter?.value) {
      if (Number(typeIdNameFilter.value) === 0) {
        typeIdNameFilter.value = '';
      }
      request.filters.push({
        condition: 'in',
        field: 'typeId',
        fieldType: 'number',
        value: typeIdNameFilter.value
      });
    }
    const titleFilter = request.filters.find((f) => f.field === 'title');
    if ((titleFilter.value as Array<number>).length) {
      const questionsFilter = request.filters.find(
        (f) => f.field === 'questionId'
      );
      if (questionsFilter) questionsFilter.value = titleFilter.value;
      else
        request.filters.push({
          condition: 'in',
          field: 'questionId',
          fieldType: 'number',
          value: titleFilter.value
        });
    }
    return lastValueFrom(
      this.feedbackService
        .findFormAnswersTable(request)
        .pipe(map((data) => data.result))
    );
  };

  cloneQuestions(group?: FeedbackGroupDTO): void {
    this.dialog
      .open(FeedbackQuestionCloneComponent, {
        data: {
          group,
          form: this.feedbackForm
        },
        width: '600px',
        header: 'Importar perguntas',
        closable: false,
        closeOnEscape: false
      })
      .onClose.subscribe(
        async (data: Array<FeedbackQuestion> | FeedbackForm) => {
          if (data) {
            await Promise.all([this.findQuestions(), this.findOptions()]);
            this.init();
            LoaderService.showLoader(false);
          }
        }
      );
  }

  cloneOptions(question?: FeedbackQuestion): void {
    this.dialog
      .open(FeedbackOptionCloneComponent, {
        data: {
          question,
          form: this.feedbackForm
        },
        width: '600px',
        header: 'Importar alternativas',
        closable: false,
        closeOnEscape: false
      })
      .onClose.subscribe(async (data: Array<FeedbackQuestion>) => {
        if (data) {
          await Promise.all([this.findQuestions(), this.findOptions()]);
          this.init();
          LoaderService.showLoader(false);
        }
      });
  }

  deleteQuestion(question: FeedbackQuestion, $event: MouseEvent): void {
    this.confirmationService.confirm({
      acceptLabel: 'Sim',
      acceptButtonStyleClass: 'p-button-danger',
      acceptIcon: PrimeIcons.TRASH,
      rejectLabel: 'Não',
      target: $event.target,
      message: `Tem certeza que deseja excluir a pergunta ${question.questionOrder}?`,
      header: 'Excluir pergunta',
      accept: async () => {
        LoaderService.showLoader();
        try {
          const detail = await lastValueFrom(
            this.feedbackAdminService
              .deleteFeedbackQuestion(question.questionId)
              .pipe(map((data) => data.result))
          );
          await this.findQuestions();
          this.init();
          this.messageService.add({
            severity: 'success',
            summary: 'Sucesso',
            detail
          });
        } catch (error) {
          AppDialogService.showErrorDialog(error);
        }
        LoaderService.showLoader(false);
      }
    });
  }

  deleteOption(
    option: FeedbackOption,
    $event: MouseEvent,
    group?: FeedbackGroupDTO
  ): void {
    this.confirmationService.confirm({
      acceptLabel: 'Sim',
      acceptButtonStyleClass: 'p-button-danger',
      acceptIcon: PrimeIcons.TRASH,
      rejectLabel: 'Não',
      target: $event.target,
      message: `Tem certeza que deseja excluir a alternativa ${option.optionOrder}?`,
      header: 'Excluir alternativa',
      accept: async () => {
        LoaderService.showLoader();
        try {
          let detail: string;
          if (group) {
            detail = await lastValueFrom(
              this.feedbackAdminService
                .deleteFeedbackQuestionGroupOption(
                  group.feedbackGroupId,
                  option.optionOrder
                )
                .pipe(map((data) => data.result))
            );
          } else {
            detail = await lastValueFrom(
              this.feedbackAdminService
                .deleteFeedbackOption(option.optionId)
                .pipe(map((data) => data.result))
            );
          }
          await Promise.all([this.findQuestions(), this.findOptions()]);
          this.init();
          this.messageService.add({
            severity: 'success',
            summary: 'Sucesso',
            detail
          });
        } catch (error) {
          AppDialogService.showErrorDialog(error);
        }
        LoaderService.showLoader(false);
      }
    });
  }

  deleteGroup(group: FeedbackQuestionGroup, $event: MouseEvent): void {
    this.confirmationService.confirm({
      acceptLabel: 'Sim',
      acceptButtonStyleClass: 'p-button-danger',
      acceptIcon: PrimeIcons.TRASH,
      rejectLabel: 'Não',
      target: $event.target,
      message: `Tem certeza que deseja excluir o grupo de pergunta ${group.questionOrder}?`,
      header: 'Excluir pergunta',
      accept: async () => {
        LoaderService.showLoader();
        try {
          const detail = await lastValueFrom(
            this.feedbackAdminService
              .deleteFeedbackQuestionGroup(group.feedbackGroupId)
              .pipe(map((data) => data.result))
          );
          await Promise.all([this.findQuestions(), this.findOptions()]);
          this.init();
          this.messageService.add({
            severity: 'success',
            summary: 'Sucesso',
            detail
          });
        } catch (error) {
          AppDialogService.showErrorDialog(error);
        }
        LoaderService.showLoader(false);
      }
    });
  }

  isQuestionTypeOptions(question: FeedbackQuestion): boolean {
    return (
      question.typeId === 3 || question.typeId === 4 || question.typeId === 6
    );
  }

  isAnsweredQuestion(question: FeedbackQuestion): boolean {
    return question.answerCount > 0;
  }

  isAnsweredGroup(group: FeedbackQuestionGroup): boolean {
    return group.questions.some((q: FeedbackQuestion) =>
      this.isAnsweredQuestion(q)
    );
  }

  isAnsweredOption(option: FeedbackOption): boolean {
    return option.absFrequency > 0;
  }

  isAnsweredGroupOption(
    group: FeedbackQuestionGroup,
    optionOrder: number
  ): boolean {
    return group.questions.some((q) =>
      this.options.some(
        (o) =>
          o.questionId === q.questionId &&
          o.optionOrder === optionOrder &&
          o.absFrequency > 0
      )
    );
  }

  getQuestionOptions(questionId: number): Array<FeedbackOption> {
    return this.options.filter((o) => o.questionId === questionId);
  }

  goToForm($event: FeedbackForm): void {
    this.router.navigate(['research/feedbacks/' + $event.formId]);
  }

  async removeProduct(product: ProductSummaryItem) {
    LoaderService.showLoader();
    const productsList = new Array<number>();
    productsList.push(product.productId);
    try {
      const result = await lastValueFrom(
        this.feedbackAdminService
          .disableForms(productsList)
          .pipe(map((data) => data.result))
      );
      this.messageService.add({
        severity: 'success',
        detail: result,
        summary: 'Sucesso'
      });
      await this.productsTable?.refresh(true);
      await this.findProducts();
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  exportExcel(): void {
    const worksheet = xlsx.utils.book_new();
    let array = [[this.feedbackForm.formTitle], [], []];
    for (let index = 0; index < this.questions.length; index++) {
      const q = this.questions[index];
      array.push([`${q.questionOrder}) ${q.title}`]);
      const options = this.getQuestionOptions(q.questionId);
      array = array.concat(
        options.map((o) => [
          `${o.optionOrder} - ${o.optionText}`,
          o.absFrequency.toString(),
          o.relFrequency.toString()
        ])
      );
      array.push([]);
    }
    xlsx.utils.sheet_add_aoa(worksheet, array);
    Object.keys(worksheet).forEach((key) => {
      if (key[0] === 'B' && Number(key.substring(1)) > 1) {
        worksheet[key].t = 'n';
        worksheet[key].z = '#,##0';
      } else if (key[0] === 'C' && Number(key.substring(1)) > 1) {
        worksheet[key].t = 'n';
        worksheet[key].z = '0.00%';
      }
    });
    const sheetName = this.feedbackForm.formTitle
      .replaceAll('?', '')
      .replaceAll('\\', '')
      .replaceAll('/', '')
      .replaceAll('*', '')
      .replaceAll('[', '')
      .replaceAll(']', '')
      .slice(0, 31);

    const workbook = {
      Sheets: {},
      SheetNames: [sheetName]
    };
    workbook.Sheets[sheetName] = worksheet;
    const excelBuffer: xlsx.WorkSheet = xlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    });
    FileUtil.saveAsExcelFile(
      excelBuffer as BlobPart,
      this.feedbackForm.formTitle
    );
  }

  setTotalAnswers() {
    if (this.totalAnswers === undefined)
      this.totalAnswers = this.answersTable?.pageContent.totalElements || 0;
  }
}

<form class="modal-form">
  <span class="p-float-label">
    <textarea
      id="billingUpdate"
      rows="5"
      cols="80"
      pInputTextarea
      [(ngModel)]="billingUpdate"
      name="billingUpdate"
      (click)="copyToClipboard(billingUpdate)"
      readonly
      required
    ></textarea>
    <label for="billingUpdate">Update do billing</label>
  </span>
  <span class="p-float-label" *ngIf="recurrenceUpdate.trim().length">
    <textarea
      id="recurrenceUpdate"
      rows="5"
      cols="80"
      pInputTextarea
      [(ngModel)]="recurrenceUpdate"
      (click)="copyToClipboard(recurrenceUpdate)"
      name="recurrenceUpdate"
      readonly
    ></textarea>
    <label for="recurrenceUpdate">Update do recurrence</label>
  </span>
  <div class="buttons">
    <p-button
      type="button"
      label="Sinalizar como resolvido"
      icon="pi pi-check"
      (onClick)="markAsResolved()"
    />
  </div>
</form>
<p-toast />

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { BlacklistCpfFormComponent } from './blacklist-cpf-form/blacklist-cpf-form.component';
import { BlacklistDomainFormComponent } from './blacklist-domain-form/blacklist-domain-form.component';

@NgModule({
  declarations: [BlacklistCpfFormComponent, BlacklistDomainFormComponent],
  imports: [
    CommonModule,
    InputMaskModule,
    ButtonModule,
    ReactiveFormsModule,
    InputTextareaModule,
    InputTextModule
  ],
  exports: [BlacklistCpfFormComponent, BlacklistDomainFormComponent]
})
export class BlacklistFormModule {}

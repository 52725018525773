<div class="filter-detail">
  <ng-container *ngIf="filter()?.id">
    <div class="filter-detail__header">
      Loja - Editar filtro {{ filter().description }}
    </div>
    <p-tabView
      [activeIndex]="selectedTab"
      (activeIndexChange)="tabChanged($event)"
    >
      <p-tabPanel header="Informações">
        <ng-container *ngTemplateOutlet="filterDetails" />
      </p-tabPanel>
      <p-tabPanel header="Produtos">
        <app-table
          *ngIf="productCols && products"
          [cols]="productCols"
          [elements]="products"
          modelName="produtos"
          sortField="name"
          [sortOrder]="-1"
          [defaultRows]="10"
          [changeColumns]="true"
          [addButton]="false"
          [lazy]="false"
          [dropdownFilters]="dropdownFilters"
        />
      </p-tabPanel>
      <p-tabPanel header="Categorias">
        <app-table
          [cols]="categoriesCols"
          [elements]="categories"
          modelName="categorias associadas"
          sortField="name"
          [sortOrder]="-1"
          [defaultRows]="10"
          [changeColumns]="true"
          [addButton]="false"
          [lazy]="false"
        />
      </p-tabPanel>
    </p-tabView>
  </ng-container>
  <ng-container *ngIf="!filter()?.id">
    <div class="filter-detail__header" *ngIf="!filter()?.id">
      Loja - Novo filtro de categoria
    </div>
    <ng-container *ngTemplateOutlet="filterDetails" />
  </ng-container>
</div>
<ng-template #filterDetails>
  <p-fieldset legend="Detalhes do filtro">
    <form [formGroup]="form" class="form-b4a" (submit)="save()">
      <span class="p-float-label">
        <input
          id="description"
          type="text"
          pInputText
          formControlName="description"
          (blur)="setSlug()"
          (input)="setSlug(false)"
        />
        <label for="description">Nome do filtro *</label>
        <small
          *ngIf="
            form?.controls?.description?.valid && form?.controls?.slug?.valid
          "
        >
          Slug: {{ form.value.slug }}
        </small>
        <small
          class="error"
          *ngIf="
            form?.controls?.slug?.errors &&
            form?.controls?.slug?.errors['exists']
          "
        >
          A slug "{{ form.controls.slug.errors['exists'].slug }}" já está em uso
        </small>
      </span>
      <span class="p-float-label" *ngIf="filterId">
        <p-inputGroup>
          <input
            id="description"
            type="text"
            pInputText
            formControlName="slug"
          />
          <button
            type="button"
            pButton
            pTooltip="Copiar"
            tooltipPosition="bottom"
            icon="pi pi-copy"
            (click)="copyFilterSlugToClipboard()"
          ></button>
          <label for="description">Tag do filtro</label>
        </p-inputGroup>
      </span>
      <span class="p-float-label">
        <input id="title" type="text" pInputText formControlName="title" />
        <label for="title">Título para visualização *</label>
        <small>Esse é o título do filtro que será exibido na loja</small>
      </span>
      <span class="p-input-checkbox">
        <p-checkbox
          formControlName="enabled"
          [binary]="true"
          inputId="enabled"
        ></p-checkbox>
        <label for="enabled">Ativo?</label>
      </span>

      <span class="buttons">
        <p-button
          label="Salvar"
          [disabled]="form.invalid"
          type="submit"
        ></p-button>
      </span>
      <small *ngIf="filter()?.updatedAt"
        >*Última alteração em:
        {{ filter().updatedAt | date : 'dd/MM/yyyy HH:mm:ss' }}</small
      >
    </form>
  </p-fieldset>

  <p-fieldset legend="Adicionar opção" *ngIf="filter()?.id">
    <form (submit)="addTag()" class="filter-detail__add">
      <span class="p-float-label">
        <input id="tag" type="text" pInputText [formControl]="newTagControl" />
        <label for="tag">Descrição *</label>
      </span>
      <p-button
        [disabled]="newTagControl.valid && !newTagControl.value?.trim()"
        label="Adicionar"
        type="submit"
        icon="pi pi-plus"
        iconPos="right"
      ></p-button>
    </form>
    <small
      class="danger"
      *ngIf="newTagControl.errors && newTagControl.errors['exists']"
      >* Já existe a opção desejada no filtro</small
    >
    <app-table
      *ngIf="filter()?.options"
      [lazy]="false"
      [elements]="filter().options"
      [cols]="cols"
      sortField="description"
      [sortOrder]="-1"
      [defaultRows]="25"
      [actionButtons]="actionButtons"
      (actionButtonClick)="deleteFilterOption($event)"
      [showHeader]="false"
      emptyMessage="Nenhuma opção cadastrada"
      modelName="opções"
    />
  </p-fieldset>
</ng-template>

<p-confirmPopup />
<p-toast />

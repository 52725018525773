<div class="editions-dashboard">
  <h3>Dashboard de edições</h3>
  <ng-container *ngIf="ready">
    <div class="filters">
      <form class="form-b4a" [formGroup]="form">
        <span class="p-float-label">
          <p-dropdown
            inputId="subscriptionId"
            [options]="subscriptions"
            (onChange)="changeSubscription()"
            formControlName="subscriptionId"
            [autoDisplayFirst]="false"
            appendTo="body"
          >
          </p-dropdown>
          <label for="subscriptionId">Assinatura</label>
        </span>
        <span class="p-float-label">
          <p-dropdown
            inputId="editionId"
            [options]="editions"
            *ngIf="editions"
            optionValue="editionId"
            optionLabel="theme"
            (onChange)="changeEdition()"
            [filter]="true"
            filterBy="theme,title,editionId,description"
            filterMatchMode="contains"
            [autoDisplayFirst]="false"
            formControlName="editionId"
            appendTo="body"
          >
            <ng-template pTemplate="item" let-edition>
              <ng-container *ngIf="form.value.subscriptionId">
                {{ edition.theme }}
                {{ editionDate(edition.editionId) }}
              </ng-container>
              <ng-container *ngIf="!form.value.subscriptionId">
                {{ editionDateName(edition.editionId) | titlecase }}
              </ng-container>
            </ng-template>
            <ng-template pTemplate="selectedItem">
              <ng-container *ngIf="form.value.subscriptionId">
                {{ edition?.theme }}
              </ng-container>
              <ng-container
                *ngIf="
                  edition && edition.editionId && !form.value.subscriptionId
                "
              >
                {{ editionDateName(edition.editionId) | titlecase }}
              </ng-container>
            </ng-template>
          </p-dropdown>
          <label for="editionId">Ediçao</label>
        </span>
      </form>
      <div class="picture">
        <app-edition-image
          [imageURL]="edition.imageURL"
          [editionId]="edition.editionId"
          *ngIf="edition"
        ></app-edition-image>
      </div>
    </div>
    <div
      (click)="showPending()"
      class="pending-warning"
      *ngIf="pendingPreparation?.length"
    >
      <b>Atenção!</b> Existem assinantes pendentes em edições anteriores. Clique
      para ver.
    </div>
    <div class="totals">
      <div class="dashboard-card" (click)="findSubscribers(1000)">
        <span class="header header--success">Total assinantes</span>
        <span class="value">{{ totalSubscribers | number : '1.0-0' }}</span>
      </div>
      <div
        class="dashboard-card"
        (click)="findSubscribers(0, 'Aguardando alocação')"
      >
        <span class="header header--warning">Aguardando alocação</span>
        <span class="value">{{ pending | number : '1.0-0' }}</span>
      </div>
      <div
        class="dashboard-card"
        *ngFor="let total of subscriberEditionStatusList; let i = index"
        (click)="findSubscribers(total.id, total.status)"
      >
        <span
          class="header"
          [ngStyle]="{
            'background-color': subscriberStatusColor(total.id),
            color: 'white',
            'text-align': 'center'
          }"
          >{{ total.status }}</span
        >
        <span class="value">{{ total.total | number : '1.0-0' }}</span>
      </div>
    </div>
    <br />
    <div>
      <app-table
        [preHeaderTemplate]="preHeaderTemplate"
        [cols]="cols"
        [lazy]="false"
        [elements]="compositions"
        modelName="Composições"
        [showHeader]="false"
        [actionButtons]="actionButtons"
        (actionButtonClick)="confirmPrepareComposition($event)"
        [paginator]="false"
      >
        <ng-template #preHeaderTemplate>
          <tr>
            <th [attr.colspan]="form.value.editionId > 1000000 ? 4 : 5">
              Status da edição
            </th>
            <th
              *ngFor="let total of subscriberEditionStatusList; let i = index"
              [ngStyle]="{
                'background-color': subscriberStatusColor(total.id),
                color: 'white',
                'text-align': 'center'
              }"
              [attr.colspan]="total.statusList?.length"
            >
              {{ total.status | titlecase }}
            </th>
          </tr>
        </ng-template>
      </app-table>
    </div>
  </ng-container>
</div>
<p-confirmPopup></p-confirmPopup>
<p-toast></p-toast>

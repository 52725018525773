<form class="modal-form">
  <span class="p-float-label">
    <textarea
      id="endEditionUpdate"
      rows="5"
      cols="80"
      pInputTextarea
      [(ngModel)]="endEditionUpdate"
      name="endEditionUpdate"
      (click)="copyToClipboard(endEditionUpdate)"
      readonly
      required
    ></textarea>
    <label for="endEditionUpdate">Update da End Edition</label>
  </span>
  <div class="buttons">
    <p-button
      type="button"
      label="Sinalizar como resolvido"
      icon="pi pi-check"
      (onClick)="markAsResolved()"
    />
  </div>
</form>
<p-toast />

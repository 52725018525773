import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { AppTableModule } from '../table/app-table.module';
import { PersonCreditCardsComponent } from './person-credit-cards.component';

@NgModule({
  declarations: [PersonCreditCardsComponent],
  imports: [
    CommonModule,
    InputTextModule,
    ButtonModule,
    InputNumberModule,
    DropdownModule,
    TooltipModule,
    RouterModule,
    ConfirmPopupModule,
    AppTableModule,
    ToastModule
  ],
  exports: [PersonCreditCardsComponent]
})
export class PersonCreditCardsModule {}

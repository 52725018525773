import { TitleCasePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { lastValueFrom, map } from 'rxjs';
import {
  ItOpsControllerService,
  NotPendingRecurrence,
  SubscriptionControllerService
} from 'src/app/admin-api';
import {
  DropdownFilter,
  TableColumn,
  TableComponent
} from 'src/app/components/table';
import { SubscriptionEnum, getAllSubscriptions } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-not-pending-recurrence',
  templateUrl: './not-pending-recurrence.component.html',
  styleUrls: ['./not-pending-recurrence.component.scss'],
  providers: [ConfirmationService, MessageService, TitleCasePipe]
})
export class NotPendingRecurrenceComponent implements OnInit {
  @ViewChild(TableComponent) table: TableComponent;

  columns: Array<TableColumn> = [
    new TableColumn('', '', false, 'checkbox'),
    new TableColumn(
      'SubscriberId',
      'subscriberId',
      true,
      'number',
      '/users/subscribers/',
      'subscriberId'
    ),
    new TableColumn(
      'PersonId',
      'personId',
      true,
      'number',
      '/users/person/',
      'personId'
    ),
    new TableColumn(
      'BoxId',
      'boxId',
      true,
      'text',
      '/users/subscribers/',
      'subscriberId'
    ),
    new TableColumn('Último Cartão', 'ultCartao', true, 'number'),
    new TableColumn('SubscriptionTypeId', 'subscriptionTypeId', true, 'number'),
    new TableColumn(
      'Valor Assinatura',
      'valorAssinatura',
      true,
      'currency',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Parcelas',
      'parcelas',
      true,
      'number',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'ShippingPrice',
      'shippingPrice',
      true,
      'currency',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Data de Pagamento',
      'nextPaymentDate',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    )
  ];

  dropdownFilters: { [field: string]: Array<DropdownFilter> };

  constructor(
    public itOpsService: ItOpsControllerService,
    private confirmationService: ConfirmationService,
    private subscriptionService: SubscriptionControllerService,
    private messageService: MessageService,
    private titleCasePipe: TitleCasePipe
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      this.dropdownFilters = {
        SubscriptionTypeId: (
          await Promise.all(
            getAllSubscriptions().map((s) =>
              lastValueFrom(
                this.subscriptionService
                  .findSubscriptionTypeList(s.value)
                  .pipe(map((data) => data.result))
              )
            )
          )
        ).reduce(
          (types: Array<DropdownFilter>, sts) =>
            (types = types.concat(
              sts.map((st) => ({
                label:
                  st.subscriptionTypeId +
                  '. ' +
                  this.titleCasePipe.transform(
                    SubscriptionEnum[st.subscriptionId] + ' ' + st.name
                  ),
                value: st.subscriptionTypeId.toString()
              }))
            )),
          []
        )
      };
    } catch (error) {
      this.dropdownFilters = {};
      AppDialogService.showErrorDialog(error);
    }
  }

  createSelectedBillings(billings: Array<NotPendingRecurrence>): void {
    this.confirmationService.confirm({
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      rejectButtonStyleClass: 'p-button-danger',
      message: 'Deseja criar o billing das assinaturas selecionadas?',
      header: 'Criar billings',
      accept: async () => {
        LoaderService.showLoader();
        try {
          await lastValueFrom(
            this.itOpsService
              .fixNotPendingRecurrences(billings)
              .pipe(map((data) => data))
          );
          this.messageService.add({
            summary: 'Sucesso',
            detail: 'Billings criados com sucesso',
            severity: 'success'
          });
          LoaderService.showLoader(false);
          await this.table.refresh(true);
        } catch (error) {
          LoaderService.showLoader(false);
          AppDialogService.showErrorDialog(error);
        }
      }
    });
  }
}

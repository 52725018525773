/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ActiveFormAdmin {
  formId?: number;
  productId?: number;
  formTitle?: string;
  productName?: string;
  theme?: string;
  dateCreated?: string;
}

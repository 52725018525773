import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { TabView } from 'primeng/tabview';
import { lastValueFrom, map } from 'rxjs';
import {
  Brand,
  BrandControllerService,
  CompositionControllerService,
  CompositionProductVariant,
  FeedbackControllerService,
  FeedbackForm,
  Product,
  ProductCategory,
  ProductControllerService,
  ProductFinality,
  ProductFinalityItem,
  ProductImage,
  ProductVariant,
  ProductVariantUpdateRequest
} from 'src/app/admin-api';
import { ProductDiffModalComponent } from 'src/app/components/product-diff-modal/product-diff-modal.component';
import { ProductFormComponent } from 'src/app/components/product-form/product-form.component';
import { TableColumn } from 'src/app/components/table';
import { ShopProductService } from 'src/app/connect-api/api/shop/shop-product.service';
import { ShopProduct } from 'src/app/connect-api/models/ShopProduct';
import {
  DescriptionField,
  getDescriptionField,
  getEquityLabel,
  ProductVariantStock
} from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
  providers: [DatePipe]
})
export class ProductDetailsComponent implements OnInit {
  @ViewChild(TabView)
  tabview: TabView | undefined;

  @ViewChild(ProductFormComponent) productForm: ProductFormComponent;

  private productService = inject(ProductControllerService);
  private activatedRoute = inject(ActivatedRoute);
  private brandService = inject(BrandControllerService);
  private datePipe = inject(DatePipe);
  private feedbackService = inject(FeedbackControllerService);
  private router = inject(Router);
  private messageService = inject(MessageService);
  private cdRef = inject(ChangeDetectorRef);
  private title = inject(Title);
  private compositionService = inject(CompositionControllerService);
  private shopProductService = inject(ShopProductService);
  private dialog = inject(DialogService);

  product: Product | undefined;
  productVariant: ProductVariant | undefined;
  productVariants: Array<ProductVariant> | undefined;
  productId: number | undefined;
  productVariantId: number | undefined;
  brands: Array<Brand> | undefined;
  categories: Array<ProductCategory> | undefined;
  feedbackForms: Array<FeedbackForm> | undefined;
  compositions: Array<CompositionProductVariant> | undefined;
  images: Array<ProductImage> | undefined;
  finalities: Array<ProductFinality> | undefined;
  productFinalities: Array<ProductFinalityItem> | undefined;
  subcategory: ProductCategory | undefined;
  selectedTab = 0;
  changed = false;

  isMobile?: boolean;
  selectedProductVariant: ProductVariant | null | undefined;
  rating = new FormControl(0);
  filterFields: Array<{ label: string; value: string }> = [];

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.checkScreenSize();
  }
  searchForm = new FormGroup({
    field: new FormControl<string | undefined>(
      'productVariantId',
      Validators.required
    ),
    value: new FormControl<string | undefined>(undefined, Validators.required)
  });
  compositionCols: Array<TableColumn> = [
    new TableColumn(
      'Id',
      'compositionId',
      true,
      'text',
      '/operations/compositions/id/',
      'compositionId'
    ),
    new TableColumn(
      'Composição',
      'compositionName',
      true,
      'text',
      '/operations/compositions/id/',
      'compositionId',
      true,
      'contains'
    ),
    new TableColumn(
      'Edição',
      'editionName',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn('Assinantes', 'subscriberCount', false, 'formattedInteger'),
    new TableColumn(
      'Variante',
      'productVariantName',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'InternalEAN',
      'internalEAN',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Validade',
      'expiration',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Data alocação',
      'compositionDate',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    )
  ];
  variantCols: Array<TableColumn> = [
    new TableColumn('', '', false, 'checkbox'),
    new TableColumn(
      'SKU Admin',
      'productVariantId',
      true,
      'number',
      '',
      'productVariantId'
    ),
    new TableColumn(
      'SKU Millennium SKU',
      'externalId',
      true,
      'number',
      '',
      'productVariantId'
    ),
    new TableColumn(
      'Nome',
      'productVariantName',
      true,
      'text',
      '',
      'productVariantId',
      true,
      'contains'
    ),
    new TableColumn(
      'InternalEAN',
      'internalEAN',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn('Peso (g)', 'weight', false, 'formattedNumber'),
    new TableColumn('Trocas', 'tradeActive', false, 'boolean'),
    new TableColumn('Validade', 'expiration', false, 'text'),
    new TableColumn(
      'Data cadastro',
      'dateCreated',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    ),
    new TableColumn(
      'Data atualização',
      'dateUpdated',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    )
  ];
  shopProduct: ShopProduct;

  checkScreenSize(): void {
    this.isMobile = window.innerWidth < 768;
  }

  ngOnInit(): void {
    this.checkScreenSize();
    this.activatedRoute.params.subscribe(async (params: Params) => {
      this.changed = false;
      if (
        params['productVariantId'] &&
        Number(params['productVariantId']) !== this.productVariantId
      ) {
        this.changed = true;
        this.productVariantId = params['productVariantId'];
        LoaderService.showLoader();
        await this.findProductVariant(Number(params['productVariantId']));
        this.productId = this.productVariant?.productId;
        this.selectedTab = 1;
        this.selectedProductVariant = this.productVariant;
      }
      if (
        params['productId'] &&
        params['productId'] !== 'new' &&
        Number(params['productId']) !== this.productId
      ) {
        this.changed = true;
        this.productId = Number(params['productId']);
      }
      if (this.changed) {
        if (this.productId) {
          LoaderService.showLoader();
          await Promise.all([
            this.findProduct(this.productId),
            this.findProductCategory(),
            this.findProductCategories(),
            this.findProductVariants(),
            this.findBrands(),
            this.findFeedbackForms(),
            this.findCompositions(),
            this.findProductImages(),
            this.findProductFinalities(),
            this.findAllProductFinalities()
          ]);
          this.title.setTitle(
            this.product?.productName +
              ' ' +
              (this.defaultProductVariant?.internalEAN ||
                this.defaultProductVariant?.cean ||
                this.defaultProductVariant?.externalId ||
                '')
          );
          if (this.product?.externalId) await this.findShopProduct();
          this.activatedRoute.queryParams.subscribe((queryParams) => {
            if (queryParams['tab']) {
              this.selectedTab = Number(queryParams['tab']) || 0;
            } else if (!this.productVariant) {
              this.selectedTab = 0;
            }
          });
        } else {
          AppDialogService.showErrorDialog(
            { message: 'Produto não encontrado' },
            true
          );
        }
      } else if (!this.productId) {
        LoaderService.showLoader();
        await Promise.all([
          this.findProductCategories(),
          this.findBrands(),
          this.findFeedbackForms(),
          this.findAllProductFinalities()
        ]);
        this.title.setTitle('Novo produto');
      }
      LoaderService.showLoader(false);
    });
  }

  async findProduct(productId: number): Promise<void> {
    try {
      this.product = await lastValueFrom(
        this.productService
          .findProductById(productId)
          .pipe(map((data) => data.result))
      );
      this.rating.setValue(this.product?.ratingAverage || 0);
    } catch (error: any) {
      AppDialogService.showErrorDialog(error, true);
    }
  }

  async findProductVariant(productVariantId: number): Promise<void> {
    try {
      this.productVariant = await lastValueFrom(
        this.productService
          .findProductVariantById(productVariantId)
          .pipe(map((data) => data.result))
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error, true);
    }
  }

  async findProductVariants(): Promise<void> {
    try {
      this.productVariants = await lastValueFrom(
        this.productService
          .findProductVariantsListByProductId(this.productId as number)
          .pipe(map((data) => data.result))
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findProductCategory(): Promise<void> {
    try {
      this.subcategory = await lastValueFrom(
        this.productService
          .findProductCategoryByProductId(this.productId as number)
          .pipe(map((data) => data.result))
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findProductCategories(): Promise<void> {
    try {
      this.categories = await lastValueFrom(
        this.productService
          .findProductCategoriesList()
          .pipe(map((data) => data.result))
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findBrands(): Promise<void> {
    try {
      this.brands = await lastValueFrom(
        this.brandService.findBrandsList().pipe(map((data) => data.result))
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findFeedbackForms(): Promise<void> {
    try {
      this.feedbackForms = await lastValueFrom(
        this.feedbackService
          .findFeedbackFormsList()
          .pipe(map((data) => data.result))
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findCompositions(): Promise<void> {
    try {
      this.compositions = await lastValueFrom(
        this.compositionService
          .findCompositionsByProductId(this.productId as number)
          .pipe(map((data) => data.result))
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findProductImages(): Promise<void> {
    try {
      this.images = await lastValueFrom(
        this.productService
          .findImagesByProductId(this.productId as number)
          .pipe(map((data) => data.result))
      );
      this.images?.sort(
        (i1, i2) => (i1.position as number) - (i2.position as number)
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findProductFinalities(): Promise<void> {
    try {
      this.productFinalities = await lastValueFrom(
        this.productService
          .findProductFinalities(this.productId as number)
          .pipe(map((data) => data.result))
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findAllProductFinalities(): Promise<void> {
    try {
      this.finalities = await lastValueFrom(
        this.productService
          .findAllProductFinalities()
          .pipe(map((data) => data.result))
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
  }

  tabChanged($event: number): void {
    if (this.productVariantId) {
      this.router.navigate(
        [`products/catalog/product-variant/${this.productVariantId}`],
        {
          queryParams: { tab: $event },
          queryParamsHandling: 'merge'
        }
      );
    } else if (this.productId) {
      this.router.navigate([`products/catalog/product/${this.productId}`], {
        queryParams: { tab: $event },
        queryParamsHandling: 'merge'
      });
    }
  }

  async updateProduct(product?: Product): Promise<void> {
    if (!product) await this.findProduct(this.productId);
    else this.product = product;
    LoaderService.showLoader();
    await Promise.all([this.findProductCategory(), this.findProductImages()]);
    LoaderService.showLoader(false);
    this.cdRef.detectChanges();
  }

  timeDiffLabel(date: string): string {
    return FormUtil.timeDiffLabel(date, this.datePipe);
  }

  async variantAction($event: ProductVariant | null): Promise<void> {
    if ($event) {
      await Promise.all([this.findProductVariants(), this.findProductImages()]);
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso',
        detail: `Variante ${
          this.selectedProductVariant ? 'alterada' : 'cadastrada'
        } com sucesso`
      });
    }
    delete this.selectedProductVariant;
    LoaderService.showLoader(false);
  }

  async disableTrades(variants: Array<ProductVariant>): Promise<void> {
    LoaderService.showLoader();
    try {
      await Promise.all(
        variants.map((p) => {
          p.tradeActive = false;
          p.isTradeable = false;
          return lastValueFrom(
            this.productService
              .updateProductVariant(p as ProductVariantUpdateRequest)
              .pipe(map((data) => data.result))
          );
        })
      );
      await this.findProductVariants();
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  async findShopProduct() {
    try {
      this.shopProduct = (await this.shopProductService.getProductBySKU(
        this.product.externalId.toString()
      )) as any;
      console.log(this.shopProduct);
    } catch (error) {
      console.error(error);
    }
  }

  static hasSyncInfo(
    column: DescriptionField,
    product: Product,
    shopProduct: Partial<ShopProduct>
  ): boolean {
    return (
      shopProduct &&
      product &&
      (shopProduct.description[column.value] || product[column.controlName]) &&
      product[column.controlName]?.trim() !==
        shopProduct.description[column.value]?.trim()
    );
  }

  async syncInfo(): Promise<void> {
    this.dialog
      .open(ProductDiffModalComponent, {
        data: {
          product: this.product,
          shopProduct: this.shopProduct
        },
        width: '60%',
        height: '90vh',
        header: 'Sincronizar ' + this.product.productName,
        maximizable: true
      })
      .onClose.subscribe(async (success) => {
        if (success) {
          LoaderService.showLoader();
          await Promise.all([
            this.findProduct(this.productId),
            this.findShopProduct()
          ]);
          this.messageService.add({
            summary: 'Sucesso',
            severity: 'success',
            detail: 'Produto sincronizado com sucesso.'
          });
          LoaderService.showLoader(false);
        }
      });
  }

  get brand(): Brand | undefined {
    return this.brands?.find((b) => b.brandId === this.product?.brandId);
  }

  get brandEquityLabel(): string | undefined {
    return getEquityLabel(this.brand?.brandEquity as number);
  }

  get category(): ProductCategory | undefined {
    return this.categories?.find(
      (c) => c.categoryId === this.subcategory?.parentId
    );
  }

  get defaultProductVariant(): ProductVariant | undefined {
    return (
      this.productVariants?.find(
        (p) => p.productVariantId === this.product?.defaultProductVariantId
      ) ||
      (this.productVariants && this.productVariants[0])
    );
  }

  get feedbackForm(): FeedbackForm | undefined {
    return this.feedbackForms?.find(
      (f) => f.formId === this.product?.activeFeedbackId
    );
  }

  get canClear(): boolean {
    return (
      (this.searchForm?.value?.field !== undefined &&
        this.searchForm?.value?.field !== 'productId' &&
        this.searchForm.value?.value?.length !== undefined &&
        this.searchForm.value?.value?.length > 0) ||
      (this.searchForm?.value?.field !== undefined &&
        this.searchForm?.value?.field === 'productId' &&
        this.searchForm.value?.value !== null &&
        Number(this.searchForm.value.value) > 0)
    );
  }

  get stockAcquisition(): number {
    return (
      this.productVariants?.reduce(
        (sum, p) => (sum += p.inStockAcquisition || 0),
        0
      ) || 0
    );
  }

  get stockFidelization(): number {
    return (
      this.productVariants?.reduce(
        (sum, p) => (sum += p.inStockFidelization || 0),
        0
      ) || 0
    );
  }

  get stockReserveMarketing(): number {
    return (
      this.productVariants?.reduce(
        (sum, p) => (sum += p.inStockReserveMarketing || 0),
        0
      ) || 0
    );
  }

  get stockInfluencer(): number {
    return (
      this.productVariants?.reduce(
        (sum, p) => (sum += p.inStockInfluencerB4A || 0),
        0
      ) || 0
    );
  }

  get stockTable(): Array<ProductVariantStock> {
    const table =
      this.productVariants?.map((p) => ({
        inStockAcquisition: p.inStockAcquisition || 0,
        inStockFidelization: p.inStockFidelization || 0,
        inStockReserveMarketing: p.inStockReserveMarketing || 0,
        inStockInfluencerB4A: p.inStockInfluencerB4A || 0,
        internalEAN: p.internalEAN,
        productVariantId: p.productVariantId as number,
        productVariantName: p.productVariantName as string
      })) || [];
    table.sort((p1, p2) => {
      if (p1.productVariantName > p2.productVariantName) {
        return 1;
      } else if (p1.productVariantName < p2.productVariantName) {
        return -1;
      } else {
        return p1.productVariantId - p2.productVariantId;
      }
    });

    return table;
  }

  get toSync() {
    return ProductDetailsComponent.fieldsToSync(this.shopProduct, this.product);
  }

  get toSyncString() {
    return (
      '\n' +
      this.toSync
        ?.map((f) => `- ${f.label}`)
        .toString()
        .replaceAll(',', '\n')
    );
  }

  static fieldsToSync(shopProduct: Partial<ShopProduct>, product: Product) {
    return shopProduct
      ? Object.keys(shopProduct.description)
          .map((c) => getDescriptionField(c))
          .filter(
            (column) =>
              column &&
              ProductDetailsComponent.hasSyncInfo(column, product, shopProduct)
          )
      : [];
  }
}

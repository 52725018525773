import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TooltipModule } from 'primeng/tooltip';
import { AppTableModule } from '../table/app-table.module';
import { PersonInvitesComponent } from './person-invites.component';

@NgModule({
  declarations: [PersonInvitesComponent],
  imports: [CommonModule, AppTableModule, TooltipModule],
  exports: [PersonInvitesComponent]
})
export class PersonInvitesModule {}

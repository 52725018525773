<div class="panel-container">
  <h3>Chama as Amigas - Ganhe glampoints</h3>

  <div class="panel-container__buttons">
    <p-button label="Nova campanha" (onClick)="goToNewScreen()"></p-button>
  </div>

  <div class="panel-container__campaigns">
    <p-panel
      *ngIf="pageBanners && pageBanners[0]; else elseBlock"
      header="Campanha atual"
    >
      <div class="panel-container__campaigns__campaign">
        <ul>
          <li>Nome: {{ pageBanners && pageBanners[0].campaignName }}</li>
          <li>
            Data inicio:
            {{
              pageBanners && pageBanners[0].beginDate | date : 'dd/MM/yyyy H:mm'
            }}
          </li>
        </ul>
        <div class="panel-container__campaigns__campaign__buttons">
          <p-button
            label="Editar"
            [outlined]="true"
            (onClick)="goToEditScreen(pageBanners && pageBanners[0])"
          ></p-button>
        </div>
      </div>
    </p-panel>

    <div
      *ngIf="pageBanners && pageBanners[1]; else elseBlock"
      class="panel-container__campaigns"
    >
      <p-message
        severity="warn"
        text="A próxima campanha excluirá a campanha atual na data agendada"
      ></p-message>
      <p-panel
        *ngFor="let section of pageBanners?.slice(1); let i = index"
        header="Próxima campanha"
      >
        <div class="panel-container__campaigns__campaign">
          <ul>
            <li>Nome: {{ pageBanners[i + 1].campaignName }}</li>
            <li>
              Data inicio:
              {{ pageBanners[i + 1].beginDate | date : 'dd/MM/yyyy H:mm' }}
            </li>
          </ul>
          <div class="panel-container__campaigns__campaign__buttons">
            <p-button
              label="Editar"
              [outlined]="true"
              (onClick)="goToEditScreen(pageBanners[i + 1])"
            ></p-button>
            <p-button
              label="Excluir"
              [outlined]="true"
              severity="danger"
              (onClick)="deleteSection(pageBanners[i + 1])"
            ></p-button>
          </div>
        </div>
        <p-confirmDialog></p-confirmDialog>
      </p-panel>
    </div>
  </div>
</div>

<ng-template #elseBlock>
  <p-panel header="Próxima campanha">Próxima campanha não cadastrada</p-panel>
</ng-template>

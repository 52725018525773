<app-table
  *ngIf="cols && dropdownFilters"
  [cols]="cols"
  pageFunctionName="getProductsList"
  [service]="productService"
  modelName="produtos"
  sortField="name"
  [sortOrder]="-1"
  [defaultRows]="10"
  [changeColumns]="true"
  [addButton]="false"
  [dropdownFilters]="dropdownFilters"
  (booleanAction)="updatePublished($event)"
  [extraButton]="true"
  extraLabel="Sincronizar Millennium"
  (extraButtonClick)="syncMillennium()"
  [exportExcelExtraButton]="true"
  [exportExcelExtraButtonLabel]="'Produtos e variantes'"
  [exportExcelExtraButtonTooltip]="'Relatório de todos produtos e variantes'"
  (exportExcelExtraButtonClick)="productVariantFullReport()"
/>
<p-toast />

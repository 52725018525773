<div class="custom-query-form">
  <h3 *ngIf="!query">Nova query</h3>
  <h3 *ngIf="query">Editar query {{ query.customQueryId }}</h3>
  <form
    class="form-b4a"
    [formGroup]="form"
    (submit)="submit()"
    *ngIf="customQueryGroups && user"
  >
    <span class="p-float-label">
      <input
        type="text"
        pInputText
        formControlName="queryName"
        name="queryName"
      />
      <label for="queryName">Nome *</label>
    </span>
    <span class="p-float-label">
      <p-dropdown
        [options]="customQueryGroups"
        formControlName="customQueryGroupId"
        optionValue="customQueryGroupId"
        optionLabel="groupName"
        [autoDisplayFirst]="false"
        inputId="customQueryGroupId"
        name="customQueryGroupId"
      ></p-dropdown>
      <label for="customQueryGroupId">Grupo *</label>
    </span>
    <span class="p-float-label">
      <textarea
        id="queryCommand"
        rows="5"
        cols="80"
        pInputTextarea
        formControlName="queryCommand"
        name="queryCommand"
        required
      ></textarea>
      <label for="queryCommand">Query *</label>
      <small
        class="danger"
        *ngIf="
          form.controls['queryCommand'].errors &&
          form.controls['queryCommand'].errors['maxlength']
        "
        >Máximo de 4000 caracteres</small
      >
    </span>
    <div class="buttons">
      <span class="actions">
        <p-button
          label="csv"
          size="small"
          [rounded]="true"
          pTooltip="Exportar CSV"
          tooltipPosition="bottom"
          (onClick)="export('exportCSV')"
        ></p-button>
        <p-button
          label="xls"
          size="small"
          [rounded]="true"
          pTooltip="Exportar XLS"
          tooltipPosition="bottom"
          severity="success"
          (onClick)="export('exportExcel')"
        ></p-button>
        <p-button
          label="html"
          size="small"
          [rounded]="true"
          pTooltip="Exportar HTML"
          tooltipPosition="bottom"
          severity="danger"
          (onClick)="export('exportHTML')"
        ></p-button>
        <p-button
          label="xml"
          size="small"
          [rounded]="true"
          pTooltip="Exportar XML"
          tooltipPosition="bottom"
          severity="secondary"
          (onClick)="export('exportXML')"
        ></p-button>
      </span>
      <p-button
        label="Salvar"
        type="submit"
        [disabled]="!form.valid"
        severity="primary"
        styleClass="p-button-max-size"
      ></p-button>
    </div>
    <small *ngIf="query?.dateUpdated"
      >*Última alteração: {{ query.username }} em
      {{ query.dateUpdated | date : 'dd/MM/yyyy HH:mm:ss' }}</small
    >
  </form>
</div>
<p-toast></p-toast>

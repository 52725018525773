import { Component, ViewChild, inject } from '@angular/core';
import { ConfirmationService, MessageService, PrimeIcons } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  IncidentControllerService,
  SubscriberEditionIncident,
  SubscriberEditionIncidentDetail
} from 'src/app/admin-api';
import { IncidentFormComponent } from 'src/app/components/incident-form/incident-form.component';
import {
  TableActionButton,
  TableColumn,
  TableComponent
} from 'src/app/components/table';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-incidents',
  templateUrl: './incidents.component.html',
  styleUrl: './incidents.component.scss',
  providers: [DialogService, ConfirmationService, MessageService]
})
export class IncidentsComponent {
  @ViewChild(TableComponent) table: TableComponent;

  private dialog = inject(DialogService);
  private confirmationService = inject(ConfirmationService);
  private messageService = inject(MessageService);
  incidentsService = inject(IncidentControllerService);

  cols = [
    new TableColumn.Builder()
      .setHeader('Produto')
      .setField('productVariantName')
      .setRouterLink('/products/catalog/product-variant/')
      .setRouterLinkFieldName('productVariantId')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('SKU Admin')
      .setField('productVariantId')
      .setRouterLink('/products/catalog/product-variant/')
      .setRouterLinkFieldName('productVariantId')
      .setType('number')
      .build(),
    new TableColumn.Builder()
      .setHeader('EAN')
      .setField('internalEAN')
      .setRouterLink('/products/catalog/product-variant/')
      .setRouterLinkFieldName('productVariantId')
      .build(),
    new TableColumn.Builder()
      .setHeader('SKU Millennium')
      .setField('millenniumSKU')
      .setRouterLink('/products/catalog/product-variant/')
      .setRouterLinkFieldName('productVariantId')
      .setType('number')
      .build(),
    new TableColumn.Builder()
      .setHeader('Ocorrência')
      .setField('incident')
      .build(),
    new TableColumn.Builder()
      .setHeader('Descrição')
      .setField('description')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('Assinante')
      .setField('personName')
      .setCondition('contains')
      .setRouterLink('/users/person/')
      .setRouterLinkFieldName('personId')
      .build(),
    new TableColumn.Builder()
      .setHeader('Email')
      .setField('email')
      .setCondition('startsWith')
      .setRouterLink('/users/person/')
      .setRouterLinkFieldName('personId')
      .build(),
    new TableColumn.Builder()
      .setHeader('Usuário')
      .setField('username')
      .setCondition('startsWith')
      .build(),
    new TableColumn.Builder()
      .setHeader('Id movimentação')
      .setField('movementId')
      .setType('number')
      .build(),
    new TableColumn.Builder()
      .setHeader('Data')
      .setField('dateCreated')
      .setType('date')
      .setCondition('between')
      .build(),
    new TableColumn.Builder()
      .setHeader('Ação')
      .setType('button')
      .setField(' ')
      .build()
  ];

  tableActions = [
    new TableActionButton(
      '',
      '',
      PrimeIcons.TRASH,
      () => true,
      '',
      'Excluir ocorrência',
      'bottoms',
      true,
      true,
      'danger',
      'small'
    )
  ];

  addIncident(): void {
    this.dialog
      .open(IncidentFormComponent, {
        header: 'Nova ocorrência',
        closeOnEscape: false,
        width: '400px'
      })
      .onClose.subscribe(async (incident: SubscriberEditionIncident) => {
        if (incident) {
          await this.table?.refresh();
        }
      });
  }

  deleteIncident(
    incident: SubscriberEditionIncidentDetail,
    $event: MouseEvent
  ): void {
    this.confirmationService.confirm({
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      acceptButtonStyleClass: 'p-button-danger',
      target: $event.target,
      message: 'Deseja excluir a ocorrência?',
      header: 'Excluir ocorrência.',
      accept: async () => {
        LoaderService.showLoader();
        try {
          const detail = await lastValueFrom(
            this.incidentsService
              .deleteIncident(incident.incidentId)
              .pipe(map((data) => data.result))
          );
          this.messageService.add({
            severity: 'success',
            detail,
            summary: 'Sucesso'
          });
          await this.table.refresh();
        } catch (error) {
          AppDialogService.showErrorDialog(error);
        }
        LoaderService.showLoader(false);
      }
    });
  }
}

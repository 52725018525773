/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface MovementVariantDTO {
  movementVariantId?: number;
  movementId?: number;
  productVariantId?: number;
  personId?: number;
  movementDate?: Date;
  editionId?: number;
  type?: string;
  productVariantName?: string;
  variantName?: string;
  internalEAN?: string;
  millenniumSKU?: string;
  productId?: number;
  personName?: string;
  expiration?: string;
  theme?: string;
}

export enum SubscriberEditionStatus {
  AGARDANDO_ALOCACAO = 0,
  COM_COMPOSICAO = 1,
  NFE_EMITIDA = 2,
  AGUARDANDO_COLETA = 3,
  COM_TRANSPORTADORA = 4,
  EM_ROTA = 5,
  ENTREGUE = 6,
  FALHA = 7,
  PARALISADO = 8,
  TESTE = 9,
  VINCULADA = 10,
  DEVOLVIDA = 11
}

<app-table
  modelName="ocorrências"
  tableTitle="Ocorrências"
  [service]="incidentsService"
  pageFunctionName="findIncidentesTable"
  [cols]="cols"
  addLabel="Nova ocorrência"
  [addButtonAction]="true"
  (addButtonClick)="addIncident()"
  sortField="dateCreated"
  [sortOrder]="-1"
  [actionButtons]="tableActions"
  (actionButtonClick)="deleteIncident($event.item, $event.$event)"
/>
<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>

import { Component, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import {
  CardDifference,
  ItOpsControllerService,
  SubscriberProblemGroupDetail
} from 'src/app/admin-api';
import { TableColumn, TableComponent } from 'src/app/components/table';
import { CardDifferenceModalComponent } from './card-difference-modal/card-difference-modal.component';

@Component({
  selector: 'app-card-difference',
  templateUrl: './card-difference.component.html',
  styleUrls: ['./card-difference.component.scss'],
  providers: [
    MessageService,
    DialogService,
    ConfirmationService,
    ItOpsControllerService
  ]
})
export class CardDifferenceComponent {
  @ViewChild(TableComponent) table: TableComponent;

  columns: Array<TableColumn> = [
    new TableColumn('', '', false, 'checkbox'),
    new TableColumn(
      'SubscriberId',
      'subscriberId',
      true,
      'number',
      '/users/subscribers/',
      'subscriberId'
    ),
    new TableColumn('EditionId', 'editionId', true, 'number'),
    new TableColumn('SubscriberCard', 'subscriberCard', true, 'number'),
    new TableColumn('BillingCard', 'billingCard', true, 'number'),
    new TableColumn('CreditCardCard', 'creditCardCard', true, 'number'),
    new TableColumn('CorrectCard', 'correctCard', true, 'number')
  ];
  ready = true;

  constructor(
    public itOpsService: ItOpsControllerService,
    private dialog: DialogService,
    private messageService: MessageService
  ) {}

  openActionModal(recurrences: Array<CardDifference>): void {
    this.dialog
      .open(CardDifferenceModalComponent, {
        height: '65vh',
        header: 'Cartões Diferentes',
        data: {
          recurrences
        }
      })
      .onClose.subscribe(async (data: Array<SubscriberProblemGroupDetail>) => {
        if (data) {
          this.ready = false;
          setTimeout(() => {
            this.messageService.add({
              severity: 'success',
              summary: 'Sucesso',
              detail: recurrences.length + ' marcado(s) como resolvido(s)'
            });
            this.ready = true;
          }, 1000);
        }
      });
  }
}

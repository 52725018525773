/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ProductReviewDetailed {
  productId?: number;
  productVariantId?: number;
  personId?: number;
  nickname?: string;
  rating?: number;
  reviewTitle?: string;
  review?: string;
  reviewStatus?: number;
  beautyStyle?: string;
  hairType?: string;
  skinType?: string;
  skinTone?: string;
  hairColor?: string;
  ageRange?: string;
  dateCreated?: Date;
  dateUpdated?: Date;
  replyId?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  rewardBalance?: number;
  productName?: string;
  descriptionArticleId?: string;
  defaultImageURL?: string;
  defaultProductVariantId?: number;
  productVariantName?: string;
  variantImageUrl?: string;
  productSummary?: string;
  productReviewStatus?: string;
}

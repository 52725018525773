import {
  Component,
  inject,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import {
  Category,
  ShopBanner,
  ShopBrand,
  ShopBrands,
  ShopSection,
  ShopSettings
} from '@infrab4a/connect';
import { GridStackOptions, GridStackWidget } from 'gridstack';
import { ConfirmationService, MessageService } from 'primeng/api';
import {
  AutoCompleteCompleteEvent,
  AutoCompleteSelectEvent
} from 'primeng/autocomplete';
import { GridstackComponent } from 'src/app/components/gridstack/gridstack.component';
import {
  CarouselSection,
  isBrands,
  isCarousel,
  ShopSettingsService
} from 'src/app/connect-api/api/shop-settings/shop-settings.service';
import { ShopCategoryService } from 'src/app/connect-api/api/shop/shop-category.service';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { environment } from 'src/environments/environment';
import { GlamHomeComponent } from '../glam-home.component';

@Component({
  selector: 'app-glam-home-section',
  templateUrl: './glam-home-section.component.html',
  styleUrl: './glam-home-section.component.scss',
  encapsulation: ViewEncapsulation.None,
  providers: [MessageService]
})
export class GlamHomeSectionComponent implements OnInit {
  @ViewChild(GridstackComponent) gridComp?: GridstackComponent;
  private activatedRoute = inject(ActivatedRoute);
  private shopSettingsService = inject(ShopSettingsService);
  private messageService = inject(MessageService);
  private confirmationService = inject(ConfirmationService);
  protected categoryService = inject(ShopCategoryService);
  protected brands: ShopBrand[];
  settings: Partial<ShopSettings> & { sections?: ShopSection[] };
  section: ShopBrands | CarouselSection;
  gridOptions: GridStackOptions = {
    margin: 5,
    minRow: 1,
    cellHeight: '200px',
    removable: false,
    column: 1
  };
  responsiveOptions = [
    {
      breakpoint: '1199px',
      numVisible: 1,
      numScroll: 1
    },
    {
      breakpoint: '991px',
      numVisible: 2,
      numScroll: 1
    },
    {
      breakpoint: '767px',
      numVisible: 1,
      numScroll: 1
    }
  ];
  sectionBrands: Array<ShopBrand & { control: FormControl<ShopBrand> }>;

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader();
    this.activatedRoute.params.subscribe(async (params: Params) => {
      try {
        if (
          params['type'] &&
          (params['type'] === 'brands' || params['type'] === 'carousel')
        ) {
          this.settings = await this.shopSettingsService.getById(
            GlamHomeComponent.documentId
          );
          this.section = this.settings?.sections?.find(
            (s) => s.type === params['type']
          );
          this.startFormControls();
        } else if (params['type']) throw { message: 'Tipo de seção inválida' };
        else throw { message: 'Informe o tipo da seção' };
      } catch (error) {
        AppDialogService.showErrorDialog(error, true);
      } finally {
        LoaderService.showLoader(false);
      }
    });
  }

  startFormControls(): void {
    if (this.section && isBrands(this.section) && this.section.brands?.length) {
      this.sectionBrands = [];
      for (let index = 0; index < 12; index++) {
        if (this.section.brands.length < index + 1)
          this.section.brands.push(ShopSettingsService.emptyBrand);
        this.sectionBrands.push({
          ...this.section.brands[index],
          control: new FormControl(this.section.brands[index], [
            Validators.required
          ])
        });
      }
    }
  }

  deleteBanner($event: MouseEvent, item: any): void {
    if (
      this.carouselSection(this.section) &&
      !this.section.banners.filter((b) => b.id !== item.id && b.published)
        .length
    ) {
      AppDialogService.showErrorDialog({
        message: 'Não é possível remover o único banner ativo'
      });
      return;
    }
    this.confirmationService.confirm({
      acceptLabel: 'Remover',
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: 'Voltar',
      target: $event.target,
      message: `Desejar remover ${
        this.section.type === 'carousel' ? 'o banner' : 'a marca'
      } ${item.altText || item.brandName}?`,
      accept: async () => {
        try {
          LoaderService.showLoader();
          const widget = this.gridComp?.gridstackItems?.find(
            (i) => i.options.id === item.id
          )?.el;
          if (!widget)
            throw {
              message:
                'Não foi possível remover o item. Atualize a página e tente novamente.'
            };
          this.gridComp?.grid?.removeWidget(widget);
          await this.listChanged();
        } catch (error) {
          AppDialogService.showErrorDialog(error);
        }
      }
    });
  }

  async listChanged(): Promise<void> {
    LoaderService.showLoader();
    const grid = this.gridComp?.grid?.save() as GridStackWidget[];
    const sections: Array<ShopBrand | ShopBanner> = [];
    grid.forEach((i) => {
      if (isBrands(this.section)) {
        sections.push(
          this.section?.brands?.find((b) => b.collectionId === i.id) ||
            ShopSettingsService.emptyBrand
        );
      } else {
        sections.push(this.section?.banners?.find((s) => s.id === i.id));
      }
    });
    const settings = { ...this.settings };
    const ref = settings.sections.find((s) => s.id === this.section.id);
    if (isCarousel(ref)) {
      ref.banners = sections as ShopBanner[];
    } else {
      ref.brands = sections as ShopBrand[];
    }
    await this.updateSettings(settings);
    this.messageService.add({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Itens atualizados!'
    });
    LoaderService.showLoader(false);
  }

  async updateSettings(settings: Partial<ShopSettings>): Promise<void> {
    try {
      this.settings = await this.shopSettingsService.update(settings);
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async searchBrands(
    event: AutoCompleteCompleteEvent,
    brand: ShopBrand & { control: FormControl<ShopBrand> }
  ): Promise<void> {
    try {
      const index = this.sectionBrands.indexOf(brand);
      const result = await Promise.all([
        this.categoryService.getCategoriesList({
          filters: [
            {
              condition: 'contains',
              field: 'slug',
              value: event.query,
              fieldType: 'text'
            },
            {
              condition: 'equals',
              field: 'brandCategory',
              value: true,
              fieldType: 'boolean'
            },
            {
              condition: 'equals',
              field: 'shop',
              value: this.settings.shop,
              fieldType: 'boolean'
            }
          ],
          page: 0,
          pageSize: 10
        }),
        this.categoryService.getCategoriesList({
          filters: [
            {
              condition: 'contains',
              field: 'name',
              value: event.query,
              fieldType: 'text'
            },
            {
              condition: 'equals',
              field: 'brandCategory',
              value: true,
              fieldType: 'boolean'
            },
            {
              condition: 'equals',
              field: 'shop',
              value: this.settings.shop,
              fieldType: 'boolean'
            }
          ],
          page: 0,
          pageSize: 10
        })
      ]);
      const brands = [
        ...(
          result.reduce(
            (cats, r) => cats.concat(r.content),
            []
          ) as Partial<Category>[]
        )
          .map(
            (c) =>
              ({
                brandName: c.name,
                slug: c.slug,
                image: c.image,
                collectionId: c.id
              } as ShopBrand)
          )
          .filter(
            (c) =>
              isBrands(this.section) &&
              this.sectionBrands?.every(
                (s, i) => s.collectionId !== c.collectionId || index === i
              )
          )
      ];
      this.brands = [
        ...new Map(brands.map((b) => [b.collectionId, b])).values()
      ];
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async brandChange(
    brand: ShopBrand & { control: FormControl<ShopBrand> },
    $event?: AutoCompleteSelectEvent
  ): Promise<void> {
    LoaderService.showLoader();
    const index = this.sectionBrands.indexOf(brand);
    const brandValue = $event?.value as ShopBrand;
    if (
      brandValue?.collectionId &&
      brandValue.collectionId !== 'placeholder' &&
      this.brandsSection(this.section)
    ) {
      const settings = { ...this.settings };
      const ref = settings.sections.find((s) => s.id === this.section.id);
      if (index < 0) {
        AppDialogService.showErrorDialog({ message: 'Referência não existe' });
        return;
      }
      if (
        !this.sectionBrands[index].collectionId ||
        this.sectionBrands[index].collectionId !== brandValue.collectionId
      ) {
        this.sectionBrands[index].brandName = brandValue.brandName;
        this.sectionBrands[index].collectionId = brandValue.collectionId;
        this.sectionBrands[index].image = brandValue.image;
        this.sectionBrands[index].slug = brandValue.slug;
        this.sectionBrands[index].control.reset(brandValue);
        ref.brands = this.sectionBrands.map((s) => ({
          brandName: s.brandName,
          collectionId: s.collectionId,
          image: s.image,
          slug: s.slug
        }));
        await this.updateSettings(settings);
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'Marca atualizada!'
        });
      }
    } else {
      brand.control.reset(
        (this.section as ShopBrands).brands[index] ||
          ShopSettingsService.emptyBrand
      );
    }
    LoaderService.showLoader(false);
  }

  checkBrand(brand: ShopBrand & { control: FormControl<ShopBrand> }) {
    if (!brand.control.valid || typeof brand.control.value === 'string')
      brand.control.patchValue(brand);
  }

  carouselSection(
    section: CarouselSection | ShopBrands
  ): section is CarouselSection {
    return isCarousel(section);
  }

  brandsSection(section: CarouselSection | ShopBrands): section is ShopBrands {
    return isBrands(section);
  }

  brandName(brand: ShopBrand): string {
    return `${brand.brandName} [slug: ${brand.slug}]`;
  }

  brandUrl(brand: ShopBrand): string {
    if (!brand.slug || brand.slug === 'placeholder') return null;
    return `${environment.glamUrl}/loja/collections/${brand.slug}`;
  }

  onlyActiveBanner(banner: ShopBanner): boolean {
    return (
      this.carouselSection(this.section) &&
      this.section.banners
        .filter((b) => b.id !== banner.id)
        .every((b) => !b.published)
    );
  }
}

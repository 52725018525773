import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { lastValueFrom, map } from 'rxjs';
import {
  InfluencerControllerService,
  InfluencerCoupon,
  InfluencerCouponDetailView,
  InfluencerDetail,
  InfluencerStatus
} from 'src/app/admin-api';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-influencer-form',
  templateUrl: './influencer-form.component.html',
  styleUrls: ['./influencer-form.component.scss'],
  providers: [MessageService]
})
export class InfluencerFormComponent implements OnInit {
  @Input()
  influencer: InfluencerDetail | undefined;

  @Input()
  coupons: Array<InfluencerCouponDetailView> | undefined;

  @Input()
  influencerStatusList: Array<InfluencerStatus> | undefined;

  @Input()
  isMobile: boolean | undefined;

  @Output()
  couponAdded = new EventEmitter<InfluencerCoupon>();

  formGroup: FormGroup | undefined;

  constructor(
    private influencerService: InfluencerControllerService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      influencerStatusId: new FormControl(this.influencer?.influencerStatusId, [
        Validators.required
      ]),
      enabled: new FormControl(this.influencer?.enabled),
      personId: new FormControl(this.influencer?.personId, Validators.required),
      pis: new FormControl(this.influencer?.pis),
      rg: new FormControl(this.influencer?.rg)
    });
  }

  async updateInfluencerStatus(): Promise<void> {
    if (this.influencer) {
      LoaderService.showLoader();
      try {
        const influ = await lastValueFrom(
          this.influencerService
            .updateInfluencer(this.formGroup?.value)
            .pipe(map((data) => data.result))
        );
        this.influencer.enabled = influ?.enabled;
        this.influencer.influencerStatusId = influ?.influencerStatusId;
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'Dados atualizados com sucesso'
        });
      } catch (error: any) {
        AppDialogService.showErrorDialog(error);
      }
      LoaderService.showLoader(false);
    }
  }

  newCoupon(coupon: InfluencerCoupon): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Cupom adicionado com sucesso'
    });
    this.couponAdded.emit(coupon);
  }
}

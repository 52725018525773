import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgxPermissionsService } from 'ngx-permissions';
import { lastValueFrom, map } from 'rxjs';
import { BadgeControllerService } from 'src/app/admin-api';
import { PageableRequest } from 'src/app/admin-api/model/pageableRequest';
import { PageContent, TableColumn } from 'src/app/components/table';
import { Badge, Role, roleAsObject } from 'src/app/models';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrl: './badge.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class BadgeComponent implements OnInit {
  permission = false;
  cols = [
    new TableColumn(
      'ícone',
      'iconUrl',
      true,
      'image',
      '/subscription-settings/badge/',
      'badgeId',
      true,
      'contains',
      'iconCol'
    ),
    new TableColumn(
      'Id',
      'badgeId',
      true,
      'number',
      '/subscription-settings/badge/',
      'badgeId',
      true,
      'contains'
    ),
    new TableColumn(
      'Jóia',
      'title',
      true,
      'text',
      '/subscription-settings/badge/',
      'badgeId',
      true,
      'contains'
    ),
    new TableColumn(
      'Ed. Consecutivas',
      'consecutiveEditionsToAchieve',
      true,
      'formattedInteger'
    ),
    new TableColumn(
      'Multiplicador',
      'glampointsMultiplier',
      true,
      'formattedNumber'
    ),
    new TableColumn('Nível', 'level', true, 'text')
  ];

  constructor(
    private title: Title,
    public badgeService: BadgeControllerService,
    private permissionsService: NgxPermissionsService
  ) {
    this.title.setTitle('Jóias');
  }

  async ngOnInit(): Promise<void> {
    this.permission = await this.permissionsService.hasPermission([
      roleAsObject(Role.Full_Administrator).enumValue
    ]);
  }

  async findPage(
    request: PageableRequest,
    service: unknown
  ): Promise<PageContent<Badge>> {
    return lastValueFrom(
      (service as BadgeControllerService)
        .listBadge(request)
        .pipe(map((data) => data.result as PageContent<Badge>))
    );
  }
}

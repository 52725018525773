import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AutoFocusModule } from 'primeng/autofocus';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { TreeSelectModule } from 'primeng/treeselect';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { FeedbackFormComponent } from './feedback-form.component';
import { FeedbackGroupFormComponent } from './feedback-group-form/feedback-group-form.component';
import { FeedbackOptionCloneComponent } from './feedback-option-clone/feedback-option-clone.component';
import { FeedbackOptionFormComponent } from './feedback-option-form/feedback-option-form.component';
import { FeedbackQuestionCloneComponent } from './feedback-question-clone/feedback-question-clone.component';
import { FeedbackQuestionFormComponent } from './feedback-question-form/feedback-question-form.component';

@NgModule({
  declarations: [
    FeedbackFormComponent,
    FeedbackQuestionFormComponent,
    FeedbackOptionFormComponent,
    FeedbackGroupFormComponent,
    FeedbackQuestionCloneComponent,
    FeedbackOptionCloneComponent
  ],
  imports: [
    CommonModule,
    InputTextModule,
    InputNumberModule,
    InputSwitchModule,
    ReactiveFormsModule,
    ButtonModule,
    CheckboxModule,
    ToastModule,
    DropdownModule,
    TooltipModule,
    FileUploadModule,
    TriStateCheckboxModule,
    ConfirmDialogModule,
    AutoFocusModule,
    AutoCompleteModule,
    TreeSelectModule,
    MultiSelectModule,
    NgxPermissionsModule.forChild()
  ],
  exports: [
    FeedbackFormComponent,
    FeedbackQuestionFormComponent,
    FeedbackOptionFormComponent,
    FeedbackGroupFormComponent,
    FeedbackQuestionCloneComponent,
    FeedbackOptionCloneComponent
  ],
  providers: [MessageService]
})
export class FeedbackFormModule {}

import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { Order } from '@infrab4a/connect';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SubscriberEditionCompositionSummary } from 'src/app/admin-api';
import { TrackingModel } from 'src/app/models';
import { FormUtil } from 'src/app/utils/form.util';
import { TableColumn } from '../table';

@Component({
  selector: 'app-shipping-tracking-modal',
  templateUrl: './shipping-tracking-modal.component.html',
  styleUrls: ['./shipping-tracking-modal.component.scss'],
  providers: [DatePipe]
})
export class ShippingTrackingModalComponent {
  edition: SubscriberEditionCompositionSummary;
  order: Partial<Order>;
  tracking: Array<TrackingModel>;
  trackingHistory: Array<{
    shippingServiceId: string;
    shippingServiceName: string;
    history: Array<TrackingModel>;
    lastUpdate: Date;
  }> = [];
  cols: Array<TableColumn> = [
    new TableColumn('TrackingCode', 'nota', false, 'text'),
    new TableColumn('Data', 'data_do_evento', false, 'date'),
    new TableColumn('Status', 'descricao_do_evento', false, 'text'),
    new TableColumn('Observação', 'observacao', false, 'text')
  ];

  constructor(
    private config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private datePipe: DatePipe
  ) {
    this.edition = this.config.data.edition;
    this.order = this.config.data.order;
    this.tracking = this.config.data.tracking || [];
    this.tracking.forEach((t) => {
      if (
        !this.trackingHistory.length ||
        this.trackingHistory.every(
          (h) => h.shippingServiceId !== t.transportador
        )
      ) {
        const history = this.tracking.filter(
          (tr) => tr.transportador === t.transportador
        );
        history.sort(
          (h1, h2) =>
            (h2.data_do_evento as any).getTime() -
            (h1.data_do_evento as any).getTime()
        );
        this.trackingHistory.push({
          shippingServiceName: t.nome_transportador as string,
          shippingServiceId: t.transportador,
          history,
          lastUpdate: history[0].data_do_evento as any
        });
      }
    });
    this.trackingHistory.sort(
      (h1, h2) => h2.lastUpdate.getTime() - h1.lastUpdate.getTime()
    );
    if (
      this.trackingHistory?.some((t) =>
        t.history?.some((h) => h.url_comprovante)
      )
    ) {
      this.cols.push(
        new TableColumn.Builder()
          .setHeader('Documento')
          .setField('url_comprovante')
          .setRouterLink((item: TrackingModel) => item.url_comprovante)
          .setDisplayFunction(() => 'Comprovante')
          .setFilter(false)
          .build()
      );
    }
  }

  timeDiffLabel(dateStart: string): string {
    return FormUtil.timeDiffLabel(dateStart, this.datePipe);
  }
}

import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
  ViewChild,
  computed
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { TabView } from 'primeng/tabview';
import { lastValueFrom, map } from 'rxjs';
import {
  PageableFilter,
  PageableRequest,
  ProductCategory,
  ProductCategorySummaryDTO,
  ProductControllerService,
  ProductSummaryItem,
  ProductVariant
} from 'src/app/admin-api';
import { PageContent, TableColumn } from 'src/app/components/table';
import {
  Role,
  getAllBrandEquities,
  getEquityLabel,
  roleAsObject
} from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-category-detail',
  templateUrl: './category-detail.component.html',
  styleUrls: ['./category-detail.component.scss']
})
export class CategoryDetailComponent implements OnInit {
  @ViewChild(TabView)
  tabview: TabView | undefined;

  categoryId: number | undefined;
  category: ProductCategory | undefined;
  categories: Array<ProductCategory> | undefined;
  products: Array<ProductSummaryItem> | undefined;
  productVariants: Array<ProductVariant> | undefined;
  selectedTab = 0;
  isMobile?: boolean;
  productCols: Array<TableColumn>;
  variantCols: Array<TableColumn> = [
    new TableColumn(
      'Id',
      'externalId',
      true,
      'number',
      '/products/catalog/product-variant/',
      'productVariantId'
    ),
    new TableColumn(
      'Nome',
      'productVariantName',
      true,
      'text',
      '/products/catalog/product-variant/',
      'productVariantId',
      true,
      'contains'
    ),
    new TableColumn(
      'Marca',
      'brandName',
      true,
      'text',
      '/products/brands/',
      'brandId',
      true,
      'contains'
    ),
    new TableColumn(
      'InternalEAN',
      'internalEAN',
      true,
      'text',
      '/products/catalog/product-variant/',
      'productVariantId',
      true,
      'contains'
    ),
    new TableColumn('Peso (g)', 'weight', false, 'formattedNumber'),
    new TableColumn(
      'Validade',
      'expiration',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Data cadastro',
      'dateCreated',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    ),
    new TableColumn(
      'Data atualização',
      'dateUpdated',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    )
  ];
  categoryCols: Array<TableColumn> = [
    new TableColumn(
      'Id',
      'categoryId',
      true,
      'number',
      '/products/categories/',
      'categoryId'
    ),
    new TableColumn(
      'Id Externo',
      'externalIdString',
      true,
      'text',
      '/products/categories/',
      'categoryId',
      true,
      'contains'
    ),
    new TableColumn(
      'Categoria',
      'category',
      true,
      'text',
      '/products/categories/',
      'categoryId',
      true,
      'contains'
    ),
    new TableColumn('Produtos', 'products', false, 'text')
  ];
  fixedFilters: Array<PageableFilter> | undefined;
  dropdownFilters = computed(() => {
    return {
      brandEquityLabel: [{ label: 'Todas', value: '-1' }].concat(
        getAllBrandEquities().map((e) => ({
          label: e.label,
          value: e.label
        }))
      ),
      subcategory:
        this.categories
          ?.filter((c) => c.parentId === this.categoryId)
          .map((c) => ({
            label: `${this.category.externalId}.${c.externalId} ${c.displayName}`,
            value: `${this.category.externalId}.${c.externalId} ${c.displayName}`
          })) || []
    };
  });

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.checkScreenSize();
  }
  permission = false;

  constructor(
    public productService: ProductControllerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private title: Title,
    private ngxPermissionsService: NgxPermissionsService
  ) {}

  checkScreenSize(): void {
    this.isMobile = window.innerWidth < 768;
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(async (params) => {
      this.permission = await this.ngxPermissionsService.hasPermission([
        roleAsObject(Role.Full_Administrator).enumValue,
        roleAsObject(Role.Admin_Allocation).enumValue,
        roleAsObject(Role.User_Allocation).enumValue
      ]);
      if (params['categoryId'] && params['categoryId'] !== 'new') {
        try {
          LoaderService.showLoader();
          this.categoryId = Number(params['categoryId']);
          await Promise.all([
            this.findCategory(),
            this.findCategoryProducts(),
            this.findCategoriesList(),
            this.findCategoryProductVariants()
          ]);
          if (!this.parentCategory) {
            this.fixedFilters = [
              {
                condition: 'equals',
                field: 'parentId',
                value: this.category?.categoryId?.toString(),
                fieldType: 'number'
              }
            ];
          }
          this.setProductCols();
          this.title.setTitle('Categoria ' + this.category?.displayName);
          this.activatedRoute.queryParams.subscribe((queryParams) => {
            if (queryParams['tab']) {
              this.selectedTab = Number(queryParams['tab']) || 0;
            }
          });
        } catch (error: any) {
          if (params['categoryId'] !== 'new') {
            AppDialogService.showErrorDialog(
              { message: 'Categoria não encontrada' },
              true
            );
          }
        }
      } else if (this.permission) {
        LoaderService.showLoader();
        await this.findCategoriesList();
        this.title.setTitle('Nova categoria');
      } else {
        this.router.navigate(['/unauthorized']);
      }
      LoaderService.showLoader(false);
    });
  }

  async findCategory(): Promise<void> {
    try {
      this.category = await lastValueFrom(
        this.productService
          .findProductCategoryById(this.categoryId as number)
          .pipe(map((data) => data.result))
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error, true);
    }
  }

  async findCategoryProducts(): Promise<void> {
    try {
      this.products = await lastValueFrom(
        this.productService
          .findProductCategoryProducts(this.categoryId as number)
          .pipe(
            map((data) =>
              data.result?.map((p) => ({
                ...p,
                brandEquityLabel: getEquityLabel(p.brandEquity as number)
              }))
            )
          )
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findCategoryProductVariants(): Promise<void> {
    try {
      this.productVariants = await lastValueFrom(
        this.productService
          .findProductVariantsListByCategoryId(this.categoryId as number)
          .pipe(
            map((data) =>
              data.result?.map((p) => ({
                ...p,
                brandEquityLabel: getEquityLabel(p.brandEquity as number)
              }))
            )
          )
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findCategoriesList(): Promise<void> {
    try {
      this.categories = await lastValueFrom(
        this.productService
          .findProductCategoriesList()
          .pipe(map((data) => data.result))
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
  }

  tabChanged($event: number): void {
    this.router.navigate([`products/categories/${this.categoryId}`], {
      queryParams: { tab: $event },
      queryParamsHandling: 'merge'
    });
  }

  async updateCategory(category: ProductCategory): Promise<void> {
    this.category = category;
    this.cdRef.detectChanges();
    LoaderService.showLoader(false);
  }

  async findPage(
    request: PageableRequest,
    service: unknown
  ): Promise<PageContent<ProductCategorySummaryDTO>> {
    return lastValueFrom(
      (service as ProductControllerService)
        .findProductCategoriesTable(request)
        .pipe(
          map((data) => data.result as PageContent<ProductCategorySummaryDTO>)
        )
    );
  }

  setProductCols(): void {
    let cols = [
      new TableColumn(
        'Id',
        'externalId',
        true,
        'number',
        '/products/catalog/product/',
        'productId'
      ),
      new TableColumn(
        'InternalEAN',
        'internalEAN',
        true,
        'text',
        '/products/catalog/product/',
        'productId',
        true,
        'contains'
      ),
      new TableColumn(
        'Nome',
        'productName',
        true,
        'text',
        '/products/catalog/product/',
        'productId',
        true,
        'contains'
      ),
      new TableColumn(
        'Marca',
        'brandName',
        true,
        'text',
        '/products/brands/',
        'brandId',
        true,
        'contains'
      ),
      new TableColumn(
        'BrandEquity',
        'brandEquityLabel',
        true,
        'text',
        undefined,
        undefined,
        true,
        'equals'
      ),
      new TableColumn(
        'Subcategoria',
        'subcategory',
        true,
        'text',
        '/products/categories/',
        'subcategoryId',
        true,
        'contains'
      ),
      new TableColumn('Nota', 'ratingAverage', false, 'formattedNumber'),
      new TableColumn('Avaliações', 'reviewCount', false, 'formattedInteger'),
      new TableColumn('Estoque', 'stock', false, 'formattedInteger'),
      new TableColumn(
        'Criação',
        'dateCreated',
        true,
        'date',
        undefined,
        undefined,
        true,
        'between'
      )
    ];
    if (this.parentCategory) {
      cols = [...cols.filter((c) => c.field !== 'subcategory')];
    }
    this.productCols = [...cols];
  }

  get childrenCategories(): Array<ProductCategory> {
    return (
      this.categories?.filter(
        (c) => c.parentId && c.parentId === this.categoryId
      ) || []
    );
  }

  get parentCategory(): ProductCategory | undefined {
    return this.categories?.find(
      (c) => this.category?.parentId && c.categoryId === this.category.parentId
    );
  }
}

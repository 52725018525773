import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Buy2WinComponent } from './buy-2-win/buy-2-win.component';
import { CampaignDetailsComponent } from './buy-2-win/campaign-details/campaign-details.component';
import { CouponDetailComponent } from './coupons/coupon-detail/coupon-detail.component';
import { CouponsComponent } from './coupons/coupons.component';
import { ShopCouponDetailComponent } from './shop-coupons/shop-coupon-detail/shop-coupon-detail.component';
import { ShopCouponsComponent } from './shop-coupons/shop-coupons.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'coupons' },
  { path: 'coupons', component: CouponsComponent },
  { path: 'coupons/:couponId', component: CouponDetailComponent },
  { path: 'shop-coupons', component: ShopCouponsComponent },
  { path: 'shop-coupons/new', component: ShopCouponDetailComponent },
  { path: 'shop-coupons/:couponId', component: ShopCouponDetailComponent },
  {
    path: 'buy-2-win',
    component: Buy2WinComponent
  },
  {
    path: 'buy-2-win/:campaignId',
    component: CampaignDetailsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MarketingRoutingModule {}

import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { lastValueFrom, map } from 'rxjs';
import {
  BrandControllerService,
  BrandSummaryView,
  PageableRequest
} from 'src/app/admin-api';
import { PageContent, TableColumn } from 'src/app/components/table';
import { getAllBrandEquities, getEquityLabel } from 'src/app/models';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss'],
  providers: [DatePipe]
})
export class BrandsComponent {
  cols = [
    new TableColumn('Id', 'brandId', true, 'number', '', 'brandId'),
    new TableColumn(
      'Nome',
      'brandName',
      true,
      'text',
      '',
      'brandId',
      true,
      'contains'
    ),
    new TableColumn(
      'BrandEquity',
      'brandEquityLabel',
      true,
      'text',
      undefined,
      undefined,
      true,
      'equals'
    ),
    new TableColumn('Produtos', 'products', false, 'formattedInteger'),
    new TableColumn(
      'Data criação',
      'dateCreated',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    ),
    new TableColumn(
      'Data atualização',
      'dateUpdated',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    )
  ];
  dropdownFilters = {
    brandEquityLabel: [{ label: 'Todas', value: '-1' }].concat(
      getAllBrandEquities().map((e) => ({
        label: e.label,
        value: e.value.toString()
      }))
    )
  };

  constructor(
    public brandService: BrandControllerService,
    private title: Title
  ) {
    this.title.setTitle('Produtos de assinatura - Marcas');
  }

  async findPage(
    request: PageableRequest,
    brandService: unknown
  ): Promise<PageContent<BrandSummaryView>> {
    const filterEquity = request.filters?.find(
      (f) => f.field === 'brandEquityLabel'
    );
    if (filterEquity?.value) {
      if (Number(filterEquity.value) < 0) {
        filterEquity.value = '';
      }
      request.filters?.push({
        condition: 'equals',
        field: 'brandEquity',
        fieldType: 'number',
        value: filterEquity.value
      });
    }
    return lastValueFrom(
      (brandService as BrandControllerService).findBrandsTable(request).pipe(
        map((data) => {
          if (data.result?.content) {
            data.result.content = data.result.content.map((p) => ({
              ...p,
              brandEquityLabel: `${getEquityLabel(p.brandEquity as number)}`
            }));
          }
          return data.result as PageContent<BrandSummaryView>;
        })
      )
    );
  }
}

<h1>Dashboard de Exportações</h1>

<h2>Pedidos pendentes de exportação</h2>
<div *ngIf="pendingExportOrder !== null" class="integration-dashboard">
  <div class="dashboard-card" *ngFor="let key of getKeys(pendingExportOrder)">
    <span class="header header--primary">
      {{ keyDescription[key] }}
    </span>
    <span class="value">
      {{ pendingExportOrder[key] | number : '1.0-0' }}
    </span>
  </div>
</div>

<br />

<h2>
  Pedidos exportados no mês de
  {{ selectedMonthName | titlecase }}
</h2>
<form class="form-b4a" *ngIf="exportedOrderTotal">
  <span class="p-float-label">
    <p-dropdown
      id="selectedMonth"
      [options]="months"
      [formControl]="selectedMonth"
      (ngModelChange)="onMonthChange($event.value)"
      inputId="selectedMonth"
      name="selectedMonth"
    >
    </p-dropdown>
    <label for="selectedMonth">Selecione um mês:</label>
  </span>
</form>

<br />

<div *ngIf="exportedOrderTotal" class="integration-dashboard">
  <div class="dashboard-card" *ngFor="let key of getKeys(exportedOrderTotal)">
    <span class="header header--success">
      {{ keyDescription[key] }}
    </span>
    <span class="value">
      {{ exportedOrderTotal[key] | number : '1.0-0' }}
    </span>
  </div>
</div>

<br />

<div>
  <p-chart
    type="bar"
    [data]="chartData"
    [options]="chartConfig"
    [responsive]="true"
  />
</div>

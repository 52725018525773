import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  NotificationControllerService,
  NotificationTrendRequest,
  PageableFilter,
  PageableRequest
} from 'src/app/admin-api';
import { EmailNotificationDetail } from 'src/app/admin-api/model/emailNotificationDetail';
import { PageEmailNotificationDetail } from 'src/app/admin-api/model/pageEmailNotificationDetail';
import { TableColumn, TableComponent } from 'src/app/components/table';

interface DropdownOption {
  label: string;
  value: string;
}

@Component({
  selector: 'app-email-list',
  templateUrl: './email-list.component.html',
  styleUrls: ['./email-list.component.scss']
})
export class EmailListComponent implements OnInit {
  @ViewChild(TableComponent) table: TableComponent;

  config = inject(DynamicDialogConfig);
  public notificationService = inject(NotificationControllerService);

  event: {
    color: '#4CAF50';
    label: 'Total Enviados';
    eventName: string;
  } = this.config.data.event;
  filters: NotificationTrendRequest = this.config.data.filters;

  selectedEvent: EmailNotificationDetail | null = null;
  dropdownFilters: { [key: string]: DropdownOption[] } = {};
  expansionColumns: TableColumn[] = [];
  columns: TableColumn[] = [];

  eventLabels: Record<string, string> = {
    PROCESSED: 'Processado',
    DELIVERED: 'Entregue',
    OPENED: 'Aberto',
    CLICKED: 'Clicado',
    BOUNCED: 'Rejeitado',
    SPAM: 'Spam',
    BLOCKED: 'Bloqueado',
    DROPPED: 'Descartado',
    DEFERRED: 'Adiado',
    UNSUBSCRIBED: 'Descadastrado'
  };
  fixedFilters: PageableFilter[];

  ngOnInit(): void {
    this.initDropdownFilters();
    this.initColumns();
    this.initExpansionColumns();
  }

  private initDropdownFilters(): void {
    this.dropdownFilters['event'] = Object.entries(this.eventLabels)
      .map(([value, label]) => ({
        value,
        label
      }))
      .concat([{ label: 'Todos', value: null }]);
    this.fixedFilters = [
      {
        field: 'sentAt',
        value: [this.filters.startDate, this.filters.endDate],
        fieldType: 'date',
        condition: 'between'
      } as PageableFilter,
      {
        field: 'templateId',
        value: this.filters.templates,
        fieldType: 'number',
        condition: 'in'
      } as PageableFilter
    ].concat(
      this.event.eventName !== 'sent'
        ? [
            {
              field: 'event',
              value: (this.event?.eventName || '').toUpperCase(),
              fieldType: 'text',
              condition: 'equals'
            } as PageableFilter
          ]
        : []
    );
  }

  private initColumns(): void {
    this.columns = [
      new TableColumn.Builder()
        .setHeader('Template')
        .setField('templateName')
        .setCondition('contains')
        .setSortable(true)
        .build(),
      new TableColumn.Builder()
        .setHeader('Email')
        .setField('recipientEmail')
        .setCondition('equals')
        .setSortable(true)
        .build(),
      new TableColumn.Builder()
        .setHeader('Status')
        .setField('event')
        .setCondition('equals')
        .setSortable(true)
        .setDisplayFunction((item: any) => this.formatEventLabel(item.event))
        .setStyleClass('event-status')
        .setFilter(this.showAll)
        .setInnerHTML((item: any) => {
          const eventType = item.event?.toLowerCase();
          return `<span class="event-status-${eventType}">${this.formatEventLabel(
            item.event
          )}</span>`;
        })
        .build(),
      new TableColumn.Builder()
        .setHeader('Assunto')
        .setField('subject')
        .setCondition('contains')
        .setSortable(true)
        .build(),
      new TableColumn.Builder()
        .setHeader('Data de Envio')
        .setField('sentAt')
        .setType('date')
        .setSortable(true)
        .setCondition('between')
        .setFilter(false)
        .build(),
      new TableColumn.Builder()
        .setHeader('Data do Evento')
        .setField('receivedAt')
        .setType('date')
        .setSortable(true)
        .setFilter(false)
        .setCondition('between')
        .build()
    ];
  }

  private initExpansionColumns(): void {
    this.expansionColumns = ['ip', 'linkClicked', 'reason', 'userAgent'].map(
      (field) =>
        new TableColumn.Builder()
          .setHeader(this.formatHeaderLabel(field))
          .setField(field)
          .build()
    );
  }

  public async findPage(
    request: PageableRequest,
    service?: NotificationControllerService
  ): Promise<PageEmailNotificationDetail> {
    return await lastValueFrom(
      service.getEventDetails(request).pipe(
        map((data) => ({
          ...data.result,
          content: data.result.content.map((c) => ({
            ...c,
            detailsData:
              c.ip || c.linkClicked || c.reason || c.userAgent
                ? [
                    {
                      ip: c.ip,
                      linkClicked: c.linkClicked,
                      reason: c.reason,
                      userAgent: c.userAgent
                    }
                  ]
                : []
          }))
        }))
      )
    );
  }

  formatHeaderLabel(field: string): string {
    const labels: Record<string, string> = {
      ip: 'IP',
      linkClicked: 'Link',
      reason: 'Motivo',
      userAgent: 'User Agent'
    };
    return labels[field] || field;
  }

  formatEventLabel(eventType: string): string {
    return this.eventLabels[eventType] || eventType;
  }

  onRowExpand(event: any): void {
    this.selectedEvent = event.$event?.data;
  }

  get showAll() {
    return this.event.eventName === 'sent';
  }

  get eventLabel() {
    return this.eventLabel[this.event.eventName] || 'Enviados';
  }
}

/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Brand {
  brandId?: number;
  brandName?: string;
  summary?: string;
  coverImageUrl?: string;
  carouselImageUrl?: string;
  logoImageUrl?: string;
  coverImageDisplay?: number;
  carouselImageDisplay?: number;
  dateCreated?: Date;
  dateUpdated?: Date;
  descriptionArticleId?: number;
  wmsId?: number;
  brandEquity?: number;
}

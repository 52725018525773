import { Inject, Injectable } from '@angular/core';
import { OrderBlocked, OrderBlockedRepository } from '@infrab4a/connect';
import { PageableFilter, PageableRequest } from 'src/app/admin-api';
import { PageContent } from 'src/app/components/table';
import { BaseConnectService } from './base-connect.service';

@Injectable({
  providedIn: 'root'
})
export class OrderBlockedService extends BaseConnectService<OrderBlocked> {
  constructor(
    @Inject('OrderBlockedRepository')
    protected orderBlockedRepository: OrderBlockedRepository
  ) {
    super(orderBlockedRepository);
  }

  async getOrders(filters: PageableFilter[]) {
    return (
      await this.paginatedSearch({
        filters,
        page: 0,
        pageSize: 9999
      })
    ).data;
  }

  async paginateOrdersBlocked(
    request: Partial<PageableRequest>
  ): Promise<PageContent<Partial<OrderBlocked>>> {
    const result = await this.paginatedSearch(request);
    const content = result.data.map((order) => ({ ...order }));
    const totalPages = request.pageSize
      ? Math.floor(result.count / request.pageSize)
      : undefined;
    const totalElements = result.count;

    return {
      totalPages,
      totalElements,
      content,
      empty: result.count === 0
    };
  }

  override get scheme(): 'firebase' | 'hasura' {
    return 'hasura';
  }
}

export class TableColumn {
  header: string;
  field: string;
  filter: boolean;
  type:
    | 'text'
    | 'number'
    | 'date'
    | 'link'
    | 'button'
    | 'boolean'
    | 'inputSwitch'
    | 'checkbox'
    | 'currency'
    | 'image'
    | 'status'
    | 'formattedInteger'
    | 'formattedNumber'
    | 'percentage'
    | 'inputNumber' = 'text';
  routerLink?: string | ((item: any) => string);
  routerLinkFieldName?: string;
  linkActive?: (value: object) => boolean;
  showMobile: boolean;
  condition:
    | 'equals'
    | 'notEquals'
    | 'contains'
    | 'startsWith'
    | 'gt'
    | 'lt'
    | 'gte'
    | 'between'
    | 'in'
    | 'notIn'
    | 'lte' = 'equals';
  styleClass: string | undefined;
  /**
   * Função que retorna o status
   * <ul>
   * <li> -1 para inativo</li>
   * <li> 0 para pendente</li>
   * <li> 1 para ativo</li>
   * </ul>
   */
  statusFunction?: (value: object) => number;
  canSelect?: (value: object) => boolean;
  selectDisabledTooltip?: string;
  switchTrueLabel = 'Sim';
  switchFalseLabel = 'Não';
  alwaysVisible = false;
  ngStyle: any;
  displayFunction: (item: any) => any;
  sortable = true;
  innerHTML: ((item: any) => string) | string;

  constructor(
    header: string,
    field = '',
    filter = true,
    type:
      | 'text'
      | 'number'
      | 'date'
      | 'link'
      | 'button'
      | 'boolean'
      | 'inputSwitch'
      | 'checkbox'
      | 'currency'
      | 'image'
      | 'status'
      | 'formattedNumber'
      | 'formattedInteger'
      | 'percentage'
      | 'inputNumber' = 'text',
    routerLink?: string | ((item: any) => string),
    routerLinkFieldName?: string,
    showMobile = true,
    condition:
      | 'equals'
      | 'notEquals'
      | 'contains'
      | 'startsWith'
      | 'gt'
      | 'lt'
      | 'gte'
      | 'between'
      | 'in'
      | 'notIn'
      | 'lte' = 'equals',
    styleClass?: string,
    statusFunction?: (value: object) => number,
    canSelect?: (value: object) => boolean,
    selectDisabledTooltip?: string,
    linkActive: (value: object) => boolean = () => true,
    switchTrueLabel?: string,
    switchFalseLabel?: string,
    alwaysVisible = false,
    ngStyle: any = {},
    displayFunction?: (item: any) => any,
    sortable = true,
    innerHTML?: ((item: any) => string) | string
  ) {
    this.header = header;
    this.field = field;
    this.filter = filter;
    this.type = type;
    this.routerLink = routerLink;
    this.routerLinkFieldName = routerLinkFieldName;
    this.showMobile = showMobile;
    this.condition = condition;
    this.styleClass = styleClass;
    this.statusFunction = statusFunction;
    this.canSelect = canSelect;
    this.selectDisabledTooltip = selectDisabledTooltip;
    this.linkActive = linkActive;
    if (switchTrueLabel) this.switchTrueLabel = switchTrueLabel;
    if (switchFalseLabel) this.switchFalseLabel = switchFalseLabel;
    this.alwaysVisible =
      alwaysVisible || this.type === 'button' || this.type === 'checkbox';
    this.ngStyle = ngStyle;
    this.displayFunction = displayFunction;
    this.sortable = sortable;
    this.innerHTML = innerHTML;
  }

  static Builder = class {
    header: string;
    field: string;
    filter: boolean;
    type:
      | 'text'
      | 'number'
      | 'date'
      | 'link'
      | 'button'
      | 'boolean'
      | 'inputSwitch'
      | 'checkbox'
      | 'currency'
      | 'image'
      | 'formattedInteger'
      | 'formattedNumber'
      | 'percentage'
      | 'status'
      | 'inputNumber' = 'text';
    routerLink?: string | ((item: any) => string);
    routerLinkFieldName?: string;
    linkActive?: (value: object) => boolean;
    showMobile: boolean;
    condition:
      | 'equals'
      | 'notEquals'
      | 'contains'
      | 'startsWith'
      | 'gt'
      | 'lt'
      | 'gte'
      | 'between'
      | 'in'
      | 'notIn'
      | 'lte' = 'equals';
    styleClass: string | undefined;
    statusFunction?: (value: object) => number;
    canSelect?: (value: object) => boolean;
    selectDisabledTooltip?: string;
    switchTrueLabel = 'Sim';
    switchFalseLabel = 'Não';
    alwaysVisible = false;
    ngStyle: any;
    displayFunction: (item: any) => any;
    sortable = true;
    innerHTML: ((item: any) => string) | string;

    setHeader(header: string) {
      this.header = header;
      return this;
    }
    setField(field: string) {
      this.field = field;
      return this;
    }
    setFilter(filter: boolean) {
      this.filter = filter;
      return this;
    }
    setType(
      type:
        | 'text'
        | 'number'
        | 'date'
        | 'link'
        | 'button'
        | 'boolean'
        | 'inputSwitch'
        | 'checkbox'
        | 'currency'
        | 'image'
        | 'formattedInteger'
        | 'formattedNumber'
        | 'percentage'
        | 'inputNumber'
        | 'status'
    ) {
      this.type = type;
      return this;
    }
    setRouterLink(routerLink: string | ((item: any) => string)) {
      this.routerLink = routerLink;
      return this;
    }
    setRouterLinkFieldName(routerLinkFieldName: string) {
      this.routerLinkFieldName = routerLinkFieldName;
      return this;
    }
    setLinkActive(linkActive: (value: object) => boolean) {
      this.linkActive = linkActive;
      return this;
    }
    setShowMobile(showMobile: boolean) {
      this.showMobile = showMobile;
      return this;
    }
    setCondition(
      condition:
        | 'equals'
        | 'notEquals'
        | 'contains'
        | 'startsWith'
        | 'gt'
        | 'lt'
        | 'gte'
        | 'between'
        | 'in'
        | 'notIn'
        | 'lte'
    ) {
      this.condition = condition;
      return this;
    }
    setStyleClass(styleClass: string | undefined) {
      this.styleClass = styleClass;
      return this;
    }
    setStatusFunction(statusFunction: (value: object) => number) {
      this.statusFunction = statusFunction;
      return this;
    }
    setCanSelect(canSelect: (value: object) => boolean) {
      this.canSelect = canSelect;
      return this;
    }
    setSelectDisabledTooltip(selectDisabledTooltip: string) {
      this.selectDisabledTooltip = selectDisabledTooltip;
      return this;
    }
    setSwitchTrueLabel(switchTrueLabel = 'Sim') {
      this.switchTrueLabel = switchTrueLabel;
      return this;
    }
    setSwitchFalseLabel(switchFalseLabel = 'Não') {
      this.switchFalseLabel = switchFalseLabel;
      return this;
    }
    setAlwaysVisible(alwaysVisible = false) {
      this.alwaysVisible = alwaysVisible;
      return this;
    }
    setNgStyle(ngStyle: any) {
      this.ngStyle = ngStyle;
      return this;
    }
    setDisplayFunction(displayFunction: (item: any) => any) {
      this.displayFunction = displayFunction;
      return this;
    }
    setSortable(sortable: boolean) {
      this.sortable = sortable;
      return this;
    }
    setInnerHTML(innerHTML: ((item: any) => string) | string) {
      this.innerHTML = innerHTML;
      return this;
    }
    build() {
      const column = new TableColumn(
        this.header,
        this.field,
        this.filter,
        this.type,
        this.routerLink,
        this.routerLinkFieldName,
        this.showMobile,
        this.condition,
        this.styleClass,
        this.statusFunction,
        this.canSelect,
        this.selectDisabledTooltip,
        this.linkActive,
        this.switchTrueLabel,
        this.switchFalseLabel,
        this.alwaysVisible,
        this.ngStyle,
        this.displayFunction,
        this.sortable,
        this.innerHTML
      );
      return column;
    }
  };
}

import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  FeedbackAdminControllerService,
  FeedbackOption,
  FeedbackQuestion,
  FeedbackQuestionGroup,
  FeedbackQuestionUpdateRequest
} from 'src/app/admin-api';
import {
  getAllQuestionTypes,
  getQuestionTypeDescription
} from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ImageUploadComponent } from '../../image-upload/image-upload.component';

@Component({
  selector: 'app-feedback-question-form',
  templateUrl: './feedback-question-form.component.html',
  styleUrl: './feedback-question-form.component.scss',
  providers: [ConfirmationService]
})
export class FeedbackQuestionFormComponent
  extends ImageUploadComponent<FeedbackQuestion>
  implements OnInit
{
  ref: DynamicDialogRef = inject(DynamicDialogRef);
  private feedbackService: FeedbackAdminControllerService = inject(
    FeedbackAdminControllerService
  );
  private config = inject(DynamicDialogConfig);
  private messageService: MessageService = inject(MessageService);
  private sanitizer: DomSanitizer = inject(DomSanitizer);
  private confirmationService: ConfirmationService =
    inject(ConfirmationService);

  group: FeedbackQuestionGroup = this.config.data.question;
  question: FeedbackQuestion = !this.group?.feedbackGroupId
    ? this.config.data.question
    : null;
  options: Array<FeedbackOption> = this.config.data.options;
  formId: number = this.config.data.question?.formId || this.config.data.formId;
  override form = new FormGroup({
    maxAnswers: new FormControl<number>(this.question?.maxAnswers || 1, [
      Validators.required,
      Validators.min(1)
    ]),
    minAnswers: new FormControl<number>(this.question?.minAnswers || 1, [
      Validators.required,
      Validators.min(1)
    ]),
    maxCount: new FormControl<number>(this.question?.maxCount || 4000, [
      Validators.required,
      Validators.min(this.question?.minCount || 1 + 1),
      Validators.max(4000)
    ]),
    minCount: new FormControl<number>(this.question?.minCount || 1, [
      Validators.required,
      Validators.min(1)
    ]),
    questionId: new FormControl<number>(
      {
        disabled: !this.question,
        value: this.question?.questionId
      },
      Validators.required
    ),
    status: new FormControl<number>(this.question ? this.question.status : 1),
    title: new FormControl<string>(this.question?.title, Validators.required),
    horizontalOptions: new FormControl<boolean>({
      disabled: true,
      value: this.question?.horizontalOptions || false
    }),
    mediaUrl: new FormControl<string>({
      value: this.question?.mediaUrl,
      disabled: true
    }),
    showRadioButton: new FormControl<boolean>({
      disabled: true,
      value: this.question ? this.question.showRadioButton : true
    }),
    typeId: new FormControl<number>(
      this.group?.questions?.at(0)?.typeId || this.question?.typeId,
      [Validators.required, Validators.min(1)]
    ),
    formId: new FormControl<number>(this.question?.formId || this.formId, [
      Validators.required,
      Validators.min(1)
    ]),
    groupId: new FormControl<number>(
      {
        value: this.group?.feedbackGroupId,
        disabled: !this.group?.feedbackGroupId
      },
      Validators.required
    ),
    lastQuestion: new FormControl<boolean>({
      value: this.question?.lastQuestion,
      disabled: true
    }),
    isShuffled: new FormControl<boolean>({
      value: this.question?.isShuffled || false,
      disabled: true
    })
  });
  questionTypes: Array<{
    label: string;
    value: number;
    description: string;
    disabled?: boolean;
  }> = getAllQuestionTypes().filter((q) => !q.disabled);
  imagesTypes = ['.jpg', '.gif', '.png', '.jpeg', '.webp'];

  ngOnInit(): void {
    if (this.group?.feedbackGroupId || this.question?.groupId) {
      this.questionTypes = this.questionTypes.filter((qt) =>
        [3, 4].includes(qt.value)
      );
    }
    this.changeType();
  }

  async submit(): Promise<void> {
    if (!this.form.valid) {
      Object.keys(this.form.controls).forEach((key) => {
        this.form.controls[key].markAsDirty();
        this.form.controls[key].markAsTouched();
        this.form.controls[key].updateValueAndValidity();
        return;
      });
    }
    if (!(await this.checkGroupTypeIdChange())) {
      return;
    }
    LoaderService.showLoader();
    try {
      if (this.question) {
        this.question = await lastValueFrom(
          this.feedbackService
            .updateFeedbackQuestion(this.form.getRawValue())
            .pipe(map((data) => data.result))
        );
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'Pergunta atualizada com sucesso'
        });
      } else if (!this.group?.feedbackGroupId) {
        this.question = await lastValueFrom(
          this.feedbackService
            .createFeedbackQuestion(this.form.getRawValue())
            .pipe(map((data) => data.result))
        );
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'Pergunta criada com sucesso'
        });
      } else {
        this.group = await lastValueFrom(
          this.feedbackService
            .addNewQuestionToQuestionGroup(
              this.group.feedbackGroupId,
              this.form.value
            )
            .pipe(map((data) => data.result))
        );
      }
      await this.afterSubmit();
      this.ref.close(this.question || this.group);
    } catch (error) {
      LoaderService.showLoader(false);
      AppDialogService.showErrorDialog(error);
    }
  }

  async checkGroupTypeIdChange(): Promise<boolean> {
    // Se alterou o typeId
    return new Promise<boolean>((resolve) => {
      if (
        this.question?.groupId &&
        this.form.value.showRadioButton !== this.question.showRadioButton
      ) {
        this.confirmationService.confirm({
          acceptLabel: 'Continuar',
          rejectLabel: 'Voltar',
          header: 'Alterar Exibição do radio-button?',
          rejectButtonStyleClass: 'p-button-danger',
          dismissableMask: false,
          message:
            'Ao alterar a exibição dos radio-buttons, todas as perguntas do grupo também serão alteradas.',
          accept: () => {
            resolve(true);
          },
          reject: () => {
            resolve(false);
          }
        });
      } else if (
        this.question?.groupId &&
        this.form.value.lastQuestion !== this.question.lastQuestion
      ) {
        this.confirmationService.confirm({
          acceptLabel: 'Continuar',
          rejectLabel: 'Voltar',
          header: 'Alterar Última pergunta?',
          rejectButtonStyleClass: 'p-button-danger',
          dismissableMask: false,
          message:
            'Ao alterar a Última pergunta?, todas as perguntas do grupo também serão alteradas.',
          accept: () => {
            resolve(true);
          },
          reject: () => {
            resolve(false);
          }
        });
      } else {
        resolve(true);
      }
    });
  }

  changeType(): void {
    if (this.form.value.typeId === 2 || this.form.value.typeId === 5) {
      // this.form.controls['maxAnswers'].disable();
      // this.form.controls['minAnswers'].disable();
      this.form.controls['maxCount'].enable();
      this.form.controls['minCount'].enable();
      // } else if (this.form.value.typeId === 4) {
      // this.form.controls['maxAnswers'].enable();
      // this.form.controls['minAnswers'].enable();
      //   this.form.controls['maxCount'].disable();
      //   this.form.controls['minCount'].disable();
    } else {
      // this.form.controls['maxAnswers'].disable();
      // this.form.controls['minAnswers'].disable();
      this.form.controls['maxCount'].disable();
      this.form.controls['minCount'].disable();
    }
  }

  validateMinAndMax(): void {
    const question = this.form.getRawValue() as FeedbackQuestionUpdateRequest;
    if (question.maxAnswers && question.minAnswers > question.maxAnswers) {
      this.form.controls['maxAnswers'].setErrors({
        min: { min: question.minAnswers }
      });
    } else {
      this.form.controls['maxAnswers'].reset(question.maxAnswers);
      this.form.controls['maxAnswers'].markAsTouched();
      this.form.controls['maxAnswers'].markAsDirty();
    }
    if (question.minCount > question.maxCount) {
      this.form.controls['maxCount'].setErrors({
        min: { min: question.minCount }
      });
    } else {
      this.form.controls['maxCount'].reset(question.maxCount);
      this.form.controls['maxCount'].markAsTouched();
      this.form.controls['maxCount'].markAsDirty();
    }
  }

  public override get filePath(): string {
    return '/admin/feedback/questions/';
  }

  get questionTypeDescription(): string {
    return getQuestionTypeDescription(this.form.value.typeId);
  }

  get mediaType(): 'img' | 'video' {
    return this.imagesTypes.reduce(
      (match, type) =>
        (match = match || (this.form.value.mediaUrl as string).includes(type)),
      false
    )
      ? 'img'
      : 'video';
  }

  get safeMediaUrl(): SafeUrl {
    return this.form.value.mediaUrl
      ? this.sanitizer.bypassSecurityTrustResourceUrl(
          this.form.value.mediaUrl.replace('watch?v=', 'embed/')
        )
      : null;
  }

  public override get model(): FeedbackQuestion {
    return this.question;
  }

  public override get modelId(): number {
    return this.question?.questionId;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { AppTableModule } from '../table/app-table.module';
import { PersonInstagramHistoryComponent } from './person-instagram-history.component';

@NgModule({
  declarations: [PersonInstagramHistoryComponent],
  imports: [
    CommonModule,
    TooltipModule,
    ButtonModule,
    ConfirmPopupModule,
    ToastModule,
    AppTableModule
  ],
  exports: [PersonInstagramHistoryComponent]
})
export class PersonInstagramHistoryModule {}

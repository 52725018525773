<app-table
  [cols]="cols"
  modelName="movimentações"
  sortField="movementDate"
  [sortOrder]="1"
  [service]="movementService"
  [findPage]="findPage"
  [addButton]="false"
  [dropdownFilters]="dropdownFilters"
  [defaultFilterValues]="defaultFilterValues"
  linkTarget="_action"
  (linkAction)="goToReference($event)"
  [actionFields]="actionFields"
  [changeColumns]="true"
></app-table>

import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgxPermissionsService } from 'ngx-permissions';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { EmailTemplateControllerService } from 'src/app/admin-api';
import { EmailTemplateResponse } from 'src/app/admin-api/model/emailTemplateResponse';
import { EmailTemplateFormComponent } from 'src/app/components/email-template-form/email-template-form.component';
import {
  TableActionButton,
  TableColumn,
  TableComponent
} from 'src/app/components/table';
import { Role, roleAsObject } from 'src/app/models';

@Component({
  selector: 'app-email-templates',
  templateUrl: './email-templates.component.html',
  styleUrls: ['./email-templates.component.scss'],
  providers: [MessageService, DialogService]
})
export class EmailTemplatesComponent implements OnInit {
  @ViewChild(TableComponent) tableComponent: TableComponent;

  public emailTemplateService = inject(EmailTemplateControllerService);
  private messageService = inject(MessageService);
  private title = inject(Title);
  private dialogService = inject(DialogService);
  private permissionService = inject(NgxPermissionsService);

  templates: EmailTemplateResponse[] = [];

  actionButtons = [
    new TableActionButton(
      '',
      '',
      'pi pi-pencil',
      () => true,
      'p-button-text',
      'Editar template',
      'bottom'
    )
  ];

  columns: TableColumn[];

  public static roles = [
    roleAsObject(Role.Full_Administrator).enumValue,
    roleAsObject(Role.ItOps).enumValue,
    roleAsObject(Role.TI).enumValue
  ];
  permission = false;

  async ngOnInit() {
    this.title.setTitle('Templates de email');
    this.permission = await this.permissionService.hasPermission(
      EmailTemplatesComponent.roles
    );
    this.columns = [
      new TableColumn.Builder()
        .setHeader('Nome')
        .setField('templateName')
        .setCondition('contains')
        .setSortable(true)
        .build(),
      new TableColumn.Builder()
        .setHeader('Template ID')
        .setField('templateId')
        .setType('number')
        .build(),
      new TableColumn.Builder()
        .setHeader('Assunto')
        .setField('subject')
        .setCondition('contains')
        .setSortable(true)
        .build(),
      new TableColumn.Builder()
        .setHeader('Subtítulo')
        .setField('subtitle')
        .setCondition('contains')
        .setSortable(true)
        .build(),
      new TableColumn.Builder()
        .setHeader('Descrição')
        .setField('description')
        .setCondition('contains')
        .setSortable(true)
        .build(),
      new TableColumn.Builder()
        .setHeader('Status')
        .setField('active')
        .setType('status')
        .setDisplayFunction((item: EmailTemplateResponse) =>
          item.active ? 'Ativo' : 'Inativo'
        )
        .setStatusFunction((item: EmailTemplateResponse) =>
          item.active ? 1 : -1
        )
        .setSortable(true)
        .build()
    ].concat(
      this.permission
        ? [
            new TableColumn.Builder()
              .setHeader('Ações')
              .setField('actions')
              .setType('button')
              .setSortable(false)
              .build()
          ]
        : []
    );
  }

  onEdit(template?: EmailTemplateResponse): void {
    this.dialogService
      .open(EmailTemplateFormComponent, {
        header: template ? 'Editar template' : 'Novo template',
        draggable: false,
        resizable: false,
        closeOnEscape: true,
        modal: true,
        width: '600px',
        data: {
          template,
          permission: this.permission
        }
      })
      .onClose.subscribe((success) => this.onSave(success, template));
  }

  async onSave(success: boolean, template: EmailTemplateResponse) {
    if (success) {
      if (template) {
        this.showSuccessMessage(true);
        await this.tableComponent.refresh();
      } else {
        this.showSuccessMessage(false);
        await this.tableComponent.refresh();
      }
    }
  }

  private showSuccessMessage(isNew: boolean): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Sucesso',
      detail: isNew
        ? 'Template criado com sucesso'
        : 'Template atualizado com sucesso'
    });
  }

  openNew(): void {
    this.onEdit();
  }

  actionButtonEvent(template: EmailTemplateResponse): void {
    this.onEdit(template);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppTableModule } from '../table/app-table.module';
import { TableModalComponent } from './table-modal.component';

@NgModule({
  declarations: [TableModalComponent],
  imports: [CommonModule, AppTableModule],
  exports: [TableModalComponent]
})
export class TableModalModule {}

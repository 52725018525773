import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UserRoleViewResponse } from 'src/app/admin-api';

@Component({
  selector: 'app-user-form-modal',
  templateUrl: './user-form-modal.component.html',
  styleUrls: ['./user-form-modal.component.scss']
})
export class UserFormModalComponent {
  user: (UserRoleViewResponse & { role: string }) | undefined;

  constructor(
    private config: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {
    this.user = this.config.data?.user;
  }

  userCreated(user: UserRoleViewResponse): void {
    this.config.header = 'Permissões de ' + user.displayName;
  }
}

import { Component, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { ItOpsControllerService, RenewalWrongPrice } from 'src/app/admin-api';
import { TableColumn, TableComponent } from 'src/app/components/table';
import { SubscriberProblemGroupEnum } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-renewal-wrong-price',
  templateUrl: './renewal-wrong-price.component.html',
  styleUrls: ['./renewal-wrong-price.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class RenewalWrongPriceComponent {
  @ViewChild(TableComponent) table: TableComponent;

  columns: Array<TableColumn> = [
    new TableColumn('', '', false, 'checkbox'),
    new TableColumn(
      'SubscriberId',
      'subscriberid',
      true,
      'number',
      '/users/subscribers/',
      'subscriberid'
    ),
    new TableColumn(
      'Subscriber Renewal Id',
      'subscriberRenewalId',
      true,
      'number',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Valor do Desconto',
      'discountValue',
      true,
      'currency',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Frete',
      'shippingPrice',
      true,
      'currency',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Frete',
      'shippingPrice',
      true,
      'currency',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Pagando',
      'toPay',
      true,
      'currency',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Deveria Pagar',
      'correctValue',
      true,
      'number',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn(
      'Diferença',
      'difference',
      true,
      'currency',
      undefined,
      undefined,
      true,
      'gte'
    )
  ];

  constructor(
    public itOpsService: ItOpsControllerService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  markAsResolved(elements: Array<RenewalWrongPrice>): void {
    this.confirmationService.confirm({
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      rejectButtonStyleClass: 'p-button-danger',
      message: 'Deseja enviar o relatório?',
      header: 'Corrigir',
      accept: async () => {
        LoaderService.showLoader();
        try {
          await lastValueFrom(
            this.itOpsService.markAsResolved(
              SubscriberProblemGroupEnum.RenewalReprocess,
              elements
            )
          );
          await this.table.refresh(true);
          this.messageService.add({
            summary: 'Sucesso',
            detail: elements.length + ' marcado(s) como resolvido(s)',
            severity: 'success'
          });
          LoaderService.showLoader(false);
        } catch (error) {
          LoaderService.showLoader(false);
          AppDialogService.showErrorDialog(error);
        }
      }
    });
  }
}

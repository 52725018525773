export interface TrackingModel {
  nota: string;
  data_do_evento: string;
  hora_do_evento: string;
  descricao_do_evento: string;
  observacao: string;
  transportador: string;
  nome_transportador: string;
  categoria_do_evento: string;
  url_comprovante: string;
}

export interface TrackingList {
  eventos: TrackingModel[];
}

<h2>Valores fiscais</h2>
<!-- Tabela para Valor das assinaturas -->
<p-table
  *ngIf="subscriptionAndSubscriptionTypes?.length"
  [value]="subscriptionTypesTableData"
  styleClass="custom-table"
  [tableStyle]="{ 'min-width': '880px' }"
  [scrollable]="true"
>
  <!-- Cabeçalho -->
  <ng-template pTemplate="header">
    <tr>
      <th pFrozenColumn>Valores das assinaturas</th>
      <th
        *ngFor="let sub of subscriptions"
        [class.box]="sub.subscriptionId === 1"
        [class.bag]="sub.subscriptionId === 5"
        [class.pass]="sub.subscriptionId === 6"
        [class.combo]="sub.subscriptionId === 7"
      >
        {{ sub.subscriptionName | titlecase }}
      </th>
    </tr>
  </ng-template>

  <!-- Corpo da tabela -->
  <ng-template pTemplate="body" let-row let-index="rowIndex">
    <tr>
      <td pFrozenColumn>{{ row.label }}</td>
      <td *ngFor="let sub of subscriptionAndSubscriptionTypes">
        <ng-container *ngIf="sub.subscriptionTypes?.at(index)">
          R$ {{ getSubscriptionTypePrice(sub, row.label) }}
        </ng-container>
        <ng-container *ngIf="!sub.subscriptionTypes?.at(index)"
          >N/D</ng-container
        >
      </td>
    </tr>
  </ng-template>
</p-table>
<br />
<!-- Tabela para Valor do e-book -->
<p-table
  *ngIf="subscriptions?.length"
  [value]="tableData"
  class="custom-table"
  [tableStyle]="{ 'min-width': '880px' }"
  [scrollable]="true"
  dataKey="value"
>
  <ng-template pTemplate="header">
    <tr>
      <th pFrozenColumn><strong>Valor do e-book</strong></th>
      <th
        *ngFor="let sub of subscriptions"
        colspan="2"
        [class.box]="sub.subscriptionId === 1"
        [class.bag]="sub.subscriptionId === 5"
        [class.pass]="sub.subscriptionId === 6"
        [class.combo]="sub.subscriptionId === 7"
      >
        {{ sub.subscriptionName | titlecase }}
      </th>
    </tr>
  </ng-template>

  <!-- Corpo da tabela para Valor do e-book -->
  <ng-template pTemplate="body" let-row let-index="rowIndex">
    <tr>
      <td pFrozenColumn>{{ row.label }}</td>
      <ng-container *ngFor="let sub of subscriptions">
        <td pEditableColumn>
          <ng-container *ngIf="sub.ebookPrices?.at(index)">
            <p-cellEditor
              *ngxPermissionsOnly="['ROLE_ADMIN', 'ROLE_FINANCIAL']"
            >
              <ng-template pTemplate="input">
                <p-inputNumber
                  [(ngModel)]="sub.ebookPrices.at(index).price"
                  [minFractionDigits]="2"
                  [maxFractionDigits]="2"
                  mode="currency"
                  currency="BRL"
                  locale="pt-BR"
                  [max]="999.99"
                  (onFocus)="previousPrice = sub.ebookPrices.at(index).price"
                  (onBlur)="
                    onPriceChange(
                      sub.ebookPrices.at(index),
                      sub.subscriptionId,
                      'ebook'
                    )
                  "
                  (onKeyDown)="
                    preventKeys(
                      $event,
                      sub.ebookPrices.at(index),
                      sub.subscriptionId,
                      'ebook'
                    )
                  "
                >
                </p-inputNumber>
              </ng-template>
              <ng-template pTemplate="output">
                <ng-container *ngIf="sub.ebookPrices?.at(index)">{{
                  sub.ebookPrices?.at(index)?.price | currency : 'BRL'
                }}</ng-container>
              </ng-template>
            </p-cellEditor>
            <span *ngxPermissionsExcept="['ROLE_ADMIN', 'ROLE_FINANCIAL']">{{
              sub.ebookPrices?.at(index)?.price | currency : 'BRL'
            }}</span>
          </ng-container>
          <ng-container *ngIf="!sub.ebookPrices?.at(index)">N/D</ng-container>
        </td>
        <td *ngIf="sub.ebookPrices?.at(index)?.price > 0">
          {{
            getPercentageValue(sub.ebookPrices.at(index)) | number : '1.0-2'
          }}%
        </td>
        <td
          *ngIf="
            !sub.ebookPrices?.at(index)?.price ||
            sub.ebookPrices?.at(index)?.price <= 0
          "
        ></td>
      </ng-container>
    </tr>
  </ng-template>
</p-table>
<br />

<!-- Tabela para Valor do serviço -->
<p-table
  *ngIf="subscriptions?.length"
  [value]="tableData"
  class="custom-table"
  [tableStyle]="{ 'min-width': '880px' }"
  [scrollable]="true"
>
  <ng-template pTemplate="header">
    <tr>
      <th pFrozenColumn><strong>Valor do serviço</strong></th>
      <th
        *ngFor="let sub of subscriptions"
        colspan="2"
        [class.box]="sub.subscriptionId === 1"
        [class.bag]="sub.subscriptionId === 5"
        [class.pass]="sub.subscriptionId === 6"
        [class.combo]="sub.subscriptionId === 7"
      >
        {{ sub.subscriptionName | titlecase }}
      </th>
    </tr>
  </ng-template>

  <!-- Corpo da tabela para Valor do serviço -->
  <ng-template pTemplate="body" let-row let-index="rowIndex">
    <tr>
      <td pFrozenColumn>{{ row.label }}</td>
      <ng-container *ngFor="let sub of subscriptions">
        <td pEditableColumn>
          <ng-container *ngIf="sub.servicePrices?.at(index)">
            <p-cellEditor
              *ngxPermissionsOnly="['ROLE_ADMIN', 'ROLE_FINANCIAL']"
            >
              <ng-template pTemplate="input">
                <p-inputNumber
                  [(ngModel)]="sub.servicePrices.at(index).price"
                  [minFractionDigits]="2"
                  [maxFractionDigits]="2"
                  mode="currency"
                  currency="BRL"
                  locale="pt-BR"
                  [max]="999.99"
                  (onFocus)="previousPrice = sub.servicePrices.at(index).price"
                  (onBlur)="
                    onPriceChange(
                      sub.servicePrices.at(index),
                      sub.subscriptionId,
                      'ebook'
                    )
                  "
                >
                </p-inputNumber>
              </ng-template>
              <ng-template pTemplate="output">
                <ng-container *ngIf="sub.servicePrices?.at(index)">{{
                  sub.servicePrices?.at(index)?.price | currency : 'BRL'
                }}</ng-container>
              </ng-template>
            </p-cellEditor>
            <span *ngxPermissionsExcept="['ROLE_ADMIN', 'ROLE_FINANCIAL']">{{
              sub.servicePrices?.at(index)?.price | currency : 'BRL'
            }}</span>
          </ng-container>
          <ng-container *ngIf="!sub.servicePrices?.at(index)">N/D</ng-container>
        </td>
        <td *ngIf="sub.servicePrices?.at(index)?.price > 0">
          {{
            getPercentageValue(sub.servicePrices.at(index)) | number : '1.0-2'
          }}%
        </td>
        <td
          *ngIf="
            !sub.servicePrices?.at(index)?.price ||
            sub.servicePrices?.at(index)?.price <= 0
          "
        ></td>
      </ng-container>
    </tr>
  </ng-template>
</p-table>

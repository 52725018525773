/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ShippingTrackingStatusDTO {
  shippingTrackingStausId?: number;
  shippingOrderId?: number;
  shippingServiceId?: number;
  trackingCode?: string;
  trackingStatus?: string;
  trackingStatusPlace?: string;
  trackingStatusDate?: Date;
  notifiedCIO?: boolean;
  trackingStatusOrder?: number;
  shippingServiceName?: string;
  trackingOrderId?: number;
}

import { DialogOptions, TypedError } from '../models';
import { BroadcastUtil } from '../utils/broadcast.util';

export class AppDialogService {
  static showInfoDialog(
    options:
      | DialogOptions
      | { message: string; header: string; visible?: boolean },
    goBack = false
  ): void {
    options.visible = true;
    BroadcastUtil.get('dialog').emit({ options, goBack });
  }

  static showErrorDialog(
    error: TypedError,
    goBack = false,
    genericMessage = 'Erro ao realizar esta operação, entre em contato com o suporte'
  ): void {
    if (error.status === 403 || error.status === 401) {
      return;
    }
    console.error(error);
    this.showInfoDialog(
      {
        message: error?.error?.message || error?.message || genericMessage,
        header: 'Erro',
        visible: true
      },
      goBack
    );
  }
}

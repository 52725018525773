<app-table
  *ngIf="cols && dropdownFilters"
  [cols]="cols"
  [findPage]="findPage"
  [service]="subscriberService"
  modelName="assinaturas"
  sortField="subscriberId"
  [sortOrder]="1"
  [addButton]="false"
  [dropdownFilters]="dropdownFilters"
  [changeColumns]="true"
></app-table>

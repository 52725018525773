import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { lastValueFrom, map } from 'rxjs';
import {
  CustomQuery,
  CustomQueryControllerService,
  CustomQueryGroup,
  UserControllerService,
  UserRoleViewResponse
} from 'src/app/admin-api';
import { CustomQueryService } from 'src/app/services/custom-query.service';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-custom-query-form',
  templateUrl: './custom-query-form.component.html',
  styleUrls: ['./custom-query-form.component.scss'],
  providers: [MessageService]
})
export class CustomQueryFormComponent implements OnInit {
  @Input() queryId: string;

  user: UserRoleViewResponse;
  query: CustomQuery | undefined;
  form: FormGroup = new FormGroup({
    queryName: new FormControl<string>('', Validators.required),
    queryCommand: new FormControl<string>('', [
      Validators.required,
      Validators.maxLength(4000)
    ]),
    username: new FormControl<string>('', Validators.required),
    customQueryGroupId: new FormControl<number>(1, Validators.required),
    isActive: new FormControl<number>(1, Validators.required)
  });
  customQueryGroups: Array<CustomQueryGroup> | undefined;

  constructor(
    private customQueryService: CustomQueryControllerService,
    private userService: UserControllerService,
    private title: Title,
    private messageService: MessageService,
    private customQueryExecuteService: CustomQueryService
  ) {}

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader();
    if (this.queryId) {
      try {
        this.query = await lastValueFrom(
          this.customQueryService
            .findCustomQueryById(Number(this.queryId))
            .pipe(map((data) => data.result))
        );
        this.form.patchValue(this.query);
        this.title.setTitle(this.query.queryName);
      } catch (error) {
        AppDialogService.showErrorDialog(error, true);
      }
    } else {
      this.title.setTitle('Nova consulta personalizada');
    }
    await Promise.all([this.findUser(), this.findGroups()]);
    this.form.controls['username'].setValue(this.user.username);
    LoaderService.showLoader(false);
  }

  async findUser(): Promise<void> {
    try {
      this.user = await lastValueFrom(
        this.userService.getInfo().pipe(map((data) => data.result))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findGroups(): Promise<void> {
    try {
      this.customQueryGroups = await lastValueFrom(
        this.customQueryService
          .findCustomQueryGroups()
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async submit(): Promise<void> {
    LoaderService.showLoader();
    try {
      if (this.query) {
        this.query = await lastValueFrom(
          this.customQueryService
            .updateCustomQuery(this.query.customQueryId, this.form.value)
            .pipe(map((data) => data.result))
        );
        this.messageService.add({
          summary: 'Sucesso',
          severity: 'success',
          detail: 'Query atualizada com sucesso.'
        });
      } else {
        this.query = await lastValueFrom(
          this.customQueryService
            .createCustomQuery(this.form.value)
            .pipe(map((data) => data.result))
        );
        this.messageService.add({
          summary: 'Sucesso',
          severity: 'success',
          detail: 'Query criada com sucesso.'
        });
      }
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  async export(
    action: 'exportCSV' | 'exportExcel' | 'exportHTML' | 'exportXML'
  ): Promise<void> {
    await this.customQueryExecuteService.export(action, this.query);
  }
}

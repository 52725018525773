import {
  Component,
  computed,
  inject,
  Input,
  OnDestroy,
  OnInit,
  signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import { Router } from '@angular/router';
import { Order } from '@infrab4a/connect';
import { lastValueFrom, map } from 'rxjs';
import {
  KpiControllerService,
  ShopContributionResponse,
  ShopIncomeResponse,
  SubscriptionsTotalResponse
} from 'src/app/admin-api';
import { ShopOrderService } from 'src/app/connect-api/api/shop/shop-order.service';
import { ShopSubscriptionService } from 'src/app/connect-api/api/shop/shop-subscription.service';
import { Message, SubscriberUpdate, Topic } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { WebsocketService } from 'src/app/services/websocket.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-consolidated-dashboard',
  templateUrl: './consolidated-dashboard.component.html',
  styleUrl: './consolidated-dashboard.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class ConsolidatedDashboardComponent implements OnInit, OnDestroy {
  @Input()
  glamSubscriptions: WritableSignal<SubscriptionsTotalResponse> =
    signal(undefined);

  @Input()
  show: WritableSignal<boolean>;

  private kpiService = inject(KpiControllerService);
  private shopOrderService = inject(ShopOrderService);
  private subscriptionService = inject(ShopSubscriptionService);
  private websocketService = inject(WebsocketService);

  shopIncome: WritableSignal<ShopIncomeResponse> = signal(undefined);
  ownBrandsIncome: WritableSignal<ShopIncomeResponse> = signal(undefined);
  mensSubscriptions: WritableSignal<number> = signal(undefined);
  subscriptionsTotal = computed(() => {
    if (
      this.mensSubscriptions() === undefined ||
      this.glamSubscriptions() === undefined
    )
      return undefined;
    return {
      total:
        (this.glamSubscriptions ? this.glamSubscriptions()?.total || 0 : 0) +
        (this.mensSubscriptions() || 0),
      createdAt: this.glamSubscriptions
        ? this.glamSubscriptions()?.createdAt
        : null
    } as SubscriptionsTotalResponse;
  });
  contributionResponse: WritableSignal<ShopContributionResponse> =
    signal(undefined);
  panel = signal(!inject(Router).url.includes('/dashboard'));
  interval: NodeJS.Timeout;
  orders: WritableSignal<Array<Order>> = signal(undefined);

  async ngOnInit() {
    if (!this.panel()) this.connect();
    await this.findPage(!this.panel());
    this.startIntervals();
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
      delete this.interval;
    }
    this.websocketService.disconnect();
  }

  private startIntervals() {
    if (!this.interval) {
      this.interval = setInterval(async () => {
        await this.findPage(false);
      }, 60000);
    }
  }

  async findPage(findCount = true) {
    await this.findOrders();
    const promises = [
      this.findShopIncome(),
      this.findOwnBrandsIncome(),
      this.findMensSubscriptions(),
      this.findContributionMargin()
    ];
    if (findCount) {
      if (!this.glamSubscriptions) this.glamSubscriptions = signal(undefined);
      promises.push(this.findSubscriptionsRealtimeCount());
    }
    await Promise.all(promises);
  }

  async findOrders() {
    try {
      this.orders.set(await this.shopOrderService.paidToday());
    } catch (error) {
      console.error(error);
      if (!this.orders()) this.orders.set([]);
    }
  }

  async findShopIncome() {
    try {
      const total = this.orders().reduce(
        (total, o) => (total += o.totalPrice || 0),
        0
      );
      const createdAt = this.orders().at(0)?.createdAt;
      if (
        !this.shopIncome ||
        !this.shopIncome()?.total ||
        this.shopIncome().total < total ||
        !this.shopIncome()?.createdAt ||
        (createdAt &&
          this.shopIncome().createdAt.getTime() < createdAt.getTime())
      )
        this.shopIncome.set({ total, createdAt });
    } catch (error) {
      if (!this.shopIncome())
        this.shopIncome.set({
          total: 0
        });
      console.error(error);
    }
  }

  async findOwnBrandsIncome() {
    try {
      const date = new Date();
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
      const glambeauty = this.orders()
        .filter((o) =>
          o.lineItems?.some((li) =>
            ['glam beauty', 'glambeauty', "men's", 'malmo'].includes(
              li.brand.toLowerCase()
            )
          )
        )
        .map((o) => ({
          ...o,
          lineItems: o.lineItems.filter((li) =>
            ['glam beauty', 'glambeauty', "men's", 'malmo'].includes(
              li.brand.toLowerCase()
            )
          )
        }));
      const total = glambeauty
        .map((o) => ({
          orderNumber: o.orderNumber,
          total: o.lineItems.reduce(
            (sum, li) =>
              (sum +=
                (li.pricePaidWithDiscount || li.pricePaid || 0) *
                (li.quantity || 1)),
            0
          )
        }))
        .reduce((sum, t) => (sum += t.total), 0);
      const createdAt = glambeauty.at(0)?.createdAt;
      if (
        !this.ownBrandsIncome ||
        !this.ownBrandsIncome()?.total ||
        this.ownBrandsIncome().total < total ||
        !this.ownBrandsIncome()?.createdAt ||
        (createdAt &&
          this.ownBrandsIncome().createdAt.getTime() < createdAt.getTime())
      )
        this.ownBrandsIncome.set({ total, createdAt });
    } catch (error) {
      if (!this.ownBrandsIncome())
        this.ownBrandsIncome.set({
          total: 0
        });
      console.error(error);
    }
  }

  async findMensSubscriptions() {
    try {
      const mensSubscriptions =
        (await this.subscriptionService.paidToday())?.length || 0;
      if (
        !this.mensSubscriptions ||
        !this.mensSubscriptions() ||
        this.mensSubscriptions() < mensSubscriptions
      )
        this.mensSubscriptions.set(mensSubscriptions);
    } catch (error) {
      if (!this.mensSubscriptions()) this.mensSubscriptions.set(0);
    }
  }

  async findContributionMargin() {
    try {
      const contributionResponse = await lastValueFrom(
        this.kpiService
          .findContributionMargin()
          .pipe(map((data) => data.result))
      );
      if (
        !this.contributionResponse ||
        !this.contributionResponse()?.contributionMargin ||
        this.contributionResponse().contributionMargin !==
          contributionResponse.contributionMargin ||
        !this.contributionResponse()?.contributionMarginPercentage ||
        this.contributionResponse().contributionMarginPercentage !==
          contributionResponse.contributionMarginPercentage
      )
        this.contributionResponse.set(contributionResponse);
    } catch (error) {
      if (!this.contributionResponse())
        this.contributionResponse.set({
          contributionMargin: 0,
          contributionMarginPercentage: 0
        });
      console.error(error);
    }
  }

  async findSubscriptionsRealtimeCount(): Promise<void> {
    try {
      const total = await lastValueFrom(
        this.kpiService.findAnalyticsDashboardRealtimeTotal(0).pipe(
          map((data) => ({
            ...data.result,
            createdAt: FormUtil.utcDate(data.result.createdAt)
          }))
        )
      );
      if (
        !this.glamSubscriptions()?.total ||
        !this.glamSubscriptions()?.createdAt ||
        total.total > this.glamSubscriptions().total ||
        total.createdAt.getTime() > this.glamSubscriptions().createdAt.getTime()
      )
        this.glamSubscriptions.set(total);
    } catch (error) {
      console.error(error);
    }
  }

  connect() {
    try {
      this.websocketService.connect(this.onMessage.bind(this));
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  onMessage(message: Message<any>): void {
    console.log(message);
    if (message.topic === Topic.SUBSCRIBER_UPDATE) {
      this.addNewSubscriber({
        ...message.body,
        dateAuthorized: FormUtil.utcDate(message.body.dateAuthorized)
      });
    }
  }

  addNewSubscriber(subscriber: SubscriberUpdate) {
    if ([0, 2].includes(subscriber.creditCardPaymentTypeId))
      this.glamSubscriptions.update((subs) => ({
        total: subs.total + 1,
        createdAt: subscriber.dateAuthorized
      }));
  }
}

import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { lastValueFrom, map } from 'rxjs';
import {
  PageableFilter,
  PageableRequest,
  SubscriberCancellationDetail,
  SubscriberControllerService
} from 'src/app/admin-api';
import { PageContent, TableColumn } from 'src/app/components/table';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-subscriber-cancellations',
  templateUrl: './subscriber-cancellations.component.html',
  styleUrls: ['./subscriber-cancellations.component.scss'],
  providers: [DatePipe]
})
export class SubscriberCancellationsComponent implements OnInit {
  referenceForm = new FormGroup({
    referenceDate: new FormControl(
      this.datePipe.transform(new Date(), 'yyyy-MM'),
      Validators.required
    ),
    columns: new FormControl<Array<TableColumn>>([], Validators.minLength(1))
  });
  referenceDates: Array<{ label: string; value: string }>;
  cols: Array<TableColumn> | undefined;
  colsOriginal = [
    new TableColumn(
      'SubId',
      'subscriberId',
      true,
      'number',
      '/users/subscribers/',
      'subscriberId'
    ),
    new TableColumn(
      'Assinante',
      'shippingName',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'GlamId',
      'boxId',
      true,
      'text',
      '/users/subscribers/',
      'subscriberId',
      true
    ),
    new TableColumn(
      'Motivo',
      'subscriberCancellationReasonName',
      true,
      'text',
      undefined,
      undefined,
      false
    ),
    new TableColumn(
      'Justificativa',
      'cancellationReason',
      true,
      'text',
      undefined,
      undefined,
      false,
      'contains'
    ),
    new TableColumn(
      'Responsável',
      'cancelledBy',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Data',
      'dateCancelled',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    )
  ];
  fixedFilters: Array<PageableFilter> = [];
  dropdownFilters:
    | { [key: string]: Array<{ label: string; value: string }> }
    | undefined;
  totalCols = 0;
  constructor(
    public subscriberService: SubscriberControllerService,
    private datePipe: DatePipe,
    private activatedRoute: ActivatedRoute,
    private title: Title,
    private cdRef: ChangeDetectorRef
  ) {
    this.cols = [...this.colsOriginal];
    this.referenceForm.controls['columns'].setValue(this.colsOriginal);
    this.totalCols = this.cols.length;
    this.title.setTitle('Cancelamentos');
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      if (queryParams['editionId']) {
        this.referenceForm.controls['referenceDate'].setValue(
          (
            Number((queryParams['editionId'] as string).substring(1)) / 100
          ).toFixed(0) +
            '-' +
            Number((queryParams['editionId'] as string).substring(5))
              .toFixed(0)
              .padStart(2, '0')
        );
      }
    });
    this.referenceDates = [];
    let date = new Date(this.datePipe.transform(new Date(), 'yyyy-MM-05'));
    while (date.getFullYear() > 2011) {
      this.referenceDates.push({
        label: this.datePipe.transform(
          date,
          'MMMM yyyy',
          undefined,
          'pt-BR'
        ) as string,
        value: this.datePipe.transform(date, 'yyyy-MM') as string
      });
      date = new Date(date.setMonth(date.getMonth() - 1));
    }
    this.setDateFilters();
  }

  async ngOnInit(): Promise<void> {
    await this.findSubscriberCancellationReasons();
  }

  setDateFilters(): void {
    this.fixedFilters = [
      {
        condition: 'between',
        field: 'dateCancelled',
        value: [
          this.datePipe.transform(
            new Date(
              Number(this.referenceForm.value.referenceDate?.split('-')[0]),
              Number(this.referenceForm.value.referenceDate?.split('-')[1]) - 1,
              1
            ),
            'yyyy-MM-dd'
          ) as string,
          this.datePipe.transform(
            new Date(
              Number(this.referenceForm.value.referenceDate?.split('-')[0]),
              Number(this.referenceForm.value.referenceDate?.split('-')[1]),
              1
            ),
            'yyyy-MM-dd'
          ) as string
        ],
        fieldType: 'date'
      }
    ];
  }

  async findPage(
    request: PageableRequest,
    service?: unknown
  ): Promise<PageContent<SubscriberCancellationDetail>> {
    const filterReason = request.filters?.find(
      (f) => f.field === 'subscriberCancellationReasonName'
    );
    if (filterReason?.value) {
      if (Number(filterReason.value) < 0) {
        filterReason.value = '';
      }
      request.filters?.push({
        condition: 'equals',
        field: 'cancellationReasonId',
        fieldType: 'number',
        value: filterReason.value
      });
    }
    return lastValueFrom(
      (service as SubscriberControllerService)
        .findAllSubscriberCancellations(request)
        .pipe(
          map(
            (data) => data.result as PageContent<SubscriberCancellationDetail>
          )
        )
    );
  }

  async findSubscriberCancellationReasons(): Promise<void> {
    try {
      const reasons = await lastValueFrom(
        this.subscriberService
          .getSubscriberCancellationReasons()
          .pipe(map((data) => data.result))
      );
      if (reasons)
        this.dropdownFilters = {
          subscriberCancellationReasonName: [
            { label: 'Todos', value: '-1' }
          ].concat(
            reasons.map((r) => ({
              label: r.subscriberCancellationReasonName as string,
              value: r.subscriberCancellationReasonId?.toString() as string
            }))
          )
        };
    } catch (error: any) {
      delete this.dropdownFilters;
    }
  }

  reloadColumns(): void {
    if (
      this.referenceForm.value.columns?.length &&
      (this.referenceForm.value.columns.length !== this.cols.length ||
        this.referenceForm.value.columns.some((c) =>
          this.cols.every((cd) => cd.field !== c.field)
        ))
    ) {
      LoaderService.showLoader();
      delete this.cols;
      // this.cdRef.detach();
      setTimeout(() => {
        this.cols = this.referenceForm.value.columns;
        // this.cdRef.reattach();
        // this.cdRef.detectChanges();
        LoaderService.showLoader(false);
      }, 1000);
    }
  }

  updateTotalCols() {
    this.totalCols = this.referenceForm.value.columns?.length || 0;
    this.cdRef.detectChanges();
  }

  get columnsLabel(): string {
    return this.referenceForm.value.columns.length === this.colsOriginal.length
      ? 'Todas'
      : `${this.referenceForm.value.columns.length} colunas`;
  }
}

<app-table
  *ngIf="orders"
  [lazy]="false"
  [cols]="visibleColumns"
  [elements]="orders"
  [tableTitle]="'Pedidos' + (shop() ? ' - ' + shopName : '')"
  modelName="pedidos"
  sortField="createdAt"
  [sortOrder]="1"
  [defaultRows]="15"
  [changeColumns]="true"
  [dropdownFilters]="dropdownFilters"
  [addButton]="false"
  [multiSelectFilter]="true"
  [captionTemplate]="captionTemplate"
  colsExportMode="visible"
>
  <ng-template #captionTemplate>
    <form class="header" [formGroup]="referenceForm()" (submit)="reloadTable()">
      <span class="p-float-label">
        <p-calendar
          id="dateIni"
          formControlName="dateIni"
          dateFormat="dd/mm/yy"
          (onSelect)="reloadTable()"
          (onInput)="$event.preventDefault()"
          [readonlyInput]="true"
        >
        </p-calendar>
        <label for="referenceDate">Data início</label>
      </span>
      <span class="p-float-label">
        <p-multiSelect
          emptyMessage="Nenhuma"
          id="columns"
          [options]="validColumns"
          formControlName="columns"
          (onPanelHide)="reloadTable(false)"
          class="input"
          optionLabel="header"
          [maxSelectedLabels]="0"
          (onChange)="updateTotalCols()"
          [selectedItemsLabel]="columnsLabel"
        >
        </p-multiSelect>
        <label for="columns">Colunas para exibição</label>
      </span>
    </form>
  </ng-template>
</app-table>

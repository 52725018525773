<h3 *ngIf="banner">Editar banner: {{ banner.title || banner.altText }}</h3>
<h3 *ngIf="!banner">Novo banner</h3>
<form class="form-b4a" [formGroup]="form" (submit)="submit()">
  <span class="p-float-label">
    <input
      type="text"
      pInputText
      formControlName="title"
      id="title"
      name="title"
    />
    <label for="title">Nome *</label>
  </span>
  <span class="p-float-label">
    <input
      type="text"
      pInputText
      formControlName="altText"
      id="altText"
      name="altText"
    />
    <label for="altText">Alt Text *</label>
  </span>
  <span class="p-float-label">
    <p-dropdown
      [options]="exhibitionRules"
      [autoDisplayFirst]="false"
      formControlName="exhibitionRule"
      inputId="exhibitionRule"
    />
    <label for="exhibitionRule">Regra de exibição *</label>
  </span>
  <span class="p-float-label">
    <input
      type="text"
      pInputText
      formControlName="link"
      id="link"
      name="link"
    />
    <label for="link">Link do Banner</label>
    <small *ngIf="isRouterLink">
      * Recomenda-se iniciar o link com '/' para referências dentro da Glam
    </small>
  </span>
  <span class="p-input-checkbox">
    <p-checkbox
      formControlName="autoPublish"
      [binary]="true"
      inputId="autoPublish"
      (onChange)="checkAutomaticPublish()"
    />
    <label for="autoPublish">Publicar automaticamente?</label>
  </span>
  <span class="p-float-label">
    <p-calendar
      inputId="publishDate"
      dateFormat="dd/mm/yy"
      appendTo="body"
      formControlName="publishDate"
      [showTime]="true"
      [hourFormat]="24"
    />
    <label for="publishDate">Data de publicação</label>
  </span>
  <span class="p-input-checkbox">
    <p-checkbox
      formControlName="autoDelete"
      [binary]="true"
      inputId="autoDelete"
      (onChange)="checkAutomaticPublish()"
    />
    <label for="autoDelete">Excluir automaticamente?</label>
  </span>
  <span class="p-float-label">
    <p-calendar
      inputId="deleteDate"
      dateFormat="dd/mm/yy"
      appendTo="body"
      formControlName="deleteDate"
      [showTime]="true"
      [hourFormat]="24"
    />
    <label for="deleteDate">Data de exclusão</label>
  </span>
  <span
    class="input-switch"
    *ngIf="!form.value.autoDelete && !form.value.autoPublish"
  >
    <label for="published">Inativo</label>
    <p-inputSwitch formControlName="published" inputId="published" />
    <label for="published">Ativo</label>
  </span>
  <span class="image-upload">
    <div class="file-upload">
      <span class="p-float-label">
        <input type="text" readonly pInputText formControlName="desktopImage" />
        <label for="desktopImage">
          Banner Desktop * - (Máx. 250kb / WEBP) Dimensão base:
          {{ desktopWidth | number : '1.0-0' }}x{{
            desktopHeight | number : '1.0-0'
          }}
        </label>
      </span>
      <p-fileUpload
        mode="basic"
        chooseLabel="Selecionar"
        name="file"
        [auto]="true"
        accept=".webp"
        [maxFileSize]="250 * 1024"
        uploadIcon="pi pi-file"
        [customUpload]="true"
        (uploadHandler)="
          onUpload(
            $event,
            'desktopImage',
            desktopUpload,
            undefined,
            true,
            desktopWidth,
            desktopHeight
          )
        "
        id="desktopImage"
        #desktopUpload
      />
    </div>
    <img *ngIf="form.value.desktopImage" [src]="form.value.desktopImage" />
  </span>
  <span class="image-upload">
    <div class="file-upload">
      <span class="p-float-label">
        <input type="text" readonly pInputText formControlName="mobileImage" />
        <label for="mobileImage">
          Banner Mobile * - (Máx. 250kb / WEBP) Dimensão base:
          {{ mobileWidth | number : '1.0-0' }}x{{
            mobileHeight | number : '1.0-0'
          }}
        </label>
      </span>
      <p-fileUpload
        mode="basic"
        chooseLabel="Selecionar"
        name="file"
        [auto]="true"
        accept=".webp"
        [maxFileSize]="200 * 1024"
        uploadIcon="pi pi-file"
        [customUpload]="true"
        (uploadHandler)="
          onUpload(
            $event,
            'mobileImage',
            mobileUpload,
            undefined,
            true,
            mobileWidth,
            mobileHeight
          )
        "
        id="mobileImage"
        #mobileUpload
      />
    </div>
    <img *ngIf="form.value.mobileImage" [src]="form.value.mobileImage" />
  </span>
  <span class="buttons">
    <p-button label="Voltar" severity="danger" routerLink="../../" />
    <p-button label="Salvar" type="submit" [disabled]="!form.valid" />
  </span>
</form>

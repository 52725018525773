export enum TransactionStatusEnum {
  CRIADA = 0,
  EM_ANDAMENTO = 1,
  AUTENTICADA = 2,
  NAO_AUTENTICADA = 3,
  AUTORIZADA = 4,
  NAO_AUTORIZADA = 5,
  CAPTURADA = 6,
  CANCELADA = 9,
  EM_AUTENTICACAO = 10,
  EM_CANCELAMENTO = 12
}

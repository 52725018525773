/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PersonActiveAttributeOptionsGrouped {
  attributeId?: number;
  attributeOptionId?: number;
  attributeAnswers?: number;
  optionAnswers?: number;
}

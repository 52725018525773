/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpResponse
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs';

import { CompositionCheckRequest } from '../model/compositionCheckRequest';
import { CompositionProduct } from '../model/compositionProduct';
import { CompositionProductionCreateRequest } from '../model/compositionProductionCreateRequest';
import { CompositionSubscribersToRemoveRequest } from '../model/compositionSubscribersToRemoveRequest';
import { PageableRequest } from '../model/pageableRequest';
import { ResponseB4AComposition } from '../model/responseB4AComposition';
import { ResponseB4ACompositionProduct } from '../model/responseB4ACompositionProduct';
import { ResponseB4ACompositionProduction } from '../model/responseB4ACompositionProduction';
import { ResponseB4ACompositionProductionSummary } from '../model/responseB4ACompositionProductionSummary';
import { ResponseB4ACompositionSubscriberEditionStatusCounter } from '../model/responseB4ACompositionSubscriberEditionStatusCounter';
import { ResponseB4AListComposition } from '../model/responseB4AListComposition';
import { ResponseB4AListCompositionBeautyProfileCheck } from '../model/responseB4AListCompositionBeautyProfileCheck';
import { ResponseB4AListCompositionDetails } from '../model/responseB4AListCompositionDetails';
import { ResponseB4AListCompositionProductVariant } from '../model/responseB4AListCompositionProductVariant';
import { ResponseB4AListCompositionProductVariantDetail } from '../model/responseB4AListCompositionProductVariantDetail';
import { ResponseB4AListCompositionProduction } from '../model/responseB4AListCompositionProduction';
import { ResponseB4AListCompositionSubscriberEditionStatusCounter } from '../model/responseB4AListCompositionSubscriberEditionStatusCounter';
import { ResponseB4AListCompositionSubstitutionDetail } from '../model/responseB4AListCompositionSubstitutionDetail';
import { ResponseB4AListSubscriberEditionCompositionSummary } from '../model/responseB4AListSubscriberEditionCompositionSummary';
import { ResponseB4APageCompositionDetails } from '../model/responseB4APageCompositionDetails';
import { ResponseB4APageCompositionProductionSummary } from '../model/responseB4APageCompositionProductionSummary';
import { ResponseB4AString } from '../model/responseB4AString';

import { Configuration } from '../configuration';
import { BASE_PATH } from '../variables';

@Injectable()
export class CompositionControllerService {
  protected basePath = 'http://localhost:5000';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createCompositionProduction(
    body?: CompositionProductionCreateRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ACompositionProduction>;
  public createCompositionProduction(
    body?: CompositionProductionCreateRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ACompositionProduction>>;
  public createCompositionProduction(
    body?: CompositionProductionCreateRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ACompositionProduction>>;
  public createCompositionProduction(
    body?: CompositionProductionCreateRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4ACompositionProduction>(
      'post',
      `${this.basePath}/composition/admin/production`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param compositionId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteComposition(
    compositionId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AString>;
  public deleteComposition(
    compositionId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AString>>;
  public deleteComposition(
    compositionId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AString>>;
  public deleteComposition(
    compositionId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (compositionId === null || compositionId === undefined) {
      throw new Error(
        'Required parameter compositionId was null or undefined when calling deleteComposition.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AString>(
      'delete',
      `${this.basePath}/composition/allocation/${encodeURIComponent(
        String(compositionId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param compositionProductionId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteCompositionProduction(
    compositionProductionId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AString>;
  public deleteCompositionProduction(
    compositionProductionId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AString>>;
  public deleteCompositionProduction(
    compositionProductionId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AString>>;
  public deleteCompositionProduction(
    compositionProductionId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (
      compositionProductionId === null ||
      compositionProductionId === undefined
    ) {
      throw new Error(
        'Required parameter compositionProductionId was null or undefined when calling deleteCompositionProduction.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AString>(
      'delete',
      `${this.basePath}/composition/admin/production/${encodeURIComponent(
        String(compositionProductionId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findBeautyProfileCheck(
    body?: CompositionCheckRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListCompositionBeautyProfileCheck>;
  public findBeautyProfileCheck(
    body?: CompositionCheckRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListCompositionBeautyProfileCheck>>;
  public findBeautyProfileCheck(
    body?: CompositionCheckRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListCompositionBeautyProfileCheck>>;
  public findBeautyProfileCheck(
    body?: CompositionCheckRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AListCompositionBeautyProfileCheck>(
      'put',
      `${this.basePath}/composition/allocation/report`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param compositionId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findCompositionById(
    compositionId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AComposition>;
  public findCompositionById(
    compositionId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AComposition>>;
  public findCompositionById(
    compositionId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AComposition>>;
  public findCompositionById(
    compositionId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (compositionId === null || compositionId === undefined) {
      throw new Error(
        'Required parameter compositionId was null or undefined when calling findCompositionById.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AComposition>(
      'get',
      `${this.basePath}/composition/id/${encodeURIComponent(
        String(compositionId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findCompositionDetailsPage(
    body?: PageableRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4APageCompositionDetails>;
  public findCompositionDetailsPage(
    body?: PageableRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4APageCompositionDetails>>;
  public findCompositionDetailsPage(
    body?: PageableRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4APageCompositionDetails>>;
  public findCompositionDetailsPage(
    body?: PageableRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4APageCompositionDetails>(
      'put',
      `${this.basePath}/composition/detail`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findCompositionProductions(
    body?: PageableRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4APageCompositionProductionSummary>;
  public findCompositionProductions(
    body?: PageableRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4APageCompositionProductionSummary>>;
  public findCompositionProductions(
    body?: PageableRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4APageCompositionProductionSummary>>;
  public findCompositionProductions(
    body?: PageableRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4APageCompositionProductionSummary>(
      'put',
      `${this.basePath}/composition/production`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param compositionId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findCompositionProductionsByCompositionId(
    compositionId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListCompositionProduction>;
  public findCompositionProductionsByCompositionId(
    compositionId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListCompositionProduction>>;
  public findCompositionProductionsByCompositionId(
    compositionId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListCompositionProduction>>;
  public findCompositionProductionsByCompositionId(
    compositionId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (compositionId === null || compositionId === undefined) {
      throw new Error(
        'Required parameter compositionId was null or undefined when calling findCompositionProductionsByCompositionId.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListCompositionProduction>(
      'get',
      `${this.basePath}/composition/production/${encodeURIComponent(
        String(compositionId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param compositionId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findCompositionProductionsSummaryByCompositionId(
    compositionId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ACompositionProductionSummary>;
  public findCompositionProductionsSummaryByCompositionId(
    compositionId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ACompositionProductionSummary>>;
  public findCompositionProductionsSummaryByCompositionId(
    compositionId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ACompositionProductionSummary>>;
  public findCompositionProductionsSummaryByCompositionId(
    compositionId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (compositionId === null || compositionId === undefined) {
      throw new Error(
        'Required parameter compositionId was null or undefined when calling findCompositionProductionsSummaryByCompositionId.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4ACompositionProductionSummary>(
      'get',
      `${this.basePath}/composition/production-summary/${encodeURIComponent(
        String(compositionId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param compositionId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findCompositionProducts(
    compositionId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListCompositionProductVariantDetail>;
  public findCompositionProducts(
    compositionId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListCompositionProductVariantDetail>>;
  public findCompositionProducts(
    compositionId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListCompositionProductVariantDetail>>;
  public findCompositionProducts(
    compositionId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (compositionId === null || compositionId === undefined) {
      throw new Error(
        'Required parameter compositionId was null or undefined when calling findCompositionProducts.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListCompositionProductVariantDetail>(
      'get',
      `${this.basePath}/composition/products/${encodeURIComponent(
        String(compositionId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param compositionId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findCompositionSubscribers(
    compositionId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListSubscriberEditionCompositionSummary>;
  public findCompositionSubscribers(
    compositionId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<
    HttpResponse<ResponseB4AListSubscriberEditionCompositionSummary>
  >;
  public findCompositionSubscribers(
    compositionId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListSubscriberEditionCompositionSummary>>;
  public findCompositionSubscribers(
    compositionId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (compositionId === null || compositionId === undefined) {
      throw new Error(
        'Required parameter compositionId was null or undefined when calling findCompositionSubscribers.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListSubscriberEditionCompositionSummary>(
      'get',
      `${this.basePath}/composition/subscribers/${encodeURIComponent(
        String(compositionId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param compositionId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findCompositionSubstitutions(
    compositionId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListCompositionSubstitutionDetail>;
  public findCompositionSubstitutions(
    compositionId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListCompositionSubstitutionDetail>>;
  public findCompositionSubstitutions(
    compositionId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListCompositionSubstitutionDetail>>;
  public findCompositionSubstitutions(
    compositionId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (compositionId === null || compositionId === undefined) {
      throw new Error(
        'Required parameter compositionId was null or undefined when calling findCompositionSubstitutions.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListCompositionSubstitutionDetail>(
      'get',
      `${this.basePath}/composition/substitutions/${encodeURIComponent(
        String(compositionId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findCompositionToExportPage(
    body?: PageableRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4APageCompositionDetails>;
  public findCompositionToExportPage(
    body?: PageableRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4APageCompositionDetails>>;
  public findCompositionToExportPage(
    body?: PageableRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4APageCompositionDetails>>;
  public findCompositionToExportPage(
    body?: PageableRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4APageCompositionDetails>(
      'put',
      `${this.basePath}/composition/detail/not-exported`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param editionId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findCompositionsByEditionId(
    editionId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListComposition>;
  public findCompositionsByEditionId(
    editionId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListComposition>>;
  public findCompositionsByEditionId(
    editionId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListComposition>>;
  public findCompositionsByEditionId(
    editionId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (editionId === null || editionId === undefined) {
      throw new Error(
        'Required parameter editionId was null or undefined when calling findCompositionsByEditionId.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListComposition>(
      'get',
      `${this.basePath}/composition/edition/${encodeURIComponent(
        String(editionId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param productId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findCompositionsByProductId(
    productId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListCompositionProductVariant>;
  public findCompositionsByProductId(
    productId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListCompositionProductVariant>>;
  public findCompositionsByProductId(
    productId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListCompositionProductVariant>>;
  public findCompositionsByProductId(
    productId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (productId === null || productId === undefined) {
      throw new Error(
        'Required parameter productId was null or undefined when calling findCompositionsByProductId.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListCompositionProductVariant>(
      'get',
      `${this.basePath}/composition/product/${encodeURIComponent(
        String(productId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param productVariantId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findCompositionsByProductVariantId(
    productVariantId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListCompositionProductVariant>;
  public findCompositionsByProductVariantId(
    productVariantId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListCompositionProductVariant>>;
  public findCompositionsByProductVariantId(
    productVariantId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListCompositionProductVariant>>;
  public findCompositionsByProductVariantId(
    productVariantId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (productVariantId === null || productVariantId === undefined) {
      throw new Error(
        'Required parameter productVariantId was null or undefined when calling findCompositionsByProductVariantId.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListCompositionProductVariant>(
      'get',
      `${this.basePath}/composition/product-variant/${encodeURIComponent(
        String(productVariantId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param editionId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findCompositionsDashboard(
    editionId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListCompositionSubscriberEditionStatusCounter>;
  public findCompositionsDashboard(
    editionId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<
    HttpResponse<ResponseB4AListCompositionSubscriberEditionStatusCounter>
  >;
  public findCompositionsDashboard(
    editionId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<
    HttpEvent<ResponseB4AListCompositionSubscriberEditionStatusCounter>
  >;
  public findCompositionsDashboard(
    editionId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (editionId === null || editionId === undefined) {
      throw new Error(
        'Required parameter editionId was null or undefined when calling findCompositionsDashboard.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListCompositionSubscriberEditionStatusCounter>(
      'get',
      `${this.basePath}/composition/dashboard/${encodeURIComponent(
        String(editionId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findPendingPreparation(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListCompositionDetails>;
  public findPendingPreparation(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListCompositionDetails>>;
  public findPendingPreparation(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListCompositionDetails>>;
  public findPendingPreparation(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListCompositionDetails>(
      'get',
      `${this.basePath}/composition/pending/preparation`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param subscriberEditionStatusId
   * @param editionId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findSubscribersBySubscriberEditionStatusAndEditionId(
    subscriberEditionStatusId: number,
    editionId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListSubscriberEditionCompositionSummary>;
  public findSubscribersBySubscriberEditionStatusAndEditionId(
    subscriberEditionStatusId: number,
    editionId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<
    HttpResponse<ResponseB4AListSubscriberEditionCompositionSummary>
  >;
  public findSubscribersBySubscriberEditionStatusAndEditionId(
    subscriberEditionStatusId: number,
    editionId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListSubscriberEditionCompositionSummary>>;
  public findSubscribersBySubscriberEditionStatusAndEditionId(
    subscriberEditionStatusId: number,
    editionId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (
      subscriberEditionStatusId === null ||
      subscriberEditionStatusId === undefined
    ) {
      throw new Error(
        'Required parameter subscriberEditionStatusId was null or undefined when calling findSubscribersBySubscriberEditionStatusAndEditionId.'
      );
    }

    if (editionId === null || editionId === undefined) {
      throw new Error(
        'Required parameter editionId was null or undefined when calling findSubscribersBySubscriberEditionStatusAndEditionId.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListSubscriberEditionCompositionSummary>(
      'get',
      `${this.basePath}/composition/subscribers/${encodeURIComponent(
        String(subscriberEditionStatusId)
      )}/${encodeURIComponent(String(editionId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param compositionId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public prepareComposition(
    compositionId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ACompositionSubscriberEditionStatusCounter>;
  public prepareComposition(
    compositionId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<
    HttpResponse<ResponseB4ACompositionSubscriberEditionStatusCounter>
  >;
  public prepareComposition(
    compositionId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<
    HttpEvent<ResponseB4ACompositionSubscriberEditionStatusCounter>
  >;
  public prepareComposition(
    compositionId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (compositionId === null || compositionId === undefined) {
      throw new Error(
        'Required parameter compositionId was null or undefined when calling prepareComposition.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4ACompositionSubscriberEditionStatusCounter>(
      'put',
      `${this.basePath}/composition/admin/prepare/${encodeURIComponent(
        String(compositionId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public removeCompositionChecks(
    body?: CompositionSubscribersToRemoveRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AString>;
  public removeCompositionChecks(
    body?: CompositionSubscribersToRemoveRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AString>>;
  public removeCompositionChecks(
    body?: CompositionSubscribersToRemoveRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AString>>;
  public removeCompositionChecks(
    body?: CompositionSubscribersToRemoveRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AString>(
      'put',
      `${this.basePath}/composition/allocation/remove`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param compositionId
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateCompositionName(
    compositionId: number,
    body?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AComposition>;
  public updateCompositionName(
    compositionId: number,
    body?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AComposition>>;
  public updateCompositionName(
    compositionId: number,
    body?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AComposition>>;
  public updateCompositionName(
    compositionId: number,
    body?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (compositionId === null || compositionId === undefined) {
      throw new Error(
        'Required parameter compositionId was null or undefined when calling updateCompositionName.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AComposition>(
      'put',
      `${this.basePath}/composition/id/${encodeURIComponent(
        String(compositionId)
      )}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateCompositionProduct(
    body?: CompositionProduct,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ACompositionProduct>;
  public updateCompositionProduct(
    body?: CompositionProduct,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ACompositionProduct>>;
  public updateCompositionProduct(
    body?: CompositionProduct,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ACompositionProduct>>;
  public updateCompositionProduct(
    body?: CompositionProduct,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4ACompositionProduct>(
      'put',
      `${this.basePath}/composition/product`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}

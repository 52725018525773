import { Component, Input, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ShopProductService } from 'src/app/connect-api/api/shop/shop-product.service';
import { VariantDTO } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-shop-product-variant-form',
  templateUrl: './shop-product-variant-form.component.html',
  styleUrl: './shop-product-variant-form.component.scss'
})
export class ShopProductVariantFormComponent implements OnInit {
  @Input()
  variant: Partial<VariantDTO>;

  private productService = inject(ShopProductService);
  private messageService = inject(MessageService);

  form = new FormGroup({
    grade: new FormGroup({
      title: new FormControl<string>({ value: null, disabled: true }),
      value: new FormControl<string>({ value: null, disabled: true })
    }),
    sku: new FormControl<string>(null, [Validators.required]),
    EAN: new FormControl<string>(null, [Validators.required]),
    weight: new FormControl<number>(0, [Validators.required]),
    costPrice: new FormControl<number>({ value: 0, disabled: true }, [
      Validators.required
    ]),
    stock: new FormGroup({
      quantity: new FormControl<number>(0, [Validators.required])
    }),
    price: new FormGroup({
      price: new FormControl<number>(0, [
        Validators.required,
        Validators.min(0.01)
      ]),
      fullPrice: new FormControl<number>(0, [
        Validators.required,
        Validators.min(0.01)
      ]),
      subscriberDiscountPercentage: new FormControl<number>(
        0,
        Validators.required
      ),
      fullPriceDiscountPercentage: new FormControl<number>(
        { value: 0, disabled: true },
        Validators.required
      ),
      subscriberPrice: new FormControl<number>(
        { value: 0, disabled: true },
        Validators.required
      )
    }),
    group: new FormControl<number>(null, [Validators.required]),
    validity: new FormControl<boolean>(null, [Validators.required]),
    published: new FormControl<boolean>(false)
  });

  groups = [
    { name: 'Grupo 1', value: 1 },
    { name: 'Grupo 2', value: 2 },
    { name: 'Grupo 3', value: 3 },
    { name: 'Grupo 4', value: 4 },
    { name: 'Grupo 5', value: 5 },
    { name: 'Grupo 6', value: 6 },
    { name: 'Grupo 7', value: 7 },
    { name: 'Grupo 8 (manual)', value: 8 }
  ];
  validities = [
    { name: 'Sim', value: true },
    { name: 'Não', value: false }
  ];

  ngOnInit(): void {
    console.log(this.variant);
    this.form.patchValue(this.variant);
    this.recalculatePrices();
  }

  recalculatePrices(): void {
    this.form.controls['price'].controls['subscriberPrice'].setValue(
      (1 - this.form.value.price.subscriberDiscountPercentage / 100) *
        (this.form.value.price.price || 0)
    );
    this.form.controls['price'].controls[
      'fullPriceDiscountPercentage'
    ].setValue(
      (100 *
        ((this.form.value.price.fullPrice || 0) -
          (this.form.getRawValue().price.subscriberPrice || 0))) /
        (this.form.value.price.fullPrice || 1)
    );
  }

  async submit(): Promise<void> {
    if (this.form.valid) {
      try {
        LoaderService.showLoader();
        const variant = FormUtil.getTouchedAndDirtyOnly(this.form);
        if (Object.keys(variant).length) {
          this.variant = await this.productService.updateVariant(
            variant,
            this.variant.id
          );
          this.messageService.add({
            severity: 'success',
            summary: 'Sucesso',
            detail: 'Variante atualizada com sucesso.'
          });
        }
      } catch (error) {
        AppDialogService.showErrorDialog(error);
      } finally {
        LoaderService.showLoader(false);
      }
    }
  }
}

<div
  class="consolidated-dashboard-container"
  [class.consolidated-dashboard-container--open]="!panel() || (show && show())"
  [class.consolidated-dashboard-container--panel]="panel()"
  [class.consolidated-dashboard-container--regular]="!panel()"
>
  <div
    [class.consolidated-dashboard--hidden]="show !== undefined && !show()"
    [class.consolidated-dashboard--fade]="show !== undefined && show()"
    [class.consolidated-dashboard--panel]="panel()"
    class="consolidated-dashboard"
  >
    <div class="consolidated-card consolidated-card--header">
      <img class="logo" src="assets/images/Logo_B4A_White.png" alt="B4A" />
      <span class="title">Consolidado de Lojas e Assinaturas - AO VIVO</span>
    </div>
    <div class="totals">
      <div class="consolidated-card">
        <span class="header">Total Assinaturas glam + Clube Men's</span>
        <span class="value">
          <ng-container *ngIf="subscriptionsTotal && subscriptionsTotal()">
            {{ subscriptionsTotal()?.total || 0 | number : '1.0-0' }}
          </ng-container>
          <i
            *ngIf="!subscriptionsTotal || subscriptionsTotal() === undefined"
            class="pi pi-spinner pi-spin"
          >
          </i>
        </span>
      </div>
      <div class="consolidated-card">
        <span class="header">Receita Lojas glam + Men's MKT</span>
        <span class="value" appValueChange>
          <ng-container *ngIf="shopIncome()">
            {{ shopIncome().total || 0 | currency : 'BRL' }}
          </ng-container>
          <i class="pi pi-spinner pi-spin" *ngIf="!shopIncome()"></i>
        </span>
      </div>
      <div class="consolidated-card">
        <span class="header">Receita Marcas Próprias</span>
        <span class="value">
          <ng-container *ngIf="ownBrandsIncome()">
            {{ ownBrandsIncome().total || 0 | currency : 'BRL' }}
          </ng-container>
          <i class="pi pi-spinner pi-spin" *ngIf="!ownBrandsIncome()"></i>
        </span>
      </div>
      <div class="consolidated-card">
        <span class="header">Margem de Contribuição Lojas</span>
        <span class="value">
          <ng-container *ngIf="contributionResponse()">
            {{
              contributionResponse().contributionMargin || 0 | currency : 'BRL'
            }}
            <br />
            ({{
              contributionResponse().contributionMarginPercentage || 0
                | number : '1.0-2'
            }}
            %)
          </ng-container>
          <i class="pi pi-spinner pi-spin" *ngIf="!contributionResponse()"></i>
        </span>
      </div>
    </div>
    <div class="totals">
      <div class="consolidated-card">
        <span class="header">Horário Última Assinatura​</span>
        <span class="value-date">
          <ng-container
            *ngIf="subscriptionsTotal && subscriptionsTotal()?.createdAt"
          >
            {{ subscriptionsTotal()?.createdAt | date : 'dd/MM/yyyy HH' }}h{{
              subscriptionsTotal()?.createdAt | date : 'mm'
            }}
          </ng-container>
          <i
            *ngIf="!subscriptionsTotal()?.createdAt"
            class="pi pi-spinner pi-spin"
          >
          </i>
        </span>
      </div>
      <div class="consolidated-card">
        <span class="header">Horário Última Venda​</span>
        <span class="value-date">
          <ng-container *ngIf="shopIncome()?.createdAt">
            {{ shopIncome()?.createdAt | date : 'dd/MM/yyyy HH' }}h{{
              shopIncome()?.createdAt | date : 'mm'
            }}
          </ng-container>
          <i *ngIf="!shopIncome()?.createdAt" class="pi pi-spinner pi-spin"></i>
        </span>
      </div>
      <div class="consolidated-card">
        <span class="header"
          >Horário Última Venda <br />
          (Marcas Próprias​)</span
        >
        <span class="value-date">
          <ng-container *ngIf="ownBrandsIncome()?.createdAt">
            {{ ownBrandsIncome()?.createdAt | date : 'dd/MM/yyyy HH' }}h{{
              ownBrandsIncome()?.createdAt | date : 'mm'
            }}
          </ng-container>
          <i
            *ngIf="!ownBrandsIncome()?.createdAt"
            class="pi pi-spinner pi-spin"
          ></i>
        </span>
      </div>
    </div>
  </div>
</div>

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RequestOptions } from '../models';
import { TokenStorageService } from '../services/auth/token-storage.service';

@Injectable()
export class GlamHttpClient {
  constructor(private http: HttpClient) {}

  public get<T>(endPoint: string, options: RequestOptions = {}): Observable<T> {
    options.headers = this.generateHeaders();
    return this.http.get<T>(environment.apiUrl + endPoint, options);
  }

  public post<T>(
    endPoint: string,
    params: object,
    options: RequestOptions = {}
  ): Observable<T> {
    options.headers = this.generateHeaders();
    return this.http.post<T>(environment.apiUrl + endPoint, params, options);
  }

  public put<T>(
    endPoint: string,
    params: object,
    options: RequestOptions = {}
  ): Observable<T> {
    options.headers = this.generateHeaders();
    return this.http.put<T>(environment.apiUrl + endPoint, params, options);
  }

  public delete<T>(
    endPoint: string,
    options: RequestOptions = {}
  ): Observable<T> {
    options.headers = this.generateHeaders();
    return this.http.delete<T>(environment.apiUrl + endPoint, options);
  }

  private generateHeaders(): HttpHeaders {
    if (TokenStorageService.userLogged) {
      return new HttpHeaders({
        Authorization: TokenStorageService.token
      });
    }
    return new HttpHeaders();
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppTableModule } from '../table/app-table.module';
import { PersonSocialMediasComponent } from './person-social-medias.component';

@NgModule({
  declarations: [PersonSocialMediasComponent],
  imports: [CommonModule, AppTableModule],
  exports: [PersonSocialMediasComponent]
})
export class PersonSocialMediasModule {}

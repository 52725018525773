import { Component } from '@angular/core';
import { SubscriptionControllerService } from 'src/app/admin-api';
import { TableColumn } from 'src/app/components/table';

@Component({
  selector: 'app-editions',
  templateUrl: './editions.component.html',
  styleUrls: ['./editions.component.scss']
})
export class EditionsComponent {
  columns: Array<TableColumn> = [
    new TableColumn(
      'Tema',
      'editionTheme',
      true,
      'text',
      '',
      'editionDate',
      true,
      'contains'
    ),
    new TableColumn(
      'Data de vigência',
      'editionDate',
      true,
      'number',
      undefined,
      undefined,
      true,
      'between'
    )
  ];

  constructor(public subscriberService: SubscriptionControllerService) {}
}

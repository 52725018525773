import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
  inject
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import {
  ConfirmationService,
  MessageService,
  SelectItemGroup
} from 'primeng/api';
import { CheckboxChangeEvent } from 'primeng/checkbox';
import { DialogService } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  Coupon,
  CouponControllerService,
  CouponPriceChange,
  CouponProductChangeDetail,
  CouponProductVariantGroupRestriction,
  CouponProductVariantSubscriptionTypeRestriction,
  CouponProductVariantSummary,
  ProductVariantSummary,
  SubscriptionType
} from 'src/app/admin-api';
import { CouponProductVariantFormComponent } from 'src/app/components/coupon-form/coupon-product-variant-form/coupon-product-variant-form.component';
import { ProductVariantSelectModalComponent } from 'src/app/components/product-select-modal/product-variant-select-modal/product-variant-select-modal.component';
import { getSubscriptionName } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-coupon-product-variants',
  templateUrl: './coupon-product-variants.component.html',
  styleUrl: './coupon-product-variants.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class CouponProductVariantsComponent implements OnInit, OnChanges {
  @Input({ required: true }) coupon: Coupon & {
    subscriptionTypeIds?: number[];
  };
  @Input() couponPriceChange: CouponPriceChange | undefined;
  @Input() schedule = false;
  @Input() productVariants:
    | Array<CouponProductVariantSummary | CouponProductChangeDetail>
    | undefined;
  @Input() subscriptionTypes: Array<SubscriptionType> | undefined;
  @Input() couponProductVariantSubscriptionTypes:
    | Array<CouponProductVariantSubscriptionTypeRestriction>
    | undefined;
  @Input() subscriptionTypesTree: SelectItemGroup[];
  groupRestrictions: CouponProductVariantGroupRestriction[];

  private dialog = inject(DialogService);
  private couponService = inject(CouponControllerService);
  private messageService = inject(MessageService);
  private confirmationService = inject(ConfirmationService);
  groups: Array<{
    groupVariantId: number;
    variants: Array<CouponProductVariantSummary>;
  }> = [];
  subscriptionTypesForm = new FormGroup({
    subscriptionTypes: new FormArray<FormControl<number[]>>([])
  });
  restrictionForm = new FormGroup({
    restrictAllGroups: new FormControl(true),
    subscriptionTypeIds: new FormControl<number[]>([])
  });

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader();
    if (!this.productVariants)
      if (!this.schedule || !this.couponPriceChange)
        await this.findProductVariants();
      else if (this.couponPriceChange) {
        this.productVariants = this.couponPriceChange.productVariants || [];
      }
    await this.startPage();
    LoaderService.showLoader(false);
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (
      this.schedule &&
      changes['subscriptionTypesTree'] &&
      changes['subscriptionTypesTree'].previousValue &&
      (
        changes['subscriptionTypesTree'].previousValue as SelectItemGroup[]
      ).some((ps, idx) =>
        (changes['subscriptionTypesTree'].currentValue as SelectItemGroup[])
          .at(idx)
          ?.items.every((item, i) => ps.items?.at(i)?.value === item.value)
      )
    ) {
      this.restrictionForm?.controls?.subscriptionTypeIds?.setValue(
        this.restrictionForm?.value?.subscriptionTypeIds?.filter((st) =>
          (
            changes['subscriptionTypesTree'].currentValue as SelectItemGroup[]
          ).some((s) => s.items.some((i) => i.value === st))
        ) || []
      );
      let types = [
        ...(this.subscriptionTypesForm?.value.subscriptionTypes || [])
      ];
      types = types.reduce((list: number[][], sts, i) => {
        list[i] = sts.filter((st) =>
          (
            changes['subscriptionTypesTree'].currentValue as SelectItemGroup[]
          ).some((s) => s.items.some((i) => i.value === st))
        );
        return list;
      }, []);

      this.subscriptionTypesForm.controls.subscriptionTypes.setValue(
        types || []
      );
      this.messageService.add({
        severity: 'info',
        summary: 'Atenção',
        detail: 'As restrições do(s) brinde(s) também serão afetadas'
      });
    }
  }

  configureTree() {
    if (this.subscriptionTypes?.length && !this.subscriptionTypesTree) {
      this.subscriptionTypesTree = (
        (this.couponPriceChange
          ? this.couponPriceChange?.subscriptionTypes?.map((cst) =>
              this.subscriptionTypes.find((st) => st.subscriptionTypeId === cst)
            )
          : this.coupon?.subscriptionTypeIds?.length
          ? this.coupon?.subscriptionTypeIds?.map((cst) =>
              this.subscriptionTypes.find((st) => st.subscriptionTypeId === cst)
            )
          : null) || this.subscriptionTypes
      ).reduce((list: SelectItemGroup[], st) => {
        const exists = list.find((n) => n.value === st.subscriptionId);
        if (!exists) {
          list.push({
            value: st.subscriptionId,
            label: getSubscriptionName(st.subscriptionId),
            items: [
              {
                value: st.subscriptionTypeId,
                label: st.name,
                title: `${getSubscriptionName(st.subscriptionId)} ${st.name}`
              }
            ]
          });
        } else {
          exists.items.push({
            value: st.subscriptionTypeId,
            label: st.name,
            title: `${getSubscriptionName(st.subscriptionId)} ${st.name}`
          });
        }
        return list;
      }, []);
    }
    this.subscriptionTypesForm = new FormGroup({
      subscriptionTypes: new FormArray([])
    });
    this.groups?.forEach(() =>
      this.subscriptionTypesForm.controls.subscriptionTypes.push(
        new FormControl(
          this.couponProductVariantSubscriptionTypes?.map(
            (st) => st.restrictedSubscriptionTypeId
          ) || []
        )
      )
    );
  }

  subscriptionLabel(subscriptionTypeId: number) {
    const subType = this.subscriptionTypes?.find(
      (st) => st.subscriptionTypeId === subscriptionTypeId
    );
    return subType
      ? `${getSubscriptionName(subType.subscriptionId)} ${subType.name}`
      : 'Não encontrado';
  }

  async updateGroupRestrictions(loader = true) {
    try {
      if (loader) LoaderService.showLoader();
      this.restrictions = await lastValueFrom(
        this.couponService
          .updateProductVariantGroupRestrictions(
            this.coupon.couponId,
            this.subscriptionTypesForm.value.subscriptionTypes?.reduce(
              (list: CouponProductVariantGroupRestriction[], ids, i) =>
                (list = list.concat(
                  ids.map((id) => ({
                    couponId: this.coupon.couponId,
                    groupVariant: i + 1,
                    subscriptionTypeId: id
                  }))
                )),
              []
            )
          )
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      if (loader) {
        LoaderService.showLoader(false);
        this.messageService.add({
          severity: 'success',
          detail: 'Restrições de brinde atualizadas.',
          summary: 'Sucesso'
        });
      }
    }
  }

  async findGroupRestrictions() {
    try {
      this.restrictions = await lastValueFrom(
        this.couponService
          .findProductVariantGroupRestrictions(this.coupon.couponId)
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async saveRestrictions() {
    try {
      LoaderService.showLoader();
      this.couponProductVariantSubscriptionTypes = await lastValueFrom(
        this.couponService
          .updateProductVariantRestrictions(
            this.coupon.couponId,
            this.restrictionForm.value.subscriptionTypeIds
          )
          .pipe(map((data) => data.result))
      );
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso',
        detail: 'Restrições atualizadas!'
      });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  updateRestrictionSettings(event: CheckboxChangeEvent) {
    this.confirmationService.confirm({
      acceptLabel: 'Sim',
      acceptButtonStyleClass: 'p-button-primary',
      rejectLabel: 'Voltar',
      rejectButtonStyleClass: 'p-button-danger',
      message: (event.checked
        ? 'A restrições dos grupos serão redefinidas'
        : 'As restrições de plano dos brindes serão redefinidas'
      ).concat('. Deseja continuar?'),
      header: 'Alterar restrições',
      accept: async () => {
        LoaderService.showLoader();
        if (!event.checked) {
          for (
            let index = 0;
            index < this.subscriptionTypesForm.value.subscriptionTypes.length;
            index++
          ) {
            this.subscriptionTypesForm.controls.subscriptionTypes
              .at(index)
              .setValue(this.restrictionForm.value.subscriptionTypeIds || []);
          }
          this.restrictionForm.controls.subscriptionTypeIds.reset([]);
          if (!this.schedule)
            await Promise.all([
              this.saveRestrictions(),
              this.updateGroupRestrictions(false)
            ]);
          LoaderService.showLoader(false);
        } else {
          const ids =
            this.subscriptionTypesForm.value.subscriptionTypes?.reduce(
              (list: number[], ids) =>
                (list = FormUtil.onlyUniques(list.concat(ids)) as number[]),
              []
            ) || [];
          this.restrictionForm.controls.subscriptionTypeIds.setValue(ids);
          this.subscriptionTypesForm.controls.subscriptionTypes.reset(
            this.groups?.map(() => []) || []
          );
          if (!this.schedule) await this.saveRestrictions();
          LoaderService.showLoader(false);
        }
      },
      reject: () => {
        this.restrictionForm.controls.restrictAllGroups.setValue(
          !event.checked
        );
      }
    });
  }

  async startPage(): Promise<void> {
    this.groups = [];
    this.productVariants?.forEach((pv) => {
      const group = this.groups.find(
        (g) => g.groupVariantId === pv.groupVariant
      );
      if (group) {
        group.variants.push(pv);
      } else {
        this.groups.push({
          groupVariantId: pv.groupVariant as number,
          variants: [pv]
        });
      }
    });
    this.groups.sort((g1, g2) => g1.groupVariantId - g2.groupVariantId);
    this.configureTree();
    this.restrictionForm.setValue({
      subscriptionTypeIds:
        this.couponProductVariantSubscriptionTypes?.map(
          (st) => st.restrictedSubscriptionTypeId
        ) || [],
      restrictAllGroups: true
    });
    await this.findGroupRestrictions();
  }

  addProductVariant(groupVariant?: number): void {
    if (this.schedule) {
      this.chooseProductVariant(groupVariant);
    } else {
      this.addNewProduct(groupVariant);
    }
  }

  chooseProductVariant(groupVariant?: number): void {
    this.dialog
      .open(ProductVariantSelectModalComponent, {
        header: 'Nova variante',
        dismissableMask: undefined,
        width: '60%',
        focusOnShow: false
      })
      .onClose.subscribe(async (result: ProductVariantSummary) => {
        if (result) {
          LoaderService.showLoader();
          this.productVariants.push({
            couponId: this.coupon.couponId,
            couponPriceChangeId: this.couponPriceChange?.couponPriceChangeId,
            couponProductVariantType: groupVariant !== undefined ? 0 : 1,
            productVariantName: result.productVariantName,
            groupVariant:
              groupVariant !== undefined
                ? groupVariant
                : (this.groups[this.groups.length - 1]?.groupVariantId || 0) +
                  1,
            productVariantId: result.productVariantId,
            quantity: 1,
            brandName: result.brandName,
            defaultImageURL: result.defaultImageURL,
            inStockReserveMarketing: result.inStockReserveMarketing,
            internalEAN: result.internalEAN,
            ratingAverage: result.ratingAverage,
            reviewCount: result.reviewCount
          } as CouponProductChangeDetail);
          this.startPage();
          LoaderService.showLoader(false);
        }
      });
  }

  addNewProduct(groupVariant?: number): void {
    this.dialog
      .open(CouponProductVariantFormComponent, {
        header: 'Nova variante',
        dismissableMask: undefined,
        data: {
          groupVariant,
          coupon: this.coupon,
          lastGroupVariant:
            this.groups[this.groups.length - 1]?.groupVariantId || 0
        },
        width: '60%'
      })
      .onClose.subscribe(async (result) => {
        if (result) {
          LoaderService.showLoader();
          await this.findProductVariants();
          this.startPage();
          this.messageService.add({
            severity: 'success',
            detail: 'Produto adicionado com sucesso',
            summary: 'Sucesso'
          });
          LoaderService.showLoader(false);
        }
      });
  }

  async findProductVariants(): Promise<void> {
    delete this.productVariants;
    try {
      this.productVariants = await lastValueFrom(
        this.couponService
          .findCouponProductVariants(this.coupon?.couponId as number)
          .pipe(
            map((data) => {
              if (this.schedule) {
                return data.result;
              }
              return data.result;
            })
          )
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
  }

  removeProductVariant(
    $event: any,
    product: CouponProductVariantSummary | CouponProductChangeDetail,
    groupVariant: number
  ): void {
    if (this.schedule) {
      this.removeProductFromList(product, groupVariant);
    } else {
      this.removeProductConfirmation($event, product);
    }
  }

  removeProductFromList(
    product: CouponProductVariantSummary | CouponProductChangeDetail,
    groupVariant: number
  ): void {
    this.productVariants = this.productVariants.filter(
      (p) =>
        p.productVariantId !== product.productVariantId ||
        p.groupVariant !== groupVariant
    );
    this.startPage();
  }

  removeProductConfirmation(
    $event: any,
    product: CouponProductVariantSummary
  ): void {
    this.confirmationService.confirm({
      acceptLabel: 'Sim',
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: 'Não',
      rejectButtonStyleClass: 'Não',
      message: `Deseja remover o o produto ${product.productVariantName} do cupom?`,
      target: $event.target,
      accept: async () => {
        await this.removeProduct(product);
      }
    });
  }

  async removeProduct(product: CouponProductVariantSummary): Promise<void> {
    LoaderService.showLoader();
    try {
      const detail = await lastValueFrom(
        this.couponService
          .removeProductVariantFromCoupon(product.id as number)
          .pipe(map((data) => data.result))
      );
      await this.findProductVariants();

      this.startPage();
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso',
        detail
      });
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  async updateQuantity(
    $event: number,
    product: CouponProductVariantSummary
  ): Promise<void> {
    try {
      product.quantity = $event;
      if (!this.schedule)
        await lastValueFrom(
          this.couponService
            .updateCouponProductVariant(product)
            .pipe(map((data) => data.result))
        );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
  }

  get outOfStock(): boolean {
    return (
      this.groups?.some((g) =>
        g.variants.every(
          (pv) => !pv.inStockReserveMarketing || pv.inStockReserveMarketing <= 0
        )
      ) || false
    );
  }

  dirtyForm() {
    return (
      !this.schedule &&
      this.restrictionForm.controls.subscriptionTypeIds.touched &&
      this.restrictionForm.controls.subscriptionTypeIds.dirty &&
      (this.restrictionForm.value.subscriptionTypeIds?.some((st) =>
        this.couponProductVariantSubscriptionTypes?.every(
          (cst) => cst.restrictedSubscriptionTypeId !== st
        )
      ) ||
        this.couponProductVariantSubscriptionTypes?.some((cst) =>
          this.restrictionForm?.value.subscriptionTypeIds?.includes(
            cst.restrictedSubscriptionTypeId
          )
        ) ||
        this.restrictionForm.value.subscriptionTypeIds.length !==
          this.couponProductVariantSubscriptionTypes?.length)
    );
  }

  groupRestrictionsDirty(i: number) {
    return (
      !this.schedule &&
      this.subscriptionTypesForm.controls.subscriptionTypes.controls[i]
        .touched &&
      this.subscriptionTypesForm.controls.subscriptionTypes.controls[i].dirty &&
      (this.subscriptionTypesForm.value.subscriptionTypes[i].some((st) =>
        this.groupRestrictions
          .filter((gr) => gr.groupVariant === i + 1)
          .every((gr) => gr.subscriptionTypeId !== st)
      ) ||
        this.groupRestrictions
          .filter((gr) => gr.groupVariant === i + 1)
          .some(
            (gr) =>
              !this.subscriptionTypesForm.value.subscriptionTypes[i].includes(
                gr.subscriptionTypeId
              )
          ))
    );
  }

  set restrictions(list: CouponProductVariantGroupRestriction[]) {
    this.groupRestrictions = list;
    if (!this.schedule) {
      for (
        let index = 0;
        index < this.subscriptionTypesForm.controls.subscriptionTypes.length;
        index++
      ) {
        this.subscriptionTypesForm.controls.subscriptionTypes.controls[
          index
        ].setValue(
          this.groupRestrictions
            .filter((r) => r.groupVariant === index + 1)
            .map((r) => r.subscriptionTypeId)
        );
      }
      this.restrictionForm.controls.restrictAllGroups.setValue(
        this.restrictionForm.value.subscriptionTypeIds?.length > 0
      );
    } else if (this.groupRestrictions?.length) {
      this.restrictionForm.controls.subscriptionTypeIds.setValue(
        FormUtil.onlyUniques(
          this.groupRestrictions.map((r) => r.subscriptionTypeId)
        )
      );
    }
  }
}

import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { lastValueFrom, map } from 'rxjs';
import {
  ProductControllerService,
  ProductVariantTotals,
  ProductVariantTotalsByDate
} from 'src/app/admin-api';
import { TableColumn } from 'src/app/components/table';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-stock-movements',
  templateUrl: './stock-movements.component.html',
  styleUrl: './stock-movements.component.scss',
  providers: [DatePipe]
})
export class StockMovementsComponent implements OnInit {
  private productService = inject(ProductControllerService);
  private datePipe = inject(DatePipe);
  private title = inject(Title);
  private cdRef = inject(ChangeDetectorRef);

  referenceForm = new FormGroup({
    period: new FormControl(
      [new Date(new Date().setDate(new Date().getDate() - 30)), new Date()],
      [Validators.required, Validators.minLength(2)]
    ),
    columns: new FormControl<Array<TableColumn>>([], Validators.minLength(1))
  });
  products: Array<ProductVariantTotals>;
  totals: Array<ProductVariantTotalsByDate>;
  rows: Array<ProductVariantTotals & ProductVariantTotalsByDate>;
  today = new Date();
  totalCols = 0;
  cols: Array<TableColumn> | undefined;
  colsOriginal = [
    // new TableColumn.Builder()
    //   .setHeader('')
    //   .setField('')
    //   .setType('checkbox')
    //   .build(),
    new TableColumn.Builder()
      .setHeader('SKU Admin')
      .setField('productVariantId')
      .setRouterLink('/products/catalog/product-variant/')
      .setRouterLinkFieldName('productVariantId')
      .setType('number')
      .build(),
    new TableColumn.Builder()
      .setHeader('Nome')
      .setField('productVariantName')
      .setRouterLink('/products/catalog/product-variant/')
      .setRouterLinkFieldName('productVariantId')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('EAN')
      .setField('internalEAN')
      .setRouterLink('/products/catalog/product-variant/')
      .setRouterLinkFieldName('productVariantId')
      .build(),
    new TableColumn.Builder()
      .setHeader('Millennium SKU')
      .setField('externalId')
      .setRouterLink('/products/catalog/product-variant/')
      .setRouterLinkFieldName('productVariantId')
      .setType('number')
      .build(),
    new TableColumn.Builder()
      .setHeader('Estoque aquisição')
      .setField('inStockAcquisition')
      .setType('formattedInteger')
      .setCondition('gte')
      .build(),
    new TableColumn.Builder()
      .setHeader('Estoque fidelização')
      .setField('inStockFidelization')
      .setType('formattedInteger')
      .setCondition('gte')
      .build(),
    new TableColumn.Builder()
      .setHeader('Estoque Res. Marketing')
      .setField('inStockReserveMarketing')
      .setType('formattedInteger')
      .setCondition('gte')
      .build(),
    new TableColumn.Builder()
      .setHeader('Estoque influencer')
      .setField('inStockInfluencerB4A')
      .setType('formattedInteger')
      .setCondition('gte')
      .build(),
    new TableColumn.Builder()
      .setHeader('Total composições')
      .setField('totalCompositions')
      .setType('formattedInteger')
      .setCondition('gte')
      .build(),
    new TableColumn.Builder()
      .setHeader('Total reenvios')
      .setField('totalResend')
      .setType('formattedInteger')
      .setCondition('gte')
      .build(),
    new TableColumn.Builder()
      .setHeader('Total brindes')
      .setField('totalGift')
      .setType('formattedInteger')
      .setCondition('gte')
      .build(),
    new TableColumn.Builder()
      .setHeader('Total trocas')
      .setField('totalTrade')
      .setType('formattedInteger')
      .setCondition('gte')
      .build(),
    new TableColumn.Builder()
      .setHeader('Total incentivos')
      .setField('totalIncentive')
      .setType('formattedInteger')
      .setCondition('gte')
      .build()
  ];
  productsSelected: Array<ProductVariantTotals>;

  async ngOnInit(): Promise<void> {
    this.title.setTitle('Estoque e saídas de produtos');
    this.cols = [...this.colsOriginal];
    this.totalCols = this.cols.length;
    this.referenceForm.controls['columns'].setValue(this.colsOriginal);
    await this.findTable();
  }

  async findTable(): Promise<void> {
    LoaderService.showLoader();
    await Promise.all([
      this.findProductsVariantTotalsInPeriod(),
      this.findProductsVariantsInPeriod()
    ]);
    this.createTableRows();
    LoaderService.showLoader(false);
  }

  async findProductsVariantsInPeriod(): Promise<void> {
    try {
      this.products = await lastValueFrom(
        this.productService
          .getProductVariantsUseInPeriod(
            this.datePipe.transform(
              this.referenceForm.value.period[0],
              'yyyy-MM-dd'
            ),
            this.datePipe.transform(
              this.referenceForm.value.period[1],
              'yyyy-MM-dd'
            )
          )
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findProductsVariantTotalsInPeriod(): Promise<void> {
    try {
      this.totals = await lastValueFrom(
        this.productService
          .getStockTotalsByDate(
            this.datePipe.transform(
              this.referenceForm.value.period[0],
              'yyyy-MM-dd'
            ),
            this.datePipe.transform(
              this.referenceForm.value.period[1],
              'yyyy-MM-dd'
            )
          )
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  createTableRows(): void {
    delete this.rows;
    this.rows = this.products.map((p) => this.productToRow(p));
  }

  productToRow(
    product: ProductVariantTotals
  ): ProductVariantTotals & ProductVariantTotalsByDate {
    return {
      ...product,
      totalCompositions: this.productTotal(
        product.productVariantId,
        'totalCompositions'
      ),
      totalGift: this.productTotal(product.productVariantId, 'totalGift'),
      totalIncentive: this.productTotal(
        product.productVariantId,
        'totalIncentive'
      ),
      totalResend: this.productTotal(
        product.productVariantId,
        'totalIncentive'
      ),
      totalTrade: this.productTotal(product.productVariantId, 'totalTrade')
    };
  }

  productTotal(
    productVariantId: number,
    totalName:
      | 'totalCompositions'
      | 'totalResend'
      | 'totalTrade'
      | 'totalGift'
      | 'totalIncentive'
  ) {
    return this.totals
      .filter((t) => t.productVariantId === productVariantId)
      .reduce((sum, t) => (sum += t[totalName]), 0);
  }

  reloadColumns(): void {
    if (
      this.referenceForm.value.columns?.length &&
      (this.referenceForm.value.columns.length !== this.cols.length ||
        this.referenceForm.value.columns.some((c) =>
          this.cols.every((cd) => cd.field !== c.field)
        ))
    ) {
      LoaderService.showLoader();
      delete this.cols;
      setTimeout(() => {
        this.cols = this.referenceForm.value.columns;
        LoaderService.showLoader(false);
      }, 1000);
    }
  }

  updateTotalCols() {
    this.totalCols = this.referenceForm.value.columns?.length || 0;
    this.cdRef.detectChanges();
  }

  showReport(products: Array<ProductVariantTotals>) {
    this.productsSelected = products;
  }

  async periodChange(): Promise<void> {
    if (
      this.referenceForm.controls['period'].valid &&
      this.referenceForm.value.period.filter((d) => d).length === 2
    ) {
      await this.findTable();
    }
  }

  get columnsLabel(): string {
    return this.referenceForm.value.columns.length === this.colsOriginal.length
      ? 'Todas'
      : `${this.referenceForm.value.columns.length} colunas`;
  }
}

import { inject, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Role } from './models';
import { ContentModule } from './pages/content/content.module';
import { CustomizationModule } from './pages/customization/customization.module';
import { DashboardsModule } from './pages/dashboards/dashboards.module';
import { HomeModule } from './pages/home/home.module';
import { IntegrationModule } from './pages/integration/integration.module';
import { ItOpsModule } from './pages/it-ops/it-ops.module';
import { LoginModule } from './pages/login/login.module';
import { LogoutModule } from './pages/logout/logout.module';
import { MarketingModule } from './pages/marketing/marketing.module';
import { OperationsModule } from './pages/operations/operations.module';
import { ProductsModule } from './pages/products/products.module';
import { ResearchModule } from './pages/research/research.module';
import { SettingsModule } from './pages/settings/settings.module';
import { ShopOrdersModule } from './pages/shop-orders/shop-orders.module';
import { ShopProductsModule } from './pages/shop-products/shop-products.module';
import { SubscriptionSettingsModule } from './pages/subscription-settings/subscription-settings.module';
import { UnauthorizedModule } from './pages/unauthorized/unauthorized.module';
import { UsersModule } from './pages/users/users.module';
import { AuthGuard } from './services/auth/auth-guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard'
  },
  {
    path: 'login',
    loadChildren: () => LoginModule
  },
  {
    path: 'users',
    loadChildren: () => UsersModule,
    canActivate: [() => inject(AuthGuard).canActivate()]
  },
  {
    path: 'products',
    loadChildren: () => ProductsModule,
    canActivate: [() => inject(AuthGuard).canActivate()]
  },
  {
    path: 'shop-products',
    loadChildren: () => ShopProductsModule,
    canActivate: [() => inject(AuthGuard).canActivate()]
  },
  {
    path: 'shop-orders',
    loadChildren: () => ShopOrdersModule,
    canActivate: [() => inject(AuthGuard).canActivate()]
  },
  {
    path: 'marketing',
    loadChildren: () => MarketingModule,
    canActivate: [() => inject(AuthGuard).canActivate()]
  },
  {
    path: 'settings',
    loadChildren: () => SettingsModule,
    canActivate: [() => inject(AuthGuard).canActivate()]
  },
  {
    path: 'it-ops',
    loadChildren: () => ItOpsModule,
    canActivate: [
      () =>
        inject(AuthGuard).canActivate() &&
        inject(AuthGuard).canActivatePermissions([
          Role.Full_Administrator,
          Role.ItOps,
          Role.TI
        ])
    ]
  },
  {
    path: 'integrations',
    loadChildren: () => IntegrationModule,
    canActivate: [
      () =>
        inject(AuthGuard).canActivate() &&
        inject(AuthGuard).canActivatePermissions([
          Role.Full_Administrator,
          Role.ItOps,
          Role.TI,
          Role.Delivery
        ])
    ]
  },
  {
    path: 'dashboard',
    loadChildren: () => DashboardsModule,
    canActivate: [() => inject(AuthGuard).canActivate()]
  },
  {
    path: 'logout',
    loadChildren: () => LogoutModule
  },
  {
    path: 'configuracoes',
    loadChildren: () => CustomizationModule,
    canActivate: [() => inject(AuthGuard).canActivate()]
  },
  {
    path: 'operations',
    loadChildren: () => OperationsModule,
    canActivate: [() => inject(AuthGuard).canActivate()]
  },
  {
    path: 'research',
    loadChildren: () => ResearchModule,
    canActivate: [() => inject(AuthGuard).canActivate()]
  },
  {
    path: 'content',
    loadChildren: () => ContentModule,
    canActivate: [() => inject(AuthGuard).canActivate()]
  },
  {
    path: 'subscription-settings',
    loadChildren: () => SubscriptionSettingsModule,
    canActivate: [() => inject(AuthGuard).canActivate()]
  },
  {
    path: 'unauthorized',
    loadChildren: () => UnauthorizedModule,
    canActivate: [() => inject(AuthGuard).canActivate()]
  },
  {
    path: 'home',
    loadChildren: () => HomeModule,
    canActivate: [() => inject(AuthGuard).canActivate()]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { bindToComponentInputs: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { ShopProductDescriptionFormComponent } from './shop-product-description-form/shop-product-description-form.component';
import { ShopProductDescriptionReviewComponent } from './shop-product-description-review/shop-product-description-review.component';
import { ShopProductFormComponent } from './shop-product-form.component';
import { ShopProductVariantFormComponent } from './shop-product-variant-form/shop-product-variant-form.component';

@NgModule({
  declarations: [
    ShopProductFormComponent,
    ShopProductVariantFormComponent,
    ShopProductDescriptionFormComponent,
    ShopProductDescriptionReviewComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextModule,
    EditorModule,
    CheckboxModule,
    DropdownModule,
    FieldsetModule,
    ButtonModule,
    ToastModule,
    InputNumberModule,
    AccordionModule,
    ChipsModule,
    AutoCompleteModule
  ],
  exports: [
    ShopProductFormComponent,
    ShopProductVariantFormComponent,
    ShopProductDescriptionFormComponent,
    ShopProductDescriptionReviewComponent
  ]
})
export class ShopProductFormModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ToastModule } from 'primeng/toast';
import { PersonCardModule } from '../person-card/person-card.module';
import { ShopCouponFormComponent } from './shop-coupon-form.component';

@NgModule({
  declarations: [ShopCouponFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextModule,
    EditorModule,
    CheckboxModule,
    AutoCompleteModule,
    DropdownModule,
    FieldsetModule,
    ConfirmPopupModule,
    CalendarModule,
    InputTextareaModule,
    InputMaskModule,
    InputNumberModule,
    ChipsModule,
    ToastModule,
    PersonCardModule
  ],
  exports: [ShopCouponFormComponent]
})
export class ShopCouponFormModule {}

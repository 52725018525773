<p-tabView>
  <p-tabPanel header="Informações">
    <form class="form-b4a" [formGroup]="form" (submit)="submit()">
      <span class="p-float-label">
        <input
          type="text"
          pInputText
          formControlName="name"
          id="name"
          name="name"
        />
        <label for="name">Nome</label>
      </span>
      <span class="p-float-label full-w" *ngIf="token">
        <p-multiSelect
          [options]="roleOptions"
          [formControl]="roles"
          inputId="roles"
          name="roles"
        />
        <label for="roles">Permissões</label>
      </span>
      <span class="buttons">
        <p-button
          type="submit"
          label="Salvar"
          [disabled]="!form.valid || !roles.valid"
        />
      </span>
      <span
        class="p-float-label"
        pTooltip="Clique para copiar"
        tooltipPosition="bottom"
        *ngIf="token"
      >
        <textarea
          id="token"
          rows="6"
          cols="80"
          pInputTextarea
          formControlName="token"
          name="token"
          (click)="copyToClipboard(token.token)"
          required
          readonly
          style="resize: none"
        ></textarea>
        <label for="token"> Token </label>
      </span>
    </form>
  </p-tabPanel>
  <p-tabPanel header="Domínios" *ngIf="token && tokenDomains">
    <form class="form-b4a">
      <span class="p-float-label">
        <input
          type="text"
          pInputText
          [formControl]="domain"
          id="domains"
          name="domains"
        />
        <label for="domains">Domínio</label>
        <small
          class="error"
          *ngIf="domain.touched && domain.dirty && !domain.valid"
        >
          * Informe um endereço válido
        </small>
      </span>
      <p-button
        label="Adicionar"
        (onClick)="addDomain()"
        [disabled]="!domain.valid"
      />
    </form>
    <br />
    <app-table
      [lazy]="false"
      [elements]="tokenDomains"
      [cols]="cols"
      modelName="domínios"
      tableTitle="Domínios autorizados"
      [addButton]="false"
      [actionButtons]="actionButtons"
      (actionButtonClick)="deleteDomain($event)"
    />
  </p-tabPanel>
</p-tabView>
<p-toast />

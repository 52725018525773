/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface NotificationTrend {
    id?: number;
    referenceDate?: Date;
    sent?: number;
    delivered?: number;
    opened?: number;
    clicked?: number;
    bounced?: number;
    blocked?: number;
    unsubscribed?: number;
    spam?: number;
    processed?: number;
    dropped?: number;
    deferred?: number;
}

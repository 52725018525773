<form class="modal-form" [formGroup]="form" (submit)="submit()">
  <span class="p-float-label">
    <p-dropdown
      [options]="[
        { label: 'Outro formulário', value: 0 },
        { label: 'Arquivo XLSX', value: 1 }
      ]"
      formControlName="importType"
      id="importType"
      name="importType"
      inputId="importType"
      (onChange)="importTypeChange()"
    ></p-dropdown>
    <label for="importType">Fonte *</label>
  </span>
  <ng-container *ngIf="!form.value.importType">
    <span class="p-float-label">
      <p-autoComplete
        [suggestions]="forms"
        [lazy]="true"
        formControlName="feedbackForm"
        (completeMethod)="findFeedbackForms($event.query)"
        inputId="feedbackForm"
        name="feedbackForm"
        (onSelect)="formChange()"
        placeholder="Pesquise pelo nome"
        filterBy="formTitle"
        optionLabel="formTitle"
        appendTo="body"
        (onClear)="clearForm()"
        [showClear]="true"
      >
        <ng-template let-feedback pTemplate="item">
          {{ feedback.formTitle }}
        </ng-template>
      </p-autoComplete>
      <label for="feedbackForm">Formulário</label>
      <small
        class="danger"
        *ngIf="
          form.controls['feedbackForm'].touched &&
          form.controls['feedbackForm'].dirty &&
          form.controls['feedbackForm'].errors &&
          form.controls['feedbackForm'].errors['empty']
        "
      >
        O formulário está vazio
      </small>
    </span>
    <span class="p-float-label">
      <p-dropdown
        [options]="[]"
        formControlName="questions"
        inputId="questions"
        *ngIf="!questionItems[form.value.feedbackFormId]?.length"
      ></p-dropdown>
      <p-treeSelect
        *ngIf="questionItems[form.value.feedbackFormId]?.length"
        formControlName="questions"
        [options]="questionItems[form.value.feedbackFormId]"
        name="questions"
        inputId="questions"
        selectionMode="checkbox"
        (onNodeSelect)="questionChange($event)"
        (onNodeUnselect)="questionChange($event)"
        [filter]="true"
        appendTo="body"
      >
      </p-treeSelect>
      <label for="questions">Perguntas</label>
    </span>
    <span class="p-input-checkbox">
      <p-checkbox
        formControlName="copyOptions"
        [binary]="true"
        inputId="copyOptions"
      ></p-checkbox>
      <label for="copyOptions">Copiar alternativas?</label>
      <small
        >* Clonar também todas as alternativas das perguntas selecionadas</small
      >
    </span>
  </ng-container>
  <div class="file-upload" *ngIf="form.value.importType">
    <span class="p-float-label">
      <input type="text" pInputText formControlName="fileName" />
      <label for="fileName">Arquivo * (.xslx)</label>
    </span>
    <p-fileUpload
      mode="basic"
      chooseLabel="Selecionar"
      name="file"
      [auto]="true"
      accept=".xlsx"
      [maxFileSize]="1000000"
      uploadIcon="pi pi-file"
      [customUpload]="true"
      (uploadHandler)="setFile($event, fileUpload)"
      id="questions"
      #fileUpload
      [disabled]="form.controls['file'].disabled"
    ></p-fileUpload>
    <a
      class="p-button p-button-secondary"
      href="assets/documents/FeedbackForm Modelo V2.xlsx'"
      download="assets/documents/FeedbackForm Modelo V2.xlsx"
    >
      <i class="pi pi-download"></i>
      Baixar modelo
    </a>
  </div>
  <div class="buttons">
    <p-button
      label="Cancelar"
      severity="danger"
      type="button"
      (onClick)="ref.close()"
      *ngIf="!config.closable"
    ></p-button>
    <p-button
      label="Clonar"
      [disabled]="!form.valid"
      severity="primary"
      type="submit"
    ></p-button>
  </div>
</form>

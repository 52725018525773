export enum ArticleTypeEnum {
  'Artigo' = 0,
  'Marca' = 2,
  'Produto' = 4,
  'Conteúdo do site' = 8,
  'Email template' = 16,
  'Tutorial' = 32
}

export function getAllArticlesType(): Array<{ label: string; value: number }> {
  const objetos: Array<{ label: string; value: number }> = [];
  for (const [p, propertyValue] of Object.entries(ArticleTypeEnum)) {
    try {
      if (Number(propertyValue) >= 0) {
        objetos.push({
          label: p,
          value: Number(propertyValue)
        });
      }
    } catch (error: any) {
      objetos.length;
    }
  }
  return objetos;
}

export function getAllArticlesTypeIncludeAll(): Array<{
  label: string;
  value: number;
}> {
  const objetos: Array<{ label: string; value: number }> = [];
  for (const [p, propertyValue] of Object.entries(ArticleTypeEnum)) {
    try {
      if (Number(propertyValue) >= 0) {
        objetos.push({
          label: p,
          value: Number(propertyValue)
        });
      }
    } catch (error: any) {
      objetos.length;
    }
  }
  return objetos.concat([
    {
      label: 'Todas',
      value: 0
    }
  ]);
}

export function getArticleTypeName(id: number): string | undefined {
  return getAllArticlesType().find((e) => e.value === id)?.label;
}

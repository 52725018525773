import { Clipboard } from '@angular/cdk/clipboard';
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  WritableSignal,
  inject,
  signal
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Category, Filter, FilterOption, Product } from '@infrab4a/connect';
import { ConfirmationService, MessageService, PrimeIcons } from 'primeng/api';
import { PageableFilter } from 'src/app/admin-api';
import {
  DropdownFilter,
  TableActionButton,
  TableColumn,
  TableComponent
} from 'src/app/components/table';
import { ShopCategoryFilterService } from 'src/app/connect-api/api/shop/shop-category-filter.service';
import { ShopProductService } from 'src/app/connect-api/api/shop/shop-product.service';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { slugify } from 'src/app/utils/slug.util';

@Component({
  selector: 'app-category-filter-detail',
  templateUrl: './category-filter-detail.component.html',
  styleUrl: './category-filter-detail.component.scss'
})
export class CategoryFilterDetailComponent implements OnInit {
  @Input()
  filterId: string | undefined;

  @ViewChild(TableComponent) table: TableComponent;

  protected form = new FormGroup({
    title: new FormControl('', [Validators.required]),
    description: new FormControl(
      { value: null, disabled: true },
      Validators.required
    ),
    slug: new FormControl({ value: null, disabled: true }, Validators.required),
    enabled: new FormControl(true)
  });
  protected newTagControl = new FormControl('', [
    Validators.required,
    Validators.minLength(1)
  ]);
  protected cols = [
    new TableColumn('Opção', 'description', false, 'text'),
    new TableColumn('Tag', 'id', false, 'text'),
    new TableColumn('Ação', '', false, 'button')
  ];
  protected actionButtons: Array<TableActionButton> = [
    new TableActionButton(
      '',
      'delete',
      PrimeIcons.TRASH,
      undefined,
      '',
      'Excluir',
      'bottom',
      true,
      true,
      'danger'
    )
  ];
  filter: WritableSignal<Filter> = signal(undefined);
  categoriesCols = [
    new TableColumn(
      'Id',
      'id',
      true,
      'text',
      '/shop-products/categories/category/',
      'id',
      true,
      'contains'
    ),
    new TableColumn(
      'Nome',
      'name',
      true,
      'text',
      '/shop-products/categories/category/',
      'id',
      true,
      'contains'
    ),
    new TableColumn('Slug', 'slug', true, 'text', undefined, undefined)
  ];
  productCols = [
    new TableColumn.Builder()
      .setHeader('Nome')
      .setField('name')
      .setRouterLink('/shop-products/catalog/product/')
      .setRouterLinkFieldName('id')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('EAN')
      .setField('EAN')
      .setRouterLink('/shop-products/catalog/product/')
      .setRouterLinkFieldName('id')
      .build(),
    new TableColumn.Builder()
      .setHeader('SKU')
      .setField('sku')
      .setRouterLink('/shop-products/catalog/product/')
      .setRouterLinkFieldName('id')
      .build(),
    new TableColumn.Builder()
      .setHeader('Tipo')
      .setField('type')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('Marca')
      .setField('brand')
      .setCondition('contains')
      .setRouterLink('/shop-products/categories/category/')
      .setRouterLinkFieldName('category_id')
      .build(),
    new TableColumn.Builder()
      .setHeader('Possui variantes?')
      .setField('hasVariants')
      .setType('boolean')
      .build(),
    new TableColumn.Builder()
      .setHeader('Quantidade')
      .setField('stock')
      .setDisplayFunction(
        (product: Partial<Product>) => product.stock?.quantity || 0
      )
      .setType('formattedInteger')
      .setCondition('gte')
      .build(),
    new TableColumn.Builder()
      .setHeader('Ativo')
      .setField('published')
      .setType('boolean')
      .build(),
    new TableColumn.Builder()
      .setHeader('Tags')
      .setField('tags')
      .setType('text')
      .setSortable(false)
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('Filtros')
      .setField('filters')
      .setType('text')
      .setSortable(false)
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('Data criação')
      .setField('createdAt')
      .setType('date')
      .setCondition('gte')
      .build()
  ];
  dropdownFilters: {
    [field: string]: Array<DropdownFilter>;
  } = {
    published: [
      { label: 'Todos', value: null },
      { label: 'Sim', value: true },
      { label: 'Não', value: false }
    ],
    hasVariants: [
      { label: 'Todos', value: null },
      { label: 'Sim', value: true },
      { label: 'Não', value: false }
    ]
  };
  fixedFilters: Array<PageableFilter>;
  products: Array<Partial<Product>>;
  categories: Array<Partial<Category>>;
  selectedTab = 0;
  private clipboard = inject(Clipboard);

  constructor(
    private title: Title,
    private categoryFilterService: ShopCategoryFilterService,
    private router: Router,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public productService: ShopProductService,
    private activatedRoute: ActivatedRoute
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.filterId) {
      await this.loadFilter();
    } else {
      this.filter.set({} as Filter);
      this.form.controls.description.enable();
      this.form.controls.slug.enable();
    }
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      if (queryParams['tab']) {
        this.selectedTab = Number(queryParams['tab']) || 0;
      } else if (!this.filter()) {
        this.selectedTab = 0;
      }
    });
  }

  async setSlug(validate = true, slug = ''): Promise<void> {
    if (slug) {
      this.form.patchValue({ slug });
      return;
    }
    console.log('depois do if');
    if (this.form.controls.description.valid) {
      const slug = slugify(this.form.value.description);
      if (validate)
        try {
          const exists = await this.categoryFilterService.getFilterBySlug(slug);
          if (exists) {
            this.form.get('slug').setErrors({ exists });
            return;
          }
        } catch (error) {
          AppDialogService.showErrorDialog(error);
        }
      this.form.get('slug').setValue(slug);
      this.form.get('slug').updateValueAndValidity();
    }
  }

  copyFilterSlugToClipboard(): void {
    this.clipboard.copy(this.form.getRawValue().slug);
    this.messageService.add({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Slug do filtro copiado para área de trasnferência'
    });
  }

  async save(): Promise<void> {
    LoaderService.showLoader();
    try {
      if (!this.filterId) {
        const savedFilter = await this.categoryFilterService.saveFilter({
          ...(this.form.value as Filter),
          id: this.filterId ? Number(this.filterId) : undefined,
          options: this.filter()?.options || []
        });
        LoaderService.showLoader(false);
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'Filtro criado com sucesso'
        });
        this.router.navigate([
          `/shop-products/filters/filter/${savedFilter.id}`
        ]);
        return;
      } else {
        await this.categoryFilterService.updateFilter({
          ...(this.form.value as Filter),
          id: this.filterId ? Number(this.filterId) : null,
          options: this.filter().options
        });
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'Filtro salvo com sucesso'
        });
      }

      await this.loadFilter();
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  async loadFilter(): Promise<void> {
    LoaderService.showLoader();
    try {
      this.filter.set(
        await this.categoryFilterService.getFilterById(this.filterId)
      );
      this.categories = this.filter().categories ?? [];
      const products = await Promise.all(
        this.filter().options.map((o) =>
          this.productService.getProductsList({
            filters: [{ condition: 'in', field: 'filters', value: o.id }],
            page: 0,
            pageSize: 9999
          })
        )
      );
      this.products = products.reduce(
        (list: Partial<Product>[], products) =>
          (list = list.concat(
            products?.content?.filter((p) =>
              list.every((pp) => pp.id !== p.id)
            ) || []
          )),
        []
      );
      this.form.patchValue(this.filter());
      this.form.get('description').disable();
      this.setSlug(false, this.filter().slug);
      this.title.setTitle(`Loja - Filtro ${this.filter().description}`);
    } catch (error) {
      AppDialogService.showErrorDialog(error, true);
    }
    LoaderService.showLoader(false);
  }

  deleteFilterOption($event: { item: Filter; $event: Event }) {
    this.confirmationService.confirm({
      target: $event.$event.target,
      message: 'Deseja excluir a opção?',
      header: 'Atenção',
      icon: 'pi pi-exclamation-triangle danger',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      acceptButtonStyleClass: 'p-button-primary',
      rejectButtonStyleClass: 'p-button-danger',
      accept: async () => {
        LoaderService.showLoader();
        try {
          await this.categoryFilterService.deleteFilterOption(
            $event.item.id.toString()
          );
          this.messageService.add({
            severity: 'success',
            detail: `Opção excluída!`,
            summary: 'Sucesso'
          });
          await this.loadFilter();
        } catch (error) {
          AppDialogService.showErrorDialog(error);
        }
      }
    });
  }

  async addTag(): Promise<void> {
    if (this.newTagControl.valid) {
      const slug = slugify(
        `${this.filter().description} ${this.newTagControl.value}`
      );
      if (this.filter().options.some((o) => o.id === slug)) {
        this.newTagControl.setErrors({ exists: true });
        return;
      }
      this.filter.update((filter) => {
        filter.options = (filter.options || []).concat([
          {
            description: this.newTagControl.value,
            id: slug,
            filterId: this.filter().id
          } as FilterOption
        ]);
        this.newTagControl.reset();
        return filter;
      });
      if (this.filterId) await this.save();
    }
  }

  validateOption(): void {
    if (this.newTagControl.valid) {
      const slug = slugify(this.newTagControl.value);
      if (this.filter().options.some((o) => o.id === slug)) {
        this.newTagControl.setErrors({ exists: true });
      } else {
        this.newTagControl.setErrors({});
      }
    } else {
      this.newTagControl.markAsTouched();
      this.newTagControl.markAsDirty();
      this.newTagControl.updateValueAndValidity();
    }
  }

  tabChanged($event: number): void {
    this.router.navigate(
      [`/shop-products/filters/filter/${this.filter().id}`],
      {
        queryParams: { tab: $event },
        queryParamsHandling: 'merge'
      }
    );
  }
}

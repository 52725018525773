/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SubscriberEditionIncidentDetail {
  incidentId?: number;
  incidentTypeId?: number;
  description?: string;
  productVariantId?: number;
  personId?: number;
  editionId?: number;
  dateCreated?: Date;
  userId?: number;
  theme?: string;
  incident?: string;
  productVariantName?: string;
  externalId?: number;
  internalEAN?: string;
  millenniumSKU?: string;
  personName?: string;
  username?: string;
  email?: string;
  cpf?: number;
}

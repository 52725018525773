/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CouponProductChangeDetail {
  couponProductChangeId?: number;
  productVariantId?: number;
  quantity?: number;
  couponProductVariantType?: number;
  couponProductVariantTypeName?: string;
  groupVariant?: number;
  dateCreated?: Date;
  changed?: boolean;
  couponPriceChangeId?: number;
  userId?: number;
  userName?: string;
  userEmail?: string;
  productVariantName?: string;
  defaultImageURL?: string;
  brandName?: string;
  ratingAverage?: number;
  internalEAN?: string;
  reviewCount?: number;
  inStockReserveMarketing?: number;
}

import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { lastValueFrom, map } from 'rxjs';
import {
  Edition,
  EditionControllerService,
  EditionScoreDetails,
  KpiControllerService
} from 'src/app/admin-api';
import { TableColumn } from 'src/app/components/table';
import {
  EditionId,
  getAllSubscriptionsIncludeAll,
  getSubscriptionName
} from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-rating-and-scores-dashboard',
  templateUrl: './rating-and-scores-dashboard.component.html',
  styleUrl: './rating-and-scores-dashboard.component.scss'
})
export class RatingAndScoresDashboardComponent implements OnInit {
  form = new FormGroup({
    subscriptionId: new FormControl<number>(0, [
      Validators.required,
      Validators.min(0)
    ]),
    startEditionId: new FormControl<number>(
      EditionId.incrementEdition(EditionId.currentEdition(0), -25),
      [Validators.required, Validators.min(1)]
    ),
    endEditionId: new FormControl<number>(
      EditionId.incrementEdition(EditionId.currentEdition(0), -1),
      [Validators.required, Validators.min(1)]
    )
  });
  subscriptions: Array<{ label: string; value: number }>;
  editions: Array<Edition> | undefined;
  ready = false;
  chartData:
    | {
        labels: Array<string>;
        datasets: Array<{
          type: 'pie' | 'doughtnut' | 'line' | 'bar' | 'radar' | 'polarArea';
          label: string;
          borderWidth?: number;
          backgroundColor?: string;
          borderColor?: string;
          fill?: boolean;
          tension?: number;
          data: Array<number>;
          subscriptionId?: number;
          stack?: string;
          hidden?: boolean;
        }>;
      }
    | undefined;
  chartConfig = {
    options: {
      animation: {
        easing: 'easeInQuad'
      },
      scales: {
        y: {
          min: 0,
          max: 10,
          bounds: 'data'
        }
      }
    }
  };
  width: string;
  editionScoreDetails:
    | Array<
        EditionScoreDetails & { editionDate: string; subscriptionName: string }
      >
    | undefined;
  cols: Array<TableColumn> = [
    new TableColumn(
      'Tema',
      'theme',
      true,
      'text',
      '',
      'editionId',
      true,
      'contains'
    ),
    new TableColumn(
      'Referência',
      'editionDate',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Assinatura',
      'subscriptionName',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn('Avg Box Rating', 'avgBoxRating', false, 'formattedNumber'),
    new TableColumn('Avg Delivery', 'avgDelivery', false, 'formattedNumber'),
    new TableColumn('Avg Products', 'avgProducts', false, 'formattedNumber'),
    new TableColumn('Avg Match', 'avgFitting', false, 'formattedNumber')
  ];
  constructor(
    private kpiService: KpiControllerService,
    private editionService: EditionControllerService,
    private datePipe: DatePipe,
    private router: Router,
    private title: Title
  ) {
    this.title.setTitle('Dashboard Rating and Scores');
    this.subscriptions = getAllSubscriptionsIncludeAll().filter(
      (s) => s.value !== 6
    );
    this.width =
      (document.getElementById('main-container').clientWidth - 60).toString() +
      'px';
  }

  async ngOnInit(): Promise<void> {
    await this.findPage();
    await this.findEditions();
  }

  async findEditions(): Promise<void> {
    try {
      this.editions = await lastValueFrom(
        this.editionService
          .findEditionsBySubscriptionId(this.form.value.subscriptionId || 1)
          .pipe(
            map((data) =>
              data.result.filter((e) => e.editionId % 1000000 >= 202101)
            )
          )
      );
      if (this.form.value.subscriptionId === 0) {
        this.editions?.forEach(
          (e) => (e.editionId = (e.editionId || 0) % 1000000)
        );
      }
    } catch (error: any) {
      this.editions = [];
    }
  }

  async changeSubscription(): Promise<void> {
    LoaderService.showLoader();
    const form = { ...this.form.value };
    this.form.controls.startEditionId.reset(
      (form.subscriptionId || 0) * 1000000 +
        ((form.startEditionId || 0) % 1000000)
    );
    this.form.controls.endEditionId.reset(
      (form.subscriptionId || 0) * 1000000 +
        ((form.endEditionId || 0) % 1000000)
    );
    delete this.editionScoreDetails;
    this.ready = false;
    await this.findPage();
    await this.findEditions();
    LoaderService.showLoader(false);
  }

  async changeEdition(): Promise<void> {
    LoaderService.showLoader();
    delete this.editionScoreDetails;
    this.ready = false;
    await this.findPage();
    LoaderService.showLoader(false);
  }

  editionDate(editionId: number): string {
    return FormUtil.editionDate(editionId);
  }

  editionDateName(editionId: number): string {
    return (
      this.datePipe.transform(
        new Date(
          `${((editionId % 1000000) / 100).toFixed(0)}-${editionId % 100}-02`
        ),
        'MMMM/yyyy'
      ) || this.editionDate(editionId)
    );
  }

  async findRatingAndScore(): Promise<void> {
    try {
      this.editionScoreDetails = await lastValueFrom(
        this.kpiService
          .findScoresAndRatingsDashboard(
            this.form.value.startEditionId,
            this.form.value.endEditionId
          )
          .pipe(
            map((data) => {
              const result = data.result.map((e) => ({
                ...e,
                editionDate: this.editionDate(e.editionId),
                subscriptionName: getSubscriptionName(
                  Number((e.editionId / 1000000).toFixed(0))
                )
              }));
              result.sort((e1, e2) => {
                if (
                  EditionId.yearMonthReference(e1.editionId) >
                  EditionId.yearMonthReference(e2.editionId)
                )
                  return -1;
                if (
                  EditionId.yearMonthReference(e1.editionId) <
                  EditionId.yearMonthReference(e2.editionId)
                )
                  return 1;
                return (
                  EditionId.subscriptionId(e1.editionId) -
                  EditionId.subscriptionId(e2.editionId)
                );
              });
              return result;
            })
          )
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findPage(): Promise<void> {
    LoaderService.showLoader();
    await this.findRatingAndScore();
    this.chartData = {
      labels: this.xAxis,
      datasets: [
        {
          data: this.xAxis.map((x) => {
            const editions = this.editionScoreDetails.filter(
              (e) => this.editionDate(e.editionId) === x && e.avgBoxRating > 0
            );
            return (
              (editions.reduce((sum, e) => (sum += e.avgBoxRating), 0) || 0) /
              (editions?.length || 1)
            );
          }),
          label: 'Box Rating',
          type: 'line',
          tension: 0.4,
          backgroundColor: '#fe357b',
          borderColor: '#fe357b'
        },
        {
          data: this.xAxis.map((x) => {
            const editions = this.editionScoreDetails.filter(
              (e) => this.editionDate(e.editionId) === x && e.avgProducts > 0
            );
            return (
              (editions.reduce((sum, e) => (sum += e.avgProducts), 0) || 0) /
              (editions?.length || 1)
            );
          }),
          label: 'Products Rating',
          type: 'line',
          tension: 0.4,
          backgroundColor: '#00aaff',
          borderColor: '#00aaff'
        },
        {
          data: this.xAxis.map((x) => {
            const editions = this.editionScoreDetails.filter(
              (e) => this.editionDate(e.editionId) === x && e.avgFitting > 0
            );
            return (
              (editions.reduce((sum, e) => (sum += e.avgFitting), 0) || 0) /
              (editions?.length || 1)
            );
          }),
          label: 'Match Rating',
          type: 'line',
          tension: 0.4,
          backgroundColor: '#8540f5',
          borderColor: '#8540f5'
        },
        {
          data: this.xAxis.map((x) => {
            const editions = this.editionScoreDetails.filter(
              (e) => this.editionDate(e.editionId) === x && e.avgDelivery > 0
            );
            return (
              (editions.reduce((sum, e) => (sum += e.avgDelivery), 0) || 0) /
              (editions?.length || 1)
            );
          }),
          label: 'Delivery Rating',
          type: 'line',
          tension: 0.4,
          backgroundColor: '#faa43a',
          borderColor: '#faa43a'
        }
      ]
    };
    this.ready = true;
    LoaderService.showLoader(false);
  }

  get startEdition(): Edition {
    return this.editions?.find(
      (e) => e.editionId === this.form.value.startEditionId
    );
  }

  get endEdition(): Edition {
    return this.editions?.find(
      (e) => e.editionId === this.form.value.endEditionId
    );
  }

  get xAxis(): Array<string> {
    return this.editionScoreDetails?.reduce((list: Array<string>, e) => {
      if (!list.includes(this.editionDate(e.editionId))) {
        list.push(this.editionDate(e.editionId));
      }
      return list;
    }, []);
  }

  get boxBoxRating(): number {
    const boxEditions = this.editionScoreDetails.filter(
      (e) => (e.editionId / 1000000).toFixed(0) === '1' && e.avgBoxRating > 0
    );
    return (
      boxEditions.reduce((sum, e) => (sum += e.avgBoxRating), 0) /
      (boxEditions.length || 1)
    );
  }

  get bagBoxRating(): number {
    const boxEditions = this.editionScoreDetails.filter(
      (e) => (e.editionId / 1000000).toFixed(0) === '5' && e.avgBoxRating > 0
    );
    return (
      boxEditions.reduce((sum, e) => (sum += e.avgBoxRating), 0) /
      (boxEditions.length || 1)
    );
  }

  get comboBoxRating(): number {
    const boxEditions = this.editionScoreDetails.filter(
      (e) => (e.editionId / 1000000).toFixed(0) === '7' && e.avgBoxRating > 0
    );
    return (
      boxEditions.reduce((sum, e) => (sum += e.avgBoxRating), 0) /
      (boxEditions.length || 1)
    );
  }

  get boxDelivery(): number {
    const boxEditions = this.editionScoreDetails.filter(
      (e) => (e.editionId / 1000000).toFixed(0) === '1' && e.avgDelivery > 0
    );
    return (
      boxEditions.reduce((sum, e) => (sum += e.avgDelivery), 0) /
      (boxEditions.length || 1)
    );
  }

  get bagDelivery(): number {
    const boxEditions = this.editionScoreDetails.filter(
      (e) => (e.editionId / 1000000).toFixed(0) === '5' && e.avgDelivery > 0
    );
    return (
      boxEditions.reduce((sum, e) => (sum += e.avgDelivery), 0) /
      (boxEditions.length || 1)
    );
  }

  get comboDelivery(): number {
    const boxEditions = this.editionScoreDetails.filter(
      (e) => (e.editionId / 1000000).toFixed(0) === '7' && e.avgDelivery > 0
    );
    return (
      boxEditions.reduce((sum, e) => (sum += e.avgDelivery), 0) /
      (boxEditions.length || 1)
    );
  }

  get boxProducts(): number {
    const boxEditions = this.editionScoreDetails.filter(
      (e) => (e.editionId / 1000000).toFixed(0) === '1' && e.avgProducts > 0
    );
    return (
      boxEditions.reduce((sum, e) => (sum += e.avgProducts), 0) /
      (boxEditions.length || 1)
    );
  }

  get bagProducts(): number {
    const boxEditions = this.editionScoreDetails.filter(
      (e) => (e.editionId / 1000000).toFixed(0) === '5' && e.avgProducts > 0
    );
    return (
      boxEditions.reduce((sum, e) => (sum += e.avgProducts), 0) /
      (boxEditions.length || 1)
    );
  }

  get comboProducts(): number {
    const boxEditions = this.editionScoreDetails.filter(
      (e) => (e.editionId / 1000000).toFixed(0) === '7' && e.avgProducts > 0
    );
    return (
      boxEditions.reduce((sum, e) => (sum += e.avgProducts), 0) /
      (boxEditions.length || 1)
    );
  }

  get boxFitting(): number {
    const boxEditions = this.editionScoreDetails.filter(
      (e) => (e.editionId / 1000000).toFixed(0) === '1' && e.avgFitting > 0
    );
    return (
      boxEditions.reduce((sum, e) => (sum += e.avgFitting), 0) /
      (boxEditions.length || 1)
    );
  }

  get bagFitting(): number {
    const boxEditions = this.editionScoreDetails.filter(
      (e) => (e.editionId / 1000000).toFixed(0) === '5' && e.avgFitting > 0
    );
    return (
      boxEditions.reduce((sum, e) => (sum += e.avgFitting), 0) /
      (boxEditions.length || 1)
    );
  }

  get comboFitting(): number {
    const boxEditions = this.editionScoreDetails.filter(
      (e) => (e.editionId / 1000000).toFixed(0) === '7' && e.avgFitting > 0
    );
    return (
      boxEditions.reduce((sum, e) => (sum += e.avgFitting), 0) /
      (boxEditions.length || 1)
    );
  }

  get avgBoxRating(): number {
    let total = 0;
    let sum = 0;
    if (this.boxBoxRating) {
      total++;
      sum += this.boxBoxRating;
    }
    if (this.bagBoxRating) {
      total++;
      sum += this.bagBoxRating;
    }
    if (this.comboBoxRating) {
      total++;
      sum += this.comboBoxRating;
    }
    return sum / (total || 1);
  }

  get avgProducts(): number {
    let total = 0;
    let sum = 0;
    if (this.boxProducts) {
      total++;
      sum += this.boxProducts;
    }
    if (this.bagProducts) {
      total++;
      sum += this.bagProducts;
    }
    if (this.comboProducts) {
      total++;
      sum += this.comboProducts;
    }
    return sum / (total || 1);
  }

  get avgDelivery(): number {
    let total = 0;
    let sum = 0;
    if (this.boxDelivery) {
      total++;
      sum += this.boxDelivery;
    }
    if (this.bagDelivery) {
      total++;
      sum += this.bagDelivery;
    }
    if (this.comboDelivery) {
      total++;
      sum += this.comboDelivery;
    }
    return sum / (total || 1);
  }

  get avgFitting(): number {
    let total = 0;
    let sum = 0;
    if (this.boxFitting) {
      total++;
      ('');
      sum += this.boxFitting;
    }
    if (this.bagFitting) {
      total++;
      sum += this.bagFitting;
    }
    if (this.comboFitting) {
      total++;
      sum += this.comboFitting;
    }
    return sum / (total || 1);
  }

  get recomendationsTotal() {
    return (
      this.editionScoreDetails?.reduce(
        (sum, d) => (sum += d.recomendationsTotal),
        0
      ) || 0
    );
  }
}

<ng-container *ngIf="settings">
  <h3 class="title">Customizar Home da Glam</h3>
  <div class="fixed-section" *ngFor="let section of firstSections">
    <ng-container
      *ngTemplateOutlet="sectionTemplate; context: { section: section }"
    />
  </div>
  <app-gridstack
    (changeCB)="listChanged()"
    [options]="gridOptions"
    *ngIf="movableSections?.length"
  >
    <app-gridstack-item
      *ngFor="let section of movableSections"
      [options]="{
        noResize: true,
        noMove: section?.fixed,
        id: section?.id
      }"
      class="gridstack-card"
    >
      <ng-container
        *ngTemplateOutlet="sectionTemplate; context: { section: section }"
      />
    </app-gridstack-item>
  </app-gridstack>
  <div class="fixed-section" *ngFor="let section of lastSections">
    <ng-container
      *ngTemplateOutlet="sectionTemplate; context: { section: section }"
    />
  </div>
  <div class="footer">
    <span class="footer__button">
      <p-button
        label="Adicionar banner"
        routerLink="banner/new"
        [disabled]="maxBanners"
      />
      <small *ngIf="maxBanners">* Máximo 5 banners</small>
    </span>
    <span class="footer__button">
      <p-button
        severity="warning"
        label="Adicionar coleção"
        routerLink="collection/new"
        [disabled]="maxCollections"
      />
      <small *ngIf="maxCollections">* Máximo 6 coleções</small>
    </span>
  </div>
</ng-container>
<ng-template #sectionTemplate let-section="section">
  <div class="section">
    <p-fieldset>
      <ng-template pTemplate="header">
        <div class="fieldset-label">
          <span class="font-bold">{{ sectionTitle(section) | titlecase }}</span>
          <i class="pi pi-lock" *ngIf="section.fixed"></i>
        </div>
      </ng-template>
      <ng-container *ngIf="section?.type === 'carousel'">
        <ng-container
          *ngTemplateOutlet="carousel; context: { section: section }"
        />
      </ng-container>
      <ng-container *ngIf="section?.type === 'banner'">
        <ng-container
          *ngTemplateOutlet="bannerTemp; context: { section: section }"
        />
      </ng-container>
      <ng-container
        *ngIf="
          section?.type === 'collection' ||
          section?.type === 'collection premium'
        "
      >
        <ng-container
          *ngTemplateOutlet="collectionTemp; context: { section: section }"
        />
      </ng-container>
      <ng-container *ngIf="section?.type === 'brands'">
        <ng-container
          *ngTemplateOutlet="brandsTemp; context: { section: section }"
        />
      </ng-container>
      <ng-container *ngIf="section?.type === 'plans'">
        <ng-container
          *ngTemplateOutlet="plansTemp; context: { section: section }"
        />
      </ng-container>
      <ng-container
        *ngIf="
          section?.type !== 'banner' &&
          section.type !== 'carousel' &&
          section.type !== 'collection' &&
          section.type !== 'collection premium' &&
          section.type !== 'brands' &&
          section.type !== 'plans'
        "
      >
        <ng-container
          *ngTemplateOutlet="defaultTemp; context: { section: section }"
        />
      </ng-container>
      <div class="actions">
        <div class="actions__vertical">
          <p-button
            icon="pi pi-pencil"
            [disabled]="!section?.editable"
            [text]="true"
            [rounded]="true"
            pTooltip="Editar"
            tooltipPosition="bottom"
            [routerLink]="
              section.type.replace(' premium', '') +
              (section.type === 'banner' ||
              section.type === 'collection' ||
              section.type === 'collection premium'
                ? '/' + section.id
                : '')
            "
          />
          <p-button
            icon="pi pi-trash"
            *ngIf="
              section.type === 'banner' ||
              section.type === 'collection' ||
              section.type === 'collection premium'
            "
            [text]="true"
            [rounded]="true"
            severity="danger"
            pTooltip="Excluir"
            tooltipPosition="bottom"
            (onClick)="deleteSection($event, section)"
          />
        </div>
        <p-button
          icon="pi pi-thumbtack"
          [disabled]="true"
          *ngIf="section?.fixed"
          [text]="true"
          [rounded]="true"
          pTooltip="Fixo"
          tooltipPosition="bottom"
        />
        <i
          class="pi pi-arrows-v p-ripple p-element p-button p-button-icon-only p-button-text p-button-primary p-button-rounded"
          *ngIf="!section?.fixed"
          pTooltip="Mover"
          tooltipPosition="bottom"
        ></i>
      </div>
    </p-fieldset>
  </div>
</ng-template>

<ng-template #carousel let-section="section">
  <p-carousel
    [value]="carrouselBanners"
    [numVisible]="1"
    [numScroll]="1"
    [circular]="true"
    [responsiveOptions]="responsiveOptionsBig"
    [autoplayInterval]="carrouselBanners?.length > 1 ? 5000 : null"
    *ngIf="carrouselBanners?.length"
    [showNavigators]="carrouselBanners?.length > 1"
    [showIndicators]="carrouselBanners?.length > 1"
    styleClass="carousel__glam"
  >
    <ng-template pTemplate="header">
      <app-glam-home-banner-alert [section]="section" />
    </ng-template>
    <ng-template let-banner pTemplate="item">
      <div class="carousel-banner">
        <img
          [alt]="banner.altText"
          [src]="banner.desktopImage"
          class="border-round"
        />
      </div>
    </ng-template>
  </p-carousel>
</ng-template>
<ng-template #bannerTemp let-section="section">
  <div class="info">
    <p>Nome: {{ section.altText }}</p>
    <p *ngIf="section.link?.length > 50">
      Link: {{ section.link | slice : 1 : 50 }}...
    </p>
    <p *ngIf="section.link?.length <= 50">
      Link: {{ section.link || 'Sem link' }}
    </p>
    <p *ngIf="section.publishDate">
      Data de publicação:
      {{ section.publishDate | date : 'dd/MM/yyyy HH:mm:ss' }}
    </p>
  </div>
  <div class="image">
    <img
      [src]="section.desktopImage"
      [alt]="section.altText"
      class="image-banner"
    />
    <img
      [src]="section.mobileImage"
      [alt]="section.altText"
      class="image-banner__mobile"
    />
  </div>
</ng-template>
<ng-template #collectionTemp let-section="section">
  <div class="info">
    <p>Nome: {{ section.title }}</p>
    <p *ngIf="section.categoryId">
      Coleção:
      <a
        [routerLink]="
          '/shop-products/categories/category/' + section.categoryId
        "
      >
        {{ category(section.categoryId)?.name }}
      </a>
    </p>
    <p class="brand-info" *ngIf="collectionUrl(section)">
      Link:
      <a target="_blank" [href]="collectionUrl(section)">
        {{ collectionUrl(section) }}
      </a>
    </p>
  </div>
  <div
    class="color-preview"
    *ngIf="
      section.type === 'collection premium' &&
      section.collectionImage &&
      section.collectionColor
    "
    [ngStyle]="{
      background:
        'linear-gradient(180deg, ' +
        section.collectionColor +
        ' 33.33%, #f6b6b700 74.24%)'
    }"
  >
    <img [src]="section.collectionImage" [alt]="section.collectionImageAlt" />
  </div>
  <p-carousel
    [value]="products[section.categoryId]"
    *ngIf="
      section.type === 'collection' &&
      section.categoryId &&
      products[section.categoryId]?.length
    "
    [numVisible]="4"
    [numScroll]="4"
    [circular]="false"
    [responsiveOptions]="responsiveOptionsSmall"
    [showNavigators]="false"
    [showIndicators]="false"
    styleClass="carousel__glam"
  >
    <ng-template let-product pTemplate="item">
      <div class="collections-banner">
        <img
          *ngIf="product.images?.length"
          [alt]="product.name"
          [src]="product.images[0]"
          class="collection-banner"
        />
        <img
          *ngIf="!product.images?.length"
          [alt]="product.name"
          src="assets/images/no_img_available.jpg"
          class="collection-banner"
        />
      </div>
    </ng-template>
  </p-carousel>
  <div
    class="info"
    *ngIf="
      section.type === 'collection' && !products[section.categoryId]?.length
    "
  >
    Sem produtos nesta coleção
  </div>
</ng-template>
<ng-template #brandsTemp let-section="section">
  <p-carousel
    [value]="section.brands"
    *ngIf="section.brands?.length"
    [numVisible]="6"
    [numScroll]="6"
    [circular]="false"
    [responsiveOptions]="responsiveOptionsSmall"
    [showIndicators]="false"
    styleClass="carousel__glam"
  >
    <ng-template let-brand pTemplate="item">
      <div class="brands-banner">
        <img
          *ngIf="brand.image"
          [alt]="brand.name"
          [src]="brand.image"
          class="brand-banner"
        />
        <img
          *ngIf="!brand.image"
          [alt]="brand.name"
          src="assets/images/no_img_available.jpg"
          class="brand-banner"
        />
        <p>{{ brand.brandName }}</p>
      </div>
    </ng-template>
  </p-carousel>
  <div class="info" *ngIf="!brands?.length">Sem marcas cadastradas</div>
</ng-template>
<ng-template #plansTemp let-section="section">
  <p-carousel
    [value]="plans"
    *ngIf="plans?.length"
    [numVisible]="4"
    [numScroll]="4"
    [circular]="false"
    [responsiveOptions]="responsiveOptionsSmall"
    [showIndicators]="false"
    [showNavigators]="false"
    styleClass="carousel__glam"
  >
    <ng-template let-plan pTemplate="item">
      <div class="plans-banner">
        <img
          *ngIf="plan.image"
          [alt]="plan.name"
          [src]="plan.image"
          class="plan-banner"
        />
        <p>{{ plan.name | titlecase }}</p>
      </div>
    </ng-template>
  </p-carousel>
</ng-template>
<ng-template #defaultTemp let-section="section">
  <img class="fixed-image" [src]="'assets/images/' + section.type + '.png'" />
</ng-template>
<p-toast />
<p-confirmPopup />

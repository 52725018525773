import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BatchUpdateComponent } from './batch-update/batch-update.component';
import { CategoryFilterDetailComponent } from './category-filters/category-filter-detail/category-filter-detail.component';
import { CategoryFiltersComponent } from './category-filters/category-filters.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { ProductStockNotificationsComponent } from './product-stock-notifications/product-stock-notifications.component';
import { ShopCategoriesComponent } from './shop-categories/shop-categories.component';
import { ShopCategoryDetailComponent } from './shop-categories/shop-category-detail/shop-category-detail.component';
import { ShopProductsComponent } from './shop-products.component';
import { ShopReviewsComponent } from './shop-reviews/shop-reviews.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'catalog'
  },
  {
    path: 'catalog',
    component: ShopProductsComponent
  },
  {
    path: 'categories',
    component: ShopCategoriesComponent
  },
  {
    path: 'categories/category/new',
    component: ShopCategoryDetailComponent
  },
  {
    path: 'categories/category/:categoryId',
    component: ShopCategoryDetailComponent
  },
  {
    path: 'catalog/product/:productId',
    component: ProductDetailComponent
  },
  {
    path: 'filters',
    component: CategoryFiltersComponent
  },
  {
    path: 'filters/new',
    component: CategoryFilterDetailComponent
  },
  {
    path: 'filters/filter/:filterId',
    component: CategoryFilterDetailComponent
  },
  {
    path: 'reviews',
    component: ShopReviewsComponent
  },
  {
    path: 'batch-update',
    component: BatchUpdateComponent
  },
  {
    path: 'avise-me',
    component: ProductStockNotificationsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductsRoutingModule {}

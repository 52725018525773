import { Component, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Category, Home, Product, Shops } from '@infrab4a/connect';
import { CarouselResponsiveOptions } from 'primeng/carousel';
import { HomeService } from 'src/app/connect-api/api/shop-settings/home.service';
import { ShopCategoryService } from 'src/app/connect-api/api/shop/shop-category.service';
import { ShopProductService } from 'src/app/connect-api/api/shop/shop-product.service';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-mens-home',
  templateUrl: './mens-home.component.html',
  styleUrl: './mens-home.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class MensHomeComponent implements OnInit {
  private homeService = inject(HomeService);
  private categoryService = inject(ShopCategoryService);
  private productService = inject(ShopProductService);
  private title = inject(Title);
  home: Home;
  responsiveOptionsSmall: CarouselResponsiveOptions[] = [
    {
      breakpoint: '1199px',
      numVisible: 4,
      numScroll: 4
    },
    {
      breakpoint: '991px',
      numVisible: 3,
      numScroll: 3
    },
    {
      breakpoint: '767px',
      numVisible: 2,
      numScroll: 2
    }
  ];
  responsiveOptionsBig = [
    {
      breakpoint: '1199px',
      numVisible: 1,
      numScroll: 1
    },
    {
      breakpoint: '991px',
      numVisible: 1,
      numScroll: 1
    },
    {
      breakpoint: '767px',
      numVisible: 1,
      numScroll: 1
    }
  ];
  categories: Partial<Category>[];
  products: { [collectionId: string]: Product[] } = {};
  ready = false;

  async ngOnInit(): Promise<void> {
    try {
      LoaderService.showLoader();
      this.title.setTitle('Customizar Home da Mens');
      this.home = await this.homeService.get('mens_market');
      this.ready = true;
      await this.findCollections();
      await this.findProducts();
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async findCollections(): Promise<void> {
    const collections = this.sections
      .filter(
        (s) =>
          s.type === 'string[]' &&
          (this.home[s.id] as string[])?.length &&
          s.id?.trim()
      )
      .reduce(
        (collections: string[], s) =>
          (collections = collections.concat(
            (this.home[s.id] as string[]) || []
          )),
        []
      )
      .filter((b) => b.trim().length);
    if (collections.length)
      this.categories = await Promise.all(
        [...new Map(collections.map((b) => [b, b])).values()].map((id) =>
          this.categoryService.getCategoryById(id)
        )
      );
  }

  async findProducts(): Promise<void> {
    for (const cat of this.categories) {
      if (cat.getMostRelevantByShop(Shops.MENSMARKET)) {
        this.products[cat.id] = await Promise.all(
          cat
            .getMostRelevantByShop(Shops.MENSMARKET)
            .slice(0, 4)
            .map((p) => this.productService.getProductById(p))
        );
      } else if (cat.products?.length) {
        this.products[cat.id] = await Promise.all(
          cat.products
            .slice(0, 4)
            .map((p) => this.productService.getProductById(p))
        );
      } else {
        this.products[cat.id] = [];
      }
    }
  }

  collection(collectionId: string): Partial<Category> {
    return this.categories?.find((c) => c.id === collectionId);
  }

  get sections() {
    return HomeService.EDITABLE_SECTIONS.filter((s) => !s.hidden);
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BlacklistComponent } from './blacklist/blacklist.component';
import { PaymentsComponent } from './payments/payments.component';
import { PersonDetailsComponent } from './person-details/person-details.component';
import { PersonComponent } from './person/person.component';
import { SubscriberCancellationsComponent } from './subscriber-cancellations/subscriber-cancellations.component';
import { SubscriberDetailsComponent } from './subscriber-details/subscriber-details.component';
import { SubscriberRefundsComponent } from './subscriber-refunds/subscriber-refunds.component';
import { SubscribersComponent } from './subscribers/subscribers.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'person'
  },
  {
    path: 'person',
    component: PersonComponent
  },
  {
    path: 'person/:id',
    component: PersonDetailsComponent
  },
  {
    path: 'subscribers',
    component: SubscribersComponent
  },
  {
    path: 'subscribers/:subscriberId',
    component: SubscriberDetailsComponent
  },
  {
    path: 'cancellations',
    component: SubscriberCancellationsComponent
  },
  {
    path: 'payments',
    component: PaymentsComponent
  },
  {
    path: 'blacklists',
    component: BlacklistComponent
  },
  {
    path: 'refund',
    component: SubscriberRefundsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule {}

import {
  Component,
  Injectable,
  Injector,
  ViewEncapsulation
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ProductReviews } from '@infrab4a/connect';
import { FileUpload } from 'primeng/fileupload';
import { DropdownFilter, TableColumn } from 'src/app/components/table';
import {
  BATCH_CATEGORY_MOST_RELEVANT_SERVICE,
  BATCH_CATEGORY_SERVICE,
  BATCH_PRODUCT_SERVICE,
  BATCH_REVIEW_SERVICE,
  BatchService
} from 'src/app/connect-api/api/shop/batch/batch.service';
import { ShopProductReviewService } from 'src/app/connect-api/api/shop/shop-product-review.service';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FileUtil } from 'src/app/utils/file.util';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-batch-update',
  templateUrl: './batch-update.component.html',
  styleUrl: './batch-update.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class BatchUpdateComponent {
  activeIndex = 0;
  readyToUpload = false;
  uploading = false;
  protected batchService: BatchService;
  type: 'product' | 'category' | 'review' | 'mostRelevant';
  results: {
    [type: string]: Array<any>;
  } = {};
  categoryCols = [
    new TableColumn(
      'Id',
      'id',
      true,
      'text',
      '/shop-products/categories/category/',
      'id',
      true,
      'contains'
    ),
    new TableColumn(
      'Nome',
      'name',
      true,
      'text',
      '/shop-products/categories/category/',
      'id',
      true,
      'contains'
    ),
    new TableColumn('Loja', 'shop', true, 'text', undefined, undefined),
    new TableColumn(
      'É uma marca',
      'brandCategory',
      true,
      'boolean',
      undefined,
      undefined
    ),
    new TableColumn(
      'É uma coleção',
      'isCollection',
      true,
      'boolean',
      undefined,
      undefined
    ),
    new TableColumn(
      'É uma Wishlist',
      'isWishlist',
      true,
      'boolean',
      undefined,
      undefined
    ),
    new TableColumn(
      'Referência',
      'reference',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Ativo',
      'published',
      true,
      'boolean',
      undefined,
      undefined,
      true
    )
  ];
  dropdownFilters: {
    [field: string]: Array<DropdownFilter>;
  } = {
    shop: [
      { label: 'Todos', value: undefined },
      { label: 'Glamshop', value: 'Glamshop' },
      { label: 'Men`s Market', value: 'mensmarket' }
    ],
    brandCategory: [
      { label: 'Todos', value: undefined },
      { label: 'Sim', value: true },
      { label: 'Não', value: false }
    ],
    isCollection: [
      { label: 'Todos', value: undefined },
      { label: 'Sim', value: true },
      { label: 'Não', value: false }
    ],
    isWishlist: [
      { label: 'Todos', value: undefined },
      { label: 'Sim', value: true },
      { label: 'Não', value: false }
    ],
    published: [
      { label: 'Todos', value: undefined },
      { label: 'Sim', value: true },
      { label: 'Não', value: false }
    ]
  };
  productCols = [
    new TableColumn(
      'SKU',
      'sku',
      true,
      'text',
      '/shop-products/catalog/product/',
      'id',
      true,
      'contains'
    ),
    new TableColumn(
      'EAN',
      'EAN',
      true,
      'text',
      '/shop-products/catalog/product/',
      'id'
    ),
    new TableColumn(
      'Nome',
      'name',
      true,
      'text',
      '/shop-products/catalog/product/',
      'id',
      true,
      'contains'
    ),
    new TableColumn(
      'Marca',
      'brand',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    )
  ];
  protected reviewCols = [
    new TableColumn.Builder()
      .setHeader('SKU admin')
      .setField('productId')
      .setRouterLink('/shop-products/catalog/product/')
      .setRouterLinkFieldName('productId')
      .setSortable(false)
      .setCondition('equals')
      .build(),
    new TableColumn.Builder()
      .setHeader('Email')
      .setField('email')
      .setRouterLink('/users/person/')
      .setRouterLinkFieldName('personId')
      .build(),
    new TableColumn.Builder()
      .setHeader('Autor')
      .setField('author')
      .setRouterLink('/users/person/')
      .setRouterLinkFieldName('personId')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('Produto')
      .setField('productName')
      .setRouterLink('/shop-products/catalog/product/')
      .setRouterLinkFieldName('productId')
      .setSortable(false)
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('Nota')
      .setField('rate')
      .setType('number')
      .build(),
    new TableColumn.Builder()
      .setHeader('Titulo')
      .setField('title')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('Avaliação')
      .setField('review')
      .setCondition('contains')
      .setDisplayFunction(
        (review: ProductReviews) =>
          `${review.review?.substring(0, 40)}${
            review.review?.length >= 40 ? '...' : ''
          }`
      )
      .build(),
    new TableColumn.Builder()
      .setHeader('Status')
      .setField('status')
      .setDisplayFunction((review: ProductReviews) =>
        ShopProductReviewService.mapReviewStatus(review.status)
      )
      .build(),
    new TableColumn.Builder()
      .setHeader('Criação')
      .setField('createdAt')
      .setType('date')
      .setCondition('gte')
      .build(),
    new TableColumn.Builder()
      .setHeader('Ação')
      .setField('')
      .setType('button')
      .build()
  ];

  constructor(private title: Title, public injector: Injector) {
    this.title.setTitle('Loja - Atualização em lote');
  }

  async onFileSelected(
    $event: { files: File[] },
    fileUpload: FileUpload,
    type: 'product' | 'category' | 'review' | 'mostRelevant'
  ) {
    LoaderService.showLoader();
    this.type = type;
    this.uploading = false;
    this.readyToUpload = false;
    const { files } = $event;
    this.batchService = this.getServiceByType(type);
    this.results[type] = undefined;

    try {
      await this.batchService.parse(files[0]);
      this.batchService.update().then((items) => {
        this.results[type] = items;
        LoaderService.showLoader(false);
      });
    } catch (error) {
      LoaderService.showLoader(false);
      AppDialogService.showErrorDialog(error);
    } finally {
      fileUpload.clear();
    }
  }

  uploadBatch() {
    this.uploading = true;
  }

  showToastMessage(message: string, type: 'success' | 'error') {
    AppDialogService.showInfoDialog({
      message,
      header: type === 'success' ? 'Sucesso' : 'Erro'
    });
  }

  getServiceByType(type: 'product' | 'category' | 'review' | 'mostRelevant') {
    switch (type) {
      case 'product':
        return this.injector.get(BATCH_PRODUCT_SERVICE);
      case 'category':
        return this.injector.get(BATCH_CATEGORY_SERVICE);
      case 'mostRelevant':
        return this.injector.get(BATCH_CATEGORY_MOST_RELEVANT_SERVICE);
      case 'review':
        return this.injector.get(BATCH_REVIEW_SERVICE);
    }
  }

  get itemTypeDescription() {
    return this.activeIndex === 0
      ? 'Categorias'
      : this.activeIndex === 1
      ? 'Produtos'
      : 'Reviews';
  }

  public downloadCategoryTemplate() {
    const modelo = [
      'name',
      'slug',
      'isBrand',
      'isCollection',
      'published',
      'description',
      'tags',
      'filters',
      'shops',
      'glamMetadataTitle',
      'glamMetadataDescription',
      'mensMetadataTitle',
      'mensMetadataDescription',
      'reference',
      'categoryReference'
    ];
    FileUtil.exportCSV([], 'Modelo Batch update categorias.csv', modelo);
  }

  public downloadProductTemplate() {
    const modelo = [
      'sku',
      'published',
      'description',
      'differentials',
      'whoMustUse',
      'howToUse',
      'brandDescription',
      'ingredients',
      'purpose',
      'type',
      'tags',
      'filters',
      'price',
      'fullPrice',
      'discountPercentage',
      'brand',
      'weight',
      'name',
      'slug',
      'NCM',
      'EAN',
      'CEST',
      'stock',
      'video',
      'metadataTitle',
      'metadataDescription',
      'category_reference',
      'label'
    ];

    FileUtil.exportCSV([], 'Modelo Batch update produtos.csv', modelo);
  }

  public downloadReviewTemplate() {
    const modelo = [
      'sku',
      'shop',
      'email',
      'author',
      'rate',
      'location',
      'title',
      'review',
      'createdAt',
      'status'
    ];
    FileUtil.exportCSV([], 'Modelo Batch update reviews.csv', modelo);
  }

  public downloadMostRelevantTemplate() {
    const headers = ['slugs', 'slug-example'];
    const content = ['shops', 'Glamshop,mensmarket', 'EAN-example'];

    FileUtil.exportCSV(
      content,
      'Modelo Batch update mais relevantes.csv',
      headers
    );
  }
}

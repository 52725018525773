/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpResponse
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { ResponseB4ABoolean } from '../model/responseB4ABoolean';
import { ResponseB4AExportedOrderTotal } from '../model/responseB4AExportedOrderTotal';
import { ResponseB4AListDailyOrderCountByMonth } from '../model/responseB4AListDailyOrderCountByMonth';
import { ResponseB4AListLoggerUpdatedServiceCompanyRangeDTO } from '../model/responseB4AListLoggerUpdatedServiceCompanyRangeDTO';
import { ResponseB4AListLoggerUpdatedShippingCompanyRangeDTO } from '../model/responseB4AListLoggerUpdatedShippingCompanyRangeDTO';
import { ResponseB4AListSendFailure } from '../model/responseB4AListSendFailure';
import { ResponseB4AListServiceCompanyRangeDTO } from '../model/responseB4AListServiceCompanyRangeDTO';
import { ResponseB4AListShippingCompany } from '../model/responseB4AListShippingCompany';
import { ResponseB4AListShippingCompanyRangeDTO } from '../model/responseB4AListShippingCompanyRangeDTO';
import { ResponseB4AListShippingService } from '../model/responseB4AListShippingService';
import { ResponseB4AListStateDTO } from '../model/responseB4AListStateDTO';
import { ResponseB4AListWeightRangeServiceCompanyRange } from '../model/responseB4AListWeightRangeServiceCompanyRange';
import { ResponseB4AListWeightRangeShippingCompanyRange } from '../model/responseB4AListWeightRangeShippingCompanyRange';
import { ResponseB4APendingExportOrder } from '../model/responseB4APendingExportOrder';
import { ResponseB4AString } from '../model/responseB4AString';

import { Configuration } from '../configuration';
import { BASE_PATH } from '../variables';

@Injectable()
export class IntegrationControllerService {
  protected basePath = 'https://admin-api-stage.b4a.com.br';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Interrompe atualização da tabela de frete (ativação)
   * Este endpoint interrompe o processo de atualização da tabela de frete (ativação).
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public abortUpdateServiceCompanyRange(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AString>;
  public abortUpdateServiceCompanyRange(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AString>>;
  public abortUpdateServiceCompanyRange(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AString>>;
  public abortUpdateServiceCompanyRange(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AString>(
      'post',
      `${this.basePath}/integration/delivery/abort-update-service-company-range`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Interrompe atualização da tabela de frete (aquisição)
   * Este endpoint interrompe o processo de atualização da tabela de frete (aquisição).
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public abortUpdateShippingCompanyRange(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AString>;
  public abortUpdateShippingCompanyRange(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AString>>;
  public abortUpdateShippingCompanyRange(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AString>>;
  public abortUpdateShippingCompanyRange(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AString>(
      'post',
      `${this.basePath}/integration/delivery/abort-update-shipping-company-range`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Recomeça a busca e persistência em homologação da nova tabela de frete (ativação)
   * Este endpoint recomeça a busca na TSimples a nova tabela de frete e grava a mesma em homologação (ativação).
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public continueSyncServiceCompanyRange(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AString>;
  public continueSyncServiceCompanyRange(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AString>>;
  public continueSyncServiceCompanyRange(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AString>>;
  public continueSyncServiceCompanyRange(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AString>(
      'post',
      `${this.basePath}/integration/delivery/continue-sync-service-company-range`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Recomeça a busca e persistência em homologação da nova tabela de frete (aquisição)
   * Este endpoint recomeça a busca na TSimples a nova tabela de frete e grava a mesma em homologação (aquisição).
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public continueSyncShippingCompanyRange(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AString>;
  public continueSyncShippingCompanyRange(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AString>>;
  public continueSyncShippingCompanyRange(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AString>>;
  public continueSyncShippingCompanyRange(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AString>(
      'post',
      `${this.basePath}/integration/delivery/continue-sync-shipping-company-range`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Obtém uma lista de pedidos com falhas na exportação
   * Este endpoint retorna uma lista com pedidos que falharam no processo de exportação.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findAllFailExport(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListSendFailure>;
  public findAllFailExport(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListSendFailure>>;
  public findAllFailExport(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListSendFailure>>;
  public findAllFailExport(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListSendFailure>(
      'get',
      `${this.basePath}/integration/admin/fail-export`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Obtém uma lista dos ranges de peso (ativação)
   * Este endpoint retorna uma lista com os ranges de peso das transportadoras (ativação).
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findAllWeightRangeServiceCompanyRange(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListWeightRangeServiceCompanyRange>;
  public findAllWeightRangeServiceCompanyRange(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListWeightRangeServiceCompanyRange>>;
  public findAllWeightRangeServiceCompanyRange(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListWeightRangeServiceCompanyRange>>;
  public findAllWeightRangeServiceCompanyRange(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListWeightRangeServiceCompanyRange>(
      'get',
      `${this.basePath}/integration/delivery/weight-range-service-company-range`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Obtém uma lista dos ranges de peso (aquisição)
   * Este endpoint retorna uma lista com os ranges de peso das transportadoras (aquisição).
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findAllWeightRangeShippingCompanyRange(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListWeightRangeShippingCompanyRange>;
  public findAllWeightRangeShippingCompanyRange(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListWeightRangeShippingCompanyRange>>;
  public findAllWeightRangeShippingCompanyRange(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListWeightRangeShippingCompanyRange>>;
  public findAllWeightRangeShippingCompanyRange(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListWeightRangeShippingCompanyRange>(
      'get',
      `${this.basePath}/integration/delivery/weight-range-shipping-company-range`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Obtém uma lista de pedidos exportados por dia referente ao mês informado
   * Este endpoint retorna uma lista com o quantitativo de pedidos exportados por dia referente ao mês informado.
   * @param dateInit
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findDailyOrderCountByMonth(
    dateInit: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListDailyOrderCountByMonth>;
  public findDailyOrderCountByMonth(
    dateInit: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListDailyOrderCountByMonth>>;
  public findDailyOrderCountByMonth(
    dateInit: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListDailyOrderCountByMonth>>;
  public findDailyOrderCountByMonth(
    dateInit: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (dateInit === null || dateInit === undefined) {
      throw new Error(
        'Required parameter dateInit was null or undefined when calling findDailyOrderCountByMonth.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (dateInit !== undefined && dateInit !== null) {
      queryParameters = queryParameters.set('dateInit', <any>dateInit);
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListDailyOrderCountByMonth>(
      'get',
      `${this.basePath}/integration/admin/daily-order-of-month`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Obtém um totalizador de pedidos exportados para o mês informado
   * Este endpoint retorna um único objeto com totalizadores referentes a pedidos exportados no mês informado.
   * @param dateInit
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findExportedOrderTotal(
    dateInit: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AExportedOrderTotal>;
  public findExportedOrderTotal(
    dateInit: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AExportedOrderTotal>>;
  public findExportedOrderTotal(
    dateInit: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AExportedOrderTotal>>;
  public findExportedOrderTotal(
    dateInit: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (dateInit === null || dateInit === undefined) {
      throw new Error(
        'Required parameter dateInit was null or undefined when calling findExportedOrderTotal.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (dateInit !== undefined && dateInit !== null) {
      queryParameters = queryParameters.set('dateInit', <any>dateInit);
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AExportedOrderTotal>(
      'get',
      `${this.basePath}/integration/admin/exported-order-total`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Obtém uma lista das atualizações da tabela de frete (ativação)
   * Este endpoint retorna uma lista com o histórico de atualizações de tabela de frete (ativação).
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findLoggerUpdateServiceCompanyRange(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListLoggerUpdatedServiceCompanyRangeDTO>;
  public findLoggerUpdateServiceCompanyRange(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<
    HttpResponse<ResponseB4AListLoggerUpdatedServiceCompanyRangeDTO>
  >;
  public findLoggerUpdateServiceCompanyRange(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListLoggerUpdatedServiceCompanyRangeDTO>>;
  public findLoggerUpdateServiceCompanyRange(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListLoggerUpdatedServiceCompanyRangeDTO>(
      'get',
      `${this.basePath}/integration/delivery/logger-update-service-company-range`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Obtém uma lista das atualizações da tabela de frete (aquisição)
   * Este endpoint retorna uma lista com o histórico de atualizações de tabela de frete (aquisição).
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findLoggerUpdateShippingCompanyRange(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListLoggerUpdatedShippingCompanyRangeDTO>;
  public findLoggerUpdateShippingCompanyRange(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<
    HttpResponse<ResponseB4AListLoggerUpdatedShippingCompanyRangeDTO>
  >;
  public findLoggerUpdateShippingCompanyRange(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListLoggerUpdatedShippingCompanyRangeDTO>>;
  public findLoggerUpdateShippingCompanyRange(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListLoggerUpdatedShippingCompanyRangeDTO>(
      'get',
      `${this.basePath}/integration/delivery/logger-update-shipping-company-range`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Obtém um totalizador de pedidos pendentes para exportação
   * Este endpoint retorna um único objeto com totalizadores referentes a pedidos pendentes de exportação.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findTotalPendingExport(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4APendingExportOrder>;
  public findTotalPendingExport(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4APendingExportOrder>>;
  public findTotalPendingExport(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4APendingExportOrder>>;
  public findTotalPendingExport(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4APendingExportOrder>(
      'get',
      `${this.basePath}/integration/admin/pending-export`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Filtra ranges de CEPs persistidos (ativação)
   * Este endpoint busca os ranges de CEPs gravados (ativação).
   * @param shippingServiceId
   * @param zipStart
   * @param zipEnd
   * @param locale
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getServiceCompanyRanges(
    shippingServiceId: number,
    zipStart: number,
    zipEnd: number,
    locale: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListServiceCompanyRangeDTO>;
  public getServiceCompanyRanges(
    shippingServiceId: number,
    zipStart: number,
    zipEnd: number,
    locale: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListServiceCompanyRangeDTO>>;
  public getServiceCompanyRanges(
    shippingServiceId: number,
    zipStart: number,
    zipEnd: number,
    locale: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListServiceCompanyRangeDTO>>;
  public getServiceCompanyRanges(
    shippingServiceId: number,
    zipStart: number,
    zipEnd: number,
    locale: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (shippingServiceId === null || shippingServiceId === undefined) {
      throw new Error(
        'Required parameter shippingServiceId was null or undefined when calling getServiceCompanyRanges.'
      );
    }

    if (zipStart === null || zipStart === undefined) {
      throw new Error(
        'Required parameter zipStart was null or undefined when calling getServiceCompanyRanges.'
      );
    }

    if (zipEnd === null || zipEnd === undefined) {
      throw new Error(
        'Required parameter zipEnd was null or undefined when calling getServiceCompanyRanges.'
      );
    }

    if (locale === null || locale === undefined) {
      throw new Error(
        'Required parameter locale was null or undefined when calling getServiceCompanyRanges.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (shippingServiceId !== undefined && shippingServiceId !== null) {
      queryParameters = queryParameters.set(
        'shippingServiceId',
        <any>shippingServiceId
      );
    }
    if (zipStart !== undefined && zipStart !== null) {
      queryParameters = queryParameters.set('zipStart', <any>zipStart);
    }
    if (zipEnd !== undefined && zipEnd !== null) {
      queryParameters = queryParameters.set('zipEnd', <any>zipEnd);
    }
    if (locale !== undefined && locale !== null) {
      queryParameters = queryParameters.set('locale', <any>locale);
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListServiceCompanyRangeDTO>(
      'get',
      `${this.basePath}/integration/service-ranges`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Busca as transportadoras de assinatura
   * Este endpoint lista todas as transportadoras de assinatura.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getShippingCompanies(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListShippingCompany>;
  public getShippingCompanies(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListShippingCompany>>;
  public getShippingCompanies(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListShippingCompany>>;
  public getShippingCompanies(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListShippingCompany>(
      'get',
      `${this.basePath}/integration/shipping-companies`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Filtra ranges de CEPs persistidos (aquisição)
   * Este endpoint busca os ranges de CEPs gravados (aquisição).
   * @param shippingCompanyId
   * @param zipStart
   * @param zipEnd
   * @param locale
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getShippingCompanyRanges(
    shippingCompanyId: number,
    zipStart: number,
    zipEnd: number,
    locale: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListShippingCompanyRangeDTO>;
  public getShippingCompanyRanges(
    shippingCompanyId: number,
    zipStart: number,
    zipEnd: number,
    locale: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListShippingCompanyRangeDTO>>;
  public getShippingCompanyRanges(
    shippingCompanyId: number,
    zipStart: number,
    zipEnd: number,
    locale: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListShippingCompanyRangeDTO>>;
  public getShippingCompanyRanges(
    shippingCompanyId: number,
    zipStart: number,
    zipEnd: number,
    locale: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (shippingCompanyId === null || shippingCompanyId === undefined) {
      throw new Error(
        'Required parameter shippingCompanyId was null or undefined when calling getShippingCompanyRanges.'
      );
    }

    if (zipStart === null || zipStart === undefined) {
      throw new Error(
        'Required parameter zipStart was null or undefined when calling getShippingCompanyRanges.'
      );
    }

    if (zipEnd === null || zipEnd === undefined) {
      throw new Error(
        'Required parameter zipEnd was null or undefined when calling getShippingCompanyRanges.'
      );
    }

    if (locale === null || locale === undefined) {
      throw new Error(
        'Required parameter locale was null or undefined when calling getShippingCompanyRanges.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (shippingCompanyId !== undefined && shippingCompanyId !== null) {
      queryParameters = queryParameters.set(
        'shippingCompanyId',
        <any>shippingCompanyId
      );
    }
    if (zipStart !== undefined && zipStart !== null) {
      queryParameters = queryParameters.set('zipStart', <any>zipStart);
    }
    if (zipEnd !== undefined && zipEnd !== null) {
      queryParameters = queryParameters.set('zipEnd', <any>zipEnd);
    }
    if (locale !== undefined && locale !== null) {
      queryParameters = queryParameters.set('locale', <any>locale);
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListShippingCompanyRangeDTO>(
      'get',
      `${this.basePath}/integration/shipping-ranges`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Busca os tipos de serviço para loja
   * Este endpoint lista todos os tipos de serviço para loja
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getShippingServices(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListShippingService>;
  public getShippingServices(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListShippingService>>;
  public getShippingServices(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListShippingService>>;
  public getShippingServices(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListShippingService>(
      'get',
      `${this.basePath}/integration/shipping-services`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Busca os estados do Brasil
   * Este endpoint lista todos os estados por sigla do Brasil.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getStates(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListStateDTO>;
  public getStates(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListStateDTO>>;
  public getStates(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListStateDTO>>;
  public getStates(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListStateDTO>(
      'get',
      `${this.basePath}/integration/states`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Deleta e grava tabela de frete em produção com base no backup (aquisição)
   * Este endpoint deleta a tabela de frete e persiste em produção o backup (aquisição).
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public rollback(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AString>;
  public rollback(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AString>>;
  public rollback(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AString>>;
  public rollback(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AString>(
      'post',
      `${this.basePath}/integration/delivery/rollback-shipping-company-range`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Verifica se o backup é valido (aquisição)
   * Este endpoint verifica se o backup disponível é válido (aquisição).
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public rollbackIsValid(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ABoolean>;
  public rollbackIsValid(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ABoolean>>;
  public rollbackIsValid(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ABoolean>>;
  public rollbackIsValid(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4ABoolean>(
      'get',
      `${this.basePath}/integration/delivery/rollback-valid`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Verifica se o backup é valido (ativação)
   * Este endpoint verifica se o backup disponível é válido (ativação).
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public rollbackIsValidServiceCompany(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ABoolean>;
  public rollbackIsValidServiceCompany(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ABoolean>>;
  public rollbackIsValidServiceCompany(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ABoolean>>;
  public rollbackIsValidServiceCompany(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4ABoolean>(
      'get',
      `${this.basePath}/integration/delivery/rollback-service-company-valid`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Deleta e grava tabela de frete em produção com base no backup (ativação)
   * Este endpoint deleta a tabela de frete e persiste em produção o backup (ativação).
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public rollbackServiceCompany(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AString>;
  public rollbackServiceCompany(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AString>>;
  public rollbackServiceCompany(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AString>>;
  public rollbackServiceCompany(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AString>(
      'post',
      `${this.basePath}/integration/delivery/rollback-service-company-range`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Pausa atualização da tabela de frete (ativação)
   * Este endpoint pausa o processo de atualização da tabela de frete (ativação).
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public stopUpdateServiceCompanyRange(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AString>;
  public stopUpdateServiceCompanyRange(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AString>>;
  public stopUpdateServiceCompanyRange(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AString>>;
  public stopUpdateServiceCompanyRange(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AString>(
      'post',
      `${this.basePath}/integration/delivery/stop-update-service-company-range`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Pausa atualização da tabela de frete (aquisição)
   * Este endpoint pausa o processo de atualização da tabela de frete (aquisição).
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public stopUpdateShippingCompanyRange(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AString>;
  public stopUpdateShippingCompanyRange(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AString>>;
  public stopUpdateShippingCompanyRange(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AString>>;
  public stopUpdateShippingCompanyRange(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AString>(
      'post',
      `${this.basePath}/integration/delivery/stop-update-shipping-company-range`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Realiza a busca e persistência em homologação da nova tabela de frete (ativação)
   * Este endpoint busca na TSimples a nova tabela de frete e grava a mesma em homologação (ativação).
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public syncServiceCompanyRange(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AString>;
  public syncServiceCompanyRange(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AString>>;
  public syncServiceCompanyRange(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AString>>;
  public syncServiceCompanyRange(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AString>(
      'post',
      `${this.basePath}/integration/delivery/sync-service-company-range`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Realiza a busca e persistência em homologação da nova tabela de frete (aquisição)
   * Este endpoint busca na TSimples a nova tabela de frete e grava a mesma em homologação (aquisição).
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public syncShippingCompanyRange(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AString>;
  public syncShippingCompanyRange(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AString>>;
  public syncShippingCompanyRange(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AString>>;
  public syncShippingCompanyRange(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AString>(
      'post',
      `${this.basePath}/integration/delivery/sync-shipping-company-range`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Deleta e grava a nova tabela de frete em produção (ativação)
   * Este endpoint deleta a antiga tabela de frete e persiste em produção a nova (ativação).
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateServiceCompanyRange(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AString>;
  public updateServiceCompanyRange(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AString>>;
  public updateServiceCompanyRange(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AString>>;
  public updateServiceCompanyRange(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AString>(
      'post',
      `${this.basePath}/integration/delivery/update-service-company-range`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Deleta e grava a nova tabela de frete em produção (aquisição)
   * Este endpoint deleta a antiga tabela de frete e persiste em produção a nova (aquisição).
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateShippingCompanyRange(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AString>;
  public updateShippingCompanyRange(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AString>>;
  public updateShippingCompanyRange(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AString>>;
  public updateShippingCompanyRange(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AString>(
      'post',
      `${this.basePath}/integration/delivery/update-shipping-company-range`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}

import { Component, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { TreeNode } from 'primeng/api';
import { TreeNodeSelectEvent } from 'primeng/tree';
import { lastValueFrom, map } from 'rxjs';
import {
  BeautyProfileAttribute,
  BeautyProfileControllerService,
  FeedbackAnswer,
  FeedbackControllerService,
  FeedbackForm,
  FeedbackFormDetail,
  FeedbackOption,
  FeedbackQuestion,
  FeedbackQuestionGroup
} from 'src/app/admin-api';
import { TableColumn } from 'src/app/components/table';
import { FeedbackOptionDTO } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-feedback-crossing',
  templateUrl: './feedback-crossing.component.html',
  styleUrl: './feedback-crossing.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class FeedbackCrossingComponent implements OnInit {
  feedbackService: FeedbackControllerService = inject(
    FeedbackControllerService
  );
  beautyProfileService: BeautyProfileControllerService = inject(
    BeautyProfileControllerService
  );
  title: Title = inject(Title);

  beautyProfile: Array<BeautyProfileAttribute>;
  search = new FormControl('');
  forms: Array<FeedbackFormDetail>;
  formsAvailable: Array<FeedbackFormDetail>;
  form = new FormGroup({
    form1: new FormControl<FeedbackForm>(null, Validators.required),
    form2: new FormControl<FeedbackForm>(null, Validators.required),
    question1: new FormControl<TreeNode<FeedbackQuestion>>(
      null,
      Validators.required
    ),
    question2: new FormControl<TreeNode<FeedbackQuestion>>(
      null,
      Validators.required
    ),
    questionId1: new FormControl<number>(null),
    questionId2: new FormControl<number>(null),
    filters: new FormArray([
      new FormGroup({
        filterType: new FormControl<number>(0, Validators.required),
        optionIds: new FormControl<Array<number>>(
          { disabled: true, value: [] },
          [Validators.required, Validators.minLength(1)]
        ),
        form: new FormControl<FeedbackForm>(null, Validators.required),
        question: new FormControl<TreeNode<FeedbackQuestion>>(
          null,
          Validators.required
        ),
        questionId: new FormControl<number>(
          { disabled: true, value: null },
          Validators.required
        )
      })
    ])
  });
  questionsList: {
    [formId: number]: Array<
      FeedbackQuestion & { options?: Array<FeedbackQuestion> }
    >;
  } = {};
  questions: {
    [formId: number]: Array<FeedbackQuestion | FeedbackQuestionGroup>;
  } = {};
  questionItems: {
    [formId: number]: Array<TreeNode<FeedbackQuestion | FeedbackQuestionGroup>>;
  } = {};
  answers: Array<FeedbackAnswer>;
  rows: Array<FeedbackOptionDTO>;
  cols: Array<TableColumn>;
  ready = false;

  async ngOnInit(): Promise<void> {
    this.title.setTitle('Cruzamento de formulários');
    LoaderService.showLoader();
    await this.findBeautyProfile();
    LoaderService.showLoader(false);
  }

  async findBeautyProfile(): Promise<void> {
    try {
      this.beautyProfile = await lastValueFrom(
        this.beautyProfileService
          .findBeautyProfileFormActive()
          .pipe(
            map((data) =>
              data.result.reduce(
                (list: Array<BeautyProfileAttribute>, g) =>
                  (list = list.concat(g.attributes)),
                []
              )
            )
          )
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findFeedbackForms(search: string): Promise<void> {
    try {
      this.forms = await lastValueFrom(
        this.feedbackService
          .findFeedbackFormsTable({
            page: 0,
            pageSize: 5,
            filters: [
              {
                condition: 'contains',
                field: 'formTitle',
                fieldType: 'text',
                value: search
              }
            ],
            sortBy: 'formTitle',
            sortDirection: 'ASC'
          })
          .pipe(map((data) => data.result.content))
      );
      this.formsAvailable = [...this.forms];
      this.formsAvailable.unshift({
        formTitle: 'Perfil de beleza',
        formId: 0
      });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findQuestions(formId: number): Promise<void> {
    try {
      this.questions[formId] = await lastValueFrom(
        this.feedbackService.findFormQuestions(formId).pipe(
          map((data) => {
            return data.result.questions
              .concat(data.result.groups)
              .filter(
                (q: FeedbackQuestion | FeedbackQuestionGroup) =>
                  (q as FeedbackQuestionGroup).feedbackGroupId ||
                  [3, 4].includes((q as FeedbackQuestion).typeId)
              );
          })
        )
      );
      this.questions[formId].sort(
        (q1, q2) => q1.questionOrder - q2.questionOrder
      );
      this.questionsList[formId] = this.questions[formId].reduce((list, q) => {
        if ((q as FeedbackQuestion).questionId) list.push(q);
        else list = list.concat((q as FeedbackQuestionGroup).questions);
        return list;
      }, []);
      this.questionItems[formId] = this.questions[formId].map((q) => {
        (q as FeedbackQuestionGroup)?.questions?.sort(
          (q1, q2) => q1.questionOrder - q2.questionOrder
        );
        return {
          label:
            q.questionOrder +
            '. ' +
            ((q as FeedbackQuestion).title ||
              (q as FeedbackQuestionGroup).feedbackGroupName),
          key:
            (q as FeedbackQuestion).questionId?.toString() ||
            (q as FeedbackQuestionGroup).feedbackGroupId?.toString(),
          children:
            (q as FeedbackQuestionGroup).questions?.map(
              (qq, i) =>
                ({
                  label: i + 1 + '. ' + qq.title,
                  data: qq,
                  leaf: true,
                  key: qq.questionId.toString()
                } as TreeNode<FeedbackQuestion>)
            ) || undefined,
          leaf: !(q as FeedbackQuestionGroup).questions?.length,
          selectable: (q as FeedbackQuestion).questionId > 0,
          data: q
        } as TreeNode<FeedbackQuestion | FeedbackQuestionGroup>;
      });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findOptions(
    question: FeedbackQuestion & { options?: Array<FeedbackOption> }
  ): Promise<void> {
    try {
      question.options = await lastValueFrom(
        this.feedbackService
          .findQuestionOptions(question.questionId)
          .pipe(map((data) => data.result))
      );
      question.options.sort((o1, o2) => o1.optionOrder - o2.optionOrder);
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async questionChange(i: number, $event: TreeNodeSelectEvent): Promise<void> {
    this.ready = false;
    this.form.controls['filters']
      .at(i)
      .controls['questionId'].setValue(Number($event.node.key));
    const question = this.questionsList[
      this.form.value.filters[i].form?.formId
    ].find(
      (q) =>
        (q as FeedbackQuestion).questionId ===
        this.form.value.filters[i].questionId
    );
    if (!question.options?.length) {
      LoaderService.showLoader();
      await this.findOptions(question);
      LoaderService.showLoader(false);
    }
    this.form.controls['filters']?.at(i)?.controls['optionIds']?.reset();
    this.form.controls['filters']?.at(i)?.controls['optionIds']?.enable();
  }

  async crossingQuestionChange(
    formId: number,
    $event: TreeNodeSelectEvent,
    formControlName: 'question1' | 'question2'
  ): Promise<void> {
    this.ready = false;
    this.form
      .get(formControlName.replace('1', 'Id1').replace('2', 'Id2'))
      .setValue(Number($event.node.key));
    const question = this.questionsList[formId].find(
      (q) => (q as FeedbackQuestion).questionId?.toString() === $event.node.key
    );
    if (!question.options?.length) {
      LoaderService.showLoader();
      await this.findOptions(question);
      LoaderService.showLoader(false);
    }
  }

  attributeChange(i: number, attribute: BeautyProfileAttribute): void {
    this.ready = false;
    this.form.controls['filters']
      .at(i)
      .controls['questionId'].setValue(attribute.id);
    this.form.controls['filters'].at(i).controls['optionIds'].enable();
  }

  async formChange(form: FeedbackForm, i?: number): Promise<void> {
    this.ready = false;
    if (!form && i > 0) {
      this.form.controls['filters']?.at(i)?.reset();
      this.form.controls['filters']?.at(i)?.controls['question']?.disable();
      this.form.controls['filters']?.at(i)?.controls['questionId']?.disable();
      this.form.controls['filters']?.at(i)?.controls['optionIds']?.disable();
      return;
    } else if (!form && i === 0) {
      this.removeFilter(i);
      return;
    }
    if (i >= 0) {
      this.form.controls['filters']
        .at(i)
        .controls['filterType'].setValue(form.formId ? 1 : 0);
      if (!this.questionsList[form.formId]?.length) {
        LoaderService.showLoader();
        await this.findQuestions(form.formId);
        LoaderService.showLoader(false);
      }
      this.form.controls['filters']?.at(i)?.controls['question']?.enable();
      this.form.controls['filters']?.at(i)?.controls['questionId']?.enable();
      this.form.controls['filters']?.at(i)?.controls['optionIds']?.disable();
    } else {
      if (!this.form.value.form1?.formId)
        this.form.controls['form1'].setValue(form);
      else if (!this.form.value.form2?.formId)
        this.form.controls['form2'].setValue(form);
      if (!this.questions[form.formId]?.length) {
        LoaderService.showLoader();
        await this.findQuestions(form?.formId);
        LoaderService.showLoader(false);
      }
    }
  }

  addFilter(): void {
    this.form.controls['filters'].push(
      new FormGroup({
        filterType: new FormControl(null, Validators.required),
        form: new FormControl(null, Validators.required),
        optionIds: new FormControl({ disabled: true, value: [] }, [
          Validators.required,
          Validators.minLength(1)
        ]),
        questionId: new FormControl(
          { disabled: true, value: null },
          Validators.required
        ),
        question: new FormControl(
          { disabled: true, value: null },
          Validators.required
        )
      })
    );
  }

  removeFilter(i: number): void {
    if (this.form.controls['filters'].at(i).valid) this.ready = false;
    this.form.controls['filters'].removeAt(i);
  }

  async submit(): Promise<void> {
    if (this.form.value.questionId1 === this.form.value.questionId2) {
      this.form.controls['question2'].setErrors({ sameQuestion: 1 });
      return;
    }
    this.ready = false;
    LoaderService.showLoader();
    try {
      this.answers = await lastValueFrom(
        this.feedbackService
          .feedbackQuestionCrossing({
            questionId1: this.form.value.questionId1,
            questionId2: this.form.value.questionId2,
            filters: this.form.value.filters
              .filter((f) => f.form?.formId >= 0)
              .map((f) => ({
                filterType: f.filterType,
                optionIds: f.optionIds
              }))
          })
          .pipe(map((data) => data.result))
      );
      this.rows = this.question1.options.map((o) => {
        const answers = this.answers.filter((a) => a.optionId === o.optionId);
        return {
          personIds: answers.map((a) => a.personId),
          optionId: o.optionId,
          optionText: o.optionText,
          answers: answers.length,
          percentage: answers.length / (this.question1Answers?.length || 1)
        };
      });
      this.rows.push({
        optionId: Number.MAX_VALUE,
        optionText: 'Total',
        answers: this.question1Answers.length,
        percentage: 1,
        personIds: this.question1Answers.map((a) => a.personId)
      });
      this.rows.forEach((r) => {
        this.question2.options.forEach((o) => {
          const answers = this.answers.filter(
            (a) => a.optionId === o.optionId && r.personIds.includes(a.personId)
          );
          r[o.optionId] = answers.length;
          r[o.optionId + '-percentage'] =
            answers.length / (r.personIds?.length || 1);
        });
      });
      this.cols = [
        new TableColumn('Alternativa', 'optionText', false, 'text'),
        new TableColumn('Assinantes', 'answers', false, 'formattedInteger'),
        new TableColumn('%', 'percentage', false, 'percentage')
      ];
      this.question2?.options.forEach((o) => {
        this.cols.push(
          new TableColumn(
            o.optionText,
            o.optionId.toString(),
            false,
            'formattedInteger'
          )
        );
        this.cols.push(
          new TableColumn(
            o.optionText + ' %',
            o.optionId + '-percentage',
            false,
            'percentage'
          )
        );
      });
      this.ready = true;
      LoaderService.showLoader(false);
    } catch (error) {
      LoaderService.showLoader(false);
      AppDialogService.showErrorDialog(error);
    }
  }

  question(
    i: number
  ):
    | (FeedbackQuestion & { options?: Array<FeedbackOption> })
    | BeautyProfileAttribute {
    return this.form.value.filters[i].form?.formId
      ? this.questionsList[this.form.value.filters[i].form?.formId]?.find(
          (q) =>
            (q as FeedbackQuestion).questionId ===
            this.form.value.filters[i].questionId
        )
      : this.form.value.filters[i].form?.formId === 0
      ? this.beautyProfile.find(
          (a) => a.id === this.form.value.filters[i].questionId
        )
      : null;
  }

  clearForm1(): void {
    this.form.controls['form1'].reset();
    this.form.controls['question1'].reset();
    this.form.controls['questionId1'].reset();
  }

  clearForm2(): void {
    this.form.controls['form2'].reset();
    this.form.controls['question2'].reset();
    this.form.controls['questionId2'].reset();
  }

  get valid(): boolean {
    return (
      this.form.valid ||
      (this.form.controls['form1'].valid &&
        this.form.controls['form2'].valid &&
        this.form.controls['questionId1'].valid &&
        this.form.controls['questionId2'].valid &&
        this.form.value.filters.every((f) => !f.form))
    );
  }

  get question1(): FeedbackQuestion & { options?: Array<FeedbackOption> } {
    return this.form.value.form1?.formId && this.form.value.question1.key
      ? this.questionsList[this.form.value.form1?.formId].find(
          (q) =>
            (q as FeedbackQuestion).questionId?.toString() ===
            this.form.value.question1.key
        )
      : null;
  }

  get question2(): FeedbackQuestion & { options?: Array<FeedbackOption> } {
    return this.form.value.form2?.formId && this.form.value.question2.key
      ? this.questionsList[this.form.value.form2?.formId].find(
          (q) =>
            (q as FeedbackQuestion).questionId?.toString() ===
            this.form.value.question2.key
        )
      : null;
  }

  get question1Answers(): Array<FeedbackAnswer> {
    return this.answers.filter((a) =>
      this.question1.options.some((o) => o.optionId === a.optionId)
    );
  }

  get question2Answers(): Array<FeedbackAnswer> {
    return this.answers.filter((a) =>
      this.question2.options.some((o) => o.optionId === a.optionId)
    );
  }

  get crossingName(): string {
    return this.form.value.form1?.formId === this.form.value.form2?.formId
      ? this.form.value.form1.formTitle
      : `${this.form.value.form1.formTitle} x ${this.form.value.form2.formTitle}`;
  }
}

<app-table
  [lazy]="true"
  [cols]="columns"
  tableTitle="Billing Pendente Incorreta"
  [addButton]="false"
  emptyMessage="Sem mais billings pendentes incorretas no momento."
  [exportPdf]="false"
  [exportExcel]="false"
  pageFunctionName="getIncorrectPendingBillingTable"
  [service]="itOpsService"
  modelName="recurrences"
  sortField="subscriberId"
  [sortOrder]="-1"
  selectionActionLabel="Corrigir"
  selectionActionTooltip="Ações para corrigir os billing selecionados"
  (emitSelection)="openActionModal($event)"
  *ngIf="ready"
></app-table>
<p-toast></p-toast>

export enum KeyExportDescriptionEnum {
  totalEdition = 'Pedidos de Edição',
  totalGift = 'Pedidos de Brinde',
  totalTrade = 'Pedidos de Troca',
  totalResend = 'Pedidos de Reenvio',
  totalInfluencer = 'Pedidos de Influencer',
  totalIncentive = 'Pedidos de Incentivo',
  totalBfluenceAward = 'Pedidos de Premiação Bfluence'
}

export function getDescriptionByKey(key: string): string {
  return KeyExportDescriptionEnum[key as keyof typeof KeyExportDescriptionEnum];
}

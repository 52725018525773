<form class="modal-form" (submit)="submit()" [formGroup]="form">
  <span class="p-float-label">
    <input
      type="text"
      pInputText
      formControlName="optionText"
      [autofocus]="true"
      pAutoFocus
      name="optionText"
    />
    <label for="optionText">Texto da alternativa *</label>
  </span>
  <span class="p-float-label">
    <p-dropdown
      [options]="optionTypes"
      formControlName="optionType"
      inputId="optionType"
      [autoDisplayFirst]="false"
      appendTo="body"
    >
      <ng-template pTemplate="item" let-type>
        <span
          class="tooltip-item"
          [pTooltip]="type.description"
          tooltipPosition="right"
          >{{ type.label }}</span
        >
      </ng-template>
    </p-dropdown>
    <label for="optionType">Tipo de alternativa *</label>
  </span>
  <span class="p-float-label" *ngIf="questions?.length && !question?.groupId">
    <p-dropdown
      [options]="questions"
      formControlName="nextQuestionId"
      inputId="nextQuestionId"
      appendTo="body"
      [filter]="true"
      filterBy="title,questionOrder"
      optionValue="questionId"
      placeholder="Selecione"
    >
      <ng-template pTemplate="item" let-question>
        <ng-container *ngIf="question.questionOrder">
          {{ question.questionOrder }}. </ng-container
        >{{ question.title }}
      </ng-template>
      <ng-template pTemplate="selectedItem">
        <ng-container *ngIf="!nextQuestion?.questionId">Nenhuma</ng-container>
        <ng-container *ngIf="nextQuestion?.questionId">
          <ng-container *ngIf="nextQuestion.questionOrder">
            {{ nextQuestion.questionOrder }}. </ng-container
          >{{ nextQuestion.title }}
        </ng-container>
      </ng-template>
    </p-dropdown>
    <label for="nextQuestionId">Próxima pergunta</label>
  </span>
  <span class="p-float-label">
    <p-dropdown
      [options]="displayTypes"
      formControlName="displayType"
      appendTo="body"
      [autoDisplayFirst]="false"
      inputId="displayType"
      (onChange)="changeDisplayType()"
    ></p-dropdown>
    <label for="displayType">Exibição (em breve)*</label>
  </span>
  <ng-container *ngIf="form.controls['mediaUrl'].enabled">
    <span class="question-media" *ngIf="form.controls['mediaUrl'].valid">
      <img [src]="form.value.mediaUrl" />
    </span>
    <div class="file-upload">
      <span class="p-float-label">
        <input type="text" pInputText formControlName="mediaUrl" />
        <label for="mediaUrl">Mídia</label>
        <p-fileUpload
          mode="basic"
          chooseLabel="Selecionar"
          name="file"
          [auto]="true"
          accept="image/*"
          [maxFileSize]="1000000"
          uploadIcon="pi pi-file"
          [customUpload]="true"
          (uploadHandler)="onUpload($event, 'mediaUrl', fileUpload)"
          id="mediaUrl"
          #fileUpload
        ></p-fileUpload>
      </span>
      <small
        *ngIf="!form.controls['mediaUrl'].errors?.invalid"
        [class.danger]="
          form.controls['mediaUrl'].touched &&
          form.controls['mediaUrl'].dirty &&
          form.controls['mediaUrl'].errors
        "
      >
        * Informe um link ou faça upload de uma imagem
      </small>
      <small class="danger" *ngIf="form.controls['mediaUrl'].errors?.invalid">
        * O link informado não é uma imagem
      </small>
    </div>
  </ng-container>
  <ng-container *ngIf="form.controls['iconOutlinedUrl'].enabled">
    <span class="question-media" *ngIf="form.controls['iconOutlinedUrl'].valid">
      <img [src]="form.value.iconOutlinedUrl" />
    </span>
    <div class="file-upload">
      <span class="p-float-label">
        <input type="text" pInputText formControlName="iconOutlinedUrl" />
        <label for="iconOutlinedUrl">Ícone vazio *</label>
        <p-fileUpload
          mode="basic"
          chooseLabel="Selecionar"
          name="file"
          [auto]="true"
          accept="image/*"
          [maxFileSize]="1000000"
          uploadIcon="pi pi-file"
          [customUpload]="true"
          (uploadHandler)="onUpload($event, 'iconOutlinedUrl', fileUpload)"
          id="iconOutlinedUrl"
          #fileUpload
        ></p-fileUpload>
      </span>
      <small
        *ngIf="!form.controls['iconOutlinedUrl'].errors?.invalid"
        [class.danger]="
          form.controls['iconOutlinedUrl'].touched &&
          form.controls['iconOutlinedUrl'].dirty &&
          form.controls['iconOutlinedUrl'].errors
        "
      >
        * Informe um link ou faça upload de uma imagem
      </small>
      <small
        class="danger"
        *ngIf="form.controls['iconOutlinedUrl'].errors?.invalid"
      >
        * O link informado não é uma imagem
      </small>
    </div>
  </ng-container>
  <ng-container *ngIf="form.controls['iconFilledUrl'].enabled">
    <span class="question-media" *ngIf="form.controls['iconFilledUrl'].valid">
      <img [src]="form.value.iconFilledUrl" />
    </span>
    <div class="file-upload">
      <span class="p-float-label">
        <input type="text" pInputText formControlName="iconFilledUrl" />
        <label for="iconFilledUrl">Ícone selecionado *</label>
        <p-fileUpload
          mode="basic"
          chooseLabel="Selecionar"
          name="file"
          [auto]="true"
          accept="image/*"
          [maxFileSize]="1000000"
          uploadIcon="pi pi-file"
          [customUpload]="true"
          (uploadHandler)="onUpload($event, 'iconFilledUrl', fileUpload)"
          id="iconFilledUrl"
          #fileUpload
        ></p-fileUpload>
      </span>
      <small
        *ngIf="!form.controls['iconFilledUrl'].errors?.invalid"
        [class.danger]="
          form.controls['iconFilledUrl'].touched &&
          form.controls['iconFilledUrl'].dirty &&
          form.controls['iconFilledUrl'].errors
        "
      >
        * Informe um link ou faça upload de uma imagem
      </small>
      <small
        class="danger"
        *ngIf="form.controls['iconFilledUrl'].errors?.invalid"
      >
        * O link informado não é uma imagem
      </small>
    </div>
  </ng-container>
  <span class="input-switch">
    <label for="enabled">Inativo</label>
    <p-inputSwitch formControlName="enabled" inputId="enabled"></p-inputSwitch>
    <label for="enabled">Ativo</label>
  </span>
  <div class="buttons">
    <p-button
      type="button"
      label="Voltar"
      (onClick)="ref.close()"
      severity="danger"
    ></p-button>
    <p-button
      type="submit"
      [disabled]="!form.valid"
      label="Salvar"
      severity="primary"
    ></p-button>
  </div>
</form>

/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpResponse
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { ModifyReviewsRequest } from '../model/modifyReviewsRequest';
import { PageableRequest } from '../model/pageableRequest';
import { ProductCategoryCreateRequest } from '../model/productCategoryCreateRequest';
import { ProductCategoryUpdateRequest } from '../model/productCategoryUpdateRequest';
import { ProductCreateRequest } from '../model/productCreateRequest';
import { ProductImageProductVariantUpdateRequest } from '../model/productImageProductVariantUpdateRequest';
import { ProductImageRequest } from '../model/productImageRequest';
import { ProductUpdateRequest } from '../model/productUpdateRequest';
import { ProductVariantCreateRequest } from '../model/productVariantCreateRequest';
import { ProductVariantUpdateRequest } from '../model/productVariantUpdateRequest';
import { ResponseB4AListFeedbackLLMResponse } from '../model/responseB4AListFeedbackLLMResponse';
import { ResponseB4AListProductCategory } from '../model/responseB4AListProductCategory';
import { ResponseB4AListProductFinality } from '../model/responseB4AListProductFinality';
import { ResponseB4AListProductFinalityItem } from '../model/responseB4AListProductFinalityItem';
import { ResponseB4AListProductImage } from '../model/responseB4AListProductImage';
import { ResponseB4AListProductReviewId } from '../model/responseB4AListProductReviewId';
import { ResponseB4AListProductSummaryItem } from '../model/responseB4AListProductSummaryItem';
import { ResponseB4AListProductVariant } from '../model/responseB4AListProductVariant';
import { ResponseB4AListProductVariantBrand } from '../model/responseB4AListProductVariantBrand';
import { ResponseB4AListProductVariantCategoryDetail } from '../model/responseB4AListProductVariantCategoryDetail';
import { ResponseB4AListProductVariantSummary } from '../model/responseB4AListProductVariantSummary';
import { ResponseB4AListProductVariantTotals } from '../model/responseB4AListProductVariantTotals';
import { ResponseB4AListProductVariantTotalsByDate } from '../model/responseB4AListProductVariantTotalsByDate';
import { ResponseB4APageProductCategorySummaryDTO } from '../model/responseB4APageProductCategorySummaryDTO';
import { ResponseB4APageProductReviewDetailed } from '../model/responseB4APageProductReviewDetailed';
import { ResponseB4APageProductSummaryItem } from '../model/responseB4APageProductSummaryItem';
import { ResponseB4APageProductVariantDetail } from '../model/responseB4APageProductVariantDetail';
import { ResponseB4AProduct } from '../model/responseB4AProduct';
import { ResponseB4AProductCategory } from '../model/responseB4AProductCategory';
import { ResponseB4AProductFinality } from '../model/responseB4AProductFinality';
import { ResponseB4AProductImage } from '../model/responseB4AProductImage';
import { ResponseB4AProductVariant } from '../model/responseB4AProductVariant';
import { ResponseB4AProductVariantAttribute } from '../model/responseB4AProductVariantAttribute';
import { ResponseB4AString } from '../model/responseB4AString';
import { ShopReviewLLMRequest } from '../model/shopReviewLLMRequest';

import { Configuration } from '../configuration';
import { BASE_PATH } from '../variables';

@Injectable()
export class ProductControllerService {
  protected basePath = 'https://admin-api-stage.b4a.com.br';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   *
   * @param personId
   * @param productId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public acceptProductReview(
    personId: number,
    productId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AString>;
  public acceptProductReview(
    personId: number,
    productId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AString>>;
  public acceptProductReview(
    personId: number,
    productId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AString>>;
  public acceptProductReview(
    personId: number,
    productId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (personId === null || personId === undefined) {
      throw new Error(
        'Required parameter personId was null or undefined when calling acceptProductReview.'
      );
    }

    if (productId === null || productId === undefined) {
      throw new Error(
        'Required parameter productId was null or undefined when calling acceptProductReview.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AString>(
      'put',
      `${this.basePath}/product/review/${encodeURIComponent(
        String(personId)
      )}/accept/${encodeURIComponent(String(productId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addProductFinality(
    body: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AProductFinality>;
  public addProductFinality(
    body: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AProductFinality>>;
  public addProductFinality(
    body: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AProductFinality>>;
  public addProductFinality(
    body: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling addProductFinality.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AProductFinality>(
      'post',
      `${this.basePath}/product/finality`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addProductImage(
    body: ProductImageRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AProductImage>;
  public addProductImage(
    body: ProductImageRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AProductImage>>;
  public addProductImage(
    body: ProductImageRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AProductImage>>;
  public addProductImage(
    body: ProductImageRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling addProductImage.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AProductImage>(
      'post',
      `${this.basePath}/product/image`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createProduct(
    body: ProductCreateRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AProduct>;
  public createProduct(
    body: ProductCreateRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AProduct>>;
  public createProduct(
    body: ProductCreateRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AProduct>>;
  public createProduct(
    body: ProductCreateRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling createProduct.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AProduct>(
      'post',
      `${this.basePath}/product/`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createProductCategory(
    body: ProductCategoryCreateRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AProductCategory>;
  public createProductCategory(
    body: ProductCategoryCreateRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AProductCategory>>;
  public createProductCategory(
    body: ProductCategoryCreateRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AProductCategory>>;
  public createProductCategory(
    body: ProductCategoryCreateRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling createProductCategory.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AProductCategory>(
      'post',
      `${this.basePath}/product/category/allocation`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createProductVariant(
    body: ProductVariantCreateRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AProductVariant>;
  public createProductVariant(
    body: ProductVariantCreateRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AProductVariant>>;
  public createProductVariant(
    body: ProductVariantCreateRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AProductVariant>>;
  public createProductVariant(
    body: ProductVariantCreateRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling createProductVariant.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AProductVariant>(
      'post',
      `${this.basePath}/product/variant`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param personId
   * @param productId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public denyProductReview(
    personId: number,
    productId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AString>;
  public denyProductReview(
    personId: number,
    productId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AString>>;
  public denyProductReview(
    personId: number,
    productId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AString>>;
  public denyProductReview(
    personId: number,
    productId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (personId === null || personId === undefined) {
      throw new Error(
        'Required parameter personId was null or undefined when calling denyProductReview.'
      );
    }

    if (productId === null || productId === undefined) {
      throw new Error(
        'Required parameter productId was null or undefined when calling denyProductReview.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AString>(
      'put',
      `${this.basePath}/product/review/${encodeURIComponent(
        String(personId)
      )}/deny/${encodeURIComponent(String(productId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findAllProductFinalities(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListProductFinality>;
  public findAllProductFinalities(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListProductFinality>>;
  public findAllProductFinalities(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListProductFinality>>;
  public findAllProductFinalities(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListProductFinality>(
      'get',
      `${this.basePath}/product/finality`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param brandId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findBrandProducts(
    brandId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListProductSummaryItem>;
  public findBrandProducts(
    brandId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListProductSummaryItem>>;
  public findBrandProducts(
    brandId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListProductSummaryItem>>;
  public findBrandProducts(
    brandId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (brandId === null || brandId === undefined) {
      throw new Error(
        'Required parameter brandId was null or undefined when calling findBrandProducts.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListProductSummaryItem>(
      'get',
      `${this.basePath}/product/brand/${encodeURIComponent(String(brandId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param productId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findImagesByProductId(
    productId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListProductImage>;
  public findImagesByProductId(
    productId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListProductImage>>;
  public findImagesByProductId(
    productId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListProductImage>>;
  public findImagesByProductId(
    productId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (productId === null || productId === undefined) {
      throw new Error(
        'Required parameter productId was null or undefined when calling findImagesByProductId.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListProductImage>(
      'get',
      `${this.basePath}/product/image/${encodeURIComponent(
        String(productId)
      )}/list`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param productVariantId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findImagesByProductVariantId(
    productVariantId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListProductImage>;
  public findImagesByProductVariantId(
    productVariantId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListProductImage>>;
  public findImagesByProductVariantId(
    productVariantId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListProductImage>>;
  public findImagesByProductVariantId(
    productVariantId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (productVariantId === null || productVariantId === undefined) {
      throw new Error(
        'Required parameter productVariantId was null or undefined when calling findImagesByProductVariantId.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListProductImage>(
      'get',
      `${this.basePath}/product/variant/image/${encodeURIComponent(
        String(productVariantId)
      )}/list`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param productId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findProductById(
    productId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AProduct>;
  public findProductById(
    productId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AProduct>>;
  public findProductById(
    productId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AProduct>>;
  public findProductById(
    productId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (productId === null || productId === undefined) {
      throw new Error(
        'Required parameter productId was null or undefined when calling findProductById.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AProduct>(
      'get',
      `${this.basePath}/product/id/${encodeURIComponent(String(productId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findProductCategoriesList(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListProductCategory>;
  public findProductCategoriesList(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListProductCategory>>;
  public findProductCategoriesList(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListProductCategory>>;
  public findProductCategoriesList(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListProductCategory>(
      'get',
      `${this.basePath}/product/category`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findProductCategoriesTable(
    body: PageableRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4APageProductCategorySummaryDTO>;
  public findProductCategoriesTable(
    body: PageableRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4APageProductCategorySummaryDTO>>;
  public findProductCategoriesTable(
    body: PageableRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4APageProductCategorySummaryDTO>>;
  public findProductCategoriesTable(
    body: PageableRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling findProductCategoriesTable.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4APageProductCategorySummaryDTO>(
      'put',
      `${this.basePath}/product/category/table`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param categoryId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findProductCategoryById(
    categoryId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AProductCategory>;
  public findProductCategoryById(
    categoryId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AProductCategory>>;
  public findProductCategoryById(
    categoryId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AProductCategory>>;
  public findProductCategoryById(
    categoryId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (categoryId === null || categoryId === undefined) {
      throw new Error(
        'Required parameter categoryId was null or undefined when calling findProductCategoryById.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AProductCategory>(
      'get',
      `${this.basePath}/product/category/id/${encodeURIComponent(
        String(categoryId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param productId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findProductCategoryByProductId(
    productId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AProductCategory>;
  public findProductCategoryByProductId(
    productId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AProductCategory>>;
  public findProductCategoryByProductId(
    productId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AProductCategory>>;
  public findProductCategoryByProductId(
    productId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (productId === null || productId === undefined) {
      throw new Error(
        'Required parameter productId was null or undefined when calling findProductCategoryByProductId.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AProductCategory>(
      'get',
      `${this.basePath}/product/category/${encodeURIComponent(
        String(productId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param categoryId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findProductCategoryProducts(
    categoryId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListProductSummaryItem>;
  public findProductCategoryProducts(
    categoryId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListProductSummaryItem>>;
  public findProductCategoryProducts(
    categoryId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListProductSummaryItem>>;
  public findProductCategoryProducts(
    categoryId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (categoryId === null || categoryId === undefined) {
      throw new Error(
        'Required parameter categoryId was null or undefined when calling findProductCategoryProducts.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListProductSummaryItem>(
      'get',
      `${this.basePath}/product/category/${encodeURIComponent(
        String(categoryId)
      )}/products`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param productId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findProductFinalities(
    productId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListProductFinalityItem>;
  public findProductFinalities(
    productId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListProductFinalityItem>>;
  public findProductFinalities(
    productId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListProductFinalityItem>>;
  public findProductFinalities(
    productId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (productId === null || productId === undefined) {
      throw new Error(
        'Required parameter productId was null or undefined when calling findProductFinalities.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListProductFinalityItem>(
      'get',
      `${this.basePath}/product/finality/${encodeURIComponent(
        String(productId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param productVariantId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findProductVariantById(
    productVariantId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AProductVariant>;
  public findProductVariantById(
    productVariantId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AProductVariant>>;
  public findProductVariantById(
    productVariantId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AProductVariant>>;
  public findProductVariantById(
    productVariantId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (productVariantId === null || productVariantId === undefined) {
      throw new Error(
        'Required parameter productVariantId was null or undefined when calling findProductVariantById.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AProductVariant>(
      'get',
      `${this.basePath}/product/variant/${encodeURIComponent(
        String(productVariantId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param productVariantId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findProductVariantExpirationAttribute(
    productVariantId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AProductVariantAttribute>;
  public findProductVariantExpirationAttribute(
    productVariantId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AProductVariantAttribute>>;
  public findProductVariantExpirationAttribute(
    productVariantId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AProductVariantAttribute>>;
  public findProductVariantExpirationAttribute(
    productVariantId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (productVariantId === null || productVariantId === undefined) {
      throw new Error(
        'Required parameter productVariantId was null or undefined when calling findProductVariantExpirationAttribute.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AProductVariantAttribute>(
      'get',
      `${this.basePath}/product/variant/attribute/${encodeURIComponent(
        String(productVariantId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findProductVariantsForGlamclub(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListProductVariantSummary>;
  public findProductVariantsForGlamclub(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListProductVariantSummary>>;
  public findProductVariantsForGlamclub(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListProductVariantSummary>>;
  public findProductVariantsForGlamclub(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListProductVariantSummary>(
      'get',
      `${this.basePath}/product/variant/glamclub`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findProductVariantsInStock(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListProductVariantSummary>;
  public findProductVariantsInStock(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListProductVariantSummary>>;
  public findProductVariantsInStock(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListProductVariantSummary>>;
  public findProductVariantsInStock(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListProductVariantSummary>(
      'get',
      `${this.basePath}/product/variant/in-stock`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param brandId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findProductVariantsListByBrandId(
    brandId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListProductVariantBrand>;
  public findProductVariantsListByBrandId(
    brandId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListProductVariantBrand>>;
  public findProductVariantsListByBrandId(
    brandId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListProductVariantBrand>>;
  public findProductVariantsListByBrandId(
    brandId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (brandId === null || brandId === undefined) {
      throw new Error(
        'Required parameter brandId was null or undefined when calling findProductVariantsListByBrandId.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListProductVariantBrand>(
      'get',
      `${this.basePath}/product/variant/brand/${encodeURIComponent(
        String(brandId)
      )}/list`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param categoryId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findProductVariantsListByCategoryId(
    categoryId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListProductVariantBrand>;
  public findProductVariantsListByCategoryId(
    categoryId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListProductVariantBrand>>;
  public findProductVariantsListByCategoryId(
    categoryId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListProductVariantBrand>>;
  public findProductVariantsListByCategoryId(
    categoryId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (categoryId === null || categoryId === undefined) {
      throw new Error(
        'Required parameter categoryId was null or undefined when calling findProductVariantsListByCategoryId.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListProductVariantBrand>(
      'get',
      `${this.basePath}/product/variant/category/${encodeURIComponent(
        String(categoryId)
      )}/list`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param productId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findProductVariantsListByProductId(
    productId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListProductVariant>;
  public findProductVariantsListByProductId(
    productId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListProductVariant>>;
  public findProductVariantsListByProductId(
    productId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListProductVariant>>;
  public findProductVariantsListByProductId(
    productId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (productId === null || productId === undefined) {
      throw new Error(
        'Required parameter productId was null or undefined when calling findProductVariantsListByProductId.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListProductVariant>(
      'get',
      `${this.basePath}/product/variant/${encodeURIComponent(
        String(productId)
      )}/list`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findProductVariantsTable(
    body: PageableRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4APageProductVariantDetail>;
  public findProductVariantsTable(
    body: PageableRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4APageProductVariantDetail>>;
  public findProductVariantsTable(
    body: PageableRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4APageProductVariantDetail>>;
  public findProductVariantsTable(
    body: PageableRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling findProductVariantsTable.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4APageProductVariantDetail>(
      'put',
      `${this.basePath}/product/variant/list`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param formId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findProductsByActiveFormId(
    formId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListProductSummaryItem>;
  public findProductsByActiveFormId(
    formId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListProductSummaryItem>>;
  public findProductsByActiveFormId(
    formId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListProductSummaryItem>>;
  public findProductsByActiveFormId(
    formId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (formId === null || formId === undefined) {
      throw new Error(
        'Required parameter formId was null or undefined when calling findProductsByActiveFormId.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListProductSummaryItem>(
      'get',
      `${this.basePath}/product/feedback/${encodeURIComponent(String(formId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findProductsTable(
    body: PageableRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4APageProductSummaryItem>;
  public findProductsTable(
    body: PageableRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4APageProductSummaryItem>>;
  public findProductsTable(
    body: PageableRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4APageProductSummaryItem>>;
  public findProductsTable(
    body: PageableRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling findProductsTable.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4APageProductSummaryItem>(
      'put',
      `${this.basePath}/product/list`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProductReviewDetailed(
    body: PageableRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4APageProductReviewDetailed>;
  public getProductReviewDetailed(
    body: PageableRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4APageProductReviewDetailed>>;
  public getProductReviewDetailed(
    body: PageableRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4APageProductReviewDetailed>>;
  public getProductReviewDetailed(
    body: PageableRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling getProductReviewDetailed.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4APageProductReviewDetailed>(
      'put',
      `${this.basePath}/product/review/detailed`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param dateStart
   * @param dateEnd
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProductVariantsUseInPeriod(
    dateStart: string,
    dateEnd: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListProductVariantTotals>;
  public getProductVariantsUseInPeriod(
    dateStart: string,
    dateEnd: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListProductVariantTotals>>;
  public getProductVariantsUseInPeriod(
    dateStart: string,
    dateEnd: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListProductVariantTotals>>;
  public getProductVariantsUseInPeriod(
    dateStart: string,
    dateEnd: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (dateStart === null || dateStart === undefined) {
      throw new Error(
        'Required parameter dateStart was null or undefined when calling getProductVariantsUseInPeriod.'
      );
    }

    if (dateEnd === null || dateEnd === undefined) {
      throw new Error(
        'Required parameter dateEnd was null or undefined when calling getProductVariantsUseInPeriod.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (dateStart !== undefined && dateStart !== null) {
      queryParameters = queryParameters.set('dateStart', <any>dateStart);
    }
    if (dateEnd !== undefined && dateEnd !== null) {
      queryParameters = queryParameters.set('dateEnd', <any>dateEnd);
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListProductVariantTotals>(
      'get',
      `${this.basePath}/product/stock/used`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param dateStart
   * @param dateEnd
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getStockTotalsByDate(
    dateStart: string,
    dateEnd: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListProductVariantTotalsByDate>;
  public getStockTotalsByDate(
    dateStart: string,
    dateEnd: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListProductVariantTotalsByDate>>;
  public getStockTotalsByDate(
    dateStart: string,
    dateEnd: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListProductVariantTotalsByDate>>;
  public getStockTotalsByDate(
    dateStart: string,
    dateEnd: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (dateStart === null || dateStart === undefined) {
      throw new Error(
        'Required parameter dateStart was null or undefined when calling getStockTotalsByDate.'
      );
    }

    if (dateEnd === null || dateEnd === undefined) {
      throw new Error(
        'Required parameter dateEnd was null or undefined when calling getStockTotalsByDate.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (dateStart !== undefined && dateStart !== null) {
      queryParameters = queryParameters.set('dateStart', <any>dateStart);
    }
    if (dateEnd !== undefined && dateEnd !== null) {
      queryParameters = queryParameters.set('dateEnd', <any>dateEnd);
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListProductVariantTotalsByDate>(
      'get',
      `${this.basePath}/product/stock/totals`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param file
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateBatchProductCategory(
    file?: Blob,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListProductVariantCategoryDetail>;
  public updateBatchProductCategory(
    file?: Blob,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListProductVariantCategoryDetail>>;
  public updateBatchProductCategory(
    file?: Blob,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListProductVariantCategoryDetail>>;
  public updateBatchProductCategory(
    file?: Blob,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['multipart/form-data'];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): void };
    let useForm = false;
    let convertFormParamsToString = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({
        encoder: new CustomHttpUrlEncodingCodec()
      });
    }

    if (file !== undefined) {
      formParams = (formParams.append('file', <any>file) as any) || formParams;
    }

    return this.httpClient.request<ResponseB4AListProductVariantCategoryDetail>(
      'post',
      `${this.basePath}/product/categories/batch`,
      {
        body: convertFormParamsToString ? formParams.toString() : formParams,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public llmReviews(
    body: ModifyReviewsRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListFeedbackLLMResponse>;
  public llmReviews(
    body: ModifyReviewsRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListFeedbackLLMResponse>>;
  public llmReviews(
    body: ModifyReviewsRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListFeedbackLLMResponse>>;
  public llmReviews(
    body: ModifyReviewsRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling llmReviews.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AListFeedbackLLMResponse>(
      'put',
      `${this.basePath}/product/review/modify/llm`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public modifyReviews(
    body: ModifyReviewsRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListProductReviewId>;
  public modifyReviews(
    body: ModifyReviewsRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListProductReviewId>>;
  public modifyReviews(
    body: ModifyReviewsRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListProductReviewId>>;
  public modifyReviews(
    body: ModifyReviewsRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling modifyReviews.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AListProductReviewId>(
      'put',
      `${this.basePath}/product/reviews/modify`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param productImageId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public removeProductImage(
    productImageId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AString>;
  public removeProductImage(
    productImageId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AString>>;
  public removeProductImage(
    productImageId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AString>>;
  public removeProductImage(
    productImageId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (productImageId === null || productImageId === undefined) {
      throw new Error(
        'Required parameter productImageId was null or undefined when calling removeProductImage.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AString>(
      'delete',
      `${this.basePath}/product/image/${encodeURIComponent(
        String(productImageId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public removeProductVariantFromGlamclub(
    body: Array<number>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListProductVariantSummary>;
  public removeProductVariantFromGlamclub(
    body: Array<number>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListProductVariantSummary>>;
  public removeProductVariantFromGlamclub(
    body: Array<number>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListProductVariantSummary>>;
  public removeProductVariantFromGlamclub(
    body: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling removeProductVariantFromGlamclub.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AListProductVariantSummary>(
      'put',
      `${this.basePath}/product/variant/glamclub/disable`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public saveGlamclubDisplayOrders(
    body: Array<number>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListProductVariantSummary>;
  public saveGlamclubDisplayOrders(
    body: Array<number>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListProductVariantSummary>>;
  public saveGlamclubDisplayOrders(
    body: Array<number>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListProductVariantSummary>>;
  public saveGlamclubDisplayOrders(
    body: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling saveGlamclubDisplayOrders.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AListProductVariantSummary>(
      'put',
      `${this.basePath}/product/variant/glamclub/`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param productId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public saveImagePositions(
    body: Array<number>,
    productId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListProductImage>;
  public saveImagePositions(
    body: Array<number>,
    productId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListProductImage>>;
  public saveImagePositions(
    body: Array<number>,
    productId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListProductImage>>;
  public saveImagePositions(
    body: Array<number>,
    productId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling saveImagePositions.'
      );
    }

    if (productId === null || productId === undefined) {
      throw new Error(
        'Required parameter productId was null or undefined when calling saveImagePositions.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AListProductImage>(
      'put',
      `${this.basePath}/product/image/positions/${encodeURIComponent(
        String(productId)
      )}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param search
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public searchProductByNameOrExternalIdOrBrand(
    search: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListProductSummaryItem>;
  public searchProductByNameOrExternalIdOrBrand(
    search: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListProductSummaryItem>>;
  public searchProductByNameOrExternalIdOrBrand(
    search: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListProductSummaryItem>>;
  public searchProductByNameOrExternalIdOrBrand(
    search: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (search === null || search === undefined) {
      throw new Error(
        'Required parameter search was null or undefined when calling searchProductByNameOrExternalIdOrBrand.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListProductSummaryItem>(
      'get',
      `${this.basePath}/product/search/${encodeURIComponent(String(search))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param search
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public searchVariantsByNameOrExternalIdOrBrandOrInternalEAN(
    search: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListProductVariantSummary>;
  public searchVariantsByNameOrExternalIdOrBrandOrInternalEAN(
    search: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListProductVariantSummary>>;
  public searchVariantsByNameOrExternalIdOrBrandOrInternalEAN(
    search: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListProductVariantSummary>>;
  public searchVariantsByNameOrExternalIdOrBrandOrInternalEAN(
    search: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (search === null || search === undefined) {
      throw new Error(
        'Required parameter search was null or undefined when calling searchVariantsByNameOrExternalIdOrBrandOrInternalEAN.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListProductVariantSummary>(
      'get',
      `${this.basePath}/product/variant/list/${encodeURIComponent(
        String(search)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public shopReviewllm(
    body: Array<ShopReviewLLMRequest>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListFeedbackLLMResponse>;
  public shopReviewllm(
    body: Array<ShopReviewLLMRequest>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListFeedbackLLMResponse>>;
  public shopReviewllm(
    body: Array<ShopReviewLLMRequest>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListFeedbackLLMResponse>>;
  public shopReviewllm(
    body: Array<ShopReviewLLMRequest>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling shopReviewllm.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AListFeedbackLLMResponse>(
      'put',
      `${this.basePath}/product/shop-review/llm`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param file
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateGlamclubXlsxFileForm(
    file?: Blob,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListProductVariantSummary>;
  public updateGlamclubXlsxFileForm(
    file?: Blob,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListProductVariantSummary>>;
  public updateGlamclubXlsxFileForm(
    file?: Blob,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListProductVariantSummary>>;
  public updateGlamclubXlsxFileForm(
    file?: Blob,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['multipart/form-data'];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): void };
    let useForm = false;
    let convertFormParamsToString = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({
        encoder: new CustomHttpUrlEncodingCodec()
      });
    }

    if (file !== undefined) {
      formParams = (formParams.append('file', <any>file) as any) || formParams;
    }

    return this.httpClient.request<ResponseB4AListProductVariantSummary>(
      'post',
      `${this.basePath}/product/glamclub/upload`,
      {
        body: convertFormParamsToString ? formParams.toString() : formParams,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateImageProductVariantId(
    body: ProductImageProductVariantUpdateRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AProductImage>;
  public updateImageProductVariantId(
    body: ProductImageProductVariantUpdateRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AProductImage>>;
  public updateImageProductVariantId(
    body: ProductImageProductVariantUpdateRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AProductImage>>;
  public updateImageProductVariantId(
    body: ProductImageProductVariantUpdateRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling updateImageProductVariantId.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AProductImage>(
      'put',
      `${this.basePath}/product/image/variant`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateProduct(
    body: ProductUpdateRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AProduct>;
  public updateProduct(
    body: ProductUpdateRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AProduct>>;
  public updateProduct(
    body: ProductUpdateRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AProduct>>;
  public updateProduct(
    body: ProductUpdateRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling updateProduct.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AProduct>(
      'put',
      `${this.basePath}/product/`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateProductCategory(
    body: ProductCategoryUpdateRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AProductCategory>;
  public updateProductCategory(
    body: ProductCategoryUpdateRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AProductCategory>>;
  public updateProductCategory(
    body: ProductCategoryUpdateRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AProductCategory>>;
  public updateProductCategory(
    body: ProductCategoryUpdateRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling updateProductCategory.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AProductCategory>(
      'put',
      `${this.basePath}/product/category/allocation`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param productId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateProductFinalities(
    body: Array<number>,
    productId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListProductFinalityItem>;
  public updateProductFinalities(
    body: Array<number>,
    productId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListProductFinalityItem>>;
  public updateProductFinalities(
    body: Array<number>,
    productId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListProductFinalityItem>>;
  public updateProductFinalities(
    body: Array<number>,
    productId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling updateProductFinalities.'
      );
    }

    if (productId === null || productId === undefined) {
      throw new Error(
        'Required parameter productId was null or undefined when calling updateProductFinalities.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AListProductFinalityItem>(
      'put',
      `${this.basePath}/product/finality/${encodeURIComponent(
        String(productId)
      )}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateProductVariant(
    body: ProductVariantUpdateRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AProductVariant>;
  public updateProductVariant(
    body: ProductVariantUpdateRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AProductVariant>>;
  public updateProductVariant(
    body: ProductVariantUpdateRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AProductVariant>>;
  public updateProductVariant(
    body: ProductVariantUpdateRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling updateProductVariant.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AProductVariant>(
      'put',
      `${this.basePath}/product/variant`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}

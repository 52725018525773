import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { lastValueFrom, map } from 'rxjs';
import {
  MovementControllerService,
  PageableRequest,
  ProductVariantMovementDetail
} from 'src/app/admin-api';
import { PageContent, TableColumn } from 'src/app/components/table';
import { getAllBrandEquities, getEquityLabel } from 'src/app/models';

@Component({
  selector: 'app-movements',
  templateUrl: './movements.component.html',
  styleUrls: ['./movements.component.scss']
})
export class MovementsComponent {
  cols = [
    new TableColumn(
      'Tipo',
      'type',
      true,
      'text',
      undefined,
      undefined,
      true,
      'equals'
    ),
    new TableColumn(
      'SKU Admin',
      'productVariantId',
      true,
      'number',
      '/products/catalog/product-variant/',
      'productVariantId'
    ),
    new TableColumn(
      'SKU Millennium',
      'externalId',
      true,
      'number',
      '/products/catalog/product-variant/',
      'productVariantId'
    ),
    new TableColumn(
      'EAN',
      'internalEAN',
      true,
      'text',
      '/products/catalog/product-variant/',
      'productVariantId'
    ),
    new TableColumn(
      'Produto',
      'productVariantName',
      true,
      'text',
      '/products/catalog/product-variant/',
      'productVariantId',
      true,
      'contains'
    ),
    new TableColumn(
      'Marca',
      'brandName',
      true,
      'text',
      '/products/brands/',
      'brandId',
      true,
      'contains'
    ),
    new TableColumn(
      'Brand Equity',
      'brandEquityLabel',
      true,
      'text',
      undefined,
      undefined,
      true,
      'equals'
    ),
    new TableColumn('Qtd', 'quantity', false, 'formattedInteger'),
    new TableColumn(
      'Data movimentação',
      'movementDate',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    ),
    new TableColumn(
      'Validade',
      'expiration',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Assinante/Composição',
      'personName',
      true,
      'text',
      '',
      '',
      true,
      'contains'
    ),
    new TableColumn(
      'Motivo brinde',
      'referenceName',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    )
  ];
  actionFields = ['personName'];
  dropdownFilters = {
    type: [
      { label: 'Todas', value: 'Unkown' },
      { label: 'Troca', value: 'Troca' },
      { label: 'Brinde', value: 'Brinde' },
      { label: 'Reenvio', value: 'Reenvio' },
      { label: 'Incentivo para influencer', value: 'Incentivo influencer' },
      { label: 'Composição', value: 'Composicao' }
    ],
    brandEquityLabel: [{ label: 'Todas', value: '-1' }].concat(
      getAllBrandEquities().map((e) => ({
        label: e.label,
        value: e.value.toString()
      }))
    )
  };

  defaultFilterValues: { [field: string]: any | Array<any> };

  constructor(
    public movementService: MovementControllerService,
    private title: Title,
    private router: Router
  ) {
    this.title.setTitle('Movimentações de produtos de assinatura');
    this.defaultFilterValues = { movementDate: this.startMovementDates };
  }

  async findPage(
    request: PageableRequest,
    service: unknown
  ): Promise<
    PageContent<ProductVariantMovementDetail & { brandEquityLabel: string }>
  > {
    const all = request.filters?.find(
      (f) =>
        f.field === 'type' &&
        (f.value === 'Unkown' || !(f.value as string)?.length)
    );
    if (all) {
      all.condition = 'notEquals';
      all.value = 'Unkown';
    }
    const filterEquity = request.filters?.find(
      (f) => f.field === 'brandEquityLabel'
    );
    if (filterEquity?.value) {
      if (Number(filterEquity.value) >= 0)
        request.filters?.push({
          condition: 'equals',
          field: 'brandEquity',
          fieldType: 'number',
          value: filterEquity.value
        });
    }
    return lastValueFrom(
      (service as MovementControllerService)
        .findProductVariantMovementsTable(request)
        .pipe(
          map((data) => ({
            ...(data.result as PageContent<
              ProductVariantMovementDetail & { brandEquityLabel: string }
            >),
            content: data.result.content.map((mv) => ({
              ...mv,
              brandEquityLabel: getEquityLabel(mv.brandEquity)
            }))
          }))
        )
    );
  }

  goToReference($event: {
    value: ProductVariantMovementDetail;
    field: string;
  }): void {
    let url = '/operations/compositions/id/' + $event.value.movementId;
    if ($event.value.personId) {
      url = '/users/person/' + $event.value.personId;
    }
    this.router.navigate([url]);
  }

  get startMovementDates(): Array<Date> {
    const startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 1);
    return [startDate, new Date()];
  }
}

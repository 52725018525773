<div class="email-template-analytics">
  <div class="page-header">
    <h3>Dashboard de CRM</h3>
  </div>

  <div class="form-b4a">
    <!-- Date Filter Form -->
    <form [formGroup]="dateFilterForm" class="form-b4a">
      <span class="p-float-label">
        <p-multiSelect
          *ngIf="templates?.length"
          [options]="templates"
          appendTo="body"
          formControlName="templates"
          optionValue="id"
          optionLabel="templateName"
          name="templates"
          inputId="templates"
          (onPanelHide)="loadAnalyticsFromAPI()"
        />
        <label for="templates">Template</label>
      </span>

      <span class="p-float-label">
        <p-calendar
          formControlName="startDate"
          inputId="startDate"
          dateFormat="dd/mm/yy"
          [showIcon]="true"
          [maxDate]="dateFilterForm.get('endDate')?.value || today"
          (onSelect)="loadAnalyticsFromAPI()"
          appendTo="body"
        ></p-calendar>
        <label for="startDate">Data Inicial</label>
        <small
          class="error"
          *ngIf="
            dateFilterForm.controls['startDate'].dirty &&
            dateFilterForm.controls['startDate'].errors &&
            dateFilterForm.controls['startDate'].errors['invalidDate']
          "
          >{{
            dateFilterForm.controls['startDate'].errors['invalidDate']
          }}</small
        >
      </span>

      <span class="p-float-label">
        <p-calendar
          formControlName="endDate"
          inputId="endDate"
          dateFormat="dd/mm/yy"
          [showIcon]="true"
          [minDate]="dateFilterForm.get('startDate')?.value"
          [maxDate]="today"
          appendTo="body"
          (onSelect)="loadAnalyticsFromAPI()"
        ></p-calendar>
        <label for="endDate">Data Final</label>
        <small
          class="error"
          *ngIf="
            dateFilterForm.controls['endDate'].dirty &&
            dateFilterForm.controls['endDate'].errors &&
            dateFilterForm.controls['endDate'].errors['invalidDate']
          "
          >{{ dateFilterForm.controls['endDate'].errors['invalidDate'] }}</small
        >
      </span>
    </form>

    <!-- Analytics Cards -->
    <div class="analytics-cards" *ngIf="analyticsData">
      <div class="dashboard-cards">
        <div class="dashboard-card" *ngFor="let event of eventsArray">
          <span class="header" [style.background-color]="event.color">
            {{ event.label }}
          </span>
          <span class="value">
            <span
              [class.data-link]="totalEvent(event.eventName)"
              (click)="totalEvent(event.eventName) > 0 && showEvents(event)"
            >
              {{ totalEvent(event.eventName) | number }}
            </span>
          </span>
        </div>
      </div>
    </div>

    <!-- Chart - Maior agora que é uma página completa -->
    <div
      class="chart-section"
      *ngIf="analyticsData?.length && chartData?.datasets?.length"
    >
      <h3>Análise de Desempenho</h3>
      <div class="chart-container">
        <p-chart
          type="bar"
          [data]="chartData"
          [options]="chartOptions"
          width="100%"
          height="70vh"
        >
        </p-chart>
      </div>
    </div>
  </div>
</div>

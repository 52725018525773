export interface DialogOptions {
  header?: string;
  visible: boolean;
  position?:
    | 'center'
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'topleft'
    | 'topright'
    | 'bottomleft'
    | 'bottomright';
  closeOnEscape?: boolean;
  styleClass?: string;
}

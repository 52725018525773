import { DecimalPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ShopDashboardService } from 'src/app/connect-api/api/shop/shop-dashboard.service';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-wishlist-dashboard',
  templateUrl: './wishlist-dashboard.component.html',
  styleUrl: './wishlist-dashboard.component.scss',
  providers: [DecimalPipe]
})
export class WishlistDashboardComponent implements OnInit {
  cards: { description: string; value: any }[] = [];

  constructor(
    private dashboardService: ShopDashboardService,
    private title: Title,
    private decimalPipe: DecimalPipe
  ) {
    this.title.setTitle('Dashboard de lojinhas');
  }

  async ngOnInit() {
    LoaderService.showLoader();
    try {
      const data = await this.dashboardService.getWishlistDashboardData();

      const ownBrandsPercentage = (data.ownBrands / data.allBrands) * 100;
      this.cards = [
        {
          description: 'Total de lojinhas',
          value: this.decimalPipe.transform(data.totalShops, '1.0-0')
        },
        {
          description: 'SKUs Marcas Próprias/Todos',
          value: `${this.decimalPipe.transform(
            data.ownBrands,
            '1.0-0'
          )}/${this.decimalPipe.transform(
            data.allBrands,
            '1.0-0'
          )} (${ownBrandsPercentage.toFixed(2)}%)`
        }
      ];
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }
}

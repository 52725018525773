export enum PaymentMethod {
  credit_card = 'Cartão de crédito',
  pix = 'PIX',
  boleto = 'Boleto'
}

export function getAllPaymentMethod(): Array<{ label: string; value: string }> {
  const objetos: Array<{ label: string; value: string }> = [];
  for (const [p, propertyValue] of Object.entries(PaymentMethod)) {
    try {
      objetos.push({
        label: propertyValue,
        value: p
      });
    } catch (error: any) {
      objetos.length;
    }
  }
  return objetos;
}

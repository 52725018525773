import { Component, OnInit, inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Product } from '@infrab4a/connect';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-shop-product-description-review',
  templateUrl: './shop-product-description-review.component.html',
  styleUrl: './shop-product-description-review.component.scss'
})
export class ShopProductDescriptionReviewComponent implements OnInit {
  private config = inject(DynamicDialogConfig);
  protected ref = inject(DynamicDialogRef);
  private sanitizer = inject(DomSanitizer);

  product: Partial<Product> = this.config.data.product;
  videoHtml: SafeHtml;

  ngOnInit(): void {
    this.videoHtml = this.product?.video
      ? this.sanitizer.bypassSecurityTrustHtml(this.product.video)
      : '';
  }

  submit(): void {
    this.ref.close(true);
  }
}

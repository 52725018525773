<app-table
  [lazy]="true"
  [cols]="columns"
  tableTitle="Brindes não Recebidos"
  [addButton]="false"
  [exportPdf]="false"
  [exportExcel]="false"
  [refreshButton]="false"
  [service]="itOpsService"
  pageFunctionName="getDontReceiveGiftTable"
  emptyMessage="Sem brindes que não foram recebidos no momento."
  selectionActionLabel="Reenviar brindes"
  selectionActionTooltip="Reenviar os brindes selecionados"
  selectionActionIcon="pi pi-check"
  (emitSelection)="fixGifts($event)"
  modelName="brindes"
  [defaultRows]="15"
></app-table>
<p-confirmDialog></p-confirmDialog>
<p-toast></p-toast>

import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import { Coupon, CouponControllerService } from 'src/app/admin-api';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-email-restriction-form',
  templateUrl: './email-restriction-form.component.html',
  styleUrls: ['./email-restriction-form.component.scss']
})
export class EmailRestrictionFormComponent {
  coupon: Coupon | undefined;
  form = new FormGroup({
    emails: new FormControl<string[]>([], Validators.required),
    couponId: new FormControl<number>(null, Validators.required)
  });
  chipRegex: any = /\r|\n|;|,| /;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private couponService: CouponControllerService
  ) {
    if (!this.config.data.coupon)
      throw AppDialogService.showErrorDialog({
        message: 'Por favor informe o cupom'
      });
    this.coupon = this.config.data.coupon;
    this.form.controls['couponId'].setValue(this.coupon.couponId);
  }

  async submit(): Promise<void> {
    LoaderService.showLoader();
    try {
      const restrictions = await lastValueFrom(
        this.couponService
          .addCouponEmailRestrictions(this.form.value)
          .pipe(map((data) => data.result))
      );
      this.ref.close(restrictions);
    } catch (error) {
      LoaderService.showLoader(false);
      AppDialogService.showErrorDialog(error);
    }
  }

  async uploadFile($event: { files: File[] }): Promise<void> {
    LoaderService.showLoader();
    try {
      const restrictions = await lastValueFrom(
        this.couponService
          .addEmailRestrictionsFromXlsxFileForm(
            this.coupon.couponId,
            $event.files[0]
          )
          .pipe(map((data) => data.result))
      );
      this.ref.close(restrictions);
    } catch (error) {
      LoaderService.showLoader(false);
      AppDialogService.showErrorDialog(error);
    }
  }

  trimValues(): void {
    this.form.controls['emails'].setValue(
      this.form.value.emails.map((e) => e.trim().toLowerCase())
    );
  }
}

/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SubscriberCancellationDetail {
  subscriberCancellationId?: number;
  subscriberId?: number;
  subscriberControlId?: number;
  cancellationType?: number;
  cancelledBy?: string;
  editionCancelled?: number;
  dateCancelled?: Date;
  cancellationReasonId?: number;
  cancellationReason?: string;
  dateSyncERP?: string;
  subscriberCancellationReasonName?: string;
  shippingName?: string;
  boxId?: string;
  active?: boolean;
  cancellationReference?: number;
  dateExecuted?: Date;
  fee?: number;
  refundAmount?: number;
  chargeAmount?: number;
}

export enum DescriptionFieldEnum {
  'description' = {
    value: 'description',
    label: 'Descrição do produto',
    controlName: 'productSummary'
  } as DescriptionField | any,
  'differentials' = {
    value: 'differentials',
    label: 'Diferenciais',
    controlName: 'differentials'
  } as DescriptionField | any,
  'whoMustUse' = {
    value: 'whoMustUse',
    label: 'Para quem é indicado?',
    controlName: 'whoMustUse'
  } as DescriptionField | any,
  'howToUse' = {
    value: 'howToUse',
    label: 'Como usar?',
    controlName: 'howToUse'
  } as DescriptionField | any,
  'brand' = {
    value: 'brand',
    label: 'Sobre a marca',
    controlName: 'aboutTheBrand'
  } as DescriptionField | any,
  'ingredients' = {
    value: 'ingredients',
    label: 'Principais ingredientes',
    controlName: 'ingredients'
  } as DescriptionField | any
}

export function getAllDescriptionFields(): DescriptionField[] {
  const objetos: DescriptionField[] = [];
  for (const [, propertyValue] of Object.entries(DescriptionFieldEnum)) {
    try {
      if (typeof propertyValue === 'object') {
        objetos.push(propertyValue);
      }
    } catch (error: any) {
      objetos.length;
    }
  }
  return objetos;
}

export function getDescriptionField(value: string) {
  return getAllDescriptionFields().find((v) => v.value === value);
}

export function getDescriptionFieldFromControl(control: string) {
  return getAllDescriptionFields().find((v) => v.controlName === control);
}

export interface DescriptionField {
  value: string;
  label: string;
  controlName: string;
}

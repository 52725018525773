import { CurrencyPipe, DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { lastValueFrom } from 'rxjs';
import {
  InfluencerControllerService,
  InfluencerCoupon,
  InfluencerCouponDetailView,
  InfluencerDetail
} from 'src/app/admin-api';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';
import { TableColumn } from '../../table';
import { InfluencerCouponFormComponent } from '../influencer-coupon-form/influencer-coupon-form.component';

@Component({
  selector: 'app-influencer-coupons',
  templateUrl: './influencer-coupons.component.html',
  styleUrls: ['./influencer-coupons.component.scss'],
  providers: [DatePipe, CurrencyPipe, DialogService]
})
export class InfluencerCouponsComponent implements OnChanges {
  @Input()
  coupons:
    | Array<InfluencerCouponDetailView & { valueString?: string }>
    | undefined;

  @Input()
  influencer: InfluencerDetail | undefined;

  @Input()
  isMobile: boolean | undefined;

  @Output()
  newCoupon = new EventEmitter<InfluencerCoupon>();

  cols: Array<TableColumn> = [
    new TableColumn('Id', 'couponId', false, 'number'),
    new TableColumn('Cupom', 'couponName', false, 'text'),
    new TableColumn('Plano', 'subscriptionName', false, 'text'),
    new TableColumn('Desconto', 'valueString', false, 'text'),
    new TableColumn('Data Criação', 'dateCreated', false, 'date'),
    new TableColumn('Ativo', 'enabled', false, 'inputSwitch')
  ];

  constructor(
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    private influencerService: InfluencerControllerService,
    private dialog: DialogService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['coupons']?.currentValue)
      this.coupons?.forEach(
        (c) =>
          (c.valueString = FormUtil.couponDiscountValue(
            c.value as number,
            c.couponType as number,
            this.currencyPipe
          ))
      );
  }

  timeDiffLabel(dateStart: string): string {
    return FormUtil.timeDiffLabel(dateStart, this.datePipe);
  }

  async updateCouponStatus(coupon: InfluencerCouponDetailView): Promise<void> {
    LoaderService.showLoader();
    try {
      await lastValueFrom(
        this.influencerService.updateInfluencerCoupon(coupon)
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  async addCoupon(): Promise<void> {
    LoaderService.showLoader();
    this.dialog
      .open(InfluencerCouponFormComponent, {
        header: 'Cupom da influencer',
        data: {
          influencer: this.influencer
        },
        height: '65vh',
        width: '600px'
      })
      .onClose.subscribe((data: InfluencerCoupon) => {
        if (data) {
          this.newCoupon.emit(data);
        }
      });
    LoaderService.showLoader(false);
  }
}
